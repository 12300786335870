import {
  RavenButton,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
// import downloadIcon from "../../../assets/download-icon.svg";
import "../../../styles/dashboard/payroll/PayrollDisbursement.css";
import { ThreeDots } from "react-loader-spinner";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  generateStatementTransactionForAllEmpSingle,
  getPreviousDisbursbement,
} from "../../../redux/payroll";
import {
  formatDate,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  getPageNum,
  getTotalPage,
  trimLongString,
} from "../../../utils/helper/Helper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import GenerateAllDisburseModal from "./modals/GenerateAllDisburseModal";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import MobileEmployeeViewHistory from "./modals/MobileEmployeeViewHistory";
import useWindowSize from "../../../utils/helper/UseWindowSize";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";
import ActionContext from "../../../context/ActionContext";
// import moment from "moment";

const PayrollHistory = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({
    generate: false,
    mobile_view: false,
  });
  const [selectActive, setselectActive] = useState("Jan");
  const [monthList, setMonthList] = useState([]);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { loading, previous_disbursement } = useSelector(
    (state) => state.payroll
  );
  const size = useWindowSize();
  const headerList = [
    " NAME",
    "ACCOUNT NO.",
    "BANK NAME",
    "USERNAME",
    "SALARY",
  ];

  const avatarFormatWrap = (param) => {
    return (
      <div className="text-avatar-box">
        <div className="avatar-box">
          <span>{`${param?.split("")[0]?.charAt(0)} ${param
            ?.split("")[1]
            ?.charAt(0)}`}</span>
        </div>
        <p className="text">{param}</p>
      </div>
    );
  };

  useEffect(() => {
    getPayrollHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayrollHistory = async (cur, date) => {
    const payload = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: cur || 1,
      date: date || "none",
    };
    // console.log(payload);
    // return;
    const data = await dispatch(getPreviousDisbursbement(payload));
    if (data?.payload?.data?.status === "success") {
      const date = data?.payload?.data?.data?.datesForSubsequentRecords;
      const dateList = formatHistoryDate(date);
      setMonthList(dateList);
      const currentDate = dateList[dateList?.length - 1];
      setselectActive(currentDate);
    }
  };
  const { user } = useSelector((state) => state.user);
  const formatFilterDate = (obj) => {
    const filterOption = `${moment(obj?.start_date_for_the_month).format(
      "YYYY-MM-DD"
    )}_${moment(obj?.end_date_for_the_month).format("YYYY-MM-DD")}`;
    return filterOption;
  };

  const handleFetchBydate = (obj) => {
    const payload = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: 1,
      date: formatFilterDate(obj),
    };
    // console.log(payload);
    dispatch(getPreviousDisbursbement(payload));
  };

  const handleDownloadSingleCsv = async (id) => {
    const obj = {
      salary_transaction_id: String(id),
    };
    const data = await dispatch(
      generateStatementTransactionForAllEmpSingle(obj)
    );
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      setDownloadCsvId("");
      downloadCsv(data?.payload?.data?.data?.url);
      // downloadCsvId("");
      // console.log(data);
    } else {
      setDownloadCsvId("");
    }
  };
  const [singleObj, setSingleObj] = useState({});

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };
  const [downloadCsvId, setDownloadCsvId] = useState("");
  const payrollHistoryActions = (param) => {
    return (
      <div className="transaction-action-wrap">
        {loading ? (
          <Skeleton width={30} height={30} circle />
        ) : (
          <>
            {downloadCsvId === param?.id ? (
              <div style={{ padding: "1rem 0rem" }} className="">
                <ThreeDots
                  height="10"
                  width="80"
                  radius="9"
                  color="#020202"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <figure
                className="img-box grey-bg darken-action-bg tooltip-hover-wrap"
                onClick={() => {
                  // console.log(param?.id);
                  setDownloadCsvId(param?.id);
                  handleDownloadSingleCsv(param?.id);
                }}
              >
                <RavenToolTip
                  color={`black-light`}
                  text={`Download template for user ${
                    param?.account_name?.split(" ")[0]
                  } ${param?.account_name?.split(" ")[1]} `}
                  position={`left`}
                />
                <svg
                  className="img dull-bright-filter"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.75 14.75H1.25M12.5 7.25L8 11.75M8 11.75L3.5 7.25M8 11.75V1.25"
                    stroke="#676767"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </figure>
            )}
          </>
        )}
      </div>
    );
  };

  const formatHistoryDate = (list) => {
    const getMonth = (param) => {
      // console.log(param);
      const val = `${formatDate(param).split(" ")[0].slice(0, 3)} '${formatDate(
        param
      )
        .split(" ")[2]
        .slice(2, 4)}`;
      return val;
    };
    const newList = list.map((chi) => {
      return { ...chi, name: getMonth(chi?.end_date_for_the_month) };
    });
    return newList;
  };

  const downloadIcon = (
    <svg
      className="img"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9492 13.5133V14.7133C18.9492 16.3935 18.9492 17.2335 18.6222 17.8753C18.3346 18.4398 17.8757 18.8987 17.3112 19.1863C16.6695 19.5133 15.8294 19.5133 14.1492 19.5133H5.74922C4.06906 19.5133 3.22898 19.5133 2.58725 19.1863C2.02276 18.8987 1.56382 18.4398 1.2762 17.8753C0.949219 17.2335 0.949219 16.3935 0.949219 14.7133V13.5133M14.9492 8.51331L9.94922 13.5133M9.94922 13.5133L4.94922 8.51331M9.94922 13.5133V1.51331"
        stroke="#676767"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          onBack={() => {
            navigate(-1);
          }}
          title={`Payroll Histories`}
          text={`Easily manage your staffs  payment and deducations`}
          back
        />
        <div className="payroll-history-top-detail-card-box card-bg">
          {monthList?.length > 0 && (
            <div
              onClick={() => {
                // console.log(previous_disbursement);
              }}
              className="top-box"
            >
              <div className="select-wrap grey-bg">
                {" "}
                {monthList?.map((chi, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {loading ? (
                        <Skeleton
                          width={65}
                          height={35}
                          style={{ borderRadius: ".8rem" }}
                        />
                      ) : (
                        <div
                          onClick={() => {
                            if (selectActive?.name !== chi?.name) {
                              setselectActive(chi);
                              handleFetchBydate(chi);
                            }
                          }}
                          className={`select ${
                            chi?.name === selectActive?.name &&
                            "selected white-black-bg"
                          }`}
                        >
                          {chi?.name}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
          <div className="wrap">
            {/* left box start */}
            <div className="left-box">
              {/* wallet s\cash box start */}
              <div className="wallet-cash-box">
                {/* bosx styart */}
                <div className="box">
                  {loading ? (
                    <Skeleton width={`100%`} height={20} />
                  ) : (
                    <p className="value">
                      {formatNumWithCommaNairaSymbol(
                        previous_disbursement?.totalAmounPaid || "0"
                      )}
                    </p>
                  )}
                  <p className="label grey-white-color">TOTAL SALARY PAID</p>
                </div>
                {/* bosx end */}
                {/* bosx styart */}
                <div className="box">
                  {loading ? (
                    <Skeleton width={`100%`} height={20} />
                  ) : (
                    <p className="value">
                      {" "}
                      {formatNumWithCommaNairaSymbol(
                        previous_disbursement?.totalDeductions || "0"
                      )}
                    </p>
                  )}
                  <p className="label grey-white-color">
                    CUMULATIVE DEDUCTIONS
                  </p>
                </div>
                {/* bosx end */}
                {/* bosx styart */}
                <div className="box">
                  {loading ? (
                    <Skeleton width={`40%`} height={20} />
                  ) : (
                    <p className="value">
                      {previous_disbursement?.totalRecipients || 0}
                    </p>
                  )}
                  <p className="label grey-white-color">EMPLOYEES PAID</p>
                </div>
                {/* bosx end */}
                {/* bosx styart */}
                <div className="box">
                  {loading ? (
                    <Skeleton width={`100%`} height={20} />
                  ) : (
                    <p className="value">
                      {" "}
                      {formatNumWithCommaNairaSymbol(
                        previous_disbursement?.totalOverdraftDeductions || "0"
                      )}
                    </p>
                  )}
                  <p className="label grey-white-color">OVERDRAFTS DISBURSED</p>
                </div>
                {/* bosx end */}
              </div>
              {/* wallet s\cash box end */}
            </div>
            {/* left box end */}
            {/* right box start */}
            <div className="right-box">
              {previous_disbursement?.processed_salary_transaction?.data
                ?.length > 0 && (
                <RavenButton
                  onClick={() => {
                    setShowModal((prev) => {
                      return { ...prev, generate: true };
                    });
                  }}
                  className="download-btn"
                  color={`black-color`}
                >
                  Generate Statement{" "}
                  <figure className="img-box">
                    <img src={downloadIcon} alt="" className="img" />
                  </figure>{" "}
                </RavenButton>
              )}
            </div>
            {/* right box end */}
          </div>
        </div>
        {/* top detail card box send */}
        <ContainerWrapper
          width={`100%`}
          dontShowHeader
          pagination={
            previous_disbursement?.processed_salary_transaction?.data?.length >
              0 && (
              <RavenPagination
                color={`black-light`}
                blackHover
                currentPage={
                  Number(
                    previous_disbursement?.processed_salary_transaction
                      ?.current_page
                  ) || 1
                }
                nextPage={
                  previous_disbursement?.processed_salary_transaction
                    ?.next_page_url_params
                }
                prevPage={
                  previous_disbursement?.processed_salary_transaction
                    ?.prev_page_url_params
                }
                onNext={(e) => {
                  getPayrollHistory(
                    getPageNum(e),
                    formatFilterDate(selectActive)
                  );
                }}
                onPrev={(e) => {
                  getPayrollHistory(
                    getPageNum(e),
                    formatFilterDate(selectActive)
                  );
                }}
                totalPage={
                  getTotalPage(
                    Number(
                      previous_disbursement?.processed_salary_transaction
                        ?.per_page
                    ),
                    Number(
                      previous_disbursement?.processed_salary_transaction?.total
                    )
                  ) || 1
                }
                onNumView={(e) => {
                  getPayrollHistory(e, formatFilterDate(selectActive));
                }}
              />
            )
          }
        >
          {previous_disbursement?.processed_salary_transaction?.data?.length >
          0 ? (
            <div className="payroll-history-table-box-wrap">
              {/* per page start */}
              {/* <PerPageComponent /> */}
              {/* per page end */}
              <div className="scroll-wrap">
                <RavenTable
                  className={
                   `table-fixed-type ${ formatMetaData(user?.theme_display_settings)?.table_font ===
                      "bold" && "table-business"}`
                  }
                  headerList={headerList}
                  action
                >
                  {previous_disbursement?.processed_salary_transaction?.data?.map(
                    (chi, idx) => {
                      const {
                        account_number,
                        bank,
                        account_name,
                        net_amount,
                        username,
                        id,
                      } = chi;
                      return (
                        <RavenTableRow
                          loading={loading}
                          key={idx || id}
                          one={avatarFormatWrap(account_name)}
                          two={account_number}
                          four={username || "---"}
                          three={bank}
                          five={formatNumWithCommaNairaSymbol(net_amount)}
                          ManualAddActions={() => payrollHistoryActions(chi)}
                        />
                      );
                    }
                  )}
                </RavenTable>
              </div>

              <div className="mobile-table-box">
                {previous_disbursement?.processed_salary_transaction?.data?.map(
                  (chi, idx) => {
                    const {
                      account_number,
                      bank,
                      account_name,
                      net_amount,
                      username,
                      id,
                    } = chi;
                    return (
                      <MobileTableCard
                        key={idx || id}
                        amount={formatNumWithCommaNairaSymbol(net_amount)}
                        text={`${account_number} • ${trimLongString(bank, 15)}`}
                        avatar={`${account_name || username}`}
                        onRowClick={() => {
                          setSingleObj(chi);
                          setShowModal((prev) => {
                            return { ...prev, mobile_view: true };
                          });
                        }}
                        loading={loading}
                        title={account_name}
                        showImg
                      />
                    );
                  }
                )}
              </div>

              <div className="space-bottom"></div>
            </div>
          ) : (
            <NoContentBox title={`No Payroll History`} />
          )}
        </ContainerWrapper>
      </DashboardLayout>
      {/* delete mod start */}
      <GenerateAllDisburseModal
        visible={showModal?.generate}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, generate: false };
          });
        }}
        // onFinish={() => {
        //   // navigate("/dashboard-payroll");
        // }}
      />
      {/* delete mod end */}
      {/* delete mod start */}
      <MobileEmployeeViewHistory
        visible={showModal?.mobile_view && size.width < 900}
        detail={singleObj}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, mobile_view: false };
          });
        }}
        downloadCsv={downloadCsvId}
        onDownload={(id) => {
          setDownloadCsvId(id);
          // console.log(id);
          handleDownloadSingleCsv(id);
        }}
      />
      {/* delete mod end */}
    </>
  );
};

export default PayrollHistory;
