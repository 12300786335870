import { RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import React from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import "../../../../styles/fragments/PreviewDetailsCommonBox.css";

const PrevieDetailsBox = ({
  collapse,
  amount,
  name,
  bank,
  account_name,
  narration,
  onChange,
  value,
  id,
  checked,
  previewOpen,
  setPreviewOpen,
}) => {
  // const [showDetail, setShowDetail] = useState(false);
  return (
    <div
      className={`preview-details-common-box grey-bg animate-move-up-class ${
        collapse && "preview-details-common-box-collapse"
      } ${previewOpen === name && "preview-details-common-box-show"}`}
    >
      {/* top box start */}
      <div
        onClick={() => {
          if (collapse) {
            previewOpen === name ? setPreviewOpen("") : setPreviewOpen(name);
          }
        }}
        className="top-box"
      >
        <div className="text-box">
          <p className="key grey-white-color">Confirm Transfer of</p>
          <p className="value">{amount || "987678"}</p>
        </div>
        {collapse && (
          <div className="icon-box">
            {previewOpen !== name ? (
              <FaAngleDown className="icon" />
            ) : (
              <FaAngleRight className="icon" />
            )}
          </div>
        )}
      </div>
      {/* top box end */}
      {/* middle-content start */}
      <div className="middle-box">
        <p className="text">
          <span className="grey-white-color">To:</span>{" "}
          <span >@{name || ""}</span>
        </p>
        {/* <p className="text">
          <span className="grey-white-color">Bank:</span>{" "}
          <span style={{ textTransform: "capitalize" }}>
            {bank || "Guarantee Trust Bank"}
          </span>
        </p> */}
        <p className="text">
          <span className="grey-white-color">Account Name:</span>{" "}
          <span>{account_name || ""}</span>
        </p>
        <p className="text">
          <span className="grey-white-color">Narration:</span>{" "}
          <span>{narration || ""}</span>
        </p>
      </div>
      {/* middle content end */}
      {collapse && previewOpen !== name && (
        <div className={`collapse-box`}>
          <p className="text">
            <span className="grey-white-color">To:</span>{" "}
            <span style={{ textTransform: "capitalize" }}>{name || ""}</span>
          </p>
        </div>
      )}
      {/* bottom box start */}
      <div className="bottom-box">
        <p className="text grey-white-color">Save recipient as beneficiary</p>
        <RavenToggleSlide
          onChange={onChange}
          id={id}
          value={value}
          color={`black-light`}
          checked={checked}
          //   className={`toggle-box-wrap`}
        />
      </div>
      {/* bottom box end */}
    </div>
  );
};

export default PrevieDetailsBox;
