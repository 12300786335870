import { RavenInputField, RavenModal, toast } from "@ravenpay/raven-bank-ui";
import React, { useState, useRef, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../context/ActionContext";
import { updateUserPin } from "../../redux/user";
// import { requestPos } from "../../../../redux/pos";
import "../../styles/dashboard/pos/RequestPos.css";

const UpdateTransactionPinModal = ({ visible, onClose, onFinish }) => {
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const { loadUpdate } = useSelector((state) => state.user);
  const [details, setDetails] = useState({
    one: "",
    two: "",
    old: "",
    complete_one: false,
    complete_old: false,
    complete_two: false,
  });

  const handleSubmit = () => {
    if (details?.one !== details?.two) {
      toast.error("Pins does not match");
    } else {
      handleSubmitDetails();
    }
  };

  useEffect(() => {
    setDetails((prev) => {
      return { ...prev, complete_one: false, complete_two: false };
    });
    pinRefVal.current.forEach((input) => (input.value = ""));
    pinRefValTwo.current.forEach((input) => (input.value = ""));
    pinRefValOld.current.forEach((input) => (input.value = ""));
  }, [visible]);

  const handleSubmitDetails = async () => {
    const obj = {
      // ...details,
      old_pin: details?.old,
      new_pin: details?.one,
    };
    // console.log(obj);
    // return
    const data = await dispatch(updateUserPin(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      actionCtx?.setShowUpdate(false);
      //   dispatch(getUser());
    }
  };

  const pinRefVal = useRef();
  const pinRefValTwo = useRef();
  const pinRefValOld = useRef();

  return (
    <RavenModal
      disabled={
        !details?.complete_one ||
        !details?.complete_two ||
        !details?.complete_old
      }
      visble={visible}
      onClose={() => {
        setDetails({
          one: "",
          two: "",
          complete_one: false,
          complete_two: false,
        });
        onClose();
      }}
      btnColor={`black-light`}
      btnLabel={`Update Pin`}
      onBtnClick={handleSubmit}
      loading={loadUpdate}
    >
      <div className="set-terminal-transaction-pin-modal-wrap">
        <p className="title">Change Transaction Pin</p>
        <p className="text grey-white-color border-theme-bottom">
          Set your transaction pin, this will be used to authenticate all
          request
        </p>

        <div className="pin-input-two-wrap" style={{marginTop: "0rem"}}>
          <div
            className={`one-pin-wrap ${
              details?.complete_old && "one-pin-wrap-hide"
            }`}
          >
            <RavenInputField
              pinRef={pinRefValOld}
              className={`terminal-trans-pin`}
              color={`black-light`}
              type={`pin`}
              label={`Old Transaction Pin* `}
              placeholder={`transaction pin `}
              name={`pin`}
              value={details?.old}
              onChange={(e) => {
                setDetails((prev) => {
                  return { ...prev, old: e, complete_old: false };
                });
              }}
              id={`ikjsshs`}
              pinFieldNumber={6}
              onComplete={() => {
                setDetails((prev) => {
                  return { ...prev, complete_old: true };
                });
              }}
            />
          </div>

          <div
            className={`two-pin-wrap ${
              details?.complete_old && "two-pin-wrap-show"
            }`}
          >
            <RavenInputField
              pinRef={pinRefVal}
              className={`terminal-trans-pin`}
              color={`black-light`}
              type={`pin`}
              label={`New Transaction Pin* `}
              placeholder={`transaction pin `}
              name={`pinj`}
              value={details?.complete_old && details?.one}
              onChange={(e) => {
                setDetails((prev) => {
                  return { ...prev, one: e, complete_one: false };
                });
              }}
              id={`ikjsss`}
              pinFieldNumber={6}
              onComplete={() => {
                setDetails((prev) => {
                  return { ...prev, complete_one: true };
                });
              }}
            />
            <RavenInputField
              pinRef={pinRefValTwo}
              className={`terminal-trans-pin`}
              color={`black-light`}
              type={`pin`}
              
              label={`Confirm New Transaction Pin* `}
              placeholder={`transaction pin `}
              name={`pin`}
              id={`ikss`}
              value={details?.complete_old && details?.two}
              onChange={(e) => {
                setDetails((prev) => {
                  return { ...prev, two: e, complete_two: false };
                });
              }}
              pinFieldNumber={6}
              onComplete={() => {
                setDetails((prev) => {
                  return { ...prev, complete_two: true };
                });
              }}
            />
          </div>
        </div>
      </div>
    </RavenModal>
  );
};

export default UpdateTransactionPinModal;
