import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
// import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import "../../../styles/auth/SignUp.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, validateResetPasswordOtp } from "../../../redux/user";

const ForgetPasswordModal = ({ showModal, userEmail, onClose }) => {
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [toggleKey, setKeyToggle] = useState(false);
  const [completeToken, setCompleteToken] = useState(false);
  const [details, setDetails] = useState({
    token: "",
  });

  useEffect(() => {
    setKeyToggle(!toggleKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleSubmit = async () => {
    // e.preventDefault();
    const obj = {
      email: userEmail,
      otp: details?.token,
    };
    const data = await dispatch(validateResetPasswordOtp(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      localStorage?.setItem("otp", details?.token);
      navigate("/create-password");
    }
  };

  const handleResendEmailOtp = async () => {
    const obj = {
      email: userEmail,
    };
    const data = await dispatch(forgotPassword(obj));
    if (data?.payload?.data?.status === "success") {
      setKeyToggle(!toggleKey);
    }
  };

  return (
    <RavenModal
      onClose={onClose}
      visble={showModal}
      btnLabel="Verify Account"
      btnColor={`black-light`}
      effect={`fadeInUp`}
      disabled={!completeToken}
      loading={loading}
      onBtnClick={() => {
        handleSubmit();
      }}
      className={`auth-pin-modal-wrap`}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        action=""
        className="form form-modal-auth"
      >
        <div className="text-box">
          <p className="big-title">Enter Your Token</p>
          <p className="small-title">Follow Prompt to recover your password</p>
        </div>
        <RavenInputField
          type={`pin`}
          // label={`Enter token sent to your email`}
          color={`black-light`}
          onChange={(e) => {
            setCompleteToken(false);
            setDetails((prev) => {
              return { ...prev, token: e };
            });
          }}
          onComplete={() => {
            setCompleteToken(true);
          }}
          value={details?.token}
          // showCountDown
          // countDownTime={1000}
          key={toggleKey}
        />
        <p style={{marginTop: "2rem"}} onClick={handleResendEmailOtp} className="resend-text">
          Resend OTP
        </p>
      </form>
    </RavenModal>
  );
};

export default ForgetPasswordModal;
