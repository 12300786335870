import React, { useContext,  } from "react";
// import NewSelectScheduleBox from "../../../components/reusables/NewSelectScheduleBox";
// import SelectScheduleBox from "../../../components/reusables/SelectScheduleBox";Group 238182raven-default-bank-img.svg
import "../../../styles/fragments/PreviewDetailsCommonBox.css";
// import bankIconDefault from "../../../assets/bank-general-img.svg"
// import bankIconDefault from "../../../assets/raven-default-bank-img.svg";
import { RavenInputField,  } from "@ravenpay/raven-bank-ui";
import moment from "moment";
import {
  capitalizeFirstLetter,
  convertToLowercase,
  formatDate,
  formatMetaData,
  formatNumWithCommaNaira,
  formatTypeFunction,
  // trimLongString,
} from "../../../utils/helper/Helper";
import { FaArrowRight } from "react-icons/fa";
// import ChangeSourceAccount from "../../../components/reusables/ChangeSourceAccount";
import ActionContext from "../../../context/ActionContext";
import { useSelector } from "react-redux";

const NewPreviewDetailBox = ({
  collapse,
  amount,
  name,
  bank,
  account_name,
  narration,
  onChange,
  value,
  id,
  checked,
  onChangeSchedule,
  valueSchedule,
  handleChange,
  details,
  bulkAmount,
  reciepientVal,
  list,
  notSwap,
  convertedAmount,
  convertedCurrency,
  exChangeRate,
}) => {
  // const [viewAll, setViewAll] = useState(false);
  const actionCtx = useContext(ActionContext);
  const {
    // loading,
    // single_global_account,
    // loading,
    all_global_account,
    // single_global_account_trans,
    // all_currencies,
  } = useSelector((state) => state.global_account);
  const swapIcon = (
    <svg
      className="img"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.77778 2.55566V11.4446M9.77778 11.4446L7.55556 9.22233M9.77778 11.4446L12 9.22233M4.22222 11.4446V2.55566M4.22222 2.55566L2 4.77789M4.22222 2.55566L6.44444 4.77789"
        stroke="#ffffff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        // className="dull-bright-filter"
      />
    </svg>
  );

  // const usaIcon = (
  //   <svg
  //     className="img"
  //     width="33"
  //     height="33"
  //     viewBox="0 0 33 33"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <g clip-path="url(#clip0_18332_23977)">
  //       <rect width="33" height="33" rx="16.5" fill="white" />
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M0 0H19.8V15.4H0V0Z"
  //         fill="#1A47B8"
  //       />
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M19.8 0V2.2H46.2V0H19.8ZM19.8 4.4V6.6H46.2V4.4H19.8ZM19.8 8.8V11H46.2V8.8H19.8ZM19.8 13.2V15.4H46.2V13.2H19.8ZM0 17.6V19.8H46.2V17.6H0ZM0 22V24.2H46.2V22H0ZM0 26.4V28.6H46.2V26.4H0ZM0 30.8V33H46.2V30.8H0Z"
  //         fill="#F93939"
  //       />
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M2.2 2.20001V4.40001H4.4V2.20001H2.2ZM6.6 2.20001V4.40001H8.8V2.20001H6.6ZM11 2.20001V4.40001H13.2V2.20001H11ZM15.4 2.20001V4.40001H17.6V2.20001H15.4ZM13.2 4.40001V6.60001H15.4V4.40001H13.2ZM8.8 4.40001V6.60001H11V4.40001H8.8ZM4.4 4.40001V6.60001H6.6V4.40001H4.4ZM2.2 6.60001V8.80001H4.4V6.60001H2.2ZM6.6 6.60001V8.80001H8.8V6.60001H6.6ZM11 6.60001V8.80001H13.2V6.60001H11ZM15.4 6.60001V8.80001H17.6V6.60001H15.4ZM2.2 11V13.2H4.4V11H2.2ZM6.6 11V13.2H8.8V11H6.6ZM11 11V13.2H13.2V11H11ZM15.4 11V13.2H17.6V11H15.4ZM13.2 8.80001V11H15.4V8.80001H13.2ZM8.8 8.80001V11H11V8.80001H8.8ZM4.4 8.80001V11H6.6V8.80001H4.4Z"
  //         fill="white"
  //       />
  //     </g>
  //     <defs>
  //       <clipPath id="clip0_18332_23977">
  //         <rect width="33" height="33" rx="16.5" fill="white" />
  //       </clipPath>
  //     </defs>
  //   </svg>
  // );

  // const britishIcon = (
  //   <svg
  //     className="img"
  //     width="33"
  //     height="33"
  //     viewBox="0 0 33 33"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <g clipPath="url(#clip0_18332_24110)">
  //       <rect width="33" height="33" rx="16.5" fill="#1A47B8" />
  //       <path
  //         fill-rule="evenodd"
  //         clip-rule="evenodd"
  //         d="M-1.68526 0H-6.6V5.5L34.6586 33L39.6 33V27.5L-1.68526 0Z"
  //         fill="white"
  //       />
  //       <path
  //         d="M-4.96078 0L39.6 29.778V33H37.9992L-6.6 3.19123V0H-4.96078Z"
  //         fill="#F93939"
  //       />
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M35.2 0H39.6V5.5C39.6 5.5 11.0216 23.8217 -2.2 33H-6.6V27.5L35.2 0Z"
  //         fill="white"
  //       />
  //       <path
  //         d="M39.6 0H38.1079L-6.6 29.8036V33H-4.96078L39.6 3.21533V0Z"
  //         fill="#F93939"
  //       />
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M10.2016 0H22.8369V10.179H39.6V22.8143H22.8369V33H10.2016V22.8143H-6.6V10.179H10.2016V0Z"
  //         fill="white"
  //       />
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M12.8526 0H20.1474V12.6923H39.6V20.3077H20.1474V33H12.8526V20.3077H-6.6V12.6923H12.8526V0Z"
  //         fill="#F93939"
  //       />
  //     </g>
  //     <defs>
  //       <clipPath id="clip0_18332_24110">
  //         <rect width="33" height="33" rx="16.5" fill="white" />
  //       </clipPath>
  //     </defs>
  //   </svg>
  // );

  const getSymbolTotalAmount = (param) => {
    const obj = all_global_account?.all_support_currencies?.find(
      (chi) => chi?.currency === param
    );
    const symbol = obj?.symbol;
    // setTotalSymbol(symbol);
    // console.log();
    return symbol;
    // return (
    //   <><span>{symbol}</span></>
    // )
  };

  return (
    <div
      // onClick={() => {
      //   console.log(reciepientVal);
      // }}
      className="new-preview-detail-box-wrap card-bg border-theme"
    >
      {/* img top box start */}
      <div className="img-top-box-wrap img-top-box-wrap-global-account-swap border-theme-bottom">
        {notSwap ? (
          <div className="img-wrap">
            <figure className="img-box">
              {" "}
              <img
                src={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                  actionCtx?.globalCurrency?.currency
                )}.png`}
                className="img"
                alt=""
              />
            </figure>
            <div className="arrow-wrap grey-bg">
              <div className="arrow-box">
                <FaArrowRight className="icon" />
              </div>
            </div>
          </div>
        ) : (
          <div className="img-wrap">
            <figure className="img-box">
              <img
                src={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                  details?.sendCurrency?.currency
                )}.png`}
                alt=""
                className="img"
              />
            </figure>
            <div className="swap-global-icon-box grey-bg">
              <figure className="img-box black-white-bg">{swapIcon}</figure>
            </div>
            <figure className="img-box">
              {" "}
              <img
                src={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                  details?.receiveCurreny?.currency
                )}.png`}
                alt=""
                className="img"
              />
            </figure>
            {/* <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div> */}
          </div>
        )}

        {/* amount date box styart */}
        <div className="amount-date-box">
          <p className="amount">
            {!notSwap ? (
              <span
                // className="grey-white-color"
                dangerouslySetInnerHTML={{
                  __html: `${getSymbolTotalAmount(
                    details?.sendCurrency?.currency
                  )}`,
                }}
              />
            ) : (
              <span
                // className="grey-white-color"
                dangerouslySetInnerHTML={{
                  __html: `${getSymbolTotalAmount(
                    actionCtx?.globalCurrency?.currency
                  )}`,
                }}
              />
            )}
            {list?.length > 0 ? bulkAmount : amount || ""}
          </p>
          <p className="date grey-white-color-white">
            {valueSchedule
              ? formatDate(valueSchedule)
              : formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div
        className="middle-content-wrap"
        style={{ marginBottom: "0", paddingBottom: "0" }}
        onClick={() => {
          // console.log(reciepientVal);
          // console.log(formatMetaData(reciepientVal?.payload));
        }}
      >
        {/* not swap start */}
        {notSwap && (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Recipient Name:</p>
              <p className="value">{account_name || "---"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Bank Name:</p>
              <p className="value">{reciepientVal?.bank_name || "---"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            {formatMetaData(reciepientVal?.payload)?.swift_code && (
              <div className="box">
                <p className="label grey-white-color-white">Swift code:</p>
                <p className="value">
                  {formatMetaData(reciepientVal?.payload)?.swift_code || "---"}
                </p>
              </div>
            )}
            {/* box end */}
            {/* box start */}
            {formatMetaData(reciepientVal?.payload)?.routing_number && (
              <div className="box">
                <p className="label grey-white-color-white">Routing Number:</p>
                <p className="value">
                  {formatMetaData(reciepientVal?.payload)?.routing_number ||
                    "---"}
                </p>
              </div>
            )}
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Amount converted:</p>
              <p className="value">
                {" "}
                <span
                  // className="grey-white-color"
                  dangerouslySetInnerHTML={{
                    __html: `${getSymbolTotalAmount(convertedCurrency)}`,
                  }}
                />
                {convertedAmount}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Exchange rate:</p>
              <div className="value">{exChangeRate}</div>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Delivery method:</p>
              <div className="value">
                {capitalizeFirstLetter(
                  formatTypeFunction(reciepientVal?.delivery_method)
                ) ||
                  capitalizeFirstLetter(
                    formatTypeFunction(details?.delivery_method)
                  ) ||
                  ""}
              </div>
            </div>
            {/* box end */}
            {/* {formatMetaData(reciepientVal?.payload)?.narration} */}
            <form
              style={{ width: "100%", marginTop: "2rem" }}
              autoComplete="off"
              action=""
              className="form"
            >
              <RavenInputField
                label="narration*"
                color="black-light"
                name="narration"
                placeholder="Enter narration"
                value={details?.narration}
                onChange={(e) => {
                  handleChange && handleChange(e);
                }}
              />
            </form>
          </>
        )}
        {/* not swap end */}
        {!notSwap && (
          <>
            {" "}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Amount converted:</p>
              <p className="value">
                {" "}
                <span
                  // className="grey-white-color"
                  dangerouslySetInnerHTML={{
                    __html: `${getSymbolTotalAmount(
                      details?.receiveCurreny?.currency
                    )}`,
                  }}
                />
                {formatNumWithCommaNaira(details?.convertedAmount)}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Fee:</p>
              <p className="value">{account_name || "$5"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Exchange rate:</p>
              <div className="value">
                {Object?.keys(details?.receiveCurreny)?.length > 0 &&
                details?.sendAmount &&
                Object?.keys(details?.sendCurrency)?.length > 0 ? (
                  <span className="value">{`1 ${
                    actionCtx?.globalCurrency?.currency || ""
                  } = ${
                    Number?.isNaN(details?.savedRate)
                      ? ""
                      : details?.savedRate || ""
                  } ${details?.receiveCurreny?.currency || ""}`}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* box end */}
          </>
        )}
      </div>
      {/* middle content wrap end */}

      {/* source acount change box */}
      {/* <ChangeSourceAccount /> */}
      {/* source account change box end */}
    </div>
  );
};

export default NewPreviewDetailBox;
