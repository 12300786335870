import React, { useState } from "react";
import "../../styles/fragments/SelectCurrencyFragment.css";
import usdIcon from "../../assets/usdIcon 2.svg";
import ngnIcon from "../../assets/ngnIcon.svg";
import rwfIcon from "../../assets/rwfIcon.svg";
import eurIcon from "../../assets/eurIcon.svg";
import kesIcon from "../../assets/kesicon.svg";
import gbpIcon from "../../assets/gpbIcon.svg";
import { useOnClickOutside } from "../../utils/helper/UseOnClickOutside";

const SelectCurrencyFragment = ({ onSelect, className, disableCur }) => {
  const [selectedObj, setSelectedObj] = useState({ name: "NGN", img: ngnIcon });
  const [showDrop, setShowDropDown] = useState(false);

  const currencyList = [
    { name: "USD", img: usdIcon },
    { name: "NGN", img: ngnIcon },
    { name: "GBP", img: gbpIcon },
    { name: "EUR", img: eurIcon },
    { name: "KES", img: kesIcon },
    { name: "RWF", img: rwfIcon },
  ];
  const arrowDownIcon = (
    <svg
      className="img"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 9L12.5 15L18.5 9"
        stroke="#333333"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const testingCurrencyDropRef = useOnClickOutside(() => {
    setShowDropDown(false);
  });

  return (
    <div
      ref={testingCurrencyDropRef}
      className={`select-currency-fragment-index-wrap ${className}`}
    >
      <div
        onClick={() => {
          !disableCur && setShowDropDown(!showDrop);
        }}
        className="selected-obj-wrap"
      >
        <div className="cur-img">
          {" "}
          <figure className="img-box">
            <img src={selectedObj?.img} alt="" className="img" />
          </figure>
        </div>

        <p className="selected-value">{selectedObj?.name || " "}</p>
        <div className="down-icon">
          <figure className="img-box">{arrowDownIcon}</figure>
        </div>
      </div>
      {/* select list start */}
      {showDrop && (
        <div className="select-list card-bg">
          {currencyList?.map((chi, idx) => {
            return (
              <div
                key={idx}
                onClick={() => {
                  if (!disableCur) {
                    selectedObj?.name !== chi?.name && setSelectedObj(chi);
                    setShowDropDown(false);
                    onSelect && onSelect(chi);
                  }
                }}
                className={`cur-item ${
                  selectedObj?.name === chi?.name && "cur-item-selected"
                }`}
              >
                {" "}
                <div className="cur-img">
                  {" "}
                  <figure className="img-box">
                    <img src={chi?.img} alt="" className="img" />
                  </figure>
                </div>
                <p className="select-value">{chi?.name || " "}</p>
              </div>
            );
          })}
        </div>
      )}
      {/* select list  end */}
    </div>
  );
};

export default SelectCurrencyFragment;
