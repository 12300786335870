import React from "react";
import VerificationIndexOneFive from "../VerificationIndexOneFive";
import ModalLayout from "./ModalLayout";

const TierThreeIndex = ({ children , stage}) => {
  return (
    <>
      <VerificationIndexOneFive />
      <ModalLayout stage={stage}>{children}</ModalLayout>
    </>
  );
};

export default TierThreeIndex;
