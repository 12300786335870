import React from 'react';

const ProgressBar = ({ bgcolor, completed, baseColor , height,  borderRadius, className, onClick}) => {

    const containerStyles = {
      height: height || 3.5,
      width: "100%",
      alignSelf: "center",
      backgroundColor: baseColor ? baseColor : "rgba(238, 238, 238, 1)",
    //   borderRadius: 50,
      position: "relative",
      margin: "-.2rem 0rem 0rem 0rem",
      borderRadius:  borderRadius,
    };
  
    const fillerStyles = {
      height: "100%",
      width: `${completed > 100 ? 100 : completed}%`,
      backgroundColor: bgcolor,
      borderRadius: "inherit",
      textAlign: "right",
      transition: "1s ease 0.3s",
      };
  
    // const iconStyles = {
    //   height: "6rem",
    //   width: "6rem",
    //   marginLeft: "-6rem",   
    //   position: "absolute",
    //   bottom: "-300%",
    //   left: `${completed}%`,
    //   color: completed <= 0 ? "#CCCCCC" : bgcolor,    
    // }
  
    const labelStyles = {
      padding: 7,
      color: "white",
      fontWeight: "bold",
    };
  
    return (
      <div onClick={() => {
        onClick && onClick()
      }} className={`progress-bar-wrap-reuseable ${className}`} style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}></span>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;