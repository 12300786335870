import CreateNewPassword from "../../pages/auth/CreateNewPassword";
import EnterTokenForgetPassword from "../../pages/auth/EnterTokenForgetPassword";
import ForgetPassword from "../../pages/auth/ForgetPassword";
import Login from "../../pages/auth/Login";
import RegisterInvite from "../../pages/auth/RegisterInvite";
// import SignUp from "../../pages/auth/SignUp";
import SignUpOneFive from "../../pages/auth/SignUpVersionOneFive";


export const auth_routes_group = [
    {path: "/login", element: <Login />},
    // {path: "/sign-up", element: <SignUp />},
    {path: "/sign-up", element: <SignUpOneFive />},
    {path: "/forget-password", element: <ForgetPassword />},
    {path: "/forget-password-token", element: <EnterTokenForgetPassword />},
    {path: "/create-password", element: <CreateNewPassword />},
    {path: "/sign-up-invite", element: <RegisterInvite />}
]