import DashboardSettingsLayout from "./SettingsLayout";

import React, { useContext, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../context/ActionContext";
import {
  updateAppearance,
  updateAppearanceTwo,
} from "../../../../redux/settings";
import { getUser } from "../../../../redux/user";
import {
  formatMetaData,
  //   reactSelectStyle,
} from "../../../../utils/helper/Helper";
import { quickActionAllList } from "../../../../utils/helper/QuickActionList";
import { ThreeDots } from "react-loader-spinner";
import { useDarkMode } from "../../../../utils/themes/useDarkMode";
import { toast } from "@ravenpay/raven-bank-ui";

const DashboardSettingsAppearance = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.settings);
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const [appearanceObj, setAppearanceObj] = useState({});
  const [checkList, setCheckList] = useState([]);
  const [tableFont, setTableFont] = useState("");
  const [overviewStyle, setOverviewStyle] = useState("");

  const tableFontOptions = [
    { label: "Bold", value: "bold" },
    { label: "Light", value: "light" },
  ];

  const overviewHeroOptions = [
    { label: "Dark", value: "dark" },
    { label: "Purple", value: "purple" },
  ];

  const tableSizeOptions = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "250", value: 250 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];

  useEffect(() => {
    setAppearanceObj(formatMetaData(user?.theme_display_settings));
    setCheckList(formatMetaData(user?.theme_display_settings)?.quick_action);
    setTableFont(formatMetaData(user?.theme_display_settings)?.table_font);
    setOverviewStyle(
      formatMetaData(user?.theme_display_settings)?.dashboard_overview
    );
  }, [user]);

  const AddToNewList = (param) => {
    // if (
    //   checkList?.length < 4 &&
    //   checkList?.includes(param.toLocaleLowerCase())
    // ) {
    //   toast.error("Selected action already exist in quick actions", {});
    //   return;
    // }
    if (checkExist(param)) {
      const newFilterList = checkList?.filter(
        (chi) => chi.toLocaleLowerCase() !== param.toLocaleLowerCase()
      );
      setCheckList(newFilterList);
      return;
    } else {
      if (
        checkList?.length >= 4 &&
        !checkList?.includes(param.toLocaleLowerCase())
      ) {
        toast.error(
          "Maximum number of selected action reached, unselect from selected to select a new action",
          {}
        );
        return;
      }
      if (
        checkList?.length < 4 &&
        !checkList?.includes(param.toLocaleLowerCase())
      ) {
        const newList = [...checkList, param];
        setCheckList(newList);
        return;
      }
    }
  };

  const checkExist = (param) => {
    const val = checkList?.some(
      (chi) => chi.toLocaleLowerCase() === param.toLocaleLowerCase()
    );
    return val;
  };

  const actionCtx = useContext(ActionContext);

  const handleSideNavUpdate = async (obj) => {
    const data = await dispatch(updateAppearance(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      localStorage?.setItem("sidebar-theme-style", obj?.side_nav_style);
      dispatch(getUser());
    }
  };

  const handleSideNavUpdateTwo = async (obj) => {
    const data = await dispatch(updateAppearanceTwo(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      localStorage?.setItem("sidebar-theme-style", obj?.side_nav_style);
      dispatch(getUser());
    }
  };

  return (
    <>
      <DashboardSettingsLayout>
        <div className="detail-wrap animate-move-up-class">
          <p
            onClick={() => {
              // const newArray = [...appearanceObj?.quick_action, "test"];
              // const newObj = { ...appearanceObj, quick_action: newArray };
              // console.log(newObj);
            }}
            className="title"
          >
            Appearance Preferences
          </p>
          <p className="sub-title grey-white-color">
            Customise the look and feel of your application.
          </p>
          <div className="box-wrap  box-wrap-remove-padding grey-bg">
            {/* theme preference start */}
            <div className="theme-preference-wrap">
              <p className="content-title ">Theme Preference.</p>
              {/* select wrapper start */}
              <div className="select-wrapper">
                {/* box start */}
                <div
                  onClick={() => {
                    theme === "dark" && toggleTheme("light");
                    actionCtx.setToggleTheme();
                    const newObj = { ...appearanceObj, theme: "light" };
                    handleSideNavUpdate(newObj);
                  }}
                  className={`box`}
                >
                  <div
                    className={`img-wrap select-option-class  ${
                      theme === "light" && "select-option-class-active"
                    }`}
                  >
                    {" "}
                    <div className="check-box">
                      <FaCheck className="icon" />
                    </div>
                  </div>
                  <p className="text">Light</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div
                  onClick={() => {
                    theme === "light" && toggleTheme("dark");
                    actionCtx.setToggleTheme();
                    const newObj = { ...appearanceObj, theme: "dark" };
                    handleSideNavUpdate(newObj);
                  }}
                  className={`box`}
                >
                  <div
                    className={`img-wrap select-option-class dark-img ${
                      theme === "dark" && "select-option-class-active"
                    }`}
                  >
                    {" "}
                    <div className="check-box">
                      <FaCheck className="icon" />
                    </div>
                  </div>
                  <p className="text">Dark</p>
                </div>
                {/* box end */}
              </div>
              {/* select wrapper end */}
            </div>
            {/* theme preference end */}
            {/* theme preference start */}
            <div className="theme-preference-wrap">
              <p className="content-title ">Side Nav Style</p>
              {/* select wrapper start */}
              <div className="select-wrapper">
                {/* box start */}
                <div
                  onClick={() => {
                    // theme === "light" && toggleTheme("dark");
                    actionCtx?.setSideBarNav("solid");
                    const newObj = {
                      ...appearanceObj,
                      side_nav_style: "solid",
                    };
                    setAppearanceObj(newObj);
                    handleSideNavUpdate(newObj);
                  }}
                  className={`box`}
                >
                  <div
                    className={`img-wrap solid select-option-class ${
                      appearanceObj?.side_nav_style === "solid" &&
                      "select-option-class-active"
                    }`}
                  >
                    {" "}
                    <div className="check-box">
                      <FaCheck className="icon" />
                    </div>
                  </div>
                  <p className="text">Solid</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div
                  onClick={() => {
                    // theme === "dark" && toggleTheme("light");
                    actionCtx?.setSideBarNav("minimal");
                    const newObj = {
                      ...appearanceObj,
                      side_nav_style: "minimal",
                    };
                    setAppearanceObj(newObj);
                    handleSideNavUpdate(newObj);
                  }}
                  className={`box`}
                >
                  <div
                    className={`img-wrap minimal select-option-class ${
                      appearanceObj?.side_nav_style === "minimal" &&
                      "select-option-class-active"
                    } `}
                  >
                    {" "}
                    <div className="check-box">
                      <FaCheck className="icon" />
                    </div>
                  </div>
                  <p className="text">Minimal</p>
                </div>
                {/* box end */}
              </div>
              {/* select wrapper end */}
            </div>
            {/* theme preference end */}
            {/* theme preference start */}
            <div className="theme-preference-wrap">
              <div className="btn-text-box">
                <div className="text-box">
                  {" "}
                  <p className="content-title ">Quick Menu</p>
                  <p className="content-text grey-white-color-white">
                    Customise what you see on your home section
                  </p>
                </div>
                {loading ? (
                  <div
                    style={{
                      padding: "0rem",
                      display: "flex",
                      justifyContent: "flex-end",
                      transform: "translateX(1.5rem)",
                    }}
                    className="load-wrap"
                  >
                    <ThreeDots
                      height="10"
                      width="80"
                      radius="9"
                      // color="#020202"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <>
                    {checkList?.length >= 4 && (
                      <p
                        onClick={() => {
                          const newObj = {
                            ...appearanceObj,
                            quick_action: checkList,
                          };
                          handleSideNavUpdateTwo(newObj);
                        }}
                        className="update"
                      >
                        Update
                      </p>
                    )}
                  </>
                )}
              </div>
              {/* select wrapper start */}
              <div className="select-quick-action-wrap">
                {quickActionAllList.map((chi, idx) => {
                  const { name } = chi;
                  return (
                    <div
                      key={idx}
                      className={`child card-bg border-theme ${
                        checkExist(name) && "child-active"
                      }`}
                      onClick={() => {
                        AddToNewList(name);
                      }}
                    >
                      <span className="grey-white-color">{name}</span>
                      {checkExist(name) && (
                        <div className="check-box">
                          <FaCheck className="icon" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* select wrapper end */}
            </div>
            {/* theme preference end */}
            {/* theme preference start */}
            <div className="theme-preference-wrap">
              <div className="btn-text-box">
                <div className="text-box">
                  {" "}
                  <p className="content-title ">Overview Hero Style</p>
                  <p className="content-text grey-white-color-white">
                    Customise the dashboard overview hero style
                  </p>
                </div>
              </div>

              {/* select wrapper start */}
              <div
                className="select-wrapper table-select-font-wrap "
                style={{ alignSelf: "flex-start", minWidth: "27rem" }}
              >
                {overviewHeroOptions?.map((chi, idx) => {
                  const { label, value } = chi;
                  return (
                    <div
                      key={idx}
                      className={`child card-bg border-theme ${
                        overviewStyle === value && "child-active"
                      }`}
                      onClick={() => {
                        const newObj = {
                          ...appearanceObj,
                          dashboard_overview: value,
                        };
                        // console.log(newObj);
                        // setAppearanceObj(newObj);
                        handleSideNavUpdate(newObj);
                        setOverviewStyle(value);
                      }}
                    >
                      <span className="grey-white-color">{label}</span>
                      {overviewStyle === value && (
                        <div className="check-box">
                          <FaCheck className="icon" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* select wrapper end */}
            </div>
            {/* theme preference end */}
            {/* theme preference start */}
            <div className="theme-preference-wrap">
              <div className="btn-text-box">
                <div className="text-box">
                  {" "}
                  <p className="content-title ">Table Font Weight</p>
                  <p className="content-text grey-white-color-white">
                    Customise the font weight of text on all tables
                  </p>
                </div>
              </div>

              {/* select wrapper start */}
              <div
                className="select-wrapper table-select-font-wrap "
                style={{ alignSelf: "flex-start", minWidth: "27rem" }}
              >
                {tableFontOptions?.map((chi, idx) => {
                  const { label, value } = chi;
                  return (
                    <div
                      key={idx}
                      className={`child card-bg border-theme ${
                        tableFont === value && "child-active"
                      }`}
                      onClick={() => {
                        const newObj = { ...appearanceObj, table_font: value };
                        setAppearanceObj(newObj);
                        handleSideNavUpdate(newObj);
                        setTableFont(value);
                      }}
                    >
                      <span className="grey-white-color">{label}</span>
                      {tableFont === value && (
                        <div className="check-box">
                          <FaCheck className="icon" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* select wrapper end */}
            </div>
            {/* theme preference end */}
            {/* theme preference start */}
            <div className="theme-preference-wrap">
              <div className="btn-text-box">
                <div className="text-box">
                  {" "}
                  <p className="content-title ">Table Page Size</p>
                  <p className="content-text grey-white-color-white">
                    Customise the table size per page on all tables
                  </p>
                </div>
              </div>

              {/* select wrapper start */}
              <div
                className="select-wrapper table-select-font-wrap "
                style={{ alignSelf: "flex-start", minWidth: "27rem" }}
              >
                {tableSizeOptions?.map((chi, idx) => {
                  const { label, value } = chi;
                  return (
                    <div
                      key={idx}
                      className={`child card-bg border-theme ${
                        actionCtx?.perPageVal?.value === value && "child-active"
                      } ${
                        appearanceObj?.table_size === value && "child-active"
                      }`}
                      onClick={() => {
                        const newObj = { ...appearanceObj, table_size: value };
                        setAppearanceObj(newObj);
                        handleSideNavUpdate(newObj);
                        actionCtx?.setPerPage({
                          label: label,
                          value: value,
                        });
                        // setTableFont(value);
                      }}
                    >
                      <span className="grey-white-color">{label}</span>
                      {actionCtx?.perPageVal?.value === value ||
                      appearanceObj?.table_size === value ? (
                        <div className="check-box">
                          <FaCheck className="icon" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </div>
              {/* select wrapper end */}
            </div>
            {/* theme preference end */}
          </div>
        </div>
      </DashboardSettingsLayout>
    </>
  );
};

export default DashboardSettingsAppearance;