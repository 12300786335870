import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import {
  deactivateUserPayrollAll,
  // getPayrollSetup,
} from "../../../../redux/payroll";

const DeactivatePayroll = ({ visible, onCancel, onFinish }) => {
  const [testLoading, handleTestLoading] = useState(false);
  const {  payrollSettings } = useSelector(
    (state) => state.payroll
  );
  const dispatch = useDispatch();

  const handleDelete = async () => {
    handleTestLoading(true);
    const obj = {
      payroll_status: !payrollSettings ? "0" : payrollSettings?.payroll_status === "1" ? "0" : "1",
      id: payrollSettings?.id,
    };
    // console.log(obj);
    // return
    const data = await dispatch(deactivateUserPayrollAll(obj));
    if (data?.payload?.data?.status === "success") {
      // dispatch(getPayrollSetup());
      handleTestLoading(false);
      onFinish();
    }
  };
  return (
    <DeleteModal
      loading={testLoading}
      visible={visible}
      buttonColor={
        payrollSettings?.payroll_status === "1" ? `error-light` : !payrollSettings ?  `error-light` : `black-light`
      }
      deleteText={
        payrollSettings?.payroll_status === "1" ? "Deactivate"  : !payrollSettings ? "Deactivate" : "Activate"
      }
      onCancel={onCancel}
      onDelete={handleDelete}
      title={
        payrollSettings?.payroll_status === "1"
          ? "Deactivate Payroll" : !payrollSettings ? "Deactivate Payroll"
          : "Activate Payroll"
      }
      text={
        payrollSettings?.payroll_status === "1"
          ? `Pause your payroll for a certain time, this mean you won’t be able to perform payroll transactions.` : !payrollSettings ? `Pause your payroll for a certain time, this mean you won’t be able to perform payroll transactions.`
          : "Activate your payroll for the main time until it get deactivated, this mean you will be able to perform payroll transactions."
      }
    />
  );
};

export default DeactivatePayroll;
