import {
  RavenButton,
  RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import ChangeSourceAccount from "../../../../components/reusables/ChangeSourceAccount";
import ActionContext from "../../../../context/ActionContext";
import { getVirtualCards, withdrawFromCard } from "../../../../redux/card";
import { getAllAccounts } from "../../../../redux/user";
import "../../../../styles/dashboard/card/Modal.css";
import {
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  lowerCaseWrap,
  reactSelectStyle,
} from "../../../../utils/helper/Helper";
// import { formatNumWithoutCommaNaira } from "../../../../utils/Helpers";

const WithdrawCardModal = ({
  visible,
  onCancel,
  onWithdraw,
  // loadingBvn,
  value,
  onFinish,
}) => {
  const [details, setDetails] = useState({
    amount: "",
  });
  // const { card, config } = useSelector((state) => state.cards);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [currentAccountVal, setCurrentAccountVal] = useState(
    actionCtx?.currentAccount
  );
  const { all_account } = useSelector((state) => state.user);
  const { loadingBvn, card, config } = useSelector((state) => state.cards);
  // const { all_account } = useSelector((state) => state.user);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async () => {
    const obj = {
      card_id: String(card?.id),
      amount: formatNumWithoutCommaNaira(details?.amount),
      selected_account_number: currentAccountVal?.account_number,
    };
    // console.log(obj);
    // return
    const data = await dispatch(withdrawFromCard(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
      setDetails({
        amount: "",
      });
      dispatch(getVirtualCards());
      dispatch(getAllAccounts());
    }
  };

  useEffect(() => {
    setCurrentAccountVal(actionCtx?.currentAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setDetails({
      amount: "",
    });
    onCancel();
  };

  const showLabelFormat = (name, acc_num, amount, showBorder) => {
    return (
      <div
        style={{
          cursor: "pointer",
          //   paddingBottom: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: ".3rem",
        }}
        className={`name-account-box`}
      >
        <p
          style={{ fontWeight: 600, fontSize: "1.5rem", color: "#020202" }}
          className="name"
        >
          {name}
        </p>
        <div
          style={{ display: "flex", alignItems: "center", gap: ".6rem" }}
          className="wrap-box"
        >
          <p className="account_num grey-white-color">{acc_num}</p> <p>•</p>
          <p className="account_num grey-white-color">
            {formatNumWithCommaNairaSymbol(amount)}
          </p>
        </div>
      </div>
    );
  };

  const formatOptionOne = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const newList = list?.map((chi, idx) => {
        return {
          ...chi,
          label: showLabelFormat(
            lowerCaseWrap(chi?.custom_account_name),
            chi?.account_number,
            chi?.balance,
            idx !== list?.length - 1 ? true : false
          ),
          value: chi?.account_number,
        };
      });
      return newList;
    }
  };

  const formatObjValue = (param) => {
    return { ...param, label: param?.custom_account_name };
  };

  return (
    <RavenModal
      className={`card-top-up-modal-reuse-wrap`}
      visble={visible}
      effect={`fadeInRight`}
      onClose={handleCancel}
    >
      <div
        style={{ minHeight: "30rem", paddingBottom: "0rem" }}
        className="container-wrap"
      >
        <p className="title-card">Withdraw from this card</p>
        <p className="text grey-white-color pin-test">
          Any amount withdrawn from this card would be added to your Account,
          and it’s super fast and easy, the current rate is{" "}
          <span>{formatNumWithCommaNairaSymbol(config?.usd_rate)} - $1</span> .
        </p>
        <form
          style={{ width: "100%", paddingBottom: "10rem" }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          autoComplete="off"
          action=""
        >
          {/* <RavenInputField
            id="top-up-card-id"
            type={`number`}
            color={`black-light`}
            label={`Amount* `}
            value={details?.amount}
            name={`amount`}
            thousandFormat
            onChange={handleChange}
            placeholder={`Amount to withdraw`}
            labelColor={`black-light`}
            labelSpanText={
              actionCtx?.showAmount
                ? `Bal: ${
                    card?.currency === "USD"
                      ? formatNumWithCommaNairaSymbol(card?.balance)?.replace(
                          "₦",
                          "$"
                        )
                      : formatNumWithCommaNairaSymbol(card?.balance) || "---"
                  }`
                : "Bal: ₦ ••••••"
            }
            labelClassName={`label-span-amount label-span-theme`}
            numberPrefix={card?.currency === "USD" ? "$" : `₦`}
            onActionClick={() => {
              actionCtx?.setShowAmount(!actionCtx?.showAmount);
            }}
          /> */}
          {/* source acount change box */}
          {/* <ChangeSourceAccount
            onObjChange={(e) => {
              setCurrentAccountVal(e);
              // console.log(e);
            }}
            darkType={true}
            // onChange={() => onClose()}
            title={`Card Settlement Account.`}
            defaultObj={currentAccountVal}
          /> */}
          {/* source account change box end */}

          <form
            style={{ width: "100%", minHeight: "13rem" }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="off"
            action=""
          >
            <RavenInputField
              id="top-up-card-id"
              type={`number`}
              color={`black-light`}
              label={`Amount* `}
              value={details?.amount}
              name={`amount`}
              thousandFormat
              onChange={handleChange}
              placeholder={`Amount to fund into the card`}
              labelColor={`black-light`}
              labelSpanText={
                actionCtx?.showAmount
                  ? `Bal: ${
                      card?.currency === "USD"
                        ? formatNumWithCommaNairaSymbol(card?.balance)?.replace(
                            "₦",
                            "$"
                          )
                        : formatNumWithCommaNairaSymbol(card?.balance) || "---"
                    }`
                  : "Bal: ₦ ••••••"
              }
              labelClassName={`label-span-amount label-span-theme`}
              numberPrefix={card?.currency === "USD" ? "$" : `₦`}
              onActionClick={() => {
                actionCtx?.setShowAmount(!actionCtx?.showAmount);
              }}
            />
            {details?.amount && (
              <p
                style={{ marginTop: "1rem" }}
                className="text grey-white-color pin-test"
              >
                Amount in naira:{" "}
                <span>
                  {formatNumWithCommaNairaSymbol(
                    Number(formatNumWithoutCommaNaira(details?.amount)) *
                      config?.usd_rate
                  )}
                </span>
              </p>
            )}
            {/* form group star */}
            <form
              style={{
                width: "100%",
                position: "relative",
                zIndex: 20,
                marginTop: "2rem",
              }}
              action=""
              autoComplete="off"
            >
              <RavenInputField
              menuPlacement={`top`}
                selectStyles={reactSelectStyle}
                type="select"
                label="Receiver account* "
                placeholder="e.g main account"
                color="black-light"
                selectOption={formatOptionOne(all_account)}
                onChange={(e) => {
                  setCurrentAccountVal(e);
                }}
                value={formatObjValue(currentAccountVal)}
              />
            </form>
            {/* form group end */}
            {/* source acount change box */}
            {/* <ChangeSourceAccount
            onObjChange={(e) => {
              setCurrentAccountVal(e);
              // console.log(e);
            }}
            darkType={true}
            // onChange={() => onClose()}
            title={`Card Settlement Account.`}
            defaultObj={currentAccountVal}
          /> */}
            {/* source account change box end */}
          </form>
        </form>
      </div>
      <div className="two-button-wrap">
        <p onClick={handleCancel} className="cancel grey-white-color">
          Cancel
        </p>
        <RavenButton
          color={`black-light`}
          label="Process withdrawal"
          loading={loadingBvn}
          size={`small`}
          // onClick={onWithdraw}
          disabled={!details?.amount}
          // disabled={!details?.amount}
          onClick={handleSubmit}
          // onClose={onCancel}
        />
      </div>
    </RavenModal>
  );
};

export default WithdrawCardModal;
