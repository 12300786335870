import React, { useState, useEffect } from "react";

const checkImageURL = (url) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      resolve(true);
    };

    img.onerror = () => {
      resolve(false);
    };

    img.src = url;
  });
};

const CheckUrlFragment = ({ url, children, className }) => {
  const [imageValid, setImageValid] = useState(null);

  useEffect(() => {
    const imageUrl = url;

    checkImageURL(imageUrl).then((isValid) => {
      setImageValid(isValid);
    });
  }, [url]);

  if (imageValid === null) {
    return " ";
  }

  return (
    <div>
      {imageValid ? (
        <figure className={`img-box ${className && className}`}>
          {" "}
          <img src={url} alt="" className="img" />
        </figure>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default CheckUrlFragment;
