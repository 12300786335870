
import EditInvoicePage from '../../../pages/dashboard/invoice/EditInvoicePage';
import Invoice from '../../../pages/dashboard/invoice/index';
import NewInvoice from '../../../pages/dashboard/invoice/NewInvoice';
import PreviewInvoice from '../../../pages/dashboard/invoice/PreviewInvoice';



export const invoice_route_group = [
    {path: "/dashboard-invoice", element: <Invoice />},
    {path: "/dashboard-invoice-new", element: <NewInvoice />},
    {path: "/dashboard-invoice-edit", element: <EditInvoicePage />},
    {path: "/dashboard-invoice-preview", element: <PreviewInvoice />},
    
]