import React from "react";
// import ActionContext from "../../context/ActionContext";
import newImgLoader from "../../assets/raven-loader.gif";
import "../../styles/fragments/BlackoutLogoModal.css"

const BlackoutLogoModal = () => {
  return (
    <div className="blackout-loading-logo-modal">
      <div className="wrap">
        <figure className="img-box">
          <img src={newImgLoader} alt="" className="img" />
        </figure>
      </div>
    </div>
  );
};

export default BlackoutLogoModal;
