import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/bill-payment/BillPaymentIndex.css";
import { FaAngleRight } from "react-icons/fa";
import ActionContext from "../../../context/ActionContext";
import AccountDropDownBox from "../../../components/fragments/AccountDropDownBox";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import { useSelector } from "react-redux";
import NoContentBox from "../../../components/reusables/NoContentBox";
// import NoContentBox from "../../../components/reusables/NoContentBox";

const DashboardBillPaymentIndex = () => {
  const [urlBack, setUrlBack] = useState("");
  const navigate = useNavigate();
  const {all_account } = useSelector((state) => state.user)
  const actionCtx = useContext(ActionContext);

  const airtimeIcon = (
    <svg
      className="img"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3419 4.75686C14.3419 4.21637 14.0489 3.83092 13.5403 3.69239C13.4786 3.67699 13.4154 3.6685 13.3519 3.66707H6.75417C6.50824 3.66411 6.26868 3.74709 6.075 3.90232C5.76944 4.15493 5.68222 4.53042 5.68361 4.95314C5.69528 9.28668 5.68972 13.6208 5.68972 17.9532C5.68972 18.6644 6.11083 19.0939 6.80889 19.0945H13.2089C13.9269 19.0945 14.3425 18.6706 14.3425 17.9381V7.55912C14.3425 6.62494 14.3423 5.69085 14.3419 4.75686ZM10.7442 5.2029C10.4989 5.2046 10.2536 5.2029 10.0083 5.2029C9.76833 5.2029 9.52778 5.2029 9.2875 5.2029C9.11361 5.2029 9.0125 5.10902 9.01444 4.95911C9.01667 4.81688 9.11555 4.72272 9.28055 4.72243C9.77111 4.71997 10.2619 4.71997 10.7528 4.72243C10.9175 4.72243 11.0169 4.81602 11.0189 4.95939C11.0197 5.10845 10.9175 5.20176 10.7442 5.2029Z"
        fill="#020202"
      />
      <path
        d="M11.8711 5.153C11.8747 5.4295 11.6855 5.6016 11.3644 5.60387C10.9105 5.607 10.4563 5.60387 10.0033 5.60387C9.55884 5.60387 9.11439 5.60615 8.66995 5.60387C8.34745 5.6016 8.1605 5.43035 8.16411 5.15243C8.16856 4.88702 8.35106 4.71492 8.65689 4.71407C9.5643 4.70989 10.4717 4.70989 11.3791 4.71407C11.6833 4.71492 11.8672 4.88788 11.8711 5.153Z"
        fill="white"
      />
      <path
        d="M11.3094 17.093C11.3122 17.326 11.2472 17.5545 11.1228 17.7496C10.9983 17.9447 10.82 18.0974 10.6105 18.1884C10.4011 18.2794 10.1699 18.3046 9.94644 18.2607C9.72298 18.2168 9.51732 18.1058 9.35562 17.9419C9.19393 17.7779 9.0835 17.5684 9.03839 17.34C8.99328 17.1116 9.01553 16.8747 9.1023 16.6593C9.18907 16.4439 9.33644 16.2597 9.52568 16.1303C9.71491 16.001 9.93745 15.9321 10.165 15.9326C10.4663 15.9333 10.7553 16.0555 10.9694 16.2726C11.1836 16.4897 11.3057 16.7844 11.3094 17.093Z"
        fill="white"
      />
    </svg>
  );
  const dataIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.64"
        d="M7.45378 14.5552H6.26778C5.38854 14.5552 4.67578 15.2679 4.67578 16.1472V17.9575C4.67578 18.8367 5.38854 19.5495 6.26778 19.5495H7.45378C8.33302 19.5495 9.04578 18.8367 9.04578 17.9575V16.1472C9.04578 15.2679 8.33302 14.5552 7.45378 14.5552Z"
        fill="#CCCCCC"
      />
      <path
        d="M12.61 10.2832H11.424C10.5448 10.2832 9.83203 10.996 9.83203 11.8752V17.9572C9.83203 18.8364 10.5448 19.5492 11.424 19.5492H12.61C13.4893 19.5492 14.202 18.8364 14.202 17.9572V11.8752C14.202 10.996 13.4893 10.2832 12.61 10.2832Z"
        fill="#020202"
      />
      <path
        d="M17.7643 7.33984H16.5783C15.6991 7.33984 14.9863 8.05261 14.9863 8.93184V17.9568C14.9863 18.8361 15.6991 19.5488 16.5783 19.5488H17.7643C18.6436 19.5488 19.3563 18.8361 19.3563 17.9568V8.93184C19.3563 8.05261 18.6436 7.33984 17.7643 7.33984Z"
        fill="#020202"
      />
    </svg>
  );
  const electricityIcon = (
    <svg
      className="img"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3036 5.14014H17.6722L15.1962 12.1245C15.1647 12.2147 15.1551 12.3112 15.1684 12.4059C15.1817 12.5006 15.2174 12.5907 15.2726 12.6688C15.3278 12.7469 15.4009 12.8106 15.4857 12.8547C15.5705 12.8989 15.6646 12.9221 15.7602 12.9225H16.7069C17.2336 12.9225 17.5032 13.5538 17.1386 13.9341L10.0869 21.2951C9.66288 21.7375 8.92688 21.3321 9.07422 20.7375L10.7716 13.8851C10.7934 13.797 10.7948 13.7051 10.7758 13.6163C10.7568 13.5276 10.7179 13.4443 10.6619 13.3728C10.606 13.3014 10.5345 13.2436 10.4529 13.2038C10.3712 13.1641 10.2817 13.1434 10.1909 13.1435H8.85755C8.76538 13.1431 8.67453 13.1216 8.59204 13.0804C8.50956 13.0393 8.43765 12.9797 8.38191 12.9063C8.32617 12.8329 8.2881 12.7477 8.27063 12.6572C8.25317 12.5667 8.25679 12.4733 8.28122 12.3845L10.3036 5.14014Z"
        fill="#020202"
      />
    </svg>
  );
  const cableIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12395_102851)">
        <path
          d="M17.7359 17.1759H2.26382C1.02769 17.1759 0.0253906 15.9106 0.0253906 14.35V2.85815C0.0253906 1.29721 1.02769 0.0322266 2.26382 0.0322266H17.7359C18.9724 0.0322266 19.9744 1.29759 19.9744 2.85815V14.35C19.9744 15.9106 18.9721 17.1759 17.7359 17.1759Z"
          fill="#020202"
        />
        <path
          d="M15.5163 20H4.48495C4.12761 20 3.83789 19.6342 3.83789 19.1831C3.83789 18.732 4.12761 18.3662 4.48495 18.3662H15.5163C15.8736 18.3662 16.1634 18.732 16.1634 19.1831C16.1634 19.6342 15.8736 20 15.5163 20Z"
          fill="#CCCCCC"
        />
        <path
          d="M17.7361 17.2077H2.26397C1.01553 17.2077 0 15.9257 0 14.3496V2.85768C0 1.28195 1.01553 -0.000488281 2.26397 -0.000488281H17.7361C18.9845 -0.000488281 20.0001 1.28157 20.0001 2.85768V14.3496C20.0001 15.9253 18.9845 17.2077 17.7361 17.2077ZM2.26397 0.0636337C1.04378 0.0636337 0.0507913 1.31686 0.0507913 2.85768V14.3496C0.0507913 15.89 1.04348 17.1436 2.26397 17.1436H17.7361C18.9563 17.1436 19.9493 15.8904 19.9493 14.3496V2.85768C19.9493 1.31724 18.9566 0.0636337 17.7361 0.0636337H2.26397Z"
          fill="#9BE5C9"
        />
      </g>
      <defs>
        <clipPath id="clip0_12395_102851">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const betIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1338 8.33341C17.0621 11.4627 15.9903 14.5915 14.9185 17.7197C14.7562 18.1934 14.5968 18.6667 14.4318 19.1397C14.3638 19.3471 14.2337 19.5286 14.0593 19.6597C13.8848 19.7909 13.6743 19.8653 13.4562 19.8731C13.3295 19.8481 13.1978 19.8374 13.0772 19.7954C11.0056 19.0894 8.93561 18.3812 6.86717 17.6707C6.5005 17.5454 6.13383 17.4211 5.76983 17.2941C5.17383 17.0881 4.88817 16.5041 5.0935 15.9074C6.2935 12.4001 7.49495 8.89274 8.69783 5.38541C8.72917 5.29607 8.7585 5.20574 8.7895 5.11641C8.8343 4.98399 8.90487 4.86175 8.99715 4.75675C9.08944 4.65175 9.2016 4.56607 9.32718 4.50464C9.45275 4.44322 9.58924 4.40728 9.72878 4.39888C9.86832 4.39049 10.0081 4.40981 10.1402 4.45574C12.3662 5.21641 14.5915 5.97819 16.8162 6.74108C17.0342 6.81608 17.2522 6.88908 17.4685 6.96474C18.0458 7.16674 18.3315 7.75441 18.1338 8.33341Z"
        fill="#020202"
      />
      <path
        opacity="0.64"
        d="M20.0603 6.15611C19.8423 6.07444 19.6233 5.99711 19.405 5.91744C17.1694 5.10322 14.9343 4.28967 12.6996 3.47678C12.567 3.42766 12.426 3.40531 12.2847 3.41103C12.1434 3.41675 12.0047 3.45041 11.8765 3.51008C11.7483 3.56975 11.6332 3.65423 11.5378 3.75866C11.4425 3.86308 11.3688 3.98536 11.321 4.11844C11.2876 4.20844 11.2566 4.29911 11.2233 4.38878C9.94085 7.91189 8.65751 11.4348 7.37329 14.9574C7.15429 15.5574 7.43162 16.1521 8.03096 16.3731C8.39829 16.5078 8.76496 16.6418 9.13096 16.7751C11.2101 17.5318 13.2892 18.2873 15.3683 19.0418C15.4893 19.0858 15.6223 19.1001 15.7496 19.1278C15.9702 19.1236 16.1842 19.0521 16.363 18.9228C16.5417 18.7935 16.6766 18.6126 16.7496 18.4044C16.9263 17.9304 17.096 17.4538 17.2693 16.9791C18.4137 13.8358 19.5582 10.6931 20.7026 7.55111C20.9166 6.97111 20.64 6.37244 20.0603 6.15611ZM16.2896 14.5914C15.7933 14.9134 15.2656 14.9581 14.7196 14.6981C14.3893 14.5411 14.0863 14.3498 13.8743 14.0428C13.789 13.9181 13.727 13.7761 13.6473 13.6311C13.6203 13.7504 13.5843 13.8678 13.5683 13.9881C13.4953 14.5411 13.7146 14.9521 14.1866 15.2348C14.2773 15.2898 14.3776 15.3274 14.473 15.3758C14.4916 15.3864 14.5196 15.4181 14.515 15.4298C14.4416 15.6408 14.3643 15.8508 14.2853 16.0661L10.933 14.8461C11.0156 14.6228 11.0946 14.4054 11.177 14.1811C11.6436 14.3321 12.099 14.3751 12.5163 14.0581C12.7253 13.8994 12.9453 13.5691 12.9723 13.3751C12.8195 13.4845 12.6428 13.5559 12.457 13.5834C11.8236 13.6624 11.229 13.5734 10.757 13.1121C10.1163 12.4884 10.0156 11.2454 10.8263 10.5308C11.1456 10.2488 11.522 10.0674 11.9133 9.91578C12.4643 9.70311 13.0216 9.50678 13.571 9.29111C14.1209 9.07975 14.6416 8.79892 15.1203 8.45544C15.1326 8.44644 15.147 8.43844 15.173 8.42211C15.1946 8.50278 15.2136 8.57378 15.2333 8.64478C15.4333 9.36178 15.7666 10.0198 16.1363 10.6614C16.3543 11.0401 16.5733 11.4198 16.7816 11.8041C16.954 12.1234 17.0956 12.4574 17.1423 12.8201C17.24 13.5924 16.926 14.1774 16.2896 14.5914Z"
        fill="#CCCCCC"
      />
      <path
        d="M15.1731 8.42236C15.1951 8.50336 15.2138 8.57436 15.2334 8.64503C15.4334 9.36236 15.7668 10.0197 16.1364 10.6614C16.3544 11.0407 16.5734 11.4197 16.7814 11.8047C16.9538 12.1234 17.0958 12.4577 17.1424 12.8204C17.2424 13.592 16.9274 14.1774 16.2908 14.5907C15.7944 14.913 15.2664 14.9574 14.7211 14.698C14.3901 14.5407 14.0878 14.3494 13.8758 14.0424C13.7901 13.9177 13.7278 13.7757 13.6481 13.631C13.6214 13.7504 13.5854 13.868 13.5694 13.9884C13.4964 14.5407 13.7151 14.9524 14.1874 15.2347C14.2784 15.289 14.3794 15.327 14.4738 15.376C14.4931 15.386 14.5204 15.4177 14.5161 15.4297C14.4428 15.6407 14.3651 15.8504 14.2864 16.0664L10.9341 14.8464C11.0161 14.6227 11.0954 14.4054 11.1778 14.1797C11.6444 14.3304 12.0994 14.3737 12.5168 14.0567C12.7258 13.898 12.9464 13.5674 12.9731 13.3737C12.8202 13.483 12.6434 13.5543 12.4574 13.5817C11.8264 13.661 11.2301 13.5724 10.7574 13.111C10.1171 12.4867 10.0164 11.2444 10.8268 10.5294C11.1461 10.2477 11.5221 10.066 11.9138 9.9147C12.4648 9.70236 13.0218 9.50536 13.5718 9.29003C14.1212 9.07813 14.6416 8.79745 15.1204 8.4547C15.1341 8.44636 15.1471 8.4387 15.1731 8.42236Z"
        fill="white"
      />
    </svg>
  );

  const selectList = [
    {
      name: "Buy Airtime",
      text: "Recharge your phone with all supported Nigerian networks",
      icon: airtimeIcon,
      link: "/dashboard-bill-payment-airtime?dashboard-bill-payment",
    },
    {
      name: "Buy Data",
      text: "Buy data with all supported Nigerian networks",
      icon: dataIcon,
      link: "/dashboard-bill-payment-data?dashboard-bill-payment",
    },
    {
      name: "Pay for Electricity ",
      text: "Pay for your electricity bills easily with ease.",
      icon: electricityIcon,
      link: "/dashboard-bill-payment-electricity?dashboard-bill-payment",
    },
    {
      name: "Pay for Cable TVs",
      text: "Pay for your cable Tv’s with support for all providers",
      icon: cableIcon,
      link: "/dashboard-bill-payment-cable?dashboard-bill-payment",
    },
    {
      name: "Bettings",
      text: "Fund multiple betting account from this channel.",
      icon: betIcon,
      link: "/dashboard-bill-payment-betting?dashboard-bill-payment",
    },
  ];

  useState(() => {
    const url = window?.location?.href?.split("?")[1];
    if (url) {
      setUrlBack(url);
    }
  }, []);

  const [showAccountDrop, setShowAccountDrop] = useState(false);
  const dropDownIcon = (
    <svg
      className="img"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.2423 6.0083L8.24774 10.0137L12.2532 6.0083"
        stroke="rgba(117, 90, 226, 1)"
        strokeWidth="1.20163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const testingAccountDropBillRef = useOnClickOutside(() => {
    // setShowAccountDrop(false);
    // actionCtx.setCurrentAccountDrop(false)
  });

  if (
    actionCtx?.currentAccount?.authorization_level !== ("initiator_approver" || "initiator")  && actionCtx?.currentAccount?.authorization_level !==
    ("initiator" || "initiator_approver" )
  ) {
    return (
      <DashboardLayout>
        <TitletextBox
        title={`Bill Payment`}
        text={`All bill payment manage easily`}
          // exportBtn
          // onExport={() => {
          //   setShowModal((prev) => {
          //     return { ...prev, statement: true };
          //   });
          // }}
          // exportText={`Generate Statement`}
        />
        {/* title box end */}
        <ContainerWrapper dontShowHeader>
          <NoContentBox
            title={`No permission to view bill payment`}
            text={`You will be able to view this screen when you have the permission`}
            bgColor
            // loading={true}
          />
        </ContainerWrapper>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      {/* title box start */}
      <TitletextBox
        title={`Bill Payment`}
        text={`All bill payment manage easily`}
        back={urlBack}
        onBack={() => {
          navigate(`/${urlBack}`);
        }}
      />
      {/* title box end */}
      {/* cointainer wrap start */}
      <ContainerWrapper>
        <div className="bill-payment-wrap-box ">
          <div className="bill-payment-wrap">
            <div ref={testingAccountDropBillRef} className="title-drop-box">
              <p className="title grey-white-color">
                {" "}
                Select your preferred option
              </p>
              {/* ----------------------------- */}
            {all_account?.length > 1 &&    <div
                onClick={() => {
                  // setShowAccountDrop(!showAccountDrop);
                  actionCtx.setCurrentAccountDrop(
                    !actionCtx.currentAccountDrop
                  );
                }}
                className="value-box grey-bg-two"
              >
                <p className="value">
                  {actionCtx?.currentAccount?.custom_account_name || "---"}
                </p>
                <figure
                  className={`img-box ${showAccountDrop && "img-box-up"}`}
                >
                  {dropDownIcon}
                </figure>
              </div>}
              {/* toggle account box start */}
              {showAccountDrop && (
                <div className="select-toggle-box">
                  <AccountDropDownBox
                    big={false}
                    onSelect={(obj) => {
                      actionCtx?.setCurrentAccount(obj);
                      // console.log(obj);
                      setShowAccountDrop(false);
                    }}
                  />
                </div>
              )}
              {/* toggle account box end */}
              {/* ----------------------------- */}
            </div>
            {/* select wrap start */}
            <div className="select-box border-theme animate-move-up-class">
              {selectList.map((chi, idx) => {
                const { name, icon, text, link } = chi;

                return (
                  <div
                    key={idx}
                    className="box box-card border-theme-bottom"
                    onClick={() => {
                      link && navigate(link);
                    }}
                  >
                    <figure className="img-box">{icon}</figure>
                    <div className="text-box">
                      <p className="name">{name}</p>
                      <p className="text grey-white-color">{text}</p>
                    </div>
                    {/* right arrow styart */}
                    <div className="right-arrow">
                      <FaAngleRight className="icon black-white-color" />
                    </div>
                    {/* right arrow end */}
                  </div>
                );
              })}
            </div>
            {/* select wrap end */}
          </div>
        </div>
      </ContainerWrapper>
      {/* cointainer wrap end */}
    </DashboardLayout>
  );
};

export default DashboardBillPaymentIndex;
