import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { changeEmployeePaymentStatus } from "../../../../redux/payroll";

const RemoveEmpFRomCurModal = ({ visible, onCancel, detail, onFinish }) => {
  const dispatch = useDispatch();
  const { loadingStatus } = useSelector((state) => state.payroll);
  const handleDelete = async () => {
    const obj = {
      id: detail?.id,
      status:
        detail?.status === "pending_approval" ? "removed" : "pending_approval",
    };
    const data = await dispatch(changeEmployeePaymentStatus(obj));
    if (data?.payload.data?.status === "success") {
      onFinish && onFinish();
      return;
    }
  };
  return (
    <DeleteModal
      loading={loadingStatus}
      visible={visible}
      deleteText={detail?.status === "pending_approval" ? "Remove" : "Add"}
      onCancel={onCancel}
      onDelete={handleDelete}
      buttonColor={
        detail?.status === "pending_approval" ? `error-light` : `black-light`
      }
      title={
        detail?.status === "pending_approval"
          ? `Remove from Current Payroll`
          : "Add to Current Payroll"
      }
      text={detail?.status === "pending_approval" ? `Remove this users ( ${
        detail?.account_name || detail?.raven_account_name
      } ), from your payroll list, this mean you won’t be able to perform transaction, for this user.` : `Add this users ( ${
        detail?.account_name || detail?.raven_account_name
      } ), to your payroll list, this mean you will be able to perform transaction, for this user.`}
    />
  );
};

export default RemoveEmpFRomCurModal;
