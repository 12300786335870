import { RavenButton } from "@ravenpay/raven-bank-ui";
import React from "react";
import { invoiceIcon } from "../../assets/icon/icons";
require("../../styles/dashboard/invoice/no_invoice.css");

function NoContent({ icon, title, subTitle, onClick, btnLabel, btnColor }) {
  return (
    <div className="no_content_wrapper flex wp-99 hp-99 align-center justify-center">
      <div className="no_content_container flex align-center justify-center flex-column">
        <figure>{icon ? icon : invoiceIcon.noInvoice}</figure>
        <div className="flex justify-center  align-center flex-column ">
          <p className="font-700 text-18 mt-16 ">
            {title ? title : "Title goes here."}
          </p>
          <small className="text-14 sub_text font-400">
            {subTitle ? subTitle : "Click the button below to get started"}
          </small>
        </div>

        <div className="mt-16">
          <RavenButton
            onClick={onClick}
            size={"big"}
            color={btnColor ? btnColor : `black-light`}
            label={btnLabel ? btnLabel : "Create Invoice"}
          />
        </div>
      </div>
    </div>
  );
}

export default NoContent;
