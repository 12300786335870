import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { removeRoleFromSubAccount } from "../../../../redux/sub_account";

const RemoveRoleFromAccountModal = ({ detail, visible, onClose, onFinish }) => {
  const { loading } = useSelector((state) => state.sub_account);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const obj = {
      account_id: String(detail?.id),
      role_to_remove: detail?.role
    }
    // console.log(detail);
    // return 
    const data = await dispatch(removeRoleFromSubAccount(obj));
    if(data?.payload?.data?.status === "success"){
      onFinish()
    }
  };
  return (
    <DeleteModal
      title={`Remove  Role from ( ${detail?.custom_account_name} )`}
      text={`Removing this role ( ${detail?.role} ) from sub account, would disable their access to your account on your business, ensure you confirm this device before totally removing them from your account.`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
      loading={loading}
    />
  );
};

export default RemoveRoleFromAccountModal;
