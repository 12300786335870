/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import setAuthToken from "../utils/auth";
import { toast } from "@ravenpay/raven-bank-ui";
// import { decrypt } from "../utils/EncryptAndDecrypt";
// import { formatMetaData } from "../utils/helper/Helper";

export const registerUser = createAsyncThunk(
  "/register",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post("register", formData);

      // console.log("register", data?.data.message);
      if (data?.data?.status === "fail") {
        toast.error(data?.data.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data.message, {
        //   position: "top-right",
        // });
        return data;
        // await thunkAPI.dispatch(login(data.data.user));
        // return data;
      }
      // return data;
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",

        //
        // });
        return err;
      }
    }
  }
);

export const validateOtp = createAsyncThunk(
  "register/validate_registration_otp",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post(
        "register/validate_registration_otp",
        payload
      );
      // console.log(data);
      if (data?.data?.status === "fail") {
        // console.log(data.data.message);
        toast.error(data.data.message);
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(login(data.data));
        await thunkAPI.dispatch(setUser(data.data.data.user));
        await thunkAPI.dispatch(setBusiness(data.data.data.business));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const validateOtpTwoFa = createAsyncThunk(
  "register/validate_otp-two-fa",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/two-factor/verify-2-fa", payload);
      // console.log(data);
      if (data?.data?.status === "fail") {
        // console.log(data.data.message);
        toast.error(data.data.message);
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(login(data.data));
        // await thunkAPI.dispatch(setUser(data.data.data.user));
        // await thunkAPI.dispatch(setBusiness(data.data.data.business));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "register/update-user-profile",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const { data } = await axios.post("/update_user", payload);
      // console.log(data);
      if (data.status === "fail") {
        // toast.error(data.message, {
        //   theme: "colored",

        //
        // });
        return data;
      }
      if (data.status === "success") {
        // toast.success(data.message, {
        //   theme: "colored",

        //   toastId: customSuccessId,
        // });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const validateSSO = createAsyncThunk(
  "login/validate_email_captured_via_login",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const { data } = await axios.post(
        "login/validate_email_captured_via_login",
        payload
      );
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: "colored",

        //
        // });
        return data;
      }
      if (data.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const authenticateLogin = createAsyncThunk(
  "authenticate_login",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("authenticate_login", payload);
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   position: "top-right",
        // });
        //  getUser()
        await thunkAPI.dispatch(setUser(data.data.data.user));
        await thunkAPI.dispatch(setBusiness(data.data.data.business));
        // await thunkAPI.dispatch(login(data.data.user));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response?.data?.status === "fail") {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail") {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const CheckUserLoginDetails = createAsyncThunk(
  "authenticate_login",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/check_login_token", payload);
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "success") {
        if (data.data.message) {
          // data.data.message !== "login in progress" &&
          //   toast.success(data.data.message, {
          //     position: "top-right",
          //   });
        }

        //  getUser()
        // await thunkAPI.dispatch(setUser(data.data.data.user));
        // await thunkAPI.dispatch(setBusiness(data.data.data.business));
        // await thunkAPI.dispatch(login(data.data.user));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response?.data?.status === "fail") {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail") {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const authenticateLoginSso = createAsyncThunk(
  "authenticate_login_sso",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post(
        "login/validate_email_captured_via_login",
        payload
      );
      // console.log(data);
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   position: "top-right",
        // });
        //  getUser()
        await thunkAPI.dispatch(setUser(data.data.data.user));
        await thunkAPI.dispatch(setBusiness(data.data.data.business));
        // await thunkAPI.dispatch(login(data.data.user));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response?.data?.status === "fail") {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail") {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const getUserREsourcesAndPermissions = createAsyncThunk(
  "user-resource-and-permission",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.get(
        "/roles_and_permissions/get_permission_for_logged_in_user",
        payload
      );
      // console.log(data, "---------------------------");
      if (data?.data?.status === "fail") {
        // toast.error(data.data.message, {
        //   position: "top-right",
        // });
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   position: "top-right",
        // });
        //  getUser()
        await thunkAPI.dispatch(setUserResourceAndPermissions(data.data.data));
        // await thunkAPI.dispatch(login(data.data.user));
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response?.data?.status === "fail") {
        // toast.error(err?.response?.data?.message, {
        //   position: "top-right",
        // });
      }
      if (err.message === "Network Error") {
        // toast.error(err.message, {
        //   position: "top-right",
        // });
      }
      if (err.response.data.status === "fail") {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const resendEmail = createAsyncThunk(
  "register/resend_email_validation_otp",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "register/resend_email_validation_otp",
        payload
      );
      // console.log(data);
      if (data?.data?.status === "fail") {
        toast.error(data?.data.message, {
          position: "top-right",
        });
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data.message, {
          position: "top-right",
        });
        // alert(data?.data?.message);
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        // toast.error(err.message)
      }
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const resendEmailLogin = createAsyncThunk(
  "register/resend_email_validation_otp_login",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("resend_login_otp", payload);
      // console.log(data);
      if (data?.data?.status === "fail") {
        toast.error(data?.data.message, {
          position: "top-right",
        });
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data.message, {
          position: "top-right",
        });
        // alert(data?.data?.message);
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        // toast.error(err.message)
      }
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const getUser = createAsyncThunk(
  "register/get-user",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get("/get_user");
      // console.log(data, "---------cjksks-------------");
      if (data?.status !== "success") {
        // toast.error(data.message, {
        //   theme: "colored",
        //
        // });
        // return data;
      }
      if (data?.status === "success") {
        // console.log("resend otp", data); setShowGlobalAccount
        const business1 = data?.data?.business;
        localStorage.setItem("bvn_status", business1?.bvn);
        const showGlobal = data?.data?.global_account_control;

        const account_details1 = data?.data?.account_details;
        const user1 = data?.data?.user;
        await thunkAPI.dispatch(setUser(user1));
        await thunkAPI.dispatch(setBusiness(business1));
        await thunkAPI.dispatch(setAccountDetails(account_details1));
        await thunkAPI.dispatch(setShowGlobalAccount(showGlobal));
        return data;
      }
    } catch (err) {
      // ;
      if (
        err?.response?.data?.status === "fail" &&
        err?.response?.status !== 400
      ) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const getAllAccounts = createAsyncThunk(
  "register/get-all-accounts",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const data = await axios.get("/get_all_accounts");
      // console.log(data, "---------cjksks-------------",);
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: "colored",
        //
        // });
        // return data;
      }
      if (data?.data?.status === "success") {
        const accounts = data?.data?.data?.account;
        await thunkAPI.dispatch(setAllAccounts(accounts));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const cancelTokenFunc = createAsyncThunk(
  "register/cancel-token-func",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const data = await axios.get("/update_token_status");
      // console.log(data, "---------cjksks-------------");
      if (data?.status !== "success") {
        // toast.error(data.message, {
        //   theme: "colored",
        //
        // });
        // return data;
      }
      if (data?.data?.status === "success") {
        const accounts = data?.data?.data?.account;
        await thunkAPI.dispatch(setAllAccounts(accounts));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const getAllBusinesses = createAsyncThunk(
  "register/get-all-businesses",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const data = await axios.get("/get_all_business");
      // console.log(data, "---------cjksks-------------");
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: "colored",
        //
        // });
        // return data;
      }
      if (data?.data?.status === "success") {
        const accounts = data?.data?.data?.businesses;
        await thunkAPI.dispatch(setBusinessAccount(accounts));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const getAllNotification = createAsyncThunk(
  "register/get-all-notification",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const data = await axios.get(
        `/notification?per_page=${payload?.per_page || 10}&current_page=${
          payload?.current_page || 1
        }`
      );

      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: "colored",
        //
        // });
        // return data;
      }
      if (data?.data?.status === "success") {
        // console.log(data);
        const accounts = data?.data?.data;
        // console.log(accounts);
        await thunkAPI.dispatch(setNotification(accounts));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 400) {
      }
      return err;
    }
  }
);

export const loginIntoAnotherBusiness = createAsyncThunk(
  "set_user_pin",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/change_logged_in_business", payload);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const createAnotherBusiness = createAsyncThunk(
  "create-another-business",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/add_new_business", payload);
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      // console.log(err);
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const setUserPin = createAsyncThunk(
  "set_user_pin",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/set_user_pin", payload);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const updateUserPin = createAsyncThunk(
  "set_user_pin_update",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/update_pin", payload);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const loginUser = createAsyncThunk(
  "login",
  async (formData, thunkAPI) => {
    const payload = {
      email: formData.email,
      password: formData.password,
      device_id: formData.device_id,
    };
    // console.log(formData);
    // const data = await decrypt("")
    try {
      const data = await axios.post("/login", payload);
      // console.log("we gere", data);
      if (data?.response?.data?.status === "fail") {
        // console.log(data);
        toast.error(data?.response?.data?.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "fail") {
        if (data?.payload?.data?.action === "verify_email") {
          toast.error(data?.data?.message, {
            position: "top-right",
          });
          return data?.data;
        }
        toast.error(data?.data?.message, {
          position: "top-right",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.data?.status === "success") {
        data.data.message !== "login in progress" &&
          // toast.success(data?.data?.message, {
          //   position: "top-right",
          // });
          (await thunkAPI.dispatch(login(data?.data)));
        return thunkAPI.rejectWithValue(data?.data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response?.data?.status === "fail") {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (
        err.response.data.status === "fail" &&
        err.response.data.message !==
          "Your email address is yet to be verified. A mail has been sent to your email address. Please check and follow the instruction in the mail to verify account."
      ) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgot-password",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post("/forgot_password", formData);
      if (data?.response?.data?.status === "fail") {
        // console.log(data);
        toast.error(data?.response?.data?.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      if (err.response?.data?.status === "fail") {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/reset_password", payload);
      if (data?.response?.data?.status === "fail") {
        // console.log(data);
        toast.error(data?.response?.data?.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        return data;
      }
    } catch (err) {
      if (err.response?.data?.status === "fail") {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const validateUniqueEmail = createAsyncThunk(
  "register/unique_email_and_phone",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/register/unique_email_and_phone",
        payload
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        // console.log(data);
        toast.error(data?.response?.data?.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "success") {
        return data?.data;
      } else if (data?.response?.data?.status !== "success") {
        return data;
      } else if (data?.data?.status !== "success") {
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
      }
      return err;
    }
  }
);

export const validateResetPasswordOtp = createAsyncThunk(
  "verify_reset_passwordOTP",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post("verify_reset_passwordOTP", formData);
      // console.log("validateOtp", data);
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {
          position: "top-right",
        });
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      if (err.response?.data?.status === "fail") {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const logoutUser = createAsyncThunk(
  "logout",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/logout_user", payload);
      // console.log(data);
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {
        //   theme: "colored",
        //
        // });
        // return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message);
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const getTeamMemberByInvite = createAsyncThunk(
  "get-team-member",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/teams/team_members_with_pending_invites/${payload}`
      );
      // console.log(data);
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {
        //   theme: "colored",
        // });
        // return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   theme: "colored",
        // });
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        // });
      }
      return err;
    }
  }
);

export const processInviteTeamRegistration = createAsyncThunk(
  "lprocess-team-member",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/teams/register", payload);

      // console.log("register", data);

      if (data?.data?.status !== "success") {
        // toast.error(data.message, {
        //   theme: "colored",

        //
        // });
        return thunkAPI.rejectWithValue(data?.data);
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",

        //   toastId: customSuccessId,
        // });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const uploadBusinessLogo = createAsyncThunk(
  "upload-business-logo",
  async (payload, thunkAPI) => {
    try {
      const obj = {
        payload,
        type: "formData",
      };
      const data = await axios.post("/upload_logo", obj);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const removeBusinessLogo = createAsyncThunk(
  "remove-business-logo",
  async (payload, thunkAPI) => {
    try {
      const obj = {
        payload,
        type: "formData",
      };
      const data = await axios.post("/update_business_logo_status", obj);
      //  console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.success(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        // toast.error(err.response.data.message, {
        //   theme: "colored",
        //   position: "top-right",
        //
        // });
      }
      return err;
    }
  }
);

export const user = createSlice({
  name: "user",
  initialState: {
    user: {},
    business: {},
    business_accounts: [],
    account_details: {},
    all_notification: [],
    global_account: "0",
    all_account: [],
    isAuth: false,
    loading: false,
    loadUser: false,
    loadUpdate: false,
    loadLogout: false,
    loadingUpload: false,
    loadingNotify: false,
    user_resource_and_permissions: {},
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
    },
    setBusiness: (state, action) => {
      state.business = action.payload;
      state.isAuth = true;
    },
    setNotification: (state, action) => {
      state.all_notification = action.payload;
      state.isAuth = true;
    },
    setBusinessAccount: (state, action) => {
      state.business_accounts = action.payload;
      state.isAuth = true;
    },
    setAccountDetails: (state, action) => {
      state.account_details = action.payload;
      state.isAuth = true;
    },
    setShowGlobalAccount: (state, action) => {
      state.global_account = action.payload;
      state.isAuth = true;
    },
    setAllAccounts: (state, action) => {
      state.all_account = action.payload;
      state.isAuth = true;
    },
    setUserResourceAndPermissions: (state, action) => {
      state.user_resource_and_permissions = action.payload;
      state.isAuth = true;
    },
    login: (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
      state.loading = false;
    },
  },

  extraReducers: {
    [registerUser.pending]: (state) => {
      state.loading = true;
    },
    [registerUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [registerUser.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getUser.pending]: (state) => {
      state.loadUser = true;
    },
    [getUser.fulfilled]: (state) => {
      state.loadUser = false;
    },
    [getUser.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadUser = false;
      state.isAuth = false;
      state = null;
    },
    [validateOtp.pending]: (state) => {
      state.loading = true;
    },
    [validateOtp.fulfilled]: (state) => {
      state.loading = false;
    },
    [validateOtp.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [validateOtpTwoFa.pending]: (state) => {
      state.loading = true;
    },
    [validateOtpTwoFa.fulfilled]: (state) => {
      state.loading = false;
    },
    [validateOtpTwoFa.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [authenticateLogin.pending]: (state) => {
      state.loading = true;
    },
    [authenticateLogin.fulfilled]: (state) => {
      state.loading = false;
    },
    [authenticateLogin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [CheckUserLoginDetails.pending]: (state) => {
      state.loading = true;
    },
    [CheckUserLoginDetails.fulfilled]: (state) => {
      state.loading = false;
    },
    [CheckUserLoginDetails.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [authenticateLoginSso.pending]: (state) => {
      state.loading = true;
    },
    [authenticateLoginSso.fulfilled]: (state) => {
      state.loading = false;
    },
    [authenticateLoginSso.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [resendEmail.pending]: (state) => {
      state.loading = true;
    },
    [resendEmail.fulfilled]: (state) => {
      state.loading = false;
    },
    [resendEmail.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [resendEmailLogin.pending]: (state) => {
      state.loading = true;
    },
    [resendEmailLogin.fulfilled]: (state) => {
      state.loading = false;
    },
    [resendEmailLogin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [loginUser.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [forgotPassword.pending]: (state) => {
      state.loading = true;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.loading = false;
    },
    [forgotPassword.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [resetPassword.pending]: (state) => {
      state.loading = true;
    },
    [resetPassword.fulfilled]: (state) => {
      state.loading = false;
    },
    [resetPassword.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [validateUniqueEmail.pending]: (state) => {
      state.loading = true;
    },
    [validateUniqueEmail.fulfilled]: (state) => {
      state.loading = false;
    },
    [validateUniqueEmail.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [validateResetPasswordOtp.pending]: (state) => {
      state.loading = true;
    },
    [validateResetPasswordOtp.fulfilled]: (state) => {
      state.loading = false;
    },
    [validateResetPasswordOtp.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [setUserPin.pending]: (state) => {
      state.loading = true;
    },
    [setUserPin.fulfilled]: (state) => {
      state.loading = false;
    },
    [setUserPin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [uploadBusinessLogo.pending]: (state) => {
      state.loadingUpload = true;
    },
    [uploadBusinessLogo.fulfilled]: (state) => {
      state.loadingUpload = false;
    },
    [uploadBusinessLogo.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingUpload = false;
      state.isAuth = false;
      state = null;
    },

    [removeBusinessLogo.pending]: (state) => {
      state.loadingUpload = true;
    },
    [removeBusinessLogo.fulfilled]: (state) => {
      state.loadingUpload = false;
    },
    [removeBusinessLogo.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingUpload = false;
      state.isAuth = false;
      state = null;
    },

    [validateSSO.pending]: (state) => {
      state.loading = true;
    },
    [validateSSO.fulfilled]: (state) => {
      state.loading = false;
    },
    [validateSSO.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [updateUserProfile.pending]: (state) => {
      state.loadUpdate = true;
    },
    [updateUserProfile.fulfilled]: (state) => {
      state.loadUpdate = false;
    },
    [updateUserProfile.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadUpdate = false;
      state.isAuth = false;
      state = null;
    },
    [logoutUser.pending]: (state) => {
      state.loadLogout = true;
    },
    [logoutUser.fulfilled]: (state) => {
      state.loadLogout = false;
    },
    [logoutUser.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadLogout = false;
      state.isAuth = false;
      state = null;
    },
    [loginIntoAnotherBusiness.pending]: (state) => {
      state.loadLogout = true;
    },
    [loginIntoAnotherBusiness.fulfilled]: (state) => {
      state.loadLogout = false;
    },
    [loginIntoAnotherBusiness.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadLogout = false;
      state.isAuth = false;
      state = null;
    },
    [updateUserPin.pending]: (state) => {
      state.loadUpdate = true;
    },
    [updateUserPin.fulfilled]: (state) => {
      state.loadUpdate = false;
    },
    [updateUserPin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadUpdate = false;
      state.isAuth = false;
      state = null;
    },
    [processInviteTeamRegistration.pending]: (state) => {
      state.loading = true;
    },
    [processInviteTeamRegistration.fulfilled]: (state) => {
      state.loading = false;
    },
    [processInviteTeamRegistration.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getAllNotification.pending]: (state) => {
      state.loadingNotify = true;
    },
    [getAllNotification.fulfilled]: (state) => {
      state.loadingNotify = false;
    },
    [getAllNotification.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingNotify = false;
      state.isAuth = false;
      state = null;
    },
    [createAnotherBusiness.pending]: (state) => {
      state.loadingNotify = true;
    },
    [createAnotherBusiness.fulfilled]: (state) => {
      state.loadingNotify = false;
    },
    [createAnotherBusiness.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingNotify = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setUser,
  setBusiness,
  setAccountDetails,
  setAllAccounts,
  setBusinessAccount,
  setNotification,
  setUserResourceAndPermissions,
  setShowGlobalAccount,
} = user.actions;

export default user.reducer;
