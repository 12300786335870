/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "@ravenpay/raven-bank-ui";
import axios from "../utils/axios";

export const getInvoice = createAsyncThunk(
  "admin/get-invoices",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/invoice/single_invoice?id=${payload.id}`,
        payload
      );
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log("register", data?.data?.data);
        // toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(setInvoice(data?.data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getInvoicePublicPreview = createAsyncThunk(
  "admin/get-invoices-public-preview",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/invoice/invoice_preview?reference=${payload.id}`,
        payload
      );
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log("register", data?.data?.data);
        // toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(setInvoice(data?.data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getInvoiceSettings = createAsyncThunk(
  "admin/get-invoice-settings",
  async (payload, thunkAPI) => {
    // console.log("jhdkjhghjsb--------------");
    try {
      const data = await axios.get(`/invoice/get_setting`);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(setInvoiceSetting(data?.data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const downloadInvoice = createAsyncThunk(
  "admin/get-invoices-download",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`invoice/download/${payload.id}`, payload, {
        responseType: "blob",
      });
      if (data?.status === 200) {
        // console.log(data.data);
        //Create a Blob from the PDF Stream
        // const file = new Blob([data.data], { type: "application/pdf" });
        //Build a URL from the file
        // const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        // window.open(fileURL);
        // return data;
      }
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(setInvoices(data?.data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getInvoicesByFilter = createAsyncThunk(
  "admin/get-invoices-filter",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      //       // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getInvoices = createAsyncThunk(
  "admin/get-invoice-single",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/fetch_invoices_v2?per_page=${payload.per_page}&current_page=${
          payload.current_page
        }&status=${payload?.status || "all"}&search=${payload?.search}`
      );
      // console.log("we gere", data);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(setInvoices(data?.data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getCustomers = createAsyncThunk(
  "admin/get-customers",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`customer`, payload);
      // console.log(data?.data, 'mini')

      if (data?.response?.status === 400) {
        toast.error("Something went wrong, Try again!", {});
        // return data?.data;
      } else if (data?.data?.status !== "success") {
        toast.error(data?.data?.message, {
          theme: "colored",
          position: "top-right",
        });

        return data?.data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   theme: "colored",
        //   position: "top-right",
        // });
        await thunkAPI.dispatch(setCustomers(data?.data?.data));
        return data?.data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const updateInvoice = createAsyncThunk(
  "invoice/update",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/invoice/update", payload);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const shareInvoice = createAsyncThunk(
  "invoice/share",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("invoice/share", payload);
      if ("response" in data) {
        if (data?.response?.data?.status !== "success") {
          toast.error(data?.response?.data?.message, {
            theme: "colored",
            position: "top-right",
          });
          return data?.response;
        }
      }

      if ("data" in data) {
        if (data?.data?.status !== "success") {
          toast.error(data?.data?.message, {
            theme: "colored",
            position: "top-right",
          });
          return data.data;
        }

        if (data?.data?.status === "success") {
          toast.success(data?.data?.message, {
            theme: "colored",
            position: "top-right",
          });
          // await thunkAPI.dispatch(setInvoice(data?.data?.data));
          return data?.data;
        }
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  "admin/delete-invoices",
  async (payload, thunkAPI) => {
    try {
      const postData = {
        id: String(payload.id),
      };
      const data = await axios.post(`invoice/remove`, postData);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const createNewInvoice = createAsyncThunk(
  "invoice/create-invoice",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/invoice/create_invoice_with_draft",
        payload
      );
      // console.log("we gere", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const createNewInvoiceDraft = createAsyncThunk(
  "invoice/create-invoice-draft",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/invoice/create_invoice_with_draft",
        payload
      );
      // console.log("we gere", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        // console.log(data);
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const createNewInvoiceDraftAuto = createAsyncThunk(
  "invoice/create-invoice-draft",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/invoice/create_invoice_with_draft",
        payload
      );
      // console.log("we gere", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const updateNewInvoice = createAsyncThunk(
  "invoice/update-invoice",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("invoice/update", payload);
      // console.log("we gere", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const createInvoiceSettings = createAsyncThunk(
  "invoice/create-settings",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/invoice/setting_update", payload);
      // console.log("we gere", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const shareInvoiceLink = createAsyncThunk(
  "admin/share-invoice-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`/invoice/share`, payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const invoice = createSlice({
  name: "invoice",
  initialState: {
    invoiceSetting: {},
    invoices: [],
    invoice: [{}],
    customers: [{}],
    loadingDelete: false,
    loadingSetting: false,
    isAuth: false,
    loading: false,
    loading_single_draft: false,
    loading_auto: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setInvoices: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.invoices = action.payload;
    },
    setInvoiceSetting: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.invoiceSetting = action.payload;
    },
    setCustomers: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.customers = action.payload;
    },
    setInvoice: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.invoice = action.payload;
    },
  },
  extraReducers: {
    [getInvoices.pending]: (state) => {
      state.loading = true;
    },
    [getInvoices.fulfilled]: (state) => {
      state.loading = false;
    },
    [getInvoices.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [createNewInvoiceDraftAuto.pending]: (state) => {
      state.loading_auto = true;
    },
    [createNewInvoiceDraftAuto.fulfilled]: (state) => {
      state.loading_auto = false;
    },
    [createNewInvoiceDraftAuto.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading_auto = false;
      state.isAuth = false;
      state = null;
    },
    [getInvoicePublicPreview.pending]: (state) => {
      state.loading = true;
    },
    [getInvoicePublicPreview.fulfilled]: (state) => {
      state.loading = false;
    },
    [getInvoicePublicPreview.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getInvoice.pending]: (state) => {
      state.loading = true;
    },
    [getInvoice.fulfilled]: (state) => {
      state.loading = false;
    },
    [getInvoice.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [getCustomers.pending]: (state) => {
      state.loading = true;
    },
    [getCustomers.fulfilled]: (state) => {
      state.loading = false;
    },
    [getCustomers.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [shareInvoice.pending]: (state) => {
      state.loading = true;
    },
    [shareInvoice.fulfilled]: (state) => {
      state.loading = false;
    },
    [shareInvoice.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [getInvoice.pending]: (state) => {
      state.loading = true;
    },
    [getInvoice.fulfilled]: (state) => {
      state.loading = false;
    },
    [getInvoice.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [createNewInvoice.pending]: (state) => {
      state.loading_single_draft = true;
    },
    [createNewInvoice.fulfilled]: (state) => {
      state.loading_single_draft = false;
    },
    [createNewInvoice.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading_single_draft = false;
      state.isAuth = false;
      state = null;
    },

    [createNewInvoiceDraft.pending]: (state) => {
      state.loading_single_draft = true;
    },
    [createNewInvoiceDraft.fulfilled]: (state) => {
      state.loading_single_draft = false;
    },
    [createNewInvoiceDraft.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading_single_draft = false;
      state.isAuth = false;
      state = null;
    },
    [updateNewInvoice.pending]: (state) => {
      state.loading = true;
    },
    [updateNewInvoice.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateNewInvoice.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateInvoice.pending]: (state) => {
      state.loading = true;
    },
    [updateInvoice.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateInvoice.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [shareInvoiceLink.pending]: (state) => {
      state.loading = true;
    },
    [shareInvoiceLink.fulfilled]: (state) => {
      state.loading = false;
    },
    [shareInvoiceLink.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteInvoice.pending]: (state) => {
      state.loadingDelete = true;
    },
    [deleteInvoice.fulfilled]: (state) => {
      state.loadingDelete = false;
    },
    [deleteInvoice.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingDelete = false;
      state.isAuth = false;
      state = null;
    },
    [downloadInvoice.pending]: (state) => {
      state.loadingDelete = true;
    },
    [downloadInvoice.fulfilled]: (state) => {
      state.loadingDelete = false;
    },
    [downloadInvoice.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingDelete = false;
      state.isAuth = false;
      state = null;
    },
    [createInvoiceSettings.pending]: (state) => {
      state.loadingSetting = true;
    },
    [createInvoiceSettings.fulfilled]: (state) => {
      state.loadingSetting = false;
    },
    [createInvoiceSettings.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSetting = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setInvoices,
  setCustomers,
  setInvoice,
  setInvoiceSetting,
} = invoice.actions;

export default invoice.reducer;
