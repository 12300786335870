// import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import DeleteModal from "../../../components/reusables/DeleteModal";
import { useDispatch } from "react-redux";
import {
//   deletePaymentLink,
  disablePaymentLink,
} from "../../../redux/requestMoney";
import { trimLongString } from "../../../utils/helper/Helper";

const DisablePaymentModal = ({ visible, onCancel, detail, onFinish }) => {
//   const { loading } = useSelector((state) => state.paymentLinks);
  const [loadingAction, setLoadingAction] = useState(false)
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const obj = {
      payment_link_id: String(detail?.id),
    };
    setLoadingAction(true)
    const data = await dispatch(disablePaymentLink(obj));
    // console.log(data);
    if (data?.payload?.response?.data?.status === "fail") {
      onCancel();
      setLoadingAction(false)
    }
    if (data?.payload?.data?.status === "fail") {
      onCancel();
      setLoadingAction(false)
    }
    if (data?.payload?.data?.status === "success") {
      onFinish();
      setLoadingAction(false)
    }
  };
  return (
    <DeleteModal   
      title={
        detail?.status === 1
          ? `Disable Link ( ${trimLongString(detail?.title, 17)} )`
          : `Enable Link ( ${trimLongString(detail?.title, 17)} )`
      }
      onDelete={handleSubmit}
      buttonColor={detail?.status === 1 ? "error-light" : `black-light`}
      loading={loadingAction}
      visible={visible}
      onCancel={onCancel}
      deleteText={detail?.status === 1 ? "Disable" : "Enable"}
      text={
        detail?.status === 1
          ? "Please be aware that disabling the payment link description will make link unavailable for payment. We recommend considering the potential consequences before making any changes."
          : "Please be aware that enabling the payment link description will make link available for payment. We recommend considering the potential consequences before making any changes."
      }
    />
  );
};

export default DisablePaymentModal;
