import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { deleteCashiers } from "../../../../redux/pos";

const DeleteCashierModal = ({ visible, onClose, detail, onFinish }) => {
  const dispatch = useDispatch();
  const { loadingSettlement } = useSelector((state) => state.pos);

  const handleSubmit = async () => {
    const obj = {
      cashier_phone: detail?.cashier_phone,
    };
    const data = await dispatch(deleteCashiers(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
    }
  };

  return (
    <DeleteModal
      title={`Delete Cashier (${detail?.full_name})`}
      text={`This terminal won’t be able to receive or process transaction any more, unless you reach our customer care agent to unblock your terminal. do you wish to proceed`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
      loading={loadingSettlement}
    />
  );
};

export default DeleteCashierModal;
