import { RavenButton, RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ProgressBar from "../../../components/fragments/ProgreeBar";
import ActionContext from "../../../context/ActionContext";
import CryptoJS from "crypto-js";
import {
  cancelTokenFunc,
  createAnotherBusiness,
  getAllBusinesses,
  loginIntoAnotherBusiness,
} from "../../../redux/user";
import {
  getAccountPurposes,
  getBusinessIndustries,
  getLgaByStates,
  getMonthlyTransactionrange,
  getNumberOfStaff,
  getStates,
} from "../../../redux/verifications";
import "../../../styles/dashboard/create-business/CreateBusinessWrapIndex.css";
import {
  formatTypeFunction,
  reactSelectStyle,
} from "../../../utils/helper/Helper";
import { validateEmail } from "../../../utils/helper/ValidateInput";
import { useDarkMode } from "../../../utils/themes/useDarkMode";

const CreateBusinessWrapIndex = () => {
  const actionCtx = useContext(ActionContext);
  // eslint-disable-next-line no-unused-vars
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const [step, setStep] = useState(1);
  const {
    states,
    Lgas,
    business_industries,
    monthly_transaction_range,
    number_of_staff,
    // account_purpose_list,
  } = useSelector((state) => state.verifications);
  const dispatch = useDispatch();
  const { loadingNotify, user, loadLogout } = useSelector(
    (state) => state.user
  );
  const closeIcon = (
    <svg
      className="img"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M4.5 4.5L13.5 13.5M13.5 4.5L4.5 13.5"
        stroke="#020202"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    dispatch(getBusinessIndustries());
    dispatch(getMonthlyTransactionrange());
    dispatch(getNumberOfStaff());
    dispatch(getAccountPurposes());
    dispatch(getStates());
    // console.log(account_purpose_list_two);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [details, setDetails] = useState({
    user_email: "",
    business_email: "",
    business_name: "",
    business_phone: "",
    state: "",
    local_government: "",
    address: "",
    closest_landmark: "",
    business_industry: "",
    monthly_transaction_range: "",
    number_of_staffs: "",
    business_website: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
    password_msg: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleStep = () => {
    if (step < 3) {
      setStep(step + 1);
    }
    if (step === 3) {
      AddNewBusiness();
    }
  };

  const detailList = [
    "Your password remains the same for other businesses for faster login processes.",
    "Verification is different, you would be required to verify your new business as a stand alone entity.",
    "Your new business can be managed independently of your other businesses.",
  ];
  const [newBusiness, setNewBusiness] = useState({});
  const AddNewBusiness = async () => {
    const obj = {
      ...details,
      user_email: user?.email,
      business_industry: details?.business_industry?.value,
      local_government: details?.local_government?.value,
      monthly_transaction_range: details?.monthly_transaction_range?.value,
      number_of_staffs: details?.number_of_staffs?.value,
      state: details?.state?.value,
    };
    // console.log(obj);
    // return
    const data = await dispatch(createAnotherBusiness(obj));
    if (data?.payload?.data?.status === "success") {
      // console.log(data);
      setNewBusiness(data.payload?.data?.data?.business);
      setDetails({
        user_email: "",
        business_email: "",
        business_name: "",
        business_phone: "",
        state: "",
        local_government: "",
        address: "",
        closest_landmark: "",
        business_industry: "",
        monthly_transaction_range: "",
        number_of_staffs: "",
        business_website: "",
      });
      dispatch(getAllBusinesses());
      setStep(4);
    }
  };

  const encryptTokenFunc = (tok) => {
    const token = tok;
    const secretKey =
      "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

    // Encrypt the token
    const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
    return encryptedToken;
  };

  const handleSwitchAccount = async (param) => {
    // console.log(newBusiness);
    const obj = {
      business_id: String(param),
      // password: details?.pin,
    };
    actionCtx?.setShowGeneralLoader(true);
    const data = await dispatch(loginIntoAnotherBusiness(obj));
    if (data?.payload?.data?.status === "success") {
      // console.log(data);'
      const token = data?.payload?.data?.data?.token;
      localStorage?.setItem("token", encryptTokenFunc(token));
      // dispatch(cancelTokenFunc());
      //   actionCtx?.setShowGeneralLoader(false);
      localStorage.removeItem("current_sub_account_id");
      window.location.reload();

      setTimeout(() => {
        dispatch(cancelTokenFunc());
      }, 2000);
    }
  };

  return (
    <div className="create-new-business-index-wrap ">
      <div className="content-wrap card-bg">
        <div className="top-wrap grey-bg">
          <div className="top-content">
            <div
              onClick={() => actionCtx.createBusinessFunc(false)}
              className="close-wrap"
            >
              <figure className="img-box">{closeIcon}</figure>
              <p className="text">Close</p>
            </div>
            {/* step show start */}
            <div className="step-box">
              <span>STEP</span>
              <p>{step}/4</p>
            </div>
            {/* step show send */}
          </div>
          {/* bar content start */}
          <div className="bar-wrap">
            {" "}
            <ProgressBar
              bgcolor={theme === "light" ? `#020202` : "rgba(117, 90, 226, 1)"}
              completed={
                step === 1 ? 25 : step === 2 ? 50 : step === 3 ? 75 : 100
              }
              //   baseColor={theme !== "light" && `#020202`}
            />
          </div>

          {/* bar content end */}
        </div>
        {/* bottom wrap start */}
        <div className="bottom-wrap">
          <div className="content-wrap-box-form">
            {step !== 4 && (
              <div className="title-back-text-box">
                {step > 1 && step !== 4 && (
                  <>
                    {" "}
                    <div
                      onClick={() => {
                        step > 1 && setStep(step - 1);
                      }}
                      className="back-box"
                    >
                      <figure className="img-box">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="img"
                        >
                          <path
                            className="dull-bright-filter"
                            d="M11.25 13.5L6.75 9L11.25 4.5"
                            stroke="#020202"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </figure>
                      <p className="back">Back</p>
                    </div>{" "}
                  </>
                )}
                {step !== 4 && (
                  <>
                    {" "}
                    <p className="title">
                      {step === 1
                        ? `Create a new business`
                        : step === 2
                        ? `Enter business detail.`
                        : "Enter business address"}
                    </p>
                    <p className="text grey-white-color">
                      Add a new business using the same account you are logged
                      in with.
                    </p>
                  </>
                )}
              </div>
            )}
            <div className="form-box">
              {/* step 1 start ------------------------- */}
              {step === 1 && (
                <>
                  {/* form content start */}
                  <div className="form-content">
                    {/* form group start */}
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      <RavenInputField
                        type={`text`}
                        color={`black-light`}
                        label="Business Name*"
                        placeholder="e.g Raven Business"
                        name={`business_name`}
                        value={details?.business_name}
                        onChange={handleChange}
                      />
                    </form>
                    {/* form group end */}
                    {/* form group start */}
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      <RavenInputField
                        type={`email`}
                        color={`black-light`}
                        label="Email Attached to Account* "
                        placeholder="ayeni77@gmail.com"
                        name={`user_email`}
                        value={user?.email}
                        onChange={(e) => {
                          handleChange(e);
                          setError((prev) => {
                            return { ...prev, user_email: false };
                          });
                        }}
                        disabled
                        showError={error?.user_email}
                      />
                    </form>
                    {/* form group end */}
                    {/* form group start */}
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      <RavenInputField
                        type={`email`}
                        color={`black-light`}
                        label="Business Email* "
                        placeholder="ayeni77@gmail.com"
                        name={`business_email`}
                        value={details?.business_email}
                        onChange={(e) => {
                          handleChange(e);
                          setError((prev) => {
                            return { ...prev, business_email: false };
                          });
                        }}
                        showError={error?.business_email}
                      />
                    </form>
                    {/* form group end */}
                    {/* form group start */}
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      <RavenInputField
                        type={`phone`}
                        color={`black-light`}
                        label="Phone Number* "
                        placeholder="0816263...."
                        name={`business_phone`}
                        value={details?.business_phone}
                        onChange={handleChange}
                      />
                    </form>
                    {/* form group end */}
                  </div>
                  {/* form content end */}
                  <div className="submit-btn-wrap">
                    <RavenButton
                      color="black-light"
                      label={`Proceed`}
                      onClick={() => {
                        if (!validateEmail(details.business_email)) {
                          toast.error("Invalid business email address!", {
                            position: "top-right",
                          });
                          setError((prev) => {
                            return { ...prev, business_email: true };
                          });
                          return;
                        } else {
                          handleStep();
                        }
                      }}
                      disabled={
                        !details?.business_name ||
                        !details?.business_phone ||
                        !details?.business_email
                        // !details?.user_email
                      }
                    />
                  </div>
                </>
              )}
              {/* step 1 end --------------------- */}

              {/* step 2 start ------------------------- */}
              {step === 2 && (
                <>
                  {/* form content start */}
                  <div className="form-content">
                    {/* two group start */}
                    <div className="two-group">
                      {/* form group start */}
                      <form
                        action=""
                        autoComplete="off"
                        style={{
                          width: "100%",
                          position: "relative",
                          zIndex: "13",
                        }}
                      >
                        {" "}
                        <RavenInputField
                          type={`select`}
                          label={`Business industry * `}
                          placeholder="E.g Banking"
                          color={`black-light`}
                          name="business_industry"
                          selectStyles={reactSelectStyle}
                          onChange={(e) => {
                            setDetails((prev) => {
                              return { ...prev, business_industry: e };
                            });
                          }}
                          value={details?.business_industry}
                          style={{ position: "relative", zIndex: "13" }}
                          selectOption={business_industries}
                        />
                      </form>
                      {/* form group end */}
                      {/* form group start */}
                      <form
                        action=""
                        autoComplete="off"
                        style={{
                          width: "100%",
                          position: "relative",
                          zIndex: "12",
                        }}
                      >
                        {" "}
                        <RavenInputField
                          type={`select`}
                          label={`No of staff*`}
                          placeholder="E.g 1-10"
                          color={`black-light`}
                          name="number_of_staffs"
                          selectStyles={reactSelectStyle}
                          onChange={(e) => {
                            setDetails((prev) => {
                              return { ...prev, number_of_staffs: e };
                            });
                          }}
                          value={details?.number_of_staffs}
                          style={{ position: "relative", zIndex: "12" }}
                          selectOption={number_of_staff}
                        />
                      </form>
                      {/* form group end */}
                    </div>
                    {/* end of two groups */}
                    {/* form group start */}
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      <RavenInputField
                        type={`select`}
                        label={`Monthly transaction range* `}
                        placeholder="E.g 1,000,000 - 5,000,00"
                        color={`black-light`}
                        name="monthly_transaction_range"
                        selectStyles={reactSelectStyle}
                        onChange={(e) => {
                          setDetails((prev) => {
                            return { ...prev, monthly_transaction_range: e };
                          });
                        }}
                        value={details?.monthly_transaction_range}
                        style={{ position: "relative", zIndex: "11" }}
                        selectOption={monthly_transaction_range}
                      />
                    </form>
                    {/* form group end */}
                    {/* form group start */}
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      <RavenInputField
                        type={`text`}
                        label={`Business website `}
                        placeholder="E.g www.bukolawebsite.com"
                        color={`black-light`}
                        name="business_website"
                        onChange={handleChange}
                        value={details?.business_website}
                        // thousandFormat={true}
                      />
                    </form>
                    {/* form group end */}
                  </div>
                  {/* form content end */}
                  <div className="submit-btn-wrap">
                    <RavenButton
                      color="black-light"
                      label={`Proceed`}
                      onClick={handleStep}
                      disabled={
                        !details?.business_industry ||
                        !details?.number_of_staffs ||
                        !details?.monthly_transaction_range
                        // !details?.business_website
                      }
                    />
                  </div>
                </>
              )}
              {/* step 2 end --------------------- */}
              {/* step 3 start ------------------------- */}
              {step === 3 && (
                <>
                  {/* form content start */}
                  <div className="form-content">
                    {/* two group start */}
                    <div className="two-group">
                      {/* form group start */}
                      <form
                        action=""
                        autoComplete="off"
                        style={{
                          width: "100%",
                          position: "relative",
                          zIndex: "13",
                        }}
                      >
                        {" "}
                        <RavenInputField
                          type={`select`}
                          label={`State * `}
                          placeholder="E.g Lagos"
                          selectStyles={reactSelectStyle}
                          color={`black-light`}
                          name="state"
                          onChange={(e) => {
                            dispatch(getLgaByStates(e?.label));
                            setDetails((prev) => {
                              return { ...prev, state: e, lga: "" };
                            });
                          }}
                          value={details?.state}
                          selectOption={states}
                          style={{ position: "relative", zIndex: "11" }}
                        />
                      </form>
                      {/* form group end */}
                      {/* form group start */}
                      <form
                        action=""
                        autoComplete="off"
                        style={{
                          width: "100%",
                          position: "relative",
                          zIndex: "12",
                        }}
                      >
                        {" "}
                        <RavenInputField
                          type={`select`}
                          label={`Local government *`}
                          selectStyles={reactSelectStyle}
                          placeholder="E.g Eti Osa"
                          color={`black-light`}
                          disabled={!details?.state}
                          name="local_government"
                          onChange={(e) => {
                            setDetails((prev) => {
                              return { ...prev, local_government: e };
                            });
                          }}
                          value={details?.local_government}
                          selectOption={Lgas}
                          style={{ position: "relative", zIndex: "10" }}
                        />
                      </form>
                      {/* form group end */}
                    </div>
                    {/* end of two groups */}
                    {/* form group start */}
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      <RavenInputField
                        type={`text`}
                        label={`Street address * `}
                        placeholder="E.g Layi yusuf street "
                        color={`black-light`}
                        name="address"
                        onChange={handleChange}
                        value={details?.address}
                        // thousandFormat={true}
                      />
                    </form>
                    {/* form group end */}
                    {/* form group start */}
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      <RavenInputField
                        type={`text`}
                        label={`Closest landmark *`}
                        placeholder="E.g a church near you"
                        color={`black-light`}
                        name="closest_landmark"
                        onChange={handleChange}
                        value={details?.closest_landmark}
                        // thousandFormat={true}
                      />
                    </form>
                    {/* form group end */}
                  </div>
                  {/* form content end */}
                  <div className="submit-btn-wrap">
                    <RavenButton
                      color="black-light"
                      label={`Proceed`}
                      onClick={handleStep}
                      loading={loadingNotify}
                      disabled={
                        !details?.state ||
                        !details?.local_government ||
                        !details?.address ||
                        !details?.closest_landmark
                      }
                    />
                  </div>
                </>
              )}
              {/* step 3 end --------------------- */}
              {/* step 4 start ------------------------- */}
              {step === 4 && (
                <>
                  {/* form content start */}
                  <div className="form-content">
                    <div className="img-business-wrap"></div>
                    <p className="small-title">Welcome to Raven</p>
                    <p className="big-title">
                      !
                      {formatTypeFunction(
                        newBusiness?.business_name || "---"
                      ) || "---"}
                    </p>
                    <p className="small-create-text grey-white-color">
                      We are glad to have you, with us here at Raven, before
                      proceeding here are few things you need to know.{" "}
                    </p>
                    {/* detail list start */}
                    <div className="detail-list-box">
                      {detailList?.map((chi, idx) => {
                        return (
                          <div key={idx} className="detail-item">
                            <div className="square">
                              <span></span>
                            </div>
                            <p className="text">{chi || ""}</p>
                          </div>
                        );
                      })}
                    </div>
                    {/* detail list end */}
                  </div>
                  {/* form content end */}
                  <div className="submit-btn-wrap">
                    <RavenButton
                      color="black-light"
                      label={`Take me to my dashboard`}
                      onClick={() => {
                        handleSwitchAccount(newBusiness?.id);
                      }}
                      loading={loadLogout}
                    />
                  </div>
                </>
              )}
              {/* step 4 end --------------------- */}
            </div>
          </div>
        </div>
        {/* bottom wrap end */}
      </div>
    </div>
  );
};

export default CreateBusinessWrapIndex;
