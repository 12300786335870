/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// import axios, { AxiosError, AxiosResponse } from "axios";
import instance from "../utils/axios";
// import { logoutUser } from "../../redux/user";
// import { useDispatch } from "react-redux";
// import { toast } from "@ravenpay/raven-bank-ui";
// import { logoutUser } from "../redux/user";
import ActionContext from "../context/ActionContext";
// import { decrypt, encrypt } from "../utils/EncryptAndDecrypt";

const PrivateRouteDashboard = () => {
  const location = useLocation();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const urlSave = location.pathname.split("/")[1];
  const actionCtx = useContext(ActionContext);

  let isLoggingOut = false; // global

 
  instance.interceptors.response.use(
    async (response) => {
      // console.log(response);
      if (
        response?.response?.data?.message === "bearer token cannot be empty" &&
        response?.response?.data?.status === "fail"
      ) {
        actionCtx?.setIsLogout(true);
      }
      if (
        response?.data?.message === "Authentication failed" ||
        response?.data?.data === "Authentication failed"
      ) {
        // console.log("No");
        actionCtx?.setIsLogout(true);
        return;
      }
      if (response?.response?.status === 401) {
        // console.log(response);
        if (!isLoggingOut) {
          isLoggingOut = true; // disallow re-entrant calls
          try {
            if (
              response?.response?.status === 401 ||
              response?.response?.data?.message === "token_expired"
            ) {
              actionCtx?.setIsLogout(true);
            }
            await instance.delete("auth/sign-out");
          } catch (deletionError) {
            // throw errors away
          } finally {
            // clearLocalStorage();
            isLoggingOut = false;
            // isLoggedIn = false; // if the variable is assignable
          }
        }
        return;
      }
      // const decrypted = await decrypt(response?.data);
      // // console.log(response.data);
      // // console.log(response.data.data);
      // console.log(decrypted);
      // // const newObj = { ...decrypted, data: decrypted };
      // // console.log(newObj);
      return response;
    },
    async (error) => {
      // console.log(error);

      if (
        error?.response?.data?.message === "Network Error" ||
        error?.message === "Network Error"
      ) {
      }
      if (error?.response?.data?.status === "fail") {
        //   toast.error(error?.response?.data?.message, {});
      }
      if (error?.response?.status === 401) {
        // console.log(error);
        if (!isLoggingOut) {
          isLoggingOut = true; // disallow re-entrant calls
          try {
            if (
              error?.response?.status === 401 ||
              error?.response?.data?.message === "token_expired"
            ) {
              actionCtx?.setIsLogout(true);
            }
            await instance.delete("auth/sign-out");
          } catch (deletionError) {
            // throw errors away
          } finally {
            // clearLocalStorage();
            isLoggingOut = false;
            // isLoggedIn = false; // if the variable is assignable
          }
        }
        return;
      }
      return error;
    }
  );

  const authenticated = localStorage.getItem("token")  ?? false;
  return authenticated ? (
    <Outlet />
  ) : (
    window.location.replace(`/login?${urlSave}`)
  );
};

export default PrivateRouteDashboard;
