import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { createCashiers } from "../../../../redux/pos";
import "../../../../styles/dashboard/pos/DashboardPosIndex.css";
import { formatNumWithoutCommaNaira } from "../../../../utils/helper/Helper";

const AssignCashierModal = ({ visible, onClose, onFinish }) => {
  // const dispatch = useDispatch();
  // const { loadingSettlement } = useSelector((state) => state.pos);
  const [details, setDetails] = useState({
    token: "YB80kf6fdYabM91tk05lj6fwllezKo",
    cashier_phone: "",
    cashier_name: "",
    cashier_pin: "",
    pin: "1111",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async () => {
    const obj = {
      cashier_phone: formatNumWithoutCommaNaira(details?.cashier_phone),
      cashier_name: details?.cashier_name,
      cashier_pin: details?.cashier_pin,
    };
    onFinish(obj);
  };

  return (
    <RavenModal
      // loading={loadingSettlement}
      onBtnClick={handleSubmit}
      visble={visible}
      onClose={() => {
        onClose();
        setDetails({
          cashier_phone: "",
          cashier_name: "",
          cashier_pin: "",
        });
      }}
      btnColor={`black-light`}
      btnLabel={`Save information`}
      disabled={
        !details?.cashier_name ||
        !details?.cashier_phone ||
        !details?.cashier_pin
      }
    >
      <div className="cashier-assign-modal-wrap">
        <p className="title">Save information</p>
        <p className="text grey-white-color">
          Add a cashier to this POS Terminal, cashiers serves as admins to each
          POS terminals assigned.
        </p>
        <div className="underline"></div>
        {/* form wrap start */}
        <div className="form-inputs-wrap">
          <RavenInputField
            color={`black-light`}
            type={`text`}
            label={`Cashier Name* `}
            placeholder={`E.g Timi Ayeni`}
            name={`cashier_name`}
            onChange={handleChange}
            value={details?.cashier_name}
            capitalize
          />
          <RavenInputField
            color={`black-light`}
            type={`phone`}
            label={`Cashier Phone number* `}
            placeholder={`E.g 0812.....`}
            name={`cashier_phone`}
            onChange={handleChange}
            value={
              details?.cashier_phone.slice(0, 3) === "234"
                ? details?.cashier_phone.replace("234", "0")
                : details?.cashier_phone
            }
          />
          <RavenInputField
            color={`black-light`}
            type={`number`}
            label={`Cashier PIN* `}
            placeholder={`6 digit PIN`}
            name={`cashier_pin`}
            onChange={handleChange}
            value={details?.cashier_pin}
          />
        </div>
        {/* form wrap end */}
      </div>
    </RavenModal>
  );
};

export default AssignCashierModal;
