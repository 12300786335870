import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
// import { updateBusinessProfile } from "../../redux/settings";
import { getUser } from "../../redux/user";
// import { validateUniqueEmail } from "../../../../redux/user";
import "../../styles/dashboard/pos/DashboardPosIndex.css";
import { removeCountryCode, removeSpace } from "../../utils/helper/Helper";
// import { formatNumWithoutCommaNaira } from "../../utils/Helpers";
import { intiateBvnVerification } from "../../redux/verifications";
import VerificationSDK from "@ravenpay/verify-sdk";
import ActionContext from "../../context/ActionContext";
import { useSelector } from "react-redux";

const BvnIntiateModal = ({ visible, onClose, }) => {
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { loading } = useSelector((state) => state.verifications);
  const [details, setDetails] = useState({
    bvn: "",
  });

  //   useEffect(() => {
  //     // console.log(user);
  //     setDetails((prev) => {
  //       return {
  //         ...prev,
  //         first_name: user?.fname,
  //         last_name: user?.lname,
  //         email: user?.email,
  //       };
  //     });
  //   }, [user]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    showError && setShowError(false);
  };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // if (!validateEmail(details.email)) {
//     //   toast.error("Invalid email address!", {
//     //     position: "top-right",
//     //   });
//     //   setShowError((prev) => {
//     //     return { ...prev, email: true };
//     //   });
//     //   return;
//     // }
//     const obj = {
//       phone: `0${formatNumWithoutCommaNaira(removeCountryCode(details?.bvn))}`,
//     };
//     // console.log(obj);
//     // return
//     const data = await dispatch(updateBusinessProfile(obj));
//     if (data?.payload?.data?.status === "success") {
//       dispatch(getUser());
//       onClose();
//       setDetails({
//         bvn: "",
//       });
//     }
//   };
  const [showError, setShowError] = useState(false);
  const [loadingSdk, setLoadingSdk] = useState(false);
  const handleIntiateBvnVerification = async () => {
    const obj = {
        identity_number: removeSpace(details?.bvn),
    };
    const data = await dispatch(intiateBvnVerification(obj));
    if (data?.payload?.data?.status === "success") {
      setLoadingSdk(true);
      dispatch(getUser());
      const url = data?.payload?.data?.data?.url;
      const extractRef = url?.split("reference=")[1];
      const refValue = extractRef?.replaceAll("%", "");
      // console.log(extractRef);
      // console.log(refValue);
      // setInitiateObj(refValue);
      if (refValue) {
        VerificationSDK.init("bvn", {
          mobileCallBackURL:
            "https://businessbanking.getraventest.com/dashboard-verification-bvn?intent=mobile",
          reference: refValue,
          bvnOrNINValue: removeSpace(details?.bvn),
          style: {
            color: "black-light",
          },
        });
      }

      // window.open(url, "_blank").focus();
      // "https://signup.getmati.com/?merchantToken=61e6d21822dcd6001cffdcaa&flowId=646073d1040f5e001c9b3874&metadata=%7B%22reference%22%3A%220015409156188772%22%7D"
    }
    if (
        data?.payload?.response?.status === 400 &&
        data?.payload?.response?.data?.message ===
          "Provided BVN is currently blacklisted, please contact support for more details"
      ) {
        //   console.log(
        //     "rovided NIN is currently blacklisted, please contact support for more details"
        //   );
        setShowError(true);
      }
  };

  VerificationSDK.onLoad = () => {
    setLoadingSdk(false);
    onClose();
  };

  VerificationSDK.onSupport = () => {
    // window.Intercom("show");
    window.fcWidget.open();
  };

  VerificationSDK.onClose= () => {
    onClose();
    dispatch(getUser());
  };

  return (
    <RavenModal
      visble={visible}
      onClose={() => {
        setDetails((prev) => {
          return {...prev, bvn: ""}
        })
        onClose()
      }}
      btnColor={`black-light`}
      btnLabel={`Submit BVN details`}
      loading={loading || loadingSdk}
      disabled={!details?.bvn}
      //   onBtnClick={handleSubmit}
      onBtnClick={() => {
        actionCtx.setVericationBtn("bvn", true);

        handleIntiateBvnVerification();
        setTimeout(() => {
          actionCtx.setVericationBtn("bvn", false);
        }, 300000);
      }}
    >
      <div className="cashier-assign-modal-wrap">
        <p className="title">BVN Details</p>
        <p className="text grey-white-color">
          Please input BVN details to initiate process
        </p>
        <div className="underline"></div>
        {/* form wrap start */}
        <div style={{ minHeight: "unset" }} className="form-inputs-wrap">
          <RavenInputField
            color={`black-light`}
            type={`account-number`}
            label={`BVN number`}
            placeholder={`Enter your  BVN number`}
            maxLength={11}
            onChange={handleChange}
            name={`bvn`}
            showError={showError}
            value={removeCountryCode(details?.bvn)}
          />
        </div>
        {/* form wrap end */}
      </div>
    </RavenModal>
  );
};

export default BvnIntiateModal;
