import React, { useEffect, useState } from "react";
import TierOneAddressIndex from "../TierOneIndex";
import { RavenButton, RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import {
  capitalizeFirstLetter,
  formatMetaData,
  hasTimePassed,
  reactSelectStyle,
  trimLongString,
} from "../../../../../utils/helper/Helper";
import {
  changeVerificationStatus,
  getLgaByStates,
  getStates,
  verifyAddressInformation,
} from "../../../../../redux/verifications";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "../../../../../redux/user";
import SuccessModal from "../../../../../components/reusables/SuccessModal";
import ReUsuableStatus from "../../ReUsuableStatus";
import { useNavigate } from "react-router-dom";
import failedImg from "../../../../../assets/cac-failed-img.png";
import successImgAddress from "../../../../../assets/verified-success-one.png";
import pendingImgCac from "../../../../../assets/cac-pending-img.png"

const AdressVerificationFive = () => {
  const [editState, setEditState] = useState(false);
  const editIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        fill="#676767"
      />
      <path
        className="dull-bright-filter"
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        fill="#676767"
      />
    </svg>
  );
  const homeIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.449 6.87641C2.16761 7.0667 2.00049 7.37474 2.00049 7.7031V16.4783C2.00049 17.3187 2.71683 17.9999 3.60049 17.9999H16.4005C17.2841 17.9999 18.0005 17.3187 18.0005 16.4783V7.7031C18.0005 7.37474 17.8334 7.0667 17.552 6.87641L10.6186 2.18765C10.2486 1.93737 9.75242 1.93737 9.38233 2.18765L2.449 6.87641ZM6.00114 13.6666C5.5409 13.6666 5.16781 14.0397 5.16781 14.4999C5.16781 14.9602 5.5409 15.3333 6.00114 15.3333H14.0011C14.4614 15.3333 14.8345 14.9602 14.8345 14.4999C14.8345 14.0397 14.4614 13.6666 14.0011 13.6666H6.00114Z"
        fill="#676767"
      />
    </svg>
  );

  const pendImg = (
    <svg
      className="img"
      width="90"
      height="91"
      viewBox="0 0 90 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_19492_680)">
        <path d="M21 67.36H69V19.36H21V67.36Z" fill="#676767" />
      </g>
      <defs>
        <filter
          id="filter0_d_19492_680"
          x="0.299999"
          y="0.659985"
          width="89.4"
          height="89.4"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10.35" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_19492_680"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_19492_680"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
  const navigate = useNavigate();
  const { business } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { loading, states, Lgas } = useSelector((state) => state.verifications);
  const [details, setDetails] = useState({
    country: "Nigeria",
    state: "",
    lga: "",
    street_address: "",
    file: "",
    business_address: "",
    address_document_type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  useEffect(() => {
    dispatch(getStates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const obj = {
      country: details?.country,
      state: !editState ? business?.state : details?.state?.value,
      lga: !editState ? business?.lga : details?.lga?.value,
      street_address: !editState
        ? business?.business_address
        : details?.business_address,
      address_document_type: details?.address_document_type?.value,
      file: details?.file,
    };
    // console.log(obj);
    const formData = new FormData();
    formData.append("file", obj?.file);
    formData.append("street_address", obj?.street_address);
    formData.append("state", obj?.state);
    formData.append("lga", obj?.lga);
    formData.append("country", obj?.country);
    formData.append("address_document_type", obj?.address_document_type);

    const data = await dispatch(verifyAddressInformation(formData));
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
      setDetails({
        country: "Nigeria",
        state: "",
        lga: "",
        street_address: "",
        file: "",
        business_address: "",
        address_document_type: "",
      });
      setShowModal(true);
    }
  };

  const successImg = (
    <svg
      className="img"
      width="118"
      height="105"
      viewBox="0 0 118 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.448 40.5707C12.7878 41.6934 11.8018 43.5109 11.8018 45.4482V97.2221C11.8018 102.18 16.0282 106.2 21.2418 106.2H96.7617C101.975 106.2 106.202 102.18 106.202 97.2221V45.4482C106.202 43.5109 105.216 41.6934 103.556 40.5707L62.6489 12.907C60.4654 11.4304 57.5382 11.4304 55.3546 12.907L14.448 40.5707ZM35.4056 80.6329C32.6902 80.6329 30.4889 82.8341 30.4889 85.5495C30.4889 88.2649 32.6902 90.4662 35.4056 90.4662H82.6056C85.321 90.4662 87.5223 88.2649 87.5223 85.5495C87.5223 82.8341 85.321 80.6329 82.6056 80.6329H35.4056Z"
        fill="url(#paint0_linear_19488_34365)"
      />
      <path
        d="M13.3018 45.4482C13.3018 44.0291 14.0236 42.6685 15.2882 41.8132L56.1949 14.1496L55.3546 12.907L56.1949 14.1496C57.8708 13.0162 60.1327 13.0162 61.8086 14.1496L62.6489 12.907L61.8086 14.1496L102.715 41.8132C103.98 42.6685 104.702 44.0291 104.702 45.4482V97.2221C104.702 101.282 101.219 104.7 96.7617 104.7H21.2418C16.7847 104.7 13.3018 101.282 13.3018 97.2221V45.4482ZM35.4056 79.1329C31.8618 79.1329 28.9889 82.0057 28.9889 85.5495C28.9889 89.0934 31.8618 91.9662 35.4056 91.9662H82.6056C86.1494 91.9662 89.0223 89.0934 89.0223 85.5495C89.0223 82.0057 86.1494 79.1329 82.6056 79.1329H35.4056Z"
        stroke="black"
        stroke-opacity="0.05"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_19488_34365"
          x1="59.0018"
          y1="11.7995"
          x2="59.0018"
          y2="106.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
      </defs>
    </svg>
  );

  const uploadTypeOption = [
    { label: "Utility bill", value: "utility_bills" },
    { label: "Teenancy agreement", value: "tenancy_agreement" },
    { label: "Bank Statement", value: "commercial_bank_statement" },
    {
      label: "Mortgage / Lease Agreement",
      value: "mortgage_or_lease_agreement",
    },
  ];

  const [statusCheck, setStatusCheck] = useState(0);
  useEffect(() => {
    if (Object.keys(business).length > 0) {
      setStatusCheck(Number(business?.address));
    }
    if (Number(business?.nin) !== 3) {
      navigate("/dashboard-verification");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  const handleRetryFunction = async () => {
    const obj = {
      type: "address",
    };
    const data = await dispatch(changeVerificationStatus(obj));
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
    }
  };

  if (String(statusCheck) === "1") {
    return (
      <TierOneAddressIndex stage={90}>
        <ReUsuableStatus
          img={pendImg}
          text={`We are currently verifying your address you can continue to complete other part of your verification you would be notified once completed.`}
          title={`Address Verification Pending`}
          imgBg={`rgba(247, 247, 247, 1)`}
          imgSrc={pendingImgCac}
        />
        {hasTimePassed(
          formatMetaData(business?.verification_time)?.address,
          30
        ) && (
          <div
            style={{ minHeight: "unset", width: " 100%", cursor: "pointer" }}
            className="form-inputs-wrap"
          >
            {" "}
            <RavenButton
              // type={`submit`}

              color="black-light"
              label={`Retry Verification`}
              style={{
                backgroundColor: "#ffffff",
                border: ".1rem solid #020202",
                color: "#020202",
                width: "100%",
                cursor: "pointer",
              }}
              loading={loading}
              onClick={handleRetryFunction}
              className="btn-submit"
            />
          </div>
        )}
      </TierOneAddressIndex>
    );
  }

  const failedIcon = (
    <svg
      className="img"
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.678 23.1388L18.328 56.6465C15.9391 60.7901 18.9252 65.9632 23.7113 65.9632H62.4054C67.1903 65.9632 70.18 60.7841 67.7887 56.6465L48.4387 23.1388C46.0498 18.9917 40.0704 18.9917 37.678 23.1388Z"
        fill="url(#paint0_linear_20124_9100)"
      />
      <path
        d="M62.4052 66.0671H23.7147C22.6066 66.0671 21.518 65.7753 20.5583 65.2213C19.5986 64.6672 18.8016 63.8703 18.2475 62.9106C17.6933 61.951 17.4015 60.8624 17.4014 59.7542C17.4012 58.6461 17.6927 57.5574 18.2466 56.5976L37.5966 23.0898C38.1505 22.1299 38.9475 21.3327 39.9072 20.7784C40.867 20.2242 41.9558 19.9324 43.0641 19.9324C44.1725 19.9324 45.2613 20.2242 46.221 20.7784C47.1808 21.3327 47.9778 22.1299 48.5317 23.0898L67.8817 56.5976C68.4356 57.5574 68.7271 58.6461 68.7269 59.7542C68.7268 60.8624 68.4349 61.951 67.8808 62.9106C67.3266 63.8703 66.5297 64.6672 65.57 65.2213C64.6103 65.7753 63.5217 66.0671 62.4135 66.0671H62.4052ZM43.0552 20.13C41.9803 20.1237 40.9231 20.4036 39.9921 20.941C39.0611 21.4783 38.29 22.2538 37.7578 23.1878L18.4078 56.6955C17.8711 57.6254 17.5886 58.6801 17.5887 59.7538C17.5888 60.8274 17.8714 61.8821 18.4083 62.8119C18.9451 63.7417 19.7172 64.5138 20.647 65.0506C21.5768 65.5874 22.6315 65.87 23.7052 65.8701H62.4052C63.4788 65.87 64.5335 65.5874 65.4633 65.0506C66.3931 64.5138 67.1652 63.7417 67.7021 62.8119C68.2389 61.8821 68.5216 60.8274 68.5217 59.7538C68.5218 58.6801 68.2393 57.6254 67.7025 56.6955L48.3525 23.1878C47.8208 22.2545 47.0504 21.4795 46.1203 20.9422C45.1903 20.4048 44.1341 20.1245 43.06 20.13H43.0552Z"
        fill="url(#paint1_linear_20124_9100)"
      />
      <path
        d="M46.3971 34.3546C46.1451 38.7 45.8883 43.1111 45.6279 47.5269C45.5932 48.1349 45.6195 48.7536 45.5192 49.3508C45.4228 49.917 45.1288 50.4306 44.6895 50.8004C44.2501 51.1702 43.6938 51.3723 43.1196 51.3706C41.8964 51.3814 40.8322 50.5692 40.6948 49.3281C40.5037 47.6141 40.4452 45.8846 40.3365 44.1622C40.1311 40.8619 39.9495 37.5593 39.7226 34.2614C39.5553 31.8367 41.2944 29.9614 43.5197 30.223C44.2678 30.3157 44.9613 30.6631 45.4833 31.207C46.0054 31.7508 46.3243 32.4578 46.3864 33.2091C46.421 33.5639 46.3971 33.9222 46.3971 34.3546Z"
        fill="white"
      />
      <path
        d="M39.7751 56.9344C39.7916 56.0632 40.1482 55.233 40.7687 54.6212C41.3891 54.0094 42.2243 53.6646 43.0956 53.6604C44.9315 53.6604 46.3553 55.1535 46.3397 57.0526C46.33 57.9136 45.9816 58.7361 45.37 59.3421C44.7584 59.9481 43.9327 60.2889 43.0717 60.2908C42.6348 60.2872 42.2028 60.1975 41.8006 60.0268C41.3983 59.8561 41.0337 59.6078 40.7275 59.2961C40.4213 58.9843 40.1796 58.6153 40.0162 58.21C39.8527 57.8048 39.7708 57.3713 39.7751 56.9344Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20124_9100"
          x1="43.0588"
          y1="20.0284"
          x2="43.0588"
          y2="65.9632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20124_9100"
          x1="43.0641"
          y1="19.9324"
          x2="43.0641"
          y2="66.0671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
      </defs>
    </svg>
  );

  if (String(statusCheck) === "2") {
    return (
      <TierOneAddressIndex stage={30}>
        <ReUsuableStatus
          text={`We tried verifying your Address but we noticed some errors, kindly retry this verification to continue.`}
          title={`Address Verification Failed`}
          imgBg={`rgba(255, 246, 237, 1)`}
          img={failedIcon}
          imgSrc={failedImg}
        />
        <div
          style={{ minHeight: "unset", width: " 100%" }}
          className="form-inputs-wrap"
        >
          {" "}
          <RavenButton
            // type={`submit`}

            color="black-light"
            label={`Retry Verification`}
            style={{
              backgroundColor: "#ffffff",
              border: ".1rem solid #020202",
              color: "#020202",
              width: "100%",
            }}
            loading={loading}
            onClick={handleRetryFunction}
            className="btn-submit"
          />
        </div>
      </TierOneAddressIndex>
    );
  }

  if (String(statusCheck) === "5") {
    return (
      <TierOneAddressIndex stage={30}>
        <ReUsuableStatus
          text={`We tried verifying your Address but we noticed some errors, kindly retry this verification to continue.`}
          title={`Address Verification Revoked`}
          imgBg={`rgba(255, 246, 237, 1)`}
          img={failedIcon}
          imgSrc={failedImg}
        />
        <div
          style={{ minHeight: "unset", width: " 100%" }}
          className="form-inputs-wrap"
        >
          {" "}
          <RavenButton
            // type={`submit`}

            color="black-light"
            label={`Retry Verification`}
            style={{
              backgroundColor: "#ffffff",
              border: ".1rem solid #020202",
              color: "#020202",
              width: "100%",
            }}
            loading={loading}
            onClick={handleRetryFunction}
            className="btn-submit"
          />
        </div>
      </TierOneAddressIndex>
    );
  }

  if (String(statusCheck) === "3") {
    return (
      <TierOneAddressIndex stage={100}>
        <ReUsuableStatus
          text={`Thanks for taking your time to verify your BVN profile, we are confident about your details, and your limit has been increased successfully.`}
          title={`Address Verified Successfully`}
          imgBg={`rgba(255, 246, 237, 1)`}
          img={successImg}
          // bottomImg={true}
          imgSrc={successImgAddress}
        />
        {String(business?.cac) !== "3" && (
          <div
            style={{ minHeight: "unset", marginTop: "1.5rem" }}
            className="form-inputs-wrap"
          >
            {" "}
            <RavenInputField
              type={`submit`}
              color="black-light"
              value={`Proceed`}
              onSubmit={() => {
                navigate("/dashboard-verification-cac-new");
              }}
              className="btn-submit"
              // loading={loading}
            />
          </div>
        )}
      </TierOneAddressIndex>
    );
  }

  return (
    <>
      <TierOneAddressIndex stage={85}>
        <div
          onClick={() => {
            // console.log(user);
            // console.log(business);
          }}
          className="form-wrap"
        >
          <div className="form-title-text">
            <p className="title">Confirm Address </p>
            <p className="text grey-white-color">
              This address would need to be verified, before your account limit
              can be increased.
            </p>
          </div>
          {/* edit and not edit state box start */}
          {!editState ? (
            <>
              <div className="profile-address-state-box border-theme">
                {/* value-label-box start */}
                <div className="value-label-box border-theme-bottom">
                  <p className="label grey-white-color grey-bg">Adress</p>
                  <p className="value grey-white-color">
                    {trimLongString(
                      capitalizeFirstLetter(business?.business_address),
                      30
                    ) || ""}
                  </p>
                </div>
                {/* value-label-box end */}
                {/* value-label-box start */}
                <div className="value-label-box border-theme-bottom">
                  <p className="label grey-white-color grey-bg">
                    {business?.state || ""}
                  </p>
                  <p className="value grey-white-color">Lagos</p>
                </div>
                {/* value-label-box end */}
                {/* value-label-box start */}
                <div className="value-label-box">
                  <p className="label grey-white-color grey-bg">LGA</p>
                  <p className="value grey-white-color">
                    {business?.lga || ""}
                  </p>
                </div>
                {/* value-label-box end */}
              </div>
            </>
          ) : (
            <>
              <div className="two-input-group">
                <RavenInputField
                  type={`select`}
                  label={`State * `}
                  placeholder="E.g Lagos"
                  selectStyles={reactSelectStyle}
                  color={`black-light`}
                  name="state"
                  onChange={(e) => {
                    dispatch(getLgaByStates(e?.label));
                    setDetails((prev) => {
                      return { ...prev, state: e, lga: "" };
                    });
                  }}
                  value={details?.state}
                  selectOption={states}
                  style={{ position: "relative", zIndex: "11" }}
                />
                <RavenInputField
                  type={`select`}
                  label={`Local government *`}
                  selectStyles={reactSelectStyle}
                  placeholder="E.g Eti Osa"
                  color={`black-light`}
                  name="lga"
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, lga: e };
                    });
                  }}
                  value={details?.lga}
                  selectOption={Lgas}
                  style={{ position: "relative", zIndex: "10" }}
                />
              </div>
              <div style={{ minHeight: "unset" }} className="form-inputs-wrap">
                <RavenInputField
                  color={`black-light`}
                  type={`text`}
                  label={`Business address * `}
                  placeholder={`e.g Lagos, Nigeria.`}
                  // maxLength={11}
                  capitalize
                  onChange={handleChange}
                  name={`business_address`}
                  value={details?.business_address}
                />
              </div>
            </>
          )}
          <div
            onClick={() => {
              setEditState(!editState);
            }}
            className="change-edit-state-box"
          >
            {!editState ? (
              <>
                <div className="icon-box">
                  <figure className="img-box">{editIcon}</figure>
                </div>
                <p className="text">Edit Address</p>
              </>
            ) : (
              <>
                {" "}
                <div className="icon-box">
                  <figure className="img-box">{homeIcon}</figure>
                </div>
                <p className="text">Use your registered address</p>
              </>
            )}
          </div>
          {/* edit and not edit state box end */}
          <div
            style={{
              minHeight: "unset",
              marginBottom: "1.5rem",
              padding: "0rem .1rem",
            }}
            className="form-inputs-wrap"
          >
            {" "}
            <RavenInputField
              type={`select`}
              label={`Select Document type* `}
              selectStyles={reactSelectStyle}
              placeholder="E.g Eti Osa"
              color={`black-light`}
              name="address_document_type"
              onChange={(e) => {
                setDetails((prev) => {
                  return { ...prev, address_document_type: e };
                });
              }}
              selectOption={uploadTypeOption}
              value={details?.address_document_type}
              // selectOption={Lgas}
              style={{ position: "relative", zIndex: "8" }}
            />
          </div>
          <RavenInputField
            label={`Upload a utility bill showing the above address*`}
            type={`upload`}
            color={`black-light`}
            maxSize={5.1}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, file: e };
              });
            }}
            onRemoveFile={(e) => {
              setDetails((prev) => {
                return { ...prev, file: "" };
              });
            }}
            onSizeError={() => {
              toast.error("File size cannot exceed 5MB", {});
            }}
          />{" "}
          <div
            style={{ minHeight: "unset", marginTop: "1.5rem" }}
            className="form-inputs-wrap"
          >
            {" "}
            <RavenInputField
              type={`submit`}
              color="black-light"
              value={`Verify and proceed`}
              onSubmit={handleSubmit}
              disabled={
                editState
                  ? !details?.address_document_type ||
                    !details.file ||
                    !details?.business_address ||
                    !details?.lga ||
                    !details?.state
                  : !editState
                  ? !details?.address_document_type || !details.file
                  : false
              }
              className="btn-submit"
              loading={loading}
            />
          </div>
        </div>
      </TierOneAddressIndex>
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        title="Address Verification Successful."
        text={`Address verification is currently in progress. This process can take up to 48 hours. Your banking limit will be increased once verification is confirmed`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal(false);
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default AdressVerificationFive;
