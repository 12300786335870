import React, { useContext } from "react";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import { useSelector } from "react-redux";
// import AccountDropDownBox from "../../../components/fragments/AccountDropDownBox";
import {
  formatMetaData,
  formatNumWithCommaNaira,
} from "../../../utils/helper/Helper";
// import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import closeEye from "../../../assets/close-eye-amount-icon.svg";
import openEye from "../../../assets/open-eye-amount-icon.svg";
import ActionContext from "../../../context/ActionContext";
import { useNavigate } from "react-router-dom";

const sendIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="img"
  >
    <path
      d="M1.42322 10.4182L10.754 1.08748M10.754 1.08748V10.4182M10.754 1.08748H1.42322"
      stroke="white"
      strokeWidth="1.67953"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const addIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="img"
  >
    <path
      d="M10.8545 1.0874L1.52376 10.4181M1.52376 10.4181V1.0874M1.52376 10.4181H10.8545"
      stroke="#020202"
      strokeWidth="1.67953"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const OldTopBalance = () => {
  const navigate = useNavigate();
  const actionCtx = useContext(ActionContext);
  const { user , all_account} = useSelector((state) => state.user);
  //   const [showAccountDrop, setShowAccountDrop] = useState(false);
  return (
    <>
      {/* balance top box start */}
      <div
        style={{ backgroundImage: "unset" }}
        className={`balance-top-box black-fade-white-bg ${
          formatMetaData(user?.theme_display_settings)?.dashboard_overview ===
            "purple" && "balance-top-box-purple"
        }`}
      >
        {/* .toggle text box start */}
        <div className="toggle-text-box">
          <p className="text">Available Balance</p>
          <figure
            onClick={() => {
              actionCtx?.setShowAmount(!actionCtx?.showAmount);
            }}
            className="img-box"
          >
            <img
              src={actionCtx?.showAmount ? closeEye : openEye}
              alt=""
              className="img"
            />
          </figure>
        </div>
        {/* .toggle text box end */}
        {/* amount value box start */}
        <div className="amount-value-box">
          {actionCtx?.showAmount && <sup>₦</sup>}
          <p className="amount">
            {actionCtx?.showAmount
              ? formatNumWithCommaNaira(
                  actionCtx?.currentAccount?.balance || "0.00"
                )
              : "********"}
          </p>
        </div>
        {/* amount value box end */}
        {/* btn wrap box start */}
        <div className="btn-wrap-box">
          <RavenButton
            className="btn-one"
            color={`white-light`}
            // label="Add Money"
            onClick={() => {
              if (all_account?.length > 0) {
                navigate("/dashboard-add-money?dashboard-overview");
              }
            }}
            disabled={all_account?.length < 1}
          >
            <span>Add </span>
            <figure className="img-box">{addIcon}</figure>{" "}
          </RavenButton>
          <RavenButton
            className="btn-two"
            color={`purple-light`}
            onClick={() => {
              if (
                actionCtx?.currentAccount?.authorization_level !==
                ("initiator_approver" || "initiator") &&    actionCtx?.currentAccount?.authorization_level !==
                ("initiator" || "initiator_approver" )
              ) {
                return;
              }
              navigate("/dashboard-send-money?dashboard-overview");
            }}
            disabled={
              actionCtx?.currentAccount?.authorization_level !==
              ("initiator_approver" || "initiator") &&    actionCtx?.currentAccount?.authorization_level !==
              ("initiator" || "initiator_approver" )
            }
          >
            <span>Send</span>
            <figure className="img-box">
              {/* <img src={sendIcon} alt="" className="img" /> */}
              {sendIcon}
            </figure>{" "}
          </RavenButton>
        </div>
        {/* btn wrap box end */}
      </div>
      {/* balance top box end */}
    </>
  );
};

export default OldTopBalance;
