/* eslint-disable no-lone-blocks */
import React, {
  // Component,
  Fragment,
  useContext,
  useEffect,
  // useEffect,
  useState,
} from "react";
import "../../styles/dashboard/DashboardLayout.css";
import SideMenu from "./SideMenu";
import { darkTheme, GlobalStyles, lightTheme } from "../../utils/themes/theme";
import { useDarkMode } from "../../utils/themes/useDarkMode";
import { ThemeProvider } from "styled-components";
import HeaderNav from "./HeaderNav";
// import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";
// import { toggleSidebar } from "../../redux/home";
import { RavenActionBar } from "@ravenpay/raven-bank-ui";
import ActionContext from "../../context/ActionContext";
import SetTransactionPinModal from "../../components/reusables/SetTransactionPinModal";
import { useLocation, useNavigate } from "react-router-dom";
import DeactivatePayroll from "../../pages/dashboard/payroll/modals/DeactivatePayroll";
// import { getPayrollSetup } from "../../redux/payroll";
// import Intercom from 'intercom-client';
// import confettiSound from "../../assets/test-sound.wav";
import UpdateTransactionPinModal from "../../components/reusables/UpdateTransactionPinModal";
import SessionEndModal from "./SessionEndModal";
// import ManageTransactionLimitModal from "../../pages/dashboard/profile/ManageTransactionLimitModal";
import waveSound from "../../assets/raven_success_sound.wav";
import NewSideMenu from "./NewSideMenu";
import {
  formatMetaData,
  // getSystemThemePreference,
  // getPrimaryAccount,
  SPECIAL_ROLE_NAME,
} from "../../utils/helper/Helper";
import EditPhoneNumberModal from "../../components/reusables/EditPhoneNumberModal";
import { IdleTimerProvider } from "react-idle-timer";
// import AccountDropModal from "./AccountDropModal";
// import { io } from "socket.io-client";
// import env from "../../env";
import { useDispatch } from "react-redux";
import { getAllAccounts, getAllNotification } from "../../redux/user";
// import { getTransactionsTwo } from "../../redux/transaction";
import SwitchBusinessAccountModal from "./SwitchBusinessAccountModal";
import NotificationModal from "../../components/reusables/NotificationModal";
import BlackoutLogoModal from "../../components/fragments/BlackoutLogoModal";
import CreateBusinessWrapIndex from "../../pages/dashboard/create-business/CreateBusinessWrapIndex";
import Confetti from "react-confetti";
import MoneyReceivedModal from "../../components/reusables/MoneyReceivedModal";
// import ConfettiWithSound from "../../components/reusables/ConffetiWithSound";
// import moneyReceivedSound from "../../assets/smile-ringtone.mp3";
// import messageSound from "../../assets/message-with-intro.mp3";
import ConfettiWithSound from "../../components/reusables/ConffetiWithSound";
import UseNotificationSound from "../../components/reusables/newSoundReusable";
import NoContentBox from "../../components/reusables/NoContentBox";
import NewAccountsDropDownModal from "../../components/fragments/NewAccountsDropDownModal";
// import { UseSocketFunction } from "../../utils/helper/UseSocket";
// import CreateRecipientGlobalWrapIndex from "../../pages/dashboard/create-recipient-global/CreateRecipientGlobal";
// import NewCreateRecipientGlobalWrapIndex from "../../pages/dashboard/create-recipient-global/NewRecipientGlobalAccount";
// import waterDropSound from "../../assets/test-sound.wav";

const DashboardLayout = ({
  children,
  donstShowHeader,
  props,
  loadingContent,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const { single_sub_account } = useSelector((state) => state.sub_account);
  const { card } = useSelector((state) => state.cards);
  const { payrollSettings } = useSelector((state) => state.payroll);
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const [shoeDeactivatePayroll, setShowDeActivatePayroll] = useState(true);
  const themeMode = theme === "light" ? lightTheme : darkTheme;
  const { user, business, all_notification, all_account } = useSelector(
    (state) => state.user
  );
  const actionCtx = useContext(ActionContext);
  const [showModal, setShowModal] = useState({
    set_pin: false,
    update_pin: false,
    limit: false,
    showAccounts: false,
    switchBusiness: false,
    notification: false,
    money_received: false,
  });

  useEffect(() => {
    if (localStorage.getItem("theme") === undefined) {
      localStorage.removeItem("theme");
      localStorage.setItem(
        "theme",
        formatMetaData(user?.theme_display_settings)?.theme
      );
    }
    // console.log(formatMetaData(user?.theme_display_settings)?.side_nav_style);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   useEffect(() => {
  //  if(user?.pin_status === 0){
  //   setShowModal((prev) => {
  //     return {...prev, set_pin: true}
  //   })
  //  }
  //  else{
  //   setShowModal((prev) => {
  //     return {...prev, set_pin: false}
  //   })
  //  }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [user]);

  // const SERVER = "https://google.com/search?q=45";
  // const SERVER = process.env.REACT_APP_SOCKET_BASE_URL;
  // const authenticated = localStorage.getItem("token");
  const dispatch = useDispatch();
  // const decryptTokenFunc = (tok) => {
  //   const encryptedToken = tok;
  //   const secretKey =
  //     "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

  //   // Encrypt the token
  //   const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  //   const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
  //   // console.log(tok);
  //   // console.log(decryptedToken);
  //   return decryptedToken;
  // };

  // useEffect(() => {
  //   const socket = UseSocketFunction();
  //   // console.log(socket);
  //   socket.on("setup_for_roles_and_permission", () => {
  //     dispatch(getUser());
  //     dispatch(getTransactionsTwo());
  //     dispatch(getAllAccounts());
  //     // console.log("yes-----------------------");
  //   });

  //   // socket.on("update_balance", () => {
  //   //   dispatch(getUser());
  //   //   dispatch(getTransactionsTwo());
  //   //   getAllAccountsWebsocket();
  //   //   // console.log("yes-----------------------");
  //   // });

  //   return () => {
  //     socket.off("setup_for_roles_and_permission");
  //     // socket.off("update_balance");
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   const socket = UseSocketFunction();

  //   socket.on("update_balance", () => {
  //     dispatch(getUser());
  //     dispatch(getTransactionsTwo());
  //     getAllAccountsWebsocket();
  //     // console.log("yes-----------------------");
  //   });

  //   return () => {
  //     socket.off("update_balance");
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // eslint-disable-next-line no-unused-vars
  const getAllAccountsWebsocket = async () => {
    const data = await dispatch(getAllAccounts());
    if (data?.payload?.data?.status === "success") {
      const account = data?.payload?.data?.data?.account;
      // console.log(account);
      if (data?.payload?.data?.data?.account?.length > 0) {
        if (Object?.keys(actionCtx?.currentAccount)?.length > 0) {
          const getObj = account?.filter(
            (chi) =>
              chi?.account_number === actionCtx?.currentAccount?.account_number
          );
          actionCtx.setCurrentAccount(getObj[0]);
        }
        // else {
        //   actionCtx.setCurrentAccount(getPrimaryAccount(account));
        // }
      }
    }
  };

  const [showSetAdminNumber, setShowAdminNumber] = useState(false);
  useEffect(() => {
    // console.log(user);
    if (business?.phone === null || !business?.phone) {
      if (user?.clearance === SPECIAL_ROLE_NAME) {
        setShowAdminNumber(true);
        // console.log("uydg");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);
  // const { sidebar } = useSelector((state) => state.home);
  // const dispatch = useDispatch();
  const playNotification =
    formatMetaData(user?.theme_display_settings)?.enable_sound === 1
      ? UseNotificationSound(waveSound)
      : UseNotificationSound("");
  const [canRefresh, setCanRefresh] = useState(true);
  const [notiDetail, setNotiDetail] = useState("");
  const onPresenceChange = () => {
    if (localStorage.getItem("token") !== "byebyebye") {
      // localStorage.setItem("token", "byebyebye");
      // actionCtx?.setIsLogout(true);
      setCanRefresh(false);
      localStorage.setItem("can-refresh", "no");
    }

    // dispatch(logoutUser())
  };

  // const channelID = "";
  useEffect(() => {
    if (
      location.pathname.includes("/dashboard-") ||
      !location.pathname.includes("/dashboard-verification")
    ) {
      const script = document.createElement("script");
      // script.src = `//fw-cdn.com/10934263/3694369.js?channelId=${channelID}`;
      script.src = `//fw-cdn.com/10934263/3694369.js`;
      script.setAttribute("chat", "true");

      // Add an event listener to check when the script has loaded
      script.onload = () => {
        if (window.fcWidget) {
          // To set unique user id in your system when it is available
          window.fcWidget.setExternalId(`${business?.id}-${user?.id}`);
          // To set user name
          window.fcWidget.user.setFirstName(user?.fname);
          // To set user email
          window.fcWidget.user.setEmail(
            business?.user_email || business?.email
          );
        }
      };

      // Append the script to the document's body
      document.body.appendChild(script);

      // Cleanup by removing the script from the DOM when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const channelID = 'your-channel-id';

  // useEffect(() => {
  //   // Create a script element
  //   const script = document.createElement('script');

  //   // Define the Freshchat API script URL with your Freshchat token and channel ID
  //   script.src = `https://wchat.freshchat.com/js/widget.js?token=10934263/3694369&channel_id=${channelID}`;

  //   // Load the script asynchronously
  //   script.async = true;

  //   // Append the script to the document's head
  //   document.head.appendChild(script);

  //   return () => {
  //     // Cleanup: remove the script from the document's head when the component unmounts
  //     document.head.removeChild(script);
  //   };
  // }, [channelID]);

  // const handleSoundNotification = () => {
  //   // console.log("----jhfgj------------");
  //   // playNotification();
  //   if (
  //     Number(formatMetaData(user?.theme_display_settings)?.enable_sound) === 1
  //   ) {
  //     playNotification();
  //   }
  //   // playNotification();
  // };

  const [alertUser, setAlertUser] = useState(false);
  // const [checkToggle, setCheckToggle] = useState(false)
  useEffect(() => {
    if (
      alertUser &&
      String(formatMetaData(user?.theme_display_settings)?.enable_sound) === "1"
    ) {
      playNotification();
      setTimeout(() => {
        setAlertUser(false);
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertUser]);

  const [checkSubnotify, setSubCheckNotify] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [notficationList, setNotificationList] = useState([]);
  // useEffect(() => {
  //   const socket = UseSocketFunction();
  //   socket.on("push_notification", async (data) => {
  //     const dataTwo = await data;
  //     // console.log();
  //     if (dataTwo?.email === business?.email) {
  //       setAlertUser(true);
  //       const type = dataTwo?.data?.notification_type;
  //       if (Number(user?.credit_alert_notification_status) === 1) {
  //         if (type === "credit_transaction") {
  //           setNotiDetail(dataTwo?.data);
  //           actionCtx?.setConfettiVal();
  //           setShowModal((prev) => {
  //             return { ...prev, money_received: true };
  //           });
  //           dispatch(getAllNotification());
  //         }
  //       }
  //       if (type !== "credit_transaction") {
  //         const tryFetch = await dispatch(getAllNotification());
  //         if (tryFetch?.payload?.data?.status === "success") {
  //           // setAlertUser(true);
  //         }
  //       }
  //       // setAlertUser(true);
  //     }
  //   });
  //   return () => {
  //     socket.off("push_notification");
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    dispatch(getAllNotification());

    const resonRefresh = localStorage.getItem("can-refresh");
    if (resonRefresh === "no") {
      setCanRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getAllNotificationFunc = async () => {
  //   const data = await dispatch(getAllNotification());
  //   if (data?.payload?.data?.status === "success") {
  //     const messsages = data?.payload?.data?.data;
  //     // console.log(messsages);
  //     setNotificationList(messsages);
  //   }
  // };

  // useEffect(() => {
  //   const checkLocal = localStorage.getItem("theme");

  //   if (checkLocal === "undefined") {
  //     // console.log(checkLocal, "--------------------252");
  //     localStorage?.setItem(
  //       "sidebar-theme-style",
  //       formatMetaData(user?.theme_display_settings)?.side_nav_style
  //     );
  //     localStorage?.setItem("theme", getSystemThemePreference());
  //   } else {
  //     if (formatMetaData(user?.theme_display_settings)?.theme === "system") {
  //       localStorage?.setItem(
  //         "sidebar-theme-style",
  //         formatMetaData(user?.theme_display_settings)?.side_nav_style
  //       );
  //       localStorage?.setItem("theme", getSystemThemePreference());
  //     } else {
  //       localStorage?.setItem(
  //         "theme",
  //         formatMetaData(user?.theme_display_settings)?.theme
  //       );
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  useEffect(() => {
    // setNotificationList(all_notification);
    actionCtx?.setAllNotifications(all_notification);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_notification || location.pathname]);

  return (
    <Fragment>
      <IdleTimerProvider
        timeout={720 * 60000}
        onPresenceChange={onPresenceChange}
      >
        <ThemeProvider theme={themeMode}>
          <GlobalStyles />

          <div
            // onClick={() => {
            //   window.fcWidget.close();
            // }}
            className="dashboard-layout-wrap"
          >
            {/* <div  className="chat-box">
            {" "}
            <figure className="img-box">
              <img src={ChatIcon} alt="" className="img" />
            </figure>
          </div> */}
            {/* side bar start */}
            {/* blur bg start */}
            {actionCtx?.checkShow?.six && <div className="blurr-bg-box"></div>}
            {/* blur bg end */}
            <div
              onClick={() => {
                actionCtx?.setShowProfileDrop("close");
                // setAlertUser(true);
                // console.log("poiu");
                // formatMetaData(user?.theme_display_settings)?.enable_sound ===
                //   1 && playNotification();
                // console.log(
                //   formatMetaData(user?.theme_display_settings)?.enable_sound
                // );
              }}
              className={`sidebar-wrap ${
                actionCtx?.mobileSlide && "sidebar-wrap-show"
              }`}
            >
              {actionCtx?.sideBarNav === "minimal" ? (
                <NewSideMenu
                  onDropAccount={() => {
                    actionCtx.setCurrentAccountDrop(true);
                    actionCtx.setShowChangeAccountBtn(true);
                  }}
                  theme={theme}
                />
              ) : (
                <SideMenu
                  theme={theme}
                  onDropAccount={() => {
                    actionCtx.setCurrentAccountDrop(true);
                    actionCtx.setShowChangeAccountBtn(true);
                  }}
                />
              )}
              {/* <SideMenu
                  theme={theme}
                  onDropAccount={() => {
                    actionCtx.setCurrentAccountDrop(true);
                    actionCtx.setShowChangeAccountBtn(true);
                  }}
                /> */}
            </div>
            {/* side bar end */}

            {/* mobile blur start */}
            {actionCtx?.mobileSlide && (
              <div
                onClick={() => {
                  actionCtx?.setMobileSlide(false);
                }}
                className="mobile-blur-bg"
              ></div>
            )}
            {/* mobile blur end */}
            {/* body start */}
            <div className="main-body-wrap">
              {/* header wrap start */}
              {!donstShowHeader && (
                <div className="header-wrap">
                  <HeaderNav
                    setHideInfo={() => {
                      setSubCheckNotify(true);
                    }}
                    setShowModalMain={setShowModal}
                    theme={theme}
                    toggleTheme={toggleTheme}
                    setShowModalForBusiness={() => {
                      setShowModal((prev) => {
                        return { ...prev, switchBusiness: true };
                      });
                    }}
                    onViewNotification={() => {
                      setShowModal((prev) => {
                        return { ...prev, notification: true };
                      });
                    }}
                    showNotify={actionCtx?.allNotifications?.data?.some(
                      (chi) => chi?.status === 0
                    )}
                  />
                </div>
              )}
              {/* header wrap end */}
              {/* action bar start --------------------- */}
              {card?.main_card_object?.status !== "inactive" &&
                !location?.pathname?.includes("/dashboard-card") && (
                  <>
                    {" "}
                    {all_account?.length > 0 ? (
                      <>
                        {" "}
                        <RavenActionBar
                          msg={`We notice you don’t have a business phone number Setup, Add a busnisess phone number to update profile`}
                          type="warning"
                          actionText="Add Phone Number"
                          visible={
                            !business?.phone &&
                            user?.clearance === SPECIAL_ROLE_NAME
                              ? true
                              : business?.phone === null &&
                                user?.clearance === SPECIAL_ROLE_NAME
                              ? true
                              : location?.pathname?.includes(
                                  "/dashboard-payroll"
                                ) && payrollSettings?.payroll_status !== "1"
                              ? false
                              : false
                          }
                          className={`action-bar-set-pin-info`}
                          onCancel={() => {
                            actionCtx?.setShowPinSet(false);
                          }}
                          onAction={() => {
                            setShowAdminNumber(true);
                          }}
                        />
                        {actionCtx?.currentAccount?.authorization_level !==
                          ("initiator_approver" || "initiator") &&
                          actionCtx?.currentAccount?.authorization_level !==
                            ("initiator" || "initiator_approver") && (
                            <RavenActionBar
                              msg={`We notice you don’t have a PIN Setup, Add a pin to authenticate all your request`}
                              type="warning"
                              actionText="Setup PIN"
                              visible={
                                user?.pin_status
                                  ? false
                                  : !business?.phone
                                  ? false
                                  : user?.pin_status === 0 &&
                                    actionCtx?.showPinSet
                                  ? true
                                  : location?.pathname?.includes(
                                      "/dashboard-payroll"
                                    ) && payrollSettings?.payroll_status !== "1"
                                  ? false
                                  : location?.pathname?.includes(
                                      "/dashboard-sub-account-single"
                                    )
                                  ? false
                                  : false
                              }
                              className={`action-bar-set-pin-info`}
                              onCancel={() => {
                                actionCtx?.setShowPinSet(false);
                              }}
                              onAction={() => {
                                actionCtx?.setTrxPin(true);
                                // setShowModal((prev) => {
                                //   return { ...prev, set_pin: true };
                                // });
                              }}
                            />
                          )}
                        {/* main start */}
                        {/* deactivate payroll info start */}
                        <RavenActionBar
                          msg={`Your payroll is deactivated, click enable payroll to enable payroll back`}
                          type="error"
                          actionText="Enable Payroll"
                          className={`action-bar-set-pin-info`}
                          visible={
                            payrollSettings?.payroll_status === "0" &&
                            location?.pathname?.includes(
                              "/dashboard-payroll"
                            ) &&
                            shoeDeactivatePayroll
                          }
                          onCancel={() => {
                            setShowDeActivatePayroll(false);
                          }}
                          onAction={() => {
                            actionCtx?.setCheckShow("five");
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {checkSubnotify && (
                      <>
                        {" "}
                        <RavenActionBar
                          msg={`To generate your account number, we need to verify your BVN. Kindly click "Verify ${
                            business?.bvn !== 3
                              ? "BVN"
                              : business?.nin !== 3
                              ? "NIN"
                              : ""
                          }" to proceed.`}
                          type="info"
                          actionText={
                            business?.bvn !== 3
                              ? "Verify BVN"
                              : business?.nin !== 3
                              ? "Verify NIN"
                              : "Contact support"
                          }
                          visible={checkSubnotify}
                          className={`action-bar-set-pin-info`}
                          onCancel={() => {
                            setSubCheckNotify(false);
                          }}
                          onAction={() => {
                            // window.Intercom("show");
                            if (business?.bvn !== 3) {
                              navigate("/dashboard-verification-bvn");
                              return;
                            }
                            if (business?.nin !== 3) {
                              navigate("/dashboard-verification-nin");
                              return;
                            }
                            window.fcWidget.open();
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              {/* action bar end ------------------- */}

              {location?.pathname?.includes("/dashboard-card") &&
                card?.main_card_object?.status !== "active" &&
                card?.main_card_object && (
                  <>
                    <RavenActionBar
                      msg={`This card is deactivated, click "Unfreeze card" to enable card back`}
                      type="error"
                      actionText="Unfreeze card"
                      visible={
                        location?.pathname?.includes("dashboard-card") &&
                        card?.main_card_object?.status !== "active"
                      }
                      className={`action-bar-set-pin-info`}
                      onCancel={() => {
                        // setSubCheckNotify(false);
                      }}
                      onAction={() => {
                        actionCtx.setUnfreezeCard(true);
                      }}
                    />
                  </>
                )}
              {/* deactivate payroll info end */}
              {/* deactivate payroll info start */}
              {/* <RavenActionBar
                msg={`This sub account is freezed, click Unfreeze button or text to unfreeze sub account back`}
                type="error"
                actionText="Unfreeze sub account"
                className={`action-bar-set-pin-info`}
                visible={
                  single_sub_account.freeze === 1 &&
                  location?.pathname?.includes("/dashboard-sub-account-single")
                }
                onCancel={() => {
                  setShowDeActivatePayroll(false);
                }}
                onAction={() => {
                  actionCtx?.setCheckShow("five");
                }}
              /> */}
              {/* deactivate payroll info end */}
              <div
                onClick={() => {
                  actionCtx?.setShowProfileDrop("close");
                  // sidebar && onToggle();
                }}
                className="main-wrap"
              >
                {loadingContent ? (
                  <>
                    <NoContentBox loading={true} />
                  </>
                ) : (
                  <>{children}</>
                )}
              </div>
              {/* main end */}
            </div>
            {/* body end */}
            {/* create business start */}

            {actionCtx?.createBusiness && <CreateBusinessWrapIndex />}
            {/* {actionCtx?.createRecipientGlobal && <CreateRecipientGlobalWrapIndex />} */}
            {/* create business end */}
          </div>
        </ThemeProvider>
      </IdleTimerProvider>
      {/* confetti start -------------- */}
      <MoneyReceivedModal
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, money_received: false };
          });
          setNotiDetail("");
        }}
        visible={showModal?.money_received}
        detail={notiDetail}
      />
      {actionCtx?.confettiVal && (
        <div
          className=""
          style={{ zIndex: 100000000, position: "fixed", top: "0", left: "0" }}
        >
          <Confetti />
        </div>
      )}

      {/* confetti end ------------- */}

      {/* transaction pin start */}
      <SetTransactionPinModal
        visible={actionCtx?.trxPin}
        onClose={() => {
          // setShowModal((prev) => {
          //   return { ...prev, set_pin: false };
          // });
          actionCtx?.setTrxPin(false);
        }}
        onFinish={() => {
          actionCtx?.setTrxPin(false);
          // setShowModal((prev) => {
          //   return { ...prev, set_pin: false };
          // });
        }}
      />
      <SetTransactionPinModal
        visible={actionCtx?.checkShow?.nine}
        onClose={() => {
          actionCtx.setCheckShow("close");
        }}
        onFinish={() => {
          actionCtx.setCheckShow("close");
        }}
      />
      {/* transaction pin end */}
      {/* delete mod start */}
      <DeactivatePayroll
        visible={actionCtx?.checkShow?.five}
        onCancel={() => {
          actionCtx?.setCheckShow("close");
        }}
        onFinish={() => {
          // dispatch(getPayrollSetup());
          actionCtx?.setCheckShow("close");
        }}
      />
      {/* delete mod end */}
      {/* UPDATE PIN MODAL STRT */}
      <UpdateTransactionPinModal
        visible={actionCtx?.updatePin}
        onClose={() => {
          actionCtx?.setShowUpdate(false);
        }}
        onFinish={() => {
          actionCtx?.setShowUpdate(false);
        }}
      />
      <EditPhoneNumberModal
        onClose={() => {
          setShowAdminNumber(false);
        }}
        visible={showSetAdminNumber}
      />
      {/* Update pin modal end */}
      {/* account drop modal start */}
      {/* <AccountDropModal
        // dontShowAddButton={location?.pathname === "/dashboard-bill-payment"}
        visible={actionCtx.currentAccountDrop}
        onClose={() => {
          actionCtx.setCurrentAccountDrop(false);
          actionCtx.setShowChangeAccountBtn(false);
        }}
      /> */}

      <NewAccountsDropDownModal
        big={true}
        onSelect={(obj) => {
          localStorage.setItem("current_sub_account_id", obj?.id);
          actionCtx?.setCurrentAccount(obj);
          actionCtx.setCurrentAccountDrop(false);
          actionCtx.setShowChangeAccountBtn(false);
        }}
        visble={actionCtx.currentAccountDrop}
        onClose={() => {
          actionCtx.setCurrentAccountDrop(false);
          actionCtx.setShowChangeAccountBtn(false);
        }}
      />
      {/* account drop modal end */}
      {/* switch business account start */}
      <SwitchBusinessAccountModal
        visible={showModal?.switchBusiness}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, switchBusiness: false };
          });
        }}
        // setShowModal={setShowModal}
      />
      {/* switch business account end */}
      {/* notification modal start */}
      <NotificationModal
        visible={showModal?.notification}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, notification: false };
          });
        }}
        list={actionCtx.allNotifications}
      />
      {actionCtx?.playSound && <ConfettiWithSound />}
      {/* notification modal end */}
      {/* loader genral start */}
      {actionCtx?.showGeneralLoader && <BlackoutLogoModal />}
      {/* loader general end */}
      {playNotification}
      <SessionEndModal canRefresh={canRefresh} />
    </Fragment>
  );
};

export default DashboardLayout;
