import {
  RavenCheckBox,
  RavenInputField,
  RavenModal,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { validateBvn } from "../../../../redux/settings";
import { validateBvn } from "../../../../redux/card";
import "../../../../styles/dashboard/pos/DashboardPosIndex.css";
import useDebounce from "../../../../utils/helper/UseDebounce";
import { ThreeDots } from "react-loader-spinner";
import { validateEmail } from "../../../../utils/helper/ValidateInput";
import { removeSpace } from "../../../../utils/helper/Helper";

const AddForAnotherUserModal = ({ visible, onClose, setToDetails }) => {
  const dispatch = useDispatch();
  const { loadingBvn } = useSelector((state) => state.cards);
  const [details, setDetails] = useState({
    card_name: "",
    bvn: "",
    nin: "",
    third_party_email: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };
  const [error, setError] = useState({
    email: false,
    password: false,
    password_msg: "",
  });
  const handleSubmit = () => {
    if (!validateEmail(details.third_party_email)) {
      toast.error("Invalid email address!", { position: "top-right" });
      setError((prev) => {
        return { ...prev, email: true };
      });
      return;
    }
    setToDetails(details);
    setDetails({
      card_name: "",
      bvn: "",
      nin: "",
      third_party_email: "",
    });
  };

  const handleValidateBvn = async (param) => {
    // console.log(param);
    const data = await dispatch(validateBvn(param));
    // console.log(data);
    if (data?.payload?.data?.status === "fail") {
      const msg = data?.payload?.data?.data || data?.payload?.data?.message;
      setError((prev) => {
        return { ...prev, password_msg: msg, password: true };
      });
    }
    if (data?.payload?.data?.status === "success") {
      const details = data?.payload?.data?.data;
      setDetails((prev) => {
        return {
          ...prev,
          card_name: `${details?.firstname} ${details?.lastname}`,
        };
      });
    } else {
      setDetails((prev) => {
        return { ...prev, card_name: "" };
      });
    }
  };

  const debouncedSearchTerm = useDebounce(details?.bvn, 1000);
  useEffect(() => {
    let isMount = true;
    //  console.log(removeSpace(debouncedSearchTerm)?.length);
    if (isMount && removeSpace(debouncedSearchTerm)?.length > 10) {
      const obj = {
        bvn: details?.bvn,
      };
      handleValidateBvn(obj);
      //   console.log(obj);
    }

    if (isMount && removeSpace(debouncedSearchTerm)?.length < 1) {
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <RavenModal
      visble={visible}
      onClose={() => {
        onClose()
        setDetails({
          card_name: "",
          bvn: "",
          nin: "",
          third_party_email: "",
        })
      }}
      btnColor={`black-light`}
      btnLabel={`Add User`}
      disabled={
        !details?.third_party_email ||
        !details?.bvn ||
        !details?.nin ||
        !details?.card_name
      }
      onBtnClick={handleSubmit}
    >
      <div className="cashier-assign-modal-wrap">
        <p className="title">Add User Information</p>
        <p className="text grey-white-color">
          We need to have some basic information about the user you are
          requesting the card for.
        </p>
        <div className="underline"></div>
        {/* form wrap start */}
        <div className="form-inputs-wrap">
          <RavenInputField
            color={`black-light`}
            type={`email`}
            label={`User Email`}
            placeholder={`E.g ayeniKehi@gmail.com`}
            onChange={(e) => {
              handleChange(e);
              setError((prev) => {
                return { ...prev, email: false };
              });
            }}
            name={`third_party_email`}
            value={details?.third_party_email}
            showError={error?.email}
          />
          <RavenInputField
            color={`black-light`}
            type={`account-number`}
            label={`BVN Number`}
            placeholder={`11 digit bvn details`}
            maxLength={11}
            showError={error.password}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, card_name: "" };
              });
              setError((prev) => {
                return { ...prev, password: false, password_msg: "" };
              });
              handleChange(e);
            }}
            errorText={error.password_msg}
            name={`bvn`}
            value={details?.bvn}
            labelSpanText={
              loadingBvn ? (
                <div
                  style={{
                    padding: "0rem",
                    display: "flex",
                    justifyContent: "flex-end",
                    transform: "translateX(1.5rem)",
                  }}
                  className="load-wrap"
                >
                  <ThreeDots
                    height="10"
                    width="80"
                    radius="9"
                    color="#020202"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                "Verify"
              )
            }
            labelColor={`purple-light`}
          />
          {/* verify name box start */}
          {details?.card_name && (
            <div
              className={`verify-name-box ${
                details?.card_name && "verify-name-box-show"
              }`}
            >
              <RavenCheckBox
                checked={true}
                color={`black-light`}
                className={`check`}
              />
              <p className="text">{details?.card_name}</p>
            </div>
          )}
          {/* verify name box end */}
          <RavenInputField
            color={`black-light`}
            type={`email`}
            label={`NIN Number`}
            placeholder={`nin details`}
            onChange={handleChange}
            name={`nin`}
            value={details?.nin}
          />
        </div>
        {/* form wrap end */}
      </div>
    </RavenModal>
  );
};

export default AddForAnotherUserModal;
