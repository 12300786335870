/* eslint-disable default-case */
import React, { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ActionContext from "../../context/ActionContext";
// import {  useDispatch } from "react-redux";
// import { getTransactionsChart } from "../../redux/transaction";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// defaults.global.defaultFontFamily = '"br_firma", sans-serif'

// const yAxesScaleOptions = [
//   {
//     barPercentage: 0.2,
//     categoryPercentage: 0.2,
//   },
// ];

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Q" },
    { value: 1e18, symbol: "QT" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}
const TransactionBarChart = ({ datatoSHow }) => {
  const [labelColor, setLabelColor] = useState("#020202");
  const actionCtx = useContext(ActionContext);
  useEffect(() => {
    localStorage?.getItem("theme") === "dark"
      ? setLabelColor("#ffffff")
      : setLabelColor("#020202");
  }, [actionCtx?.toggleTheme]);





  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },

    scales: {
      x: {
        display: false,
        grid: {
          drawBorder: false,
          display: false,
        },
        barThickness: 0.1,
        barPercentage: 0.4,
        categoryPercentage: 0.4,
        ticks: {
          color: "#020202",
        },
      },
      y: {
        border: { dash: [4, 4], display: false }, // for the grid lines
        grid: {
          color: "#1110101a", // for the grid lines
          tickColor: "#1110101a", // for the tick mark
          tickBorderDash: [2, 3], // also for the tick, if long enough
          tickLength: 10, // just to see the dotted line
          tickWidth: 2,
          offset: true,
          drawTicks: true, // true is default
          drawOnChartArea: true, // true is default
        },
        ticks: {
          color: labelColor,
          beginAtZero: false,
          // callback: (value) => `₦${nFormatter(value)}`,
          callback: (value) => `${nFormatter(value)}`,
        },
        // scale:  yAxesScaleOptions
      },
    },
  };

  return (
    <>
      <Bar
        // className="chart"
        redraw={false}
        width={100}
        height={100}
        options={options}
        data={datatoSHow}
      />
    </>
  );
};

export default TransactionBarChart;
