import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { clearUserPayrollAll } from "../../../../redux/payroll";

const ClearPayrollDataModal = ({ visible, onCancel, onFinish }) => {
  const { loadingDetail, payrollSettings } = useSelector(
    (state) => state.payroll
  );
  const dispatch = useDispatch();

  const handleDelete = () => {
    const obj = {
      id: payrollSettings?.id,
    };
    const data = dispatch(clearUserPayrollAll(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
    }
  };
  return (
    <DeleteModal
      loading={loadingDetail}
      visible={visible}
      deleteText="Clear"
      onCancel={onCancel}
      onDelete={handleDelete}
      title="Clear Payroll data"
      text={`Delete and clear all data from this payroll, this mean you won’t be able to perform payroll transactions.`}
    />
  );
};

export default ClearPayrollDataModal;
