import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadTransactionReciept } from "../../../../redux/transaction";
import "../../../../styles/dashboard/transaction/DashboardTransaction.css";
import {
  formatDate,
  formatNUmPan,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  lowerCaseWrap,
} from "../../../../utils/helper/Helper";

const DownloadReceiptModal = ({ onClose, visible, param, detail }) => {
  const { loadingDownload } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const formatMetaData = (param) => {
    if (param) {
      const obj = JSON?.parse(param);
      // console.log(obj);
      return obj;
    }
  };

  const formatTypeWrap = (param, chi) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "0" && "status-type-wrap-pending"
        } ${param === "3" && "status-type-wrap-success"} ${
          chi?.direction === "credit" && "status-type-wrap-success"
        } ${param === "paid" && "status-type-wrap-success"} ${
          chi?.direction === "debit" && "status-type-wrap-fail"
        } ${param === "overdue" && "status-type-wrap-pending"} ${
          param === "1" && "status-type-wrap-pending"
        } ${param === "pending" && "status-type-wrap-pending"} ${
          chi?.direction === "credit" && "status-type-wrap-success"
        } ${param === "failed" && "status-type-wrap-disabled"} ${
          chi?.direction === null && "status-type-wrap-disabled"
        }`}
      >
        <span
          style={{ textTransform: "capitalize" }}
          className={`${param === "failed" && "dull-bright-filter"}`}
        >
          {param === "1"
            ? "Processing"
            : param === "0"
            ? "Pending"
            : param === "3"
            ? "Success"
            : param === "2"
            ? "Failed"
            : param === "11"
            ? "Reversal"
            : param === "failed"
            ? "Failed"
            : chi?.direction === null
            ? "Failed"
            : chi?.direction}
        </span>
      </div>
    );
  };

  const handleSubmit = async () => {
    const obj = {
      transaction_id: String(detail?.id),
    };
    const data = await dispatch(downloadTransactionReciept(obj));
    if (data?.payload?.data?.status === "success") {
      // setDownloadCsvId("");
      downloadCsv(data?.payload?.data?.data?.url);
      // downloadCsvId("");
      // console.log(data);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <RavenModal
      btnColor={`black-light`}
      visble={visible}
      btnLabel={`Download Reciept`}
      className="transaction-download-receipt-modal"
      onClose={onClose}
      onBtnClick={handleSubmit}
      loading={loadingDownload}
      // dontShowClose={true}
    >
      <div className="wrap-modal">
        <p
          onClick={() => {
            // console.log(formatMetaData(detail?.meta_data));
            // console.log(detail);
          }}
          className="title"
        >
          Transaction Reciept
        </p>
        <div className="underline"></div>
        {/* label-value-status box start */}
        <div className="label-value-status-box">
          {/* box start */}
          {detail?.type === "Reversal" && (
            <div className="box">
              <p className="label-download grey-white-color">Recipient</p>
              <p className="value-download">{`System Reversal`}</p>
            </div>
          )}
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Amount:</p>
            <p className="value-download">
              {formatNumWithCommaNairaSymbol(detail?.amount || 0)}
            </p>
          </div>
          {/* box end */}
          {/* type start ----------------- */}
          {detail?.type === "airtime" || detail?.type === "data" ? (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.data?.network || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Phone number</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.data?.phone_number ||
                    "---"}
                </p>
              </div>
              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}
          {/* type start ----------------- */}
          {detail?.type === "bet" || detail?.type === "data" ? (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.type || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Customer name</p>
                <p className="value-download">
                  {lowerCaseWrap(formatMetaData(detail?.meta_data)?.name) ||
                    "---"}
                </p>
              </div>
              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}
          {/* type start ----------------- */}
          {detail?.type === "electricity" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.meta?.service ||
                    formatMetaData(detail?.meta_data)?.provider ||
                    "---"}
                </p>
              </div>
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">Plan</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.meta?.meter_type ||
                    formatMetaData(detail?.meta_data)?.meter_type ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Meter number</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.meta?.meter_number ||
                    formatMetaData(detail?.meta_data)?.meter_no ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              {formatMetaData(detail?.meta_data)?.meta?.electricity_token && (
                <div className="box">
                  <p className="label-download grey-white-color">Token</p>
                  <p className="value-download">
                    {formatMetaData(detail?.meta_data)?.meta
                      ?.electricity_token || "---"}
                  </p>
                </div>
              )}
              {/* box end */}
              {/* box start */}
              {formatMetaData(detail?.meta_data)?.meta?.reference && (
                <div className="box">
                  <p className="label-download grey-white-color">Reference</p>
                  <p className="value-download">
                    {formatMetaData(detail?.meta_data)?.meta?.reference ||
                      "---"}
                  </p>
                </div>
              )}
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div> */}
              {/* box end */}
            </>
          )}
          {/* type end ----------------- */}
          {detail?.type === "card" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Card Number:</p>
                <p className="value-download">
                  {`${formatNUmPan(
                    formatMetaData(detail?.meta_data)?.card_pan
                  )}` || "---"}
                </p>
              </div>
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">RRN</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.rrn || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box end electricity_token*/}
              {detail?.type === "transfer_in" &&
                Number(detail?.amount) >= 10000 && (
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Stamp Duty
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.rrn || "---"}
                    </p>
                  </div>
                )}
              {/* box end */}
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">Stan</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.stan || "---"}
                </p>
              </div>
              {/* box end */}

              {detail?.direction === "credit" && (
                <>
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">Card Fee</p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatNumWithCommaNairaSymbol(detail?.fee || 0)}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {detail?.direction === null && (
                <>
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Response Code
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.response_code ||
                        "---"}
                    </p>
                  </div>
                  {/* box end */}
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Response meaning
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.response_meaning ||
                        "---"}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {/* box start */}
              {/* <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div> */}
              {/* box end */}
            </>
          )}
          {detail?.type === "pdon_card_raven" ||  detail?.type === "pdon_card" ? (
            <>
              <div className="box">
                <p className="label-download grey-white-color">
                  Affilaite App:
                </p>
                <p className="value-download">
                  {`${
                    formatMetaData(detail?.pdon_meta)?.app_profile
                      ?.affiliate_app_name
                  }` || "---"}
                </p>
              </div>
              <div className="box">
                <p className="label-download grey-white-color">Card Number:</p>
                <p className="value-download">
                  {`${formatNUmPan(
                    formatMetaData(detail?.meta_data)?.card_pan
                  )}` || "---"}
                </p>
              </div>
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">RRN</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.rrn || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box end electricity_token*/}
              {detail?.type === "transfer_in" &&
                Number(detail?.amount) >= 10000 && (
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Stamp Duty
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.rrn || "---"}
                    </p>
                  </div>
                )}
              {/* box end */}
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">Stan</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.stan || "---"}
                </p>
              </div>
              {/* box end */}

              {detail?.direction === "credit" && (
                <>
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">Card Fee</p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatNumWithCommaNairaSymbol(detail?.fee || 0)}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {detail?.direction === null && (
                <>
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Response Code
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.response_code ||
                        "---"}
                    </p>
                  </div>
                  {/* box end */}
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Response meaning
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.response_meaning ||
                        "---"}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {/* box start */}
              {/* <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div> */}
              {/* box end */}
            </>
          ) : ""}
          {/* type end ----------------- */}

          {/* type start ----------------- */}
          {detail?.type === "Cable Recharge" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.provider || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Meter No.</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.smart_card_number ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div>
              {/* box end */}
            </>
          )}
          {/* type end ----------------- */}
          {detail?.type === "Money Received" &&
            formatMetaData(detail?.meta_data)?.first_name &&
            formatMetaData(detail?.meta_data)?.last_name && (
              <>
                <div className="box">
                  <p className="label-download grey-white-color">Sent By:</p>
                  <p className="value-download">
                    {`${
                      formatMetaData(detail?.meta_data)?.first_name || "---"
                    } ${formatMetaData(detail?.meta_data)?.last_name || "---"}`}
                  </p>
                </div>
                {/* box end electricity_token*/}
              </>
            )}
          {detail?.type === "transfer_in" && (
            <>
              {" "}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Source:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.bank ||
                    formatMetaData(detail?.meta_data)?.source?.bank ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Account No.</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.source?.account_number ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              <div className="box">
                <p className="label-download grey-white-color">Sent By:</p>
                <p className="value-download">
                  {`${
                    formatMetaData(detail?.meta_data)?.source?.first_name ||
                    "---"
                  } ${
                    formatMetaData(detail?.meta_data)?.source?.last_name ||
                    "---"
                  }`}
                </p>
              </div>
            </>
          )}
          {/* type start ----------------- */}
          {detail?.type === "transfer_out" ||
          detail?.type === "Reversal" ||
          detail?.type === "Transaction Charge" ? (
            <>
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Bank:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.bank || "---"}
                </p>
              </div>
              {/* box end */}

              {detail?.type === "transfer_out" && (
                <>
                  {" "}
                  {/* box start */}
                  <div className="box">
                    <p className="label-download grey-white-color">Recipient</p>
                    <p className="value-download">
                      {formatMetaData(detail?.meta_data)?.account_name || "---"}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Session ID</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.session_id || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}

          {/* box start */}
          {detail?.type !== "Reversal" && (
            <div className="box">
              <p className="label-download grey-white-color">
                Transaction Type{" "}
              </p>
              <p
                style={{ textTransform: "capitalize" }}
                className="value-download"
              >
                {formatTypeFunction(detail?.type) === "Pdon card" || formatTypeFunction(detail?.type) === "Pdon card raven" ? "Bankbox Payment" : formatTypeFunction(detail?.type) || "---"}
              </p>
            </div>
          )}
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Transaction date </p>
            <p className="value-download">
              {formatDate(detail?.created_at) || "---"}
            </p>
          </div>
          {/* box end */}
          {detail?.type !== "Reversal" && detail?.type !== "card" && (
            <>
              {" "}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">
                  Transaction Ref.
                </p>
                <p className="value-download ref">
                  <span>
                    {" "}
                    {formatMetaData(detail?.meta_data)?.data?.reference ||
                      detail?.reference ||
                      "---"}
                  </span>
                </p>
              </div>
              {/* box end */}
            </>
          )}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Status </p>
            {formatTypeWrap(detail?.status, detail)}
            {/* <div
              className={`status-type-wrap ${
                detail?.status === "0" && "status-type-wrap-pending"
              } ${detail?.status === "3" && "status-type-wrap-success"} ${
                detail?.status === "11" && "status-type-wrap-success"
              } ${detail?.status === "success" && "status-type-wrap-success"} ${
                detail?.status === "paid" && "status-type-wrap-success"
              } ${detail?.status === "failed" && "status-type-wrap-fail"} ${
                detail?.status === "overdue" && "status-type-wrap-pending"
              } ${detail?.status === "1" && "status-type-wrap-pending"} ${
                detail?.status === "pending" && "status-type-wrap-pending"
              } ${detail?.direction === null && "status-type-wrap-disabled"}`}
            >
              <span>
                {" "}
                {detail?.status === "1"
                  ? "Processing"
                  : detail?.status === "0"
                  ? "Pending"
                  : detail?.status === "3"
                  ? "Success"
                  : detail?.status === "2"
                  ? "Failed"
                  : detail?.status === "11"
                  ? "Success"
                  : detail?.direction === null
                  ? "Failed"
                  : detail?.status}
              </span>
            </div> */}
          </div>
          {/* box end */}
        </div>
        {/* label-value-status box end */}
      </div>
    </RavenModal>
  );
};

export default DownloadReceiptModal;

// const formatTypeWrap = (param, chi) => {
//     return (
//       <div
//         className={`status-type-wrap ${
//           param === "0" && "status-type-wrap-pending"
//         } ${param === "3" && "status-type-wrap-success"} ${
//           chi?.direction === "credit" && "status-type-wrap-success"
//         } ${param === "paid" && "status-type-wrap-success"} ${
//           chi?.direction === "debit" && "status-type-wrap-fail"
//         } ${param === "overdue" && "status-type-wrap-pending"} ${
//           param === "1" && "status-type-wrap-pending"
//         } ${param === "pending" && "status-type-wrap-pending"} ${
//           chi?.direction === "credit" && "status-type-wrap-success"
//         } ${param === "failed" && "status-type-wrap-disabled"} ${
//           chi?.direction === null && "status-type-wrap-disabled"
//         }`}
//       >
//         <span style={{ textTransform: "capitalize" }}>
//           {param === "1"
//             ? "Processing"
//             : param === "0"
//             ? "Pending"
//             : param === "3"
//             ? "Success"
//             : param === "2"
//             ? "Failed"
//             : param === "11"
//             ? "Reversal"
//             : param === "failed"
//             ? "Failed"
//             : chi?.direction === null
//             ? "Failed"
//             : chi?.direction}
//         </span>
//       </div>
//     );
//   };
