/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
// import setAuthToken from "../utils/auth";
import { toast } from "@ravenpay/raven-bank-ui";

export const getPayrolls = createAsyncThunk(
  "payroll/get_employees",
  async (payload, thunkAPI) => {
    try {
      let url;
      const { per_page, current_page, verification_status } = payload;
      if (verification_status) {
        url = `payroll/get_employees?per_page=${per_page}&current_page=${current_page}&verification_status=${verification_status}`;
      } else {
        url = `payroll/get_employees?per_page=${per_page}&current_page=${current_page}`;
      }
      const { data } = await axios.get(url);
      // console.log("payrolls", data.data);

      if (data.status !== "success") {
        // toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data);
        await thunkAPI.dispatch(setAllEmployees(data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPayrollsBySearch = createAsyncThunk(
  "payroll/get_employees",
  async (payload, thunkAPI) => {
    try {
      const { search_term } = payload;
      const { data } = await axios.get(
        `payroll/search_through_employees_record?search_term=${search_term}`
      );
      // console.log("payrolls", data.data);

      if (data.status !== "success") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data);
        const emp = data?.data?.employees;
        const val = { ...data?.data, employees: { data: emp } };
        await thunkAPI.dispatch(setAllEmployees(val));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSalaryDisburseMents = createAsyncThunk(
  "payroll/salary-disbursements",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `payroll/salary_disbursements?per_page=${payload.per_page}&current_page=${payload.current_page}`
      );
      // ;

      if (data.status !== "success") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(
          setPreviousDisbursement(data?.data?.salary_disbursements)
        );
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const downloadPayrollTemplate = createAsyncThunk(
  "payroll/download-template-payroll",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`payroll/download_payroll_template`);
      // ;

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.status === 200) {
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPayroll = createAsyncThunk(
  "payroll/get_employee",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `payroll/get_single_employee/${payload}`
      );
      // console.log("paroll", data);

      if (data.status !== "success") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data.data);
        await thunkAPI.dispatch(setSingleEmployee(data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSingleEmployee = createAsyncThunk(
  "payroll/get_single_employee",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/payroll/single_employee/${payload?.id}?per_page=${
          payload.per_page || ""
        }&current_page=${payload.current_page || ""}`
      );
      // console.log("paroll", data);

      if (data.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data.data);
        await thunkAPI.dispatch(setSingleEmployee(data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSingleEmployeeByEmail = createAsyncThunk(
  "payroll/get_single_employee",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/payroll/get_employee_detail_with_email/${payload}`
      );
      // console.log("paroll", data);

      if (data.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data.data);
        await thunkAPI.dispatch(setSingleEmployee(data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPayrollSetup = createAsyncThunk(
  "payroll/get_payroll_setup",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("payroll/get_payroll_setup", payload);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return;
      }

      if (data?.data?.status === "fail") {
        // toast.error(data.message, {});
        // console.log("register", data);
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log("register", data);
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(
          setFetchPayrollSettings(data?.data?.data?.payroll_setup)
        );
        return data;
      }
      return data;
    } catch (err) {
      // console.log("register", err);
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const deactivatePayroll = createAsyncThunk(
  "admin/deactivate-payroll",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.delete("", payload);
      //       // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const enableDisableSponsorShip = createAsyncThunk(
  "admin/enable-disable-sponsorship",
  async (payload, thunkAPI) => {
    try {
      // console.log("hello");
      const { data } = await axios.post(
        `payroll/toggle_od_sponsorship/${payload.id}`,
        payload
      );
      // console.log("register", data);

      if (data.status !== "success") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        toast.success(data.data.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const generateStatementTransactionForAllEmp = createAsyncThunk(
  "admin/enable-disable-sponsorship-generate",
  async (payload, thunkAPI) => {
    try {
      // console.log("hello");
      const data = await axios.post(
        `/payroll/salary_transactions_statement?date=${payload?.date}`,
        payload
      );
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const generateStatementAllTransactionForAllEmp = createAsyncThunk(
  "admin/enable-disable-sponsorship-generate",
  async (payload, thunkAPI) => {
    try {
      // console.log("hello");
      const data = await axios.get(
        `/payroll/salary_transactions_statement_for_all_employees?currency=NGN&date=${payload?.date}`,
        payload
      );
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const generateStatementTransactionForAllEmpSingle = createAsyncThunk(
  "admin/enable-disable-sponsorship-generate",
  async (payload, thunkAPI) => {
    try {
      // console.log("hello");
      const data = await axios.post(
        `/payroll/create_bank_statement_for_single_salary_transactions`,
        payload
      );
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const approveDisbursements = createAsyncThunk(
  "admin/approve-disbursements",
  async (payload, thunkAPI) => {
    try {
      // console.log("hello");
      const { data } = await axios.post(
        `payroll/confirm_salary_transactions_for_payment`,
        payload
      );
      // console.log("register", data);

      if (data.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        toast.success(data.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const deactivateUserPayrollAll = createAsyncThunk(
  "admin/enable-disable-sponsorship",
  async (payload, thunkAPI) => {
    try {
      // console.log("hello");
      const data = await axios.post(
        `payroll/toggle_payroll_status/${payload.id}`,
        payload
      );
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(
          setFetchPayrollSettings(data?.data?.data?.payroll_setup)
        );
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const clearUserPayrollAll = createAsyncThunk(
  "admin/enable-disable-sponsorship",
  async (payload, thunkAPI) => {
    try {
      // console.log("hello");
      const data = await axios.get(
        `payroll/toggle_payroll_status/${payload.id}`
      );
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const updateEmployeeDetails = createAsyncThunk(
  "admin/delete-payroll",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post(
        `payroll/update_employee/${payload?.id}`,
        payload
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      //
      // console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const initialPayrollSettings = createAsyncThunk(
  "payroll/settings",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("payroll/settings", payload);
      //       // console.log("register", data);

      if (data?.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data?.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const suspendEmployee = createAsyncThunk(
  "payroll/toggle_employee_status",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `payroll/toggle_employee_status/${payload.id}`,
        { employment_status: payload.employment_status }
      );
      //       // console.log("register", data);

      if (data.status !== "success") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);
export const manualAddEmployeeToPayroll = createAsyncThunk(
  "manual-add-employee-to-payroll",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/payroll/upload_single_employee", payload);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const deletePayroll = createAsyncThunk(
  "payroll/delete_an_employee_erom_payroll",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/payroll/delete_an_employee_from_payroll",
        payload
      );
      //       // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const autoAddEmployeeToPayroll = createAsyncThunk(
  "payroll/upload_bulk_employees",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("payroll/upload_bulk_employees", payload);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const addDeductionToEmployee = createAsyncThunk(
  "admin/add-deduction-to-employee",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/payroll/update_a_salary_deduction",
        payload
      );
      if (data?.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        toast.success(data.message, {});
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const cancelDisbursment = createAsyncThunk(
  "admin/add-deduction-to-employee",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `/payroll/cancel-salary-disbursement/${payload?.id}`,
        payload
      );
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const disburseCurrentPayroll = createAsyncThunk(
  "admin/disburse-now",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `payroll/confirmPaymentOfSalaryAndChangePaymentToCurrentDate`,
        payload
      );
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const approveCurrentPayroll = createAsyncThunk(
  "admin/disburse-now",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `/payroll/confirm_salary_transactions_for_payment`,
        payload
      );
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const changeEmployeePaymentStatus = createAsyncThunk(
  "admin/change-employee-payment-status",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `/payroll/update_employee_salary_transaction_payment_status/${payload.id}`,
        payload
      );
      // ;
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {});
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const generatePayrollSlipTemplate = createAsyncThunk(
  "admin/generate-bank-statement-electricity",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `payroll/salary_transactions_statement?currency=NGN&date=${payload?.date}`,
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.status === 200) {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const managePayrollDeductionAndTax = createAsyncThunk(
  "admin/payroll-deduction-tax-manage",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`/payroll/settings`, payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const validateRavenUserName = createAsyncThunk(
  "admin/validate-raven-username",
  async (payload, thunkAPI) => {
    // console.log(payload);
    try {
      const data = await axios.post(
        `/transfer/validate_raven_username`,
        payload
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {});
        return data?.data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const addIncrementOrDeduction = createAsyncThunk(
  "admin/validate-raven-username",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `/payroll/add_or_deduct_from_employee_salary`,
        payload
      );
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {});
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPendingDisbursment = createAsyncThunk(
  "admin/getPendingDisbursment",
  async (payload, thunkAPI) => {
    try {
      // console.log("tyugfd");
      const { data } = await axios.get(
        `payroll/pending_salary_disbursement?per_page=${
          payload.per_page
        }&current_page=${payload.current_page}&range=${
          payload.range
        }&search_term=${payload?.search || "none"}`,
        payload
      );
      // console.log(data);
      if (data.status === "fail") {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setPendingDisbursement(data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPreviousDisbursbement = createAsyncThunk(
  "admin/getPreviousDisbursment",
  async (payload, thunkAPI) => {
    try {
      // console.log("tyugfd");
      const data = await axios.get(
        `/payroll/salary_disbursements_v2?date=${payload?.date}&per_page=${
          payload.per_page
        }&current_page=${payload.current_page}&search_term=${
          payload?.search || "none"
        }`,
        payload
      );

      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        // toast.error(data.data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {});
        await thunkAPI.dispatch(setPreviousDisbursement(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPendingDisburstmentTransactions = createAsyncThunk(
  "admin/getPendingDisbursmentTransaction",
  async (payload, thunkAPI) => {
    try {
      // console.log("tyugfd");
      const { data } = await axios.get(
        `payroll/salary_transactions/${payload?.ref}?per_page=${payload.per_page}&current_page=${payload.current_page}&range=${payload.range}`,
        payload
      );
      // console.log(data.data);
      if (data.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        await thunkAPI.dispatch(
          setPendingDisbursementTransaction(data.data.salary_transaction)
        );
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const generateAllCsvForEmp = createAsyncThunk(
  "admin/generate-all-csv-emp",
  async (payload, thunkAPI) => {
    try {
      // console.log("tyugfd");
      const data = await axios.get(
        `payroll/generate_and_download_list_of_employees?verification_status=${
          payload?.status || "all"
        }`,
        payload
      );
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {});
        return data;
      }
    } catch (err) {
      // ;
      // console.log("jhj");
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const getPendingDisburstmentTransactionDetail = createAsyncThunk(
  "admin/getPendingDisbursmentTransactiondetails",
  async (payload, thunkAPI) => {
    try {
      // console.log("tyugfd");
      const { data } = await axios.get(
        `payroll/single_salary_transactions/${payload}`
      );
      if (data.status === "fail") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(setPendingDisbursementTransaction(data.data.salary_transaction));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const downloadLoadUnVerifiedPendingCsv = createAsyncThunk(
  "admin/downloading-not-verified-csv-payroll",
  async (payload, thunkAPI) => {
    try {
      // console.log("tyugfd");
      const { data } = await axios.get(
        `/payroll/generate_csv_for_un_verified_employees`
      );
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.status === "success") {
        // console.log(data);
        // console.log("register", data.data.data.electricity_bill_trasactions);
        // await thunkAPI.dispatch(setElectricityStatements(data.data.data.electricity_bill_trasactions));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      }
      return err;
    }
  }
);

export const payroll = createSlice({
  name: "payroll",
  initialState: {
    payrolls: [],
    payroll: {},
    allEmployees: [],
    previous_disbursement: [],
    pending_disbursement: [],
    pending_disbursement_transaction: [],
    singleEmployee: {},
    isAuth: false,
    loading: false,
    loadingDetail: false,
    loadingManual: false,
    loadingSearch: false,
    loadingStatus: false,
    loadingDeduct: false,
    loadingDowloadSingle: false,
    loadingCsv: false,
    loadingStatement: false,
    loadingVerify: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },

  reducers: {
    setPayrolls: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.paymentLinks = action.payload;
    },
    setPreviousDisbursement: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.previous_disbursement = action.payload;
    },
    setPendingDisbursement: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.pending_disbursement = action.payload;
    },
    setAllEmployees: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.allEmployees = action.payload;
    },

    setFetchPayrollSettings: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.payrollSettings = action.payload;
    },

    setSingleEmployee: (state, action) => {
      state.isAuth = true;
      state.singleEmployee = action.payload;
    },
    setPendingDisbursementTransaction: (state, action) => {
      state.isAuth = true;
      state.pending_disbursement_transaction = action.payload;
    },
  },

  extraReducers: {
    [getPayrolls.pending]: (state) => {
      state.loading = true;
      state.loadingManual = true;
    },
    [getPayrolls.fulfilled]: (state) => {
      state.loading = false;
      state.loadingManual = false;
    },
    [getPayrolls.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.loadingManual = false;
      state.isAuth = false;
      state = null;
    },
    [generateAllCsvForEmp.pending]: (state) => {
      state.loadingCsv = true;
      // state.loadingCsvManual = true;
    },
    [generateAllCsvForEmp.fulfilled]: (state) => {
      state.loadingCsv = false;
      // state.loadingCsvManual = false;
    },
    [generateAllCsvForEmp.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingCsv = false;
      // state.loadingCsvManual = false;
      state.isAuth = false;
      state = null;
    },
    [getPayrollsBySearch.pending]: (state) => {
      state.loadingSearch = true;
    },
    [getPayrollsBySearch.fulfilled]: (state) => {
      state.loadingSearch = false;
    },
    [getPayrollsBySearch.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSearch = false;
      state.isAuth = false;
      state = null;
    },
    [validateRavenUserName.pending]: (state) => {
      state.loadingVerify = true;
      state.loadingSearch = true;
    },
    [validateRavenUserName.fulfilled]: (state) => {
      state.loadingVerify = false;
      state.loadingSearch = false;
    },
    [validateRavenUserName.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingVerify = false;
      state.loadingSearch = false;
      state.isAuth = false;
      state = null;
    },
    [getPayroll.pending]: (state) => {
      state.loading = true;
    },
    [getPayroll.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPayroll.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [enableDisableSponsorShip.pending]: (state) => {
      state.loading = true;
    },
    [enableDisableSponsorShip.fulfilled]: (state) => {
      state.loading = false;
    },
    [enableDisableSponsorShip.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deletePayroll.pending]: (state) => {
      state.loading = true;
    },
    [deletePayroll.fulfilled]: (state) => {
      state.loading = false;
    },
    [deletePayroll.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [manualAddEmployeeToPayroll.pending]: (state) => {
      state.loadingManual = true;
    },
    [manualAddEmployeeToPayroll.fulfilled]: (state) => {
      state.loadingManual = false;
    },
    [manualAddEmployeeToPayroll.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingManual = false;
      state.isAuth = false;
      state = null;
    },
    [updateEmployeeDetails.pending]: (state) => {
      state.loading = true;
      state.loadingDetail = true;
    },
    [updateEmployeeDetails.fulfilled]: (state) => {
      state.loading = false;
      state.loadingDetail = false;
    },
    [updateEmployeeDetails.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.loadingDetail = false;
      state.isAuth = false;
      state = null;
    },
    [getSalaryDisburseMents.pending]: (state) => {
      state.loading = true;
    },
    [getSalaryDisburseMents.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSalaryDisburseMents.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSingleEmployeeByEmail.pending]: (state) => {
      state.loading = true;
    },
    [getSingleEmployeeByEmail.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSingleEmployeeByEmail.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [downloadLoadUnVerifiedPendingCsv.pending]: (state) => {
      state.loadingManual = true;
    },
    [downloadLoadUnVerifiedPendingCsv.fulfilled]: (state) => {
      state.loadingManual = false;
    },
    [downloadLoadUnVerifiedPendingCsv.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingManual = false;
      state.isAuth = false;
      state = null;
    },
    [getPendingDisbursment.pending]: (state) => {
      state.loading = true;
    },
    [getPendingDisbursment.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPendingDisbursment.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [generateStatementTransactionForAllEmp.pending]: (state) => {
      state.loadingManual = true;
    },
    [generateStatementTransactionForAllEmp.fulfilled]: (state) => {
      state.loadingManual = false;
    },
    [generateStatementTransactionForAllEmp.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingManual = false;
      state.isAuth = false;
      state = null;
    },
    [getSingleEmployee.pending]: (state) => {
      state.loading = true;
    },
    [getSingleEmployee.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSingleEmployee.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getPendingDisburstmentTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getPendingDisburstmentTransactions.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPendingDisburstmentTransactions.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [changeEmployeePaymentStatus.pending]: (state) => {
      state.loadingStatus = true;
    },
    [changeEmployeePaymentStatus.fulfilled]: (state) => {
      state.loadingStatus = false;
    },
    [changeEmployeePaymentStatus.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatus = false;
      state.isAuth = false;
      state = null;
    },
    [getPendingDisburstmentTransactionDetail.pending]: (state) => {
      state.loadingDetail = true;
    },
    [getPendingDisburstmentTransactionDetail.fulfilled]: (state) => {
      state.loadingDetail = false;
    },
    [getPendingDisburstmentTransactionDetail.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingDetail = false;
      state.isAuth = false;
      state = null;
    },
    [addDeductionToEmployee.pending]: (state) => {
      state.loadingDetail = true;
    },
    [addDeductionToEmployee.fulfilled]: (state) => {
      state.loadingDetail = false;
    },
    [addDeductionToEmployee.rejected]: (state) => {
      state.loadingDetail = false;
      state.isAuth = false;
      state = null;
    },
    [managePayrollDeductionAndTax.pending]: (state) => {
      state.loadingDetail = true;
    },
    [managePayrollDeductionAndTax.fulfilled]: (state) => {
      state.loadingDetail = false;
    },
    [managePayrollDeductionAndTax.rejected]: (state) => {
      state.loadingDetail = false;
      state.isAuth = false;
      state = null;
    },
    [getPayrollSetup.pending]: (state) => {
      state.loading = true;
    },
    [getPayrollSetup.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPayrollSetup.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [approveDisbursements.pending]: (state) => {
      state.loadingDetail = true;
    },
    [approveDisbursements.fulfilled]: (state) => {
      state.loadingDetail = false;
    },
    [approveDisbursements.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingDetail = false;
      state.isAuth = false;
      state = null;
    },
    [downloadPayrollTemplate.pending]: (state) => {
      state.loading = true;
    },
    [downloadPayrollTemplate.fulfilled]: (state) => {
      state.loading = false;
    },
    [downloadPayrollTemplate.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [initialPayrollSettings.pending]: (state) => {
      state.loading = true;
    },
    [initialPayrollSettings.fulfilled]: (state) => {
      state.loading = false;
    },
    [initialPayrollSettings.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [autoAddEmployeeToPayroll.pending]: (state) => {
      state.loading = true;
    },
    [autoAddEmployeeToPayroll.fulfilled]: (state) => {
      state.loading = false;
    },
    [autoAddEmployeeToPayroll.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [generatePayrollSlipTemplate.pending]: (state) => {
      state.loading = true;
    },
    [generatePayrollSlipTemplate.fulfilled]: (state) => {
      state.loading = false;
    },
    [generatePayrollSlipTemplate.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [cancelDisbursment.pending]: (state) => {
      state.loading = true;
    },
    [cancelDisbursment.fulfilled]: (state) => {
      state.loading = false;
    },
    [cancelDisbursment.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [disburseCurrentPayroll.pending]: (state) => {
      state.loadingManual = true;
    },
    [disburseCurrentPayroll.fulfilled]: (state) => {
      state.loadingManual = false;
    },
    [disburseCurrentPayroll.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingManual = false;
      state.isAuth = false;
      state = null;
    },
    [deactivateUserPayrollAll.pending]: (state) => {
      state.loadingManual = true;
      state.loadingStatement = true;
    },
    [deactivateUserPayrollAll.fulfilled]: (state) => {
      state.loadingManual = false;
      state.loadingStatement = false;
    },
    [deactivateUserPayrollAll.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingManual = false;
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [clearUserPayrollAll.pending]: (state) => {
      state.loadingDetail = true;
    },
    [clearUserPayrollAll.fulfilled]: (state) => {
      state.loadingDetail = false;
    },
    [clearUserPayrollAll.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingDetail = false;
      state.isAuth = false;
      state = null;
    },
    [approveCurrentPayroll.pending]: (state) => {
      state.loadingManual = true;
    },
    [approveCurrentPayroll.fulfilled]: (state) => {
      state.loadingManual = false;
    },
    [approveCurrentPayroll.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingManual = false;
      state.isAuth = false;
      state = null;
    },
    [addIncrementOrDeduction.pending]: (state) => {
      state.loadingDeduct = true;
    },
    [addIncrementOrDeduction.fulfilled]: (state) => {
      state.loadingDeduct = false;
    },
    [addIncrementOrDeduction.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingDeduct = false;
      state.isAuth = false;
      state = null;
    },
    [generateStatementTransactionForAllEmp.pending]: (state) => {
      state.loadingDowloadSingle = true;
    },
    [generateStatementTransactionForAllEmp.fulfilled]: (state) => {
      state.loadingDowloadSingle = false;
    },
    [generateStatementTransactionForAllEmp.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingDowloadSingle = false;
      state.isAuth = false;
      state = null;
    },
    [getPreviousDisbursbement.pending]: (state) => {
      state.loading = true;
    },
    [getPreviousDisbursbement.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPreviousDisbursbement.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [generateStatementTransactionForAllEmpSingle.pending]: (state) => {
      state.loadingDowloadSingle = true;
    },
    [generateStatementTransactionForAllEmpSingle.fulfilled]: (state) => {
      state.loadingDowloadSingle = false;
    },
    [generateStatementTransactionForAllEmpSingle.rejected]: (state) => {
      state.loadingDowloadSingle = false;
      state.isAuth = false;
      state = null;
    },
    [generateStatementAllTransactionForAllEmp.pending]: (state) => {
      state.loadingManual = true;
      state.loadingStatement = true;
    },
    [generateStatementAllTransactionForAllEmp.fulfilled]: (state) => {
      state.loadingManual = false;
      state.loadingStatement = false;
    },
    [generateStatementAllTransactionForAllEmp.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingManual = false;
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setPaymentLinks,
  setAllEmployees,
  setSingleEmployee,
  setPreviousDisbursement,
  setPendingDisbursement,
  setPendingDisbursementTransaction,
  setFetchPayrollSettings,
} = payroll.actions;

export default payroll.reducer;
