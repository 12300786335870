import { RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/bill-payment/Airtime.css";
import mtnICon from "../../../../assets/bill-mtn-icon.svg";
import airtelICon from "../../../../assets/bill-airtel-icon.svg";
import mobileICon from "../../../../assets/bill-moblie-icon.svg";
import gloICon from "../../../../assets/bill-glo-icon.svg";
import addIcon from "../../../../assets/add-icon-form.svg";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";
// import PreviewAirtimeDetailsBox from "./PreviewAirtime";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";
import { DetectNetwork } from "../../../../utils/helper/DetectNetwork";
import {
  filterEmptyColumns,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  getAllAmount,
} from "../../../../utils/helper/Helper";
import ActionContext from "../../../../context/ActionContext";
import { useSelector, useDispatch } from "react-redux";
import SuccessModal from "../../../../components/reusables/SuccessModal";
// import moment from "moment";
import { buyAirtime } from "../../../../redux/billPayment";
import { getAllAccounts, getUser } from "../../../../redux/user";
import useWindowSize from "../../../../utils/helper/UseWindowSize";
import NewPreviewDetailBox from "./NewPreviewAirtime";

const DashboardBillPaymentAirtime = () => {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState("");
  const [previewOpen, setPreviewOpen] = useState("");
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.billPayment);
  const actionCtx = useContext(ActionContext);
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const [urlBack, setUrlBack] = useState("");
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [viewAll, setViewAll] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [details, setDetails] = useState({
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    provider: "",
    type: "",
    amount: "",
    phone: "",
    user_pin: "",
    schedule: "",
    schedule_date: "",
    recipients: [],
  });

  DetectNetwork(
    formatNumWithoutCommaNaira(`0${details?.phone}`),
    setSelectedProvider
  );

  const providerList = [
    { name: "mtn", icon: mtnICon },
    { name: "mobile", icon: mobileICon },
    { name: "airtel", icon: airtelICon },
    { name: "glo", icon: gloICon },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  // useEffect(() => {
  //   if (
  //     !user_resource_and_permissions?.allowed_permissions?.includes(
  //       "can_view_bill_payments"
  //     )
  //   ){
  //     navigate("/dashboard-bill-payment")
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user_resource_and_permissions])

  const stepList = ["Transaction detail", "Confirm Transactions"];
  useState(() => {
    const url = window?.location?.href?.split("?")[1];
    if (url) {
      setUrlBack(url);
    }
  }, []);

  const handleBulkTransfer = () => {
    const checkList = details?.bulk_list?.some(
      (chi) => chi?.phone === details?.phone
    );
    if (!checkList) {
      const obj = {
        phone: details?.phone,
        amount: details?.amount,
        // beneficiary: false,
        provider: selectedProvider,
      };
      const newList = [obj, ...details?.bulk_list];
      //   console.log(newList);
      setSelectedProvider("");
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          phone: "",
          amount: "",
        };
      });
    } else {
      toast.error("Recepient already exist in bulk list");
    }
  };

  const handleRemoveFromList = (obj) => {
    if (details?.bulk_list?.length === 1) {
      setViewAll(false);
    }
    const newList = details?.bulk_list?.filter(
      (chi) => chi?.phone !== obj?.phone
    );
    setDetails((prev) => {
      return {
        ...prev,
        bulk_list: newList,
      };
    });
  };

  const handleAddBeneficiary = (obj, val) => {
    if (details?.bulk_list?.length > 0) {
      const newList = details?.bulk_list?.map((chi) =>
        chi?.phone === obj?.phone ? { ...chi, beneficiary: val } : chi
      );
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
        };
      });
    } else {
      const newObj = { ...details, beneficiary: val };
      setDetails(newObj);
    }
  };

  const handleNextStep = (param) => {
    if (param === "one") {
      setStep(2);
      // console.log(details);
    }
    if (param === "two") {
      if (user?.pin_status === 0) {
        actionCtx.setCheckShow("nine");
      } else {
        setShowModal((prev) => {
          return { ...prev, pin: true };
        });
      }
      // setShowModal((prev) => {
      //   return { ...prev, pin: true };
      // });
    }
  };

  const handleSubmit = async (pin, token) => {
    let val;
    if (details?.bulk_list?.length > 0) {
      const newList = details?.bulk_list?.map((chi) => {
        return {
          amount: formatNumWithoutCommaNaira(chi?.amount),
          phone: `0${formatNumWithoutCommaNaira(chi?.phone)}`,
          network: chi?.provider,
        };
      });
      const obj = {
        recipients: newList,
        type: "1",
        user_pin: pin,
        two_factor_token: token,
        schedule: fromDate ? "later" : "now",
        schedule_date: fromDate ? fromDate : "",
        selected_account_number: actionCtx?.currentAccount?.account_number,
      };
      val = obj;
    } else {
      const obj = {
        type: "0",
        recipients: [
          {
            amount: formatNumWithoutCommaNaira(details?.amount),
            phone: `0${formatNumWithoutCommaNaira(details?.phone)}`,
            network: selectedProvider,
          },
        ],
        user_pin: pin,
        two_factor_token: token,
        schedule: fromDate ? "later" : "now",
        schedule_date: fromDate ? fromDate : "",
        selected_account_number: actionCtx?.currentAccount?.account_number,
      };
      val = obj;
    }
    // console.log(val);
    // return
    const data = await dispatch(buyAirtime(filterEmptyColumns(val)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    setSelectedProvider("");
    dispatch(getUser());
    dispatch(getAllAccounts());
    setDetails({
      narration: "Sent from raven",
      bulk_list: [],
      beneficiary: false,
      provider: "",
      type: "",
      amount: "",
      phone: "",
      user_pin: "",
      schedule: "",
      schedule_date: "",
      recipients: [],
    });
    setStep(1);
    setShowModal((prev) => {
      return { ...prev, success: true, pin: false };
    });
  };

  const size = useWindowSize();

  useEffect(() => {
    if (
      actionCtx?.currentAccount?.authorization_level !==
      ("initiator_approver" || "initiator") && actionCtx?.currentAccount?.authorization_level !==
      ("initiator" || "initiator_approver" )
    ) {
      navigate("/dashboard-bill-payment");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Buy Airtime`}
          text={`Recharge your phone with any network`}
          back={true}
          onBack={() => {
            urlBack
              ? navigate(`/${urlBack}`)
              : navigate("/dashboard-bill-payment");
          }}
        />
        {/* title box end */}
        <ContainerWrapper
          width={size?.width < 900 && `100%`}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          stepClickable={
            details?.bulk_list.length > 0
              ? details?.bulk_list.length > 0
              : details?.amount && details?.phone && selectedProvider
          }
          onStepClick={(e) => {
            details?.bulk_list.length > 0
              ? setStep(e)
              : details?.amount &&
                details?.phone &&
                selectedProvider &&
                setStep(e);
          }}
          activeStep={step}
          stepList={stepList}
          btnText={`Complete Transaction`}
          disabled={
            step === 1
              ? details?.bulk_list.length > 0
                ? details?.bulk_list.length < 1
                : !details?.amount || !details?.phone || !selectedProvider
              : false
          }
          onBtnClick={() => {
            step === 1 && handleNextStep("one");
            step === 2 && handleNextStep("two");
          }}
        >
          <div className="bill-payment-airtime-wrap animate-move-up-class">
            {step === 1 && (
              <>
                {/* select type box start */}
                <div className="select-type-box ">
                  <p className="title dull-bright-filter-two">Select Network</p>
                  {/* select wrap start */}
                  <div className="select-wrap ">
                    {providerList.map((chi, idx) => {
                      const { icon, name } = chi;
                      return (
                        <div className={`child-wrap`} key={idx}>
                          <figure
                            className={`img-box select-option-class  ${
                              selectedProvider === name &&
                              "select-option-class-active"
                            }`}
                            onClick={() => {
                              setSelectedProvider(name);
                              setDetails((prev) => {
                                return { ...prev, provider: name };
                              });
                            }}
                          >
                            <div className="check-box">
                              <FaCheck className="icon" />
                            </div>
                            <img src={icon} alt="" className="img" />
                          </figure>
                        </div>
                      );
                    })}
                  </div>
                  {/* select wrap end */}
                </div>
                {/* select type box end */}
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    label="Phone number* "
                    labelSpanText="Use my number"
                    labelColor={`purple-light`}
                    type={`phone`}
                    name={`phone`}
                    placeholder={`E.g 816263....`}
                    labelClassName={`label-span-class`}
                    onChange={handleChange}
                    value={details?.phone}
                    onActionClick={() =>
                      setDetails((prev) => {
                        return {
                          ...prev,
                          phone:
                            user?.phone.slice(0, 1) === "0"
                              ? user?.phone.replace("0", "")
                              : user?.phone,
                        };
                      })
                    }
                  />
                </form>
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    label="Amount* "
                    onActionClick={() => {
                      actionCtx?.setShowAmount(!actionCtx?.showAmount);
                    }}
                    labelSpanText={`Bal: ${
                      actionCtx?.showAmount
                        ? formatNumWithCommaNairaSymbol(
                            actionCtx?.currentAccount?.balance
                          )
                        : "••••••"
                    }`}
                    labelColor={`black-light`}
                    labelClassName={`label-span-amount label-span-theme`}
                    type={`number`}
                    thousandFormat
                    placeholder="How much are you sending"
                    name={`amount`}
                    onChange={handleChange}
                    value={details?.amount}
                  />
                </form>

                {/* <RavenInputField
                  color={`black-light`}
                  label="Narration* "
                  labelColor={`black-light`}
                  type={`text`}
                  placeholder="E.g From timi"
                  name={`narration`}
                  onChange={handleChange}
                  value={details?.narration}
                /> */}
                {/* add another text start */}
                <div
                  onClick={() => {
                    selectedProvider &&
                      details?.amount &&
                      details?.phone &&
                      handleBulkTransfer();
                  }}
                  className={`add-another-box ${
                    selectedProvider &&
                    details?.amount &&
                    details?.phone &&
                    "add-another-box-active"
                  }`}
                >
                  <figure className="img-box">
                    <img src={addIcon} alt="" className="img" />
                  </figure>
                  <p className="text">Recharge for another recipient</p>
                </div>
                {/* add another text end */}
                {/* recepient box start */}
                <div
                  className={`recepient-box ${
                    details?.bulk_list?.length > 0 && "recepient-box-show"
                  }`}
                >
                  <div className="text-line">
                    <span></span>
                    <p className="text">Recipients</p>
                    <span></span>
                  </div>
                  <div className="view-total-box">
                    <p className="total">{`Total added (${details?.bulk_list?.length})`}</p>
                    <div className="view-wrap">
                      {/* view all box start */}
                      <div
                        className={`view-all-box card-bg ${
                          viewAll && "view-all-box-show"
                        }`}
                      >
                        <div className="wrap">
                          {details?.bulk_list?.map((chi, idx) => {
                            return (
                              <div key={idx} className="child-wrap">
                                <figure className="img-box">
                                  <img
                                    src={
                                      chi?.provider === "mtn"
                                        ? mtnICon
                                        : chi?.provider === "glo"
                                        ? gloICon
                                        : chi?.provider === "airtel"
                                        ? airtelICon
                                        : mobileICon
                                    }
                                    alt=""
                                    className="img"
                                  />
                                </figure>
                                <div className="text-box">
                                  <p className="name">{`+234 ${chi?.phone}`}</p>
                                  <p className="text grey-white-color">{` ₦ ${chi?.amount}`}</p>
                                </div>
                                <div
                                  onClick={() => {
                                    handleRemoveFromList(chi);
                                  }}
                                  className="cancel-box"
                                >
                                  <FaTimes className="icon" />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* view all box end */}
                      {viewAll ? (
                        <p
                          onClick={() => {
                            setViewAll(false);
                            details?.bulk_list?.length === 1 &&
                              setViewAll(false);
                          }}
                          className="text"
                        >
                          Close
                        </p>
                      ) : (
                        <p
                          onClick={() => {
                            setViewAll(true);
                          }}
                          className="text"
                        >
                          View all
                        </p>
                      )}
                    </div>
                  </div>
                  {/* end of view total box */}
                  {/* preview list start */}
                  {!viewAll && (
                    <div className="preview-box">
                      {details?.bulk_list?.slice(0, 2).map((chi, idx) => {
                        const { phone, amount, provider } = chi;
                        return (
                          <div className="box box-card grey-bg" key={idx}>
                            <div
                              onClick={() => {
                                handleRemoveFromList(chi);
                              }}
                              className="cancel-box"
                            >
                              <FaTimes className="icon" />
                            </div>
                            <figure className="img-box">
                              <img
                                src={
                                  provider === "mtn"
                                    ? mtnICon
                                    : provider === "glo"
                                    ? gloICon
                                    : provider === "airtel"
                                    ? airtelICon
                                    : mobileICon
                                }
                                alt=""
                                className="img"
                              />
                            </figure>
                            <p className="name">{`+234 ${phone}`}</p>
                            <p className="text grey-white-color-white">{` ₦ ${amount}`}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* preview list end */}

                  <div className="space-bottom"></div>
                </div>
                {/* recepient box end */}
              </>
            )}
            {step === 2 && (
              <>
                {/* select schedule start */}
                {/* <SelectScheduleBox
                  textSchedule={`When would you like to send this airtime?`}
                  id={`onupd-iu`}
                  value={fromDate}
                  onChange={(e) => setFromDate(e)}
                  onReset={() => setFromDate("")}
                /> */}
                {/* select schedule end */}
                {details?.bulk_list?.length > 0 ? (
                  // <div
                  //   style={{
                  //     display: "flex",
                  //     flexDirection: "column",
                  //     gap: "2rem",
                  //   }}
                  // >
                  //   {details?.bulk_list?.length === 1 ? (
                  //     <>
                  //       {details?.bulk_list?.map((chi, idx) => {
                  //         const {
                  //           phone,
                  //           amount,
                  //           beneficiary,
                  //           narration,
                  //           provider,
                  //         } = chi;
                  //         return (
                  //           <PreviewAirtimeDetailsBox
                  //             key={idx}
                  //             value={beneficiary}
                  //             onChange={(e) => {
                  //               handleAddBeneficiary(chi, e.target.checked);
                  //             }}
                  //             provider={provider}
                  //             number={phone}
                  //             narration={narration}
                  //             // collapse
                  //             amount={amount}
                  //             checked={beneficiary}
                  //           />
                  //         );
                  //       })}
                  //     </>
                  //   ) : (
                  //     <>
                  //       {details?.bulk_list?.map((chi, idx) => {
                  //         const {
                  //           // name,
                  //           amount,
                  //           beneficiary,
                  //           narration,
                  //           phone,
                  //           provider,
                  //         } = chi;
                  //         return (
                  //           <PreviewAirtimeDetailsBox
                  //             key={idx}
                  //             id={idx}
                  //             value={beneficiary}
                  //             onChange={(e) => {
                  //               handleAddBeneficiary(chi, e.target.checked);
                  //             }}
                  //             previewOpen={previewOpen}
                  //             setPreviewOpen={setPreviewOpen}
                  //             provider={provider}
                  //             number={phone}
                  //             narration={narration}
                  //             collapse
                  //             amount={amount}
                  //             checked={beneficiary}
                  //           />
                  //         );
                  //       })}
                  //     </>
                  //   )}
                  //   {/* <div className="space-bottom"></div> */}
                  // </div>
                  <NewPreviewDetailBox
                    collapse={true}
                    list={details?.bulk_list}
                    onReset={() => setFromDate("")}
                    valueSchedule={fromDate}
                    onChangeSchedule={(e) => {
                      setFromDate(e);
                    }}
                    value={details?.beneficiary}
                    onChange={(e) => {
                      handleAddBeneficiary(e.target.checked);
                    }}
                    amount={details?.amount}
                    name={details?.username}
                    narration={details?.narration}
                    checked={details?.beneficiary}
                    previewOpen={previewOpen}
                    setPreviewOpen={setPreviewOpen}
                    provider={details?.provider || selectedProvider}
                    number={details?.phone}
                    bulkAmount={
                      details?.bulk_list?.length > 0
                        ? formatNumWithCommaNairaSymbol(
                            getAllAmount(details?.bulk_list)
                          )
                        : formatNumWithCommaNairaSymbol(0)
                    }
                    handleRemoveFromList={(obj) => {
                      //   console.log(listTableToSubmit?.length);
                      if (step === 2 && details?.bulk_list?.length === 1) {
                        setStep(1);
                      }
                      handleRemoveFromList(obj);
                    }}
                  />
                ) : (
                  <>
                    <NewPreviewDetailBox
                      onReset={() => setFromDate("")}
                      valueSchedule={fromDate}
                      onChangeSchedule={(e) => {
                        setFromDate(e);
                      }}
                      value={details?.beneficiary}
                      onChange={(e) => {
                        handleAddBeneficiary(e.target.checked);
                      }}
                      amount={details?.amount}
                      name={details?.username}
                      narration={details?.narration}
                      checked={details?.beneficiary}
                      previewOpen={previewOpen}
                      setPreviewOpen={setPreviewOpen}
                      provider={details?.provider || selectedProvider}
                      number={details?.phone}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, user_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Airtime Purchase Successful."
        text={` You should receive a confirmation message with the details of your purchase shortly.  We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default DashboardBillPaymentAirtime;
