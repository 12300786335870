import { RavenButton, RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { downloadTransactionReciept } from "../../../redux/transaction";
import "../../../styles/dashboard/schedule-payment/ViewScheduleModal.css";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
} from "../../../utils/helper/Helper";

const ModalOverviewModalInvoice = ({
  onClose,
  visible,
  param,
  detail,
  onDelete,
}) => {
  const navigate = useNavigate();
//   const { loadingDownload } = useSelector((state) => state.transaction);
//   const dispatch = useDispatch();
//   const formatMetaData = (param) => {
//     if (param) {
//       const obj = JSON?.parse(param);
//       // console.log(obj);
//       return obj;
//     }
//   };

//   const handleSubmit = async () => {
//     const obj = {
//       transaction_id: String(detail?.id),
//     };
//     const data = await dispatch(downloadTransactionReciept(obj));
//     if (data?.payload?.data?.status === "success") {
//       // setDownloadCsvId("");
//       downloadCsv(data?.payload?.data?.data?.url);
//       // downloadCsvId("");
//       // console.log(data);
//     }
//   };

//   const downloadCsv = (url) => {
//     window.open(url, "_blank").focus();
//   };

  return (
    <RavenModal
      visble={visible}
      className="view-schedule-payment-modal"
      onClose={() => {
        onClose();
      }}
    >
      <div className="wrap-modal">
        <p className="title">Invoice details</p>
        <div className="underline"></div>
        {/* label-value-status box start */}
        <div className="label-value-status-box">
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Invoice No:</p>
            <p className="value">
              {/* {formatNumWithCommaNairaSymbol(detail?.gross || 0)} */}
              {detail?.displayId || "---"}
            </p>
          </div>
          {/* box end */}
          {/* type start ----------------- */}
          {/* displayId,
                        title,
                        customer_name,
                        due_date,
                        gross,
                        status,
                        net, */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Title </p>
            <p className="value">{detail?.title || "---"}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Customer Name</p>
            <p className="value">{detail?.customer_name || "---"}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Due date</p>
            <p className="value">{formatDate(detail?.due_date) || "---"}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Gross amount</p>
            <p className="value">
              {" "}
              {formatNumWithCommaNairaSymbol(detail?.gross || 0)}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Net amount</p>
            <p className="value">
              {" "}
              {formatNumWithCommaNairaSymbol(detail?.net || 0)}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Status </p>
            <div
              className={`status-type-wrap ${
                detail?.status === 0 && "status-type-wrap-pending"
              } ${detail?.status === 2 && "status-type-wrap-success "} ${
                detail?.status === 3 && "status-type-wrap-fail"
              } ${detail?.status === 1 && "status-type-wrap-pending"} ${
                detail?.status === 0 && "status-type-wrap-pending"
              }`}
            >
              <span>
                {" "}
                {detail?.status === 1
                  ? "Unpaid"
                  : detail?.status === 0
                  ? "Pending"
                  : detail?.status === 3
                  ? "Due"
                  : detail?.status === 2
                  ? "Paid"
                  : detail?.status}
              </span>
            </div>
          </div>
          {/* box end */}
        </div>
        {/* label-value-status box end */}
      </div>
      <div className="two-button-wrap">
        <RavenButton
          onClick={() => {
            onDelete();
          }}
          //   style={{ gridColumn: "1 / -1" }}
          color={`error-light`}
          label={`Delete`}
          //   style={{width: reschedule && "max-content"}}
        />
        <RavenButton
          onClick={() => {
            localStorage?.setItem("invoice-single-id", detail?.id);
            navigate("/dashboard-invoice-preview");
          }}
          //   style={{ gridColumn: "1 / -1" }}
          color={`black-light`}
          label={`Edit`}
          //   style={{width: reschedule && "max-content"}}
        />
      </div>
    </RavenModal>
  );
};

export default ModalOverviewModalInvoice;
