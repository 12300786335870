import React, { useContext, useEffect, useState } from "react";
import AuthLayoutTwo from "../../layout/auth/AuthLayoutTwo";
import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import "../../styles/auth/SignUp.css";
import { RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";
import LoginModal from "./modal/LoginModal";
import { useSelector, useDispatch } from "react-redux";
import { authenticateLogin, loginUser } from "../../redux/user";
import {
  validateEmail,
  validatePasswordLowercase,
  validatePasswordNumber,
  // validatePasswordSpecialCharacter,
  validatePasswordUpperCase,
} from "../../utils/helper/ValidateInput";
import ActionContext from "../../context/ActionContext";
import AuthAccountSelectModal from "./modal/AuthAccountSelectModal";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { clearCache, formatMetaData } from "../../utils/helper/Helper";
import CryptoJS from "crypto-js";
import TwoFaModal from "./modal/TwoFaModal";
// import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const { loading } = useSelector((state) => state.user);
  const [useSSo, setUseSSo] = useState(false);
  const [inviteProcess, setinviteProcess] = useState(false);
  // const [withoutOtpDetails, setWithoutOtpDetails] = useState({});
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const [businessArray, setBusinessArray] = useState([]);
  const [showModal, setShowModal] = useState({
    pin: false,
    select: false,
    twoFa: false,
  });
  const [details, setDetails] = useState({
    email: "",
    password: "",
    pin: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
    password_msg: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  // useEffect(() => {
  //   dispatch(loginUser())
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  const [deviceIdVal, seDeviceIdVal] = useState("");
  useEffect(() => {
    // Generate a fingerprint
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();

      // Use the fingerprint as the device identifier
      const deviceId = result.visitorId;

      // Store the device identifier in local storage
      // console.log(deviceId);
      seDeviceIdVal(deviceId);
    };

    getFingerprint();
  }, []);

  const formSelectList = (list) => {
    // if (list?.length > 0) {
    //   const newList = list?.filter(
    //     (chi) => Number(chi?.confirmation_status) === 1
    //   );
    //   return newList;
    // }
    // console.log(list);
    return list;
  };

  useEffect(() => {
    const email = localStorage?.getItem("invite--email");
    if (email) {
      setinviteProcess(true);
      setDetails((prev) => {
        return { ...prev, email: email };
      });
    } else {
      localStorage?.removeItem("invite--email");
    }
    // console.log(email);
  }, []);

  const [twoFactor, setTwoFactor] = useState(false);

  const handleSubmit = async () => {
    actionCtx?.setIsLogout(false);

    if (!checkLoginDetails()) {
      return;
    }

    const obj = { ...details, device_id: deviceIdVal };
    // console.log(obj);
    const data = await dispatch(loginUser(obj));
    // console.log(data);
    if (data?.payload?.data?.action === "verify_email") {
      setShowModal((prev) => {
        return { ...prev, pin: true };
      });
      setUseSSo(true);
    }
    if (
      data?.payload?.status === "success" &&
      data?.payload?.data?.action !== "verify_email"
    ) {
      // console.log(data);
      const two_factor = data?.payload?.data?.two_factor;
      if (two_factor) {
        setTwoFactor(true);
        if (data?.payload?.data?.direct_login === 1) {
          handleDirectLogin(data);
        } else {
          setShowModal((prev) => {
            return { ...prev, pin: true };
          });
        }
      } else {
        setTwoFactor(false);
        if (data?.payload?.data?.direct_login === 1) {
          handleDirectLogin(data);
        } else {
          setShowModal((prev) => {
            return { ...prev, pin: true };
          });
        }
      }

      setUseSSo(false);
    }
    if (data?.payload?.response?.data?.message === "Invalid password") {
      setError((prev) => {
        return {
          ...prev,
          password: true,
          password_msg: data?.payload?.response?.data?.message,
        };
      });
    }
  };

  const handleDirectLogin = (data) => {
    const obj = {
      ...details,
      device_id: deviceIdVal,
      authentication_key: data?.payload?.data?.key_value,
    };
    const businesses = formSelectList(data?.payload?.data?.businesses);
    // console.log(businesses);
    setDetails((prev) => {
      return { ...prev, ...obj };
    });
    if (businesses?.length > 1) {
      setBusinessArray(businesses);
      setShowModal((prev) => {
        return { ...prev, select: true };
      });
    }
    if (businesses?.length <= 1) {
      const objDirect = {
        ...obj,
        business_id: String(businesses[0]?.business_id),
      };
      handleSubmitDirect(objDirect);
    }
  };

  const encryptTokenFunc = (tok) => {
    const token = tok;
    const secretKey =
      "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

    // Encrypt the token
    const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
    return encryptedToken;
  };

  const handleSubmitDirect = async (objDetails) => {
    // e.preventDefault();
    // return
    const data = await dispatch(authenticateLogin(objDetails));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      // localStorage.setItem(
      //   "baby-theme-face",
      //   encryptTokenFunc(details?.password)
      // );
      actionCtx?.setIsLogout(false);
      // return
      clearCache();
      const token = data?.payload?.data?.data?.token;
      localStorage?.setItem("token", encryptTokenFunc(token));
      const business = data?.payload?.data?.data?.business;
      const user = data?.payload?.data?.data?.user;
      localStorage?.setItem(
        "sidebar-theme-style",
        formatMetaData(user?.theme_display_settings)?.side_nav_style
      );
      actionCtx?.setPerPage({
        label: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
        value: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
      });
      localStorage?.setItem(
        "theme",
        formatMetaData(user?.theme_display_settings)?.theme
      );
      localStorage.setItem("bvn_status", business?.bvn);
      checkNavigate(business);

      // onSuccess();
    }
  };

  const infoIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15940_68964)">
        <path
          d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6024 18.3332 10C18.3332 5.39765 14.6022 1.66669 9.99984 1.66669C5.39746 1.66669 1.6665 5.39765 1.6665 10C1.6665 14.6024 5.39746 18.3334 9.99984 18.3334Z"
          fill="#EA872D"
        />
        <path
          d="M9.99984 6.66669V10M9.99984 13.3334H10.0082M18.3332 10C18.3332 14.6024 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6024 1.6665 10C1.6665 5.39765 5.39746 1.66669 9.99984 1.66669C14.6022 1.66669 18.3332 5.39765 18.3332 10Z"
          stroke="#FFF6ED"
          strokeWidth="1.43293"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15940_68964">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const checkNavigate = (business) => {
    if (business?.bvn !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      return;
    }
    if (business?.nin !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-cac");
      return;
    }
    if (business?.cac !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-cac");
      return;
    }
    if (business?.address !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-address");
      return;
    }
    if (business?.business_info !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-business");
      return;
    }
    // onSuccess();
    const urlLink = window?.location.href.split("?")[1];
    if (urlLink) {
      navigate(`/${urlLink}`);
    } else {
      navigate("/dashboard-overview");
    }
  };

  const checkLoginDetails = () => {
    let val;
    if (!validateEmail(details.email)) {
      toast.error("Invalid email address!", { position: "top-right" });
      setError((prev) => {
        return { ...prev, email: true };
      });
      val = false;
      return;
    } else if (!validatePasswordLowercase(details?.password)) {
      toast.error("Password should have at least 1 lowercase letter", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      val = false;
      return;
    } else if (!validatePasswordUpperCase(details?.password)) {
      toast.error("Password should have at least 1 capital letter", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      val = false;
      return;
    } else if (!validatePasswordNumber(details?.password)) {
      toast.error("Password should have at least  1 numeric character.", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      val = false;
      return;
    } else {
      val = true;
    }

    return val;
  };

  return (
    <AuthLayoutTwo>
      {/* login start */}
      <div className="sign-in-wrap">
        {/* form start */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            actionCtx?.setIsLogout(false);
          }}
          action=""
          className="form"
        >
          {/* security tip start */}
          <div className="security-tip-wrap">
            <div className="img-wrap">
              <figure className="img-box">{infoIcon}</figure>
            </div>
            <p className="text">
              Confirm if this url is{" "}
              <span>
                <a href="https://business.getravenbank.com">
                  https://business.getravenbank.com
                </a>{" "}
              </span>{" "}
            </p>
          </div>
          {/* security tip end */}
          <div className="text-box">
            <p className="big-title">Welcome Back Champ 🙌🏽</p>
            <p className="small-title">
              Explore financial products right for your business.
            </p>
          </div>
          <RavenInputField
            disabled={inviteProcess}
            type={`email`}
            label={`Email address * `}
            placeholder="Your email address here"
            color={`black-light`}
            name="email"
            onChange={(e) => {
              // console.log(e.target.value);
              handleChange(e);
              setError((prev) => {
                return { ...prev, email: false };
              });
            }}
            value={details?.email}
            showError={error?.email}
            removeAutoComplete={true}
          />
          <RavenInputField
            type={`password`}
            label={`Password *`}
            placeholder="Your password here"
            color={`black-light`}
            name="password"
            onChange={(e) => {
              handleChange(e);
              setError((prev) => {
                return { ...prev, password: false, password_msg: "" };
              });
            }}
            value={details?.password}
            showColor={`purple-light`}
            showError={error?.password}
            errorText={error?.password_msg}
          />
          {/* forget text start */}
          <p
            onClick={() => {
              navigate("/forget-password");
            }}
            className="forget-text"
          >
            Forgot Password?
          </p>
          {/* forget text end */}
          <RavenInputField
            type={`submit`}
            color="black-light"
            value={`Login`}
            disabled={!details?.email || !details.password}
            className="btn-submit"
            onSubmit={handleSubmit}
            loading={loading}
          />
          {/* already have accout start */}
          <p className="already-text">
            Don’t have an account?{" "}
            <span
              onClick={() => {
                navigate("/sign-up");
              }}
            >
              Create account
            </span>
          </p>
          {/* already have account end */}
        </form>
        {/* form end */}
      </div>
      {/* login end */}
      {/* sign up modal start */}
      <LoginModal
        deviceId={deviceIdVal}
        userEmail={details?.email}
        // showModal={true}
        showModal={showModal.pin}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        twoFactor={twoFactor}
        sso={useSSo}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, pin: e };
          });
        }}
        loginDetails={details}
        setBusiness={(param) => {
          setBusinessArray(param);
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
          setShowModal((prev) => {
            return { ...prev, select: true };
          });
        }}
      />
      {/* sign up modal end */}
      {/* two fa modal */}
      <TwoFaModal
        showModal={showModal?.twoFa}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, twoFa: false };
          });
        }}
        onSuccess={() => {}}
      />
      {/* two fa modal end */}
      {/* auth select account modal start */}
      <AuthAccountSelectModal
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, select: false };
          });
        }}
        visible={showModal?.select}
        selectList={businessArray}
        loginDetails={details}
        sso={useSSo}
        deviceId={deviceIdVal}
      />
    </AuthLayoutTwo>
  );
};

export default Login;
