/* eslint-disable jsx-a11y/iframe-has-title */
import {
  RavenButton,
  RavenCheckBox,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React from "react";
import "../../../styles/dashboard/global-account/TermsAndConditionModal.css";

const TermsAndConditionModal = ({
  visible,
  onClose,
  agreeBox,
  setAgreeBox,
  srcUrl,
}) => {
  return (
    <>
      <RavenModal
        btnColor="black-light"
        // btnLabel="Done"
        className={`global-account-terms-and-condition-modal-box-wrap`}
        visble={visible}
        onClose={onClose}
        disabled={!agreeBox}
        onBtnClick={onClose}
        outerClose={true}
        dontShowClose={true}
      >
        <div className="global-account-terms-and-condition-modal-box">
          <div className="frame-box">
            <iframe
              id="embeddedFrame"
              //   src="https://getravenbank.com/terms-and-conditions?tab=global%2520accounts&hideLayout=true"
              src={srcUrl || "https://getravenbank.com/terms-and-conditions?tab=global%2520accounts&hideLayout=true"}
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </div>
          {/* agree checkbox wrap start */}
          <div className="btn-aggree-box">
            <div className="agree-checkbox-wrap">
              <RavenCheckBox
                checked={agreeBox}
                onChange={() => {
                  setAgreeBox(!agreeBox);
                }}
                color="black-light"
                key={`kjvbjskjos`}
              />
              <p className="text grey-white-color">
                I hereby accept and agree to the terms and conditions
                {/* <span>
                {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "rgba(117, 90, 226, 1)",
                  }}
                  href="https://getravenbank.com/terms-and-conditions?tab=global%2520accounts"
                >
                  terms and conditions
                </a>
              </span> */}
              </p>
            </div>
            <RavenButton
              onClick={onClose}
              color="black-light"
              label="Done"
              disabled={!agreeBox}
              className="btn"
            />
          </div>

          {/* agree checkbox wrap end */}
        </div>
      </RavenModal>
    </>
  );
};

export default TermsAndConditionModal;
