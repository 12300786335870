import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import "../../../styles/dashboard/payroll/PayrollCretaeIndex.css";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import {
  RavenCheckBox,
  RavenInputField,
  // RavenToggleSlide,
  toast,
} from "@ravenpay/raven-bank-ui";
import bankIcon from "../../../assets/payroll-other-bank-icon.svg";
import ravenIcon from "../../../assets/payroll-raven-bank-icon.svg";
import { FaCheck } from "react-icons/fa";
// import yellowInfo from "../../../assets/yellow-info.svg";
// import switchIcon from "../../../assets/payroll-switch-icon.svg";
import { PayrollTaxCalc } from "./PayrollTaxCalc";
import { ThreeDots } from "react-loader-spinner";
import { fetchBanks, validateBankAccount } from "../../../redux/transaction";
import { useSelector, useDispatch } from "react-redux";
import {
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  reactSelectStyle,
  removeCountryCode,
  removeSpace,
  sumAllNum,
} from "../../../utils/helper/Helper";
import {
  getPayrollSetup,
  manualAddEmployeeToPayroll,
  validateRavenUserName,
} from "../../../redux/payroll";
// import moment from "moment";
import SuccessModal from "../../../components/reusables/SuccessModal";
import useDebounce from "../../../utils/helper/UseDebounce";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import { validateEmail } from "../../../utils/helper/ValidateInput";
import NewPreviewDetailBox from "./NewPreviewDetailBox";
import ActionContext from "../../../context/ActionContext";

const PayrollManualCreate = () => {
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { bankList, loadingVerify } = useSelector((state) => state.transaction);
  const { loadingManual } = useSelector((state) => state.payroll);
  const stepList = ["Employee Info", "Bank details", "Add deductions"];
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  // const [verify, setVerify] = useState(false);
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const [nhf, setNhf] = useState("0");
  const [pension, setPension] = useState("0");
  const [payee, setPayee] = useState("0");
  const [fromDate] = useState("");
  const [details, setDetails] = useState({
    fname: "",
    lname: "",
    amount: "",
    email: "",
    bank: "",
    acc_num: "",
    acc_name: "",
    nhf_status: 0,
    pension_status: 0,
    payee_status: 0,
    amountMinusTax: "",
    totalDeduction: "",
    phone: "",
    raven_username: "",
    addCheckVal: false,
    onSetAmount: "",
    selected_account_number: "",
  });

  // console.log(PayrollTaxCalc(formatNumWithoutCommaNaira(details?.amount)));

  const optionList = [
    { name: "Raven Bank", icon: ravenIcon },
    { name: "Other Banks", icon: bankIcon },
  ];
  const [settleOption, setSettleoption] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  useEffect(() => {
    dispatch(fetchBanks());
    // getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name } = chi;
      return { label: name, value: code };
    });
    return list;
  };

  // validate bank account details
  const validateBankDetails = async (e) => {
    const payload = {
      bank_code: e || details?.bank.value || details?.bank?.bank_code,
      account_number: removeSpace(details.acc_num),
    };
    // console.log(payload);
    if (step === 2 && payload?.account_number?.length > 9) {
      const data = await dispatch(validateBankAccount(payload));
      // console.log(data);
      if (data?.payload?.data.status === "success") {
        calculatTaxValue();
        setDetails((prev) => {
          return { ...prev, acc_name: data?.payload?.data.data };
        });
        // setStage(3);
      }
    }
  };

  useEffect(() => {
    if (details?.bank && details?.acc_num?.length > 9) {
      validateBankDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.acc_num || details?.bank]);

  useEffect(() => {
    getPayrollSetupFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayrollSetupFunc = async () => {
    const data = await dispatch(getPayrollSetup());
    // console.log(data);
    if (data?.payload?.response?.data?.message === "No Data found") {
      // console.log("yes");
    }
    if (data?.payload?.data?.status === "success") {
      // console.log(data);
      const setUp = data.payload.data.data.payroll_setup;
      // console.log(setUp);
      const nhf = setUp.nhf_status;
      setNhf(nhf);
      const payee = setUp.payee_status;
      setPayee(payee);
      const pension = setUp.pension_status;
      setPension(pension);
      const sub_account = setUp?.sub_account_details;
      setDetails((prev) => {
        return { ...prev, selected_account_number: sub_account || actionCtx?.currentAccount };
      });
    }
  };

  const Paye = PayrollTaxCalc(
    formatNumWithoutCommaNaira(details?.onSetAmount)
  )?.monthlyPaye;
  const Pension = PayrollTaxCalc(
    formatNumWithoutCommaNaira(details?.onSetAmount)
  )?.monthlyPension;
  const Nhf = PayrollTaxCalc(
    formatNumWithoutCommaNaira(details?.onSetAmount)
  )?.monthlyNhf;

  useEffect(() => {
    if (step === 3) {
      calculatTaxValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nhf, payee, pension]);

  useEffect(() => {
    if (details?.addCheckVal) {
      const deduct = sumAllNum([
        payee === "1" ? Paye : 0,
        nhf === "1" ? Nhf : 0,
        pension === "1" ? Pension : 0,
      ]);
      const valSum =
        Number(formatNumWithoutCommaNaira(details?.onSetAmount)) +
        Number(deduct);
      setDetails((prev) => {
        return {
          ...prev,
          amount: valSum || "",
          amountMinusTax: formatNumWithoutCommaNaira(details?.onSetAmount),
        };
      });
    } else {
      const valSum = Number(formatNumWithoutCommaNaira(details?.onSetAmount));
      const deduct = sumAllNum([
        payee === "1" ? Paye : 0,
        nhf === "1" ? Nhf : 0,
        pension === "1" ? Pension : 0,
      ]);
      setDetails((prev) => {
        return {
          ...prev,
          amount: valSum || "",
          amountMinusTax:
            formatNumWithoutCommaNaira(details?.onSetAmount) - deduct,
        };
      });
    }
    // console.log(details?.addCheckVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.addCheckVal, nhf, payee, pension]);

  const calculatTaxValue = () => {
    const deduct = sumAllNum([
      payee === "1" ? Paye : 0,
      nhf === "1" ? Nhf : 0,
      pension === "1" ? Pension : 0,
    ]);
    const newVal = details?.addCheckVal
      ? formatNumWithoutCommaNaira(details?.onSetAmount)
      : formatNumWithoutCommaNaira(details?.onSetAmount) - deduct;
    setDetails((prev) => {
      return { ...prev, amountMinusTax: newVal, totalDeduction: deduct };
    });
  };

  const handleSubmit = async () => {
    let val;
    if (settleOption === "Raven Bank") {
      const obj = {
        first_name: details?.fname,
        last_name: details?.lname,
        date_of_birth: fromDate,
        email: details?.email,
        phone_number: `0${formatNumWithoutCommaNaira(
          removeCountryCode(details?.phone)
        )}`,
        gross_salary: details?.addCheckVal
          ? formatNumWithoutCommaNaira(details?.amount)
          : formatNumWithoutCommaNaira(details?.onSetAmount),
        raven_username: details?.raven_username,
        // bank: details?.bank?.label,
        // bank_code: details?.bank?.value,
        // account_number: details?.acc_num,
        // account_name: details?.acc_name,
        nhf_status: nhf,
        payee_status: payee,
        pension_status: pension,
        selected_account_number: details?.selected_account_number?.account_number,
      };
      val = obj;
    } else {
      const obj = {
        first_name: details?.fname,
        last_name: details?.lname,
        date_of_birth: fromDate,
        email: details?.email,
        phone_number: `0${formatNumWithoutCommaNaira(
          removeCountryCode(details?.phone)
        )}`,
        gross_salary: formatNumWithoutCommaNaira(details?.amount),
        bank: details?.bank?.label,
        bank_code: details?.bank?.value,
        account_number: details?.acc_num,
        account_name: details?.acc_name,
        nhf_status: nhf,
        payee_status: payee,
        pension_status: pension,
        selected_account_number: details?.selected_account_number?.account_number,
      };
      val = obj;
    }

    // console.log(val);
    // return
    const data = await dispatch(manualAddEmployeeToPayroll(val));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };
  const [loadingVerifyVal, setLoadingVerifyVal] = useState(false);
  const validateRavenUsernameFunc = async () => {
    const obj = {
      username: details?.raven_username,
    };
    setLoadingVerifyVal(true);
    const data = await dispatch(validateRavenUserName(obj));
    if (data?.payload?.status === "success") {
      setLoadingVerifyVal(false);
      const name = `${data?.payload?.data?.fname} ${data?.payload?.data?.lname}`;
      setValidateRavenValName(name);
    } else {
      setLoadingVerifyVal(false);
    }
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  // const [userInfo, setUserInfo] = useState(false);
  const [validateRavenNameVal, setValidateRavenValName] = useState("");
  const debouncedSearchTerm = useDebounce(details?.raven_username, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 2 && step === 2) {
      validateRavenUsernameFunc();
    }

    if (isMount && debouncedSearchTerm?.length < 2) {
      // setDetails((prev) => {
      //   return { ...prev, raven_username: "" };
      // });
      setValidateRavenValName("");
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleFinish = () => {
    setShowModal((prev) => {
      return { ...prev, success: true };
    });
    actionCtx?.setConfettiVal();
    setStep(1);
    setDetails({
      fname: "",
      lname: "",
      amount: "",
      email: "",
      bank: "",
      acc_num: "",
      acc_name: "",
      nhf_status: 0,
      pension_status: 0,
      payee_status: 0,
      amountMinusTax: "",
      totalDeduction: "",
      phone: "",
      raven_username: "",
      addCheckVal: false,
      onSetAmount: "",
      selected_account_number: "",
    });
    getPayrollSetupFunc();
  };

  const size = useWindowSize();

  const [showError, setShowError] = useState({
    num: false,
    email: false,
    amount: false,
    meter: false,
  });

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Manual Payroll Setup`}
          text={`Add members to your payroll setup.`}
          back
          onBack={() => {
            navigate(-1);
          }}
        />
        <ContainerWrapper
          width={size.width < 900 && `100%`}
          activeStep={step}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          loading={loadingManual}
          stepList={stepList}
          btnText={
            step === 1
              ? "Next to Bank details"
              : step === 2
              ? "Save & proceed"
              : "Save & create Payroll"
          }
          stepClickable={
            details?.amount &&
            details?.email &&
            details?.lname &&
            details?.fname &&
            details?.bank &&
            details?.acc_num &&
            details?.acc_name
          }
          onStepClick={(e) => {
            details?.amount &&
              details?.email &&
              details?.lname &&
              details?.fname &&
              details?.bank &&
              details?.acc_num &&
              details?.acc_name &&
              setStep(e);
          }}
          disabled={
            step === 1
              ? !details?.amount ||
                !details?.email ||
                !details?.lname ||
                !details?.fname
              : step === 2 && settleOption === "Raven Bank"
              ? !details?.amount || !details?.email || !validateRavenNameVal
              : step === 2 && settleOption === "Other Banks"
              ? !details?.amount ||
                !details?.email ||
                !details?.lname ||
                !details?.fname ||
                !details?.bank ||
                !details?.acc_num ||
                !details?.acc_name
              : false
          }
          onBtnClick={() => {
            // console.log(details);
            if (step === 1) {
              if (!validateEmail(details.email)) {
                toast.error("Invalid email address!", {
                  position: "top-right",
                });
                setShowError((prev) => {
                  return { ...prev, email: true };
                });
                return;
              }
            }
            step < 3 ? setStep(step + 1) : handleSubmit();
            step === 2 && calculatTaxValue();
            // step === 2 && calculatTaxValue()
          }}
        >
          <div className="payroll-manual-wrap-box">
            {/* step one start ---------------------------------- */}
            {step === 1 && (
              <>
                <div className="form-wrap-box">
                  <div className="two-group">
                    <form
                      autoComplete="off"
                      style={{ width: "100%" }}
                      action=""
                    >
                      {" "}
                      <RavenInputField
                        label={`Employee First name* `}
                        color={`black-light`}
                        placeholder={`E.g Timi `}
                        value={details?.fname}
                        type={`text`}
                        onChange={handleChange}
                        name={`fname`}
                        capitalize
                      />
                    </form>
                    <form
                      autoComplete="off"
                      style={{ width: "100%" }}
                      action=""
                    >
                      {" "}
                      <RavenInputField
                        label={`Employee Last name* `}
                        color={`black-light`}
                        placeholder={`E.g Ayeni`}
                        value={details?.lname}
                        type={`text`}
                        onChange={handleChange}
                        name={`lname`}
                        capitalize
                      />
                    </form>
                  </div>
                  <form autoComplete="off" style={{ width: "100%" }} action="">
                    {" "}
                    <RavenInputField
                      label={`Employee email address* `}
                      color={`black-light`}
                      placeholder={`mail@you.com`}
                      name={`email`}
                      value={details?.email}
                      type={`email`}
                      showError={showError?.email}
                      onChange={(e) => {
                        setShowError((prev) => {
                          return { ...prev, email: false };
                        });
                        handleChange(e);
                      }}
                    />
                  </form>
                  <form autoComplete="off" style={{ width: "100%" }} action="">
                    {" "}
                    <RavenInputField
                      label={`Employee Phone No* `}
                      color={`black-light`}
                      placeholder={`E.g 81 623 6....`}
                      name={`phone`}
                      value={removeCountryCode(details?.phone)}
                      type={`phone`}
                      onChange={handleChange}
                    />
                  </form>

                  {/* <RavenInputField
                  label={`Date of birth* `}
                  color={`black-light`}
                  placeholder={`mail@you.com`}
                  name={`date`}
                  value={fromDate}
                  type={`date`}
                  onChange={(e) => {
                    setFromDate(moment(e[0]).format("YYYY-MM-DD HH:mm:ss"))
                  }}
                /> */}
                  <form autoComplete="off" style={{ width: "100%" }} action="">
                    {" "}
                    <RavenInputField
                      label={`Employee Gross Salary* `}
                      color={`black-light`}
                      placeholder={`150,000`}
                      name={`amount`}
                      value={details?.amount}
                      type={`number`}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return {
                            ...prev,
                            amount: e.target?.value,
                            onSetAmount: e.target.value,
                          };
                        });
                      }}
                      thousandFormat
                    />
                  </form>
                </div>
              </>
            )}
            {/* step one end ---------------------------------- */}
            {/* step two start ---------------------------------- */}
            {step === 2 && (
              <>
                <div className="form-wrap-box">
                  <p>Select Bank</p>
                  <div className="select-wrap-box">
                    {optionList?.map((chi, idx) => {
                      const { name, icon } = chi;
                      return (
                        <div
                          key={idx}
                          className={`box grey-bg hover-white-class select-option-class ${
                            settleOption === name &&
                            "select-option-class-active"
                          }`}
                          onClick={() => {
                            setSettleoption(name);
                            setDetails((prev) => {
                              return {
                                ...prev,
                                bank: "",
                                acc_num: "",
                                raven_username: "",
                                acc_name: "",
                              };
                            });
                            setValidateRavenValName("");
                          }}
                        >
                          <div className="check-box">
                            <FaCheck className="icon" />
                          </div>
                          <figure className="img-box">
                            <img src={icon} alt="" className="img" />
                          </figure>
                          <p className="name">{name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* select option end */}
                  <div
                    className={`show-hide-box ${
                      settleOption === "Raven Bank" && "show-hide-box-visible"
                    }`}
                  >
                    <form
                      autoComplete="off"
                      style={{ width: "100%" }}
                      action=""
                    >
                      {" "}
                      <RavenInputField
                        color={`black-light`}
                        type="text"
                        label={`Raven username* `}
                        value={details?.raven_username}
                        onChange={handleChange}
                        placeholder={`E.g akot`}
                        name={`raven_username`}
                        labelSpanText={
                          loadingVerifyVal ? (
                            <div
                              style={{
                                padding: "0rem",
                                display: "flex",
                                justifyContent: "flex-end",
                                transform: "translateX(1.5rem)",
                              }}
                              className="load-wrap"
                            >
                              <ThreeDots
                                height="10"
                                width="80"
                                radius="9"
                                color="#020202"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <>{validateRavenNameVal ? "Verified" : "Verify"}</>
                          )
                        }
                        labelClassName={`verify-text`}
                        onActionClick={() => validateRavenUsernameFunc()}
                      />
                    </form>

                    {/* verify name box start */}
                    <div
                      className={`verify-name-box ${
                        validateRavenNameVal && "verify-name-box-show"
                      }`}
                      style={{
                        marginTop: "-1.5rem",
                      }}
                    >
                      <RavenCheckBox
                        checked={true}
                        color={`black-light`}
                        className={`check`}
                      />
                      <p className="text">{validateRavenNameVal}</p>
                    </div>
                    {/* verify name box end */}
                  </div>
                  {/* end of show hide */}
                  {/* show end start */}
                  <div
                    className={`show-hide-box ${
                      settleOption === "Other Banks" && "show-hide-box-visible"
                    }`}
                  >
                    <form
                      autoComplete="off"
                      style={{ width: "100%" }}
                      action=""
                    >
                      {" "}
                      <RavenInputField
                        color={`black-light`}
                        type="select"
                        label={`Select bank* `}
                        value={details?.bank}
                        selectStyles={reactSelectStyle}
                        onChange={(e) => {
                          setDetails((prev) => {
                            return { ...prev, bank: e };
                          });
                          validateBankDetails(e.value);
                        }}
                        style={{
                          marginTop: "-2rem",
                          position: "relative",
                          zIndex: "10",
                        }}
                        placeholder={`Select bank`}
                        selectValue={details?.bank}
                        selectOption={formatSelectOption(bankList)}
                      />
                    </form>

                    <div className="">
                      <form
                        autoComplete="off"
                        style={{ width: "100%" }}
                        action=""
                      >
                        {" "}
                        <RavenInputField
                          type={`account-number`}
                          maxLength={10}
                          label={`Account number* `}
                          placeholder={`10 digit account number`}
                          color={`black-light`}
                          labelSpanText={
                            loadingVerify ? (
                              <div
                                style={{
                                  padding: "0rem",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  transform: "translateX(1.5rem)",
                                }}
                                className="load-wrap"
                              >
                                <ThreeDots
                                  height="10"
                                  width="80"
                                  radius="9"
                                  color="#020202"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <>{details?.acc_name ? "Verified" : "Verify"}</>
                            )
                          }
                          labelClassName={`verify-text`}
                          onActionClick={() => validateBankDetails()}
                          value={details?.acc_num}
                          name={`acc_num`}
                          onChange={(e) => {
                            handleChange(e);
                            setDetails((prev) => {
                              return { ...prev, acc_name: "" };
                            });
                          }}
                          maxSize={11}
                        />
                      </form>

                      {/* verify name box start */}
                      <div
                        className={`verify-name-box ${
                          details?.acc_name && "verify-name-box-show"
                        }`}
                      >
                        <RavenCheckBox
                          checked={true}
                          color={`black-light`}
                          className={`check`}
                        />
                        <p className="text">{details?.acc_name}</p>
                      </div>
                      {/* verify name box end */}
                    </div>
                  </div>
                  {/* show end end --- */}
                </div>
              </>
            )}
            {/* step two end ---------------------------------- */}
            {/* step 3 start ----------------------------------- */}
            {step === 3 && (
              <>
                <div style={{ paddingBottom: "15rem", marginTop: "3rem" }}>
                  <NewPreviewDetailBox
                    netSalary={formatNumWithCommaNairaSymbol(details?.amount)}
                    grossSalary={formatNumWithCommaNairaSymbol(
                      details?.amountMinusTax
                    )}
                    amount={details?.amount}
                    name={details?.username}
                    narration={details?.narration}
                    checked={details?.beneficiary}
                    number={details?.phone}
                    totalDeduction={formatNumWithCommaNairaSymbol(
                      details?.totalDeduction
                    )}
                    payeeAmount={formatNumWithCommaNairaSymbol(Paye)}
                    onChangePayee={(e) => {
                      payee === "1" ? setPayee("0") : setPayee("1");
                    }}
                    checkedPayee={payee === "1" ? true : false}
                    payeeValue={payee}
                    pensionAmount={formatNumWithCommaNairaSymbol(Pension)}
                    onChangePension={(e) => {
                      pension === "1" ? setPension("0") : setPension("1");
                    }}
                    checkedPension={pension === "1" ? true : false}
                    pensionValue={pension}
                    nhfAmount={formatNumWithCommaNairaSymbol(Nhf)}
                    onChangeNhf={(e) => {
                      nhf === "1" ? setNhf("0") : setNhf("1");
                    }}
                    checkedNhf={nhf === "1" ? true : false}
                    nhfValue={nhf}
                    addCheckVal={details?.addCheckVal}
                    onAddCheckVal={() => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          addCheckVal: !details?.addCheckVal,
                        };
                      });
                    }}
                    defaultSubAccount={
                      details?.selected_account_number ||
                      actionCtx?.currentAccount
                    }
                    setDefaultSubAccount={(e) => {
                      setDetails((prev) => {
                        return { ...prev, selected_account_number: e };
                      });
                    }}
                  />
                </div>
              </>
            )}
            {/* step 3 end ----------------------------------- */}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Payroll Created Successfully."
        text={`You have successfully added a new employee to your payroll. We appreciate your business and look forward to serving you again in the future.`}
        btnText={`Close`}
        onBtnClick={() => {
          navigate("/dashboard-payroll");
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default PayrollManualCreate;
