import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { deleteTeamMember, getTeamMembers } from "../../../../redux/settings";

const DeleteTeamModal = ({ visible, onClose, detail, onFinish }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.settings);

  const handleSubmit = async () => {
    const obj = {
      email: detail?.email,
    };
    const data = await dispatch(deleteTeamMember(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      onFinish();
      dispatch(getTeamMembers())
    }
  };
  return (
    <DeleteModal
      title={`Remove ${detail?.fname} ${detail?.lname} from your team?`}
      text={`Removing this user , would disable their access to your account on your business, ensure you confirm this users before totally removing them from your account.`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      loading={loading}
      onDelete={handleSubmit}
    />
  );
};

export default DeleteTeamModal;
