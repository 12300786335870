import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import "../../styles/auth/SignUp.css";
// import dagoteIcon from "../../../assets/dagote-icon.svg";
import { formatTypeFunction, trimLongString } from "../../utils/helper/Helper";
// import { authenticateLogin, authenticateLoginSso } from "../../redux/user";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import ActionContext from "../../context/ActionContext";
import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {
  cancelTokenFunc,
  // getAllAccounts,
  // getAllBusinesses,
  // getUser,
  loginIntoAnotherBusiness,
} from "../../redux/user";
import ActionContext from "../../context/ActionContext";
import { useDarkMode } from "../../utils/themes/useDarkMode";
import { genCaptchaKeyAsync } from "../../components/reusables/ReCaptchaFile";
// import { getTransactionLimits } from "../../redux/transaction";
// import { getDirectorShareholders } from "../../redux/settings";
// import { set } from "immer/dist/internal";

const SwitchBusinessAccountModal = ({ visible, onClose }) => {
  // eslint-disable-next-line no-unused-vars
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const [accountSelected, setAccountSelected] = useState("");
  // eslint-disable-next-line no-unused-vars
  const actionCtx = useContext(ActionContext);
  // eslint-disable-next-line no-unused-vars
  const [details, setDetails] = useState({
    pin: "",
  });
  //   const [completeToken, setCompleteToken] = useState(false);
  const { loadLogout, business_accounts, business } = useSelector(
    (state) => state.user
  );
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  //   const actionCtx = useContext(ActionContext);

  const encryptTokenFunc = (tok) => {
    const token = tok;
    const secretKey =
      "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

    // Encrypt the token
    const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
    return encryptedToken;
  };

  // const decryptTokenFunc = (tok) => {
  //   const encryptedToken = tok;
  //   const secretKey =
  //     "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

  //   // Encrypt the token
  //   const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  //   const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
  //   console.log(tok);
  //   console.log(decryptedToken);
  //   return decryptedToken;
  // };

  const [deviceIdVal, seDeviceIdVal] = useState("");
  useEffect(() => {
    // Generate a fingerprint
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();

      // Use the fingerprint as the device identifier
      const deviceId = result.visitorId;

      // Store the device identifier in local storage
      // console.log(deviceId);
      seDeviceIdVal(deviceId);
    };

    getFingerprint();
  }, []);

  const handleSubmit = async (param) => {
    genCaptchaKeyAsync("6Lfbo54nAAAAAEUGhpgYHJY7DvpS_e7JoUt2Na4d");
    const obj = {
      business_id: String(param?.business_id || accountSelected.business_id),
      device_id: deviceIdVal,
    };
    actionCtx?.setShowGeneralLoader(true);
    const data = await dispatch(loginIntoAnotherBusiness(obj));
    if (data?.payload?.data?.status === "success") {
      // dispatch(cancelTokenFunc());
      const token = data?.payload?.data?.data?.token;
      localStorage?.setItem("token", encryptTokenFunc(token));
      handleClose();
      localStorage.removeItem("current_sub_account_id");
      window.location.reload();
      // navigate("/dashboard-overview");
      setTimeout(() => {
        dispatch(cancelTokenFunc());
      }, 2000);
    }
  };

  const formatBusonessList = (list) => {
    if (list?.length > 0) {
      const newList = list?.filter(
        (chi) => Number(chi?.business_id) !== Number(business?.id)
      );
      return formSelectList(newList);
    }
  };

  const handleClose = () => {
    onClose();
    setDetails((prev) => {
      return { ...prev, pin: "" };
    });
    setAccountSelected("");
  };

  const formSelectList = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const newList = list?.filter(
        (chi) => Number(chi?.confirmation_status) === 1
      );
      return newList;
    }
  };

  return (
    <RavenModal
      className={`auth-pin-modal-wrap`}
      onClose={handleClose}
      visble={visible}
      btnLabel={`Create new business.`}
      btnColor={`black-light`}
      // disabled={!accountSelected}
      onBtnClick={() => {
        // console.log("hgs");
        handleClose();
        actionCtx.createBusinessFunc(true);
      }}
      loading={loadLogout}
    >
      <div
        // onSubmit={(e) => {
        //   e.preventDefault(e);
        // }}
        action=""
        className="form form-modal-auth form-modal-switch-business-account"
      >
        <div className="text-box">
          <p
            className="big-title "
            style={{ color: theme === "light" ? "#020202" : "#ffffff" }}
          >
            Select your business
          </p>
          <p className="small-title">
            We noticed your account is linked to multiple business select the
            business you want to log into.
          </p>
        </div>
        {/* select wrap start */}
        <div className="select-wrap-account-auth select-wrap-account-auth-in-app">
          {formatBusonessList(business_accounts)?.map((chi, idx) => {
            const { business_name, business_logo } = chi;
            return (
              <div
                onClick={() => {
                  if (accountSelected?.business_name === business_name) {
                    setAccountSelected("");
                  } else {
                    setAccountSelected(chi);
                    handleSubmit(chi);
                  }
                }}
                className="select-item  border-theme"
                key={idx}
                // style={{
                //   borderColor: theme === "light" && "rgba(247, 248, 247, 0.1)",
                // }}
              >
                <div className="img-avatar-wrap">
                  {business_logo ? (
                    <figure className="img-box">
                      <img src={business_logo} alt="" className="img" />
                    </figure>
                  ) : (
                    <div
                      style={{
                        backgroundColor:
                          theme === "light" ? "#020202" : "#ffffff",
                      }}
                      className="avatar-box avatar-box-two grey-bg-two"
                    >
                      <span style={{ textTransform: "uppercase" }}>{`${
                        formatTypeFunction(business_name?.replace("-", " "))
                          ?.split(" ")[0]
                          ?.charAt(0) || ""
                      } ${
                        formatTypeFunction(business_name?.replace("-", " "))
                          ?.split(" ")[1]
                          ?.charAt(0) ||
                        formatTypeFunction(business_name?.replace("-", " "))
                          ?.split(" ")[0]
                          ?.charAt(1) ||
                        ""
                      }`}</span>
                    </div>
                  )}
                </div>
                <p
                  style={{
                    textTransform: "capitalize",
                  }}
                  className="name black-white-color"
                >
                  {trimLongString(business_name?.replace("-", " "), 30) ||
                    "---"}
                </p>
                {/* check wrap start */}
                <div
                  className={`check-wrap ${
                    accountSelected?.business_name === business_name &&
                    "check-wrap-active "
                  }`}
                ></div>
                {/* check wrap end */}
              </div>
            );
          })}
        </div>
        {/* select wrap end */}
        {/* password input box start */}
        {/* <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          autoComplete="off"
          style={{ width: "100%" }}
          action=""
        >
          {" "}
          <RavenInputField
            value={details?.pin}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, pin: e.target.value };
              });
            }}
            className={`switch-business-input`}
            color="black-light"
            label="Password"
            type="password"
            placeholder="Enter your login password"
          />
        </form> */}

        {/* password input box end */}
      </div>
    </RavenModal>
  );
};

export default SwitchBusinessAccountModal;
