import React, { useContext, useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
// import addIcon from "../../../../assets/add-icon-form.svg";
import { RavenCheckBox, RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import billImg from "../../../../assets/bill-electricity-icon.svg";
import PreviewElectricityDetailsBox from "./PreviewElectricityBox";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";
import { ThreeDots } from "react-loader-spinner";
import {
  filterEmptyColumns,
  formatNumWithCommaNairaSymbol,
  reactSelectStyle,
  removeSpace,
} from "../../../../utils/helper/Helper";
import { useSelector, useDispatch } from "react-redux";
import ActionContext from "../../../../context/ActionContext";
// import moment from "moment";
import { formatNumWithoutCommaNaira } from "../../../../utils/Helpers";
import SuccessModal from "../../../../components/reusables/SuccessModal";
import {
  getDiscos,
  payElectricityBill,
  validateMeterNumber,
} from "../../../../redux/billPayment";
import useDebounce from "../../../../utils/helper/UseDebounce";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";
import { getAllAccounts, getUser } from "../../../../redux/user";
import useWindowSize from "../../../../utils/helper/UseWindowSize";
// import { validateEmail } from "../../../../utils/helper/ValidateInput";
import NewPreviewDetailBox from "./NewPreviewBox";

const DashboardBillPaymentElectricity = () => {
  // const [theme, toggleTheme] = useDarkMode(localStorage.getItem("
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [previewOpen, setPreviewOpen] = useState("");
  // const [fromDate, setFromDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const { loading, allDiscos, loadingVerify } = useSelector((state) => state.billPayment);
  const [verifiedNum, setVerifiedNum] = useState(false);
  const actionCtx = useContext(ActionContext);
  const [urlBack, setUrlBack] = useState("");
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [viewAll, setViewAll] = useState(false);
  // const [showError, setShowError] = useState({
  //   num: false,
  // });
  const [selectedProvider, setSelectedProvider] = useState("prepaid");
  const [details, setDetails] = useState({
    number: "",
    amount: "",
    bulk_list: [],
    beneficiary: false,
    schedule: "",
    schedule_date: "",
    provider: "prepaid",
    elec_plan: "",
    type: "",
    user_pin: "",
    meter_no: "",
    phone: "",
    meter_type: "",
    email: "",
  });
  // const

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };
  const stepList = ["Transaction detail", "Confirm Transactions"];
  useState(() => {
    const url = window?.location?.href?.split("?")[1];
    if (url) {
      setUrlBack(url);
    }
  }, []);

  const handleRemoveFromList = (obj) => {
    if (details?.bulk_list?.length === 1) {
      setViewAll(false);
    }
    const newList = details?.bulk_list?.filter(
      (chi) => chi?.meter_no !== obj?.meter_no
    );
    setDetails((prev) => {
      return {
        ...prev,
        bulk_list: newList,
      };
    });
  };

  const handleAddBeneficiary = (obj, val) => {
    if (details?.bulk_list?.length > 0) {
      const newList = details?.bulk_list?.map((chi) =>
        chi?.meter_no === obj?.meter_no ? { ...chi, beneficiary: val } : chi
      );
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
        };
      });
    } else {
      const newObj = { ...details, beneficiary: val };
      setDetails(newObj);
    }
  };

  const handleNextStep = (param) => {
    if (param === "one") {
      setStep(2);
      // console.log(details);
    }
    if (param === "two") {
      if (user?.pin_status === 0) {
        actionCtx.setCheckShow("nine");
      } else {
        setShowModal((prev) => {
          return { ...prev, pin: true };
        });
      }
      // setShowModal((prev) => {
      //   return { ...prev, pin: true };
      // });
    }
  };

  // useEffect(() => {
  //   if (
  //     !user_resource_and_permissions?.allowed_permissions?.includes(
  //       "can_view_bill_payments"
  //     )
  //   ){
  //     navigate("/dashboard-bill-payment")
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user_resource_and_permissions])

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name } = chi;
      return { ...chi, label: name, value: code };
    });
    return list;
  };

  const typeList = [
    {
      value: "prepaid",
      name: "Prepaid",
      icon: (
        <svg
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img dull-bright-filter"
        >
          <path
            d="M8.59992 16.6586V19C8.59992 20.1046 9.45954 21 10.5199 21C11.5803 21 12.4399 20.1046 12.4399 19V16.6586M10.5199 1V2M1.87992 11H0.919922M4.27992 4.5L3.70383 3.8999M16.7599 4.5L17.3362 3.8999M20.1199 11H19.1599M16.2799 11C16.2799 14.3137 13.7011 17 10.5199 17C7.33876 17 4.75992 14.3137 4.75992 11C4.75992 7.68629 7.33876 5 10.5199 5C13.7011 5 16.2799 7.68629 16.2799 11Z"
            // stroke={theme === "light" ? `black` : `white`}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      value: "postpaid",
      name: "Post-paid",
      icon: (
        <svg
          width="16"
          height="22"
          viewBox="0 0 16 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img dull-bright-filter"
        >
          <path
            d="M5.76248 21H10.4942M6.23566 9H10.0211M8.12836 9L8.12836 15M10.9674 14.3264C13.2055 13.2029 14.7528 10.7924 14.7528 8C14.7528 4.13401 11.7869 1 8.12836 1C4.46978 1 1.50391 4.13401 1.50391 8C1.50391 10.7924 3.05124 13.2029 5.28931 14.3264V15C5.28931 15.9319 5.28931 16.3978 5.43338 16.7654C5.62548 17.2554 5.99394 17.6448 6.4577 17.8478C6.80553 18 7.24647 18 8.12836 18C9.01025 18 9.45119 18 9.79902 17.8478C10.2628 17.6448 10.6312 17.2554 10.8233 16.7654C10.9674 16.3978 10.9674 15.9319 10.9674 15V14.3264Z"
            // stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];

  const handleSubmit = async (pin, token) => {
    let val;
    if (details?.bulk_list.length > 0) {
      const newList = details?.bulk_list?.map((chi) => {
        return {
          ...chi,
          phone: `0${formatNumWithoutCommaNaira(chi?.phone)}`,
          amount: chi.cable_plan?.amount,
        };
      });
      const obj = {
        recipients: newList,
        user_pin: pin,
        two_factor_token: token,
      };
      val = obj;
    } else {
      const obj = {
        recipients: [
          {
            meter_no: removeSpace(details?.meter_no),
            meter_type: selectedProvider || details?.provider,
            phone: `0${formatNumWithoutCommaNaira(details?.phone)}`,
            provider: details?.elec_plan?.value,
            amount: formatNumWithoutCommaNaira(details?.amount),
            customer_name: customerName,
            // email: details?.email,
          },
        ],
        user_pin: pin,
        two_factor_token: token,
        schedule: fromDate ? "later" : "now",
        schedule_date: fromDate ? fromDate : "",
        selected_account_number: actionCtx?.currentAccount?.account_number,
      };
      val = obj;
    }
    // console.log(val);
    // return
    const data = await dispatch(payElectricityBill(filterEmptyColumns(val)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    setSelectedProvider("");
    dispatch(getUser());
    dispatch(getAllAccounts());
    setVerifiedNum(false);
    setDetails({
      number: "",
      amount: "",
      narration: "Sent from raven",
      bulk_list: [],
      beneficiary: false,
      schedule: "",
      provider: "",
      elec_plan: "",
      smart_card_number: "",
      phone: "",
      code: "",
      user_pin: "",
      schedule_date: "",
      email: "",
    });
    setStep(1);
    setShowModal((prev) => {
      return { ...prev, success: true, pin: false };
    });
  };

  useEffect(() => {
    dispatch(getDiscos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearchTerm = useDebounce(details?.meter_no, 1000);
  useEffect(() => {
    let isMount = true;

    if (
      isMount &&
      selectedProvider &&
      debouncedSearchTerm?.length > 9 &&
      step === 1 &&
      details?.elec_plan
    ) {
      validateCardNumber();
    }

    if (isMount && debouncedSearchTerm?.length < 9) {
      setVerifiedNum(false);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, details?.elec_plan]);
  const [customerName, setCustomerName] = useState("");
  const validateCardNumber = async () => {
    const payload = {
      meter_type: selectedProvider,
      provider: details?.elec_plan?.value,
      meter_no: details?.meter_no,
    };
    if (step === 1 && removeSpace(payload?.meter_no).length > 10) {
      const data = await dispatch(validateMeterNumber(payload));
      // console.log(data);
      if (data.payload.status === "fail") {
        setShowError((prev) => {
          return { ...prev, num: true };
        });
        setTimeout(() => {
          setShowError((prev) => {
            return { ...prev, num: false };
          });
        }, 10000);
      }
      if (data.payload.status === "success") {
        const name = data?.payload?.data?.cust_name;
        setCustomerName(name);
        setVerifiedNum(true);
        // setValidated(data.payload.data);
        // setStage("summary");
        // console.log('the valid', validated);
        // console.log(data.payload.data);
      }
    }
  };

  const size = useWindowSize();
  const [showError, setShowError] = useState({
    num: false,
    email: false,
    amount: false,
    meter: false,
  });

  useEffect(() => {
    if (
      actionCtx?.currentAccount?.authorization_level !==
      ("initiator_approver" || "initiator") && actionCtx?.currentAccount?.authorization_level !==
      ("initiator" || "initiator_approver" )
    ) {
      navigate("/dashboard-bill-payment");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Electricity Recharge`}
          text={`Pay for your cable Tv’s with support for all providers`}
          back={true}
          onBack={() => {
            urlBack
              ? navigate(`/${urlBack}`)
              : navigate("/dashboard-bill-payment");
          }}
        />
        {/* title box end */}
        <ContainerWrapper
          width={size?.width < 900 && `100%`}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          activeStep={step}
          stepList={stepList}
          btnText={`Complete Transaction`}
          stepClickable={
            details?.bulk_list.length > 0
              ? details?.bulk_list.length > 0
              : details?.amount &&
                details?.meter_no &&
                details?.provider &&
                // details?.email &&
                details?.elec_plan &&
                verifiedNum
          }
          onStepClick={(e) => {
            details?.bulk_list.length > 0
              ? setStep(e)
              : details?.amount &&
                details?.meter_no &&
                details?.provider &&
                // details?.email &&
                details?.elec_plan &&
                verifiedNum &&
                setStep(e);
          }}
          disabled={
            step === 1
              ? details?.bulk_list.length > 0
                ? details?.bulk_list.length < 1
                : !details?.amount ||
                  !details?.meter_no ||
                  !details?.provider ||
                  !details?.phone ||
                  !details?.elec_plan ||
                  !verifiedNum
              : false
          }
          onBtnClick={() => {
            if (step === 1) {
              if (formatNumWithoutCommaNaira(details?.amount) < 1000) {
                toast.error("Low purchase amount", {
                  position: "top-right",
                });
                setShowError((prev) => {
                  return { ...prev, amount: true };
                });
                return;
              }
              // if (!validateEmail(details.email)) {
              //   toast.error("Invalid email address!", {
              //     position: "top-right",
              //   });
              //   setShowError((prev) => {
              //     return { ...prev, email: true };
              //   });
              //   return;
              // }
              handleNextStep("one");
            }

            step === 2 && handleNextStep("two");
          }}
        >
          <div className="bill-payment-airtime-wrap bill-payment-electricity-wrap">
            {step === 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                {/* select type box start */}
                <div className="select-type-box">
                  <p className="title dull-bright-filter-two">
                    Select Electricity type
                  </p>
                  {/* select wrap start */}
                  <div className="select-wrap">
                    {typeList.map((chi, idx) => {
                      const { icon, name, value } = chi;
                      return (
                        <div
                          className={`child-wrap-row grey-bg  select-option-class ${
                            selectedProvider === value &&
                            "select-option-class-active"
                          }`}
                          key={idx}
                          onClick={() => {
                            if (selectedProvider !== value) {
                              setSelectedProvider(value);
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  provider: value,
                                };
                              });
                            }
                          }}
                        >
                          <div className="check-box">
                            <FaCheck className="icon" />
                          </div>
                          <figure className="img-box">{icon}</figure>
                          <p className="name">{name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* select wrap end */}
                </div>
                {/* select type box end */}
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    label="Service provider*"
                    // labelSpanText="Use my Cable No"
                    labelColor={`purple-light`}
                    type={`select`}
                    selectStyles={reactSelectStyle}
                    // name={`number`}
                    onChange={(e) => {
                      // console.log(e);
                      setDetails((prev) => {
                        return { ...prev, elec_plan: e };
                      });
                    }}
                    style={{ position: "relative", zIndex: "20" }}
                    className={`select-electricy`}
                    value={details?.elec_plan}
                    selectValue={details?.elec_plan}
                    selectOption={formatSelectOption(allDiscos)}
                    placeholder={`Select Provider`}
                  />
                </form>
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    label="Meter Number* "
                    type={`account-number`}
                    showError={showError?.num}
                    maxLength={13}
                    labelSpanText={
                      loadingVerify ? (
                        <div
                          style={{
                            padding: "0rem",
                            display: "flex",
                            justifyContent: "flex-end",
                            transform: "translateX(1.5rem)",
                          }}
                          className="load-wrap"
                        >
                          <ThreeDots
                            height="10"
                            width="80"
                            radius="9"
                            color="#020202"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Verify"
                      )
                    }
                    labelColor={`purple-light`}
                    placeholder="Enter smartcard number ."
                    name={`meter_no`}
                    onChange={(e) => {
                      setShowError((prev) => {
                        return { ...prev, num: false };
                      });
                      setVerifiedNum(false);
                      handleChange(e);
                    }}
                    value={details?.meter_no}
                  />
                </form>

                {/* verify name box start */}
                <div
                  className={`verify-name-box ${
                    verifiedNum && "verify-name-box-show"
                  }`}
                >
                  <RavenCheckBox
                    checked={true}
                    color={`black-light`}
                    className={`check`}
                  />
                  <p className="text">{customerName || "Verified"}</p>
                </div>
                {/* verify name box end */}
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    onActionClick={() => {
                      actionCtx?.setShowAmount(!actionCtx?.showAmount);
                    }}
                    label="Amount"
                    labelSpanText={`Bal: ${
                      actionCtx?.showAmount
                        ? formatNumWithCommaNairaSymbol(
                            actionCtx?.currentAccount?.balance
                          )
                        : "₦ ••••••"
                    }`}
                    labelColor={`black-light`}
                    labelClassName={`label-span-amount label-span-theme`}
                    type={`number`}
                    placeholder="E.g 5,000.00"
                    className={`select-data-plan`}
                    name={`amount`}
                    showError={showError?.amount}
                    onChange={(e) => {
                      setShowError((prev) => {
                        return { ...prev, amount: false };
                      });
                      handleChange(e);
                    }}
                    value={details?.amount}
                    thousandFormat
                  />
                </form>
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    label="Phone Number* "
                    labelColor={`black-light`}
                    type={`phone`}
                    placeholder="E.g 081 626 ..."
                    name={`phone`}
                    onChange={handleChange}
                    value={
                      details?.phone.slice(0, 3) === "234"
                        ? details?.phone.replace("234", "0")
                        : details?.phone.slice(0, 1) === "0"
                        ? details?.phone.replace("0", "")
                        : details?.phone
                    }
                  />
                </form>
                {/* <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    label="Recipient email* "
                    labelColor={`black-light`}
                    type={`email`}
                    placeholder="E.g ayeni@...."
                    name={`email`}
                    showError={showError?.email}
                    onChange={(e) => {
                      setShowError((prev) => {
                        return { ...prev, email: false };
                      });
                      handleChange(e);
                    }}
                    value={details?.email}
                  />
                </form> */}

                {/* add another text start */}
                {/* <div
                  onClick={() => {
                    selectedProvider &&
                      details?.amount &&
                      details?.meter_no &&
                      details?.elec_plan &&
                      details?.phone &&
                      verifiedNum &&
                      handleBulkTransfer();
                  }}
                  className={`add-another-box ${
                    selectedProvider &&
                    details?.amount &&
                    details?.meter_no &&
                    details?.elec_plan &&
                    details?.phone &&
                    verifiedNum &&
                    "add-another-box-active"
                  }`}
                >
                  <figure className="img-box">
                    <img src={addIcon} alt="" className="img" />
                  </figure>
                  <p className="text">Recharge for another recipient.</p>
                </div> */}
                {/* add another text end */}
                {/* recepient box start */}
                <div
                  className={`recepient-box ${
                    details?.bulk_list?.length > 0 && "recepient-box-show"
                  }`}
                >
                  <div className="text-line">
                    <span></span>
                    <p className="text">Recipients</p>
                    <span></span>
                  </div>
                  <div
                    style={{ zIndex: viewAll && "30" }}
                    className="view-total-box"
                  >
                    <p className="total">{`Total added (${details?.bulk_list?.length})`}</p>
                    <div className="view-wrap">
                      {/* view all box start */}
                      <div
                        className={`view-all-box card-bg ${
                          viewAll && "view-all-box-show"
                        }`}
                      >
                        <div className="wrap">
                          {details?.bulk_list?.map((chi, idx) => {
                            return (
                              <div key={idx} className="child-wrap">
                                <figure className="img-box">
                                  <img src={billImg} alt="" className="img" />
                                </figure>
                                <div className="text-box">
                                  <p className="name">
                                    {chi?.meter_no.length > 12
                                      ? `${chi?.meter_no.slice(0, 12)} ...`
                                      : chi?.meter_no}
                                  </p>
                                  <p className="text grey-white-color">{`₦${chi?.amount}`}</p>
                                </div>
                                <div
                                  onClick={() => {
                                    handleRemoveFromList(chi);
                                  }}
                                  className="cancel-box"
                                >
                                  <FaTimes className="icon" />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* view all box end */}
                      {viewAll ? (
                        <p
                          onClick={() => {
                            setViewAll(false);
                            details?.bulk_list?.length === 1 &&
                              setViewAll(false);
                          }}
                          className="text"
                        >
                          Close
                        </p>
                      ) : (
                        <p
                          onClick={() => {
                            setViewAll(true);
                          }}
                          className="text"
                        >
                          View all
                        </p>
                      )}
                    </div>
                  </div>
                  {/* end of view total box */}
                  {/* preview list start */}
                  {!viewAll && (
                    <div className="preview-box">
                      {details?.bulk_list?.slice(0, 2).map((chi, idx) => {
                        const { amount } = chi;
                        return (
                          <div className="box box-card " key={idx}>
                            <div
                              onClick={() => {
                                handleRemoveFromList(chi);
                              }}
                              className="cancel-box"
                            >
                              <FaTimes className="icon" />
                            </div>
                            <figure className="img-box">
                              <img src={billImg} alt="" className="img" />
                            </figure>
                            <p className="name">
                              {" "}
                              {chi?.meter_no.length > 12
                                ? `${chi?.meter_no.slice(0, 12)} ...`
                                : chi?.meter_no}
                            </p>
                            <p className="text grey-white-color">{`₦${amount}`}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* preview list end */}
                  <div className="space-bottom"></div>
                </div>
                {/* recepient box end */}
                <div className="space-bottom"></div>
              </div>
            )}
            {step === 2 && (
              <>
                {/* select schedule start */}
                {/* <SelectScheduleBox
                  id={`onaklupdaaa`}
                  value={fromDate}
                  onChange={(e) => setFromDate(e)}
                  onReset={() => setFromDate("")}
                /> */}
                {/* select schedule end */}
                {details?.bulk_list?.length > 0 ? (
                  <>
                    {details?.bulk_list?.length === 1 ? (
                      <>
                        {details?.bulk_list?.map((chi, idx) => {
                          const {
                            meter_no,
                            amount,
                            beneficiary,
                            meter_type,
                            provider,
                            elec_plan,
                            phone,
                            name,
                          } = chi;
                          return (
                            <PreviewElectricityDetailsBox
                              key={idx}
                              value={beneficiary}
                              onChange={(e) => {
                                handleAddBeneficiary(chi, e.target.checked);
                              }}
                              name={name}
                              provider={provider}
                              number={meter_no}
                              narration={elec_plan?.label}
                              meter_type={meter_type}
                              phone={phone}
                              amount={amount}
                              checked={beneficiary}
                              tipposition={`bottom-left`}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {details?.bulk_list?.map((chi, idx) => {
                          const {
                            // name,
                            amount,
                            beneficiary,
                            meter_no,
                            provider,
                            elec_plan,
                            phone,
                            meter_type,
                            name,
                          } = chi;
                          return (
                            <PreviewElectricityDetailsBox
                              previewOpen={previewOpen}
                              setPreviewOpen={setPreviewOpen}
                              key={idx}
                              id={idx}
                              value={beneficiary}
                              onChange={(e) => {
                                handleAddBeneficiary(chi, e.target.checked);
                              }}
                              name={name}
                              provider={provider}
                              logo={elec_plan?.logo}
                              number={meter_no}
                              narration={elec_plan?.label}
                              collapse
                              meter_type={meter_type}
                              phone={phone}
                              amount={amount}
                              checked={beneficiary}
                              tipposition={`top-left`}
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <PreviewElectricityDetailsBox
                      value={details?.beneficiary}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, beneficiary: e.target?.checked };
                        });
                      }}
                      name={customerName}
                      provider={details?.meter_type || selectedProvider}
                      number={details?.meter_no}
                      narration={details?.elec_plan?.label}
                      amount={details?.amount}
                      checked={details?.beneficiary}
                      phone={details?.phone}
                      meter_type={details?.meter_type || selectedProvider}
                      tipposition={`bottom-left`}
                      email={details?.email}
                    /> */}
                    <NewPreviewDetailBox
                      onReset={() => setFromDate("")}
                      valueSchedule={fromDate}
                      onChangeSchedule={(e) => {
                        setFromDate(e);
                      }}
                      value={details?.beneficiary}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, beneficiary: e.target.checked };
                        });
                      }}
                      logo={details?.elec_plan?.logo}
                      previewOpen={previewOpen}
                      setPreviewOpen={setPreviewOpen}
                      name={customerName}
                      provider={details?.meter_type || selectedProvider}
                      number={details?.meter_no}
                      narration={details?.elec_plan?.label}
                      amount={details?.amount}
                      checked={details?.beneficiary}
                      phone={details?.phone}
                      meter_type={details?.meter_type || selectedProvider}
                      email={details?.email}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, user_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Electricity Purchase Successful."
        text={` You should receive a confirmation message with the details of your purchase shortly.  We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default DashboardBillPaymentElectricity;
