import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../../../../styles/dashboard/send-money/SendMoneyIndex.css";
import autoIcon from "../../../../assets/auto-icon-upload.svg";
import manualIcon from "../../../../assets/manual-upload-icon.svg";

const SelectUploadPayrollModal = ({ visible, onClose }) => {
  // eslint-disable-next-line no-unused-vars
  const [activeSelect, setActiveSelect] = useState("Manual Upload");
  //   const [theme] = useDarkMode(localStorage.getItem("theme"));

  //   const size = useWindowSize();
  const navigate = useNavigate();

  return (
    <RavenModal visble={visible} onClose={onClose}>
      <div className="send-money-select-bulk-type-modal-wrap">
        {/* switch box start */}
        <p
          style={{
            fontWeight: "700",
            fontSize: "1.7rem",
            marginBottom: "-1rem",
          }}
          className="select-payrolll-title"
        >
          Select Upload Type
        </p>
        {/* switch box end */}
        {/* select box start */}
        <div className="select-box">
          <div
            onClick={() => {
              navigate("/dashboard-payroll-create-manual");
            }}
            className="select-item grey-bg"
          >
            <figure className="img-box white-black-bg">
              <img src={manualIcon} alt="" className="img" />
            </figure>
            <div className="text-box">
              <p className="text">Manual Upload</p>
              <p style={{ fontSize: "1.2rem" }} className="grey-white-color">
                Provide each employee details manually
              </p>
            </div>
            <FaAngleRight className="icon" />
          </div>
          <div
            onClick={() => {
              navigate("/dashboard-payroll-create-auto");
            }}
            className="select-item grey-bg"
          >
            <figure className="img-box white-black-bg">
              <img src={autoIcon} alt="" className="img" />
            </figure>
            <div className="text-box">
              <p className="text">Automatic Upload</p>
              <p style={{ fontSize: "1.2rem" }} className="grey-white-color">
                Uplaod a file containing employee details
              </p>
            </div>
            {/* <p className="text">Automatic Upload </p> */}
            <FaAngleRight className="icon" />
          </div>
        </div>
        {/* select box end */}
        {/* <div className="des-text grey-white-color">
          <div className="img-wrap">
            <figure className="img-box">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="img"
              >
                <path
                  d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                  stroke="#EA872D"
                  strokeWidth="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </figure>
          </div>
          <span>
            {" "}
            {activeSelect === "Automatic Upload"
              ? `Automatic upload allows you upload using a CSV File to either a Raven user or bank account directly, select your preferred to get started with payroll`
              : `Manual upload allows you input your details and process your payroll creation much faster and easier, than the usual.`}
          </span>
        </div> */}
      </div>
    </RavenModal>
  );
};

export default SelectUploadPayrollModal;
