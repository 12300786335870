import {  RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import React, {  useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/payment-links/PaymentLink.css";
// import oneTimeIcon from "../../../assets/request-one-time-icon.svg";
// import reccuringIcon from "../../../assets/request-one-time-icon (1).svg";
// import { FaCheck } from "react-icons/fa";
// import moment from "moment";
// import PreviewPaymentDetailsBox from "./PreviePaymentLinkBox";
// import DeleteModal from "../../../components/reusables/DeleteModal";
// import DeletePaymentModal from "./DeletePaymentModal";
import { useDispatch, useSelector } from "react-redux";
// import { createPaymentLink } from "../../../redux/requestMoney";
// import { formatNumWithoutCommaNaira } from "../../../utils/helper/Helper";
import SuccessModal from "../../../components/reusables/SuccessModal";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import NewPreviewDetailBox from "./NewPreviewLinkBox";
// import ActionContext from "../../../context/ActionContext";
import { validateEmail } from "../../../utils/helper/ValidateInput";
import {
//   createCustomers,
  getCustomer,
  updateCustomer,
} from "../../../redux/customers";
// import NocontentPage from "../../../components/reusables/NocontentPage";
import NoContentBox from "../../../components/reusables/NoContentBox";
// import { validateEmail } from "../../../utils/helper/ValidateInput";

const UpdateCustomerIndex = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
//   const actionCtx = useContext(ActionContext);
  const { fetachingUpdate, loading } = useSelector((state) => state.customers);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const stepList = ["Customer details"];
  // eslint-disable-next-line no-unused-vars
  const [paymentType, setPaymentType] = useState("Single Charge");

  const [details, setDetails] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });
  const [checkDetails, setCheckDetails] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });

  useEffect(() => {
    const id = localStorage.getItem("customer-inv-id");
    if (id) {
      getSingleLink();
    } else {
      navigate("/dashboard-customers");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleLink = async () => {
    const id = localStorage.getItem("customer-inv-id");
    const data = await dispatch(getCustomer(id));
    if (data?.payload?.data?.status === "success") {
      // console.log(data);
      const obj = data?.payload?.data?.data;
      setDetails((prev) => {
        return {
          ...prev,
          id: obj?.id,
          email: obj?.customer_email,
          first_name: obj?.customer_name?.split(" ")[0] || "",
          last_name: obj?.customer_name?.split(" ")[1] || "",
          phone: obj?.customer_phone,
        };
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
    setCheckDetails(obj);
  };

  const [error, setError] = useState({
    email: false,
  });

  const handleSubmit = async () => {
    if (!validateEmail(details.email)) {
      toast.error("Invalid email address!", { position: "top-right" });
      setError((prev) => {
        return { ...prev, email: true };
      });
      return;
    }
    const obj = {
      customer_email: details?.email,
      customer_name: `${details?.first_name} ${details?.last_name}`,
      customer_phone: `0${details?.phone}`,
      customer_id: String(details?.id),
    };
    // console.log(obj);
    // return;
    const data = await dispatch(updateCustomer(obj));
    if (data?.payload?.data?.status === "success") {
      setDetails({
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
      });
      setCheckDetails({
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
      });
      navigate("/dashboard-customers");
    }
  };

  const size = useWindowSize();
  // const [showError, setShowError] = useState({
  //   num: false,
  //   email: false,
  //   amount: false,
  //   meter: false,
  // });

    useEffect(() => {
      if (
        !user_resource_and_permissions?.allowed_permissions?.includes(
          "can_update_customer"
        )
      ) {
        navigate("/dashboard-customers" || -1);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_resource_and_permissions]);

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          back
          onBack={() => navigate("/dashboard-customers" || -1)}
          title={`Update Customer`}
          text={`Edit a customer to your list of customers.`}
        />
        {/* wrapper start */}
        {!fetachingUpdate ? (
          <ContainerWrapper
            width={size?.width < 900 && `90%`}
            btnText={`Update Customer`}
            stepList={stepList}
            activeStep={step}
            back={step > 1}
            loading={loading}
            stepClickable={
              paymentType === "Single Charge"
                ? details?.request_amount &&
                  details?.title &&
                  details?.request_description
                : details?.request_amount &&
                  details?.title &&
                  details?.request_description &&
                  details?.expiry_date
            }
            onStepClick={(e) => {
              paymentType === "Single Charge"
                ? details?.request_amount &&
                  details?.title &&
                  details?.request_description &&
                  setStep(e)
                : details?.request_amount &&
                  details?.title &&
                  details?.request_description &&
                  details?.expiry_date &&
                  setStep(e);
            }}
            disabled={
              !checkDetails?.email ||
              !checkDetails?.first_name ||
              !checkDetails?.last_name ||
              !checkDetails?.phone
            }
            onBack={() => {
              step > 1 && setStep(step - 1);
            }}
            onBtnClick={() => {
              if (step === 1) {
                handleSubmit();
              }

              // step === 2 && handleSubmit();
            }}
          >
            {/* request money wrap start */}
            <div className="request-money-wrap-box">
              {step === 1 && (
                <>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    action=""
                    autoComplete="off"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    <RavenInputField
                      type={`text`}
                      label={`First Name*`}
                      color={`black-light`}
                      placeholder={`e.g Timi`}
                      // showError={showError?.email}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={details?.first_name}
                      name={`first_name`}
                      capitalize
                      // className={`below-expiry`}
                    />
                  </form>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    action=""
                    autoComplete="off"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    <RavenInputField
                      type={`text`}
                      label={`Last Name*`}
                      color={`black-light`}
                      placeholder={`e.g Paul`}
                      // showError={showError?.email}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={details?.last_name}
                      name={`last_name`}
                      // className={`below-expiry`}
                      capitalize
                    />
                  </form>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    action=""
                    autoComplete="off"
                    style={{ width: "100%" }}
                  >
                    <RavenInputField
                      type={`email`}
                      label={`Email Address* `}
                      color={`black-light`}
                      placeholder={`E.g ayeni@gmail.com`}
                      // textareaRow={4}
                      onChange={(e) => {
                        // console.log(e.target.value);
                        handleChange(e);
                        setError((prev) => {
                          return { ...prev, email: false };
                        });
                      }}
                      value={details?.email}
                      showError={error?.email}
                      name={`email`}
                    />
                  </form>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    action=""
                    autoComplete="off"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    <RavenInputField
                      type={`phone`}
                      label={`Phone Number`}
                      color={`black-light`}
                      placeholder={`E.g 816263....`}
                      onChange={handleChange}
                      value={details?.phone}
                      name={`phone`}
                    />
                  </form>
                </>
              )}
              {/* step 2 start -------------------------- */}
              {step === 2 && (
                <>
                  {/* <PreviewPaymentDetailsBox
                  narration={details?.request_description}
                  linkType={paymentType}
                  email={details?.title}
                  amount={details?.request_amount}
                  linkDuration={details?.expiry_date}
                  value={details?.agree}
                  onChange={(e) =>
                    setDetails((prev) => {
                      return { ...prev, agree: e.target.checked };
                    })
                  }
                  id={`nnsj873`}
                  checked={details?.agree}
                /> */}
                  <NewPreviewDetailBox
                    linkDuration={details?.expiry_date}
                    amount={details?.request_amount}
                    narration={details?.request_description}
                    linkType={paymentType}
                    email={details?.title}
                  />
                </>
              )}
              {/* step 2 end -------------------------- */}
              {/* <div className="space-bottom"></div> */}
            </div>
            {/* request money wrap end */}
          </ContainerWrapper>
        ) : (
          <NoContentBox
            loading={fetachingUpdate}
            // img={noContentImg}
            title={`Customers globally 🌍, with your Payment links`}
            // list={noContentList}
            btntext={`Back to Customers screen`}
            onBtnClick={() => {
              navigate("/dashboard-customers");
            }}
          />
        )}
        {/* wrapper end */}
      </DashboardLayout>
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        title="Link Created Successful."
        text={`Payment link successfully created, click on close to view link on payment link list`}
        btnText={`Close`}
        onBtnClick={() => {
          navigate("/dashboard-payment-links");
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default UpdateCustomerIndex;
