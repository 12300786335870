import { configureStore } from "@reduxjs/toolkit";
import home from "./home";
import user from "./user";
import transaction from "./transaction";
import verifications from "./verifications";
import settings from "./settings";
import paymentLinks from "./requestMoney";
import billPayment from "./billPayment";
import schedule from "./schedulePayment";
import payroll from "./payroll";
import cards from "./card";
import pos from "./pos";
import invoice from "./invoice";
import sub_account from "./sub_account";
import role_and_permission from "./roleAndPermission"
import payment_request from "./paymentRequest"
import customers from "./customers";
import  global_account from "./globalAccount";

// const initialState = {};
const store = configureStore({
  reducer: {
    user,
    home,
    transaction,
    schedule,
    paymentLinks,
    billPayment,
    payroll,
    invoice,
    pos,
    cards,
    verifications,
    settings,
    sub_account,
    role_and_permission,
    payment_request,
    customers,
    global_account,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore the headers field in all actions
        ignoredActions: "*",
        ignoredPaths: ["payload.headers"],
      },
    }),
});

export default store;
