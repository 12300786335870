/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
// import setAuthToken from "../utils/auth";
import { toast } from "@ravenpay/raven-bank-ui";

export const getSubAccountCreateDetails = createAsyncThunk(
  "admin/get-sub-account-create-details",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("/get_data_for_account_creation");
      if (data?.response?.data?.status === "fail") {
        //   toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log(data);
        //   toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(setSubAccountDetails(data?.data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

// export const getInfoForAddRoleToSubAccount = createAsyncThunk(
//   "admin/info-for-add-role-to-sub-account",
//   async (payload, thunkAPI) => {
//     try {
//       const data = await axios.get(
//         `/sub_account/data_for_role_addition_to_a_sub_account?id=${
//           payload?.id || ""
//         }`
//       );
//       if (data?.response?.data?.status === "fail") {
//         //   toast.error(data?.response?.data?.message, {});
//         return data;
//       }
//       if (data?.data?.status === "success") {
//         // console.log(data);
//         //   toast.success(data?.data?.message, {});
//         // await thunkAPI.dispatch(setSubAccountDetails(data?.data?.data));
//         return data;
//       }
//     } catch (err) {
//       // ;
//       if (err.response.data.status === "fail" && err.response.status !== 401) {
//         toast.error(err.response.data.message, {});
//       }
//       return err;
//     }
//   }
// );

export const getSingleSubAccount = createAsyncThunk(
  "admin/get-single-sub-account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `get_single_sub_account?id=${
          payload?.id || localStorage?.getItem("sub-account-single-id")
        }`
      );
      if (data?.response?.data?.status === "fail") {
        //   toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        //   console.log(data);
        //   toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(
          setSubAccountSingleDetails(data?.data?.data?.account)
        );
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSingleSubAccountRoleInfo = createAsyncThunk(
  "admin/get-single-sub-account-role-info",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/sub_account/data_for_role_addition_to_a_sub_account?id=${payload?.id}`
      );
      // console.log(data, "-----------------");
      if (data?.response?.data?.status === "fail") {
        //   toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log(data);
        //   toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(
          setSubAccountSingleDetailsRoleInfo(data?.data?.data)
        );
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const createSubAccount = createAsyncThunk(
  "admin/create-sub-account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/generate_account_number", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const changeAuthLevelInSubAccount = createAsyncThunk(
  "admin/change-auth-level-sub-account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/sub_account/change_user_auth_on_an_account",
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const addRoleToSubAccount = createAsyncThunk(
  "admin/add-role-to-sub-account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/sub_account/add_roles_to_existing_account",
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const changePrimarySubAccount = createAsyncThunk(
  "admin/change-primary-sub-account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/update_primary_account", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const removeRoleFromSubAccount = createAsyncThunk(
  "admin/remove-role-from-sub-account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/remove_role_from_sub_account", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const freezeAndUnfreezeSubAccount = createAsyncThunk(
  "admin/freeze-unfreeze-sub-account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/sub_account/freeze_and_un_freeze_sub_account",
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const sub_account = createSlice({
  name: "payment-link",
  initialState: {
    isAuth: false,
    loading: false,
    subAccoutDetailList: {},
    single_sub_account: {},
    single_sub_account_role_info: {},
    loadingSearch: false,
    loadingTeamUpdate: false,
    loadingStatement: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setSubAccountDetails: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.subAccoutDetailList = action.payload;
    },
    setSubAccountSingleDetails: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.single_sub_account = action.payload;
    },
    setSubAccountSingleDetailsRoleInfo: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.single_sub_account_role_info = action.payload;
    },
  },
  extraReducers: {
    [getSubAccountCreateDetails.pending]: (state) => {
      state.loadingTeamUpdate = true;
    },
    [getSubAccountCreateDetails.fulfilled]: (state) => {
      state.loadingTeamUpdate = false;
    },
    [getSubAccountCreateDetails.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingTeamUpdate = false;
      state.isAuth = false;
      state = null;
    },
    [createSubAccount.pending]: (state) => {
      state.loading = true;
    },
    [createSubAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [createSubAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSingleSubAccount.pending]: (state) => {
      state.loading = true;
    },
    [getSingleSubAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSingleSubAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [changePrimarySubAccount.pending]: (state) => {
      state.loading = true;
    },
    [changePrimarySubAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [changePrimarySubAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [removeRoleFromSubAccount.pending]: (state) => {
      state.loading = true;
    },
    [removeRoleFromSubAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [removeRoleFromSubAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSingleSubAccountRoleInfo.pending]: (state) => {
      state.loadingStatement = true;
    },
    [getSingleSubAccountRoleInfo.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [getSingleSubAccountRoleInfo.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [addRoleToSubAccount.pending]: (state) => {
      state.loadingTeamUpdate = true;
    },
    [addRoleToSubAccount.fulfilled]: (state) => {
      state.loadingTeamUpdate = false;
    },
    [addRoleToSubAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingTeamUpdate = false;
      state.isAuth = false;
      state = null;
    },
    [freezeAndUnfreezeSubAccount.pending]: (state) => {
      state.loadingTeamUpdate = true;
    },
    [freezeAndUnfreezeSubAccount.fulfilled]: (state) => {
      state.loadingTeamUpdate = false;
    },
    [freezeAndUnfreezeSubAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingTeamUpdate = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setSubAccountDetails,
  setSubAccountSingleDetails,
  setSubAccountSingleDetailsRoleInfo,
} = sub_account.actions;

export default sub_account.reducer;
