import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import "../../../../styles/dashboard/pos/DashboardPosIndex.css";

const ViewSingleTopUPModal = ({ visible, onClose }) => {
  const { terminalInfoSingle, loading} =
    useSelector((state) => state.pos);
  const [copyItem, setCopyItem] = useState("");
  const handleCopy = async () => {
    setCopyItem(terminalInfoSingle?.accountDetail?.account_number);
    await navigator.clipboard.writeText(
      terminalInfoSingle?.accountDetail?.account_number
    );
    setTimeout(() => {
      setCopyItem("");
    }, 1000);
  };
  return (
    <RavenModal visble={visible} onClose={onClose}>
      <div className="top-up-pos-modal-wrap-box">
        <div className="wrap grey-bg">
          <div className="top-wrap">
            <p className="text">TOP UP TO YOUR ACCOUNT</p>
          </div>
          {/* bottom wrap  start */}
          <div className="bttom-wrap">
            {/* box start */}
            <div className="box">
              <div className="label-value-box">
                <p className="label grey-white-color">Account Number</p>
                {loading ? (
                  <Skeleton width={`100%`} height={20} />
                ) : (
                  <p className="value">
                    {terminalInfoSingle?.accountDetail?.account_number || "---"}
                  </p>
                )}
              </div>
              {loading ? (
                <Skeleton width={45} height={22} borderRadius={`2rem`} />
              ) : (
                <div
                  onClick={() => handleCopy("account-number")}
                  className={`copy-box grey-bg ${
                    copyItem && "copy-box-active"
                  } `}
                >
                  {copyItem ? (
                    <>
                      <span>
                        <FaCheck className="icon" />
                      </span>
                      <span>COPIED</span>{" "}
                    </>
                  ) : (
                    <span>COPY</span>
                  )}
                </div>
              )}
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <div className="label-value-box">
                <p className="label grey-white-color">Account Name</p>
                {loading ? (
                  <Skeleton width={150} height={20} />
                ) : (
                  <p className="value">
                    {terminalInfoSingle?.accountDetail?.account_name || "---"}
                  </p>
                )}
              </div>
              <div style={{ marginRight: "unset" }} className="label-value-box">
                <p className="label grey-white-color">Bank</p>
                {loading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  <p className="value">
                    {terminalInfoSingle?.accountDetail?.bank || "---"}
                  </p>
                )}
              </div>
            </div>
            {/* box end */}
          </div>
          {/* bottom wrap  end */}
        </div>
      </div>
    </RavenModal>
    
  );
};

export default ViewSingleTopUPModal;
