import { RavenInputField, RavenModal, toast } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../../redux/settings";
import { getUser } from "../../../redux/user";
// import { validateUniqueEmail } from "../../../../redux/user";
import "../../../styles/dashboard/pos/DashboardPosIndex.css";
// import { reactSelectStyle } from "../../../../utils/helper/Helper";
import { validateEmail } from "../../../utils/helper/ValidateInput";

const EditProfileModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.user);
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  useEffect(() => {
    // console.log(user);
    setDetails((prev) => {
      return {
        ...prev,
        first_name: user?.fname,
        last_name: user?.lname,
        email: user?.email,
      };
    });
  }, [user]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(details.email)) {
      toast.error("Invalid email address!", {
        position: "top-right",
      });
      setShowError((prev) => {
        return { ...prev, email: true };
      });
      return;
    }
    const obj = {
      fname: details?.first_name,
      lname: details?.last_name,
      email: details?.email,
    };
    // console.log(obj);
    // return
    const data = await dispatch(updateUserProfile(obj));
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
      onClose();
      setDetails({
        first_name: "",
        last_name: "",
        email: "",
        role: "",
      });
    }
  };

  const [showError, setShowError] = useState({
    num: false,
    email: false,
    amount: false,
    meter: false,
  });

  return (
    <RavenModal
      visble={visible}
      onClose={onClose}
      btnColor={`black-light`}
      btnLabel={`Edit Profile`}
      loading={loading}
      disabled={!details?.email || !details?.first_name || !details?.last_name}
      onBtnClick={handleSubmit}
    >
      <div className="cashier-assign-modal-wrap">
        <p className="title">Edit Profile</p>
        <p className="text grey-white-color">
          Personalize your account details, update your contact information
        </p>
        <div className="underline"></div>
        {/* form wrap start */}
        <div className="form-inputs-wrap">
          <RavenInputField
            color={`black-light`}
            type={`text`}
            label={`First Name`}
            placeholder={`E.g Timi`}
            onChange={handleChange}
            name={`first_name`}
            value={details?.first_name}
          />
          <RavenInputField
            color={`black-light`}
            type={`text`}
            label={`Last Name`}
            placeholder={`E.g Ayeni`}
            onChange={handleChange}
            name={`last_name`}
            value={details?.last_name}
          />
          <RavenInputField
            color={`black-light`}
            type={`email`}
            label={`Email address* `}
            placeholder={`E.g ayenikehinde@...`}
            name={`email`}
            value={details?.email}
            showError={showError?.email}
            onChange={(e) => {
              setShowError((prev) => {
                return { ...prev, email: false };
              });
              handleChange(e);
            }}
          />
        </div>
        {/* form wrap end */}
      </div>
    </RavenModal>
  );
};

export default EditProfileModal;
