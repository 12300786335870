import payrollIcon from "../../assets/quick-payroll-icon.svg";
import payBillIcon from "../../assets/quick-pay-bill-icon.svg";
import invoiceIcon from "../../assets/quick-invoice-icon.svg";
import posIcon from "../../assets/overview-pos-icon.svg";

export const quickActionAllList = [
  {
    name: "Payroll",
    icon: payrollIcon,
    text: "Automate the process of paying your staff.",
    link: "/dashboard-payroll?dashboard-overview",
  },
  {
    name: "Pay Bills",
    icon: payBillIcon,
    text: "All your bill payment handled seamlessly.",
    link: "/dashboard-bill-payment?dashboard-overview",
  },
  {
    name: "Invoice",
    icon: invoiceIcon,
    text: "Track who owes you and accept payment directly.",
    link: "/dashboard-invoice?dashboard-overview",
  },
  {
    name: "BankBox" || "POS",
    icon: posIcon,
    text: "Manage all your BankBox terminals easily.",
    link: "/dashboard-pos?dashboard-overview",
  },
  {
    name: "Cards",
    icon: payrollIcon,
    text: "Shop globally with Naira and Dollar virtual Cards.",
    link: "/dashboard-card?dashboard-overview",
  },
  {
    name: "Loans",
    icon: payBillIcon,
    text: "All your bill payment handled seemlessly.",
    link: "/dashboard-loan?dashboard-overview",
  },
  {
    name: "Stores",
    icon: invoiceIcon,
    text: "Setup your store and create an inventory for your pro",
    link: "/dashboard-store?dashboard-overview",
  },
  {
    name: "Payment links",
    icon: posIcon,
    text: "Receive payment globally with just your link.",
    link: "/dashboard-payment-links?dashboard-overview",
  },
  {
    name: "Airtime",
    icon: invoiceIcon,
    text: "Buy airtime both single and in bulk to all network.",
    link: "/dashboard-bill-payment-airtime?dashboard-overview",
  },
  {
    name: "Data",
    icon: posIcon,
    text: "Buy data both single and in bulk to all network.",
    link: "/dashboard-bill-payment-data?dashboard-overview",
  },
  {
    name: "Cable TV",
    icon: payrollIcon,
    text: "Recharge your Cable plans from your comfort.",
    link: "/dashboard-bill-payment-cable?dashboard-overview",
  },
  {
    name: "Electricity",
    icon: payBillIcon,
    text: "Pay electricity bill, and automate the process.",
    link: "/dashboard-bill-payment-electricity?dashboard-overview",
  },
  {
    name: "Overdraft",
    icon: invoiceIcon,
    text: "Empower your business with quick cash.",
    link: "/dashboard-overdraft?dashboard-overview",
  },
  {
    name: "Invoice financing",
    icon: posIcon,
    text: "Track who owes you and accept payment directly.",
    link: "/dasboard-finance-invoice?dashboard-overview",
  },
];
