import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
// import settingIcon from ",,.../../assets/payroll-settings-icon.svg";
// import autoIcon from "../../../assets/auto-icon-upload.svg";
import payrollSettingImg from "../../../assets/auto-icon-upload.svg";
import payrollHistoryImg from "../../../assets/payroll-history-icon-drop.svg";
import deactivateImg from "../../../assets/drop-settings-icon.svg";
import "../../../styles/dashboard/payroll/PayrollIndex.css";
// import GaugeChart from "react-gauge-chart";
import noContentImg from "../../../assets/no-payroll-img.png";
import { ThreeDots } from "react-loader-spinner";
import {
  // RavenActionBar,
  RavenButton,
  RavenInputField,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
  // toast,
} from "@ravenpay/raven-bank-ui";
// import downloadIcon from "../../../assets/download-icon.svg";
import DeactivatePayroll from "./modals/DeactivatePayroll";
import ClearPayrollDataModal from "./modals/ClearPayrollDataModal";
import ProcessPayrollModal from "./modals/ProcessPayrollModal";
// import TransactionPinModal from "../../../components/reusables/TransactionPinModal";
import InitiatePayrollModal from "./modals/InitiatePayrollModal";
import { useSelector, useDispatch } from "react-redux";
// import { FaAngleRight } from "react-icons/fa";
import {
  generateAllCsvForEmp,
  // deletePayroll,
  getPayrolls,
  getPayrollsBySearch,
  getPayrollSetup,
} from "../../../redux/payroll";
import {
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  formatStatus,
  getPageNum,
  getTotalPage,
  reactSelectStyle,
  trimLongString,
} from "../../../utils/helper/Helper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import useDebounce from "../../../utils/helper/UseDebounce";
import DeleteUserModal from "./modals/DeleteUserModal";
import ManageDeductionModal from "./modals/ManageDeductionModal";
import ActionContext from "../../../context/ActionContext";
// import useWindowSize from "../../../utils/helper/UseWindowSize";
import NocontentPage from "../../../components/reusables/NocontentPage";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import SelectUploadPayrollModal from "./modals/SelectUploadPayrollModal";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";

const DashboardPayrollIndex = () => {
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  // const [perPage] = useState(5);
  const { payrollSettings, allEmployees, loading, loadingCsv, loadingSearch } =
    useSelector((state) => state.payroll);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const [signleObj, setSingleObj] = useState({});
  // const [payrollFound, setPayrollFound] = useState(false);
  const [refreshTable, setResfreshTable] = useState(false);
  // const [disableGuageAnime, setDisableGuageAnime] = useState(true);
  const [urlBack, setUrlBack] = useState("");
  const navigate = useNavigate();
  const [payrollSetUp, setPayrollSetUp] = useState({});
  const [showModal, setShowModal] = useState({
    deactivate_payroLL: false,
    clear_payroll_data: false,
    process_payroll: false,
    initiate_payroll: false,
    delete_employee: false,
    manage_deduction: false,
    select_type: false,
  });
  const [gaugeInfo, setGaugeInfo] = useState({
    total: 0,
    used: 0,
    daysLeft: 0,
  });

  const payrollSettingsList = [
    {
      label: "Payroll Settings",
      labelTwo: "Manage all your payroll setup settings",
      img: payrollSettingImg,
    },
    {
      label: "Payroll Histories",
      labelTwo: "See previous disbursement of salaries",
      img: payrollHistoryImg,
    },
    {
      label: "Deactivate Payroll",
      labelTwo: "Pause your payroll for a certain time.",
      img: deactivateImg,
    },
    // {
    //   label: "Clear Payroll data",
    //   labelTwo: "Delete and clear all data from this payroll",
    //   img: deactivateImg,
    // },
  ];

  const purpleNavIcon = (
    <svg
      className="img"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.07422" cy="8" r="8" fill="#F0EEFF" />
      <g clipPath="url(#clip0_15985_77757)">
        <path
          d="M8.07438 12.1666C10.3756 12.1666 12.241 10.3012 12.241 7.99998C12.241 5.69879 10.3756 3.83331 8.07438 3.83331C5.7732 3.83331 3.90771 5.69879 3.90771 7.99998C3.90771 10.3012 5.7732 12.1666 8.07438 12.1666Z"
          fill="#755AE2"
        />
        <path
          d="M8.07438 9.66665L9.74105 7.99998L8.07438 6.33331"
          fill="#755AE2"
        />
        <path
          d="M8.07438 9.66665L9.74105 7.99998M9.74105 7.99998L8.07438 6.33331M9.74105 7.99998H6.40771M12.241 7.99998C12.241 10.3012 10.3756 12.1666 8.07438 12.1666C5.7732 12.1666 3.90771 10.3012 3.90771 7.99998C3.90771 5.69879 5.7732 3.83331 8.07438 3.83331C10.3756 3.83331 12.241 5.69879 12.241 7.99998Z"
          stroke="#F0EEFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15985_77757">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(3.07422 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  useEffect(() => {
    const url = window?.location?.href?.split("?")[1];
    if (url) {
      setUrlBack(url);
    }
  }, []);

  const noContentList = [
    "Ensures that employees are paid accurately and on time",
    "Provides a record of employee earnings and deductions,",
    "Comply with legal requirements for tax and deductions automatically.",
  ];

  const headerList = [
    " NAME",
    "ACCOUNT NO.",
    "BANK NAME",
    "USERNAME",
    "SALARY",
    "STATUS",
  ];

  const avatarFormatWrap = (param) => {
    return (
      <div className="text-avatar-box">
        <div className="avatar-box">
          <span>{`${param?.split(" ")[0]?.charAt(0)} ${param
            ?.split(" ")[1]
            ?.charAt(0)}`}</span>
        </div>
        <p style={{ textTransform: "capitalize" }} className="text">
          {param}
        </p>
      </div>
    );
  };

  const payrollActions = (param) => {
    return (
      <div className="payroll-table-action-wrap">
        {loading ? (
          <>
            <Skeleton width={35} height={35} circle />{" "}
            <Skeleton width={35} height={35} circle />
          </>
        ) : (
          <>
            {" "}
            {user_resource_and_permissions?.allowed_permissions?.includes(
              "can_update_employee_records"
            ) && (
              <figure
                className="img-box grey-bg darken-action-bg tooltip-hover-wrap"
                onClick={() => {
                  localStorage.setItem("payroll-employee-id", param?.id);
                  navigate("/dashboard-payroll-employee-profile-edit");
                }}
              >
                <RavenToolTip
                  color={`black-light`}
                  text={`Edit employee ${param?.first_name} ${param?.last_name}`}
                  position={`left`}
                />
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="img dull-bright-filter"
                >
                  <path
                    d="M9.49998 15.0001H16.25M2.75 15H4.00591C4.37279 15 4.55624 15 4.72887 14.9586C4.88192 14.9219 5.02824 14.8613 5.16245 14.779C5.31382 14.6862 5.44354 14.5565 5.70296 14.2971L15.125 4.87505C15.7463 4.25373 15.7463 3.24637 15.125 2.62505C14.5037 2.00373 13.4963 2.00373 12.875 2.62505L3.45295 12.0471C3.19352 12.3065 3.0638 12.4362 2.97104 12.5876C2.8888 12.7218 2.82819 12.8681 2.79145 13.0212C2.75 13.1938 2.75 13.3773 2.75 13.7442V15Z"
                    // stroke="#020202"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </figure>
            )}
            {user_resource_and_permissions?.allowed_permissions?.includes(
              "can_delete_an_employee_from_payroll"
            ) && (
              <figure
                onClick={() => {
                  setSingleObj(param);
                  setShowModal((prev) => {
                    return { ...prev, delete_employee: true };
                  });
                }}
                className="img-box delete-box darken-action-bg tooltip-hover-wrap"
              >
                <RavenToolTip
                  color={`black-light`}
                  text={`Delete employee ${param?.first_name} ${param?.last_name}`}
                  position={`top-left`}
                />
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="img"
                >
                  <path
                    d="M10.5 4.5V3.9C10.5 3.05992 10.5 2.63988 10.3365 2.31901C10.1927 2.03677 9.96323 1.8073 9.68099 1.66349C9.36012 1.5 8.94008 1.5 8.1 1.5H6.9C6.05992 1.5 5.63988 1.5 5.31901 1.66349C5.03677 1.8073 4.8073 2.03677 4.66349 2.31901C4.5 2.63988 4.5 3.05992 4.5 3.9V4.5M6 8.625V12.375M9 8.625V12.375M0.75 4.5H14.25M12.75 4.5V12.9C12.75 14.1601 12.75 14.7902 12.5048 15.2715C12.289 15.6948 11.9448 16.039 11.5215 16.2548C11.0402 16.5 10.4101 16.5 9.15 16.5H5.85C4.58988 16.5 3.95982 16.5 3.47852 16.2548C3.05516 16.039 2.71095 15.6948 2.49524 15.2715C2.25 14.7902 2.25 14.1601 2.25 12.9V4.5"
                    stroke="#FF0F00"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </figure>
            )}
          </>
        )}
      </div>
    );
  };
  const { user } = useSelector((state) => state.user);

  const [checkSetUp, setCheckSetup] = useState(true);
  useEffect(() => {
    getPayrollSetupFunc();
    fetchPayroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);
  const [tableData, setTableData] = useState(false);
  const getPayrollSetupFunc = async () => {
    const data = await dispatch(getPayrollSetup());
    // console.log(data);
    if (data?.payload?.response?.data?.message === "No Data found") {
      // setTableData(false);
      setCheckSetup(false);
      // console.log("no----------");
    }
    if (data?.payload?.data?.status === "success") {
      const obj = data.payload.data.data.payroll_setup;
      setPayrollSetUp(obj);
      const setUpVal = Object.keys(obj).length === 0 ? false : true;
      // console.log(obj);
      setTableData(setUpVal);
      if (setUpVal) {
        const date = moment(obj?.next_pay_date).format("YYYY-MM-DD HH:mm:ss");
        // console.log(date.getDate());
        const day = date?.split("-")[2]?.split(" ")[0];
        // console.log(day);
        // console.log(daysUntil25th(Number(day)));

        const dateOne = new Date();
        var date1 = moment(dateOne);
        var date2 = moment(date);
        var diffVal = date2.diff(date1, "days");
        const totalDays = moment(date).date();
        // console.log(diffVal);
        // console.log(totalDays);
        const daysUsed = totalDays - diffVal;
        // console.log(daysUsed);
        setGaugeInfo((prev) => {
          return {
            ...prev,
            total:
              totalDays > Number(obj?.payday)
                ? Number(obj?.payday) - diffVal
                : totalDays,
            used: daysUsed < 0 ? 0 : daysUsed,
            daysLeft: daysUntil25th(Number(day)),
          };
        });
      }
    }
  };

  function daysUntil25th(targetDay) {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    // const targetDay = 25;

    let daysLeft;

    if (currentDay < targetDay) {
      daysLeft = targetDay - currentDay;
    } else {
      const nextMonth = currentDate.getMonth() + 1;
      const nextYear =
        nextMonth > 11
          ? currentDate.getFullYear() + 1
          : currentDate.getFullYear();
      const nextMonthStartDate = new Date(nextYear, nextMonth, 1);
      const daysInNextMonth =
        (nextMonthStartDate - currentDate) / (1000 * 60 * 60 * 24);

      daysLeft = daysInNextMonth + targetDay;
    }
    return Number(Math.floor(daysLeft));
  }

  const [currentPage] = useState(1);
  const fetchPayroll = async (cur, e) => {
    const payload = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: cur || currentPage,
      verification_status: e?.value,
    };
    const data = await dispatch(getPayrolls(payload));
    // console.log(data);
    if (data?.payload?.status === "success") {
      const val = data?.payload?.data?.employees?.data;
      // console.log(val);
      // setDisableGuageAnime(false);
      if (val?.length > 0) {
        // setPayrollFound(true);
        setTableDataTwo(true);
      }
    }
  };

  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      const obj = {
        search_term: search,
      };
      dispatch(getPayrollsBySearch(obj));
      // console.log(obj);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      fetchPayroll();
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const formatTypeWrap = (param) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "0" && "status-type-wrap-pending"
        } ${
          formatStatus(param) === "processing" && "status-type-wrap-success"
        } ${param === "1" && "status-type-wrap-success"} ${
          param === "2" && "status-type-wrap-fail"
        } ${param === "overdue" && "status-type-wrap-pending"} ${
          param === "0" && "status-type-wrap-pending"
        } ${param === "pending" && "status-type-wrap-pending"}`}
      >
        <span style={{ textTransform: "capitalize" }}>
          {param === "0" ? "pending" : param === "1" ? "Verified" : "Failed"}
        </span>
      </div>
    );
  };

  const [sortFilter, setSortFilter] = useState({ label: "All", value: "" });
  const selectOption = [
    { label: "All", value: "" },
    { label: "Verified", value: "verification_successful" },
    { label: "Pending", value: "pending_verification" },
    { label: "Not Verified", value: "verification_failed" },
  ];

  const handleFinish = () => {
    actionCtx?.setCheckShow("close");
    setShowModal({
      deactivate_payroLL: false,
      clear_payroll_data: false,
      process_payroll: false,
      initiate_payroll: false,
      delete_employee: false,
      manage_deduction: false,
    });
    setResfreshTable(!refreshTable);
  };

  const generateAllCsvFile = async () => {
    const obj = {
      status: sortFilter?.value,
    };
    const data = await dispatch(generateAllCsvForEmp(obj));
    if (data?.payload?.data?.status === "success") {
      downloadCsv(data?.payload?.data?.data?.url);
      // console.log(data);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const downloadIcon = (
    <svg
      className="img"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9492 13.5133V14.7133C18.9492 16.3935 18.9492 17.2335 18.6222 17.8753C18.3346 18.4398 17.8757 18.8987 17.3112 19.1863C16.6695 19.5133 15.8294 19.5133 14.1492 19.5133H5.74922C4.06906 19.5133 3.22898 19.5133 2.58725 19.1863C2.02276 18.8987 1.56382 18.4398 1.2762 17.8753C0.949219 17.2335 0.949219 16.3935 0.949219 14.7133V13.5133M14.9492 8.51331L9.94922 13.5133M9.94922 13.5133L4.94922 8.51331M9.94922 13.5133V1.51331"
        stroke="#676767"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  // const size = useWindowSize();

  function isJSONString(value) {
    // console.log(value);
    if (value) {
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    }
  }

  const [tableDataTwo, setTableDataTwo] = useState(false);

  return (
    <>
      <DashboardLayout>
        {allEmployees?.employees?.data?.length > 0 ||
        tableDataTwo ||
        tableData ? (
          <>
            <TitletextBox
              rearrangeIcon={true}
              title={`Your Payroll`}
              back={urlBack}
              onBack={() => navigate(urlBack ? `/${urlBack}` : -1)}
              text={`Easily manage your staffs  payment and deducations`}
              toolTipMsg={`Add Employee`}
              addBtn={user_resource_and_permissions?.allowed_permissions?.includes(
                "can_add_employee_records"
              )}
              onAdd={() => {
                // console.log('ejaha');
                // setShowModal((prev) => {
                //   return { ...prev, select_type: true };
                // });
                navigate("/dashboard-payroll-create-select-method");
              }}
              // bulkSelectList={bulkSelectList}
              settings={
                payrollSettings?.payroll_status === "1"
                  ? payrollSettingsList
                  : !checkSetUp
                  ? payrollSettingsList
                  : [
                      ...payrollSettingsList?.slice(0, 2),
                      payrollSettingsList[3],
                    ]
              }
              onClickDrop={(e) => {
                e?.label === "Payroll Histories" &&
                  navigate("/dashboard-payroll-history");
                e?.label === "Deactivate Payroll" &&
                  setShowModal((prev) => {
                    return { ...prev, deactivate_payroLL: true };
                  });
                e?.label === "Clear Payroll data" &&
                  setShowModal((prev) => {
                    return { ...prev, clear_payroll_data: true };
                  });
                e.label === "Payroll Settings" &&
                  setShowModal((prev) => {
                    return { ...prev, manage_deduction: true };
                  });
              }}
            />

            {/* {tableData && allEmployees?.employees?.data?.length > 0 && ( */}
            <div
              onClick={() => {
                // console.log(payrollSettings);
                // console.log(allEmployees);
                // console.log(payrollSetUp);
                actionCtx?.setCheckShow("close");
              }}
              className="payroll-top-box-wrap"
            >
              {/* top detail grid card start */}
              <div className="top-payroll-container-box">
                {/* box start */}
                <div className="box card-bg">
                  <div
                    onClick={() => navigate("/dashboard-payroll-history")}
                    className="title grey-white-color"
                  >
                    Total Salary Paid{" "}
                    <div className="title-total ">
                      <figure className="img-box">{purpleNavIcon}</figure>
                    </div>
                  </div>
                  <div className="value-box grey-bg">
                    {loading ? (
                      <Skeleton width={`20%`} height={20} />
                    ) : (
                      <p className="value">
                        {formatNumWithCommaNairaSymbol(
                          allEmployees?.total_salaries || "0.00"
                        )}
                      </p>
                    )}
                  </div>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box card-bg">
                  <div
                    onClick={() => {
                      setShowModal((prev) => {
                        return { ...prev, manage_deduction: true };
                      });
                    }}
                    className="title grey-white-color"
                  >
                    Total Deductions{" "}
                    <div className="title-total ">
                      {" "}
                      <figure className="img-box">{purpleNavIcon}</figure>
                    </div>
                  </div>
                  {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
                  <div className="value-box grey-bg">
                    {loading ? (
                      <Skeleton width={`20%`} height={20} />
                    ) : (
                      <p className="value">
                        {formatNumWithCommaNairaSymbol(
                          allEmployees?.total_deductions || "0.00"
                        )}
                      </p>
                    )}
                  </div>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box card-bg">
                  <div className="title grey-white-color">
                    Total Overdraft{" "}
                    <div className="title-total">
                      {" "}
                      <figure className="img-box">{purpleNavIcon}</figure>
                    </div>
                  </div>
                  {/* <p className="title grey-white-color">Total Overdue</p> invoice-single-id*/}
                  <div className="value-box grey-bg">
                    {loading ? (
                      <Skeleton width={`20%`} height={20} />
                    ) : (
                      <p className="value">
                        {formatNumWithCommaNairaSymbol(
                          allEmployees?.total_overdraft || "0.00"
                        )}
                      </p>
                    )}
                  </div>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box card-bg">
                  <div
                    onClick={() => {
                      if (payrollSettings?.payroll_status !== "0") {
                        navigate("/dashboard-payroll-disbursement-current");
                      }
                    }}
                    className={`title grey-white-color ${
                      payrollSettings?.payroll_status === "0" &&
                      "title-disabled  tooltip-hover-wrap"
                    }`}
                    // style={{cursor: payrollSettings?.payroll_status === "0" && "not-allowed"}}
                  >
                    {payrollSettings?.payroll_status === "0" && (
                      <>
                        {" "}
                        <RavenToolTip
                          color="black-light"
                          position={`top-center`}
                          text={`Payroll disabled`}
                        />
                      </>
                    )}
                    Next Payout Date{" "}
                    <div className="title-total">
                      <figure className="img-box">{purpleNavIcon}</figure>
                    </div>
                  </div>
                  {/* <p className="title grey-white-color">Total Pending invoice</p> */}
                  <div className="value-box grey-bg">
                    {loading ? (
                      <Skeleton width={`20%`} height={20} />
                    ) : (
                      <p className="value">
                        {gaugeInfo?.daysLeft ? (
                          `${gaugeInfo?.daysLeft} Day${
                            gaugeInfo?.daysLeft > 1 ? "s" : ""
                          } to go.`
                        ) : (
                          <>&nbsp;</>
                        )}
                      </p>
                    )}
                  </div>
                </div>
                {/* box end */}
              </div>
              {/* top detail grid card start */}
            </div>
            {/* )} */}
            {/* {tableData ? ( */}
            <ContainerWrapper
              className={`payroll-wrapper-container`}
              pagination={
                allEmployees?.employees?.next_page_url_params ||
                allEmployees?.employees?.prev_page_url_params ? (
                  <RavenPagination
                    color={`black-light`}
                    blackHover
                    currentPage={
                      Number(allEmployees?.employees?.current_page) || 1
                    }
                    nextPage={allEmployees?.employees?.next_page_url_params}
                    prevPage={allEmployees?.employees?.prev_page_url_params}
                    onNext={(e) => {
                      fetchPayroll(getPageNum(e));
                    }}
                    onPrev={(e) => {
                      fetchPayroll(getPageNum(e));
                    }}
                    totalPage={
                      getTotalPage(
                        Number(allEmployees?.employees?.per_page),
                        Number(allEmployees?.employees?.total)
                      ) || 1
                    }
                    onNumView={(e) => {
                      fetchPayroll(e);
                    }}
                  />
                ) : (
                  ""
                )
              }
              width={`100%`}
              dontShowHeader
            >
              <>
                <div
                  onClick={() => {
                    actionCtx?.setCheckShow("close");
                  }}
                  className="payroll-index-wrap-box"
                >
                  <div
                    style={{ display: "positive", zIndex: "200" }}
                    className="text-search-box"
                  >
                    {/* per page start */}
                    {/* <PerPageComponent /> */}
                    {/* per page end */}
                    {/* <p className="title">Employee Record</p> */}
                    <div className="title grey-white-color">
                      Employee Record{" "}
                      <div className="title-total black-fade-white-bg">
                        <span>{allEmployees?.number_of_employess || "0"}</span>
                      </div>
                    </div>
                    <form
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      action=""
                      style={{ marginRight: "auto" }}
                    >
                      {" "}
                      <RavenInputField
                        className={`search-input`}
                        placeholder={`Search`}
                        color={`black-light`}
                        type={`search`}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </form>

                    {allEmployees?.employees?.data?.length > 0 && (
                      <>
                        {loadingCsv ? (
                          <div className="">
                            {" "}
                            <ThreeDots
                              height="12"
                              width="100"
                              radius="9"
                              color="#020202"
                              ariaLabel="three-dots-loading"
                              // wrapperStyle={{tra}}
                              wrapperClassName=""
                              // visible={true}
                            />
                          </div>
                        ) : (
                          <RavenButton
                            onClick={generateAllCsvFile}
                            className="download-btn"
                            color={`black-color`}
                          >
                            Export{" "}
                            <figure className="img-box">{downloadIcon}</figure>{" "}
                          </RavenButton>
                        )}
                      </>
                    )}
                    <form
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      action=""
                      style={{ display: "positive" }}
                    >
                      <RavenInputField
                        className={`search-input`}
                        color={`black-light`}
                        type={`select`}
                        placeholder={`Sort by status`}
                        value={sortFilter}
                        onChange={(e) => {
                          setSortFilter(e);
                          fetchPayroll("", e);
                        }}
                        selectStyles={reactSelectStyle}
                        selectOption={selectOption}
                      />
                    </form>
                  </div>
                  {/* text search box end */}
                  {/* table box start */}
                  {allEmployees?.employees?.data?.length > 0 ? (
                    <div className="table-box">
                      <div className="table-wrap">
                        <RavenTable
                          className={`table-fixed-type ${
                            formatMetaData(user?.theme_display_settings)
                              ?.table_font === "bold" && "table-business"
                          }`}
                          headerList={headerList}
                          action
                        >
                          {allEmployees?.employees?.data?.map((chi, idx) => {
                            const {
                              account_number,
                              bank,
                              last_name,
                              first_name,
                              gross_salary,
                              raven_username,
                              status,
                            } = chi;
                            return (
                              <RavenTableRow
                                onRowClick={() => {
                                  localStorage.setItem(
                                    "payroll-employee-id",
                                    chi?.id
                                  );
                                  navigate(
                                    "/dashboard-payroll-employee-profile"
                                  );
                                }}
                                loading={loading || loadingSearch}
                                key={idx}
                                one={avatarFormatWrap(
                                  `${first_name || "---"} ${last_name || "---"}`
                                )}
                                two={account_number || "---"}
                                four={
                                  `${raven_username ? "@" : ""} ${
                                    raven_username ? raven_username : "---"
                                  }` || "---"
                                }
                                three={
                                  isJSONString(bank)
                                    ? formatMetaData(bank)?.label
                                    : bank || "---"
                                }
                                five={
                                  formatNumWithCommaNairaSymbol(gross_salary) ||
                                  "---"
                                }
                                six={formatTypeWrap(status) || "---"}
                                ManualAddActions={() => payrollActions(chi)}
                                // action
                              />
                            );
                          })}
                        </RavenTable>
                      </div>

                      <div className="mobile-table-box">
                        {allEmployees?.employees?.data?.map((chi, idx) => {
                          const {
                            account_number,
                            bank,
                            last_name,
                            first_name,
                            gross_salary,
                            // raven_username,
                            // status,
                          } = chi;
                          return (
                            <MobileTableCard
                              key={idx}
                              amount={formatNumWithCommaNairaSymbol(
                                gross_salary
                              )}
                              text={`${account_number} • ${trimLongString(
                                bank,
                                15
                              )}`}
                              avatar={`${first_name} ${last_name}`}
                              onRowClick={() => {
                                localStorage.setItem(
                                  "payroll-employee-id",
                                  chi?.id
                                );
                                navigate("/dashboard-payroll-employee-profile");
                              }}
                              loading={loading}
                              title={`${first_name} ${last_name}`}
                              showImg
                            />
                          );
                        })}
                      </div>

                      {/* pagination start */}
                      {/* <div className="table-pagination-box">
                <RavenPagination
                  color={`black-light`}
                  blackHover
                  currentPage={1}
                  totalPage={7}
                />
              </div> */}
                      {/* pagination end */}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        minHeight: "35rem",
                      }}
                      className=""
                    >
                      {" "}
                      <NoContentBox
                        loading={loadingSearch}
                        title={`No Employee record for ${sortFilter?.label}`}
                        text={`Select All to refresh table content`}
                      />
                    </div>
                  )}
                  {/* table box end */}
                </div>
              </>
            </ContainerWrapper>
            {/* ) : ( */}

            {/* )} */}
          </>
        ) : (
          <NocontentPage
            title={`Easy access to managing your employee with Payroll`}
            list={noContentList}
            img={noContentImg}
            loading={loading}
            btntext={
              user_resource_and_permissions?.allowed_permissions?.includes(
                "can_add_employee_records"
              ) && `Create Payroll`
            }
            onBtnClick={() => {
              navigate("/dashboard-payroll-create-select-method");
              // setShowModal((prev) => {
              //   return { ...prev, select_type: true };
              // });
            }}
          />
        )}
      </DashboardLayout>
      {/* delete mod start */}
      <DeactivatePayroll
        visible={showModal?.deactivate_payroLL}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, deactivate_payroLL: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* delete mod end */}
      {/* delete mod start */}
      <ClearPayrollDataModal
        visible={showModal?.clear_payroll_data}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, clear_payroll_data: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* delete mod end */}
      {/* delete mod start */}
      <ProcessPayrollModal
        visible={showModal?.process_payroll}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, process_payroll: false };
          });
        }}
        onProceed={() => {
          setShowModal((prev) => {
            return { ...prev, initiate_payroll: true, process_payroll: false };
          });
        }}
      />
      {/* delete mod end */}
      {/* delete mod start */}
      <InitiatePayrollModal
        visible={showModal?.initiate_payroll}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, initiate_payroll: false };
          });
        }}
      />
      {/* delete mod end */}
      {/* delete employee mod start */}
      <DeleteUserModal
        visible={showModal?.delete_employee}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, delete_employee: false };
          });
        }}
        detail={signleObj}
        onFinish={handleFinish}
      />
      {/* delete mod end */}
      {/* delete employee mod start */}
      <ManageDeductionModal
        visible={showModal?.manage_deduction}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, manage_deduction: false };
          });
        }}
        setPayrollSetup={setPayrollSetUp}
        setPayrollSetupDate={(e) => {
          setPayrollSetUp((prev) => {
            return { ...prev, next_pay_date: e };
          });
        }}
        onFinish={handleFinish}
        detail={payrollSetUp}
      />
      {/* delete mod end */}
      {/* select create type start */}
      <SelectUploadPayrollModal
        visible={showModal?.select_type}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, select_type: false };
          });
        }}
      />
      {/* select create type end */}
    </>
  );
};

export default DashboardPayrollIndex;
