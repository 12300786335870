import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/pos/RequestPos.css";
// import accountIcon from "../../../assets/pos-settle-account.svg";
// import bankIcon from "../../../assets/pos-settle-bank.svg";
import infoIcon from "../../../assets/info-black-icon.svg";
import { FaCheck } from "react-icons/fa";
import yellowInfo from "../../../assets/yellow-info.svg";
import {
  RavenCheckBox,
  RavenInputField,
  RavenToggleSlide,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import {
  getPosAdminInfo,
  getPosDashboardInfo,
  manageSettlement,
} from "../../../redux/pos";
import { useDispatch, useSelector } from "react-redux";
import { fetchBanks, validateBankAccount } from "../../../redux/transaction";
import { reactSelectStyle, removeSpace } from "../../../utils/helper/Helper";
import { ThreeDots } from "react-loader-spinner";
// import Skeleton from "react-loading-skeleton";

const SettleBalancePos = () => {
  const { loadingSettlement, adminInfo } = useSelector((state) => state.pos);
  const { account_details, user_resource_and_permissions } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [step, setStep] = useState(1);
  const [settleOption, setSettleoption] = useState("Your Account");
  const [details, setDetails] = useState({
    type: "1",
    auto: "yes",
    bank_name: "",
    bank_code: "",
    account_number: "",
    account_name: "",
    bank: "",
  });
  const optionList = [
    {
      value: "1",
      name: "Your Account",
      icon: (
        <svg
          width="22"
          height="18"
          viewBox="0 0 22 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img dull-bright-filter"
        >
          <path
            d="M21 5.5H1M1 9.5H4.54668C5.08687 9.5 5.35696 9.5 5.61813 9.54655C5.84995 9.58788 6.0761 9.65635 6.29191 9.75056C6.53504 9.85669 6.75977 10.0065 7.20924 10.3062L7.79076 10.6938C8.24023 10.9935 8.46496 11.1433 8.70809 11.2494C8.9239 11.3437 9.15005 11.4121 9.38186 11.4534C9.64304 11.5 9.91313 11.5 10.4533 11.5H11.5467C12.0869 11.5 12.357 11.5 12.6181 11.4534C12.85 11.4121 13.0761 11.3437 13.2919 11.2494C13.535 11.1433 13.7598 10.9935 14.2092 10.6938L14.7908 10.3062C15.2402 10.0065 15.465 9.85669 15.7081 9.75056C15.9239 9.65635 16.15 9.58788 16.3819 9.54655C16.643 9.5 16.9131 9.5 17.4533 9.5H21M1 4.2L1 13.8C1 14.9201 1 15.4802 1.21799 15.908C1.40973 16.2843 1.71569 16.5903 2.09202 16.782C2.51984 17 3.07989 17 4.2 17L17.8 17C18.9201 17 19.4802 17 19.908 16.782C20.2843 16.5903 20.5903 16.2843 20.782 15.908C21 15.4802 21 14.9201 21 13.8V4.2C21 3.0799 21 2.51984 20.782 2.09202C20.5903 1.7157 20.2843 1.40974 19.908 1.21799C19.4802 1 18.9201 1 17.8 1L4.2 1C3.0799 1 2.51984 1 2.09202 1.21799C1.7157 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.07989 1 4.2Z"
            // stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      value: "0",
      name: "Other Banks",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img dull-bright-filter"
        >
          <path
            d="M3 7.00002V15M7.5 7.00002V15M12.5 7.00002V15M17 7.00002V15M1 16.6L1 17.4C1 17.9601 1 18.2401 1.10899 18.454C1.20487 18.6422 1.35785 18.7952 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H17.4C17.9601 19 18.2401 19 18.454 18.891C18.6422 18.7952 18.7951 18.6422 18.891 18.454C19 18.2401 19 17.9601 19 17.4V16.6C19 16.04 19 15.7599 18.891 15.546C18.7951 15.3579 18.6422 15.2049 18.454 15.109C18.2401 15 17.9601 15 17.4 15H2.6C2.03995 15 1.75992 15 1.54601 15.109C1.35785 15.2049 1.20487 15.3579 1.10899 15.546C1 15.7599 1 16.04 1 16.6ZM9.65291 1.07715L2.25291 2.7216C1.80585 2.82094 1.58232 2.87062 1.41546 2.99082C1.26829 3.09685 1.15273 3.24092 1.08115 3.40759C1 3.59654 1 3.82553 1 4.28349L1 5.40002C1 5.96007 1 6.2401 1.10899 6.45401C1.20487 6.64217 1.35785 6.79515 1.54601 6.89103C1.75992 7.00002 2.03995 7.00002 2.6 7.00002H17.4C17.9601 7.00002 18.2401 7.00002 18.454 6.89103C18.6422 6.79515 18.7951 6.64217 18.891 6.45401C19 6.2401 19 5.96007 19 5.40002V4.2835C19 3.82553 19 3.59655 18.9188 3.40759C18.8473 3.24092 18.7317 3.09685 18.5845 2.99082C18.4177 2.87062 18.1942 2.82094 17.7471 2.7216L10.3471 1.07715C10.2176 1.04837 10.1528 1.03398 10.0874 1.02824C10.0292 1.02314 9.97077 1.02314 9.91264 1.02824C9.8472 1.03398 9.78244 1.04837 9.65291 1.07715Z"
            // stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];

  const { bankList, loadingVerify } = useSelector((state) => state.transaction);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name } = chi;
      return { label: name, value: code };
    });
    return list;
  };

  useEffect(() => {
    dispatch(fetchBanks());
    dispatch(getPosDashboardInfo());
    dispatch(getPosAdminInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // info?.settlement_type === "manual"
    const info = adminInfo?.getInfo;
    if (info?.settlement_bank_code) {
      // console.log(info);
      info?.settlement_account_number === account_details?.account_number
        ? setSettleoption("Your Account")
        : setSettleoption("Other Banks");
      setDetails((prev) => {
        return {
          ...prev,
          auto: info?.settlement_type === "auto" ? "yes" : "no",
          type: info?.settlement_account_number === account_details?.account_number ? "1" : "0",
          bank_name: info?.settlement_bank_name,
          bank_code: info?.settlement_bank_code,
          account_number: info?.settlement_account_number,
          account_name: info?.settlement_account_name,
          bank: {
            label: info?.settlement_bank_name,
            value: info?.settlement_bank_code,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminInfo]);

  // validate bank account details
  const validateBankDetails = async (e) => {
    const payload = {
      bank_code: e || details?.bank.value || details?.bank?.bank_code,
      account_number: details.account_number,
    };
    // console.log(payload);
    if (removeSpace(payload?.account_number)?.length > 9) {
      const data = await dispatch(validateBankAccount(payload));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        // console.log(data);
        setDetails((prev) => {
          return { ...prev, account_name: data?.payload?.data?.data };
        });
      }
    }
  };

  useEffect(() => {
    if (details?.bank && details?.account_number?.length > 9) {
      validateBankDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.account_number || details?.bank]);

  const handleSubnmit = async () => {
    let val;
    if (details?.type === "1") {
      const obj = {
        type: "1",
        auto: details?.auto,
      };
      val = obj;
    } else {
      const obj = {
        type: "0",
        auto: details?.auto,
        bank_name: details?.bank_name,
        bank_code: details?.bank_code,
        account_number: details?.account_number,
        account_name: details?.account_name,
      };
      val = obj;
    }
    // console.log(val);
    // return
    const data = await dispatch(manageSettlement(val));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    dispatch(getPosAdminInfo());
  };

  return (
    <DashboardLayout>
      <TitletextBox
        title={`Settle your balance`}
        text={`Ready to be a bank to the less banked people, request for terminal now.`}
        back
        onBack={() => {
          navigate(-1);
        }}
      />
      <ContainerWrapper
        loading={loadingSettlement}
        onBtnClick={handleSubnmit}
        disabled={
          details?.type === "0"
            ? !details?.bank_code ||
              !details?.bank_name ||
              !details?.account_name ||
              !details?.account_number
            : false
        }
        btnText={user_resource_and_permissions?.allowed_permissions?.includes(
          "can_update_admin_settlement_account"
        ) &&  `Activate settlement`}
        dontShowHeader
      >
        <div
          onClick={() => {
            // console.log(adminInfo);
          }}
          className="settle-balance-pos-wrap"
        >
          <p className="title">Where would you like to settle to.</p>
          <div className="select-wrap-box">
            {optionList?.map((chi, idx) => {
              const { name, icon } = chi;
              return (
                <div
                  key={idx}
                  className={`box grey-bg hover-white-class select-option-class ${
                    settleOption === name && "select-option-class-active"
                  }`}
                  onClick={() => {
                    // if (name === "Other Banks") {
                    //   dispatch(getPosAdminInfo());
                    // }
                    setSettleoption(name);
                    // setDetails((prev) => {
                    //   return {
                    //     ...prev,
                    //     type: value,
                    //     bank_name: "",
                    //     bank_code: "",
                    //     account_number: "",
                    //     account_name: "",
                    //     bank: "",
                    //   };
                    // });
                  }}
                >
                  <div className="check-box">
                    <FaCheck className="icon" />
                  </div>
                  <figure className="img-box">{icon}</figure>
                  <p className="name">{name}</p>
                </div>
              );
            })}
          </div>
          {/* select option end */}
          <div className="text-line">
            <span></span>
            <p className="text">Settlement account</p>
            <span></span>
          </div>
          {/* text line end */}
          {/* detail wrap form start */}
          {settleOption === "Your Account" ? (
            <>
              <div className="your-account-detail-box">
                <div className="personal-info-box">
                  <div className="box">
                    <p className="label">Settlement Bank</p>
                    <p className="value"> {account_details?.bank || "---"}</p>
                  </div>
                  <div className="box">
                    <p className="label">Bank Account Number</p>
                    <p className="value">
                      {account_details?.account_number || "---"}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="form-input-wraps">
                <RavenInputField
                  color={`black-light`}
                  type={`select`}
                  placeholder="Select a bank."
                  label={`Settlement Bank`}
                  style={{ position: "relative", zIndex: "10" }}
                  selectValue={details?.bank}
                  name={`bank`}
                  value={details?.bank}
                  className={`select-react`}
                  selectOption={formatSelectOption(bankList)}
                  selectStyles={reactSelectStyle}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return {
                        ...prev,
                        bank: e,
                        bank_code: e.value,
                        bank_name: e.label,
                        account_number: "",
                      };
                    });
                  }}
                />
                <RavenInputField
                  color={`black-light`}
                  label={`Account Number* `}
                  type={`account-number`}
                  name={`account_number`}
                  value={details?.account_number}
                  onChange={handleChange}
                  maxLength={11}
                  placeholder="E.g 022874..."
                  labelSpanText={
                    loadingVerify ? (
                      <div
                        style={{
                          padding: "0rem",
                          display: "flex",
                          justifyContent: "flex-end",
                          transform: "translateX(1.5rem)",
                        }}
                        className="load-wrap"
                      >
                        <ThreeDots
                          height="10"
                          width="80"
                          radius="9"
                          color="#020202"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <>{details?.account_name ? "Verified" : "Verify"}</>
                    )
                  }
                  labelColor={`purple-light`}
                  onActionClick={() => {
                    details?.account_number?.length === 10 &&
                      validateBankDetails();
                  }}
                />
                {/* verify name box start */}
                <div
                  className={`verify-name-box ${
                    details?.account_name && "verify-name-box-show"
                  }`}
                >
                  <RavenCheckBox
                    checked={true}
                    color={`black-light`}
                    className={`check`}
                  />
                  <p className="text">{details?.account_name}</p>
                </div>
                {/* verify name box end */}
              </div>
            </>
          )}
          {/* detail wrap form end */}
          {/* toggle slide box start */}
          <div className="toggle-slide-text-box">
            <div className="text-info-box">
              <p className="text grey-white-color">
                Enable auto settlement to this account?
              </p>
              <figure className="img-box tooltip-hover-wrap">
                <img src={infoIcon} alt="" className="img" />
                <RavenToolTip
                  color={`black-light`}
                  text={`Auto-settlements automate the process of transferring your POS funds into your account daily.`}
                  big
                  title={`Auto-settlements`}
                  downText={`Got it`}
                  position={`bottom-right`}
                />
              </figure>
            </div>
            <RavenToggleSlide
              color={`black-light`}
              value={details?.auto}
              checked={details?.auto === "yes" ? true : false}
              onChange={() => {
                setDetails((prev) => {
                  return {
                    ...prev,
                    auto: details?.auto === "no" ? "yes" : "no",
                  };
                });
              }}
            />
          </div>
          {/* toggle slide box end */}
          {/* yellow info box start */}
          <div className="yellow-info-box">
            <figure className="img-box">
              <img src={yellowInfo} alt="" className="img" />
            </figure>
            <p className="text">
              Please note that your total balance would be settled into this
              account at the end of each day.
            </p>
          </div>
          {/* yellow info box end */}
        </div>
      </ContainerWrapper>
    </DashboardLayout>
  );
};

export default SettleBalancePos;
