import React from "react";
import NoContentBox from "../components/reusables/NoContentBox";
import DashboardLayout from "../layout/dashboard/DashboardLayout";
import NotfoundImg from "../assets/no-opage-content-img.svg"
import { useNavigate } from "react-router-dom";

const NoPageFound = () => {
    const navigate = useNavigate()
  return (
    <DashboardLayout donstShowHeader={true}>
      <NoContentBox
        title={`Page not found`}
        text={`Sorry, the page you're looking for doesn't exist.`}
        btnText={`Back to dashboard`}
        img={NotfoundImg}
        onBtnClick={() => {
            navigate("/dashboard-overview")
        }}
      />
    </DashboardLayout>
  );
};

export default NoPageFound;
