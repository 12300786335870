import React, { useContext } from "react";
// import ravenLogo from "../../assets/raven-logo-full-white.svg";
import "../../styles/dashboard/SideMenu.css";
// import copyIcon from "../../assets/side-copy-icon.svg";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import ActionContext from "../../context/ActionContext";
// import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import { useSelector } from "react-redux";
// import ravenLogoMinimal from "../../assets/raven-logo-full-white.svg";
import ravenBlackLogo from "../../assets/new-raven-black-logo-icon.svg";
import ravenBlackNew from "../../assets/raven-white-logo-no-bank.svg";
import ravenWhiteLoneIcon from "../../assets/raven-white-lone-icon.svg";
import {
  new_business_tool_side_menu_list,
  new_capital_side_menu_list,
  new_dashboard_side_menu_list,
} from "./NewSideMenuList";
// import OldAccountDetailsBox from "./OldAccountDetailsBox";
import NewAccountDetailsBox from "./NewAccountDetailBox";
// import OldAccountDetailsBox from "./OldAccountDetailsBox";
import { useSelector } from "react-redux";

const SideMenu = ({ onDropAccount, theme }) => {
  const { user_resource_and_permissions, all_account, global_account } = useSelector(
    (state) => state.user
  );
  // const [isCopied, setIsCopied] = useState(false);
  const location = useLocation();
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  // const [showDiv, setShowdiv] = useState(false)
  // const handleCopied = async () => {
  //   setIsCopied(true);
  //   await navigator.clipboard.writeText(account_details?.account_number);
  //   setTimeout(() => {
  //     setIsCopied(false);
  //   }, 1000);
  // };

  return (
    <div
      className={`side-menu-wrap side-menu-wrap-round-icon ${
        actionCtx?.sideBarNav === "minimal" && "new-side-menu-wrap-box"
      } `}
    >
      {/* logo box start */}
      <div
        onClick={() => {
          navigate("/dashboard-overview");
        }}
        className={`logo-box`}
      >
        <figure className="img-box">
          {actionCtx?.sideBarNav === "minimal" ? (
            <>
              {" "}
              {/* <a
                style={{ textDecoration: "none", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
                href="https://getravenbank.com/raven-business"
              > */}{" "}
              <img
                src={theme === "dark" ? ravenBlackNew : ravenBlackLogo}
                alt=""
                className="img"
              />
              {/* </a> */}
            </>
          ) : (
            // <a
            //   style={{ textDecoration: "none" }}
            //   target="_blank"
            //   rel="noreferrer"
            //   href="https://getravenbank.com/raven-business"
            // >
            <img src={ravenBlackNew} alt="" className="img" />
            // </a>
          )}
        </figure>
        <p className="text">Business</p>
      </div>
      {/* logo box end */}

      {/* logo box for ipad start */}
      <div className="logo-box-ipad">
        <figure className="img-box">
          <img src={ravenWhiteLoneIcon} alt="" className="img" />
        </figure>
      </div>

      {/* <OldAccountDetailsBox /> */}
      {all_account?.length > 0 && (
        <NewAccountDetailsBox onClick={onDropAccount} />
      )}
      {/* account detail box end */}
      {/* list box start */}
      <div className="list-box">
        {/* <p className="wrap-title">DASHBOARD</p> */}
        {/* first link box */}
        <>
          {new_dashboard_side_menu_list.map((chi, idx) => {
            const { name, icon, link, children, iconTwo } = chi;
            // if (
            //   !user_resource_and_permissions?.allowed_permissions?.includes(
            //     "can_initiate_transfers"
            //   ) &&
            //   name === "Send Money"
            // ) {
            //   return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            // }
            if (
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator_approver" || "initiator") &&
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator" || "initiator_approver") &&
              name === "Send Money"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator_approver" || "approver") &&
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator" || "initiator_approver") &&
              name === "Approval Request"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (
              !user_resource_and_permissions?.allowed_permissions?.includes(
                "can_view_transactions"
              ) &&
              name === "Transactions"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (
              !user_resource_and_permissions?.allowed_permissions?.includes(
                "can_setup_global_account"
              ) &&
              name === "Global Account"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (String(global_account) === "0" &&
              name === "Global Account"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (
              !user_resource_and_permissions?.allowed_permissions?.includes(
                "can_view_virtual_cards"
              ) &&
              name === "Cards"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator_approver" || "initiator") &&
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator" || "initiator_approver") &&
              name === "Bill Payment"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (children && name === "Banking" && all_account?.length < 1) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (children && name === "Banking") {
              return (
                <React.Fragment key={`${idx}-${name}`}>
                  {/* parent link start */}
                  <div
                    key={`${idx}-${name}`}
                    className={`nav-link nav-link-solid nav-link-children  ${
                      location.pathname.includes("/dashboard-send-money") ||
                      location.pathname.includes("/dashboard-add-money") ||
                      location.pathname.includes(
                        "/dashboard-payment-request"
                      ) ||
                      location.pathname.includes("/dashboard-schedule-payment")
                        ? "nav-link-active"
                        : ""
                    }`}
                    onClick={() => {
                      actionCtx?.setSideMenuDrop("four");
                      link && navigate(link);
                      if (localStorage.getItem("customer-inv-id")) {
                        localStorage.removeItem("customer-inv-id");
                      }
                      if (localStorage.removeItem("invoice-draft-single-id")) {
                        localStorage.getItem("invoice-draft-single-id");
                      }
                    }}
                  >
                    <figure className="img-box">
                      <img
                        style={{
                          filter: location.pathname.includes(link)
                            ? `saturate(100%)`
                            : `saturate(0%)`,
                        }}
                        src={icon}
                        alt=""
                        className="img"
                      />
                    </figure>
                    <p className="name">{name}</p>
                    {!actionCtx?.sideMenuDrop?.four ? (
                      <FaAngleRight className="icon" />
                    ) : (
                      <FaAngleDown className="icon" />
                    )}
                  </div>
                  {/* parent link end */}
                  {/* children box start */}
                  <div
                    className={`children-box ${
                      actionCtx?.sideMenuDrop?.four && "children-box-show"
                    }`}
                    onClick={() => {
                      actionCtx?.setMobileSlide(false);
                    }}
                  >
                    {children.map((chi, i) => {
                      const { chi_name, chi_link } = chi;
                      if (
                        actionCtx?.currentAccount?.authorization_level !==
                          ("initiator_approver" || "initiator") &&
                        actionCtx?.currentAccount?.authorization_level !==
                          ("initiator" || "initiator_approver") &&
                        chi_name === "Send Money"
                      ) {
                        return (
                          <React.Fragment
                            key={`${i}-${chi_name}`}
                          ></React.Fragment>
                        );
                      }
                      if (
                        actionCtx?.currentAccount?.authorization_level !==
                          ("initiator_approver" || "approver") &&
                        actionCtx?.currentAccount?.authorization_level !==
                          ("approver" || "initiator_approver") &&
                        chi_name === "Approval Request"
                      ) {
                        return (
                          <React.Fragment
                            key={`${i}-${chi_name}`}
                          ></React.Fragment>
                        );
                      }
                      return (
                        <div
                          key={`${i}-${chi_name}`}
                          className={`nav-link nav-link-solid ${
                            location.pathname.includes(chi_link) &&
                            "nav-link-active"
                          }`}
                          onClick={() => {
                            chi_link && navigate(chi_link);
                            if (localStorage.getItem("customer-inv-id")) {
                              localStorage.removeItem("customer-inv-id");
                            }
                            if (
                              localStorage.removeItem("invoice-draft-single-id")
                            ) {
                              localStorage.getItem("invoice-draft-single-id");
                            }
                          }}
                        >
                          <p className="name">{chi_name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* children box end */}
                </React.Fragment>
              );
            }

            if (children && name === "Global Account") {
              return (
                <React.Fragment key={`${idx}-${name}`}>
                  {/* parent link start */}
                  <div
                    key={`${idx}-${name}`}
                    className={`nav-link nav-link-solid nav-link-children  ${
                      location.pathname.includes("/dashboard-global-account")
                        ? "nav-link-active"
                        : ""
                    }`}
                    onClick={() => {
                      actionCtx?.setSideMenuDrop("five");
                      link && navigate(link);
                      if (localStorage.getItem("customer-inv-id")) {
                        localStorage.removeItem("customer-inv-id");
                      }
                      if (localStorage.removeItem("invoice-draft-single-id")) {
                        localStorage.getItem("invoice-draft-single-id");
                      }
                      actionCtx?.sideMenuDrop?.five && actionCtx?.setMobileSlide(false);
                    }}
                  >
                    <figure className="img-box">
                      <img
                        style={{
                          filter: location.pathname.includes(link)
                            ? `saturate(100%)`
                            : `saturate(0%)`,
                        }}
                        src={icon}
                        alt=""
                        className="img"
                      />
                    </figure>
                    <p className="name">{name}</p>
                    {!actionCtx?.sideMenuDrop?.five ? (
                      <FaAngleRight className="icon" />
                    ) : (
                      <FaAngleDown className="icon" />
                    )}
                  </div>
                  {/* parent link end */}
                  {/* children box start */}
                  <div
                    className={`children-box ${
                      actionCtx?.sideMenuDrop?.five && "children-box-show"
                    }`}
                    onClick={() => {
                      actionCtx?.setMobileSlide(false);
                    }}
                  >
                    {children.map((chi, i) => {
                      const { chi_name, chi_link } = chi;
                      if (
                        actionCtx?.currentAccount?.authorization_level !==
                          ("initiator_approver" || "initiator") &&
                        actionCtx?.currentAccount?.authorization_level !==
                          ("initiator" || "initiator_approver") &&
                        chi_name === "Send Money"
                      ) {
                        return (
                          <React.Fragment
                            key={`${i}-${chi_name}`}
                          ></React.Fragment>
                        );
                      }
                      if (
                        actionCtx?.currentAccount?.authorization_level !==
                          ("initiator_approver" || "approver") &&
                        actionCtx?.currentAccount?.authorization_level !==
                          ("approver" || "initiator_approver") &&
                        chi_name === "Approval Request"
                      ) {
                        return (
                          <React.Fragment
                            key={`${i}-${chi_name}`}
                          ></React.Fragment>
                        );
                      }
                      return (
                        <div
                          key={`${i}-${chi_name}`}
                          className={`nav-link nav-link-solid ${
                            location.pathname.includes(chi_link) &&
                            "nav-link-active"
                          }`}
                          onClick={() => {
                            chi_link && navigate(chi_link);
                            if (localStorage.getItem("customer-inv-id")) {
                              localStorage.removeItem("customer-inv-id");
                            }
                            if (
                              localStorage.removeItem("invoice-draft-single-id")
                            ) {
                              localStorage.getItem("invoice-draft-single-id");
                            }
                          }}
                        >
                          <p className="name">{chi_name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* children box end */}
                </React.Fragment>
              );
            }

            if (children) {
              return (
                <React.Fragment key={`${idx}-${name}`}>
                  {/* parent link start */}
                  <div
                    key={`${idx}-${name}`}
                    className={`nav-link nav-link-solid nav-link-children  ${
                      location.pathname.includes(link) && "nav-link-active"
                    }`}
                    onClick={() => {
                      actionCtx?.setSideMenuDrop("one");
                      link && navigate(link);
                      if (localStorage.getItem("customer-inv-id")) {
                        localStorage.removeItem("customer-inv-id");
                      }
                      if (localStorage.removeItem("invoice-draft-single-id")) {
                        localStorage.getItem("invoice-draft-single-id");
                      }
                    }}
                  >
                    <figure className="img-box">
                      {iconTwo ? (
                        <>{iconTwo}</>
                      ) : (
                        <img
                          // style={{
                          //   filter: location.pathname.includes(link)
                          //     ? `saturate(100%)`
                          //     : `saturate(0%)`,
                          // }}
                          src={icon}
                          alt=""
                          className="img"
                        />
                      )}
                    </figure>
                    <p className="name">{name}</p>
                    {!actionCtx?.sideMenuDrop?.one ? (
                      <FaAngleRight className="icon" />
                    ) : (
                      <FaAngleDown className="icon" />
                    )}
                  </div>
                  {/* parent link end */}
                  {/* children box start */}
                  <div
                    className={`children-box ${
                      actionCtx?.sideMenuDrop?.one && "children-box-show"
                    }`}
                    onClick={() => {
                      actionCtx?.setMobileSlide(false);
                    }}
                  >
                    {children.map((chi, i) => {
                      const { chi_name, chi_link } = chi;
                      return (
                        <div
                          key={`${i}-${chi_name}`}
                          // className={`nav-link ${
                          //   location.pathname.includes(chi_link) &&
                          //   "nav-link-active"
                          // }`}
                          className={`nav-link nav-link-solid ${
                            location.pathname.includes(chi_link) &&
                            "nav-link-active"
                          }`}
                          onClick={() => {
                            chi_link && navigate(chi_link);
                            if (localStorage.getItem("customer-inv-id")) {
                              localStorage.removeItem("customer-inv-id");
                            }
                            if (
                              localStorage.removeItem("invoice-draft-single-id")
                            ) {
                              localStorage.getItem("invoice-draft-single-id");
                            }
                          }}
                        >
                          <p className="name">{chi_name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* children box end */}
                </React.Fragment>
              );
            }
            return (
              <div
                key={`${idx}-${name}`}
                className={`nav-link nav-link-solid ${
                  location.pathname.includes(link) && "nav-link-active"
                }`}
                onClick={() => {
                  //   actionCtx?.setSideMenuDrop("close");
                  link && navigate(link);
                  actionCtx?.setMobileSlide(false);
                  if (localStorage.getItem("customer-inv-id")) {
                    localStorage.removeItem("customer-inv-id");
                  }
                  if (localStorage.removeItem("invoice-draft-single-id")) {
                    localStorage.getItem("invoice-draft-single-id");
                  }
                }}
              >
                <figure className="img-box">
                  {iconTwo ? (
                    <>{iconTwo}</>
                  ) : (
                    <>
                      {" "}
                      <img
                        style={{
                          filter: location.pathname.includes(link)
                            ? `saturate(100%)`
                            : `saturate(0%)`,
                        }}
                        src={icon}
                        alt=""
                        className="img"
                      />
                    </>
                  )}
                </figure>
                <p className="name ">{name}</p>
              </div>
            );
          })}
        </>
        {/* first link box end */}
        {/* second link box */}
        <>
          {new_business_tool_side_menu_list.map((chi, idx) => {
            const { name, icon, link, children } = chi;
            if (children) {
              return (
                <React.Fragment key={`${idx}-${name}`}>
                  {/* parent link start */}
                  <div
                    key={`${idx}-${name}`}
                    className={`nav-link nav-link-solid nav-link-children  ${
                      location.pathname.includes("/dashboard-payroll") ||
                      location.pathname.includes("/dashboard-payment-links") ||
                      location.pathname.includes("/dashboard-pos") ||
                      location.pathname.includes("/dashboard-store") ||
                      location.pathname.includes("/dashboard-invoice")
                        ? "nav-link-active"
                        : ""
                    }`}
                    onClick={() => {
                      actionCtx?.setSideMenuDrop("two");
                      link && navigate(link);
                      if (localStorage.getItem("customer-inv-id")) {
                        localStorage.removeItem("customer-inv-id");
                      }
                      if (localStorage.removeItem("invoice-draft-single-id")) {
                        localStorage.getItem("invoice-draft-single-id");
                      }
                    }}
                  >
                    <figure className="img-box">
                      <img
                        style={{
                          filter:
                            location.pathname.includes("/dashboard-payroll") ||
                            location.pathname.includes(
                              "/dashboard-payment-links"
                            ) ||
                            location.pathname.includes("/dashboard-pos") ||
                            location.pathname.includes("/dashboard-store") ||
                            location.pathname.includes("/dashboard-invoice")
                              ? `saturate(100%)`
                              : `saturate(0%)`,
                        }}
                        src={icon}
                        alt=""
                        className="img"
                      />
                    </figure>
                    <p className="name">{name}</p>
                    {!actionCtx?.sideMenuDrop?.two ? (
                      <FaAngleRight className="icon" />
                    ) : (
                      <FaAngleDown className="icon" />
                    )}
                  </div>
                  {/* parent link end */}
                  {/* children box start */}
                  <div
                    className={`children-box ${
                      actionCtx?.sideMenuDrop?.two && "children-box-show"
                    }`}
                    onClick={() => {
                      actionCtx?.setMobileSlide(false);
                    }}
                  >
                    {children.map((chi, i) => {
                      const { chi_name, chi_link } = chi;
                      if (
                        !user_resource_and_permissions?.allowed_permissions?.includes(
                          "can_view_terminals"
                        ) &&
                        chi_name === "Invoices"
                      ) {
                        return (
                          <React.Fragment
                            key={`${idx}-${name}`}
                          ></React.Fragment>
                        );
                      }
                      return (
                        <div
                          // style={{ marginTop: i === 0 && "1rem" }}
                          key={`${i}-${chi_name}`}
                          className={`nav-link nav-link-solid ${
                            location.pathname.includes(chi_link) &&
                            "nav-link-active"
                          }`}
                          onClick={() => {
                            chi_link && navigate(chi_link);
                          }}
                        >
                          <p className="name">{chi_name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* children box end */}
                </React.Fragment>
              );
            }
            return (
              <div
                key={`${idx}-${name}`}
                className={`nav-link nav-link-solid nav-link-solid ${
                  location.pathname.includes(link) && "nav-link-active"
                }`}
                onClick={() => {
                  //   actionCtx?.setSideMenuDrop("close");
                  link && navigate(link);
                  actionCtx?.setMobileSlide(false);
                  if (localStorage.getItem("customer-inv-id")) {
                    localStorage.removeItem("customer-inv-id");
                  }
                  if (localStorage.removeItem("invoice-draft-single-id")) {
                    localStorage.getItem("invoice-draft-single-id");
                  }
                }}
                // style={{ marginTop: idx === 0 && "1rem" }}
              >
                <figure className="img-box">
                  <img
                    // style={{
                    //   filter: location.pathname.includes(link)
                    //     ? `saturate(100%)`
                    //     : `saturate(0%)`,
                    // }}
                    src={icon}
                    alt=""
                    className="img"
                  />
                </figure>
                <p className="name">{name}</p>
              </div>
            );
          })}
        </>
        {/* second link box end */}
        {/* third link box */}
        <>
          {new_capital_side_menu_list.map((chi, idx) => {
            const { name, icon, link, children } = chi;
            if (children) {
              return (
                <React.Fragment key={`${idx}-${name}`}>
                  {/* parent link start */}
                  <div
                    key={`${idx}-${name}`}
                    className={`nav-link nav-link-solid nav-link-children 
                    ${
                      location.pathname.includes(
                        "/dashboard-finance-invoice"
                      ) ||
                      location.pathname.includes("/dashboard-loan") ||
                      location.pathname.includes("/dashboard-overdraft")
                        ? "nav-link-active"
                        : ""
                    }
                    `}
                    onClick={() => {
                      actionCtx?.setSideMenuDrop("three");
                      link && navigate(link);
                      if (localStorage.getItem("customer-inv-id")) {
                        localStorage.removeItem("customer-inv-id");
                      }
                      if (localStorage.removeItem("invoice-draft-single-id")) {
                        localStorage.getItem("invoice-draft-single-id");
                      }
                    }}
                  >
                    <figure className="img-box">
                      <img
                        style={{
                          filter:
                            location.pathname.includes(link) ||
                            location.pathname.includes(
                              "/dasboard-finance-invoice"
                            ) ||
                            location.pathname.includes("/dasboard-loan") ||
                            location.pathname.includes("/dasboard-overdraft")
                              ? `saturate(100%)`
                              : `saturate(0%)`,
                        }}
                        src={icon}
                        alt=""
                        className="img"
                      />
                    </figure>
                    <p className="name">{name}</p>
                    {!actionCtx?.sideMenuDrop?.three ? (
                      <FaAngleRight className="icon" />
                    ) : (
                      <FaAngleDown className="icon" />
                    )}
                  </div>
                  {/* parent link end */}
                  {/* children box start */}
                  <div
                    className={`children-box ${
                      actionCtx?.sideMenuDrop?.three && "children-box-show"
                    }`}
                    onClick={() => {
                      actionCtx?.setMobileSlide(false);
                    }}
                  >
                    {children.map((chi, i) => {
                      const { chi_name, chi_link } = chi;
                      return (
                        <div
                          key={`${i}-${chi_name}`}
                          className={`nav-link nav-link-solid ${
                            location.pathname.includes(chi_link) &&
                            "nav-link-active"
                          }`}
                          onClick={() => {
                            chi_link && navigate(chi_link);
                          }}
                        >
                          <p className="name">{chi_name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* children box end */}
                </React.Fragment>
              );
            }
            return (
              <div
                key={`${idx}-${name}`}
                className={`nav-link nav-link-solid ${
                  location.pathname.includes(link) && "nav-link-active"
                }`}
                onClick={() => {
                  //   actionCtx?.setSideMenuDrop("close");
                  link && navigate(link);
                  actionCtx?.setMobileSlide(false);
                  if (localStorage.getItem("customer-inv-id")) {
                    localStorage.removeItem("customer-inv-id");
                  }
                  if (localStorage.removeItem("invoice-draft-single-id")) {
                    localStorage.getItem("invoice-draft-single-id");
                  }
                }}
              >
                <figure className="img-box">
                  <img
                    style={{
                      filter: location.pathname.includes(link)
                        ? `saturate(100%)`
                        : `saturate(0%)`,
                    }}
                    src={icon}
                    alt=""
                    className="img"
                  />
                </figure>
                <p className="name">{name}</p>
              </div>
            );
          })}
        </>
        {/* third link box end */}
      </div>
      {/* list box end */}
    </div>
  );
};

export default SideMenu;
