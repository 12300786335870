import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/overwiew/DashboardOverview.css";
// import openEye from "../../../assets/open-eye-amount-icon.svg";
// import closeEye from "../../../assets/close-eye-amount-icon.svg";
import { RavenButton } from "@ravenpay/raven-bank-ui";
// import { useDarkMode } from "../../../utils/themes/useDarkMode";
import { useNavigate } from "react-router-dom";
import payrollIcon from "../../../assets/quick-payroll-icon.svg";
import payBillIcon from "../../../assets/quick-pay-bill-icon.svg";
import invoiceIcon from "../../../assets/quick-invoice-icon.svg";
import posIcon from "../../../assets/overview-pos-icon.svg";
// import ActionContext from "../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import {
  formatMetaData,
  // formatNumWithCommaNaira,
} from "../../../utils/helper/Helper";
import { getUser } from "../../../redux/user";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import { quickActionAllList } from "../../../utils/helper/QuickActionList";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { updateAppearance } from "../../../redux/settings";
import Skeleton from "react-loading-skeleton";
import { updateAppearance } from "../../../redux/settings";
// import AccountDropDownBox from "../../../components/fragments/AccountDropDownBox";
// import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import NewTopBalance from "./NewTopBalance";
import OldTopBalance from "./oldTopBalance";

const DashboardOverview = () => {
  // const [theme] = useDarkMode(localStorage.getItem("theme"));
  // const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading, all_account } = useSelector((state) => state.user);
  const size = useWindowSize();
  // const [showAccountDrop, setShowAccountDrop] = useState(false);
  const actionList = [
    {
      name: "Payroll",
      icon: payrollIcon,
      text: "Automate the process of paying your staff.",
      link: "/dashboard-payroll?dashboard-overview",
    },
    {
      name: "Pay Bills",
      icon: payBillIcon,
      text: "All your bill payment handled seamlessly.",
      link: "/dashboard-bill-payment?dashboard-overview",
    },
    {
      name: "Invoice",
      icon: invoiceIcon,
      text: "Track who owes you and accept payment directly.",
      link: "/dashboard-invoice?dashboard-overview",
    },
    {
      name: "BankBox" || "POS",
      icon: posIcon,
      text: "Manage all your BankBox terminals easily.",
      link: "/dashboard-pos?dashboard-overview",
    },
  ];

  useEffect(() => {
    dispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const sendIcon = (
  //   <svg
  //     width="12"
  //     height="12"
  //     viewBox="0 0 12 12"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     className="img"
  //   >
  //     <path
  //       d="M1.42322 10.4182L10.754 1.08748M10.754 1.08748V10.4182M10.754 1.08748H1.42322"
  //       stroke="white"
  //       strokeWidth="1.67953"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     />
  //   </svg>
  // );

  // const addIcon = (
  //   <svg
  //     width="12"
  //     height="12"
  //     viewBox="0 0 12 12"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     className="img"
  //   >
  //     <path
  //       d="M10.8545 1.0874L1.52376 10.4181M1.52376 10.4181V1.0874M1.52376 10.4181H10.8545"
  //       stroke="#020202"
  //       strokeWidth="1.67953"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     />
  //   </svg>
  // );

  const [listToCompare, setListToCompare] = useState([]);
  const [listTosShow, setListToShow] = useState([]);

  useEffect(() => {
    const checkList = formatMetaData(
      user?.theme_display_settings
    )?.quick_action;
    setListToCompare(checkList);
    // console.log(checkList);
    if (checkList?.length > 0) {
      handleCheckQuickActionList();
    }
    // console.log(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const rearrangeObjArrayList = (list) => {
    const lowercaseArray = list?.map((obj) => {
      return { ...obj, name: obj?.name?.toLowerCase() };
    });

    const resultArray = list?.map((obj) => lowercaseArray[list.indexOf(obj)]);
    return resultArray;
  };

  const handleCheckQuickActionList = () => {
    // const checkList = formatMetaData(
    //   user?.theme_display_settings
    // )?.quick_action;
    // const filteredArray = quickActionAllList.filter(obj => {
    //   return !arrayOfObjectsToRemove.some(item => item.id === obj.id && item.name === obj.name);
    // });
    const lowercaseArray = listToCompare?.map((str) => str.toLowerCase());
    const resultArray = listToCompare?.map(
      (str) => lowercaseArray[listToCompare.indexOf(str)]
    );
    // const listCheck = rearrangeObjArrayList(quickActionAllList)?.filter((chi) =>
    //   resultArray?.includes(chi?.name.toLowerCase())
    // );
    const resultArrayOrdered = resultArray?.map((str) =>
      rearrangeObjArrayList(quickActionAllList)?.find(
        (obj) => obj?.name === str
      )
    );
    // console.log(resultArrayOrdered);
    setListToShow(resultArrayOrdered);
    // return listCheck;
  };

  const [dragLoad, setDragLoad] = useState(false);
  const handleDragEnd = async (result) => {
    // console.log(result);
    setDragLoad(true);
    const items = Array?.from(listTosShow);
    const [rearragedChildren] = items?.splice(result?.source?.index, 1);
    items?.splice(result?.destination?.index, 0, rearragedChildren);
    // handleCheckQuickActionListToShow(items);
    setListToShow(items);
    const newList = items?.map((chi) => chi?.name);
    const newObj = {
      ...formatMetaData(user?.theme_display_settings),
      quick_action: newList,
    };
    // console.log(newObj);
    // console.log(...formatMetaData(
    //   user?.theme_display_settings
    // ));
    // return
    const data = await dispatch(updateAppearance(newObj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      const dataTwo = await dispatch(getUser());
      // console.log(dataTwo);
      setDragLoad(true);
      if (dataTwo?.payload?.status === "success") {
        setDragLoad(false);
      }
    }
  };

  const handleCheckQuickActionListToShow = (list) => {
    // const checkList = formatMetaData(
    //   user?.theme_display_settings
    // )?.quick_action;
    // const filteredArray = quickActionAllList.filter(obj => {
    //   return !arrayOfObjectsToRemove.some(item => item.id === obj.id && item.name === obj.name);
    // });
    const lowercaseArray = listToCompare?.map((str) => str?.toLowerCase());
    const resultArray = listToCompare?.map(
      (str) => lowercaseArray[listToCompare?.indexOf(str)]
    );
    // const listCheck = rearrangeObjArrayList(quickActionAllList)?.filter((chi) =>
    //   resultArray?.includes(chi?.name.toLowerCase())
    // );
    const resultArrayOrdered = resultArray?.map((str) =>
      rearrangeObjArrayList(list)?.find((obj) => obj?.name === str)
    );
    // console.log(resultArrayOrdered);
    // setListToShow(resultArrayOrdered);
    return resultArrayOrdered;
  };

  return (
    <DashboardLayout>
      <div
        // onClick={() => {
        //   console.log(all_account);
        // }}
        className="dashboard-overview-wrap"
      >
        {/* wrap starr */}
        <div className="wrap">
          {/* balance top box start */}
          {all_account?.length < 2 ? <OldTopBalance /> : <NewTopBalance />}
          {/* <NewTopBalance /> */}
          {/* <OldTopBalance /> */}
          {/* balance top box end */}
          {/* quick-action box start */}
          <div className="quick-action-wrap">
            <p
              // onClick={() => {
              //   handleCheckQuickActionList();
              // }}
              className="title "
            >
              Quick Actions
            </p>
            {/* ksks */}
            {listTosShow?.length < 1 && (
              <div className="select-box ">
                {!loading ? (
                  <>
                    {actionList?.map((chi, i) => {
                      return (
                        <div key={i} className="box card-bg ">
                          <Skeleton
                            className="img-box"
                            width={30}
                            height={30}
                            circle
                          />
                          <Skeleton className="name" width={50} height={10} />
                          <Skeleton className="text" width={100} height={9} />
                          <Skeleton className="text" width={35} height={9} />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {" "}
                    {listTosShow?.map((chi, idx) => {
                      const { name, icon, text, link } = chi || {};
                      return (
                        <div
                          key={idx || chi?.id}
                          // role={chi?.role}
                          onClick={() => {
                            link && navigate(link);
                          }}
                          className="box card-bg "
                        >
                          <figure className="img-box">
                            <img src={icon} alt="" className="img" />
                          </figure>
                          <p className="name black-white-color">{name}</p>
                          <p className="text grey-white-color">{text}</p>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}
            {/* ksks */}
            {/* drag start */}
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                droppableId="childSequence"
                direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="dragable-row select-box shadow-shadow-box-shadow"
                  >
                    {/* list start */}
                    {loading || dragLoad ? (
                      <>
                        {quickActionAllList?.slice(0, 4)?.map((chi, i) => {
                          return (
                            <div key={i} className="box card-bg ">
                              <Skeleton
                                className="img-box"
                                width={30}
                                height={30}
                                circle
                              />
                              <Skeleton
                                className="name"
                                width={50}
                                height={10}
                              />
                              <Skeleton
                                className="text"
                                width={100}
                                height={9}
                              />
                              <Skeleton
                                className="text"
                                width={35}
                                height={9}
                              />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {" "}
                        {listTosShow?.length > 0 &&
                          handleCheckQuickActionListToShow(listTosShow)?.map(
                            (chi, i) => {
                              const { name, icon, text, link } = chi || {};
                              return (
                                <Draggable
                                  draggableId={`draggable${i}`}
                                  key={`draggable${i}`}
                                  index={i}
                                >
                                  {(provided) => (
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      key={chi?.id}
                                      // role={chi?.role}
                                      level={chi?.level || i}
                                      onClick={() => {
                                        link && navigate(link);
                                      }}
                                      className="box card-bg "
                                    >
                                      <figure className="img-box">
                                        <img
                                          src={icon}
                                          alt=""
                                          className="img"
                                        />
                                      </figure>
                                      <p
                                        style={{
                                          // textTransform: "capitalize",
                                        }}
                                        className="name black-white-color"
                                      >
                                        {name === "bankbox" ? "BankBox" : name || "---"}
                                      </p>
                                      <p className="text grey-white-color">
                                        {text || "----- ----- ----"}
                                      </p>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }
                          )}
                      </>
                    )}
                    {/* end of list */}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* drag end */}
            {/* select-box start */}
            {/* <div className="select-box shadow-shadow-box-shadow">
              {handleCheckQuickActionList().map((chi, idx) => {
                const { name, icon, text, link } = chi;
                return (
                  <div
                    onClick={() => {
                      link && navigate(link);
                    }}
                    className="box card-bg "
                    key={idx}
                  >
                    <figure className="img-box">
                      <img src={icon} alt="" className="img" />
                    </figure>
                    <p className="name black-white-color">{name}</p>
                    <p className="text grey-white-color">{text}</p>
                  </div>
                );
              })}
            </div> */}
            {/* select-box end */}
          </div>
          {/* Quick Actions box end */}
          {/* request advert box start */}
          <div
            // style={{ boxShadow: theme === "dark" && "unset" }}
            className="request-advert-box shadow-shadow-box-shadow card-bg"
          >
            <div className="content-box">
              <p className="text">
                Request POS to help accept payments from your customers
              </p>
              <RavenButton
                className="btn no-shadow-box-shadow"
                label={`Request Terminal`}
                color={`black-light`}
                // loading={true}
                onClick={() => {
                  navigate("/dashboard-pos-management-request-pos");
                }}
                size={size?.width < 900 ? "small" : "medium"}
              />
            </div>
            <div className="img-content-box"></div>
          </div>
          {/* request advert box end */}
        </div>
        {/* wrap end */}
      </div>
    </DashboardLayout>
  );
};

export default DashboardOverview;
