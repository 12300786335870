import React, { useContext, useEffect, useState } from "react";
import ActionContext from "../../context/ActionContext";
import shippedTerminal from "../../assets/shipped-terminal-img.png";
import orderReceiveTerminal from "../../assets/order-recieved-terminal-img.png";
import setupTerminal from "../../assets/setup-terminal-img.png";
import { getPosTerminalRequest } from "../../utils/helper/Helper";
import { useSelector } from "react-redux";

const terminalStatuList = [
  {
    img: orderReceiveTerminal,
    title: "Order recieved",
    text: "We have received your terminal order and it’s currently been processed, if you have any complaint and issues please",
  },
  {
    img: shippedTerminal,
    title: "Your Terminal is being shipped!",
    text: "Our terminal delivery usually take 48 - 72 hours, if your delivery duration exceed this period please",
  },
  {
    img: setupTerminal,
    title: "Setup your terminal.",
    text: "At this time, we believe you have received your terminal but not setup yet, our customer service would reach you to help you setup your terminal. if you have any issue,",
  },
];

const BlurWrap = ({ children }) => {
  const { status } = useSelector((state) => state.pos);
  const [requestStatus, setRequestStatus] = useState("");
  const actionCtx = useContext(ActionContext);

  useEffect(() => {
    setRequestStatus(getPosTerminalRequest(status));
  }, [status]);

  return (
    <div
      onClick={() => {
        actionCtx?.setCheckShow("close");
      }}
      className="blurr-bg-box"
      style={{ display: actionCtx?.checkShow?.six ? "flex" : "none" }}
    >
      {/* message-bos start */}
      {actionCtx?.checkShow?.six && (
        <div className="message-wrap-box card-bg">
          <div
            style={{
              backgroundImage: `url(${
                terminalStatuList[requestStatus < 2 ? 0 : requestStatus]?.img
              })`,
            }}
            className="left-box"
          ></div>
          {/* right box start */}
          <div className="right-box">
            <p className="title">
              {terminalStatuList[requestStatus < 2 ? 0 : requestStatus]?.title}
            </p>
            <p className="text grey-white-color">
              {terminalStatuList[requestStatus < 2 ? 0 : requestStatus]?.text}{" "}
              <span
                onClick={() => {
                  // window.Intercom("show");
                  window.fcWidget.open()
                }}
              >
                Contact Support
              </span>
            </p>
          </div>
          {/* right box end */}
        </div>
      )}
      {/* message-bos end */}
    </div>
  );
};

export default BlurWrap;
