import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
// import { getSingleBulkTransactions } from "../../../redux/transaction";
import { CSVLink } from "react-csv";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import "../../../styles/dashboard/transaction/DashboardTransaction.css";
import Skeleton from "react-loading-skeleton";
import {
  RavenButton,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  // RavenToolTip,
  //   RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import {
  formatDate,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  getTotalPage,
  lowerCaseWrap,
  trimLongString,
} from "../../../utils/helper/Helper";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
// import DownloadReceiptModal from "./DownloadReceiptModal";
import GenerateStatementModal from "../settings/sections/modal/GenerateStatementModal";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";
import ActionContext from "../../../context/ActionContext";
import { formatNumWithComma } from "../../../utils/Helpers";
import { useDispatch } from "react-redux";
import {
  downloadTransactionReciept,
  getSingleBulkTransactions,
} from "../../../redux/transaction";
import SingleDownloadReceiptModal from "./SingleDownloadReceiptModal";

const SingleBulkTransaction = () => {
  const [singleObj, setSingleObj] = useState({});
  const [showModal, setShowModal] = useState({
    view: false,
    statement: false,
  });
  const { loadingDownload } = useSelector((state) => state.transaction);
  // const dispatch = useDispatch();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  //   const dispatch = useDispatch();
  const { loading, single_bulk_transactions } = useSelector(
    (state) => state.transaction
  );
  const actionCtx = useContext(ActionContext);
  const size = useWindowSize();
  //   const [tableData, setTableData] = useState(false);

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.perPageVal]);

  const getTransactions = async (num) => {
    const refLocal = localStorage.getItem("bulk-ref-single-id");
    const payload = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: num || 1,
      ref: refLocal,
    };
    const data = await dispatch(getSingleBulkTransactions(payload));
    // console.log(data);
    if (data?.payload?.data?.transaction_details?.data?.length > 0) {
      //   setTableData(true);
    } else {
      navigate("/dashboard-transaction");
    }
  };

  const rowTypeText = (type, text, textTwo) => {
    return (
      <div className="type-text-box">
        {loading ? (
          <>
            {" "}
            <Skeleton
              width={30}
              height={30}
              circle
              style={{ marginRight: "1rem" }}
            />{" "}
            <Skeleton width={200} height={17} />
          </>
        ) : (
          <>
            {" "}
            <div className="img-wrap">
              <figure
                className={`img-box-cre-deb ${
                  type === "debit" && "img-box-debit"
                } img-box-debit-credit`}
              >
                {type === "credit" ? (
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M9 1L1 9M1 9H6.33333M1 9V3.66667"
                      stroke="#1ACE37"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M1 9L9 1M9 1H3.66667M9 1V6.33333"
                      stroke="#FF0F00"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </figure>
            </div>
            <div className=" text-box">
              <p className="text">{text}</p>
              <p className="text-small grey-white-color">{textTwo}</p>
            </div>
          </>
        )}
      </div>
    );
  };

  const exportTableIcon = (
    <svg
      className="img "
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
    </svg>
  );

  const formatTypeWrap = (param) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "0" && "status-type-wrap-pending"
        } ${param === "3" && "status-type-wrap-success"} ${
          param === "success" && "status-type-wrap-success"
        } ${param === "paid" && "status-type-wrap-success"} ${
          param === "2" && "status-type-wrap-fail"
        } ${param === "overdue" && "status-type-wrap-pending"} ${
          param === "1" && "status-type-wrap-pending"
        } ${param === "pending" && "status-type-wrap-pending"}`}
      >
        <span style={{ textTransform: "capitalize" }}>
          {param === "1"
            ? "Processing"
            : param === "0"
            ? "Pending"
            : param === "3"
            ? "Success"
            : param === "2"
            ? "Failed"
            : param === "11"
            ? "Reversal"
            : param}
        </span>
      </div>
    );
  };

  const headerList = [
    "Recipient",
    "AMOUNT",
    "TYPE",
    " DATE",
    "REFERENCE",
    "STATUS",
  ];

  const CsvHeader = [
    { label: "S/N", key: "num" },
    { label: "Narration One", key: "narration_one" },
    { label: "Narration Two", key: "narration_two" },
    { label: "Amount", key: "_value" },
    { label: "Balance Before", key: "bal_before" },
    { label: "Balance After", key: "bal_after" },
    { label: "Trx Type", key: "type" },
    { label: "Trx Direction", key: "direction" },
    { label: "Reference", key: "reference" },
    { label: "Bulk Reference", key: "bulk_reference" },
    { label: "Date Executed", key: "created_at" },
    { label: "Status", key: "status" },
  ];

  const formatExportData = (data) => {
    const list = data?.map((chi, idx) => {
      const {
        type,
        amount,
        status,
        created_at,
        bulk_transfer_reference,
        trx_ref,
        network,
        phone,
        account_name,
        account_number,
        bank,
        meta_data,
        identifier,
      } = chi;
      return {
        num: idx < 10 ? `0${idx + 1}` : idx + 1,
        narration_one:
          phone ||
          account_name ||
          lowerCaseWrap(formatMetaData(meta_data)?.meta?.account_name) ||
          "",
        narration_two:
          single_bulk_transactions?.main_transaction?.type.includes("recharge")
            ? `${lowerCaseWrap(network)} ${formatTypeFunction(
                single_bulk_transactions?.main_transaction?.type
              )}`
            : identifier
            ? `@${identifier} • Raven Bank`
            : `${
                account_number || formatMetaData(meta_data)?.account_number
              } • ${
                trimLongString(bank, 20) ||
                trimLongString(formatMetaData(meta_data)?.bank, 20)
              }` ||
              `${lowerCaseWrap(network)} ${formatTypeFunction(
                single_bulk_transactions?.main_transaction?.type
              )}` ||
              `${account_number} • ${lowerCaseWrap(
                trimLongString(bank, 20)
              )}` ||
              "",
        _value: formatNumWithCommaNairaSymbol(amount),
        bal_after: formatNumWithCommaNairaSymbol(
          String(single_bulk_transactions?.main_transaction?.bal_after)
        ),
        bal_before: formatNumWithCommaNairaSymbol(
          String(single_bulk_transactions?.main_transaction?.bal_before)
        ),
        direction: lowerCaseWrap(
          single_bulk_transactions?.main_transaction?.direction
        ),
        type: bulk_transfer_reference
          ? `Bulk ${formatTypeFunction(type) || ""}`
          : type === "invoice-payment"
          ? "Invoice Payment"
          : type === "payment-link"
          ? "Payment Link"
          : formatTypeFunction(
              single_bulk_transactions?.main_transaction?.type
            ),
        status:
          String(status) === "1"
            ? "Processing"
            : String(status) === "0"
            ? "Pending"
            : String(status) === "3"
            ? "Success"
            : String(status) === "2"
            ? "Failed"
            : String(status) === "11"
            ? "Reversal"
            : status,
        reference: trx_ref || "---",
        bulk_reference:
          single_bulk_transactions?.main_transaction?.bulk_transfer_reference ||
          "---",
        created_at: formatDate(created_at),
      };
    });
    return list;
  };

  const handleSubmit = async () => {
    const obj = {
      transaction_id: String(single_bulk_transactions?.main_transaction?.id),
    };
    // console.log(obj);
    // return
    const data = await dispatch(downloadTransactionReciept(obj));
    if (data?.payload?.data?.status === "success") {
      // setDownloadCsvId("");
      downloadCsv(data?.payload?.data?.data?.url);
      // downloadCsvId("");
      // console.log(data);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={` Bulk Summary`}
          text={`See all bulk transaction and click on a transfer to insight into each transactions`}
          back
          onBack={() => {
            // console.log(single_bulk_transactions);
            navigate("/dashboard-transaction");
          }}
          //   exportBtn
          //   onExport={() => {
          //     setShowModal((prev) => {
          //       return { ...prev, statement: true };
          //     });
          //   }}
        />
        {/* top invoice container start */}
        <div className="top-transaction-single-bulk-container-box">
          {/* box start */}
          <div className="box card-bg">
            <div className="title grey-white-color">
              Total Recepient
              {/* <div className="title-total black-fade-white-bg">
                    <span>{single_bulk_transactions?.total || "0"}</span>
                  </div> */}
            </div>
            <div className="value-box grey-bg">
              {loading ? (
                <Skeleton width={`20%`} height={20} />
              ) : (
                <p className="value">
                  {formatNumWithComma(
                    single_bulk_transactions?.transaction_details?.total || "0"
                  ) || "0"}
                </p>
              )}
            </div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box card-bg">
            <div className="title grey-white-color">
              Total Amount
              <div className="title-total black-fade-white-bg">
                <span>
                  {formatNumWithComma(
                    single_bulk_transactions?.transaction_details?.total || "0"
                  ) || "0"}
                </span>
              </div>
            </div>
            {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
            <div className="value-box grey-bg">
              {loading ? (
                <Skeleton width={`20%`} height={20} />
              ) : (
                <p className="value">
                  {formatNumWithCommaNairaSymbol(
                    single_bulk_transactions?.main_transaction?.amount || 0
                  ) || "0"}
                </p>
              )}
            </div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box card-bg">
            <div className="title grey-white-color">
              Total Sent{" "}
              <div className="title-total black-fade-white-bg">
                <span>
                  {formatNumWithComma(
                    formatMetaData(
                      single_bulk_transactions?.main_transaction?.meta_data
                    )?.summary?.total_no_sent || 0
                  ) || "0"}
                </span>
              </div>
            </div>
            {Number(single_bulk_transactions?.main_transaction?.fee) > 0 && (
              <div className="title-two grey-white-color">
                Total Fee:{" "}
                <p className="title black-white-color">
                  {single_bulk_transactions?.main_transaction?.fee
                    ? formatNumWithCommaNairaSymbol(
                        single_bulk_transactions?.main_transaction?.fee
                      )
                    : formatNumWithCommaNairaSymbol("0")}
                </p>
              </div>
            )}
            <div className="value-box grey-bg">
              {loading ? (
                <Skeleton width={`20%`} height={20} />
              ) : (
                <p style={{ color: "#1ACE37" }} className="value">
                  {formatNumWithCommaNairaSymbol(
                    formatMetaData(
                      single_bulk_transactions?.main_transaction?.meta_data
                    )?.summary?.total_sent || 0
                  ) || "0"}
                </p>
              )}
            </div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box card-bg">
            <div className="title grey-white-color">
              Total Failed{" "}
              <div className="title-total black-fade-white-bg">
                <span>
                  {" "}
                  {formatNumWithComma(
                    formatMetaData(
                      single_bulk_transactions?.main_transaction?.meta_data
                    )?.summary?.total_no_failed || 0
                  ) || "0"}
                </span>
              </div>
            </div>
            {/* <p className="title grey-white-color">Total Overdue</p> */}
            <div className="value-box grey-bg">
              {loading ? (
                <Skeleton width={`20%`} height={20} />
              ) : (
                <p style={{ color: "#FF0F00" }} className="value">
                  {formatNumWithCommaNairaSymbol(
                    formatMetaData(
                      single_bulk_transactions?.main_transaction?.meta_data
                    )?.summary?.total_failed || 0
                  ) || "0"}
                </p>
              )}
            </div>
          </div>
          {/* box end */}
        </div>
        {/* top invoice container end */}
        <ContainerWrapper
          pagination={
            single_bulk_transactions?.transaction_details?.data?.length > 0 && (
              <RavenPagination
                color={`black-light`}
                blackHover
                numSpacing={size.width < 900 && 1}
                removeNext={size.width < 900}
                removePrev={size.width < 900}
                currentPage={
                  Number(
                    single_bulk_transactions?.transaction_details?.current_page
                  ) || 1
                }
                nextPage={
                  single_bulk_transactions?.transaction_details
                    ?.next_page_url_params
                }
                prevPage={
                  single_bulk_transactions?.transaction_details
                    ?.prev_page_url_params
                }
                onNext={(e) => {
                  // console.log(e);
                  const num = e?.split("&current_page=")[1]?.split("&")[0];
                  getTransactions(Number(num));
                }}
                onPrev={(e) => {
                  const num = e?.split("&current_page=")[1]?.split("&")[0];
                  getTransactions(Number(num));
                }}
                totalPage={
                  getTotalPage(
                    single_bulk_transactions?.transaction_details?.per_page,
                    single_bulk_transactions?.transaction_details?.total
                  ) || 1
                }
                onNumView={(e) => {
                  // console.log(e);
                  getTransactions(e);
                }}
              />
            )
          }
          width={`100%`}
          dontShowHeader
        >
          <div className="bulk-transaction-main-wrap">
            <div
              className="two-btn"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "2rem",
              }}
            >
              <RavenButton
                onClick={handleSubmit}
                loading={loadingDownload}
                label="Download Reciept"
                color="black-light"
              />
              {/* export table content start */}
              <CSVLink
                className="export-table-content-box tooltip-hover-wrap grey-bg"
                filename={"Raven Business Banking Bulk Transactions.csv"}
                data={
                  single_bulk_transactions?.transaction_details?.data
                    ? formatExportData(
                        single_bulk_transactions?.transaction_details?.data
                      )
                    : ""
                    || ""}
                headers={CsvHeader}
              >
                {" "}
                {/* <RavenToolTip
                color="black-light"
                text="Export Table Content"
                position={`right`}
              /> */}
                <p className="text">Export Content</p>
                <figure className="img-box">{exportTableIcon}</figure>
              </CSVLink>
              {/* export table content endd */}
            </div>

            {single_bulk_transactions?.transaction_details?.data?.length > 0 ? (
              <div className="transaction-main-wrap">
                <div
                  className="table-wrap table-transaction-cover"
                  style={{ height: "100%" }}
                  onClick={() => {
                    //   showFilterOption && setShowFilterOption(false);
                  }}
                >
                  {/* per page start */}
                  {/* <PerPageComponent /> */}
                  {/* per page end */}
                  <div className="wrap-scroll">
                    <RavenTable
                      className={
                        formatMetaData(user?.theme_display_settings)
                          ?.table_font === "bold" && "table-business"
                      }
                      headerList={headerList}
                      //   action
                    >
                      {single_bulk_transactions?.transaction_details?.data?.map(
                        (chi, idx) => {
                          const {
                            amount,
                            // direction,
                            status,
                            created_at,
                            bulk_transfer_reference,
                            trx_ref,
                            network,
                            phone,
                            account_name,
                            account_number,
                            bank,
                            meta_data,
                            identifier,
                          } = chi;
                          return (
                            <RavenTableRow
                              onEdit={() => {
                                setShowModal(true);
                              }}
                              key={idx}
                              one={rowTypeText(
                                single_bulk_transactions?.main_transaction
                                  ?.direction,
                                lowerCaseWrap(
                                  phone ||
                                    account_name ||
                                    lowerCaseWrap(
                                      formatMetaData(meta_data)?.meta
                                        ?.account_name
                                    ) ||
                                    ""
                                ),
                                single_bulk_transactions?.main_transaction?.type.includes(
                                  "recharge"
                                )
                                  ? `${lowerCaseWrap(
                                      network
                                    )} ${formatTypeFunction(
                                      single_bulk_transactions?.main_transaction
                                        ?.type
                                    )}`
                                  : identifier
                                  ? `@${identifier} • Raven Bank`
                                  : `${
                                      account_number ||
                                      formatMetaData(meta_data)?.account_number
                                    } • ${
                                      trimLongString(bank, 20) ||
                                      trimLongString(
                                        formatMetaData(meta_data)?.bank,
                                        20
                                      )
                                    }` ||
                                    `${lowerCaseWrap(
                                      network
                                    )} ${formatTypeFunction(
                                      single_bulk_transactions?.main_transaction
                                        ?.type
                                    )}` ||
                                    `${account_number} • ${lowerCaseWrap(
                                      trimLongString(bank, 20)
                                    )}` ||
                                    ""
                              )}
                              onRowClick={() => {
                                setShowModal((prev) => {
                                  return { ...prev, view: true };
                                });
                                const newObj = {
                                  ...chi,
                                  type: single_bulk_transactions
                                    ?.main_transaction?.type,
                                };
                                setSingleObj(newObj);
                              }}
                              two={formatNumWithCommaNairaSymbol(amount)}
                              three={
                                bulk_transfer_reference
                                  ? "Bulk Transfer"
                                  : formatTypeFunction(
                                      single_bulk_transactions?.main_transaction
                                        ?.type
                                    )
                              }
                              four={formatDate(created_at)}
                              five={trx_ref}
                              six={formatTypeWrap(String(status) || "Not set")}
                              // action
                              // dontShowAction
                              // dontShowEdit
                              //   ManualAddActions={() => transactionActions(chi)}
                              loading={loading}
                            />
                          );
                        }
                      )}
                    </RavenTable>
                  </div>

                  <div
                    //   onClick={() => {
                    //     showFilterOption && setShowFilterOption(false);
                    //   }}
                    className="mobile-table-box table-transaction-cover"
                  >
                    {single_bulk_transactions?.transaction_details?.data?.map(
                      (chi, idx) => {
                        const {
                          amount,
                          // direction,
                          // type,
                          created_at,
                          meta_data,
                          phone,
                          account_name,
                          network,
                          identifier,
                          account_number,
                          bank,
                        } = chi;
                        return (
                          <MobileTableCard
                            key={idx}
                            amount={`${
                              single_bulk_transactions?.main_transaction
                                ?.direction === "debit"
                                ? "-"
                                : "+"
                            }${formatNumWithCommaNairaSymbol(amount)}`}
                            text={
                              single_bulk_transactions?.main_transaction?.type.includes(
                                "recharge"
                              )
                                ? `${lowerCaseWrap(
                                    network
                                  )} ${formatTypeFunction(
                                    single_bulk_transactions?.main_transaction
                                      ?.type
                                  )}`
                                : identifier
                                ? `@${identifier} • Raven Bank`
                                : `${
                                    account_number ||
                                    formatMetaData(meta_data)?.account_number
                                  } • ${
                                    trimLongString(bank, 20) ||
                                    trimLongString(
                                      formatMetaData(meta_data)?.bank,
                                      20
                                    )
                                  }` ||
                                  `${lowerCaseWrap(
                                    network
                                  )} ${formatTypeFunction(
                                    single_bulk_transactions?.main_transaction
                                      ?.type
                                  )}` ||
                                  `${account_number} • ${lowerCaseWrap(
                                    trimLongString(bank, 20)
                                  )}` ||
                                  ""
                            }
                            textTwo={`${formatDate(created_at).split("—")[0]}`}
                            onRowClick={() => {
                              setShowModal((prev) => {
                                return { ...prev, view: true };
                              });
                              const newObj = {
                                ...chi,
                                type: single_bulk_transactions?.main_transaction
                                  ?.type,
                              };
                              setSingleObj(newObj);
                            }}
                            loading={loading}
                            title={lowerCaseWrap(
                              phone ||
                                account_name ||
                                lowerCaseWrap(
                                  formatMetaData(meta_data)?.meta?.account_name
                                ) ||
                                ""
                            )}
                            showImg
                            type={
                              single_bulk_transactions?.main_transaction
                                ?.direction
                            }
                            amountColor={
                              single_bulk_transactions?.main_transaction
                                ?.direction === "debit"
                                ? `#FF0F00`
                                : "#1ACE37"
                            }
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <NoContentBox
                title={`No bulk transaction found`}
                text={`Bulk transaction history will appear here when transaction is made`}
                bgColor
                loading={loading}
              />
            )}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      <SingleDownloadReceiptModal
        visible={showModal?.view}
        onClose={() =>
          setShowModal((prev) => {
            return { ...prev, view: false };
          })
        }
        detail={singleObj}
      />
      <GenerateStatementModal
        visible={showModal?.statement}
        onCancel={() =>
          setShowModal((prev) => {
            return { ...prev, statement: false };
          })
        }
      />
    </>
  );
};

export default SingleBulkTransaction;
