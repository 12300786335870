import {
  RavenCheckBox,
  RavenInputField,
  RavenModal,
  RavenToggleSlide,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPosAdminInfo, manageSettlement } from "../../../../redux/pos";
import { validateBankAccount } from "../../../../redux/transaction";
import { reactSelectStyle, removeSpace } from "../../../../utils/helper/Helper";
import { ThreeDots } from "react-loader-spinner";
import "../../../../styles/dashboard/pos/DashboardPosIndex.css";
import infoIcon from "../../../../assets/info-black-icon.svg";

const SettlementSettingModal = ({ visible, onClose, onFinish }) => {
  const { loadingSettlement, adminInfo } = useSelector((state) => state.pos);
  const { account_details } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { bankList, loadingVerify } = useSelector((state) => state.transaction);
  const [settleOption, setSettleoption] = useState("Your Account");
  const [details, setDetails] = useState({
    type: "1",
    auto: "yes",
    bank_name: "",
    bank_code: "",
    account_number: "",
    account_name: "",
    bank: "",
  });

  const listType = ["Business Account", "Bank Account"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name } = chi;
      return { label: name, value: code };
    });
    return list;
  };

  useEffect(() => {
    // info?.settlement_type === "manual"
    const info = adminInfo?.getInfo;
    if (info?.settlement_bank_code) {
      // console.log(info);
      info?.settlement_account_number === account_details?.account_number
        ? setSettleoption("Business Account")
        : setSettleoption("Bank Account");
      setDetails((prev) => {
        return {
          ...prev,
          auto: info?.settlement_type === "auto" ? "yes" : "no",
          type:
            info?.settlement_account_number === account_details?.account_number
              ? "1"
              : "0",
          bank_name: info?.settlement_bank_name,
          bank_code: info?.settlement_bank_code,
          account_number: info?.settlement_account_number,
          account_name: info?.settlement_account_name,
          bank: {
            label: info?.settlement_bank_name,
            value: info?.settlement_bank_code,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminInfo]);

  // validate bank account details
  const validateBankDetails = async (e) => {
    const payload = {
      bank_code: e || details?.bank.value || details?.bank?.bank_code,
      account_number: details.account_number,
    };
    // console.log(payload);
    if (removeSpace(payload?.account_number)?.length > 9) {
      const data = await dispatch(validateBankAccount(payload));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        // console.log(data);
        setDetails((prev) => {
          return { ...prev, account_name: data?.payload?.data?.data };
        });
      }
    }
  };

  useEffect(() => {
    if (details?.bank && details?.account_number?.length > 9) {
      validateBankDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.account_number || details?.bank]);

  const handleSubnmit = async () => {
    let val;
    if (details?.type === "1") {
      const obj = {
        type: "1",
        auto: details?.auto,
      };
      val = obj;
    } else {
      const obj = {
        type: "0",
        auto: details?.auto,
        bank_name: details?.bank_name,
        bank_code: details?.bank_code,
        account_number: details?.account_number,
        account_name: details?.account_name,
      };
      val = obj;
    }
    // console.log(val);
    // return
    const data = await dispatch(manageSettlement(val));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    dispatch(getPosAdminInfo());
    onFinish();
  };

  return (
    <RavenModal
      loading={loadingSettlement}
      visble={visible}
      onClose={onClose}
      btnColor={`black-light`}
      btnLabel={`Activate Settlement`}
      onBtnClick={handleSubnmit}
      disabled={
        details?.type === "0"
          ? !details?.bank_code ||
            !details?.bank_name ||
            !details?.account_name ||
            !details?.account_number
          : false
      }
    >
      <div className="pos-settlement-wrap-modal">
        {/* title text box start */}
        <div className="title-text-box">
          <p className="title">Settlement setting</p>
          <p className="text grey-white-color">
            Choose where to settle your balance into
          </p>
        </div>
        {/* title text box end */}

        {/* content box start */}
        <div className="content-box">
          {/* select type box start */}
          <div className="select-type-box border-theme-bottom">
            {listType?.map((chi, idx) => {
              return (
                <div
                  key={idx}
                  className={`type-child grey-white-color ${
                    settleOption === chi && "type-child-active"
                  }`}
                  onClick={() => {
                    setSettleoption(chi);
                  }}
                >
                  <span
                    className={` ${
                      !settleOption === chi && "grey-white-color"
                    } `}
                  >
                    {chi}
                  </span>
                </div>
              );
            })}
          </div>
          {/* select type box end */}
          {settleOption === "Business Account" ? (
            <>
              <div className="accout-wrap grey-bg">
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Account Number</p>
                  <p className="value">
                    {" "}
                    {account_details?.account_number || "---"}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Bank</p>
                  <p className="value"> {account_details?.bank || "---"}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Account Name</p>
                  <p className="value">
                    {" "}
                    {account_details?.account_name || "---"}
                  </p>
                </div>
                {/* box end */}
              </div>
            </>
          ) : (
            <>
              <div className="form-input-wraps">
                <RavenInputField
                  color={`black-light`}
                  type={`select`}
                  placeholder="Select a bank."
                  label={`Settlement Bank`}
                  style={{ position: "relative", zIndex: "10" }}
                  selectValue={details?.bank}
                  name={`bank`}
                  value={details?.bank}
                  className={`select-react`}
                  selectOption={formatSelectOption(bankList)}
                  selectStyles={reactSelectStyle}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return {
                        ...prev,
                        bank: e,
                        bank_code: e.value,
                        bank_name: e.label,
                        account_number: "",
                      };
                    });
                  }}
                />
                <RavenInputField
                  color={`black-light`}
                  label={`Account Number* `}
                  type={`account-number`}
                  name={`account_number`}
                  value={details?.account_number}
                  onChange={handleChange}
                  maxLength={11}
                  labelSpanText={
                    loadingVerify ? (
                      <div
                        style={{
                          padding: "0rem",
                          display: "flex",
                          justifyContent: "flex-end",
                          transform: "translateX(1.5rem)",
                        }}
                        className="load-wrap"
                      >
                        <ThreeDots
                          height="10"
                          width="80"
                          radius="9"
                          color="#020202"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <>{details?.account_name ? "Verified" : "Verify"}</>
                    )
                  }
                  labelColor={`purple-light`}
                  onActionClick={() => {
                    details?.account_number?.length === 10 &&
                      validateBankDetails();
                  }}
                />
                {/* verify name box start */}
                <div
                  className={`verify-name-box ${
                    details?.account_name && "verify-name-box-show"
                  }`}
                >
                  <RavenCheckBox
                    checked={true}
                    color={`black-light`}
                    className={`check`}
                  />
                  <p className="text">{details?.account_name}</p>
                </div>
                {/* verify name box end */}
              </div>
            </>
          )}
          {/* toggle slide box start */}
          <div className="toggle-slide-text-box">
            <div className="text-info-box">
              <p className="text grey-white-color">
                Enable auto settlement to this account?
              </p>
              <figure className="img-box tooltip-hover-wrap">
                <img src={infoIcon} alt="" className="img" />
                <RavenToolTip
                  color={`black-light`}
                  text={`Auto-settlements automate the process of transferring your POS funds into your account daily.`}
                  big
                  title={`Auto-settlements`}
                  downText={`Got it`}
                  position={`bottom-right`}
                />
              </figure>
            </div>
            <RavenToggleSlide
              color={`black-light`}
              value={details?.auto}
              checked={details?.auto === "yes" ? true : false}
              onChange={() => {
                setDetails((prev) => {
                  return {
                    ...prev,
                    auto: details?.auto === "no" ? "yes" : "no",
                  };
                });
              }}
            />
          </div>
          {/* toggle slide box end */}
        </div>
        {/* content box end */}
      </div>
    </RavenModal>
  );
};

export default SettlementSettingModal;
