import { RavenInputField, RavenModal, toast } from "@ravenpay/raven-bank-ui";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUser, setUserPin } from "../../redux/user";
// import { requestPos } from "../../../../redux/pos";
import "../../styles/dashboard/pos/RequestPos.css";

const SetTransactionPinModal = ({ visible, onClose, onFinish }) => {
  const dispatch = useDispatch();
  //   const { loading } = useSelector((state) => state.user);
  const [details, setDetails] = useState({
    one: "",
    two: "",
    complete_one: false,
    complete_two: false,
  });

  const handleSubmit = () => {
    if (details?.one !== details?.two) {
      toast.error("Pins does not match");
    } else {
      handleSubmitDetails();
    }
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDetails((prev) => {
      return { ...prev, complete_one: false, complete_two: false };
    });
    pinRefVal.current.forEach((input) => (input.value = ""));
    pinRefValTwo.current.forEach((input) => (input.value = ""));
  }, [visible]);

  const handleSubmitDetails = async () => {
    setLoading(true);
    const obj = {
      pin: details?.one,
    };
    const data = await dispatch(setUserPin(obj));
    if (data?.payload?.data?.status === "success") {
      setLoading(false);
      onFinish();
      onClose();
      dispatch(getUser());
    } else {
      setLoading(false);
    }
  };

  const pinRefVal = useRef();
  const pinRefValTwo = useRef();

  return (
    <RavenModal
      disabled={!details?.complete_one || !details?.complete_two}
      visble={visible}
      onClose={() => {
        setDetails({
          one: "",
          two: "",
          complete_one: false,
          complete_two: false,
        });
        onClose();
      }}
      btnColor={`black-light`}
      btnLabel={`Create Pin`}
      onBtnClick={handleSubmit}
      loading={loading}
    >
      <div className="set-terminal-transaction-pin-modal-wrap">
        <p className="title"> Create Transaction Pin</p>
        <p className="text grey-white-color border-theme-bottom">
          Set your transaction pin, this will be used to authenticate all
          request
        </p>

        <div className="pin-input-two-wrap">
          <RavenInputField
            pinRef={pinRefVal}
            className={`terminal-trans-pin`}
            color={`black-light`}
            type={`pin`}
            label={`Transaction Pin* `}
            placeholder={`transaction pin `}
            name={`pin`}
            value={details?.one}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, one: e, complete_one: false };
              });
            }}
            id={`ikjsss`}
            pinFieldNumber={6}
            onComplete={() => {
              setDetails((prev) => {
                return { ...prev, complete_one: true };
              });
            }}
          />
          <RavenInputField
            pinRef={pinRefValTwo}
            className={`terminal-trans-pin`}
            color={`black-light`}
            type={`pin`}
            label={`Confirm Transaction Pin * `}
            placeholder={`transaction pin `}
            name={`pin`}
            id={`ikss`}
            value={details?.two}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, two: e, complete_two: false };
              });
            }}
            pinFieldNumber={6}
            onComplete={() => {
              setDetails((prev) => {
                return { ...prev, complete_two: true };
              });
            }}
          />
        </div>
      </div>
    </RavenModal>
  );
};

export default SetTransactionPinModal;
