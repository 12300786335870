import React, { useContext, useState } from "react";
// import { FaAngleLeft } from "react-icons/fa";
import "../../styles/reusables/TitletextBox.css";
import arrowRight from "../../assets/arrow-right-icon.svg";
import arrowRightIcon from "../../assets/Iconarrow-right-icon.svg";
import {
  RavenButton,
  // RavenCheckBox,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";
import settingIcon from "../../assets/payroll-settings-icon.svg";
// import autoIcon from "../../../assets/auto-icon-upload.svg";
// import payrollSettingImg from "../../assets/auto-icon-upload.svg";
// import payrollHistoryImg from "../../assets/payroll-history-icon-drop.svg";
// import deactivateImg from "../../assets/drop-settings-icon.svg";
import ActionContext from "../../context/ActionContext";
// import BlurWrap from "../fragments/BlurWrap";
import topUpIcon from "../../assets/top-up-pos-icon.svg";
import settleBalIcon from "../../assets/settle-balance-pos-icon.svg";
import viewAllIcon from "../../assets/transaction-pos-icon.svg";
import cashierviewIcon from "../../assets/manage-cashier-pos-icon.svg";
import posStatementIcon from "../../assets/pos-generate-statement-icon.svg";
import { useSelector } from "react-redux";
import { formatStringPermissionToLowerCase } from "../../utils/helper/Helper";
import { useOnClickOutside } from "../../utils/helper/UseOnClickOutside";

//   <svg
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     className="img"
//   >
//     <path
//       d="M12.6124 19.9536C17.11 19.9536 20.7561 16.3075 20.7561 11.8099C20.7561 7.3123 17.11 3.66626 12.6124 3.66626C8.11479 3.66626 4.46875 7.3123 4.46875 11.8099C4.46875 16.3075 8.11479 19.9536 12.6124 19.9536Z"
//       fill="#272823"
//     />
//     <path
//       d="M12.6132 20.013C8.08982 20.013 4.41016 16.3334 4.41016 11.81C4.41016 7.28669 8.08982 3.60669 12.6132 3.60669C17.1365 3.60669 20.8165 7.28669 20.8165 11.81C20.8165 16.3334 17.1365 20.013 12.6132 20.013ZM12.6132 3.72569C8.15549 3.72569 4.52916 7.35236 4.52916 11.81C4.52916 16.2677 8.15549 19.894 12.6132 19.894C17.0708 19.894 20.6972 16.2667 20.6972 11.81C20.6972 7.35336 17.0708 3.72569 12.6132 3.72569Z"
//       fill="#272823"
//     />
//     <path
//       d="M12.185 16.8831C12.1288 16.827 12.0843 16.7604 12.0539 16.6871C12.0235 16.6137 12.0079 16.5351 12.008 16.4558V7.16442C12.0061 7.08389 12.0203 7.00379 12.0498 6.92883C12.0793 6.85388 12.1235 6.78558 12.1798 6.72795C12.2361 6.67032 12.3034 6.62453 12.3776 6.59327C12.4518 6.562 12.5316 6.5459 12.6121 6.5459C12.6927 6.5459 12.7724 6.562 12.8467 6.59327C12.9209 6.62453 12.9882 6.67032 13.0445 6.72795C13.1008 6.78558 13.145 6.85388 13.1745 6.92883C13.204 7.00379 13.2182 7.08389 13.2163 7.16442V16.4558C13.2162 16.5752 13.1808 16.692 13.1144 16.7913C13.048 16.8906 12.9537 16.968 12.8433 17.0137C12.733 17.0595 12.6115 17.0715 12.4943 17.0482C12.3772 17.025 12.2695 16.9675 12.185 16.8831Z"
//       fill="white"
//     />
//     <path
//       d="M7.53996 12.2371C7.45555 12.1525 7.3981 12.0449 7.37484 11.9277C7.35159 11.8105 7.36358 11.6891 7.40931 11.5787C7.45504 11.4684 7.53245 11.374 7.63176 11.3077C7.73107 11.2413 7.84783 11.2058 7.96729 11.2057H17.2586C17.3392 11.2038 17.4193 11.2181 17.4942 11.2476C17.5692 11.2771 17.6375 11.3213 17.6951 11.3776C17.7527 11.4339 17.7985 11.5011 17.8298 11.5754C17.861 11.6496 17.8772 11.7293 17.8772 11.8099C17.8772 11.8905 17.861 11.9702 17.8298 12.0444C17.7985 12.1187 17.7527 12.1859 17.6951 12.2422C17.6375 12.2985 17.5692 12.3427 17.4942 12.3722C17.4193 12.4017 17.3392 12.416 17.2586 12.4141H7.96729C7.88792 12.4141 7.80931 12.3985 7.73598 12.3682C7.66265 12.3378 7.59603 12.2932 7.53996 12.2371Z"
//       fill="white"
//     />
//   </svg>
// );

// const terminalStatuList = [
//   {
//     img: orderReceiveTerminal,
//     title: "Order recieved",
//     text: "We have received your terminal order and it’s currently been processed, if you have any complaint and issues please",
//   },
//   {
//     img: shippedTerminal,
//     title: "Your Terminal is being shipped!",
//     text: "Our terminal delivery usually take 48 - 72 hours, if your delivery duration exceed this period please",
//   },
//   {
//     img: setupTerminal,
//     title: "Setup your terminal.",
//     text: "At this time, we believe you have received your terminal but not setup yet, our customer service would reach you to help you setup your terminal. if you have any issue,",
//   },
// ];

const TitletextBox = ({
  title,
  text,
  onAdd,
  onBack,
  back,
  next,
  onNext,
  btnText,
  onBtnClick,
  addBtn,
  toolTipMsg,
  bulkSelectList,
  settings,
  onClickDrop,
  btnPosText,
  settingPos,
  rearrangeIcon,
  showTerminalBtn,
  onViewCashier,
  onPosTopUp,
  viewPosTransaction,
  onGenerate,
  exportBtn,
  onExport,
  paymenlinkSettings,
  paymenlinkSettingStatus,
  onLinkAction,
  settingPosSingle,
  invoiceSettings,
  onInvoiceSettings,
  onSettlementPos,
  exportText,
  onRequestTerminal,
  nextHoverText,
  children,
  showSettingsIcon,
}) => {
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const [showSettings, setShowSettings] = useState(false);
  const deleteIcon = (
    <svg
      className="img"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#F7F8F7" />
      <g clipPath="url(#clip0_1_63)">
        <path
          d="M17.941 24.6517C22.1669 24.6517 25.5927 21.2259 25.5927 17C25.5927 12.7741 22.1669 9.34833 17.941 9.34833C13.7151 9.34833 10.2893 12.7741 10.2893 17C10.2893 21.2259 13.7151 24.6517 17.941 24.6517Z"
          fill="#FF0F00"
        />
        <path
          d="M17.941 24.7077C13.691 24.7077 10.2333 21.25 10.2333 17C10.2333 12.75 13.691 9.29233 17.941 9.29233C22.191 9.29233 25.6483 12.75 25.6483 17C25.6483 21.25 22.191 24.7077 17.941 24.7077ZM17.941 9.40433C13.7527 9.40433 10.3453 12.8117 10.3453 17C10.3453 21.1883 13.7527 24.5957 17.941 24.5957C22.1293 24.5957 25.5363 21.1883 25.5363 17C25.5363 12.8117 22.129 9.40433 17.941 9.40433Z"
          fill="#FF0F00"
        />
        <path
          d="M21.0273 20.6543C20.8772 20.654 20.7333 20.594 20.6273 20.4877L14.453 14.315C14.4003 14.2623 14.3585 14.1997 14.3299 14.1308C14.3014 14.0619 14.2867 13.9881 14.2867 13.9135C14.2867 13.8389 14.3014 13.7651 14.3299 13.6962C14.3585 13.6273 14.4003 13.5647 14.453 13.512C14.5057 13.4593 14.5683 13.4174 14.6372 13.3889C14.7061 13.3604 14.7799 13.3457 14.8545 13.3457C14.9291 13.3457 15.0029 13.3604 15.0718 13.3889C15.1407 13.4174 15.2033 13.4593 15.256 13.512L21.429 19.685C21.509 19.7642 21.5636 19.8654 21.5858 19.9758C21.608 20.0861 21.5969 20.2006 21.5538 20.3046C21.5107 20.4086 21.4376 20.4974 21.3439 20.5597C21.2501 20.622 21.1399 20.6549 21.0273 20.6543Z"
          fill="white"
        />
        <path
          d="M14.8543 20.6543C14.7419 20.6546 14.632 20.6214 14.5384 20.559C14.4449 20.4966 14.3721 20.4077 14.3292 20.3038C14.2863 20.1999 14.2753 20.0856 14.2976 19.9754C14.3199 19.8652 14.3744 19.7641 14.4543 19.685L20.626 13.512C20.7325 13.4055 20.8769 13.3457 21.0275 13.3457C21.1781 13.3457 21.3225 13.4055 21.429 13.512C21.5355 13.6185 21.5953 13.7629 21.5953 13.9135C21.5953 14.0641 21.5355 14.2085 21.429 14.315L15.256 20.488C15.2033 20.5408 15.1407 20.5827 15.0718 20.6112C15.0028 20.6398 14.9289 20.6544 14.8543 20.6543Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_63">
          <rect
            width="19"
            height="20"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const disableIconTwo = (
    <svg
      className="img"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#F7F8F7" />
      <path
        d="M13.333 16.0002H22.6663M13.333 20.0002H22.6663M22.6663 13.3335L13.333 22.6668"
        stroke="#676767"
        strokeWidth="1.35383"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const posSettingsDropList = [
    {
      name: showTerminalBtn ? "Terminal status" : "Request Terminal",
      text: showTerminalBtn
        ? "Check terminal request status"
        : "Request a new terminal.",
      icon: viewAllIcon,
    },
    {
      name: "Top up account",
      text: "See your account details and top up",
      icon: topUpIcon,
    },
    {
      name: "Settlement Settings",
      text: "Set how you settle balance into your wallet",
      icon: settleBalIcon,
    },
    // {
    //   name: "View all transaction",
    //   text: "View transaction on your manager app.",
    //   icon: viewAllIcon,
    // },
    {
      name: "Manage Cashier",
      text: "Add and remove  cashiers",
      icon: cashierviewIcon,
    },
    {
      name: "Generate Statements",
      text: "Add and remove  cashiers",
      icon: posStatementIcon,
    },
  ];

  const posSettingsDropListSingle = [
    // {
    //   name: "Terminal details",
    //   text: "See your terminal details here",
    //   icon: viewAllIcon,
    // },
    {
      name: "Top up account",
      text: "See your account details and top up",
      icon: topUpIcon,
    },
    {
      name: "Generate Statements",
      text: "Add and remove  cashiers",
      icon: posStatementIcon,
    },
    {
      name: "Disable Terminal",
      text: "Disable terminal to stop activities on this terminal",
      icon: disableIconTwo,
    },
  ];

  const viewAllTranIcon = (
    <svg
      className="img"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8604 5.79093H16.6198V5.2494C16.6198 5.13586 16.5746 5.02697 16.4944 4.94668C16.4141 4.86639 16.3052 4.82129 16.1916 4.82129H15.8204C15.7069 4.82129 15.598 4.86639 15.5177 4.94668C15.4374 5.02697 15.3923 5.13586 15.3923 5.2494V5.79093H13.8742V5.2494C13.8742 5.13586 13.8291 5.02697 13.7488 4.94668C13.6685 4.86639 13.5596 4.82129 13.4461 4.82129H13.0754C12.9619 4.82129 12.853 4.86639 12.7727 4.94668C12.6924 5.02697 12.6473 5.13586 12.6473 5.2494V5.79093H11.2126V5.2494C11.2126 5.13586 11.1675 5.02697 11.0872 4.94668C11.0069 4.86639 10.898 4.82129 10.7845 4.82129H10.4138C10.3003 4.82129 10.1914 4.86639 10.1111 4.94668C10.0308 5.02697 9.98571 5.13586 9.98571 5.2494V5.79093H9.65899C9.26251 5.79093 8.88227 5.94843 8.60192 6.22878C8.32156 6.50913 8.16406 6.88937 8.16406 7.28585V14.4855C8.16402 14.6818 8.20266 14.8763 8.27777 15.0577C8.35288 15.2391 8.46299 15.4039 8.60181 15.5428C8.74063 15.6816 8.90544 15.7918 9.08683 15.8669C9.26823 15.942 9.46264 15.9807 9.65899 15.9807H16.8604C17.0568 15.9807 17.2512 15.942 17.4326 15.8669C17.614 15.7918 17.7788 15.6816 17.9176 15.5428C18.0564 15.4039 18.1665 15.2391 18.2416 15.0577C18.3168 14.8763 18.3554 14.6818 18.3554 14.4855V7.28525C18.3552 6.88888 18.1976 6.50879 17.9173 6.22857C17.637 5.94835 17.2568 5.79093 16.8604 5.79093Z"
        fill="white"
      />
      <path
        d="M12.8133 17.5637C14.7421 15.6349 14.7421 12.5077 12.8133 10.5789C10.8844 8.65003 7.7572 8.65003 5.82838 10.5788C3.89956 12.5077 3.89956 15.6349 5.82838 17.5637C7.7572 19.4925 10.8844 19.4925 12.8133 17.5637Z"
        fill="#676767"
      />
      <path
        d="M11.596 15.036H9.24277C9.14838 15.036 9.05786 14.9985 8.99111 14.9317C8.92437 14.865 8.88687 14.7744 8.88687 14.6801V11.5404C8.88548 11.4928 8.89367 11.4453 8.91094 11.4009C8.92821 11.3565 8.95422 11.316 8.98742 11.2818C9.02062 11.2477 9.06034 11.2205 9.10422 11.2019C9.14811 11.1834 9.19528 11.1738 9.24292 11.1738C9.29057 11.1738 9.33774 11.1834 9.38163 11.2019C9.42551 11.2205 9.46523 11.2477 9.49843 11.2818C9.53163 11.316 9.55764 11.3565 9.57491 11.4009C9.59218 11.4453 9.60037 11.4928 9.59898 11.5404V14.3242H11.596C11.6904 14.3242 11.7809 14.3616 11.8477 14.4284C11.9144 14.4951 11.9519 14.5857 11.9519 14.6801C11.9519 14.7744 11.9144 14.865 11.8477 14.9317C11.7809 14.9985 11.6904 15.036 11.596 15.036Z"
        fill="white"
      />
    </svg>
  );

  // const actionComponent = <manualAddSettings />

  const downloadIcon = (
    <svg
      className="img"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9492 13.5133V14.7133C18.9492 16.3935 18.9492 17.2335 18.6222 17.8753C18.3346 18.4398 17.8757 18.8987 17.3112 19.1863C16.6695 19.5133 15.8294 19.5133 14.1492 19.5133H5.74922C4.06906 19.5133 3.22898 19.5133 2.58725 19.1863C2.02276 18.8987 1.56382 18.4398 1.2762 17.8753C0.949219 17.2335 0.949219 16.3935 0.949219 14.7133V13.5133M14.9492 8.51331L9.94922 13.5133M9.94922 13.5133L4.94922 8.51331M9.94922 13.5133V1.51331"
        stroke="#020202"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const editIcon = (
    <svg
      className="img"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#F7F8F7" />
      <path
        d="M24 21.9998L23.3332 22.7292C22.9796 23.1159 22.5001 23.3332 22.0001 23.3332C21.5001 23.3332 21.0205 23.1159 20.6669 22.7292C20.3128 22.3432 19.8333 22.1265 19.3335 22.1265C18.8336 22.1265 18.3541 22.3432 18 22.7292M12 23.3332H13.1164C13.4425 23.3332 13.6055 23.3332 13.759 23.2963C13.895 23.2637 14.0251 23.2098 14.1444 23.1367C14.279 23.0542 14.3943 22.9389 14.6249 22.7083L23 14.3332C23.5523 13.7809 23.5523 12.8854 23 12.3332C22.4477 11.7809 21.5523 11.7809 21 12.3332L12.6248 20.7083C12.3942 20.9389 12.2789 21.0542 12.1965 21.1888C12.1234 21.3081 12.0695 21.4381 12.0368 21.5742C12 21.7276 12 21.8907 12 22.2168V23.3332Z"
        stroke="#676767"
        strokeWidth="1.35383"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const shareIcon = (
    <svg
      className="img"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#F7F8F7" />
      <path
        d="M24 15H21.8667C20.7466 15 20.1865 15 19.7587 15.218C19.3824 15.4097 19.0764 15.7157 18.8847 16.092C18.6667 16.5198 18.6667 17.0799 18.6667 18.2V19M24 15L22 13M24 15L22 17M16.6667 13H15.2C14.0799 13 13.5198 13 13.092 13.218C12.7157 13.4097 12.4097 13.7157 12.218 14.092C12 14.5198 12 15.0799 12 16.2V21.8C12 22.9201 12 23.4802 12.218 23.908C12.4097 24.2843 12.7157 24.5903 13.092 24.782C13.5198 25 14.0799 25 15.2 25H20.8C21.9201 25 22.4802 25 22.908 24.782C23.2843 24.5903 23.5903 24.2843 23.782 23.908C24 23.4802 24 22.9201 24 21.8V20.3333"
        stroke="#676767"
        strokeWidth="1.35383"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const disableIcon = (
    <svg
      className="img"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#F7F8F7" />
      <path
        d="M13.333 16.0002H22.6663M13.333 20.0002H22.6663M22.6663 13.3335L13.333 22.6668"
        stroke="#676767"
        strokeWidth="1.35383"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const paymentLinkList = [
    {
      name: "Edit Link",
      text: "Make changes to this payment link",
      icon: editIcon,
    },
    {
      name: "Share Link",
      text: "Share a link directly to your customer email",
      icon: shareIcon,
    },
    {
      name: "Disable Link",
      text: "Don’t enable payment on this link anymore",
      icon: disableIcon,
    },
    {
      name: "Delete Link",
      text: "Remove this link totally from your record",
      icon: deleteIcon,
    },
  ];
  const paymentLinkListOne = [
    {
      name: "Delete Link",
      text: "Remove this link totally from your record",
      icon: deleteIcon,
    },
  ];
  const dpaymentLinkListTwo = [
    {
      name: "Edit Link",
      text: "Make changes to this payment link",
      icon: editIcon,
    },
    {
      name: "Share Link",
      text: "Share a link directly to your customer email",
      icon: shareIcon,
    },
    {
      name: "Enable Link",
      text: "Enable payment on this link .",
      icon: disableIcon,
    },
    {
      name: "Delete Link",
      text: "Remove this link totally from your record",
      icon: deleteIcon,
    },
  ];

  const checkListToUse = (status) => {
    const list =
      status === 1
        ? paymentLinkList
        : status === 2
        ? paymentLinkListOne
        : dpaymentLinkListTwo;
    return list;
  };
  const { user_resource_and_permissions } = useSelector((state) => state.user);

  const testingPreviewRefSetting = useOnClickOutside(() => {
    setShowSettings(false);
  });

  return (
    <div className="title-text-box-wrap">
      <div className="back-text-box">
        {/* back icon start */}
        {/* {back && ( */}
        <div
          onClick={() => {
            back ? onBack() : navigate("/dashboard-overview");
          }}
          className="back-box no-border-border"
        >
          <figure className="img-box">
            <img src={arrowRight} alt="" className="img" />
          </figure>
          {/* <FaAngleLeft className="icon" /> */}
        </div>
        {/* )} */}
        {/* back icon end */}
        {/* text box start */}
        <div className="text-box">
          <p className="title">{title}</p>
          <p className="text grey-white-color">{text}</p>
        </div>
        {/* text box end */}
      </div>

      <div className="all-btn-wrap">
        {/* terminal request start */}
        {viewPosTransaction && (
          <div className="terminal-transaction-box">
            <RavenButton
              className="raven-btn"
              color={`black-light`}
              onClick={() => {
                localStorage.removeItem("pos-terminal-id");
                navigate("/dashboard-pos-terminal-information");
              }}
              // size="small"
            >
              {" "}
              <figure className="img-box">{viewAllTranIcon}</figure>
              <span>{viewPosTransaction}</span>{" "}
            </RavenButton>
          </div>
        )}
        {/* terminal request end */}
        {/* btn box start */}
        <div className="btn-box">
          {btnText && (
            <RavenButton
              onClick={onBtnClick}
              label={btnText}
              color={`black-light`}
            />
          )}
        </div>
        {/* btn box end */}
        {/* next box start */}
        {next && (
          <div
            onClick={onNext}
            className={`next-box ${nextHoverText && "tooltip-hover-wrap"}`}
          >
            {nextHoverText && (
              <RavenToolTip
                position={`left`}
                color={`black-light`}
                text={nextHoverText}
              />
            )}
            <figure className="img-box">
              <img src={arrowRightIcon} alt="" className="img" />
            </figure>
          </div>
        )}

        {/* next box end */}

        {/* add box start */}
        {addBtn && (
          <div
            className={`add-box toggle-box tooltip-hover-wrap  ${
              rearrangeIcon && "add-box-rearrange"
            }`}
          >
            {bulkSelectList?.length > 0 && (
              <div
                className={`select-bulk-type-box card-bg ${
                  actionCtx?.checkShow?.two && "select-bulk-type-box-show"
                }`}
              >
                {bulkSelectList?.map((chi, idx) => {
                  const { icon, name, text, link } = chi;
                  return (
                    <div
                      onClick={() => {
                        link && navigate(link);
                        actionCtx?.setCheckShow("close");
                      }}
                      className="child-row"
                      key={idx}
                    >
                      <figure className="img-box">
                        <img src={icon} alt="" className="img" />
                      </figure>
                      <div className="text-name-box">
                        <p className="title">{name}</p>
                        <p className="text grey-white-color">{text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <RavenButton
              onClick={() => {
                actionCtx?.setCheckShow("two");
                onAdd && onAdd();
                // setShowPayrollSetting(false);
              }}
              type={`btn-plus`}
              color={`black-light`}
            ></RavenButton>
            <RavenToolTip
              color={`black-light`}
              position={`left`}
              text={toolTipMsg}
            />
          </div>
        )}
        {/* add box end */}
        {/* settings box sytart */}
        {settings && (
          <div className="setting-box-wrap">
            {/* drop down wrap start */}
            {actionCtx.checkShow?.one && (
              <div className="drop-wrap card-bg">
                {settings?.map((chi, idx) => {
                  const { label, img, labelTwo } = chi || "";
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_delete_payroll"
                    ) &&
                    label === "Deactivate Payroll"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  return (
                    <div
                      onClick={() => {
                        onClickDrop && onClickDrop(chi);
                        actionCtx?.setCheckShow("close");
                      }}
                      key={idx}
                      className={`text-box ${labelTwo && "text-box_two"}`}
                    >
                      <figure className="img-box">
                        <img src={img} alt="" className="img" />
                      </figure>
                      <p className={`text text_two `}>
                        <span className="name">{label || chi}</span>
                        {labelTwo && (
                          <span className="grey-white-color">{labelTwo}</span>
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
            {/* drop down wrap end */}
            <div
              onClick={() => {
                actionCtx?.setCheckShow("one");
                // setShowBulkOption(false)
              }}
              className="setting-box"
            >
              <figure className="img-bo">
                <img src={settingIcon} alt="" className="img" />
              </figure>
            </div>
          </div>
        )}
        {/* <RavenCheckBox  color={`purple-light`} id="1" /> */}
        {settingPos && (
          <div className="setting-box-wrap setting-pos-box-wrap">
            {actionCtx?.checkShow?.three && (
              <div
                onClick={() => {
                  // actionCtx?.setCheckShow("close");
                }}
                className="drop-wrap card-bg setting-pos-box"
              >
                {posSettingsDropList.map((chi, idx) => {
                  const { name, text, icon } = chi;

                  if (
                    !formatStringPermissionToLowerCase(
                      user_resource_and_permissions?.allowed_permissions
                    )?.includes("can_request_bankbox") &&
                    name === "Request Terminal"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_view_cashiers"
                    ) &&
                    name === "Manage Cashier"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_manage_settlement"
                    ) &&
                    name === "Settlement Settings"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !formatStringPermissionToLowerCase(
                      user_resource_and_permissions?.allowed_permissions
                    )?.includes("can_generate_bankbox_transaction_statement") &&
                    name === "Generate Statements"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  return (
                    <div
                      key={idx}
                      onClick={() => {
                        name === "Request Terminal" && onRequestTerminal();
                        name === "Settlement Settings" && onSettlementPos();
                        name === "Manage Cashier" && onViewCashier();
                        name === "Top up account" && onPosTopUp();
                        name === "Terminal status" &&
                          actionCtx?.setCheckShow("six");
                        name !== "Terminal status" &&
                          actionCtx?.setCheckShow("close");
                        name === "Generate Statements" && onGenerate();
                      }}
                      className="child-row-pos border-theme-bottom"
                    >
                      {name === "Terminal status" ? (
                        <div className="terminal-request-stat-box">
                          {/* btn start */}
                          <figure className="img-box">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="img"
                            >
                              <path
                                d="M9.99888 13.5714V9.99998M9.99888 6.42855H10.0078M18.9275 9.99998C18.9275 14.9311 14.93 18.9286 9.99888 18.9286C5.06777 18.9286 1.07031 14.9311 1.07031 9.99998C1.07031 5.06887 5.06777 1.07141 9.99888 1.07141C14.93 1.07141 18.9275 5.06887 18.9275 9.99998Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeinejoin="round"
                              />
                            </svg>
                          </figure>
                          {/* btn end */}
                        </div>
                      ) : (
                        <div className="img-wrap-box">
                          <figure className="img-box grey-bg">
                            <img src={icon} alt="" className="img" />
                          </figure>
                        </div>
                      )}
                      <div className="name-text-box">
                        <p className="name">{name}</p>
                        <p className="text grey-white-color">{text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              onClick={() => {
                actionCtx?.setCheckShow("three");
              }}
              className="setting-box tooltip-hover-wrap"
            >
              <RavenToolTip
                color={`black-light`}
                text={`Manage Terminal`}
                position={`bottom-left`}
              />
              <figure className="img-box">
                <img src={settingIcon} alt="" className="img" />
              </figure>
            </div>
          </div>
        )}

        {settingPosSingle && (
          <div className="setting-box-wrap setting-pos-box-wrap">
            {actionCtx?.checkShow?.eight && (
              <div
                onClick={() => {
                  // actionCtx?.setCheckShow("close");
                }}
                className="drop-wrap card-bg setting-pos-box"
              >
                {posSettingsDropListSingle.map((chi, idx) => {
                  const { name, text, icon } = chi;
                  return (
                    <div
                      key={idx}
                      onClick={() => {
                        name === "Settle Balance" &&
                          navigate("/dashboard-pos-management-settle-balance");
                        name === "Disable Terminal" && onViewCashier();
                        name === "Top up account" && onPosTopUp();
                        name === "Terminal status" &&
                          actionCtx?.setCheckShow("six");
                        name !== "Terminal status" &&
                          actionCtx?.setCheckShow("close");
                        name === "Generate Statements" && onGenerate();
                      }}
                      className="child-row-pos border-theme-bottom"
                    >
                      <div className="img-wrap-box">
                        <figure className="img-box grey-bg">
                          <img src={icon} alt="" className="img" />
                        </figure>
                      </div>
                      <div className="name-text-box">
                        <p className="name">{name}</p>
                        <p className="text grey-white-color">{text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              onClick={() => {
                actionCtx?.setCheckShow("eight");
              }}
              className="setting-box tooltip-hover-wrap"
            >
              <RavenToolTip
                color={`black-light`}
                text={`Manage Terminal`}
                position={`left`}
              />
              <figure className="img-box">
                <img src={settingIcon} alt="" className="img" />
              </figure>
            </div>
          </div>
        )}

        {/* settings box end */}
        {btnPosText && (
          <RavenButton
            label={btnPosText}
            // onClick={onBtnPosClick}
            color={`error-light`}
          />
        )}

        {exportBtn && (
          <RavenButton
            textColor="black-light"
            color="white-light"
            className="border-theme"
            onClick={onExport}
          >
            {exportText || `Export`}{" "}
            <figure className="img-box">{downloadIcon} </figure>
          </RavenButton>
        )}

        {paymenlinkSettings && (
          <div className="setting-box-wrap setting-pos-box-wrap">
            {actionCtx?.checkShow?.seven && (
              <div
                onClick={() => {
                  // actionCtx?.setCheckShow("close");
                }}
                className="drop-wrap card-bg setting-pos-box "
              >
                {checkListToUse(paymenlinkSettingStatus)?.map((chi, idx) => {
                  const { icon, name, text } = chi;
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_share_payment_links"
                    ) &&
                    name === "Share Link"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_delete_payment_links"
                    ) &&
                    name === "Delete Link"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_update_payment_links"
                    ) &&
                    name === "Edit Link"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_suspend_payment_link"
                    ) &&
                    name === "Disable Link"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_suspend_payment_link"
                    ) &&
                    name === "Enable Link"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  return (
                    <div
                      key={idx}
                      onClick={() => {
                        onLinkAction(name);
                      }}
                      className="setting-payment-link-row"
                    >
                      <div className="img-wrap">
                        <figure className="img-box">{icon}</figure>
                      </div>
                      <div className="name-text-box">
                        <p
                          style={{ color: name === "Delete Link" && "#FF0F00" }}
                          className="name"
                        >
                          {name}
                        </p>
                        <p className="text grey-white-color">{text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              onClick={() => {
                actionCtx?.setCheckShow("seven");
              }}
              className="setting-box tooltip-hover-wrap "
            >
              <RavenToolTip
                color={`black-light`}
                text={`Manage Payment Link`}
                position={`left`}
              />
              <figure className="img-box">
                <img src={settingIcon} alt="" className="img" />
              </figure>
            </div>
          </div>
        )}

        {invoiceSettings && (
          <div className="setting-box-wrap setting-pos-box-wrap">
            <div
              onClick={onInvoiceSettings}
              className="setting-box tooltip-hover-wrap "
            >
              <RavenToolTip
                color={`black-light`}
                text={`Manage Invoice`}
                position={`bottom-left`}
              />
              <figure className="img-box">
                <img src={settingIcon} alt="" className="img" />
              </figure>
            </div>
          </div>
        )}

        {showSettingsIcon && (
          <div ref={testingPreviewRefSetting} className="setting-box-wrap">
            {/* drop down wrap start */}
            {showSettings && <>{children}</>}
            {/* drop down wrap end */}
            <div
              onClick={() => {
                // actionCtx?.setCheckShow("one");
                // setShowBulkOption(false)
                setShowSettings(!showSettings);
              }}
              className="setting-box"
            >
              <figure className="img-bo">
                <img src={settingIcon} alt="" className="img" />
              </figure>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TitletextBox;
