import React, { useContext, useState } from "react";
import "../../styles/dashboard/SideMenu.css";
// import ravenLogo from "../../assets/raven-logo-full-white.svg";
// import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ActionContext from "../../context/ActionContext";
// import ravenBlackLogo from "../../assets/new-raven-black-logo-icon.svg";
// import ravenWhiteLogo from "../../assets/new-raven-white-logo-icon.svg";
import ravenWhiteNew from "../../assets/raven-white-logo-no-bank.svg";
import ravenBlackNew from "../../assets/raven-black-logo-no-bank copy.svg";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import {
  new_business_tool_side_menu_list,
  new_capital_side_menu_list,
  new_dashboard_side_menu_list,
} from "./NewSideMenuList";
import NewAccountDetailsBox from "./NewAccountDetailBox";

const NewSideMenu = ({ theme, all_account, onDropAccount }) => {
  const { user_resource_and_permissions, global_account } = useSelector(
    (state) => state.user
  );
  // eslint-disable-next-line no-unused-vars
  const [isCopied, setIsCopied] = useState(false);
  const location = useLocation();
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();

  // const handleCopied = async () => {
  //   setIsCopied(true);
  //   await navigator.clipboard.writeText(account_details?.account_number);
  //   setTimeout(() => {
  //     setIsCopied(false);
  //   }, 1000);
  // };

  // const copyIconSvg = (
  //   <svg
  //     className="img"
  //     width="10"
  //     height="10"
  //     viewBox="0 0 10 10"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M2.88215 0.5354H6.09194C7.10471 0.5354 7.61109 0.5354 7.99791 0.732497C8.33817 0.905868 8.61481 1.18251 8.78818 1.52277C8.98528 1.90959 8.98528 2.41597 8.98528 3.42873V6.63853M2.29444 8.6729H5.95632C6.4627 8.6729 6.71589 8.6729 6.9093 8.57435C7.07943 8.48767 7.21775 8.34935 7.30444 8.17922C7.40299 7.98581 7.40299 7.73261 7.40299 7.22623V3.56436C7.40299 3.05798 7.40299 2.80479 7.30444 2.61138C7.21775 2.44125 7.07943 2.30293 6.9093 2.21624C6.71589 2.11769 6.4627 2.11769 5.95632 2.11769H2.29444C1.78806 2.11769 1.53487 2.11769 1.34146 2.21624C1.17133 2.30293 1.03301 2.44125 0.946327 2.61138C0.847778 2.80479 0.847778 3.05798 0.847778 3.56436V7.22623C0.847778 7.73261 0.847778 7.98581 0.946327 8.17922C1.03301 8.34935 1.17133 8.48767 1.34146 8.57435C1.53487 8.6729 1.78806 8.6729 2.29444 8.6729Z"
  //       stroke=""
  //       strokeWidth="0.904167"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     />
  //   </svg>
  // );

  return (
    <div className="new-side-menu-wrap-box">
      {/* top info box start */}
      <div className="top-info-box border-theme-bottom white-black-bg">
        {/* logo box start */}
        <div
          onClick={() => {
            navigate("/dashboard-overview");
          }}
          className="logo-box"
        >
          <figure className="img-box">
            {/* <a
              style={{ textDecoration: "none", cursor: "pointer"  }}
              target="_blank"
              rel="noreferrer"
              href="https://getravenbank.com/raven-business"
            > */}{" "}
            <img
              src={theme === "dark" ? ravenWhiteNew : ravenBlackNew}
              alt=""
              className="img"
            />
            {/* </a> */}
          </figure>
          <p className="text black-white-color">Business</p>
        </div>
        {/* logo box end */}
        {/* account detail box start */}
        {all_account?.length > 0 && (
          <NewAccountDetailsBox onClick={onDropAccount} />
        )}
        {/* account detail box end */}
      </div>
      {/* top info box end */}
      {/* bottom info box start */}
      <div className="bottom-info-box white-black-bg">
        {/* first link box */}
        <>
          {new_dashboard_side_menu_list.map((chi, idx) => {
            const { name, link, children, icon } = chi;
            // if (
            //   !user_resource_and_permissions?.allowed_permissions?.includes(
            //     "can_initiate_transfers"
            //   ) &&
            //   name === "Send Money"
            // ) {
            //   return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            // }
            if (
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator_approver" || "initiator") &&
              name === "Send Money"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator_approver" || "approver") &&
              name === "Approval Request"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (
              !user_resource_and_permissions?.allowed_permissions?.includes(
                "can_view_transactions"
              ) &&
              name === "Transactions"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (
              !user_resource_and_permissions?.allowed_permissions?.includes(
                "can_setup_global_account"
              ) &&
              name === "Global Account"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (String(global_account) === "0" && name === "Global Account") {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (
              actionCtx?.currentAccount?.authorization_level !==
                ("initiator_approver" || "initiator") &&
              name === "Bill Payment"
            ) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            // if (
            //   !user_resource_and_permissions?.allowed_permissions?.includes(
            //     "can_view_transfers"
            //   ) &&
            //   name === "Bill Payment"
            // ) {
            //   return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            // }
            if (children && name === "Banking" && all_account?.length < 1) {
              return <React.Fragment key={`${idx}-${name}`}></React.Fragment>;
            }
            if (children && name === "Banking") {
              return (
                <React.Fragment key={`${idx}-${name}`}>
                  {/* parent link start */}
                  <div
                    key={`${idx}-${name}`}
                    className={`nav-link nav-link-solid nav-link-children  ${
                      location.pathname.includes("/dashboard-send-money") ||
                      location.pathname.includes("/dashboard-add-money") ||
                      location.pathname.includes("/dashboard-payment-request")
                        ? "nav-link-active"
                        : ""
                    }`}
                    onClick={() => {
                      actionCtx?.setSideMenuDrop("four");
                      link && navigate(link);
                    }}
                  >
                    <figure className="img-box">
                      <img
                        style={{
                          filter: location.pathname.includes(link)
                            ? `saturate(100%)`
                            : `saturate(0%)`,
                        }}
                        src={icon}
                        alt=""
                        className="img"
                      />
                    </figure>
                    <p className="name">{name}</p>
                    {!actionCtx?.sideMenuDrop?.four ? (
                      <FaAngleRight className="icon" />
                    ) : (
                      <FaAngleDown className="icon" />
                    )}
                  </div>
                  {/* parent link end */}
                  {/* children box start */}
                  <div
                    className={`children-box ${
                      actionCtx?.sideMenuDrop?.four && "children-box-show"
                    }`}
                    onClick={() => {
                      actionCtx?.setMobileSlide(false);
                    }}
                  >
                    {children.map((chi, i) => {
                      const { chi_name, chi_link } = chi;
                      return (
                        <div
                          key={`${i}-${chi_name}`}
                          className={`nav-link ${
                            location.pathname.includes(chi_link) &&
                            "nav-link-active"
                          }`}
                          onClick={() => {
                            chi_link && navigate(chi_link);
                          }}
                        >
                          <p className="name">{chi_name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* children box end */}
                </React.Fragment>
              );
            }
            if (children) {
              return (
                <React.Fragment key={`${idx}-${name}`}>
                  {/* parent link start */}
                  <div
                    key={`${idx}-${name}`}
                    className={`nav-link nav-link-children minimal-before-active-color ${
                      location.pathname.includes(link) && "nav-link-active "
                    }`}
                    onClick={() => {
                      actionCtx?.setSideMenuDrop("one");
                      link && navigate(link);
                    }}
                  >
                    <figure
                      className={`img-box ${
                        !location?.pathname?.includes(link) &&
                        "grey-nav-side-bg"
                      }`}
                    >
                      <img src={chi?.iconMinimal} alt="" className="img" />
                    </figure>
                    <p className="name">{name}</p>
                    {!actionCtx?.sideMenuDrop?.one ? (
                      <FaAngleRight className="icon" />
                    ) : (
                      <FaAngleDown className="icon" />
                    )}
                    {/* kkkkk */}
                  </div>
                  {/* parent link end */}
                  {/* children box start */}
                  <div
                    className={`children-box ${
                      actionCtx?.sideMenuDrop?.one && "children-box-show"
                    }`}
                    onClick={() => {
                      actionCtx?.setMobileSlide(false);
                    }}
                  >
                    {children.map((chi, i) => {
                      const { chi_name, chi_link } = chi;
                      return (
                        <div
                          key={`${i}-${chi_name}`}
                          className={`nav-link ${
                            location.pathname.includes(chi_link) &&
                            "nav-link-active"
                          }`}
                          onClick={() => {
                            chi_link && navigate(chi_link);
                          }}
                        >
                          <p className="name">{chi_name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* children box end */}
                </React.Fragment>
              );
            }
            return (
              <div
                key={`${idx}-${name}`}
                className={`nav-link minimal-before-active-color ${
                  location.pathname.includes(link) && "nav-link-active"
                }`}
                onClick={() => {
                  //   actionCtx?.setSideMenuDrop("close");
                  link && navigate(link);
                  actionCtx?.setMobileSlide(false);
                }}
              >
                <figure
                  className={`img-box ${
                    !location?.pathname?.includes(link) && "grey-nav-side-bg"
                  }`}
                >
                  <img
                    // style={{
                    //   filter: location.pathname.includes(link)
                    //     ? `brightness(100%)`
                    //     : `brightness(0%)`,
                    // }}
                    src={chi?.iconMinimal}
                    alt=""
                    className="img"
                  />
                </figure>
                <p className="name">{name}</p>
              </div>
            );
          })}
        </>
        {/* first link box end */}
        {/* second link box */}
        <>
          {new_business_tool_side_menu_list.map((chi, idx) => {
            const { name, link, children } = chi;
            if (children) {
              return (
                <React.Fragment key={`${idx}-${name}`}>
                  {/* parent link start */}
                  <div
                    key={`${idx}-${name}`}
                    className={`nav-link nav-link-children minimal-before-active-color  ${
                      location.pathname.includes("/dashboard-payroll") ||
                      location.pathname.includes("/dashboard-payment-links") ||
                      location.pathname.includes("/dashboard-pos") ||
                      location.pathname.includes("/dashboard-store") ||
                      location.pathname.includes("/dashboard-invoice")
                        ? "nav-link-active"
                        : ""
                    }`}
                    onClick={() => {
                      actionCtx?.setSideMenuDrop("two");
                      link && navigate(link);
                    }}
                  >
                    <figure
                      className={`img-box ${
                        !location?.pathname?.includes(link) &&
                        "grey-nav-side-bg"
                      }`}
                    >
                      <img
                        // style={{
                        //   filter: location.pathname.includes(link)
                        //     ? `saturate(100%)`
                        //     : `saturate(0%)`,
                        // }}
                        src={chi?.iconMinimal}
                        alt=""
                        className="img"
                      />
                    </figure>
                    <p className="name">{name}</p>
                    {!actionCtx?.sideMenuDrop?.two ? (
                      <FaAngleRight className="icon" />
                    ) : (
                      <FaAngleDown className="icon" />
                    )}
                  </div>
                  {/* parent link end */}
                  {/* children box start */}
                  <div
                    className={`children-box ${
                      actionCtx?.sideMenuDrop?.two && "children-box-show"
                    }`}
                    onClick={() => {
                      actionCtx?.setMobileSlide(false);
                    }}
                  >
                    {children.map((chi, i) => {
                      const { chi_name, chi_link } = chi;
                      if (
                        !user_resource_and_permissions?.allowed_permissions?.includes(
                          "can_view_terminals"
                        ) &&
                        chi_name === "Invoices"
                      ) {
                        return (
                          <React.Fragment
                            key={`${idx}-${name}`}
                          ></React.Fragment>
                        );
                      }
                      return (
                        <div
                          style={{ marginTop: i === 0 && "1rem" }}
                          key={`${i}-${chi_name}`}
                          className={`nav-link ${
                            location.pathname.includes(chi_link) &&
                            "nav-link-active"
                          }`}
                          onClick={() => {
                            chi_link && navigate(chi_link);
                          }}
                        >
                          <p className="name">{chi_name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* children box end */}
                </React.Fragment>
              );
            }
            return (
              <div
                key={`${idx}-${name}`}
                className={`nav-link minimal-before-active-color ${
                  location.pathname.includes(link) && "nav-link-active"
                }`}
                onClick={() => {
                  //   actionCtx?.setSideMenuDrop("close");
                  link && navigate(link);
                  actionCtx?.setMobileSlide(false);
                }}
                style={{ marginTop: idx === 0 && "1rem" }}
              >
                <figure
                  className={`img-box ${
                    !location?.pathname?.includes(link) && "grey-nav-side-bg"
                  }`}
                >
                  <img
                    // style={{
                    //   filter: location.pathname.includes(link)
                    //     ? `saturate(100%)`
                    //     : `saturate(0%)`,
                    // }}
                    src={chi?.iconMinimal}
                    alt=""
                    className="img"
                  />
                </figure>
                <p className="name">{name}</p>
              </div>
            );
          })}
        </>
        {/* second link box end */}
        {/* third link box */}
        <>
          {new_capital_side_menu_list.map((chi, idx) => {
            const { name, link, children } = chi;
            if (children) {
              return (
                <React.Fragment key={`${idx}-${name}`}>
                  {/* parent link start */}
                  <div
                    key={`${idx}-${name}`}
                    className={`nav-link nav-link-children  minimal-before-active-color
                    ${
                      location.pathname.includes("/dasboard-finance-invoice") ||
                      location.pathname.includes("/dasboard-loan") ||
                      location.pathname.includes("/dasboard-overdraft")
                        ? "nav-link-active"
                        : ""
                    }
                    `}
                    onClick={() => {
                      actionCtx?.setSideMenuDrop("three");
                      link && navigate(link);
                    }}
                  >
                    <figure
                      className={`img-box ${
                        !location?.pathname?.includes(link) &&
                        "grey-nav-side-bg"
                      }`}
                    >
                      <img
                        // style={{
                        //   filter: location.pathname.includes(link)
                        //     ? `saturate(100%)`
                        //     : `saturate(0%)`,
                        // }}
                        src={chi?.iconMinimal}
                        alt=""
                        className="img"
                      />
                    </figure>
                    <p className="name">{name}</p>
                    {!actionCtx?.sideMenuDrop?.three ? (
                      <FaAngleRight className="icon" />
                    ) : (
                      <FaAngleDown className="icon" />
                    )}
                  </div>
                  {/* parent link end */}
                  {/* children box start */}
                  <div
                    className={`children-box ${
                      actionCtx?.sideMenuDrop?.three && "children-box-show"
                    }`}
                    onClick={() => {
                      actionCtx?.setMobileSlide(false);
                    }}
                  >
                    {children.map((chi, i) => {
                      const { chi_name, chi_link } = chi;
                      return (
                        <div
                          key={`${i}-${chi_name}`}
                          className={`nav-link ${
                            location.pathname.includes(chi_link) &&
                            "nav-link-active"
                          }`}
                          onClick={() => {
                            chi_link && navigate(chi_link);
                          }}
                        >
                          <p className="name">{chi_name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* children box end */}
                </React.Fragment>
              );
            }
            return (
              <div
                key={`${idx}-${name}`}
                className={`nav-link minimal-before-active-color ${
                  location.pathname.includes(link) && "nav-link-active"
                }`}
                onClick={() => {
                  //   actionCtx?.setSideMenuDrop("close");
                  link && navigate(link);
                  actionCtx?.setMobileSlide(false);
                }}
              >
                <figure
                  className={`img-box ${
                    !location?.pathname?.includes(link) && "grey-nav-side-bg"
                  }`}
                >
                  <img
                    // style={{
                    //   filter: location.pathname.includes(link)
                    //     ? `saturate(100%)`
                    //     : `saturate(0%)`,
                    // }}
                    src={chi?.iconMinimal}
                    alt=""
                    className="img"
                  />
                </figure>
                <p className="name">{name}</p>
              </div>
            );
          })}
        </>
        {/* third link box end */}
      </div>
      {/* bottom info box end */}
    </div>
  );
};

export default NewSideMenu;
