import React, { useEffect } from "react";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/setting/DashboardSettings.css";
import { Link } from "react-scroll";
// import { useLocation } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const DashboardSettingsLayout = ({ theme, toggleTheme, children }) => {
  const { directors } = useSelector((state) => state.settings);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  // const [singleChild, setSingleChild] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const [showModal, setShowModal] = useState({
  //   team_invite: false,
  //   team_delete: false,
  //   device_delete: false,
  //   statement: false,
  //   electricity: false,
  //   view_directors: false,
  //   delete_sub_account: false,
  //   change_primary: false,
  //   remove_role_account: false,
  //   add_role_account: false,
  // });
  const selectList = [
    { name: "Notifications", link: "/dashboard-settings-notification" },
    { name: "Verification", link: "/dashboard-settings-verification" },
    { name: "Security", link: "/dashboard-settings-security" },
    { name: "Teams", link: "/dashboard-settings-team" },
    { name: "Beneficiary Management", link: "/dashboard-settings-beneficiary" },
    { name: "Roles & Permission", link: "/dashboard-settings-role-permission" },
    { name: "Sub Account", link: "/dashboard-settings-sub-account" },
    { name: "Directors", link: "/dashboard-settings-directors" },
    { name: "Statement", link: "/dashboard-settings-statement" },
    { name: "Device Management", link: "/dashboard-settings-device-management" },
    { name: "Appearance", link: "/dashboard-settings-appearance" },
  ];

  // const [selectedSettings, setSelectedSettings] = useState("Notifications");
  // const location = useLocation();


  useEffect(() => {
    // dispatch(getSubAccountCreateDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   dispatch(getTeamRoles());
  //   dispatch(getSubAccountCreateDetails());
  //   const path = window.location.href
  //     ?.split("?settingsPath=")[1]
  //     ?.replace("%20", " ");
  //   const fiePath = path?.replace("#Notifications", "");
  //   // console.log(fiePath);
  //   // console.log(path);
  //   if (fiePath) {
  //     if (path === "Roles_Permission#Notifications") {
  //       setSelectedSettings("Roles & Permission");
  //       return;
  //     }
  //     setSelectedSettings(fiePath);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const filterOutList = list

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Settings`}
          text={`Configure your profile to fit your taste.`}
        />
        <ContainerWrapper
          width={`100%`}
          className={`cointainer-wrapper`}
          dontShowHeader
        >
          <div
            className="dashboard-settings-wrap-box"
            id={`coitainer-scroll-box`}
          >
            {/* left box start */}
            <div className="left-box">
              <div className="wrap grey-bg">
                {selectList.map((chi, idx) => {
                  const { name, link } = chi;
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_view_account"
                    ) &&
                    name === "Sub Account"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_download_bank_statements"
                    ) &&
                    name === "Statement"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_view_roles"
                    ) &&
                    name === "Roles  & Permission"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (directors?.length < 1 && name === "Directors") {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }

                  return (
                    <Link
                      containerId="coitainer-scroll-box"
                      to={link}
                      spy={true}
                      smooth={true}
                      hashSpy={true}
                      offset={-100}
                      duration={700}
                      isDynamic={true}
                      ignoreCancelEvents={false}
                      spyThrottle={500}
                      key={idx}
                      // activeClass="text-active"
                    >
                      <div
                        onClick={() => {
                          link && navigate(link);
                        }}
                        className={`text ${
                          location.pathname.includes(link) &&
                          "text-active test-active-settings white-black-bg"
                        }`}
                      >
                        {" "}
                        <div className="check-box">
                          <FaCheck className="icon" />
                        </div>{" "}
                        {name}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
            {/* left box end */}
            {/* right box start */}
            <div className="right-box">{children}</div>
            {/* right box end */}
          </div>
        </ContainerWrapper>
      </DashboardLayout>

    </>
  );
};

export default DashboardSettingsLayout;
