import React, { useContext, useEffect, useState } from "react";
import TitletextBox from "../../../components/reusables/TitletextBox";
// import { useContext } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import { RavenButton, RavenInputField, RavenToolTip, toast } from "@ravenpay/raven-bank-ui";
// import { RavenButton } from "@ravenpay/raven-bank-ui";
import { invoiceIcon } from "../../../assets/icon/icons";
import moment from "moment";
import {
  capitalizeFirstLetter,
  formatDate,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  removeLeadingZeroFromString,
  sumAllNum,
  trimLongString,
} from "../../../utils/helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import {
  //   createNewInvoice,
  getInvoice,
  // getInvoiceSettings,
  updateNewInvoice,
} from "../../../redux/invoice";
import SuccessModal from "../../../components/reusables/SuccessModal";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../../utils/helper/ValidateInput";
import ActionContext from "../../../context/ActionContext";
import ChangeSourceAccount from "../../../components/reusables/ChangeSourceAccount";
import useWindowSize from "../../../utils/helper/UseWindowSize";
require("../../../styles/dashboard/invoice/new_invoice.css");
require("@ravenpay/raven-bank-ui/dist/esm/styles/index.css");

function EditInvoicePage() {
  const dispatch = useDispatch();
  const { loading, invoiceSetting } = useSelector((state) => state.invoice);
  const navigate = useNavigate();
  const next = () => {
    setStage(stage + 1);
  };
  const [showModal, setShowModal] = useState({
    success: false,
  });
  const size = useWindowSize()
  const { business,  all_account } = useSelector((state) => state.user);
  const [option, setOption] = useState(false);
  const [stage, setStage] = useState(1);
  const [item, setItem] = useState({
    name: "",
    quantity: "",
    price: "",
  });
  const [itemArr, setItemArr] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    startDate: "",
    endDate: "",
    note: "",
    cust_fullname: "",
    cust_email_address: "",
    cust_phone_no: "",
    discount: 0,
    vat: 0,
    shipping: "",
    discount_val: "",
    vat_val: "",
    totalPrice: "",
    totalSum: "",
  });
  const actionCtx = useContext(ActionContext)

  // useEffect(() => {
  //   dispatch(getInvoiceSettings());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const [subAccount, setSubAccount] = useState({});
  useEffect(() => {
    getSingleDetail();
    invoiceSetting?.selected_sub_account
    ? setSubAccount(invoiceSetting?.selected_sub_account)
    : setSubAccount(actionCtx?.currentAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleDetail = async () => {
    const id = localStorage.getItem("invoice-single-id");
    if (!id) {
      navigate("/dashboard-invoice");
      return;
    }
    const obj = {
      id: id,
    };
    if (id) {
      const data = await dispatch(getInvoice(obj));
      //   setLoading(true);
      if (data?.payload?.data?.status === "success") {
        // setLoading(false);
        const obj = data?.payload?.data?.data;
        // console.log(obj);
        if (obj?.items?.length < 2) {
          setItemArr([
            {
              name: obj?.items[0]?.product,
              quantity: obj?.items[0]?.quantity,
              price: obj?.items[0]?.unit_price,
              total_price: obj?.items[0]?.unit_price * obj?.items[0]?.quantity,
              id: obj?.items[0]?.id,
            },
          ]);
        }
        if (obj?.items?.length > 1) {
          const newArr = obj?.items?.map((chi) => {
            return {
              name: chi?.product,
              quantity: chi?.quantity,
              price: chi?.unit_price,
              total_price: chi?.unit_price * chi?.quantity,
              id: chi?.id,
            };
          });
          setItemArr(newArr);
        }
        setFormData((prev) => {
          return {
            ...prev,
            title: obj?.title,
            startDate: moment(obj?.created_at).format("YYYY-MM-DD"),
            endDate: moment(obj?.due_date).format("YYYY-MM-DD"),
            note: obj?.note,
            cust_fullname: obj?.customer_name,
            cust_email_address: obj?.customer_email,
            cust_phone_no: obj?.customer_phone,
            discount: obj?.discount,
            vat: obj?.tax,
            shipping: obj?.shipping_fee,
            id: obj?.id,
            discount_val: "",
            vat_val: "",
            totalPrice: "",
            totalSum: "",
            item_id: obj?.items[0]?.id,
          };
        });
        setTimeout(() => {
          calculatePreviewData();
        }, 1000);
      } else {
        // setLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   if (Object?.keys(invoiceSetting).length > 0) {
  //     // console.log(invoiceSetting);
  //     setFormData((prev) => {
  //       return {
  //         ...prev,
  //         discount: invoiceSetting?.discount,
  //         vat: invoiceSetting?.tax,
  //         shipping: invoiceSetting?.shipping_fee,
  //       };
  //     });
  //   }
  //   if (Object?.keys(invoiceSetting).length < 1) {
  //     setFormData((prev) => {
  //       return {
  //         ...prev,
  //         discount: 0,
  //         vat: 0,
  //         shipping: 0,
  //       };
  //     });
  //   }
  // }, [invoiceSetting]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...formData, [name]: value };
    setFormData(obj);
  };

  const handleChangeItem = (e) => {
    const { name, value } = e.target;
    const obj = { ...item, [name]: value };
    // console.log(obj);
    setItem(obj);
  };

  function addObjectToArray(newObject, array) {
    const index = array.findIndex((obj) => obj.id === newObject.id);
    if (index !== -1) {
      array[index] = newObject; // replace existing object
    } else {
      array.unshift(newObject); // add new object to the beginning of the array
    }
    return array;
  }

  const onItemAdd = () => {
    const uniqueId =
      Date.now().toString(36) + Math.random().toString(36).substr(2);
    const newObj = {
      ...item,
      id: item?.id ? item?.id : uniqueId,
      total_price: Math.round(
        Number(formatNumWithoutCommaNaira(item?.price)) *
          Number(formatNumWithoutCommaNaira(item?.quantity))
      ),
      removeId: true,
    };
    const newList =
      itemArr?.length > 0
        ? addObjectToArray(newObj, itemArr)
        : [...itemArr, newObj];
    setItemArr(newList);
    setItem((prev) => {
      return { ...prev, name: "", quantity: "", price: "" };
    });

    editState && setEditState(false);
  };

  useEffect(() => {
    calculatePreviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.discount, formData?.vat, item?.price, item?.quantity]);

  const calculatePreviewData = () => {
    if (itemArr?.length > 0) {
      const totalList = itemArr.map((chi) =>
        Number(formatNumWithoutCommaNaira(chi?.total_price))
      );
      const sumTotal = sumAllNum(totalList);
      const discountPrice =
        Number(formData?.discount) > 0
          ? Math.round((Number(formData?.discount) * sumTotal) / 100)
          : 0;
      const taxPrice =
        Number(formData?.vat) > 0
          ? Math.round((Number(formData?.vat) * sumTotal) / 100)
          : 0;
      const finalValue = Math.round(sumTotal + +taxPrice - discountPrice);
      // console.log(itemArr);
      // console.log(itemArr);
      // console.log(totalList);
      // console.log(sumTotal);
      // console.log(taxPrice);
      // console.log(discountPrice);
      setFormData((prev) => {
        return {
          ...prev,
          totalPrice: finalValue,
          discount_val: discountPrice,
          totalSum: sumTotal,
          vat_val: taxPrice,
        };
      });
    } else {
      const discountPrice =
        Number(formData?.discount) > 0
          ? Math.round(
              (Number(formData?.discount) *
                Number(
                  formatNumWithoutCommaNaira(item?.price) *
                    Number(item?.quantity)
                )) /
                100
            )
          : 0;
      const taxPrice =
        Number(formData?.vat) > 0
          ? Math.round(
              (Number(formData?.vat) *
                Number(
                  formatNumWithoutCommaNaira(item?.price) *
                    Number(item?.quantity)
                )) /
                100
            )
          : 0;
      const finalValue = Math.round(
        Number(
          formatNumWithoutCommaNaira(item?.price) * Number(item?.quantity)
        ) +
          taxPrice -
          discountPrice
      );
      // console.log(itemArr);
      // if (item?.name && item?.price && item?.quantity) {
      //   setItemArr([
      //     {
      //       ...item,
      //       total_price: Math.round(
      //         Number(formatNumWithoutCommaNaira(item?.price)) *
      //           Number(formatNumWithoutCommaNaira(item?.quantity))
      //       ),
      //     },
      //   ]);
      // }
      setFormData((prev) => {
        return {
          ...prev,
          totalPrice: finalValue,
          discount_val: discountPrice,
          totalSum: Number(
            formatNumWithoutCommaNaira(item?.price) * Number(item?.quantity)
          ),
          vat_val: taxPrice,
        };
      });
    }
  };

  const handleCreate = async () => {
    const newArry = itemArr?.map((chi) => {
      let checkRemoveList;
      if (chi?.removeId) {
        const obj = {
          unit_price: formatNumWithoutCommaNaira(chi?.price),
          quantity: chi?.quantity,
          product: chi?.name,
          product_name: chi?.name,
          type: "1",
        };
        checkRemoveList = obj;
      } else {
        const obj = {
          unit_price: formatNumWithoutCommaNaira(chi?.price),
          quantity: chi?.quantity,
          product: chi?.name,
          product_name: chi?.name,
          type: "1",
          id: chi?.id,
        };
        checkRemoveList = obj;
      }
      return checkRemoveList;
    });

    const obj = {
      title: formData?.title,
      customer_email: formData?.cust_email_address,
      customer_name: formData?.cust_fullname,
      start_date: formData?.startDate,
      due_date: formData?.endDate,
      discount: formData?.discount,
      tax: formData?.vat,
      customer_phone: `0${formatNumWithoutCommaNaira(
        removeLeadingZeroFromString(formData?.cust_phone_no)
      )}`,
      items:
        itemArr?.length > 0
          ? newArry
          : [
              {
                unit_price: formatNumWithoutCommaNaira(item?.price),
                quantity: item?.quantity,
                product: item?.name,
                product_name: item?.name,
                type: "1",
                id: formData?.item_id,
              },
            ],
      shipping_fee: formatNumWithoutCommaNaira(formData?.shipping),
      note: formData?.note,
      id: Number(formData?.id),
      selected_account_number: subAccount?.account_number,
      account_number: subAccount?.account_number,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(updateNewInvoice(obj));
    if (data?.payload?.data?.status === "success") {
      //   console.log(data);
      handleFinish();
    }
  };

  const handleFinish = () => {
    setFormData({
      title: "",
      startDate: "",
      endDate: "",
      note: "",
      cust_fullname: "",
      cust_email_address: "",
      cust_phone_no: "",
      discount: invoiceSetting?.discount,
      vat: invoiceSetting?.vat,
      shipping: invoiceSetting?.shipping_fee,
      discount_val: "",
      vat_val: "",
      totalPrice: "",
      totalSum: "",
      shipping_fee: "",
    });
    setItem({
      name: "",
      quantity: "",
      price: "",
    });
    setStage(1);
    setItemArr([]);
    navigate("/dashboard-invoice");
  };

  //delete items from array
  const handleRemoveFromList = (i) => {
    const newList = itemArr?.filter((chi) => chi?.id !== i);
    setItemArr(newList);
  };

  const stepList = [
    "Invoice details",
    "Customer details",
    "Add products",
    "Preview invoice",
  ];

  const [showError, setShowError] = useState({
    num: false,
    email: false,
    amount: false,
    meter: false,
  });

  const editIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 18.0002L19.9999 19.0943C19.4695 19.6744 18.7501 20.0002 18.0001 20.0002C17.2501 20.0002 16.5308 19.6744 16.0004 19.0943C15.4692 18.5154 14.75 18.1903 14.0002 18.1903C13.2504 18.1903 12.5311 18.5154 12 19.0943M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
    </svg>
  );

  const deleteIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167"
        stroke="#FF0F00"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Edit Invoice`}
          back={true}
          onBack={() => navigate(-1)}
          text={`Edit an invoice to share for later.`}
          onAdd={() => navigate("/dashboard-invoices-new")}
        ></TitletextBox>
        <ContainerWrapper
          loading={loading}
          width="100%"
          // className="mt-16"
          disabled={
            stage === 1
              ? !formData.title ||
                !formData.note ||
                !formData?.startDate ||
                !formData?.endDate
              : stage === 2
              ? !formData.cust_fullname ||
                !formData.cust_email_address ||
                !formData.cust_phone_no
              : stage === 3
              ? itemArr.length > 0
                ? itemArr?.length < 1
                : !item.name || !item.quantity || !item.price
              : false
          }
          stepClickable={
            formData.title &&
            formData.note &&
            formData?.startDate &&
            formData?.endDate &&
            formData.cust_fullname &&
            formData.cust_email_address &&
            formData.cust_phone_no &&
            itemArr.length > 0
              ? itemArr?.length > 0
              : item.name && item.quantity && item.price
          }
          onStepClick={(e) => {
            // eslint-disable-next-line no-unused-expressions
            calculatePreviewData();
            itemArr.length > 0
              ? itemArr?.length > 0 &&
                formData.title &&
                formData.note &&
                formData?.startDate &&
                formData?.endDate &&
                formData.cust_fullname &&
                formData.cust_email_address &&
                formData.cust_phone_no &&
                setStage(e)
              : item.name &&
                item.quantity &&
                item.price &&
                formData.title &&
                formData.note &&
                formData?.startDate &&
                formData?.endDate &&
                formData.cust_fullname &&
                formData.cust_email_address &&
                formData.cust_phone_no &&
                setStage(e);
          }}
          back={stage > 1}
          onBack={() => {
            stage > 1 && setStage(stage - 1);
          }}
          onBtnClick={() => {
            if (stage === 2) {
              if (!validateEmail(formData?.cust_email_address)) {
                toast.error("Invalid email address!", {
                  position: "top-right",
                });
                setShowError((prev) => {
                  return { ...prev, email: true };
                });
                return;
              }
            }
            if (item?.name && item?.price && item?.quantity) {
              onItemAdd();
            }
            stage < 4 ? next() : handleCreate();
            stage === 3 && calculatePreviewData();
          }}
          btnText={ stage === 4 && all_account?.length > 1
            ? false
            : stage === 4 && all_account?.length < 2 ? "Update & save invoice" :
            stage === 1
              ? "Next customer detail"
              : stage === 2
              ? "Next add product"
              : stage === 3
              ? "Preview Invoice"
              : "Update & save invoice"
          }
          activeStep={stage}
          stepList={stepList}
        >
          <div className="container_content_wrapper">
            <div className="content_contain">
              {stage === 1 && (
                <React.Fragment>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Title*"}
                      color={`black-light`}
                      labelColor={`purple-light`}
                      type={`text`}
                      name={`title`}
                      placeholder={`Invoice title.`}
                      onChange={handleChange}
                      value={capitalizeFirstLetter(formData?.title)}
                      required={true}
                    />
                  </form>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Start Date*"}
                      color={`black-light`}
                      placeholder={`Select a start date`}
                      type={`date`}
                      name="startDate"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          startDate: moment(e[0]).format("YYYY-MM-DD"),
                        });
                      }}
                      value={formData?.startDate}
                      required={true}
                    />
                  </form>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"End Date*"}
                      color={`black-light`}
                      placeholder={`Select a end date`}
                      type={`date`}
                      name="endDate"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          endDate: moment(e[0]).format("YYYY-MM-DD"),
                        });
                      }}
                      value={formData?.endDate}
                      required={true}
                    />
                  </form>

                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Add a note*"}
                      color={`black-light`}
                      placeholder={`Write your thoughts here... `}
                      type={`textarea`}
                      name="note"
                      textareaRow={5}
                      onChange={handleChange}
                      value={formData?.note}
                      required={true}
                    />
                  </form>
                </React.Fragment>
              )}

              {stage === 2 && (
                <React.Fragment>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Customer full name*"}
                      color={`black-light`}
                      placeholder={`e.g Timi Ayeni`}
                      labelColor={`purple-light`}
                      type={`text`}
                      name={`cust_fullname`}
                      onChange={handleChange}
                      value={capitalizeFirstLetter(formData?.cust_fullname)}
                      required={true}
                    />
                  </form>

                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Customer email address*"}
                      color={`black-light`}
                      placeholder={`e.g timiAyeni@gmail.com`}
                      name={`cust_email_address`}
                      type={`email`}
                      showError={showError?.email}
                      onChange={(e) => {
                        setShowError((prev) => {
                          return { ...prev, email: false };
                        });
                        handleChange(e);
                      }}
                      value={formData?.cust_email_address}
                    />
                  </form>

                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      id="jf6"
                      label={"Customer phone number* "}
                      color={`black-light`}
                      placeholder={`e.g 9064xxxxxx`}
                      type={`phone`}
                      name={`cust_phone_no`}
                      onChange={handleChange}
                      value={
                        formData?.cust_phone_no.slice(0, 3) === "234"
                          ? formData?.cust_phone_no.replace("234", "0")
                          : formData?.cust_phone_no.slice(0, 1) === "0"
                          ? formData?.cust_phone_no.replace("0", "")
                          : formData?.cust_phone_no
                      }
                      // required={true}
                    />
                  </form>
                </React.Fragment>
              )}

              {stage === 3 && (
                <React.Fragment>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Item name*"}
                      color={`black-light`}
                      placeholder={`e.g Yoga candle`}
                      labelColor={`purple-light`}
                      type={`text`}
                      name="name"
                      onChange={handleChangeItem}
                      value={item?.name}
                      required={true}
                    />
                  </form>

                  <div className="grouped_input wp-100 flex flex-row">
                    <div className="quantity_contain">
                      <form
                        action=""
                        autoComplete="off"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        <RavenInputField
                          style={{ width: "100%" }}
                          placeholder={"0"}
                          numberUp={() => {
                            setItem((prev) => {
                              return {
                                ...prev,
                                quantity: Number(item?.quantity) + 1,
                              };
                            });
                          }}
                          // className={`wp-30`}
                          numberSpinner
                          numberDown={() => {
                            item?.quantity >= 1 &&
                              setItem((prev) => {
                                return {
                                  ...prev,
                                  quantity: Number(item?.quantity) - 1,
                                };
                              });
                          }}
                          label={"Item quantity* "}
                          color={`black-light`}
                          name={`quantity`}
                          type={`number`}
                          onChange={handleChangeItem}
                          value={item?.quantity <= -1 ? 0 : item?.quantity}
                          required={true}
                        />
                      </form>
                    </div>
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      <RavenInputField
                        // style={{ width: "70%" }}
                        label={"Price per quantity*"}
                        type={`number`}
                        color={`black-light`}
                        name={`price`}
                        value={item?.price}
                        onChange={handleChangeItem}
                        placeholder={`How much is this item?`}
                        thousandFormat
                        numberPrefix={`₦`}
                      />
                    </form>
                  </div>

                  {item?.quantity && item?.price && item?.name && (
                    <div
                      onClick={() => {
                        if (item?.name && item?.price && item.quantity) {
                          onItemAdd();
                        }
                      }}
                      className="add_another_item"
                      style={{
                        opacity:
                          item?.name && item?.price && item.quantity
                            ? "1"
                            : ".5",
                        cursor:
                          item?.name && item?.price && item.quantity
                            ? "pointer"
                            : "unset",
                      }}
                    >
                      <figure>{invoiceIcon.add}</figure>
                      {editState ? (
                        <span>Update item</span>
                      ) : (
                        <span>
                          {item?.quantity && item?.price && item?.name
                            ? `Add item`
                            : `Add another item`}
                        </span>
                      )}
                    </div>
                  )}

                  <div className="items_break">
                    <p />
                    <span>Items</span>
                    <p />
                  </div>

                  {itemArr.length < 1 && (
                    <div className="no_items_wrapper grey-bg">
                      <div>
                        <figure>{invoiceIcon.noInvoice}</figure>
                        <span className="grey-white-color-white">
                          Added items would show here
                        </span>
                      </div>
                    </div>
                  )}
                  {itemArr.length > 0 && (
                    <React.Fragment>
                      {/* view all box wrapper end */}
                      <div className="top_indicator new-invoice-top-indicator flex justify-between align-center wp-99">
                        <span className="font-400 text-14">
                          Total Added ({itemArr.length})
                        </span>
                        <span
                          onClick={() => {
                            setViewAll(!viewAll);
                          }}
                          className="font-600 view-text text-14 "
                        >
                          {viewAll ? `Close` : ` View All`}
                        </span>
                      </div>

                      <div className="items_wrapper">
                        {/* view all box wrapper start */}
                        {/* view all box start */}
                        <div
                          className={`view-all-box card-bg ${
                            viewAll && "view-all-box-show"
                          }`}
                        >
                          <div className="wrap">
                            {itemArr?.map((chi, idx) => {
                              return (
                                <div key={idx} className="child-row grey-bg">
                                  <div className="detail-wrap">
                                    <p className="font-600 name text-14">{`${chi.quantity} x ${chi.name}`}</p>
                                    <p className="grey-white-color price">{`${chi.quantity} x ${chi.price}`}</p>
                                  </div>
                                  <div className="two-icon-box">
                                    {chi?.id === item?.id && editState ? (
                                      <>
                                        <p className="grey-white-color">
                                          Editing
                                        </p>
                                        <figure
                                          onClick={() => {
                                            setEditState(false);
                                            setItem({
                                              name: "",
                                              quantity: "",
                                              price: "",
                                            });
                                            setViewAll(false)
                                          }}
                                          className="img-box tooltip-hover-wrap"
                                        >
                                          <RavenToolTip
                                            color={`black-light`}
                                            text={`Cancel Edit`}
                                            position={`bottom-left`}
                                          />
                                          {invoiceIcon.cancel}
                                        </figure>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <figure
                                          onClick={() => {
                                            // console.log(itemArr);
                                            setEditState(true);
                                            setViewAll(false);
                                            setItem((prev) => {
                                              return {
                                                ...prev,
                                                ...chi,
                                                name: chi?.name,
                                              };
                                            });
                                          }}
                                          className="img-box tooltip-hover-wrap"
                                        >
                                          {editIcon}
                                          <RavenToolTip
                                            color={`black-light`}
                                            text={`Edit item ${trimLongString(
                                              chi?.name,
                                              10
                                            )}`}
                                            position={`left`}
                                          />
                                        </figure>
                                        <figure
                                          onClick={() => {
                                            handleRemoveFromList(chi?.id);
                                          }}
                                          className="img-box tooltip-hover-wrap"
                                        >
                                          {deleteIcon}
                                          <RavenToolTip
                                            color={`black-light`}
                                            text={`Delete item ${trimLongString(
                                              chi?.name,
                                              10
                                            )}`}
                                            position={`bottom-left`}
                                          />
                                        </figure>
                                      </>
                                    )}
                                  </div>
                                  {/* <figure
                                    onClick={() => {
                                      // console.log(chi?.id);
                                      handleRemoveFromList(chi?.id);
                                    }}
                                  >
                                    {invoiceIcon.cancel}
                                  </figure> */}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* view all box end */}
                      </div>

                      {/* preview box start */}
                      <div className="invoice-create-preview-box">
                        {itemArr?.slice(0, 2)?.map((chi, idx) => {
                          return (
                            <div key={idx} className="child-row grey-bg">
                              <div className="detail-wrap">
                                <p className="font-600 name text-14">{`${chi.quantity} x ${chi.name}`}</p>
                                <p className="grey-white-color price">{`${chi.quantity} x ${chi.price}`}</p>
                              </div>
                              <div className="two-icon-box">
                                {chi?.id === item?.id && editState ? (
                                  <>
                                    <p className="grey-white-color">Editing</p>
                                    <figure
                                      onClick={() => {
                                        setEditState(false);
                                        setItem({
                                          name: "",
                                          quantity: "",
                                          price: "",
                                        });
                                      }}
                                      className="img-box tooltip-hover-wrap"
                                    >
                                      <RavenToolTip
                                        color={`black-light`}
                                        text={`Cancel Edit`}
                                        position={`top-left`}
                                      />
                                      {invoiceIcon.cancel}
                                    </figure>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <figure
                                      onClick={() => {
                                        // console.log(itemArr);
                                        setEditState(true);
                                        setItem((prev) => {
                                          return {
                                            ...prev,
                                            ...chi,
                                            name: chi?.name,
                                          };
                                        });
                                      }}
                                      className="img-box tooltip-hover-wrap"
                                    >
                                      {editIcon}
                                      <RavenToolTip
                                        color={`black-light`}
                                        text={`Edit item ${trimLongString(
                                          chi?.name,
                                          10
                                        )}`}
                                        position={`left`}
                                      />
                                    </figure>
                                    <figure
                                      onClick={() => {
                                        handleRemoveFromList(chi?.id);
                                      }}
                                      className="img-box tooltip-hover-wrap"
                                    >
                                      {deleteIcon}
                                      <RavenToolTip
                                        color={`black-light`}
                                        text={`Delete item ${trimLongString(
                                          chi?.name,
                                          10
                                        )}`}
                                        position={`top-left`}
                                      />
                                    </figure>
                                  </>
                                )}
                              </div>
                              {/* <figure
                                onClick={() => {
                                  // console.log(chi?.id);
                                  handleRemoveFromList(chi?.id);
                                }}
                              >
                                {invoiceIcon.cancel}
                              </figure> */}
                            </div>
                          );
                        })}
                      </div>
                      {/* preview box start */}
                    </React.Fragment>
                  )}

                  <div
                    onClick={() => setOption(!option)}
                    className="add_another_item"
                  >
                    <figure>
                      {option ? invoiceIcon.minus : invoiceIcon.add}
                    </figure>
                    <span>
                      {option
                        ? "Hide additional option"
                        : "Show additional options"}
                    </span>
                  </div>
                  <div
                    className={`additional_option ${option ? "show" : "hide"}`}
                  >
                    <RavenInputField
                      label={"Add a discount"}
                      showValue
                      type="range"
                      onChange={(e) => {
                        setFormData((prev) => {
                          return { ...prev, discount: e.target.value };
                        });
                      }}
                      range={formData?.discount}
                      additionalValue={
                        formData?.discount
                          ? `  (${formatNumWithCommaNairaSymbol(
                              formData?.discount_val || "0"
                            )}) `
                          : ""
                      }
                    />

                    <RavenInputField
                      additionalValue={
                        formData?.vat
                          ? ` (${formatNumWithCommaNairaSymbol(
                              formData?.vat_val || "0"
                            )})`
                          : ""
                      }
                      range={formData?.vat}
                      onChange={(e) => {
                        setFormData((prev) => {
                          return { ...prev, vat: e.target.value };
                        });
                      }}
                      label={"V.A.T"}
                      showValue
                      type="range"
                    />

                    <RavenInputField
                      label={"Shipping fee*"}
                      placeholder="e.g NGN 2,000"
                      type={`number`}
                      color={`black-light`}
                      labelClassName={"range-label"}
                      name={`shipping`}
                      value={formData?.shipping}
                      onChange={handleChange}
                      thousandFormat
                      numberPrefix={`₦`}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>

            {stage === 4 && (
              <>
                <div className="invoice-step-four-preview-wrap">
                  {all_account?.length < 2 ? (
                    <>
                      {" "}
                      <div
                        style={{ width: size.width < 900 ? "100%" : "65rem" }}
                        className="invoice-preview-detail-wrap  remove-dark-border"
                      >
                        {/* box start */}
                        <div className="box grey-bg top-info">
                          <div className="left-box">
                            <p className="label">Invoice Name:</p>
                            <p className="value grey-white-color">
                              {formData?.title || "---"}
                            </p>
                          </div>
                          <div className="right-box">
                            <p className="label">Date:</p>
                            <p className="value grey-white-color">
                              {formData?.startDate && formData?.endDate
                                ? `${
                                    formatDate(formData?.startDate)?.split(
                                      "—"
                                    )[0]
                                  } — ${
                                    formatDate(formData?.endDate)?.split("—")[0]
                                  }`
                                : "---"}
                            </p>
                          </div>
                        </div>
                        {/* box end */}
                        {/* box start */}
                        <div className="box middle-info">
                          <div className="left-box">
                            <p className="label">Bill From:</p>
                            <p className="value grey-white-color">
                              {business?.business_name || "---"}
                            </p>
                            <p className="value grey-white-color">
                              {business?.business_address || "---"}
                            </p>
                            <p className="value grey-white-color">
                              {business?.business_email || "---"}
                            </p>
                          </div>
                          <div className="right-box">
                            <p className="label">Bill To:</p>
                            <p className="value grey-white-color">
                              {formData?.cust_fullname || "---"}
                            </p>
                            <p className="value grey-white-color">
                              {formData?.cust_email_address || "---"}
                            </p>
                            <p className="value grey-white-color">
                              +234 {formData?.cust_phone_no || "---"}
                            </p>
                          </div>
                        </div>
                        {/* box end */}
                        {/* box start */}
                        <div className="box grey-bg top-info detail-info">
                          <div className="left-box">
                            <p className="label">Items</p>
                          </div>
                          <div className="right-box">
                            <p className="label">Cost</p>
                            <p className="label">Qty</p>
                            <p className="label">Price</p>
                          </div>
                        </div>
                        {/* box end */}

                        {/* box start */}
                        {itemArr?.length > 0 &&
                          itemArr?.map((chi, idx) => {
                            const { name, quantity, price, id, total_price } =
                              chi;
                            return (
                              <div
                                key={id || idx}
                                className="box sum-detail-box"
                              >
                                <div className="left-box">
                                  <p className="name">{name || "---"}</p>
                                </div>
                                <div className="right-box">
                                  <p className="cost">
                                    {formatNumWithCommaNairaSymbol(
                                      Number(
                                        formatNumWithoutCommaNaira(price)
                                      ) || "0"
                                    )}
                                  </p>
                                  <p className="quantity">
                                    {quantity || "---"}
                                  </p>
                                  <p className="total-price">
                                    {formatNumWithCommaNairaSymbol(
                                      total_price || "0"
                                    )}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        {itemArr?.length < 1 && (
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className="name">{item?.name || "---"}</p>
                            </div>
                            <div className="right-box">
                              <p className="cost">
                                {formatNumWithCommaNairaSymbol(
                                  Number(
                                    formatNumWithoutCommaNaira(item?.price)
                                  ) || "0"
                                )}
                              </p>
                              <p className="quantity">
                                {item?.quantity || "---"}
                              </p>
                              <p className="total-price">
                                {formatNumWithCommaNairaSymbol(
                                  Number(item?.quantity) *
                                    Number(
                                      formatNumWithoutCommaNaira(item?.price)
                                    ) || "0"
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                        {/* box end */}
                        {/* box start */}
                        <div className="box sum-detail-box sum-detail-box-underline">
                          <div className="left-box"></div>
                          <div className="right-box">
                            <div className="underline border-theme"></div>
                          </div>
                        </div>
                        {/* box end */}
                        {/* box start summary start */}
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">Subtotal:</p>
                            <p className="quantity">
                              {" "}
                              {itemArr?.length > 0
                                ? sumAllNum(
                                    itemArr?.map((chi) => Number(chi?.quantity))
                                  )
                                : item?.quantity || "1"}
                            </p>
                            <p className="total-price">
                              {formatNumWithCommaNairaSymbol(
                                formData?.totalSum || "0"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">
                              Discount ({formData?.discount}%)
                            </p>
                            <p className="quantity"></p>
                            <p className="total-price">
                              -{" "}
                              {formatNumWithCommaNairaSymbol(
                                formData?.discount_val || "0"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">
                              Tax ({formData?.vat}%)
                            </p>
                            <p className="quantity"></p>
                            <p className="total-price">
                              {" "}
                              {formatNumWithCommaNairaSymbol(
                                formData?.vat_val || "0"
                              )}
                            </p>
                          </div>
                        </div>
                        {formData?.shipping ? (
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">
                                Shipping Fee
                              </p>
                              <p className="quantity"></p>
                              <p className="total-price">
                                {" "}
                                {formData?.shipping || ""}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* box start */}
                        <div className="box sum-detail-box sum-detail-box-underline">
                          <div className="left-box"></div>
                          <div className="right-box">
                            <div className="underline border-theme"></div>
                          </div>
                        </div>
                        {/* box end */}
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">
                              Invoice Total
                            </p>
                            <p className="quantity"></p>
                            <p
                              className="total-price"
                              style={{ fontWeight: "bold" }}
                            >
                              {formatNumWithCommaNairaSymbol(
                                formData?.totalPrice +
                                  Number(
                                    formatNumWithoutCommaNaira(
                                      formData?.shipping
                                    )
                                  ) || "0"
                              )}
                            </p>
                          </div>
                        </div>

                        {/* box end summary end */}
                        <div className="underline-box">
                          <div className="underline border-theme"></div>
                        </div>
                        {/* note wrap start */}
                        <div className="note-wrap-box">
                          <p className="note">Note:</p>
                          <p className="text grey-white-color">
                            {`Hi ${formData?.cust_fullname}, ${formData?.note}`}
                          </p>
                        </div>
                        {/* note wrap end */}
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="left-wrap">
                        <div className="invoice-preview-detail-wrap  remove-dark-border">
                          {/* box start */}
                          <div className="box grey-bg top-info">
                            <div className="left-box">
                              <p className="label">Invoice Name:</p>
                              <p className="value grey-white-color">
                                {formData?.title || "---"}
                              </p>
                            </div>
                            <div className="right-box">
                              <p className="label">Date:</p>
                              <p className="value grey-white-color">
                                {formData?.startDate && formData?.endDate
                                  ? `${
                                      formatDate(formData?.startDate)?.split(
                                        "—"
                                      )[0]
                                    } — ${
                                      formatDate(formData?.endDate)?.split(
                                        "—"
                                      )[0]
                                    }`
                                  : "---"}
                              </p>
                            </div>
                          </div>
                          {/* box end */}
                          {/* box start */}
                          <div className="box middle-info">
                            <div className="left-box">
                              <p className="label">Bill From:</p>
                              <p className="value grey-white-color">
                                {business?.business_name || "---"}
                              </p>
                              <p className="value grey-white-color">
                                {business?.business_address || "---"}
                              </p>
                              <p className="value grey-white-color">
                                {business?.business_email || "---"}
                              </p>
                            </div>
                            <div className="right-box">
                              <p className="label">Bill To:</p>
                              <p className="value grey-white-color">
                                {formData?.cust_fullname || "---"}
                              </p>
                              <p className="value grey-white-color">
                                {formData?.cust_email_address || "---"}
                              </p>
                              <p className="value grey-white-color">
                                +234 {formData?.cust_phone_no || "---"}
                              </p>
                            </div>
                          </div>
                          {/* box end */}
                          {/* box start */}
                          <div className="box grey-bg top-info detail-info">
                            <div className="left-box">
                              <p className="label">Items</p>
                            </div>
                            <div className="right-box">
                              <p className="label">Cost</p>
                              <p className="label">Qty</p>
                              <p className="label">Price</p>
                            </div>
                          </div>
                          {/* box end */}

                          {/* box start */}
                          {itemArr?.length > 0 &&
                            itemArr?.map((chi, idx) => {
                              const { name, quantity, price, id, total_price } =
                                chi;
                              return (
                                <div
                                  key={id || idx}
                                  className="box sum-detail-box"
                                >
                                  <div className="left-box">
                                    <p className="name">{name || "---"}</p>
                                  </div>
                                  <div className="right-box">
                                    <p className="cost">
                                      {formatNumWithCommaNairaSymbol(
                                        Number(
                                          formatNumWithoutCommaNaira(price)
                                        ) || "0"
                                      )}
                                    </p>
                                    <p className="quantity">
                                      {quantity || "---"}
                                    </p>
                                    <p className="total-price">
                                      {formatNumWithCommaNairaSymbol(
                                        total_price || "0"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          {itemArr?.length < 1 && (
                            <div className="box sum-detail-box">
                              <div className="left-box">
                                <p className="name">{item?.name || "---"}</p>
                              </div>
                              <div className="right-box">
                                <p className="cost">
                                  {formatNumWithCommaNairaSymbol(
                                    Number(
                                      formatNumWithoutCommaNaira(item?.price)
                                    ) || "0"
                                  )}
                                </p>
                                <p className="quantity">
                                  {item?.quantity || "---"}
                                </p>
                                <p className="total-price">
                                  {formatNumWithCommaNairaSymbol(
                                    Number(item?.quantity) *
                                      Number(
                                        formatNumWithoutCommaNaira(item?.price)
                                      ) || "0"
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                          {/* box end */}
                          {/* box start */}
                          <div className="box sum-detail-box sum-detail-box-underline">
                            <div className="left-box"></div>
                            <div className="right-box">
                              <div className="underline border-theme"></div>
                            </div>
                          </div>
                          {/* box end */}
                          {/* box start summary start */}
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">Subtotal:</p>
                              <p className="quantity">
                                {" "}
                                {itemArr?.length > 0
                                  ? sumAllNum(
                                      itemArr?.map((chi) =>
                                        Number(chi?.quantity)
                                      )
                                    )
                                  : item?.quantity || "1"}
                              </p>
                              <p className="total-price">
                                {formatNumWithCommaNairaSymbol(
                                  formData?.totalSum || "0"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">
                                Discount ({formData?.discount}%)
                              </p>
                              <p className="quantity"></p>
                              <p className="total-price">
                                -{" "}
                                {formatNumWithCommaNairaSymbol(
                                  formData?.discount_val || "0"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">
                                Tax ({formData?.vat}%)
                              </p>
                              <p className="quantity"></p>
                              <p className="total-price">
                                {" "}
                                {formatNumWithCommaNairaSymbol(
                                  formData?.vat_val || "0"
                                )}
                              </p>
                            </div>
                          </div>
                          {formData?.shipping ? (
                            <div className="box sum-detail-box">
                              <div className="left-box">
                                <p className=""></p>
                              </div>
                              <div className="right-box">
                                <p className="cost grey-white-color">
                                  Shipping Fee
                                </p>
                                <p className="quantity"></p>
                                <p className="total-price">
                                  {" "}
                                  {formData?.shipping || ""}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {/* box start */}
                          <div className="box sum-detail-box sum-detail-box-underline">
                            <div className="left-box"></div>
                            <div className="right-box">
                              <div className="underline border-theme"></div>
                            </div>
                          </div>
                          {/* box end */}
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">
                                Invoice Total
                              </p>
                              <p className="quantity"></p>
                              <p
                                className="total-price"
                                style={{ fontWeight: "bold" }}
                              >
                                {formatNumWithCommaNairaSymbol(
                                  formData?.totalPrice +
                                    Number(
                                      formatNumWithoutCommaNaira(
                                        formData?.shipping
                                      )
                                    ) || "0"
                                )}
                              </p>
                            </div>
                          </div>

                          {/* box end summary end */}
                          <div className="underline-box">
                            <div className="underline border-theme"></div>
                          </div>
                          {/* note wrap start */}
                          <div className="note-wrap-box">
                            <p className="note">Note:</p>
                            <p className="text grey-white-color">
                              {`Hi ${formData?.cust_fullname}, ${formData?.note}`}
                            </p>
                          </div>
                          {/* note wrap end */}
                        </div>
                      </div>
                      {/* account source box start */}
                      <div className="preview-account-source-wrap border-theme-left">
                        <div className="wrap border-theme">
                          {/* account drop wrap start */}
                          <ChangeSourceAccount
                            onObjChange={(e) => {
                              setSubAccount(e);
                            }}
                            downDropType
                            darkType={true}
                            // onChange={() => onClose()}
                            title={`Invoice Settlement Account.`}
                            defaultObj={subAccount}
                          />
                          {/* account drop wrap end */}
                          {/* Submit button start */}
                          <div className="submit-btn-box">
                            {" "}
                            <RavenButton
                              className="submit-btn"
                              label="Update Invoice now"
                              color="black-light"
                              onClick={handleCreate}
                              loading={loading}
                            />
                          </div>

                          {/* Submit button end */}
                        </div>
                      </div>
                      {/* account source box end */}
                    </>
                  )}
                </div>
              </>
            )}
            {/* {viewAll && <div className="space-bottom"></div>} */}
          </div>
          {/* <div className="space-bottom"></div> */}
        </ContainerWrapper>
      </DashboardLayout>
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Invoice Created Successfully."
        text={`The invoice has been sent to the recipient's email address, We appreciate your business and look forward to serving you again in the future.`}
        btnText={`Close`}
        onBtnClick={() => {
          navigate("/dashboard-invoice");
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
}

export default EditInvoicePage;
