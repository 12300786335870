import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Function to check if the image URL is valid
const checkImageURL = (url) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      resolve(true);
    };

    img.onerror = () => {
      resolve(false);
    };

    img.src = url;
  });
}; 


// ImageFragment component
const ImageFragment = ({ url, children, className, styles, loadSize }) => {
  const [imageValid, setImageValid] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const imageUrl = url;

    if (imageUrl) {
      checkImageURL(imageUrl).then((isValid) => {
        setImageValid(isValid );
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [url]);

  if (loading) {
    return (
      <div style={{overflow: "hidden"}} className="img-box">
        <Skeleton width={loadSize || 35} height={loadSize || 35} circle />
      </div>
    ); // You can customize the loading indicator as needed
  }

  return (
    <div>
      {imageValid ? (
        <figure style={styles} className={`img-box ${className && className}`}>
          <img src={url} alt="" className="img" />
        </figure>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default ImageFragment;
