// import DashboardPaymentLink from "../../../pages/dashboard/payment-link";
import EditPaymentLink from "../../../pages/dashboard/payment-link/EditPaymentLink";
import DashboardPaymentLinkNewIndex from "../../../pages/dashboard/payment-link/NewPaymentLinkIndex";
import NewPaymentLinkInfo from "../../../pages/dashboard/payment-link/NewPaymentLinkInfo";
import PaymentLinkRequestMoney from "../../../pages/dashboard/payment-link/PaymentLinkRequestMoney";


export const dashboard_payment_link_route_group = [
    // {path: "/dashboard-payment-links", element: <DashboardPaymentLink />},NewPaymentLinkInfo
    {path: "/dashboard-payment-links", element: <DashboardPaymentLinkNewIndex />},
    {path: "/dashboard-payment-links-detail-view", element: <NewPaymentLinkInfo />},
    {path: "/dashboard-payment-links-request-money", element: <PaymentLinkRequestMoney />},
    {path: "/dashboard-payment-links-request-money-edit", element: <EditPaymentLink />},
]