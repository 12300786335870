import useSound from "use-sound";
// import waveSound from "../../assets/raven_success_sound.wav";
// import { formatMetaData } from "../../utils/helper/Helper";
// import { useSelector } from "react-redux";

function UseNotificationSound(soundUrl) {
  // const { user } = useSelector(
  //   (state) => state.user
  // );
  const [play] = useSound(soundUrl, { volume: 0.5 });

  const playNotification = () => {
    play();
  };

  return playNotification;
}
 
export default UseNotificationSound;
