import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import BillImage from "../../../../../assets/new-electricity-icon.png";
import {
  generateBankStatementElectricity,
  generateBankStatementElectricityTemplate,
  searchBankStatementElectricity,
} from "../../../../../redux/settings";
import "../../../../../styles/dashboard/setting/SettingModal.css";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
} from "../../../../../utils/helper/Helper";
import useDebounce from "../../../../../utils/helper/UseDebounce";
import NoContentBox from "../../../../../components/reusables/NoContentBox";

const ElectricityGenerateModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const { loading, electricityStatements, loadingSearch } = useSelector(
    (state) => state.settings
  );

  const [showActive, setShowActive] = useState("");

  const handleDownloadElectricitydownload = async () => {
    const obj = {
      transaction_id: String(showActive),
    };

    const data = await dispatch(generateBankStatementElectricityTemplate(obj));
    if (data?.payload?.data?.status === "success") {
      // console.log(data);
      downloadCsv(data?.payload?.data.data.url);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 3) {
      const obj = {
        search_term: search,
      };
      dispatch(searchBankStatementElectricity(obj));
      // console.log(obj);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      dispatch(generateBankStatementElectricity());
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <RavenModal
      btnColor={`black-light`}
      disabled={!showActive}
      btnLabel={`Generate Statement`}
      visble={visible}
      onClose={onClose}
      onBtnClick={handleDownloadElectricitydownload}
      loading={loading}
    >
      <div className="settings-modal-wrap">
        <div className="generate-modal-wrap">
          <p className="title">Generate Electricity Statement</p>
          <p className="text grey-white-color">
            Select how to generate statement
          </p>

          {electricityStatements?.length > 6 && (
            <div className="input-type-wrap input-type-wrap-two">
              <form action="" autoComplete="off" style={{ width: "100%" }}>
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`search`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={`Search electricity statement`}
                />
              </form>
            </div>
          )}

          <div className="electricity-child-wrap">
            {/* child-wrap-box start */}
            {electricityStatements?.length > 0 ? (
              <div className="child-wrap-box">
                {/* map start */}
                {electricityStatements?.map((chi, idx) => {
                  const { id, amount, created_at } = chi;
                  return (
                    <div
                      key={id || idx}
                      className={`child-wrap ${
                        showActive === id && "child-wrap-active"
                      }`}
                      onClick={() => {
                        showActive === id
                          ? setShowActive("")
                          : setShowActive(id);
                      }}
                    >
                      {/* check  box start */}
                      {showActive === id && (
                        <div className="check-box-show">
                          <FaCheck className="icon" />
                        </div>
                      )}
                      {/* check  box end */}
                      <div className="img-wrap">
                        {loadingSearch ? (
                          <Skeleton width={35} height={35} circle />
                        ) : (
                          <figure className="img-box">
                            <img src={BillImage} alt="" className="img" />
                          </figure>
                        )}
                      </div>
                      {loadingSearch ? (
                        <div className="text-box">
                          <Skeleton width={140} height={15} />
                          <Skeleton width={70} height={15} />
                        </div>
                      ) : (
                        <div className="text-box">
                          <p className="title">{`Statement for ${formatDate(
                            created_at
                          )}`}</p>
                          <p className="value">
                            {formatNumWithCommaNairaSymbol(amount)}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
                {/* map end */}
              </div>
            ) : (
              <>
                {" "}
                <div
                  className="box-wrap "
                  style={{
                    display: "grid",
                    placeItems: "center",
                    minHeight: "20rem",
                  }}
                >
                  {" "}
                  <NoContentBox
                    loading={loading}
                    title={`No electricity statement`}
                    text={`List of all electricity statement will be found here`}
                  />
                </div>
              </>
            )}
            {/* child wrapbox end */}
          </div>
        </div>
      </div>
    </RavenModal>
  );
};

export default ElectricityGenerateModal;
