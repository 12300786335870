const vars = {
  // base_url:
  base_url:
    process.env.NODE_ENV === "development"
      ? "https://businessapi.getraventest.com"
      : "https://businessapi.getraventest.com",
};
function config() {
  return vars;
}

const env = config();

export default env;

// https://businessapi.getravenbank.com/v1
// https://businessapi.getraventest.com

// tunnel v0.5 --------- https://ae7a-105-112-31-132.ngrok-free.app ----------
//  tunnel v1 ---------- https://8973-105-112-22-106.ngrok-free.app -------
    // "build": "sh -ac '. .env.${NODE_ENV}; react-scripts build'",
   