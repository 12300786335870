import React from "react";
import successImg from "../../assets/invite-ref-success.png";
import failedImg from "../../assets/invite-ref-failed.png";

const SuccessOrFailedContent = ({ title, type, text }) => {
  return (
    <div className="success-failed-main-index-content-wrap card-bg">
      <div
        className={`img-round-box ${
          type === "failed" && "img-round-box-failed"
        }`}
      >
        <figure className="img-box">
          <img
            className="img"
            src={type === "failed" ? failedImg : successImg}
            alt=""
          />
        </figure>
      </div>
      <p className="title">{title || "Title"}</p>
      <p className="text grey-white-color">{text || "text"}</p>
    </div>
  );
};

export default SuccessOrFailedContent;
