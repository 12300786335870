import React, { useState } from "react";
import NewSelectScheduleBox from "../../../../components/reusables/NewSelectScheduleBox";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";Group 238182raven-default-bank-img.svg
import "../../../../styles/fragments/PreviewDetailsCommonBox.css";
// import bankIconDefault from "../../../../assets/bank-general-img.svg"
// import bankIconDefault from "../../../../assets/raven-default-bank-img.svg";
import mtnICon from "../../../../assets/bill-mtn-icon.svg";
import airtelICon from "../../../../assets/bill-airtel-icon.svg";
import mobileICon from "../../../../assets/bill-moblie-icon.svg";
import gloICon from "../../../../assets/bill-glo-icon.svg";
import moment from "moment";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
  //   formatNumWithoutCommaNaira,
  //   trimLongString,
} from "../../../../utils/helper/Helper";
import { FaArrowRight, FaTimes } from "react-icons/fa";
// import ActionContext from "../../../../context/ActionContext";
import ChangeSourceAccount from "../../../../components/reusables/ChangeSourceAccount";

const NewPreviewDetailBox = ({
  collapse,
  amount,
  name,
  logo,
  account_name,
  narration,
  onChange,
  value,
  id,
  checked,
  previewOpen,
  setPreviewOpen,
  onChangeSchedule,
  valueSchedule,
  enableTime,
  onReset,
  details,
  bulkAmount,
  setDetails,
  handleRemoveFromList,
  handleChange,
  list,
  provider,
  number,
}) => {
  const [viewAll, setViewAll] = useState(false);
  // const actionCtx = useContext(ActionContext);

  // const InfoIcon = (
  //   <svg
  //     className="img"
  //     width="12"
  //     height="12"
  //     viewBox="0 0 12 12"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
  //       stroke="#8B8B8B"
  //       strokeWidth="1.5"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //     />
  //   </svg>
  // );

  return (
    <div className="new-preview-detail-box-wrap card-bg border-theme">
      {/* img top box start */}
      <div
        // style={{ marginTop: "3rem" }}
        className="img-top-box-wrap border-theme-bottom"
      >
        <div className="img-wrap">
          <figure className="img-box">
            <img src={logo} alt="" className="img" />
          </figure>
          <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div>
        </div>
        {/* amount date box styart */}
        <div className="amount-date-box">
          <p className="amount">
            {list?.length > 0
              ? bulkAmount
              : formatNumWithCommaNairaSymbol(amount) || ""}
          </p>
          <p className="date grey-white-color-white">
            {valueSchedule
              ? formatDate(valueSchedule)
              : formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div className="middle-content-wrap border-theme-bottom">
        {collapse ? (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Recipients:</p>
              <div className="value">
                <div
                  onClick={() => {
                    viewAll ? setViewAll(false) : setViewAll(true);
                  }}
                  className="see-all-box"
                >
                  <span>({list?.length || 0})</span>
                  <p className="see-all-text">
                    {viewAll ? `Close` : `See all`}
                  </p>
                </div>
                {/*  */}
                <div
                  className={`view-all-box card-bg ${
                    viewAll && "view-all-box-show"
                  }`}
                >
                  {" "}
                  <div className="wrap">
                    {list?.map((chi, idx) => {
                      return (
                        <div key={idx} className="child-wrap">
                          <figure className="img-box">
                            <img
                              src={
                                chi?.provider === "mtn"
                                  ? mtnICon
                                  : chi?.provider === "glo"
                                  ? gloICon
                                  : chi?.provider === "airtel"
                                  ? airtelICon
                                  : mobileICon
                              }
                              alt=""
                              className="img"
                            />
                          </figure>
                          <div className="text-box">
                            <p className="name">{`+234 ${chi?.phone}`}</p>
                            <p className="text grey-white-color">{` ₦ ${chi?.amount}`}</p>
                          </div>
                          <div
                            onClick={() => {
                              handleRemoveFromList(chi);
                            }}
                            className="cancel-box"
                          >
                            <FaTimes className="icon" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            {/* <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">{bulkAmount || 0}</div>
            </div> */}
            {/* box end */}
          </>
        ) : (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Customer Id:</p>
              <p className="value">{number || ""}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">To:</p>
              <p className="value">{name || "---"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Provider:</p>
              <p className="value">{provider || ""}</p>
            </div>
            {/* box end */}
          </>
        )}
      </div>
      {/* middle content wrap end */}
      {/* bottom- content wrap start */}
      <div className="bottom-content-wrap">
        <NewSelectScheduleBox
          id={`onudjhahabafw`}
          value={valueSchedule}
          textSchedule="Schedule this transaction"
          onChange={(e) => {
            onChangeSchedule(e);
          }}
          onReset={onReset}
        />
      </div>
      {/* bottom- content wrap end */}
      {/* account drop wrap start */}
      <ChangeSourceAccount />
      {/* account drop wrap end */}
    </div>
  );
};

export default NewPreviewDetailBox;
