import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadTransactionReciept } from "../../../redux/transaction";
import "../../../styles/dashboard/transaction/DashboardTransaction.css";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  lowerCaseWrap,
} from "../../../utils/helper/Helper";

const SingleDownloadReceiptModal = ({ onClose, visible, param, detail }) => {
  const { loadingDownload } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const formatMetaData = (param) => {
    if (param) {
      const obj = JSON?.parse(param);
      // console.log(obj);
      return obj;
    }
  };

  const handleSubmit = async () => {
    const obj = {
      transaction_id: String(detail?.id),
    };
    const data = await dispatch(downloadTransactionReciept(obj));
    if (data?.payload?.data?.status === "success") {
      // setDownloadCsvId("");
      downloadCsv(data?.payload?.data?.data?.url);
      // downloadCsvId("");
      // console.log(data);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <RavenModal
      btnColor={`black-light`}
      visble={visible}
      // btnLabel={`Download Reciept`}
      className="transaction-download-receipt-modal"
      onClose={onClose}
      onBtnClick={handleSubmit}
      loading={loadingDownload}
      // dontShowClose={true}
    >
      <div className="wrap-modal">
        <p
          onClick={() => {
            // console.log(formatMetaData(detail?.meta_data));
            // console.log(detail);
          }}
          className="title"
        >
          Transaction Reciept
        </p>
        <div className="underline"></div>
        {/* label-value-status box start */}
        <div className="label-value-status-box">
          {/* box start */}
          {formatTypeFunction(detail?.type) === "Reversal" && (
            <div className="box">
              <p className="label-download grey-white-color">Recipient</p>
              <p className="value-download">{`System Reversal`}</p>
            </div>
          )}
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Amount:</p>
            <p className="value-download">
              {formatNumWithCommaNairaSymbol(detail?.amount || 0)}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          {/* {detail?.fee  && ( */}
          <div className="box">
            <p className="label-download grey-white-color">
              Transaction Charges:
            </p>
            <p className="value-download">
              {formatNumWithCommaNairaSymbol(detail?.fee || 0)}
            </p>
          </div>
          {/* )} */}
          {/* box end */}
          {/* box start */}
          {Number(detail?.amount) > 10000 &&
            formatTypeFunction(detail?.type) === "Money Received" && (
              <div className="box">
                <p className="label-download grey-white-color">Stamp Duty</p>
                <p style={{ width: "80%" }} className="value-download">
                  {formatNumWithCommaNairaSymbol(detail?.stamp_duty || "0")}
                </p>
              </div>
            )}
          {/* box end */}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Airtime Recharge" ||
          formatTypeFunction(detail?.type) === "Data Recharge" ? (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {lowerCaseWrap(formatMetaData(detail?.meta_data)?.network) ||
                    lowerCaseWrap(detail?.network) ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Phone number</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.phone_number ||
                    detail?.phone ||
                    "---"}
                </p>
              </div>
              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Electricity Payment" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.provider || "---"}
                </p>
              </div>
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">Plan</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.meter_type || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Meter number</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.meter_no || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Token</p>
                <p className="value-download">
                  {detail?.electricity_token || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div> */}
              {/* box end */}
            </>
          )}
          {/* type end ----------------- */}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Cable Recharge" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.provider ||
                    detail?.provider ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Meter No.</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.smart_card_number ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div>
              {/* box end */}
            </>
          )}
          {/* type end ----------------- */}
          {formatTypeFunction(detail?.type) === "Money Received" &&
            formatMetaData(detail?.meta_data)?.first_name &&
            formatMetaData(detail?.meta_data)?.last_name && (
              <>
                <div className="box">
                  <p className="label-download grey-white-color">Sent By:</p>
                  <p className="value-download">
                    {`${
                      formatMetaData(detail?.meta_data)?.first_name || "---"
                    } ${formatMetaData(detail?.meta_data)?.last_name || "---"}`}
                  </p>
                </div>
                {/* box end electricity_token*/}
              </>
            )}
          {/* type start ----------------- */}
          {formatTypeFunction(detail?.type) === "Money Received" ||
          formatTypeFunction(detail?.type) === "Money Sent" ||
          formatTypeFunction(detail?.type) === "Reversal" ||
          formatTypeFunction(detail?.type) === "Transaction Charge" ? (
            <>
              {/* box start */}
              {!detail?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">Bank:</p>
                  <p className="value-download">
                    {formatMetaData(detail?.meta_data)?.bank ||
                      detail?.bank ||
                      "---"}
                  </p>
                </div>
              )}
              {detail?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">Bank:</p>
                  <p className="value-download">Raven Bank</p>
                </div>
              )}
              {/* box end */}
              {/* box start */}
              {!detail?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">Account No.</p>
                  <p className="value-download">
                    {formatMetaData(detail?.meta_data)?.account_number ||
                      detail?.account_number ||
                      "---"}
                  </p>
                </div>
              )}
              {detail?.identifier && (
                <div className="box">
                  <p className="label-download grey-white-color">
                    Raven Username
                  </p>
                  <p className="value-download">
                    {`@${detail?.identifier}` || "---"}
                  </p>
                </div>
              )}
              {/* box end */}
              {formatTypeFunction(detail?.type) === "Money Sent" && (
                <>
                  {" "}
                  {/* box start */}
                  {!detail?.identifier && (
                    <div className="box">
                      <p className="label-download grey-white-color">
                        Recipient
                      </p>
                      <p className="value-download">
                        {formatMetaData(detail?.meta_data)?.account_name ||
                          detail?.account_name ||
                          "---"}
                      </p>
                    </div>
                  )}
                  {/* box end */}
                </>
              )}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Session ID</p>
                <p style={{ width: "80%" }} className="value-download">
                  {detail?.session_id ||
                    formatMetaData(detail?.meta_data)?.session_id ||
                    "---"}
                </p>
              </div>
              {/* box end */}

              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}

          {/* box start */}
          {formatTypeFunction(detail?.type) !== "Reversal" && (
            <div className="box">
              <p className="label-download grey-white-color">
                Transaction Type{" "}
              </p>
              <p className="value-download">
                {detail?.type === "invoice-payment"
                  ? "Invoice Payment"
                  : detail?.type === "payment-link"
                  ? "Payment Link"
                  : formatTypeFunction(detail?.type) || "---"}
              </p>
            </div>
          )}
          {/* box end */}
          {detail?.type === "payment-link" && (
            <>
              {" "}
              <div className="box">
                <p className="label-download grey-white-color">
                  Customer Email
                </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_email || ""}
                </p>
              </div>
            </>
          )}
          {formatTypeFunction(detail?.type) === "Payroll Charge" && (
            <>
              {" "}
              <div className="box">
                <p className="label-download grey-white-color">Narration: </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.narration}
                </p>
              </div>
            </>
          )}
          {detail?.type === "invoice-payment" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Invoice Id </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.narration?.replace(
                    "payment for invoice",
                    ""
                  )}
                </p>
              </div>
              <div className="box">
                <p className="label-download grey-white-color">
                  Customer Email
                </p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_email || ""}
                </p>
              </div>
            </>
          )}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Transaction date </p>
            <p className="value-download">
              {formatDate(detail?.created_at) || "---"}
            </p>
          </div>
          {/* box end */}
          {formatTypeFunction(detail?.type) !== "Reversal" && (
            <>
              {" "}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">
                  Transaction Ref.
                </p>
                <p className="value-download ref">
                  <span>
                    {" "}
                    {formatMetaData(detail?.meta_data)?.reference ||
                      detail?.merchant_ref ||
                      detail?.trx_ref ||
                      "---"}
                  </span>
                </p>
              </div>
              {/* box end */}
            </>
          )}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Status </p>
            <div
              className={`status-type-wrap ${
                String(detail?.status) === "0" && "status-type-wrap-pending"
              } ${
                String(detail?.status) === "3" && "status-type-wrap-success"
              } ${
                String(detail?.status) === "11" && "status-type-wrap-success"
              } ${
                String(detail?.status) === "success" &&
                "status-type-wrap-success"
              } ${
                String(detail?.status) === "paid" && "status-type-wrap-success"
              } ${String(detail?.status) === "2" && "status-type-wrap-fail"} ${
                String(detail?.status) === "overdue" &&
                "status-type-wrap-pending"
              } ${
                String(detail?.status) === "1" && "status-type-wrap-pending"
              } ${
                String(detail?.status) === "pending" &&
                "status-type-wrap-pending"
              }`}
            >
              <span>
                {" "}
                {String(detail?.status) === "1"
                  ? "Processing"
                  : String(detail?.status) === "0"
                  ? "Pending"
                  : String(detail?.status) === "3"
                  ? "Success"
                  : String(detail?.status) === "2"
                  ? "Failed"
                  : String(detail?.status) === "11"
                  ? "Success"
                  : detail?.status}
              </span>
            </div>
          </div>
          {/* box end */}
        </div>
        {/* label-value-status box end */}
      </div>
    </RavenModal>
  );
};

export default SingleDownloadReceiptModal;
