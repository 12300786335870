import React, { useEffect, useState } from "react";
import DashboardSettingsLayout from "./SettingsLayout";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import verifyIcon from "../../../../assets/settings-directors-icon.svg";
import NoContentBox from "../../../../components/reusables/NoContentBox";
import { getDirectorShareholders } from "../../../../redux/settings";
import ViewDirectorModal from "../modal/ViewDirector";

const DashboardSettingsDirectors = () => {
  const dispatch = useDispatch();
  const [singleChild, setSingleChild] = useState({});
  const [showModal, setShowModal] = useState({
    team_invite: false,
    team_delete: false,
    device_delete: false,
    statement: false,
    electricity: false,
    view_directors: false,
    delete_sub_account: false,
    change_primary: false,
    remove_role_account: false,
    add_role_account: false,
  });
  const { directors, loading } = useSelector((state) => state.settings);
  // const { business } = useSelector((state) => state.user);

  useEffect(() => {
    if (directors?.length < 1) {
      dispatch(getDirectorShareholders());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   useEffect(() => {

  //   }, [])

  return (
    <>
      <DashboardSettingsLayout>
        <>
          {directors?.length > 0 ? (
            <div
              //   onClick={() => {
              //     // console.log(directors);
              //   }}
              className="detail-wrap animate-move-up-class"
              id="Verification"
            >
              <p className="title">Directors</p>
              <p className="sub-title grey-white-color">
                Manage directors for your business page.
              </p>
              <div className="box-wrap grey-bg">
                {directors?.map((chi, idx) => {
                  const { id, lname, phone_number, fname, bvn_verified } = chi;
                  return (
                    <div
                      onClick={() => {
                        setShowModal((prev) => {
                          setSingleChild(chi);
                          return { ...prev, view_directors: true };
                        });
                      }}
                      key={id || idx}
                      className="noti-wrap"
                      style={{ cursor: "pointer" }}
                    >
                      <figure className="img-box">
                        <img src={verifyIcon} alt="" className="img" />
                      </figure>
                      <div className="text-box">
                        <p className="value">{`${fname || "---"} ${
                          lname || "---"
                        }`}</p>
                        <p className="label grey-white-color">
                          {phone_number || "---"}
                        </p>
                      </div>
                      <div
                        className={`add-error-box  ${
                          bvn_verified === 1 && "verified"
                        }`}
                        // onClick={() => {
                        //   navigate("/dashboard-verification-cac");
                        // }}
                      >
                        <span>
                          {bvn_verified === 1 ? "Verified" : "Update Profile"}
                        </span>
                      </div>
                      {/* <RavenToggleSlide id="jgjk" color="black-light" /> */}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="detail-wrap animate-move-up-class">
              <div
                className="box-wrap grey-bg"
                style={{
                  display: "grid",
                  placeItems: "center",
                  minHeight: "45rem",
                }}
              >
                {" "}
                <NoContentBox
                  loading={loading}
                  title={`No director found.`}
                  text={`Complete CAC verification to add director automatically`}
                />
              </div>
            </div>
          )}
        </>
      </DashboardSettingsLayout>
      {/* genrate start */}
      <ViewDirectorModal
        visible={showModal?.view_directors}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view_directors: false };
          });
        }}
        detail={singleChild}
      />
      {/* genrate end */}
    </>
  );
};

export default DashboardSettingsDirectors;
