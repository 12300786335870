import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import { useSelector } from "react-redux";
import NoContentBox from "../../../../components/reusables/NoContentBox";
import "../../../../styles/dashboard/pos/DashboardPosIndex.css";
import { formatStringPermissionToLowerCase } from "../../../../utils/helper/Helper";

const cashierIcon = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="img"
  >
    <g filter="url(#filter0_dd_12761_68366)">
      <rect
        x="1.10156"
        y="0.600098"
        width="18"
        height="18"
        rx="2.7"
        fill="white"
      />
      <rect
        x="1.21695"
        y="0.715482"
        width="17.7692"
        height="17.7692"
        rx="2.58462"
        stroke="#CCCCCC"
        stroke-opacity="0.3"
        strokeWidth="0.230769"
      />
    </g>
    <circle cx="4.9088" cy="3.94616" r="1.96154" fill="#0B8376" />
    <path
      d="M5.60192 4.52329L5.145 3.81713L5.5861 3.13867H5.2617L4.96698 3.60153L4.67225 3.13867H4.34588L4.78698 3.81911L4.33203 4.52329H4.66434L4.96698 4.04461L5.26764 4.52329H5.60192Z"
      fill="white"
    />
    <rect
      x="2.94727"
      y="7.98486"
      width="14.7692"
      height="1.61538"
      rx="0.461538"
      fill="#F7F8F7"
    />
    <rect
      x="2.94727"
      y="10.9849"
      width="14.7692"
      height="1.61538"
      rx="0.461538"
      fill="#F7F8F7"
    />
    <rect
      x="2.94727"
      y="13.7542"
      width="14.7692"
      height="1.84615"
      rx="0.461538"
      fill="#F7F8F7"
    />
    <defs>
      <filter
        id="filter0_dd_12761_68366"
        x="0.178486"
        y="0.138559"
        width="19.8462"
        height="20.0769"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.230769" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.02 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_12761_68366"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.692308" />
        <feGaussianBlur stdDeviation="0.461538" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_12761_68366"
          result="effect2_dropShadow_12761_68366"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_12761_68366"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
const deleteIcon = (
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="img"
  >
    <path
      d="M10.5 4.5V3.9C10.5 3.05992 10.5 2.63988 10.3365 2.31901C10.1927 2.03677 9.96323 1.8073 9.68099 1.66349C9.36012 1.5 8.94008 1.5 8.1 1.5H6.9C6.05992 1.5 5.63988 1.5 5.31901 1.66349C5.03677 1.8073 4.8073 2.03677 4.66349 2.31901C4.5 2.63988 4.5 3.05992 4.5 3.9V4.5M6 8.625V12.375M9 8.625V12.375M0.75 4.5H14.25M12.75 4.5V12.9C12.75 14.1601 12.75 14.7902 12.5048 15.2715C12.289 15.6948 11.9448 16.039 11.5215 16.2548C11.0402 16.5 10.4101 16.5 9.15 16.5H5.85C4.58988 16.5 3.95982 16.5 3.47852 16.2548C3.05516 16.039 2.71095 15.6948 2.49524 15.2715C2.25 14.7902 2.25 14.1601 2.25 12.9V4.5"
      stroke="#FF0F00"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ViewCashierModal = ({
  visible,
  onClose,
  onAdd,
  settingPos,
  onCashierDelete,
}) => {
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  return (
    <RavenModal
      btnColor="black-light"
      btnLabel={
        user_resource_and_permissions?.allowed_permissions?.includes(
          "can_create_new_cashier"
        ) && "Add new Cashier"
      }
      onBtnClick={onAdd}
      visble={visible}
      onClose={onClose}
    >
      <div className="view-pos-cashier-modal-wrap">
        {settingPos?.lenght > 0 ? (
          <div className="wrap">
            {settingPos?.map((chi, idx) => {
              const { full_name, phone_number } = chi;
              return (
                <div
                  key={idx}
                  onClick={() => {
                    formatStringPermissionToLowerCase(
                      user_resource_and_permissions?.allowed_permissions
                    )?.includes("can_remove_bankbox_cashier") &&
                      onCashierDelete(chi);
                  }}
                  className="child-row border-theme-bottom"
                >
                  <div className="icon-wrap ">
                    <figure className="img-box grey-bg">{cashierIcon}</figure>
                  </div>

                  <div className="text-box">
                    <p className="name">{full_name || "---"}</p>
                    <p className="number grey-white-color">
                      {phone_number || "---"}
                    </p>
                  </div>

                  <div className="delete-wrap">
                    <figure className="img-box">{deleteIcon}</figure>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              minHeight: "25rem",
              display: "grid",
              placeItems: "center",
            }}
            className="no-content-casier"
          >
            <NoContentBox
              title={`No Cashier Added Yet`}
              // loading={loading}
              text="Click on add new cashier to add a cashier to this POS Terminal"
            />
          </div>
        )}
      </div>
    </RavenModal>
  );
};

export default ViewCashierModal;
