import {
  RavenButton,
  // RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../../styles/dashboard/schedule-payment/ViewScheduleModal.css";
import Flatpicker from "react-flatpickr";
import moment from "moment";
import { formatDate, trimLongString } from "../../../utils/helper/Helper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { reschedulePayment } from "../../../redux/schedulePayment";

const CustomerMobileViewMobile = ({
  param,
  visible,
  onClose,
  onCancel,
  onReschedule,
  detail,
  setNewDate,
  onFinish,
}) => {
  const [reschedule, setReschedule] = useState(false);
  //   const { loading } = useSelector((state) => state.schedule);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  //   const dispatch = useDispatch();
  const [details, setDetails] = useState({
    date: "",
  });

  const navigate = useNavigate();

  return (
    <RavenModal
      visble={visible}
      className="view-schedule-payment-modal"
      onClose={() => {
        setReschedule(false);
        onClose();
      }}
    >
      {reschedule ? (
        <>
          <div className="reschedule-payment-box">
            <p className="title">Rescheduled Transaction</p>
            <div className="underline"></div>
            <div className="wrap">
              <Flatpicker
                theme="dark"
                options={{ enableTime: true, inline: true }}
                value={
                  details.date ||
                  moment(detail?.schedule_date).format("YYYY-MM-DD HH:mm:ss")
                }
                onChange={(date) => {
                  setDetails((prev) => {
                    return {
                      ...prev,
                      date: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
                    };
                  });
                  // setFromDate(moment(date[0]).format("YYYY-MM-DD HH:mm:ss"));
                }}
                className={`form-input input-calender`}
                autoComplete="off"
                //   placeholder={"Placeholder Here"}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="wrap-modal">
          <p
            // onClick={() => {

            // }}
            className="title"
          >
            Customer Information
          </p>
          <div className="underline"></div>
          {/* label-value-status box start */}
          <div className="label-value-status-box">
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Customer Name:</p>
              <p className="value">{detail?.customer_name}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Customer Email</p>
              <p className="value">
                {trimLongString(detail?.customer_email, 20)}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Customer Phone</p>
              <p className="value">{detail?.customer_phone}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Date Created </p>
              <p style={{ textTransform: "capitalize" }} className="value">
                {formatDate(detail?.created_at)}
              </p>
            </div>
            {/* box end */}
          </div>
          {/* label-value-status box end */}
        </div>
      )}
      {user_resource_and_permissions?.allowed_permissions?.includes(
        "can_delete_customer"
      ) ||
      user_resource_and_permissions?.allowed_permissions?.includes(
        "can_update_customer"
      ) ? (
        <div className="two-button-wrap">
          {user_resource_and_permissions?.allowed_permissions?.includes(
            "can_update_customer"
          ) && (
            <RavenButton
              onClick={() => {
                localStorage?.setItem("customer-inv-id", param?.id);
                navigate("/dashboard-customers-edit");
              }}
              style={{ gridColumn: detail?.status === 2 && `1 / -1` }}
              color={`black-light`}
              label={reschedule ? `Cancel` : `Edit Customer`}
              className={reschedule && `btn-outline-base-bg-black`}
              textColor={reschedule && `black-light`}
            />
          )}
          {user_resource_and_permissions?.allowed_permissions?.includes(
            "can_delete_customer"
          ) && (
            <RavenButton
              onClick={onCancel}
              color={`error-light`}
              label={`Delete Customer`}
            />
          )}
        </div>
      ) : (
        ""
      )}
    </RavenModal>
  );
};

export default CustomerMobileViewMobile;
