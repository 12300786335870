import React, { useContext, useEffect, useState } from "react";
import "../../../styles/dashboard/global-account/GlobalAccountSingleIndex.css";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitletextBox from "../../../components/reusables/TitletextBox";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import { useNavigate } from "react-router-dom";
import ActionContext from "../../../context/ActionContext";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllGlobalAccount,
  getSingleGlobalAccount,
  // getSingleGlobalAccountTransaction,
} from "../../../redux/globalAccount";
import NocontentPage from "../../../components/reusables/NocontentPage";
import GlobalSelectDropBox from "../../../components/fragments/GlobalSelectDropBox";
import {
  capitalizeFirstLetter,
  // formatDate,
  formatMetaData,
  formatNumWithCommaNaira,
  getCurrencyName,
  getPageNum,
  getTotalPage,
  // formatTypeFunction,
  trimLongString,
} from "../../../utils/helper/Helper";
import Skeleton from "react-loading-skeleton";
import NoContentBox from "../../../components/reusables/NoContentBox";
// import DateFragmentBox from "../../../components/fragments/DateFragmentBox";
// import { RavenDateTime } from "@ravenpay/raven-bank-ui";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import DateFragmentBox from "../../../components/fragments/DateFragmentBox";
import { RavenPagination } from "@ravenpay/raven-bank-ui";
import DownloadReceiptModal from "./DownloadReceiptModal";

const GlobalAccountSingleIndex = () => {
  const [singleObj, setSingleObj] = useState({});
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState({
    view: false,
    statement: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const size = useWindowSize();
  const {
    // loading,
    single_global_account,
    loading,
    all_global_account,
    // single_global_account_trans,
    all_currencies,
  } = useSelector((state) => state.global_account);
  const eyeOpenIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
        stroke="#676767"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="dull-bright-filter"
        d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
        stroke="#676767"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const eyeCloseIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const downIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18339_42800)">
        <path
          //   className="dull-bright-filter"
          d="M8 3.33331V12.6666"
          stroke="#9C9CAB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          //   className="dull-bright-filter"
          d="M12 8.66669L8 12.6667"
          stroke="#9C9CAB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          //   className="dull-bright-filter"
          d="M4 8.66669L8 12.6667"
          stroke="#9C9CAB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18339_42800">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const downUpIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // className="dull-bright-filter"
        d="M11.3333 2.66669V13.3334M11.3333 13.3334L8.66667 10.6667M11.3333 13.3334L14 10.6667M4.66667 13.3334V2.66669M4.66667 2.66669L2 5.33335M4.66667 2.66669L7.33333 5.33335"
        stroke="#9C9CAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    const id = localStorage?.getItem("global-acc-id");
    // console.log(id);
    if (id) {
      getSingleGlobalAccFunc(id);
    } else {
      navigate("/dashboard-global-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleGlobalAccFunc = async (param, curPage) => {
    // console.log(param);
    // for transactions
    // console.log(param);
    const obj = {
      id: param || localStorage?.getItem("global-acc-id"),
      per_page: actionCtx?.perPageVal?.value,
      current_page: curPage,
    };
    // console.log(obj);
    dispatch(getSingleGlobalAccount(obj));
  };

  useEffect(() => {
    // if (Object?.keys(all_currencies)?.length < 1) {
    //   dispatch(getAllCurrencies());
    // }

    if (Object?.keys(all_global_account)?.length < 1) {
      dispatch(getAllGlobalAccount());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [currencyList, setCurrencyList] = useState([]);
  useEffect(() => {
    if (
      all_global_account?.global_accounts_available_for_current_business
        ?.length > 1
    ) {
      const newList =
        all_global_account?.global_accounts_available_for_current_business?.filter(
          (chi) => chi?.id !== single_global_account?.global_account?.id
        );
      // console.log(newList);
      setCurrencyList(newList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_global_account, single_global_account]);

  const getSymbolTotalAmount = (param) => {
    const obj = all_global_account?.all_support_currencies?.find(
      (chi) => chi?.currency === param
    );
    const symbol = obj?.symbol;
    return symbol;
  };

  // const getTrasactionsFunc = async (param) => {
  //   // console.log(param);
  //   const obj = {
  //     currency: param,
  //   };
  //   dispatch(getSingleGlobalAccountTransaction(obj));
  // };

  // const getCurrencyName = (param, list) => {
  //   if (list?.length > 0 && param) {
  //     const obj = list?.find((chi) => chi?.currency === param);
  //     return obj?.name;
  //   }
  //   if (param) {
  //     const obj = all_currencies?.all_support_currencies?.find(
  //       (chi) => chi?.currency === param
  //     );
  //     return obj?.name;
  //   }
  // };

  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_setup_global_account"
      )
    ) {
      navigate("/dashboard-global-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* title start */}
        <TitletextBox
          title={`Global Account`}
          text={`Accept and transacts in over ${
            all_currencies?.all_support_currencies?.length - 1 || ""
          } currencies`}
          //   addBtn={user_resource_and_permissions?.allowed_permissions?.includes(
          //     "can_create_customer"
          //   )}
          addBtn={false}
          toolTipMsg={`Create a new global account`}
          onAdd={() => {
            navigate("/dashboard-global-account-create");
          }}
          back={true}
          onBack={() => {
            navigate("/dashboard-global-account");
          }}
        ></TitletextBox>
        {/* titlte box end */}
        <ContainerWrapper
          pagination={
            single_global_account?.transactions?.next_page_url_params ||
            single_global_account?.transactions?.prev_page_url_params ? (
              <RavenPagination
                color={`black-light`}
                blackHover
                currentPage={
                  Number(single_global_account?.transactions?.current_page) || 1
                }
                nextPage={
                  single_global_account?.transactions?.next_page_url_params
                }
                prevPage={
                  single_global_account?.transactions?.prev_page_url_params
                }
                onNext={(e) => {
                  !loading && getSingleGlobalAccFunc("", getPageNum(e));
                }}
                onPrev={(e) => {
                  !loading && getSingleGlobalAccFunc("", getPageNum(e));
                }}
                totalPage={
                  getTotalPage(
                    Number(single_global_account?.transactions?.per_page),
                    Number(single_global_account?.transactions?.total)
                  ) || 1
                }
                onNumView={(e) => {
                  !loading && getSingleGlobalAccFunc("", e);
                }}
              />
            ) : (
              ""
            )
          }
          width={`100%`}
          dontShowHeader={true}
        >
          {loading && Object?.keys(single_global_account)?.length < 1 ? (
            <>
              {" "}
              <NocontentPage
                loading={true}
                // img={noContentImg}
                title={`Receive payment globally 🌍, with your Payment links`}
                // list={noContentList}
                //  btntext={
                //    user_resource_and_permissions?.allowed_permissions?.includes(
                //      "can_create_payment_link"
                //    ) && `Create link`
                //  }
                //  // onBtnClick={() => {
                //  //   navigate("/dashboard-payment-links-request-money");
                //  // }}
              />
            </>
          ) : (
            <>
              {" "}
              <div
                // onClick={() => {
                //   console.log(all_global_account);
                // }}
                className="global-account-single-page-index-wrap"
              >
                {/* centered wrap start */}
                <div className="centered-wrap">
                  {/* amount swap receive send wrap start */}
                  <div className="amount-swap-receive-send-wrap-box border-theme">
                    {/* show balance currency box start*/}
                    <div className="show-balance-currency-box">
                      {/* showBalance box start */}
                      <div
                        onClick={() => {
                          if (!loading) {
                            actionCtx?.setShowAmount(!actionCtx?.showAmount);
                          }
                        }}
                        className="show-balance-box"
                      >
                        <p className="text grey-white-color">Show Balance</p>
                        <div className="eye-icon">
                          <figure className="img-box">
                            {!actionCtx?.showAmount ? (
                              <>{eyeOpenIcon}</>
                            ) : (
                              <>{eyeCloseIcon}</>
                            )}
                          </figure>
                        </div>
                      </div>
                      {/* showBalance box end */}
                      {/* select drop box stat */}
                      {loading ? (
                        <Skeleton
                          width={82}
                          height={30}
                          style={{ borderRadius: "3.5rem" }}
                        />
                      ) : (
                        <>
                          <GlobalSelectDropBox
                            dontautoUpdate={true}
                            currencyList={currencyList}
                            hideDrop={
                              all_global_account
                                ?.global_accounts_available_for_current_business
                                ?.length < 2
                            }
                            hideDropIcon={
                              all_global_account
                                ?.global_accounts_available_for_current_business
                                ?.length < 2
                            }
                            setCurrency={(param) => {
                              // console.log(param);
                              if (param) {
                                getSingleGlobalAccFunc(param?.id);
                                localStorage.setItem(
                                  "global-acc-id",
                                  param?.id
                                );
                                const newList =
                                  all_global_account?.global_accounts_available_for_current_business?.filter(
                                    (chi) => chi?.id !== param?.id
                                  );
                                setCurrencyList(newList);
                              }
                            }}
                            selectedCurrency={
                              single_global_account?.global_account
                            }
                          />
                        </>
                      )}
                      {/* select drop box end */}
                    </div>
                    {/* show balance currency box end*/}
                    {/* amount receive swaop box start */}
                    <div className="amount-receive-swap-send-box">
                      {/* amount detail start */}
                      <div className="amount-detail-box">
                        {loading ? (
                          <Skeleton
                            width={35}
                            height={20}
                            style={{ marginBottom: ".3rem" }}
                          />
                        ) : (
                          <p className="label grey-white-color">
                            {getCurrencyName(
                              single_global_account?.global_account?.currency,
                              all_currencies?.all_support_currencies
                            ) || ""}
                          </p>
                        )}
                        {loading ? (
                          <Skeleton width={100} height={30} />
                        ) : (
                          <p className="value">
                            {actionCtx?.showAmount ? (
                              <>
                                {single_global_account?.global_account
                                  ?.currency && (
                                  <>
                                    {" "}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: `${getSymbolTotalAmount(
                                          single_global_account?.global_account
                                            ?.currency
                                        )}`,
                                      }}
                                    />
                                    {formatNumWithCommaNaira(
                                      single_global_account?.global_account
                                        ?.balance
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              "********"
                            )}
                          </p>
                        )}
                      </div>
                      {/* amount detail end */}
                      {/* receive send swap box start */}
                      <div className="receive-send-swap-box">
                        {loading ? (
                          <>
                            <Skeleton
                              width={82}
                              height={30}
                              style={{ borderRadius: "3.5rem" }}
                            />
                            <Skeleton
                              width={82}
                              height={30}
                              style={{ borderRadius: "3.5rem" }}
                            />
                            <Skeleton
                              width={82}
                              height={30}
                              style={{ borderRadius: "3.5rem" }}
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            {/* box start */}
                            <div
                              onClick={() => {
                                localStorage.setItem(
                                  "global-acc-id",
                                  single_global_account?.global_account?.id
                                );
                                navigate("/dashboard-global-account-receive");
                              }}
                              className="box grey-bg-two"
                            >
                              <p className="text grey-white-color">Receive </p>
                              <div className="icon-box">
                                <figure className="img-box">{downIcon}</figure>
                              </div>
                            </div>
                            {/* box end */}
                            {/* box start */}
                            {user_resource_and_permissions?.allowed_permissions?.includes(
                              "can_setup_foreign_beneficiary"
                            ) && (
                              <div
                                onClick={() => {
                                  localStorage.setItem(
                                    "global-acc-id",
                                    single_global_account?.global_account?.id
                                  );
                                  navigate("/dashboard-global-account-send");
                                }}
                                className="box grey-bg-two"
                              >
                                <p className="text grey-white-color">Send </p>
                                <div className="icon-box rotate">
                                  <figure className="img-box">
                                    {downIcon}
                                  </figure>
                                </div>
                              </div>
                            )}
                            {/* box end */}
                            {/* box start */}
                            {/* {all_global_account
                              ?.global_accounts_available_for_current_business
                              ?.length > 1 && ( */}
                            {user_resource_and_permissions?.allowed_permissions?.includes(
                              "can_swap_currencies"
                            ) && (
                              <div
                                onClick={() => {
                                  navigate("/dashboard-global-account-swap");
                                }}
                                className="box grey-bg-two"
                              >
                                <p className="text grey-white-color">Swap </p>
                                <div className="icon-box">
                                  <figure className="img-box">
                                    {downUpIcon}
                                  </figure>
                                </div>
                              </div>
                            )}
                            {/* )} */}
                            {/* box end */}
                          </>
                        )}
                      </div>
                      {/* receive send swap box end */}
                    </div>
                    {/* amount receive swaop box end */}
                  </div>
                  {/* amount swap receive send wrap end */}

                  {/* transaction wrap start */}
                  <div
                    // onClick={() => {
                    //   console.log(single_global_account);
                    // }}
                    className="transaction-global-wrap-box"
                  >
                    <p className="title grey-white-color global-single-title">
                      Recent Transactions
                    </p>
                    {/* trans content box start */}
                    <div className="transaction-content-box">
                      {single_global_account?.transactions?.data?.length < 1 ? (
                        <>
                          {loading ? (
                            <>
                              <NoContentBox loading={true} />
                            </>
                          ) : (
                            <div className="no-global-acc-box">
                              <div className="center-div">
                                <div className="img-box-no-content"></div>
                                <p className="text grey-white-color">
                                  You don’t have any USD <br /> transaction yet,
                                  start transacting
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              paddingBottom:
                                single_global_account?.transactions
                                  ?.next_page_url_params ||
                                single_global_account?.transactions
                                  ?.prev_page_url_params
                                  ? "10rem"
                                  : "2rem",
                            }}
                            className="global-trans-table-box"
                          >
                            {single_global_account?.transactions?.data?.map(
                              (chi, idx) => {
                                const { amount, direction, email } = chi;
                                return (
                                  <div
                                    className={`item-row ${
                                      size?.width > 768
                                        ? "border-theme-bottom"
                                        : "border-theme"
                                    }`}
                                    style={{ cursor: "pointer" }}
                                    key={idx}
                                    onClick={() => {
                                      setSingleObj(chi);
                                      setShowModal((prev) => {
                                        return { ...prev, view: true };
                                      });
                                      // console.log(chi);
                                      // console.log(
                                      //   formatMetaData(chi?.meta_data)
                                      // );
                                    }}
                                  >
                                    <div className="img-wrap">
                                      {loading ? (
                                        <Skeleton
                                          width={30}
                                          height={30}
                                          circle
                                        />
                                      ) : (
                                        <figure
                                          className={`img-box-cre-deb ${
                                            direction === "debit" &&
                                            "img-box-debit"
                                          } img-box-debit-credit`}
                                        >
                                          {direction === "credit" ? (
                                            <svg
                                              width="10"
                                              height="10"
                                              viewBox="0 0 10 10"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="img"
                                            >
                                              <path
                                                d="M9 1L1 9M1 9H6.33333M1 9V3.66667"
                                                stroke="#1ACE37"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          ) : (
                                            <svg
                                              width="10"
                                              height="10"
                                              viewBox="0 0 10 10"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="img"
                                            >
                                              <path
                                                d="M1 9L9 1M9 1H3.66667M9 1V6.33333"
                                                stroke="#FF0F00"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          )}
                                        </figure>
                                      )}
                                    </div>
                                    <div className="label-value-box">
                                      {loading ? (
                                        <>
                                          <Skeleton
                                            width={90}
                                            height={22}
                                            // circle
                                          />
                                          <Skeleton
                                            width={180}
                                            height={12}
                                            // circle
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <p className="value">
                                            {chi?.type === "swap"
                                              ? "Swap"
                                              : direction === "credit"
                                              ? `${
                                                  trimLongString(
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.title,
                                                    30
                                                  ) ||
                                                  trimLongString(
                                                    formatMetaData(
                                                      chi?.meta_data
                                                    )?.narration,
                                                    35
                                                  )
                                                }`
                                              : formatMetaData(chi?.meta_data)
                                                  ?.beneficiary_name ||
                                                trimLongString(
                                                  formatMetaData(chi?.meta_data)
                                                    ?.narration,
                                                  35
                                                ) ||
                                                capitalizeFirstLetter(
                                                  chi?.type
                                                )}
                                          </p>
                                          <p className="label grey-white-color">
                                            {chi?.type === "swap" &&
                                            direction === "debit"
                                              ? `Swap from ${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.from
                                                } to ${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.to
                                                }`
                                              : chi?.type === "swap" &&
                                                direction === "credit"
                                              ? `Swap to ${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.to
                                                } from ${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.from
                                                }`
                                              : chi?.type === "system_deduction"
                                              ? `${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.first_name
                                                } ${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.last_name
                                                }`
                                              : chi?.type === "system_addition"
                                              ? `${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.first_name
                                                } ${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.last_name
                                                }`
                                              : direction === "debit"
                                              ? `${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.bank_name
                                                }  • ${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.account_number
                                                }`
                                              : `${
                                                  formatMetaData(chi?.meta_data)
                                                    ?.customer_email || email
                                                }` || email}
                                          </p>
                                        </>
                                      )}
                                    </div>
                                    <div className="label-value-box label-value-box-right ">
                                      {loading ? (
                                        <>
                                          <div
                                            style={{ marginLeft: "auto" }}
                                            className=""
                                          >
                                            <Skeleton
                                              width={50}
                                              height={20}
                                              style={{
                                                marginLeft: "auto",
                                                textAlign: "right",
                                              }}
                                            />
                                          </div>
                                          <Skeleton
                                            width={120}
                                            height={12}
                                            // circle
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <p className="value">
                                            {" "}
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: `${getSymbolTotalAmount(
                                                  single_global_account
                                                    ?.global_account?.currency
                                                )}`,
                                              }}
                                            />
                                            {`${formatNumWithCommaNaira(
                                              amount
                                            )}`}
                                          </p>
                                          <p className="label ">
                                            <DateFragmentBox
                                              date={chi?.created_at}
                                              dontShowTime={true}
                                              dontShowAgo={true}
                                            />
                                            {/* {formatDate(chi?.created_at)} */}
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {/* trans content box end */}
                  </div>
                  {/* transaction wrap end */}
                </div>
                {/* centered wrap end */}
              </div>
            </>
          )}
        </ContainerWrapper>
      </DashboardLayout>

      <DownloadReceiptModal
        visible={showModal?.view}
        onClose={() =>
          setShowModal((prev) => {
            return { ...prev, view: false };
          })
        }
        detail={singleObj}
        all_global_account={all_global_account}
      />
    </>
  );
};

export default GlobalAccountSingleIndex;
