import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = "tpoagsuebszYhaVque7nFavRoPPjdgab";

function encrypt(payload) {
  // console.log(payload);
  // if(payload?.type === "formData")
  //   if (payload.type && payload.type === "formData") {
  //     console.log("yes");
  //     return { data: payload };
  //   }
  const iv = CryptoJS.lib.WordArray.random(16);
  const cipher = CryptoJS.AES.encrypt(
    JSON.stringify(payload),
    CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  const encrypted = iv.concat(cipher.ciphertext).toString(CryptoJS.enc.Hex);
  return { data: encrypted };
}



async function decrypt(data) {
  const hash = data.data ? data.data : "null";
  const ciphertext = CryptoJS.enc.Hex.parse(hash);
  const iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();

  const encrypted = ciphertext.clone();
  encrypted.words.splice(0, 4); // Remove IV from the ciphertext
  encrypted.sigBytes -= 16;

  const decipher = CryptoJS.AES.decrypt(
    { ciphertext: encrypted },
    CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  const decrypted = await decipher.toString(CryptoJS.enc.Utf8);
  let val;
  if (data?.status === "fail") {
    const newObj = {
      status: data?.status,
      data: JSON.parse(decrypted),
      message: JSON.parse(decrypted),
    };

    val = newObj;
  }
  if (data?.status === "success") {
    const parsed = await JSON.parse(decrypted);
    //   console.log(JSON.parse(parsed), 'unu')
    const newObj = JSON.parse(parsed);
    const obj = {
      status: data?.status,
      message: newObj.message,
      data: newObj.data,
    };
    val = obj;
  }
  //  console.log(val);
  return val;
}

export { encrypt, decrypt };
