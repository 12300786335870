import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import "../../../styles/dashboard/payroll/PayrollCretaeIndex.css";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import {
  RavenCheckBox,
  RavenInputField,
  // RavenToggleSlide,
} from "@ravenpay/raven-bank-ui";
import bankIcon from "../../../assets/payroll-other-bank-icon.svg";
import ravenIcon from "../../../assets/payroll-raven-bank-icon.svg";
import { FaCheck } from "react-icons/fa";
// import yellowInfo from "../../../assets/yellow-info.svg";
// import switchIcon from "../../../assets/payroll-switch-icon.svg";
import { PayrollTaxCalc } from "./PayrollTaxCalc";
import { ThreeDots } from "react-loader-spinner";
import { fetchBanks, validateBankAccount } from "../../../redux/transaction";
import { useSelector, useDispatch } from "react-redux";
import {
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  reactSelectStyle,
  removeCountryCode,
  removeLeadingZeroFromString,
  sumAllNum,
} from "../../../utils/helper/Helper";
import {
  // getPayrollSetup,
  getSingleEmployee,
  getSingleEmployeeByEmail,
  // manualAddEmployeeToPayroll,
  updateEmployeeDetails,
  validateRavenUserName,
} from "../../../redux/payroll";
// import moment from "moment";
import SuccessModal from "../../../components/reusables/SuccessModal";
import useDebounce from "../../../utils/helper/UseDebounce";
import ActionContext from "../../../context/ActionContext";
import NewPreviewDetailBox from "./NewPreviewDetailBox";

const EditEmployee = () => {
  const dispatch = useDispatch();
  const { bankList, loadingVerify } = useSelector((state) => state.transaction);
  const { loadingManual, loading, loadingDetail } = useSelector(
    (state) => state.payroll
  );
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const stepList = ["Employee Info", "Bank details", "Add deductions"];
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  // const [verify, setVerify] = useState(false);
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const [nhf, setNhf] = useState("0");
  const [pension, setPension] = useState("0");
  const [payee, setPayee] = useState("0");
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    fname: "",
    lname: "",
    amount: "",
    email: "",
    bank: "",
    acc_num: "",
    acc_name: "",
    nhf_status: 0,
    pension_status: 0,
    payee_status: 0,
    amountMinusTax: "",
    totalDeduction: "",
    phone: "",
    raven_username: "",
    addCheckVal: false,
    selected_account_number: "",
  });

  // console.log(PayrollTaxCalc(formatNumWithoutCommaNaira(details?.amount)));

  const optionList = [
    { name: "Raven Bank", icon: ravenIcon },
    { name: "Other Banks", icon: bankIcon },
  ];
  const [settleOption, setSettleoption] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };
  // const [checkDisabled, setCheckDisabled] = useState({
  //   raven: false,
  //   bank: false,
  // });
  useEffect(() => {
    const email = actionCtx?.employeeEmail;
    if (email) {
      getEmployeeDetailByEmail(email);
    } else {
      // console.log("yes");
      getEmployeeInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmployeeDetailByEmail = async (param) => {
    const data = await dispatch(getSingleEmployeeByEmail(param));

    if (data?.payload?.status === "success") {
      const obj = data?.payload?.data.employee_details;
      localStorage.setItem("payroll-employee-id", obj?.id);
      actionCtx.setEmployeeEmail("");
      const nhf = String(obj?.nhf);
      setNhf(nhf);
      const payee = String(obj?.paye);
      setPayee(payee);
      const pension = String(obj?.pension);
      setPension(pension);
      setDetails({
        ...obj?.employee,
        fname: obj?.first_name,
        lname: obj?.last_name,
        amount: obj?.gross_salary,
        email: obj?.email,
        bank: { label: obj?.bank, value: obj?.bank_code },
        acc_num: obj?.account_number,
        acc_name: obj?.account_name,
        nhf_status: 0,
        pension_status: 0,
        payee_status: 0,
        amountMinusTax: "",
        totalDeduction: "",
        phone: obj?.phone_number,
        raven_username: obj?.raven_username,
        payment_channel: obj?.payment_channel,
      });
      obj?.payment_channel === "raven"
        ? setSettleoption("Raven Bank")
        : setSettleoption("Other Banks");

      // setCheckDisabled((prev) => {
      //   return {
      //     ...prev,
      //     raven: obj?.raven_username ? true : false,
      //     bank: obj?.bank ? true : false,
      //   };
      // });
    }
  };

  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_update_employee_records"
      )
    ) {
      navigate("/dashboard-payroll");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_resource_and_permissions]);

  const getEmployeeInfo = async () => {
    const id = localStorage.getItem("payroll-employee-id");
    const obj = {
      id: id,
    };
    const data = await dispatch(getSingleEmployee(obj));
    if (data?.payload?.status === "success") {
      const obj = data?.payload?.data;
      //   console.log(obj);
      const nhf = String(obj?.employee?.nhf);
      setNhf(nhf);
      const payee = String(obj?.employee?.paye);
      setPayee(payee);
      const pension = String(obj?.employee?.pension);
      setPension(pension);
      setDetails({
        ...obj?.employee,
        fname: obj?.employee?.first_name,
        lname: obj?.employee?.last_name,
        amount: obj?.employee?.gross_salary,
        email: obj?.employee?.email,
        bank: { label: isJSONString(obj?.employee?.bank) ? formatMetaData(obj?.employee?.bank)?.label : obj?.employee?.bank, value: obj?.employee?.bank_code },
        acc_num: obj?.employee?.account_number,
        acc_name: obj?.employee?.account_name,
        nhf_status: 0,
        pension_status: 0,
        payee_status: 0,
        amountMinusTax: "",
        totalDeduction: "",
        phone: obj?.employee?.phone_number,
        raven_username: obj?.employee?.raven_username,
        payment_channel: obj?.employee?.payment_channel,
      });
      obj?.employee?.payment_channel === "raven"
        ? setSettleoption("Raven Bank")
        : setSettleoption("Other Banks");

      // setCheckDisabled((prev) => {
      //   return {
      //     ...prev,
      //     raven: obj?.employee?.raven_username ? true : false,
      //     bank: obj?.employee?.bank ? true : false,
      //   };
      // });
    }
  };

  useEffect(() => {
    dispatch(fetchBanks());
    // getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name } = chi;
      return { label: name, value: code };
    });
    return list;
  };

  // validate bank account details
  const validateBankDetails = async (e) => {
    const payload = {
      bank_code: e || details?.bank.value || details?.bank?.bank_code,
      account_number: details.acc_num,
    };
    // console.log(payload);
    if (step === 2) {
      const data = await dispatch(validateBankAccount(payload));
      // console.log(data);
      if (data?.payload?.data.status === "success") {
        calculatTaxValue();
        setDetails((prev) => {
          return { ...prev, acc_name: data?.payload?.data.data };
        });
        // setStage(3);
      }
    }
  };

  useEffect(() => {
    if (
      details?.bank &&
      formatNumWithoutCommaNaira(details?.acc_num)?.length > 9
    ) {
      validateBankDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.acc_num || details?.bank]);

  const Paye = PayrollTaxCalc(
    formatNumWithoutCommaNaira(details?.amount)
  )?.monthlyPaye;
  const Pension = PayrollTaxCalc(
    formatNumWithoutCommaNaira(details?.amount)
  )?.monthlyPension;
  const Nhf = PayrollTaxCalc(
    formatNumWithoutCommaNaira(details?.amount)
  )?.monthlyNhf;

  useEffect(() => {
    if (step === 3) {
      calculatTaxValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nhf, payee, pension]);

  const calculatTaxValue = () => {
    if (payee === "1" && pension === "1" && nhf === "1") {
      const newVal =
        formatNumWithoutCommaNaira(details?.amount) -
        sumAllNum([Paye, Pension, Nhf]);
      const deduct = sumAllNum([Paye, Pension, Nhf]);
      setDetails((prev) => {
        return { ...prev, amountMinusTax: newVal, totalDeduction: deduct };
      });
      return;
    }
    if (payee === "0" && pension === "0" && nhf === "0") {
      // console.log(details?.amount);
      setDetails((prev) => {
        return { ...prev, amountMinusTax: details?.amount, totalDeduction: 0 };
      });
      return;
    }
    if (payee === "0" && pension === "0") {
      const newVal =
        formatNumWithoutCommaNaira(details?.amount) - sumAllNum([Nhf]);
      const deduct = sumAllNum([Nhf]);
      setDetails((prev) => {
        return { ...prev, amountMinusTax: newVal, totalDeduction: deduct };
      });
      return;
    }
    if (nhf === "0" && pension === "0") {
      const newVal =
        formatNumWithoutCommaNaira(details?.amount) - sumAllNum([Paye]);
      const deduct = sumAllNum([Paye]);
      setDetails((prev) => {
        return { ...prev, amountMinusTax: newVal, totalDeduction: deduct };
      });
      return;
    }
    if (nhf === "0" && payee === "0") {
      const newVal =
        formatNumWithoutCommaNaira(details?.amount) - sumAllNum([Pension]);
      const deduct = sumAllNum([Pension]);
      setDetails((prev) => {
        return { ...prev, amountMinusTax: newVal, totalDeduction: deduct };
      });
      return;
    }
    if (nhf === "0") {
      // console.log("yes");
      const newVal =
        formatNumWithoutCommaNaira(details?.amount) -
        sumAllNum([Paye, Pension]);
      const deduct = sumAllNum([Paye, Pension]);
      setDetails((prev) => {
        return { ...prev, amountMinusTax: newVal, totalDeduction: deduct };
      });
      return;
    }
    if (payee === "0") {
      const newVal =
        formatNumWithoutCommaNaira(details?.amount) - sumAllNum([Pension, Nhf]);
      const deduct = sumAllNum([Pension, Nhf]);
      setDetails((prev) => {
        return { ...prev, amountMinusTax: newVal, totalDeduction: deduct };
      });
      return;
    }
    if (pension === "0") {
      const newVal =
        formatNumWithoutCommaNaira(details?.amount) - sumAllNum([Paye, Nhf]);
      const deduct = sumAllNum([Paye, Nhf]);
      setDetails((prev) => {
        return { ...prev, amountMinusTax: newVal, totalDeduction: deduct };
      });
      return;
    }
  };

  const handleSubmit = async () => {
    const id = localStorage.getItem("payroll-employee-id");
    const obj = {
      // ...details,
      gross_salary: formatNumWithoutCommaNaira(details?.amount),
      first_name: details?.first_name,
      last_name: details?.last_name,
      email: details?.email,
      payment_channel: details?.payment_channel,
      nhf: nhf,
      payee: payee,
      pension: pension,
      bank: details?.bank?.label,
      bank_code: details?.bank?.value,
      raven_username: details?.raven_username,
      phone: `0${removeLeadingZeroFromString(details?.phone)}`,
      id: id,
      account_number: details?.acc_num,
    };
    // console.log(details);
    // console.log(obj);
    // return;
    const data = await dispatch(updateEmployeeDetails(obj));

    if (data?.payload?.data?.status === "success") {
      navigate("/dashboard-payroll-employee-profile");
    }
  };

  const validateRavenUsernameFunc = async () => {
    const data = await dispatch(validateRavenUserName(details?.raven_username));
    if (data?.payload?.status === "success") {
      const name = `${data?.payload?.data?.fname} ${data?.payload?.data?.lname}`;
      setValidateRavenValName(name);
    }
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  // const [userInfo, setUserInfo] = useState(false);
  const [validateRavenNameVal, setValidateRavenValName] = useState("");
  const debouncedSearchTerm = useDebounce(details?.raven_username, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 2 && step === 2) {
      validateRavenUsernameFunc();
    }

    if (isMount && debouncedSearchTerm?.length < 2) {
      // setDetails((prev) => {
      //   return { ...prev, raven_username: "" };
      // });
      setValidateRavenValName("");
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  function isJSONString(value) {
    // console.log(value);
    if (value) {
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    }
  }

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Update Employee Details`}
          text={`Add members to your payroll setup.`}
          back
          onBack={() => {
            navigate(-1);
          }}
        />
        <ContainerWrapper
          activeStep={step}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          loading={loadingManual || loadingDetail || loading}
          stepList={stepList}
          btnText={
            step === 1
              ? "Next to Bank details"
              : step === 2
              ? "Save & proceed"
              : "Save & Update Payroll"
          }
          onBtnClick={() => {
            // console.log(details);
            step < 3 ? setStep(step + 1) : handleSubmit();
            step === 2 && calculatTaxValue();
            // step === 2 && calculatTaxValue()
          }}
        >
          <div className="payroll-manual-wrap-box">
            {/* step one start ---------------------------------- */}
            {step === 1 && (
              <>
                <div className="form-wrap-box">
                  <div className="two-group">
                    <RavenInputField
                      label={`Employee First name* `}
                      color={`black-light`}
                      placeholder={`E.g Timi `}
                      value={details?.fname}
                      type={`text`}
                      onChange={handleChange}
                      name={`fname`}
                    />
                    <RavenInputField
                      label={`Employee Last name* `}
                      color={`black-light`}
                      placeholder={`E.g Ayeni`}
                      value={details?.lname}
                      type={`text`}
                      onChange={handleChange}
                      name={`lname`}
                    />
                  </div>
                  <RavenInputField
                    label={`Employee email address* `}
                    color={`black-light`}
                    placeholder={`mail@you.com`}
                    name={`email`}
                    value={details?.email}
                    type={`email`}
                    onChange={handleChange}
                    disabled
                  />
                  <RavenInputField
                    label={`Employee Phone No* `}
                    color={`black-light`}
                    placeholder={`E.g 081 623 6....`}
                    name={`phone`}
                    value={removeCountryCode(details?.phone)}
                    type={`phone`}
                    onChange={handleChange}
                  />
                  {/* <RavenInputField
                  label={`Date of birth* `}
                  color={`black-light`}
                  placeholder={`mail@you.com`}
                  name={`date`}
                  value={fromDate}
                  type={`date`}
                  onChange={(e) => {
                    setFromDate(moment(e[0]).format("YYYY-MM-DD HH:mm:ss"))
                  }}
                /> */}
                  <RavenInputField
                    label={`Employee Gross Salary* `}
                    color={`black-light`}
                    placeholder={`150,000`}
                    name={`amount`}
                    value={details?.amount}
                    type={`number`}
                    onChange={handleChange}
                    thousandFormat
                  />
                </div>
              </>
            )}
            {/* step one end ---------------------------------- */}
            {/* step two start ---------------------------------- */}
            {step === 2 && (
              <>
                <div className="form-wrap-box">
                  <p>Select Payment Channel</p>
                  <div className="select-wrap-box">
                    {optionList?.map((chi, idx) => {
                      const { name, icon } = chi;
                      return (
                        <div
                          key={idx}
                          className={`box grey-bg hover-white-class select-option-class ${
                            settleOption === name &&
                            "select-option-class-active"
                          }`}
                          onClick={() => {
                            setSettleoption(name);
                            setDetails((prev) => {
                              return {
                                ...prev,
                                payment_channel:
                                  name === "Raven Bank"
                                    ? "raven"
                                    : "other banks",
                              };
                            });
                            // setVerify(false);
                          }}
                        >
                          <div className="check-box">
                            <FaCheck className="icon" />
                          </div>
                          <figure className="img-box">
                            <img src={icon} alt="" className="img" />
                          </figure>
                          <p className="name">{name}</p>
                        </div>
                      );
                    })}
                  </div>
                  {/* select option end */}
                  <div
                    className={`show-hide-box ${
                      settleOption === "Raven Bank" && "show-hide-box-visible"
                    }`}
                  >
                    <RavenInputField
                      color={`black-light`}
                      type="text"
                      label={`Raven username* `}
                      value={details?.raven_username}
                      //   disabled={checkDisabled?.raven}
                      onChange={handleChange}
                      placeholder={`E.g akot`}
                      name={`raven_username`}
                      labelSpanText={
                        loading ? (
                          <div
                            style={{
                              padding: "0rem",
                              display: "flex",
                              justifyContent: "flex-end",
                              transform: "translateX(1.5rem)",
                            }}
                            className="load-wrap"
                          >
                            <ThreeDots
                              height="10"
                              width="80"
                              radius="9"
                              color="#020202"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <>{validateRavenNameVal ? "Verified" : "Verify"}</>
                        )
                      }
                      labelClassName={`verify-text`}
                      onActionClick={() => validateRavenUsernameFunc()}
                    />
                    {/* verify name box start */}
                    <div
                      className={`verify-name-box ${
                        validateRavenNameVal && "verify-name-box-show"
                      }`}
                      style={{
                        marginTop: "-1.5rem",
                      }}
                    >
                      <RavenCheckBox
                        checked={true}
                        color={`black-light`}
                        className={`check`}
                      />
                      <p className="text">{validateRavenNameVal}</p>
                    </div>
                    {/* verify name box end */}
                  </div>
                  {/* end of show hide */}
                  {/* show end start */}
                  <div
                    className={`show-hide-box ${
                      settleOption === "Other Banks" && "show-hide-box-visible"
                    }`}
                  >
                    <RavenInputField
                      color={`black-light`}
                      type="select"
                      label={`Select bank* `}
                      value={details?.bank}
                      selectStyles={reactSelectStyle}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, bank: e };
                        });
                      }}
                      //   disabled={checkDisabled?.bank}
                      style={{
                        marginTop: "-2rem",
                        position: "relative",
                        zIndex: "10",
                      }}
                      placeholder={`Select bank`}
                      selectValue={details?.bank}
                      selectOption={formatSelectOption(bankList)}
                    />
                    <div className="">
                      <RavenInputField
                        // disabled={checkDisabled?.bank}
                        type={`account-number`}
                        label={`Account number* `}
                        placeholder={`11 digit account number`}
                        color={`black-light`}
                        labelSpanText={
                          loadingVerify ? (
                            <div
                              style={{
                                padding: "0rem",
                                display: "flex",
                                justifyContent: "flex-end",
                                transform: "translateX(1.5rem)",
                              }}
                              className="load-wrap"
                            >
                              <ThreeDots
                                height="10"
                                width="80"
                                radius="9"
                                color="#020202"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <>{details?.acc_name ? "Verified" : "Verify"}</>
                          )
                        }
                        labelClassName={`verify-text`}
                        onActionClick={() => validateBankDetails()}
                        value={details?.acc_num}
                        name={`acc_num`}
                        onChange={(e) => {
                          handleChange(e);
                          setDetails((prev) => {
                            return { ...prev, acc_name: "" };
                          });
                        }}
                        maxSize={11}
                      />
                      {/* verify name box start */}
                      <div
                        className={`verify-name-box ${
                          details?.acc_name && "verify-name-box-show"
                        }`}
                      >
                        <RavenCheckBox
                          checked={true}
                          color={`black-light`}
                          className={`check`}
                        />
                        <p className="text">{details?.acc_name}</p>
                      </div>
                      {/* verify name box end */}
                    </div>
                  </div>
                  {/* show end end --- */}
                </div>
              </>
            )}
            {/* step two end ---------------------------------- */}
            {/* step 3 start ----------------------------------- */}
            {step === 3 && (
              <>
          
                <div style={{paddingBottom: "15rem", marginTop: "3rem"}}>
                <NewPreviewDetailBox
                  netSalary={formatNumWithCommaNairaSymbol(details?.amount)}
                  grossSalary={formatNumWithCommaNairaSymbol(
                    details?.amountMinusTax
                  )}
                  amount={details?.amount}
                  name={details?.username}
                  narration={details?.narration}
                  checked={details?.beneficiary}
                  number={details?.phone}
                  totalDeduction={formatNumWithCommaNairaSymbol(
                    details?.totalDeduction
                  )}
                  payeeAmount={formatNumWithCommaNairaSymbol(Paye)}
                  onChangePayee={(e) => {
                    payee === "1" ? setPayee("0") : setPayee("1");
                  }}
                  checkedPayee={payee === "1" ? true : false}
                  payeeValue={payee}
                  pensionAmount={formatNumWithCommaNairaSymbol(Pension)}
                  onChangePension={(e) => {
                    pension === "1" ? setPension("0") : setPension("1");
                  }}
                  checkedPension={pension === "1" ? true : false}
                  pensionValue={pension}
                  nhfAmount={formatNumWithCommaNairaSymbol(Nhf)}
                  onChangeNhf={(e) => {
                    nhf === "1" ? setNhf("0") : setNhf("1");
                  }}
                  checkedNhf={nhf === "1" ? true : false}
                  nhfValue={nhf}
                  addCheckVal={details?.addCheckVal}
                  onAddCheckVal={() => {
                    setDetails((prev) => {
                      return {
                        ...prev,
                        addCheckVal: !details?.addCheckVal,
                      };
                    });
                  }}
                />
              </div>
              </>
            )}
            {/* step 3 end ----------------------------------- */}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Payroll Created Successfully."
        text={``}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default EditEmployee;
