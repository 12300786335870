import {
  RavenButton,
  RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import ChangeSourceAccount from "../../../../components/reusables/ChangeSourceAccount";
import ActionContext from "../../../../context/ActionContext";
import { fundCard, getVirtualCards } from "../../../../redux/card";
import { getAllAccounts } from "../../../../redux/user";
import "../../../../styles/dashboard/card/Modal.css";
import {
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  lowerCaseWrap,
  reactSelectStyle,
} from "../../../../utils/helper/Helper";

const TopUpCardModal = ({ visible, onCancel, onTopUp, value, onFinish }) => {
  const { all_account } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { card, config } = useSelector((state) => state.cards);
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    amount: "",
  });
  const [currentAccountVal, setCurrentAccountVal] = useState(
    actionCtx?.currentAccount
  );
  const { loading } = useSelector((state) => state.cards);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  useEffect(() => {
    setCurrentAccountVal(actionCtx?.currentAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const obj = {
      card_id: String(card?.id),
      amount: formatNumWithoutCommaNaira(details?.amount),
      selected_account_number: currentAccountVal?.account_number,
    };
    // console.log(obj);
    // return
    const data = await dispatch(fundCard(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
      setDetails({
        amount: "",
      });
      dispatch(getVirtualCards());
      dispatch(getAllAccounts());
    }
  };

  const handleCancel = () => {
    setDetails({
      amount: "",
    });
    onCancel();
  };

  const showLabelFormat = (name, acc_num, amount, showBorder) => {
    return (
      <div
        style={{
          cursor: "pointer",
          //   paddingBottom: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: ".3rem",
        }}
        className={`name-account-box`}
      >
        <p
          style={{ fontWeight: 600, fontSize: "1.5rem", color: "#020202" }}
          className="name"
        >
          {name}
        </p>
        <div
          style={{ display: "flex", alignItems: "center", gap: ".6rem" }}
          className="wrap-box"
        >
          <p className="account_num grey-white-color">{acc_num}</p> <p>•</p>
          <p className="account_num grey-white-color">
            {formatNumWithCommaNairaSymbol(amount)}
          </p>
        </div>
      </div>
    );
  };

  const formatOptionOne = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const newList = list?.map((chi, idx) => {
        return {
          ...chi,
          label: showLabelFormat(
            lowerCaseWrap(chi?.custom_account_name),
            chi?.account_number,
            chi?.balance,
            idx !== list?.length - 1 ? true : false
          ),
          value: chi?.account_number,
        };
      });
      return newList;
    }
  };

  const formatObjValue = (param) => {
    return { ...param, label: param?.custom_account_name };
  };

  return (
    <RavenModal
      className={`card-top-up-modal-reuse-wrap`}
      visble={visible}
      effect={`fadeInRight`}
      onClose={handleCancel}
      // loading={loading}
    >
      <div
        style={{ minHeight: "30rem", paddingBottom: "0rem" }}
        className="container-wrap"
      >
        <p className="title-card">
          Top up your card ( <span>{lowerCaseWrap(card?.card_title)}</span> )
        </p>
        <p className="text grey-white-color pin-test">
          Add money to your card ( <span>{card?.card_title}</span> ) to perform
          transactions globally.
        </p>

        <form
          style={{ width: "100%", minHeight: "13rem" }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          autoComplete="off"
          action=""
        >
          <RavenInputField
            id="top-up-card-j-id"
            type={`number`}
            color={`black-light`}
            label={`Amount* `}
            value={details?.amount}
            name={`amount`}
            thousandFormat
            onChange={handleChange}
            placeholder={`Amount to fund into the card`}
            labelColor={`black-light`}
            labelSpanText={
              actionCtx?.showAmount
                ? `Bal: ${
                    card?.currency === "USD"
                      ? formatNumWithCommaNairaSymbol(card?.balance)?.replace(
                          "₦",
                          "$"
                        )
                      : formatNumWithCommaNairaSymbol(card?.balance) || "---"
                  }`
                : "Bal: ₦ ••••••"
            }
            labelClassName={`label-span-amount label-span-theme`}
            numberPrefix={card?.currency === "USD" ? "$" : `₦`}
            onActionClick={() => {
              actionCtx?.setShowAmount(!actionCtx?.showAmount);
            }}
          />
          {details?.amount && (
            <p
              style={{ marginTop: "1rem" }}
              className="text grey-white-color pin-test"
            >
              Amount in naira:{" "}
              <span>
                {formatNumWithCommaNairaSymbol(
                  Number(formatNumWithoutCommaNaira(details?.amount)) *
                    config?.usd_rate
                )}
              </span>
            </p>
          )}
          {/* form group star */}
          <form
            style={{
              width: "100%",
              position: "relative",
              zIndex: 20,
              marginTop: "2rem",
            }}
            action=""
            autoComplete="off"
          >
            <RavenInputField
              selectStyles={reactSelectStyle}
              type="select"
              label="Receiver account* "
              placeholder="e.g main account"
              color="black-light"
              selectOption={formatOptionOne(all_account)}
              onChange={(e) => {
                setCurrentAccountVal(e);
              }}
              value={formatObjValue(currentAccountVal)}
            />
          </form>
          {/* form group end */}
          {/* source acount change box */}
          {/* <ChangeSourceAccount
            onObjChange={(e) => {
              setCurrentAccountVal(e);
              // console.log(e);
            }}
            darkType={true}
            // onChange={() => onClose()}
            title={`Card Settlement Account.`}
            defaultObj={currentAccountVal}
          /> */}
          {/* source account change box end */}
        </form>
        <div
          style={{ paddingTop: "2rem", paddingBottom: "10rem" }}
          className="select-card-request-type-box"
        >
          <div className="description-box-wrap yellow-grey-dark-bg grey-white-color">
            <div className="icon-subtitle">
              <div className="img-wrap">
                <figure className="img-box">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                      stroke="#EA872D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </figure>
              </div>
              <p className="sub-title">Things you need to know</p>
            </div>
            {/* list start */}
            <div className="list-box">
              {/* {details?.currency !== "NGN" && ( */}
              <div className="list-item">
                <div className="black-fade-white-bg"></div>
                <p className="text grey-white-color">{`The current rate is $${formatNumWithCommaNairaSymbol(
                  config?.usd_rate
                )} - $1`}</p>
              </div>
              <div className="list-item">
                <div className="black-fade-white-bg"></div>
                <p className="text grey-white-color">{`Minimum card funding amount is $${formatNumWithCommaNairaSymbol(
                  config?.usd_minimum_fund
                )?.replace("₦", "")}`}</p>
              </div>
              <div className="list-item">
                <div className="black-fade-white-bg"></div>
                <p className="text grey-white-color">{`Maximum card funding amount is $${formatNumWithCommaNairaSymbol(
                  config?.usd_maximum_fund
                )?.replace("₦", "")}`}</p>
              </div>
              {/* )} */}
            </div>
            {/* list end */}
          </div>
        </div>
      </div>
      <div className="two-button-wrap">
        <p onClick={handleCancel} className="cancel grey-white-color">
          Cancel
        </p>
        <RavenButton
          color={`black-light`}
          label="Top up"
          loading={loading}
          size={`small`}
          // onClick={onTopUp}
          disabled={!details?.amount || !currentAccountVal}
          // disabled={!details?.amount}
          onClick={handleSubmit}
          // onClose={onCancel}
        />
      </div>
    </RavenModal>
  );
};

export default TopUpCardModal;
