import moment from "moment";
import {
  RavenInputField,
  RavenModal,
  RavenTabLink,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { generateBankStatement } from "../../../../../redux/settings";
import "../../../../styles/dashboard/setting/SettingModal.css";
import { reactSelectStyle } from "../../../../utils/helper/Helper";
import { FaCheck } from "react-icons/fa";
import { downloadPosStatement } from "../../../../redux/pos";

const GenerateStatementModalPos = ({
  visible,
  onCancel,
  terminalOptions,
  selectOptionVal,
  // setSelectOptionVal,
}) => {
  const [fromDate, setFromDate] = useState("");
  const [specificDate, setSpecificDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [sendToVal, setSendToVal] = useState({
    label: "Download",
    value: "download",
  });
  const [fileTypeVal, setFileTypeVal] = useState({
    label: "PDF",
    value: "pdf",
  });
  const [terminalIdVal, setTerminalIdVal] = useState("")
  const { loadingSettlement } = useSelector((state) => state.pos);
  const dispatch = useDispatch();
  const [generateType, setGenerateType] = useState(0);
  const selectList = ["Range Date Select", "Custom Date Select"];
  const [details, setDetails] = useState({
    selected: "",
  });
  const terminal_id = localStorage.getItem("pos-terminal-id") || "";

  useEffect(() => {
    if(terminal_id){
      setTerminalIdVal(selectOptionVal)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectOptions = [
    { label: "Specific Date", value: "specific_date" },
    { label: "1 month", value: 1 },
    { label: "3 months", value: 3 },
    { label: "6 months", value: 6 },
  ];

  const fileTypeList = [
    { label: "PDF", value: "pdf" },
    { label: "CSV", value: "csv" },
  ];

  const sendToList = [
    { label: "Download", value: "download" },
    { label: "Email", value: "email" },
    { label: "Both", value: "both" },
  ];

  const handleSubmit = async () => {
    // console.log(details);
    let obj;
    if (generateType === 1) {
      const val = {
        stop_date: toDate,
        start_date: fromDate,
        file_type: fileTypeVal?.value,
        send_to: sendToVal?.value,
        tid: terminalIdVal?.value  || "",
      };
      obj = val;
    }
    if (generateType !== 1 && details.selected.value === "specific_date") {
      const val = {
        stop_date: specificDate,
        start_date: specificDate,
        // date: `${specificDate}_${specificDate}`,
        file_type: fileTypeVal?.value,
        tid: terminalIdVal?.value  || "",
        send_to: sendToVal?.value,
      };
      obj = val;
    }
    if (generateType !== 1 && details.selected.value !== "specific_date") {
      const val = {
        range_date: `${details?.selected.value}_months`,
        file_type: fileTypeVal?.value,
        tid: terminalIdVal?.value  || "",
        send_to: sendToVal?.value,
      };
      obj = val;
    }
    // else {
    //   const val = {
    //     date: `${details?.selected.value}_months`,
    //     file_type: fileTypeVal?.value,
    //     send_to: sendToVal?.value,
    //   };
    //   obj = val;
    // }
    // console.log(obj);
    // return;
    const data = await dispatch(downloadPosStatement(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      // downloadCsv(data?.payload?.data.data.url);
      toast.success(` ${data?.payload?.data?.message}`, {});
      if (data?.payload?.data.data?.url && sendToVal?.value !== "email") {
        downloadCsv(data?.payload?.data?.data?.url);
      }
      setDetails((prev) => {
        return { ...prev, selected: "" };
      });
      setSendToVal({
        label: "Download",
        value: "download",
      });
      setFileTypeVal({
        label: "PDF",
        value: "pdf",
      });
      setFromDate("");
      setToDate("");
      setSpecificDate("");
      onCancel();
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  // const formatOptions = (list)

  return (
    <RavenModal
      visble={visible}
      btnColor={`black-light`}
      btnLabel={`Generate Statement`}
      onClose={() => {
        onCancel();
        setFromDate("");
        setToDate("");
        setDetails((prev) => {
          return { ...prev, selected: "" };
        });
      }}
      onBtnClick={handleSubmit}
      loading={loadingSettlement}
      disabled={
        generateType === 0 && details.selected.value !== "specific_date"
          ? !details?.selected
          : generateType === 0 && details.selected.value === "specific_date"
          ? !details?.selected || !specificDate
          : !fromDate || !toDate
      }
    >
      {/* Sttings modal start -------------- */}
      <div className="settings-modal-wrap">
        {/* gnerate mwrap start */}
        <div className="generate-modal-wrap">
          <p className="title">Generate Statement</p>
          <p className="text grey-white-color">
            Select how to generate statement
          </p>
          {/* select wrap start */}
          <div className="select-wrap">
            <RavenTabLink
              color={`black-light`}
              key={`kaja`}
              list={selectList}
              onClick={(e) => {
                setGenerateType(e);
                if (e === 0) {
                  setFromDate("");
                  setToDate("");
                } else {
                  setDetails((prev) => {
                    return { ...prev, selected: "" };
                  });
                  setSpecificDate("");
                }
              }}
              fadedBlack
            />
          </div>
          {/* select wrap end */}
          {/*  */}
          {/* custom start */}
          <div className="input-type-wrap">
            <form
              autoComplete="off"
              action=""
              style={{ width: "100%", position: "relative", zIndex: 300, marginBottom: "1.5rem" }}
            >
              <RavenInputField
                selectOption={terminalOptions?.length > 0 ? [{label: "All terminals", value:""} , ...terminalOptions] : []}
                color={`black-light`}
                type={"select"}
                label={`Filter By Source`}
                placeholder={`Terminal Source`}
                selectStyles={reactSelectStyle}
                value={terminalIdVal}
                onChange={(e) => {
                  // setCurrentPage(1);
                  // localStorage.setItem("pos-terminal-id", e.value);
                  setTerminalIdVal(e);
                  // showFilterOption && setShowFilterOption(false);
                  // setCheckCustom(false);
                }}
                // selectValue={typeFilter}
              />
            </form>
            {/* select wrap type  */}
            <div
              className={`select-type-wrap ${
                generateType === 0 && "select-type-wrap-show"
              }`}
            >
              <form
                action=""
                autoComplete="off"
                style={{ width: "100%", position: "relative", zIndex: 200 }}
              >
                {" "}
                <RavenInputField
                  color={`black-light`}
                  label={`select date`}
                  placeholder={`E.g 1 month`}
                  value={details?.selected}
                  selectStyles={reactSelectStyle}
                  type={`select`}
                  selectOption={selectOptions}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, selected: e };
                    });
                  }}
                />
              </form>
              <div
                className={`custom-type-wrap ${
                  details.selected.value === "specific_date" &&
                  "custom-type-wrap-show"
                }`}
                style={{
                  position: "relative",
                  zIndex: 100,
                  marginTop: "1.5rem",
                }}
              >
                {" "}
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    type={`date`}
                    value={specificDate}
                    dateOptions={{ maxDate: new Date() }}
                    label={`Specific Date`}
                    placeholder={`Select specific from`}
                    onChange={(e) => {
                      setSpecificDate(moment(e[0]).format("YYYY-MM-DD"));
                    }}
                  />
                </form>
              </div>
            </div>
            {/* select wrap end */}
            {/* custom type start */}
            <div
              className={`custom-type-wrap ${
                generateType === 1 && "custom-type-wrap-show"
              }`}
            >
              <form action="" autoComplete="off" style={{ width: "100%" }}>
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`date`}
                  value={fromDate}
                  dateOptions={{ maxDate: new Date() }}
                  label={`From`}
                  placeholder={`Select date from`}
                  onChange={(e) => {
                    setFromDate(moment(e[0]).format("YYYY-MM-DD"));
                  }}
                />
              </form>
              <form action="" autoComplete="off" style={{ width: "100%" }}>
                {" "}
                <RavenInputField
                  placeholder={`Select date to`}
                  dateOptions={{ maxDate: new Date(), minDate: fromDate }}
                  color={`black-light`}
                  type={`date`}
                  value={toDate}
                  label={`To`}
                  onChange={(e) => {
                    setToDate(moment(e[0]).format("YYYY-MM-DD"));
                  }}
                />
              </form>
            </div>
            {/* custom type end */}
          </div>
          {/* custom end */}
          {/* theme preference start */}
          <div className="theme-preference-wrap">
            <div className="btn-text-box">
              <div className="text-box">
                {" "}
                <p className="content-title ">File Type</p>
                <p className="content-text grey-white-color-white">
                  Customise your preferred file type to be generated
                </p>
              </div>
            </div>

            {/* select wrapper start */}
            <div
              className="select-wrapper table-select-font-wrap "
              // style={{ alignSelf: "flex-start", minWidth: "27rem" }}
            >
              {fileTypeList?.map((chi, idx) => {
                const { label, value } = chi;
                return (
                  <div
                    key={idx}
                    className={`child card-bg border-theme ${
                      fileTypeVal.value === value && "child-active"
                    }`}
                    onClick={() => {
                      setFileTypeVal(chi);
                    }}
                  >
                    <span className="grey-white-color">{label}</span>
                    {fileTypeVal.value === value && (
                      <div className="check-box">
                        <FaCheck className="icon" />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {/* select wrapper end */}
          </div>
          {/* theme preference end */}
          {/* theme preference start */}
          <div className="theme-preference-wrap">
            <div className="btn-text-box">
              <div className="text-box">
                {" "}
                <p className="content-title ">File location</p>
                <p className="content-text grey-white-color-white">
                  Customise where file generated will be sent to
                </p>
              </div>
            </div>

            {/* select wrapper start */}
            <div
              className="select-wrapper table-select-font-wrap "
              // style={{ alignSelf: "flex-start", minWidth: "27rem" }}
            >
              {sendToList?.map((chi, idx) => {
                const { label, value } = chi;
                return (
                  <div
                    key={idx}
                    className={`child card-bg border-theme ${
                      sendToVal.value === value && "child-active"
                    }`}
                    onClick={() => {
                      setSendToVal(chi);
                    }}
                  >
                    <span className="grey-white-color">{label}</span>
                    {sendToVal.value === value && (
                      <div className="check-box">
                        <FaCheck className="icon" />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {/* select wrapper end */}
          </div>
          {/* theme preference end */}
        </div>
        {/* gnerate mwrap end */}
      </div>
      {/* settings modal end -------------- */}
    </RavenModal>
  );
};

export default GenerateStatementModalPos;
