import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React from "react";
import "../../styles/reusables/ReusableScheduleBox.css";
import moment from "moment";
import { FaAngleDown } from "react-icons/fa";

const SelectScheduleBox = ({
  onChange,
  value,
  id,
  enableTime,
  onReset,
  textSchedule,
}) => {
  return (
    <div className="reusable-schedule-box animate-move-up-class">
      <p className="text-schedule">
        {textSchedule || `When would you like to send this money?`}
      </p>
      <div className="date-arrow-wrap">
        <form autoComplete="off" action="">
          {" "}
          <RavenInputField
            type={`date`}
            value={value}
            onChange={(e) => {
              onChange && onChange(moment(e[0]).format("YYYY-MM-DD HH:mm:ss"));
            }}
            id={`select-raven-schedule-${id}`}
            color={`black-light`}
            className={`input-date  ${value && "input-date-long"}`}
            placeholder="Now"
            enableTime={true}
            // scheduleFormat={true}
          />
        </form>

        {!value && (
          <label htmlFor={`select-raven-schedule-${id}`}>
            <FaAngleDown className="icon" />
          </label>
        )}
      </div>
      {value && (
        <p
          onClick={() => {
            onReset && onReset();
          }}
          className="reset"
        >
          Reset
        </p>
      )}
    </div>
  );
};

export default SelectScheduleBox;
