import React, { useState } from "react";
// import NewSelectScheduleBox from "../../../../components/reusables/NewSelectScheduleBox";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";Group 238182raven-default-bank-img.svg
import "../../../styles/fragments/PreviewDetailsCommonBox.css";
import bankIconDefault from "../../../assets/bank-general-img.svg";
// import bankIconDefault from "../../../assets/raven-default-bank-img.svg";
// import mtnICon from "../../../assets/bill-mtn-icon.svg";
// import airtelICon from "../../../assets/bill-airtel-icon.svg";
// import mobileICon from "../../../assets/bill-moblie-icon.svg";
// import gloICon from "../../../assets/bill-glo-icon.svg";
import moment from "moment";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  formatTypeFunction,
  //   trimLongString,
  //   formatNumWithCommaNairaSymbol,
  //   formatNumWithoutCommaNaira,
  //   trimLongString,
} from "../../../utils/helper/Helper";
import { FaTimes } from "react-icons/fa";
// import ActionContext from "../../../context/ActionContext";
// import { formatNumWithComma } from "../../../utils/Helpers";
// import { useSelector } from "react-redux";
import ChangeSourceAccount from "../../../components/reusables/ChangeSourceAccount";
import { RavenRadioCheck } from "@ravenpay/raven-bank-ui";
// import { RavenToggleSlide } from "@ravenpay/raven-bank-ui";

const NewPreviewDetailBox = ({
  collapse,
  amount,
  name,
  bank,
  account_name,
  narration,
  onChange,
  value,
  id,
  checked,
  previewOpen,
  setPreviewOpen,
  onChangeSchedule,
  valueSchedule,
  rate,
  details,
  bulkAmount,
  setDetails,
  setShowModal,
  handleRemoveFromList,
  card_theme,
  list,
  card_fee,
  card_type,
  currency,
}) => {
  const [viewAll, setViewAll] = useState(false);
  //   const actionCtx = useContext(ActionContext);
  //   const { business } = useSelector((state) => state.user);

  //   const InfoIcon = (
  //     <svg
  //       className="img"
  //       width="12"
  //       height="12"
  //       viewBox="0 0 12 12"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
  //         stroke="#8B8B8B"
  //         strokeWidth="1.5"
  //         stroke-linecap="round"
  //         stroke-linejoin="round"
  //       />
  //     </svg>
  //   );

  return (
    <div className="new-preview-detail-box-wrap card-bg border-theme">
      {/* img top box start */}
      <div
        // style={{ marginTop: "3rem" }}
        className="img-top-box-wrap border-theme-bottom"
      >
        <div className="img-wrap">
          <figure className="img-box">
            <img src={bankIconDefault} alt="" className="img" />
          </figure>
          {/* <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div> */}
        </div>
        {/* amount date box styart */}
        <div style={{ marginTop: "-1rem" }} className="amount-date-box">
          <p className="amount">
            {list?.length > 0
              ? bulkAmount
              : formatNumWithCommaNairaSymbol(
                  (Number(formatNumWithoutCommaNaira(amount)) +
                    Number(formatNumWithoutCommaNaira(card_fee))) *
                    rate
                ) || "---"}
          </p>
          <p className="date grey-white-color-white">
            {valueSchedule
              ? formatDate(valueSchedule)
              : "Total charge in naira" ||
                formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div
        style={{ paddingBottom: "0rem", marginBottom: "0rem" }}
        className="middle-content-wrap"
      >
        {collapse ? (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Recipients:</p>
              <div className="value">
                <div
                  onClick={() => {
                    viewAll ? setViewAll(false) : setViewAll(true);
                  }}
                  className="see-all-box"
                >
                  <span>({list?.length || 0})</span>
                  <p className="see-all-text">
                    {viewAll ? `Close` : `See all`}
                  </p>
                </div>
                {/*  */}
                <div
                  className={`view-all-box card-bg ${
                    viewAll && "view-all-box-show"
                  }`}
                >
                  {" "}
                  <div className="wrap">
                    {list?.map((chi, idx) => {
                      return (
                        <div key={idx} className="child-wrap">
                          <figure className="img-box">
                            <img src={bankIconDefault} alt="" className="img" />
                          </figure>
                          <div className="text-box">
                            <p className="name">{`+234 ${chi?.phone}`}</p>
                            <p className="text grey-white-color">{` ₦ ${chi?.amount}`}</p>
                          </div>
                          <div
                            onClick={() => {
                              handleRemoveFromList(chi);
                            }}
                            className="cancel-box"
                          >
                            <FaTimes className="icon" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            {/* <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">{bulkAmount || 0}</div>
            </div> */}
            {/* box end */}
          </>
        ) : (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Card Type:</p>
              <p className="value">{card_type || ""}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Card theme:</p>
              <p className="value">
                {formatTypeFunction(card_theme)?.replace(".png", "") || "---"}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white"> Currency:</p>
              <p className="value">{currency || "---"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">
                Funding Card amount:
              </p>
              <p className="value">
                {currency === "USD" && "$"}
                {formatNumWithCommaNairaSymbol(
                  formatNumWithoutCommaNaira(amount)
                )?.replace("₦", "") || "---"}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Card fee:</p>
              <p className="value">
                {currency === "USD" && "$"}
                {formatNumWithCommaNairaSymbol(card_fee)?.replace("₦", "") ||
                  "---"}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            {/* <div className="box">
              <p className="label grey-white-color-white">Note:</p>
              <p className="value">{reason || "---"}</p>
            </div> */}
            {/* box end */}
          </>
        )}
      </div>
      {/* middle content wrap end */}
      {/* add for another user box start */}
      <div className="add-another-user-option-box border-theme-top">
        <p
          onClick={() => {
            //   console.log(config);
          }}
          className="text"
        >
          Who are you creating this card for.
        </p>
        {/* select user box start */}
        <div className="select-user-box">
          {/* box start */}
          <div className="box">
            <RavenRadioCheck
              value={details?.option}
              checked={details?.option === "business" ? true : false}
              name={`user-type`}
              color={`black-light`}
              onChange={() => {
                setDetails((prev) => {
                  return {
                    ...prev,
                    option: "business",
                    bvn: "",
                    nin: "",
                    card_name: "",
                    third_party_email: "",
                  };
                });
              }}
              id={`fgh`}
            />
            <label htmlFor="input-radio-fgh">Yourself</label>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <RavenRadioCheck
              id={`fgh9u`}
              value={details?.option}
              checked={details?.option === "third_party" ? true : false}
              name={`user-type`}
              color={`black-light`}
              onChange={() => {
                setShowModal((prev) => {
                  return { ...prev, add_user: true };
                });
                setDetails((prev) => {
                  return { ...prev, option: "third_party" };
                });
              }}
            />
            <label htmlFor="input-radio-fgh9u">Another user</label>
          </div>
          {/* box end */}
        </div>
        {/* select user box end */}
        {/* detail- box preview start */}
        {details?.option && (
          <div className="detail-preview-two grey-bg">
            <div className="title-bvn-box">
              <p className="title">
                {details?.card_name || "Raven Marketing Team"}
              </p>
              <div className="bvn-nin-box">
                <p>
                  <span className="grey-white-color">NIN:</span>{" "}
                  <span>•••••••••••••••</span>
                </p>
                <p>
                  <span className="grey-white-color">BVN:</span>{" "}
                  <span>•••••••••••••••</span>
                </p>
              </div>
            </div>
            {details?.option !== "business" && (
              <div
                onClick={() => {
                  setDetails((prev) => {
                    return {
                      ...prev,
                      option: "",
                      bvn: "",
                      nin: "",
                      card_name: "",
                      third_party_email: "",
                    };
                  });
                }}
                className="remove add-error-box"
              >
                <span>Remove</span>
              </div>
            )}
          </div>
        )}
        {/* detail- box preview end */}
      </div>
      {/* add for another user box end */}
      {/* account drop wrap start */}
      <div style={{ marginTop: "0rem" }}>
        {/* source acount change box */}
        <ChangeSourceAccount />
        {/* source account change box end */}
      </div>
      {/* account drop wrap end */}
      {/* <div
        className="border border-theme-top"
        style={{ marginTop: "2rem" }}
      ></div> */}
      {/* charge and rate box start */}

      {/* charge and rate box end */}
    </div>
  );
};

export default NewPreviewDetailBox;
