import {
  // RavenButton,
  RavenInputField,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import { getTeamRoles } from "../../../redux/settings";
import {
  changeAuthLevelInSubAccount,
  getSingleSubAccount,
  getSingleSubAccountRoleInfo,
  getSubAccountCreateDetails,
} from "../../../redux/sub_account";
import "../../../styles/dashboard/sub-account/SingleSubAccountWrap.css";
import {
  formatTypeFunction,
  lowerCaseWrap,
  reactSelectStyle,
  SPECIAL_ROLE_NAME,
  trimLongString,
} from "../../../utils/helper/Helper";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import ChangePrimaryModal from "../settings/modal/ChangePrimaryAccountModal";
import DeleteAccountModal from "../settings/modal/DeleteAccountModal";
import RemoveRoleFromAccountModal from "../settings/modal/RemoveRoleFromAccountModal";
import AddRoleToAccountModal from "./AddRoleToAccount";

const SingleSubAccountIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teamRoles } = useSelector((state) => state.settings);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const [refreshTable, setRefreshTable] = useState(false);
  const [selectOptionVal, setSelectOptionVal] = useState({
    one: "",
    two: "",
  });
  const [roleToView, setRoleToview] = useState("");
  const [showModal, setShowModal] = useState({
    delete_sub_account: false,
    change_primary: false,
    remove_role_account: false,
    add_role_account: false,
  });
  const { loading, single_sub_account, subAccoutDetailList } = useSelector(
    (state) => state.sub_account
  );

  //   useEffect(() => {
  //     dispatch(getSubAccountCreateDetails());
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  useEffect(() => {
    const obj = {
      id: localStorage?.getItem("sub-account-single-id"),
    };
    if (obj?.id) {
      dispatch(getSingleSubAccount(obj));
      dispatch(getSubAccountCreateDetails());
      dispatch(getSingleSubAccountRoleInfo(obj));
    } else {
      navigate("/dashboard-settings-sub-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_view_account"
      )
    ) {
      navigate("/dashboard-settings-sub-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_resource_and_permissions]);

  useEffect(() => {
    dispatch(getTeamRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addRoleIcon = (
    <svg
      className="img dull-bright-filter"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M19 21V15M16 18H22M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className=" dull-bright-filter"
      />
    </svg>
  );

  const primaryIcon = (
    <svg
      className="img dull-bright-filter"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className=" dull-bright-filter"
        d="M21 9.5V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H9.5M17.3862 17.7113L15.6879 20.8653C15.4103 21.3808 15.2715 21.6386 15.1023 21.7059C14.9555 21.7643 14.7896 21.7498 14.6551 21.6668C14.5001 21.5712 14.4081 21.2933 14.2241 20.7375L11.5004 12.5113C11.3392 12.0245 11.2586 11.7812 11.3166 11.6191C11.367 11.478 11.478 11.367 11.6191 11.3166C11.7812 11.2586 12.0245 11.3392 12.5113 11.5004L20.7374 14.2241C21.2933 14.4082 21.5712 14.5002 21.6668 14.6551C21.7498 14.7897 21.7642 14.9555 21.7058 15.1024C21.6386 15.2715 21.3808 15.4103 20.8652 15.6879L17.7113 17.3862C17.6328 17.4285 17.5935 17.4497 17.5591 17.4768C17.5286 17.501 17.501 17.5286 17.4768 17.5591C17.4497 17.5935 17.4285 17.6328 17.3862 17.7113Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const freezeIcon = (
    <svg
      className="img "
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8V16M12 8V2M12 8L7 3M12 8L17 3M12 16V22M12 16L7 21M12 16L17 21M16 12H8M16 12H22M16 12L21 7M16 12L21 17M8 12H2M8 12L3 7M8 12L3 17"
        stroke="#1B1B1B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const cancelIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4L4 12M4 4L12 12"
        stroke="#FF0F00"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  // const formAuthLevel = (list) => {
  //   if (list?.length > 0) {
  //     const newList = list?.map((chi) => {
  //       return {
  //         value: chi,
  //         label: lowerCaseWrap(formatTypeFunction(chi)),
  //       };
  //     });
  //     const AllListAdd = [...newList];
  //     return AllListAdd;
  //   }
  // };

  const formatRoles = (list) => {
    if (list) {
      const newList = JSON.parse(list);
      return newList;
    }
  };

  const formatUserToviewAuthLevel = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          auth_level: {
            label: lowerCaseWrap(formatTypeFunction(chi?.auth_level)),
            value: chi?.auth_level,
          },
        };
      });
      return newList;
    }
  };

  const [userToView, setUserToView] = useState([]);
  useEffect(() => {
    setUserToView(
      formatUserToviewAuthLevel(single_sub_account?.account_authorization_data)
    );
    // console.log(single_sub_account?.account_authorization_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_sub_account]);

  const [showFilterOption, setShowFilterOption] = useState(false);
  const testingSingleSubRef = useOnClickOutside(() => {
    setShowFilterOption(false);
  });

  const filterListToShowFunction = (param) => {
    const newList = single_sub_account?.account_authorization_data?.filter(
      (chi) => {
        return chi?.auth_level === param;
      }
    );
    // console.log(single_sub_account?.account_authorization_data);
    // console.log(param);
    // console.log(newList);
    if (param === "all") {
      setUserToView(single_sub_account?.account_authorization_data);
    } else {
      setUserToView(newList);
    }
  };

  const size = useWindowSize();

  //   const formatDropVal = (obj) => {
  //     if (obj) {
  //       const newObj = {
  //         ...obj,
  //         label: lowerCaseWrap(obj?.label),
  //       };
  //       return newObj;
  //     }
  //   };

  const formAuthLevel = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const filterList = list?.filter((chi) => chi !== "verifier");
      const newList = filterList?.map((chi) => {
        return {
          ...chi,
          value: chi,
          label: lowerCaseWrap(formatTypeFunction(chi)),
        };
      });
      return newList;
    }
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        delete_sub_account: false,
        change_primary: false,
        remove_role_account: false,
        add_role_account: false,
      };
    });
    setRefreshTable(!refreshTable);
  };

  const sortListByAdmin = (list) => {
    if (list?.length > 0) {
      const sortedArray = list?.sort((a, b) => {
        if (
          a?.user_object?.clearance === SPECIAL_ROLE_NAME &&
          b?.user_object?.clearance !== SPECIAL_ROLE_NAME
        ) {
          return -1; // a comes first
        } else if (
          a?.user_object?.clearance !== SPECIAL_ROLE_NAME &&
          b?.user_object?.clearance === SPECIAL_ROLE_NAME
        ) {
          return 1; // b comes first
        }
        return 0; // no change in order
      });
      return sortedArray;
    }
  };

  const handleChangeAuthLevel = async (obj, id, role) => {
    const newList = userToView?.map((chi) => {
      return chi?.user_id === id ? { ...chi, auth_level: obj } : chi;
    });
    setUserToView(newList);
    const val = {
      account_id: String(single_sub_account?.id),
      user_id: id,
      auth_level: obj?.value,
      role: role,
    };
    // console.log(val);
    // return
    const data = await dispatch(changeAuthLevelInSubAccount(val));
    if (data?.payload?.data?.status === "success") {
      setRefreshTable(!refreshTable);
    }
  };

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={
            !loading ? single_sub_account?.custom_account_name : "Sub Account"
          }
          text={`Manage your sub account`}
          back
          onBack={() => {
            // console.log(subAccoutDetailList);
            // console.log(userToView);
            // console.log(single_sub_account);
            navigate("/dashboard-settings-sub-account");
          }}
        />
        {/* title box end */}
        {/* container wrap start */}
        <ContainerWrapper dontShowHeader={true} width={`100%`}>
          {/* mai wrapper start */}
          {loading ? (
            <>
              {" "}
              <NoContentBox
                title={`No information  found for sub account`}
                text={`Click on the back button above to go to previous page.`}
                loading={loading}
                // btnText="Create role"
              />
            </>
          ) : (
            <div className="single-sub-account-detail-wrap">
              {/* left detail start */}
              <div className="left-detail-box border-theme-right">
                {/* card detail top box start */}
                <div className="card-detail-top-box">
                  {/* detail bank box start */}
                  <div className="bank-number-name-box">
                    <p className="name-bank-box grey-white-color-white">
                      {`${
                        trimLongString(single_sub_account?.account_name, 20) ||
                        "---"
                      } • ${trimLongString(
                        single_sub_account?.bank || "---",
                        20
                      )}`}
                    </p>
                    <p className="number ">
                      {single_sub_account?.account_number || "---"}{" "}
                    </p>
                  </div>
                  {/* detail bank box end */}
                  {/* account name box start */}
                  <div className="account-name-box">
                    <p className="text grey-white-color">
                      {single_sub_account?.custom_account_name || "---"}
                    </p>
                  </div>
                  {/* account name box end */}
                </div>
                {/* card detail top box end */}

                {/* role to attach start */}
                <div className="role-to-attach-box">
                  <p className="title grey-white-color">
                    Roles attached to this account.
                  </p>
                  {/* role select box start */}
                  <div className="role-select-box">
                    {formatRoles(single_sub_account?.selected_roles)?.map(
                      (chi, idx) => {
                        return (
                          <div
                            key={idx}
                            className="role-item-box grey-bg"
                            style={{
                              cursor:
                                chi?.toLocaleLowerCase() !==
                                  SPECIAL_ROLE_NAME && "pointer",
                            }}
                          >
                            <p className="role">{lowerCaseWrap(chi)}</p>
                            {chi?.toLocaleLowerCase() !== SPECIAL_ROLE_NAME &&
                              single_sub_account?.freeze !== 1 &&
                              user_resource_and_permissions?.allowed_permissions?.includes(
                                "can_remove_role_from_sub_account"
                              ) && (
                                <div
                                  onClick={() => {
                                    //   setActionDrop("");
                                    setRoleToview(chi);
                                    setShowModal((prev) => {
                                      return {
                                        ...prev,
                                        remove_role_account: true,
                                      };
                                    });
                                  }}
                                  className="cancel-icon-box tooltip-hover-wrap"
                                >
                                  <RavenToolTip
                                    color="black-light"
                                    text="Remove role"
                                    position={`top-center`}
                                  />
                                  <figure className="img-box">
                                    {cancelIcon}
                                  </figure>
                                </div>
                              )}
                          </div>
                        );
                      }
                    )}
                  </div>
                  {/* role select box end */}
                </div>
                {/* role to attach end */}
                {/* three action box start */}
                <div className="three-action-box">
                  {/* icon text box start */}
                  {formatRoles(single_sub_account?.selected_roles)?.length <
                    teamRoles?.length && (
                    <>
                      {" "}
                      {single_sub_account?.freeze !== 1 &&
                        user_resource_and_permissions?.allowed_permissions?.includes(
                          "can_add_role_to_an_account"
                        ) && (
                          <div
                            onClick={() => {
                              setShowModal((prev) => {
                                return { ...prev, add_role_account: true };
                              });
                            }}
                            className="icon-text-box border-theme"
                          >
                            <div className="img-wrap">
                              {" "}
                              <figure className="img-box">{addRoleIcon}</figure>
                            </div>
                            <p className="text">
                              Add new roles to this account
                            </p>
                          </div>
                        )}
                    </>
                  )}
                  {/* icon text box end */}
                  {/* icon text box start */}
                  {/* {primary !== 1 && ( */}
                  {single_sub_account?.freeze !== 1 &&
                  user_resource_and_permissions?.allowed_permissions?.includes(
                    "can_change_primary_account"
                  ) &&
                  single_sub_account?.primary !== 1 ? (
                    <div
                      onClick={() => {
                        //   setActionDrop("");
                        //   setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, change_primary: true };
                        });
                      }}
                      className="icon-text-box border-theme"
                    >
                      <div className="img-wrap">
                        {" "}
                        <figure className="img-box">{primaryIcon}</figure>
                      </div>
                      <p className="text">Set this account to primary </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* )} */}
                  {/* icon text box end */}
                  {/* icon text box start */}
                  {/* {primary !== 1 && ( */}
                  {single_sub_account?.primary !== 1 &&
                    user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_freeze_and_unfreeze_account"
                    ) && (
                      <div
                        onClick={() => {
                          //   setActionDrop("");
                          //   setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, delete_sub_account: true };
                          });
                        }}
                        className="icon-text-box border-theme"
                      >
                        <div className="img-wrap">
                          {" "}
                          <figure className="img-box">{freezeIcon}</figure>
                        </div>
                        <p className={`text `}>
                          {single_sub_account?.freeze === 0
                            ? `Freeze this account`
                            : `Unfreeze this account`}
                        </p>
                      </div>
                    )}
                  {/* )} */}
                  {/* icon text box end */}
                </div>
                {/* three action box ebnd */}
              </div>
              {/* left detail end */}
              {/* right detail start */}
              <div className="right-detail-box">
                {/* filter title box start */}
                <div className="filter-title-box border-theme-bottom">
                  <p className="title">Authorisation levels to this account</p>
                  {/* filter badge box start */}
                  <div className="filter-badge-box">
                    {/* badge filter options start */}
                    {selectOptionVal?.one.label ||
                    selectOptionVal?.two.label ? (
                      <div className="badge-filter-option-box">
                        {/* box start */}
                        {selectOptionVal?.one.label && (
                          <div className="option-box grey-bg">
                            <span>
                              {size.width < 1500
                                ? trimLongString(selectOptionVal?.one?.label, 7)
                                : selectOptionVal?.one?.label || "---"}
                            </span>{" "}
                            <FaTimes
                              onClick={() => {
                                setUserToView(
                                  single_sub_account?.account_authorization_data
                                );
                                setSelectOptionVal((prev) => {
                                  return {
                                    ...prev,
                                    one: { label: "", value: "" },
                                  };
                                });
                              }}
                              className="icon grey-white-color-white"
                            />{" "}
                          </div>
                        )}
                        {selectOptionVal?.two.label && (
                          <div className="option-box grey-bg">
                            <span>{selectOptionVal?.two?.label || "--"}</span>{" "}
                            <FaTimes
                              onClick={() => {
                                setSelectOptionVal((prev) => {
                                  return {
                                    ...prev,
                                    two: { label: "", value: "" },
                                  };
                                });
                              }}
                              className="icon grey-white-color-white"
                            />{" "}
                          </div>
                        )}
                        {/* box end */}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* badge filter options end */}
                    {/* filter-drop box start */}
                    <div ref={testingSingleSubRef} className="filter-drop-box">
                      {/* drop box start */}
                      {showFilterOption && (
                        <div className="drop-box-wrap  card-bg shadow-shadow-box-shadow">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                            autoComplete="off"
                            action=""
                          >
                            {" "}
                            <RavenInputField
                              selectOption={formAuthLevel(
                                subAccoutDetailList?.authorization_values
                              )}
                              label={`Filter By Authorization level`}
                              color={`black-light`}
                              type={"select"}
                              placeholder={`Auth level`}
                              selectStyles={reactSelectStyle}
                              value={selectOptionVal?.one}
                              onChange={(e) => {
                                filterListToShowFunction(e.value);
                                setSelectOptionVal((prev) => {
                                  return { ...prev, one: e };
                                });
                                showFilterOption && setShowFilterOption(false);
                                // setCheckCustom(true);
                              }}
                              selectValue={selectOptionVal?.one}
                            />
                          </form>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                            autoComplete="off"
                            action=""
                          ></form>
                        </div>
                      )}
                      {/* drop box end */}
                      {/* filter box start */}
                      <div
                        className="filter-box grey-bg"
                        onClick={() => {
                          setShowFilterOption(!showFilterOption);
                        }}
                      >
                        <p className="text grey-white-color-white">Filter</p>
                        <div className="line-box">
                          <span className=""></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                      {/* filter box end */}
                      {/* drop box start */}
                      <div className="drop-filter-wrap"></div>
                      {/* drop box end */}
                    </div>
                    {/* filter-drop box end */}
                  </div>
                  {/* filter badge box end */}
                </div>
                {/* filter title box end */}
                {/* auth level box start */}
                <div className="auth-level-view-box">
                  {sortListByAdmin(userToView)?.map((chi, idx) => {
                    const { auth_level, user_id, user_object } = chi;
                    return (
                      <div
                        //   htmlFor={`${name}-${idx}`}
                        key={`${auth_level.value}-${idx}`}
                        onClick={() => {
                          // console.log("yes");
                          // handleAddManagerToList(name);
                        }}
                        className={`select-item ${
                          userToView?.length - 1 !== idx &&
                          "border-theme-bottom"
                        }`}
                      >
                        <div className="avatar-box ">
                          <span className="">{`${
                            user_object?.fname?.charAt(0) || "-"
                          } ${user_object?.lname?.charAt(0) || "-"}`}</span>
                        </div>
                        <div className="name-role-box">
                          <p className="name">{`${
                            lowerCaseWrap(user_object?.fname) || "---"
                          } ${lowerCaseWrap(user_object?.lname) || "---"}`}</p>
                          <p className="role grey-white-color">
                            {lowerCaseWrap(user_object?.clearance) || "---"}
                          </p>
                        </div>
                        <div
                          className={`select-option-wrap ${
                            user_object?.clearance === SPECIAL_ROLE_NAME &&
                            "select-react-disabled"
                          }`}
                        >
                          <form autoComplete="off" action="">
                            <RavenInputField
                              style={{
                                cursor:
                                  user_object?.clearance ===
                                    SPECIAL_ROLE_NAME ||
                                  !user_resource_and_permissions?.allowed_permissions?.includes(
                                    "can_change_user_authorization_level_on_an_account"
                                  )
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              color="black-light"
                              selectStyles={reactSelectStyle}
                              className={`select-react`}
                              type="select"
                              placeholder="select"
                              disabled={
                                user_object?.clearance === SPECIAL_ROLE_NAME ||
                                !user_resource_and_permissions?.allowed_permissions?.includes(
                                  "can_change_user_authorization_level_on_an_account"
                                )
                              }
                              onChange={(e) => {
                                handleChangeAuthLevel(
                                  e,
                                  user_id,
                                  user_object?.clearance
                                );
                                //   handleAddUserToList(e.value, chi?.user_id);
                              }}
                              value={auth_level}
                              selectOption={formAuthLevel(
                                subAccoutDetailList?.authorization_values
                              )}
                            />
                          </form>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* auth level box end */}
                {/* submit btn start */}
                {/* <div className="right-submit-wrap-btn border-theme-top">
                  <RavenButton className="edit-btn" color="black-light" label="Save changes" />
                </div> */}
                {/* submit btn end */}
              </div>
              {/* right detail end */}
            </div>
          )}
          {/* mai wrapper end */}
        </ContainerWrapper>
        {/* container wrap end */}
      </DashboardLayout>

      {/* genrate start */}
      <DeleteAccountModal
        visible={showModal?.delete_sub_account}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, delete_sub_account: false };
          });
        }}
        detail={single_sub_account}
        onFinish={handleFinish}
      />
      {/* genrate end */}
      {/* genrate start */}
      <ChangePrimaryModal
        visible={showModal?.change_primary}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, change_primary: false };
          });
        }}
        detail={single_sub_account}
        onFinish={handleFinish}
      />
      {/* genrate end */}
      {/* genrate start */}
      <RemoveRoleFromAccountModal
        visible={showModal?.remove_role_account}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, remove_role_account: false };
          });
        }}
        detail={{
          ...single_sub_account,
          role: roleToView,
          custom_account_name: single_sub_account?.custom_account_name,
          id: single_sub_account?.id,
        }}
        onFinish={handleFinish}
      />
      {/* genrate end */}
      {/* genrate start */}
      <AddRoleToAccountModal
        detail={single_sub_account}
        visible={showModal?.add_role_account}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, add_role_account: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* genrate end */}
    </>
  );
};

export default SingleSubAccountIndex;
