import React, { useEffect } from "react";
import { useDarkMode } from "../../utils/themes/useDarkMode";
import { darkTheme, lightTheme } from "../../utils/themes/theme";
import {
  formatMetaData,
  getSystemThemePreference,
} from "../../utils/helper/Helper";
import { useSelector } from "react-redux";

const CheckTheme = () => {
  const { user } = useSelector((state) => state.user);
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const themeMode = theme === "light" ? lightTheme : darkTheme;
//   useEffect(() => {
//     const checkLocal = localStorage.getItem("theme");

//     if (checkLocal === "undefined") {
//       //   console.log(getSystemThemePreference(), "--------------------252");
//       localStorage?.setItem(
//         "sidebar-theme-style",
//         formatMetaData(user?.theme_display_settings)?.side_nav_style
//       );
//       localStorage?.setItem("theme", getSystemThemePreference());
//     } else {
//       if (formatMetaData(user?.theme_display_settings)?.theme === "system") {
//         localStorage?.setItem(
//           "sidebar-theme-style",
//           formatMetaData(user?.theme_display_settings)?.side_nav_style
//         );
//         localStorage?.setItem("theme", getSystemThemePreference());
//       } else {
//         localStorage?.setItem(
//           "sidebar-theme-style",
//           formatMetaData(user?.theme_display_settings)?.side_nav_style
//         );
//         localStorage?.setItem(
//           "theme",
//           formatMetaData(user?.theme_display_settings)?.theme
//         );
//       }
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [user]);
  return <></>;
};

export default CheckTheme;
