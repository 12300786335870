import {
  RavenButton,
  // RavenInputField,
  RavenPagination,
  RavenTable,
  // RavenToggleSlide,
  toast,
} from "@ravenpay/raven-bank-ui";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";
import SuccessModal from "../../../../components/reusables/SuccessModal";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";
import ActionContext from "../../../../context/ActionContext";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import { validateRavenUserName } from "../../../../redux/payroll";
import {
  bulkTransferManualRaven,
  getAllSaveRecordsRaven,
} from "../../../../redux/transaction";
import { getAllAccounts, getUser } from "../../../../redux/user";
import "../../../../styles/dashboard/send-money/ManualPageRavanToRaven.css";
import {
  filterEmptyColumns,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  sumAllNum,
} from "../../../../utils/helper/Helper";
import useDebounce from "../../../../utils/helper/UseDebounce";
import useWindowSize from "../../../../utils/helper/UseWindowSize";
// import { formatNumWithComma } from "../../../../utils/Helpers";
import ManualEditTable from "./ManualEditTable";
import NewPreviewDetailBox from "./NewPreviewDetailBox";
import RecordModal from "./RecordModal";

const RavenManualPage = () => {
  const { loading, ravenBulkRecord } = useSelector(
    (state) => state.transaction
  );
  const dispatch = useDispatch();
  const [bulkSelectVal, setBulkSelectVal] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
    records: false,
  });
  const [activeStep, setActiveStep] = useState(1);
  const [details, setDetails] = useState({
    transfer_data: [],
    transfer_pin: "",
    schedule: "",
    schedule_time: "",
    narration: "Sent from raven",
    template_title: "",
    save_template: false,
    beneficiary: false,
  });
  const stepList = ["Transfer detail", "Confirm Transactions"];
  const headerList = [
    "S/N",
    "Raven Username",
    "Account Name",
    "Amount",
    "status",
  ];
  const [listTableToSubmit, setListTableToSubmit] = useState([]);
  const [perPageVal] = useState(50);
  const { user } = useSelector((state) => state.user);
  const [paginating, setPaginating] = useState(false);
  //   const [current_page, setCurrent_page] = useState(1)
  const [editObj, setEditObj] = useState({
    raven_username: "",
    account_name: "",
    status: "pending",
    amount: "",
    id: "",
  });
  const navigate = useNavigate();

  const handleChange = (e, id) => {
    if (editState) {
      const { name, value } = e.target;
      //   const obj = { ...editObj, [name]: value };
      setEditObj((prev) => {
        return { ...prev, [name]: value };
      });
      //   setEditObj(obj);
      const newList = listTableToSubmit.map((chi) =>
        chi?.id === editObj?.id ? { ...chi, [name]: value } : chi
      );
      setListTableToSubmit(newList);
      paginateFunc(newList, perPageVal, paginatedList?.current_page);
    } else {
      const { name, value } = e.target;
      //   const obj = { ...editObj, [name]: value };
      setEditObj((prev) => {
        return { ...prev, [name]: value };
      });
      //   setEditObj(obj);
    }
  };

  const [editState, setEditState] = useState(false);

  //   const addToList = () => {};

  const handleSetEditChild = () => {
    const checkList = listTableToSubmit?.some(
      (chi) => chi?.raven_username === editObj?.raven_username
    );
    if (!editState && checkList) {
      toast.error("Recepient already exist in bulk list");
      return;
    }
    const id = Math.random().toString(36).substring(2, 10);
    const obj = {
      raven_username: editObj?.raven_username,
      account_name: editObj?.account_name,
      status: editObj?.status,
      amount: editObj?.amount,
      id: id,
      num: listTableToSubmit?.length,
    };
    const newList = [...listTableToSubmit, obj];
    setListTableToSubmit(newList);
    paginateFunc(newList, perPageVal, 1);
    setEditObj({
      raven_username: "",
      account_name: "",
      status: "pending",
      amount: "",
      id: "",
    });
  };

  //   const handleEditChild = (id) => {};
const actionCtx = useContext(ActionContext)
  const handleDelete = (id) => {
    const newList = listTableToSubmit.filter((chi) => chi?.id !== id);
    setListTableToSubmit(newList);
    paginateFunc(newList, perPageVal, paginatedList?.current_page);
  };

  const [error, setError] = useState({
    raven_username: false,
  });

  const [loadingVery, setLoadingVerify] = useState(false);
  const validateraven_Username = async (param) => {
    setLoadingVerify(true);
    let data = await dispatch(validateRavenUserName(param));
    // console.log(data);
    if (data?.payload?.status === "success") {
      if (editState) {
        setLoadingVerify(false);
        const info = data?.payload.data;
        const fullName = `${info?.fname} ${info?.lname}`;
        // console.log(fullName);
        // const obj = {
        //   ...editObj,
        //   status: "verified",
        //   account_name: fullName,
        // };
        setEditObj((prev) => {
          return { ...prev, status: "verified", account_name: fullName };
        });
        // setEditObj(obj);
        const newList = listTableToSubmit?.map((chi) =>
          chi?.id === editObj?.id
            ? { ...chi, status: "verified", account_name: fullName }
            : chi
        );
        setListTableToSubmit(newList);
        paginateFunc(newList, perPageVal, paginatedList?.current_page);
      } else {
        setLoadingVerify(false);
        const info = data?.payload.data;
        const fullName = `${info?.fname} ${info?.lname}`;
        // console.log(fullName);
        // const obj = {
        //   ...editObj,
        //   status: "verified",
        //   account_name: fullName,
        // };
        setEditObj((prev) => {
          return { ...prev, status: "verified", account_name: fullName };
        });
        // setEditObj(obj);
      }
    }
    if (data?.payload?.data?.status === "fail") {
      if (editState) {
        // const obj = {
        //   ...editObj,
        //   status: "unverified",
        //   account_name: "",
        // };
        setEditObj((prev) => {
          return { ...prev, status: "unverified", account_name: "" };
        });
        // setEditObj(obj);
        const newList = listTableToSubmit.map((chi) =>
          chi?.id === editObj?.id
            ? { ...chi, status: "unverified", account_name: "" }
            : chi
        );
        setListTableToSubmit(newList);
        paginateFunc(newList, perPageVal, paginatedList?.current_page);
        setLoadingVerify(false);
        setError((prev) => {
          return { ...prev, raven_username: true };
        });
      } else {
        // const obj = {
        //   ...editObj,
        //   status: "unverified",
        //   account_name: "",
        // };
        // setEditObj(obj);
        setEditObj((prev) => {
          return { ...prev, status: "unverified", account_name: "" };
        });
        //   const newList = listTableToSubmit.map((chi) =>
        //     chi?.id === editObj?.id ? obj : chi
        //   );
        //   setListTableToSubmit(newList);
        setLoadingVerify(false);
        setError((prev) => {
          return { ...prev, raven_username: true };
        });
      }
    }
  };

  const debouncedSearchTerm = useDebounce(editObj?.raven_username, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 3) {
      const payload = {
        username: editObj?.raven_username,
      };
      validateraven_Username(payload);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      //   setRavenName("");
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const [paginatedList, setPaginatedList] = useState({
    current_page: 1,
    per_page: "",
    total: "",
    total_pages: "",
    dataList: [],
  });

  function paginateFunc(array, page_size, current_page) {
    const start_index = (current_page - 1) * page_size;
    const end_index = start_index + page_size;
    const paginatedData = array.slice(start_index, end_index);
    const info = {
      current_page: current_page,
      per_page: page_size,
      total: array.length,
      total_pages: Math.ceil(array.length / page_size),
      dataList: paginatedData,
    };
    // console.log(info);
    setPaginatedList((prev) => {
      return {
        ...prev,
        current_page: info?.current_page,
        per_page: info?.per_page,
        total: info?.total,
        total_pages: info?.total_pages,
        dataList: info?.dataList,
      };
    });
  }

  //   const handle

  useEffect(() => {
    if (
      paginatedList?.total_pages > paginatedList?.current_page &&
      !paginating &&
      !editState
    ) {
      paginateFunc(listTableToSubmit, perPageVal, paginatedList?.total_pages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedList?.dataList]);

  const getAllAmount = (list) => {
    if (list.length > 0) {
      const amountList = list.map((chi) =>
        Number(formatNumWithoutCommaNaira(chi?.amount))
      );
      const sumList = sumAllNum(amountList);
      return sumList;
    }
  };

  const handleSubmit = async (pin, token) => {
    let val;
    const newList = listTableToSubmit?.map((chi) => {
      return {
        identifier: chi?.raven_username,
        amount: formatNumWithoutCommaNaira(chi?.amount),
        currency: "NGN",
        narration: details?.narration,
      };
    });
    if (details?.save_template) {
      const obj = {
        transfer_data: newList,
        transfer_pin: pin,
        two_factor_token: token,
        schedule: fromDate ? 1 : 0,
        schedule_time: fromDate
          ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
          : "",
        template_title: details?.template_title,
        old_template_id: details?.bulk_ref,
        selected_account_number: actionCtx?.currentAccount?.account_number,
      };
      val = obj;
    } else {
      const obj = {
        transfer_data: newList,
        transfer_pin: pin,
        two_factor_token: token,
        schedule: fromDate ? 1 : 0,
        selected_account_number: actionCtx?.currentAccount?.account_number,
        schedule_time: fromDate
          ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
          : "",
      };
      val = obj;
    }
    // console.log(val);
    // return;

    const data = await dispatch(bulkTransferManualRaven(filterEmptyColumns(val)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return { ...prev, success: true, pin: false };
    });
    dispatch(getUser());
    dispatch(getAllAccounts());
    setActiveStep(1);
    setFromDate("");
    setDetails({
      transfer_data: [],
      transfer_pin: "",
      schedule: "",
      schedule_time: "",
      narration: "Sent from raven",
      template_title: "",
      save_template: false,
      old_template_id: "",
    });
    setPaginatedList({
      current_page: 1,
      per_page: "",
      total: "",
      total_pages: "",
      dataList: [],
    });
    setEditObj({
      raven_username: "",
      account_name: "",
      status: "pending",
      amount: "",
      id: "",
    });
    navigate("/dashboard-overview");
  };

  const formatBulkList = (list) => {
    if (list.length > 0) {
      const newList = list.map((chi) => {
        return {
          ...chi,
          label: chi.template_title,
          value: chi?.bulk_reference,
        };
      });
      return newList;
    }
  };

  const size = useWindowSize();
  useEffect(() => {
    dispatch(getAllSaveRecordsRaven());
    if (size.width < 1200) {
      navigate("/dashboard-send-money-raven");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectDataList = (obj) => {
    const getArray = JSON.parse(obj?.transfer_template_data);
    // console.log(getArray);
    const formatList = getArray?.map((chi, idx) => {
      return {
        raven_username: chi?.identifier,
        account_name: chi?.account_name || "",
        status: "verified",
        amount: chi?.amount,
        id: chi?.identifier,
        num: idx,
      };
    });
    paginateFunc(formatList, perPageVal, 1);
    setListTableToSubmit(formatList);
    // console.log(formatList);
  };

  useEffect(() => {
    if(actionCtx?.currentAccount?.authorization_level !== ("initiator_approver" || "initiator") && actionCtx?.currentAccount?.authorization_level !==
    ("initiator" || "initiator_approver" ) ){
      navigate("/dashboard-send-money")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          back
          onBack={() => {
            navigate(-1);
          }}
          next={activeStep === 1 && listTableToSubmit?.length > 0}
          onNext={() => {
            // console.log(listTableToSubmit);
            setActiveStep(2);
          }}
          title={`Manual Bulk Raven Transfer`}
          text={`Send funds to users registered with Raven with manual bulk upload`}
        />
        <ContainerWrapper
          bulkSelect={activeStep === 1 && ravenBulkRecord?.length > 0}
          onBulkSelect={(e) => {
            setShowModal((prev) => {
              return { ...prev, records: true };
            });
          }}
          bulkSelectoptions={formatBulkList(ravenBulkRecord)}
          bulkSelectValue={bulkSelectVal}
          onClearList={() => {
            paginateFunc([], perPageVal, 1);
            setListTableToSubmit([]);
            setBulkSelectVal("");
            setDetails((prev) => {
              return { ...prev, save_template: false, template_title: "" };
            });
          }}
          btnText={activeStep === 2 && `Complete Transfer`}
          onBtnClick={() => {
            if (user?.pin_status === 0) {
              actionCtx.setCheckShow("nine");
            }
            else{
              setShowModal((prev) => {
              return { ...prev, pin: true };
            });
            }
            // setShowModal((prev) => {
            //   return { ...prev, pin: true };
            // });
          }}
          disabled={
            activeStep === 2 &&
            details?.save_template &&
            !details?.template_title
              ? true
              : false
          }
          width={`100%`}
          back={activeStep > 1}
          onBack={() => {
            activeStep > 1 && setActiveStep(activeStep - 1);
          }}
          stepClickable={listTableToSubmit?.length > 0}
          onStepClick={(e) => {
            listTableToSubmit?.length > 0 && setActiveStep(e);
          }}
          activeStep={activeStep}
          stepList={stepList}
          pagination={
            paginatedList?.dataList?.length > 0 &&
            activeStep === 1 && (
              <RavenPagination
                color={`black-light`}
                blackHover
                currentPage={paginatedList?.current_page}
                totalPage={paginatedList?.total_pages}
                onNumView={(num) => {
                  setPaginating(true);
                  setTimeout(() => {
                    setPaginating(false);
                  }, 1000);
                  paginateFunc(listTableToSubmit, perPageVal, num);
                }}
                prevPage={paginatedList?.current_page > 1}
                nextPage={
                  paginatedList?.current_page < paginatedList?.total_pages
                }
                onNext={() => {
                  setPaginating(true);
                  setTimeout(() => {
                    setPaginating(false);
                  }, 1000);
                  paginateFunc(
                    listTableToSubmit,
                    perPageVal,
                    paginatedList?.current_page + 1
                  );
                }}
                onPrev={() => {
                  setPaginating(true);
                  setTimeout(() => {
                    setPaginating(false);
                  }, 1000);
                  paginateFunc(
                    listTableToSubmit,
                    perPageVal,
                    paginatedList?.current_page - 1
                  );
                }}
              />
            )
          }
        >
          <div
            onClick={() => {
              //   console.log(ravenBulkRecord);
            }}
            className="raven-to-raven-manual-page-wrap"
          >
            {/* step one start ---------- */}
            {activeStep === 1 && (
              <>
                <RavenTable
                  className={
                    formatMetaData(user?.theme_display_settings)?.table_font ===
                      "bold" && "table-business"
                  }
                  headerList={headerList}
                  action
                >
                  {paginatedList?.dataList?.map((chi, idx) => {
                    const { raven_username, account_name, status, id, amount, num } =
                      chi;
                    return (
                      <ManualEditTable
                        key={id || idx || num}
                        one={num + 1 < 10 ? `0${num + 1}` : num + 1}
                        two={raven_username}
                        three={account_name}
                        four={amount}
                        showEditButton
                        onEditFininsh={() => {
                          setError((prev) => {
                            return { ...prev, raven_username: false };
                          });
                          setEditState(false);
                          setEditObj({
                            raven_username: "",
                            account_name: "",
                            status: "pending",
                            amount: "",
                            id: "",
                          });
                          // handleSetEditChild();
                        }}
                        five={status}
                        editMode={editObj?.id === id}
                        handleChange={(e) => handleChange(e, id)}
                        loading={loadingVery && editObj?.id === id}
                        showError={error?.raven_username && editObj?.id === id}
                        setError={(e) => {
                          error?.raven_username && setError(e);
                        }}
                        onDelete={() => {
                          handleDelete(id);
                        }}
                        onEdit={() => {
                          setEditState(true);
                          setEditObj(chi);
                        }}
                      />
                    );
                  })}
                  {!editState &&
                  paginatedList?.current_page === paginatedList?.total_pages ? (
                    <ManualEditTable
                      key={`sjs`}
                      // one={
                      //   listTableToSubmit?.length + 1 < 10
                      //     ? `0${listTableToSubmit?.length + 1}`
                      //     : listTableToSubmit?.length + 1
                      // }
                      one={<>&nbsp;</>}
                      two={editObj?.raven_username}
                      three={editObj?.account_name}
                      four={editObj?.amount}
                      five={editObj?.status}
                      loading={loadingVery}
                      editMode={true}
                      handleChange={(e) => handleChange(e)}
                      showError={error?.raven_username}
                      setError={(e) => {
                        error?.raven_username && setError(e);
                      }}
                    />
                  ) : listTableToSubmit?.length < 1 ? (
                    <ManualEditTable
                      key={`sjs`}
                      // one={
                      //   listTableToSubmit?.length + 1 < 10
                      //     ? `0${listTableToSubmit?.length + 1}`
                      //     : listTableToSubmit?.length + 1
                      // }
                      one={<>&nbsp;</>}
                      two={editObj?.raven_username}
                      three={editObj?.account_name}
                      four={editObj?.amount}
                      five={editObj?.status}
                      loading={loadingVery}
                      editMode={true}
                      handleChange={(e) => handleChange(e)}
                      showError={error?.raven_username}
                      setError={(e) => {
                        error?.raven_username && setError(e);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </RavenTable>
                {/* add another child to list */}
                <div className="btn-wrap-box">
                  {!editState &&
                  paginatedList?.current_page === paginatedList?.total_pages ? (
                    <RavenButton
                      onClick={() => {
                        setError((prev) => {
                          return { ...prev, raven_username: false };
                        });
                        handleSetEditChild();
                      }}
                      color="black-light"
                      label={
                        editState ? "Complete Edit" : "Add another recipient"
                      }
                      style={{ paddingLeft: "3rem" }}
                      className={`raven-btn`}
                      disabled={
                        !editObj?.amount ||
                        !editObj?.raven_username ||
                        editObj?.status !== "verified"
                      }
                    />
                  ) : listTableToSubmit?.length < 1 ? (
                    <RavenButton
                      onClick={() => {
                        setError((prev) => {
                          return { ...prev, raven_username: false };
                        });
                        handleSetEditChild();
                      }}
                      color="black-light"
                      label={
                        editState ? "Complete Edit" : "Add another recipient"
                      }
                      style={{ paddingLeft: "3rem" }}
                      className={`raven-btn`}
                      disabled={
                        !editObj?.amount ||
                        !editObj?.raven_username ||
                        editObj?.status !== "verified"
                      }
                    />
                  ) : (
                    ""
                  )}

                  {!editState && activeStep === 1 && (
                    <>
                      <RavenButton
                        color="black-light"
                        label="Proceed"
                        onClick={() => {
                          setActiveStep(2);
                        }}
                        disabled={listTableToSubmit?.length < 1}
                      />
                    </>
                  )}
                </div>
                {/* add another child to list */}
              </>
            )}
            {/* step one end ------------- */}
            {/* step twostart ------------- */}
            {activeStep === 2 && (
              <>
                <div style={{ margin: "2rem auto", paddingBottom: "10rem" }} className="">
                  {" "}
                  <NewPreviewDetailBox
                    onReset={() => setFromDate("")}
                    valueSchedule={fromDate}
                    onChangeSchedule={(e) => {
                      setFromDate(e);
                    }}
                    value={details?.beneficiary}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, beneficiary: e.target.checked };
                      });
                      //  handleAddBeneficiaryAll(e.target.checked);
                    }}
                    list={listTableToSubmit}
                    collapse
                    bulkAmount={
                      listTableToSubmit?.length > 0
                        ? formatNumWithCommaNairaSymbol(
                            getAllAmount(listTableToSubmit)
                          )
                        : formatNumWithCommaNairaSymbol(0)
                    }
                    account_name={details?.raven_username}
                    account_num={details?.account_num}
                    bank={details?.bank?.label}
                    amount={details?.amount}
                    name={details?.raven_username}
                    narration={details?.narration}
                    checked={details?.beneficiary}
                    handleRemoveFromList={(obj) => {
                      // console.log(listTableToSubmit?.length);
                      if (activeStep === 2 && listTableToSubmit?.length === 1) {
                        setActiveStep(1);
                      }
                      handleDelete(obj?.id);
                    }}
                    details={details}
                    setDetails={setDetails}
                    handleChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, narration: e.target.value };
                      });
                    }}
                  />
                </div>
              </>
            )}
            {/* step two end ------------- */}
          </div>
        </ContainerWrapper>
      </DashboardLayout>

      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        visible={showModal.pin}
        onCancel={() =>
          setShowModal((prev) => {
            return { ...prev, pin: false };
          })
        }
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Transaction Successful."
        text={`You should also receive a confirmation email with the details of the transaction. We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
      {/* record modal start */}
      <RecordModal
        onBulkSelect={(e) => {
          setBulkSelectVal(e);
          handleSelectDataList(e);
          setDetails((prev) => {
            return {
              ...prev,
              save_template: true,
              template_title: e.template_title,
              bulk_ref: e.bulk_reference,
            };
          });
          setShowModal((prev) => {
            return { ...prev, records: false };
          });
        }}
        visible={showModal?.records}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, records: false };
          });
        }}
      />
      {/* record modal end */}
    </>
  );
};

export default RavenManualPage;
