export const setCookie = (name, value, seconds) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + seconds * 1000); // Convert seconds to milliseconds
  const cookieValue =
    encodeURIComponent(value) +
    (seconds ? `; expires=${expirationDate.toUTCString()}` : "");
  document.cookie = `${name}=${cookieValue}; path=/`;
};

export function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if this cookie is the one we're looking for
    if (cookie.startsWith(name + "=")) {
      // Return the value of the cookie
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  // If the cookie is not found, return null
  return null;
}
