import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DeleteModal from "../../../components/reusables/DeleteModal";
import ActionContext from "../../../context/ActionContext";
import { retryPaymentRequest } from "../../../redux/paymentRequest";
import { trimLongString } from "../../../utils/helper/Helper";

const RetryPaymentRequest = ({ detail, visible, onClose, onFinish }) => {
  const { loadingStatement } = useSelector((state) => state.payment_request);
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const obj = {
      request_id: String(detail?.id),
      account_number: actionCtx?.currentAccount?.account_number,
    };
    const data = await dispatch(retryPaymentRequest(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
    }
  };
  return (
    <DeleteModal
      title={`Retry Payment Request from  ${
        detail?.intiator_fname || "---"
      } ${
        detail?.intiator_fname?.length +
          detail?.intiator_lname?.length >
        12
          ? trimLongString(detail?.intiator_lname, 5)
          : detail?.intiator_lname
      }`}
      text={`Retry payment request, would automatically approve this request  on your business, ensure you confirm this before proceeding to retry.`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
      loading={loadingStatement}
      buttonColor={`black-light`}
    />
  );
};

export default RetryPaymentRequest;
