import { RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoContentBox from "../../components/reusables/NoContentBox";
import AuthLayoutOne from "../../layout/auth/AuthLayoutOne";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {
  getTeamMemberByInvite,
  getUser,
  processInviteTeamRegistration,
} from "../../redux/user";
import "../../styles/auth/SignUp.css";
import {
  validateEmail,
  validatePasswordLowercase,
  validatePasswordNumber,
  validatePasswordSpecialCharacter,
  validatePasswordUpperCase,
} from "../../utils/helper/ValidateInput";

const RegisterInvite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.user);
  const [details, setDetails] = useState({
    email: "",
    business_name: "",
    phone: "",
    fname: "",
    lname: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const [deviceIdVal, seDeviceIdVal] = useState("");
  useEffect(() => {
    // Generate a fingerprint
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();

      // Use the fingerprint as the device identifier
      const deviceId = result.visitorId;

      // Store the device identifier in local storage
      // console.log(deviceId);
      seDeviceIdVal(deviceId);
    };

    getFingerprint();
  }, []);

  useEffect(() => {
    localStorage.clear();
    getRefValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [loadingContent, setLoadingContent] = useState(true);
  const getRefValue = async () => {
    const link = window.location.href;
    const linkSplit = link.split("?ref=");
    const refVal = linkSplit[1];
    // console.log(refVal);
    const data = await dispatch(getTeamMemberByInvite(refVal));
    if (data?.payload?.data?.status === "success") {
      // console.log(data);
      const detail = data?.payload?.data?.data?.details;
      if (Number(detail?.exists) === 1) {
        setLoadingContent(false);
        // console.log(detail);
        localStorage?.setItem("invite--email", detail.email);
        localStorage?.setItem("invite-business-email", detail.business_email);
        localStorage.setItem("invite-ref", detail.reference);
        navigate("/login");
        return;
      } else {
        setLoadingContent(false);
        const obj = {
          ...details,
          fname: detail?.first_name,
          lname: detail?.last_name,
          email: detail?.email,
          reference: refVal,
        };
        setDetails(obj);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("okay");
    if (!validateEmail(details?.email)) {
      toast.error("Invalid email address!", {});
      return;
    }
    if (
      !validatePasswordLowercase(details?.password) &&
      !validatePasswordUpperCase(details?.password) &&
      !validatePasswordSpecialCharacter(details?.password) &&
      !validatePasswordNumber(details?.password)
    ) {
      toast.error(
        "Password should at least be 8 characters long,contain 1 capital letter, 1 lowercase letter,  1 special character,  1 numeric character."
      );
      return;
    }
    if (!validatePasswordLowercase(details?.password)) {
      toast.error("Password should have at least 1 lowercase letter", {});
      return;
    }
    if (!validatePasswordUpperCase(details?.password)) {
      toast.error("Password should have at least 1 capital letter", {});
      return;
    }
    if (!validatePasswordSpecialCharacter(details?.password)) {
      toast.error("Password should have at least 1 special character", {});
      return;
    }
    if (!validatePasswordNumber(details?.password)) {
      toast.error("Password should have at least  1 numeric character.", {});
      return;
    }
    const data = await dispatch(
      processInviteTeamRegistration({ ...details, device_id: deviceIdVal })
    );
    if (data?.payload?.data?.status === "success") {
      const token = data?.payload?.data?.data?.token;
      localStorage.setItem("token", token);
      dispatch(getUser());
      navigate("/dashboard-overview");
    }
  };

  return (
    <>
      {loadingContent ? (
        <>
          {" "}
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "grid",
              placeItems: "center",
              // backgroundColor: "red"
            }}
            className=""
          >
            <NoContentBox
              loading={true}
              title={`Wrong Invite Link`}
              text={`Please click on the chat icon below to reach out to our team.`}
            />
          </div>
        </>
      ) : (
        <AuthLayoutOne>
          {loadingContent ? (
            <>
              {" "}
              <NoContentBox
                loading={true}
                // title={`No Employee record for ${sortFilter?.label}`}
                // text={`Select All to refresh table content`}
              />
            </>
          ) : (
            <div className="sign-up-wrap">
              <form
                onSubmit={(e) => e.preventDefault()}
                action=""
                className="form"
              >
                <div className="text-box">
                  <p className="small-title">Complete your</p>
                  <p className="big-title">Team Invite Registration</p>
                </div>
                <div className="two-group">
                  <RavenInputField
                    color={`black-light`}
                    label={`First name`}
                    placeholder={`E.g Timi`}
                    value={details?.fname}
                    name={`fname`}
                    onChange={handleChange}
                    type={`text`}
                  />
                  <RavenInputField
                    color={`black-light`}
                    label={`Last name`}
                    placeholder={`E.g  Ayeni`}
                    value={details?.lname}
                    name={`lname`}
                    onChange={handleChange}
                    type={`text`}
                  />
                </div>
                <RavenInputField
                  color={`black-light`}
                  label={`Email`}
                  placeholder={`E.g  ayenikehin@gmail.com...`}
                  value={details?.email}
                  name={`email`}
                  onChange={handleChange}
                  type={`email`}
                />
                <RavenInputField
                  color={`black-light`}
                  label={`Phone`}
                  placeholder={`E.g 0816263...`}
                  value={details?.phone}
                  name={`phone`}
                  onChange={handleChange}
                  type={`phone`}
                />
                {/* <form
                  action=""
                  style={{ width: "100%", position: "relative", zIndex: 10 }}
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                > */}{" "}
                <RavenInputField
                  style={{ width: "100%", position: "relative", zIndex: 10 }}
                  color={`black-light`}
                  label={`Password`}
                  placeholder={`E.g Passworm.1`}
                  value={details?.password}
                  name={`password`}
                  onChange={handleChange}
                  type={`password`}
                  showPasswordStrength
                />
                {/* </form> */}
                <RavenInputField
                  // style={{ marginTop: "3rem" }}
                  className={`register-team-submit-btn`}
                  color={`black-light`}
                  value={`Submit`}
                  onSubmit={handleSubmit}
                  type={`submit`}
                  loading={loading}
                  disabled={
                    !details?.email ||
                    !details?.fname ||
                    !details?.lname ||
                    !details?.password ||
                    !details?.phone
                  }
                />
                {/* already have accout start */}
                <p className="already-text">
                  Already have an account?{" "}
                  <span
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Login
                  </span>
                </p>
                {/* already have account end */}
              </form>
            </div>
          )}
        </AuthLayoutOne>
      )}
    </>
  );
};

export default RegisterInvite;
