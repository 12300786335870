/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitletextBox from "../../../components/reusables/TitletextBox";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import "../../../styles/dashboard/global-account/GlobalAccountIndex.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RavenCheckBox, RavenModal } from "@ravenpay/raven-bank-ui";
import ActionContext from "../../../context/ActionContext";
import ExchangeRateModalBox from "./ExchangeRateModalBox";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import {
  getAllCurrencies,
  getAllGlobalAccount,
  getAllGlobalVerificationStatus,
} from "../../../redux/globalAccount";
import {
  convertToLowercase,
  formatNumWithCommaNaira,
} from "../../../utils/helper/Helper";
import NocontentPage from "../../../components/reusables/NocontentPage";
import GlobalSelectDropBox from "../../../components/fragments/GlobalSelectDropBox";
import CreateMoreCurrencyModal from "./CreateMoreCurrencyModal";
import TermsAndConditionModal from "./TermsAndConditionModal";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import NoContentBox from "../../../components/reusables/NoContentBox";

const GlobalAccountIndex = () => {
  const { all_currencies, all_global_account, loading, global_verification } =
    useSelector((state) => state.global_account);
  const [agreeBox, setAgreeBox] = useState(false);
  const [showModal, setShowModal] = useState({
    add_cur: false,
    showTerms: false,
  });
  const size = useWindowSize();
  // const [boxSelectView, setBoxSelectView] = useState("");
  const [showRate, setShowRate] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  // const [completeVeri, setCompleteVeri] = useState(false);
  // const verifyIcon = (
  //   <svg
  //     className="img"
  //     width="18"
  //     height="19"
  //     viewBox="0 0 18 19"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       className="dull-bright-filter"
  //       d="M14.3367 3.45966C14.6432 3.14224 14.605 2.61805 14.1905 2.46681C13.905 2.36265 13.5968 2.30582 13.2753 2.30582H4.37526C2.90066 2.30582 1.70526 3.50122 1.70526 4.97583V13.8759C1.70526 15.3505 2.90066 16.5459 4.37526 16.5459H13.2753C14.7499 16.5459 15.9453 15.3505 15.9453 13.8759L15.9453 6.82359C15.9453 6.15724 15.1348 5.82893 14.671 6.30741L9.87958 11.2509C9.67094 11.4595 9.38797 11.5767 9.09292 11.5767C8.79787 11.5767 8.5149 11.4595 8.30626 11.2509L6.52626 9.47088C6.0918 9.03643 6.0918 8.33203 6.52626 7.89757C6.96072 7.46311 7.66512 7.46311 8.09958 7.89757L9.09292 8.89092L14.3367 3.45966Z"
  //       fill="#646464"
  //     />
  //   </svg>
  // );
  const actionCtx = useContext(ActionContext);

  const eyeOpenIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
        stroke="#676767"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="dull-bright-filter"
        d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
        stroke="#676767"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const eyeCloseIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const dropIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.70711 7.29289L10 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
        fill="#1A1A1A"
      />
    </svg>
  );

  const usaIcon = (
    <svg
      className="img"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18332_23977)">
        <rect width="33" height="33" rx="16.5" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H19.8V15.4H0V0Z"
          fill="#1A47B8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8 0V2.2H46.2V0H19.8ZM19.8 4.4V6.6H46.2V4.4H19.8ZM19.8 8.8V11H46.2V8.8H19.8ZM19.8 13.2V15.4H46.2V13.2H19.8ZM0 17.6V19.8H46.2V17.6H0ZM0 22V24.2H46.2V22H0ZM0 26.4V28.6H46.2V26.4H0ZM0 30.8V33H46.2V30.8H0Z"
          fill="#F93939"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.2 2.20001V4.40001H4.4V2.20001H2.2ZM6.6 2.20001V4.40001H8.8V2.20001H6.6ZM11 2.20001V4.40001H13.2V2.20001H11ZM15.4 2.20001V4.40001H17.6V2.20001H15.4ZM13.2 4.40001V6.60001H15.4V4.40001H13.2ZM8.8 4.40001V6.60001H11V4.40001H8.8ZM4.4 4.40001V6.60001H6.6V4.40001H4.4ZM2.2 6.60001V8.80001H4.4V6.60001H2.2ZM6.6 6.60001V8.80001H8.8V6.60001H6.6ZM11 6.60001V8.80001H13.2V6.60001H11ZM15.4 6.60001V8.80001H17.6V6.60001H15.4ZM2.2 11V13.2H4.4V11H2.2ZM6.6 11V13.2H8.8V11H6.6ZM11 11V13.2H13.2V11H11ZM15.4 11V13.2H17.6V11H15.4ZM13.2 8.80001V11H15.4V8.80001H13.2ZM8.8 8.80001V11H11V8.80001H8.8ZM4.4 8.80001V11H6.6V8.80001H4.4Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_18332_23977">
          <rect width="33" height="33" rx="16.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const britishIcon = (
    <svg
      className="img"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18332_24110)">
        <rect width="33" height="33" rx="16.5" fill="#1A47B8" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-1.68526 0H-6.6V5.5L34.6586 33L39.6 33V27.5L-1.68526 0Z"
          fill="white"
        />
        <path
          d="M-4.96078 0L39.6 29.778V33H37.9992L-6.6 3.19123V0H-4.96078Z"
          fill="#F93939"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.2 0H39.6V5.5C39.6 5.5 11.0216 23.8217 -2.2 33H-6.6V27.5L35.2 0Z"
          fill="white"
        />
        <path
          d="M39.6 0H38.1079L-6.6 29.8036V33H-4.96078L39.6 3.21533V0Z"
          fill="#F93939"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2016 0H22.8369V10.179H39.6V22.8143H22.8369V33H10.2016V22.8143H-6.6V10.179H10.2016V0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8526 0H20.1474V12.6923H39.6V20.3077H20.1474V33H12.8526V20.3077H-6.6V12.6923H12.8526V0Z"
          fill="#F93939"
        />
      </g>
      <defs>
        <clipPath id="clip0_18332_24110">
          <rect width="33" height="33" rx="16.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  // const currencyAvailableList = [
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  //   {
  //     name: "United State Dollar",
  //     amount: "802.00",
  //     icon: usaIcon,
  //     symbol: "$",
  //   },
  //   { name: "British Pound", amount: "802.00", icon: britishIcon, symbol: "£" },
  // ];

  const rightChevronIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66666 12.6666L10.3333 7.99998L5.66666 3.33331"
        stroke="#9C9CAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const exchangeIcon = (
    <svg
      className="img"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.017 8.21198C15.017 7.51485 14.4446 6.99242 13.7919 6.99242L8.92692 6.99242L8.92692 6.98496L13.7919 6.98496C14.4446 6.98496 15.017 6.46253 15.017 5.76539L15.017 5.13791L17.1918 6.98869L15.017 8.83947L15.017 8.21198ZM5.98338 11.7878C5.98338 12.4849 6.55579 13.0073 7.20854 13.0073L12.0735 13.0073L12.0735 13.0148L7.20854 13.0148C6.55578 13.0148 5.98337 13.5372 5.98337 14.2344L5.98337 14.8618L3.80863 13.0111L5.98338 11.1603L5.98338 11.7878Z"
        fill="black"
        stroke="#676767"
        strokeWidth="2"
        className="dull-bright-filter"
      />
    </svg>
  );

  useEffect(() => {
    if (Object?.keys(all_currencies)?.length < 1) {
      dispatch(getAllCurrencies());
    }
    if (Object?.keys(global_verification)?.length < 1) {
      dispatch(getAllGlobalVerificationStatus());
    }

    getGlobalAccFunc();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGlobalAccFunc = async () => {
    const data = await dispatch(getAllGlobalAccount());
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      // console.log(data?.payload?.data)
      const accounts =
        data?.payload?.data?.global_accounts_available_for_current_business;
      // console.log(accounts);
      if (
        accounts?.length < 1 ||
        data?.payload?.data === "Business does not have any global account"
      ) {
        navigate("/dashboard-global-account-create");
      } else {
        // console.log(accounts);
        const primary = accounts?.find((chi) => Number(chi?.primary) === 1);
        // console.log(primary);
      }
    }
    if (
      data?.payload?.status === "fail" ||
      data?.payload?.data === "Business does not have any global account"
    ) {
      navigate("/dashboard-global-account-create");
    }
  };

  const getSymbolTotalAmount = (param) => {
    const obj = all_global_account?.all_support_currencies?.find(
      (chi) => chi?.currency === param
    );
    const symbol = obj?.symbol;
    // setTotalSymbol(symbol);
    // console.log();
    return symbol;
    // return (
    //   <><span>{symbol}</span></>
    // )
  };

  // const [currencyList, setCurrencyList] = useState([]);
  // useEffect(() => {
  //   if (
  //     all_global_account?.global_accounts_available_for_current_business
  //       ?.length > 1
  //   ) {
  //     const obj =
  //       all_global_account?.global_accounts_available_for_current_business?.find(
  //         (chi) => chi?.primary === 1
  //       );
  //     const newList =
  //       all_global_account?.global_accounts_available_for_current_business?.filter(
  //         (chi) => chi?.id !== obj?.id
  //       );
  //     // console.log(newList);
  //     setCurrencyList(newList);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [all_global_account]);

  const [currenCyDrop, setCurrencyDrop] = useState({});

  if (
    !user_resource_and_permissions?.allowed_permissions?.includes(
      "can_setup_global_account"
    )
  ) {
    return (
      <DashboardLayout>
        <TitletextBox
          title={`Global Account`}
          text={`Accept and transacts in over ${
            all_currencies?.all_support_currencies?.length - 1 || ""
          } currencies`}
        />
        {/* title box end */}
        <ContainerWrapper dontShowHeader>
          <NoContentBox
            title={`No permission to view this page`}
            text={`You will be able to view this screen when you have the permission`}
            bgColor
            loading={loading}
          />
        </ContainerWrapper>
      </DashboardLayout>
    );
  }

  return (
    <>
      <DashboardLayout>
        {/* title start */}
        <TitletextBox
          title={`Global Account`}
          text={`Accept and transacts in over ${
            all_currencies?.all_support_currencies?.length - 1 || ""
          } currencies`}
          //   addBtn={user_resource_and_permissions?.allowed_permissions?.includes(
          //     "can_create_customer"
          //   )}
          addBtn={
            global_verification?.verification_status_summary !==
              "incomplete-verification" &&
            Object.keys(all_currencies)?.length > 0 &&
            all_currencies?.currencies_available_for_setup?.length > 0 &&
            !loading
          }
          toolTipMsg={`Create new global account`}
          onAdd={() => {
            setShowModal((prev) => {
              return { ...prev, add_cur: true };
            });
          }}
          showSettingsIcon={false}
        ></TitletextBox>
        {/* titlte box end */}
        {/* container wrap start */}
        <ContainerWrapper
          //   btnText={!completeVeri && `Accept & Proceed`}
          width={`100%`}
          dontShowHeader={true}
          //   onBtnClick={() => {
          //     setCompleteVeri(true);
          //   }}
        >
          {/* global index wrap box start */}
          <div className="global-index-wrap-box">
            {Object?.keys(all_global_account)?.length > 0 &&
            all_global_account?.global_accounts_available_for_current_business
              ?.length > 0 ? (
              <>
                <div className="veri-complete-profile-content-wrap-box">
                  {/* left box start */}
                  <div className="left-veri-content-box">
                    <div className="view-exchange-amount-show-box">
                      <div className="total-amount-show-balance">
                        {/* showBalance box start */}
                        <div
                          onClick={() => {
                            if (!loading) {
                              actionCtx?.setShowAmount(!actionCtx?.showAmount);
                            }
                          }}
                          className="show-balance-box"
                        >
                          <p className="text grey-white-color">
                            {` Total Available Balance ${
                              Object?.keys(currenCyDrop)?.length > 0
                                ? `in ${currenCyDrop?.currency || ""}`
                                : ""
                            }`}
                          </p>
                          {!loading && (
                            <div className="eye-icon">
                              <figure className="img-box">
                                {!actionCtx?.showAmount ? (
                                  <>{eyeOpenIcon}</>
                                ) : (
                                  <>{eyeCloseIcon}</>
                                )}
                              </figure>
                            </div>
                          )}
                        </div>
                        {/* showBalance box end */}
                        <div className="total-amount">
                          {loading ? (
                            <Skeleton width={100} height={25} />
                          ) : (
                            <p className="amount">
                              {actionCtx?.showAmount ? (
                                <>
                                  {/* &#36; */}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `${getSymbolTotalAmount(
                                        all_global_account?.choosen_currency
                                      )}`,
                                    }}
                                  />
                                  {/* {totalSymbol} */}
                                  {`${
                                    formatNumWithCommaNaira(
                                      all_global_account?.total_balance
                                    )?.split(".")[0]
                                  }`}
                                  <span className="grey-white-color">
                                    .
                                    {
                                      formatNumWithCommaNaira(
                                        all_global_account?.total_balance
                                      )?.split(".")[1]
                                    }
                                  </span>
                                </>
                              ) : (
                                "********"
                              )}
                            </p>
                          )}
                          {loading ? (
                            <>
                              <Skeleton
                                width={60}
                                height={27}
                                style={{ borderRadius: "3.5rem" }}
                              />
                            </>
                          ) : (
                            <GlobalSelectDropBox
                              // currencyList={currencyList}
                              setCurrency={(param) => {
                                // console.log(param?.currency);
                                const obj = {
                                  cur: param?.currency,
                                };
                                dispatch(getAllGlobalAccount(obj));
                                setCurrencyDrop(param);
                              }}
                              currencyList={
                                all_currencies?.all_support_currencies
                              }
                              selectedCurrency={currenCyDrop}
                              dontautoUpdate
                              hideText={true}
                              // selectStyles={{padding: "3rem"}}
                              dropStyles={{ width: "10rem" }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setShowRate(true);
                        }}
                        className="view-exchange-box grey-bg"
                      >
                        <div className="icon-box">
                          <figure className="img-box">{exchangeIcon}</figure>
                        </div>
                        <p className="text">View Exchange Rate</p>
                      </div>
                    </div>

                    {/* currency created available box start */}
                    <div
                      className={`currency-created-available-box ${
                        all_global_account
                          ?.global_accounts_available_for_current_business
                          ?.length > 3 && "currency-created-available-box-many"
                      }`}
                    >
                      {all_global_account?.global_accounts_available_for_current_business?.map(
                        (chi, idx) => {
                          return (
                            <div
                              key={idx}
                              className={`item-global-box hover-white-class border-theme`}
                              onClick={() => {
                                // console.log(chi);
                                localStorage.setItem("global-acc-id", chi?.id);
                                navigate("/dashboard-global-account-single");
                              }}
                            >
                              <div className="cur-img-box">
                                {loading &&
                                Object?.keys(all_global_account)?.length < 1 ? (
                                  <Skeleton circle width={32} height={32} />
                                ) : (
                                  <figure className="img-box">
                                    <img
                                      src={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                                        chi?.currency
                                      )}.png`}
                                      className="img"
                                      alt=""
                                    />
                                  </figure>
                                )}
                              </div>
                              <div className="name grey-white-color">
                                {loading &&
                                Object?.keys(all_global_account)?.length < 1 ? (
                                  <Skeleton width={35} height={18} />
                                ) : (
                                  <span>{chi?.currency}</span>
                                )}
                              </div>
                              <div className="amount">
                                {loading &&
                                Object?.keys(all_global_account)?.length < 1 ? (
                                  <Skeleton
                                    width={size.width > 900 ? 100 : 60}
                                    height={20}
                                  />
                                ) : (
                                  <>
                                    {actionCtx?.showAmount && (
                                      <span
                                        className="grey-white-color"
                                        dangerouslySetInnerHTML={{
                                          __html: `${getSymbolTotalAmount(
                                            chi?.currency
                                          )}`,
                                        }}
                                      />
                                      // <span className="grey-white-color">
                                      //   {getSymbolTotalAmount(chi?.symbol)}
                                      // </span>
                                    )}
                                    {""}
                                    {actionCtx?.showAmount
                                      ? formatNumWithCommaNaira(chi?.balance)
                                      : "********"}
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    {/* currency created available box end */}
                  </div>
                  {/* left box end */}
                  {/* right box start */}

                  {/* right box end */}
                </div>
              </>
            ) : (
              <>
                {" "}
                <NocontentPage
                  loading={true}
                  // img={noContentImg}
                  title={`Receive payment globally 🌍, with your Payment links`}
                  // list={noContentList}
                  btntext={
                    user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_create_payment_link"
                    ) && `Create link`
                  }
                  // onBtnClick={() => {
                  //   navigate("/dashboard-payment-links-request-money");
                  // }}
                />
              </>
            )}
          </div>
          {/* global index wrap box end */}
        </ContainerWrapper>
        {/* container wrap end */}
      </DashboardLayout>

      <RavenModal
        visble={showRate}
        onClose={() => {
          setShowRate(false);
        }}
        btnLabel="Close"
        btnColor="black-light"
        onBtnClick={() => {
          setShowRate(false);
        }}
        className={`exchange-rate-modal-wrap`}
      >
        <ExchangeRateModalBox />
      </RavenModal>
      <CreateMoreCurrencyModal
        visible={showModal?.add_cur}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, add_cur: false };
          });
        }}
        onViewTerms={() => {
          setShowModal((prev) => {
            return { ...prev, showTerms: true };
          });
        }}
        agreeBox={agreeBox}
        setAgreeBox={setAgreeBox}
      />
      <TermsAndConditionModal
        agreeBox={agreeBox}
        setAgreeBox={setAgreeBox}
        visible={showModal?.showTerms}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, showTerms: false };
          });
        }}
      />
    </>
  );
};

export default GlobalAccountIndex;
