import { RavenButton, RavenToolTip } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/profile/DashboardProfile.css";
import defaultImg from "../../../assets/Default_pfp.svg.png";
import GenerateStatementModal from "../settings/sections/modal/GenerateStatementModal";
import ConfirmLogoutModal from "../../../layout/dashboard/ConfirmLogoutModal";
import UpdateTransactionPinModal from "../../../components/reusables/UpdateTransactionPinModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  // removeBusinessLogo,
  uploadBusinessLogo,
} from "../../../redux/user";
import ProgressBar from "../../../utils/helper/ProgressBar";
import { getTransactionLimits } from "../../../redux/transaction";
import { getTierLevel, sumAllNum } from "../../../utils/helper/Helper";
import { getDirectorShareholders } from "../../../redux/settings";
import ManageTransactionLimitModal from "./ManageTransactionLimitModal";
import EditProfileModal from "./EditProfileModal";
// import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
// import { ThreeDots } from "react-loader-spinner";

const DashboardProfileIndex = () => {
  const { loading, user, business, loadingUpload } = useSelector(
    (state) => state.user
  );
  const { directors } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [uploadFile, setUploadFile] = useState("");
  const [showModal, setShowodal] = useState({
    logout: false,
    change_pin: false,
    statement: false,
    limit: false,
    profile: false,
  });
  const editIcon = (
    <svg
      className="img  dull-bright-filter"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.08339 0.527832L7.47228 1.91672M0.527832 7.47228L0.971027 5.84723C0.999942 5.74121 1.0144 5.6882 1.03659 5.63876C1.0563 5.59487 1.08051 5.55315 1.10884 5.51426C1.14075 5.47047 1.17961 5.43161 1.25731 5.35391L4.8453 1.76592C4.91405 1.69716 4.94843 1.66279 4.98807 1.64991C5.02294 1.63858 5.0605 1.63858 5.09537 1.64991C5.13501 1.66279 5.16939 1.69716 5.23814 1.76592L6.23419 2.76197C6.30294 2.83072 6.33732 2.8651 6.3502 2.90474C6.36153 2.93961 6.36153 2.97717 6.3502 3.01204C6.33732 3.05168 6.30294 3.08605 6.23419 3.15481L2.6462 6.74279C2.5685 6.8205 2.52964 6.85935 2.48585 6.89126C2.44696 6.91959 2.40524 6.94381 2.36134 6.96351C2.31191 6.98571 2.2589 7.00017 2.15288 7.02908L0.527832 7.47228Z"
        stroke="black"
        stroke-inecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    dispatch(getTransactionLimits());
    dispatch(getDirectorShareholders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadLogo = async () => {
    const obj = {
      file: uploadFile,
    };
    const formData = new FormData();
    formData.append("file", obj?.file);
    const data = await dispatch(uploadBusinessLogo(formData));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
      setUploadFile("");
      // setShowPictureChange(false)
    }
  };

  // const handleRemoveLogo = async () => {
  //   // const obj = {
  //   //   file: uploadFile,
  //   // };
  //   // const formData = new FormData();
  //   // formData.append("file", obj?.file);
  //   const data = await dispatch(removeBusinessLogo());
  //   // console.log(data);
  //   if (
  //     data?.payload?.data?.status === "success" ||
  //     data?.payload?.data?.status === "fail"
  //   ) {
  //     dispatch(getUser());
  //     // setUploadFile(convertImagePathToFile(defaultImg))
  //   }
  // };

  // async function convertImagePathToFile(imagePath) {
  //   try {
  //     // Fetch the image using its URL
  //     const response = await fetch(imagePath);

  //     if (!response.ok) {
  //       throw new Error("Image fetch failed.");
  //     }

  //     // Read the response data as a blob
  //     const blob = await response.blob();

  //     // Create a File object from the blob
  //     const file = new File([blob], "image.jpg", { type: "image/jpeg" });

  //     return file;
  //   } catch (error) {
  //     // console.error("Error converting image path to File:", error);
  //     return null;
  //   }
  // }

  useEffect(() => {
    // if(business?.)
  }, []);

  const checkDirectorBvnStatus = (list) => {
    if (list?.length > 0) {
      const val = list?.some((chi) => chi?.bvn_verified === 0);
      return !val;
    }
  };

  // const retryIcon = (
  //   <svg
  //     className="img dull-bright-filter"
  //     width="16"
  //     height="17"
  //     viewBox="0 0 16 17"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <g clipPath="url(#clip0_16935_15530)">
  //       <path
  //         className="dull-bright-filter"
  //         d="M8.63812 15.1125L6.65436 13.1288M6.65436 13.1288L8.63812 11.145M6.65436 13.1288H9.96063C12.517 13.1288 14.5894 11.0564 14.5894 8.5C14.5894 6.65351 13.5082 5.05955 11.9444 4.31666M4.00934 12.6833C2.44551 11.9404 1.36432 10.3465 1.36432 8.5C1.36432 5.94359 3.4367 3.87122 5.9931 3.87122H9.29938M9.29938 3.87122L7.31561 1.88745M9.29938 3.87122L7.31561 5.85498"
  //         stroke="#020202"
  //         strokeWidth="1.23434"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </g>
  //     <defs>
  //       <clipPath id="clip0_16935_15530">
  //         <rect
  //           width="15.8701"
  //           height="15.8701"
  //           fill="white"
  //           transform="translate(0.0418091 0.564941)"
  //         />
  //       </clipPath>
  //     </defs>
  //   </svg>
  // );

  return (
    <>
      <DashboardLayout>
        <ContainerWrapper width={`100%`} dontShowHeader>
          <div
            onClick={() => {
              checkDirectorBvnStatus(directors);
            }}
            className="dashboard-profile-wrap-box"
          >
            {/* name img top box start */}
            <div className="name-image-top-box-wrap">
              {/* name-img start */}
              <div className="name-img-box">
                <input
                  type="file"
                  id="profile-img-upload"
                  name="profile-img-upload"
                  accept="image/png, image/gif, image/jpeg"
                  className="profile-img-input"
                  onChange={(e) => {
                    // setResetImage("");
                    setUploadFile(e.target.files[0]);
                    // console.log(e.target.files[0]);
                  }}
                />
                {loading || loadingUpload ? (
                  <Skeleton width={80} height={80} circle />
                ) : (
                  <div className="label-save-box">
                    <label
                      htmlFor="profile-img-upload"
                      className="profile-img-label "
                      style={{
                        backgroundImage: uploadFile
                          ? `url(${URL.createObjectURL(uploadFile)})`
                          : Number(business?.logo) === 0
                          ? `url(${defaultImg})`
                          : `url(${business?.business_logo || defaultImg})`,
                      }}
                    >
                      <figure className="img-box">{editIcon}</figure>
                    </label>
                    {uploadFile && (
                      <p onClick={handleUploadLogo} className="save-image-text">
                        Save Image
                      </p>
                      // <RavenButton
                      //   loading={loadingUpload}
                      //   className={`upload-profile-btn`}
                      //   size={`small`}
                      //   label={`Save Image`}
                      //   color={`black-light`}
                      //   disabled={!uploadFile}
                      //   onClick={handleUploadLogo}
                      // />
                    )}
                  </div>
                )}
                {/* reset to default box start */}
                {/* <>
                  {" "}
                  {loading ? (
                    <div className="reset-to-default-box ">
                      {" "}
                      <Skeleton width={25} height={25} circle />
                    </div>
                  ) : (
                    <>
                      {" "}
                      {!uploadFile && Number(business?.logo) !== 0 && (
                        <div
                          onClick={handleRemoveLogo}
                          className="reset-to-default-box grey-bg"
                        >
                          <figure className="img-box tooltip-hover-wrap">
                            {retryIcon}
                            <RavenToolTip
                              color={`black-light`}
                              text={`Remove business logo`}
                              position={`bottom-right`}
                            />
                          </figure>
                        </div>
                      )}
                    </>
                  )}
                </> */}

                {/* reset to default box end */}

                {/* name email box start */}
                <div className="name-email-box">
                  {loading ? (
                    <div style={{ display: "flex", marginBottom: ".6rem" }}>
                      <Skeleton
                        width={70}
                        height={20}
                        style={{ marginRight: ".9rem" }}
                      />
                      <Skeleton width={70} height={20} />
                    </div>
                  ) : (
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="name"
                    >{`${user?.fname || "---"} ${user?.lname || "---"}`}</p>
                  )}
                  {loading ? (
                    <Skeleton width={200} height={20} />
                  ) : (
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="busineness-name grey-white-color"
                    >
                      {user?.clearance || "---"}
                    </p>
                  )}
                  {/* limit bar start */}
                  <div className="transfer-limit-box">
                    <div className="wrap tooltip-hover-wrap">
                      {sumAllNum([
                        business?.cac === 3 ? 25 : 0,
                        business?.nin === 3 ? 25 : 0,
                        business?.bvn === 3 ? 25 : 0,
                        checkDirectorBvnStatus(directors) ? 25 : 0,
                      ]) <= 100 && (
                        <RavenToolTip
                          color={`black-light`}
                          position={`bottom-center`}
                          text={
                            getTierLevel(business)?.val === "tier_1_after_nin"
                              ? `Complete Address verification to increase limit`
                              : getTierLevel(business)?.val ===
                                "tier_1_after_address"
                              ? `Complete Tier 2 verification to increase limit`
                              : getTierLevel(business)?.val === "tier_2"
                              ? `Complete Tier 3 to increase limit`
                              : `Verify your Tier 1 to increase limit`
                          }
                        />
                      )}
                      <ProgressBar
                        emptyBg={`#F0EEFF`}
                        bgcolor={`#755AE2`}
                        completed={
                          getTierLevel(business)?.val === "tier_1_after_nin"
                            ? 25
                            : getTierLevel(business)?.val ===
                              "tier_1_after_address"
                            ? 50
                            : getTierLevel(business)?.val === "tier_2"
                            ? 75
                            : getTierLevel(business)?.val === "tier_3"
                            ? 100
                            : 0
                        }
                      />
                    </div>
                    {user?.fname && (
                      <p
                        onClick={() => {
                          setShowodal((prev) => {
                            return { ...prev, limit: true };
                          });
                        }}
                        className="text-bar"
                      >
                        {/* manage transaction limit */}
                        manage limit
                      </p>
                    )}
                  </div>
                  {/* limit bar end */}
                </div>
                {/* name email box end */}
              </div>
              {/* name-img end */}
              {/* two button start */}
              {user?.fname && (
                <div className="edit-logout-btn-box">
                  {loading ? (
                    <>
                      <Skeleton
                        width={70}
                        height={30}
                        style={{ borderRadius: ".8rem" }}
                      />{" "}
                      <Skeleton
                        width={70}
                        height={30}
                        style={{ borderRadius: ".8rem" }}
                      />{" "}
                    </>
                  ) : (
                    <>
                      {business?.bvn !== 3 && (
                        <RavenButton
                          textColor={`black-light`}
                          className="btn-outline-base-bg-black"
                          label="Edit Profile"
                          size={`small`}
                          onClick={() => {
                            setShowodal((prev) => {
                              return { ...prev, profile: true };
                            });
                          }}
                        />
                      )}
                      <RavenButton
                        size={`small`}
                        color={`error-light`}
                        label={`Logout`}
                        onClick={() => {
                          setShowodal((prev) => {
                            return { ...prev, logout: true };
                          });
                        }}
                      />
                    </>
                  )}
                </div>
              )}
              {/* two button end */}
            </div>
            {/* name img top box enmd */}
            {/* underline start */}
            <div className="underline border-theme"></div>
            {/* underline end */}
            {/* detail box start */}
            <div className="detail-box-wrap">
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">First Name</p>
                {loading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  <p className="value">{user?.fname || "---"}</p>
                )}
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Address</p>
                {loading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  <p className="value">{business?.business_address || "---"}</p>
                )}
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Last Name</p>
                {loading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  <p className="value">{user?.lname || "---"}</p>
                )}
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Business Name</p>
                {loading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  <p className="value">{business?.business_name || "---"}</p>
                )}
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Email Address</p>
                {loading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  <p style={{ textTransform: "unset" }} className="value">
                    {user?.email || "---"}
                  </p>
                )}
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Phone Number</p>
                {loading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  <p className="value">{user?.phone || "---"}</p>
                )}
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Business Industry</p>
                {loading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  <p className="value">
                    {business?.business_industry || "---"}
                  </p>
                )}
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Employees Count</p>
                {loading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  <p className="value">{business?.number_of_staff || "---"}</p>
                )}
              </div>
              {/* box end */}
            </div>
            {/* detail box end */}
            {/* name img top box enmd */}
            {/* underline start */}
            <div className="underline border-theme"></div>
            {/* underline end */}

            {/* action box start */}
            {/* <div className="action-box">
              {actionList.map((chi, idx) => {
                const { icon, name, text, link } = chi;
                if (link) {
                  return (
                    <a
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      href={link}
                    >
                      <div key={idx} className="box">
                        <div className={`img-wrap`}>
                          <figure className="img-box grey-bg">
                            <img
                              src={icon}
                              alt=""
                              className={`img ${
                                name === "Terms & Conditions" && "img-small"
                              } ${name === "Privacy Policies" && "img-small"}`}
                            />
                          </figure>
                        </div>
                        <div className="text-box">
                          <p className="value">{name}</p>
                          <p className="label grey-white-color">{text}</p>
                        </div>
                      </div>
                    </a>
                  );
                }
                return (
                  <div
                    onClick={() => {
                      name === "Account Statement" &&
                        setShowodal((prev) => {
                          return { ...prev, statement: true };
                        });
                      name === "Account PIN" &&
                        setShowodal((prev) => {
                          return { ...prev, change_pin: true };
                        });
                      name === "Transaction Limits" &&
                        setShowodal((prev) => {
                          return { ...prev, limit: true };
                        });
                    }}
                    key={idx}
                    className="box"
                  >
                    <div className={`img-wrap`}>
                      <figure className="img-box grey-bg">
                        <img
                          src={icon}
                          alt=""
                          className={`img ${
                            name === "Terms & Conditions" && "img-small"
                          } ${name === "Privacy Policies" && "img-small"}`}
                        />
                      </figure>
                    </div>
                    <div className="text-box">
                      <p className="value">{name}</p>
                      <p className="label grey-white-color">{text}</p>
                    </div>
                  </div>
                );
              })}
            </div> */}
            {/* action box end */}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      {/* statement start */}
      <GenerateStatementModal
        visible={showModal?.statement}
        onCancel={() => {
          setShowodal((prev) => {
            return { ...prev, statement: false };
          });
        }}
      />
      {/* statement end */}
      {/* logout modal start */}
      <ConfirmLogoutModal
        visible={showModal?.logout}
        onCancel={() => {
          setShowodal((prev) => {
            return { ...prev, logout: false };
          });
        }}
      />
      {/* logout modal end */}
      {/* change pin start */}
      <UpdateTransactionPinModal
        visible={showModal?.change_pin}
        onClose={() => {
          setShowodal((prev) => {
            return { ...prev, change_pin: false };
          });
        }}
      />
      {/* change pin end */}
      {/* change pin start */}
      <ManageTransactionLimitModal
        visible={showModal?.limit}
        onClose={() => {
          setShowodal((prev) => {
            return { ...prev, limit: false };
          });
        }}
      />
      {/* change pin end */}
      {/* change pin start */}
      <EditProfileModal
        visible={showModal?.profile}
        onClose={() => {
          setShowodal((prev) => {
            return { ...prev, profile: false };
          });
        }}
      />
      {/* change pin end */}
    </>
  );
};

export default DashboardProfileIndex;

// GenerateStatementModal
