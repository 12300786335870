/* eslint-disable jsx-a11y/anchor-is-valid */
// import { RavenButton, RavenToolTip } from "@ravenpay/raven-bank-ui";
import {
  RavenButton,
  RavenInputField,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import ".././../../styles/dashboard/invoice/preview_invoice.css";
// import noTapImg from "../../../assets/no-tap-paymentlink-img.svg";
import copyIcon from "../../../assets/payment-link-copy-icon.svg";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import DeleteModal from "../../../components/reusables/DeleteModal";
import DeleteInvoiceModal from "./DeleteInvoiceModal";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  reactSelectStyle,
  trimLongString,
} from "../../../utils/helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import { formatNumWithoutCommaNaira } from "../../../utils/Helpers";
import {
  downloadInvoice,
  // downloadInvoice,
  getInvoice,
  updateInvoice,
} from "../../../redux/invoice";
import Skeleton from "react-loading-skeleton";
import NocontentPage from "../../../components/reusables/NocontentPage";
import ShareInvoiceModal from "./ShareInvoiceModal";

const PreviewInvoice = () => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const [showModal, setShowModal] = useState({
    delete: false,
    share: false,
  });
  // const [itemArr, setItemArr] = useState([]);
  const { business, user_resource_and_permissions } = useSelector(
    (state) => state.user
  );
  //  const [singleChild, setSingleChild] = useState({})

  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const { loadingDelete } = useSelector((state) => state.invoice);
  const handleCopied = async (num) => {
    setIsCopied(true);
    await navigator.clipboard.writeText(
      `${
        window.location.href.split("/dashboard-invoice-preview")[0]
      }/invoice-preview?ref=${formData?.reference}`
    );
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  const [statusValue, setStatusVal] = useState("");
  useEffect(() => {
    getSingleDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculatePercent = (amount, percent) => {
    const val = Math.round((Number(percent) * Number(amount)) / 100);
    return val;
  };
  const [loading, setLoading] = useState(true);
  const getSingleDetail = async () => {
    const id = localStorage.getItem("invoice-single-id");
    if (
      !id ||
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_view_invoice"
      )
    ) {
      navigate("/dashboard-invoice");
      return;
    }
    const obj = {
      id: id,
    };
    if (id) {
      const data = await dispatch(getInvoice(obj));
      setLoading(true);
      if (data?.payload?.data?.status === "success") {
        setLoading(false);
        const obj = data?.payload?.data?.data;
        const statObj = {
          label:
            Number(obj?.status) === 0
              ? "Pending"
              : Number(obj?.status) === 1
              ? "Unpaid"
              : Number(obj?.status) === 2
              ? "Paid"
              : Number(obj?.status) === 3
              ? "Due"
              : "",
          value: obj?.status,
        };
        // console.log(obj);
        setStatusVal(statObj);
        setFormData(obj);
      } else {
        setLoading(false);
      }
    }
  };
  // 0 = pending, 1 = unpaid, 2 = paid, 3 = due
  const statusOptions = [
    { label: "Pending", value: 0 },
    { label: "Unpaid", value: 1 },
    { label: "Paid", value: 2 },
    { label: "Due", value: 3 },
  ];

  const handleUpdate = async (val) => {
    const obj = {
      ...formData,
      status: String(val?.value),
    };

    dispatch(updateInvoice(obj));
  };

  const handleDownload = async () => {
    const obj = {
      id: String(formData?.id),
    };
    // console.log(obj);
    const data = await dispatch(downloadInvoice(obj));
    if (data?.payload?.data?.status === "success") {
      downloadCsv(data?.payload?.data?.data?.url);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };
  // const id = localStorage.getItem("invoice-single-id");

  // const handleMoveToPreview = () => {
  //   // const id = localStorage.getItem("invoice-single-id");
  //   const url = `/invoice-preview?ref=${id}`;
  //   // console.log(url);
  //   // console.log(window.location.href.split("/dashboard-invoice-preview")[0]);
  // };

  // if(loading){
  //   return (
  //     <NocontentPage
  //     loading={true}
  //     // img={noContentImg}
  //     type="two"
  //     title={`No Transactions yet`}
  //     normtext={`You haven’t made any transaction yet, make your first Transfer, and we would help you keep tab of all your transactions here.`}
  //   />
  //   )
  // }

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Invoice Name`}
          text={`Make few changes to your invoice.`}
          back={true}
          onBack={() => {
            navigate(-1);
          }}
        ></TitletextBox>
        {/* wrap start */}
        <ContainerWrapper width={`100%`} dontShowHeader>
          {!loading ? (
            <div className="invoice-wrap-box">
              <div
                onClick={() => {
                  // console.log(formData);
                }}
                className="left-box"
              >
                <div className="invoice-preview-detail-wrap remove-dark-border">
                  {/* box start */}
                  <div className="box grey-bg top-info">
                    <div className="left-box">
                      <p className="label">Invoice Number:</p>
                      {loading ? (
                        <Skeleton width={`50%`} height={15} />
                      ) : (
                        <p className="value grey-white-color">
                          {formData?.displayId || "---"}
                        </p>
                      )}
                    </div>
                    <div className="right-box">
                      <p className="label">Date:</p>
                      {loading ? (
                        <Skeleton width={`50%`} height={15} />
                      ) : (
                        <p className="value grey-white-color">
                          {formData?.invoice_date && formData?.due_date
                            ? `${
                                formatDate(formData?.invoice_date)?.split(
                                  "—"
                                )[0]
                              } — ${
                                formatDate(formData?.due_date)?.split("—")[0]
                              }`
                            : "---"}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* box end */}
                  {/* box start */}
                  <div className="box middle-info">
                    <div className="left-box">
                      <p className="label">Bill From:</p>
                      {loading ? (
                        <Skeleton
                          width={`50%`}
                          height={15}
                          style={{ marginBottom: ".7rem" }}
                        />
                      ) : (
                        <p
                          style={{ textTransform: "capitalize" }}
                          className="value grey-white-color"
                        >
                          {trimLongString(business?.business_name, 30) || "---"}
                        </p>
                      )}
                      {loading ? (
                        <Skeleton
                          width={`50%`}
                          height={15}
                          style={{ marginBottom: ".7rem" }}
                        />
                      ) : (
                        <p className="value grey-white-color">
                          {trimLongString(business?.business_address, 50) ||
                            "---"}
                        </p>
                      )}
                      {loading ? (
                        <Skeleton width={`50%`} height={15} />
                      ) : (
                        <p className="value grey-white-color">
                          {trimLongString(business?.business_email, 25) ||
                            "---"}
                        </p>
                      )}
                    </div>
                    <div className="right-box">
                      <p className="label">Bill To:</p>
                      {loading ? (
                        <Skeleton
                          width={`50%`}
                          height={15}
                          style={{ marginBottom: ".7rem" }}
                        />
                      ) : (
                        <p
                          style={{ textTransform: "capitalize" }}
                          className="value grey-white-color"
                        >
                          {trimLongString(formData?.customer_name, 30) || "---"}
                        </p>
                      )}
                      {loading ? (
                        <Skeleton
                          width={`50%`}
                          height={15}
                          style={{ marginBottom: ".7rem" }}
                        />
                      ) : (
                        <p className="value grey-white-color">
                          {trimLongString(formData?.customer_email, 25) ||
                            "---"}
                        </p>
                      )}
                      {loading ? (
                        <Skeleton width={`50%`} height={15} />
                      ) : (
                        <p className="value grey-white-color">
                          {formData?.customer_phone || "---"}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* box end */}
                  {/* box start */}
                  <div className="box grey-bg top-info detail-info">
                    <div className="left-box">
                      <p className="label">Items</p>
                    </div>
                    <div className="right-box">
                      <p className="label">Cost</p>
                      <p className="label">Qty</p>
                      <p className="label">Price</p>
                    </div>
                  </div>
                  {/* box end */}

                  {/* box start */}
                  {formData?.items?.length > 0 &&
                    formData?.items?.map((chi, idx) => {
                      const { product, quantity, unit_price, id, amount } = chi;
                      return (
                        <div key={id || idx} className="box sum-detail-box">
                          <div className="left-box">
                            {loading ? (
                              <Skeleton
                                width={`50%`}
                                height={15}
                                style={{ marginBottom: ".7rem" }}
                              />
                            ) : (
                              <p className="name">{product || "---"}</p>
                            )}
                          </div>
                          <div className="right-box">
                            {loading ? (
                              <Skeleton
                                width={`50%`}
                                height={15}
                                style={{ marginBottom: ".7rem" }}
                              />
                            ) : (
                              <p className="cost">
                                {formatNumWithCommaNairaSymbol(
                                  Number(
                                    formatNumWithoutCommaNaira(unit_price)
                                  ) || "0"
                                )}
                              </p>
                            )}
                            {loading ? (
                              <Skeleton
                                width={`20%`}
                                height={15}
                                style={{ marginBottom: ".7rem" }}
                              />
                            ) : (
                              <p className="quantity">{quantity || "---"}</p>
                            )}
                            {loading ? (
                              <Skeleton
                                width={`50%`}
                                height={15}
                                style={{ marginBottom: ".7rem" }}
                              />
                            ) : (
                              <p className="total-price">
                                {formatNumWithCommaNairaSymbol(amount || "0")}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {/* box end */}
                  {/* box start */}
                  <div className="box sum-detail-box sum-detail-box-underline">
                    <div className="left-box"></div>
                    <div className="right-box">
                      <div className="underline border-theme"></div>
                    </div>
                  </div>
                  {/* box end */}
                  {/* box start summary start */}
                  <div className="box sum-detail-box">
                    <div className="left-box">
                      <p className=""></p>
                    </div>
                    <div className="right-box">
                      <p className="cost grey-white-color">Subtotal:</p>
                      <p className="quantity"></p>
                      {loading ? (
                        <Skeleton
                          width={`50%`}
                          height={15}
                          style={{ marginBottom: ".7rem" }}
                        />
                      ) : (
                        <p className="total-price">
                          {formatNumWithCommaNairaSymbol(formData?.net || "0")}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="box sum-detail-box">
                    <div className="left-box">
                      <p className=""></p>
                    </div>
                    <div className="right-box">
                      <p className="cost grey-white-color">
                        Discount ({formData?.discount}%)
                      </p>
                      <p className="quantity"></p>
                      {loading ? (
                        <Skeleton
                          width={`50%`}
                          height={15}
                          style={{ marginBottom: ".7rem" }}
                        />
                      ) : (
                        <p className="total-price">
                          -{" "}
                          {formatNumWithCommaNairaSymbol(
                            calculatePercent(
                              formData?.discount,
                              formData?.gross
                            ) || "0"
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="box sum-detail-box">
                    <div className="left-box">
                      <p className=""></p>
                    </div>
                    <div className="right-box">
                      <p className="cost grey-white-color">
                        Tax ({formData?.tax}%)
                      </p>
                      <p className="quantity"></p>
                      {loading ? (
                        <Skeleton
                          width={`50%`}
                          height={15}
                          style={{ marginBottom: ".7rem" }}
                        />
                      ) : (
                        <p className="total-price">
                          {" "}
                          {formatNumWithCommaNairaSymbol(
                            calculatePercent(formData?.tax, formData?.gross) ||
                              "0"
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  {formData?.shipping_fee ? (
                    <div className="box sum-detail-box">
                      <div className="left-box">
                        <p className=""></p>
                      </div>
                      <div className="right-box">
                        <p className="cost grey-white-color">Shipping Fee</p>
                        <p className="quantity"></p>
                        {loading ? (
                          <Skeleton
                            width={`50%`}
                            height={15}
                            style={{ marginBottom: ".7rem" }}
                          />
                        ) : (
                          <p className="total-price">
                            {" "}
                            {formatNumWithCommaNairaSymbol(
                              formData?.shipping_fee
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* box start */}
                  <div className="box sum-detail-box sum-detail-box-underline">
                    <div className="left-box"></div>
                    <div className="right-box">
                      <div className="underline border-theme"></div>
                    </div>
                  </div>
                  {/* box end */}
                  <div className="box sum-detail-box">
                    <div className="left-box">
                      <p className=""></p>
                    </div>
                    <div className="right-box">
                      <p className="cost grey-white-color">Invoice Total</p>
                      <p className="quantity"></p>
                      {loading ? (
                        <Skeleton
                          width={`50%`}
                          height={15}
                          style={{ marginBottom: ".7rem" }}
                        />
                      ) : (
                        <p
                          className="total-price"
                          style={{ fontWeight: "bold" }}
                        >
                          {formatNumWithCommaNairaSymbol(
                            formData?.gross || "0"
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* box end summary end */}
                  <div className="underline-box">
                    <div className="underline border-theme"></div>
                  </div>
                  {/* note wrap start */}
                  <div className="note-wrap-box">
                    <p className="note">Note:</p>
                    <p className="text grey-white-color">
                      {`Hi ${formData?.customer_name || "--"} ${
                        formData?.note || "--"
                      }`}
                    </p>
                  </div>
                  {/* note wrap end */}
                </div>
              </div>
              {/* left box end */}
              <div className="right-box">
                <div className="content-selected-box">
                  {/* lunch link url start */}
                  {Number(formData?.status) !== 2 &&
                    user_resource_and_permissions?.allowed_permissions?.length >
                      0 &&
                    user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_share_invoice"
                    ) && (
                      <div className="launch-link-url-box remove-dark-border grey-bg">
                        <div className="link-copy-box">
                          {/* <a href={formData?.}></a> */}
                          <div className="link-box">
                            <figure className="img-box">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="img dull-bright-filter"
                              >
                                <path
                                  d="M9.59028 14.3034L8.41177 15.4819C6.78459 17.1091 4.1464 17.1091 2.51922 15.4819C0.892032 13.8547 0.892032 11.2165 2.51922 9.58931L3.69773 8.4108M14.3043 9.58931L15.4828 8.4108C17.11 6.78361 17.11 4.14542 15.4828 2.51824C13.8557 0.891055 11.2175 0.891056 9.59028 2.51824L8.41177 3.69675M6.08436 11.9167L11.9177 6.08337"
                                  // stroke="black"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </figure>
                            <p className="link">
                              {trimLongString(
                                `${
                                  window.location.href.split(
                                    "/dashboard-invoice-preview"
                                  )[0]
                                }/invoice-preview?ref=${formData?.reference}`,
                                35
                              )}
                            </p>
                          </div>
                          {user_resource_and_permissions?.allowed_permissions?.includes(
                            "can_share_invoice"
                          ) && (
                            <div className="copy-box">
                              {!isCopied ? (
                                <figure
                                  onClick={handleCopied}
                                  className="img-box tooltip-hover-wrap "
                                >
                                  <RavenToolTip
                                    color="black-light"
                                    text="Copy URL"
                                    position={`left`}
                                  />
                                  <img src={copyIcon} alt="" className="img" />
                                </figure>
                              ) : (
                                <div className="icon-box">
                                  <FaCheck className="icon" />
                                </div>
                              )}
                            </div>
                          )}
                          {/*  */}
                        </div>
                        {/* end of link copy box */}
                        {/* launch box start */}
                        <div className="launch-link">
                          {" "}
                          <div
                            onClick={() => {
                              setShowModal((prev) => {
                                return { ...prev, share: true };
                              });
                            }}
                            className="launch-box white-black-bg"
                          >
                            <figure
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: ".4rem",
                              }}
                              className="img-box"
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="img dull-bright-filter"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              >
                                <path
                                  d="M13 5L13 1M13 1H9M13 1L7.66667 6.33333M5.66667 2.33333H4.2C3.0799 2.33333 2.51984 2.33333 2.09202 2.55132C1.71569 2.74307 1.40973 3.04903 1.21799 3.42535C1 3.85318 1 4.41323 1 5.53333V9.8C1 10.9201 1 11.4802 1.21799 11.908C1.40973 12.2843 1.71569 12.5903 2.09202 12.782C2.51984 13 3.0799 13 4.2 13H8.46667C9.58677 13 10.1468 13 10.5746 12.782C10.951 12.5903 11.2569 12.2843 11.4487 11.908C11.6667 11.4802 11.6667 10.9201 11.6667 9.8V8.33333"
                                  // stroke="black"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </figure>
                            <p className="text">SHARE URL</p>
                          </div>
                          {/* </div> */}
                          <a
                            href={formData?.payment_link}
                            className="preview-icon tooltip-hover-wrap white-black-bg"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                          >
                            {" "}
                            <RavenToolTip
                              color="black-light"
                              text="Launch URL to preview"
                              position={`left`}
                            />
                            <figure className="img-box">
                              <svg
                                className="img dull-bright-filter"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  className="img dull-bright-filter"
                                  d="M21 6H17.8C16.1198 6 15.2798 6 14.638 6.32698C14.0735 6.6146 13.6146 7.07354 13.327 7.63803C13 8.27976 13 9.11984 13 10.8V12M21 6L18 3M21 6L18 9M10 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V14"
                                  stroke="black"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </figure>
                          </a>
                        </div>
                        {/* launch box end */}
                      </div>
                    )}
                  {/* lunch link url  end */}
                  {/* change invoice status start */}
                  <div className="change_invoice_status">
                    {user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_update_invoice"
                    ) && (
                      <form autoComplete="off" action="">
                        <RavenInputField
                          type={"select"}
                          label={"Change Invoice status"}
                          color={`black-light`}
                          placeholder={`Select Status`}
                          onChange={(e) => {
                            handleUpdate(e);
                            setStatusVal(e);
                          }}
                          disabled={Number(formData?.status) === 2}
                          value={statusValue}
                          selectStyles={reactSelectStyle}
                          selectOption={statusOptions}
                          loadingSelect={loading}
                        />
                      </form>
                    )}
                    {Number(formData?.status) === 2 && (
                      <>
                        <div className="payment-detail-record border-theme-top">
                          <p className="title">Payment Information.</p>

                          <div className="label-value-detail-wrap">
                            {/* box start */}
                            <div className="box">
                              <p className="label grey-white-color">
                                Transaction date:
                              </p>
                              {loading ? (
                                <Skeleton
                                  width={100}
                                  height={15}
                                  style={{ marginBottom: ".7rem" }}
                                />
                              ) : (
                                <p className="value">
                                  {formData?.paid_at
                                    ? formatDate(formData?.paid_at)
                                    : "---"}
                                </p>
                              )}
                            </div>
                            {/* box end */}
                            {/* box start */}
                            <div className="box">
                              <p className="label grey-white-color">
                                Payment Channel:
                              </p>
                              {loading ? (
                                <Skeleton
                                  width={100}
                                  height={15}
                                  style={{ marginBottom: ".7rem" }}
                                />
                              ) : (
                                <p className="value">
                                  {formatTypeFunction(
                                    formData?.payment_method_used
                                  ) || "---"}
                                </p>
                              )}
                            </div>
                            {/* box end */}
                            {/* box start */}
                            <div className="box">
                              <p className="label grey-white-color">
                                Amount Paid:
                              </p>
                              {loading ? (
                                <Skeleton
                                  width={100}
                                  height={15}
                                  style={{ marginBottom: ".7rem" }}
                                />
                              ) : (
                                <p className="value">
                                  {formatNumWithCommaNairaSymbol(
                                    formData?.paid_amount || 0
                                  )}
                                </p>
                              )}
                            </div>
                            {/* box end */}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="btn_wrapper">
                      <div
                        style={{
                          display:
                            Number(formData?.status) === 2 ? "flex" : "grid",
                        }}
                        className="two-btn-wrapper"
                      >
                        {user_resource_and_permissions?.allowed_permissions?.includes(
                          "can_download_invoice"
                        ) && (
                          <RavenButton
                            style={{ width: "100%" }}
                            color={`black-light`}
                            // size={"big"}
                            
                            loading={loadingDelete}
                            label={"Download"}
                            onClick={() => {
                              handleDownload();
                              // console.log(formData);
                            }}
                            // disabled={Number(formData?.status) === 2}
                          />
                        )}
                        {Number(formData?.status) !== 2 &&
                          user_resource_and_permissions?.allowed_permissions?.includes(
                            "can_update_invoice"
                          ) && (
                            <RavenButton
                              label="Edit Invoice"
                              onClick={() => {
                                localStorage?.setItem(
                                  "invoice-single-id",
                                  formData?.id
                                );
                                navigate("/dashboard-invoice-edit");
                              }}
                              style={{ width: "100%" }}
                              // color={`black-light`}
                              className={`btn-outline-black-light`}
                              // textColor={`black-light`}
                            />
                          )}
                      </div>

                      {Number(formData?.status) !== 2 &&
                        user_resource_and_permissions?.allowed_permissions?.includes(
                          "can_delete_invoice"
                        ) && (
                          <span
                            onClick={() => {
                              setShowModal((prev) => {
                                return { ...prev, delete: true };
                              });
                            }}
                            className="delete"
                          >
                            Delete this invoice
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NocontentPage
              loading={true}
              // img={noContentImg}
              type="two"
              title={`No Transactions yet`}
              normtext={`You haven’t made any transaction yet, make your first Transfer, and we would help you keep tab of all your transactions here.`}
            />
          )}
        </ContainerWrapper>
        {/* wrap end */}
      </DashboardLayout>
      {/* delete modal start */}
      <DeleteInvoiceModal
        visible={showModal?.delete}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, delete: false };
          });
        }}
        detail={formData}
        onFinish={() => {
          navigate("/dashboard-invoice");
        }}
      />
      {/* delete modal end */}
      {/* delete modal start */}
      <ShareInvoiceModal
        visible={showModal?.share}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, share: false };
          });
        }}
        detail={formData}
        onFinish={() => {
          navigate("/dashboard-invoice");
        }}
      />
      {/* delete modal end */}
    </>
  );
};

export default PreviewInvoice;
