import { RavenModal, RavenNumberFormat } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../styles/dashboard/profile/DashboardProfile.css";
import {
  getCurrentObj,
  getTierLevel,
  //   formatNumWithCommaNairaSymbol,
  // numberFormatChart,
  sumAllNum,
} from "../../../utils/helper/Helper";
import ProgressBar from "../../../utils/helper/ProgressBar";

const ManageTransactionLimitModal = ({ visible, onClose }) => {
  const { business } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { directors } = useSelector((state) => state.settings);
  const { transaction_limit } = useSelector((state) => state.transaction);
  const [showAll, setShowAll] = useState(false);
  const checkDirectorBvnStatus = (list) => {
    if (list?.length > 0) {
      const val = list?.some((chi) => chi?.bvn_verified === 0);
      return !val;
    }
  };

  // const nairaSymbol = "\u{020A6}";



  return (
    <RavenModal
      className={`transfer-limit-modal-wrap`}
      visble={visible}
      btnColor={`black-light`}
      btnLabel={`Increase limit`}
      onClose={onClose}
      disabled={
        sumAllNum([
          business?.cac === 3 ? 25 : 0,
          business?.bvn === 3 ? 25 : 0,
          business?.nin === 3 ? 25 : 0,
          checkDirectorBvnStatus(directors) ? 25 : 0,
        ]) >= 100
      }
      onBtnClick={() => {
        navigate("/dashboard-verification");
      }}
    >
      <div className="manage-limit-transaction-modal-wrap">
        <div className="text-label-action-box">
          <div
            // onClick={() => {
            //   console.log(transaction_limit);
            // }}
            className="text-box"
          >
            <p className="title">Account Limits</p>
            <p className="text grey-white-color">Set your account limit </p>
          </div>
          {!getTierLevel(business)?.completed?.includes("tier_3") && (
            <p
              onClick={() => {
                navigate("/dashboard-verification");
              }}
              className="upgrade"
            >
              Upgrade Account
            </p>
          )}
        </div>
        <div className="underline border-theme"></div>
        {/* progress container start */}
        <div className="progress-container-wrap progress-bar-wrapper">
          <div className="box">
            <ProgressBar
              emptyBg={`#f7f8f7`}
              bgcolor={`#755AE2`}
              height={8}
              completed={
                getTierLevel(business)?.completed?.includes("tier_1_after_nin")
                  ? 100
                  : 0
              }
              borderRadius={"unset"}
            />
          </div>
          {/* end box */}
          <div className="box">
            <ProgressBar
              emptyBg={`#f7f8f7`}
              bgcolor={`#755AE2`}
              height={8}
              completed={
                getTierLevel(business)?.completed?.includes("tier_1_after_address")
                  ? 100
                  : 0
              }
              borderRadius={"unset"}
            />
          </div>
          {/* end box */}
          <div className="box">
            <ProgressBar
              emptyBg={`#f7f8f7`}
              bgcolor={`#755AE2`}
              height={8}
              completed={
                getTierLevel(business)?.completed?.includes("tier_2") ? 100 : 0
              }
              borderRadius={"unset"}
            />
          </div>
          {/* end box */}
          <div className="box">
            <ProgressBar
              emptyBg={`#f7f8f7`}
              bgcolor={`#755AE2`}
              completed={
                getTierLevel(business)?.completed?.includes("tier_3") ? 100 : 0
              }
              borderRadius={"unset"}
            />
          </div>
        </div>
        {/* progress container end */}
        {/* progress label start */}
        <div className="progress-label-box">
          <p className="label grey-white-color">Tier 1 </p>
          <p className="label grey-white-color">Tier 2 </p>
          <p className="label grey-white-color">Tier 3</p>
          <p className="label grey-white-color">Tier 4</p>
        </div>
        {/* progress label end */}
        {/* preview limit bosx start */}
        <div className="preview-limit-box grey-bg">
          <div className="box">
            <p className="label grey-white-color">Single Limit:</p>
            <p className="value">
              {" "}
              {/* <span className="grey-white-color">{nairaSymbol}</span> */}
              {RavenNumberFormat(
                getCurrentObj(transaction_limit, getTierLevel(business)?.val)
                  ?.single_transfer_limit || 0
              )}
            </p>
          </div>
          <div className="box">
            <p className="label grey-white-color">Daily Limit:</p>
            <p className="value">
              {" "}
              {/* <span className="grey-white-color">{nairaSymbol}</span> */}
              {RavenNumberFormat(
                getCurrentObj(transaction_limit, getTierLevel(business)?.val)?.daily ||
                  0
              )}
            </p>
          </div>
          <p
            onClick={() => {
              setShowAll(!showAll);
            }}
            className="show-all"
          >
            {showAll ? "Close all limits" : "show all limits"}
          </p>
        </div>
        {/* preview limit bosx end */}
        {/* show all box start */}
        <div
          className={`show-all-box grey-bg ${showAll && "show-all-box-show"}`}
        >
          {/* row start */}
          <div className="row border-theme-bottom">
            <div className="left-box">
              <p className="text grey-white-color">Tier 1 </p>
            </div>
            <div className="right-box">
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Single Limit</p>
                <p className="value">
                  {RavenNumberFormat(
                    getCurrentObj(transaction_limit, "tier_1_after_nin")
                      ?.single_transfer_limit || 0
                  )}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Daily Limit</p>
                <p className="value">
                  {RavenNumberFormat(
                    getCurrentObj(transaction_limit, "tier_1_after_nin")
                      ?.daily || 0
                  )}
                </p>
              </div>
              {/* box end */}
            </div>
          </div>
          {/* row end */}
          {/* row start */}
          <div className="row border-theme-bottom">
            <div className="left-box">
              <p className="text grey-white-color">Tier 2</p>
            </div>
            <div className="right-box">
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Single Limit</p>
                <p className="value">
                  {RavenNumberFormat(
                    getCurrentObj(transaction_limit, "tier_1_after_address")
                      ?.single_transfer_limit || 0
                  )}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Daily Limit</p>
                <p className="value">
                  {RavenNumberFormat(
                    getCurrentObj(transaction_limit, "tier_1_after_address")
                      ?.daily || 0
                  )}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                <p className="label grey-white-color">Monthly Limit</p>
                <p className="value">
                  {nairaSymbol}
                  {numberFormatChart(
                    sumAllNum([
                      Number(
                        transaction_limit?.bvn_and_id_verification?.overall
                      ),
                      Number(transaction_limit?.bvn_and_id_verification?.daily),
                    ])
                  )}
                </p>
              </div> */}
              {/* box end */}
            </div>
          </div>
          {/* row end */}
          {/* row start */}
          <div className="row border-theme-bottom">
            <div className="left-box">
              <p className="text  grey-white-color">Tier 3</p>
            </div>
            <div className="right-box">
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Single Limit</p>
                <p className="value">
                  {RavenNumberFormat(
                    getCurrentObj(transaction_limit, "tier_2")
                      ?.single_transfer_limit || 0
                  )}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Daily Limit</p>
                <p className="value">
                  {RavenNumberFormat(
                    getCurrentObj(transaction_limit, "tier_2")?.daily || 0
                  )}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                <p className="label grey-white-color">Monthly Limit</p>
                <p className="value">
                  {nairaSymbol}
                  {numberFormatChart(
                    sumAllNum([
                      Number(
                        transaction_limit?.address_verification_transfer_limit
                          ?.overall
                      ),
                      Number(
                        transaction_limit?.address_verification_transfer_limit
                          ?.daily
                      ),
                    ])
                  )}
                </p>
              </div> */}
              {/* box end */}
            </div>
          </div>
          {/* row end */}
          {/* row start */}
          <div className="row border-theme-bottom">
            <div className="left-box">
              <p className="text  grey-white-color">Tier 4</p>
            </div>
            <div className="right-box">
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Single Limit</p>
                <p className="value">
                  {RavenNumberFormat(
                    getCurrentObj(transaction_limit, "tier_3")
                      ?.single_transfer_limit || 0
                  )}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label grey-white-color">Daily Limit</p>
                <p className="value">
                  {RavenNumberFormat(
                    getCurrentObj(transaction_limit, "tier_3")?.daily || 0
                  )}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                <p className="label grey-white-color">Monthly Limit</p>
                <p className="value">
                  {nairaSymbol}
                  {numberFormatChart(
                    sumAllNum([
                      Number(
                        transaction_limit?.cac_verification_transfer_limit
                          ?.overall
                      ),
                      Number(
                        transaction_limit?.cac_verification_transfer_limit
                          ?.daily
                      ),
                    ])
                  )}
                </p>
              </div> */}
              {/* box end */}
            </div>
          </div>
          {/* row end */}
        </div>
        {/* show all box end */}
      </div>
    </RavenModal>
  );
};

export default ManageTransactionLimitModal;
