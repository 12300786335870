import DashboardBillPaymentIndex from "../../../pages/dashboard/bill-payment";
import DashboardBillPaymentAirtime from "../../../pages/dashboard/bill-payment/airtime";
import DashboardBettingIndex from "../../../pages/dashboard/bill-payment/betting";
import DashboardBillPaymentCable from "../../../pages/dashboard/bill-payment/cable";
import DashboardBillPaymentData from "../../../pages/dashboard/bill-payment/data";
import DashboardBillPaymentElectricity from "../../../pages/dashboard/bill-payment/electricity";

export const dashboard_bill_payment_routes_group = [
  { path: "/dashboard-bill-payment", element: <DashboardBillPaymentIndex /> },
  {
    path: "/dashboard-bill-payment-data",
    element: <DashboardBillPaymentData />,
  },
  {
    path: "/dashboard-bill-payment-airtime",
    element: <DashboardBillPaymentAirtime />,
  },
  {
    path: "/dashboard-bill-payment-electricity",
    element: <DashboardBillPaymentElectricity />,
  },
  {
    path: "/dashboard-bill-payment-cable",
    element: <DashboardBillPaymentCable />,
  },
  {
    path: "/dashboard-bill-payment-betting",
    element: <DashboardBettingIndex />,
  },
];
