import React, { useContext, useEffect, useState } from "react";
// import { FaAngleRight, FaArrowRight } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import {
  formatDate,
  formatMetaData,
  formatNUmPan,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  // getAllAmount,
  //   getStartAndStopDates,
  getTotalPage,
  lowerCaseWrap,
  reactSelectStyle,
  sumAllNum,
  trimLongString,
} from "../../../utils/helper/Helper";
import "../../../styles/dashboard/pos/DashboardPosIndex.css";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
// import { formatDate } from "../../../utils/Helpers";
import {
  RavenInputField,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import NoContentBox from "../../../components/reusables/NoContentBox";
// import debitIcon from "../../../assets/debit-table-icon.svg";
// import creditIcon from "../../../assets/income-table-icon.svg";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import {
  getPosDashboardInfo,
  getTerminalinformations,
  getTerminalinformationSingle,
  getTerminals,
} from "../../../redux/pos";
// import moment from "moment";
import DownloadReceiptModal from "./modal/DetailTransactionModal";
import useDebounce from "../../../utils/helper/UseDebounce";
import CustomDateSelectModal from "./modal/CustomDateSelectModal";
import SpecificDateSelectModal from "./modal/SpecificDateSelectModal";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";
import ActionContext from "../../../context/ActionContext";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import { FaTimes } from "react-icons/fa";
import GenerateStatementModalPos from "./modal/GenerateAllPosStatement";

const NewTerminalInfomation = () => {
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectOptionVal, setSelectOptionVal] = useState({
    one: { label: "Today", value: "today" },
    two: "",
    three: "",
  });
  const [singleObj, setSingleObj] = useState({});
  const { loading, terminalInfo, pos_dashboard, terminalInfoSingle } =
    useSelector((state) => state.pos);
  const [terminalOptions, setTerminalOptions] = useState([]);
  //   const [singleChild, setSingleChild] = useState({});
  //   const [dateFilter, setDateFilter] = useState("");
  const { user } = useSelector((state) => state.user);
  const [sorceFilter] = useState("");
  const [showModal, setShowModal] = useState({
    disable: false,
    view: false,
    selectSpecificDate: false,
    customDate: false,
    download: false,
  });

  const navigate = useNavigate();
  const terminal_id = localStorage.getItem("pos-terminal-id");

  const headerList = [
    "NARRATION",
    "AMOUNT",
    "SOURCE",
    "LABEL",
    " DATE",
    "STATUS",
  ];

  const formatTypeWrap = (param, chi) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "0" && "status-type-wrap-pending"
        } ${param === "3" && "status-type-wrap-success"} ${
          chi?.direction === "credit" && "status-type-wrap-success"
        } ${param === "paid" && "status-type-wrap-success"} ${
          chi?.direction === "debit" && "status-type-wrap-fail"
        } ${param === "overdue" && "status-type-wrap-pending"} ${
          param === "1" && "status-type-wrap-pending"
        } ${param === "pending" && "status-type-wrap-pending"} ${
          chi?.direction === "credit" && "status-type-wrap-success"
        } ${param === "failed" && "status-type-wrap-disabled"} ${
          chi?.direction === null && "status-type-wrap-disabled"
        }`}
      >
        <span style={{ textTransform: "capitalize" }}>
          {param === "1"
            ? "Processing"
            : param === "0"
            ? "Pending"
            : param === "3"
            ? "Success"
            : param === "2"
            ? "Failed"
            : param === "11"
            ? "Reversal"
            : param === "failed"
            ? "Failed"
            : chi?.direction === null
            ? "Failed"
            : chi?.direction}
        </span>
      </div>
    );
  };

  const rowTypeText = (type, text, textTwo, chi) => {
    return (
      <div className="type-text-box">
        {loading ? (
          <>
            {" "}
            <Skeleton
              width={30}
              height={30}
              circle
              style={{ marginRight: "1rem" }}
            />{" "}
            <Skeleton width={200} height={17} />
          </>
        ) : (
          <>
            {" "}
            <div className="img-wrap">
              <figure
                className={`img-box-cre-deb ${
                  type === "debit" && "img-box-debit"
                } img-box-debit-credit ${chi?.status} ${
                  chi?.status === "failed" && "img-box-failed"
                } ${chi?.direction === null && "img-box-failed"}`}
              >
                {type === "credit" ? (
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M9 1L1 9M1 9H6.33333M1 9V3.66667"
                      stroke="#1ACE37"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M1 9L9 1M9 1H3.66667M9 1V6.33333"
                      stroke="#FF0F00"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </figure>
            </div>
            <div className=" text-box">
              <p className="text">{text || "---"}</p>
              <p className="text-small grey-white-color">
                {textTwo === "undefined" ? "--jhj-" : textTwo || "-hjdb--"}
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  const transactionActions = (param) => {
    return (
      <div className="transaction-action-wrap">
        {loading ? (
          <>
            {/* <Skeleton width={35} height={35} circle /> */}
            <Skeleton width={35} height={35} circle />
          </>
        ) : (
          <>
            <figure
              onClick={() => {
                setShowModal((prev) => {
                  return { ...prev, view: true };
                });
                setSingleObj(param);
              }}
              className="img-box grey-bg darken-action-bg tooltip-hover-wrap"
            >
              <RavenToolTip
                color={`black-light`}
                text={`View terminal details`}
                position={`left`}
              />
              <svg
                className="img dull-bright-filter"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.81509 9.53488C1.71295 9.37315 1.66188 9.29229 1.63329 9.16756C1.61182 9.07387 1.61182 8.92613 1.63329 8.83244C1.66188 8.70771 1.71295 8.62685 1.81509 8.46512C2.65915 7.12863 5.17155 3.75 9.0003 3.75C12.8291 3.75 15.3415 7.12863 16.1855 8.46512C16.2877 8.62685 16.3387 8.70771 16.3673 8.83244C16.3888 8.92613 16.3888 9.07387 16.3673 9.16756C16.3387 9.29229 16.2877 9.37315 16.1855 9.53488C15.3415 10.8714 12.8291 14.25 9.0003 14.25C5.17155 14.25 2.65915 10.8714 1.81509 9.53488Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.0003 11.25C10.2429 11.25 11.2503 10.2426 11.2503 9C11.2503 7.75736 10.2429 6.75 9.0003 6.75C7.75766 6.75 6.7503 7.75736 6.7503 9C6.7503 10.2426 7.75766 11.25 9.0003 11.25Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
          </>
        )}
      </div>
    );
  };
  const [typeFilter] = useState("");

  const [refreshTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // const todayDate = new Date();

  const exportTableIcon = (
    <svg
      className="img "
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
    </svg>
  );

  // Subtract one day from the current date
  // const yearDate = todayDate.getFullYear();
  // const monthDate = todayDate.getMonth() + 1; // Months are zero-indexed, so we add 1
  // const dayDate = todayDate.getDate();

  // Format the date as a string in the format "YYYY-MM-DD"
  // const formattedDateToday = `${yearDate}-${
  //   monthDate < 10 ? "0" : ""
  // }${monthDate}-${dayDate < 10 ? "0" : ""}${dayDate}`;

  useEffect(() => {
    let val;
    if (filterVal?.name === "Specific Date") {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        search_term: search,
        specific: filterVal?.value,
      };
      val = payload;
    }
    if (filterVal?.name === "Custom Date") {
      const payload = {
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        start_date: filterVal?.value?.split("_")[0],
        stop_date: filterVal?.value?.split("_")[1],
        search_term: search,
      };
      val = payload;
    }
    if (
      filterVal?.label === "1 Week" ||
      filterVal?.label === "2 Weeks" ||
      filterVal?.label === "3  Weeks" ||
      filterVal?.label === "1 Month" ||
      filterVal?.label === "2 Months" ||
      filterVal?.label === "3 Months"
    ) {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        range: filterVal?.value,
        search_term: search,
      };
      val = payload;
    }
    if (filterVal?.label === "Today") {
      // console.log("jsytbs---------------");
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        search_term: search,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        // start_date: formattedDateToday,
        // stop_date: formattedDateToday,
      };
      val = obj;
    }
    getTerminalFunc(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, currentPage, selectOptionVal?.two, selectOptionVal?.three]);
  //   const getSingleTerminal = async () => {};
  useEffect(() => {
    // console.log(pos_dashboard);
    dispatch(getPosDashboardInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getTerminals());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pos_dashboard?.terminals?.length > 0) {
      const newList = pos_dashboard?.terminals?.map((chi) => {
        return {
          ...chi,
          label: `${chi?.tid} - ${chi?.tid_label}`,
          value: chi?.tid,
        };
      });
      const newObj = newList?.find((chi) => chi?.tid === terminal_id);
      // console.log(newObj);
      // const setUpVal = Object?.keys(newObj)?.length === 0 ? false : true;
      if (newObj) {
        // console.log(newObj, "new obj------");
        terminal_id &&
          setSelectOptionVal((prev) => {
            return { ...prev, three: newObj };
          });
      }
      setTerminalOptions(newList);
      // console.log(newList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pos_dashboard]);
  const getTerminalFunc = async (obj) => {
    // console.log(obj);
    const newObj = { ...obj, id: selectOptionVal?.three?.value || terminal_id };
    // return
    if (selectOptionVal?.three) {
      const data = await dispatch(getTerminalinformationSingle(newObj));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        setLoadingPage(false);
      }
    } else {
      const data = await dispatch(getTerminalinformations(obj));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        setLoadingPage(false);
      }
    }
  };

  // // check if  terminal id is in local ------
  // useEffect(() => {
  //   if (terminal_id) {
  //     // console.log(terminal_id, "----------------------------------------");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const today = new Date();

  // Subtract one day from the current date
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const year = yesterday.getFullYear();
  const month = yesterday.getMonth() + 1; // Months are zero-indexed, so we add 1
  const day = yesterday.getDate();

  // Format the date as a string in the format "YYYY-MM-DD"
  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
  const selectOption = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: `${formattedDate}` },
    { label: "7 days", value: "1_week" },
    { label: "14 days", value: "2_weeks" },
    // { label: "3 Weeks", value: "3_weeks" },
    { label: "1 Month", value: "1_month" },
    { label: "3 Months", value: "3_months" },
    { label: "Specific Date", value: "specific_date" },
    { label: "Custom", value: "custom" },
  ];
  //   const [perPage, setPerPage] = useState(10);
  const selectOptionTwo = [
    { label: "All Type", value: "" },
    { label: "Money Sent", value: "transfer_out" },
    { label: "Money Received", value: "transfer_in" },
    { label: "Card", value: "card" },
    { label: "Airtime", value: "airtime" },
    { label: "Data", value: "data" },
    { label: "Electricity", value: "electricity" },
  ];
  // eslint-disable-next-line no-unused-vars
  const [checkCustom, setCheckCustom] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (search && isMount && debouncedSearchTerm?.length > 1) {
      let val;
      if (filterVal?.name === "Specific Date") {
        const payload = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          search_term: search,
          specific: filterVal?.value,
        };
        val = payload;
      }
      if (filterVal?.label === "Yesterday") {
        const payload = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          search_term: search,
          start_date: filterVal?.value,
          stop_date: filterVal?.value,
        };
        val = payload;
      }
      if (filterVal?.name === "Custom Date") {
        const payload = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          start_date: filterVal?.value?.split("_")[0],
          stop_date: filterVal?.value?.split("_")[1],
          search_term: search,
        };
        val = payload;
      }
      if (
        filterVal?.label === "7 days" ||
        filterVal?.label === "14 days" ||
        filterVal?.label === "3  Weeks" ||
        filterVal?.label === "1 Month" ||
        filterVal?.label === "2 Months" ||
        filterVal?.label === "3 Months"
      ) {
        const payload = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          range: filterVal?.value,
          search_term: search,
        };
        val = payload;
      }
      if (filterVal?.label === "Today") {
        const obj = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          search_term: search,
        };
        val = obj;
      }
      //   console.log(val);
      //   return
      getTerminalFunc(val);
    }

    if (search && isMount && debouncedSearchTerm?.length < 1) {
      let val;
      if (filterVal?.name === "Specific Date") {
        const payload = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          specific: filterVal?.value,
          search_term: "",
        };
        val = payload;
      }
      if (filterVal?.label === "Yesterday") {
        const payload = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          search_term: "",
          start_date: filterVal?.value,
          stop_date: filterVal?.value,
        };
        val = payload;
      }
      if (filterVal?.name === "Custom Date") {
        const payload = {
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          start_date: filterVal?.value?.split("_")[0],
          stop_date: filterVal?.value?.split("_")[1],
          search_term: "",
          per_page: actionCtx?.perPageVal?.value,
        };
        val = payload;
      }
      if (
        filterVal?.label === "7 days" ||
        filterVal?.label === "14 days" ||
        filterVal?.label === "3  Weeks" ||
        filterVal?.label === "1 Month" ||
        filterVal?.label === "2 Months" ||
        filterVal?.label === "3 Months"
      ) {
        const payload = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          transaction_type: selectOptionVal?.two?.value,
          range: filterVal?.value,
          search_term: "",
        };
        val = payload;
      }

      if (filterVal?.label === "Today") {
        const obj = {
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          search_term: search,
        };
        val = obj;
      }

      getTerminalFunc(val);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);
  //   const [showFilterOption, setShowFilterOption] = useState(false);

  const [filterVal, setFilterVal] = useState({
    label: "Today",
    value: "today",
  });

  useEffect(() => {
    if (
      filterVal?.label === "7 days" ||
      filterVal?.label === "14 days" ||
      filterVal?.label === "3  Weeks" ||
      filterVal?.label === "1 Month" ||
      filterVal?.label === "2 Months" ||
      filterVal?.label === "3 Months"
    ) {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        range: filterVal?.value,
        search_term: search,
      };
      //   console.log(obj);
      //   return
      getTerminalFunc(obj);
    }
    if (filterVal?.name === "Specific Date") {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        specific: filterVal?.value,
        search_term: search,
      };
      //   console.log(obj);
      //   return
      getTerminalFunc(obj);
    }
    if (filterVal?.label === "Yesterday") {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        search_term: search,
        start_date: filterVal?.value,
        stop_date: filterVal?.value,
      };
      // val = payload;
      getTerminalFunc(payload);
    }
    if (filterVal?.name === "Custom Date") {
      const obj = {
        start_date: filterVal?.value?.split("_")[0],
        stop_date: filterVal?.value?.split("_")[1],
        type: selectOptionVal?.two?.value,
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
      };
      getTerminalFunc(obj);
    }
    if (filterVal?.value === "today") {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        search_term: search,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        // start_date: formattedDateToday,
        // stop_date: formattedDateToday,
      };
      getTerminalFunc(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  // useEffect(() => {
  //   if (filterVal?.value === "today") {
  //     const obj = {
  //       per_page: actionCtx?.perPageVal?.value,
  //       search_term: search,
  //       current_page: currentPage,
  //       type: selectOptionVal?.two?.value,
  //     };
  //     getTerminalFunc(obj);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterVal]);

  // useEffect(() => {
  //   if (filterVal?.name === "Specific Date") {
  //     const obj = {
  //       per_page: actionCtx?.perPageVal?.value,
  //       current_page: currentPage,
  //       type: selectOptionVal?.two?.value,
  //       specific: filterVal?.value,
  //       search_term: search,
  //     };
  //     //   console.log(obj);
  //     //   return
  //     getTerminalFunc(obj);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterVal]);

  // useEffect(() => {
  //   if (filterVal?.label === "Yesterday") {
  //     const payload = {
  //       per_page: actionCtx?.perPageVal?.value,
  //       current_page: currentPage,
  //       type: selectOptionVal?.two?.value,
  //       search_term: search,
  //       start_date: filterVal?.value,
  //       stop_date: filterVal?.value,
  //     };
  //     // val = payload;
  //     getTerminalFunc(payload);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterVal]);

  // useEffect(() => {
  //   if (filterVal?.name === "Custom Date") {
  //     const obj = {
  //       start_date: filterVal?.value?.split("_")[0],
  //       stop_date: filterVal?.value?.split("_")[1],
  //       type: selectOptionVal?.two?.value,
  //       per_page: actionCtx?.perPageVal?.value,
  //       current_page: currentPage,
  //     };
  //     getTerminalFunc(obj);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterVal]);

  const formatFilterDate = (obj) => {
    const dateOne = formatDate(obj?.split("_")[0])?.split("—")[0];
    const dateTwo = formatDate(obj?.split("_")[1])?.split("—")[0];
    const val = `${dateOne} to ${dateTwo}`;
    // console.log(val);
    return val;
  };

  const testingPosRef = useOnClickOutside(() => {
    setShowFilterOption(false);
  });

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={
            selectOptionVal.three
              ? `Single POS Transactions`
              : `All POS Transactions`
          }
          text={`All the information about your terminal displayed.`}
          back
          onBack={() => {
            navigate(-1);
          }}
        />
        {/* title box end */}
        {/* top detail card box start */}
        <div
          onClick={() => {
            //   actionCtx?.setCheckShow("close");
          }}
          className="top-pos-single-detail-card-box-new"
        >
          {/* box start */}
          <div className="box card-bg">
            <div className="title grey-white-color">
              Total Transaction Volume{" "}
            </div>
            {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
            <div className="value-box grey-bg">
              {loading ? (
                <Skeleton width={`20%`} height={20} />
              ) : (
                <p className="value black-white-color">
                  {formatNumWithCommaNairaSymbol(
                    sumAllNum([terminalInfo?.checkIn, terminalInfo?.checkOut])
                  )}
                </p>
              )}
            </div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box card-bg">
            <div className="title grey-white-color">
              {`Income  ${filterVal?.label}`}{" "}
              {/* <div className="title-total black-fade-white-bg">
                    <span>
                      {invoices?.total_paid_invoice || "0"}
                    </span>
                  </div> */}
            </div>
            {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
            <div className="value-box grey-bg">
              {loading ? (
                <Skeleton width={`20%`} height={20} />
              ) : (
                <p style={{ color: "#1ACE37" }} className="value">
                  {formatNumWithCommaNairaSymbol(
                    terminalInfo?.checkIn || "0.00"
                  )}
                </p>
              )}
            </div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box card-bg">
            <div className="title grey-white-color">
              {` Expense ${filterVal?.label}`}{" "}
              {/* <div className="title-total black-fade-white-bg">
                    <span>
                      {invoices?.total_paid_invoice || "0"}
                    </span>
                  </div> */}
            </div>
            {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
            <div className="value-box grey-bg">
              {loading ? (
                <Skeleton width={`20%`} height={20} />
              ) : (
                <p style={{ color: "#FF0F00" }} className="value">
                  {formatNumWithCommaNairaSymbol(
                    terminalInfo?.checkOut || "0.00"
                  )}
                </p>
              )}
            </div>
          </div>
          {/* box end */}
        </div>
        {/* top detail card box send */}

        <ContainerWrapper
          pagination={
            !loading &&
            !loadingPage &&
            terminalInfo?.transactions?.data?.length > 0 && (
              <RavenPagination
                color={`black-light`}
                nextPage={
                  selectOptionVal?.three
                    ? currentPage !== terminalInfo?.transactions?.last_page
                    : currentPage !==
                      terminalInfoSingle?.transactions?.last_page
                }
                prevPage={currentPage !== 1}
                blackHover
                onNext={(e) => {
                  setCurrentPage(currentPage + 1);
                  // console.log(currentPage + 1);
                }}
                onPrev={(e) => {
                  setCurrentPage(currentPage - 1);
                  // console.log(currentPage - 1);
                }}
                currentPage={currentPage}
                totalPage={
                  selectOptionVal?.three
                    ? getTotalPage(
                        Number(terminalInfoSingle?.transactions?.per_page),
                        Number(terminalInfoSingle?.transactions?.total)
                      )
                    : getTotalPage(
                        Number(terminalInfo?.transactions?.per_page),
                        Number(terminalInfo?.transactions?.total)
                      ) || 1
                }
                onNumView={(e) => {
                  setCurrentPage(e);
                }}
              />
            )
          }
          dontShowHeader
          width={`100%`}
        >
          {/* pos inde wrap start */}
          <div
            onClick={() => {
              // console.log(terminalInfo);
              // console.log(status);
            }}
            className="dashboard-pos-index-wrap"
          >
            {!loadingPage ? (
              <div className="terminal-info-table-wrap-box">
                <div className="new-search-filter-chart-box">
                  <div className="search-filter-box">
                    {/* per page start */}
                    {/* <PerPageComponent /> */}
                    {/* per page end */}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      autoComplete="off"
                      action=""
                    >
                      <div className="search-group">
                        <RavenInputField
                          type={`search`}
                          color={`black-light`}
                          placeholder={`Search`}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            showFilterOption && setShowFilterOption(false);
                          }}
                        />
                      </div>
                    </form>
                    {/* badge filter options start */}
                    {selectOptionVal?.one.label ||
                    selectOptionVal?.two.label ||
                    selectOptionVal?.three.label ? (
                      <div className="badge-filter-option-box">
                        {/* box start */}
                        {selectOptionVal?.one.label && (
                          <div className="option-box grey-bg">
                            <span>{selectOptionVal?.one?.label || "--"}</span>{" "}
                            {selectOptionVal?.one?.label !== "Today" && (
                              <FaTimes
                                onClick={() => {
                                  setCurrentPage(1);
                                  setSelectOptionVal((prev) => {
                                    return {
                                      ...prev,
                                      one: {
                                        label: "Today",
                                        value: "today",
                                      },
                                    };
                                  });
                                  setFilterVal({
                                    label: "Today",
                                    value: "today",
                                  });
                                }}
                                className="icon grey-white-color-white"
                              />
                            )}{" "}
                          </div>
                        )}
                        {selectOptionVal?.two.label && (
                          <div className="option-box grey-bg">
                            <span>{selectOptionVal?.two?.label || "--"}</span>{" "}
                            <FaTimes
                              onClick={() => {
                                setCurrentPage(1);
                                setSelectOptionVal((prev) => {
                                  return {
                                    ...prev,
                                    two: "",
                                  };
                                });
                              }}
                              className="icon grey-white-color-white"
                            />{" "}
                          </div>
                        )}
                        {selectOptionVal?.three.label && (
                          <div className="option-box grey-bg">
                            <span>{selectOptionVal?.three?.label || "--"}</span>{" "}
                            <FaTimes
                              onClick={() => {
                                localStorage.removeItem("pos-terminal-id");
                                setCurrentPage(1);
                                setSelectOptionVal((prev) => {
                                  return {
                                    ...prev,
                                    three: "",
                                  };
                                });
                              }}
                              className="icon grey-white-color-white"
                            />{" "}
                          </div>
                        )}
                        {/* box end */}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* badge filter options end */}
                    {/* export table content start */}
                    <div
                      // ref={download}
                      className="export-table-content-box tooltip-hover-wrap grey-bg"
                      onClick={() => {
                        setShowModal((prev) => {
                          return { ...prev, download: true };
                        });
                      }}
                    >
                      {" "}
                      <RavenToolTip
                        color="black-light"
                        text="Export Table Content"
                        position={`right`}
                      />
                      <figure className="img-box">{exportTableIcon}</figure>
                    </div>
                    {/* export table content endd */}
                    {/* filter-drop box start */}
                    <div ref={testingPosRef} className="filter-drop-box">
                      {/* drop box start */}
                      {showFilterOption && (
                        <div className="drop-box-wrap  card-bg shadow-shadow-box-shadow">
                          <form autoComplete="off" action="">
                            <RavenInputField
                              selectOption={selectOption}
                              label={`Filter By Transaction date`}
                              color={`black-light`}
                              type={"select"}
                              placeholder={`Transaction date`}
                              selectStyles={reactSelectStyle}
                              value={
                                filterVal || { label: "Today", value: "Today" }
                              }
                              onChange={(e) => {
                                setSelectOptionVal((prev) => {
                                  return { ...prev, one: e };
                                });
                                setCurrentPage(1);
                                if (
                                  e.label !== "Specific Date" &&
                                  e.label !== "Custom"
                                ) {
                                  setFilterVal(e);
                                }
                                if (e.label === "Specific Date") {
                                  setShowModal((prev) => {
                                    return {
                                      ...prev,
                                      selectSpecificDate: true,
                                    };
                                  });
                                }
                                if (e.label === "Custom") {
                                  setShowModal((prev) => {
                                    return { ...prev, customDate: true };
                                  });
                                }
                                setShowFilterOption(false);
                              }}
                              selectValue={selectOptionVal?.one}
                            />
                          </form>
                          <form autoComplete="off" action="">
                            <RavenInputField
                              selectOption={selectOptionTwo}
                              color={`black-light`}
                              type={"select"}
                              label={`Filter By Transaction type`}
                              placeholder={`Transaction type`}
                              selectStyles={reactSelectStyle}
                              value={selectOptionVal?.two}
                              onChange={(e) => {
                                setCurrentPage(1);
                                setSelectOptionVal((prev) => {
                                  return { ...prev, two: e };
                                });
                                showFilterOption && setShowFilterOption(false);
                                setCheckCustom(false);
                              }}
                              selectValue={typeFilter}
                            />
                          </form>
                          <form autoComplete="off" action="">
                            <RavenInputField
                              selectOption={terminalOptions}
                              color={`black-light`}
                              type={"select"}
                              label={`Filter By Source`}
                              placeholder={`Terminal Source`}
                              selectStyles={reactSelectStyle}
                              value={selectOptionVal?.three}
                              onChange={(e) => {
                                setCurrentPage(1);
                                localStorage.setItem(
                                  "pos-terminal-id",
                                  e.value
                                );
                                setSelectOptionVal((prev) => {
                                  return { ...prev, three: e };
                                });
                                showFilterOption && setShowFilterOption(false);
                                setCheckCustom(false);
                              }}
                              selectValue={typeFilter}
                            />
                          </form>
                        </div>
                      )}
                      {/* drop box end */}
                      {/* filter box start */}
                      <div
                        className="filter-box grey-bg"
                        onClick={() => {
                          setShowFilterOption(!showFilterOption);
                        }}
                      >
                        <p className="text grey-white-color-white">Filter</p>
                        <div className="line-box">
                          <span className=""></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                      {/* filter box end */}
                      {/* drop box start */}
                      <div className="drop-filter-wrap"></div>
                      {/* drop box end */}
                    </div>
                    {/* filter-drop box end */}
                  </div>
                  <div
                    onClick={() => {
                      setShowFilterOption(false);
                    }}
                    className="chart-hide-show-box"
                  ></div>
                </div>
                <div
                  onClick={() => {
                    showFilterOption && setShowFilterOption(false);
                  }}
                  className="table-body-info-wrap"
                >
                  {/* pos inde wrap end */}
                  {selectOptionVal?.three ? (
                    <>
                      {terminalInfoSingle?.transactions?.data?.length > 0 ? (
                        <>
                          <RavenTable
                            className={
                              formatMetaData(user?.theme_display_settings)
                                ?.table_font === "bold" && "table-business"
                            }
                            action
                            headerList={headerList}
                          >
                            {terminalInfoSingle?.transactions?.data?.map(
                              (chi, idx) => {
                                const {
                                  amount,
                                  created_at,
                                  direction,
                                  t_id,
                                  status,
                                  narration,
                                  type,
                                  meta_data,
                                  tid_label,
                                } = chi;
                                return (
                                  <RavenTableRow
                                    loading={loading || loadingPage}
                                    key={idx}
                                    one={
                                      rowTypeText(
                                        direction,
                                        type === "card"
                                          ? "Card Payment"
                                          : chi?.channel === "pdon"
                                          ? `BankBox Payment`
                                          : type === "transfer_out"
                                          ? formatMetaData(
                                              meta_data
                                            )?.account_name?.replace(" - ", " ")
                                          : type === "transfer_out - RVSL"
                                          ? "Transfer Out Reversal"
                                          : type === "transfer_out fee - RVSL"
                                          ? "Transfer Out Reversal Fee"
                                          : type === "transfer_in"
                                          ? formatMetaData(meta_data)?.source
                                              ?.first_name ||
                                            formatMetaData(meta_data)?.source
                                              ?.last_name
                                          : type === "bet"
                                          ? `Bet Recharge`
                                          : type === "electricity"
                                          ? `Electricity Recharge`
                                          : type === "pos_cautionfee_rvsl"
                                          ? "Caution Fee reversal"
                                          : // : formatTypeFunction(type) ===
                                          //   "Pdon card raven"
                                          // ? `BankBox Payment`
                                          narration?.top_narration ||
                                            type === "pdon_card_raven"
                                          ? `Bankbox Payment`
                                          : formatTypeFunction(type) || "---",
                                        type === "card"
                                          ? formatNUmPan(
                                              formatMetaData(meta_data)
                                                ?.card_pan
                                            )
                                          : type === "transfer_out"
                                          ? `${
                                              formatMetaData(meta_data)
                                                ?.account_number
                                            } • ${
                                              formatMetaData(meta_data)?.bank
                                            }`
                                          : type === "transfer_out - RVSL"
                                          ? "system reversed"
                                          : type === "pos_cautionfee_rvsl"
                                          ? "system reversed"
                                          : type === "bet"
                                          ? `${
                                              formatMetaData(meta_data)?.type
                                            } • ${trimLongString(
                                              formatMetaData(meta_data)?.name,
                                              16
                                            )}`
                                          : type === "electricity"
                                          ? `${
                                              formatMetaData(meta_data)?.meta
                                                ?.service ||
                                              formatMetaData(meta_data)
                                                ?.provider ||
                                              "---"
                                            } • ${
                                              formatMetaData(meta_data)?.meta
                                                ?.meter_number ||
                                              formatMetaData(meta_data)
                                                ?.meter_no ||
                                              "---"
                                            }`
                                          : // : type === "pdon_card_raven"
                                          // ? formatNUmPan(
                                          //     formatMetaData(meta_data)
                                          //       ?.card_pan
                                          //   )
                                          type === "transfer_in"
                                          ? `${
                                              formatMetaData(meta_data)?.source
                                                ?.account_number
                                            } • ${trimLongString(
                                              formatMetaData(meta_data)?.source
                                                ?.bank,
                                              16
                                            )}`
                                          : type === "transfer_out fee - RVSL"
                                          ? "system reversed"
                                          : narration?.bottom_narration ||
                                            "----",
                                        chi
                                      ) || "----"
                                    }
                                    four={tid_label || "---"}
                                    five={formatDate(created_at) || "----"}
                                    three={t_id || "---"}
                                    two={
                                      formatNumWithCommaNairaSymbol(
                                        amount || "0.00"
                                      ) || "----"
                                    }
                                    onRowClick={() => {
                                      showFilterOption
                                        ? setShowFilterOption(false)
                                        : setShowModal((prev) => {
                                            return { ...prev, view: true };
                                          });
                                      setSingleObj(chi);
                                    }}
                                    six={formatTypeWrap(status, chi)}
                                    action
                                    dontShowAction
                                    dontShowEdit
                                    ManualAddActions={() =>
                                      transactionActions(chi)
                                    }
                                  />
                                );
                              }
                            )}
                          </RavenTable>
                          <div className="mobile-table-box">
                            {terminalInfo?.transactions?.data?.map(
                              (chi, idx) => {
                                const { amount, created_at, direction, type } =
                                  chi;
                                return (
                                  <MobileTableCard
                                    key={idx}
                                    amount={formatNumWithCommaNairaSymbol(
                                      amount
                                    )}
                                    text={`${
                                      formatDate(created_at).split("—")[0] || ""
                                    } • ${type}`}
                                    onRowClick={() => {
                                      // setSingleChild(chi);
                                      setShowModal((prev) => {
                                        return { ...prev, view: true };
                                      });
                                      // console.log(chi);
                                    }}
                                    loading={loading}
                                    title={type}
                                    showImg
                                    type={direction}
                                  />
                                );
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        <NoContentBox
                          title={
                            sorceFilter
                              ? `No Transaction Found for terminal ${sorceFilter?.label}`
                              : `No Transaction Found`
                          }
                          loading={
                            loading || terminalInfo?.transactions?.length < 1
                          }
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {terminalInfo?.transactions?.data?.length > 0 ? (
                        <>
                          <RavenTable
                            className={
                              formatMetaData(user?.theme_display_settings)
                                ?.table_font === "bold" && "table-business"
                            }
                            action
                            headerList={headerList}
                          >
                            {terminalInfo?.transactions?.data?.map(
                              (chi, idx) => {
                                const {
                                  amount,
                                  created_at,
                                  direction,
                                  t_id,
                                  status,
                                  narration,
                                  type,
                                  meta_data,
                                  tid_label,
                                } = chi;
                                return (
                                  <RavenTableRow
                                    loading={loading || loadingPage}
                                    key={idx}
                                    one={
                                      rowTypeText(
                                        direction,
                                        type === "card"
                                          ? "Card Payment"
                                          : chi?.type === "pdon_card_raven" ||
                                            chi?.type === "pdon_card"
                                          ? `BankBox Payment`
                                          : type === "transfer_out"
                                          ? formatMetaData(
                                              meta_data
                                            )?.account_name?.replace(" - ", " ")
                                          : type === "transfer_out - RVSL"
                                          ? "Transfer Out Reversal"
                                          : type === "transfer_out fee - RVSL"
                                          ? "Transfer Out Reversal Fee"
                                          : type === "transfer_in"
                                          ? formatMetaData(meta_data)?.source
                                              ?.first_name ||
                                            formatMetaData(meta_data)?.source
                                              ?.last_name
                                          : type === "bet"
                                          ? `Bet Recharge`
                                          : type === "electricity"
                                          ? `Electricity Recharge`
                                          : type === "pos_cautionfee_rvsl"
                                          ? "Caution Fee reversal"
                                          : narration?.top_narration ||
                                            lowerCaseWrap(
                                              formatTypeFunction(type)
                                            ) ||
                                            "----",
                                        type === "card"
                                          ? formatNUmPan(
                                              formatMetaData(meta_data)
                                                ?.card_pan
                                            )
                                          : type === "pdon_card_raven" ||
                                            chi?.type === "pdon_card"
                                          ? formatNUmPan(
                                              formatMetaData(meta_data)
                                                ?.card_pan
                                            )
                                          : type === "transfer_out"
                                          ? `${
                                              formatMetaData(meta_data)
                                                ?.account_number
                                            } • ${
                                              formatMetaData(meta_data)?.bank
                                            }`
                                          : type === "transfer_out - RVSL"
                                          ? "system reversed"
                                          : type === "pos_cautionfee_rvsl"
                                          ? "system reversed"
                                          : type === "bet"
                                          ? `${
                                              formatMetaData(meta_data)?.type
                                            } • ${trimLongString(
                                              formatMetaData(meta_data)?.name,
                                              16
                                            )}`
                                          : type === "electricity"
                                          ? `${
                                              formatMetaData(meta_data)?.meta
                                                ?.service ||
                                              formatMetaData(meta_data)
                                                ?.provider ||
                                              "---"
                                            } • ${
                                              formatMetaData(meta_data)?.meta
                                                ?.meter_number ||
                                              formatMetaData(meta_data)
                                                ?.meter_no ||
                                              "---"
                                            }`
                                          : type === "transfer_in"
                                          ? `${
                                              formatMetaData(meta_data)?.source
                                                ?.account_number
                                            } • ${trimLongString(
                                              formatMetaData(meta_data)?.source
                                                ?.bank,
                                              16
                                            )}`
                                          : type === "airtime"
                                          ? formatMetaData(meta_data)
                                              ?.phone_number
                                          : type === "transfer_out fee - RVSL"
                                          ? "system reversed"
                                          : narration?.bottom_narration ||
                                            "----",
                                        chi
                                      ) || "----"
                                    }
                                    four={tid_label || "---"}
                                    five={formatDate(created_at) || "----"}
                                    three={t_id || "---"}
                                    two={
                                      formatNumWithCommaNairaSymbol(
                                        amount || "0.00"
                                      ) || "----"
                                    }
                                    onRowClick={() => {
                                      showFilterOption
                                        ? setShowFilterOption(false)
                                        : setShowModal((prev) => {
                                            return { ...prev, view: true };
                                          });
                                      setSingleObj(chi);
                                    }}
                                    six={formatTypeWrap(status, chi)}
                                    action
                                    dontShowAction
                                    dontShowEdit
                                    ManualAddActions={() =>
                                      transactionActions(chi)
                                    }
                                  />
                                );
                              }
                            )}
                          </RavenTable>
                          <div className="mobile-table-box">
                            {terminalInfo?.transactions?.data?.map(
                              (chi, idx) => {
                                const { amount, created_at, direction, type } =
                                  chi;
                                return (
                                  <MobileTableCard
                                    key={idx}
                                    amount={formatNumWithCommaNairaSymbol(
                                      amount
                                    )}
                                    text={`${
                                      formatDate(created_at).split("—")[0] || ""
                                    } • ${type}`}
                                    onRowClick={() => {
                                      // setSingleChild(chi);
                                      setShowModal((prev) => {
                                        return { ...prev, view: true };
                                      });
                                      // console.log(chi);
                                    }}
                                    loading={loading}
                                    title={type}
                                    showImg
                                    type={direction}
                                  />
                                );
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        <NoContentBox
                          title={
                            sorceFilter
                              ? `No Transaction Found for terminal ${sorceFilter?.label}`
                              : `No Transaction Found`
                          }
                          loading={
                            loading || terminalInfo?.transactions?.length < 1
                          }
                        />
                      )}
                    </>
                  )}

                  {/* table end */}
                </div>
              </div>
            ) : (
              <NoContentBox
                title={
                  sorceFilter
                    ? `No Transaction Found for terminal `
                    : `No Transaction Found`
                }
                loading={loadingPage}
              />
            )}
          </div>
          {/* containr start */}
        </ContainerWrapper>
        {/* container end */}
      </DashboardLayout>
      {/* specific date start  */}
      <SpecificDateSelectModal
        visible={showModal?.selectSpecificDate}
        onFinish={(e) => {
          // console.log(e);
          setFilterVal({
            label: formatDate(e)?.split("—")[0],
            value: e,
            name: "Specific Date",
          });
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
      />
      {/* specific date end */}
      {/* Custom date start */}
      <CustomDateSelectModal
        visible={showModal?.customDate}
        onFinish={(e) => {
          //   formatFilterDate(e);
          setFilterVal({
            label: formatFilterDate(e),
            value: e,
            name: "Custom Date",
          });
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
      />
      {/* custom date end */}
      <DownloadReceiptModal
        visible={showModal?.view}
        onClose={() =>
          setShowModal((prev) => {
            return { ...prev, view: false };
          })
        }
        detail={singleObj}
      />
      {/* download csv start */}
      <GenerateStatementModalPos
        terminalOptions={terminalOptions}
        selectOptionVal={selectOptionVal}
        // setSelectOptionVal={setSelectOptionVal}
        visible={showModal?.download}
        onCancel={() =>
          setShowModal((prev) => {
            return { ...prev, download: false };
          })
        }
        detail={singleObj}
      />
      {/* download csv end */}
    </>
  );
};

export default NewTerminalInfomation;
