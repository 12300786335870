/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitletextBox from "../../../components/reusables/TitletextBox";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import "../../../styles/dashboard/global-account/GlobalAccountIndex.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RavenCheckBox } from "@ravenpay/raven-bank-ui";
import { convertToLowercase, sumAllNum } from "../../../utils/helper/Helper";
import { useDispatch } from "react-redux";
import { getTransactionLimits } from "../../../redux/transaction";
import {
  createGlobalAccount,
  getAllCurrencies,
  getAllGlobalAccount,
  getAllGlobalVerificationStatus,
} from "../../../redux/globalAccount";
import Skeleton from "react-loading-skeleton";
import TermsAndConditionModal from "./TermsAndConditionModal";
// import ActionContext from "../../../context/ActionContext";

const GlobalAccountCreateIndex = () => {
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const { all_currencies, loading, global_verification, all_global_account } =
    useSelector((state) => state.global_account);
  const [boxSelectView, setBoxSelectView] = useState("");
  const navigate = useNavigate();
  const [allCurrencyToUse, setAllCurrencyToUse] = useState([]);
  const [selectedCurList, setSelectedCurList] = useState([]);
  const { business } = useSelector((state) => state.user);
  const { directors } = useSelector((state) => state.settings);
  const [completeVeri, setCompleteVeri] = useState(false);
  const verifyIcon = (
    <svg
      className="img"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3367 3.45966C14.6432 3.14224 14.605 2.61805 14.1905 2.46681C13.905 2.36265 13.5968 2.30582 13.2753 2.30582H4.37526C2.90066 2.30582 1.70526 3.50122 1.70526 4.97583V13.8759C1.70526 15.3505 2.90066 16.5459 4.37526 16.5459H13.2753C14.7499 16.5459 15.9453 15.3505 15.9453 13.8759L15.9453 6.82359C15.9453 6.15724 15.1348 5.82893 14.671 6.30741L9.87958 11.2509C9.67094 11.4595 9.38797 11.5767 9.09292 11.5767C8.79787 11.5767 8.5149 11.4595 8.30626 11.2509L6.52626 9.47088C6.0918 9.03643 6.0918 8.33203 6.52626 7.89757C6.96072 7.46311 7.66512 7.46311 8.09958 7.89757L9.09292 8.89092L14.3367 3.45966Z"
        fill="#646464"
      />
    </svg>
  );
  const [showTerms, setShowTerms] = useState(false);
  const dispatch = useDispatch();
  const [agreeBox, setAgreeBox] = useState(false);
  //   const actionCtx = useContext(ActionContext);
  //   const [showAmount, setShowAmount] = useState(true);
  const rightArrowIcon = (
    <svg
      className="img"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M9 18.5L15 12.5L9 6.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        // className="dull-bright-filter"
      />
    </svg>
  );

  const currencyIcon = (
    <svg
      className="img"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filte"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7233 9.20333C15.7233 13.1356 12.5356 16.3233 8.60334 16.3233C4.67107 16.3233 1.48334 13.1356 1.48334 9.20333C1.48334 5.27106 4.67107 2.08333 8.60334 2.08333C12.5356 2.08333 15.7233 5.27106 15.7233 9.20333ZM9.27084 5.05C9.27084 4.64039 8.93878 4.30833 8.52917 4.30833C8.11956 4.30833 7.7875 4.64039 7.7875 5.05V5.66805H7.63917C7.08397 5.66805 6.55432 5.89282 6.16596 6.28837C5.77805 6.68346 5.5625 7.21647 5.5625 7.76944C5.5625 8.32242 5.77805 8.85543 6.16596 9.25052C6.55432 9.64607 7.08397 9.87083 7.63917 9.87083H9.41917C9.5721 9.87083 9.72157 9.93258 9.83393 10.047C9.94675 10.1619 10.0125 10.3206 10.0125 10.4889C10.0125 10.6572 9.94675 10.8158 9.83393 10.9308C9.72157 11.0452 9.57209 11.1069 9.41917 11.1069H7.65882C7.5481 11.0995 7.44043 11.0598 7.34882 10.9907C7.25347 10.9188 7.17949 10.8179 7.13856 10.6996C7.00461 10.3125 6.58223 10.1073 6.19514 10.2412C5.80805 10.3752 5.60283 10.7976 5.73678 11.1847C5.87301 11.5783 6.12254 11.9237 6.45544 12.1748C6.78852 12.4261 7.18939 12.5712 7.60659 12.5896C7.61744 12.59 7.62831 12.5903 7.63917 12.5903H7.7875V13.2083C7.7875 13.6179 8.11956 13.95 8.52917 13.95C8.93878 13.95 9.27084 13.6179 9.27084 13.2083V12.5903H9.41917C9.97438 12.5903 10.504 12.3655 10.8924 11.97C11.2803 11.5749 11.4958 11.0419 11.4958 10.4889C11.4958 9.93591 11.2803 9.4029 10.8924 9.00781C10.504 8.61226 9.97437 8.3875 9.41917 8.3875H7.63917C7.48625 8.3875 7.33677 8.32575 7.22441 8.21131C7.11159 8.0964 7.04584 7.93771 7.04584 7.76944C7.04584 7.60118 7.11159 7.44248 7.22441 7.32758C7.33677 7.21313 7.48625 7.15139 7.63917 7.15139H9.39952C9.51024 7.15878 9.61791 7.19851 9.70952 7.26763C9.80487 7.33956 9.87886 7.44047 9.91978 7.55874C10.0537 7.94583 10.4761 8.15104 10.8632 8.01709C11.2503 7.88315 11.4555 7.46076 11.3216 7.07367C11.1853 6.67999 10.9358 6.33466 10.6029 6.0835C10.2698 5.83221 9.86895 5.68712 9.45176 5.66877C9.4409 5.66829 9.43004 5.66805 9.41917 5.66805H9.27084V5.05Z"
        fill="#646464"
      />
    </svg>
  );

  const termIcon = (
    <svg
      className="img"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.56 4.16C3.56 3.17693 4.35693 2.38 5.34 2.38H9.42135C9.89343 2.38 10.3462 2.56753 10.68 2.90135L13.7186 5.94C14.0525 6.27381 14.24 6.72656 14.24 7.19865V14.84C14.24 15.8231 13.4431 16.62 12.46 16.62H5.34C4.35693 16.62 3.56 15.8231 3.56 14.84V4.16ZM5.34 9.5C5.34 9.00846 5.73846 8.61 6.23 8.61H11.57C12.0615 8.61 12.46 9.00846 12.46 9.5C12.46 9.99153 12.0615 10.39 11.57 10.39H6.23C5.73846 10.39 5.34 9.99153 5.34 9.5ZM6.23 12.17C5.73846 12.17 5.34 12.5685 5.34 13.06C5.34 13.5515 5.73846 13.95 6.23 13.95H11.57C12.0615 13.95 12.46 13.5515 12.46 13.06C12.46 12.5685 12.0615 12.17 11.57 12.17H6.23Z"
        fill="#646464"
      />
    </svg>
  );

  const verificationList = [
    {
      name: "BVN",
      link: "/dashboard-verification-bvn",
      status: business?.bvn === 3 ? "verified" : "pending",
    },
    {
      name: "NIN",
      link: "/dashboard-verification-nin",
      status: business?.nin === 3 ? "verified" : "pending",
    },
    {
      name: "Director Verification",
      link: "/dashboard-settings-directors",
      status: business?.directors === 3 ? "verified" : "pending",
    },
    {
      name: "Address",
      link: "/dashboard-verification-address",
      status: business?.address === 3 ? "verified" : "pending",
    },
    {
      name: "Business Information",
      link: "/dashboard-verification-business",
      status: business?.business_info === 3 ? "verified" : "pending",
    },
    {
      name: "CAC Verification",
      link: "/dashboard-verification-cac",
      status: business?.cac === 3 ? "verified" : "pending",
    },
  ];

  const checkDirectorBvnStatus = (list) => {
    if (list?.length > 0) {
      const val = list?.some((chi) => chi?.bvn_verified === 0);
      return !val;
    }
  };

  const allIcon = (
    <svg
      className="img"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 2.26667C12.0844 4.54863 13.2689 7.51003 13.3333 10.6C13.2689 13.69 12.0844 16.6514 9.99996 18.9333M9.99996 2.26667C7.91556 4.54863 6.731 7.51003 6.66663 10.6C6.731 13.69 7.91556 16.6514 9.99996 18.9333M9.99996 2.26667C5.39759 2.26667 1.66663 5.99763 1.66663 10.6C1.66663 15.2024 5.39759 18.9333 9.99996 18.9333M9.99996 2.26667C14.6023 2.26667 18.3333 5.99763 18.3333 10.6C18.3333 15.2024 14.6023 18.9333 9.99996 18.9333M2.08331 8.1H17.9166M2.08329 13.1H17.9166"
        stroke="#755AE2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    dispatch(getTransactionLimits());
    if (Object?.keys(global_verification)?.length < 1) {
      dispatch(getAllGlobalVerificationStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getAllCurrencies());
    dispatch(getAllGlobalAccount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (all_currencies?.all_support_currencies?.length > 0) {
      const filteredData = all_currencies?.all_support_currencies?.filter(
        (item) =>
          all_currencies?.currencies_available_for_setup.includes(item.currency)
      );
      setAllCurrencyToUse(filteredData);
    }
  }, [all_currencies]);

  const handleSelectedCurrency = (param) => {
    if (selectedCurList?.includes(param)) {
      const newList = selectedCurList?.filter((chi) => chi !== param);
      // console.log(newList);
      // console.log(param);
      setSelectedCurList(newList);
    } else {
      const newList = [...selectedCurList, param];
      setSelectedCurList(newList);
    }
  };

  const verifyDone = () => {
    let check;
    // console.log(business);
    if (
      // business?.cac === 3 &&
      business?.address === 3 &&
      business?.business_info === 3 &&
      business?.bvn === 3 &&
      business?.nin === 3
      // &&
      // checkDirectorBvnStatus(directors)
    ) {
      check = true;
    } else {
      check = false;
    }
    // console.log(check);
    return check;
  };

  const handleSubmit = async () => {
    const obj = {
      selected_currencies: selectedCurList,
    };
    const data = await dispatch(createGlobalAccount(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      navigate("/dashboard-global-account");
    }
  };

  const removeNgnFromList = (param) => {
    if (param?.length > 0) {
      const newList = param?.filter((chi) => chi?.currency !== "NGN");
      return newList;
    }
  };

  useEffect(() => {
    // console.log("lkjshd");
    if (
      all_global_account?.global_accounts_available_for_current_business
        ?.length > 0
    ) {
      // console.log("kusd");
      navigate("/dashboard-global-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_global_account]);

  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_setup_global_account"
      )){
      navigate("/dashboard-global-account")
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DashboardLayout>
        {/* title start */}
        <TitletextBox
          title={`Global Account`}
          text={`Accept and transacts in over ${
            all_currencies?.all_support_currencies?.length - 1 || ""
          } currencies`}
          //   addBtn={user_resource_and_permissions?.allowed_permissions?.includes(
          //     "can_create_customer"
          //   )}
          addBtn={completeVeri}
          toolTipMsg={`Create a new global account`}
          onAdd={() => {
            setCompleteVeri(false);
          }}
          back={true}
          onBack={() => {
            navigate(-1);
          }}
        ></TitletextBox>
        {/* titlte box end */}
        {/* container wrap start */}
        <ContainerWrapper
          btnText={!completeVeri && `Accept & Proceed`}
          width={`100%`}
          dontShowHeader={true}
          onBtnClick={handleSubmit}
          disabled={
            selectedCurList?.length < 1 ||
            !agreeBox 
            // ||
            // global_verification?.verification_status_summary ===
            //   "incomplete-verification"
          }
          loading={loading}
        >
          {/* global index wrap box start */}
          <div
            // onClick={() => {
            //   console.log(global_verification);
            // }}
            className="global-index-wrap-box"
          >
            <div className="content-create-box-wrap">
              <div className="mascot-img-box"></div>
              {/* content wrap start */}
              <div className="content-wrap-global-box card-bg border-theme">
                {/* description content box wrap start */}
                <div className="description-content-box border-theme-bottom">
                  <div
                    onClick={() => {
                      if (String(business?.bvn) !== "3") {
                        navigate("/dashboard-verification-bvn");
                        return;
                      }
                      if (String(business?.nin) !== "3") {
                        navigate("/dashboard-verification-nin");
                        return;
                      }
                      if (String(business?.address) !== "3") {
                        navigate("/dashboard-verification-address");
                        return;
                      }
                      if (String(business?.business_info) !== "3") {
                        navigate("/dashboard-verification-business");
                        return;
                      }
                      if (String(business?.cac) !== "3") {
                        navigate("/dashboard-verification-cac");
                        return;
                      }
                      if (!checkDirectorBvnStatus(directors)) {
                        navigate("/dashboard-settings-directors");
                        return;
                      }
                      // if (boxSelectView === "verification") {
                      //   setBoxSelectView("");
                      // } else {
                      //   setBoxSelectView("verification");
                      // }
                    }}
                    className="descriptio-box"
                  >
                    <div className="img-icon-box">
                      <figure className="img-box border-theme card-bg shadow-shadow-box-shadow">
                        {verifyIcon}
                      </figure>
                    </div>
                    <div className="title-status-box">
                      <p className="title ">Complete your verification</p>
                      <div
                        className={`status-box grey-bg-two ${
                          Object.keys(global_verification)?.length > 0 &&
                          global_verification?.verification_status_summary !==
                            "incomplete-verification" &&
                          "status-box-success"
                        }`}
                      >
                        <p className={`status`}>
                          <span className="">
                            {/* {!verifyDone() ? `Pending` : "Done"} */}
                            {Object.keys(global_verification)?.length > 0 &&
                            global_verification?.verification_status_summary !==
                              "incomplete-verification"
                              ? `Done`
                              : "Pending"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={`right-icon ${
                        boxSelectView === "verification" && "right-icon-down"
                      }`}
                    >
                      <figure className="img-box">{rightArrowIcon}</figure>
                    </div>
                  </div>

                  <div
                    className={`content-hide-box ${
                      boxSelectView === "verification" &&
                      "content-hide-box-show "
                    }`}
                  >
                    <div className="box content-hide-box-verification ">
                      {verificationList?.map((chi, idx) => {
                        const { link, name, status } = chi;
                        return (
                          <div
                            key={idx}
                            className={`box-value ${
                              status === "verified" && "box-value-green"
                            } grey-bg-two`}
                            onClick={() => {
                              if (link) {
                                navigate(link);
                              }
                            }}
                          >
                            <p className="value">{name}</p>
                          </div>
                        );
                      })}
                    </div>

                    <p className="down-text grey-white-color">
                      Tap on the yellow chip to complete pending verifications.
                    </p>
                  </div>
                </div>
                {/* description content box wrap end */}
                {/* description content box wrap start */}
                <div className="description-content-box border-theme-bottom">
                  <div
                    onClick={() => {
                      if (boxSelectView === "currency") {
                        setBoxSelectView("");
                      } else {
                        setBoxSelectView("currency");
                      }
                    }}
                    className="descriptio-box"
                  >
                    <div className="img-icon-box">
                      <figure className="img-box border-theme card-bg shadow-shadow-box-shadow">
                        {currencyIcon}
                      </figure>
                    </div>
                    <div className="title-status-box">
                      <p className="title ">Select Preferred Currencies</p>
                      <div
                        className={`status-box ${
                          selectedCurList?.length > 0 && "status-box-success"
                        } grey-bg-two`}
                      >
                        <p className={`status `}>
                          <span className="">
                            {selectedCurList?.length > 0 ? `Done` : `Pending`}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={`right-icon ${
                        boxSelectView === "currency" && "right-icon-down"
                      }`}
                    >
                      <figure className="img-box">{rightArrowIcon}</figure>
                    </div>
                  </div>

                  <div
                    className={`content-hide-box ${
                      boxSelectView === "currency" && "content-hide-box-show "
                    }`}
                  >
                    <div
                      // onClick={() => {
                      //   console.log(all_currencies);
                      // }}
                      className="box currency-to-select-box"
                    >
                      {Object.keys(all_currencies)?.length > 0 &&
                      all_currencies?.currencies_available_for_setup?.length >
                        1 ? (
                        <div
                          className={`cur-item border-theme grey-bg-two ${
                            selectedCurList?.length ===
                              allCurrencyToUse?.length && "cur-item-active"
                          }`}
                          onClick={() => {
                            const newList = removeNgnFromList(
                              allCurrencyToUse
                            )?.map((chi) => chi?.currency);
                            if (
                              selectedCurList?.length ===
                              allCurrencyToUse?.length
                            ) {
                              setSelectedCurList([]);
                            } else {
                              setSelectedCurList(newList);
                            }
                          }}
                        >
                          <div className="img-select">
                            <figure className="img-box">{allIcon}</figure>
                          </div>
                          <p className="cur">{`All`}</p>
                        </div>
                      ) : (
                        <Skeleton
                          width={65}
                          height={30}
                          style={{ borderRadius: "3rem" }}
                        />
                      )}
                      {removeNgnFromList(allCurrencyToUse)?.map((chi, idx) => {
                        const { currency } = chi;
                        return (
                          <div
                            key={idx}
                            className={`cur-item border-theme grey-bg-two ${
                              selectedCurList?.includes(currency) &&
                              "cur-item-active"
                            }`}
                            onClick={() => {
                              handleSelectedCurrency(currency);
                            }}
                          >
                            <div className="img-select">
                              <figure className="img-box">
                                <img
                                  src={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                                    currency
                                  )}.png`}
                                  className="img"
                                  alt=""
                                />
                              </figure>
                            </div>
                            <p className="cur">{currency}</p>
                          </div>
                        );
                      })}
                    </div>
                    <p className="down-text grey-white-color">
                      Select as many as you want.
                    </p>
                  </div>
                </div>
                {/* description content box wrap end */}
                {/* description content box wrap start */}
                <div className="description-content-box">
                  <div
                    onClick={() => {
                      // if (boxSelectView === "term") {
                      //   setBoxSelectView("");
                      // } else {
                      //   setBoxSelectView("term");
                      // }
                      setShowTerms(true);
                    }}
                    className="descriptio-box"
                  >
                    <div className="img-icon-box">
                      <figure className="img-box border-theme card-bg shadow-shadow-box-shadow">
                        {termIcon}
                      </figure>
                    </div>
                    <div className="title-status-box">
                      <p className="title ">Accept Terms & Conditions</p>
                      <div
                        className={`status-box grey-bg-two ${
                          agreeBox && "status-box-success"
                        }`}
                      >
                        <p className={`status`}>
                          <span className="">
                            {agreeBox ? `Done` : `Pending`}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={`right-icon  ${
                        boxSelectView === "term" && "right-icon-down"
                      }`}
                    >
                      <figure className="img-box">{rightArrowIcon}</figure>
                    </div>
                  </div>

                  <div
                    className={`content-hide-box  ${
                      boxSelectView === "term" && "content-hide-box-show "
                    }`}
                  >
                    <div className="box term-content-box-wrap">
                      <div className="text-term-box"></div>
                      {/* agree checkbox wrap start */}
                      {/* <div className="agree-checkbox-wrap">
                        <RavenCheckBox
                          checked={agreeBox}
                          onChange={() => {
                            setAgreeBox(!agreeBox);
                          }}
                          color="black-light"
                          key={`kjvbjskjos`}
                        />
                        <p className="text grey-white-color">
                          I hereby accept and agree to the{" "}
                          <span>
                            {" "}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "rgba(117, 90, 226, 1)",
                              }}
                              href="https://getravenbank.com/terms-and-conditions?tab=global%2520accounts"
                            >
                              terms and conditions
                            </a>
                          </span>
                        </p>
                      </div> */}
                      {/* agree checkbox wrap end */}
                    </div>
                  </div>
                </div>
                {/* description content box wrap end */}
              </div>
              {/* content wrap end */}
            </div>
          </div>
          {/* global index wrap box end */}
        </ContainerWrapper>
        {/* container wrap end */}
      </DashboardLayout>

      <TermsAndConditionModal
        agreeBox={agreeBox}
        setAgreeBox={setAgreeBox}
        visible={showTerms}
        onClose={() => {
          setShowTerms(!showTerms);
        }}
      />
    </>
  );
};

export default GlobalAccountCreateIndex;
