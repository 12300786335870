

import BeneficiaryGlobalAccount from "../../../pages/dashboard/global-account/BeneficiaryGlobalAccount";
import GlobalAccountCreateIndex from "../../../pages/dashboard/global-account/GlobalAccountCreateIndex";
import GlobalAccountIndex from "../../../pages/dashboard/global-account/GlobalAccountIndex";
import GlobalAccountSingleIndex from "../../../pages/dashboard/global-account/GlobalAccountSingleIndex";
import ReceiveGlobalAccount from "../../../pages/dashboard/global-account/ReceiveGlobalAccount";
import SendMoneyGlobalAccount from "../../../pages/dashboard/global-account/SendMoneyGlobalAccount";
import SwapGlobalAccountIndex from "../../../pages/dashboard/global-account/SwapGlobalAccount";


export const dashboard_global_account_route_group = [
    { path: "/dashboard-global-account", element: <GlobalAccountIndex /> },
    { path: "/dashboard-global-account-create", element: <GlobalAccountCreateIndex /> },
    { path: "/dashboard-global-account-single", element: <GlobalAccountSingleIndex /> },
    { path: "/dashboard-global-account-receive", element: <ReceiveGlobalAccount /> },
    { path: "/dashboard-global-account-send", element: <SendMoneyGlobalAccount /> },
    { path: "/dashboard-global-account-swap", element: <SwapGlobalAccountIndex /> },
    { path: "/dashboard-global-account-beneficiary", element: <BeneficiaryGlobalAccount/> },
  ];
  