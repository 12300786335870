import { RavenButton, RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ProgressBar from "../../../components/fragments/ProgreeBar";
import ActionContext from "../../../context/ActionContext";
// import CryptoJS from "crypto-js";
// import {
//   cancelTokenFunc,
//   createAnotherBusiness,
//   getAllBusinesses,
//   loginIntoAnotherBusiness,
// } from "../../../redux/user";
import { getStates } from "../../../redux/verifications";
import "../../../styles/dashboard/create-business/CreateBusinessWrapIndex.css";
import {
  filterEmptyColumns,
  //   formatTypeFunction,
  reactSelectStyle,
} from "../../../utils/helper/Helper";
// import { validateEmail } from "../../../utils/helper/ValidateInput";
import { useDarkMode } from "../../../utils/themes/useDarkMode";
// import { validateEmail } from "../../../utils/helper/ValidateInput";
import {
  createRecipientGlobalAccount,
  getAllBeneficiaries,
} from "../../../redux/globalAccount";
import NoContentBox from "../../../components/reusables/NoContentBox";

const NewCreateRecipientGlobalWrapIndex = ({
  detail,
  inputObjList,
  setOptionList,
  activeSelect,
  setActiveSelect,
  selectOptionList,
  handleFinishFunc,
}) => {
  const actionCtx = useContext(ActionContext);
  // eslint-disable-next-line no-unused-vars
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  // const { user, loadLogout } = useSelector((state) => state.user);
  const { loadingTrans, loadingInput } = useSelector(
    (state) => state.global_account
  );
  const closeIcon = (
    <svg
      className="img"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M4.5 4.5L13.5 13.5M13.5 4.5L4.5 13.5"
        stroke="#020202"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const [details, setDetails] = useState({
    currency: "",
    bank_name: "",
    account_number: "",
    beneficiary_name: "",
    email: "",
    state: "",
    city: "",
    postal_code: "",
    routing_number: "",
    swift_code: "",
    beneficiary_country: "",
    beneficiary_address: "",
    full_name: "",
    street_address: "",
  });
  // const [error, setError] = useState({
  //   email: false,
  //   password: false,
  //   password_msg: "",
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  // const [newBusiness, setNewBusiness] = useState({});

  useEffect(() => {
    dispatch(getStates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [reciepientVal, setRecipientVal] = useState(false);
  const handleSubmit = async () => {
    const val = transformArrayToObject(inputObjList);
    const objTwo = {
      ...val,
      currency: detail?.sendCurrency?.currency,
      beneficiary_account_type:
        activeSelect === "Individual" ? "individual" : "business",
      beneficiary_country: detail?.country.label || detail?.country || "",
      delivery_method: detail?.delivery_method,
      sender_country: val?.beneficiary_country || "",
    };
    // console.log(objTwo);
    const data = await dispatch(
      createRecipientGlobalAccount(filterEmptyColumns(objTwo))
    );
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      const recipient = data?.payload?.data?.data;
      // console.log(recipient);
      setRecipientVal(recipient);
      dispatch(
        getAllBeneficiaries({ currency: detail?.sendCurrency?.currency })
      );
      setStep(4);
    }
  };

  const [emailError, setEmailError] = useState(false);

  const handleFinish = () => {
    setDetails({
      currency: "",
      bank_name: "",
      account_number: "",
      beneficiary_name: "",
      email: "",
      state: "",
      city: "",
      postal_code: "",
      routing_number: "",
      swift_code: "",
      beneficiary_country: "",
      beneficiary_address: "",
      full_name: "",
      street_address: "",
      beneficiary_account_type: "",
    });
    handleFinishFunc(reciepientVal);
    actionCtx?.createRecipientGlobalFunc(false);
    actionCtx?.createRecipientGlobalTypeFunc("");
  };

  // function hasEmptyValues(array, pageValue, columnName) {
  //   if (array?.length > 0) {
  //     return array
  //       ?.filter((item) => item?.page === pageValue)
  //       ?.some((item) => !item[columnName] || item[columnName]?.trim() === "");
  //   }
  // }

  function hasEmptyValues(array, pageValue, columnName) {
    if (array?.length > 0 && Array?.isArray(array) && array?.length > 0) {
      return array
        ?.filter((item) => item?.page === pageValue)
        ?.some((item) => {
          const value = item[columnName];
          // Check if the value is empty (null, undefined, empty string, or empty object)
          return (
            value === null ||
            value === undefined ||
            (typeof value === "string" && value?.trim() === "") ||
            (typeof value === "object" &&
              value !== null &&
              Object?.keys(value)?.length === 0)
          );
        });
    }
    return false;
  }

  function hasPageValue(array, pageValue) {
    if (array?.length > 0) {
      return array?.some((item) => item?.page === pageValue);
    }
  }

  const filterArrayByPage = (array, pageValue) => {
    if (array?.length > 1) {
      const newList = array?.filter((chi) => chi?.page === pageValue);
      return newList;
    }
  };

  function transformArrayToObject(array) {
    return array?.reduce((acc, item) => {
      acc[item?.key] =
        typeof item?.value === "object" ? item?.value?.value : item?.value;
      return acc;
    }, {});
  }

  function hasInvalidEmails(array) {
    // Regular expression for basic email validation
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (array?.length > 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      for (let item of array) {
        if (item?.key === "email" && item?.value !== undefined) {
          if (!emailRegex?.test(item?.value)) {
            setEmailError(item?.label);
            toast.error(`Invalid ${item?.label}`, {
              position: "top-right",
            });
            return true;
          }
        }
      }

      return false;
    }
  }

  return (
    <div
      // onClick={() => {
      //   console.log(reciepientVal);
      // }}
      className="create-new-business-index-wrap "
    >
      <div className="content-wrap card-bg">
        <div className="top-wrap grey-bg">
          <div className="top-content">
            <div
              onClick={() => {
                actionCtx?.createRecipientGlobalFunc(false);
                actionCtx?.setGlobalCurrency({});
                actionCtx?.createRecipientGlobalTypeFunc("");
              }}
              className="close-wrap"
            >
              <figure className="img-box">{closeIcon}</figure>
              <p className="text">Close</p>
            </div>
            {/* step show start */}
            <div className="step-box">
              <span>STEP</span>
              <p>{step}/4</p>
            </div>
            {/* step show send */}
          </div>
          {/* bar content start */}
          <div className="bar-wrap">
            {" "}
            <ProgressBar
              bgcolor={theme === "light" ? `#020202` : "rgba(117, 90, 226, 1)"}
              completed={
                step === 1 ? 25 : step === 2 ? 50 : step === 3 ? 75 : 100
              }
              //   baseColor={theme !== "light" && `#020202`}
            />
          </div>

          {/* bar content end */}
        </div>
        {/* bottom wrap start */}
        <div className="bottom-wrap">
          {loadingInput ? (
            <>
              <NoContentBox loading={true} />
            </>
          ) : (
            <div className="content-wrap-box-form">
              {step !== 4 && (
                <div className="title-back-text-box">
                  {step > 1 && step !== 4 && (
                    <>
                      {" "}
                      <div
                        onClick={() => {
                          step > 1 && setStep(step - 1);
                        }}
                        className="back-box"
                      >
                        <figure className="img-box">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="img"
                          >
                            <path
                              className="dull-bright-filter"
                              d="M11.25 13.5L6.75 9L11.25 4.5"
                              stroke="#020202"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </figure>
                        <p className="back">Back</p>
                      </div>{" "}
                    </>
                  )}
                  {step !== 4 && (
                    <>
                      {" "}
                      <p className="title">
                        {step === 1
                          ? `Create a new recipient`
                          : step === 2
                          ? `Create a new recipient.`
                          : "Confirm Recipients Details"}
                      </p>
                      <p className="text grey-white-color">
                        {step === 3
                          ? "Confirm details before creating record."
                          : "Add a new recipient to your transfer list"}
                      </p>
                    </>
                  )}
                </div>
              )}
              <div className="form-box">
                {/* step 1 start ------------------------- */}
                {step === 1 && (
                  <>
                    {/* select option start */}
                    <div
                      style={{ marginBottom: "2rem" }}
                      className="select-option-global-wrap-box grey-bg"
                    >
                      {selectOptionList?.map((chi, idx) => {
                        return (
                          <div
                            className={`select-item ${
                              activeSelect === chi &&
                              "select-item-active white-black-bg"
                            }`}
                            key={idx}
                            style={{
                              cursor:
                                activeSelect !== chi
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                            onClick={() => {
                              setActiveSelect(chi);
                            }}
                          >
                            <p
                              className={`text ${
                                activeSelect !== chi && "grey-white-color"
                              }`}
                            >
                              {chi}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    {/* select option end */}
                    {/* bank step 1 start */}
                    {actionCtx?.createRecipientGlobalType === "bank" && (
                      <>
                        {/* form content start */}
                        <div className="form-content">
                          {inputObjList?.length > 0 && (
                            <>
                              {inputObjList?.map((chi, idx) => {
                                const {
                                  input_type,
                                  key,
                                  label,
                                  page,
                                  required,
                                  value,
                                  visible,
                                  options,
                                } = chi;
                                if (visible && Number(page) === 1) {
                                  return (
                                    <form
                                      action=""
                                      autoComplete="off"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                        zIndex:
                                          input_type === "select" ? 200 - idx : "1",
                                      }}
                                      key={idx}
                                    >
                                      <RavenInputField
                                        type={
                                          key === "email" ? key : input_type
                                        }
                                        color={`black-light`}
                                        label={`${label} ${required && "*"}`}
                                        placeholder={`Enter ${label}`}
                                        name={key}
                                        value={value}
                                        showError={label === emailError}
                                        selectOption={
                                          options?.length > 0 && options
                                        }
                                        // menuPlacement={`top`}
                                        selectStyles={
                                          options?.length > 0 &&
                                          reactSelectStyle
                                        }
                                        onChange={(e) => {
                                          const obj = {
                                            target: {
                                              value: e,
                                              name: key,
                                              type: input_type,
                                            },
                                          };
                                          emailError && setEmailError("");
                                          if (input_type === "select") {
                                            // console.log(obj);
                                            setOptionList(obj);
                                          } else {
                                            setOptionList(e);
                                          }
                                        }}
                                      />
                                    </form>
                                  );
                                }
                                return (
                                  <React.Fragment key={idx}></React.Fragment>
                                );
                              })}
                            </>
                          )}
                        </div>
                        {/* form content end */}
                        <div className="submit-btn-wrap">
                          <RavenButton
                            color="black-light"
                            label={`Proceed`}
                            disabled={hasEmptyValues(inputObjList, 1, "value")}
                            onClick={() => {
                              if (hasPageValue(inputObjList, 2)) {
                                if (
                                  hasInvalidEmails(
                                    filterArrayByPage(inputObjList, 1)
                                  )
                                ) {
                                  return;
                                } else {
                                  setStep(2);
                                }
                              } else {
                                setStep(3);
                              }
                            }}
                          />
                        </div>
                      </>
                    )}
                    {/* bank step 1 end */}
                    {/* card step 1 start */}
                    {actionCtx?.createRecipientGlobalType === "card" && (
                      <>
                        {" "}
                        {/* form content start */}
                        <div className="form-content">
                          <form
                            action=""
                            autoComplete="off"
                            style={{ width: "100%" }}
                          >
                            <RavenInputField
                              type={`select`}
                              color={`black-light`}
                              label="Card Number* "
                              placeholder="e.g 0536365712847"
                              name={`bank_name`}
                              value={details?.bank_name}
                              onChange={handleChange}
                            />
                          </form>
                          {/* form group start */}
                          <form
                            action=""
                            autoComplete="off"
                            style={{ width: "100%" }}
                          >
                            <RavenInputField
                              type={`select`}
                              color={`black-light`}
                              label="Name on Card* "
                              placeholder="e.g Raven Business"
                              name={`bank_name`}
                              value={details?.bank_name}
                              onChange={handleChange}
                            />
                          </form>
                          {/* form group end */}
                        </div>
                        {/* form content end */}
                        <div className="submit-btn-wrap">
                          <RavenButton
                            color="black-light"
                            label={`Proceed`}
                            onClick={() => {
                              // handleStep();
                              // if (!validateEmail(details.business_email)) {
                              //   toast.error("Invalid business email address!", {
                              //     position: "top-right",
                              //   });
                              //   setError((prev) => {
                              //     return { ...prev, business_email: true };
                              //   });
                              //   return;
                              // } else {
                              //   handleStep();
                              // }
                            }}
                            //   disabled={
                            //     !details?.business_name ||
                            //     !details?.business_phone ||
                            //     !details?.business_email
                            //     // !details?.user_email
                            //   }
                          />
                        </div>
                      </>
                    )}
                    {/* card step 1 end */}
                    {/* momo step 1 start */}
                    {actionCtx?.createRecipientGlobalType === "momo" && (
                      <>
                        {" "}
                        {/* form content start */}
                        <div className="form-content">
                          <form
                            action=""
                            autoComplete="off"
                            style={{ width: "100%" }}
                          >
                            <RavenInputField
                              type={`number`}
                              color={`black-light`}
                              label="Provider* "
                              placeholder="e.g 0536365712847"
                              name={`bank_name`}
                              value={details?.bank_name}
                              onChange={handleChange}
                            />
                          </form>
                          {/* form group start */}
                          <form
                            action=""
                            autoComplete="off"
                            style={{ width: "100%" }}
                          >
                            <RavenInputField
                              type={`number`}
                              color={`black-light`}
                              label="Account Number* "
                              placeholder="e.g Raven Business"
                              name={`bank_name`}
                              value={details?.bank_name}
                              onChange={handleChange}
                            />
                          </form>
                          {/* form group end */}
                          <form
                            action=""
                            autoComplete="off"
                            style={{ width: "100%" }}
                          >
                            <RavenInputField
                              type={`phone`}
                              color={`black-light`}
                              label="Reachable phone number* "
                              placeholder="e.g 0536365712847"
                              name={`bank_name`}
                              value={details?.bank_name}
                              onChange={handleChange}
                            />
                          </form>
                          {/* form group start */}
                          <form
                            action=""
                            autoComplete="off"
                            style={{ width: "100%" }}
                          >
                            <RavenInputField
                              type={`text`}
                              color={`black-light`}
                              label="Account Name* "
                              placeholder="e.g Raven Business"
                              name={`bank_name`}
                              value={details?.bank_name}
                              onChange={handleChange}
                            />
                          </form>
                          {/* form group end */}
                        </div>
                        {/* form content end */}
                        <div className="submit-btn-wrap">
                          <RavenButton
                            color="black-light"
                            label={`Proceed`}
                            onClick={() => {
                              // handleStep();
                              // if (!validateEmail(details.business_email)) {
                              //   toast.error("Invalid business email address!", {
                              //     position: "top-right",
                              //   });
                              //   setError((prev) => {
                              //     return { ...prev, business_email: true };
                              //   });
                              //   return;
                              // } else {
                              //   handleStep();
                              // }
                            }}
                            //   disabled={
                            //     !details?.business_name ||
                            //     !details?.business_phone ||
                            //     !details?.business_email
                            //     // !details?.user_email
                            //   }
                          />
                        </div>
                      </>
                    )}
                    {/* momo step 1 end */}
                  </>
                )}
                {/* step 1 end --------------------- */}

                {/* step 2 start ------------------------- */}
                {step === 2 && (
                  <>
                    {/* form content start */}
                    <div
                      onClick={() => {
                        // console.log(inputObjList);
                      }}
                      className="form-content"
                    >
                      {inputObjList?.length > 0 && (
                        <>
                          {inputObjList?.map((chi, idx) => {
                            const {
                              input_type,
                              key,
                              label,
                              page,
                              required,
                              value,
                              visible,
                              options,
                            } = chi;
                            if (visible && Number(page) === 2) {
                              return (
                                <form
                                  action=""
                                  autoComplete="off"
                                  style={{
                                    width: "100%",
                                    // position: "relative",
                                    position: "relative",
                                    zIndex:
                                      input_type === "select" ? 200 - idx : "1",
                                  }}
                                  key={idx}
                                >
                                  <RavenInputField
                                    type={key === "email" ? key : input_type}
                                    color={`black-light`}
                                    showError={label === emailError}
                                    label={`${label} ${required && "*"}`}
                                    placeholder={`Enter ${label}`}
                                    name={key}
                                    value={value}
                                    selectOption={
                                      options?.length > 0 && options
                                    }
                                    selectStyles={
                                      options?.length > 0 && reactSelectStyle
                                    }
                                    onChange={(e) => {
                                      const obj = {
                                        target: {
                                          value: e,
                                          name: key,
                                          type: input_type,
                                        },
                                      };
                                      emailError && setEmailError("");
                                      if (input_type === "select") {
                                        // console.log(obj);
                                        setOptionList(obj);
                                      } else {
                                        setOptionList(e);
                                      }
                                    }}
                                  />
                                </form>
                              );
                            }
                            return <React.Fragment key={idx}></React.Fragment>;
                          })}
                        </>
                      )}
                    </div>
                    {/* form content end */}
                    <div className="submit-btn-wrap">
                      <RavenButton
                        color="black-light"
                        label={`Verify and proceed`}
                        onClick={() => {
                          // const obj = inputObjList?.map((chi))
                          if (
                            hasInvalidEmails(filterArrayByPage(inputObjList, 2))
                          ) {
                            return;
                          } else {
                            setStep(3);
                          }
                        }}
                        disabled={hasEmptyValues(inputObjList, 2, "value")}
                      />
                    </div>
                  </>
                )}
                {/* step 2 end --------------------- */}
                {/* step 3 start ------------------------- */}
                {step === 3 && (
                  <>
                    {/* form content start */}
                    <div className="form-content">
                      <div
                        style={{
                          paddingBottom:
                            inputObjList?.length > 8 ? "7rem" : "3rem",
                        }}
                        className="preview-detal-content-info-box border-theme card-bg"
                      >
                        {/* preview wrap global start  */}
                        <div
                          className={`preview-global-wrap-recipient-box ${
                            hasPageValue(inputObjList, 2) &&
                            "border-theme-bottom"
                          }`}
                        >
                          <div className="title-edit-box">
                            <p className="title">Bank Details</p>
                            <p
                              onClick={() => {
                                setStep(1);
                              }}
                              className="edit grey-white-color border-theme-bottom"
                            >
                              Edit
                            </p>
                          </div>
                          {inputObjList?.length > 0 && (
                            <>
                              {filterArrayByPage(inputObjList, 1)?.map(
                                (chi, idx) => {
                                  return (
                                    <div key={idx} className="value-label-box">
                                      <p className="label">
                                        {chi?.label || ""}
                                      </p>
                                      <p className="value">
                                        {typeof chi?.value === "object"
                                          ? chi?.value?.label
                                          : chi?.value || ""}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          )}
                        </div>
                        {/* preview wrap global end */}
                        {/* preview wrap global start */}
                        {inputObjList?.length > 0 &&
                          hasPageValue(inputObjList, 2) && (
                            <div
                              onClick={() => {
                                // console.log(inputObjList);
                              }}
                              className="preview-global-wrap-recipient-box border-theme-bottom"
                            >
                              <div className="title-edit-box">
                                <p className="title">Recipient Details</p>
                                <p
                                  onClick={() => {
                                    setStep(2);
                                  }}
                                  className="edit grey-white-color border-theme-bottom"
                                >
                                  Edit
                                </p>
                              </div>
                              {inputObjList?.length > 0 && (
                                <>
                                  {filterArrayByPage(inputObjList, 2)?.map(
                                    (chi, idx) => {
                                      return (
                                        <div
                                          key={idx}
                                          className="value-label-box"
                                        >
                                          <p className="label">
                                            {chi?.label || ""}
                                          </p>
                                          <p className="value">
                                            {typeof chi?.value === "object"
                                              ? chi?.value?.label
                                              : chi?.value || ""}
                                          </p>
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        {/* preview wrap global end */}
                      </div>
                    </div>
                    {/* form content end */}
                    <div className="submit-btn-wrap">
                      <RavenButton
                        color="black-light"
                        label={`Create Recipient`}
                        onClick={handleSubmit}
                        loading={loadingTrans}
                        disabled={
                          hasEmptyValues(inputObjList, 2, "value") &&
                          hasEmptyValues(inputObjList, 1, "value")
                        }
                      />
                    </div>
                  </>
                )}
                {/* step 3 end --------------------- */}
                {/* step 4 start ------------------------- */}
                {step === 4 && (
                  <>
                    {/* form content start */}
                    <div className="form-content">
                      <div className="success-create-recipient">
                        <div className="img-success-box"></div>
                        <p className="success-title">
                          Transfer Recipient Created
                        </p>
                        <p className="succes-text grey-white-color">
                          You have successfully created a recipient for your{" "}
                          {detail?.sendCurrency?.currency} <br />
                          currency click close & continue to continue your{" "}
                          <br />
                          transaction
                        </p>
                      </div>
                    </div>
                    {/* form content end */}
                    <div className="submit-btn-wrap">
                      <RavenButton
                        color="black-light"
                        label={`Close & continue`}
                        onClick={() => {
                          handleFinish();
                        }}
                      />
                    </div>
                  </>
                )}
                {/* step 4 end --------------------- */}
              </div>
            </div>
          )}
        </div>
        {/* bottom wrap end */}
      </div>
    </div>
  );
};

export default NewCreateRecipientGlobalWrapIndex;
