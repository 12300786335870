// import { setCookie } from "../../utils/CookiesFunction";

export const genCaptchaKeyAsync = async (param) => {
  try {
    const token = await window?.grecaptcha?.execute("6Lfbo54nAAAAAEUGhpgYHJY7DvpS_e7JoUt2Na4d", {
      action: "validate_captcha",
    });
    // console.log(token);
    // setCookie('g-rec-response', token?.toString(), 1500000);
    return token;
  } catch (e) {
    // console.log(e);

    return "error_string";
  }
};
