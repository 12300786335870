/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
// import setAuthToken from "../utils/auth";
import { toast } from "@ravenpay/raven-bank-ui";

export const rejectPaymentRequest = createAsyncThunk(
  "admin/cancel-payment-request",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/payment_request/cancel", payload);
      // console.log("register", data);

      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const retryPaymentRequest = createAsyncThunk(
  "admin/retry-payment-request",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/payment_request/retry", payload);
      // console.log("register", data);

      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const approvePaymentRequest = createAsyncThunk(
  "admin/approve-payment-request",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/payment_request/approve", payload);
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const markNotificationAsRead = createAsyncThunk(
  "admin/mark-notify-as-read",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/notification/seen", payload);
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const markAllNotificationAsRead = createAsyncThunk(
  "admin/mark-all-notify-as-read",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/notification/update_to_read_in_bulk",
        payload
      );
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAllPaymentRequest = createAsyncThunk(
  "admin/all-payment-requests",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/payment_request?startDate=${payload?.start_date || ""}&endDate=${
          payload?.end_date || ""
        }&search=${payload?.search_term || ""}&per_page=${
          payload?.per_page || ""
        }&current_page=${payload?.current_page || ""}&status=${
          payload?.status || ""
        }&type=${payload?.type || ""}`,
        payload
      );
      //   console.log("register", data);

      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(setAllPaymentRequest(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSinglePaymentRequest = createAsyncThunk(
  "admin/single-payment-requests",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/payment_request/${
          payload?.id ||
          localStorage?.getItem("single-payment-request-id") ||
          ""
        }`,
        payload
      );
      //   console.log("register", data);

      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(setAllPaymentRequest(data.data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const payment_request = createSlice({
  name: "payment-request",
  initialState: {
    isAuth: false,
    loading: false,
    payment_requests: {},
    payment_request: {},
    loadingSearch: false,
    loadingTeamUpdate: false,
    loadingStatement: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setAllPaymentRequest: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.payment_requests = action.payload;
    },
    setSinglePaymentRequest: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.payment_request = action.payload;
    },
  },
  extraReducers: {
    [getAllPaymentRequest.pending]: (state) => {
      state.loading = true;
    },
    [getAllPaymentRequest.fulfilled]: (state) => {
      state.loading = false;
    },
    [getAllPaymentRequest.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSinglePaymentRequest.pending]: (state) => {
      state.loading = true;
    },
    [getSinglePaymentRequest.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSinglePaymentRequest.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [rejectPaymentRequest.pending]: (state) => {
      state.loadingStatement = true;
    },
    [rejectPaymentRequest.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [rejectPaymentRequest.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [retryPaymentRequest.pending]: (state) => {
      state.loadingStatement = true;
    },
    [retryPaymentRequest.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [retryPaymentRequest.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [approvePaymentRequest.pending]: (state) => {
      state.loadingStatement = true;
    },
    [approvePaymentRequest.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [approvePaymentRequest.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [markAllNotificationAsRead.pending]: (state) => {
      state.loadingStatement = true;
    },
    [markAllNotificationAsRead.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [markAllNotificationAsRead.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllPaymentRequest, setSinglePaymentRequest } =
  payment_request.actions;

export default payment_request.reducer;
