import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import ActionContext from "../../context/ActionContext";
import "../../styles/reusables/ChangeSourceAccount.css";
import {
  // formatNumWithCommaNairaSymbol,
  trimLongString,
} from "../../utils/helper/Helper";
import { useOnClickOutside } from "../../utils/helper/UseOnClickOutside";
import AccountDropDownBox from "../fragments/AccountDropDownBox";

const ChangeSourceAccount = ({
  title,
  darkType,
  onChange,
  defaultObj,
  onObjChange,
  downDropType,
  // defaultVal,
  dontShowChange,
}) => {
  const actionCtx = useContext(ActionContext);

  const [showAccountDrop, setShowAccountDrop] = useState(false);
  const { all_account } = useSelector((state) => state.user);
  const InfoIcon = (
    <svg
      className="img"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
        stroke="#8B8B8B"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const testingPreviewSourceRef = useOnClickOutside(() => {
    setShowAccountDrop(false);
  });

  if (all_account?.length < 2) {
    return <></>;
  }

  if (defaultObj) {
    return (
      <div
        className={`account-change-drop-wrap border-theme-top ${
          darkType && "account-change-drop-wrap-dark"
        }`}
      >
        <p className="title grey-white-color">{title || `Source Account.`}</p>
        <div className="change-wrap grey-bg-two">
          <div className="change-name-info">
            <div className="name-icon">
              <p className="name">
                {trimLongString(defaultObj?.custom_account_name, 25) || "---"}
              </p>
              <figure className="img-box">{InfoIcon}</figure>
            </div>
            <p className="label grey-white-color">
              {/* {formatNumWithCommaNairaSymbol(defaultObj?.balance)} */}
              {`${actionCtx?.currentAccount?.account_number} • ${trimLongString(
                actionCtx?.currentAccount?.bank,
                20
              )}`}
            </p>
            {/* <p className="label grey-white-color">
              {formatNumWithCommaNairaSymbol(defaultObj?.balance)}
            </p> */}
          </div>
          <div
            onClick={() => {
              // actionCtx.setCurrentAccountDrop(!actionCtx.currentAccountDrop);
              setShowAccountDrop(!showAccountDrop);
            }}
            ref={testingPreviewSourceRef}
            className="change-text"
          >
            {!dontShowChange && (
              <p className="text">{showAccountDrop ? "Close" : `Change`}</p>
            )}
            {showAccountDrop && (
              <div
                style={{ borderWidth: ".2rem", borderRadius: "1.2rem" }}
                className={`select-toggle-box  ${
                  downDropType && "select-toggle-box-down"
                } border-theme  shadow-shadow-box-shadow`}
              >
                <AccountDropDownBox
                  downDropType
                  typeTwo={true}
                  defaultVal={defaultObj}
                  big={false}
                  onSelect={(obj) => {
                    // actionCtx?.setCurrentAccount(obj);
                    // console.log(obj);
                    // dontShowChange={dontShowChange}
                    onObjChange && onObjChange(obj);
                    setShowAccountDrop(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`account-change-drop-wrap border-theme-top ${
          darkType && "account-change-drop-wrap-dark"
        }`}
      >
        <p className="title grey-white-color">{title || `Source Account.`}</p>
        <div className="change-wrap grey-bg-two">
          <div className="change-name-info">
            <div className="name-icon">
              <p className="name">
                {trimLongString(
                  actionCtx?.currentAccount?.custom_account_name,
                  25
                ) || "------------"}
              </p>
              <figure className="img-box">{InfoIcon}</figure>
            </div>
            <p className="label grey-white-color">
              {/* {formatNumWithCommaNairaSymbol(
                actionCtx?.currentAccount?.balance
              )} */}
              {`${
                actionCtx?.currentAccount?.account_number || "------------"
              } • ${trimLongString(
                actionCtx?.currentAccount?.bank || "-----------",
                20
              )}`}
            </p>
            {/* <p className="label grey-white-color">
              {formatNumWithCommaNairaSymbol(defaultObj?.balance)}
            </p> */}
          </div>
          <div
            onClick={() => {
              actionCtx.setCurrentAccountDrop(!actionCtx.currentAccountDrop);
              onChange && onChange();
            }}
            className="change-text"
          >
            <p className="text">Change</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeSourceAccount;
