import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import forgetImg from "../../assets/forget-password-icon.svg";
import AuthLayoutTwo from "../../layout/auth/AuthLayoutTwo";
// import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import "../../styles/auth/SignUp.css";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const EnterTokenForgetPassword = () => {
  const navigate = useNavigate();
  const [completeToken, setCompleteToken] = useState(false);
  const [details, setDetails] = useState({
    token: "",
  });

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     const obj = { ...details, [name]: value };
  //     setDetails(obj);
  //   };

  //   console.log("kjhs");
  return (
    <AuthLayoutTwo>
      <div className="sign-in-wrap">
        {/* form start */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          action=""
          className="form form-forget-password"
        >
          <div className="img-wrap-forget">
            <figure className="img-box">
              <img src={forgetImg} alt="" className="img" />
            </figure>
          </div>
          {/* title text box start */}
          <div className="text-box">
            <p className="big-title">Enter Your Token</p>
            <p className="small-title">
              Follow Prompt to recover your password
            </p>
          </div>
          {/* title text box end */}
          <RavenInputField
            type={`pin`}
            // label={`Enter token sent to your email`}
            color={`black-light`}
            onChange={(e) => {
              setCompleteToken(false);
              setDetails((prev) => {
                return { ...prev, token: e };
              });
            }}
            onComplete={() => {
              setCompleteToken(true);
            }}
            value={details?.token}
            showCountDown
            countDownTime={1000}
            key="oiu"
          />
          <RavenInputField
            type={`submit`}
            color="black-light"
            value={`Proceed to next step`}
            disabled={!completeToken}
            className="btn-submit"
            // loading
            onSubmit={() => {
              navigate("/create-password");
            }}
          />
          {/* back text start */}
          <div className="back-text">
            <FaArrowLeft className="icon" />
            <p className="text">
              Back to <span onClick={() => navigate("/login")}>Login</span>
            </p>
          </div>
          {/* back text end */}
        </form>
        {/* form end */}
      </div>
    </AuthLayoutTwo>
  );
};

export default EnterTokenForgetPassword;
