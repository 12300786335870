// import { useContext, useEffect, useState } from "react";
// // import Confetti from "react-confetti";
// import Sound from "react-sound";
// import confettiSound from "../../assets/water_droplet_5-165635.mp3"; 
// import ActionContext from "../../context/ActionContext";

// function ConfettiWithSound({soundUrl}) {
// //   const [confettiActive, setConfettiActive] = useState(false);
//   const [soundStatus, setSoundStatus] = useState(Sound.status.STOPPED);
//   const actionCtx = useContext(ActionContext)

//   const startConfettiAndSound = () => {
//     // setConfettiActive(true);
//     setSoundStatus(Sound.status.PLAYING);

//     // Stop the sound after a certain duration (adjust as needed)
//     setTimeout(() => {
//       setSoundStatus(Sound.status.STOPPED);
//     //   setConfettiActive(false);
//     }, 3000); // 3 seconds (adjust as needed)
//   };

//   useEffect(() => {
//     startConfettiAndSound()
//   }, [actionCtx.confettiVal])

//   return (
//     <div>
//       {/* <button onClick={startConfettiAndSound}>Start Confetti and Sound</button> */}

//       {/* Confetti */}
//       {/* {confettiActive && <Confetti />} */}

//       {/* Sound */}
//       <Sound
//         url={actionCtx?.soundVal || confettiSound}
//         playStatus={soundStatus}
//         onFinishedPlaying={() => setSoundStatus(Sound.status.STOPPED)}
//       />
//     </div>
//   );
// }

// export default ConfettiWithSound;

import { useSound } from 'use-sound';

function ConfettiWithSound(soundUrl) {
  const [play] = useSound(soundUrl, { volume: 0.5 }); // Adjust volume as needed

  const playNotification = () => {
    play();
  };

  return playNotification;
}

export default ConfettiWithSound;