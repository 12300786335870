import {
  //   RavenButton,
  RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../../../styles/dashboard/Two-Fa/ConfirmTwoFaModal.css";
// import QRCode from "react-qr-code";
// import { FaCheck } from "react-icons/fa";
// import NoContentBox from "../../../../components/reusables/NoContentBox";
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import {
  //   disableTwoFaFactorSettings,
  getTokenForTransactionResetPin,
  resetTransactionPin,
  verifyResetTokenTransationPin,
  //   verifyTwoFaFactorSettings,
} from "../../../../redux/settings";
// import { getUser } from "../../../../redux/user";
// import { debounce } from "lodash";

const ResetTransactionPin = ({ visible, onClose, onFinish, detail }) => {
  const { loadingEnable, loadingStatement } = useSelector(
    (state) => state.settings
  );
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  // const [isCopied, setIsCopied] = useState(false);
  const [details, setDetails] = useState({
    token: "",
    new_pin: "",
  });
  const [completeToken, setCompleteToken] = useState(false);
  const [completeTokenNewPin, setCompleteTokenNewPin] = useState(false);
  const [newPin, setNewPin] = useState("");

  const { user } = useSelector((state) => state.user);

  const handleClose = () => {
    setDetails((prev) => {
      return { ...prev, token: "", new_pin: "" };
    });
    setCompleteToken(false);
    setCompleteTokenNewPin(false);
    setStep(1);
    onClose();
  };

  const handleGetToken = () => {
    dispatch(getTokenForTransactionResetPin());
  };

  const handleResetPin = async () => {
    const obj = {
      otp: details?.token,
      new_pin: newPin,
    };
    const data = await dispatch(resetTransactionPin(obj));
    if (data?.payload?.data?.status === "success") {
      handleClose();
    }
  };

  const verifyOtp = async () => {
    const obj = {
      otp: details?.token,
      email: user?.business_email,
    };
    const data = await dispatch(verifyResetTokenTransationPin(obj));
    if (data?.payload?.data?.status === "success") {
      setStep(2);
    }
  };

  return (
    <>
      <RavenModal
        className={`confirm-two-fa-modal-wrap-index-modal diable-two-fa-modal-wrap-index-modal`}
        onBtnClick={() => {
          if (step === 1) {
            verifyOtp();
          }
          if (step === 2) {
            handleResetPin();
          }
        }}
        visble={visible}
        onClose={handleClose}
        btnColor="black-light"
        btnLabel={step === 1 ? `Verify OTP` : "Reset Password"}
        disabled={
          step === 1
            ? !completeToken
            : step === 2
            ? !completeToken || !completeTokenNewPin
            : false
        }
        // loading={loadingStatement || loadingEnable}
        loading={loadingEnable}
      >
        <div className="confirm-two-fa-modal-wrap-index">
          {/* content start */}
          {step === 1 ? (
            <>
              <div
                onClick={() => {
                  //   console.log(business);
                  //   console.log(user);
                }}
                className="title-text-box"
              >
                <p className="title">Reset your transaction PIN</p>
                <p className="text grey-white-color">
                  Create a new PIN for your transaction, that would be used to
                  authenticate your account.
                </p>
              </div>
              <div className="enter-pin-text-box">
                {/* <p className="enter-text">
                    Enter the verification code from the authenticator app
                  </p> */}
                <div className="pin-box">
                  <RavenInputField
                    label="Enter OTP code sent to you"
                    value={details?.token}
                    onChange={(e) => {
                      //   console.log(e);
                      setDetails((prev) => {
                        return { ...prev, token: e };
                      });
                      setCompleteToken(false);
                    }}
                    onComplete={(num) => {
                      setCompleteToken(true);
                      // handleSubmit(num);
                    }}
                    type="pin"
                    color="black-light"
                    key={`svhbskns`}
                  />
                </div>
              </div>
              {/* request pin box start */}
              <div className="request-pin-box">
                <p className="text grey-white-color">Click to</p>
                <div
                  onClick={() => {
                    if (!loadingStatement) {
                      handleGetToken();
                    }
                  }}
                  className="grey-bg text-click"
                >
                  {loadingStatement ? (
                    <>
                      {" "}
                      <div
                        style={{
                          padding: "0rem",
                          display: "flex",
                          justifyContent: "flex-end",
                          //   transform: "translateX(1.5rem)",
                        }}
                        className="load-wrap"
                      >
                        <ThreeDots
                          height="10"
                          width="80"
                          radius="9"
                          // color="#020202"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    </>
                  ) : (
                    <p className="recieve">Receive the Code</p>
                  )}
                </div>
              </div>
              {/* request pin box end */}
            </>
          ) : (
            <>
              <div className="title-text-box">
                <p className="title">Reset your transaction PIN</p>
                <p className="text grey-white-color">
                  Create a new PIN for your transaction, that would be used to
                  authenticate your account.
                </p>
              </div>
              <div className="enter-pin-text-box ">
                {/* <p className="enter-text">
                    Enter the verification code from the authenticator app
                  </p> */}
                <div className="pin-box">
                  <RavenInputField
                    label="Enter new transaction pin"
                    value={newPin}
                    pinFieldNumber={6}
                    onChange={(e) => {
                      //   console.log(e);
                      setNewPin(e);
                      setCompleteTokenNewPin(false);
                    }}
                    onComplete={(num) => {
                      setCompleteTokenNewPin(true);
                      // handleSubmit(num);
                    }}
                    key={"kbjsnd"}
                    type="pin"
                    color="black-light"
                  />
                </div>
              </div>
            </>
          )}
          {/* content end */}
        </div>
      </RavenModal>
    </>
  );
};

export default ResetTransactionPin;
