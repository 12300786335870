import DashboardSendMoneyIndex from "../../../pages/dashboard/send-money";
import DashboardSendToBank from "../../../pages/dashboard/send-money/bank";
import BankToBankManualPage from "../../../pages/dashboard/send-money/bank/BankManualUpload";
import DashboardSendMoneyBulkAuto from "../../../pages/dashboard/send-money/bulk-auto";
import DashboardSendToBankBulkManual from "../../../pages/dashboard/send-money/bulk-manual";
import DashboardSendMoneyRaven from "../../../pages/dashboard/send-money/raven";
import DashboardSendMoneyBulkAutoRaven from "../../../pages/dashboard/send-money/raven/RavenBulkUpload";
import RavenManualPage from "../../../pages/dashboard/send-money/raven/RavenManualPage";
import DashboardWithinAccount from "../../../pages/dashboard/send-money/within-account";

// BankToBankManualPage

export const dashboard_send_money_route_group = [
  { path: "/dashboard-send-money", element: <DashboardSendMoneyIndex /> },
  { path: "/dashboard-send-money-raven", element: <DashboardSendMoneyRaven /> },
  { path: "/dashboard-send-money-within-account", element: <DashboardWithinAccount /> },
  { path: "/dashboard-send-money-bank-manual", element: <BankToBankManualPage /> },
  { path: "/dashboard-send-money-raven-manual", element: <RavenManualPage /> },
  { path: "/dashboard-send-money-bank", element: <DashboardSendToBank /> },
  {
    path: "/dashboard-send-money-bank-bulk-manual",
    element: <DashboardSendToBankBulkManual />,
  },
  {
    path: "/dashboard-send-money-bank-bulk-auto",
    element: <DashboardSendMoneyBulkAuto />,
  },
  {
    path: "/dashboard-send-money-raven-bulk-auto",
    element: <DashboardSendMoneyBulkAutoRaven />,
  },
];