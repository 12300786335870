// import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
// import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/reusables/DeleteModal";

const InvoiceBusinessModal = ({ onClose, visible, onAddAddress }) => {
  // const navigate = useNavigate();
  return (
    <DeleteModal
      onDelete={onAddAddress}
      visible={visible}
      onCancel={onClose}
      title={`Add Invoice Address`}
      deleteText={`Add Invoice Address`}
      buttonColor={`black-light`}
      text={`Before you can access invoice we need some details to proceed with your invoice creation, we need your address and other details before proceed, click "Add Invoice Address" to proceed.`}
    />
  );
};

export default InvoiceBusinessModal;
