import React, { useContext, useState } from "react";
import { FaCheck } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
// import copyIcon from "../../assets/side-copy-icon.svg";
import ActionContext from "../../context/ActionContext";
import { trimLongString } from "../../utils/helper/Helper";

const NewAccountDetailsBox = ({ onClick }) => {
  const [isCopied] = useState(false);
  const { loading, all_account, user_resource_and_permissions } = useSelector(
    (state) => state.user
  );
  const actionCtx = useContext(ActionContext);
  //   const handleCopied = async () => {
  //     setIsCopied(true);
  //     await navigator.clipboard.writeText(account_details?.account_number);
  //     setTimeout(() => {
  //       setIsCopied(false);
  //     }, 1000);
  //   };

  const dropDownIcon = (
    <svg
      className="img"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.2423 6.0083L8.24774 10.0137L12.2532 6.0083"
        stroke="white"
        strokeWidth="1.20163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  // if(!actionCtx?.currentAccount){
  //   return(
  //     <></>
  //   )
  // }

  return (
    <>
      <div
        onClick={() => {
          onClick &&
            all_account?.length > 0 &&
            user_resource_and_permissions?.allowed_permissions?.includes(
              "can_view_account"
            ) &&
            onClick();
        }}
        className="account-detail-box"
      >
        <div className="text-box">
          <p className="num">
            {loading ? (
              <div
                style={{
                  alignSelf: "center",
                  display: "grid",
                  placeItems: "center",
                }}
                className=""
              >
                <Skeleton width={100} height={12} />
              </div>
            ) : (
              actionCtx?.currentAccount?.custom_account_name ||
              "---------------"
            )}
          </p>
          <p className="text">
            {loading ? (
              <div className="">
                <Skeleton width={70} height={9} />
              </div>
            ) : (
              `${
                actionCtx?.currentAccount?.account_number || "----------------"
              } • ${trimLongString(
                actionCtx?.currentAccount?.bank || "--------------",
                18
              )}` || "----------------"
            )}
          </p>
        </div>

        {all_account?.length > 0 && (
          <>
            {isCopied ? (
              <div className="copied-box">
                <FaCheck className="icon" />
              </div>
            ) : (
              <figure onClick={() => {}} className="img-box img-box-drop">
                {dropDownIcon}
              </figure>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default NewAccountDetailsBox;
