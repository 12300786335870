import React from "react";
import DeleteModal from "../../../components/reusables/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { reschedulePayment } from "../../../redux/schedulePayment";

const ScheduleDeleteModal = ({ visible,  onCancel, onFinish, detail }) => {

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.schedule);

  const handleDelete = async () => {
    const id = detail?.id;
    const obj = {
      id: id,
      status: 5,
    };
    // console.log(obj);

    const data = await dispatch(reschedulePayment(obj));
    if (data?.payload?.response?.data?.status === "fail") {
      onCancel();
    }
    if (data?.payload?.data?.status === "success") {
      // console.log(data?.payload);
      onFinish();
    }
  };

  return (
    <DeleteModal
      visible={visible}
      onCancel={onCancel}
      onDelete={handleDelete}
      text={`To cancel a scheduled payment, please click on the "cancel schedule" button below .`}
      title={`Cancel this schedule`}
      deleteText={`Cancel Schedule`}
      loading={loading}
    />
  );
};

export default ScheduleDeleteModal;
