import {
  RavenButton,
  RavenInputField,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React from "react";
import { ThreeDots } from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import {
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  reactSelectStyle,
  trimLongString,
} from "../../../../utils/helper/Helper";

const ManualEditTable = ({
  editMode,
  one,
  two,
  three,
  four,
  five,
  six,
  onEdit,
  onDelete,
  handleChange,
  loading,
  showError,
  setError,
  onRowClick,
  selectId,
  onEditFininsh,
  showEditButton,
  processLoading,
  errorMessage,
}) => {
  const { bankList } = useSelector((state) => state.transaction);

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name } = chi;
      return { label: name, value: code };
    });
    return list;
  };

  const infoIcon = (
    <svg
      className="img"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
        stroke="rgba(255, 15, 0, 1)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <tr>
      {one && (
          <td>
            <div className="one-error-box">
              {one}
              {errorMessage && (
                <div className="error-box tooltip-hover-wrap">
                  <RavenToolTip
                    color="black-light"
                    text={errorMessage}
                    position={`top-right`}
                  />
                  <figure className="img-box">{infoIcon}</figure>
                </div>
              )}
            </div>
          </td>
        )}
        {/* <td>{<></>}</td> */}

        <td>
          {editMode ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              autoComplete="off"
              style={{ width: "100%" }}
              action=""
            >
              {" "}
              <RavenInputField
                color="black-light"
                type={`account-number`}
                name={`account_number`}
                value={two}
                onChange={(e) => {
                  handleChange(e);
                  setError &&
                    setError((prev) => {
                      return { ...prev, username: false };
                    });
                }}
                placeholder={`E.g 2237760987`}
                showError={showError}
                className={`edit-table-input`}
              />
            </form>
          ) : (
            <span>{two}</span>
          )}
        </td>

        <td>
          {editMode ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              autoComplete="off"
              style={{ width: "100%" }}
              action=""
            >
              {" "}
              <RavenInputField
                color="black-light"
                type={`select`}
                name="bank"
                value={six}
                className={`edit-select-box`}
                selectValue={six}
                
                id={selectId}
                onChange={(e) => {
                  const obj = {
                    target: {
                      value: {
                        value: e.value,
                        label: e.label,
                      },
                      name: "bank",
                    },
                  };
                  // console.log(obj);
                  handleChange(obj);
                  setError &&
                    setError((prev) => {
                      return { ...prev, username: false };
                    });
                }}
                placeholder={`Select bank`}
                showError={showError}
                menuPlacement={selectId > 5 ? `top` : "bottom"}
                selectOption={formatSelectOption(bankList)}
                selectStyles={reactSelectStyle}
              />
            </form>
          ) : (
            <span>{trimLongString(six?.label, 35) || "---"}</span>
          )}
        </td>

        <td>
          {editMode ? (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                autoComplete="off"
                style={{ width: "100%" }}
                action=""
              >
                {" "}
                <RavenInputField
                  color="black-light"
                  type={`text`}
                  name={`account_name`}
                  value={three || ""}
                  onChange={handleChange}
                  disabled
                  placeholder=" "
                />
              </form>
            </>
          ) : (
            <span>{three}</span>
          )}
        </td>
        <td>
          {editMode ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              autoComplete="off"
              style={{ width: "100%" }}
              action=""
            >
              {" "}
              <RavenInputField
                color="black-light"
                type={`number`}
                name={`amount`}
                value={four}
                thousandFormat
                numberPrefix={`₦`}
                onChange={handleChange}
                placeholder="E.g ₦1,500"
                className={`edit-table-input `}
              />
            </form>
          ) : (
            <span>
              {formatNumWithCommaNairaSymbol(formatNumWithoutCommaNaira(four))}
            </span>
          )}
        </td>
        <td>
          {loading ? (
            <>
              {" "}
              <div
                style={{
                  padding: "0rem",
                  display: "flex",
                  justifyContent: "flex-start",
                  transform: "translateX(1.5rem)",
                }}
                className="load-wrap"
              >
                <ThreeDots
                  height="10"
                  width="30"
                  radius="9"
                  // color="#020202"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            </>
          ) : (
            <div
              className={`status-type-wrap ${
                five === "verified" && "status-type-wrap-success"
              } ${five === "unverified" && "status-type-wrap-fail"} ${
                five === "failed" && "status-type-wrap-fail"
              }  ${five === "pending" && "status-type-wrap-pending"}`}
            >
              <span style={{ textTransform: "capitalize" }}>{five}</span>
            </div>
          )}
        </td>
        {/* action start */}
        <td>
          {!editMode ? (
            <div className="payroll-table-action-wrap">
              {processLoading ? (
                <>
                  <Skeleton
                    width={29}
                    height={29}
                    className={`img-box`}
                    circle
                  />
                  <Skeleton
                    width={29}
                    height={29}
                    className={`img-box`}
                    circle
                  />
                </>
              ) : (
                <>
                  {" "}
                  <figure
                    className="img-box grey-bg darken-action-bg tooltip-hover-wrap"
                    onClick={onEdit}
                  >
                    <RavenToolTip
                      color={`black-light`}
                      text={`Edit recipient ${trimLongString(three, 15)}`}
                      position={`left`}
                    />
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="img dull-bright-filter"
                    >
                      <path
                        d="M9.49998 15.0001H16.25M2.75 15H4.00591C4.37279 15 4.55624 15 4.72887 14.9586C4.88192 14.9219 5.02824 14.8613 5.16245 14.779C5.31382 14.6862 5.44354 14.5565 5.70296 14.2971L15.125 4.87505C15.7463 4.25373 15.7463 3.24637 15.125 2.62505C14.5037 2.00373 13.4963 2.00373 12.875 2.62505L3.45295 12.0471C3.19352 12.3065 3.0638 12.4362 2.97104 12.5876C2.8888 12.7218 2.82819 12.8681 2.79145 13.0212C2.75 13.1938 2.75 13.3773 2.75 13.7442V15Z"
                        // stroke="#020202"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </figure>
                  <figure
                    onClick={onDelete}
                    className="img-box delete-box darken-action-bg tooltip-hover-wrap"
                  >
                    <RavenToolTip
                      color={`black-light`}
                      text={`Delete recipient ${trimLongString(three, 15)}`}
                      position={`top-left`}
                    />
                    <svg
                      width="15"
                      height="18"
                      viewBox="0 0 15 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="img"
                    >
                      <path
                        d="M10.5 4.5V3.9C10.5 3.05992 10.5 2.63988 10.3365 2.31901C10.1927 2.03677 9.96323 1.8073 9.68099 1.66349C9.36012 1.5 8.94008 1.5 8.1 1.5H6.9C6.05992 1.5 5.63988 1.5 5.31901 1.66349C5.03677 1.8073 4.8073 2.03677 4.66349 2.31901C4.5 2.63988 4.5 3.05992 4.5 3.9V4.5M6 8.625V12.375M9 8.625V12.375M0.75 4.5H14.25M12.75 4.5V12.9C12.75 14.1601 12.75 14.7902 12.5048 15.2715C12.289 15.6948 11.9448 16.039 11.5215 16.2548C11.0402 16.5 10.4101 16.5 9.15 16.5H5.85C4.58988 16.5 3.95982 16.5 3.47852 16.2548C3.05516 16.039 2.71095 15.6948 2.49524 15.2715C2.25 14.7902 2.25 14.1601 2.25 12.9V4.5"
                        stroke="#FF0F00"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </figure>
                </>
              )}
            </div>
          ) : (
            <></>
          )}
          {editMode && showEditButton && (
            <div className="payroll-table-action-wrap">
              <RavenButton
                onClick={onEditFininsh}
                color="black-light"
                size="small"
                label="Save"
              />
            </div>
          )}
        </td>
        {/* action end */}
      </tr>
    </>
  );
};

export default ManualEditTable;
