/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
// import setAuthToken from "../utils/auth";
import { toast } from "@ravenpay/raven-bank-ui";

export const getSchedulePayments = createAsyncThunk(
  "admin/get-schedule-payments",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/schedule/records?per_page=${payload?.per_page || 10}&current_page=${
          payload?.current_page || 1
        }&date=${payload?.date || ""}`,
        payload
      );
      // console.log("register", data);

      if (data?.status === "fail") {
      }
      if (data?.status === "success") {
        // toast.success(data.message, {
        //   theme: "colored",
        // });
        await thunkAPI.dispatch(setSchedules(data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getShedulesByFilter = createAsyncThunk(
  "admin/get-schedule-filter",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSchedule = createAsyncThunk(
  "admin/get-schedule",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(`/schedule/record/${payload}`, payload);
      console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSchedulesPagination = createAsyncThunk(
  "admin/get-schedules-pagination",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const cancelSchedulePayment = createAsyncThunk(
  "admin/cancel-schedule-payment",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.delete("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const reschedulePayment = createAsyncThunk(
  "admin/re-schedule-payment",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `schedule/edit_record/${payload.id}`,
        payload
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        // console.log("register", data);
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log(data);
        toast.success(data.data.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const schedulePayment = createSlice({
  name: "schedule-payment",
  initialState: {
    schedules: [],
    schedule: {},
    isAuth: false,
    loading: false,
    loadingAction: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setSchedules: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.schedules = action.payload;
    },
  },
  extraReducers: {
    [getSchedulePayments.pending]: (state) => {
      state.loading = true;
    },
    [getSchedulePayments.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSchedulePayments.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSchedule.pending]: (state) => {
      state.loadingAction = true;
    },
    [getSchedule.fulfilled]: (state) => {
      state.loadingAction = false;
    },
    [getSchedule.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingAction = false;
      state.isAuth = false;
      state = null;
    },
    [reschedulePayment.pending]: (state) => {
      state.loading = true;
    },
    [reschedulePayment.fulfilled]: (state) => {
      state.loading = false;
    },
    [reschedulePayment.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, setSchedules } = schedulePayment.actions;

export default schedulePayment.reducer;
