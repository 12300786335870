// import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import DeleteModal from "../../../components/reusables/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
// import { deletePaymentLink } from "../../../redux/requestMoney";
import { deleteCustomer } from "../../../redux/customers";

const DeleteCustomerModal = ({ visible, onCancel, detail, onFinish }) => {
  const { loading } = useSelector((state) => state.paymentLinks);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const obj = {
      customer_id: String(detail?.id),
    };
    // console.log(detail);
    // return
    const data = await dispatch(deleteCustomer(obj));
    // console.log(data);
    if (data?.payload?.response?.data?.status === "fail") {
      onCancel();
    }
    if (data?.payload?.data?.status === "fail") {
      onCancel();
    }
    if (data?.payload?.data?.status === "success") {
      onFinish();
    }
  };
  return (
    <DeleteModal
      onDelete={handleSubmit}
      loading={loading}
      visible={visible}
      onCancel={onCancel}
      title={`Delete customer ( ${detail?.customer_name} )`}
      text={`Deleting this customer will remove customer details from customers for invoice.`}
    />
  );
};

export default DeleteCustomerModal;
