export const invoiceIcon = {
  noInvoice: (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.096 20.184C18.5164 20.2138 18.9348 20.2667 19.3493 20.3422C21.808 20.864 23.5698 22.216 24.2756 24.6978C24.5191 25.5511 24.5645 26.4622 24.6853 27.3493C24.7529 27.8373 25.0125 28.1289 25.4125 28.112C25.7849 28.0969 26.024 27.8018 26.0569 27.312C26.0809 26.9618 26.0898 26.6098 26.128 26.2604C26.3298 24.4524 26.9689 22.8587 28.4293 21.6862C29.6738 20.6898 31.1227 20.2764 32.6862 20.1822C33.184 20.152 33.4711 19.8969 33.4809 19.4898C33.4916 19.0684 33.1867 18.7902 32.6809 18.7476C32.3591 18.7209 32.0356 18.7058 31.7182 18.6507C29.8382 18.3262 28.2676 17.4951 27.2178 15.8418C26.4489 14.632 26.1662 13.2827 26.1387 11.8711C26.1298 11.4027 26.0276 11.008 25.5493 10.8107H25.2116C24.856 11.0142 24.6845 11.3093 24.672 11.7333C24.6582 12.2396 24.6107 12.7445 24.5298 13.2444C24.2267 15.1227 23.3627 16.6587 21.7165 17.6889C20.6205 18.3724 19.4107 18.6978 18.1245 18.7556C17.6213 18.7787 17.2951 19.0764 17.3049 19.4827C17.3147 19.8889 17.616 20.1369 18.096 20.184Z"
        fill="#C8C9C7"
      />
      <path
        d="M52.4448 30.7102C52.2969 30.6704 52.1458 30.6436 51.9932 30.6302C51.2012 30.52 50.3932 30.4684 49.619 30.2951C44.3879 29.128 41.1559 25.632 40.3861 20.3084C40.2537 19.3929 40.2279 18.4622 40.1559 17.5377C40.1177 17.0586 39.8421 16.7635 39.4315 16.7715C39.0066 16.7804 38.7648 17.104 38.7159 17.6506C38.6137 18.7955 38.5666 19.9537 38.3559 21.08C37.8786 23.6364 36.7506 25.8737 34.7532 27.5911C32.4359 29.5849 29.6866 30.4817 26.6643 30.6337C26.083 30.6631 25.7808 30.9146 25.7755 31.376C25.7701 31.8373 26.0732 32.0951 26.6448 32.1404C27.2875 32.1911 27.9363 32.2231 28.571 32.3315C30.9008 32.7324 33.0155 33.6231 34.7986 35.2089C37.1497 37.3049 38.203 40.0346 38.5426 43.0897C38.6261 43.8426 38.6315 44.6009 38.6981 45.36C38.731 45.7502 38.9852 45.9733 39.371 45.9822C39.7568 45.9911 40.0012 45.776 40.0732 45.3911C40.0957 45.2524 40.1105 45.1127 40.1177 44.9724C40.1826 44.1333 40.1995 43.2835 40.3186 42.4542C40.6741 39.9795 41.555 37.7271 43.2972 35.8844C45.347 33.7182 47.9372 32.6373 50.8457 32.24C51.331 32.1724 51.8235 32.1804 52.3141 32.1164C52.6697 32.0684 52.9532 31.8649 52.9826 31.472C53.0119 31.0791 52.8128 30.8266 52.4448 30.7102Z"
        fill="#272823"
      />
      <path
        d="M30.3841 42.6942C29.881 42.6444 29.3743 42.616 28.873 42.5333C25.3912 41.9546 22.8623 39.7866 22.0961 36.3111C21.8943 35.3991 21.873 34.4444 21.7654 33.5128C21.7165 33.0897 21.473 32.84 21.1058 32.8213C20.7156 32.8008 20.441 33.0382 20.3645 33.472C20.3477 33.5973 20.3377 33.7234 20.3343 33.8497C20.2996 34.3404 20.2907 34.8337 20.2258 35.3208C19.9352 37.5191 19.0703 39.4257 17.2978 40.832C15.7316 42.0764 13.8987 42.5866 11.9316 42.6897C11.4063 42.7173 11.1076 42.9626 11.0961 43.3822C11.0836 43.8177 11.393 44.0888 11.937 44.128C12.2587 44.152 12.5805 44.1804 12.9014 44.2168C16.6863 44.6533 19.3716 47.1333 20.0632 50.8773C20.2107 51.6773 20.2276 52.496 20.3094 53.3057C20.3494 53.6977 20.6285 53.9928 20.9841 53.9875C21.3672 53.9822 21.6152 53.7768 21.6952 53.3955C21.7164 53.2711 21.7301 53.1456 21.7361 53.0195C21.7947 52.3626 21.8045 51.6968 21.9183 51.0497C22.4392 48.0764 24.0134 45.92 26.8703 44.7848C27.8803 44.3936 28.948 44.1717 30.0303 44.128C30.6418 44.0968 30.945 43.824 30.9378 43.2053C30.9263 42.9484 30.7556 42.7315 30.3841 42.6942Z"
        fill="#272823"
      />
      <path
        d="M18.096 20.184C18.5164 20.2138 18.9348 20.2667 19.3493 20.3422C21.808 20.864 23.5698 22.216 24.2756 24.6978C24.5191 25.5511 24.5645 26.4622 24.6853 27.3493C24.7529 27.8373 25.0125 28.1289 25.4125 28.112C25.7849 28.0969 26.024 27.8018 26.0569 27.312C26.0809 26.9618 26.0898 26.6098 26.128 26.2604C26.3298 24.4524 26.9689 22.8587 28.4293 21.6862C29.6738 20.6898 31.1227 20.2764 32.6862 20.1822C33.184 20.152 33.4711 19.8969 33.4809 19.4898C33.4916 19.0684 33.1867 18.7902 32.6809 18.7476C32.3591 18.7209 32.0356 18.7058 31.7182 18.6507C29.8382 18.3262 28.2676 17.4951 27.2178 15.8418C26.4489 14.632 26.1662 13.2827 26.1387 11.8711C26.1298 11.4027 26.0276 11.008 25.5493 10.8107H25.2116C24.856 11.0142 24.6845 11.3093 24.672 11.7333C24.6582 12.2396 24.6107 12.7445 24.5298 13.2444C24.2267 15.1227 23.3627 16.6587 21.7165 17.6889C20.6205 18.3724 19.4107 18.6978 18.1245 18.7556C17.6213 18.7787 17.2951 19.0764 17.3049 19.4827C17.3147 19.8889 17.616 20.1369 18.096 20.184Z"
        fill="#C8C9C7"
      />
      <path
        d="M52.4448 30.7102C52.2969 30.6704 52.1458 30.6436 51.9932 30.6302C51.2012 30.52 50.3932 30.4684 49.619 30.2951C44.3879 29.128 41.1559 25.632 40.3861 20.3084C40.2537 19.3929 40.2279 18.4622 40.1559 17.5377C40.1177 17.0586 39.8421 16.7635 39.4315 16.7715C39.0066 16.7804 38.7648 17.104 38.7159 17.6506C38.6137 18.7955 38.5666 19.9537 38.3559 21.08C37.8786 23.6364 36.7506 25.8737 34.7532 27.5911C32.4359 29.5849 29.6866 30.4817 26.6643 30.6337C26.083 30.6631 25.7808 30.9146 25.7755 31.376C25.7701 31.8373 26.0732 32.0951 26.6448 32.1404C27.2875 32.1911 27.9363 32.2231 28.571 32.3315C30.9008 32.7324 33.0155 33.6231 34.7986 35.2089C37.1497 37.3049 38.203 40.0346 38.5426 43.0897C38.6261 43.8426 38.6315 44.6009 38.6981 45.36C38.731 45.7502 38.9852 45.9733 39.371 45.9822C39.7568 45.9911 40.0012 45.776 40.0732 45.3911C40.0957 45.2524 40.1105 45.1127 40.1177 44.9724C40.1826 44.1333 40.1995 43.2835 40.3186 42.4542C40.6741 39.9795 41.555 37.7271 43.2972 35.8844C45.347 33.7182 47.9372 32.6373 50.8457 32.24C51.331 32.1724 51.8235 32.1804 52.3141 32.1164C52.6697 32.0684 52.9532 31.8649 52.9826 31.472C53.0119 31.0791 52.8128 30.8266 52.4448 30.7102Z"
        fill="#272823"
      />
      <path
        d="M30.3841 42.6942C29.881 42.6444 29.3743 42.616 28.873 42.5333C25.3912 41.9546 22.8623 39.7866 22.0961 36.3111C21.8943 35.3991 21.873 34.4444 21.7654 33.5128C21.7165 33.0897 21.473 32.84 21.1058 32.8213C20.7156 32.8008 20.441 33.0382 20.3645 33.472C20.3477 33.5973 20.3377 33.7234 20.3343 33.8497C20.2996 34.3404 20.2907 34.8337 20.2258 35.3208C19.9352 37.5191 19.0703 39.4257 17.2978 40.832C15.7316 42.0764 13.8987 42.5866 11.9316 42.6897C11.4063 42.7173 11.1076 42.9626 11.0961 43.3822C11.0836 43.8177 11.393 44.0888 11.937 44.128C12.2587 44.152 12.5805 44.1804 12.9014 44.2168C16.6863 44.6533 19.3716 47.1333 20.0632 50.8773C20.2107 51.6773 20.2276 52.496 20.3094 53.3057C20.3494 53.6977 20.6285 53.9928 20.9841 53.9875C21.3672 53.9822 21.6152 53.7768 21.6952 53.3955C21.7164 53.2711 21.7301 53.1456 21.7361 53.0195C21.7947 52.3626 21.8045 51.6968 21.9183 51.0497C22.4392 48.0764 24.0134 45.92 26.8703 44.7848C27.8803 44.3936 28.948 44.1717 30.0303 44.128C30.6418 44.0968 30.945 43.824 30.9378 43.2053C30.9263 42.9484 30.7556 42.7315 30.3841 42.6942Z"
        fill="#272823"
      />
    </svg>
  ),
  inputChevron: (
    <svg
      width="16"
      height="32"
      viewBox="0 0 16 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10L8 6L4 10"
        stroke="#999999"
        strokeWidth="1.4"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 22L8 26L12 22"
        stroke="#999999"
        strokeWidth="1.4"
        strokeinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),

  add: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6124 19.9537C17.11 19.9537 20.7561 16.3077 20.7561 11.81C20.7561 7.31243 17.11 3.66638 12.6124 3.66638C8.11479 3.66638 4.46875 7.31243 4.46875 11.81C4.46875 16.3077 8.11479 19.9537 12.6124 19.9537Z"
        fill="#755AE2"
      />
      <path
        d="M12.6132 20.013C8.08982 20.013 4.41016 16.3334 4.41016 11.81C4.41016 7.28669 8.08982 3.60669 12.6132 3.60669C17.1365 3.60669 20.8165 7.28669 20.8165 11.81C20.8165 16.3334 17.1365 20.013 12.6132 20.013ZM12.6132 3.72569C8.15549 3.72569 4.52916 7.35236 4.52916 11.81C4.52916 16.2677 8.15549 19.894 12.6132 19.894C17.0708 19.894 20.6972 16.2667 20.6972 11.81C20.6972 7.35336 17.0708 3.72569 12.6132 3.72569Z"
        fill="#755AE2"
      />
      <path
        d="M12.185 16.883C12.1288 16.8269 12.0843 16.7603 12.0539 16.6869C12.0235 16.6136 12.0079 16.535 12.008 16.4556V7.1643C12.0061 7.08377 12.0203 7.00367 12.0498 6.92871C12.0793 6.85376 12.1235 6.78546 12.1798 6.72783C12.2361 6.6702 12.3034 6.62441 12.3776 6.59315C12.4518 6.56188 12.5316 6.54578 12.6121 6.54578C12.6927 6.54578 12.7724 6.56188 12.8467 6.59315C12.9209 6.62441 12.9882 6.6702 13.0445 6.72783C13.1008 6.78546 13.145 6.85376 13.1745 6.92871C13.204 7.00367 13.2182 7.08377 13.2163 7.1643V16.4556C13.2162 16.5751 13.1808 16.6919 13.1144 16.7912C13.048 16.8905 12.9537 16.9679 12.8433 17.0136C12.733 17.0593 12.6115 17.0713 12.4943 17.0481C12.3772 17.0248 12.2695 16.9674 12.185 16.883Z"
        fill="white"
      />
      <path
        d="M7.53996 12.2369C7.45555 12.1524 7.3981 12.0448 7.37484 11.9276C7.35159 11.8104 7.36358 11.689 7.40931 11.5786C7.45504 11.4682 7.53245 11.3739 7.63176 11.3075C7.73107 11.2411 7.84783 11.2057 7.96729 11.2056H17.2586C17.3392 11.2037 17.4193 11.2179 17.4942 11.2475C17.5692 11.277 17.6375 11.3212 17.6951 11.3775C17.7527 11.4337 17.7985 11.501 17.8298 11.5752C17.861 11.6495 17.8772 11.7292 17.8772 11.8098C17.8772 11.8903 17.861 11.9701 17.8298 12.0443C17.7985 12.1186 17.7527 12.1858 17.6951 12.2421C17.6375 12.2984 17.5692 12.3426 17.4942 12.3721C17.4193 12.4016 17.3392 12.4158 17.2586 12.4139H7.96729C7.88792 12.414 7.80931 12.3984 7.73598 12.368C7.66265 12.3377 7.59603 12.2931 7.53996 12.2369Z"
        fill="white"
      />
    </svg>
  ),
  cancel: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#FFDFDD" />
      <path
        d="M10 6L6 10M6 6L10 10"
        stroke="#FF0F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),

  minus: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6124 19.9537C17.11 19.9537 20.7561 16.3077 20.7561 11.81C20.7561 7.31243 17.11 3.66638 12.6124 3.66638C8.11479 3.66638 4.46875 7.31243 4.46875 11.81C4.46875 16.3077 8.11479 19.9537 12.6124 19.9537Z"
        fill="#755AE2"
      />
      <path
        d="M12.6132 20.013C8.08982 20.013 4.41016 16.3334 4.41016 11.81C4.41016 7.28669 8.08982 3.60669 12.6132 3.60669C17.1365 3.60669 20.8165 7.28669 20.8165 11.81C20.8165 16.3334 17.1365 20.013 12.6132 20.013ZM12.6132 3.72569C8.15549 3.72569 4.52916 7.35236 4.52916 11.81C4.52916 16.2677 8.15549 19.894 12.6132 19.894C17.0708 19.894 20.6972 16.2667 20.6972 11.81C20.6972 7.35336 17.0708 3.72569 12.6132 3.72569Z"
        fill="#755AE2"
      />
      <path
        d="M7.53996 12.2369C7.45555 12.1524 7.3981 12.0448 7.37484 11.9276C7.35159 11.8104 7.36358 11.689 7.40931 11.5786C7.45504 11.4682 7.53245 11.3739 7.63176 11.3075C7.73107 11.2411 7.84783 11.2057 7.96729 11.2056H17.2586C17.3392 11.2037 17.4193 11.2179 17.4942 11.2475C17.5692 11.277 17.6375 11.3212 17.6951 11.3775C17.7527 11.4337 17.7985 11.501 17.8298 11.5752C17.861 11.6495 17.8772 11.7292 17.8772 11.8098C17.8772 11.8903 17.861 11.9701 17.8298 12.0443C17.7985 12.1186 17.7527 12.1858 17.6951 12.2421C17.6375 12.2984 17.5692 12.3426 17.4942 12.3721C17.4193 12.4016 17.3392 12.4158 17.2586 12.4139H7.96729C7.88792 12.414 7.80931 12.3984 7.73598 12.368C7.66265 12.3377 7.59603 12.2931 7.53996 12.2369Z"
        fill="white"
      />
    </svg>
  ),
};
