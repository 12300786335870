import React from "react";
import {
  capitalizeFirstLetter,
//   formatDate,
  //   formatDateToRelativeTime,
} from "../../utils/helper/Helper";
import "../../styles/fragments/DateFragmentBox.css";
import moment from "moment";
import dayjs from "dayjs";
// import relativeTime from "dayjs/plugin/relativeTime";

const DateFragmentBox = ({
  date,
  dontShowTime,
  dontShowAgo,
  dontShowSmallDate,
  className,
  parentStyles,
}) => {

  function formatDate(date) {
    // console.log(date);
    // var actualDate = String(moment(date).format("MMMM, DD, YYYY  , HH:mm a"));
    var actualDate = String(moment(date).format("MMMM, DD, YYYY  — HH:mm a"));
    const splitDate = actualDate.split(",");
    // var newDate = splitDate[0].slice(0, 3) + "." + splitDate[1] + "," + (splitDate[2])?.replace("—", ",");
    var newDate =
      getOrdinalSuffix(splitDate[1]) +
      " " +
      splitDate[0].slice(0, 3) +
      "" +
      splitDate[2]?.replace("—", ",");
    return newDate;
    // const val = date ? formatTimeDifference(date) : null;
    // return val;
  }

  const dontShowTimeFunc = (param) => {
    if (param) {
      const val = param?.split(",");
      return `${val[0]}, ${val[1]}`;
    }
  };

  const formatDateToRelativeTime = (date) => {
    if (date) {
      return dayjs()?.to(dayjs(date)) || "";
    }
  };

  function getOrdinalSuffix(number) {
    if (number) {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = Number(number) % 100;
      return (
        removeLeadingZeros(number) +
        (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
      );
    }
  }

  function removeLeadingZeros(input) {
    // Convert the input to a number, which will remove any leading zeros

    if (input) {
      const number = Number(input);

      // Convert the number back to a string if needed
      return number.toString();
    }
  }

  return (
    <span
      style={parentStyles && parentStyles}
      className={`date-time-fragment-box-wrap ${className && className}`}
    >
      {date && (
        <>
          {!dontShowAgo && (
            <span className="date">
              {capitalizeFirstLetter(formatDateToRelativeTime(date))}
            </span>
          )}
          {!dontShowSmallDate && (
            <span className="date-two date-time-fragment-box-wrap-date-two  grey-color-white">
              {dontShowTime
                ? dontShowTimeFunc(formatDate(date))
                : formatDate(date)}
            </span>
          )}
        </>
      )}
    </span>
  );
};

export default DateFragmentBox;
