import { RavenButton, RavenCheckBox } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import {
  editPermissionOfCustomRole,
  getRolesAndPermissions,
  getSingleRolesAndPermissions,
} from "../../../redux/roleAndPermission";
import "../../../styles/dashboard/role-and-permission/SingleRoleAndPermissionIndex.css";
import { lowerCaseWrap, SPECIAL_ROLE_NAME } from "../../../utils/helper/Helper";
import TeamInfoModal from "./AddTeamMemberToRoleModal";
// import AddTeamMemberToRoleModal from "./AddTeamMemberToRoleModal";
import ChangeMemberFromTeamModal from "./RemoveMemberFromTeamModal";
import RemoveUserFromRoleTeamModal from "./RemoveTeamMemberFromRole";
// import RemoveMemberFromTeamModal from "./RemoveMemberFromTeamModal";

const SingleRoleAndPermissionIndex = () => {
  const { single_roles_and_users, loading, loadingRemove } = useSelector(
    (state) => state.role_and_permission
  );
  const { user, user_resource_and_permissions } = useSelector(
    (state) => state.user
  );
  // const [deletedUsers, setDeletedUsers] = useState([]);
  const [singleChild, setSingleChild] = useState("");
  const navigate = useNavigate();
  const [refreshContent, setRefreshContent] = useState(false);
  const [listOfPermission, setListOfPermission] = useState([]);
  const [showModal, setShowModal] = useState({
    remove: false,
    add: false,
    change: false,
  });
  const dispatch = useDispatch();

  const plusIcon = (
    <svg
      className="img dull-bright-filter"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M8.00016 3.33334V12.6667M3.3335 8.00001H12.6668"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  // const [permissionList, setPermissionList] = useState([""]);
  const [openRole, setOpenRole] = useState("");

  // const rolesAndPerList = [
  //   {
  //     role: "Accounts",
  //     permissions: [
  //       "Can create account",
  //       "Can view account",
  //       "Can download account statements",
  //     ],
  //   },
  //   {
  //     role: "Administration",
  //     permissions: [
  //       "Can create Administration",
  //       "Can view Administration",
  //       "Can download Administration statements",
  //     ],
  //   },
  //   {
  //     role: "Bills Payments",
  //     permissions: [
  //       "Can create Payments",
  //       "Can view Payments",
  //       "Can download Payments statements",
  //     ],
  //   },
  //   {
  //     role: "Payments & Transactions",
  //     permissions: [
  //       "Can create Payments & Transactions",
  //       "Can view Payments & Transactions",
  //       "Can download Payments & Transactions statements",
  //     ],
  //   },
  //   {
  //     role: "Invoices",
  //     permissions: [
  //       "Can create Invoices",
  //       "Can view Invoices",
  //       "Can download Invoices statements",
  //     ],
  //   },
  //   {
  //     role: "Payrolls",
  //     permissions: [
  //       "Can create Payrolls",
  //       "Can view Payrolls",
  //       "Can download Payrolls statements",
  //     ],
  //   },
  //   {
  //     role: "Payment links",
  //     permissions: [
  //       "Can create Payment links",
  //       "Can view Payment links",
  //       "Can download Payment links statements",
  //     ],
  //   },
  //   {
  //     role: "Payment Scheduling",
  //     permissions: [
  //       "Can create Payment Scheduling",
  //       "Can view Payment Scheduling",
  //       "Can download Payment Scheduling statements",
  //     ],
  //   },
  //   {
  //     role: "Terminals",
  //     permissions: [
  //       "Can create Terminals",
  //       "Can view Terminals",
  //       "Can download Terminals statements",
  //     ],
  //   },
  //   {
  //     role: "Settings",
  //     permissions: [
  //       "Can create Settings",
  //       "Can view Settings",
  //       "Can download Settings statements",
  //     ],
  //   },
  // ];

  const arrowIcon = (
    <svg
      className="img dull-bright-filter"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M6 12L10 8L6 4"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const [showEditButton, setShowEditButton] = useState(false);

  const handleFinish = () => {
    setShowModal((prev) => {
      return { remove: false, add: false };
    });
    setRefreshContent(!refreshContent);
  };

  useEffect(() => {
    // console.log(localStorage?.getItem("role-permission-single-name"));
    const obj = {
      role: localStorage?.getItem("role-permission-single-name"),
    };
    if (obj?.role) {
      dispatch(getSingleRolesAndPermissions(obj));
      dispatch(getRolesAndPermissions());
    } else {
      navigate("/dashboard-settings-role-permission");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshContent]);

  const [defaultArrayOfString, setDefaultArrayOfString] = useState([]);
  const [newArrayOfString, setNewArrayOfString] = useState([]);

  useEffect(() => {
    setListOfPermission(single_roles_and_users?.resources_and_permission_array);
    setDefaultArrayOfString(
      getListOfStringArrays(
        single_roles_and_users?.resources_and_permission_array
      )
    );
    if (
      single_roles_and_users?.role?.business_email &&
      single_roles_and_users?.role?.business_id
    ) {
      setShowEditButton(true);
    }
    // console.log(single_roles_and_users);
  }, [single_roles_and_users]);

  const getListOfStringArrays = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const arrayOfValues = list?.flatMap((parent) =>
        parent?.resources_roles_and_permission?.filter(
          (child) => child.permission === "allow" && child?.description
        )
      );
      const arrayOfStrings = arrayOfValues?.map((chi) => chi.description);
      // console.log(arrayOfValues);
      return arrayOfStrings;
    }
  };

  const handleUncheckAndChecked = (obj) => {
    // console.log(obj);
    // console.log(listOfPermission);
    const firstChildList = listOfPermission?.filter(
      (chi) => chi?.resource_object?.id === obj?.resource_id
    );
    const secondChildList =
      firstChildList?.length > 0 &&
      firstChildList[0]?.resources_roles_and_permission?.map((chi) =>
        chi?.action_id === obj?.action_id
          ? {
              ...chi,
              permission: chi?.permission === "allow" ? "deny" : "allow",
            }
          : chi
      );
    // console.log(secondChildList);
    const finalList = listOfPermission?.map((chi) =>
      chi?.resource_object?.id === obj?.resource_id
        ? { ...chi, resources_roles_and_permission: secondChildList }
        : chi
    );
    setNewArrayOfString(getListOfStringArrays(finalList));
    setListOfPermission(finalList);
  };

  function arraysContainSameValues(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false; // If the lengths are different, arrays can't be the same
    }

    return arr1.every((item) => arr2.includes(item));
  }

  const handleEditToSubmit = async () => {
    // console.log(listOfPermission);
    const combinedArray = listOfPermission?.flatMap(
      (obj) => obj.resources_roles_and_permission
    );
    const newArray = combinedArray?.map((chi) => {
      return {
        permission: chi?.permission,
        resource_id: String(chi?.resource_id),
        action_id: String(chi?.action_id),
      };
    });
    const obj = {
      role: single_roles_and_users?.role?.role_unique_name,
      permissions_settings: newArray,
    };
    // console.log(obj);
    // return
    const data = await dispatch(editPermissionOfCustomRole(obj));
    if (data?.payload?.data?.status === "success") {
      setRefreshContent(!refreshContent);
    }
  };

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Role & Permissions`}
          text={`Manage your roles and permission here`}
          back
          onBack={() => {
            // console.log(single_sub_account);
            navigate("/dashboard-settings-role-permission");
          }}
        />
        {/* title box end */}
        {/* container start */}
        <ContainerWrapper width={`100%`} dontShowHeader>
          {/* single wrap role start */}
          {loading ? (
            <div
              // className="box-wrap grey-bg"
              style={{
                display: "grid",
                placeItems: "center",
                minHeight: "45rem",
                width: "100%",
              }}
            >
              {" "}
              <NoContentBox
                loading={loading}
                title={`No Role Information found`}
                text="Create a role to view a role detail and it attached permissio "
              />
            </div>
          ) : (
            <div className="role-and-permission-single-wrap">
              {/* left container start */}
              <div className="left-container-wrap border-theme-right">
                <p className="section-title">
                  {lowerCaseWrap(single_roles_and_users?.role?.name) || "---"}
                </p>
                {/* test des start */}
                <div className="text-des-box border-theme-bottom">
                  <p className="text grey-white-color">Role description</p>
                  <p className="des grey-white-color">
                    {single_roles_and_users?.role?.description || "---"}
                  </p>
                </div>
                {/* text des end */}
                <p className="sub-text grey-white-color">
                  Team mate within this role
                </p>
                {/* team mate box start */}
                <div className="team-mate-box">
                  {single_roles_and_users?.users?.map((chi, idx) => {
                    const { lname, fname, removed } = chi;
                    if (removed === 1) {
                      return <React.Fragment key={idx}></React.Fragment>;
                    }
                    return (
                      <div key={idx} className="team-item border-theme-bottom">
                        <div
                          style={{ textTransform: "uppercase" }}
                          className="avatar-wrap black-fade-white-bg"
                        >
                          <span className="white-black-color">{`${fname?.charAt(
                            0
                          )} ${lname?.charAt(0)}`}</span>
                        </div>
                        <p className="name">{`${
                          lowerCaseWrap(fname) || "---"
                        } ${lowerCaseWrap(lname) || "---"}`}</p>
                        {chi?.clearance !== SPECIAL_ROLE_NAME && (
                          <div className="remove-change-box">
                            {user_resource_and_permissions?.allowed_permissions?.includes(
                              "can_change_team_member_role"
                            ) && (
                              <p
                                onClick={() => {
                                  setShowModal((prev) => {
                                    return { ...prev, change: true };
                                  });
                                  setSingleChild(chi);
                                  //   console.log(chi);
                                }}
                                className="change"
                              >
                                Change Role /
                              </p>
                            )}
                            {/* {chi?.clearance !== SPECIAL_ROLE_NAME && ( */}
                            {user_resource_and_permissions?.allowed_permissions?.includes(
                              "can_remove_team_member"
                            ) && (
                              <p
                                onClick={() => {
                                  setShowModal((prev) => {
                                    return { ...prev, remove: true };
                                  });
                                  setSingleChild(chi);
                                  // console.log(chi);
                                }}
                                className="remove"
                              >
                                Remove
                              </p>
                            )}
                            {/* )} */}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {single_roles_and_users?.pending_team_invites?.map(
                    (chi, idx) => {
                      const { last_name, first_name } = chi;
                      return (
                        <div
                          key={idx}
                          className="team-item border-theme-bottom"
                          style={{ opacity: ".2" }}
                        >
                          <div
                            style={{ textTransform: "uppercase" }}
                            className="avatar-wrap black-fade-white-bg"
                          >
                            <span className="white-black-color">{`${first_name?.charAt(
                              0
                            )} ${last_name?.charAt(0)}`}</span>
                          </div>
                          <p className="name">{`${
                            lowerCaseWrap(first_name) || "---"
                          } ${lowerCaseWrap(last_name) || "---"}`}</p>
                        </div>
                      );
                    }
                  )}

                  {user?.clearance === SPECIAL_ROLE_NAME && (
                    <>
                      {" "}
                      {single_roles_and_users?.users?.map((chi, idx) => {
                        const { lname, fname, removed } = chi;
                        if (removed === 0) {
                          return <React.Fragment key={idx}></React.Fragment>;
                        }
                        return (
                          <div
                            key={idx}
                            className="team-item border-theme-bottom"
                            style={{ opacity: ".2" }}
                          >
                            <div
                              style={{
                                textTransform: "uppercase",
                                // opacity: ".2",
                              }}
                              className="avatar-wrap black-fade-white-bg"
                            >
                              <span className="white-black-color">{`${fname?.charAt(
                                0
                              )} ${lname?.charAt(0)}`}</span>
                            </div>
                            <p className="name">{`${
                              lowerCaseWrap(fname) || "---"
                            } ${lowerCaseWrap(lname) || "---"}`}</p>
                            <div className="remove-badge">
                              <span>Removed</span>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                {/* team mate box end */}
                {/* invite box start */}
                {user_resource_and_permissions?.allowed_permissions?.includes(
                  "can_invite_new_team_member"
                ) && (
                  <div className="invite-label-box">
                    <p className="label">Invite someone new</p>
                    {/* add box start */}
                    <div
                      onClick={() => {
                        setShowModal((prev) => {
                          return { ...prev, add: true };
                        });
                      }}
                      className="add-box grey-bg"
                    >
                      <figure className="img-box">{plusIcon}</figure>
                      <p className="text">Add new member</p>
                    </div>
                    {/* add box end */}
                  </div>
                )}
                {/* invite box end */}
              </div>
              {/* left container end */}
              {/* right container start */}
              <div className="right-container-wrap">
                {/* permission role show box start */}
                <div className="first-child  border-theme-bottom">
                  {" "}
                  {single_roles_and_users?.resources_and_permission_array
                    ?.length > 0 && (
                    <div className="role-permission-show-box border-theme">
                      {listOfPermission?.map((chi, idx) => {
                        const {
                          resource_object,
                          resources_roles_and_permission,
                        } = chi;
                        return (
                          <div
                            key={idx}
                            style={{
                              paddingBottom:
                                openRole ===
                                  resource_object?.resource_unique_name &&
                                single_roles_and_users
                                  ?.resources_and_permission_array?.length -
                                  1 !==
                                  idx &&
                                "1.5rem",
                            }}
                            className="role-per-box border-theme-bottom"
                          >
                            {/* top start */}
                            <div
                              onClick={() => {
                                openRole ===
                                resource_object?.resource_unique_name
                                  ? setOpenRole("")
                                  : setOpenRole(
                                      resource_object?.resource_unique_name
                                    );
                              }}
                              style={{
                                borderTopLeftRadius: idx === 0 && "1.2rem",
                                borderTopRightRadius: idx === 0 && "1.2rem",
                              }}
                              className={`top-box ${
                                openRole ===
                                  resource_object?.resource_unique_name &&
                                "grey-bg"
                              }`}
                            >
                              <p className="role">
                                {lowerCaseWrap(resource_object?.name) ||
                                  lowerCaseWrap(
                                    resource_object?.resource_unique_name
                                  ) ||
                                  ""}
                              </p>
                              <figure
                                className={`img-box ${
                                  openRole ===
                                    resource_object?.resource_unique_name &&
                                  "img-box-up"
                                }`}
                              >
                                {arrowIcon}
                              </figure>
                            </div>
                            {/* top end */}
                            {/* bottom box start v */}
                            <div
                              className={`bottom-box ${
                                openRole ===
                                  resource_object?.resource_unique_name &&
                                "bottom-box-show"
                              }`}
                            >
                              <div className="all-permission-box">
                                {resources_roles_and_permission?.map(
                                  (chid, i) => {
                                    const { description, role, permission } =
                                      chid;
                                    return (
                                      <div
                                        key={`${role}-${description}-${i}`}
                                        className="permission-item"
                                      >
                                        <RavenCheckBox
                                          color="purple-light"
                                          key={`${role}-${description}-${i}`}
                                          id={`${role}-${description}-${i}`}
                                          checked={
                                            permission === "allow"
                                              ? true
                                              : false
                                          }
                                          style={{
                                            cursor:
                                              !single_roles_and_users?.role
                                                ?.business_email &&
                                              !single_roles_and_users?.role
                                                ?.business_id
                                                ? "not-allowed"
                                                : !user_resource_and_permissions?.allowed_permissions?.includes(
                                                    "can_edit_permissions_for_a_role"
                                                  )
                                                ? "not-allowed"
                                                : "pointer",
                                          }}
                                          onChange={() => {
                                            // console.log(chi);
                                            single_roles_and_users?.role
                                              ?.business_email &&
                                              single_roles_and_users?.role
                                                ?.business_id &&
                                              user_resource_and_permissions?.allowed_permissions?.includes(
                                                "can_edit_permissions_for_a_role"
                                              ) &&
                                              handleUncheckAndChecked(chid);
                                          }}
                                        />
                                        <p className="permission grey-white-color">
                                          {description || "---"}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                            {/* bottom box end */}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                {/* permission role show box end */}
                {/* edit button start */}
                {showEditButton &&
                newArrayOfString?.length > 0 &&
                single_roles_and_users?.role?.business_email &&
                single_roles_and_users?.role?.business_id &&
                !arraysContainSameValues(
                  defaultArrayOfString,
                  newArrayOfString
                ) ? (
                  <div className="edit-button-box">
                    <RavenButton
                      disabled={arraysContainSameValues(
                        defaultArrayOfString,
                        newArrayOfString
                      )}
                      loading={loadingRemove}
                      onClick={handleEditToSubmit}
                      className="edit-btn"
                      label="Save Edit Change"
                      color="black-light"
                    />
                  </div>
                ) : (
                  ""
                )}
                {/* edit button end */}
              </div>
              {/* right container end */}
            </div>
          )}
          {/* single wrap role send */}
        </ContainerWrapper>
        {/* container end */}
      </DashboardLayout>
      {/* modal start */}
      <ChangeMemberFromTeamModal
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, change: false };
          });
        }}
        visible={showModal?.change}
        detail={singleChild}
        onFinish={handleFinish}
      />
      {/* modal end */}
      {/* modal start */}
      <RemoveUserFromRoleTeamModal
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, remove: false };
          });
        }}
        visible={showModal?.remove}
        detail={singleChild}
        onFinish={handleFinish}
      />
      {/* modal end */}
      {/* modal start */}
      <TeamInfoModal
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, add: false };
          });
        }}
        visible={showModal?.add}
        detail={single_roles_and_users?.role}
        onFinish={handleFinish}
      />
      {/* modal end */}
    </>
  );
};

export default SingleRoleAndPermissionIndex;
