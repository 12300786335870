import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import {  useSelector } from "react-redux";
// import { downloadTransactionReciept } from "../../../../redux/transaction";
import "../../../../styles/dashboard/transaction/DashboardTransaction.css";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
} from "../../../../utils/helper/Helper";

const TransactionDetailModal = ({ onClose, visible, param, detail }) => {
  const { loadingDownload } = useSelector((state) => state.transaction);
//   const dispatch = useDispatch();
  const formatMetaData = (param) => {
    // console.log(param);
    if (param) {
      const obj = JSON?.parse(param);
      // console.log(obj);
      return obj ? obj : "";
    }
  };

//   const handleSubmit = async () => {
//     const obj = {
//       transaction_id: String(detail?.id),
//     };
//     const data = await dispatch(downloadTransactionReciept(obj));
//     if (data?.payload?.data?.status === "success") {
//       // setDownloadCsvId("");
//       downloadCsv(data?.payload?.data?.data?.url);
//       // downloadCsvId("");
//       // console.log(data);
//     }
//   };


  const formatmetaData = (param) => {
    if (param) {
      // console.log(param);
      const val = JSON?.parse(param);
      // console.log(val);
      return val ? val : "---";
    }
  };

  return (
    <RavenModal
      btnColor={`black-light`}
      visble={visible}
      btnLabel={`Cancel`}
      className="transaction-download-receipt-modal"
      onClose={onClose}
      onBtnClick={onClose}
      loading={loadingDownload}
      // dontShowClose={true}
    >
      <div className="wrap-modal">
        <p
          onClick={() => {
            formatmetaData(detail?.meta_data);
          }}
          className="title"
        >
          Transaction Details
        </p>
        <div className="underline"></div>
        {/* label-value-status box start */}
        <div className="label-value-status-box">
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Type</p>
            <div
              className={`status-type-wrap ${
                detail?.direction === "credit" && "status-type-wrap-success"
              } ${detail?.direction === "debit" && "status-type-wrap-fail"}`}
            >
              <span> {detail?.direction}</span>
            </div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Amount:</p>
            <p className="value">
              {formatNumWithCommaNairaSymbol(detail?.amount || 0)}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          {detail?.meta_data &&
            detail?.type !== "transfer_out" &&
            detail?.meta_data !==
              "system debit" && (
                <div className="box">
                  <p className="label grey-white-color">Card No:</p>
                  <p className="value">
                    {detail?.meta_data
                      ? `${formatMetaData(detail?.meta_data)?.card_pan?.slice(
                          0,
                          6
                        )}******`
                      : "----"}
                  </p>
                </div>
              )}
          {/* box end */}

          {/* box start */}
          {detail?.type === "transfer_out" && (
            <>
              <div className="box">
                <p className="label grey-white-color">Account Name:</p>
                <p className="value">
                  {detail?.meta_data
                    ? `${formatMetaData(detail?.meta_data)?.account_name}`
                    : "----"}
                </p>
              </div>
              <div className="box">
                <p className="label grey-white-color">Account No:</p>
                <p className="value">
                  {detail?.meta_data
                    ? `${formatMetaData(detail?.meta_data)?.account_number}`
                    : "----"}
                </p>
              </div>
              <div className="box">
                <p className="label grey-white-color">Bank:</p>
                <p className="value">
                  {detail?.meta_data
                    ? `${formatMetaData(detail?.meta_data)?.bank}`
                    : "----"}
                </p>
              </div>
            </>
          )}
          {/* box end */}

          <div className="box">
            <p className="label grey-white-color">Description</p>
            <p className="value">{detail?.type || "---"}</p>
          </div>
          {/* box end */}
          {/* type start ----------------- */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Transaction date </p>
            <p className="value">{formatDate(detail?.created_at) || "---"}</p>
          </div>
          {/* box end */}
          {/* box start */}
          {detail?.meta_data === "system debit" && (
            <div className="box">
              <p className="label grey-white-color">Info.</p>
              <p className="value">{detail?.meta_data}</p>
            </div>
          )}
          {/* box end */}
        </div>
        {/* label-value-status box end */}
      </div>
    </RavenModal>
  );
};

export default TransactionDetailModal;
