// import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../../components/reusables/DeleteModal";

const CardNinVerificationModal = ({ onClose, visible }) => {
  const navigate = useNavigate();
  return (
    <DeleteModal
      onDelete={() => {
        navigate("/dashboard-verification-bvn");
      }}
      visible={visible}
      onCancel={onClose}
      title={`Verify NIN`}
      deleteText={`Verify now`}
      buttonColor={`black-light`}
      text={`Before you can access card we need some details to proceed with your verification, we need your BVN and NIN details before proceed, click verify now to proceed.`}
    />
  );
};

export default CardNinVerificationModal;
