import { RavenInputField, RavenToolTip } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import TitletextBox from "../../../components/reusables/TitletextBox";
import ActionContext from "../../../context/ActionContext";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import { getPaymentLink } from "../../../redux/requestMoney";
import "../../../styles/dashboard/payment-links/PaymentLink.css";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
  reactSelectStyle,
  trimLongString,
} from "../../../utils/helper/Helper";
import DeletePaymentModal from "./DeletePaymentModal";
import DisablePaymentModal from "./DisablePaymentLink";
import SharePaymentLinkModal from "./SharePaymentLinkModal";

const NewPaymentLinkInfo = () => {
  const actionCtx = useContext(ActionContext);
  const [showModal, setShowModal] = useState({
    delete: false,
    disable: false,
    share: false,
  });
  const [showFilterOption, setShowFilterOption] = useState(false);
  const dispatch = useDispatch();
  const [refreshTable, setRefresTable] = useState(false);
  const navigate = useNavigate();
  const { loadingSingle } = useSelector((state) => state.paymentLinks);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const [typeFilter] = useState("");
  //   const [checkCustom, setCheckCustom] = useState(false);
  const [selectOptionVal, setSelectOptionVal] = useState({
    one: "",
    two: "",
  });
  const [paymentLink, setPaymentLink] = useState({});
  const selectOption = [
    { label: "All Time", value: "all" },
    { label: "Today", value: "today" },
    { label: "1 Week", value: "7_days" },
    { label: "2 Weeks", value: "14_days" },
    { label: "1 Month", value: "30_days" },
    { label: "3 Months", value: "90_days" },
    { label: "Custom", value: "custom" },
  ];

  const selectOptionTwo = [
    { label: "All Type", value: "all" },
    { label: "Money Sent", value: "Money Sent" },
    { label: "Money Received", value: "Money Received" },
    { label: "Money Reversed", value: "Money Reversed" },
    {
      label: "Failed Card Funding Reversed",
      value: "Failed Card Funding Reversed",
    },
    {
      label: "Failed Card Processing Charge Reversed",
      value: "Failed Card Processing Charge Reversed",
    },
    { label: "Transaction Charge", value: "Transaction Charge" },
    { label: "Data Recharge", value: "Data Recharge" },
    { label: "Cable Recharge", value: "Cable Recharge" },
    { label: "Electricity Payment", value: "Electricity Payment" },
    { label: "Bet Account Recharge", value: "Bet Account Recharge" },
    { label: "Virtual Card Charge", value: "Virtual Card Charge" },
    { label: "Physical Card Charge", value: "Physical Card Charge" },
    { label: "Virtual Card Funding", value: "Virtual Card Funding" },
    { label: "Virtual Card Debit", value: "Virtual Card Debit" },
    {
      label: "Virtual Card Processing Fee Refund",
      value: "Virtual Card Processing Fee Refund",
    },
  ];

  useEffect(() => {
    const id = localStorage.getItem("payment-link-single-id");
    getSinglePaymentLink(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinish = () => {
    setRefresTable(!refreshTable);
    // setPaymentLink({});
    setShowModal({
      delete: false,
      disable: false,
      share: false,
    });
  };

  const getSinglePaymentLink = async (id) => {
    const data = await dispatch(getPaymentLink(id));
    if (data?.payload?.data?.status === "success") {
      const obj = data?.payload?.data?.data;
      setPaymentLink(obj);
      //   console.log(obj);
    }
  };
  //   const [transaaa, setTransac] = useState([""]);

  const [isCopied, setIsCopied] = useState(false);
  const handleCopied = async () => {
    setIsCopied(true);
    const hrefUrl = window.location.href;
    const splitUrl = hrefUrl.split("/dashboard-payment")[0];
    const finalUrl = `${splitUrl}/payment-details?ref=${paymentLink?.linkDetail?.reference}`;
    await navigator.clipboard.writeText(finalUrl);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          paymenlinkSettingStatus={paymentLink?.linkDetail?.status}
          paymenlinkSettings={Object?.keys(paymentLink).length > 0}
          back
          title={`Link Information`}
          text={`This is the information about this payment link`}
          onBack={() => {
            actionCtx?.setCheckShow("close");
            navigate(-1);
          }}
          onLinkAction={(e) => {
            actionCtx?.setCheckShow("close");
            // console.log(e);
            if (e === "Edit Link") {
              localStorage?.setItem(
                "payment-link-id",
                paymentLink?.linkDetail?.id
              );
              navigate("/dashboard-payment-links-request-money-edit");
            }
            if (e === "Share Link") {
              setShowModal((prev) => {
                return { ...prev, share: true };
              });
            }

            if (e === "Delete Link") {
              setShowModal((prev) => {
                return { ...prev, delete: true };
              });
            }
            if (e === "Disable Link" || e === "Enable Link") {
              setShowModal((prev) => {
                return { ...prev, disable: true };
              });
            }
          }}
        />
        {/* container start */}
        <ContainerWrapper dontShowHeader width={`100%`}>
          <div
            onClick={() => {
              actionCtx?.setCheckShow("close");
              //   console.log(paymentLink);
            }}
            className="payment-link-detail-info-wrap"
          >
            {/* left box start */}
            <div className="left-box border-theme-right">
              {loadingSingle ? (
                <Skeleton width={170} height={15} />
              ) : (
                <p
                  onClick={() => {
                    //   console.log(paymentLink);
                  }}
                  className="title"
                  style={{ textTransform: "capitalize" }}
                >
                  {paymentLink?.linkDetail?.title || "---"}
                </p>
              )}
              {/* .info-copy-wrap start */}
           {user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_share_payment_links"
                    ) &&   <div className="info-copy-wrap border-theme-bottom">
                <div className="img-wrap"></div>
                <div className="text-link-box">
                  <div className="img-icon-link">
                    <div className="box grey-bg">
                      <figure className="img-box">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="img"
                        >
                          <path
                            d="M10.3327 6.33333V4.33333C10.3327 2.49238 8.8403 1 6.99935 1C5.1584 1 3.66602 2.49238 3.66602 4.33333V6.33333M4.86602 13H9.13268C10.2528 13 10.8128 13 11.2407 12.782C11.617 12.5903 11.9229 12.2843 12.1147 11.908C12.3327 11.4802 12.3327 10.9201 12.3327 9.8V9.53333C12.3327 8.41323 12.3327 7.85318 12.1147 7.42535C11.9229 7.04903 11.617 6.74307 11.2407 6.55132C10.8128 6.33333 10.2528 6.33333 9.13268 6.33333H4.86602C3.74591 6.33333 3.18586 6.33333 2.75803 6.55132C2.38171 6.74307 2.07575 7.04903 1.884 7.42535C1.66602 7.85318 1.66602 8.41323 1.66602 9.53333V9.8C1.66602 10.9201 1.66602 11.4802 1.884 11.908C2.07575 12.2843 2.38171 12.5903 2.75803 12.782C3.18586 13 3.74591 13 4.86602 13Z"
                            stroke="#676767"
                            strokeWidth="1.36239"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </figure>
                      {loadingSingle ? (
                        <Skeleton
                          width={170}
                          height={15}
                          style={{ marginLeft: "1rem" }}
                        />
                      ) : (
                        <p className="text grey-white-color">
                          {trimLongString(paymentLink?.linkDetail?.link, 26) ||
                            "----"}
                        </p>
                      )}{" "}
                      <figure
                        style={{ cursor: "pointer", marginLeft: "auto" }}
                        onClick={() =>
                          setShowModal((prev) => {
                            return { ...prev, share: true };
                          })
                        }
                        className="img-box tooltip-hover-wrap"
                      >
                        <RavenToolTip
                          color="black-light"
                          text="Share payment link"
                          position={`top-left`}
                        />
                        <svg
                          className="img"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.7155 4.44943L11.7155 0.816406M11.7155 0.816406H8.08246M11.7155 0.816406L6.87145 5.66044M5.05494 2.02742H3.72283C2.70549 2.02742 2.19681 2.02742 1.80824 2.2254C1.46644 2.39956 1.18855 2.67745 1.01439 3.01925C0.816406 3.40782 0.816406 3.91649 0.816406 4.93384V8.80907C0.816406 9.82641 0.816406 10.3351 1.01439 10.7237C1.18855 11.0655 1.46644 11.3433 1.80824 11.5175C2.19681 11.7155 2.70549 11.7155 3.72283 11.7155H7.59806C8.6154 11.7155 9.12407 11.7155 9.51265 11.5175C9.85444 11.3433 10.1323 11.0655 10.3065 10.7237C10.5045 10.3351 10.5045 9.82641 10.5045 8.80907V7.47696"
                            stroke="#676767"
                            strokeWidth="1.36239"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </figure>
                      {/* </a> */}
                    </div>
                    <div onClick={handleCopied} className="copy-box">
                      <figure className="img-box border-theme grey-bg">
                        {isCopied ? (
                          <div className="icon-box">
                            <FaCheck className="icon" />
                          </div>
                        ) : (
                          <svg
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="img"
                          >
                            <path
                              d="M5.77563 6.27514V4.3678C5.77563 3.6048 5.77563 3.22329 5.92412 2.93186C6.05474 2.67551 6.26316 2.4671 6.51951 2.33648C6.81094 2.18799 7.19244 2.18799 7.95545 2.18799H13.1325C13.8955 2.18799 14.277 2.18799 14.5685 2.33648C14.8248 2.4671 15.0332 2.67551 15.1638 2.93186C15.3123 3.22329 15.3123 3.6048 15.3123 4.3678V9.54487C15.3123 10.3079 15.3123 10.6894 15.1638 10.9808C15.0332 11.2372 14.8248 11.4456 14.5685 11.5762C14.277 11.7247 13.8955 11.7247 13.1325 11.7247H11.2252M3.86829 15.8118H9.04536C9.80837 15.8118 10.1899 15.8118 10.4813 15.6634C10.7376 15.5327 10.9461 15.3243 11.0767 15.068C11.2252 14.7765 11.2252 14.395 11.2252 13.632V8.45496C11.2252 7.69195 11.2252 7.31045 11.0767 7.01902C10.9461 6.76267 10.7376 6.55425 10.4813 6.42364C10.1899 6.27514 9.80837 6.27514 9.04536 6.27514H3.86829C3.10529 6.27514 2.72378 6.27514 2.43235 6.42364C2.176 6.55425 1.96758 6.76267 1.83697 7.01902C1.68848 7.31045 1.68848 7.69195 1.68848 8.45496V13.632C1.68848 14.395 1.68848 14.7765 1.83697 15.068C1.96758 15.3243 2.176 15.5327 2.43235 15.6634C2.72378 15.8118 3.10529 15.8118 3.86829 15.8118Z"
                              stroke="#676767"
                              strokeWidth="1.36"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </figure>
                    </div>
                  </div>
                  {loadingSingle ? (
                    <Skeleton width={200} height={15} />
                  ) : (
                    <p className="text grey-white-color">
                      Click to{" "}
                      <span
                      // onClick={() =>
                      //   navigate(
                      //     `/payment-details?ref=${paymentLink?.linkDetail?.reference}`
                      //   )
                      // }
                      >
                        <a
                          target="_blank"
                          rel="noreferrer"
                          style={{ cursor: "pointer", textDecoration: "none" }}
                          href={`/payment-details?ref=${paymentLink?.linkDetail?.reference}`}
                        >
                          {" "}
                          preview
                        </a>
                      </span>{" "}
                      the payment widget
                    </p>
                  )}
                </div>
              </div>}
              {/* detail value label start */}
              <div className="detail-label-value-box">
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Amount Requested: </p>
                  {loadingSingle ? (
                    <Skeleton width={120} height={15} />
                  ) : (
                    <p className="value">
                      {formatNumWithCommaNairaSymbol(
                        paymentLink?.linkDetail?.request_amount || "0"
                      ) || "---"}
                    </p>
                  )}
                </div>
                {/* box end total_amount_received */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Total Received:</p>
                  {loadingSingle ? (
                    <Skeleton width={120} height={15} />
                  ) : (
                    <p className="value">
                      {formatNumWithCommaNairaSymbol(
                        paymentLink?.linkDetail?.total_amount_received || "0"
                      ) || "---"}
                    </p>
                  )}
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Link Type: </p>
                  {loadingSingle ? (
                    <Skeleton width={120} height={15} />
                  ) : (
                    <p className="value">
                      {paymentLink?.linkDetail?.recurring === 1
                        ? "Reccuring Payment"
                        : "One time Payment"}
                    </p>
                  )}
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Date created : </p>
                  {loadingSingle ? (
                    <Skeleton width={120} height={15} />
                  ) : (
                    <p className="value">
                      {formatDate(paymentLink?.linkDetail?.created_at)}
                    </p>
                  )}
                </div>
                {/* box end */}
                {/* box start */}
                {paymentLink?.linkDetail?.recurring === 1 && (
                  <div className="box">
                    <p className="label grey-white-color">Link duration : </p>
                    {loadingSingle ? (
                      <Skeleton width={120} height={15} />
                    ) : (
                      <p className="value">
                        {paymentLink?.linkDetail?.expiry_date ? (
                          <span>
                            {" "}
                            {
                              formatDate(
                                paymentLink?.linkDetail?.expiry_date
                              )?.split("-")[0]
                            }
                          </span>
                        ) : (
                          "----"
                        )}
                      </p>
                    )}
                  </div>
                )}
                {/* box end */}
                {/* box start  */}
                <div className="box">
                  <p className="label grey-white-color">Description: </p>
                  {loadingSingle ? (
                    <Skeleton width={120} height={15} />
                  ) : (
                    <p className="value" style={{ textTransform: "uppercase" }}>
                      {paymentLink?.linkDetail?.request_description}
                    </p>
                  )}
                </div>
                {/* box end */}
              </div>
              {/* detail value label end */}
            </div>
            {/* left box end */}
            {/* right box start */}
            <div className="right-box">
              {paymentLink?.linkTransactions?.length > 0 ? (
                <div className="transaction-info-wrap">
                  {/* top box startv */}
                  <div className="top-box border-theme-bottom">
                    <div className="title-text-box">
                      <div className="title-box">
                        <p className="title">Transactions</p>
                        <p className="num black-fade-white-bg">
                          <span className="">
                            {paymentLink?.linkTransactions?.length || "0"}
                          </span>
                        </p>
                      </div>
                      <p className="lebel-text grey-white-color">
                        Payment received through this link
                      </p>
                    </div>
                    {/* filter-drop box start */}
                    <div className="filter-drop-box">
                      {/* drop box start */}
                      {showFilterOption && (
                        <div className="drop-box-wrap  card-bg shadow-shadow-box-shadow">
                          <RavenInputField
                            selectOption={selectOption}
                            label={`Filter By Transaction date`}
                            color={`black-light`}
                            type={"select"}
                            placeholder={`Transaction date`}
                            selectStyles={reactSelectStyle}
                            value={selectOptionVal?.one}
                            onChange={(e) => {
                              setSelectOptionVal((prev) => {
                                return { ...prev, one: e };
                              });
                              showFilterOption && setShowFilterOption(false);
                              //   setCheckCustom(true);
                            }}
                            selectValue={selectOptionVal?.one}
                          />
                          <RavenInputField
                            selectOption={selectOptionTwo}
                            color={`black-light`}
                            type={"select"}
                            label={`Filter By Transaction type`}
                            placeholder={`Transaction type`}
                            selectStyles={reactSelectStyle}
                            value={selectOptionVal?.two}
                            onChange={(e) => {
                              setSelectOptionVal((prev) => {
                                return { ...prev, two: e };
                              });
                              showFilterOption && setShowFilterOption(false);
                              //   setCheckCustom(false);
                            }}
                            selectValue={typeFilter}
                          />
                        </div>
                      )}
                      {/* drop box end */}
                      {/* filter box start */}
                      {/* <div
                        className="filter-box grey-bg"
                        onClick={() => {
                          setShowFilterOption(!showFilterOption);
                          // console.log("g");
                        }}
                      >
                        <p className="text grey-white-color-white">Filter</p>
                        <div className="line-box">
                          <span className=""></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div> */}
                      {/* filter box end */}
                      {/* drop box start */}
                      <div className="drop-filter-wrap"></div>
                      {/* drop box end */}
                    </div>
                    {/* filter-drop box end */}
                  </div>
                  {/* top box end */}
                  {/* bottom detail start */}
                  <div className="bottom-detail-box">
                    {/* transaction row start */}
                    {paymentLink?.linkTransactions?.map((chi, idx) => {
                      const {
                        id,
                        customer_email,
                        customer_fname,
                        customer_lname,
                        status,
                        created_at,
                        amount,
                      } = chi;
                      if (status === 0) {
                        return <React.Fragment key={id}></React.Fragment>;
                      }
                      return (
                        <div
                          className={`trans-row ${
                            idx !== paymentLink?.linkTransactions?.length - 1 &&
                            "border-theme-bottom"
                          }`}
                          key={idx || id}
                        >
                          <div className="avatar-box grey-bg">
                            <span className="black-white-color">{`${
                              customer_fname?.split("")[0]
                            }`}</span>
                            <span className="black-white-color">{`${
                              customer_lname?.split("")[0]
                            }`}</span>
                          </div>
                          <div className="name-email-box">
                            {loadingSingle ? (
                              <Skeleton width={170} height={15} />
                            ) : (
                              <p className="name">{`${customer_fname} ${customer_lname}`}</p>
                            )}
                            {loadingSingle ? (
                              <Skeleton width={100} height={9} />
                            ) : (
                              <p className="email grey-white-color">
                                {trimLongString(customer_email, 25)}
                              </p>
                            )}
                          </div>
                          <div className="date-amount-box">
                            {loadingSingle ? (
                              <Skeleton width={120} height={15} />
                            ) : (
                              <p
                                style={{ color: status === 0 && "#EA872D" }}
                                className="amount"
                              >
                                {formatNumWithCommaNairaSymbol(amount)}
                              </p>
                            )}
                            {loadingSingle ? (
                              <Skeleton width={120} height={9} />
                            ) : (
                              <p className="date grey-white-color">
                                {formatDate(created_at)}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    {/* transaction row end */}
                  </div>
                  {/* bottom detail end */}
                </div>
              ) : (
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    minHeight: "100%",
                  }}
                  className=""
                >
                  {" "}
                  <NoContentBox
                    loading={loadingSingle}
                    title={`No Transactions`}
                    text={`No payment received from this link yet.`}
                  />
                </div>
              )}
            </div>
            {/* right box end */}
          </div>
        </ContainerWrapper>
        {/* container end */}
      </DashboardLayout>
      {/* delete modal start */}
      <DeletePaymentModal
        detail={paymentLink?.linkDetail}
        visible={showModal.delete}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, delete: false };
          });
        }}
        onFinish={() => {
          navigate("/dashboard-payment-links");
        }}
      />
      {/* delete modal end */}
      {/* delete modal start */}
      <DisablePaymentModal
        detail={paymentLink?.linkDetail}
        visible={showModal.disable}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, disable: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* delete modal end */}
      {/* delete modal start */}
      <SharePaymentLinkModal
        detail={paymentLink?.linkDetail}
        visible={showModal.share}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, share: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* delete modal end */}
    </>
  );
};

export default NewPaymentLinkInfo;
