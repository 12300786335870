import React from "react";
import ReactDOM from "react-dom/client";
import "../src/styles/index.css";
import "../src/styles/customIndex.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ActionContextProvider } from "./context/ActionContext";
// import { RavenToast } from "@ravenpay/raven-bank-ui";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ActionContextProvider>
      <App />
    </ActionContextProvider>
    {/* <RavenToast  /> */}
  </BrowserRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
