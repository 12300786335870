// import PublicPreviewInvoice from "../../pages/dashboard/invoice/PublicPreviewInvoice";
import PublicPreviewInvoiceVersionTwo from "../../pages/dashboard/invoice/PublicPreviewInvoiceVersionTwo";
// import PaymentWebPayIndex from "../../pages/payment-web-pay";
import PaymentLinkVersionTwoIndex from "../../pages/payment-web-pay/PaymentLinkVersionTwo";
import RefereeInvitePublic from "../../pages/referee-invite/RefereeInvitePublic";
// import PublicPreviewInvoiceVersionTwo from "../../pages/dashboard/invoice/PublicPreviewInvoiceVersionTwo";
// import PaymentLinkVersionTwoIndex from "../../pages/payment-web-pay/PaymentLinkVersionTwo";


export const payment_web_pay_routes_group = [
    // {path: "/payment-details", element: <PaymentWebPayIndex />},
    // {path: "/invoice-preview", element: <PublicPreviewInvoice />},
    {path: "/payment-details", element: <PaymentLinkVersionTwoIndex />},
    {path: "/invoice-preview", element: <PublicPreviewInvoiceVersionTwo />},
    {path: "/referee-invite-register", element: <RefereeInvitePublic />},
]

// PublicPreviewInvoiceVersionTwo