import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext } from "react";
import ActionContext from "../../context/ActionContext";
import { reactSelectStyle } from "../../utils/helper/Helper";

const PerPageComponent = ({ onChange }) => {
  const actionCtx = useContext(ActionContext);
  const options = [
    // { label: "2", value: 2 },
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "250", value: 250 },
  ];
  return (
    <>
      <form action="" autoComplete="off" className="per-page-component-wrap">
        <RavenInputField
          color="black-light"
          value={actionCtx?.perPageVal}
          type={`select`}
          selectStyles={reactSelectStyle}
          selectOption={options}
          onChange={(e) => {
            actionCtx.setPerPage(e);
            onChange && onChange(e);
          }}
        />
      </form>
    </>
  );
};

export default PerPageComponent;
