import React from "react";
import NocontentPage from "../../../components/reusables/NocontentPage";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import noContentImg from "../../../assets/store-no-content-img.png";

const DashboardInvoiceFinance = () => {
  return (
    <DashboardLayout>
      <NocontentPage
        img={noContentImg}
        type="two"
        title={`Invoice finance is coming soon 🔥`}
        normtext={`With Raven Invoice finance, you'll have access to a variety of features and tools to customise your store and make it truly unique.`}
      />
    </DashboardLayout>
  );
};

export default DashboardInvoiceFinance;
