import {
  RavenCheckBox,
  RavenInputField,
  RavenModal,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDirectorBvn,
  getDirectorShareholders,
} from "../../../../redux/settings";
import "../../../../styles/dashboard/pos/DashboardPosIndex.css";
import { ThreeDots } from "react-loader-spinner";
import useDebounce from "../../../../utils/helper/UseDebounce";
import { validateBvn, validateNin } from "../../../../redux/card";
import { removeSpace } from "../../../../utils/helper/Helper";

const ViewDirectorModal = ({ visible, onClose, detail }) => {
  const [showBvn, setShowBvn] = useState(false);
  const dispatch = useDispatch();
  const { loadingStatement } = useSelector((state) => state.settings);
  // const { loadingNin } = useSelector((state) => state.cards);
  const [details, setDetails] = useState({
    bvn: "",
    card_name: "",
    nin: "",
    nin_name: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async () => {
    const obj = {
      director_id: String(detail?.id),
      bvn: details?.bvn,
      nin: details?.nin,
    };
    if (!isWordPresent(details?.card_name, detail?.name)) {
      toast.error("Director name and BVN name does not match");
      return;
    }
    if (!isWordPresent(details?.nin_name, detail?.name)) {
      toast.error("Director name and NIN name does not match");
      return;
    }

    const data = await dispatch(addDirectorBvn(obj));
    if (data?.payload?.data?.status === "success") {
      // onFinish()
      setDetails((prev) => {
        return { ...prev, bvn: "", card_name: "", nin: "", nin_name: "" };
      });
      dispatch(getDirectorShareholders());
      onClose();
    }
  };
  const [loadingBvn, setLoadingBvn] = useState(false);
  const [loadingNin, setLoadingNin] = useState(false);
  const handleValidateBvn = async (param) => {
    // console.log(param);
    setLoadingBvn(true);
    const data = await dispatch(validateBvn(param));
    if (data?.payload?.data?.status === "success") {
      // console.log(data);
      setLoadingBvn(false);
      const details = data?.payload?.data?.data;
      setDetails((prev) => {
        return {
          ...prev,
          card_name: `${details?.firstname} ${details?.lastname} `,
        };
      });
    } else {
      setLoadingBvn(false);
      setDetails((prev) => {
        return { ...prev, card_name: "" };
      });
    }
  };

  const handleValidateNin = async (param) => {
    // console.log(param);
    setLoadingNin(true);
    const data = await dispatch(validateNin(param));
    if (data?.payload?.data?.status === "success") {
      setLoadingNin(false);
      // console.log(data);
      const details = data?.payload?.data?.data;
      setDetails((prev) => {
        return {
          ...prev,
          nin_name: `${details?.firstname} ${details?.lastname} `,
        };
      });
    } else {
      setDetails((prev) => {
        setLoadingNin(false);
        return { ...prev, nin_name: "" };
      });
    }
  };

  const debouncedSearchTerm = useDebounce(details?.bvn, 1000);
  useEffect(() => {
    let isMount = true;

    if (
      isMount &&
      removeSpace(debouncedSearchTerm)?.length > 10 &&
      !details?.card_name
    ) {
      const obj = {
        bvn: details?.bvn,
      };
      handleValidateBvn(obj);
      //   console.log(obj);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const debouncedSearchTermTwo = useDebounce(details?.nin, 1000);
  useEffect(() => {
    let isMount = true;

    if (
      isMount &&
      removeSpace(debouncedSearchTermTwo)?.length > 10 &&
      !details?.nin_name
    ) {
      const obj = {
        nin: details?.nin,
      };
      handleValidateNin(obj);
      //   console.log(obj);
    }

    if (isMount && debouncedSearchTermTwo?.length < 1) {
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTermTwo]);

  function isWordPresent(string1, string2) {
    const words1 = string1.split(" ");
    const words2 = string2.split(" ");

    for (let i = 0; i < words1.length; i++) {
      if (words2.includes(words1[i])) {
        return true;
      }
    }

    return false;
  }

  return (
    <RavenModal
      visble={visible}
      onClose={() => {
        setDetails((prev) => {
          return { ...prev, bvn: "", card_name: "", nin: "", nin_name: "" };
        });
        onClose();
      }}
      btnColor={`black-light`}
      btnLabel={detail?.bvn_verified === 1 ? "Close" : `Update profile`}
      disabled={
        detail?.bvn_verified !== 1
          ? details?.bvn?.length < 9 ||
            !details?.card_name ||
            !details?.nin ||
            !details?.nin_name
          : false
      }
      onBtnClick={() => {
        if (detail?.bvn_verified === 1) {
          onClose();
          setDetails((prev) => {
            return { ...prev, bvn: "" };
          });
        } else {
          handleSubmit();
        }
      }}
      loading={detail?.bvn_verified !== 1 && loadingStatement}
    >
      <div className="cashier-assign-modal-wrap cashier-assign-modal-wrap-settings">
        <p className="title">Manage Director</p>
        <div className="underline"></div>

        {/* detail text box setting-director view box start */}
        <div className="detail-tex-setting-director-box">
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Dir. Name</p>
            <p className="value">{detail?.name || "---"}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Dir. Email</p>
            <p className="value">{detail?.email || "---"}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Phone number</p>
            <p className="value">{detail?.phone_number || "---"}</p>
          </div>
          {/* box end */}
          {/* box start */}
          {detail?.bvn_verified === 1 && (
            <div className="box">
              <p className="label grey-white-color">Bvn</p>
              <div className="value">
                <p className="num">
                  {showBvn ? detail?.bvn : "•••••••••••••••••"}{" "}
                </p>
                <span
                  onClick={() => {
                    setShowBvn(!showBvn);
                  }}
                >
                  SHOW
                </span>
              </div>
            </div>
          )}
          {/* box end */}
        </div>
        {/* detail text box setting-director view box end */}

        {/* form wrap start */}
        {detail?.bvn_verified !== 1 && (
          <div className="form-inputs-wrap">
            <RavenInputField
              color={`black-light`}
              type={`number`}
              label={`BVN number`}
              placeholder={`10 digit BVN number`}
              name={`bvn`}
              onChange={(e) => {
                setDetails((prev) => {
                  return { ...prev, card_name: "" };
                });
                handleChange(e);
              }}
              labelColor={`purple-light`}
              labelSpanText={
                loadingBvn ? (
                  <div
                    style={{
                      padding: "0rem",
                      display: "flex",
                      justifyContent: "flex-end",
                      transform: "translateX(1.5rem)",
                    }}
                    className="load-wrap"
                  >
                    <ThreeDots
                      height="10"
                      width="80"
                      radius="9"
                      color="#020202"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  "Verify BVN"
                )
              }
              value={details?.bvn}
            />
            {/* verify name box start */}
            <div
              className={`verify-name-box ${
                details?.card_name && "verify-name-box-show"
              }`}
            >
              <RavenCheckBox
                checked={true}
                color={`black-light`}
                className={`check`}
              />
              <p className="text">{details?.card_name}</p>
            </div>
            {/* verify name box end */}
            <RavenInputField
              color={`black-light`}
              type={`number`}
              label={`NIN number`}
              placeholder={`NIN number`}
              name={`nin`}
              onChange={(e) => {
                setDetails((prev) => {
                  return { ...prev, nin_name: "" };
                });
                handleChange(e);
              }}
              labelColor={`purple-light`}
              labelSpanText={
                loadingNin ? (
                  <div
                    style={{
                      padding: "0rem",
                      display: "flex",
                      justifyContent: "flex-end",
                      transform: "translateX(1.5rem)",
                    }}
                    className="load-wrap"
                  >
                    <ThreeDots
                      height="10"
                      width="80"
                      radius="9"
                      color="#020202"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  "Verify NIN"
                )
              }
              value={details?.nin}
            />
            {/* verify name box start */}
            <div
              className={`verify-name-box ${
                details?.nin_name && "verify-name-box-show"
              }`}
            >
              <RavenCheckBox
                checked={true}
                color={`black-light`}
                className={`check`}
              />
              <p className="text">{details?.nin_name}</p>
            </div>
            {/* verify name box end */}
          </div>
        )}
        {/* form wrap end */}
      </div>
    </RavenModal>
  );
};

export default ViewDirectorModal;
