import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useContext, useState } from "react";
import "../../../../styles/dashboard/payroll/PayrollIndex.css";
import infoIcon from "../../../../assets/info-icon-black.svg";
import { useNavigate } from "react-router-dom";
import { formatNumWithoutCommaNaira } from "../../../../utils/helper/Helper";
import ActionContext from "../../../../context/ActionContext";

const AddDeductionModal = ({
  visible,
  onCancel,
  detail,
  setDeductionIncrementObj,
}) => {
  const navigate = useNavigate();
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    amount: "",
    reason: "",
    type: "",
    id: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async () => {
    const obj = {
      amount: formatNumWithoutCommaNaira(details?.amount),
      reason: details?.reason,
      type: detail?.deduction_type,
      id: String(detail?.id),
    };
    setDeductionIncrementObj(obj);
    setDetails({
      amount: "",
      reason: "",
      type: "",
      id: "",
    })
  };

  return (
    <RavenModal
      disabled={!details?.amount || !details?.reason}
      effect={`fadeInUp`}
      onClose={onCancel}
      visble={visible}
      btnLabel={
        detail?.deduction_type === "addition"
          ? `Add Increment`
          : `Add Deduction`
      }
      btnColor={`black-light`}
      onBtnClick={handleSubmit}
    >
      <div className="deduction-payroll-modal-wrap">
        <p className="title">
          {detail?.deduction_type === "addition"
            ? `Add Increment`
            : `Add deductions`}
        </p>
        <p className="text grey-white-color">
          {detail?.deduction_type === "addition"
            ? `Add a certain amount to your employees salary.`
            : ` Remove a certain amount from your employees salary.`}
        </p>
        {/* input detail wrap start */}
        <div className="input-detail-wrap border-theme-top">
          <RavenInputField
            color={`black-light`}
            label={
              detail?.deduction_type === "addition"
                ? `Amount to add*`
                : `Amount to deduct*`
            }
            placeholder={`E.g 1500`}
            type={`number`}
            thousandFormat
            value={details?.amount}
            name={`amount`}
            onChange={handleChange}
          />
          <RavenInputField
            color={`black-light`}
            label={
              detail?.deduction_type === "addition"
                ? `Reason for increment`
                : ` Reason for deduction* `
            }
            placeholder={
              detail?.deduction_type === "addition"
                ? `State a reason for increment`
                : `State a reason for deduction`
            }
            type={`text`}
            value={details?.reason}
            name={`reason`}
            onChange={handleChange}
          />

          {/* permanent edit box start here  */}
          <div className="permanent-edit-box">
            <figure className="img-box">
              <img src={infoIcon} alt="" className="img" />
            </figure>
            <p className="text grey-white-color">
              {` Note that this ${
                detail?.deduction_type === "addition"
                  ? "increment"
                  : "deduction"
              }  only affect this current payroll, to
              effect this change across all payroll you can change employee
              salary`}{" "}
              <span
                onClick={() => {
                  //   console.log(detail);
                  actionCtx?.setEmployeeEmail(detail?.employee);
                  navigate("/dashboard-payroll-employee-profile-edit");
                }}
              >
                here
              </span>
            </p>
          </div>
          {/* permanent edit box end here */}
        </div>
        {/* input detail wrap end */}
      </div>
    </RavenModal>
  );
};

export default AddDeductionModal;
