import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import SuccessModal from "../../../../components/reusables/SuccessModal";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";
import ActionContext from "../../../../context/ActionContext";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import { withinSubAccountTransfer } from "../../../../redux/transaction";
import { getAllAccounts, getUser } from "../../../../redux/user";
import "../../../../styles/dashboard/send-money/SendMoneyBank.css";
import {
  filterEmptyColumns,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  lowerCaseWrap,
  reactSelectStyle,
} from "../../../../utils/helper/Helper";
import NewPreviewDetailBox from "./NewPreviewDetailBox";
import useWindowSize from "../../../../utils/helper/UseWindowSize";

const DashboardWithinAccount = () => {
  const [fromDate, setFromDate] = useState("");
  const { all_account } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [step, setStep] = useState(1);
  const stepList = ["Transfer detail", "Confirm Transactions"];
  const [details, setDetails] = useState({
    username: "",
    amount: "",
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    schedule: "",
    transfer_pin: "",
    template_title: "",
    save_template: false,
    sender_account: "",
    receiver_account: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async (pin, token) => {
    const obj = {
      source_account_number: details?.sender_account?.account_number,
      destination_account_number: details?.receiver_account?.account_number,
      amount: formatNumWithoutCommaNaira(details?.amount),
      narration: details?.narration,
      transfer_pin: pin,
      two_factor_token: token,
      schedule: fromDate ? "1" : "0",
      schedule_time: fromDate ? fromDate : "",
      template_title: details?.save_template ? details?.template_title : "",
      selected_account_number: actionCtx?.currentAccount?.account_number,
    };
    // console.log(obj);
    // return
    const data = await dispatch(withinSubAccountTransfer(filterEmptyColumns(obj)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return { ...prev, success: true, pin: false };
    });
    dispatch(getUser());
    dispatch(getAllAccounts());
    setStep(1);
    setFromDate("");
    setDetails({
      username: "",
      amount: "",
      narration: "Sent from raven",
      bulk_list: [],
      beneficiary: false,
      schedule: "",
      transfer_pin: "",
      template_title: "",
      save_template: false,
    });
    navigate("/dashboard-overview");
  };

  const showLabelFormat = (name, acc_num, amount, showBorder) => {
    return (
      <div
        style={{
          cursor: "pointer",
          //   paddingBottom: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: ".3rem",
        }}
        className={`name-account-box`}
      >
        <p
          style={{ fontWeight: 600, fontSize: "1.5rem", color: "#020202" }}
          className="name"
        >
          {name}
        </p>
        <div
          style={{ display: "flex", alignItems: "center", gap: ".6rem" }}
          className="wrap-box"
        >
          <p className="account_num grey-white-color">{acc_num}</p> <p>•</p>
          <p className="account_num grey-white-color">
            {formatNumWithCommaNairaSymbol(amount)}
          </p>
        </div>
      </div>
    );
  };

  const formatOptionOne = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi, idx) => {
        return {
          ...chi,
          label: showLabelFormat(
            lowerCaseWrap(chi?.custom_account_name),
            chi?.account_number,
            chi?.balance,
            idx !== list?.length - 1 ? true : false
          ),
          value: chi?.account_number,
        };
      });
      return newList;
    }
  };

  const formatOptionTwo = (list) => {
    if (list?.length > 0) {
      const newList = list?.filter(
        (chi) => chi?.value !== details?.sender_account?.value
      );
      return newList;
    }
  };

  const formatOptionThree = (list) => {
    if (list?.length > 0) {
      const newList = list?.filter((chi) => chi?.balance > 0);
      return newList;
    }
  };

  useEffect(() => {
    if (actionCtx?.currentAccount?.balance > 0) {
      const newObj = {
        ...actionCtx?.currentAccount,
        label: actionCtx?.currentAccount?.custom_account_name,
        value: actionCtx?.currentAccount?.account_number,
      };
      setDetails((prev) => {
        return { ...prev, sender_account: newObj };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatObjValue = (param) => {
    return { ...param, label: param?.custom_account_name };
  };

  const size = useWindowSize()

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Transfer within your account`}
          text={`Transfers to another account amongst your sub accounts.`}
          back
          onBack={() => {
            navigate(`/dashboard-send-money`);
          }}
        />
        {/* container wraper start */}
        <ContainerWrapper
          back={step > 1}
          onBack={() => {
            if (step > 1) {
              setStep(step - 1);
            }
          }}
          disabled={
            !details?.sender_account ||
            !details?.receiver_account ||
            !details?.amount ||
            !details?.narration
          }
          width={size.width < 900 && "90%"}
          stepList={stepList}
          activeStep={step}
          btnText={step === 1 ? `Proceed to confirm` : `Complete Transfer`}
          onBtnClick={() => {
            if (step === 1) {
              setStep(step + 1);
            }
            if (step === 2) {
              if (user?.pin_status === 0) {
                actionCtx.setCheckShow("nine");
              } else {
                setShowModal((prev) => {
                  return { ...prev, pin: true };
                });
              }
            }
          }}
        >
          {/* container wrapper start */}
          <div className="transfer-send-within-account-index-wrap">
            {/* step 0ne start ------------------ */}
            {step === 1 && (
              <>
                {/* form group star */}
                <form
                  style={{ width: "100%", position: "relative", zIndex: 21 }}
                  action=""
                  autoComplete="off"
                >
                  <RavenInputField
                    type="select"
                    selectStyles={reactSelectStyle}
                    label="Sender account* "
                    placeholder="e.g main account"
                    color="black-light"
                    //   getOptionValue
                    selectOption={formatOptionThree(
                      formatOptionOne(all_account)
                    )}
                    value={formatObjValue(details?.sender_account)}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, sender_account: e };
                      });
                    }}
                  />
                </form>
                {/* form group end */}
                {/* form group star */}
                <form
                  style={{ width: "100%", position: "relative", zIndex: 20 }}
                  action=""
                  autoComplete="off"
                >
                  <RavenInputField
                    disabled={!details?.sender_account}
                    selectStyles={reactSelectStyle}
                    type="select"
                    label="Receiver account* "
                    placeholder="e.g main account"
                    color="black-light"
                    selectOption={formatOptionTwo(formatOptionOne(all_account))}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, receiver_account: e };
                      });
                    }}
                    value={formatObjValue(details?.receiver_account)}
                  />
                </form>
                {/* form group end */}
                {/* form group star */}
                <form style={{ width: "100%" }} action="" autoComplete="off">
                  {" "}
                  <RavenInputField
                    type={`number`}
                    color={`black-light`}
                    label={`Amount*`}
                    name={`amount`}
                    value={details?.amount}
                    onChange={handleChange}
                    placeholder={`How much are you sending`}
                    labelColor={`black-light`}
                    labelSpanText={
                      actionCtx?.showAmount
                        ? `Bal: ${formatNumWithCommaNairaSymbol(
                            details?.sender_account?.balance
                          )}`
                        : "Bal: ₦ ••••••"
                    }
                    labelClassName={`label-span-amount label-span-theme`}
                    thousandFormat
                    numberPrefix={`₦`}
                    onActionClick={() => {
                      actionCtx?.setShowAmount(!actionCtx?.showAmount);
                    }}
                  />
                </form>
                {/* form group end */}
                {/* form group star */}
                <form style={{ width: "100%" }} action="" autoComplete="off">
                  <RavenInputField
                    type="text"
                    label="Narration* "
                    placeholder="e.g sent from raven"
                    color="black-light"
                    name="narration"
                    onChange={handleChange}
                    value={details?.narration}
                  />
                </form>
                {/* form group end */}
              </>
            )}
            {/* step 0ne end ------------------ */}
            {/* step two start ------------------ */}
            {step === 2 && (
              <>
                <div style={{ paddingBottom: "10rem" }} className="">
                  {" "}
                  <NewPreviewDetailBox
                    subAccount={details?.sender_account}
                    setSubAccount={(e) =>
                      setDetails((prev) => {
                        return { ...prev, sender_account: e };
                      })
                    }
                    onReset={() => setFromDate("")}
                    valueSchedule={fromDate}
                    onChangeSchedule={(e) => {
                      setFromDate(e);
                    }}
                    imgCode={details?.bank?.value}
                    value={details?.beneficiary}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, beneficiary: e.target.checked };
                      });
                    }}
                    sender_account={
                      details?.sender_account?.custom_account_name
                    }
                    receiver_account={
                      details?.receiver_account?.custom_account_name
                    }
                    account_name={details?.username}
                    account_num={details?.account_num}
                    bank={details?.bank?.label}
                    amount={details?.amount}
                    name={details?.username}
                    narration={details?.narration}
                    checked={details?.beneficiary}
                  />
                </div>
              </>
            )}
            {/* step two end ------------------ */}
          </div>
          {/* container wrapper end */}
        </ContainerWrapper>
        {/* container wraper end------- */}
      </DashboardLayout>
      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Transaction Successful."
        text={`You should also receive a confirmation email with the details of the transaction. We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default DashboardWithinAccount;
