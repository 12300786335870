import React, { useEffect } from "react";
import "../../styles/auth/AuthlayoutOne.css";
import ravenLogo from "../../assets/raven-logo-full-black.svg";
import StepBox from "../../components/reusables/StepBox";
// import ChatIcon from "../../assets/chat-icon-img.svg";
// import LockBox from "../../components/fragments/LockBox";
// import Intercom from 'intercom-client';
import videoClip from "../../assets/trimmed_onboarding.mp4";
// import videoClip from "../../assets/raven_business_sign_up_video (1).mp4";
import { useLocation, useNavigate } from "react-router-dom";
import { genCaptchaKeyAsync } from "../../components/reusables/ReCaptchaFile";

const AuthLayoutOne = ({ children, activeStep, onStep }) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard-overview");
    }
    genCaptchaKeyAsync("6Lfbo54nAAAAAEUGhpgYHJY7DvpS_e7JoUt2Na4d");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const intercom = new Intercom.Client({ token: 'YOUR_APP_ID' });
  //   intercom.boot({
  //     app_id: 'YOUR_APP_ID',
  //     // Add any other options here
  //   });
  // }, []);

  // const handleClick = () => {
  //   const intercom = new Intercom.Client({ token: 'YOUR_APP_ID' });
  //   intercom.trackEvent('Clicked Button', { buttonId: 'my-button' });
  // }

  const infoIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15940_68964)">
        <path
          d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6024 18.3332 10C18.3332 5.39765 14.6022 1.66669 9.99984 1.66669C5.39746 1.66669 1.6665 5.39765 1.6665 10C1.6665 14.6024 5.39746 18.3334 9.99984 18.3334Z"
          fill="#EA872D"
        />
        <path
          d="M9.99984 6.66669V10M9.99984 13.3334H10.0082M18.3332 10C18.3332 14.6024 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6024 1.6665 10C1.6665 5.39765 5.39746 1.66669 9.99984 1.66669C14.6022 1.66669 18.3332 5.39765 18.3332 10Z"
          stroke="#FFF6ED"
          strokeWidth="1.43293"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15940_68964">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <div className="auth-layout-one-wrap">
      <div className="header-wrap">
        <div className="size-wrap">
          {/* logo wrap start */}
          <div
            onClick={() => {
              navigate("/dashboard-overview");
            }}
            className="logo-wrap"
          >
            <figure className="img-box">
              {/* <a
                style={{ textDecoration: "none", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
                href="https://getravenbank.com/raven-business"
              >
                {" "} */}
              <img src={ravenLogo} alt="" className="img" />
              {/* </a> */}
            </figure>
          </div>
          {/* logo wrap end */}
          {/* security tip start */}
          <div className="security-tip-wrap">
            <div className="img-wrap">
              <figure className="img-box">{infoIcon}</figure>
            </div>
            <p style={{ marginLeft: "-1rem" }} className="text">
              Ensure the url you visit is{" "}
              <span>
                <a href="https://business.getravenbank.com">
                  https://business.getravenbank.com
                </a>{" "}
              </span>{" "}
              , Beware of scams and malicious link that stores your password for
              use. Confirm if this url is{" "}
            </p>
          </div>
          {/* security tip end */}
          {/* step box start */}
          {!location.pathname?.includes("/team/register") && (
            <div className="step-wrap">
              <StepBox
                onStep={onStep}
                activeStep={activeStep}
                list={["Authentication details", "Business details"]}
              />
            </div>
          )}
          {/* step box end */}
        </div>
      </div>
      {/* header wrap end */}
      <div className="content-wrap">
        <div className="size-wrap">
          <div className="left-box">
            <div className="wrap">{children}</div>
            {/* tip for mobile start */}
            <div className="mobile-security-tip">
              <div className="security-wrap">
                {/* <LockBox text={`Security tip`} className="security-box" /> */}
                {/* security box end */}
                <p className="text">
                  Ensure the url you visit is{" "}
                  <span>
                    <a href="https://business.getravenbank.com">
                      https://business.getravenbank.com
                    </a>{" "}
                  </span>
                  , Beware of scams and malicious link that stores your password
                  for use.
                </p>
              </div>
            </div>
            {/* tip for mobile end */}
          </div>
          <div className="right-box">
            {/* security tip start */}
            <div className="security-tip-wrap">
              <div className="img-wrap">
                <figure className="img-box">{infoIcon}</figure>
              </div>
              <p style={{ marginLeft: "-1rem" }} className="text">
                Ensure the url you visit is{" "}
                <span>
                  <a href="https://business.getravenbank.com">
                    https://business.getravenbank.com
                  </a>{" "}
                </span>{" "}
                , Beware of scams and malicious link that stores your password
                for use. Confirm if this url is{" "}
              </p>
            </div>
            {/* security tip end */}
            {/* security wrap end */}

            {/* <div className="img-content">

            </div> */}
            <div className="video-background">
              <video
                className="video"
                width={`100%`}
                height={`100%`}
                autoPlay
                loop
                muted
              >
                <source src={videoClip} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
      {/* content wrap end */}
      <div className="footer-wrap">
        <div className="size-wrap">
          {/* <figure className="img-box">
            <img src={ChatIcon} alt="" className="img" />
          </figure> */}
        </div>
      </div>
    </div>
  );
};

export default AuthLayoutOne;
