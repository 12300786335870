import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import "../../../styles/dashboard/sub-account/AddSubAccountIdex.css";
import { RavenCheckBox, RavenInputField } from "@ravenpay/raven-bank-ui";
// import profileManageImg from "../../../assets/profileManagerImg.svg";
import useDebounce from "../../../utils/helper/UseDebounce";
import NewPreviewDetailBox from "./NewPreviewDetailBox";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  createSubAccount,
  getSubAccountCreateDetails,
} from "../../../redux/sub_account";
import {
  formatTypeFunction,
  lowerCaseWrap,
  reactSelectStyle,
  SPECIAL_ROLE_NAME,
  trimLongString,
} from "../../../utils/helper/Helper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import { getAllAccounts } from "../../../redux/user";
import { FaAngleLeft } from "react-icons/fa";

const AddSubAccountIdex = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const { subAccoutDetailList, loading, loadingTeamUpdate } = useSelector(
    (state) => state.sub_account
  );
  const stepList = ["Account Details", "Authorization Level", "Confirm"];
  const size = useWindowSize();
  const [details, setDetails] = useState({
    custom_account_name: "",
    currency: "Naira",
    purpose: "",
    managers: [],
    selected_roles: [SPECIAL_ROLE_NAME],
    authorized_users: [],
    primary: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  //   const profileImg =

  //   const [managerListShow, setmanagerListShow] = useState(managerList);

  const [search, setSearch] = useState("");
  //   const navigate = useNavigate
  function searchByName(param) {
    const lowerCaseValue = param.toLowerCase();
    const compareFunction = (a, b) => {
      const keysToSearch = ["lname", "clearance", "fname", "label"];
      //  console.log(details?.authorized_users);
      const aContainsSearchValue = keysToSearch.some((key) =>
        a[key].toLowerCase().includes(lowerCaseValue)
      );
      const bContainsSearchValue = keysToSearch.some((key) =>
        b[key].toLowerCase().includes(lowerCaseValue)
      );

      if (aContainsSearchValue && !bContainsSearchValue) {
        return -1; // Move a before b
      } else if (!aContainsSearchValue && bContainsSearchValue) {
        return 1; // Move b before a
      } else {
        return 0; // Leave the order unchanged
      }
    };

    const sortedArray = details?.authorized_users?.sort(compareFunction);
    return sortedArray;
  }

  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;
    if (isMount && debouncedSearchTerm?.length > 2) {
      const newList = searchByName(search);
      setDetails((prev) => {
        return { ...prev, authorized_users: newList };
      });
    }

    if (isMount && debouncedSearchTerm?.length < 2) {
      //   setRavenName("");
      checkUserToShow(details?.selected_roles);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const checkUserToShow = (list) => {
    const filteredObjects = subAccoutDetailList?.users?.filter((obj) => {
      const lowerCaseName = obj?.clearance?.toLowerCase();
      return list?.some((filter) =>
        lowerCaseName?.includes(filter?.toLowerCase())
      );
    });
    const checkForLabelAndValue = filteredObjects?.map((chi) => {
      return chi?.clearance === SPECIAL_ROLE_NAME
        ? {
            ...chi,
            label: formatTypeFunction("initiator_approver"),
            value: "initiator_approver",
          }
        : {
            ...chi,
            label: chi?.label || "None",
            value: chi?.value || "none",
          };
    });
    setDetails((prev) => {
      return { ...prev, authorized_users: checkForLabelAndValue };
    });
    // setListToShow(resultArrayOrdered);
  };

  const handleAddRolesToList = (param) => {
    // console.log(param);
    const checkList = details?.selected_roles.some((chi) => chi === param);
    // console.log(checkList);
    if (checkList) {
      const newList = details?.selected_roles.filter((chi) => chi !== param);
      setDetails((prev) => {
        return { ...prev, selected_roles: newList };
      });
      checkUserToShow(newList);
    } else {
      const newList = [...details?.selected_roles, param];
      setDetails((prev) => {
        return { ...prev, selected_roles: newList };
      });
      checkUserToShow(newList);
    }
  };

  useEffect(() => {
    dispatch(getSubAccountCreateDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   useEffect(() => {
  //     console.log(subAccoutDetailList);
  //   }, [subAccoutDetailList]);

  const formAuthLevel = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const filterList = list?.filter((chi) => chi !== "verifier");
      const newList = filterList?.map((chi) => {
        return {
          ...chi,
          value: chi,
          label: lowerCaseWrap(formatTypeFunction(chi)),
        };
      });
      return newList;
    }
  };

  const handleAddUserToList = (param, id) => {
    const newList = details?.authorized_users?.map((chi) =>
      chi?.user_id === id
        ? {
            ...chi,
            user_id: id,
            auth_level: param,
            label: formatTypeFunction(lowerCaseWrap(param)),
            value: param,
          }
        : chi
    );
    setDetails((prev) => {
      return { ...prev, authorized_users: newList };
    });
  };

  const handleSubmit = async () => {
    const obj = {
      currency: "NGN",
      custom_account_name: details?.custom_account_name,
      purpose: details?.purpose,
      selected_roles: details?.selected_roles,
      authorized_users: details?.authorized_users?.map((chi) => {
        return { user_id: chi?.user_id, auth_level: chi?.value };
      }),
      primary: details?.primary ? "1" : "0",
    };
    const data = await dispatch(createSubAccount(obj));
    // console.log(obj);
    if (data?.payload?.data?.status === "success") {
      // console.log(data);
      navigate("/dashboard-settings-sub-account");
      dispatch(getAllAccounts());
    }
  };

  const [showExtra, setShwoExtra] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [showExtraWrap, setShwoExtraWrap] = useState(true);

  const cancelIcon = (
    <svg
      className="img"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M9 3L3 9M3 3L9 9"
        stroke="#676767"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const authListInfo = [
    {
      name: "Initiator Approver",
      text: "As an initiator approver you have access to initiating any payment transactions on the application like Transfer Bills & Payrolls, you also have access to complete those transactions without a third party approvers",
    },
    {
      name: "Approver",
      text: "As an approver you have access to only approve payment transactions on the application like Transfer Bills & Payrolls, but you cannot initiate this payments yourself",
    },
    {
      name: "Initiator",
      text: "As an initiator you can initiate a transaction that requires a payment but you cannot approve such payment yourself. you need to have at least one person approve such payment.",
    },
    {
      name: "None",
      text: "Anyone with the None Authorisation level cannot and will not the eligible to make or approve payments.",
    },
  ];


  const arrangeListFunc = (list) => {
    if (list?.length > 0) {
      const targetValue = SPECIAL_ROLE_NAME;
    //   console.log(list);
      // Filter out the object with the specified value
      const ownerObject = list.find((obj) => obj === targetValue);
      const filteredObjects = list.filter(
        (obj) => obj !== targetValue
      );

      // Add the ownerObject to the beginning of the array
      const newArray = ownerObject ? [ownerObject, ...filteredObjects] : list;
      return newArray;
    }
  };


  const arrangeListTwoFunc = (list) => {
    if (list?.length > 0) {
      const targetValue = SPECIAL_ROLE_NAME;
    //   console.log(list);
      // Filter out the object with the specified value
      const ownerObject = list.find((obj) => obj?.clearance === targetValue);
      const filteredObjects = list.filter(
        (obj) => obj?.clearance !== targetValue
      );

      // Add the ownerObject to the beginning of the array
      const newArray = ownerObject ? [ownerObject, ...filteredObjects] : list;
      return newArray;
    }
  };


  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Add Sub-Account`}
          text={`Add new account to manage other payments`}
          back
          onBack={() => {
            navigate(-1);
          }}
        />
        {/* title box end */}
        {/* container wrapper start */}
        <ContainerWrapper
          width={size.width < 900 ? `90%` : step === 2 ? "100%" : ""}
          activeStep={step}
          stepList={stepList}
          loading={loading}
          stepClickable={
            step === 1
              ? details?.custom_account_name &&
                details?.purpose &&
                details?.selected_roles?.length > 0
              : step === 2
              ? details?.authorized_users?.length > 0
              : false
          }
          onStepClick={(e) => {
            details?.custom_account_name &&
              details?.purpose &&
              details?.selected_roles?.length > 0 &&
              setStep(e);
          }}
          disabled={
            step === 1
              ? !details?.custom_account_name ||
                !details?.purpose ||
                details?.selected_roles?.length < 1
              : step === 2
              ? details?.authorized_users?.length < 1
              : false
          }
          btnText={
            step === 1
              ? "Proceed Authorization Level"
              : step === 2
              ? "Proceed to confirm"
              : "Create Sub-Account"
          }
          back={step > 1}
          onBack={() => {
            if (step > 1) {
              setStep(step - 1);
            }
          }}
          onBtnClick={() => {
            if (step === 1) {
              checkUserToShow(details?.selected_roles);
            }
            if (step !== 3) {
              setStep(step + 1);
              // console.log(details);
            }
            if (step === 3) {
              handleSubmit();
            }
          }}
        >
          <div
            onClick={() => {
              //   console.log(subAccoutDetailList);
            }}
            className="add-sub-account-index-wrap"
          >
            {step === 1 && (
              <div className="form">
                {" "}
                {/* step 1 start -------------------------- */}
                {step === 1 && (
                  <>
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      <RavenInputField
                        value={details?.custom_account_name}
                        color={`black-light`}
                        placeholder={`E.g Timi Ayeni`}
                        label={`Account Title* `}
                        onChange={handleChange}
                        type={`text`}
                        name={`custom_account_name`}
                      />
                    </form>
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      <RavenInputField
                        value={details?.purpose}
                        color={`black-light`}
                        placeholder={`E.g For finance`}
                        label={`Account Purpose`}
                        onChange={handleChange}
                        type={`text`}
                        name={`purpose`}
                      />
                    </form>
                    {/* select currency  start */}
                    <div className="select-wrap">
                      <p className="select-title">
                        Which role can access this account.
                      </p>
                      {/* select box start */}
                      {loadingTeamUpdate &&
                      subAccoutDetailList?.roles?.length < 1 ? (
                        <>
                          <NoContentBox
                            title={`No roles  found`}
                            text={`Click on the create button below to create a role.`}
                            loading={loadingTeamUpdate}
                            btnText="Create role"
                          />
                        </>
                      ) : (
                        <>
                          {subAccoutDetailList?.roles?.length > 0 ? (
                            <div className="select-box">
                              {arrangeListFunc(subAccoutDetailList?.roles)?.map((chi, idx) => {
                                return (
                                  <div
                                    key={idx}
                                    className={`select-role-item ${
                                      subAccoutDetailList?.roles?.length - 1 !==
                                        idx && "border-theme-bottom"
                                    }`}
                                  >
                                    <p className="role">{chi}</p>
                                    <div
                                      className={`check-wrap ${
                                        chi?.toLowerCase() ===
                                          SPECIAL_ROLE_NAME &&
                                        "check-wrap-disabled"
                                      }`}
                                    >
                                      {" "}
                                      <RavenCheckBox
                                        id={`${chi}-${idx}`}
                                        color={`purple-light`}
                                        key={`${chi}-${idx}`}
                                        checked={
                                          chi?.toLowerCase() !==
                                          SPECIAL_ROLE_NAME
                                            ? details?.selected_roles.some(
                                                (chid) => chid === chi
                                              )
                                            : true
                                        }
                                        onChange={() => {
                                          chi?.toLowerCase() !==
                                            SPECIAL_ROLE_NAME &&
                                            handleAddRolesToList(chi);
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <>
                              {" "}
                              <NoContentBox
                                title={`No roles  found`}
                                text={`Click on the create button below to create a role.`}
                                loading={loadingTeamUpdate}
                                btnText="Create role"
                              />
                            </>
                          )}
                        </>
                      )}
                      {/* select box end */}
                    </div>
                    {/* select currency  end */}
                  </>
                )}
                {/* step 1 end -------------------------- */}
              </div>
            )}
            {step === 2 && (
              <div className="form form-two">
                {/* step 2 start -------------------------- */}
                {step === 2 && (
                  <>
                    <div className="two-view-box">
                      {/* left box start */}
                      <div
                        className={`left-box  border-theme-right ${
                          !showExtra && "left-box-center"
                        }`}
                      >
                        {/* bring back box start */}
                      {!showExtra &&  <div onClick={() => {
                        setShwoExtra(true);
                        setShwoExtraWrap(true)
                      }} className="bring-back-box border-theme">
                        <FaAngleLeft className="icon black-white-color" />
                        </div>}
                        {/* bring back box end */}
                        <div className="wrappert">
                          <p className="title">
                            Select Managers Authorisation Level{" "}
                          </p>
                          {details?.authorized_users?.length > 10 && (
                            <form
                              action=""
                              autoComplete="off"
                              style={{ width: "100%" }}
                            >
                              <RavenInputField
                                color="black-light"
                                type="search"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </form>
                          )}
                          {/* select manage wrap start */}
                          <div className="select-manage-wrap">
                            {arrangeListTwoFunc(details?.authorized_users)?.map((chi, idx) => {
                              const { name, lname, fname, clearance, user_id } =
                                chi;
                              return (
                                <div
                                  //   htmlFor={`${name}-${idx}`}
                                  key={`${name}-${idx}`}
                                  onClick={() => {
                                    // console.log("yes");
                                    // handleAddManagerToList(name);
                                  }}
                                  className={`select-item ${
                                    details?.authorized_users?.length - 1 !==
                                      idx && "border-theme-bottom"
                                  }`}
                                >
                                  <div className="avatar-box ">
                                    <span className="">{`${fname?.charAt(
                                      0
                                    )} ${lname?.charAt(0)}`}</span>
                                  </div>
                                  <div className="name-role-box">
                                    <p className="name">{`${
                                      lowerCaseWrap(fname) || "---"
                                    } ${
                                      lowerCaseWrap(
                                        fname?.length + lname?.length > 12 &&
                                          details?.authorized_users?.filter(
                                            (chid) => chid?.user_id === user_id
                                          )[0].label.length > 10
                                          ? trimLongString(lname, 3)
                                          : lname
                                      ) || "---"
                                    }`}</p>
                                    <p className="role grey-white-color">
                                      {lowerCaseWrap(clearance) || "---"}
                                    </p>
                                  </div>
                                  <div
                                    className={`select-option-wrap ${
                                      clearance === SPECIAL_ROLE_NAME &&
                                      "select-react-disabled"
                                    }`}
                                  >
                                    <form autoComplete="off" action="">
                                      <RavenInputField
                                        color="black-light"
                                        selectStyles={reactSelectStyle}
                                        className={`select-react`}
                                        type="select"
                                        placeholder="select"
                                        disabled={
                                          clearance === SPECIAL_ROLE_NAME
                                        }
                                        onChange={(e) => {
                                          handleAddUserToList(
                                            e.value,
                                            chi?.user_id
                                          );
                                        }}
                                        value={
                                          details?.authorized_users?.length > 0
                                            ? details?.authorized_users?.filter(
                                                (chid) =>
                                                  chid?.user_id === user_id
                                              )[0]
                                            : { label: "None", value: "none" }
                                        }
                                        selectOption={formAuthLevel(
                                          subAccoutDetailList?.authorization_values
                                        )}
                                        id={idx}
                                      />
                                    </form>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {/* select manage wrap end */}
                        </div>
                      </div>
                      {/* left box end */}
                      {/* right box start */}
                    <div className={`right-box  ${!showExtra && "right-box-leave"}`}>
                        <div className={`wrapper-sub `}>
                          <div className="wrap border-theme"> <div className="title-header grey-bg">
                            <p className="title">
                              Understand the different <br /> Authorisation
                              Levels.
                            </p>
                            <div onClick={() => {
                              setShwoExtra(false)
                              setTimeout(() => {
                                setShwoExtraWrap(false)
                              }, 100);
                            }} className="cancel-box white-black-bg">
                              <figure className="img-box">{cancelIcon}</figure>
                            </div>
                          </div>
                          <div className="content-wrap">
                            {authListInfo?.map((chi, idx) => {
                              const {name, text} = chi ;
                              return(
                                <div key={idx} className={`read-item ${idx !== authListInfo.length - 1 && "border-theme-bottom"}`}>
                                  <p className="name">{name}</p>
                                  <p className="text grey-white-color">{text}</p>
                                </div>
                              )
                            })}
                          </div></div>
                         
                        </div>
                      </div>
                      {/* right box end */}
                    </div>
                  </>
                )}
                {/* step 2 end -------------------------- */}
              </div>
            )}
            {step === 3 && (
              <div className="form ">
                {/* step 3 start -------------------------- */}
                {step === 3 && (
                  <>
                    <NewPreviewDetailBox
                      account_purpose={details?.purpose}
                      account_title={details?.custom_account_name}
                      currency={details?.currency}
                      primary_currency={"Naira"}
                      checked={details?.primary}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, primary: !details?.primary };
                        });
                      }}
                    />
                  </>
                )}
                {/* step 3 end -------------------------- */}
              </div>
            )}
          </div>
        </ContainerWrapper>
        {/* container wrapper end */}
      </DashboardLayout>
    </>
  );
};

export default AddSubAccountIdex;
