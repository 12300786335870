import React, { useState } from "react";
import TierTwoIndex from "../TierTwoIndex";
import { returnInitial } from "../../../../../utils/helper/Helper";
import BadgeComponent from "../../../../../components/fragments/badge/BadgeComponent";
import ViewDirectorModal from "./DirectorModal";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDirectorShareholders } from "../../../../../redux/settings";
import { RavenButton, RavenInputField } from "@ravenpay/raven-bank-ui";
import ReUsuableStatus from "../../ReUsuableStatus";
import { useNavigate } from "react-router-dom";
import { changeVerificationStatus } from "../../../../../redux/verifications";
import { getUser } from "../../../../../redux/user";

const DirectorsVerificationFive = () => {
  const { business } = useSelector((state) => state.user);
  const [singleChild, setSingleChild] = useState({});
  const {loading} = useSelector((state) => state.verifications)
  const { directors } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    director: false,
  });
  const navigate = useNavigate();

  const arrowIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M6 12L10 8L6 4"
        stroke="#292929"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const [statusCheck, setStatusCheck] = useState(0);
  useEffect(() => {
    if (Object.keys(business).length > 0) {
      setStatusCheck(Number(business?.directors));
    }
    if(Number(business?.cac) !== 3){
      navigate("/dashboard-verification")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  useEffect(() => {
    if (directors?.length < 1) {
      dispatch(getDirectorShareholders());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const successImg = (
    <svg
      className="img"
      width="99"
      height="99"
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.3335 44.25C47.4797 44.25 54.0835 37.6462 54.0835 29.5C54.0835 21.3538 47.4797 14.75 39.3335 14.75C31.1873 14.75 24.5835 21.3538 24.5835 29.5C24.5835 37.6462 31.1873 44.25 39.3335 44.25Z"
        fill="url(#paint0_linear_19508_20208)"
      />
      <path
        d="M39.3335 54.0833C53.9579 54.0833 66.0968 64.725 68.4291 78.6873C68.8764 81.3655 66.6322 83.5833 63.9168 83.5833H14.7502C12.0348 83.5833 9.79055 81.3655 10.2379 78.6873C12.5702 64.725 24.7091 54.0833 39.3335 54.0833Z"
        fill="url(#paint1_linear_19508_20208)"
      />
      <path
        d="M78.6668 34.4167C78.6668 31.7013 76.4656 29.5 73.7502 29.5C71.0348 29.5 68.8335 31.7013 68.8335 34.4167V39.3333H63.9168C61.2014 39.3333 59.0002 41.5346 59.0002 44.25C59.0002 46.9654 61.2014 49.1667 63.9168 49.1667H68.8335V54.0833C68.8335 56.7987 71.0348 59 73.7502 59C76.4656 59 78.6668 56.7987 78.6668 54.0833V49.1667H83.5835C86.2989 49.1667 88.5002 46.9654 88.5002 44.25C88.5002 41.5346 86.2989 39.3333 83.5835 39.3333H78.6668V34.4167Z"
        fill="url(#paint2_linear_19508_20208)"
      />
      <path
        d="M68.8335 40.8333C69.6619 40.8333 70.3335 40.1618 70.3335 39.3333V34.4167C70.3335 32.5297 71.8632 31 73.7502 31C75.6371 31 77.1668 32.5297 77.1668 34.4167V39.3333C77.1668 40.1618 77.8384 40.8333 78.6668 40.8333H83.5835C85.4705 40.8333 87.0002 42.363 87.0002 44.25C87.0002 46.137 85.4705 47.6667 83.5835 47.6667H78.6668C77.8384 47.6667 77.1668 48.3382 77.1668 49.1667V54.0833C77.1668 55.9703 75.6371 57.5 73.7502 57.5C71.8632 57.5 70.3335 55.9703 70.3335 54.0833V49.1667C70.3335 48.3382 69.6619 47.6667 68.8335 47.6667H63.9168C62.0299 47.6667 60.5002 46.137 60.5002 44.25C60.5002 42.363 62.0299 40.8333 63.9168 40.8333H68.8335ZM52.5835 29.5C52.5835 36.8178 46.6513 42.75 39.3335 42.75C32.0157 42.75 26.0835 36.8178 26.0835 29.5C26.0835 22.1822 32.0157 16.25 39.3335 16.25C46.6513 16.25 52.5835 22.1822 52.5835 29.5ZM39.3335 55.5833C53.2128 55.5833 64.7361 65.6833 66.9496 78.9344C67.2158 80.5285 65.8856 82.0833 63.9168 82.0833H14.7502C12.7814 82.0833 11.4511 80.5285 11.7174 78.9344C13.9309 65.6833 25.4542 55.5833 39.3335 55.5833Z"
        stroke="black"
        strokeOpacity="0.05"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_19508_20208"
          x1="49.1668"
          y1="14.75"
          x2="49.1668"
          y2="83.5833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_19508_20208"
          x1="49.1668"
          y1="14.75"
          x2="49.1668"
          y2="83.5833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_19508_20208"
          x1="49.1668"
          y1="14.75"
          x2="49.1668"
          y2="83.5833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
      </defs>
    </svg>
  );

  if (String(statusCheck) === "3") {
    return (
      <TierTwoIndex step={2} stage={75}>
        <ReUsuableStatus
          text={`Thanks for taking your time to verify your each director information, we are confident about your details, and your limit has been increased successfully.`}
          title={`Directors Verified Successfully`}
          imgBg={`rgba(255, 246, 237, 1)`}
          img={successImg}
          // bottomImg={true}
        />
        {String(business?.indemnity) !== "3" && (
          <div
            style={{ minHeight: "unset", marginTop: "1.5rem" }}
            className="form-inputs-wrap"
          >
            {" "}
            <RavenInputField
              type={`submit`}
              color="black-light"
              value={`Proceed`}
              onSubmit={() => {
                navigate("/dashboard-verification-sign-idenmity-new");
              }}
              className="btn-submit"
            />
          </div>
        )}
      </TierTwoIndex>
    );
  }

  const failedIcon = (
    <svg
      className="img"
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.678 23.1388L18.328 56.6465C15.9391 60.7901 18.9252 65.9632 23.7113 65.9632H62.4054C67.1903 65.9632 70.18 60.7841 67.7887 56.6465L48.4387 23.1388C46.0498 18.9917 40.0704 18.9917 37.678 23.1388Z"
        fill="url(#paint0_linear_20124_9100)"
      />
      <path
        d="M62.4052 66.0671H23.7147C22.6066 66.0671 21.518 65.7753 20.5583 65.2213C19.5986 64.6672 18.8016 63.8703 18.2475 62.9106C17.6933 61.951 17.4015 60.8624 17.4014 59.7542C17.4012 58.6461 17.6927 57.5574 18.2466 56.5976L37.5966 23.0898C38.1505 22.1299 38.9475 21.3327 39.9072 20.7784C40.867 20.2242 41.9558 19.9324 43.0641 19.9324C44.1725 19.9324 45.2613 20.2242 46.221 20.7784C47.1808 21.3327 47.9778 22.1299 48.5317 23.0898L67.8817 56.5976C68.4356 57.5574 68.7271 58.6461 68.7269 59.7542C68.7268 60.8624 68.4349 61.951 67.8808 62.9106C67.3266 63.8703 66.5297 64.6672 65.57 65.2213C64.6103 65.7753 63.5217 66.0671 62.4135 66.0671H62.4052ZM43.0552 20.13C41.9803 20.1237 40.9231 20.4036 39.9921 20.941C39.0611 21.4783 38.29 22.2538 37.7578 23.1878L18.4078 56.6955C17.8711 57.6254 17.5886 58.6801 17.5887 59.7538C17.5888 60.8274 17.8714 61.8821 18.4083 62.8119C18.9451 63.7417 19.7172 64.5138 20.647 65.0506C21.5768 65.5874 22.6315 65.87 23.7052 65.8701H62.4052C63.4788 65.87 64.5335 65.5874 65.4633 65.0506C66.3931 64.5138 67.1652 63.7417 67.7021 62.8119C68.2389 61.8821 68.5216 60.8274 68.5217 59.7538C68.5218 58.6801 68.2393 57.6254 67.7025 56.6955L48.3525 23.1878C47.8208 22.2545 47.0504 21.4795 46.1203 20.9422C45.1903 20.4048 44.1341 20.1245 43.06 20.13H43.0552Z"
        fill="url(#paint1_linear_20124_9100)"
      />
      <path
        d="M46.3971 34.3546C46.1451 38.7 45.8883 43.1111 45.6279 47.5269C45.5932 48.1349 45.6195 48.7536 45.5192 49.3508C45.4228 49.917 45.1288 50.4306 44.6895 50.8004C44.2501 51.1702 43.6938 51.3723 43.1196 51.3706C41.8964 51.3814 40.8322 50.5692 40.6948 49.3281C40.5037 47.6141 40.4452 45.8846 40.3365 44.1622C40.1311 40.8619 39.9495 37.5593 39.7226 34.2614C39.5553 31.8367 41.2944 29.9614 43.5197 30.223C44.2678 30.3157 44.9613 30.6631 45.4833 31.207C46.0054 31.7508 46.3243 32.4578 46.3864 33.2091C46.421 33.5639 46.3971 33.9222 46.3971 34.3546Z"
        fill="white"
      />
      <path
        d="M39.7751 56.9344C39.7916 56.0632 40.1482 55.233 40.7687 54.6212C41.3891 54.0094 42.2243 53.6646 43.0956 53.6604C44.9315 53.6604 46.3553 55.1535 46.3397 57.0526C46.33 57.9136 45.9816 58.7361 45.37 59.3421C44.7584 59.9481 43.9327 60.2889 43.0717 60.2908C42.6348 60.2872 42.2028 60.1975 41.8006 60.0268C41.3983 59.8561 41.0337 59.6078 40.7275 59.2961C40.4213 58.9843 40.1796 58.6153 40.0162 58.21C39.8527 57.8048 39.7708 57.3713 39.7751 56.9344Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20124_9100"
          x1="43.0588"
          y1="20.0284"
          x2="43.0588"
          y2="65.9632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20124_9100"
          x1="43.0641"
          y1="19.9324"
          x2="43.0641"
          y2="66.0671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA554" />
          <stop offset="1" stop-color="#E66F04" />
        </linearGradient>
      </defs>
    </svg>
  );

  const handleRetryFunction = async () => {
    const obj = {
      type: "directors",
    };
    const data = await dispatch(changeVerificationStatus(obj));
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
    }
  };

  if (String(statusCheck) === "5") {
    return (
      <TierTwoIndex step={2} stage={30}>
        <ReUsuableStatus
          text={`We tried verifying your Directors document but we noticed some errors, kindly retry this verification to continue.`}
          title={`Directors Verification Revoked`}
          imgBg={`rgba(255, 246, 237, 1)`}
          img={failedIcon}
        />
        <div
          style={{ minHeight: "unset", width: " 100%" }}
          className="form-inputs-wrap"
        >
          {" "}
          <RavenButton
            // type={`submit`}

            color="black-light"
            label={`Retry Verification`}
            style={{
              backgroundColor: "#ffffff",
              border: ".1rem solid #020202",
              color: "#020202",
              width: "100%",
            }}
            loading={loading}
            onClick={handleRetryFunction}
            className="btn-submit"
          />
        </div>
      </TierTwoIndex>
    );
  }

  return (
    <>
      <TierTwoIndex step={2} stage={60}>
        <div
          // onClick={() => {
          //   console.log(directors);
          // }}
          className="form-wrap"
        >
          <div className="form-title-text">
            <p className="title">Director Verification</p>
            <p className="text grey-white-color">
              You would need to verify all director attached to your CAC.
            </p>
          </div>

          {/* directors list start */}
          {directors?.length > 0 && (
            <div className="directors-list-box border-theme ">
              {directors?.map((chi, idx) => {
                const { id, name, status } = chi;
                return (
                  <div
                    className={`director-item-row ${
                      idx !== directors?.length - 1 && "border-theme-bottom"
                    }`}
                    key={idx || id}
                    onClick={() => {
                      setSingleChild(chi);
                      setShowModal((prev) => {
                        return { ...prev, director: true };
                      });
                    }}
                  >
                    <div className="avatar-box">
                      <span>{returnInitial(name)}</span>
                    </div>
                    <p className="name">{name}</p>
                    <div className="badge-box">
                      <BadgeComponent
                        className={
                          status === 0
                            ? "grey-bg black-light-color"
                            : status === 1
                            ? "pending"
                            : status === 2
                            ? "failed"
                            : "success"
                        }
                        text={
                          status === 1
                            ? "pending"
                            : status === 2
                            ? "failed"
                            : "Verified"
                        }
                      >
                        {status === 0 && "Start"}
                      </BadgeComponent>
                    </div>
                    <div className="arrow-icon">
                      <figure className="img-box">{arrowIcon}</figure>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* directors list end */}
        </div>
      </TierTwoIndex>
      <ViewDirectorModal
        onClose={() => {
          setSingleChild({});
          setShowModal((prev) => {
            return { ...prev, director: false };
          });
        }}
        detail={singleChild}
        visible={showModal?.director}
      />
    </>
  );
};

export default DirectorsVerificationFive;
