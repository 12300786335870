import moment from "moment";
import {
  RavenInputField,
  RavenModal,
  RavenTabLink,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { generateBankStatement } from "../../../../redux/settings";
import "../../../../styles/dashboard/setting/SettingModal.css";
import { reactSelectStyle } from "../../../../utils/helper/Helper";
import { generatePosStatementAll } from "../../../../redux/pos";

const GenerateStatementModalSingle = ({ visible, onCancel, detail }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
//   const { loadingStatement } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [generateType, setGenerateType] = useState(0);
  const selectList = ["Select", "Custom"];
  const [details, setDetails] = useState({
    selected: "",
  });

  const selectOptions = [
    { label: "1 month", value: 1 },
    { label: "2 month", value: 2 },
    { label: "3 months", value: 3 },
    { label: "4 months", value: 4 },
    { label: "5 months", value: 5 },
    { label: "6 months", value: 6 },
  ];

  const [loading, setLoading] = useState(false)
  const handleSubmit = async () => {
    // console.log(details);
    let obj;
    if (generateType === 1) {
      const val = {
        date: ``,
        tid: detail?.tid,
        from: fromDate,
        to: toDate,
      };
      obj = val;
    } else {
      const val = {
        date: `${details?.selected.value}_months`,
        tid: detail?.tid,
      };
      obj = val;
    }
    // console.log(obj);
    // return;
    setLoading(true)
    const data = await dispatch(generatePosStatementAll(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
        setLoading(false)
      downloadCsv(data?.payload?.data.data.url);
    //   onCancel();
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <RavenModal
      visble={visible}
      btnColor={`black-light`}
      btnLabel={`Generate Statement`}
      onClose={() => {
        onCancel();
        setFromDate("");
        setToDate("");
        setDetails((prev) => {
          return { ...prev, selected: "" };
        });
      }}
      onBtnClick={handleSubmit}
      loading={loading}
      disabled={generateType === 0 ? !details?.selected : !fromDate || !toDate}
    >
      {/* Sttings modal start -------------- */}
      <div className="settings-modal-wrap">
        {/* gnerate mwrap start */}
        <div className="generate-modal-wrap">
          <p className="title">Generate Single Terminal Statement</p>
          <p className="text grey-white-color">
            Select how to generate statement
          </p>
          {/* select wrap start */}
          <div className="select-wrap">
            <RavenTabLink
              color={`black-light`}
              key={`kaja`}
              list={selectList}
              onClick={(e) => {
                setGenerateType(e);
                if (e === 0) {
                  setFromDate("");
                  setToDate("");
                } else {
                  setDetails((prev) => {
                    return { ...prev, selected: "" };
                  });
                }
              }}
              fadedBlack
            />
          </div>
          {/* select wrap end */}
          {/*  */}
          {/* custom start */}
          <div className="input-type-wrap">
            {/* select wrap type  */}
            <div
              className={`select-type-wrap ${
                generateType === 0 && "select-type-wrap-show"
              }`}
            >
              <RavenInputField
                color={`black-light`}
                label={`select date`}
                placeholder={`E.g 1 month`}
                value={details?.selected}
                selectStyles={reactSelectStyle}
                type={`select`}
                selectOption={selectOptions}
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, selected: e };
                  });
                }}
              />
            </div>
            {/* select wrap end */}
            {/* custom type start */}
            <div
              className={`custom-type-wrap ${
                generateType === 1 && "custom-type-wrap-show"
              }`}
            >
              <RavenInputField
                color={`black-light`}
                type={`date`}
                value={fromDate}
                label={`From`}
                placeholder={`Select date from`}
                onChange={(e) => {
                  setFromDate(moment(e[0]).format("YYYY-MM-DD"));
                }}
              />
              <RavenInputField
                placeholder={`Select date to`}
                color={`black-light`}
                type={`date`}
                value={toDate}
                label={`To`}
                onChange={(e) => {
                  setToDate(moment(e[0]).format("YYYY-MM-DD"));
                }}
              />
            </div>
            {/* custom type end */}
          </div>
          {/* custom end */}
        </div>
        {/* gnerate mwrap end */}
      </div>
      {/* settings modal end -------------- */}
    </RavenModal>
  );
};

export default GenerateStatementModalSingle;
