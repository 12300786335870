import React from "react";
import "../../styles/reusables/NocontentPage.css";
// import { Oval } from "react-loader-spinner";
import { RavenButton } from "@ravenpay/raven-bank-ui";
// import { useLocation } from "react-router-dom";
// import autoIcon from "../../assets/auto-icon-upload.svg";
// import manualIcon from "../../assets/manual-upload-icon.svg";
// import ActionContext from "../../context/ActionContext";
// import useWindowSize from "../../utils/helper/UseWindowSize";
import newImgLoader from "../../assets/raven-loader.gif";

const NocontentPage = ({
  title,
  text,
  list,
  btntext,
  onBtnClick,
  loading,
  type,
  img,
  normtext,
  onIntercomLoad,
}) => {
  // const location = useLocation();
  //   const navigate = useNavigate();
  // const watchIcon = (
  //   <svg
  //     width="19"
  //     className="img"
  //     height="19"
  //     viewBox="0 0 19 19"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <circle cx="9.5" cy="9.5" r="9.5" fill="#F9AB01" />
  //     <path
  //       d="M7.6665 7.66333C7.6665 7.33961 7.6665 7.17775 7.734 7.08853C7.7928 7.0108 7.88268 6.9627 7.97997 6.95689C8.09165 6.95022 8.22633 7.04 8.49568 7.21957L12.0009 9.55636C12.2234 9.70474 12.3347 9.77893 12.3735 9.87243C12.4074 9.95419 12.4074 10.0461 12.3735 10.1278C12.3347 10.2213 12.2234 10.2955 12.0009 10.4439L8.49568 12.7807C8.22633 12.9602 8.09165 13.05 7.97997 13.0434C7.88268 13.0375 7.7928 12.9894 7.734 12.9117C7.6665 12.8225 7.6665 12.6606 7.6665 12.3369V7.66333Z"
  //       fill="white"
  //       stroke="white"
  //       strokeWidth="0.9375"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     />
  //   </svg>
  // );
  //   const actionCtx = useContext(ActionContext);

  const callIcon = (
    <svg
      className="img"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="9.5" r="9.5" fill="#F9AB01" />
      <g clipPath="url(#clip0_13196_67679)">
        <path
          d="M8.46042 9.28474C8.69242 9.76794 9.00868 10.2208 9.40921 10.6213C9.80973 11.0219 10.2626 11.3381 10.7458 11.5701C10.7874 11.5901 10.8082 11.6001 10.8345 11.6077C10.9279 11.635 11.0427 11.6154 11.1218 11.5587C11.1441 11.5428 11.1631 11.5237 11.2012 11.4856C11.3178 11.3691 11.376 11.3108 11.4346 11.2727C11.6556 11.1291 11.9405 11.1291 12.1614 11.2727C12.22 11.3108 12.2783 11.3691 12.3948 11.4856L12.4598 11.5506C12.6369 11.7277 12.7255 11.8163 12.7736 11.9114C12.8693 12.1006 12.8693 12.324 12.7736 12.5132C12.7255 12.6084 12.6369 12.6969 12.4598 12.8741L12.4072 12.9266C12.2307 13.1032 12.1424 13.1914 12.0224 13.2589C11.8892 13.3337 11.6824 13.3875 11.5297 13.387C11.392 13.3866 11.2979 13.3599 11.1098 13.3065C10.0987 13.0195 9.14454 12.478 8.34855 11.682C7.55255 10.886 7.01106 9.9319 6.72407 8.92076C6.67067 8.73261 6.64397 8.63854 6.64356 8.50089C6.6431 8.34815 6.69689 8.14132 6.7717 8.00815C6.83911 7.88814 6.92738 7.79987 7.10392 7.62333L7.15647 7.57078C7.33362 7.39364 7.42219 7.30506 7.51732 7.25695C7.7065 7.16126 7.92993 7.16126 8.11911 7.25695C8.21424 7.30506 8.30281 7.39364 8.47996 7.57078L8.54492 7.63574C8.66145 7.75228 8.71972 7.81054 8.75782 7.86914C8.90148 8.0901 8.90148 8.37496 8.75782 8.59592C8.71972 8.65452 8.66145 8.71278 8.54492 8.82932C8.50681 8.86742 8.48776 8.88648 8.47182 8.90875C8.41514 8.98789 8.39558 9.10264 8.42282 9.19609C8.43048 9.22239 8.44046 9.24317 8.46042 9.28474Z"
          fill="white"
        />
        <path
          d="M10.6835 8.00033C11.0091 8.06385 11.3083 8.22308 11.5429 8.45764C11.7775 8.6922 11.9367 8.99141 12.0002 9.31699M10.6835 6.66699C11.36 6.74214 11.9907 7.04505 12.4723 7.52599C12.9538 8.00694 13.2575 8.63733 13.3335 9.31366M9.40921 10.6213C9.00868 10.2208 8.69242 9.76794 8.46042 9.28474C8.44046 9.24317 8.43048 9.22239 8.42282 9.19609C8.39558 9.10264 8.41514 8.98789 8.47182 8.90875C8.48776 8.88648 8.50681 8.86742 8.54492 8.82932C8.66145 8.71278 8.71972 8.65452 8.75782 8.59592C8.90148 8.37496 8.90148 8.0901 8.75782 7.86914C8.71972 7.81054 8.66145 7.75228 8.54492 7.63574L8.47996 7.57078C8.30281 7.39364 8.21424 7.30506 8.11911 7.25695C7.92993 7.16126 7.7065 7.16126 7.51732 7.25695C7.42219 7.30506 7.33362 7.39364 7.15647 7.57078L7.10392 7.62333C6.92738 7.79987 6.83911 7.88814 6.7717 8.00815C6.69689 8.14132 6.6431 8.34815 6.64356 8.50089C6.64397 8.63854 6.67067 8.73261 6.72407 8.92076C7.01106 9.93189 7.55255 10.886 8.34855 11.682C9.14454 12.478 10.0987 13.0195 11.1098 13.3065C11.2979 13.3599 11.392 13.3866 11.5297 13.387C11.6824 13.3875 11.8892 13.3337 12.0224 13.2589C12.1424 13.1914 12.2307 13.1032 12.4072 12.9266L12.4598 12.8741C12.6369 12.6969 12.7255 12.6084 12.7736 12.5132C12.8693 12.324 12.8693 12.1006 12.7736 11.9114C12.7255 11.8163 12.6369 11.7277 12.4598 11.5506L12.3948 11.4856C12.2783 11.3691 12.22 11.3108 12.1614 11.2727C11.9405 11.1291 11.6556 11.1291 11.4346 11.2727C11.376 11.3108 11.3178 11.3691 11.2012 11.4856C11.1631 11.5237 11.1441 11.5428 11.1218 11.5587C11.0427 11.6154 10.9279 11.635 10.8345 11.6077C10.8082 11.6001 10.7874 11.5901 10.7458 11.5701C10.2626 11.3381 9.80973 11.0219 9.40921 10.6213Z"
          stroke="white"
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13196_67679">
          <rect width="8" height="8" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );

  //   const bulkSelectList = [
  //     {
  //       name: "Automatic Upload",
  //       text: "Some text here to explain.",
  //       icon: autoIcon,
  //       link: "/dashboard-payroll-create-auto",
  //     },
  //     {
  //       name: "Manual Upload",
  //       text: "Some text here to explain.",
  //       icon: manualIcon,
  //       link: "/dashboard-payroll-create-manual",
  //     },
  //   ];

  // const size = useWindowSize();

  return (
    <div className="no-content-wrapper-page-box card-bg">
      {loading ? (
        <div className="spinner-wrap">
          {/* <Oval
            height={size.width < 900 ? 45 : 80}
            width={size.width < 900 ? 45 : 80}
            //   color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass="loader-spiner-react"
            visible={true}
            ariaLabel="oval-loading"
            // secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          /> */}
          <figure
            // style={{ width: "15rem", height: "15rem" }}
            className="img-box"
          >
            <img
              // style={{ width: "100%", height: "100%", objectFit: "contain" }}
              src={newImgLoader}
              alt=""
              className="img"
            />
          </figure>
          {/* <ThreeCircles
            height="70"
            width="70"
            // color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass="loader-spiner-react"
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          /> */}
          {/* <Rings
            height="120"
            width="120"
            //   color="#020202"
            radius="10"
            wrapperStyle={{}}
            wrapperClass="loader-spiner-react"
            visible={true}
            ariaLabel="rings-loading"
          /> */}
        </div>
      ) : (
        <div className={`wrap ${type === "two" && "wrap-two"}`}>
          <div className="left-box">
            <div className="img-wrap">
              <figure className="img-box">
                <img src={img} alt="" className="img" />
              </figure>
            </div>
          </div>
          {/* left- box end */}
          <div className="right-box">
            <div className="title-box">
              <p className="title">{title}</p>
            </div>
            {/* normal text start */}
            {normtext && (
              <p className="normal-text grey-white-color">{normtext}</p>
            )}
            {/* normal text end */}
            {/* list box start */}
            <div className="list-box">
              {list?.length > 0 &&
                list?.map((chi, idx) => {
                  return (
                    <div key={idx} className="row-text">
                      <div className="img-wrap">
                        <figure className="img-box">
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="img"
                          >
                            <path
                              d="M5.5 0L6.9855 4.0145L11 5.5L6.9855 6.9855L5.5 11L4.0145 6.9855L0 5.5L4.0145 4.0145L5.5 0Z"
                              fill="#EA872D"
                            />
                          </svg>
                        </figure>
                      </div>
                      <p className="text grey-white-color">{chi}</p>
                    </div>
                  );
                })}
            </div>
            {/* list box end */}
            {/* btn box start */}
            {btntext && (
              <div className="btn-box">
                <RavenButton
                // loading={true}
                  color={`black-light`}
                  label={btntext}
                  onClick={() => {
                    // location.pathname.includes("/dashboard-payroll") &&
                    //   actionCtx?.setCheckShow("two");
                    onBtnClick && onBtnClick();
                  }}
                />
              </div>
            )}
            {/* btn box end */}
            {/* support learn box start */}
            <div className="support-learn-box">
            <div className="icon-text-box">
                  <figure className="img-box">{callIcon}</figure>
                  <p className="text grey-white-color">
                    Need help?,{" "}
                    <span
                      onClick={() => {
                        // window.Intercom("show");
                        window.fcWidget.open();
                      }}
                    >
                      Chat with us.
                    </span>
                  </p>
                </div>
            </div>
            {/* support learn box end */}
          </div>
        </div>
      )}
    </div>
  );
};

export default NocontentPage;
