import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";

import NotiReuseableBox from "./NotiReuseableBox";

const NotificationModal = ({ visible, onClose }) => {
  return (
    <>
      <RavenModal
        dontShowClose
        visble={visible}
        onClose={() => {
          onClose();
        }}
        className="notify-modal"
      >
        <NotiReuseableBox onClose={onClose} />
      </RavenModal>
    </>
  );
};

export default NotificationModal;
