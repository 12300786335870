import React, { useState } from "react";
import forgetImg from "../../assets/forget-password-icon.svg";
import AuthLayoutTwo from "../../layout/auth/AuthLayoutTwo";
// import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import "../../styles/auth/SignUp.css";
import {
  // RavenButton,
  RavenInputField,
  // RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import ForgetPasswordModal from "./modal/forgetPasswordModal";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../redux/user";

const ForgetPassword = () => {
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async () => {
    const data = await dispatch(forgotPassword(details));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      // console.log(data);
      setShowModal(true);
    }
  };

  // const [showGuessBox, setShowGuessBox] = useState(false);
  // const [validate, setValidate] = useState({
  //   hasLow: 0,
  //   hasCap: 0,
  //   hasNumber: 0,
  //   has8digit: 0,
  //   hasSpecial: 0,
  // });

  // const validatePassword = (password) => {
  //   if (password.match(/\d+/g)) {
  //     setValidate((o) => ({ ...o, hasNumber: 20 }));
  //   } else {
  //     setValidate((o) => ({ ...o, hasNumber: 0 }));
  //   }
  //   if (password.match(/[!@#$%^.&*_=+-]/g)) {
  //     setValidate((o) => ({ ...o, hasSpecial: 20 }));
  //   } else {
  //     setValidate((o) => ({ ...o, hasSpecial: 0 }));
  //   }

  //   if (password.match(/[A-Z]+/g)) {
  //     setValidate((o) => ({ ...o, hasCap: 20 }));
  //   } else {
  //     setValidate((o) => ({ ...o, hasCap: 0 }));
  //   }

  //   if (password.match(/[a-z]+/g)) {
  //     setValidate((o) => ({ ...o, hasLow: 20 }));
  //   } else {
  //     setValidate((o) => ({ ...o, hasLow: 0 }));
  //   }

  //   if (password.length > 7) {
  //     setValidate((o) => ({ ...o, has8digit: 20 }));
  //   } else {
  //     setValidate((o) => ({ ...o, has8digit: 0 }));
  //   }
  // };

  const infoIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15940_68964)">
        <path
          d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6024 18.3332 10C18.3332 5.39765 14.6022 1.66669 9.99984 1.66669C5.39746 1.66669 1.6665 5.39765 1.6665 10C1.6665 14.6024 5.39746 18.3334 9.99984 18.3334Z"
          fill="#EA872D"
        />
        <path
          d="M9.99984 6.66669V10M9.99984 13.3334H10.0082M18.3332 10C18.3332 14.6024 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6024 1.6665 10C1.6665 5.39765 5.39746 1.66669 9.99984 1.66669C14.6022 1.66669 18.3332 5.39765 18.3332 10Z"
          stroke="#FFF6ED"
          strokeWidth="1.43293"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15940_68964">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <AuthLayoutTwo>
      <div className="sign-in-wrap">
        {/* form start */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          action=""
          className="form form-forget-password"
        >
          {/* security tip start */}
          <div className="security-tip-wrap">
            <div className="img-wrap">
              <figure className="img-box">{infoIcon}</figure>
            </div>
            <p className="text">
              Confirm if this url is{" "}
              <span>
                <a href="https://business.getravenbank.com">
                  https://business.getravenbank.com
                </a>{" "}
              </span>{" "}
            </p>
          </div>
          {/* security tip end */}
          <div className="img-wrap-forget">
            <figure className="img-box">
              <img src={forgetImg} alt="" className="img" />
            </figure>
          </div>
          {/* title text box start */}
          <div className="text-box">
            <p className="big-title">Reset your Password</p>
            <p className="small-title">
              Follow Prompt to recover your password
            </p>
          </div>
          {/* title text box end */}
          <RavenInputField
            type={`email`}
            label={`Email address * `}
            placeholder="Your email address here"
            color={`black-light`}
            name="email"
            onChange={handleChange}
            value={details?.email}
          />
          <RavenInputField
            type={`submit`}
            color="black-light"
            value={`Proceed to next step`}
            disabled={!details?.email}
            className="btn-submit"
            loading={loading}
            onSubmit={handleSubmit}
          />

          {/* back text start */}
          <div onClick={() => navigate("/login")} className="back-text">
            <FaArrowLeft className="icon" />
            <p className="text">
              Back to <span>Login</span>
            </p>
          </div>
          {/* back text end */}
        </form>
        {/* form end */}
      </div>
      {/* sign up modal start */}
      <ForgetPasswordModal
        userEmail={details?.email}
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />
      {/* sign up modal end */}
    </AuthLayoutTwo>
  );
};

export default ForgetPassword;
