/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
// import Modal from "../../components/reusables/Modal";
import AuthLayoutOne from "../../layout/auth/AuthLayoutOne";
// import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import "../../styles/auth/SignUp.css";
import { RavenCheckBox, RavenInputField, toast } from "@ravenpay/raven-bank-ui";
// import ProgressBar from "../../utils/helper/ProgressBar";
import { useNavigate } from "react-router-dom";
import SignUpModal from "./modal/SignUpModal";
import { useSelector, useDispatch } from "react-redux";
import { registerUser, validateUniqueEmail } from "../../redux/user";
import {
  validateEmail,
  validatePasswordLowercase,
  validatePasswordNumber,
  validatePasswordSpecialCharacter,
  validatePasswordUpperCase,
} from "../../utils/helper/ValidateInput";
import {
  formatNumWithoutCommaNaira,
  getSystemThemePreference,
  reactSelectStyle,
  removeCountryCode,
  removeSpace,
  // sumAllNum,
} from "../../utils/helper/Helper";
import SuccessModal from "../../components/reusables/SuccessModal";
import ActionContext from "../../context/ActionContext";
import AuthLayoutOneFive from "../../layout/auth/version_1.5/AuthLayoutOneFive";
import {
  getLgaByStates,
  getMonthlyTransactionrange,
  getNumberOfStaff,
  getStates,
} from "../../redux/verifications";
import TermsAndConditionModal from "../dashboard/global-account/TermsAndConditionModal";
// import { FaCheck } from "react-icons/fa";

const SignUpOneFive = () => {
  const [showTerms, setShowTerms] = useState(false);
  const actionCtx = useContext(ActionContext);
  const { loading } = useSelector((state) => state.user);
  const { states, Lgas } = useSelector((state) => state.verifications);
  const [activeSelect, setActiveSelect] = useState("");
  const dispatch = useDispatch();
  const {
    monthly_transaction_range,
    number_of_staff,
    // account_purpose_list,
  } = useSelector((state) => state.verifications);
  const [showModal, setShowModal] = useState({
    success: false,
    otp: false,
  });
  const stepList = ["Authentication Details", "Business Details"];
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    email: "",
    password: "",
    phone: "",
    // last_name: "",
    business_name: "",
    ref_no: "",
    terms: false,
    confirm_password: "",
    state: "",
    lga: "",
    business_address: "",
    transaction_range: "",
    number_of_staff: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };
  const [validated, setValidated] = useState(false);
  const validateEmailAndPhone = async (e) => {
    e && e.preventDefault();
    const obj = {
      phone:
        removeSpace(details?.phone)?.length === 10
          ? `0${formatNumWithoutCommaNaira(details?.phone)}`
          : formatNumWithoutCommaNaira(details?.phone),
      email: details?.email,
    };
    if (!validateEmail(details.email)) {
      toast.error("Invalid email address!", { position: "top-right" });
      setError((prev) => {
        return { ...prev, email: true };
      });
      return;
    }
    if (details?.password !== details?.confirm_password) {
      toast.error("Password and confirm password does not match", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    if (!validatePasswordLowercase(details?.password)) {
      toast.error("Password should have at least 1 lowercase letter", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    if (!validatePasswordUpperCase(details?.password)) {
      toast.error("Password should have at least 1 capital letter", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    if (!validatePasswordSpecialCharacter(details?.password)) {
      toast.error("Password should have at least 1 special character", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    if (!validatePasswordNumber(details?.password)) {
      toast.error("Password should have at least  1 numeric character.", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    // console.log(obj);
    // return
    const data = await dispatch(validateUniqueEmail(obj));
    // console.log(data);
    if (
      data?.payload?.data?.status === "fail" &&
      data?.payload?.data?.message ===
        "User with this email address already exists. You can log into your account with this email and associated password. You can then add another business using the CREATE NEW BUSINESS BUTTON on your profile"
    ) {
      // console.log("hg");
      setError((prev) => {
        return { ...prev, email: true };
      });
    }
    if (
      data?.payload?.response?.status === 400 ||
      data?.payload?.response?.data?.status === "fail"
    ) {
      setError((prev) => {
        return { ...prev, email: true };
      });
      // alert(data?.payload?.response?.data?.message);
      toast.error(data?.payload?.response?.data?.message, {
        position: "top-right",
      });
    }
    if (data?.payload?.status === "success") {
      setStep(2);
      setValidated(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    actionCtx?.setIsLogout(false);

    const obj = {
      email: details?.email,
      business_name: details?.business_name,
      phone:
        removeSpace(details?.phone)?.length === 10
          ? `0${formatNumWithoutCommaNaira(details?.phone)}`
          : formatNumWithoutCommaNaira(details?.phone),
      password: details?.password,
    };
    // console.log(obj);
    // return
    const data = await dispatch(registerUser(obj));
    if (data?.payload?.data?.status === "success") {
      setShowModal((prev) => {
        return { ...prev, otp: true };
      });
    }
  };
  const [showGuessBox, setShowGuessBox] = useState(false);
  const [validate, setValidate] = useState({
    hasLow: 0,
    hasCap: 0,
    hasNumber: 0,
    has8digit: 0,
    hasSpecial: 0,
  });

  // const validatePassword = (password) => {
  //   if (password.match(/\d+/g)) {
  //     setValidate((o) => ({ ...o, hasNumber: 20 }));
  //   } else {
  //     setValidate((o) => ({ ...o, hasNumber: 0 }));
  //   }
  //   if (password.match(/[!@#$%^.&*_=+-]/g)) {
  //     setValidate((o) => ({ ...o, hasSpecial: 20 }));
  //   } else {
  //     setValidate((o) => ({ ...o, hasSpecial: 0 }));
  //   }

  //   if (password.match(/[A-Z]+/g)) {
  //     setValidate((o) => ({ ...o, hasCap: 20 }));
  //   } else {
  //     setValidate((o) => ({ ...o, hasCap: 0 }));
  //   }

  //   if (password.match(/[a-z]+/g)) {
  //     setValidate((o) => ({ ...o, hasLow: 20 }));
  //   } else {
  //     setValidate((o) => ({ ...o, hasLow: 0 }));
  //   }

  //   if (password.length > 7) {
  //     setValidate((o) => ({ ...o, has8digit: 20 }));
  //   } else {
  //     setValidate((o) => ({ ...o, has8digit: 0 }));
  //   }
  // };

  useEffect(() => {
    dispatch(getStates());
    dispatch(getMonthlyTransactionrange());
    dispatch(getNumberOfStaff());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* layout start */}
      <AuthLayoutOneFive
        canClick={
          details?.email && details?.password && details?.confirm_password
        }
        step={step}
        stepList={stepList}
        onNextStep={(param) => {
          //   console.log(param);
          if (Number(param) !== step) {
            // console.log("kkk");
            if (
              Number(param) === 2 &&
              details?.email &&
              details?.password &&
              details?.confirm_password
            ) {
              if (validated) {
                setStep(2);
              }
              if (!validated) {
                validateEmailAndPhone();
              }
            }
            if (Number(param) === 1) {
              setStep(1);
            }
          }
        }}
      >
        <div className="sign-up-form-content">
          {step === 1 && (
            <>
              <div className="big-text-wrap">
                <p className="text">
                  <span className="grey-white-color">Submit</span> <br />
                  Authentication details
                </p>
              </div>
              <RavenInputField
                type={`email`}
                label={`Email address * `}
                placeholder="Your email address here"
                color={`black-light`}
                name="email"
                onChange={(e) => {
                  handleChange(e);
                  setError((prev) => {
                    return { ...prev, email: false };
                  });
                }}
                value={details?.email}
                showError={error?.email}
              />
              <RavenInputField
                type={`phone`}
                label={`Phone number *  `}
                placeholder="Your phone number here"
                color={`black-light`}
                name="phone"
                onChange={handleChange}
                value={details?.phone}
                // value={
                //   details?.phone.slice(0, 3) === "234"
                //     ? details?.phone.replace("234", "0")
                //     : details?.phone.slice(0, 1) === "0"
                //     ? details?.phone.replace("0", "")
                //     : details?.phone
                // }
              />
              <form
                className="password-wrapper-check"
                autoComplete="off"
                style={{ width: "100%", position: "relative", zIndex: 100 }}
                action=""
                autoCorrect="off"
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              >
                <RavenInputField
                  type={`password`}
                  label={`Password *`}
                  placeholder="Your password here"
                  color={`black-light`}
                  name="password"
                  onChange={(e) => {
                    handleChange(e);
                    // validatePassword(e.target.value);
                    setError((prev) => {
                      return { ...prev, password: false };
                    });
                  }}
                  showError={error?.password}
                  value={details?.password}
                  showPasswordStrength
                />
                {/* check drop box start */}

                {/* check drop box end */}
              </form>

              <form
                className="password-wrapper-check"
                autoComplete="off"
                style={{ width: "100%", position: "relative", zIndex: 99 }}
                action=""
                autoCorrect="off"
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              >
                <RavenInputField
                  type={`password`}
                  label={`Confirm Password *`}
                  placeholder="Confirm password here"
                  color={`black-light`}
                  name="confirm_password"
                  onChange={(e) => {
                    handleChange(e);
                    setError((prev) => {
                      return { ...prev, password: false };
                    });
                  }}
                  value={details?.confirm_password}
                  //   showPasswordStrength
                />
                {/* check drop box start */}

                {/* check drop box end */}
              </form>

              <RavenInputField
                disabled={
                  !details?.email ||
                  !details.password ||
                  !details?.phone ||
                  !details?.confirm_password
                }
                loading={loading}
                className={`btn-submit ${
                  getSystemThemePreference() === "dark" && "btn-submit-white"
                }`}
                type={"submit"}
                value="Proceed to next step"
                color={`black-light`}
                onSubmit={validateEmailAndPhone}
                // purpleGradient={ getSystemThemePreference()  === 'dark' ? true : false}
                style={{
                  backgroundColor:
                    getSystemThemePreference() === "dark" && "#ffffff",
                  color: getSystemThemePreference() === "dark" && "#020202",
                }}
              />
              {/* already have accout start */}
              <p className="already-text">
                Already have an account?{" "}
                <span
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Login
                </span>
              </p>
              {/* already have account end */}
            </>
          )}

          {step === 2 && (
            <>
              <div className="big-text-wrap">
                <p className="text">
                  <span className="grey-white-color">Submit</span> <br />
                  Business details
                </p>
              </div>
              <RavenInputField
                type={`text`}
                label={`Business Name * `}
                placeholder="E.g Timi Limited."
                color={`black-light`}
                name="business_name"
                onChange={handleChange}
                value={details?.business_name}
              />
              <div className="two-group">
                <RavenInputField
                  type={`select`}
                  label={`State * `}
                  placeholder="E.g Lagos"
                  selectStyles={reactSelectStyle}
                  color={`black-light`}
                  name="state"
                  onChange={(e) => {
                    dispatch(getLgaByStates(e?.label));
                    setDetails((prev) => {
                      return { ...prev, state: e, lga: "" };
                    });
                  }}
                  value={details?.state}
                  selectOption={states}
                  style={{ position: "relative", zIndex: "15" }}
                />
                <RavenInputField
                  type={`select`}
                  label={`Local government *`}
                  selectStyles={reactSelectStyle}
                  placeholder="E.g Eti Osa"
                  color={`black-light`}
                  name="lga"
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, lga: e };
                    });
                  }}
                  value={details?.lga}
                  selectOption={Lgas}
                  style={{ position: "relative", zIndex: "14" }}
                />
              </div>
              <RavenInputField
                type={`text`}
                label={`Business address * `}
                placeholder="e.g Lagos, Nigeria."
                color={`black-light`}
                name="business_address"
                onChange={handleChange}
                value={details?.business_address}
              />
              <RavenInputField
                type={`select`}
                label={`Monthly transaction range* `}
                placeholder="E.g 1,000,000 - 5,000,00"
                color={`black-light`}
                name="transaction_range"
                selectStyles={reactSelectStyle}
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, transaction_range: e };
                  });
                }}
                onMenuOpen={() => {
                  setActiveSelect("range");
                }}
                value={details?.transaction_range}
                style={{
                  position: "relative",
                  zIndex: activeSelect === "range" ? "13" : "10",
                }}
                selectOption={monthly_transaction_range}
              />
              <RavenInputField
                type={`select`}
                onMenuOpen={() => {
                  setActiveSelect("staff");
                }}
                style={{
                  position: "relative",
                  zIndex: activeSelect === "staff" ? "13" : "10",
                }}
                label={`No of staff*`}
                placeholder="E.g 1-10"
                color={`black-light`}
                name="number_of_staff"
                selectStyles={reactSelectStyle}
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, number_of_staff: e };
                  });
                }}
                value={details?.number_of_staff}
                selectOption={number_of_staff}
                menuPlacement={`top`}
              />
              {/* <div className="two-group">
             
              </div> */}

              <div className="check-text-box">
                <RavenCheckBox
                  value={details?.terms}
                  onChange={(e) =>
                    setDetails((prev) => {
                      return { ...prev, terms: e.target?.checked };
                    })
                  }
                  className={`check-box`}
                  color={`black-light`}
                />
                <p className="text">
                  By clicking on Get started you agree to the{" "}
                  <span
                    onClick={() => {
                      setShowTerms(true);
                    }}
                  >
                    {" "}
                    {/* <a
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "rgba(117, 90, 226, 1)",
                      }}
                      href="https://getravenbank.com/terms-and-conditions?tab=raven%2520business"
                    >
                      terms and conditions
                    </a> */}
                    terms and conditions{" "}
                  </span>{" "}
                  guiding this services
                </p>
              </div>
              {/* text check box end */}
              <RavenInputField
                disabled={
                  !details?.email ||
                  !details.password ||
                  !details?.phone ||
                  !details?.business_name ||
                  !details?.terms ||
                  !details?.state ||
                  !details?.transaction_range ||
                  !details?.business_address ||
                  !details?.number_of_staff ||
                  !details?.lga
                }
                value="Proceed to next step"
                type={"submit"}
                color={`black-light`}
                onSubmit={handleSubmit}
                loading={loading}
              />
              {/* already have accout start */}
              <p className="already-text">
                Already have an account?{" "}
                <span
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Login
                </span>
              </p>
              {/* already have account end */}
            </>
          )}
          {/* step 2 end */}
        </div>
      </AuthLayoutOneFive>
      {/* layout end */}
      {/* sign up modal start */}
      <SignUpModal
        userEmail={details?.email}
        // showModal={true}
        detail={details}
        inputPattern="[0-9]+"
        showModal={showModal?.otp}
        onClose={() =>
          setShowModal((prev) => {
            return { ...prev, otp: false };
          })
        }
        onSuccess={() =>
          setShowModal((prev) => {
            return { ...prev, success: true, otp: false };
          })
        }
      />
      {/* sign up modal end */}
      {/* sign up modal start */}
      <SuccessModal
        authType
        visible={showModal?.success}
        onClose={() =>
          setShowModal((prev) => {
            return { ...prev, success: false };
          })
        }
        title="Registration Successful."
        text={`You have successfully registered an account with Raven Business Bank, You can now log in to your account using the credentials you provided during registration. `}
        btnText={`Go to Dashboard`}
        onBtnClick={() => {
          navigate("/dashboard-verification-bvn");
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}

      <TermsAndConditionModal
        agreeBox={details?.terms}
        setAgreeBox={(param) => {
          setDetails((prev) => {
            return { ...prev, terms: param };
          });
        }}
        visible={showTerms}
        onClose={() => {
          setShowTerms(!showTerms);
        }}
        srcUrl={`https://getravenbank.com/terms-and-conditions?tab=raven%2520business&hideLayout=true`}
      />
    </>
  );
};

export default SignUpOneFive;
