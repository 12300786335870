import { RavenButton, RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import "../../styles/reusables/SuccessModal.css";

const SuccessModal = ({ visible, onBtnClick, title, text, btnText, authType }) => {
  return (
    <RavenModal
      className={`success-modal-reuse-wrap`}
      visble={visible}
      effect={`fadeInDown`}
    >
      <div className="container-wrap">
        <div className="success-img-box">
          <div className="wrap"></div>
        </div>
        <p style={{color: authType && "#020202"}} className="title">{title || "Payment Successful."}</p>
        <p className="text grey-white-color">
          {text ||
            "Your payment link has been created successfully. Copy link below to send to your customers for payment."}
        </p>
      </div>
      <div className="button-wrapper">
        <RavenButton
          color={`black-light`}
          label={btnText || "Delete"}
          size={`medium`}
          onClick={onBtnClick}
        />
      </div>
    </RavenModal>
  );
};

export default SuccessModal;
