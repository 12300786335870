/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import { toast } from "@ravenpay/raven-bank-ui";

export const createCustomers = createAsyncThunk(
  "create/customer",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/customer/create_a_customer", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getCustomers = createAsyncThunk(
  "get/customers",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `customer?per_page=${payload.per_page}&current_page=${
          payload.current_page
        }&search_term=${payload?.search || ""}&start_date_end_date=${payload?.date || ""}`,
        payload
      );
    //   console.log(data, "------------------------------");
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setCustomers(data?.data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getCustomersWithoutPagination = createAsyncThunk(
    "get/links",
    async (payload, thunkAPI) => {
      try {
        const { data } = await axios.get(
          `/customers_for_invoice`,
          payload
        );
        // console.log(data, "-------");
        if (data.status !== "success") {
          // toast.error(data.message, {
          //   theme: 'colored'
          // });
          return data;
        }
        if (data.status === "success") {
          //   toast.success(data.data.message, {
          //     theme: "colored",
          //   });
        //   console.log(data.data, "-------");
          await thunkAPI.dispatch(setCustomersWithoutPagination(data?.data));
          return data;
        }
      } catch (err) {
        //
        // ;
        if (err.response.data.status === "fail" && err.response.status !== 401) {
          toast.error(err.response.data.message, {});
        }
        return err;
      }
    }
  );

export const getPaymentLinksByFilter = createAsyncThunk(
  "admin/get-payment-links-filter",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getCustomer = createAsyncThunk(
  "admin/get-payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`/customer/${payload}`, payload);
    //   console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setPaymentLink(data?.data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getCustomersPagination = createAsyncThunk(
  "admin/get-payment-links-pagination",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(`get/links/${payload}`, payload);
      // console.log("register", data);

      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setCustomers(data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "admin/update-payment-link",
  async (payload, thunkAPI) => {
    try {
    //   const { id } = payload;
      const data = await axios.post(`/customer/update_a_customer`, payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const ValidatePaymentLinkRef = createAsyncThunk(
  "admin/uvalidate-payment-link",
  async (payload, thunkAPI) => {
    try {
      // const { id } = payload;
      const data = await axios.post(`validate/link_ref`, payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const ProceedPaymentLinkToSdk = createAsyncThunk(
  "admin/procced-payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`/link/add_transaction`, payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const sharePaymentLink = createAsyncThunk(
  "admin/share-payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`/link/share`, payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "admin/delete-payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/customer/delete_single_customer", payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const disablePaymentLink = createAsyncThunk(
  "admin/disable-payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/link/suspend", payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const regeneratePaymentLink = createAsyncThunk(
  "admin/regenerate-link",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const customers = createSlice({
  name: "customers",
  initialState: {
    customers: [],
    all_customers: [],
    customer: {},
    isAuth: false,
    loading: false,
    fetachingUpdate: false,
    loadingSingle: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setCustomers: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.customers = action.payload;
    },
    setCustomersWithoutPagination: (state, action) => {
        //   localStorage.setItem("user", JSON.stringify(action.payload));
        state.isAuth = true;
        state.all_customers = action.payload;
      },
    setPaymentLink: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.customer = action.payload;
    },
  },
  extraReducers: {
    [getCustomers.pending]: (state) => {
      state.loading = true;
    },
    [getCustomers.fulfilled]: (state) => {
      state.loading = false;
    },
    [getCustomers.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getCustomer.pending]: (state) => {
      state.fetachingUpdate = true;
    },
    [getCustomer.fulfilled]: (state) => {
      state.fetachingUpdate = false;
    },
    [getCustomer.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.fetachingUpdate = false;
      state.isAuth = false;
      state = null;
    },
    [sharePaymentLink.pending]: (state) => {
      state.loadingSingle = true;
    },
    [sharePaymentLink.fulfilled]: (state) => {
      state.loadingSingle = false;
    },
    [sharePaymentLink.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSingle = false;
      state.isAuth = false;
      state = null;
    },
    [createCustomers.pending]: (state) => {
      state.loading = true;
    },
    [createCustomers.fulfilled]: (state) => {
      state.loading = false;
    },
    [createCustomers.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteCustomer.pending]: (state) => {
      state.loading = true;
    },
    [deleteCustomer.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteCustomer.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [disablePaymentLink.pending]: (state) => {
      state.loading = true;
    },
    [disablePaymentLink.fulfilled]: (state) => {
      state.loading = false;
    },
    [disablePaymentLink.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateCustomer.pending]: (state) => {
      state.loading = true;
    },
    [updateCustomer.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateCustomer.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getCustomersPagination.pending]: (state) => {
      state.loading = true;
    },
    [getCustomersPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getCustomersPagination.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, setCustomers, setPaymentLink, setCustomersWithoutPagination } = customers.actions;

export default customers.reducer;
