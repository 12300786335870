import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TransactionBarChart from "../../../components/charts/BarChart";
import ActionContext from "../../../context/ActionContext";
import "../../../styles/dashboard/transaction/DashboardTransaction.css";
import {
  formatNumWithCommaNaira,
  reactSelectStyle,
} from "../../../utils/helper/Helper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch } from "react-redux";
import { getTransactionsChart } from "../../../redux/transaction";

const TransactionCardBarChart = ({ fetchTransaction }) => {
  const [dataToUse, setDataToUse] = useState({
    labels: [],
    datasets: [
      {
        label: "Income",
        data: [],
        backgroundColor: "#755AE2",
        hoverBackgroundColor: "#755AE2",
      },
      {
        label: "Expense",
        data: [],
        backgroundColor: "#DBD6FC",
        hoverBackgroundColor: "#DBD6FC",
      },
    ],
  });
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const { transactions_two, loading, transactionChart } = useSelector(
    (state) => state.transaction
  );
  //   1_days, 1_years, 1_months, 2_monthsgetTransactionsChart
  const filterOptions = [
    { label: "Today", value: "1_days" },
    { label: "1 Week", value: "7_days" },
    { label: "1 Month", value: "1_months" },
    { label: "2 Months", value: "2_months" },
    { label: "3 Months", value: "3_months" },
    { label: "6 Months", value: "6_months" },
    { label: "A Year", value: "12_months" },
  ];

  const [filterVal, setFilterVal] = useState({ label: "All", value: "all" });

  useEffect(() => {
    getChartInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  const getChartInfo = async () => {
    const obj = {
      date: filterVal?.value,
    };
    const data = await dispatch(getTransactionsChart(obj));
    if (data?.payload?.status === "success") {
      const chartData = data?.payload?.data;
      const labelArray = chartData?.labelArray;
      //   console.log(chartData);
      const obj = {
        labels: labelArray,
        datasets: [
          {
            label: "Income",
            data: chartData?.incomeArray,
            backgroundColor: "#755AE2",
            hoverBackgroundColor: "#755AE2",
          },
          {
            label: "Expense",
            data: chartData?.expenseArray,
            backgroundColor: "#DBD6FC",
            hoverBackgroundColor: "#DBD6FC",
          },
        ],
      };
      setDataToUse(obj);
    }
  };

  return (
    <div
      className={`transaction-card-bar-chart-wrap ${
        actionCtx?.showChart && "transaction-card-bar-chart-wrap-show"
      }`}
      style={{ border: !actionCtx?.showChart && "none" }}
    >
      {actionCtx?.showChart && (
        <div className="underline border-theme-bottom"></div>
      )}
      {/* chart-info-content-box start */}
      <div
        onClick={() => {
          // console.log(transactionChart);
        }}
        className="chart-info-content-box"
      >
        <div className="left-box border-theme-right">
          {/* card info start */}
          <div
            onClick={() => {
              fetchTransaction("Money Received");
            }}
            className="card-info border-theme"
          >
            <div className="total-val-box border-theme-bottom">
              <div className="label-val">
                <p className="label grey-white-color">Total income</p>
                {loading ? (
                  <Skeleton width={`100%`} height={20} />
                ) : (
                  <p className="value">
                    {" "}
                    ₦
                    {formatNumWithCommaNaira(
                      transactions_two?.total_income || "0.00"
                    )}
                  </p>
                )}
              </div>
              <div className="img-wrap">
                <figure className={`img-box-cre-deb  img-box-debit-credit`}>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M9 1L1 9M1 9H6.33333M1 9V3.66667"
                      stroke="#1ACE37"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </figure>
              </div>
            </div>
            <p className="text-compare grey-white-color">
              <span
                style={{
                  color:
                    transactionChart?.percentage_difference_for_income
                      ?.direction &&
                    transactionChart?.percentage_difference_for_income
                      ?.direction !== "increase" &&
                    "#FF0F00",
                }}
              >
                {transactionChart?.percentage_difference_for_income
                  ?.percentage || "0"}
                %
              </span>{" "}
              {`      ${
                transactionChart?.percentage_difference_for_income?.direction ||
                ""
              } compared to last week`}
            </p>
            {/* <p className="filter-by-text">Filter transaction by income only</p> */}
          </div>
          {/* crd info end */}
          {/* card info start */}
          <div
            onClick={() => {
              fetchTransaction("Money Sent");
            }}
            className="card-info border-theme"
          >
            <div className="total-val-box border-theme-bottom">
              <div className="label-val">
                <p className="label grey-white-color">Total expense</p>
                {loading ? (
                  <Skeleton width={`100%`} height={20} />
                ) : (
                  <p className="value">
                    {" "}
                    ₦
                    {formatNumWithCommaNaira(
                      transactions_two?.total_expenses || "0.00"
                    )}
                  </p>
                )}
              </div>
              <div className="img-wrap">
                <figure className={`img-box-cre-deb  img-box-debit`}>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M1 9L9 1M9 1H3.66667M9 1V6.33333"
                      stroke="#FF0F00"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </figure>
              </div>
            </div>
            <p className="text-compare grey-white-color">
              <span
                style={{
                  color: "red",
                }}
              >
                {transactionChart?.percentage_difference_for_expense
                  ?.percentage || "0"}
                %
              </span>{" "}
              {`      ${
                transactionChart?.percentage_difference_for_expense
                  ?.direction || ""
              } compared to last week`}
            </p>
            {/* <p className="filter-by-text">Filter transaction by expense #FF0F00 only</p> */}
          </div>
          {/* crd info end */}
        </div>
        {/* end of left-box  */}
        {/* right chart box start */}
        <div className="right-box">
          {/* top info start */}
          <div className="top-info-box">
            <div className="total-wallet-box">
              <p className="label grey-white-color">Total Volume balance</p>
              <p className="value">
                {" "}
                ₦
                {formatNumWithCommaNaira(
                  transactions_two?.total_expenses +
                    transactions_two?.total_income || "0.00"
                )}
              </p>
              <div className="update">
                <p className="text grey-white-color">Last updated 1m ago</p>{" "}
                <svg
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 10.25C7.34721 10.25 9.25 8.34726 9.25 6.00005C9.25 4.58728 8.56067 3.33551 7.5 2.56275M5.5 11.2L4.5 10.2L5.5 9.20005M5 1.75005C2.65279 1.75005 0.75 3.65284 0.75 6.00005C0.75 7.41282 1.43933 8.66458 2.5 9.43735M4.5 2.80005L5.5 1.80005L4.5 0.800049"
                    stroke="#676767"
                    strokeWidth="1.22814"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            {/* end of total wallet */}
            <div className="label-select-box">
              <div className="text-label">
                <span className="expense"></span>
                <p className="text grey-white-color">Expense</p>
              </div>
              <div className="text-label">
                <span></span>
                <p className="text grey-white-color">Income</p>
              </div>

              {/* select start */}
              <form autoComplete="off" action="">
                <RavenInputField
                  className="pos-select-wrap"
                  type={`select`}
                  value={filterVal || { label: " A year", value: "Today" }}
                  selectStyles={reactSelectStyle}
                  color={"black-light"}
                  selectOption={filterOptions}
                  // selectMenuOpen
                  onChange={(e) => {
                    setFilterVal(e);
                  }}
                />
              </form>
              {/* select nd */}
            </div>
          </div>
          {/* top info end */}
          {/* bottom info start */}
          <div className="bottom-info-box">
            {/* bar chart start */}
            <div className="bar-chat-box">
              <TransactionBarChart datatoSHow={dataToUse} />
            </div>
            {/* bar chart end */}
          </div>
          {/* bottom info end */}
        </div>
        {/* right chart box end */}
      </div>
      {/* chart-info-content-box end */}
      {/*  */}
      {actionCtx?.showChart && (
        <div className="underline border-theme-bottom"></div>
      )}
    </div>
  );
};

export default TransactionCardBarChart;
