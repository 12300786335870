import { RavenCheckBox, RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import {
  createRoleAndPermissions,
  getAllExistingUsers,
  getResourceRolesAndPermissions,
} from "../../../redux/roleAndPermission";
import "../../../styles/dashboard/role-and-permission/SingleRoleAndPermissionIndex.css";
import { formatTypeFunction, lowerCaseWrap, trimLongString } from "../../../utils/helper/Helper";
import useWindowSize from "../../../utils/helper/UseWindowSize";

const CreateRoleAndPermission = () => {
  const navigate = useNavigate();
  const size = useWindowSize()
  const dispatch = useDispatch();

  const { resource_role_permission, loading, all_existing_users } = useSelector(
    (state) => state.role_and_permission
  );
  const stepList = ["Role Information", "Add Team member"];
  const [activeStep, setActiveStep] = useState(1);
  const [details, setDetails] = useState({
    title: "",
    des: "",
    managers: [],
  });
  const [userIdList, setUserIdList] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const [permissionList, setPermissionList] = useState([]);
  const [openRole, setOpenRole] = useState("");

  const arrowIcon = (
    <svg
      className="img dull-bright-filter"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M6 12L10 8L6 4"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    dispatch(getResourceRolesAndPermissions());
    dispatch(getAllExistingUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(resource_role_permission);
  }, [resource_role_permission]);

  const handleSubmit = async () => {
    // const filteredObjectsReal = resource_role_permission?.map((obj) => ({
    //   ...obj,
    //   resources_roles_and_permission:
    //     obj?.resources_roles_and_permission?.filter((permission) =>
    //       permissionList?.includes(
    //         permission?.description_for_action_and_resource
    //       )
    //     ),
    // }));

    const modifiedObjects = resource_role_permission?.map((obj) => ({
      ...obj,
      resources_roles_and_permission: obj?.resources_roles_and_permission?.map(
        (permission) => ({
          ...permission,
          deny: permissionList?.includes(
            permission?.description_for_action_and_resource
          ),
        })
      ),
    }));
    const filterListTwo = modifiedObjects?.map((chi) => {
      return {
        permission: chi?.resources_roles_and_permission?.map((chid) => {
          return {
            action_id: String(chid?.action_id),
            resource_id: String(chi?.resource_object?.id),
            permission: !chid?.deny ? "deny" : "allow",
          };
        }),
      };
    });
    const filteredArray = all_existing_users?.filter((obj) =>
      userIdList?.includes(obj.user_id)
    );
    const newList = filteredArray?.map((chi) => {
      return {
        role: chi?.clearance || chi?.role_unique_name,
        user_id: String(chi?.user_id),
      };
    });

    const obj = {
      role: details?.title,
      description: details?.des,
      permissions_settings: filterListTwo?.reduce((accumulator, currentObject) => {
        // Concatenate the permissions array of the current object with the accumulator array.
        return accumulator.concat(currentObject.permission);
      }, []),
      user_and_assigned_roles: newList?.length > 0 ? newList : [],
    };
    // console.log(filterListTwo);
    // console.log(filteredArray);
    // console.log(obj);
    // return;
    const data = await dispatch(createRoleAndPermissions(obj));
    if (data?.payload?.data?.status === "success") {
      navigate("/dashboard-settings-role-permission");
    }
  };

  
  const noteIcon = (
    <svg
      className="img"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16536_99374)">
        <path
          d="M8.09961 10.8002V8.10022M8.09961 5.40022H8.10636M14.8496 8.10022C14.8496 11.8281 11.8275 14.8502 8.09961 14.8502C4.37169 14.8502 1.34961 11.8281 1.34961 8.10022C1.34961 4.3723 4.37169 1.35022 8.09961 1.35022C11.8275 1.35022 14.8496 4.3723 14.8496 8.10022Z"
          stroke="#EA872D"
          strokeWidth="1.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16536_99374">
          <rect
            width="16.2"
            height="16.2"
            fill="white"
            transform="translate(0 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Create new role`}
          text={`Manage your roles and permission here`}
          back
          onBack={() => {
            // console.log(single_sub_account);
            navigate("/dashboard-settings-role-permission");
          }}
        />
        {/* title box end */}
        {/* container start */}
        <ContainerWrapper
          btnText={activeStep === 1 ? "Proceed" : "Create role"}
          activeStep={activeStep}
          stepList={stepList}
          disabled={
            activeStep === 1
              ? !details.des || !details.title || permissionList.length < 1
              : activeStep === 2
              ? !details.des || !details.title || permissionList.length < 1
              : false
          }
          onBtnClick={() => {
            if (activeStep === 1) {
              setActiveStep(activeStep + 1);
            }
            if (activeStep === 2) {
              handleSubmit();
            }
          }}
          back={activeStep > 1}
          onBack={() => {
            if (activeStep > 1) {
              setActiveStep(activeStep - 1);
            }
          }}
          loading={loading}
          width={size.width < 900 && `100%`}
        >
          <div className="creste-role-and-permission-form-wrap">
            <div className="form-wrap-start">
              {/* start of step 1 ------------------ */}
              {activeStep === 1 && (
                <>
                  <form autoComplete="off" action="">
                    <RavenInputField
                      label="Role Title* "
                      color="black-light"
                      type="text"
                      placeholder="E.g Marketing Team"
                      name="title"
                      value={details.title}
                      onChange={handleChange}
                    />
                  </form>
                  <form autoComplete="off" action="">
                    <RavenInputField
                      label="Role Descriptions*"
                      color="black-light"
                      type="textarea"
                      placeholder="Write your thoughts here... "
                      name="des"
                      value={details.des}
                      onChange={handleChange}
                    />
                  </form>
                  {/* Add permission to role box start */}
                  <div className="add-permission-to-role-box">
                    <p className="label-title">Add Permissions to Role *</p>
                    {/* permission role show box start */}
                    {resource_role_permission?.length > 0 && (
                      <div className="role-permission-show-box border-theme">
                        {resource_role_permission?.map((chi, idx) => {
                          const {
                            resource_object,
                            resources_roles_and_permission,
                          } = chi;
                          return (
                            <div
                              key={idx}
                              style={{
                                paddingBottom:
                                  openRole === resource_object?.name &&
                                  resource_role_permission?.length - 1 !== idx &&
                                  "1.5rem",
                              }}
                              className="role-per-box border-theme-bottom"
                            >
                              {/* top start */}
                              <div
                                onClick={() => {
                                  openRole === resource_object?.name
                                    ? setOpenRole("")
                                    : setOpenRole(resource_object?.name);
                                }}
                                style={{
                                  borderTopLeftRadius: idx === 0 && "1.2rem",
                                  borderTopRightRadius: idx === 0 && "1.2rem",
                                }}
                                className={`top-box ${
                                  openRole === resource_object?.name &&
                                  "grey-bg"
                                }`}
                              >
                                <p className="role">
                                  {lowerCaseWrap(resource_object?.name) ||
                                    "---"}
                                </p>
                                <figure
                                  className={`img-box ${
                                    openRole === resource_object?.name &&
                                    "img-box-up"
                                  }`}
                                >
                                  {arrowIcon}
                                </figure>
                              </div>
                              {/* top end */}
                              {/* bottom box start v */}
                              <div
                                className={`bottom-box ${
                                  openRole === resource_object?.name &&
                                  "bottom-box-show"
                                }`}
                              >
                                <div  className="all-permission-box">
                                  {resources_roles_and_permission?.map(
                                    (chid, i) => {
                                      const {
                                        description_for_action_and_resource,
                                        action_id,
                                        name,
                                        resource_id,
                                      } = chid;
                                      return (
                                        <div
                                          key={`${name}-${resource_id}-${action_id}`}
                                          className="permission-item"
                                        >
                                          <RavenCheckBox
                                            color="purple-light"
                                            id={`${name}-${resource_id}-${action_id}`}
                                            checked={permissionList?.includes(
                                              description_for_action_and_resource
                                            )}
                                            onChange={() => {
                                              if (
                                                permissionList?.includes(
                                                  description_for_action_and_resource
                                                )
                                              ) {
                                                const newList =
                                                  permissionList?.filter(
                                                    (chi) =>
                                                      chi !==
                                                      description_for_action_and_resource
                                                  );
                                                setPermissionList(newList);
                                              } else {
                                                const newList = [
                                                  ...permissionList,
                                                  description_for_action_and_resource,
                                                ];
                                                setPermissionList(newList);
                                              }
                                            }}
                                          />
                                          <p className="permission grey-white-color">
                                            {
                                              description_for_action_and_resource
                                            }
                                          </p>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                              {/* bottom box end */}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* permission role show box end */}
                  </div>
                  {/* Add permission to role box end */}
                </>
              )}

              {/* end of step 1 ------------------ */}
              {/* start of step 2 ------------------ */}
              {activeStep === 2 && (
                <>
                  <div className="add-team-to-role-box">
                    <p className="label-title">
                      Add existing team mate to this role
                    </p>
                    {/* team to add box start */}
                    {/* note box start */}
                    <div className="note-box-wrap grey-bg-two">
                      <figure className="img-box">{noteIcon}</figure>
                      <p className="text">
                        Please note that this step is optional, it can be
                        skipped and set later.
                      </p>
                    </div>
                    {/* note box end */}
                    {/* select manage wrap start */}
                    <div className="team-to-add-box select-manage-wrap">
                      {all_existing_users?.map((chi, idx) => {
                        const { name, clearance, user_id, lname, fname } = chi;
                        return (
                          <div
                            //   htmlFor={`${name}-${idx}`}
                            key={`${user_id}-${idx}`}
                            onClick={() => {
                              // console.log("yes");
                              // handleAddManagerToList(name);
                            }}
                            className={`select-item ${
                              all_existing_users?.length - 1 !== idx &&
                              "border-theme-bottom"
                            }`}
                          >
                            <div className="img-wrap">
                              <div className="avatar-box grey-bg">
                                <span className="">{`${fname?.charAt(
                                  0
                                )} ${lname?.charAt(0)}`}</span>
                              </div>
                            </div>
                            <div className="name-role-box">
                              <p className="name">{`${
                                lowerCaseWrap(fname) || "---"
                              } ${
                                lname?.length + fname?.length > 14
                                  ? trimLongString(lowerCaseWrap(lname), 5)
                                  : lowerCaseWrap(lname) || "---"
                              }`}</p>
                              <p className="role grey-white-color">
                                {formatTypeFunction(clearance) || "---"}
                              </p>
                            </div>
                            <div className="check-wrap">
                              <RavenCheckBox
                                color={`purple-light`}
                                className={``}
                                id={`${name}-${idx}`}
                                checked={
                                  userIdList?.includes(user_id) ? true : false
                                }
                                onChange={(e) => {
                                  if (userIdList?.includes(user_id)) {
                                    const newList = userIdList?.filter(
                                      (chi) => chi !== user_id
                                    );
                                    setUserIdList(newList);
                                  } else {
                                    const newList = [...userIdList, user_id];
                                    setUserIdList(newList);
                                  }
                                }}
                                key={`${name}-${idx}`}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* select manage wrap end */}
                  </div>
                </>
              )}
              {/* end of step 2 ------------------ */}
            </div>
          </div>
        </ContainerWrapper>
        {/* container end */}
      </DashboardLayout>
    </>
  );
};

export default CreateRoleAndPermission;
