import React from "react";
import VerificationIndexOneFive from "../VerificationIndexOneFive";
import ModalLayout from "./ModalLayout";
import { useSelector } from "react-redux";
// import ReUsuableStatus from "../ReUsuableStatus";

const TierTwoIndex = ({ children, stage, step }) => {
  const { business } = useSelector((state) => state.user);

  return (
    <>
      <VerificationIndexOneFive />
      <ModalLayout
        step={step}
        stage={
          String(business?.cac) === "3" &&
          String(business?.directors) === "3" &&
          String(business?.indemnity) === "3"
            ? 100
            : stage
        }
      >
        {children}
      </ModalLayout>
    </>
  );
};

export default TierTwoIndex;
