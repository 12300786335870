import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import {  deletBeneficiaryGlobal } from "../../../../redux/settings";
import { trimLongString } from "../../../../utils/helper/Helper";
import { getAllBeneficiaries } from "../../../../redux/globalAccount";
// import { getAllBeneficiaries } from "../../../../redux/globalAccount";

const DeleteBeneficiaryGlobalModal = ({ visible, onClose, detail }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.settings);

  const handleSubmit = async () => {
    // console.log(detail);
    const obj = {
        beneficiary_id: String(detail?.id),
    };
    // console.log(obj);
    // return;
    const data = await dispatch(deletBeneficiaryGlobal(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") { 
        dispatch(getAllBeneficiaries());
      onClose();
    }
  };

  return (
    <DeleteModal
      title={`Delete Beneficiary ${trimLongString(detail?.beneficiary_name || detail?.account_name, 15)}`}
      text={`Removing this beneficiary, would delete this ${detail?.bank_name || ""} details from your beneficiary, ensure you confirm this device before totally removing them from your account.`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
      loading={loading}
    />
  );
};

export default DeleteBeneficiaryGlobalModal;
