import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import "../../../../styles/dashboard/payroll/PayrollIndex.css";
import { formatDate } from "../../../../utils/helper/Helper";

const ProcessPayrollModal = ({ visible, onClose, onProceed, detail }) => {
  return (
    <RavenModal
      visble={visible}
      onClose={onClose}
      btnColor={`orange-light`}
      btnLabel={`Proceed to disburse payroll`}
      className={`process-payroll-modal-wrap`}
      onBtnClick={onProceed}
    >
      <div className="content-wrap">
        <p className="title">{`Review payroll date`}</p>
        <p className="text grey-white-color pin-test">
          You are trying to initiate your payroll before due date of
          disbursement are you sure you want to proceed to this payment. today
          instead of the{" "}
          <span style={{ fontWeight: "700", color: "#020202" }}>
            {formatDate(detail?.pay_date)?.split(" —")[0]}
          </span>
        </p>
      </div>
    </RavenModal>
  );
};

export default ProcessPayrollModal;
