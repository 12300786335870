import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../components/reusables/DeleteModal";
import { removeUserFromRoleAndPermissionsTwo } from "../../../redux/roleAndPermission";
import { getTeamMembers } from "../../../redux/settings";
import { lowerCaseWrap } from "../../../utils/helper/Helper";

const RemoveUserFromRoleTeamModal = ({
  visible,
  onClose,
  detail,
  onFinish,
}) => {
  const dispatch = useDispatch();
  const { loadingRemove } = useSelector((state) => state.role_and_permission);

  const handleSubmit = async () => {
    const obj = {
      email: detail?.user_email,
      role: detail?.clearance,
      status: "1"
    };
    // console.log(obj);
    // return
    const data = await dispatch(removeUserFromRoleAndPermissionsTwo(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      onFinish();
      dispatch(getTeamMembers());
    }
  };
  return (
    <DeleteModal
      title={`Remove  ${lowerCaseWrap(detail?.fname) || "---"} ${
        lowerCaseWrap(detail?.lname) || "---"
      } from ${lowerCaseWrap(detail?.clearance)} role?`}
      text={`Removing this user , would disable their access to your account on your business, ensure you confirm this users before totally removing them from your account.`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      loading={loadingRemove}
      onDelete={handleSubmit}
    />
  );
};

export default RemoveUserFromRoleTeamModal;
