import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DeleteModal from '../../../../components/reusables/DeleteModal'
import { cancelDisbursment } from '../../../../redux/payroll'

const CancelCurrentPayroll = ({visible, onCancel, detail, onFinish}) => {
  const {loading} = useSelector((state) => state.payroll)
  const dispatch = useDispatch();

    const handleDelete = async() => {
      // console.log(detail);
    const obj = {
      id: detail?.id,
      status: detail?.status === "cancelled" ? "pending_approval" : "cancelled"
    }
    const data = await dispatch(cancelDisbursment(obj))
    // console.log(data);
    if(data?.payload?.data?.status === "success"){
      onFinish()
    }
    }
  return (
    <DeleteModal
    loading={loading}
    visible={visible}
    buttonColor={detail?.status === "cancelled" ? "purple-light" : "error-light"}
    deleteText="Proceed"
    onCancel={onCancel}
    onDelete={handleDelete}
    title="Cancel Current Payroll"
    text={`Cancel your payroll for a certain time, this mean you won’t be able to perform payroll transactions.`}
  />
  )
}

export default CancelCurrentPayroll