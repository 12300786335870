import React from "react";
import NocontentPage from "../../../components/reusables/NocontentPage";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import noContentImg from "../../../assets/store-no-content-img.png";

const DashboardOverdraftIndex = () => {
  return (
    <DashboardLayout>
      <NocontentPage
        img={noContentImg}
        type="two"
        title={`Overdraft is coming soon 🔥`}
        normtext={`With Raven Overdraft, you'll have access to a variety of features and tools to customise your store and make it truly unique.`}
      />
    </DashboardLayout>
  );
};

export default DashboardOverdraftIndex;
