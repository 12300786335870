import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
// import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import "../../../styles/auth/SignUp.css";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-do m";
import { resendEmail, validateOtp } from "../../../redux/user";
import CryptoJS from "crypto-js";
import {
  clearCache,
  filterEmptyColumns,
//   trimLongString,
} from "../../../utils/helper/Helper";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const TwoFaModal = ({ showModal, onClose, userEmail, onSuccess, detail }) => {
  // const navigate = useNavigate();
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // const [showResend, setShowResend] = useState(false);
  const [toggleKey, setKeyToggle] = useState(false);
  const [completeToken, setCompleteToken] = useState(false);
  const [details, setDetails] = useState({
    token: "",
  });

  const [deviceIdVal, seDeviceIdVal] = useState("");
  useEffect(() => {
    // Generate a fingerprint
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();

      // Use the fingerprint as the device identifier
      const deviceId = result.visitorId;

      // Store the device identifier in local storage
      // console.log(deviceId);
      seDeviceIdVal(deviceId);
    };

    getFingerprint();
  }, []);

  useEffect(() => {
    setKeyToggle(!toggleKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleResendEmailOtp = async () => {
    const obj = {
      email: userEmail,
    };
    const data = await dispatch(resendEmail(obj));
    if (data?.payload?.data?.status === "success") {
      setKeyToggle(!toggleKey);
      // setShowResend(false);
    }
  };

  const encryptTokenFunc = (tok) => {
    const token = tok;
    const secretKey =
      "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

    // Encrypt the token
    const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
    return encryptedToken;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      ...detail,
      email: userEmail,
      otp: details?.token,
      device_id: deviceIdVal,
      state: detail?.state?.value,
      local_government: detail?.lga?.value,
      monthly_transaction_range: detail?.transaction_range?.value,
      number_of_staffs: detail?.number_of_staff?.value,
      address: detail?.business_address,
    };
    delete obj?.confirm_password;
    delete obj?.business_name;
    // console.log(filterEmptyColumns(obj));
    const data = await dispatch(validateOtp(filterEmptyColumns(obj)));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      clearCache();
      const token = data?.payload?.data?.data?.token;
      localStorage?.setItem("token", encryptTokenFunc(token));
      onSuccess();
    }
  };

  return (
    <RavenModal
      onClose={onClose}
      visble={showModal}
      btnLabel="Verify Account"
      btnColor={`black-light`}
      effect={`fadeInUp`}
      disabled={!completeToken}
      loading={loading}
      onBtnClick={handleSubmit}
      className={`auth-pin-modal-wrap`}
    >
      <form action="" className="form form-modal-auth">
        <div className="text-box">
          <p className="big-title">Enter 2FA Code</p>
          <p className="small-title">
            {`Please kindly provide the 6-Digit OTP sent to the  email provided 
to verify account.`}
          </p>
        </div>
        <RavenInputField
          type={`pin`}
          // label={`Enter token sent to your email`}
          color={`black-light`}
          onChange={(e) => {
            setCompleteToken(false);
            setDetails((prev) => {
              return { ...prev, token: e };
            });
          }}
          onComplete={() => {
            setCompleteToken(true);
          }}
          value={details?.token}
          showCountDown
          // countDownTime={1000}
          key={toggleKey}
          // onCountDownComplete={() => setShowResend(true)}
          countdownFinishClick={handleResendEmailOtp}
          countdownFinishText={` Resend OTP`}
          countdownFinishClassName={`resend-text`}
          // onCountdownFinish
          countdownLabelClassName={`black-white-color`}
        />
        {/* <p onClick={handleResendEmailOtp} className="resend-text">
          Resend OTP
        </p> */}
      </form>
    </RavenModal>
  );
};

export default TwoFaModal;
