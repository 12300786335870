import {
  RavenInputField,
  RavenModal,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
// import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
// import BillImage from "../../../assets/new-electricity-icon.png";
import //   generateBankStatementElectricity,
//   generateBankStatementElectricityTemplate,
//   inviteTeamMember,
//   searchBankStatementElectricity,
"../../../redux/settings";
import "../../../styles/dashboard/setting/SettingModal.css";
// style from electricity statement css and also for customer vew on invoice create---------
import {
  //   formatDate,
  //   formatNumWithCommaNairaSymbol,
  trimLongString,
} from "../../../utils/helper/Helper";
import useDebounce from "../../../utils/helper/UseDebounce";
// import NoContentBox from "../../../components/reusables/NoContentBox";

const ViewAllCustomersModal = ({ visible, onClose, setDetails }) => {
  //   const dispatch = useDispatch();
  const { all_customers } = useSelector((state) => state.customers);
  //   const { loading, electricityStatements, loadingSearch } = useSelector(
  //     (state) => state.settings
  //   );
  const [customerList, setCustomerList] = useState(all_customers);
  //   const [showActive, setShowActive] = useState("");
  const [customerSelect, setCustomerSelect] = useState("");
  //   const handleDownloadElectricitydownload = async () => {
  //     const obj = {
  //       transaction_id: String(showActive),
  //     };

  //     const data = await dispatch(generateBankStatementElectricityTemplate(obj));
  //     if (data?.payload?.data?.status === "success") {
  //       // console.log(data);
  //       downloadCsv(data?.payload?.data.data.url);
  //     }
  //   };

  //   const downloadCsv = (url) => {
  //     window.open(url, "_blank").focus();
  //   };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic
  const [search, setSearch] = useState("");
  //   const navigate = useNavigate
  function searchByName(param) {
    const lowerCaseValue = param.toLowerCase();
    const compareFunction = (a, b) => {
      const keysToSearch = [
        "customer_name",
        "customer_email",
        "customer_phone",
      ];

      const aContainsSearchValue = keysToSearch.some((key) =>
        a[key].toLowerCase().includes(lowerCaseValue)
      );
      const bContainsSearchValue = keysToSearch.some((key) =>
        b[key].toLowerCase().includes(lowerCaseValue)
      );

      if (aContainsSearchValue && !bContainsSearchValue) {
        return -1; // Move a before b
      } else if (!aContainsSearchValue && bContainsSearchValue) {
        return 1; // Move b before a
      } else {
        return 0; // Leave the order unchanged
      }
    };
    // console.log(all_customers);
    const sortedArray = all_customers.slice()?.sort(compareFunction);
    return sortedArray;
  }

  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      const newList = searchByName(search);
      setCustomerList(newList);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      setCustomerList(all_customers);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setCustomerList(all_customers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <RavenModal
      btnColor={`black-light`}
      disabled={!customerSelect}
      btnLabel={`Select Customer`}
      visble={visible}
      onClose={onClose}
      onBtnClick={() => {
        if (customerSelect?.id) {
          setDetails(customerSelect);
        } else {
          setDetails("");
        }
      }}
    >
      <div className="settings-modal-wrap">
        <div
          //   onClick={() => {
          //     console.log(all_customers);
          //   }}
          className="generate-modal-wrap"
        >
          <p className="title">All Exisiting Customers</p>
          <p className="text grey-white-color">
            Select customer to create invoice for
          </p>

          {all_customers?.length > 6 && (
            <div className="input-type-wrap input-type-wrap-two">
              <form action="" autoComplete="off" style={{ width: "100%" }}>
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`search`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={`Search customer`}
                />
              </form>
            </div>
          )}

          <div className="customer-invoice-child-wrap">
            {customerList?.map((chi, idx) => {
              const {
                customer_email,
                customer_phone,
                customer_name,
                number_of_invoices_issued,
              } = chi;
              return (
                <div
                  onClick={() => {
                    if (customerSelect?.id === chi?.id) {
                      setCustomerSelect("");
                      //   setDetails("");
                    } else {
                      setCustomerSelect(chi);
                      //   setDetails(chi)
                    }
                  }}
                  key={idx}
                  className={`customer-item border-theme  select-option-class  ${
                    customerSelect?.id === chi?.id &&
                    "select-option-class-active"
                  }`}
                >
                  <div className="check-box">
                    <FaCheck className="icon" />
                  </div>
                  <div className="avatar-box grey-bg">
                    <span>{customer_name?.split(" ")[0]?.charAt(0)}</span>
                    <span>{customer_name?.split(" ")[1]?.charAt(0)}</span>
                  </div>
                  <div className="detail-box">
                    <div className="name">
                      {trimLongString(customer_name, 25)}
                      {number_of_invoices_issued && (
                        <div className="num-box black-fade-white-bg tooltip-hover-wrap">
                          <RavenToolTip
                            position={
                              idx !== 0 ? `top-center` : `bottom-center`
                            }
                            color="black-light"
                            text="Number of issued invoice"
                          />
                          <span className="white-black-color">
                            {number_of_invoices_issued}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="email-num">
                      <p className="email grey-white-color">{customer_email}</p>{" "}
                      <span className="grey-white-color">•</span>
                      <p className="num grey-white-color">{customer_phone}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </RavenModal>
  );
};

export default ViewAllCustomersModal;
