import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import { getBulkTransactions } from "../../../redux/transaction";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import "../../../styles/dashboard/transaction/DashboardTransaction.css";
import Skeleton from "react-loading-skeleton";
import {
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import {
  formatDate,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  getTotalPage,
} from "../../../utils/helper/Helper";
import { FaArrowRight } from "react-icons/fa";
import PerPageComponent from "../../../components/reusables/PerPageComponent";
import ActionContext from "../../../context/ActionContext";

const AllBulkTransaction = () => {
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bulk_transactions, loading } = useSelector(
    (state) => state.transaction
  );
  const { user } = useSelector((state) => state.user);
//   const [perPage] = useState(10);
  const size = useWindowSize();
//   const [tableData, setTableData] = useState(false);

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.perPageVal]);

  const getTransactions = async (num) => {
    const payload = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: num || 1,
    };
    const data = await dispatch(getBulkTransactions(payload));
    // console.log(data);
    if (data.payload.data.transfers.data?.length > 0) {
    //   setTableData(true);
    }
    // else{
    //   navigate("/dashboard-transaction_");bulk_transactions
    // }
  };

  const tableHeader = ["Total Amount", "Total Sent", "Total Failed", "Date"];

  const transactionActions = (param) => {
    return (
      <div className="transaction-action-wrap">
        {loading ? (
          <>
            {/* <Skeleton width={35} height={35} circle /> */}
            <Skeleton width={35} height={35} circle />
          </>
        ) : (
          <>
            <figure
              onClick={() => {
                localStorage.setItem(
                  "bulk-ref-single-id",
                  param.reference_number
                );
                navigate("/dashboard-transaction-single-bulk");
              }}
              className="img-box grey-bg darken-action-bg tooltip-hover-wrap"
            >
              <RavenToolTip
                color={`black-light`}
                text={`View transaction details`}
                position={`left`}
              />
              <FaArrowRight className="grey-white-color" />
            </figure>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={` All Bulk Transactions`}
          text={`See all bulk transaction and click on a transfer to insight into each transactions`}
          back
          onBack={() => {
            navigate("/dashboard-transaction");
          }}
          //   exportBtn
          //   onExport={() => {
          //     setShowModal((prev) => {
          //       return { ...prev, statement: true };
          //     });
          //   }}
        />
        <ContainerWrapper
          pagination={
            bulk_transactions?.data?.length > 0 && (
              <RavenPagination
                color={`black-light`}
                blackHover
                numSpacing={size.width < 900 && 1}
                removeNext={size.width < 900}
                removePrev={size.width < 900}
                currentPage={Number(bulk_transactions?.current_page) || 1}
                nextPage={bulk_transactions?.next_page_url_params}
                prevPage={bulk_transactions?.prev_page_url_params}
                onNext={(e) => {
                  getTransactions(e);
                  // console.log(e);
                }}
                onPrev={(e) => {
                  getTransactions(e);
                  // console.log(e);
                }}
                totalPage={
                  getTotalPage(
                    bulk_transactions?.per_page,
                    bulk_transactions?.total
                  ) || 1
                }
                onNumView={(e) => {
                  getTransactions(e);
                }}
              />
            )
          }
          width={`100%`}
          dontShowHeader
        >
          <div className="bulk-transaction-main-wrap">
            {bulk_transactions?.data?.length > 0 ? (
              <>
                <div
                  className="table-wrap table-transaction-cover"
                  style={{ height: "100%" }}
                  onClick={() => {
                    // showFilterOption && setShowFilterOption(false);
                  }}
                >
                  {/* per page start */}
                  <PerPageComponent />
                  {/* per page end */}
                  {/* table start */}
                  <RavenTable
                    className={
                      formatMetaData(user?.theme_display_settings)
                        ?.table_font === "bold" && "table-business"
                    }
                    headerList={tableHeader}
                    action
                  >
                    {bulk_transactions?.data?.map((chi, idx) => {
                      const {
                        total_sent,
                        total_failed,
                        total_amount,
                        created_at,
                        id,
                      } = chi;
                      return (
                        <RavenTableRow
                          key={id || idx}
                          loading={loading}
                          one={formatNumWithCommaNairaSymbol(total_amount || 0)}
                          two={formatNumWithCommaNairaSymbol(total_sent || 0)}
                          three={formatNumWithCommaNairaSymbol(
                            total_failed || 0
                          )}
                          onRowClick={() => {
                            localStorage.setItem(
                              "bulk-ref-single-id",
                              chi.reference_number
                            );
                            navigate("/dashboard-transaction-single-bulk");
                          }}
                          four={formatDate(created_at) || "---"}
                          manualActionIcon={() => transactionActions(chi)}
                        />
                      );
                    })}
                  </RavenTable>
                  {/* table end */}
                </div>
              </>
            ) : (
              <NoContentBox
                title={`No bulk transaction found`}
                text={`Bulk transaction history will appear here when transaction is made`}
                bgColor
                loading={loading}
              />
            )}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
    </>
  );
};

export default AllBulkTransaction;
