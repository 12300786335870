import {
  // RavenDateTime,
  RavenInputField,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/schedule-payment/DashboardSchedulePayment.css";
import {
  formatDate,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  getPageNum,
  getTotalPage,
  lowerCaseWrap,
  reactSelectStyle,
  trimLongString,
} from "../../../utils/helper/Helper";
import ScheduleDeleteModal from "./ScheduleDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import ViewSchedulePayment from "./ViewSchedulePayment";
import {
  // getSchedule,
  getSchedulePayments,
} from "../../../redux/schedulePayment";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import noContentImg from "../../../assets/no-schedule-content-img.png";
import NocontentPage from "../../../components/reusables/NocontentPage";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";
import ActionContext from "../../../context/ActionContext";
import DateFragmentBox from "../../../components/fragments/DateFragmentBox";

const DashboardSchedulePayment = () => {
  const [refresehTable, setRefreshTable] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [singleChild, setSingleChild] = useState({});
  const { loading, schedules } = useSelector((state) => state.schedule);
  const [showModal, setShowModal] = useState({
    delete: false,
    view: false,
    reschedule: false,
  });
  const [dateFilter, setDateFilter] = useState("");
  const actionCtx = useContext(ActionContext);
  const headerList = [
    "NARRATION",
    "AMOUNT",
    "DATE CREATED",
    "SCHEDULED DATE",
    "TYPE",
    "STATUS",
  ];

  const formatTypeWrap = (param) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "0" && "status-type-wrap-pending"
        } ${param === "3" && "status-type-wrap-success"} ${
          param === "success" && "status-type-wrap-success"
        } ${param === "paid" && "status-type-wrap-success"} ${
          param === "2" && "status-type-wrap-fail"
        } ${param === "overdue" && "status-type-wrap-pending"} ${
          param === "1" && "status-type-wrap-pending"
        } ${param === "pending" && "status-type-wrap-pending"}`}
      >
        <span style={{ textTransform: "capitalize" }}>
          {param === "1"
            ? "Processing"
            : param === "0"
            ? "Pending"
            : param === "3"
            ? "Success"
            : param === "2"
            ? "Failed"
            : param === "11"
            ? "Reversal"
            : param}
        </span>
      </div>
    );
  };

  const narrationFormatWrap = (name, info) => {
    return (
      <div className="narration-wrap">
        <p className="name">{name}</p>
        <p className="info grey-white-color">{info}</p>
      </div>
    );
  };

  const batchTypeWrap = (param) => {
    return (
      <div className="type-wrap">
        <span style={{ textTransform: "capitalize" }}>{param}</span>
      </div>
    );
  };

  const selectOption = [
    { label: "All", value: "" },
    { label: "This Week", value: "1_week" },
    { label: "This month", value: "1_months" },
    { label: "Last 3 months", value: "3_months" },
    { label: "Last 6 Months", value: "6_months" },
  ];

  const filterByTimeFunc = async (param) => {
    const payload = {
      date: param,
    };
    dispatch(getSchedulePayments(payload));
  };

  useEffect(() => {
    getShedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresehTable]);

  const getShedules = async (param) => {
    const payload = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: param,
    };
    const data = await dispatch(getSchedulePayments(payload));
    if (data?.payload?.status === "success") {
      if (data?.payload?.data.data.length > 0) {
        setTableData(true);
      }
    }
  };

  const handleFinish = () => {
    setRefreshTable(!refresehTable);
    setShowModal((prev) => {
      return { ...prev, delete: false, reschedule: false, view: false };
    });
  };

  const setNewDateFuc = (param) => {
    const newObj = { ...singleChild, schedule_date: param };
    setSingleChild(newObj);
  };

  const scheduleActions = (param) => {
    return (
      <div className="schedule-action-wrap">
        {/* <figure
          className="img-box grey-bg darken-action-bg"
          // onClick={() => {
          //  dispatch(getSchedule(param))
          // }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="img dull-bright-filter"
          >
            <path
              d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
              stroke="#676767"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
              stroke="#676767"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
              stroke="#676767"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </figure> */}
        <figure
          onClick={() => {
            setSingleChild(param);
            setShowModal((prev) => {
              return { ...prev, view: true };
            });
          }}
          className="img-box grey-bg darken-action-bg tooltip-hover-wrap"
        >
          <RavenToolTip
            position={`left`}
            color={`black-light`}
            text={`View Schedule details`}
          />
          <svg
            className="img dull-bright-filter"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.81509 9.53488C1.71295 9.37315 1.66188 9.29229 1.63329 9.16756C1.61182 9.07387 1.61182 8.92613 1.63329 8.83244C1.66188 8.70771 1.71295 8.62685 1.81509 8.46512C2.65915 7.12863 5.17155 3.75 9.0003 3.75C12.8291 3.75 15.3415 7.12863 16.1855 8.46512C16.2877 8.62685 16.3387 8.70771 16.3673 8.83244C16.3888 8.92613 16.3888 9.07387 16.3673 9.16756C16.3387 9.29229 16.2877 9.37315 16.1855 9.53488C15.3415 10.8714 12.8291 14.25 9.0003 14.25C5.17155 14.25 2.65915 10.8714 1.81509 9.53488Z"
              stroke="#676767"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.0003 11.25C10.2429 11.25 11.2503 10.2426 11.2503 9C11.2503 7.75736 10.2429 6.75 9.0003 6.75C7.75766 6.75 6.7503 7.75736 6.7503 9C6.7503 10.2426 7.75766 11.25 9.0003 11.25Z"
              stroke="#676767"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </figure>
      </div>
    );
  };
  const [tableData, setTableData] = useState(false);

  return (
    <>
      <DashboardLayout>
        {schedules?.data?.length > 0 || tableData ? (
          <>
            <TitletextBox
              title={`Scheduled Payment`}
              text={`All created invoices in one section`}
            />
            <ContainerWrapper
              pagination={
                schedules.next_page_url_params ||
                schedules.prev_page_url_params ? (
                  <RavenPagination
                    color={`black-light`}
                    blackHover
                    currentPage={Number(schedules.current_page) || 1}
                    nextPage={schedules.next_page_url_params}
                    prevPage={schedules.prev_page_url_params}
                    onNext={(e) => {
                      getShedules(getPageNum(e));
                    }}
                    onPrev={(e) => {
                      getShedules(getPageNum(e));
                    }}
                    totalPage={
                      getTotalPage(
                        Number(schedules.per_page),
                        Number(schedules.total)
                      ) || 1
                    }
                    onNumView={(e) => {
                      getShedules(e);
                    }}
                  />
                ) : (
                  ""
                )
              }
              width={`100%`}
              dontShowHeader
            >
              {/* schedule wrapper start */}
              <div
                onClick={() => {
                  // console.log(schedules);
                }}
                className="dashboard-schedule-wrapper-box"
              >
                {/* title text box start */}
                <div
                  style={{ display: "flex", gap: "1.5rem", position: "relative", zIndex: 10000 }}
                  className="title-sort-text-box"
                >
                  {/* per page start */}
                  {/* <PerPageComponent /> */}
                  {/* per page end */}
                  {/* filter box start */}
                  <div className="fitler-box">
                    <form
                      action=""
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {" "}
                      <RavenInputField
                        selectOption={selectOption}
                        color={`black-light`}
                        type={"select"}
                        placeholder={`Filter By Schedule date`}
                        selectStyles={reactSelectStyle}
                        value={dateFilter}
                        onChange={(e) => {
                          setDateFilter(e);
                          filterByTimeFunc(e.value);
                        }}
                        selectValue={dateFilter}
                      />
                    </form>

                    {/* <RavenInputField
                  selectOption={selectOptionTransactionType}
                  color={`black-light`}
                  type={"select"}
                  placeholder={`Filter By Schedule type`}
                  selectStyles={reactSelectStyle}
                  value={typeFilter}
                  onChange={(e) => {
                    setTypeFilter(e);
                  }}
                  selectValue={typeFilter}
                /> */}
                    {dateFilter && (
                      <p
                        onClick={() => {
                          setDateFilter("");
                          filterByTimeFunc("");
                        }}
                        className="reset-text"
                      >
                        Reset Filter
                      </p>
                    )}
                  </div>
                  {/* filter box end */}
                </div>
                {/* title text box end */}

                {/* table wrapper start */}
                <div className="table-wrapper">
                  <RavenTable
                    className={`table-fixed-type ${
                      formatMetaData(user?.theme_display_settings)
                        ?.table_font === "bold" && "table-business"
                    }`}
                    headerList={headerList}
                    action
                  >
                    {schedules?.data?.map((chi, idx) => {
                      const {
                        amount,
                        category,
                        // created_at,
                        // schedule_date,
                        meta_data,
                        status,
                      } = chi;
                      return (
                        <RavenTableRow
                          loading={loading}
                          onRowClick={() => {
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, view: true };
                            });
                          }}
                          key={idx}
                          one={narrationFormatWrap(
                            formatMetaData(meta_data)?.account_name ||
                              formatMetaData(meta_data)?.phone_number ||
                              "",
                            `${
                              formatMetaData(meta_data)?.account_number ||
                              lowerCaseWrap(
                                formatMetaData(meta_data)?.network
                              ) ||
                              ""
                            } ${formatMetaData(meta_data)?.bank ? "•" : ""} ${
                              trimLongString(
                                formatMetaData(meta_data)?.bank,
                                20
                              ) ||
                              `${lowerCaseWrap(formatTypeFunction(category))}`
                            }`
                          )}
                          two={formatNumWithCommaNairaSymbol(amount)}
                          three={
                            <DateFragmentBox
                              date={chi?.created_at}
                              // dontShowAgo={true}
                            />
                          }
                          four={
                            <DateFragmentBox
                              date={chi?.schedule_date}
                              // dontShowAgo={true}
                            />
                          }
                          five={batchTypeWrap(formatTypeFunction(category))}
                          six={formatTypeWrap(String(status) || "---")}
                          ManualAddActions={() => scheduleActions(chi)}
                        />
                      );
                    })}
                  </RavenTable>

                  <div
                    onClick={() => {
                      // console.log(schedules?.data);
                    }}
                    className="mobile-table-box"
                  >
                    {schedules?.data?.map((chi, idx) => {
                      const {
                        amount,
                        category,
                        narration,
                        schedule_date,
                        meta_data,
                        status,
                      } = chi;
                      return (
                        <MobileTableCard
                          key={idx}
                          amount={formatNumWithCommaNairaSymbol(amount)}
                          text={` ${
                            trimLongString(
                              formatTypeFunction(
                                lowerCaseWrap(
                                  formatMetaData(narration)?.top_narration
                                )
                              )?.replace("-", " "),
                              15
                            ) ||
                            lowerCaseWrap(category) ||
                            trimLongString(
                              formatTypeFunction(
                                formatMetaData(narration)?.bottom_narration
                              )?.replace("-", " "),
                              15
                            ) ||
                            formatMetaData(meta_data)?.phone_number ||
                            formatMetaData(meta_data)?.meter_no ||
                            formatMetaData(meta_data)?.provider ||
                            formatMetaData(meta_data)?.network ||
                            ""
                          } • ${formatDate(schedule_date)?.split("—")[0]} `}
                          avatar={category}
                          onRowClick={() => {
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, view: true };
                            });
                          }}
                          loading={loading}
                          title={
                            trimLongString(
                              formatMetaData(meta_data)?.account_name,
                              25
                            ) ||
                            formatMetaData(meta_data)?.phone_number ||
                            trimLongString(
                              formatTypeFunction(
                                formatMetaData(narration)?.top_narration
                              )?.replace("-", " "),
                              15
                            ) ||
                            category
                          }
                          showImg
                          amountColor={
                            status === 0
                              ? "#ea872d"
                              : status === 2
                              ? `#FF0F00`
                              : "#1ACE37"
                          }
                        />
                      );
                    })}
                  </div>
                </div>
                {/* table wrapper end */}
              </div>
              {/* schedule wrapper end */}
            </ContainerWrapper>
          </>
        ) : (
          <NocontentPage
            loading={loading}
            img={noContentImg}
            type="two"
            title={`No Scheduled Payments`}
            normtext={`When you schedule a payment the transaction would show up here, start scheduling to see your transaction show up.`}
          />
        )}
      </DashboardLayout>
      <ScheduleDeleteModal
        visible={showModal?.delete}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, delete: false, view: true };
          });
        }}
        detail={singleChild}
        onFinish={handleFinish}
      />
      {/* view start */}
      <ViewSchedulePayment
        detail={singleChild}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, delete: true, view: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view: false };
          });
        }}
        visible={showModal?.view}
        onFinish={handleFinish}
        setNewDate={setNewDateFuc}
      />
      {/* view end */}
    </>
  );
};

export default DashboardSchedulePayment;
