import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { freezeAndUnfreezeSubAccount } from "../../../../redux/sub_account";

const DeleteAccountModal = ({ detail, visible, onClose, onFinish }) => {
  const { loadingTeamUpdate } = useSelector((state) => state.sub_account);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const obj = {
      account_id: String(detail?.id),
      status: detail?.freeze === 0 ? "1" : "0"
    }
    const data = await dispatch(freezeAndUnfreezeSubAccount(obj));
    if(data?.payload?.data?.status === "success"){
      onFinish()
    }
  };
  return (
    <DeleteModal
      title={`${detail?.freeze === 0 ? "Freeze" : "Unfreeze"} Sub Account ( ${
        detail?.custom_account_name
      } )`}
      text={`Removing this sub account, would disable their access to your account on your business, ensure you confirm this device before totally removing them from your account.`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
      loading={loadingTeamUpdate}
      buttonColor={detail?.freeze === 1 && `black-light`}
    />
  );
};

export default DeleteAccountModal;
