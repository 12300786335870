import // RavenButton,
// RavenPagination,
// RavenTable,
// RavenTableRow,
// RavenToolTip,
"@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/payroll/PayrollDisbursement.css";
// import downloadIcon from "../../../assets/download-statement-icon.svg";
// import editIcon from "../../../assets/payroll-edit-employee-icon.svg";
import DeleteUserModal from "./modals/DeleteUserModal";
// import { ThreeDots } from "react-loader-spinner";
import {
  generateStatementTransactionForAllEmpSingle,
  getSingleEmployee,
} from "../../../redux/payroll";
import { useSelector, useDispatch } from "react-redux";
import {
  formatDate,
  // formatMetaData,
  formatNumWithCommaNairaSymbol,
  // getPageNum,
  // getTotalPage,
  sumAllNum,
  trimLongString,
} from "../../../utils/helper/Helper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import GenerateSingleEmployeeModal from "./modals/GenerateSingleEmployeeModal";
import useWindowSize from "../../../utils/helper/UseWindowSize";
// import MobileTableCard from "../../../components/reusables/MobileTableCard";
import MobileEmployeeView from "./modals/MobileEmployeeView";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";
import ActionContext from "../../../context/ActionContext";
import NoContentBox from "../../../components/reusables/NoContentBox";
import DateFragmentBox from "../../../components/fragments/DateFragmentBox";
// import { RavenDateTime } from "@ravenpay/raven-bank-ui";

const EmployeeProfile = () => {
  const dispatch = useDispatch();
  // const { bankList, loadingVerify } = useSelector((state) => state.transaction);
  const { singleEmployee, loading } = useSelector((state) => state.payroll);
  const [refresehTable] = useState(false);
  const [showModal, setShowModal] = useState({
    delete: false,
    statement: false,
    mobile_view: false,
  });
  const actionCtx = useContext(ActionContext);
  // const { user } = useSelector((state) => state.user);
  const [singleObj] = useState({});
  const navigate = useNavigate();
  // const headerList = [
  //   "TRANSACTION NAME",
  //   "DEDUCTIONS / OVERDRAFT",
  //   "PAYMENT DATE",
  //   "AMOUNT",
  // ];

  useEffect(() => {
    getEmployeeDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresehTable]);

  const getEmployeeDetail = async (cur) => {
    const id = localStorage.getItem("payroll-employee-id");
    const obj = {
      id: id,
      current_page: cur || 1,
      per_page: actionCtx?.perPageVal?.value,
    };
    dispatch(getSingleEmployee(obj));
  };

  // const handleFinish = () => {
  //   setShowModal((prev) => {
  //     return { ...prev, delete: false };
  //   });
  //   setRefreshTable(!refresehTable);
  // };

  const handleDownloadSingleCsv = async (id) => {
    const obj = {
      salary_transaction_id: String(id),
    };
    const data = await dispatch(
      generateStatementTransactionForAllEmpSingle(obj)
    );
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      setDownloadCsvId("");
      downloadCsv(data?.payload?.data?.data?.url);
      // downloadCsvId("");
      // console.log(data);
    } else {
      setDownloadCsvId("");
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const [downloadCsvId, setDownloadCsvId] = useState("");
  // const payrollHistoryActions = (param) => {
  //   return (
  //     <div className="transaction-action-wrap">
  //       {loading ? (
  //         <Skeleton width={30} height={30} circle />
  //       ) : (
  //         <>
  //           {downloadCsvId === param?.id ? (
  //             <div className="">
  //               {" "}
  //               <ThreeDots
  //                 height="10"
  //                 width="80"
  //                 radius="9"
  //                 color="#020202"
  //                 ariaLabel="three-dots-loading"
  //                 wrapperStyle={{}}
  //                 wrapperClassName=""
  //                 visible={true}
  //               />
  //             </div>
  //           ) : (
  //             <figure
  //               className="img-box grey-bg darken-action-bg tooltip-hover-wrap"
  //               onClick={() => {
  //                 setDownloadCsvId(param?.id);
  //                 handleDownloadSingleCsv(param?.id);
  //                 // console.log(param);
  //               }}
  //             >
  //               <RavenToolTip
  //                 color={`black-light`}
  //                 text={`Download template for ${
  //                   formatDate(param?.date_of_payment)?.split("—")[0]
  //                 } `}
  //                 position={`left`}
  //               />
  //               <svg
  //                 className="img dull-bright-filter"
  //                 width="16"
  //                 height="16"
  //                 viewBox="0 0 16 16"
  //                 fill="none"
  //                 xmlns="http://www.w3.org/2000/svg"
  //               >
  //                 <path
  //                   d="M14.75 14.75H1.25M12.5 7.25L8 11.75M8 11.75L3.5 7.25M8 11.75V1.25"
  //                   stroke="#676767"
  //                   strokeWidth="1.4"
  //                   strokeLinecap="round"
  //                   strokeLinejoin="round"
  //                 />
  //               </svg>
  //             </figure>
  //           )}
  //         </>
  //       )}
  //     </div>
  //   );
  // };

  const editIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15992_77397)">
        <path
          className=" dull-bright-filter"
          d="M9.1665 3.33332H5.6665C4.26637 3.33332 3.56631 3.33332 3.03153 3.6058C2.56112 3.84549 2.17867 4.22794 1.93899 4.69834C1.6665 5.23312 1.6665 5.93319 1.6665 7.33332V14.3333C1.6665 15.7335 1.6665 16.4335 1.93899 16.9683C2.17867 17.4387 2.56112 17.8212 3.03153 18.0608C3.56631 18.3333 4.26637 18.3333 5.6665 18.3333H12.6665C14.0666 18.3333 14.7667 18.3333 15.3015 18.0608C15.7719 17.8212 16.1543 17.4387 16.394 16.9683C16.6665 16.4335 16.6665 15.7335 16.6665 14.3333V10.8333M6.66648 13.3333H8.06193C8.46959 13.3333 8.67341 13.3333 8.86522 13.2873C9.03528 13.2464 9.19786 13.1791 9.34698 13.0877C9.51517 12.9847 9.6593 12.8405 9.94755 12.5523L17.9165 4.58332C18.6069 3.89296 18.6069 2.77368 17.9165 2.08332C17.2261 1.39296 16.1069 1.39296 15.4165 2.08332L7.44753 10.0523C7.15928 10.3405 7.01515 10.4847 6.91208 10.6528C6.8207 10.802 6.75336 10.9645 6.71253 11.1346C6.66648 11.3264 6.66648 11.5302 6.66648 11.9379V13.3333Z"
          // stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15992_77397">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const deleteIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 5.00002V4.33335C13.3333 3.39993 13.3333 2.93322 13.1517 2.5767C12.9919 2.2631 12.7369 2.00813 12.4233 1.84834C12.0668 1.66669 11.6001 1.66669 10.6667 1.66669H9.33333C8.39991 1.66669 7.9332 1.66669 7.57668 1.84834C7.26308 2.00813 7.00811 2.2631 6.84832 2.5767C6.66667 2.93322 6.66667 3.39993 6.66667 4.33335V5.00002M8.33333 9.58335V13.75M11.6667 9.58335V13.75M2.5 5.00002H17.5M15.8333 5.00002V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9683C15.3212 17.4387 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4387 4.43915 16.9683C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00002"
        stroke="#FF0F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const size = useWindowSize();

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          back
          onBack={() => navigate(-1)}
          text={`Easily manage your staffs  payment and deducations`}
          title={`Employee Profile`}
        />
        <ContainerWrapper dontShowHeader width={`100%`}>
          {loading ? (
            <NoContentBox
              loading={loading}
              title={`No Employee profile`}
              text={`No payment received by employee yet.`}
            />
          ) : (
            <div
              onClick={() => {
                actionCtx?.setCheckShow("close");
                //   console.log(paymentLink);
              }}
              className="new-employee-profile-payroll-wrap-top-box "
            >
              {/* left box start */}
              <div className="left-box border-theme-right">
                {loading ? (
                  <Skeleton width={170} height={15} />
                ) : (
                  <p
                    onClick={() => {
                      //   console.log(paymentLink);
                    }}
                    className="title"
                    style={{ textTransform: "capitalize" }}
                  >
                    {/* {paymentLink?.linkDetail?.title || "---"} */}
                  </p>
                )}
                {/* .info-copy-wrap start */}
                <div className="info-copy-wrap border-theme-bottom">
                  {/* avatar action box start */}
                  <div className="avatar-action-box">
                    {loading ? (
                      <Skeleton width={40} height={40} circle />
                    ) : (
                      <div className="avatar-box grey-bg">
                        <span>
                          {singleEmployee?.employee?.first_name.charAt(0)}
                        </span>
                        <span>
                          {singleEmployee?.employee?.last_name.charAt(0)}
                        </span>
                      </div>
                    )}
                    <div
                      style={{ marginLeft: "auto" }}
                      className="icon-action-box"
                    >
                      <div
                        onClick={() => {
                          localStorage.setItem(
                            "payroll-employee-id",
                            singleEmployee?.employee?.id
                          );
                          navigate("/dashboard-payroll-employee-profile-edit");
                        }}
                        className="icon-box grey-bg"
                      >
                        <figure className="img-box">{editIcon}</figure>
                      </div>
                      <div
                        onClick={() => {
                          setShowModal((prev) => {
                            return { ...prev, delete: true };
                          });
                        }}
                        className="icon-box grey-bg"
                      >
                        <figure className="img-box">{deleteIcon}</figure>
                      </div>
                    </div>
                  </div>
                  {/* avatar action box end */}
                  {/* name email box start */}
                  <div className="name-email-box">
                    <div className="">
                      {loading ? (
                        <Skeleton
                          width={250}
                          height={25}
                          style={{ marginBottom: ".3rem" }}
                        />
                      ) : (
                        <p className="name">{`${
                          singleEmployee?.employee?.first_name || ""
                        } ${singleEmployee?.employee?.last_name || ""}`}</p>
                      )}
                    </div>
                    <div className="email grey-white-color">
                      {" "}
                      {loading ? (
                        <Skeleton
                          width={200}
                          height={15}
                          // style={{ marginBottom: ".3rem" }}
                        />
                      ) : (
                        <p className="">
                          {singleEmployee?.employee?.email || ""}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* name email box end */}
                </div>
                {/* detail value label start */}
                <div className="detail-label-value-box">
                  {/* box start */}
                  <div className="box">
                    <p className="label grey-white-color">
                      Total Salary Paid:{" "}
                    </p>
                    {loading ? (
                      <Skeleton width={120} height={15} />
                    ) : (
                      <p className="value">
                        {formatNumWithCommaNairaSymbol(
                          singleEmployee?.total_salaries || "0.00"
                        )}
                      </p>
                    )}
                  </div>
                  {/* box end total_amount_received */}
                  {/* box start */}
                  {/* <div className="box">
                  <p className="label grey-white-color">Total Received:</p>
                  {loading ? (
                    <Skeleton width={120} height={15} />
                  ) : (
                    <p className="value">
                      {formatNumWithCommaNairaSymbol(
                        sumAllNum([
                          singleEmployee?.total_salaries,
                          singleEmployee?.total_overdraft,
                        ])
                      )}
                    </p>
                  )}
                </div> */}
                  {/* box end */}

                  {/* box start */}
                  <div className="box">
                    <p className="label grey-white-color">Total Deduction: </p>
                    {loading ? (
                      <Skeleton width={120} height={15} />
                    ) : (
                      <p className="value">
                        {formatNumWithCommaNairaSymbol(
                          singleEmployee?.total_deductions || "0.00"
                        )}
                      </p>
                    )}
                  </div>
                  {/* box end */}
                  {/* box start */}
                  <div className="box">
                    <p className="label grey-white-color">Overdraft : </p>
                    {loading ? (
                      <Skeleton width={120} height={15} />
                    ) : (
                      <p className="value">
                        {formatNumWithCommaNairaSymbol(
                          singleEmployee?.total_overdraft || "0.00"
                        )}
                      </p>
                    )}
                  </div>
                  {/* box end */}
                  {/* box start */}
                  <div className="box">
                    <p className="label grey-white-color">Date Joined:</p>
                    {loading ? (
                      <Skeleton width={120} height={15} />
                    ) : (
                      <div className="value">
                        <DateFragmentBox
                          date={singleEmployee?.employee?.created_at}
                          // dontShowAgo={true}
                        />
                        {/* {formatDate(singleEmployee?.employee?.created_at)} */}
                      </div>
                    )}
                  </div>
                  {/* box end */}
                </div>
                {/* detail value label end */}
              </div>
              {/* left box end */}
              {/* right box start */}
              <div className="right-box">
                {singleEmployee?.previous_salary_transaction?.data?.length >
                0 ? (
                  <div className="transaction-info-wrap">
                    {/* top box startv */}
                    <div className="top-box border-theme-bottom">
                      <div className="title-text-box">
                        <div className="title-box">
                          <p className="title">Transactions</p>
                          <p className="num black-fade-white-bg">
                            <span className="">
                              {singleEmployee?.previous_salary_transaction?.data
                                ?.length || "0"}
                            </span>
                          </p>
                        </div>
                        <p className="lebel-text grey-white-color">
                          Payment received through this link
                        </p>
                      </div>
                      {/* filter-drop box start */}

                      {/* filter-drop box end */}
                    </div>
                    {/* top box end */}
                    {/* bottom detail start */}
                    <div className="bottom-detail-box">
                      {/* transaction row start */}
                      {singleEmployee?.previous_salary_transaction?.data?.map(
                        (chi, idx) => {
                          const {
                            id,
                            // customer_email,
                            // customer_fname,
                            // customer_lname,
                            status,
                            // created_at,
                            // amount,
                            net_amount,
                            narration,
                            employer_dedcutions,
                            nhf,
                            overdraft_deductions,
                            paye,
                            pension,
                            date_of_payment,
                          } = chi;
                          if (status === 0) {
                            return <React.Fragment key={id}></React.Fragment>;
                          }
                          return (
                            <div
                              className={`trans-row ${
                                idx !==
                                  singleEmployee?.previous_salary_transaction
                                    ?.data?.length -
                                    1 && "border-theme-bottom"
                              }`}
                              key={idx || id}
                            >
                              <div className="avatar-box grey-bg">
                                <span className="black-white-color">{`${
                                  narration?.split("")[0]
                                }`}</span>
                                {/* <span className="black-white-color">{`${
                                singleEmployee?.employee?.last_name?.split(
                                  ""
                                )[0]
                              }`}</span> */}
                              </div>
                              <div className="name-email-box">
                                {loading ? (
                                  <Skeleton width={170} height={15} />
                                ) : (
                                  <p className="name">
                                    {trimLongString(narration, 22) || "---"}
                                  </p>
                                )}
                                {loading ? (
                                  <Skeleton width={100} height={9} />
                                ) : (
                                  <p className="email grey-white-color">
                                    {`Deduction: ${
                                      formatNumWithCommaNairaSymbol(
                                        sumAllNum([
                                          employer_dedcutions,
                                          nhf,
                                          paye,
                                          overdraft_deductions,
                                          pension,
                                        ])
                                      ) || "0"
                                    }`}
                                  </p>
                                )}
                              </div>
                              <div className="date-amount-box">
                                {loading ? (
                                  <Skeleton width={120} height={15} />
                                ) : (
                                  <p
                                    style={{ color: status === 0 && "#EA872D" }}
                                    className="amount"
                                  >
                                    {formatNumWithCommaNairaSymbol(net_amount)}
                                  </p>
                                )}
                                {loading ? (
                                  <Skeleton width={120} height={9} />
                                ) : (
                                  <p className="date grey-white-color">
                                    {formatDate(date_of_payment)}
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}

                      {/* transaction row end */}
                    </div>
                    {/* bottom detail end */}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      minHeight: "100%",
                    }}
                    className=""
                  >
                    {" "}
                    <NoContentBox
                      loading={loading}
                      title={`No Transactions History`}
                      text={`No payment received by employee yet.`}
                    />
                  </div>
                )}
              </div>
              {/* right box end */}
            </div>
          )}
        </ContainerWrapper>
      </DashboardLayout>
      {/* delete mod start */}
      <DeleteUserModal
        visible={showModal?.delete}
        detail={singleEmployee?.employee}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, delete: false };
          });
        }}
        onFinish={() => {
          navigate("/dashboard-payroll");
        }}
      />
      {/* delete mod end */}
      {/* delete mod start */}
      <GenerateSingleEmployeeModal
        visible={showModal?.statement}
        detail={singleEmployee?.employee?.email}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, statement: false };
          });
        }}
        // onFinish={() => {
        //   // navigate("/dashboard-payroll");
        // }}
      />
      {/* delete mod end */}
      {/* delete mod start */}
      <MobileEmployeeView
        visible={showModal?.mobile_view && size.width < 900}
        detail={singleObj}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, mobile_view: false };
          });
        }}
        downloadCsv={downloadCsvId}
        onDownload={(id) => {
          setDownloadCsvId(id);
          // console.log(id);
          handleDownloadSingleCsv(id);
        }}
      />
      {/* delete mod end */}
    </>
  );
};

export default EmployeeProfile;
