/* eslint-disable jsx-a11y/anchor-is-valid */
// import { RavenButton, RavenToolTip } from "@ravenpay/raven-bank-ui";
import {
  // RavenDateTime,
  //   RavenButton,
  RavenInputField,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  //   RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import ".././../../styles/dashboard/payment-links/PaymentLink.css";
// import noTapImg from "../../../assets/no-tap-paymentlink-img.svg";
// import copyIcon from "../../../assets/payment-link-copy-icon.svg";
// import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DeletePaymentModal from "./DeletePaymentModal";
import { useDispatch, useSelector } from "react-redux";
import noContentImg from "../../../assets/no-content-img (1).png";
import {
  // getPaymentLink,
  getPaymentLinks,
  getPaymentLinksPagination,
  //   setPaymentLink,
} from "../../../redux/requestMoney";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  formatDate,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  getTotalPage,
  lowerCaseWrap,
  reactSelectStyle,
  //   reactSelectStyle,
  trimLongString,
} from "../../../utils/helper/Helper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import NocontentPage from "../../../components/reusables/NocontentPage";
// import PaymentLinkTestModalWrap from "./PaymentLinkTestModalWrap";
import DisablePaymentModal from "./DisablePaymentLink";
import SharePaymentLinkModal from "./SharePaymentLinkModal";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";
import ActionContext from "../../../context/ActionContext";
import useDebounce from "../../../utils/helper/UseDebounce";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import { FaTimes } from "react-icons/fa";
import DateFragmentBox from "../../../components/fragments/DateFragmentBox";

const DashboardPaymentLinkNewIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { user, user_resource_and_permissions } = useSelector(
    (state) => state.user
  );
  //   const [paymentLink, setPaymentLink] = useState({});
  const { loading, paymentLinks } = useSelector((state) => state.paymentLinks);
  //   const [isCopied, setIsCopied] = useState(false);
  const [showModal, setShowModal] = useState({
    delete: false,
    disable: false,
    share: false,
  });
  const headerList = [
    "Title",
    "AMOUNT",
    "LINK TYPE",
    "DATE CREATED",
    "STATUS",
    "",
  ];
  const [refreshTable, setRefresTable] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
      };
      const data = await dispatch(getPaymentLinks(obj));
      //  console.log(data);
      if (data?.payload?.status === "success") {
        if (data?.payload?.data.data.length > 0) {
          setTableData(true);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  const handleFinish = () => {
    setRefresTable(!refreshTable);
    // setPaymentLink({});
    setShowModal({
      delete: false,
      disable: false,
      share: false,
    });
  };

  const size = useWindowSize();

  //   const avatarFormatWrap = (param) => {
  //     return (
  //       <div className="text-avatar-box ">
  //         <div className="avatar-box grey-bg">
  //           <span>{param?.charAt(0)}</span>
  //         </div>
  //         <p className="text">{param}</p>
  //       </div>
  //     );
  //   };

  const formatTypeWrap = (param) => {
    return (
      <div
        className={`status-type-wrap  ${
          param === 2 && "status-type-wrap-fail"
        } ${param === 1 && "status-type-wrap-success"}  `}
      >
        <span style={{ textTransform: "capitalize" }}>
          {param === 1 ? "Active" : param === 2 ? "Expired" : "Disabled"}
        </span>
      </div>
    );
  };

  const [dropToshow, setDropToShow] = useState("");

  const dropActionList = [
    {
      name: "Edit Link",
      icon: (
        <svg
          className="img"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7998 12.7999L14.1331 13.5292C13.7794 13.916 13.2999 14.1332 12.7999 14.1332C12.2999 14.1332 11.8203 13.916 11.4667 13.5292C11.1126 13.1433 10.6331 12.9266 10.1333 12.9266C9.63339 12.9266 9.1539 13.1433 8.79979 13.5292M2.7998 14.1332H3.91617C4.24229 14.1332 4.40535 14.1332 4.5588 14.0964C4.69485 14.0637 4.82491 14.0098 4.9442 13.9367C5.07876 13.8543 5.19406 13.739 5.42466 13.5084L13.7998 5.13321C14.3521 4.58092 14.3521 3.68549 13.7998 3.13321C13.2475 2.58092 12.3521 2.58092 11.7998 3.13321L3.42464 11.5084C3.19404 11.739 3.07874 11.8543 2.99628 11.9888C2.92318 12.1081 2.86931 12.2382 2.83664 12.3742C2.7998 12.5277 2.7998 12.6907 2.7998 13.0169V14.1332Z"
            stroke="#676767"
            strokeWidth="1.35383"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Share Link",
      icon: (
        <svg
          className="img"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7998 4.19995H12.6665C11.5464 4.19995 10.9863 4.19995 10.5585 4.41794C10.1822 4.60969 9.87621 4.91565 9.68446 5.29197C9.46647 5.71979 9.46647 6.27985 9.46647 7.39995V8.19995M14.7998 4.19995L12.7998 2.19995M14.7998 4.19995L12.7998 6.19995M7.46647 2.19995H5.99981C4.8797 2.19995 4.31965 2.19995 3.89182 2.41794C3.5155 2.60968 3.20954 2.91565 3.01779 3.29197C2.7998 3.71979 2.7998 4.27985 2.7998 5.39995V11C2.7998 12.1201 2.7998 12.6801 3.01779 13.1079C3.20954 13.4843 3.5155 13.7902 3.89182 13.982C4.31965 14.2 4.8797 14.2 5.9998 14.2H11.5998C12.7199 14.2 13.28 14.2 13.7078 13.982C14.0841 13.7902 14.3901 13.4843 14.5818 13.1079C14.7998 12.6801 14.7998 12.1201 14.7998 11V9.53329"
            stroke="#676767"
            strokeWidth="1.35383"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Disable Link",
      icon: (
        <svg
          className="img"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.13281 6.60026H13.4661M4.13281 10.6003H13.4661M13.4661 3.93359L4.13281 13.2669"
            stroke="#676767"
            strokeWidth="1.35383"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Delete Link",
      icon: (
        <svg
          className="img"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.7998 4L4.7998 12M4.7998 4L12.7998 12"
            stroke="#FF0F00"
            strokeWidth="1.35383"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];

  const dropActionListOne = [
    {
      name: "Delete Link",
      icon: (
        <svg
          className="img"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.7998 4L4.7998 12M4.7998 4L12.7998 12"
            stroke="#FF0F00"
            strokeWidth="1.35383"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];

  const dropActionListTwo = [
    {
      name: "Edit Link",
      icon: (
        <svg
          className="img"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7998 12.7999L14.1331 13.5292C13.7794 13.916 13.2999 14.1332 12.7999 14.1332C12.2999 14.1332 11.8203 13.916 11.4667 13.5292C11.1126 13.1433 10.6331 12.9266 10.1333 12.9266C9.63339 12.9266 9.1539 13.1433 8.79979 13.5292M2.7998 14.1332H3.91617C4.24229 14.1332 4.40535 14.1332 4.5588 14.0964C4.69485 14.0637 4.82491 14.0098 4.9442 13.9367C5.07876 13.8543 5.19406 13.739 5.42466 13.5084L13.7998 5.13321C14.3521 4.58092 14.3521 3.68549 13.7998 3.13321C13.2475 2.58092 12.3521 2.58092 11.7998 3.13321L3.42464 11.5084C3.19404 11.739 3.07874 11.8543 2.99628 11.9888C2.92318 12.1081 2.86931 12.2382 2.83664 12.3742C2.7998 12.5277 2.7998 12.6907 2.7998 13.0169V14.1332Z"
            stroke="#676767"
            strokeWidth="1.35383"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Share Link",
      icon: (
        <svg
          className="img"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7998 4.19995H12.6665C11.5464 4.19995 10.9863 4.19995 10.5585 4.41794C10.1822 4.60969 9.87621 4.91565 9.68446 5.29197C9.46647 5.71979 9.46647 6.27985 9.46647 7.39995V8.19995M14.7998 4.19995L12.7998 2.19995M14.7998 4.19995L12.7998 6.19995M7.46647 2.19995H5.99981C4.8797 2.19995 4.31965 2.19995 3.89182 2.41794C3.5155 2.60968 3.20954 2.91565 3.01779 3.29197C2.7998 3.71979 2.7998 4.27985 2.7998 5.39995V11C2.7998 12.1201 2.7998 12.6801 3.01779 13.1079C3.20954 13.4843 3.5155 13.7902 3.89182 13.982C4.31965 14.2 4.8797 14.2 5.9998 14.2H11.5998C12.7199 14.2 13.28 14.2 13.7078 13.982C14.0841 13.7902 14.3901 13.4843 14.5818 13.1079C14.7998 12.6801 14.7998 12.1201 14.7998 11V9.53329"
            stroke="#676767"
            strokeWidth="1.35383"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Enable Link",
      icon: (
        <svg
          className="img"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.13281 6.60026H13.4661M4.13281 10.6003H13.4661M13.4661 3.93359L4.13281 13.2669"
            stroke="#676767"
            strokeWidth="1.35383"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Delete Link",
      icon: (
        <svg
          className="img"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.7998 4L4.7998 12M4.7998 4L12.7998 12"
            stroke="#FF0F00"
            strokeWidth="1.35383"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];

  const checkListToUse = (status) => {
    const list =
      status === 1
        ? dropActionList
        : status === 2
        ? dropActionListOne
        : dropActionListTwo;

    return list;
  };
  const [singleChild, setSingleChild] = useState({});
  const payrollCurrentActions = (param, idx) => {
    return (
      <div className="payroll-current-action-wrap">
        {loading ? (
          <>
            <Skeleton width={30} height={30} circle />
          </>
        ) : (
          <>
            <figure
              className="img-box grey-bg drop-down-box-payroll darken-action-bg"
              onClick={() => {
                // console.log(param);
                dropToshow === param?.id
                  ? setDropToShow("")
                  : setDropToShow(param?.id);
              }}
            >
              {/* drop down box start */}
              <div
                className={`drop-down-wrap-payroll border-theme shadow-shadow-box-shadow ${
                  dropToshow === param?.id && "drop-down-wrap-payroll-show"
                } ${idx >= 3 && "drop-down-wrap-payroll-up"}`}
              >
                {/* */}
                {checkListToUse(param?.status)?.map((chi, idx) => {
                  const { icon, name } = chi;
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_update_payment_links"
                    ) &&
                    name === "Edit Link"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_share_payment_links"
                    ) &&
                    name === "Share Link"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_delete_payment_links"
                    ) &&
                    name === "Delete Link"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_suspend_payment_link"
                    ) &&
                    name === "Disable Link"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  return (
                    <div
                      onClick={() => {
                        if (name === "Edit Link") {
                          // console.log(param);
                          localStorage?.setItem("payment-link-id", param?.id);
                          navigate(
                            "/dashboard-payment-links-request-money-edit"
                          );
                        }
                        if (name === "Share Link") {
                          setShowModal((prev) => {
                            return { ...prev, share: true };
                          });
                          setSingleChild(param);
                        }

                        if (name === "Delete Link") {
                          setShowModal((prev) => {
                            return { ...prev, delete: true };
                          });
                          setSingleChild(param);
                        }
                        if (name === "Disable Link" || name === "Enable Link") {
                          setShowModal((prev) => {
                            return { ...prev, disable: true };
                          });
                          setSingleChild(param);
                        }
                      }}
                      key={idx}
                      className="row-child"
                    >
                      <div className="img-wrap">
                        <figure className="img-box">{icon}</figure>
                      </div>
                      <p
                        className="text"
                        style={{ color: name === "Delete Link" && "#FF0F00" }}
                      >
                        {name}
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* drop down box end */}
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="img dull-bright-filter"
              >
                <path
                  d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
          </>
        )}
      </div>
    );
  };

  // const getSinglePaymentLink = async (id) => {
  //   const data = await dispatch(getPaymentLink(id));
  //   if (data?.payload?.data?.status === "success") {
  //     //   const obj = data?.payload?.data?.data;
  //     //   setPaymentLink(obj);
  //   }
  // };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  // const [userInfo, setUserInfo] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        search: search,
        status: selectOptionVal?.two?.value || "",
        type: selectOptionVal?.one?.value || "",
      };
      // console.log(obj);
      // return
      dispatch(getPaymentLinks(obj));
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        status: selectOptionVal?.two?.value || "",
        type: selectOptionVal?.one?.value || "",
      };
      dispatch(getPaymentLinks(obj));
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const [showFilterOption, setShowFilterOption] = useState(false);
  const [typeFilter] = useState("");
  // const [checkCustom, setCheckCustom] = useState(false);
  const [selectOptionVal, setSelectOptionVal] = useState({
    one: "",
    two: "",
  });

  const selectOption = [
    { label: "All Type", value: "" },
    { label: "Reccuring Payment", value: 1 },
    { label: "One time Payment", value: "0" },
  ];

  const selectOptionTwo = [
    { label: "All Type", value: "" },
    { label: "Active", value: 1 },
    { label: "Disabled", value: "0" },
    { label: "Expired", value: 2 },
  ];

  const noContentList = [
    "Multiple Payment Options for your customers",
    "Reduce the risk of fraud and errors associated with manual payment processing.",
    "Payment links reduce administrative tasks and costs associated with reconciliation ",
  ];

  const [tableData, setTableData] = useState(false);
  //   const [showWebPay, setShowWebPay] = useState(false);

  const testingPaymentRef = useOnClickOutside(() => {
    setShowFilterOption(false);
  });

  useEffect(() => {
    const obj = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: 1,
      search: search,
      status: selectOptionVal?.two?.value || "",
      type: selectOptionVal?.one?.value || "",
    };
    // console.log(obj);
    // return
    dispatch(getPaymentLinks(obj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptionVal?.one, selectOptionVal?.two]);

  return (
    <>
      <DashboardLayout>
        {paymentLinks?.data?.length > 0 || tableData ? (
          <>
            <TitletextBox
              title={`Payment Links`}
              text={`Track all created payment links in this section`}
              addBtn={user_resource_and_permissions?.allowed_permissions?.includes(
                "can_create_payment_link"
              )}
              toolTipMsg={`Create a new payment link`}
              onAdd={() => navigate("/dashboard-payment-links-request-money")}
            ></TitletextBox>
            {/* wrap start */}
            <ContainerWrapper
              pagination={
                paymentLinks?.prev_page_url_params ||
                paymentLinks?.next_page_url_params ? (
                  <RavenPagination
                    numSpacing={size.width < 900 && 1}
                    removeNext={size.width < 900}
                    removePrev={size.width < 900}
                    currentPage={Number(paymentLinks?.current_page)}
                    totalPage={getTotalPage(
                      paymentLinks?.per_page,
                      paymentLinks?.total
                    )}
                    color={`black-light`}
                    blackHover
                    prevPage={paymentLinks?.prev_page_url_params}
                    nextPage={paymentLinks?.next_page_url_params}
                    onNumView={(e) => {
                      dispatch(
                        getPaymentLinksPagination(
                          `?per_page=${actionCtx?.perPageVal?.value}&current_page=${e}&range=3`
                        )
                      );
                    }}
                    onPrev={(e) => {
                      dispatch(getPaymentLinksPagination(e));
                      // console.log(e);
                    }}
                    onNext={(e) => {
                      dispatch(getPaymentLinksPagination(e));
                      // console.log(e);
                    }}
                  />
                ) : (
                  ""
                )
              }
              width={`100%`}
              dontShowHeader
            >
              <div className="new-payment-link-table-filter-wrap">
                {/* title text box start */}
                <div className="payment-link-new-search-filter-chart-box">
                  <div className="search-filter-box">
                    {/* per page start */}
                    {/* <PerPageComponent /> */}
                    {/* per page end */}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      autoComplete="off"
                      action=""
                    >
                      <div className="search-group">
                        <RavenInputField
                          type={`search`}
                          color={`black-light`}
                          placeholder={`Search`}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                    {/* badge filter options start */}
                    <div className="badge-filter-option-box">
                      {/* box start */}
                      {selectOptionVal?.one.label && (
                        <div className="option-box grey-bg">
                          <span>{selectOptionVal?.one?.label || "--"}</span>{" "}
                          <FaTimes
                            onClick={() => {
                              setSelectOptionVal((prev) => {
                                return {
                                  ...prev,
                                  one: { label: "", value: "" },
                                };
                              });
                            }}
                            className="icon grey-white-color-white"
                          />{" "}
                        </div>
                      )}
                      {selectOptionVal?.two.label && (
                        <div className="option-box grey-bg">
                          <span>{selectOptionVal?.two?.label || "--"}</span>{" "}
                          <FaTimes
                            onClick={() => {
                              setSelectOptionVal((prev) => {
                                return {
                                  ...prev,
                                  two: { label: "", value: "" },
                                };
                              });
                            }}
                            className="icon grey-white-color-white"
                          />{" "}
                        </div>
                      )}
                      {/* box end */}
                    </div>
                    {/* badge filter options end */}
                    {/* filter-drop box start */}
                    <div ref={testingPaymentRef} className="filter-drop-box">
                      {/* drop box start */}
                      {showFilterOption && (
                        <div className="drop-box-wrap  card-bg shadow-shadow-box-shadow">
                          <form autoComplete="off" action="">
                            <RavenInputField
                              selectOption={selectOption}
                              label={`Filter By Payment Link Type`}
                              color={`black-light`}
                              type={"select"}
                              placeholder={`Payment Link Type`}
                              selectStyles={reactSelectStyle}
                              value={selectOptionVal?.one}
                              onChange={(e) => {
                                if (
                                  e.label !== "Specific Date" &&
                                  e.label !== "Custom"
                                ) {
                                  setSelectOptionVal((prev) => {
                                    return { ...prev, one: e };
                                  });
                                }

                                if (e.label === "Specific Date") {
                                  setShowModal((prev) => {
                                    return {
                                      ...prev,
                                      selectSpecificDate: true,
                                    };
                                  });
                                }
                                if (e.label === "Custom") {
                                  setShowModal((prev) => {
                                    return { ...prev, customDate: true };
                                  });
                                }
                                showFilterOption && setShowFilterOption(false);
                                // setCheckCustom(true);
                              }}
                              selectValue={selectOptionVal?.one}
                            />
                          </form>
                          <form autoComplete="off" action="">
                            {" "}
                            <RavenInputField
                              selectOption={selectOptionTwo}
                              color={`black-light`}
                              type={"select"}
                              label={`Filter By Payment Link Status`}
                              placeholder={`Payment Link Status`}
                              selectStyles={reactSelectStyle}
                              value={selectOptionVal?.two}
                              onChange={(e) => {
                                setSelectOptionVal((prev) => {
                                  return { ...prev, two: e };
                                });
                                showFilterOption && setShowFilterOption(false);
                                // setCheckCustom(false);
                              }}
                              selectValue={typeFilter}
                            />
                          </form>
                        </div>
                      )}
                      {/* drop box end */}
                      {/* filter box start */}
                      <div
                        className="filter-box grey-bg"
                        onClick={() => {
                          setShowFilterOption(!showFilterOption);
                        }}
                      >
                        <p className="text grey-white-color-white">Filter</p>
                        <div className="line-box">
                          <span className=""></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                      {/* filter box end */}
                      {/* drop box start */}
                      <div className="drop-filter-wrap"></div>
                      {/* drop box end */}
                    </div>
                    {/* filter-drop box end */}
                  </div>
                </div>
                {/* title text box end */}
                {paymentLinks?.data?.length > 0 ? (
                  <div className="payment-link-wrap-box payment-link-wrap-box-new-index">
                    {/* <div className="left-box"> */}
                    {/* tabvle start */}
                    <RavenTable
                      className={`table-wrap table-fixed-type ${
                        formatMetaData(user?.theme_display_settings)
                          ?.table_font === "bold" && "table-business"
                      }`}
                      headerList={headerList}
                    >
                      {paymentLinks?.data?.map((chi, idx) => {
                        const {
                          title,
                          request_amount,
                          // created_at,
                          id,
                          status,
                          recurring,
                        } = chi;
                        return (
                          <RavenTableRow
                            className={`payment-link-table-row ${
                              dropToshow === chi?.id &&
                              "payment-link-table-row-index"
                            } `}
                            loading={loading}
                            onRowClick={() => {
                              if (
                                user_resource_and_permissions?.allowed_permissions?.includes(
                                  "can_view_payment_links"
                                )
                              ) {
                                localStorage.setItem(
                                  "payment-link-single-id",
                                  id
                                );
                                navigate(
                                  "/dashboard-payment-links-detail-view"
                                );
                              }
                            }}
                            key={idx}
                            one={
                              lowerCaseWrap(trimLongString(title, 25)) || "--"
                            }
                            three={
                              recurring === 1
                                ? "Reccuring Payment"
                                : "One time Payment"
                            }
                            //   onRowClick
                            ManualAddActions={() =>
                              payrollCurrentActions(chi, idx)
                            }
                            two={formatNumWithCommaNairaSymbol(request_amount)}
                            four={
                              <DateFragmentBox
                                date={chi?.created_at}
                                // dontShowAgo={true}
                              />
                            }
                            five={formatTypeWrap(status)}
                          />
                        );
                      })}
                    </RavenTable>
                    <div className="mobile-table-box">
                      {paymentLinks?.data?.map((chi, idx) => {
                        const {
                          request_amount,
                          created_at,
                          id,
                          // recipient_email,
                          recurring,
                          title,
                        } = chi;
                        return (
                          <MobileTableCard
                            key={idx}
                            amount={formatNumWithCommaNairaSymbol(
                              request_amount
                            )}
                            text={
                              recurring === 1
                                ? "Reccuring Payment"
                                : "One time Payment"
                            }
                            onRowClick={() => {
                              if (
                                user_resource_and_permissions?.allowed_permissions?.includes(
                                  "can_view_payment_links"
                                )
                              ) {
                                localStorage.setItem(
                                  "payment-link-single-id",
                                  id
                                );
                                navigate(
                                  "/dashboard-payment-links-detail-view"
                                );
                              }
                            }}
                            // avatar={title}
                            textTwo={`${formatDate(created_at).split("—")[0]}`}
                            loading={loading}
                            title={trimLongString(title, 15)}
                            showImg
                          />
                        );
                      })}
                    </div>
                    {/* tabvle end */}
                    {/* left box end */}
                  </div>
                ) : (
                  <NoContentBox
                    title={`No payment link found`}
                    text={`Click on the create button above to create a payment link.`}
                    loading={loading}
                  />
                )}
              </div>
            </ContainerWrapper>
            {/* wrap end */}
          </>
        ) : (
          <NocontentPage
            loading={loading}
            img={noContentImg}
            title={`Receive payment globally 🌍, with your Payment links`}
            list={noContentList}
            btntext={
              user_resource_and_permissions?.allowed_permissions?.includes(
                "can_create_payment_link"
              ) && `Create link`
            }
            onBtnClick={() => {
              navigate("/dashboard-payment-links-request-money");
            }}
          />
        )}
      </DashboardLayout>
      {/* delete modal start */}
      <DeletePaymentModal
        detail={singleChild}
        visible={showModal.delete}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, delete: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* delete modal end */}
      {/* delete modal start */}
      <DisablePaymentModal
        detail={singleChild}
        visible={showModal.disable}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, disable: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* delete modal end */}
      {/* delete modal start */}
      <SharePaymentLinkModal
        detail={singleChild}
        visible={showModal.share}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, share: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* delete modal end */}
    </>
  );
};

export default DashboardPaymentLinkNewIndex;
