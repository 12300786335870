import React, { useEffect, useState } from "react";
import DashboardSettingsLayout from "./SettingsLayout";
import { RavenButton, RavenToolTip } from "@ravenpay/raven-bank-ui";
// import { freeze } from "@reduxjs/toolkit";
// import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoContentBox from "../../../../components/reusables/NoContentBox";
import { getTeamRoles } from "../../../../redux/settings";
import { getSingleSubAccountRoleInfo } from "../../../../redux/sub_account";
import {
  formAllAccountList,
  lowerCaseWrap,
  SPECIAL_ROLE_NAME,
  trimLongString,
} from "../../../../utils/helper/Helper";
import RemoveRoleFromAccountModal from "../modal/RemoveRoleFromAccountModal";
import { getAllAccounts } from "../../../../redux/user";
import AddRoleToAccountModal from "../../sub-account/AddRoleToAccount";
import ChangePrimaryModal from "../modal/ChangePrimaryAccountModal";
import DeleteAccountModal from "../modal/DeleteAccountModal";

const DashboardSettingsSubAccount = () => {
  const { business, all_account, user_resource_and_permissions } = useSelector(
    (state) => state.user
  );

  // eslint-disable-next-line no-unused-vars
  const [emailStatus, setEmailStatus] = useState(0);
  const [singleChild, setSingleChild] = useState({});
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    team_invite: false,
    team_delete: false,
    device_delete: false,
    statement: false,
    electricity: false,
    view_directors: false,
    delete_sub_account: false,
    change_primary: false,
    remove_role_account: false,
    add_role_account: false,
  });
  const { teamRoles } = useSelector((state) => state.settings);
  useEffect(() => {
    if (Object.keys(business)?.length > 0) {
      setEmailStatus(Number(business?.email_notification_control));
      // console.log(business?.email_notification_control);
    }
  }, [business]);

  const navigate = useNavigate();
  // const [teamMem, setTeamMembers] = useState([]);

  const dotIcon = (
    <svg
      className="img dull-bright-filter"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
        fill="#333333"
        className="dull-bright-filter"
      />
      <path
        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
        fill="#333333"
        className="dull-bright-filter"
      />
      <path
        d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
        fill="#333333"
        className="dull-bright-filter"
      />
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
      <path
        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
      <path
        d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
    </svg>
  );

  const cancelIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4L4 12M4 4L12 12"
        stroke="#FF0F00"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const plusIcon = (
    <svg
      className="img"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50016 3.33331V12.6666M3.8335 7.99998H13.1668"
        stroke="#755AE2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const formatRoles = (list) => {
    if (list) {
      const newList = JSON.parse(list);
      return newList;
    }
  };

  const [actionDrop, setActionDrop] = useState("");

  //   useEffect(() => {
  //     // console.log(all_account);
  //   }, [all_account]);

  //   const testingSubAccountDropRef = useOnClickOutside(() => {
  //     !actionDrop && setActionDrop("");
  //   });

  const addRoleIcon = (
    <svg
      className="img dull-bright-filter"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M19 21V15M16 18H22M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className=" dull-bright-filter"
      />
    </svg>
  );

  const primaryIcon = (
    <svg
      className="img dull-bright-filter"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className=" dull-bright-filter"
        d="M21 9.5V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H9.5M17.3862 17.7113L15.6879 20.8653C15.4103 21.3808 15.2715 21.6386 15.1023 21.7059C14.9555 21.7643 14.7896 21.7498 14.6551 21.6668C14.5001 21.5712 14.4081 21.2933 14.2241 20.7375L11.5004 12.5113C11.3392 12.0245 11.2586 11.7812 11.3166 11.6191C11.367 11.478 11.478 11.367 11.6191 11.3166C11.7812 11.2586 12.0245 11.3392 12.5113 11.5004L20.7374 14.2241C21.2933 14.4082 21.5712 14.5002 21.6668 14.6551C21.7498 14.7897 21.7642 14.9555 21.7058 15.1024C21.6386 15.2715 21.3808 15.4103 20.8652 15.6879L17.7113 17.3862C17.6328 17.4285 17.5935 17.4497 17.5591 17.4768C17.5286 17.501 17.501 17.5286 17.4768 17.5591C17.4497 17.5935 17.4285 17.6328 17.3862 17.7113Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const freezeIcon = (
    <svg
      className="img "
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8V16M12 8V2M12 8L7 3M12 8L17 3M12 16V22M12 16L7 21M12 16L17 21M16 12H8M16 12H22M16 12L21 7M16 12L21 17M8 12H2M8 12L3 7M8 12L3 17"
        stroke="#1B1B1B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    dispatch(getTeamRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinish = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        team_invite: false,
        team_delete: false,
        device_delete: false,
        statement: false,
        electricity: false,
        view_directors: false,
        delete_sub_account: false,
        change_primary: false,
        remove_role_account: false,
        add_role_account: false,
      };
    });
  };

  return (
    <>
      <DashboardSettingsLayout>
        <div
          className="detail-wrap animate-move-up-class"
          id=""
          style={{ paddingBottom: "unset" }}
        >
          <div className="text-btn-box">
            {" "}
            <div
              onClick={() => {
                // console.log(teamMembers);
              }}
              className="text-wrap"
            >
              <p className="title">Sub-accounts</p>
              <p className="sub-title grey-white-color">
                Manage all your sub accounts created .
              </p>
            </div>
            {user_resource_and_permissions?.allowed_permissions?.includes(
              "can_create_account"
            ) && (
              <RavenButton
                color={`black-light`}
                label={`Create a sub account`}
                onClick={() => {
                  navigate("/dashboard-sub-account-add");
                }}
              />
            )}
          </div>

          <div style={{ padding: "2rem 0rem" }} className="box-wrap ">
            {/* noti wrap start */}
            {/* team member start */}
            {all_account?.length > 0 ? (
              <>
                <div className="content-body-select-sub-account-wrap ">
                  {formAllAccountList(all_account)?.map((chi, idx) => {
                    const {
                      custom_account_name,
                      account_number,
                      bank,
                      selected_roles,
                      primary,
                      freeze,
                      id,
                    } = chi;
                    return (
                      <div
                        // onClick
                        key={idx}
                        className="sub-account-item border-theme white-black-bg"
                        // style={{opacity: freeze === 1 ? .5 : 1}}
                      >
                        <div className="detail-action-box">
                          <div
                            onClick={() => {
                              localStorage.setItem("sub-account-single-id", id);
                              navigate("/dashboard-sub-account-single");
                              setActionDrop("");
                            }}
                            className="detail-account-box"
                            style={{ opacity: freeze === 1 ? 0.5 : 1 }}
                          >
                            <p className="name">
                              {lowerCaseWrap(custom_account_name)}
                            </p>
                            <p className="bank-acc_num grey-white-color">{`${
                              account_number || "----"
                            } • ${trimLongString(bank, 25) || "---"}`}</p>
                          </div>
                          {primary === 1 && (
                            <div
                              style={{
                                transform:
                                  "translateX(-7.5rem) scale(0.8) translateY(-1.1rem)",
                              }}
                              className="primary-box grey-bg-two"
                            >
                              <span>Primary</span>
                            </div>
                          )}
                          {chi?.freeze === 1 && (
                            <div className="primary-box disabled-box grey-bg-two">
                              <span>Disabled</span>
                            </div>
                          )}
                          {user_resource_and_permissions?.allowed_permissions?.includes(
                            "can_add_role_to_an_account"
                          ) ||
                          (primary !== 1 &&
                            user_resource_and_permissions?.allowed_permissions?.includes(
                              "can_change_primary_account"
                            )) ||
                          (formatRoles(selected_roles)?.length <
                            teamRoles?.length &&
                            freeze !== 1 &&
                            user_resource_and_permissions?.allowed_permissions?.includes(
                              "can_add_role_to_an_account"
                            )) ? (
                            <div
                              onClick={() => {
                                actionDrop.id === id
                                  ? setActionDrop("")
                                  : setActionDrop(chi);
                              }}
                              // ref={testingSubAccountDropRef}
                              className="action-box"
                            >
                              <figure className="img-box">{dotIcon}</figure>
                              {/* drop bos start */}
                              <div
                                style={{ zIndex: actionDrop.id === id ? 5 : 1 }}
                                className={`drop-box-wrap card-bg ${
                                  actionDrop.id === id && "drop-box-wrap-active"
                                } ${idx >= 2 && "drop-bow-wrap-up"}`}
                              >
                                <>
                                  {chi?.freeze === 0 && (
                                    <>
                                      {" "}
                                      {/* icon text box start */}
                                      {user_resource_and_permissions?.allowed_permissions?.includes(
                                        "can_add_role_to_an_account"
                                      ) && (
                                        <div
                                          onClick={() => {
                                            setShowModal((prev) => {
                                              return {
                                                ...prev,
                                                add_role_account: true,
                                              };
                                            });
                                          }}
                                          className={`icon-text-box ${
                                            formAllAccountList(all_account)
                                              ?.length > 1 &&
                                            "border-theme-bottom"
                                          }`}
                                        >
                                          <div className="img-wrap">
                                            {" "}
                                            <figure className="img-box">
                                              {addRoleIcon}
                                            </figure>
                                          </div>
                                          <p className="text">Add Role</p>
                                        </div>
                                      )}
                                      {/* icon text box end */}
                                      {/* icon text box start */}
                                      {primary !== 1 &&
                                        user_resource_and_permissions?.allowed_permissions?.includes(
                                          "can_change_primary_account"
                                        ) && (
                                          <div
                                            onClick={() => {
                                              setActionDrop("");
                                              setSingleChild(chi);
                                              setShowModal((prev) => {
                                                return {
                                                  ...prev,
                                                  change_primary: true,
                                                };
                                              });
                                            }}
                                            className="icon-text-box border-theme-bottom"
                                          >
                                            <div className="img-wrap">
                                              {" "}
                                              <figure className="img-box">
                                                {primaryIcon}
                                              </figure>
                                            </div>
                                            <p className="text">
                                              Set as primary
                                            </p>
                                          </div>
                                        )}
                                      {/* icon text box end */}
                                    </>
                                  )}
                                </>
                                {/* icon text box start */}
                                {primary !== 1 &&
                                  user_resource_and_permissions?.allowed_permissions?.includes(
                                    "can_freeze_and_unfreeze_account"
                                  ) && (
                                    <div
                                      onClick={() => {
                                        setActionDrop("");
                                        setSingleChild(chi);
                                        setShowModal((prev) => {
                                          return {
                                            ...prev,
                                            delete_sub_account: true,
                                          };
                                        });
                                      }}
                                      className="icon-text-box "
                                    >
                                      <div className="img-wrap">
                                        {" "}
                                        <figure className="img-box">
                                          {freezeIcon}
                                        </figure>
                                      </div>
                                      <p className={`text `}>
                                        {chi?.freeze === 0
                                          ? `Freeze account`
                                          : `Unfreeze account`}
                                      </p>
                                    </div>
                                  )}
                                {/* icon text box end */}
                              </div>
                              {/* drop bos end */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* roles start */}
                        <div
                          onClick={() => {
                            setActionDrop("");
                          }}
                          className="role-box-wrap grey-bg"
                          style={{ opacity: freeze === 1 ? 0.5 : 1 }}
                        >
                          {formatRoles(selected_roles)?.map((chi, idx) => {
                            return (
                              <div
                                key={idx}
                                className="role-item-box white-black-bg"
                                style={{
                                  cursor:
                                    chi?.toLocaleLowerCase() !==
                                      SPECIAL_ROLE_NAME && "pointer",
                                }}
                              >
                                <p className="role">{lowerCaseWrap(chi)}</p>
                                {chi?.toLocaleLowerCase() !==
                                  SPECIAL_ROLE_NAME &&
                                  user_resource_and_permissions?.allowed_permissions?.includes(
                                    "can_remove_role_from_sub_account"
                                  ) && (
                                    <div
                                      onClick={() => {
                                        setActionDrop("");
                                        setSingleChild({
                                          role: chi,
                                          custom_account_name:
                                            custom_account_name,
                                          id: id,
                                        });
                                        setShowModal((prev) => {
                                          return {
                                            ...prev,
                                            remove_role_account: true,
                                          };
                                        });
                                      }}
                                      className="cancel-icon-box tooltip-hover-wrap"
                                    >
                                      <RavenToolTip
                                        color="black-light"
                                        text="Remove role"
                                        position={`top-center`}
                                      />
                                      {freeze !== 1 && (
                                        <figure className="img-box">
                                          {cancelIcon}
                                        </figure>
                                      )}
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                          {/* add new role box start */}
                          {formatRoles(selected_roles)?.length <
                            teamRoles?.length &&
                            freeze !== 1 &&
                            user_resource_and_permissions?.allowed_permissions?.includes(
                              "can_add_role_to_an_account"
                            ) && (
                              <div
                                onClick={() => {
                                  setShowModal((prev) => {
                                    return { ...prev, add_role_account: true };
                                  });
                                  setSingleChild(chi);
                                  const obj = {
                                    id: chi?.id,
                                  };
                                  // console.log(chi);
                                  dispatch(getSingleSubAccountRoleInfo(obj));
                                }}
                                className="role-item-box purple-item-box grey-bg-two"
                              >
                                <div className="cancel-icon-box">
                                  <figure className="img-box">
                                    {plusIcon}
                                  </figure>
                                </div>
                                <p className="role">Add new role</p>
                              </div>
                            )}
                          {/* add new role box end */}
                        </div>
                        {/* roles end */}
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div
                // className="box-wrap grey-bg"
                style={{
                  display: "grid",
                  placeItems: "center",
                  minHeight: "45rem",
                }}
              >
                {" "}
                <NoContentBox
                  // loading={loading}
                  title={`No Sub Account`}
                  text="Add Sub Account by clicking on the Create a sub account button above"
                />
              </div>
            )}

            {/* noti wrap end */}
          </div>
        </div>
      </DashboardSettingsLayout>
      {/* genrate start */}
      <DeleteAccountModal
        visible={showModal?.delete_sub_account}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, delete_sub_account: false };
          });
        }}
        detail={singleChild}
        onFinish={() => {
          dispatch(getAllAccounts());
          handleFinish();
        }}
      />
      {/* genrate end */}
      {/* genrate start */}
      <ChangePrimaryModal
        visible={showModal?.change_primary}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, change_primary: false };
          });
        }}
        onFinish={() => {
          dispatch(getAllAccounts());
          handleFinish();
        }}
        detail={singleChild}
      />
      {/* genrate end */}
      {/* genrate start */}
      <RemoveRoleFromAccountModal
        visible={showModal?.remove_role_account}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, remove_role_account: false };
          });
        }}
        onFinish={() => {
          dispatch(getAllAccounts());
          handleFinish();
        }}
        detail={singleChild}
      />
      {/* genrate end */}
      {/* genrate start */}
      <AddRoleToAccountModal
        visible={showModal?.add_role_account}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, add_role_account: false };
          });
        }}
        detail={singleChild}
        onFinish={() => {
          dispatch(getAllAccounts());
          handleFinish();
        }}
      />
      {/* genrate end */}
    </>
  );
};

export default DashboardSettingsSubAccount;
