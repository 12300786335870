import {
  RavenButton,
  // RavenCheckBox,
  RavenInputField,
  RavenPagination,
  RavenTable,
  // RavenTableRow,
  // RavenToggleSlide,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import infoIcon from "../../../assets/info-black-icon.svg";
import "../../../styles/dashboard/payroll/PayrollCretaeIndex.css";
// import switchIcon from "../../../assets/payroll-switch-icon.svg";
// import yellowInfo from "../../../assets/yellow-info.svg";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import downloadCsv from "../../../assets/raven business payroll template .xlsx";
import {
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  generateUniqueId,
  sumAllNum,
  trimLongString,
} from "../../../utils/helper/Helper";
import { PayrollTaxCalc } from "./PayrollTaxCalc";
import { useSelector, useDispatch } from "react-redux";
import {
  autoAddEmployeeToPayroll,
  getPayrollSetup,
} from "../../../redux/payroll";
import SuccessModal from "../../../components/reusables/SuccessModal";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import MobileEmployeeViewCreate from "./modals/MobileCreatAutoViewModal";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";
import ActionContext from "../../../context/ActionContext";
import PayrollEditTableRow from "./PayrollEditTableRow";
import NewPreviewDetailBox from "./NewPreviewDetailBox";

const PayrollAutoCreate = () => {
  const stepList = ["Upload file", "View file", "Add deductions"];
  const [editObj, setEditObj] = useState({
    name: "",
    account_number: "",
    bank: "",
    bank_code: "",
    gross_salary: "",
    raven_username: "",
    email: "",
    phone_number: "",
    id: "",
  });
  const [editState, setEditState] = useState(false);
  const actionCtx = useContext(ActionContext);
  const [bulkList, setBulkList] = useState([]);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.payroll);
  // const [perPageVal] = useState(10);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
    mobile_view: false,
  });
  const [nhf, setNhf] = useState("0");
  const [singleObj, setSingleObj] = useState({});
  const [pension, setPension] = useState("0");
  const [payee, setPayee] = useState("0");
  // const [fromDate, setFromDate] = useState("");
  const [details, setDetails] = useState({
    nhf_status: 0,
    pension_status: 0,
    payee_status: 0,
    amount: "",
    totalDeduction: "",
    amountMinusTax: "",
    addCheckVal: false,
    onSetAmount: "",
    selected_account_number: "",
  });
  const headerList = [
    " NAME",
    "ACCOUNT NO.",
    "BANK NAME",
    "USERNAME",
    "SALARY",
    "EMAIL",
    "PHONE NO",
    // "",
  ];

  // const avatarFormatWrap = (param) => {
  //   return (
  //     <div className="text-avatar-box">
  //       <div className="avatar-box">
  //         <span>{`${param?.split(" ")[0]?.charAt(0)} ${param
  //           ?.split(" ")[1]
  //           ?.charAt(0)}`}</span>
  //       </div>
  //       <p className="text">{param}</p>
  //     </div>
  //   );
  // };

  function getExtension(filename) {
    return filename.split(".").pop();
  }

  const [parsedJson, setParsedJson] = useState(0);
  let expectedFormat = {
    0: "Last Name",
    1: "First Name",
    2: "Account Number",
    3: "Bank",
    4: "Bank Code",
    5: "Salary",
    6: "Raven Username",
    7: "Employee Email",
    8: "Employee Phone",
  };

  function handleFileUpload(file) {
    // function handleFile(file /*:File*/) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX?.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // console.log(rABS, wb);
      /* Convert array of arrays */
      const data = XLSX?.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      setParsedJson(data);
      // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
    };
    if (rABS) reader?.readAsBinaryString(file);
    else reader?.readAsArrayBuffer(file);
    // }

    // var hasTriggered = true;
    // function triggerProcess() {
    //   // If this process has already been triggered,
    //   // don't execute the function
    //   if (hasTriggered) return;
    //   setParsedJson();
    //   toast.error(
    //     "Your bulk file is not a valid excel format, Download and use the template below."
    //   );
    //   // Set the flag to signal that we've already triggered
    //   hasTriggered = true;

    //   // ...
    // }
  }

  const handleChange = (e, id) => {
    // console.log(editObj);
    // console.log(bulkList);
    // console.log(id);
    if (editState) {
      const { name, value } = e.target;
      const obj = { ...editObj, [name]: value };
      // console.log(e.target.name);
      setEditObj(obj);
      if (name === "bank") {
        setEditObj((prev) => {
          return { ...prev, [name]: value, bank_code: value?.value };
        });
      }
      setEditObj((prev) => {
        return { ...prev, [name]: value };
      });
      //   setEditObj(obj);
      const newListAll = bulkList?.map((chi) =>
        chi?.id === id
          ? {
              ...chi,
              [name]: value,
              bank_code: name === "bank" ? value?.value : chi?.bank_code,
            }
          : chi
      );
      // console.log(newListAll);
      setBulkList(newListAll);
      paginateFunc(
        newListAll,
        actionCtx?.perPageVal?.value,
        paginatedList?.current_page
      );
    } else {
      const { name, value } = e.target;
      //   const obj = { ...editObj, [name]: value };
      if (name === "bank") {
        setEditObj((prev) => {
          return { ...prev, [name]: value, bank_code: value?.value };
        });
        return;
      }
      setEditObj((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  useEffect(() => {
    if (parsedJson?.length > 2) {
      // console.log("222");
      if (
        expectedFormat[0] !== parsedJson[0][0] &&
        expectedFormat[1] !== parsedJson[1][1] &&
        expectedFormat[2] !== parsedJson[2][2] &&
        expectedFormat[3] !== parsedJson[3][3] &&
        expectedFormat[4] !== parsedJson[4][4] &&
        expectedFormat[5] !== parsedJson[5][5] &&
        expectedFormat[6] !== parsedJson[6][6] &&
        expectedFormat[7] !== parsedJson[7][7] &&
        expectedFormat[8] !== parsedJson[8][8]
      ) {
        toast.error(
          "Your bulk file is not a valid excel format, Download and use the template below."
        );
      } else {
        //  filter the array for empty objects
        // console.log("333");
        if (parsedJson.length > 1) {
          const filteredJson = parsedJson?.filter((element) => {
            // console.log(element, "elementi");
            if (Object.keys(element)?.length > 5) {
              return true;
            }

            return false;
          });

          // structure data for consumption
          // structure data for consumption
          const structuredData = filteredJson.map(
            ({
              0: last_name,
              1: first_name,
              2: account_number,
              3: bank,
              4: bank_code,
              5: gross_salary,
              6: raven_username,
              7: email,
              8: phone_number,
              ...rest
            }) => ({
              first_name,
              last_name,
              account_number,
              bank,
              bank_code,
              gross_salary,
              raven_username,
              email,
              phone_number,
              ...rest,
            })
          );
          // console.log(structuredData);
          const removeIndexOne = structuredData?.filter(
            (chi, index) => index !== 0
          );
          // console.log(removeIndexOne);
          const allGrossAmount = removeIndexOne?.map(
            (chi) => chi?.gross_salary
          );

          if (removeIndexOne?.length > 0) {
            setStep(2);
            setBulkList(formatBulkList(removeIndexOne));
            calculatTaxValue();
            setDetails((prev) => {
              return {
                ...prev,
                amount: sumAllNum(allGrossAmount),
                onSetAmount: sumAllNum(allGrossAmount),
              };
            });
            paginateFunc(removeIndexOne, actionCtx?.perPageVal?.value, 1);
          } else {
            toast.error("No data found in uploaded file");
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedJson]);
  const { user } = useSelector((state) => state.user);
  const [paginatedList, setPaginatedList] = useState({
    current_page: "",
    per_page: "",
    total: "",
    total_pages: "",
    dataList: [],
  });

  function paginateFunc(array, page_size, current_page) {
    const start_index =
      (current_page - 1) * Number(actionCtx?.perPageVal?.value);
    const end_index = start_index + Number(actionCtx?.perPageVal?.value);
    const paginatedData = array.slice(start_index, end_index);
    const info = {
      current_page: current_page,
      per_page: Number(actionCtx?.perPageVal?.value),
      total: array.length,
      total_pages: Math.ceil(
        array.length / Number(actionCtx?.perPageVal?.value)
      ),
      dataList: paginatedData,
    };
    // console.log(info);
    setPaginatedList((prev) => {
      return {
        ...prev,
        current_page: info?.current_page,
        per_page: actionCtx?.perPageVal?.value,
        total: info?.total,
        total_pages: info?.total_pages,
        dataList: info?.dataList,
      };
    });
  }

  useEffect(() => {
    getPayrollSetupFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.perPageVal]);

  const getPayrollSetupFunc = async () => {
    const data = await dispatch(getPayrollSetup());
    if (data?.payload?.response?.data?.message === "No Data found") {
      // console.log("yes");
    }
    if (data?.payload?.data?.status === "success") {
      const setUp = data.payload.data.data.payroll_setup;
      // console.log(setUp);
      const nhf = setUp.nhf_status;
      setNhf(nhf);
      const payee = setUp.payee_status;
      setPayee(payee);
      const pension = setUp.pension_status;
      setPension(pension);
      const sub_account = setUp?.sub_account_details;
      setDetails((prev) => {
        return { ...prev, selected_account_number: sub_account  || actionCtx?.currentAccount};
      });
    }
  };

  const Paye = PayrollTaxCalc(
    formatNumWithoutCommaNaira(details?.onSetAmount)
  )?.monthlyPaye;
  const Pension = PayrollTaxCalc(
    formatNumWithoutCommaNaira(details?.onSetAmount)
  )?.monthlyPension;
  const Nhf = PayrollTaxCalc(
    formatNumWithoutCommaNaira(details?.onSetAmount)
  )?.monthlyNhf;

  useEffect(() => {
    if (details?.addCheckVal) {
      const deduct = sumAllNum([
        payee === "1" ? Paye : 0,
        nhf === "1" ? Nhf : 0,
        pension === "1" ? Pension : 0,
      ]);
      const valSum =
        Number(formatNumWithoutCommaNaira(details?.onSetAmount)) +
        Number(deduct);
      setDetails((prev) => {
        return {
          ...prev,
          amount: valSum,
          amountMinusTax: formatNumWithoutCommaNaira(details?.onSetAmount),
        };
      });
    } else {
      const valSum = Number(formatNumWithoutCommaNaira(details?.onSetAmount));
      const deduct = sumAllNum([
        payee === "1" ? Paye : 0,
        nhf === "1" ? Nhf : 0,
        pension === "1" ? Pension : 0,
      ]);
      setDetails((prev) => {
        return {
          ...prev,
          amount: valSum,
          amountMinusTax:
            formatNumWithoutCommaNaira(details?.onSetAmount) - deduct,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.addCheckVal, nhf, payee, pension]);

  useEffect(() => {
    if (step === 3) {
      calculatTaxValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nhf, payee, pension]);

  const calculatTaxValue = () => {
    const deduct = sumAllNum([
      payee === "1" ? Paye : 0,
      nhf === "1" ? Nhf : 0,
      pension === "1" ? Pension : 0,
    ]);
    const newVal = details?.addCheckVal
      ? formatNumWithoutCommaNaira(details?.onSetAmount)
      : formatNumWithoutCommaNaira(details?.onSetAmount) - deduct;
    setDetails((prev) => {
      return { ...prev, amountMinusTax: newVal, totalDeduction: deduct };
    });
  };

  const handleSubmit = async () => {

    const newList = bulkList?.map((chi) => {
      return {...chi, bank: chi?.bank?.label}
    })
    const obj = {
      nhf_status: nhf,
      payee_status: payee,
      pension_status: pension,
      employees: newList,
      selected_account_number: actionCtx?.currentAccount?.account_number,
    };
    // console.log(obj);
    // return
    const data = await dispatch(autoAddEmployeeToPayroll(obj));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return { ...prev, success: true };
    });
    setStep(1);
    actionCtx?.setConfettiVal();
    setDetails({
      nhf_status: 0,
      pension_status: 0,
      payee_status: 0,
      amount: "",
      totalDeduction: "",
      amountMinusTax: "",
      addCheckVal: false,
      onSetAmount: "",
    });
    getPayrollSetupFunc();
  };

  const size = useWindowSize();

  const formatBulkList = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          id: generateUniqueId(),
          bank: { label: chi?.bank, value: chi?.bank_code },
          name: `${chi?.first_name} ${chi?.last_name}`,
        };
      });
      return newList;
    }
  };

  const handleDelete = (id) => {
    const newList = bulkList?.filter((chi) => chi?.id !== id);
    // console.log(newList);
    // console.log(id);
    setBulkList(newList);
    paginateFunc(
      newList,
      actionCtx?.perPageVal?.value,
      paginatedList?.current_page
    );
    if (bulkList?.length === 1) {
      setStep(1);
    }
  };

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Automatic Payroll Setup`}
          text={`Send funds to a multiple users at once.`}
          next={step === 2}
          onNext={() => {
            calculatTaxValue();
            setStep(3);
          }}
          back
          onBack={() => {
            navigate(-1);
          }}
        />
        <ContainerWrapper
          width={step === 2 ? "100%" : size.width < 900 ? `100%` : `unset`}
          activeStep={step}
          loading={loading}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          stepClickable={bulkList?.length > 0}
          onStepClick={(e) => {
            bulkList?.length > 0 && setStep(e);
          }}
          stepList={stepList}
          btnText={step === 3 && "Save & create Payroll"}
          pagination={
            step === 2 && (
              <RavenPagination
                color={`black-light`}
                blackHover
                currentPage={paginatedList?.current_page}
                totalPage={paginatedList?.total_pages}
                onNumView={(num) =>
                  paginateFunc(bulkList, actionCtx?.perPageVal?.value, num)
                }
                prevPage={paginatedList?.current_page > 1}
                nextPage={
                  paginatedList?.current_page < paginatedList?.total_pages
                }
                onNext={() =>
                  paginateFunc(
                    bulkList,
                    actionCtx?.perPageVal?.value,
                    paginatedList?.current_page + 1
                  )
                }
                onPrev={() =>
                  paginateFunc(
                    bulkList,
                    actionCtx?.perPageVal?.value,
                    paginatedList?.current_page - 1
                  )
                }
              />
            )
          }
          onBtnClick={() => {
            handleSubmit();
          }}
        >
          <div className="payroll-create-index-wrap">
            {/* step 1 start ----------------------------------- */}
            {step === 1 && (
              <>
                <div className="upload-doc-wrap">
                  {/* download info box start */}
                  <div className="download-info-box grey-bg">
                    <div className="text-icon-box">
                      <figure className="img-box">
                        <img src={infoIcon} alt="" className="img" />
                      </figure>
                      <p className="text">
                        Getting started with Payroll
                        <a
                          href={downloadCsv}
                          download="raven_business_payroll_template.xlsx"
                          style={{
                            textDecoration: "none",
                            color: "#755ae2",
                          }}
                        >
                          <span
                            style={{
                              color: "#755ae2 !important",
                              marginLeft: ".5rem",
                            }}
                          >
                            Download sample Template
                          </span>
                        </a>
                      </p>
                    </div>
                    <div className="dot-text">
                      <p className="dot grey-white-color">•</p>
                      <p className="text grey-white-color">
                        You can upload .xlsx or .csv file
                      </p>
                    </div>
                    <div className="dot-text">
                      <p className="dot grey-white-color">•</p>
                      <p className="text grey-white-color">
                        Make sure your file has correct column headers such as{" "}
                        <span>
                          Employee Name, Employee Email, Employee Bank Name, Net
                          Salary and Account number
                        </span>
                      </p>
                    </div>
                    <div className="dot-text">
                      <p className="dot grey-white-color">•</p>
                      <p className="text grey-white-color">
                        Numbers of row should not exceed 1,000
                      </p>
                    </div>
                  </div>
                  {/* download info box end */}
                  {/* upload box start */}
                  <div className="upload-wrapper">
                    {/* <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.google.com/spreadsheets/d/1dHXOiODAadTVUSuIoVIZf6stEX1QLvxb/edit?usp=sharing&ouid=114408454696079379125&rtpof=true&sd=true"
                    >
                      {" "}
                      <p className="upload-text-pro">Use Online Template</p>
                    </a> */}
                    <p className="upload-text-pro">Use Older Template</p>
                    <RavenInputField
                      onChange={(e) => {
                        getExtension(e["name"]) === "xlsx"
                          ? handleFileUpload(e)
                          : toast.error(
                              "File format not supported, Kindly download supportted format above"
                            );
                      }}
                      type={`upload-two`}
                      uploadSpan={`Click to upload `}
                      upploadText={`or drag and drop your xlsx/csv file here`}
                      color={`black-light`}
                    />
                  </div>
                  {/* upload box end */}
                </div>
              </>
            )}
            {/* step 1 end ----------------------------------- */}
            {/* step 2 start ----------------------------------- */}
            {step === 2 && (
              <>
                <div className="create-table-wrap">
                  {/* per page start */}
                  {/* <PerPageComponent /> */}
                  {/* per page end */}
                  <div className="scroll-wrap">
                    <RavenTable
                      className={
                        formatMetaData(user?.theme_display_settings)
                          ?.table_font === "bold" && "table-business"
                      }
                      headerList={headerList}
                      action
                    >
                      {bulkList?.map((chi, idx) => {
                        // console.log(chi);
                        const {
                          account_number,
                          bank,
                          // first_name,
                          // last_name,
                          gross_salary,
                          raven_username,
                          email,
                          phone_number,
                          name,
                          id,
                        } = chi;
                        return (
                          // <RavenTableRow
                          //   one={
                          //     avatarFormatWrap(`${first_name} ${last_name}`) ||
                          //     "---"
                          //   }
                          //   key={idx}
                          //   two={account_number || "---"}
                          //   three={bank || "---"}
                          //   four={raven_username || "---"},
                          //   five={email || "---"}
                          //   six={phone_number || "---"}
                          //   seven={
                          //     formatNumWithCommaNairaSymbol(gross_salary) ||
                          //     "---"
                          //   }
                          // />
                          <PayrollEditTableRow
                            key={id}
                            one={name}
                            two={account_number}
                            three={raven_username}
                            four={gross_salary}
                            five={email}
                            six={bank}
                            seven={phone_number}
                            onEditFininsh={() => {
                              // setError((prev) => {
                              //   return { ...prev, account_number: false };
                              // });
                              setEditState(false);
                              setEditObj({
                                name: "",
                                account_number: "",
                                bank: "",
                                bank_code: "",
                                gross_salary: "",
                                raven_username: "",
                                email: "",
                                phone_number: "",
                                id: "",
                              });
                              // handleSetEditChild();
                            }}
                            showEditButton
                            editMode={editObj?.id === id}
                            handleChange={(e) => handleChange(e, id)}
                            loading={editObj?.id === id}
                            // showError={
                            //   error?.account_number && editObj?.id === id
                            // }
                            // setError={(e) => {
                            //   error?.account_number && setError(e);
                            // }}
                            selectId={id}
                            onDelete={() => {
                              handleDelete(id);
                            }}
                            onEdit={() => {
                              // console.log("gdfghj");
                              setEditState(true);
                              setEditObj(chi);
                            }}
                          />
                        );
                      })}
                    </RavenTable>
                  </div>

                  <div className="mobile-table-box">
                    {bulkList?.map((chi, idx) => {
                      const {
                        account_number,
                        bank,
                        first_name,
                        last_name,
                        gross_salary,
                      } = chi;
                      return (
                        <MobileTableCard
                          key={idx}
                          amount={formatNumWithCommaNairaSymbol(gross_salary)}
                          text={`${account_number} • ${trimLongString(
                            bank,
                            15
                          )}`}
                          avatar={`${first_name} ${last_name}`}
                          onRowClick={() => {
                            setSingleObj(chi);
                            setShowModal((prev) => {
                              return { ...prev, mobile_view: true };
                            });
                          }}
                          loading={loading}
                          title={`${first_name} ${last_name}`}
                          showImg
                        />
                      );
                    })}
                  </div>

                  {/* pagination start */}
                  {/* <div className="table-pagination-box">
                  <RavenPagination
                    color={`black-light`}
                    blackHover
                    currentPage={1}
                    totalPage={7}
                  />
                </div> */}
                  {/* pagination end */}
                  {/* button start */}
                  <div className="btn-upload-box">
                    {/* <RavenButton
                    textColor={`base-bg-black`}
                    color={`black-light`}
                    className={`btn-outline-grey`}
                    // label={`Reupload file`}
                    size={`medium`}
                  >
                    {" "}
                    <label
                      htmlFor="input-upload-file-bulk"
                      className="label-upload-file-bulk"
                      style={{ cursor: "pointer" }}
                    >
                      Reupload file
                    </label>
                  </RavenButton> */}
                    {/* <input
                      type="file"
                      name="input-upload-file-bulk"
                      id="input-upload-file-bulk"
                      className="input-upload"
                      onChange={() => {}}
                    /> */}

                    <RavenButton
                      onClick={() => {
                        calculatTaxValue();
                        setStep(3);
                      }}
                      label={`Proceed`}
                      color={`black-light`}
                      style={{ marginBottom: "5rem" }}
                    />
                  </div>
                  {/* button end */}
                </div>
              </>
            )}
            {/* step 2 end ----------------------------------- */}
            {/* step 3 start ----------------------------------- */}
            {step === 3 && (
              <div style={{ paddingBottom: "15rem", marginTop: "3rem" }}>
                <NewPreviewDetailBox
                  netSalary={formatNumWithCommaNairaSymbol(details?.amount)}
                  grossSalary={formatNumWithCommaNairaSymbol(
                    details?.amountMinusTax
                  )}
                  amount={details?.amount}
                  name={details?.username}
                  narration={details?.narration}
                  checked={details?.beneficiary}
                  number={details?.phone}
                  totalDeduction={formatNumWithCommaNairaSymbol(
                    details?.totalDeduction
                  )}
                  payeeAmount={formatNumWithCommaNairaSymbol(Paye)}
                  onChangePayee={(e) => {
                    payee === "1" ? setPayee("0") : setPayee("1");
                  }}
                  checkedPayee={payee === "1" ? true : false}
                  payeeValue={payee}
                  pensionAmount={formatNumWithCommaNairaSymbol(Pension)}
                  onChangePension={(e) => {
                    pension === "1" ? setPension("0") : setPension("1");
                  }}
                  checkedPension={pension === "1" ? true : false}
                  pensionValue={pension}
                  nhfAmount={formatNumWithCommaNairaSymbol(Nhf)}
                  onChangeNhf={(e) => {
                    nhf === "1" ? setNhf("0") : setNhf("1");
                  }}
                  checkedNhf={nhf === "1" ? true : false}
                  nhfValue={nhf}
                  addCheckVal={details?.addCheckVal}
                  onAddCheckVal={() => {
                    setDetails((prev) => {
                      return {
                        ...prev,
                        addCheckVal: !details?.addCheckVal,
                      };
                    });
                  }}
                  defaultSubAccount={
                    details?.selected_account_number ||
                    actionCtx?.currentAccount
                  }
                  setDefaultSubAccount={(e) => {
                    setDetails((prev) => {
                      return { ...prev, selected_account_number: e };
                    });
                  }}
                />
              </div>
            )}
            {/* step 3 end ----------------------------------- */}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Payroll Created Successfully."
        text={`You have successfully added  new employee(s) to your payroll. We appreciate your business and look forward to serving you again in the future.`}
        btnText={`Go to Payroll`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
          navigate("/dashboard-payroll");
        }}
      />
      {/* sign up modal end */}
      {/* delete mod start */}
      <MobileEmployeeViewCreate
        visible={showModal?.mobile_view && size.width < 900}
        detail={singleObj}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, mobile_view: false };
          });
        }}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, mobile_view: false };
          });
        }}
      />
      {/* delete mod end */}
    </>
  );
};

export default PayrollAutoCreate;
