import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useContext, useState } from "react";
import "../../../styles/auth/SignUp.css";
// import dagoteIcon from "../../../assets/dagote-icon.svg";
import {
  clearCache,
  formatMetaData,
  formatTypeFunction,
  lowerCaseWrap,
  trimLongString,
} from "../../../utils/helper/Helper";
import { authenticateLogin, authenticateLoginSso } from "../../../redux/user";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ActionContext from "../../../context/ActionContext";
import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";

const AuthAccountSelectModal = ({
  visible,
  onClose,
  selectList,
  loginDetails,
  sso,
  deviceId,
}) => {
  const [accountSelected, setAccountSelected] = useState("");
  const { loading } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);

  const encryptTokenFunc = (tok) => {
    const token = tok;
    const secretKey =
      "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

    // Encrypt the token
    const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
    return encryptedToken;
  };

  const handleSubmitDirect = async () => {
    // e.preventDefault();

    let val;
    const emailInvite = localStorage?.getItem("invite--email");
    const refBusinessEmail = localStorage?.getItem("invite-business-email");
    const refInv = localStorage.getItem("invite-ref");
    if (emailInvite && refBusinessEmail === accountSelected?.business_email) {
      const obj = {
        ...loginDetails,
        business_id: String(accountSelected?.business_id),
        email: accountSelected?.user_email || loginDetails?.email,
        otp: loginDetails?.pin,
        password: loginDetails?.password,
        device_id: deviceId,
        invite_ref: refInv,
      };
      val = obj;
    } else {
      const obj = {
        ...loginDetails,
        business_id: String(accountSelected?.business_id),
        email: accountSelected?.user_email || loginDetails?.email,
        otp: loginDetails?.pin,
        password: loginDetails?.password,
        device_id: deviceId,
      };
      val = obj;
    }

    // console.log(val);
    // return
    if (sso) {
      // console.log("yeah");
      // return;
      const data = await dispatch(authenticateLoginSso(val));
      // console.log(data);
      // return
      clearCache();
      if (data?.payload?.data?.status === "success") {
        actionCtx?.setIsLogout(false);
        localStorage?.removeItem("invite--email");
        localStorage?.removeItem("invite-business-email");
        localStorage.removeItem("invite-ref");
        const token = data?.payload?.data?.data?.token;
        localStorage?.setItem("token", encryptTokenFunc(token));
        const business = data?.payload?.data?.data?.business;
        const user = data?.payload?.data?.data?.user;
        localStorage?.setItem(
          "sidebar-theme-style",
          formatMetaData(user?.theme_display_settings)?.side_nav_style
        );
        localStorage?.setItem(
          "theme",
          formatMetaData(user?.theme_display_settings)?.theme
        );
        actionCtx?.setPerPage({
          label: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
          value: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
        });
        localStorage.setItem("bvn_status", business?.bvn);
        checkNavigate(business)
      }
    }
    if (!sso) {
      const data = await dispatch(authenticateLogin(val));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        actionCtx?.setIsLogout(false);
        localStorage?.removeItem("invite--email");
        localStorage?.removeItem("invite-business-email");
        localStorage.removeItem("invite-ref");
        // return
        clearCache();
        const token = data?.payload?.data?.data?.token;
        localStorage?.setItem("token", encryptTokenFunc(token));
        const business = data?.payload?.data?.data?.business;
        const user = data?.payload?.data?.data?.user;
        localStorage?.setItem(
          "sidebar-theme-style",
          formatMetaData(user?.theme_display_settings)?.side_nav_style
        );
        actionCtx?.setPerPage({
          label: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
          value: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
        });
        localStorage?.setItem(
          "theme",
          formatMetaData(user?.theme_display_settings)?.theme
        );
        localStorage.setItem("bvn_status", business?.bvn);
        checkNavigate(business)
      }
    }
  };

  const formSelectList = (list) => {
    // if (list?.length > 0) {
    //   const newList = list?.filter(
    //     (chi) => Number(chi?.confirmation_status) === 1
    //   );
    //   return newList;
    // }
    // console.log(list);
    return list;
  };

  const formatTypeWrap = (param) => {
    return (
      <div
        className={`status-type-wrap status-type-wrap-virtual ${
          param === "Virtual" && "status-type-wrap-virtual"
        }`}
      >
        <span>{lowerCaseWrap(param)}</span>
      </div>
    );
  };
  // console.log(selectList);

  const checkNavigate = (business) => {
    if (business?.bvn !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      return;
    }
    if (business?.nin !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-cac");
      return;
    }
    if (business?.cac !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-cac");
      return;
    }
    if (business?.address !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-address");
      return;
    }
    if (business?.business_info !== 3) {
      const urlLink = window?.location.href.split("?")[1];
      navigate(`/dashboard-verification${urlLink ? `?${urlLink}` : ""}`);
      // navigate("/dashboard-verification-business");
      return;
    }
    // onSuccess();
    const urlLink = window?.location.href.split("?")[1];
    if (urlLink) {
      navigate(`/${urlLink}`);
    } else {
      navigate("/dashboard-overview");
    }
  };

  return (
    <RavenModal
      className={`auth-pin-modal-wrap`}
      onClose={onClose}
      visble={visible}
      btnLabel={`Login to account.`}
      btnColor={`black-light`}
      disabled={!accountSelected}
      onBtnClick={handleSubmitDirect}
      loading={loading}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        action=""
        className="form form-modal-auth"
      >
        <div className="text-box">
          <p className="big-title">Select your business</p>
          <p className="small-title">
            We noticed your account is linked to multiple business select the
            business you want to log into.
          </p>
        </div>
        {/* select wrap start */}
        <div className="select-wrap-account-auth">
          {formSelectList(selectList)?.map((chi, idx) => {
            const { business_name, business_logo, confirmation_status } = chi;
            return (
              <div
                onClick={() => {
                  accountSelected?.business_name === business_name
                    ? setAccountSelected("")
                    : setAccountSelected(chi);
                }}
                className="select-item"
                key={idx}
              >
                <div className="img-avatar-wrap">
                  {business_logo ? (
                    <figure className="img-box">
                      <img src={business_logo} alt="" className="img" />
                    </figure>
                  ) : (
                    <div className="avatar-box">
                      <span style={{ textTransform: "uppercase" }}>{`${
                        formatTypeFunction(business_name?.replace("-", " "))
                          ?.split(" ")[0]
                          ?.charAt(0) || ""
                      } ${
                        formatTypeFunction(business_name?.replace("-", " "))
                          ?.split(" ")[1]
                          ?.charAt(0) ||
                        formatTypeFunction(business_name?.replace("-", " "))
                          ?.split(" ")[0]
                          ?.charAt(1) ||
                        ""
                      }`}</span>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    textTransform: "capitalize",
                  }}
                  className="name"
                >
                  {trimLongString(business_name?.replace("-", " "), 25) ||
                    "---"}{" "}
                  {String(confirmation_status) === "0" ? (
                    <div>{formatTypeWrap("Pending")}</div>
                  ) : (
                    ""
                  )}
                </div>
                {/* check wrap start */}
                <div
                  className={`check-wrap ${
                    accountSelected?.business_name === business_name &&
                    "check-wrap-active "
                  }`}
                ></div>
                {/* check wrap end */}
              </div>
            );
          })}
        </div>
        {/* select wrap end */}
      </form>
    </RavenModal>
  );
};

export default AuthAccountSelectModal;
