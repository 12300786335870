import React from 'react'
import securityIcon from "../../assets/security-tip-icon.svg";
import "../../styles/fragments/LockBox.css"

const LockBox = ({className, text}) => {
  return (
    <div className={`security-lock-wrap-box ${className}`}>
    <figure className="img-box">
      <img src={securityIcon} alt="" className="img dull-bright-filter" />
    </figure>
    <p className="text grey-white-color dull-bright-filter">{text}</p>
  </div>
  )
}

export default LockBox