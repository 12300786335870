import {
  RavenButton,
  RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../../../styles/dashboard/Two-Fa/ConfirmTwoFaModal.css";
// import QRCode from "react-qr-code";
// import { FaCheck } from "react-icons/fa";
import NoContentBox from "../../../../components/reusables/NoContentBox";
import { useSelector } from "react-redux";
// import { trimLongString } from "../../../../utils/helper/Helper";
import { useDispatch } from "react-redux";
import {
  disableTwoFaFactorSettings,
  // verifyTwoFaFactorSettings,
} from "../../../../redux/settings";
import { getUser } from "../../../../redux/user";
import { debounce } from "lodash";

const DisableTwoFaModal = ({ visible, onClose, onFinish, detail }) => {
  const { loadingEnable, loadingVerify2fa } = useSelector(
    (state) => state.settings
  );
  // const copyIcon = (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="21"
  //     height="20"
  //     fill="none"
  //     viewBox="0 0 21 20"
  //     className="img"
  //   >
  //     <path
  //       stroke="#676767"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       strokeWidth="1.5"
  //       d="M9.609 1.669c-.563.008-.9.04-1.174.18-.313.16-.568.414-.728.728-.14.273-.172.61-.18 1.173m9.582-2.081c.562.008.9.04 1.173.18.314.16.569.414.728.728.14.273.172.61.18 1.173m0 7.5c-.008.563-.04.9-.18 1.173-.16.314-.414.569-.728.729-.273.139-.61.171-1.173.179m2.083-6.664v1.666m-6.667-6.666h1.667m-9 16.666h6.333c.934 0 1.4 0 1.757-.181.314-.16.569-.415.728-.729.182-.356.182-.823.182-1.756V9.333c0-.933 0-1.4-.182-1.756a1.667 1.667 0 00-.728-.729c-.357-.181-.823-.181-1.757-.181H5.192c-.933 0-1.4 0-1.757.181-.313.16-.568.415-.728.729-.182.356-.182.823-.182 1.756v6.334c0 .933 0 1.4.182 1.756.16.314.415.569.728.729.357.181.824.181 1.757.181z"
  //     ></path>
  //   </svg>
  // );
  const dispatch = useDispatch();
  // const [isCopied, setIsCopied] = useState(false);
  const [details, setDetails] = useState({
    token: "",
  });
  const [completeToken, setCompleteToken] = useState(false);

  const handleSubmit = debounce(async (num) => {
    const obj = {
      token: num || details?.token,
    };
    const data = await dispatch(disableTwoFaFactorSettings(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
      dispatch(getUser());
    }
  }, 100);

  const handleClose = () => {
    setDetails((prev) => {
      return { ...prev, token: "" };
    });
    setCompleteToken(false);
    onClose();
  };

  return (
    <>
      <RavenModal
        className={`confirm-two-fa-modal-wrap-index-modal diable-two-fa-modal-wrap-index-modal`}
        onBtnClick={handleSubmit}
        visble={visible}
        onClose={handleClose}
      >
        <div style={{paddingBottom: "10rem"}} className="confirm-two-fa-modal-wrap-index">
          <div className="title-text-box">
            <p className="title">Disable 2FA Authentication</p>
            <p className="text grey-white-color">
              Enter the verification code from the authenticator app.
            </p>
          </div>
          {loadingEnable ? (
            <>
              <NoContentBox loading={true} />
            </>
          ) : (
            <>
              {" "}
              {/* Enter pin box start */}
              <div className="enter-pin-text-box">
                {/* <p className="enter-text">
                  Enter the verification code from the authenticator app
                </p> */}
                <div className="pin-box">
                  <RavenInputField
                    value={details?.token}
                    onChange={(e) => {
                      //   console.log(e);
                      setDetails((prev) => {
                        return { ...prev, token: e };
                      });
                      setCompleteToken(false);
                    }}
                    onComplete={(num) => {
                      setCompleteToken(true);
                      // handleSubmit(num);
                    }}
                    type="pin"
                    color="black-light"
                  />
                </div>
              </div>
              {/* Enter pin box end */}
              {/* buttuon box start */}
              <div className="btn-two-box grey-bg">
                <RavenButton
                  color="black-light white-black-bg black-white-color border-theme"
                  label="Cancel"
                  style={{
                    backgroundColor: "#ffffff",
                    border: "0.1rem solid rgba(227, 227, 227, 1)",
                  }}
                  onClick={onClose}
                />
                <RavenButton
                  disabled={!completeToken}
                  loading={loadingVerify2fa}
                  color="black-light"
                  label="Disable"
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </div>
              {/* buttuon box end */}
            </>
          )}
        </div>
      </RavenModal>
    </>
  );
};

export default DisableTwoFaModal;
