// import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentLinkIcon from "../../assets/PaymeIcnoNotiify.svg";
import OtherLinkIcon from "../../assets/OthersIcnoNotiify.svg";
import AirtimeLinkIcon from "../../assets/AirtimeIcnoNotiify.svg";
import ElectricityLinkIcon from "../../assets/ElectricityIcnoNotiify.svg";
import PaymentLinkTwoIcon from "../../assets/PaymentIcnoNotiify.svg";
import teamInviteIcon from "../../assets/InviteIcnoNotiify.svg";
import {
  markAllNotificationAsRead,
  markNotificationAsRead,
} from "../../redux/paymentRequest";
import { ThreeDots } from "react-loader-spinner";
import "../../styles/reusables/NotificationModal.css";
import {
  formatDate,
  formatTypeFunction,
  getPageNum,
  getTotalPage,
  lowerCaseWrap,
  trimLongString,
} from "../../utils/helper/Helper";
import { getAllNotification } from "../../redux/user";
import NoContentBox from "./NoContentBox";
import { RavenPagination } from "@ravenpay/raven-bank-ui";
import Skeleton from "react-loading-skeleton";
import { getTransactionsTwo } from "../../redux/transaction";
import ActionContext from "../../context/ActionContext";

const NotiReuseableBox = ({ onClose, typeTwo }) => {
  const actionCtx = useContext(ActionContext)
  const perPage = 10;
  const selectOption = ["All", "Unread", "Read"];
  const { loadingNotify } = useSelector(
    (state) => state.user
  );

  // eslint-disable-next-line no-unused-vars
  const [viewAll, setViewAll] = useState(false);
  const [activeSelect, setActiveSelect] = useState("All");
  const { loadingStatement } = useSelector((state) => state.payment_request);
  const dispatch = useDispatch();
  const formatListToView = (param) => {
    let listToUse;
    if (param?.length > 0) {
      if (activeSelect === "All") {
        listToUse = param;
      }
      if (activeSelect === "UnRead") {
        listToUse = param?.filter((chi) => String(chi?.status) === "0");
      }
      if (activeSelect === "Read") {
        listToUse = param?.filter((chi) => String(chi?.status) === "1");
      }
      return listToUse;
    }
  };

  const handleViewAllFunction = (param) => {
    let listToUse;
    if (param?.length > 0) {
      if (viewAll) {
        listToUse = param?.slice(0, param?.length)?.map((chi) => chi);
      } else {
        listToUse = param?.slice(0, 10)?.map((chi) => chi);
      }
      return listToUse;
    }
  };
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (param, id, chi) => {
    // console.log(chi);
    if (Number(chi?.status) !== 1) {
      handleMarkAsRead(id);
    }
    // onClose()
    if (
      param === "payment_request" &&
      String(JSON?.parse(chi?.payload)?.status) === "26"
    ) {
      if(  actionCtx?.currentAccount?.authorization_level !==
        ("initiator_approver" || "approver") &&
      actionCtx?.currentAccount?.authorization_level !==
        ("approver" || "initiator_approver")){
          return
        }
      const idTwo = JSON?.parse(chi?.payload)?.id;
      localStorage.setItem("single-payment-request-id", idTwo);
      location.pathname === "/dashboard-payment-requests-confirm"
        ? window?.location.replace("/dashboard-payment-requests")
        : 
        navigate("/dashboard-payment-requests");
      return;
    }
    // if (param === "betting") {
    //   navigate("/dashboard-bill-payment-betting");
    // }
    // if (param === "airtime") {
    //   navigate("/dashboard-bill-payment-airtime");
    // }
    // if (param === "data") {
    //   navigate("/dashboard-bill-payment-data");
    // }
    // if (param === "cable") {
    //   navigate("/dashboard-bill-payment-cable");
    // }
    // if (param === "electricity") {
    //   navigate("/dashboard-bill-payment-electricity");
    // }
    if (param === "credit_transaction" || param === "transfer") {
      // console.log("jhgdj");
      if(location.pathname === "/dashboard-transaction"){
        dispatch(getTransactionsTwo())
        onClose()
      }
      else{
        navigate("/dashboard-transaction");
      }
    }
    if (param === "payment_request") {
      navigate("/dashboard-payment-requests");
    }
    else{
      navigate("/dashboard-transaction");
    }
    onClose && onClose();
    setActiveSelect("All");
  };

  const handleMarkAsRead = async (param) => {
    const obj = {
      notifiactionId: String(param),
    };
    dispatch(markNotificationAsRead(obj));
  };

  const handleMarkAllAsRead = async () => {
    // console.log(all_notification);
    const newList = actionCtx?.allNotifications?.data?.filter(
      (chi) => String(chi?.status) === "0"
    );
    const onlYIdList = newList?.map((chi) => chi?.id);
    // console.log(onlYIdList);
    const obj = {
      notifiactionIds: onlYIdList,
    };
    const data = await dispatch(markAllNotificationAsRead(obj));
    if (data?.payload?.data?.status === "success") {
      dispatch(getAllNotification());
    }
  };

  // useEffect(() => {
  //   // console.log(actionCtxad);
  // }, [actionCtxad]);

  const fetchNotification = (param) => {
    const obj = {
      per_page: perPage,
      current_page: String(param),
    };
    // console.log(obj);
    dispatch(getAllNotification(obj));
  };

  return (
    <>
      <div
        className={`${!typeTwo && "notification-wrap-modal-index"} ${
          typeTwo && "notification-wrap-modal-index-big card-bg"
        }`}
      >
        {/* title and read all box start */}
        <div className="title-all-box">
          <p className="title">Notifications</p>
          {loadingStatement ? (
            <>
              {" "}
              <div
                style={{
                  padding: "0rem",
                  display: "flex",
                  justifyContent: "flex-end",
                  // transform: "translateX(1.5rem)",
                }}
                className="load-wrap"
              >
                <ThreeDots
                  height="10"
                  width="80"
                  radius="9"
                  // color="#020202"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            </>
          ) : (
            <>
              {actionCtx?.allNotifications?.data?.some(
                (chi) => String(chi?.status) === "0"
              ) && (
                <p
                  onClick={handleMarkAllAsRead}
                  className="all grey-white-color"
                >
                  Mark all as read
                </p>
              )}
            </>
          )}
        </div>
        {/* title and read all box end */}
        {/* select header box start */}
        <div className="select-view-box border-theme-bottom">
          {selectOption?.map((chi, idx) => {
            return (
              <div
                onClick={() => {
                  setActiveSelect(chi);
                }}
                key={idx}
                className={`select-view-item ${
                  activeSelect === chi && "select-view-item-active"
                }`}
              >
                <p
                  className={`text grey-white-color-white ${
                    activeSelect === chi && "text-active"
                  }`}
                >
                  {chi}
                </p>
                {chi === "All" && (
                  <p style={{marginLeft: ".4rem"}} className="num black-fade-white-bg">
                    <span>{actionCtx?.allNotifications?.data?.length || "0"}</span>
                  </p>
                )}
              </div>
            );
          })}
        </div>
        {/* select header box end */}
        {/* notify content start */}
        {formatListToView(actionCtx?.allNotifications?.data)?.length > 0 ? (
          <div className="notification-content-box">
            {handleViewAllFunction(
              formatListToView(actionCtx?.allNotifications?.data)
            )?.map((chi, idx) => {
              const { status, title, notification_type, created_at, id } = chi;
              return (
                <div
                  key={idx}
                  className={`notify-item ${
                    idx !== actionCtx?.allNotifications?.data?.length - 1 &&
                    "border-theme-bottom"
                  } ${String(status) === "0" && "grey-bg"}`}
                  onClick={() => {
                    handleNavigate(notification_type, id, chi);
                  }}
                >
                  <div className="img-wrap">
                    {loadingNotify ? (
                      <>
                        <Skeleton width={30} height={30} circle />
                      </>
                    ) : (
                      <figure className="img-box ">
                        <img
                          src={
                            notification_type === "payment_request"
                              ? PaymentLinkIcon
                              : notification_type === "airtime" ||
                                notification_type === "data"
                              ? AirtimeLinkIcon
                              : notification_type === "electricity"
                              ? ElectricityLinkIcon
                              : notification_type === "payment_link"
                              ? PaymentLinkTwoIcon
                              : notification_type === "team_invite"
                              ? teamInviteIcon
                              : OtherLinkIcon
                          }
                          alt=""
                          className="img"
                        />
                      </figure>
                    )}
                  </div>
                  <div className="description-box">
                    {" "}
                    {loadingNotify ? (
                      <>
                        <Skeleton width={180} height={15} />
                      </>
                    ) : (
                      <div className="notify-text tooltip-hover-wrap">
                        {/* <RavenToolTip
                          text={title?.replace("NGN", "\u{020A6}")}
                          // big
                          color="black-light"
                          position={idx < 2 ? `bottom-center` : `top-center`}
                        /> */}
                        <span>
                          {lowerCaseWrap(
                            formatTypeFunction(
                              title?.replace("NGN", "\u{020A6}")?.split("-")[0]
                            )
                          )}
                        </span>{" "}
                        {lowerCaseWrap(
                          formatTypeFunction(
                            trimLongString(
                              title?.replace("NGN", "\u{020A6}")?.split("-")[1],
                              45
                            )
                          )
                        )}
                      </div>
                    )}
                    {loadingNotify ? (
                      <>
                        <Skeleton width={250} height={9} />
                      </>
                    ) : (
                      <div className="time-type-box">
                        <p className="time grey-white-color">{`${formatDate(
                          created_at
                        )}  • ${lowerCaseWrap(formatTypeFunction(notification_type))}`}</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{ flex: "1", display: "grid", placeItems: "center" }}
            className=""
          >
            <NoContentBox
              title={`No ${
                activeSelect !== "All" ? `( ${activeSelect} )` : ""
              } notification yet`}
              text={`Notification will appear here when notification is available`}
              bgColor
              // loading={loading}
            />
          </div>
        )}
        {/* notify content end */}
        {/* view all box start */}
        {loadingNotify ? (
          <div className="view-all-box-wrap"></div>
        ) : actionCtx?.allNotifications?.next_page_url_params ||
          actionCtx?.allNotifications?.prev_page_url_params ? (
          <div className="view-all-box-wrap">
            {/* <p
              onClick={() => {
                setViewAll(!viewAll);
              }}
              className="text grey-white-color-white"
            >
              {viewAll
                ? `View first 10 notifications`
                : `View all notifications`}
            </p> */}
          {formatListToView(actionCtx?.allNotifications?.data)?.length > 0 &&  <RavenPagination
              color={`black-light`}
              blackHover
              currentPage={Number(actionCtx?.allNotifications?.current_page) || 1}
              nextPage={actionCtx?.allNotifications?.next_page_url_params}
              prevPage={actionCtx?.allNotifications?.prev_page_url_params}
              onNext={(e) => {
                fetchNotification(getPageNum(e));
              }}
              onPrev={(e) => {
                fetchNotification(getPageNum(e));
              }}
              totalPage={
                getTotalPage(
                  Number(actionCtx?.allNotifications?.per_page),
                  Number(actionCtx?.allNotifications?.total)
                ) || 1
              }
              onNumView={(e) => {
                fetchNotification(e);
              }}
            />}
          </div>
        ) : loadingNotify ? (
          ""
        ) : (
          ""
        )}
        {/* view all box end */}
      </div>
    </>
  );
};

export default NotiReuseableBox;
