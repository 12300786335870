import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import bankIcon from "../../../assets/add-money-bank-transfer.svg";
import cardIcon from "../../../assets/add-money-card-icon.svg";
import LockBox from "../../../components/fragments/LockBox";
import "../../../styles/dashboard/global-account/ReceiveGlobalAccount.css";
// import copyIcon from "../../../assets/add-money-copy-icon.svg";
import { FaCheck } from "react-icons/fa";
// import { useDarkMode } from "../../../utils/themes/useDarkMode";
import { useSelector } from "react-redux";
import ActionContext from "../../../context/ActionContext";
// import AccountDropDownBox from "../../../components/fragments/AccountDropDownBox";
import {
  formatNumWithCommaNairaSymbol,
  trimLongString,
} from "../../../utils/helper/Helper";
import {
  RavenCheckBox,
  RavenInputField,
  RavenRadioCheck,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import NoContentBox from "../../../components/reusables/NoContentBox";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import NewPreviewDetailBox from "./PreviewGlobalAccountReceive";
import { useDispatch } from "react-redux";
import {
  getAllGlobalAccount,
  getSingleGlobalAccount,
} from "../../../redux/globalAccount";
// import { validateRavenUserName } from "../../../redux/payroll";

const ReceiveGlobalAccount = () => {
  // eslint-disable-next-line no-unused-vars
  const [urlBack, setUrlBack] = useState("");
  const [optionSelected, setOptionSelected] = useState("One time payment");
  const [activeSelect, setActiveSelect] = useState("Default Link");
  const {  all_global_account, single_global_account } = useSelector(
    (state) => state.global_account
  );
  const navigate = useNavigate();
  const actionCtx = useContext(ActionContext);
  const [step, setStep] = useState(1);
  const [isCopied, setIsCopied] = useState(false);
  // const [ theme ] = useDarkMode(localStorage.getItem("theme"));
  const dispatch = useDispatch();
  const { all_account } = useSelector((state) => state.user);

  const defaultIcon = (
    <svg
      className="img"
      width="38"
      height="40"
      viewBox="0 0 38 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_18352_43729)">
        <rect
          x="2"
          y="2.59998"
          width="33.8"
          height="33.8"
          rx="16.9"
          fill="white"
          shapeRendering="crispEdges"
        />
        <rect
          x="2.5"
          y="3.09998"
          width="32.8"
          height="32.8"
          rx="16.4"
          stroke="#F7F7F7"
          shapeRendering="crispEdges"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0203 15.0499C26.0203 13.5753 24.8249 12.3799 23.3503 12.3799H14.4503C12.9757 12.3799 11.7803 13.5753 11.7803 15.0499V23.95C11.7803 25.4246 12.9757 26.62 14.4503 26.62H23.3503C24.8249 26.62 26.0203 25.4246 26.0203 23.95L26.0203 15.0499ZM17.424 16.3601C17.1344 16.6498 17.1344 17.1194 17.424 17.409C17.7137 17.6987 18.1833 17.6987 18.4729 17.409L19.2634 16.6185C19.6094 16.2725 20.0848 16.0796 20.5952 16.0834C21.106 16.0871 21.5909 16.2877 21.9492 16.646L21.9575 16.6541C22.3248 17.0101 22.524 17.4906 22.5278 18C22.5314 18.4988 22.3308 18.9775 21.9766 19.3317L21.1861 20.1222C20.8965 20.4119 20.8965 20.8815 21.1861 21.1711C21.4758 21.4608 21.9454 21.4608 22.235 21.1711L23.0255 20.3806C23.6535 19.7526 24.0177 18.8949 24.0111 17.9891C24.0045 17.096 23.6527 16.2336 22.994 15.593C22.356 14.9571 21.498 14.6066 20.6061 14.6001C19.712 14.5935 18.8508 14.9334 18.2145 15.5696L17.424 16.3601ZM14.9538 18.7821C14.3258 19.4101 13.9616 20.2678 13.9683 21.1736C13.9748 22.0649 14.3251 22.9255 14.9812 23.5657C15.6214 24.2218 16.4822 24.5721 17.3733 24.5786C18.2858 24.5853 19.1303 24.2436 19.7649 23.6091L20.5554 22.8186C20.845 22.5289 20.845 22.0593 20.5554 21.7697C20.2658 21.4801 19.7962 21.4801 19.5065 21.7697L18.716 22.5602C18.3683 22.9079 17.9081 23.0992 17.3842 23.0953C16.8748 23.0916 16.3942 22.8924 16.0383 22.5251C16.0329 22.5195 16.0274 22.514 16.0218 22.5086C15.6545 22.1526 15.4553 21.6721 15.4515 21.1627C15.4479 20.6639 15.6485 20.1852 16.0027 19.831L16.7932 19.0405C17.0828 18.7508 17.0828 18.2812 16.7932 17.9916C16.5036 17.702 16.034 17.702 15.7443 17.9916L14.9538 18.7821ZM20.9405 18.6443L18.0445 21.5402C17.7549 21.8299 17.2853 21.8299 16.9956 21.5402C16.706 21.2506 16.706 20.781 16.9956 20.4913L19.8916 17.5954C20.1812 17.3057 20.6508 17.3057 20.9405 17.5954C21.2301 17.885 21.2301 18.3546 20.9405 18.6443Z"
          fill="#646464"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_18352_43729"
          x="0"
          y="0.599976"
          width="37.8"
          height="38.8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18352_43729"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_18352_43729"
            result="effect2_dropShadow_18352_43729"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_18352_43729"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  const [bankDetails] = useState([
    {
      title: "Default Link",
      text: "Add money through an already created link.",
      icon: cardIcon,
      //   coming_soon: true,
      iconTwo: defaultIcon,
      linkToUse: all_global_account?.default_payment_link?.link || "",
    },
    {
      title: "Receive using link",
      text: "Add money through by creating a new link.",
      icon: cardIcon,
      iconTwo: defaultIcon,
      //   coming_soon: true,
      link: `/dashboard-payment-links-request-money?currency=${
        single_global_account?.global_account?.currency || ""
      }`,
    },
    {
      title: "Bank Transfers",
      text: "Copy the account number and make a transfer",
      icon: bankIcon,
      // bank_name: `Guaranteed Trust Bank (GTBank)`,
      // acc_num: `0121559651`,
      // acc_name: `Olakunle Temitayo Abraham`,
      coming_soon: true,
    },
  ]);
  const [details, setDetails] = useState({
    request_amount: "",
    email: "",
    request_description: "",
    expiry_date: "",
    recurring: false,
    agree: false,
    title: "",
    allow_edit_amount: false,
  });

  // useEffect(() => {
  //   const url = window?.location?.href?.split("?")[1];
  //   if (url) {
  //     setUrlBack(url);
  //   }
  // }, []);

  useEffect(() => {
    dispatch(getAllGlobalAccount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const id = localStorage?.getItem("global-acc-id");
    // console.log(id);
    if (id) {
      getSingleGlobalAccFunc(id);
    } else {
      navigate("/dashboard-global-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleGlobalAccFunc = async (param) => {
    // console.log(param, "------ud-----");
    // for transactions
    // console.log(param);
    const obj = {
      id: param,
    };
    // console.log(obj);
    dispatch(getSingleGlobalAccount(obj));

    // end for transaction
  };

  // useEffect(() )

  const handleCopied = async (num) => {
    setIsCopied(true);
    await navigator.clipboard.writeText(num);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  const size = useWindowSize();
  //   const [showAccountDrop, setShowAccountDrop] = useState(false);
  //   const dropDownIcon = (
  //     <svg
  //       className="img"
  //       width="17"
  //       height="17"
  //       viewBox="0 0 17 17"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M4.2423 6.0083L8.24774 10.0137L12.2532 6.0083"
  //         stroke="rgba(117, 90, 226, 1)"
  //         strokeWidth="1.20163"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   );

  const rightArrowIcon = (
    <svg
      className="img"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M6 9.5L12 15.5L18 9.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const { user_resource_and_permissions } = useSelector((state) => state.user);
  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_setup_global_account"
      )){
      navigate("/dashboard-global-account")
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (all_account?.length < 1) {
    return (
      <DashboardLayout>
        <TitletextBox
          title={`Add Money`}
          text={`Top up your Raven account with multiple methods`}
        />
        {/* title box end */}
        <ContainerWrapper dontShowHeader>
          <NoContentBox
            title={`No permission to view this page`}
            text={`You will be able to view this screen when your role has been added to an account`}
            bgColor
            // loading={loading}
          />
        </ContainerWrapper>
      </DashboardLayout>
    );
  }

  const stepList = ["Enter details", "Confirm details"];

  const selectOptionLinkList = ["One time payment", "Recurring Payment"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async () => {};

  


  return (
    <DashboardLayout>
      {/* title box start */}
      <TitletextBox
        title={`Receive USD Payment`}
        text={`Accept and transact with your USD account`}
        back={true}
        onBack={() => {
          navigate("/dashboard-global-account-single");
          //   if (urlBack) {
          //     navigate(`/${urlBack}`);
          //   } else {
          //     navigate("/dashboard-global-account");
          //   }
        }}
      />
      {/* title box end */}
      {/* container wrapp start */}
      <ContainerWrapper
        activeStep={step}
        stepList={activeSelect === "Receive using link" && stepList}
        width={size.width < 900 && "90%"}
        back={activeSelect === "Receive using link" && step > 1}
        btnText={
          activeSelect === "Receive using link" && step === 1
            ? "Proceed"
            : activeSelect === "Receive using link" && step === 2
            ? "Create link."
            : ""
        }
        onBack={() => {
          if (step > 1) {
            setStep(step - 1);
          }
        }}
        onBtnClick={() => {
          if (step === 1) {
            setStep(2);
          } else {
            handleSubmit();
          }
        }}
      >
        <div
          className={`receive-global-select-wrap-box-new ${
            step === 2 && "receive-global-select-wrap-box-new-two"
          }`}
          // onClick={() => {
          //   console.log(single_global_account?.global_account?.currency);
          // }}
        >
          {/* content wrap start */}
          {step < 2 && (
            <div className="content-box-two border-theme  animate-move-up-class">
              {step < 2 && (
                <>
                  {" "}
                  {bankDetails?.map((chi, idx) => {
                    const {
                      icon,
                      text,
                      title,
                      coming_soon,
                      iconTwo,
                      link,
                      linkToUse,
                    } = chi;
                    return (
                      <div
                        key={idx}
                        className={`add-money-item ${
                          idx !== bankDetails?.length - 1 &&
                          "border-theme-bottom"
                        } ${coming_soon && "wrap-coming-soon"}`}
                      >
                        <div
                          onClick={() => {
                            if (!coming_soon) {
                              activeSelect === title
                                ? setActiveSelect("")
                                : setActiveSelect(title);

                              if (
                                title === "Receive using link" &&
                                link &&
                                Object?.keys(single_global_account)?.length > 0
                              ) {
                                navigate(link);
                              }
                            }
                          }}
                          style={{ cursor: coming_soon && "not-allowed" }}
                          className="top-box"
                        >
                          <div className="img-wrap">
                            <figure className="img-box">
                              {iconTwo ? (
                                <>{iconTwo}</>
                              ) : (
                                <img src={icon} alt="" className="img" />
                              )}
                            </figure>
                          </div>
                          <div
                            // onClick={() => {
                            //   console.log(actionCtx?.currentAccount);
                            // }}
                            className="title-text-box"
                          >
                            <p className="title">{title}</p>
                            <p className="text">{text}</p>
                          </div>
                          <div className="arrow-coming-soon-box">
                            {coming_soon ? (
                              <>
                                {" "}
                                <LockBox
                                  text="Coming soon"
                                  className="coming-soon"
                                />
                              </>
                            ) : (
                              <>
                                <figure
                                  className={`img-box-arrow ${
                                    activeSelect === title &&
                                    `img-box-arrow-rotate`
                                  }`}
                                >
                                  {rightArrowIcon}
                                </figure>
                              </>
                            )}
                          </div>
                        </div>
                        {/* content display for bank start */}
                        <div
                          className={`content-display-box ${
                            activeSelect === title &&
                            title === "Bank Transfers" &&
                            "content-display-box-show"
                          }`}
                        >
                          <div className="grey-bg display-wrap">
                            <div className="info-box">
                              <div className="bank-info grey-white-color">
                                <div className="name tooltip-hover-wrap">
                                  {trimLongString(
                                    actionCtx?.currentAccount?.account_name?.replaceAll(
                                      "-",
                                      " "
                                    ),
                                    20
                                  ) || "------"}{" "}
                                  <RavenToolTip
                                    color="black-light"
                                    position={`right`}
                                    text={
                                      actionCtx?.currentAccount?.account_name ||
                                      "------------"
                                    }
                                  />{" "}
                                </div>
                                <p>•</p>
                                <p className="bank">
                                  {actionCtx?.currentAccount?.bank ||
                                    "----------------"}
                                </p>
                              </div>
                              <p className="acc_num">
                                {actionCtx?.currentAccount?.account_number ||
                                  "---------------"}
                              </p>
                            </div>
                            {actionCtx?.currentAccount?.account_number && (
                              <div
                                onClick={() => {
                                  // const obj = {
                                  //   bank: actionCtx?.currentAccount?.bank || "",
                                  //   account_name:
                                  //     actionCtx?.currentAccount?.account_name,
                                  //   account_number:
                                  //     actionCtx?.currentAccount?.account_number || "",
                                  // };
                                  handleCopied(
                                    actionCtx?.currentAccount?.account_number
                                  );
                                }}
                                className="copy-box white-black-bg"
                              >
                                <figure className="img-box">
                                  {isCopied ? (
                                    <FaCheck className="img icon" />
                                  ) : (
                                    <svg
                                      width="10"
                                      height="11"
                                      viewBox="0 0 10 11"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="img"
                                    >
                                      <path
                                        // className="dull-bright-filter"
                                        d="M2.22222 2.5V1C2.22222 0.867391 2.28075 0.740215 2.38494 0.646446C2.48913 0.552678 2.63044 0.5 2.77778 0.5H9.44444C9.59179 0.5 9.7331 0.552678 9.83728 0.646446C9.94147 0.740215 10 0.867391 10 1V7.99999C10 8.1326 9.94147 8.25978 9.83728 8.35355C9.7331 8.44731 9.59179 8.49999 9.44444 8.49999H7.77778V9.99999C7.77778 10.276 7.52778 10.5 7.21833 10.5H0.559444C0.486182 10.5004 0.413554 10.4877 0.345735 10.4628C0.277915 10.4379 0.216241 10.4011 0.164255 10.3546C0.112269 10.3082 0.0709961 10.2529 0.0428079 10.1921C0.0146198 10.1312 7.18746e-05 10.0659 0 9.99999L0.00166668 3C0.00166668 2.724 0.251667 2.5 0.561111 2.5H2.22222ZM1.11278 3.5L1.11111 9.49999H6.66667V3.5H1.11278ZM3.33333 2.5H7.77778V7.49999H8.88889V1.5H3.33333V2.5Z"
                                        fill="#755AE2"
                                      />
                                    </svg>
                                  )}
                                </figure>{" "}
                                <span>{isCopied ? `Copied` : `Copy`}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* content display for bank end */}
                        {/* content display for default link start */}
                        <div
                          className={`content-display-box ${
                            activeSelect === title &&
                            title === "Default Link" &&
                            "content-display-box-show"
                          }`}
                        >
                          <div className="grey-bg display-wrap">
                            <div className="info-box">
                              <div className="bank-info grey-white-color">
                                <p className="bank">Accept payment with.</p>
                              </div>
                              <p className="acc_num">
                                {trimLongString(linkToUse, 30)}
                              </p>
                            </div>
                            {/* {actionCtx?.currentAccount?.account_number && ( */}
                            <div
                              onClick={() => {
                                handleCopied(linkToUse);
                              }}
                              className="copy-box white-black-bg"
                            >
                              <figure className="img-box">
                                {isCopied ? (
                                  <FaCheck className="img icon" />
                                ) : (
                                  <svg
                                    width="10"
                                    height="11"
                                    viewBox="0 0 10 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="img"
                                  >
                                    <path
                                      // className="dull-bright-filter"
                                      d="M2.22222 2.5V1C2.22222 0.867391 2.28075 0.740215 2.38494 0.646446C2.48913 0.552678 2.63044 0.5 2.77778 0.5H9.44444C9.59179 0.5 9.7331 0.552678 9.83728 0.646446C9.94147 0.740215 10 0.867391 10 1V7.99999C10 8.1326 9.94147 8.25978 9.83728 8.35355C9.7331 8.44731 9.59179 8.49999 9.44444 8.49999H7.77778V9.99999C7.77778 10.276 7.52778 10.5 7.21833 10.5H0.559444C0.486182 10.5004 0.413554 10.4877 0.345735 10.4628C0.277915 10.4379 0.216241 10.4011 0.164255 10.3546C0.112269 10.3082 0.0709961 10.2529 0.0428079 10.1921C0.0146198 10.1312 7.18746e-05 10.0659 0 9.99999L0.00166668 3C0.00166668 2.724 0.251667 2.5 0.561111 2.5H2.22222ZM1.11278 3.5L1.11111 9.49999H6.66667V3.5H1.11278ZM3.33333 2.5H7.77778V7.49999H8.88889V1.5H3.33333V2.5Z"
                                      fill="#755AE2"
                                    />
                                  </svg>
                                )}
                              </figure>{" "}
                              <span>{isCopied ? `Copied` : `Copy`}</span>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                        {/* content display for default link end */}
                        {/* content display for request link start */}
                        <div
                          className={`content-display-box ${
                            activeSelect === title &&
                            title === "Receive using link" &&
                            "content-display-box-show content-display-box-show-two"
                          }`}
                        >
                          {/* stepo one start */}
                          {step === 1 && (
                            <>
                              {" "}
                              {/* select option link box start */}
                              <div className="select-option-link-box">
                                {selectOptionLinkList?.map((chi, idx) => {
                                  return (
                                    <label
                                      key={idx}
                                      // onClick={() => {
                                      //   setOptionSelected(chi);
                                      // }}
                                      htmlFor={`${chi}-${idx}`}
                                      className="radio-label-item"
                                    >
                                      <RavenRadioCheck
                                        color="black-light"
                                        id={`${chi}-${idx}`}
                                        //   key={`${chi}-${idx}`}
                                        name="global-text"
                                        value={chi}
                                        checked={optionSelected === chi}
                                        onChange={() => {
                                          setOptionSelected(chi);
                                        }}
                                      />
                                      <p className="p grey-white-color">
                                        {chi}
                                      </p>
                                    </label>
                                  );
                                })}
                              </div>
                              {/* select option link box end */}
                              {/* form box start */}
                              <div className="form-box">
                                {/* form group start */}
                                <RavenInputField
                                  color="black-light"
                                  label="Link Title"
                                  type="text"
                                  placeholder="E.g Payment One"
                                />
                                {/* form group end */}
                                {/* form group start */}
                                <RavenInputField
                                  type={`number`}
                                  color={`black-light`}
                                  label={`Amount* `}
                                  name={`amount`}
                                  value={details?.amount}
                                  onChange={handleChange}
                                  placeholder={`How much are you receiving`}
                                  labelColor={`black-light`}
                                  labelSpanText={
                                    actionCtx?.showAmount
                                      ? `Bal: ${formatNumWithCommaNairaSymbol(
                                          actionCtx?.currentAccount?.balance
                                        )}`
                                      : "Bal: ₦ ••••••"
                                  }
                                  labelClassName={`label-span-amount label-span-theme`}
                                  thousandFormat
                                  numberPrefix={`₦`}
                                  onActionClick={() => {
                                    actionCtx?.setShowAmount(
                                      !actionCtx?.showAmount
                                    );
                                  }}
                                />
                                {/* form group end */}
                                {/* edit amount start */}
                                <div
                                  style={{ display: "flex" }}
                                  className="edit-amount-check-wrap"
                                >
                                  <RavenCheckBox
                                    color="black-light"
                                    value={details?.allow_edit_amount}
                                    onChange={(e) => {
                                      setDetails((prev) => {
                                        return {
                                          ...prev,
                                          allow_edit_amount:
                                            !details.allow_edit_amount,
                                        };
                                      });
                                    }}
                                    key={`hgh`}
                                    id={`jhbbbdv73`}
                                    checked={details?.allow_edit_amount}
                                  />
                                  <p className="text">
                                    Allow customer edit amount
                                  </p>
                                </div>
                                {/* edit amount end */}
                                {/* form group start */}
                                <RavenInputField
                                  color="black-light"
                                  label="Link Description"
                                  type="text"
                                  placeholder="Write your thoughts here... "
                                />
                                {/* form group end */}
                              </div>
                              {/* form box end */}
                            </>
                          )}
                          {/* step one end  */}
                        </div>
                        {/* content display for request link end */}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          )}
          {/* content wrap end */}
          {step === 2 && (
            <>
              <NewPreviewDetailBox />
            </>
          )}
        </div>
      </ContainerWrapper>
      {/* container wrapp end */}
    </DashboardLayout>
  );
};

export default ReceiveGlobalAccount;
