import AddressVerification from "../../../pages/dashboard/verification/AddressVerification";
import BusinessVerification from "../../../pages/dashboard/verification/BusinessVerification";
import BvnVerification from "../../../pages/dashboard/verification/BvnVerification";
import CacVerification from "../../../pages/dashboard/verification/CacVerification";
import IdVerification from "../../../pages/dashboard/verification/IdVerification";
import NinVerification from "../../../pages/dashboard/verification/NinVerification";
import VerificationIndexOneFive from "../../../pages/dashboard/verificationOneFive/VerificationIndexOneFive";
import AdressVerificationFive from "../../../pages/dashboard/verificationOneFive/tier-one-address/adress/AdressVerificationFive";
// import AdressVerificationFive from "../../../pages/dashboard/verificationOneFive/tier-one/adress/AdressVerificationFive";
import BvnContentVerificationOneFive from "../../../pages/dashboard/verificationOneFive/tier-one/bvn/BvnContent";
import NinContentVerificationOneFive from "../../../pages/dashboard/verificationOneFive/tier-one/nin/NinContent";
import InviteRefereeFive from "../../../pages/dashboard/verificationOneFive/tier-three/invite-referee/InviteRefereeFive";
import ShareHolderVerifyFive from "../../../pages/dashboard/verificationOneFive/tier-three/share-holder/ShareHolderVerifyFive";
import CacVerifcationFive from "../../../pages/dashboard/verificationOneFive/tier-two/cac-verification/CacVerifcationFive";
import DirectorsVerificationFive from "../../../pages/dashboard/verificationOneFive/tier-two/directors/DirectorsVerificationFive";
import SignIdenmityFive from "../../../pages/dashboard/verificationOneFive/tier-two/sign-idenmity/SignIdenmityFive";
// import NinVerification from "../../../pages/dashboard/verification/NinVerification";



export const verification_routes_group = [
    {path: "/dashboard-verification-bvn", element: <VerificationIndexOneFive />},
    {path: "/dashboard-verification-id", element: <VerificationIndexOneFive />},
    {path: "/dashboard-verification-nin", element: <VerificationIndexOneFive />},
    {path: "/dashboard-verification-business", element: <VerificationIndexOneFive />},
    {path: "/dashboard-verification-cac", element: <VerificationIndexOneFive />},
    {path: "/dashboard-verification-address", element: <VerificationIndexOneFive />},
    {path: "/dashboard-verification", element: <VerificationIndexOneFive />},
    {path: "/dashboard-verification-bvn-new", element: <BvnContentVerificationOneFive />},
    {path: "/dashboard-verification-nin-new", element: <NinContentVerificationOneFive />},
    {path: "/dashboard-verification-cac-new", element: <CacVerifcationFive />},
    {path: "/dashboard-verification-address-new", element: <AdressVerificationFive />},
    {path: "/dashboard-verification-directors-new", element: <DirectorsVerificationFive />},
    {path: "/dashboard-verification-sign-idenmity-new", element: <SignIdenmityFive />},
    {path: "/dashboard-verification-invite-referee-new", element: <InviteRefereeFive />},
    {path: "/dashboard-verification-shareholder-new", element: <ShareHolderVerifyFive />},
]

// DirectorsVerificationFive