import React, { useContext, useEffect, useState } from "react";
import DashboardSettingsLayout from "./SettingsLayout";
import NoContentBox from "../../../../components/reusables/NoContentBox";
import { useDispatch } from "react-redux";
import { getBeneficiaries } from "../../../../redux/settings";
import deleteIcon from "../../../../assets/delete-icon.svg";
import bankIcon from "../../../../assets/bank-general-img.svg";
import { useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  convertToLowercase,
  formatMetaData,
  trimLongString,
} from "../../../../utils/helper/Helper";
import CheckUrlFragment from "../../../../components/fragments/CheckUrlCodeFragment";
import DeleteBeneficiaryModal from "../modal/DeleteBeneficiaryModal";
import EditBeneficiaryLocalModal from "../modal/EditBeneficiaryLocalModal";
import Skeleton from "react-loading-skeleton";
import {
  getAllBeneficiaries,
  getAllCurrencies,
  getCurrencyInput,
} from "../../../../redux/globalAccount";
import DeleteBeneficiaryGlobalModal from "../modal/DeleteBeneficiaryGlobalModal";
import ActionContext from "../../../../context/ActionContext";
import EditRecipientGlobalWrapIndex from "../../create-recipient-global/EditRecipientGlobalAccount";
import { useLocation } from "react-router-dom";
// import DeleteDeviceModal from "../modal/DeleteDeviceModal";

const BeneficiarySettings = () => {
  const location = useLocation();
  const actionCtx = useContext(ActionContext);
  const selectBoxList = ["Local Account", "Global Account"];
  const [selectectedBox, setSelectedBox] = useState("Local Account");
  const dispatch = useDispatch();
  const { all_beneficiaries, loadingBene, all_currencies } = useSelector(
    (state) => state.global_account
  );
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const { beneficiaries, loading } = useSelector((state) => state?.settings);
  const [singleChild, setSingleChild] = useState({});
  const [showModal, setShowModal] = useState({
    delete: false,
    edit: false,
    delete_global: false,
  });

  useEffect(() => {
    if (Object?.keys(beneficiaries)?.length < 1) {
      dispatch(getBeneficiaries());
    }

    if (Object?.keys(all_beneficiaries)?.length < 1) {
      //   const obj = {
      //     currency: "USD",
      //   };
      dispatch(getAllBeneficiaries());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editIcon = (
    <svg
      className="img"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M14.1391 1.79773L17.1058 4.7644M2.27246 16.6311L3.21913 13.16C3.28089 12.9335 3.31177 12.8203 3.35917 12.7147C3.40127 12.6209 3.45299 12.5318 3.5135 12.4487C3.58166 12.3552 3.66465 12.2722 3.83063 12.1062L11.4946 4.44228C11.6414 4.29542 11.7149 4.222 11.7995 4.19448C11.874 4.17028 11.9542 4.17028 12.0287 4.19448C12.1134 4.222 12.1868 4.29542 12.3337 4.44228L14.4612 6.56985C14.6081 6.7167 14.6815 6.79013 14.709 6.8748C14.7332 6.94928 14.7332 7.02951 14.709 7.10399C14.6815 7.18866 14.6081 7.26209 14.4612 7.40895L6.7973 15.0729C6.63132 15.2389 6.54833 15.3219 6.45478 15.39C6.37172 15.4505 6.2826 15.5023 6.18884 15.5444C6.08325 15.5918 5.97002 15.6226 5.74356 15.6844L2.27246 16.6311Z"
        stroke="#333333"
        strokeWidth="1.335"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const [activeSelect, setActiveSelect] = useState("Individual");
  const selectOptionList = ["Individual", "Business"];
  const [inputObjList, setInputObjList] = useState([]);
  const [previousObj, setPreviousObj] = useState({});
  const getCurrencyInputFunc = async (param, chi) => {
    if (param) {
      const data = await dispatch(getCurrencyInput({ currency: param }));
      // console.log(data);
      if (data?.payload?.status === "success") {
        const inputOptions = data?.payload?.data?.currency_inputs?.data;
        const inputOptionTwo = inputOptions?.map((chi) => {
          const obj = {
            ...chi,
            value: chi?.value || "",
            input_type:
              chi?.input_type === "digit"
                ? "number"
                : chi?.input_type === "selector"
                ? "select"
                : chi?.input_type,
            options:
              chi?.options?.length > 0
                ? chi?.options?.map((chid) => {
                    return { ...chid, label: chid?.key || chid?.value };
                  })
                : "",
          };
          return obj;
        });
        const updatedArray = inputOptionTwo?.map((obj) => {
          // Check if the key exists in the columnValues object
          if (chi?.hasOwnProperty(obj?.key)) {
            // Create a new object with the updated value
            return {
              ...obj,
              value:
                obj?.options?.length > 0
                  ? { label: chi[obj.key], value: chi[obj.key] }
                  : chi[obj.key],
            };
          }
          return { ...obj, value: "" };
        });

        setInputObjList(updatedArray);
        getInputCountriesFromAllCurrencies(param, updatedArray);
      }
    }
  };

  const handleChangeInputValue = (e) => {
    const { name, value, type } = e.target;
    if (type && type === "select") {
      // console.log(e);
      const newList = inputObjList?.map((chi) =>
        chi?.key === name ? { ...chi, value: value } : chi
      );
      setInputObjList(newList);
    } else {
      const newList = inputObjList?.map((chi) =>
        chi?.key === name ? { ...chi, value: value } : chi
      );
      setInputObjList(newList);
    }
  };

  useEffect(() => {
    if (Object?.keys(all_currencies)?.length < 1) {
      dispatch(getAllCurrencies());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getInputCountriesFromAllCurrencies = (param, list) => {
    const obj = all_currencies?.all_support_currencies?.find(
      (chi) => chi?.currency === param
    );
    const countryOptions = obj?.countries?.map((chi) => {
      return { ...chi, label: chi?.country, value: chi?.country };
    });
    // console.log(countryOptions);
    changeCountryTypeToSelect(countryOptions, list);
  };

  const changeCountryTypeToSelect = (options, list) => {
    // console.log(value);
    const inputOptionTwo = list?.map((chi) =>
      chi?.key === "beneficiary_country"
        ? {
            ...chi,
            value: {label: chi?.value, value: chi?.value} || "",
            input_type: "select",
            options: options,
          }
        : chi
    );
    // console.log(inputOptionTwo);
    setInputObjList(inputOptionTwo)
  };

  return (
    <>
      <DashboardSettingsLayout>
        <div
          //   onClick={() => {
          //     console.log(all_beneficiaries);
          //   }}
          className="detail-wrap animate-move-up-class"
          id="Teams"
        >
          <div className="text-btn-box">
            {" "}
            <div
              //   onClick={() => {
              //     console.log(beneficiaries);
              //   }}
              className="text-wrap"
            >
              <p className="title">Beneficiary Management</p>
              <p className="sub-title grey-white-color">
                Manage your beneficiary list
              </p>
            </div>
            {/* select here start */}
            {Object.keys(all_beneficiaries)?.length > 0 ? (
              <div className="select-box-list-wrap grey-bg">
                {selectBoxList?.map((chi, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`item-box ${
                        selectectedBox === chi &&
                        "white-black-bg card-bg item-box-active"
                      }`}
                      onClick={() => {
                        if (selectectedBox !== chi) {
                          setSelectedBox(chi);
                        }
                      }}
                    >
                      <p
                        className={`item ${
                          selectectedBox !== chi && "grey-white-color"
                        }`}
                      >
                        {chi}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {/* select here end */}
          </div>

          <div
            onClick={() => {
              // rerrangeListFunc(teamMemberList);
            }}
            style={{ minHeight: "45rem" }}
            className="box-wrap box-wrap-two grey-bg"
          >
            {/* noti wrap start */}
            {/* local beneficiary start */}
            {selectectedBox === "Local Account" && (
              <>
                {beneficiaries?.bankAccounts?.length > 0 ? (
                  <>
                    {beneficiaries?.bankAccounts?.map((chi, idx) => {
                      const {
                        account_name,
                        account_number,
                        bank,
                        bank_code,
                        // valid,
                      } = chi;
                      return (
                        <div key={idx} className="noti-wrap">
                          {loading ? (
                            <Skeleton width={45} height={45} circle />
                          ) : (
                            <div className="avatar-box">
                              <CheckUrlFragment
                                url={`https://businessapi.getraventest.com/static/media/banks/${bank_code}.png`}
                              >
                                <figure className="img-box">
                                  <img src={bankIcon} alt="" className="img" />
                                </figure>
                                {/* <span style={{ textTransform: "capitalize" }}>
                                {account_name &&
                                  account_name?.split(" ")[0]?.charAt(0)}
                                {account_name &&
                                  account_name?.split(" ")[1]?.charAt(0)}
                              </span> */}
                              </CheckUrlFragment>
                              {/* <figure className="img-box">
                              <img
                                src={
                                  `https://businessapi.getraventest.com/static/media/banks/${bank_code}.png` ||
                                  bankIcon
                                }
                                alt=""
                                className="img"
                              />
                            </figure> */}
                              {/* {valid ? (
                                <figure className="img-box">
                                  <img
                                    src={
                                      `https://businessapi.getraventest.com/static/media/banks/${bank_code}.png` ||
                                      bankIcon
                                    }
                                    alt=""
                                    className="img"
                                  />
                                </figure>
                              ) : (
                                <>
                                  {" "}
                                  <span style={{ textTransform: "capitalize" }}>
                                    {account_name &&
                                      account_name?.split(" ")[0]?.charAt(0)}
                                    {account_name &&
                                      account_name?.split(" ")[1]?.charAt(0)}
                                  </span>
                                </>
                              )} */}
                            </div>
                          )}
                          <div className="text-box">
                            {loading ? (
                              <Skeleton width={100} height={20} />
                            ) : (
                              <p
                                style={{ textTransform: "capitalize" }}
                                className="value"
                              >
                                {trimLongString(account_name, 30) || ""}
                              </p>
                            )}
                            {loading ? (
                              <Skeleton width={200} height={10} />
                            ) : (
                              <p className="label grey-white-color">
                                {`${bank || ""} • ${account_number || ""}`}
                              </p>
                            )}
                          </div>
                          {/* select input start */}
                          {loading ? (
                            <Skeleton
                              width={42}
                              height={42}
                              style={{ borderRadius: "1rem" }}
                            />
                          ) : (
                            <div
                              onClick={() => {
                                setSingleChild(chi);
                                setShowModal((prev) => {
                                  return { ...prev, edit: true };
                                });
                              }}
                              className="delete-box white-black-bg"
                            >
                              <figure className="img-box">{editIcon}</figure>
                            </div>
                          )}
                          {/* select input end */}
                          {/* delete box start */}
                          {loading ? (
                            <Skeleton
                              width={42}
                              height={42}
                              style={{
                                borderRadius: "1rem",
                                marginLeft: "1.5rem",
                              }}
                            />
                          ) : (
                            <div
                              onClick={() => {
                                setSingleChild(chi);
                                setShowModal((prev) => {
                                  return { ...prev, delete: true };
                                });
                              }}
                              className="delete-box white-black-bg"
                            >
                              <figure className="img-box">
                                <img src={deleteIcon} alt="" className="img" />
                              </figure>
                            </div>
                          )}
                          {/* delete box end */}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      // className="box-wrap grey-bg"
                      style={{
                        display: "grid",
                        placeItems: "center",
                        minHeight: "45rem",
                      }}
                    >
                      {" "}
                      <NoContentBox
                        loading={loading}
                        title={`No Beneficiary`}
                        text="All beneficiary added will appear here when saved"
                      />
                    </div>
                  </>
                )}
              </>
            )}

            {/* local beneficiary  end */}
            {/* local beneficiary start */}
            {selectectedBox === "Global Account" && (
              <>
                {all_beneficiaries?.beneficiaries?.length > 0 ? (
                  <>
                    {" "}
                    {all_beneficiaries?.beneficiaries?.map((chi, idx) => {
                      const {
                        // account_name,
                        account_number,
                        bank_name,
                        // bank_code,
                        // valid,
                        currency,
                        beneficiary_name,
                      } = chi;

                      return (
                        <div key={idx} className="noti-wrap">
                          {loading ? (
                            <Skeleton width={45} height={45} circle />
                          ) : (
                            <div className="avatar-box">
                              <CheckUrlFragment
                                url={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                                  currency
                                )}.png`}
                              >
                                <span style={{ textTransform: "capitalize" }}>
                                  {beneficiary_name &&
                                    beneficiary_name?.split(" ")[0]?.charAt(0)}
                                  {beneficiary_name &&
                                    beneficiary_name?.split(" ")[1]?.charAt(0)}
                                </span>
                              </CheckUrlFragment>
                            </div>
                          )}
                          <div className="text-box">
                            {loading ? (
                              <Skeleton width={100} height={20} />
                            ) : (
                              <p
                                style={{ textTransform: "capitalize" }}
                                className="value"
                              >
                                {trimLongString(beneficiary_name, 30) || ""}
                              </p>
                            )}
                            {loading ? (
                              <Skeleton width={200} height={10} />
                            ) : (
                              <p className="label grey-white-color">
                                {`${bank_name || ""} • ${account_number || ""}`}
                              </p>
                            )}
                          </div>
                          {/* select input start */}
                          {loading ? (
                            <Skeleton
                              width={42}
                              height={42}
                              style={{ borderRadius: "1rem" }}
                            />
                          ) : (
                            <div
                              onClick={() => {
                                setSingleChild(chi);
                                setActiveSelect(
                                  capitalizeFirstLetter(
                                    chi?.beneficiary_account_type
                                  )
                                );
                                setPreviousObj(formatMetaData(chi?.payload));
                                getCurrencyInputFunc(
                                  chi?.currency,
                                  formatMetaData(chi?.payload)
                                );
                                actionCtx?.createRecipientGlobalTypeFunc(
                                  "bank"
                                );
                                actionCtx?.editRecipientGlobalFunc(true);
                              }}
                              className="delete-box white-black-bg"
                            >
                              <figure className="img-box">{editIcon}</figure>
                            </div>
                          )}
                          {/* select input end */}
                          {/* delete box start */}
                          {user_resource_and_permissions?.allowed_permissions?.includes(
                              "can_delete_beneficiary"
                            ) && <> {loading ? (
                            <Skeleton
                              width={42}
                              height={42}
                              style={{
                                borderRadius: "1rem",
                                marginLeft: "1.5rem",
                              }}
                            />
                          ) : (
                            <div
                              onClick={() => {
                                setSingleChild(chi);
                                setShowModal((prev) => {
                                  return { ...prev, delete_global: true };
                                });
                              }}
                              className="delete-box white-black-bg"
                            >
                              <figure className="img-box">
                                <img src={deleteIcon} alt="" className="img" />
                              </figure>
                            </div>
                          )}</>}
                         
                          {/* delete box end */}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      // className="box-wrap grey-bg"
                      style={{
                        display: "grid",
                        placeItems: "center",
                        minHeight: "45rem",
                      }}
                    >
                      {" "}
                      <NoContentBox
                        loading={loadingBene}
                        title={`No Beneficiary`}
                        text="All beneficiary added will appear here when saved"
                      />
                    </div>
                  </>
                )}
              </>
            )}

            {/* local beneficiary  end */}
            {/* noti wrap end */}
          </div>
        </div>
      </DashboardSettingsLayout>
      {/* delete device modal start */}
      <DeleteBeneficiaryModal
        visible={showModal?.delete}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, delete: false };
          });
        }}
        detail={singleChild}
      />
      {/* delete device modal end */}
      {/* delete device modal start */}
      <DeleteBeneficiaryGlobalModal
        visible={showModal?.delete_global}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, delete_global: false };
          });
        }}
        detail={singleChild}
      />
      {/* delete device modal end */}
      {/* modal for edit staart */}
      <EditBeneficiaryLocalModal
        visible={showModal?.edit}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, edit: false };
          });
        }}
        detail={singleChild}
        // setDetails={() => {}}
      />
      {/* modal for edit end */}
      {actionCtx?.editRecipientGlobal &&
        location?.pathname === "/dashboard-settings-beneficiary" && (
          <EditRecipientGlobalWrapIndex
            previousObj={previousObj}
            selectOptionList={selectOptionList}
            activeSelect={activeSelect}
            setActiveSelect={setActiveSelect}
            setOptionList={(e) => {
              handleChangeInputValue(e);
            }}
            inputObjList={inputObjList}
            //   detail={details}
            handleFinishFunc={(param) => {
              actionCtx?.editRecipientGlobalFunc(false);
              setPreviousObj({});
            }}
            singleChild={singleChild}
          />
        )}
    </>
  );
};

export default BeneficiarySettings;
