import React, { useContext } from "react";
import "../../styles/reusables/AccountDropDownBox.css";

// import nairaIcon from "../../assets/nairaIcon.svg";
// import usdIcon from "../../assets/usdIcon.svg";
// // import kshIcon from "../../assets/kshIcon.svg";
// import poundsIcon from "../../assets/poundsIcon.svg";
// // import cedicsIcon from "../../assets/cedicsIcon.svg";
// import randsIcon from "../../assets/randsIcon.svg";
import {
  formAllAccountList,
  //   formatNumWithCommaNaira,
  formatNumWithCommaNairaSymbol,
  SPECIAL_ROLE_NAME,
  trimLongString,
} from "../../utils/helper/Helper";
import ActionContext from "../../context/ActionContext";
import { useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";

const AccountDropDownBox = ({
  big,
  onSelect,
  typeTwo,
  defaultVal,
  dontShowChange,
}) => {
  const actionCtx = useContext(ActionContext);
  const { all_account, user } = useSelector((state) => state.user);

  if (typeTwo) {
    return (
      <div
        className={`account-drop-down-box-toggle-wrap ${!big && "card-bg"} ${
          !big && "no-shadow-box-shadow"
        } ${!big && "account-drop-down-box-toggle-wrap-big"}`}
        style={{
          width: big ? "100%" : "max-content",
          animation: big && "unset",
        }}
      >
        {formAllAccountList(all_account)?.map((chi, idx) => {
          const { custom_account_name, balance, primary } = chi;
          return (
            <div
              key={idx}
              className={`account-select ${
                all_account?.length - 1 !== idx && "border-theme-bottom"
              } ${
                defaultVal?.custom_account_name === custom_account_name &&
                "grey-bg active-sub-account"
              }`}
              onClick={() => {
                onSelect && onSelect(chi);
              }}
            >
              {/* label value box start */}
              <div className="label-value-box" style={{ marginRight: "auto" }}>
                <p className="label">{custom_account_name || "---"}</p>
                <p className="value grey-white-color-white">
                  {user?.clearance === SPECIAL_ROLE_NAME
                    ? `${formatNumWithCommaNairaSymbol(balance)}`
                    : `${chi?.account_number} • ${trimLongString(
                        chi?.bank,
                        30
                      )}`}
                </p>
              </div>
              {/* label value box end */}
              {/* primary box start */}
              {primary === 1 && big && (
                <>
                  <div className="primary-box grey-bg-two">
                    <p className="text">Primary</p>
                  </div>
                </>
              )}
              {/* primary box end */}
              {/* chaeck active start */}
              {defaultVal?.custom_account_name === custom_account_name && (
                <>
                  <div className="check-box shadow-shadow-box-shadow ">
                    <FaCheck className="icon" />
                  </div>
                </>
              )}
              {/* chaeck active end */}
            </div>
          );
        })}
      </div>
    );
  }

  // if(newType){
  //   return (

  //   )
  // }

  return (
    <div
      className={`account-drop-down-box-toggle-wrap ${!big && "card-bg"} ${
        !big && "no-shadow-box-shadow"
      } ${!big && "account-drop-down-box-toggle-wrap-big"}`}
      style={{ width: big ? "100%" : "max-content", animation: big && "unset" }}
      // onClick={() => {
      //   console.log(all_account);
      // }}
    >
      {formAllAccountList(all_account)?.map((chi, idx) => {
        const { custom_account_name, balance, primary } = chi || {};
        return (
          <div
            key={idx}
            className={`account-select ${
              all_account?.length - 1 !== idx && "border-theme-bottom"
            } ${
              actionCtx?.currentAccount?.custom_account_name ===
                custom_account_name && "grey-bg active-sub-account"
            }`}
            onClick={() => {
              onSelect && onSelect(chi);
            }}
          >
            {/* label value box start */}
            <div className="label-value-box" style={{ marginRight: "auto" }}>
              <p className="label">{custom_account_name || "---"}</p>
              <p className="value grey-white-color-white">
                {user?.clearance === SPECIAL_ROLE_NAME
                  ? `${formatNumWithCommaNairaSymbol(balance)}`
                  : `${chi?.account_number} • ${trimLongString(chi?.bank, 30)}`}
              </p>
            </div>
            {/* label value box end */}
            {/* primary box start */}
            {primary === 1 && big && (
              <>
                <div className="primary-box grey-bg-two">
                  <p className="text">Primary</p>
                </div>
              </>
            )}
            {/* primary box end */}
            {/* chaeck active start */}
            {actionCtx?.currentAccount?.custom_account_name ===
              custom_account_name && (
              <>
                <div className="check-box shadow-shadow-box-shadow ">
                  <FaCheck className="icon" />
                </div>
              </>
            )}
            {/* chaeck active end */}
          </div>
        );
      })}
    </div>
  );
};

export default AccountDropDownBox;
