import DashboardTransaction from "../../../pages/dashboard/transaction";
import AllBulkTransaction from "../../../pages/dashboard/transaction/AllBulkTransaction";
import NoTransactionPage from "../../../pages/dashboard/transaction/NoTransactionPage";
import SingleBulkTransaction from "../../../pages/dashboard/transaction/SingleBulkTransaction";


export const dashboard_transaction_route_group = [
    {path: "/dashboard-transaction", element: <DashboardTransaction />},
    {path: "/dashboard-transaction-all-bulk", element: <AllBulkTransaction />},
    {path: "/dashboard-transaction-single-bulk", element: <SingleBulkTransaction />},
    {path: "/dashboard-transaction_", element: <NoTransactionPage />},
]