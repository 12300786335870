import React, { useState } from "react";
import DashboardSettingsLayout from "./SettingsLayout";
import generateIcon from "../../../../assets/generate-settings-icon.svg";
import GenerateStatementModal from "../sections/modal/GenerateStatementModal";
import ElectricityGenerateModal from "../sections/modal/ElectricityGenerateModal";

const DashboardSettingsStatement = () => {
  const [showModal, setShowModal] = useState({
    team_invite: false,
    team_delete: false,
    device_delete: false,
    statement: false,
    electricity: false,
    view_directors: false,
    delete_sub_account: false,
    change_primary: false,
    remove_role_account: false,
    add_role_account: false,
  });
  return (
    <>
      <DashboardSettingsLayout>
        <div className="detail-wrap animate-move-up-class">
          <p className="title">Statements</p>
          <p className="sub-title grey-white-color">
            Manage all your notifications in one place.
          </p>
          <div className="box-wrap grey-bg">
            <div className="noti-wrap">
              <figure className="img-box">
                <img src={generateIcon} alt="" className="img" />
              </figure>
              <div className="text-box">
                <p className="value">Bank Statement</p>
                <p className="label grey-white-color">
                  Generate bank statement
                </p>
              </div>
              <div
                onClick={() => {
                  setShowModal((prev) => {
                    return { ...prev, statement: true };
                  });
                }}
                className="add-error-box"
              >
                <span>Generate</span>
              </div>
              {/* <RavenToggleSlide id="jgjk" color="black-light" /> */}
            </div>
            <div className="noti-wrap">
              <figure className="img-box">
                <img src={generateIcon} alt="" className="img" />
              </figure>
              <div className="text-box">
                <p className="value">Electricity Bill Statement</p>
                <p className="label grey-white-color">
                  Generate electricity statement
                </p>
              </div>
              <div
                onClick={() => {
                  setShowModal((prev) => {
                    return { ...prev, electricity: true };
                  });
                }}
                className="add-error-box"
              >
                <span>Generate</span>
              </div>
              {/* <RavenToggleSlide id="jgjk" color="black-light" /> */}
            </div>
          </div>
        </div>
      </DashboardSettingsLayout>
      {/* genrate start */}
      <GenerateStatementModal
        visible={showModal?.statement}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, statement: false };
          });
        }}
      />
      {/* genrate end */}
      {/* genrate start */}
      <ElectricityGenerateModal
        visible={showModal?.electricity}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, electricity: false };
          });
        }}
      />
      {/* genrate end */}
    </>
  );
};

export default DashboardSettingsStatement;
