import {
  // RavenInputField,
  RavenToggleSlide,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import "../../../../styles/fragments/PreviewDetailsCommonBox.css";
import infoIcon from "../../../../assets/info-icon-black.svg";
import { formatNumWithoutCommaNaira } from "../../../../utils/helper/Helper";

const PreviewElectricityDetailsBox = ({
  collapse,
  amount,
  provider,
  number,
  narration,
  onChange,
  value,
  id,
  checked,
  tipposition,
  meter_type,
  phone,
  previewOpen,
  setPreviewOpen,
  name,
  email,
}) => {
  // const [showDetail, setShowDetail] = useState(false);
  return (
    <div
      className={`preview-details-common-box grey-bg ${
        collapse && "preview-details-common-box-collapse"
      } ${previewOpen === number && "preview-details-common-box-show"}`}
    >
      {/* top box start */}
      <div
        onClick={() => {
          if (collapse) {
            previewOpen === number
              ? setPreviewOpen("")
              : setPreviewOpen(number);
          }
        }}
        className="top-box"
      >
        <div className="text-box">
          <p className="key grey-white-color">Electricity recharge of</p>
          <p className="value">₦{amount || "987678"}</p>
        </div>
        {collapse && (
          <div className="icon-box">
            {previewOpen === number ? (
              <FaAngleDown className="icon" />
            ) : (
              <FaAngleRight className="icon" />
            )}
          </div>
        )}
      </div>
      {/* top box end */}
      {/* middle-content start */}
      <div className="middle-box">
        <p className="text">
          <span className="grey-white-color">To:</span>{" "}
          <span>{`${name} `}</span>
        </p>
        <p className="text">
          <span className="grey-white-color">Meter No:</span>{" "}
          <span>{`${number} `}</span>
        </p>
        <p className="text">
          <span className="grey-white-color">Phone No:</span>{" "}
          <span>
            {formatNumWithoutCommaNaira(
              phone.slice(0, 3) === "234" ? phone.replace("234", "0") : phone
            ) || ""}
          </span>
        </p>
        <p className="text">
          <span className="grey-white-color">Email:</span> <span>{email}</span>
        </p>
        <div className="text pro-text-img">
          <span className="grey-white-color ">Type:</span>{" "}
          <span>{provider || meter_type}</span>
        </div>
        {/* {linkDuration && <p className="text">
          <span className="grey-white-color">Link duration:</span>{" "}
          <span>{linkDuration || "09/12/2023"}</span>
        </p>} */}
        <p className="text">
          <span className="grey-white-color">Provider:</span>{" "}
          <span>{narration || ""}</span>
        </p>
      </div>
      {/* middle content end */}
      {collapse && previewOpen !== number && (
        <div className={`collapse-box`}>
          <p className="text">
            <span className="grey-white-color">To:</span>{" "}
            <span>{name || ""}</span>
          </p>
        </div>
      )}
      {/* bottom box start */}
      <div className="bottom-box">
        <div className="text grey-white-color">Add as beneficaries</div>{" "}
        <figure className="img-box img-box-info tooltip-hover-wrap">
          <RavenToolTip
            color={`black-light`}
            position={tipposition}
            text={`If you have a saved meter number already, once you toggle the save meter it would be replaced with the new meter number added.`}
            title={`Save Meter Number`}
            downText={`Got it!`}
            big
          />
          <img src={infoIcon} alt="" className="img" />
        </figure>
        <RavenToggleSlide
          onChange={onChange}
          id={id}
          value={value}
          color={`black-light`}
          checked={checked}
          //   className={`toggle-box-wrap`}
        />
      </div>
      {/* bottom box end */}
    </div>
  );
};

export default PreviewElectricityDetailsBox;
