/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import { toast } from "@ravenpay/raven-bank-ui";

export const createGlobalAccount = createAsyncThunk(
  "create/globalAccount",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/setup_global_account", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const createRecipientGlobalAccount = createAsyncThunk(
  "create/globalAccount-recipient",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/save_foreign_beneficiary", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const editRecipientGlobalAccount = createAsyncThunk(
  "create/globalAccount-recipient-edit",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/update_foreign_beneficiary", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const swapGlobalAccount = createAsyncThunk(
  "create/globalAccount-swap",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/swap_currencies", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const sendSameCurGlobalAccount = createAsyncThunk(
  "create/globalAccount-swap-same-cur",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/initiate_global_transfer", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const sendDifCurGlobalAccount = createAsyncThunk(
  "create/globalAccount-swap-dif-cur",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post(
        "/swap_currencies_and_execute_transfer",
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAllCurrencies = createAsyncThunk(
  "get/currencies",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(`/available_currencies`);
      //   console.log(data, "------------------------------");
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data);
        await thunkAPI.dispatch(setAllCurrencies(data?.data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAllGlobalVerificationStatus = createAsyncThunk(
  "get/verification-status",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(`/get_verification_status`);
      //   console.log(data, "------------------------------");
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        await thunkAPI.dispatch(
          setAllGlobalVerification(data?.data?.verification_status)
        );
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAllBeneficiaries = createAsyncThunk(
  "get/beneficiaries",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `fetch_foreign_beneficiaries?currency=${payload?.currency || ""}`
      );
      // console.log(data, "------------------------------");
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data);
        await thunkAPI.dispatch(setAllBeneficiaries(data?.data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getCurrencyInput = createAsyncThunk(
  "get/currency-input",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/get_currency_inputs?currency=${payload?.currency || ""}`
      );
      //   console.log(data, "------------------------------");
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data);
        //   await thunkAPI.dispatch(setAllCurrencies(data?.data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAllGlobalAccount = createAsyncThunk(
  "get/all-global-account",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/available_global_accounts_for_a_business${
          payload?.cur ? `?choosen_currency=${payload?.cur}` : ""
        }`
        // `/available_global_accounts_for_a_business?choosen_currency=${payload?.cur || ""}`
      );
      //   console.log(data, "------------------------------");
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data.data);
        await thunkAPI.dispatch(setAllGlobalAccount(data?.data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSingleGlobalAccount = createAsyncThunk(
  "get/single-global-account",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/single_global_account?account_id=${payload?.id || ""}&per_page=${payload?.per_page || 10}&current_page=${
          payload?.current_page || 1
        }`
      );
      //   console.log(data, "------------------------------");
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data.data);
        await thunkAPI.dispatch(setSingleGlobalAccount(data?.data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSingleGlobalAccountTransaction = createAsyncThunk(
  "get/single-global-account-transaction",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/fetch_global_account_transactions?currency=${payload?.currency || ""}`
      );
      //   console.log(data, "------------------------------");
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // console.log(data.data);
        await thunkAPI.dispatch(setSingleGlobalAccountTrans(data?.data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const global_account = createSlice({
  name: "global_account",
  initialState: {
    all_currencies: {},
    all_beneficiaries: {},
    all_global_account: {},
    single_global_account: {},
    single_global_account_trans: {},
    global_verification: {},
    isAuth: false,
    loading: false,
    loadingBene: false,
    loadingInput: false,
    fetachingUpdate: false,
    loadingSingle: false,
    loadingTrans: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setAllCurrencies: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.all_currencies = action.payload;
    },
    setAllGlobalVerification: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.global_verification = action.payload;
    },
    setAllBeneficiaries: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.all_beneficiaries = action.payload;
    },
    setAllGlobalAccount: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.all_global_account = action.payload;
    },
    setSingleGlobalAccount: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.single_global_account = action.payload;
    },
    setSingleGlobalAccountTrans: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.single_global_account_trans = action.payload;
    },
  },
  extraReducers: {
    [getAllGlobalAccount.pending]: (state) => {
      state.loading = true;
    },
    [getAllGlobalAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [getAllGlobalAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getCurrencyInput.pending]: (state) => {
      state.loadingInput = true;
    },
    [getCurrencyInput.fulfilled]: (state) => {
      state.loadingInput = false;
    },
    [getCurrencyInput.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingInput = false;
      state.isAuth = false;
      state = null;
    },
    [sendDifCurGlobalAccount.pending]: (state) => {
      state.loading = true;
    },
    [sendDifCurGlobalAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [sendDifCurGlobalAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [sendSameCurGlobalAccount.pending]: (state) => {
      state.loading = true;
    },
    [sendSameCurGlobalAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [sendSameCurGlobalAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [swapGlobalAccount.pending]: (state) => {
      state.loading = true;
    },
    [swapGlobalAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [swapGlobalAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getAllBeneficiaries.pending]: (state) => {
      state.loadingBene = true;
    },
    [getAllBeneficiaries.fulfilled]: (state) => {
      state.loadingBene = false;
    },
    [getAllBeneficiaries.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingBene = false;
      state.isAuth = false;
      state = null;
    },
    [getSingleGlobalAccount.pending]: (state) => {
      state.loading = true;
    },
    [getSingleGlobalAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSingleGlobalAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSingleGlobalAccountTransaction.pending]: (state) => {
      state.loadingTrans = true;
    },
    [getSingleGlobalAccountTransaction.fulfilled]: (state) => {
      state.loadingTrans = false;
    },
    [getSingleGlobalAccountTransaction.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingTrans = false;
      state.isAuth = false;
      state = null;
    },
    [createGlobalAccount.pending]: (state) => {
      state.loading = true;
    },
    [createGlobalAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [createGlobalAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [createRecipientGlobalAccount.pending]: (state) => {
      state.loadingTrans = true;
    },
    [createRecipientGlobalAccount.fulfilled]: (state) => {
      state.loadingTrans = false;
    },
    [createRecipientGlobalAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingTrans = false;
      state.isAuth = false;
      state = null;
    },
    [editRecipientGlobalAccount.pending]: (state) => {
      state.loadingTrans = true;
    },
    [editRecipientGlobalAccount.fulfilled]: (state) => {
      state.loadingTrans = false;
    },
    [editRecipientGlobalAccount.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingTrans = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAllCurrencies,
  setAllGlobalAccount,
  setSingleGlobalAccount,
  setSingleGlobalAccountTrans,
  setAllBeneficiaries,
  setAllGlobalVerification,
} = global_account.actions;

export default global_account.reducer;
