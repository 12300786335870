import { RavenInputField, RavenModal, toast } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeamMembers,
  // getTeamRoles,
  inviteTeamMember,
} from "../../../redux/settings";
// import { validateUniqueEmail } from "../../../../redux/user";
import "../../../styles/dashboard/pos/DashboardPosIndex.css";
import {
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  lowerCaseWrap,
  reactSelectStyle,
  // sumAllNum,
} from "../../../utils/helper/Helper";
import { validateEmail } from "../../../utils/helper/ValidateInput";
import { getSubAccountCreateDetails } from "../../../redux/sub_account";
import ActionContext from "../../../context/ActionContext";
// import ProgressBar from "../../../components/fragments/ProgreeBar";
import { FaAngleLeft } from "react-icons/fa";
// import { useLocation } from "react-router-dom";

const TeamInfoModal = ({ visible, onClose, onFinish, detail }) => {
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [step, setStep] = useState(1);
  const { all_account } = useSelector((state) => state.user);
  const { subAccoutDetailList } = useSelector((state) => state.sub_account);
  const { teamRoles, loadingStatement } = useSelector(
    (state) => state.settings
  );
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: "",
    selected_account_id: "",
    authorisation_level: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e && e.preventDefault();
    if (!validateEmail(details.email)) {
      toast.error("Invalid email address!", {
        position: "top-right",
      });
      setShowError((prev) => {
        return { ...prev, email: true };
      });
      return;
    }
    const obj = {
      ...details,
      role: detail?.role_unique_name || detail?.name,
      selected_account_id:
        details?.selected_account_id?.id || actionCtx?.currentAccount?.id,
      authorisation_level: details?.authorisation_level?.value,
    };
    // console.log(obj);
    // return
    const data = await dispatch(inviteTeamMember(obj));
    if (data?.payload?.data?.status === "success") {
      dispatch(getTeamMembers());
      setStep(1);
      onFinish();
      setDetails({
        first_name: "",
        last_name: "",
        email: "",
        role: "",
        selected_account_id: "",
        authorisation_level: "",
      });
    }
  };

  const [showError, setShowError] = useState({
    num: false,
    email: false,
    amount: false,
    meter: false,
  });

  const showLabelFormat = (name, acc_num, amount, showBorder) => {
    return (
      <div
        style={{
          cursor: "pointer",
          //   paddingBottom: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: ".3rem",
        }}
        className={`name-account-box`}
      >
        <p
          style={{ fontWeight: 600, fontSize: "1.5rem", color: "#020202" }}
          className="name"
        >
          {name}
        </p>
        <div
          style={{ display: "flex", alignItems: "center", gap: ".6rem" }}
          className="wrap-box"
        >
          <p className="account_num grey-white-color">{acc_num}</p> <p>•</p>
          <p className="account_num grey-white-color">
            {formatNumWithCommaNairaSymbol(amount)}
          </p>
        </div>
      </div>
    );
  };

  const formatOptionOne = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi, idx) => {
        return {
          ...chi,
          label: showLabelFormat(
            lowerCaseWrap(chi?.custom_account_name),
            chi?.account_number,
            chi?.balance,
            idx !== list?.length - 1 ? true : false
          ),
          value: chi?.account_number,
        };
      });
      return newList;
    }
  };

  const formatOptionTwo = (list) => {
    if (list?.length > 0) {
      const newList = list?.filter(
        (chi) => chi?.value !== details?.sender_account?.value
      );
      return newList;
    }
  };
  const [all_account_list, setAllAccountsList] = useState([]);
  function filterObjectsByRole(objectsArray, name) {
    const newList = objectsArray.filter((obj) => {
      // Check if the selected_roles array includes "finance"
      // console.log(obj.selected_roles);
      // console.log(detail?.name);
      return (
        obj.selected_roles && JSON?.parse(obj.selected_roles).includes(name)
      );
    });
    // console.log(all_account);
    // console.log(newList);
    setAllAccountsList(newList);
  }
  // const location = useLocation();
  useEffect(() => {
    if (all_account?.length > 0) {
      filterObjectsByRole(all_account, detail?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_account, visible]);

  useEffect(() => {
    dispatch(getSubAccountCreateDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formAuthLevel = (list) => {
    // console.log(list);
    if (list?.length > 0) {
      const filterList = list?.filter((chi) => chi !== "verifier");
      const newList = filterList?.map((chi) => {
        return {
          ...chi,
          value: chi,
          label: lowerCaseWrap(formatTypeFunction(chi)),
        };
      });
      return newList;
    }
  };

  const noteIcon = (
    <svg
      className="img"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16536_99374)">
        <path
          d="M8.09961 10.8002V8.10022M8.09961 5.40022H8.10636M14.8496 8.10022C14.8496 11.8281 11.8275 14.8502 8.09961 14.8502C4.37169 14.8502 1.34961 11.8281 1.34961 8.10022C1.34961 4.3723 4.37169 1.35022 8.09961 1.35022C11.8275 1.35022 14.8496 4.3723 14.8496 8.10022Z"
          stroke="#EA872D"
          strokeWidth="1.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16536_99374">
          <rect
            width="16.2"
            height="16.2"
            fill="white"
            transform="translate(0 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const authLevelOptions = [
    {
      value: "initiator_approver",
      label: lowerCaseWrap(formatTypeFunction("initiator_approver")),
    },
    { value: "initiator", label: "Initiator" },
    { value: "approver", label: "Approver" },
    { value: "none", label: "None" },
  ];

  const formatObjValue = (param) => {
    // console.log(param);
    if (param) {
      return { ...param, label: param?.custom_account_name };
    } else {
      return all_account_list?.length > 0
        ? {
            label: all_account_list[0]?.custom_account_name,
            value: all_account_list[0]?.account_number,
          }
        : {
            label: actionCtx?.currentAccount?.custom_account_name,
            value: actionCtx?.currentAccount?.account_number,
          };
    }
  };
  const stepList = ["Personal details", "Business details"];
  const [openSelect, setOpenSelect] = useState("");

  return (
    <RavenModal
      visble={visible}
      onClose={() => {
        onClose();
        setStep(1);
        setDetails((prev) => {
          return {
            ...prev,
            first_name: "",
            last_name: "",
            email: "",
            role: "",
            selected_account_id: "",
            authorisation_level: "",
          };
        });
      }}
      btnColor={`black-light`}
      btnLabel={
        step === 1
          ? `Proceed to next step`
          : `Add user to ${lowerCaseWrap(detail?.name || "---")}`
      }
      // btnLabel={`Add user to ${lowerCaseWrap(detail?.name || "---")}`}
      loading={loadingStatement}
      disabled={
        step === 1
          ? !details?.email || !details?.first_name || !details?.last_name
          : step === 2
          ? !details?.authorisation_level
          : false
      }
      onBtnClick={(e) => {
        if (step === 1) {
          if (!validateEmail(details.email)) {
            toast.error("Invalid email address!", {
              position: "top-right",
            });
            setShowError((prev) => {
              return { ...prev, email: true };
            });
            return;
          }
          setStep(2);
        }
        if (step === 2) {
          handleSubmit(e);
        }
      }}
    >
      <div className="cashier-assign-modal-wrap">
        <p className="title">User Information</p>
        <p className="text grey-white-color">
          {` Add a user to ${
            detail?.name || "---"
          } role and give them permission to access
          your business banking dashboard`}
        </p>
        <div className="underline"></div>
        {/* step process start */}
        <div className="step-process-wrap-box">
          {step === 2 && (
            <div
              onClick={() => {
                setStep(1);
              }}
              className="back-box"
            >
              <FaAngleLeft className="icon grey-white-color" />
              <p className="text">back</p>
            </div>
          )}
          {/* step box start */}
          {stepList && (
            <div className="step-box">
              {/* box start */}
              {stepList?.map((chi, idx) => {
                return (
                  <div
                    className={`box ${step >= idx + 1 && "box-active"}`}
                    key={idx}
                    onClick={() => {
                      if (idx === 0) {
                        setStep(idx + 1);
                      }
                      if (
                        idx === 1 &&
                        details?.email &&
                        details?.first_name &&
                        details?.last_name
                      ) {
                        if (!validateEmail(details.email)) {
                          toast.error("Invalid email address!", {
                            position: "top-right",
                          });
                          setShowError((prev) => {
                            return { ...prev, email: true };
                          });
                          return;
                        }
                        setStep(idx + 1);
                      }
                    }}
                    style={{
                      cursor:
                        idx === 1 &&
                        details?.email &&
                        details?.first_name &&
                        details?.last_name
                          ? "pointer"
                          : idx === 0
                          ? "pointer"
                          : "not-allowed",
                    }}
                  >
                    <div className="num-box">
                      {" "}
                      <div className="num">
                        <span>{idx + 1}</span>
                      </div>
                    </div>

                    <p className="text">{chi || "0ieje"}</p>
                  </div>
                );
              })}
              {/* box end */}
            </div>
          )}
          {/* step box end */}
        </div>
        {/* step process end */}
        <div style={{ marginBottom: "2.5rem" }} className="underline"></div>
        {/* step box start */}
        {/* <div className="step-progress-wrap-box">
          <ProgressBar
            bgcolor={`#755AE2`}
            completed={
              sumAllNum([
                details?.email ? 1 : 0,
                details?.first_name ? 1 : 0,
                details?.last_name ? 1 : 0,
              ]) === 3
                ? 100
                : sumAllNum([
                    details?.email ? 1 : 0,
                    details?.first_name ? 1 : 0,
                    details?.last_name ? 1 : 0,
                  ]) === 2
                ? 60
                : sumAllNum([
                    details?.email ? 1 : 0,
                    details?.first_name ? 1 : 0,
                    details?.last_name ? 1 : 0,
                  ]) === 1
                ? 30
                : 0
            }
            borderRadius={50}
            height={5}
            className={`grey-bg-two`}
            key={`jhvbjk`}
            onClick={() => {
              setStep(1);
            }}
          />
          <ProgressBar
            className={`grey-bg-two`}
            bgcolor={`#755AE2`}
            borderRadius={50}
            height={5}
            key={`hgyh`}
            onClick={() => {
              if (details?.email && details?.first_name && details?.last_name) {
                setStep(2);
              }
            }}
            completed={
              step === 2 &&
              sumAllNum([
                detail?.role_unique_name ? 1 : 0,
                details?.authorisation_level ? 1 : 0,
              ]) === 2
                ? 100
                : step === 2 &&
                  sumAllNum([
                    detail?.role_unique_name ? 1 : 0,
                    details?.authorisation_level ? 1 : 0,
                  ]) === 2
                ? 60
                : 0
            }
          />
        </div> */}
        {/* step box end */}
        {/* step one start */}
        {step === 1 && (
          <>
            {" "}
            <div className="form-inputs-wrap">
              <form action="" autoComplete="off" style={{ width: "100%" }}>
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`text`}
                  label={`First Name`}
                  placeholder={`E.g Timi`}
                  onChange={handleChange}
                  name={`first_name`}
                  value={details?.first_name}
                />
              </form>
              <form action="" autoComplete="off" style={{ width: "100%" }}>
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`text`}
                  label={`Last Name`}
                  placeholder={`E.g Ayeni`}
                  onChange={handleChange}
                  name={`last_name`}
                  value={details?.last_name}
                />
              </form>
              <form action="" autoComplete="off" style={{ width: "100%" }}>
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`email`}
                  label={`Email address* `}
                  placeholder={`E.g ayenikehi@gamil.com`}
                  name={`email`}
                  value={details?.email}
                  showError={showError?.email}
                  onChange={(e) => {
                    setShowError((prev) => {
                      return { ...prev, email: false };
                    });
                    handleChange(e);
                  }}
                />
              </form>
            </div>
          </>
        )}
        {/* step one end */}
        {/* step two start */}
        {step === 2 && (
          <>
            {" "}
            <div className="form-inputs-wrap">
              {all_account?.length > 1 && (
                <form
                  action=""
                  autoComplete="off"
                  style={{
                    width: "100%",
                    position: "relative",
                    zIndex: openSelect === "account" ? 100 : 72,
                  }}
                >
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    type={`select`}
                    label={`Select Account* `}
                    placeholder={`Account Option`}
                    selectOption={formatOptionTwo(
                      formatOptionOne(all_account_list)
                    )}
                    onMenuOpen={() => {
                      // console.log("kjhd");
                      setOpenSelect("account");
                    }}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          selected_account_id: e,
                        };
                      });
                    }}
                    value={formatObjValue(details?.selected_account_id)}
                    // value={details?.role}
                    // menuPlacement={`top`}
                    selectStyles={reactSelectStyle}
                  />
                </form>
              )}
              <form action="" autoComplete="off" style={{ width: "100%" }}>
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`select`}
                  label={`Assign a role* `}
                  disabled
                  placeholder={`Dropdown Option`}
                  selectOption={teamRoles}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, role: e };
                    });
                  }}
                  value={{
                    label: lowerCaseWrap(detail?.name),
                    value: detail?.name,
                  }}
                  selectStyles={reactSelectStyle}
                />
              </form>
              <form
                action=""
                autoComplete="off"
                style={{
                  width: "100%",
                  position: "relative",
                  zIndex: openSelect === "auth" ? 100 : 70,
                }}
              >
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`select`}
                  label={`Select Auth level* `}
                  placeholder={`E.g Initiator`}
                  selectOption={
                    formAuthLevel(subAccoutDetailList?.authorization_values) ||
                    authLevelOptions
                  }
                  onMenuOpen={() => {
                    // console.log("kjhd");
                    setOpenSelect("auth");
                  }}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, authorisation_level: e };
                    });
                  }}
                  value={details?.authorisation_level}
                  menuPlacement={`top`}
                  selectStyles={reactSelectStyle}
                />
              </form>
               {/* note box start */}
               <div className="note-box-wrap-team-invite grey-bg-two">
                <figure className="img-box">{noteIcon}</figure>
                <p className="text">
                  Please note that assignment of roles is per the account that
                  is selected.
                </p>
              </div>
              {/* note box end */}
            </div>
          </>
        )}

        {/* step two end */}
        {/* form wrap start */}

        {/* form wrap end */}
      </div>
    </RavenModal>
  );
};

export default TeamInfoModal;
