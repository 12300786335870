// import { toast } from "@ravenpay/raven-bank-ui";
import React from "react";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { deletePayroll } from "../../../../redux/payroll";
import { useDispatch, useSelector } from "react-redux";

const DeleteUserModal = ({ visible, onCancel, onFinish, detail }) => {
  const { loading } = useSelector((state) => state.payroll);
  const dispatch = useDispatch();
  const handleDelete = async () => {
    const obj = {
      id: String(detail?.id),
    };
    // console.log(obj);
    const data = await dispatch(deletePayroll(obj));
    // console.log(data);
    // if (data?.payload?.data?.status !== "success") {
    //   toast.success("Employee removed successfully");
    //   // toast.error('Oops! Unable to delete employee', {
    //   //   theme: "colored",
    //   //   position: "top-right",
    //   // })

    //  onFinish()
    //   return;
    // }
    if (data?.payload.data?.status === "success") {
      // toast.success("Employee removed successfully");
      onFinish && onFinish();
      return;
    }
  };
  return (
    <DeleteModal
      loading={loading}
      visible={visible}
      deleteText="Delete"
      onCancel={onCancel}
      onDelete={handleDelete}
      title="Delete this person"
      text={`Remove this users, from your payroll list, this mean you won’t be able to perform transaction, for this user.`}
    />
  );
};

export default DeleteUserModal;
