import React, { useEffect, useState } from "react";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoContentBox from "../../../../components/reusables/NoContentBox";
// import notyemailIcon from "../../../../assets/email-settings-notifi-icon.svg";
// import notySmsIcon from "../../../../assets/settings-sms-notification-icon.svg";
import { getRolesAndPermissions } from "../../../../redux/roleAndPermission";
// import { notificationSettings } from "../../../../redux/settings";
// import { getUser } from "../../../../redux/user";
import { SPECIAL_ROLE_NAME, lowerCaseWrap } from "../../../../utils/helper/Helper";
import DashboardSettingsLayout from "./SettingsLayout";

const DashboardSettingsRoles = () => {
  const { business, user_resource_and_permissions } = useSelector(
    (state) => state.user
  );
  const { roles_and_users, loading } = useSelector(
    (state) => state.role_and_permission
  );
  // eslint-disable-next-line no-unused-vars
  const [emailStatus, setEmailStatus] = useState(0);
  // const [disableSms, setDisableSms] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(business)?.length > 0) {
      setEmailStatus(Number(business?.email_notification_control));
      // console.log(business?.email_notification_control);
    }
  }, [business]);

  const arrowIcon = (
    <svg
      className="img dull-bright-filter"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M6 12L10 8L6 4"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    dispatch(getRolesAndPermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const arrangeListFunc = (list) => {
    if (list?.length > 0) {
      const targetValue = SPECIAL_ROLE_NAME;
    //   console.log(list);
      // Filter out the object with the specified value
      const ownerObject = list.find((obj) => obj.role_object?.role_unique_name === targetValue);
      const filteredObjects = list.filter(
        (obj) => obj.role_object?.role_unique_name !== targetValue
      );

      // Add the ownerObject to the beginning of the array
      const newArray = ownerObject ? [ownerObject, ...filteredObjects] : list;
      return newArray;
    }
  };

  return (
    <>
      <DashboardSettingsLayout>
        <>
          {roles_and_users?.length > 0 ? (
            <div className="detail-wrap role-permission-setting-wrap animate-move-up-class">
              <div className="text-btn-box">
                {" "}
                <div
                  onClick={() => {
                    //    navigate("/dashboard-role-and-permission-create")
                  }}
                  className="text-wrap"
                >
                  <p className="title">Roles & Permission</p>
                  <p className="sub-title grey-white-color">
                    Manage your roles and permission here.
                  </p>
                </div>
                {user_resource_and_permissions?.allowed_permissions?.includes(
                  "can_create_new_role"
                ) && (
                  <RavenButton
                    color={`black-light`}
                    label={`Create Custom Role`}
                    onClick={() => {
                      navigate("/dashboard-role-and-permission-create");
                    }}
                  />
                )}
              </div>
              <div className="box-wrap border-theme">
                {arrangeListFunc(roles_and_users)?.map((chi, idx) => {
                  const { role_object, number_of_users } = chi;
                  // console.log(chi);
                  return (
                    <div
                      onClick={() => {
                        if (
                          user_resource_and_permissions?.allowed_permissions?.includes(
                            "can_view_roles"
                          )
                        ) {
                          navigate("/dashboard-role-and-permission-single");
                          localStorage?.setItem(
                            "role-permission-single-name",
                            role_object?.role_unique_name
                          );
                        }

                        // console.log(role_object?.id);
                      }}
                      key={idx || role_object?.id}
                      className={`role-wrap border-theme`}
                    >
                      <p className="name">{lowerCaseWrap(role_object?.name)}</p>
                      <p className="des grey-white-color">
                        {role_object?.description}
                      </p>
                      {/* staff no box start */}
                      <div className="staff-no-box grey-bg">
                        <p className="text">{`${number_of_users} Staff Added`}</p>
                        <figure className="img-box">{arrowIcon}</figure>
                      </div>
                      {/* staff no box end */}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="detail-wrap animate-move-up-class">
              <div
                className="box-wrap grey-bg"
                style={{
                  display: "grid",
                  placeItems: "center",
                  minHeight: "45rem",
                }}
              >
                {" "}
                <NoContentBox
                  loading={loading}
                  title={`No Roles and Permission`}
                  text={`List of all roles and permissions will be found here`}
                />
              </div>
            </div>
          )}
        </>
      </DashboardSettingsLayout>
    </>
  );
};

export default DashboardSettingsRoles;
