import {
  RavenCheckBox,
  RavenInputField,
  RavenModal,
  //   toast,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editBeneficiaries,
  getBeneficiaries,
  //   getTeamMembers,
  // getTeamRoles,
  //   inviteTeamMember,
} from "../../../../redux/settings";
// import { validateUniqueEmail } from "../../../../redux/user";
import "../../../../styles/dashboard/pos/DashboardPosIndex.css";
import { ThreeDots } from "react-loader-spinner";
import {
  reactSelectStyle,
  removeSpace,
  //   removeSpace,
  // sumAllNum,
} from "../../../../utils/helper/Helper";
// import { validateEmail } from "../../../../utils/helper/ValidateInput";
// import ActionContext from "../../../../context/ActionContext";
// import { getSubAccountCreateDetails } from "../../../../redux/sub_account";
// import ProgressBar from "../../../../components/fragments/ProgreeBar";
// import { FaAngleLeft } from "react-icons/fa";
import { fetchBanks, validateBankAccount } from "../../../../redux/transaction";
// import FingerprintJS from "@fingerprintjs/fingerprintjs";

const EditBeneficiaryLocalModal = ({ visible, onClose, detail }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [step, setStep] = useState(1);

  const { bankList, loadingVerify } = useSelector((state) => state.transaction);
  //   const actionCtx = useContext(ActionContext);
  //   const { loadingStatement } = useSelector((state) => state.settings);
  const { loading } = useSelector((state) => state.settings);

  const [details, setDetails] = useState({
    username: "",
    amount: "",
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    account_num: "",
    bank: "",
    name: "",
    schedule_time: "",
    schedule: "",
    transfer_pin: "",
    template_title: "",
    save_template: false,
  });

  // const [beneListVal, setBenelistVal] = useState(beneficiaries?.bankAccounts);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const [validateName, setValidatedName] = useState("");
  // validate bank account details
  const validateBankDetails = async (e) => {
    const payload = {
      bank_code: e || details?.bank.value || details?.bank?.bank_code,
      account_number: removeSpace(details.account_num),
    };
    // console.log(payload);

    if (payload?.bank_code && payload?.account_number?.length > 9) {
      const data = await dispatch(validateBankAccount(payload));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        // console.log(data?.payload?.data.data);
        setDetails((prev) => {
          return { ...prev, username: data?.payload?.data.data };
        });
        setValidatedName(data?.payload?.data.data);
        // setStage(3);
      }
    }
  };

  const selectValidateAccount = async (e) => {
    if (details?.account_num.replace?.length > 9) {
      validateBankDetails(e);
    }
  };

  useEffect(() => {
    if (details?.bank && details?.account_num?.length > 9) {
      validateBankDetails();
    }
    removeSpace(details?.account_num);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.account_num || details?.bank]);

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name } = chi;
      return {
        label: chi?.name,
        value: code,
        labelTwo: name,
      };
    });
    // const newList = formatListWithImage(list)
    // console.log(newList);
    return list;
  };

  //   const [showError, setShowError] = useState({
  //     num: false,
  //     email: false,
  //     amount: false,
  //     meter: false,
  //   });

  useEffect(() => {
    if (visible && Object?.keys(detail)?.length > 0) {
      //   console.log(detail);
      setDetails((prev) => {
        return {
          ...prev,
          account_num: detail?.account_number,
          bank: { value: detail?.bank_code, label: detail?.bank },
        };
      });
      setValidatedName(detail?.account_name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    dispatch(fetchBanks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const obj = {
      bank: details?.bank?.label,
      account_name: validateName,
      account_number: details?.account_num,
      bank_code: details?.bank?.value,
      id: String(detail?.id),
    };
    const data = await dispatch(editBeneficiaries(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      dispatch(getBeneficiaries());
      setValidatedName("");
      setDetails((prev) => {
        return {
          ...prev,
          username: "",
          amount: "",
          narration: "Sent from raven",
          bulk_list: [],
          beneficiary: false,
          account_num: "",
          bank: "",
          name: "",
          schedule_time: "",
          schedule: "",
          transfer_pin: "",
          template_title: "",
          save_template: false,
        };
      });
      onClose();
    }
  };

  return (
    <RavenModal
      visble={visible}
      onClose={() => {
        onClose();
        setValidatedName("");
        setDetails((prev) => {
          return {
            ...prev,
            username: "",
            amount: "",
            narration: "Sent from raven",
            bulk_list: [],
            beneficiary: false,
            account_num: "",
            bank: "",
            name: "",
            schedule_time: "",
            schedule: "",
            transfer_pin: "",
            template_title: "",
            save_template: false,
          };
        });
      }}
      btnColor={`black-light`}
      btnLabel={`Save changes`}
      loading={loading}
      disabled={ details?.account_num === detail?.account_number &&
        details?.bank?.value === detail?.bank_code ? true :
        !validateName ||
        !details?.bank ||
        !details?.account_num 
        
      }
      onBtnClick={handleSubmit}
    >
      <div className="cashier-assign-modal-wrap">
        <p className="title">Edit Beneficiary</p>
        <p className="text grey-white-color">
          Add a team mate to your business .
        </p>
        {/* <div className="underline"></div> */}
        <div style={{ marginBottom: "2.5rem" }} className="underline"></div>
        {/* step box start */}

        {/* step one start */}
        {step === 1 && (
          <>
            <div className="form-inputs-wrap">
              <div
                autoComplete="off"
                style={{
                  width: "100%",
                  position: "relative",
                  zIndex: 1000,
                }}
                action=""
              >
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`select`}
                  label="Select bank* "
                  placeholder="Choose a bank"
                  // selectComponents={{ Option: CustomOption }}
                  onChange={(e) => {
                    // setBeneficiarySelect("");
                    setValidatedName("");
                    selectValidateAccount(e.bank_code);
                    setDetails((prev) => {
                      return { ...prev, bank: e, account_num: "" };
                    });
                    // if (details?.account_num?.length > 9) {
                    //   validateBankDetails(e.value);
                    // }
                  }}
                  selectValue={details?.bank}
                  name={`bank`}
                  value={details?.bank}
                  className={`select-react`}
                  selectOption={formatSelectOption(bankList)}
                  selectStyles={reactSelectStyle}
                />
              </div>
              <div autoComplete="off" style={{ width: "100%" }} action="">
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`account-number`}
                  label="Account number* "
                  placeholder="Recipient account no."
                  onChange={(e) => {
                    setValidatedName("");
                    handleChange(e);
                  }}
                  value={details?.account_num}
                  name={`account_num`}
                  maxLength={10}
                  labelSpanText={
                    loadingVerify ? (
                      <div
                        style={{
                          padding: "0rem",
                          display: "flex",
                          justifyContent: "flex-end",
                          transform: "translateX(1.5rem)",
                        }}
                        className="load-wrap"
                      >
                        <ThreeDots
                          height="10"
                          width="80"
                          radius="9"
                          // color="#020202"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      "Verify"
                    )
                  }
                  labelColor={`purple-light`}
                  labelClassName={`label-class-span ${
                    details?.account_num?.length === 10 &&
                    "label-class-span-active"
                  }`}
                  onActionClick={() => {
                    details?.account_num?.length === 10 &&
                      validateBankDetails();
                  }}
                />
              </div>

              {/* verify name box start */}
              <div
                className={`verify-name-box-beneficiary-settings ${
                  validateName && "verify-name-box-show-beneficiary-settings"
                }`}
              >
                <RavenCheckBox
                  checked={true}
                  color={`black-light`}
                  className={`check`}
                />
                <p className="text">{validateName}</p>
              </div>
              {/* verify name box end */}
            </div>
          </>
        )}
        {/* step one end */}
      </div>
    </RavenModal>
  );
};

export default EditBeneficiaryLocalModal;
