import { RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
// import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import "../../../styles/fragments/PreviewDetailsCommonBox.css";

const PreviewPaymentDetailsBox = ({
  collapse,
  amount,
  email,
  bank,
  linkDuration,
  narration,
  onChange,
  value,
  id,
  checked,
  linkType,
}) => {
  const [showDetail, setShowDetail] = useState(false);
  return (
    <div
      className={`preview-details-common-box  ${
        collapse && "preview-details-common-box-collapse"
      } ${showDetail && "preview-details-common-box-show"}`}
    >
      {/* top box start */}
      <div
        onClick={() => {
          collapse && setShowDetail(!showDetail);
        }}
        className="top-box"
      >
        <div className="text-box">
          <p
            style={{ textTransform: "uppercase", fontWeight: "600" }}
            className="key grey-white-color"
          >
            requested amount
          </p>
          <p className="value">{amount || "987678"}</p>
        </div>
        {/* {collapse && (
          <div className="icon-box">
            {!showDetail ? (
              <FaAngleDown className="icon" />
            ) : (
              <FaAngleRight className="icon" />
            )}
          </div>
        )} */}
      </div>
      {/* top box end */}
      {/* middle-content start */}
      <div className="middle-box">
        <div className="text">
          {/* <span className="grey-white-color">To:</span>{" "} */}
          <div className="img-wrap">
            <figure className="img-box">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="img"
              >
                <circle cx="16" cy="16" r="16" fill="#F7F8F7" />
                <g clipPath="url(#clip0_14182_73659)">
                  <path
                    d="M10.2861 13.1432L13.254 15.1218C13.4253 15.236 13.5109 15.2931 13.6035 15.3335C13.6857 15.3694 13.7719 15.3955 13.8602 15.4112C13.9597 15.429 14.0626 15.429 14.2684 15.429H17.7325C17.9383 15.429 18.0412 15.429 18.1407 15.4112C18.229 15.3955 18.3151 15.3694 18.3973 15.3335C18.49 15.2931 18.5756 15.236 18.7468 15.1218L21.7147 13.1432M13.029 20.5718H18.9718C19.9319 20.5718 20.412 20.5718 20.7787 20.385C21.1013 20.2206 21.3635 19.9584 21.5279 19.6358C21.7147 19.2691 21.7147 18.789 21.7147 17.829V14.1718C21.7147 13.2117 21.7147 12.7317 21.5279 12.365C21.3635 12.0424 21.1013 11.7802 20.7787 11.6158C20.412 11.429 19.9319 11.429 18.9718 11.429H13.029C12.0689 11.429 11.5889 11.429 11.2221 11.6158C10.8996 11.7802 10.6373 12.0424 10.473 12.365C10.2861 12.7317 10.2861 13.2117 10.2861 14.1718V17.829C10.2861 18.789 10.2861 19.2691 10.473 19.6358C10.6373 19.9584 10.8996 20.2206 11.2221 20.385C11.5889 20.5718 12.0689 20.5718 13.029 20.5718Z"
                    stroke="black"
                    strokeWidth="1.35591"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14182_73659">
                    <rect
                      width="13.7143"
                      height="13.7143"
                      fill="white"
                      transform="translate(9.14258 9.14307)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </figure>
          </div>
          <span>{email || "---"}</span>
        </div>
        <div className="text">
          {/* <span className="grey-white-color">Link type:</span>{" "} */}
          <div className="img-wrap">
            <figure className="img-box">
              <svg
                className="img"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16" cy="16" r="16" fill="#F7F8F7" />
                <path
                  d="M16.4043 19.6368L15.5962 20.4449C14.4804 21.5607 12.6714 21.5607 11.5556 20.4449C10.4398 19.3291 10.4398 17.5201 11.5556 16.4043L12.3637 15.5962M19.6368 16.4043L20.4449 15.5962C21.5607 14.4804 21.5607 12.6714 20.4449 11.5556C19.3291 10.4398 17.5201 10.4398 16.4043 11.5556L15.5962 12.3637M14.0003 18.0002L18.0003 14.0002"
                  stroke="black"
                  strokeWidth="1.35591"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
          </div>
          <span>{linkType || "Recurring payment"}</span>
        </div>
        {linkDuration && (
          <div className="text">
            {/* <span className="grey-white-color">Link duration:</span>{" "} */}
            <div className="img-wrap">
              <figure className="img-box">
                <svg
                  className="img"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="16" cy="16" r="16" fill="#F7F8F7" />
                  <g clipPath="url(#clip0_14182_73668)">
                    <path
                      d="M14.285 17.7145H17.7136M21.1422 13.7145H10.8564M18.285 10.2859V12.0002M13.7136 10.2859V12.0002M13.5993 21.7145H18.3993C19.3594 21.7145 19.8394 21.7145 20.2061 21.5276C20.5287 21.3633 20.791 21.101 20.9553 20.7784C21.1422 20.4117 21.1422 19.9317 21.1422 18.9716V14.1716C21.1422 13.2115 21.1422 12.7315 20.9553 12.3648C20.791 12.0422 20.5287 11.7799 20.2061 11.6156C19.8394 11.4287 19.3594 11.4287 18.3993 11.4287H13.5993C12.6392 11.4287 12.1592 11.4287 11.7925 11.6156C11.4699 11.7799 11.2076 12.0422 11.0433 12.3648C10.8564 12.7315 10.8564 13.2115 10.8564 14.1716V18.9716C10.8564 19.9317 10.8564 20.4117 11.0433 20.7784C11.2076 21.101 11.4699 21.3633 11.7925 21.5276C12.1592 21.7145 12.6392 21.7145 13.5993 21.7145Z"
                      stroke="black"
                      strokeWidth="1.35591"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_14182_73668">
                      <rect
                        width="13.7143"
                        height="13.7143"
                        fill="white"
                        transform="translate(9.14258 9.14307)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </figure>
            </div>
            <span>{linkDuration}</span>
          </div>
        )}
        <div className="text">
          <div className="img-wrap">
            <figure className="img-box">
              <svg
                className="img"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16" cy="16" r="16" fill="#F7F8F7" />
                <g clipPath="url(#clip0_14182_73676)">
                  <path
                    d="M19.429 10.2859L21.7147 12.5716M10.2861 21.7145L11.0155 19.0401C11.0631 18.8656 11.0869 18.7784 11.1234 18.697C11.1558 18.6248 11.1957 18.5561 11.2423 18.4921C11.2948 18.4201 11.3588 18.3561 11.4866 18.2282L17.3915 12.3234C17.5046 12.2103 17.5612 12.1537 17.6264 12.1325C17.6838 12.1139 17.7456 12.1139 17.803 12.1325C17.8682 12.1537 17.9248 12.2103 18.038 12.3234L19.6772 13.9626C19.7903 14.0758 19.8469 14.1324 19.8681 14.1976C19.8867 14.255 19.8867 14.3168 19.8681 14.3742C19.8469 14.4394 19.7903 14.496 19.6772 14.6091L13.7724 20.5139C13.6445 20.6418 13.5805 20.7058 13.5085 20.7583C13.4445 20.8049 13.3758 20.8448 13.3036 20.8772C13.2222 20.9137 13.135 20.9375 12.9605 20.9851L10.2861 21.7145Z"
                    stroke="black"
                    strokeWidth="1.35591"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14182_73676">
                    <rect
                      width="13.7143"
                      height="13.7143"
                      fill="white"
                      transform="translate(9.14258 9.14307)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </figure>
          </div>
          <span>
            {narration || "Paid my money today else you would see shege."}
          </span>
        </div>
      </div>
      {/* middle content end */}
      {/* {collapse && !showDetail && (
        <div className={`collapse-box`}>
          <p className="text">
            <span className="grey-white-color">To:</span>{" "}
            <span>{name || "Olakunle Temitayo Abraham"}</span>
          </p>
        </div>
      )} */}
      {/* bottom box start */}
      <div className="bottom-box">
        <p className="text grey-white-color">
          I confirm my request to create this link
        </p>
        <RavenToggleSlide
          onChange={onChange}
          id={id}
          value={value}
          color={`black-light`}
          checked={checked}
          //   className={`toggle-box-wrap`}
        />
      </div>
      {/* bottom box end */}
      {/* charge and rate box start */}
      <div className="charge-rate-box-wrap border-theme-top">
        <div className="img-wrap">
          <figure className="img-box">
            <svg
              className="img"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6583 21.1963C16.9342 21.1963 21.2112 16.9193 21.2112 11.6434C21.2112 6.36753 16.9342 2.09058 11.6583 2.09058C6.38242 2.09058 2.10547 6.36753 2.10547 11.6434C2.10547 16.9193 6.38242 21.1963 11.6583 21.1963Z"
                fill="#EA872D"
              />
              <path
                d="M11.6583 7.82228V11.6434M11.6583 15.4646H11.6679M21.2112 11.6434C21.2112 16.9193 16.9342 21.1963 11.6583 21.1963C6.38242 21.1963 2.10547 16.9193 2.10547 11.6434C2.10547 6.36753 6.38242 2.09058 11.6583 2.09058C16.9342 2.09058 21.2112 6.36753 21.2112 11.6434Z"
                stroke="#FFF6ED"
                strokeWidth="1.43293"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </figure>
        </div>
        <p className="text">
          You would be charged <span>1%</span> but not more than{" "}
          <span> 2,000 </span> on every transaction.
        </p>
      </div>
      {/* charge and rate box end */}
    </div>
  );
};

export default PreviewPaymentDetailsBox;
