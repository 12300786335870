import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import "../../../styles/dashboard/global-account/BeneficiaryGlobalModal.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllBeneficiaries,
  getAllCurrencies,
  getCurrencyInput,
} from "../../../redux/globalAccount";
import NoContentBox from "../../../components/reusables/NoContentBox";
import deleteIcon from "../../../assets/delete-icon.svg";
import Skeleton from "react-loading-skeleton";
import CheckUrlFragment from "../../../components/fragments/CheckUrlCodeFragment";
import {
  capitalizeFirstLetter,
  convertToLowercase,
  formatMetaData,
  trimLongString,
} from "../../../utils/helper/Helper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import DeleteBeneficiaryGlobalModal from "../settings/modal/DeleteBeneficiaryGlobalModal";
import ActionContext from "../../../context/ActionContext";
import EditRecipientGlobalWrapIndex from "../create-recipient-global/EditRecipientGlobalAccount";
import { useLocation, useNavigate } from "react-router-dom";

const BeneficiaryGlobalAccount = () => {
  const location = useLocation();
  const size = useWindowSize();
  const actionCtx = useContext(ActionContext);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [activeSelect, setActiveSelect] = useState("Individual");
  const selectOptionList = ["Individual", "Business"];
  const selectBoxListTwo = [
    {
      label: size?.width < 900 ? "Bank" : "Bank Account",
      value: "bank_account",
    },
    {
      label: size?.width < 900 ? "Momo" : "Mobile Money",
      value: "mobile_money",
    },
    { label: size?.width < 900 ? "Card" : "Card Recipient", value: "card" },
  ];
  const [selectectedBox, setSelectedBox] = useState({
    label: "Bank Account",
    value: "bank_account",
  });

  const [singleChild, setSingleChild] = useState({});
  const [showModal, setShowModal] = useState({
    delete: false,
    edit: false,
    delete_global: false,
  });
  const dispatch = useDispatch();
  const { all_beneficiaries, loadingBene, all_currencies } = useSelector(
    (state) => state.global_account
  );

  useEffect(() => {
    dispatch(getAllBeneficiaries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListToShow = (param) => {
    if (param?.length > 0) {
      const newlist = param?.filter(
        (chi) => chi?.delivery_method === selectectedBox?.value
      );
      return newlist;
    }
  };

  const editIcon = (
    <svg
      className="img"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M14.1391 1.79773L17.1058 4.7644M2.27246 16.6311L3.21913 13.16C3.28089 12.9335 3.31177 12.8203 3.35917 12.7147C3.40127 12.6209 3.45299 12.5318 3.5135 12.4487C3.58166 12.3552 3.66465 12.2722 3.83063 12.1062L11.4946 4.44228C11.6414 4.29542 11.7149 4.222 11.7995 4.19448C11.874 4.17028 11.9542 4.17028 12.0287 4.19448C12.1134 4.222 12.1868 4.29542 12.3337 4.44228L14.4612 6.56985C14.6081 6.7167 14.6815 6.79013 14.709 6.8748C14.7332 6.94928 14.7332 7.02951 14.709 7.10399C14.6815 7.18866 14.6081 7.26209 14.4612 7.40895L6.7973 15.0729C6.63132 15.2389 6.54833 15.3219 6.45478 15.39C6.37172 15.4505 6.2826 15.5023 6.18884 15.5444C6.08325 15.5918 5.97002 15.6226 5.74356 15.6844L2.27246 16.6311Z"
        stroke="#333333"
        strokeWidth="1.335"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const [inputObjList, setInputObjList] = useState([]);
  const [previousObj, setPreviousObj] = useState({});

  const getCurrencyInputFunc = async (param, chi) => {
    if (param) {
      const data = await dispatch(getCurrencyInput({ currency: param }));
      // console.log(data);
      if (data?.payload?.status === "success") {
        const inputOptions = data?.payload?.data?.currency_inputs?.data;
        // console.log(inputOptions);
        const inputOptionTwo = inputOptions?.map((chi) => {
          const obj = {
            ...chi,
            value: chi?.value || "",
            input_type:
              chi?.input_type === "digit"
                ? "number"
                : chi?.input_type === "selector"
                ? "select"
                : chi?.input_type,
            options:
              chi?.options?.length > 0
                ? chi?.options?.map((chid) => {
                    return { ...chid, label: chid?.key || chid?.value };
                  })
                : "",
          };
          return obj;
        });
        const updatedArray = inputOptionTwo?.map((obj) => {
          // Check if the key exists in the columnValues object
          if (chi?.hasOwnProperty(obj?.key)) {
            // Create a new object with the updated value
            return {
              ...obj,
              value:
                obj?.options?.length > 0
                  ? { label: chi[obj.key], value: chi[obj.key] }
                  : chi[obj.key],
            };
          }
          return { ...obj, value: "" };
        });

        setInputObjList(updatedArray);
        getInputCountriesFromAllCurrencies(param, updatedArray);
      }
    }
  };

  const handleChangeInputValue = (e) => {
    const { name, value, type } = e.target;
    if (type && type === "select") {
      // console.log(e);
      const newList = inputObjList?.map((chi) =>
        chi?.key === name ? { ...chi, value: value } : chi
      );
      setInputObjList(newList);
    } else {
      const newList = inputObjList?.map((chi) =>
        chi?.key === name ? { ...chi, value: value } : chi
      );
      setInputObjList(newList);
    }
  };

  useEffect(() => {
    if (Object?.keys(all_currencies)?.length < 1) {
      dispatch(getAllCurrencies());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getInputCountriesFromAllCurrencies = (param, list) => {
    const obj = all_currencies?.all_support_currencies?.find(
      (chi) => chi?.currency === param
    );
    const countryOptions = obj?.countries?.map((chi) => {
      return { ...chi, label: chi?.country, value: chi?.country };
    });
    // console.log(countryOptions);
    changeCountryTypeToSelect(countryOptions, list);
  };

  const changeCountryTypeToSelect = (options, list) => {
    // console.log(value);
    const inputOptionTwo = list?.map((chi) =>
      chi?.key === "beneficiary_country"
        ? {
            ...chi,
            value: { label: chi?.value, value: chi?.value } || "",
            input_type: "select",
            options: options,
          }
        : chi
    );
    // console.log(inputOptionTwo);
    setInputObjList(inputOptionTwo);
  };

  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_setup_global_account"
      )
    ) {
      navigate("/dashboard-global-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Global Account Beneficiaries`}
          text={`Manage all your global account beneficiaries here`}
          showSettingsIcon={false}
        />
        <ContainerWrapper width={`100%`} dontShowHeader={true}>
          {/* index beneficiary global account start */}
          <div
            // onClick={() => {
            //   console.log(all_beneficiaries);
            // }}
            className="index-beneficiary-global-account-wrap-box"
          >
            <div className="middle-content-box">
              {/* select beneficiary type box start */}
              <div className="select-box-list-wrap grey-bg">
                {selectBoxListTwo?.map((chi, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`item-box ${
                        selectectedBox?.label === chi?.label &&
                        "white-black-bg card-bg item-box-active"
                      }`}
                      onClick={() => {
                        if (selectectedBox?.label !== chi?.label) {
                          setSelectedBox(chi);
                        }
                      }}
                    >
                      <p
                        className={`item ${
                          selectectedBox?.label !== chi?.label &&
                          "grey-white-color"
                        }`}
                      >
                        {chi?.label}
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* select beneficiary type box end */}
              <>
                {getListToShow(all_beneficiaries?.beneficiaries)?.length > 0 ? (
                  <div className="box-wrap grey-bg">
                    {" "}
                    {getListToShow(all_beneficiaries?.beneficiaries)?.map(
                      (chi, idx) => {
                        const {
                          // account_name,
                          account_number,
                          bank_name,
                          // bank_code,
                          // valid,
                          currency,
                          beneficiary_name,
                        } = chi;

                        return (
                          <div
                            key={idx}
                            className="noti-wrap border-theme-bottom"
                          >
                            {loadingBene ? (
                              <Skeleton width={45} height={45} circle />
                            ) : (
                              <div className="avatar-box">
                                <CheckUrlFragment
                                  url={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                                    currency
                                  )}.png`}
                                >
                                  <span style={{ textTransform: "capitalize" }}>
                                    {beneficiary_name &&
                                      beneficiary_name
                                        ?.split(" ")[0]
                                        ?.charAt(0)}
                                    {beneficiary_name &&
                                      beneficiary_name
                                        ?.split(" ")[1]
                                        ?.charAt(0)}
                                  </span>
                                </CheckUrlFragment>
                              </div>
                            )}
                            <div className="text-box">
                              {loadingBene ? (
                                <Skeleton width={100} height={20} />
                              ) : (
                                <p
                                  style={{ textTransform: "capitalize" }}
                                  className="value"
                                >
                                  {trimLongString(beneficiary_name, 30) || ""}
                                </p>
                              )}
                              {loadingBene ? (
                                <Skeleton width={200} height={10} />
                              ) : (
                                <p className="label grey-white-color">
                                  {`${bank_name || ""} • ${
                                    account_number || ""
                                  }`}
                                </p>
                              )}
                            </div>
                            {/* select input start */}
                            {loadingBene ? (
                              <Skeleton
                                width={42}
                                height={42}
                                style={{ borderRadius: "1rem" }}
                              />
                            ) : (
                              <div
                                onClick={() => {
                                  // getInputCountriesFromAllCurrencies(chi?.currency);
                                  setSingleChild(chi);
                                  setActiveSelect(
                                    capitalizeFirstLetter(
                                      chi?.beneficiary_account_type
                                    )
                                  );
                                  setPreviousObj(formatMetaData(chi?.payload));
                                  getCurrencyInputFunc(
                                    chi?.currency,
                                    formatMetaData(chi?.payload)
                                  );
                                  getInputCountriesFromAllCurrencies();
                                  actionCtx?.createRecipientGlobalTypeFunc(
                                    "bank"
                                  );
                                  actionCtx?.editRecipientGlobalFunc(true);
                                }}
                                className="delete-box border-theme white-black-bg"
                              >
                                <figure className="img-box">{editIcon}</figure>
                              </div>
                            )}
                            {/* select input end */}
                            {/* delete box start */}
                            {user_resource_and_permissions?.allowed_permissions?.includes(
                              "can_delete_beneficiary"
                            ) && (
                              <>
                                {loadingBene ? (
                                  <Skeleton
                                    width={42}
                                    height={42}
                                    style={{
                                      borderRadius: "1rem",
                                      marginLeft:
                                        size.width > 900 ? "1.5rem" : ".75rem",
                                    }}
                                  />
                                ) : (
                                  <div
                                    onClick={() => {
                                      setSingleChild(chi);
                                      setShowModal((prev) => {
                                        return { ...prev, delete_global: true };
                                      });
                                    }}
                                    style={{
                                      // borderRadius: "1rem",
                                      marginLeft: "1.5rem",
                                    }}
                                    className="delete-box border-theme white-black-bg"
                                  >
                                    <figure className="img-box">
                                      <img
                                        src={deleteIcon}
                                        alt=""
                                        className="img"
                                      />
                                    </figure>
                                  </div>
                                )}
                              </>
                            )}

                            {/* delete box end */}
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <>
                    {" "}
                    <div
                      // className="box-wrap grey-bg"
                      style={{
                        display: "grid",
                        placeItems: "center",
                        minHeight: "45rem",
                        borderRadius: "1.6rem",
                        padding: "1.5rem",
                      }}
                      className="grey-bg"
                    >
                      {" "}
                      <NoContentBox
                        loading={loadingBene}
                        title={`No Beneficiary for ${selectectedBox?.label} option`}
                        text={`All ${selectectedBox?.label} beneficiary added will appear here when saved`}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
          {/* index beneficiary global account  end */}
        </ContainerWrapper>
      </DashboardLayout>

      {/* delete device modal start */}
      <DeleteBeneficiaryGlobalModal
        visible={showModal?.delete_global}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, delete_global: false };
          });
        }}
        detail={singleChild}
      />
      {/* delete device modal end */}
      {actionCtx?.editRecipientGlobal &&
        location?.pathname === "/dashboard-global-account-beneficiary" && (
          <EditRecipientGlobalWrapIndex
            previousObj={previousObj}
            selectOptionList={selectOptionList}
            activeSelect={activeSelect}
            setActiveSelect={setActiveSelect}
            setOptionList={(e) => {
              handleChangeInputValue(e);
            }}
            inputObjList={inputObjList}
            //   detail={details}
            handleFinishFunc={(param) => {
              actionCtx?.editRecipientGlobalFunc(false);
              setPreviousObj({});
            }}
            singleChild={singleChild}
          />
        )}
    </>
  );
};

export default BeneficiaryGlobalAccount;
