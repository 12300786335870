import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import "../../../styles/dashboard/send-money/ConfirmTransactionPinModal.css";

const ConfirmTransactionPinModal = ({ visible, onClose }) => {
  return (
    <RavenModal
      visble={visible}
      effect={`fadeInDown`}
      className={`modal-transaction-main-wrap`}
      btnColor={`black-light`}
      btnLabel={`Pay`}
      onClose={onClose}
    >
      <div className="transaction-pin-wrap">
        <p className="title">Enter your transaction PIN</p>
        <p className="text grey-white-color">Enter your transaction PIN</p>

        <RavenInputField
          className={`form-pin`}
          color={`black-light`}
          type={`pin`}
        />
      </div>
    </RavenModal>
  );
};

export default ConfirmTransactionPinModal;
