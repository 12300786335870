import React from "react";
import NocontentPage from "../../../components/reusables/NocontentPage";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import noContentImg from "../../../assets/no-content-transaction.png";

const NoTransactionPage = () => {
  return (
    <DashboardLayout>
      <NocontentPage
        // loading={loading}
        img={noContentImg}
        type="two"
        title={`No Transactions yet`}
        normtext={`You haven’t made any transaction yet, make your first Transfer, and we would help you keep tab of all your transactions here.`}
      />
    </DashboardLayout>
  );
};

export default NoTransactionPage;
