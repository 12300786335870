import React from "react";
import "../../styles/reusables/StepBox.css";

const StepBox = ({
  steps,
  list,
  activeStep,
  styleName,
  onStep,
}) => {
  return (
    <div
      className={`step-box-wrap ${styleName}`}
      style={{ gridTemplateColumns: `repeat(${list?.length}, 1fr)` }}
    >
      {list &&
        list.map((chi, idx) => {
          return (
            <div
              key={idx}
              className={`box ${activeStep >= idx + 1 && "box-active"}`}
            >
              <div onClick={() => {onStep(idx + 1)}} className="num">
                <span>{idx + 1}</span>
              </div>
              <p className="text">{chi}</p>
            </div>
          );
        })}
    </div>
  );
};

export default StepBox;
