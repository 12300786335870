import { RavenButton, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useRef, useState } from "react";
import "../../../../../styles/reusables/RavenSignModal.css";
import SignatureCanvas from "react-signature-canvas";
import BadgeComponent from "../../../../../components/fragments/badge/BadgeComponent";

const SignRavenModal = ({ visible, onClose, onFinish }) => {
  const sigCanvas = useRef(null);
  const [imageURL, setImageURL] = useState("");
  const [btnEnable, setBtnEnable] = useState(false);

  // useEffect(() => {
  //   if (!visible && sigCanvas.current) {
  //     sigCanvas.current.clear(); // Clear the canvas whenever it becomes visible
  //   }
  // }, [visible]);

  function base64ToImageFile(base64, filename, type = "image/png") {
    const [prefix, base64String] = base64.split(",");
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type });
    const file = new File([blob], filename, { type });

    return file;
  }

  const clearImage = () => {
    setBtnEnable(false);
    sigCanvas.current.clear();
  };
  const save = () => {
    const img = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setImageURL(img);
    setBtnEnable(false);
    // onFinish(img);
    onFinish(base64ToImageFile(img, "example.png"))
    // onFinish(img);
  };

  //   function convertBase64ToSrc(base64Data, mimeType = 'image/png') {
  //     // Check if base64Data is not empty
  //     if (!base64Data) return null;

  //     // Combine the mimeType and base64Data to form a data URL
  //     const src = `data:${mimeType};base64,${base64Data}`;

  //     return src;
  //   }

  const infoIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4001 8.0001C14.4001 11.5347 11.5347 14.4001 8.0001 14.4001C4.46548 14.4001 1.6001 11.5347 1.6001 8.0001C1.6001 4.46548 4.46548 1.6001 8.0001 1.6001C11.5347 1.6001 14.4001 4.46548 14.4001 8.0001ZM8.8001 4.8001C8.8001 5.24193 8.44192 5.6001 8.0001 5.6001C7.55827 5.6001 7.2001 5.24193 7.2001 4.8001C7.2001 4.35827 7.55827 4.0001 8.0001 4.0001C8.44192 4.0001 8.8001 4.35827 8.8001 4.8001ZM7.2001 7.2001C6.75827 7.2001 6.4001 7.55827 6.4001 8.0001C6.4001 8.44193 6.75827 8.8001 7.2001 8.8001V11.2001C7.2001 11.6419 7.55827 12.0001 8.0001 12.0001H8.8001C9.24192 12.0001 9.6001 11.6419 9.6001 11.2001C9.6001 10.7583 9.24192 10.4001 8.8001 10.4001V8.0001C8.8001 7.55827 8.44192 7.2001 8.0001 7.2001H7.2001Z"
        fill="#EA872D"
      />
    </svg>
  );

  return (
    <>
      <RavenModal
        // disabled={true}
        visble={visible}
        onClose={() => {
          setImageURL("");
          setBtnEnable(false);
          onClose();
        }}
      >
        <div className="raven-sign-modal-wrap-box">
          <p className="sign-title">Sign here</p>
          <div className="badge-sign-panel-content-box border-theme grey-bg">
            <div className="sign-panel-content-box ">
              {visible && (
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="#020202"
                  canvasProps={{ className: "sigCanvas" }}
                  onBegin={() => {
                    // console.log("jhsg");
                    setBtnEnable(true);
                  }}
                />
              )}
            </div>
            <div className="badge-sign"> <BadgeComponent
              text={`Ensure the signature looks like the one on your ID`}
            >
              <figure className="img-box">{infoIcon}</figure>
            </BadgeComponent></div>
           
          </div>

          {/* btn box wrap  start */}
          <div className="btn-box-wrap">
            <RavenButton
              color="black-light"
              className="btn-outline border-theme white-black-bg black-white-color"
              label="Clear"
              onClick={clearImage}
            />
            <RavenButton
              disabled={!btnEnable}
              onClick={save}
              color="black-light"
              label="Submit"
            />
          </div>
          {/* btn box wrap  end */}
        </div>
      </RavenModal>
    </>
  );
};

export default SignRavenModal;
