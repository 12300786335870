import {
  RavenButton,
  RavenInputField,
  RavenModal,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ActionContext from "../../context/ActionContext";
import { clearCache, formatMetaData } from "../../utils/helper/Helper";
// import DeleteModal from "../../components/reusables/DeleteModal";
import { useSelector } from "react-redux";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import CryptoJS from "crypto-js";
import {
  validatePasswordLowercase,
  validatePasswordNumber,
  validatePasswordUpperCase,
} from "../../utils/helper/ValidateInput";
import { authenticateLogin, loginUser } from "../../redux/user";
import { useDispatch } from "react-redux";
import { setCookie } from "../../utils/CookiesFunction";

const SessionEndModal = ({ visible, onCancel, canRefresh }) => {
  // const [deviceIdVal, seDeviceIdVal] = useState("");
  const { loading, business } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlSave = location.pathname.split("/")[1];

  const handleClear = () => {
    // console.log(business);
    // console.log(actionCtx?.currentAccount);
    // return;
    actionCtx?.setIsLogout(false);
    clearCache();
    localStorage.clear();
    setCookie('g-rec-response', "", 0);
    window.location.replace(`/login?${urlSave}`);
    //   localStorage.clear()
  };

  const [deviceIdVal, seDeviceIdVal] = useState("");
  useEffect(() => {
    // Generate a fingerprint
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();

      // Use the fingerprint as the device identifier
      const deviceId = result.visitorId;

      // Store the device identifier in local storage
      // console.log(deviceId);
      seDeviceIdVal(deviceId);
    };

    getFingerprint();
  }, []);

  const [details, setDetails] = useState({
    email: "",
    password: "",
    pin: "",
  });

  const [error, setError] = useState({
    email: false,
    password: false,
    password_msg: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmitRefresh = async () => {
    if (!validatePasswordLowercase(details?.password)) {
      toast.error("Password should have at least 1 lowercase letter", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    if (!validatePasswordUpperCase(details?.password)) {
      toast.error("Password should have at least 1 capital letter", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    if (!validatePasswordNumber(details?.password)) {
      toast.error("Password should have at least  1 numeric character.", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }

    const objOne = {
      email: business?.email,
      password: details?.password,
      device_id: deviceIdVal,
    };
    const data = await dispatch(loginUser(objOne));
    if (data?.payload?.status === "success") {
      if (Number(data?.payload?.data?.direct_login) === 1) {
        const objTwo = {
          email: business?.email,
          password: details?.password,
          device_id: deviceIdVal,
          authentication_key: data?.payload?.data?.key_value,
          business_id: String(actionCtx?.currentAccount?.business_id),
        };
        // console.log(objTwo);
        // return
        handleSubmitDirect(objTwo);
      }
    }
  };

  const encryptTokenFunc = (tok) => {
    const token = tok;
    const secretKey =
      "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

    // Encrypt the token
    const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
    return encryptedToken;
  };

  const handleSubmitDirect = async (objDetails) => {
    // e.preventDefault();
    // return
    const data = await dispatch(authenticateLogin(objDetails));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      // localStorage.setItem(
      //   "baby-theme-face",
      //   encryptTokenFunc(details?.password)
      // );
      actionCtx?.setIsLogout(false);
      // return
      clearCache();
      const token = data?.payload?.data?.data?.token;
      localStorage?.setItem("token", encryptTokenFunc(token));
      const business = data?.payload?.data?.data?.business;
      const user = data?.payload?.data?.data?.user;
      localStorage?.setItem(
        "sidebar-theme-style",
        formatMetaData(user?.theme_display_settings)?.side_nav_style
      );
      actionCtx?.setPerPage({
        label: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
        value: `${formatMetaData(user?.theme_display_settings)?.table_size}`,
      });
      localStorage?.setItem(
        "theme",
        formatMetaData(user?.theme_display_settings)?.theme
      );
      localStorage.setItem("bvn_status", business?.bvn);
      actionCtx?.setIsLogout(false);
      localStorage?.removeItem("can-refresh");
      // window.location.reload();
      navigate("/dashboard-overview");
      setDetails({ email: "", password: "", pin: "" });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [useThis, setUseThis] = useState(true)

  if (useThis) {
    return (
      <RavenModal
        btnColor={`black-light`}
        onBtnClick={handleClear}
        visble={actionCtx?.isLogout}
        btnLabel="Back to login"
        onClose={() => {
          actionCtx?.setIsLogout(false);
        }}
        outerClose={true}
        dontShowClose={true}
      >
        <div
          style={{
            marginBottom: "1rem",
            marginTop: "1.5rem",
          }}
          className="session-expired-wrap"
          // style={{marginTop: ""}}
        >
          <p
            style={{
              fontWeight: "700",
              marginBottom: ".5rem",
              fontSize: "1.8rem",
            }}
            className="title"
          >
            Session Expired
          </p>
          <p className="text grey-white-color">
            We apologise for any inconvenience this may have caused, but we take
            your security very seriously. Please navigate back to our login page
            to re-enter your credentials and restart your session.
          </p>
        </div>
      </RavenModal>
    );
  }

  return (
    <RavenModal
      btnColor={`black-light`}
      onBtnClick={handleClear}
      visble={actionCtx?.isLogout}
      // btnLabel="Back to login"
      // onClose={() => {
      //   actionCtx?.setIsLogout(false);
      // }}
      
      outerClose={true}
      dontShowClose={true}
    >
      <div
        style={{
          marginBottom: "1rem",
          marginTop: "1.5rem",
        }}
        className="session-expired-wrap"
        // style={{marginTop: ""}}
      >
        <p
          style={{
            fontWeight: "700",
            marginBottom: ".5rem",
            fontSize: "1.8rem",
          }}
          className="title"
        >
          Session Expired
        </p>
        <p className="text grey-white-color">
          We apologise for any inconvenience this may have caused, but we take
          your security very seriously. Please navigate back to our login page
          to re-enter your credentials or input your password to resfresh your
          session.
        </p>
        <form
          style={{ width: "100%", margin: "2rem 0rem" }}
          autoComplete="off"
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <RavenInputField
            name="password"
            onChange={(e) => {
              handleChange(e);
              setError((prev) => {
                return { ...prev, password: false, password_msg: "" };
              });
            }}
            value={details?.password}
            showColor={`purple-light`}
            showError={error?.password}
            errorText={error?.password_msg}
            placeholder="E.g timi1828@1."
            color="black-light"
            type="password"
            label="Password *"
          />
        </form>
        {/* two button box start */}
        <div className="two-btn-box">
          <RavenButton
            size="small"
            label="Yes It's me, refresh"
            color="purple-light"
            disabled={!details?.password}
            onClick={handleSubmitRefresh}
            loading={loading}
          />
          <RavenButton
            onClick={handleClear}
            style={{ opacity: ".9" }}
            size="small"
            label="Back to login"
            color="black-light"
          />
        </div>
        {/* two button box end */}
      </div>
    </RavenModal>
  );
};

export default SessionEndModal;
