import React, { useContext, useEffect, useState } from "react";
// import { FaAngleRight } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import {
  formatDate,
  formatMetaData,
  formatNUmPan,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  getTotalPage,
  reactSelectStyle,
  trimLongString,
} from "../../../utils/helper/Helper";
import "../../../styles/dashboard/pos/DashboardPosIndex.css";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
// import { formatDate } from "../../../utils/Helpers";
import {
  RavenInputField,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import NoContentBox from "../../../components/reusables/NoContentBox";
// import debitIcon from "../../../assets/debit-table-icon.svg";
// import creditIcon from "../../../assets/income-table-icon.svg";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import {
  //   getTerminalinformations,
  getTerminalinformationSingle,
  getTerminals,
} from "../../../redux/pos";
// import moment from "moment";
import DownloadReceiptModal from "./modal/DetailTransactionModal";
import ViewSingleTopUPModal from "./modal/SingleTopUpModal";
// import GenerateStatementModal from "../settings/sections/modal/GenerateStatementModal";
import DeleteTerminalModal from "./modal/DeleteTerminalModal";
import ActionContext from "../../../context/ActionContext";
import GenerateStatementModalSingle from "./modal/GenerateSinglePosStatement";
import useDebounce from "../../../utils/helper/UseDebounce";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";
// import DownloadReceiptModal from "../transaction/DownloadReceiptModal";

const NewTerminalInfomation = () => {
  const dispatch = useDispatch();
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [typeFilter] = useState("");
  // const actionCtx = useContext(ActionContext)
  const { loading, terminalInfoSingle } = useSelector((state) => state.pos);
  // const
  const [singleObj, setSingleObj] = useState({});
  const [selectOptionVal, setSelectOptionVal] = useState({
    one: { label: "Today", value: "today" },
    two: "",
  });
  const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);
  const [sorceFilter] = useState("");
  const [showModal, setShowModal] = useState({
    disable: false,
    view: false,
    top_up: false,
    statement: false,
  });

  const navigate = useNavigate();

  const headerList = ["NARRATION", "AMOUNT", "SOURCE", " DATE", "STATUS"];

  const formatTypeWrap = (param, chi) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "0" && "status-type-wrap-pending"
        } ${param === "3" && "status-type-wrap-success"} ${
          chi?.direction === "credit" && "status-type-wrap-success"
        } ${param === "paid" && "status-type-wrap-success"} ${
          chi?.direction === "debit" && "status-type-wrap-fail"
        } ${param === "overdue" && "status-type-wrap-pending"} ${
          param === "1" && "status-type-wrap-pending"
        } ${param === "pending" && "status-type-wrap-pending"} ${
          chi?.direction === "credit" && "status-type-wrap-success"
        } ${param === "failed" && "status-type-wrap-disabled"} ${
          param === "failed" && "dull-bright-filter"
        } ${chi?.direction === null && "status-type-wrap-disabled"}`}
      >
        <span style={{ textTransform: "capitalize" }}>
          {param === "1"
            ? "Processing"
            : param === "0"
            ? "Pending"
            : param === "3"
            ? "Success"
            : param === "2"
            ? "Failed"
            : param === "11"
            ? "Reversal"
            : param === "failed"
            ? "Failed"
            : chi?.direction === null
            ? "Failed"
            : chi?.direction}
        </span>
      </div>
    );
  };

  const rowTypeText = (type, text, textTwo, chi) => {
    return (
      <div className="type-text-box">
        {loading ? (
          <>
            {" "}
            <Skeleton
              width={30}
              height={30}
              circle
              style={{ marginRight: "1rem" }}
            />{" "}
            <Skeleton width={200} height={17} />
          </>
        ) : (
          <>
            {" "}
            <div className="img-wrap">
              <figure
                className={`img-box-cre-deb ${
                  type === "debit" && "img-box-debit"
                } img-box-debit-credit ${chi?.status} ${
                  chi?.status === "failed" && "img-box-failed"
                } ${chi?.direction === null && "img-box-failed"}`}
              >
                {type === "credit" ? (
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M9 1L1 9M1 9H6.33333M1 9V3.66667"
                      stroke="#1ACE37"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img"
                  >
                    <path
                      d="M1 9L9 1M9 1H3.66667M9 1V6.33333"
                      stroke="#FF0F00"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </figure>
            </div>
            <div className=" text-box">
              <p className="text">{text || "---"}</p>
              <p className="text-small grey-white-color">
                {textTwo === "undefined" ? "---" : textTwo || "---"}
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  const transactionActions = (param) => {
    return (
      <div className="transaction-action-wrap">
        {loading ? (
          <>
            {/* <Skeleton width={35} height={35} circle /> */}
            <Skeleton width={35} height={35} circle />
          </>
        ) : (
          <>
            <figure
              onClick={() => {
                setShowModal((prev) => {
                  return { ...prev, view: true };
                });
                setSingleObj(param);
              }}
              key={param?.id}
              className="img-box grey-bg darken-action-bg tooltip-hover-wrap"
            >
              <RavenToolTip
                color={`black-light`}
                text={`View transaction details`}
                position={`left`}
              />
              <svg
                className="img dull-bright-filter"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.81509 9.53488C1.71295 9.37315 1.66188 9.29229 1.63329 9.16756C1.61182 9.07387 1.61182 8.92613 1.63329 8.83244C1.66188 8.70771 1.71295 8.62685 1.81509 8.46512C2.65915 7.12863 5.17155 3.75 9.0003 3.75C12.8291 3.75 15.3415 7.12863 16.1855 8.46512C16.2877 8.62685 16.3387 8.70771 16.3673 8.83244C16.3888 8.92613 16.3888 9.07387 16.3673 9.16756C16.3387 9.29229 16.2877 9.37315 16.1855 9.53488C15.3415 10.8714 12.8291 14.25 9.0003 14.25C5.17155 14.25 2.65915 10.8714 1.81509 9.53488Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.0003 11.25C10.2429 11.25 11.2503 10.2426 11.2503 9C11.2503 7.75736 10.2429 6.75 9.0003 6.75C7.75766 6.75 6.7503 7.75736 6.7503 9C6.7503 10.2426 7.75766 11.25 9.0003 11.25Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
          </>
        )}
      </div>
    );
  };
  const getTerminalFunc = async (obj) => {
    // console.log(obj);
    dispatch(getTerminalinformationSingle(obj));
  };
  const terminal_id = localStorage.getItem("pos-terminal-id");
  const [refreshTable, setRefreshTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    let val;
    if (filterVal?.name === "Specific Date") {
      const payload = {
        id: terminal_id,
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        search_term: search,
        specific: filterVal?.value,
      };
      val = payload;
    }
    if (filterVal?.name === "Custom Date") {
      const payload = {
        id: terminal_id,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        start_date: filterVal?.value?.split("_")[0],
        stop_date: filterVal?.value?.split("_")[1],
        search_term: search,
      };
      val = payload;
    }
    if (
      filterVal?.label === "1 Week" ||
      filterVal?.label === "2 Weeks" ||
      filterVal?.label === "3  Weeks" ||
      filterVal?.label === "1 Month" ||
      filterVal?.label === "2 Months" ||
      filterVal?.label === "3 Months"
    ) {
      const payload = {
        id: terminal_id,
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        range: filterVal?.value,
        search_term: search,
      };
      val = payload;
    }
    if (filterVal?.label === "Today") {
      const obj = {
        id: terminal_id,
        per_page: actionCtx?.perPageVal?.value,
        search_term: search,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
      };
      val = obj;
    }
    // console.log(val);
    getTerminalFunc(val);
    dispatch(getTerminals());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, currentPage, selectOptionVal?.two]);
  //   const getSingleTerminal = async () => {};

  const selectOption = [
    { label: "Today", value: "today" },
    { label: "1 Week", value: "1_weeek" },
    { label: "2 Weeks", value: "2_weeks" },
    { label: "3 Weeks", value: "3_weeks" },
    { label: "1 Month", value: "1_month" },
    { label: "2 Months", value: "2_months" },
    { label: "3 Months", value: "3_months" },
    { label: "Specific Date", value: "specific_date" },
    { label: "Custom", value: "custom" },
  ];
  //   const [perPage, setPerPage] = useState(10);
  const selectOptionTwo = [
    { label: "All Type", value: "" },
    { label: "Money Sent", value: "transfer_out" },
    { label: "Money Received", value: "transfer_in" },
    { label: "Card", value: "card" },
    { label: "Airtime", value: "airtime" },
    { label: "Data", value: "data" },
    { label: "Electricity", value: "electricity" },
  ];
  //   const [checkCustom, setCheckCustom] = useState(false);
  //   const [showFilterOption, setShowFilterOption] = useState(false);

  const [filterVal, setFilterVal] = useState({
    label: "Today",
    value: "today",
  });

  useEffect(() => {
    if (
      filterVal?.label === "1 Week" ||
      filterVal?.label === "2 Weeks" ||
      filterVal?.label === "3  Weeks" ||
      filterVal?.label === "1 Month" ||
      filterVal?.label === "2 Months" ||
      filterVal?.label === "3 Months"
    ) {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        range: filterVal?.value,
        search_term: search,
        id: terminal_id,
      };
      //   console.log(obj);
      //   return
      getTerminalFunc(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  useEffect(() => {
    if (filterVal?.label === "Today") {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        search_term: search,
        id: terminal_id,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
      };
      //   console.log(obj);
      getTerminalFunc(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal, currentPage]);

  useEffect(() => {
    if (filterVal?.name === "Specific Date") {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        type: selectOptionVal?.two?.value,
        specific: filterVal?.value,
        search_term: search,
        id: terminal_id,
      };
      //   console.log(obj);
      //   return
      getTerminalFunc(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  useEffect(() => {
    if (filterVal?.name === "Custom Date") {
      const obj = {
        start_date: filterVal?.value?.split("_")[0],
        stop_date: filterVal?.value?.split("_")[1],
        type: selectOptionVal?.two?.value,
        per_page: actionCtx?.perPageVal?.value,
        current_page: currentPage,
        id: terminal_id,
      };
      getTerminalFunc(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  //   const formatFilterDate = (obj) => {
  //     const dateOne = formatDate(obj?.split("_")[0])?.split("—")[0];
  //     const dateTwo = formatDate(obj?.split("_")[1])?.split("—")[0];
  //     const val = `${dateOne} to ${dateTwo}`;
  //     // console.log(val);
  //     return val;
  //   };
  const handleFinish = () => {
    setRefreshTable(!refreshTable);
    setShowModal({
      disable: false,
      view: false,
      top_up: false,
      statement: false,
    });
  };

  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      let val;
      if (filterVal?.name === "Specific Date") {
        const payload = {
          id: terminal_id,
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          search_term: search,
          specific: filterVal?.value,
        };
        val = payload;
      }
      if (filterVal?.name === "Custom Date") {
        const payload = {
          id: terminal_id,
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          start_date: filterVal?.value?.split("_")[0],
          stop_date: filterVal?.value?.split("_")[1],
          search_term: search,
        };
        val = payload;
      }
      if (
        filterVal?.label === "1 Week" ||
        filterVal?.label === "2 Weeks" ||
        filterVal?.label === "3  Weeks" ||
        filterVal?.label === "1 Month" ||
        filterVal?.label === "2 Months" ||
        filterVal?.label === "3 Months"
      ) {
        const payload = {
          id: terminal_id,
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          range: filterVal?.value,
          search_term: search,
        };
        val = payload;
      }
      // console.log(val);
      // return;
      getTerminalFunc(val);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      let val;
      if (filterVal?.name === "Specific Date") {
        const payload = {
          id: terminal_id,
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          specific: filterVal?.value,
          search_term: "",
        };
        val = payload;
      }
      if (filterVal?.name === "Custom Date") {
        const payload = {
          id: terminal_id,
          current_page: currentPage,
          type: selectOptionVal?.two?.value,
          start_date: filterVal?.value?.split("_")[0],
          stop_date: filterVal?.value?.split("_")[1],
          search_term: "",
          per_page: actionCtx?.perPageVal?.value,
        };
        val = payload;
      }
      if (
        filterVal?.label === "1 Week" ||
        filterVal?.label === "2 Weeks" ||
        filterVal?.label === "3 Weeks" ||
        filterVal?.label === "1 Month" ||
        filterVal?.label === "2 Months" ||
        filterVal?.label === "3 Months"
      ) {
        const payload = {
          id: terminal_id,
          per_page: actionCtx?.perPageVal?.value,
          current_page: currentPage,
          transaction_type: selectOptionVal?.two?.value,
          range: filterVal?.value,
          search_term: "",
        };
        val = payload;
      }
      // console.log(val);
      // return
      getTerminalFunc(val);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const testingSinglePosRef = useOnClickOutside(() => {
    setShowFilterOption(false);
  });

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Terminal information`}
          text={`All the information about your terminal displayed.`}
          back
          onBack={() => {
            navigate(-1);
          }}
          settingPosSingle={terminalInfoSingle?.terminalDetail?.status === 1}
          onPosTopUp={() => {
            setShowModal((prev) => {
              return { ...prev, top_up: true };
            });
          }}
          onGenerate={() => {
            setShowModal((prev) => {
              return { ...prev, statement: true };
            });
          }}
          onViewCashier={() => {
            setShowModal((prev) => {
              return { ...prev, disable: true };
            });
          }}
          btnPosText={
            terminalInfoSingle?.terminalDetail?.status === 0 &&
            `Terminal Disabled`
          }
        />
        {/* title box end */}
        {/* top detail card box start */}
        <div
          onClick={() => {
            //   actionCtx?.setCheckShow("close");
          }}
          className="top-pos-single-detail-card-box-new"
        >
          {/* box start */}
          <div className="box card-bg">
            <div className="title grey-white-color">
              Total Transaction Volume{" "}
            </div>
            {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
            <div className="value-box grey-bg">
              {loading ? (
                <Skeleton width={`20%`} height={20} />
              ) : (
                <p className="value black-white-color">
                  {formatNumWithCommaNairaSymbol(
                    terminalInfoSingle?.walletBalance || "0.00"
                  )}
                </p>
              )}
            </div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box card-bg">
            <div className="title grey-white-color">
              Cash In Today{" "}
              {/* <div className="title-total black-fade-white-bg">
                    <span>
                      {invoices?.total_paid_invoice || "0"}
                    </span>
                  </div> */}
            </div>
            {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
            <div className="value-box grey-bg">
              {loading ? (
                <Skeleton width={`20%`} height={20} />
              ) : (
                <p style={{ color: "#1ACE37" }} className="value">
                  {formatNumWithCommaNairaSymbol(
                    terminalInfoSingle?.checkIn || "0.00"
                  )}
                </p>
              )}
            </div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box card-bg">
            <div className="title grey-white-color">
              Cash Out Today{" "}
              {/* <div className="title-total black-fade-white-bg">
                    <span>
                      {invoices?.total_paid_invoice || "0"}
                    </span>
                  </div> */}
            </div>
            {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
            <div className="value-box grey-bg">
              {loading ? (
                <Skeleton width={`20%`} height={20} />
              ) : (
                <p style={{ color: "#FF0F00" }} className="value">
                  {formatNumWithCommaNairaSymbol(
                    terminalInfoSingle?.checkOut || "0.00"
                  )}
                </p>
              )}
            </div>
          </div>
          {/* box end */}
        </div>
        {/* top detail card box send */}
        <ContainerWrapper
          pagination={
            Number(terminalInfoSingle?.transactions?.total) >
              actionCtx?.perPageVal?.value &&
            !loading && (
              <RavenPagination
                color={`black-light`}
                nextPage={
                  currentPage !== terminalInfoSingle?.transactions?.last_page
                }
                prevPage={currentPage !== 1}
                blackHover
                onNext={(e) => {
                  setCurrentPage(currentPage + 1);
                  // console.log(currentPage + 1);
                }}
                onPrev={(e) => {
                  setCurrentPage(currentPage - 1);
                  // console.log(currentPage - 1);
                }}
                currentPage={currentPage}
                totalPage={
                  getTotalPage(
                    Number(terminalInfoSingle?.transactions?.per_page),
                    Number(terminalInfoSingle?.transactions?.total)
                  ) || 1
                }
                onNumView={(e) => {
                  setCurrentPage(e);
                }}
              />
            )
          }
          dontShowHeader
          width={`100%`}
        >
          {/* pos inde wrap start */}
          <div
            onClick={() => {
              // console.log(terminalInfoSingle);
              // console.log(status);
            }}
            className="dashboard-pos-index-wrap"
          >
            {/* containr start */}
            <div
              onClick={() => {
                actionCtx?.setCheckShow("close");
              }}
              className="terminal-info-table-wrap-box"
            >
              {/* {terminalInfoSingle?.transactions?.data?.length > 0 ? ( */}
              <>
                <div className="new-search-filter-chart-box">
                  <div className="search-filter-box">
                    {/* per page start */}
                    {/* <PerPageComponent /> */}
                    {/* per page end */}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      autoComplete="off"
                      action=""
                    >
                      <div className="search-group">
                        <RavenInputField
                          type={`search`}
                          color={`black-light`}
                          placeholder={`Search`}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            showFilterOption && setShowFilterOption(false);
                          }}
                        />
                      </div>
                    </form>
                    {/* filter-drop box start */}
                    <div ref={testingSinglePosRef} className="filter-drop-box">
                      {/* drop box start */}
                      {showFilterOption && (
                        <div className="drop-box-wrap  card-bg shadow-shadow-box-shadow">
                          <form autoComplete="off" action="">
                            <RavenInputField
                              selectOption={selectOption}
                              label={`Filter By Transaction date`}
                              color={`black-light`}
                              type={"select"}
                              placeholder={`Transaction date`}
                              selectStyles={reactSelectStyle}
                              value={
                                filterVal || {
                                  label: "Today",
                                  value: "Today",
                                }
                              }
                              onChange={(e) => {
                                if (
                                  e.label !== "Specific Date" &&
                                  e.label !== "Custom"
                                ) {
                                  setFilterVal(e);
                                }
                                if (e.label === "Specific Date") {
                                  setShowModal((prev) => {
                                    return {
                                      ...prev,
                                      selectSpecificDate: true,
                                    };
                                  });
                                }
                                if (e.label === "Custom") {
                                  setShowModal((prev) => {
                                    return { ...prev, customDate: true };
                                  });
                                }
                                setShowFilterOption(false);
                              }}
                              selectValue={selectOptionVal?.one}
                            />
                          </form>
                          <form autoComplete="off" action="">
                            <RavenInputField
                              selectOption={selectOptionTwo}
                              color={`black-light`}
                              type={"select"}
                              label={`Filter By Transaction type`}
                              placeholder={`Transaction type`}
                              selectStyles={reactSelectStyle}
                              value={selectOptionVal?.two}
                              onChange={(e) => {
                                setSelectOptionVal((prev) => {
                                  return { ...prev, two: e };
                                });
                                showFilterOption && setShowFilterOption(false);
                                // setCheckCustom(false);
                              }}
                              selectValue={typeFilter}
                            />
                          </form>
                        </div>
                      )}
                      {/* drop box end */}
                      {/* filter box start */}
                      <div
                        className="filter-box grey-bg"
                        onClick={() => {
                          setShowFilterOption(!showFilterOption);
                        }}
                      >
                        <p className="text grey-white-color-white">Filter</p>
                        <div className="line-box">
                          <span className=""></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                      {/* filter box end */}
                      {/* drop box start */}
                      <div className="drop-filter-wrap"></div>
                      {/* drop box end */}
                    </div>
                    {/* filter-drop box end */}
                  </div>
                  <div
                    onClick={() => {
                      setShowFilterOption(false);
                    }}
                    className="chart-hide-show-box"
                  ></div>
                </div>
                {/* pos inde wrap end */}
                {/* table contet syart */}
                <div className="table-content-pos">
                  {/* table contet end */}
                  {terminalInfoSingle?.transactions?.data?.length > 0 ? (
                    <>
                      <RavenTable
                        className={
                          formatMetaData(user?.theme_display_settings)
                            ?.table_font === "bold" && "table-business"
                        }
                        action
                        headerList={headerList}
                      >
                        {terminalInfoSingle?.transactions?.data?.map(
                          (chi, idx) => {
                            const {
                              amount,
                              created_at,
                              direction,
                              type,
                              t_id,
                              status,
                              narration,
                              meta_data,
                            } = chi;
                            return (
                              <RavenTableRow
                                loading={loading}
                                key={idx}
                                one={
                                  rowTypeText(
                                    direction,
                                    type === "card"
                                      ? "Card Payment"
                                      : chi?.type === "pdon_card_raven" ||
                                        chi?.type === "pdon_card"
                                      ? `BankBox Payment`
                                      : type === "transfer_out"
                                      ? formatMetaData(meta_data)?.account_name
                                      : type === "transfer_out - RVSL"
                                      ? "Transfer Out Reversal"
                                      : type === "transfer_out fee - RVSL"
                                      ? "Transfer Out Reversal Fee"
                                      : type === "transfer_in"
                                      ? formatMetaData(meta_data)?.source
                                          ?.first_name
                                      : type === "bet"
                                      ? `Bet Recharge`
                                      : type === "electricity"
                                      ? `Electricity Recharge`
                                      : type === "pos_cautionfee_rvsl"
                                      ? "Caution Fee reversal"
                                      : narration?.top_narration ||
                                        formatTypeFunction(type) ||
                                        "----",
                                    type === "card"
                                      ? formatNUmPan(
                                          formatMetaData(meta_data)?.card_pan
                                        )
                                      : chi?.type === "pdon_card_raven"
                                      ? formatNUmPan(
                                          formatMetaData(meta_data)?.card_pan
                                        )
                                      : type === "transfer_out"
                                      ? `${
                                          formatMetaData(meta_data)
                                            ?.account_number
                                        } • ${formatMetaData(meta_data)?.bank}`
                                      : type === "transfer_out - RVSL"
                                      ? "system reversed"
                                      : type === "pos_cautionfee_rvsl"
                                      ? "system reversed"
                                      : type === "transfer_in"
                                      ? `${
                                          formatMetaData(meta_data)?.source
                                            ?.account_number
                                        } • ${trimLongString(
                                          formatMetaData(meta_data)?.source
                                            ?.bank,
                                          16
                                        )}`
                                      : type === "bet"
                                      ? `${
                                          formatMetaData(meta_data)?.type
                                        } • ${trimLongString(
                                          formatMetaData(meta_data)?.name,
                                          16
                                        )}`
                                      : type === "electricity"
                                      ? `${
                                          formatMetaData(meta_data)?.meta
                                            ?.service ||
                                          formatMetaData(meta_data)?.provider ||
                                          "---"
                                        } • ${
                                          formatMetaData(meta_data)?.meta
                                            ?.meter_number ||
                                          formatMetaData(meta_data)?.meter_no ||
                                          "---"
                                        }`
                                      : type === "airtime"
                                      ? formatMetaData(meta_data)?.phone_number
                                      : type === "transfer_out fee - RVSL"
                                      ? "system reversed"
                                      : narration?.bottom_narration || "----",
                                    chi
                                  ) || "----"
                                }
                                four={formatDate(created_at) || "----"}
                                three={t_id || "---"}
                                two={
                                  formatNumWithCommaNairaSymbol(
                                    amount || "0.00"
                                  ) || "----"
                                }
                                onRowClick={() => {
                                  setSingleObj(chi);
                                  setShowModal((prev) => {
                                    return { ...prev, view: true };
                                  });
                                  // console.log(chi);
                                }}
                                five={formatTypeWrap(status, chi)}
                                action
                                dontShowAction
                                dontShowEdit
                                ManualAddActions={() => transactionActions(chi)}
                              />
                            );
                          }
                        )}
                      </RavenTable>
                      <div className="mobile-table-box">
                        {terminalInfoSingle?.transactions?.data?.map(
                          (chi, idx) => {
                            const { amount, created_at, direction, type } = chi;
                            return (
                              <MobileTableCard
                                key={idx}
                                amount={formatNumWithCommaNairaSymbol(amount)}
                                text={`${
                                  formatDate(created_at).split("—")[0] || ""
                                } • ${type}`}
                                onRowClick={() => {
                                  setSingleObj(chi);
                                  setShowModal((prev) => {
                                    return { ...prev, view: true };
                                  });
                                  //   console.log(chi);
                                }}
                                loading={loading}
                                title={type}
                                showImg
                                type={direction}
                              />
                            );
                          }
                        )}
                      </div>
                    </>
                  ) : (
                    <NoContentBox
                      title={
                        sorceFilter
                          ? `No Transaction Found for terminal ${sorceFilter?.label}`
                          : `No Transaction Found`
                      }
                      loading={loading}
                    />
                  )}
                  {/* table end */}
                </div>
              </>
            </div>
          </div>
        </ContainerWrapper>
        {/* container end */}
      </DashboardLayout>
      <DownloadReceiptModal
        visible={showModal?.view}
        onClose={() =>
          setShowModal((prev) => {
            return { ...prev, view: false };
          })
        }
        detail={singleObj}
      />
      {/* sdfghj */}
      {/* delete terminal modal start */}
      <ViewSingleTopUPModal
        visible={showModal?.top_up}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, top_up: false };
          });
        }}
      />
      {/* delete terminal modal end */}
      {/* genrate start */}
      <GenerateStatementModalSingle
        visible={showModal?.statement}
        detail={terminalInfoSingle?.terminalDetail}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, statement: false };
          });
        }}
      />
      {/* genrate end */}
      {/* delete terminal modal start */}
      <DeleteTerminalModal
        detail={terminalInfoSingle?.terminalDetail}
        visible={showModal?.disable}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, disable: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* delete terminal modal end */}
    </>
  );
};

export default NewTerminalInfomation;
