import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../../../styles/dashboard/schedule-payment/ViewScheduleModal.css";
import Flatpicker from "react-flatpickr";
import moment from "moment";
// import {} from "../../../../utils/helper/Helper";
// import { useDispatch, useSelector } from "react-redux";
//   import { reschedulePayment } from "../../../../redux/schedulePayment";

const SpecificDateSelectModal = ({
  param,
  visible,
  onClose,
  onCancel,
  onReschedule,
  // detail,
  setNewDate,
  onFinish,
}) => {
//   const [reschedule, setReschedule] = useState(false);
//   const { loading } = useSelector((state) => state.schedule);
  // const [fromDate, setFromDate] = useState("");
//   const dispatch = useDispatch();
  const [details, setDetails] = useState({
    date: "",
  });

  return (
    <RavenModal
      visble={visible}
      className="view-schedule-payment-modal"
      onClose={() => {
        // setReschedule(false);
        setDetails({ date: "" });
        onClose();
      }}
      btnColor={`black-light`}
      btnLabel={`Filter by selected date`}
      onBtnClick={() => {
        onFinish(details?.date);
      }}
      disabled={!details?.date}
    >
      <>
        <div
          style={{ paddingBottom: "unset" }}
          className="reschedule-payment-box"
        >
          <p className="title">Select Specific Date</p>
          <div className="underline"></div>
          <div className="wrap">
            <Flatpicker
              theme="dark"
              options={{ inline: true ,  maxDate: new Date()}}
              value={details.date}
              onChange={(date) => {
                setDetails((prev) => {
                  return {
                    ...prev,
                    date: moment(date[0]).format("YYYY-MM-DD"),
                  };
                });
                // setFromDate(moment(date[0]).format("YYYY-MM-DD HH:mm:ss"));
              }}
              className={`form-input input-calender`}
              autoComplete="off"
              //   placeholder={"Placeholder Here"}
            />
          </div>
        </div>
      </>
      {/* <div className="two-button-wrap">
          <RavenButton
            onClick={() => {
              setReschedule(!reschedule);
            }}
            style={{gridColumn:  detail?.status === 2 && `1 / -1`}}
            color={`black-light`}
            label={reschedule ? `Cancel` : `Reschedule`}
            //   style={{width: reschedule && "max-content"}}
            className={reschedule && `btn-outline-base-bg-black`}
            textColor={reschedule && `black-light`}
          />
          {detail?.status !== 2 && (
            <>
              {reschedule ? (
                <RavenButton
                  onClick={handleReschedule}
                  color={`black-light`}
                  label={`Apply`}
                  loading={loading}
                  // style={{width: reschedule && "max-content"}}
                />
              ) : (
                <RavenButton
                  onClick={onCancel}
                  color={`error-light`}
                  label={`Cancel Schedule`}
                />
              )}
            </>
          )}
        </div> */}
    </RavenModal>
  );
};

export default SpecificDateSelectModal;
