import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { removeUserFromRoleAndPermissions } from "../../../redux/roleAndPermission";
import "../../../styles/dashboard/pos/DashboardPosIndex.css";
import { lowerCaseWrap, reactSelectStyle } from "../../../utils/helper/Helper";

const ChangeMemberFromTeamModal = ({ visible, onClose, detail, onFinish }) => {
  const [selectValue, setSelectValue] = useState("");
  const dispatch = useDispatch()
  const { roles_and_users, loadingAction } = useSelector(
    (state) => state.role_and_permission
  );
  const frmatOption = (list) => {
    if (list?.length > 0) {
      const newList = roles_and_users?.map((chi) => {
        return {
          label: lowerCaseWrap(chi?.role_object?.name),
          value: chi?.role_object?.role_unique_name,
        };
      });
    //   console.log(roles_and_users);
    //   console.log(newList);
      return newList;
    }
  };

  const handleSubmit = async () => {
    const obj = {
      role: selectValue?.value,
      user_id: String(detail?.user_id),
    };
    // console.log(obj);
    // return
    const data = await dispatch(removeUserFromRoleAndPermissions(obj))
    if(data?.payload?.data?.status === "success"){
        onFinish()
    }
  };

  return (
    <RavenModal
      visble={visible}
      onClose={onClose}
      btnColor={`black-light`}
      btnLabel={`Change Role`}
      loading={loadingAction}
      disabled={!selectValue}
      onBtnClick={handleSubmit}
    >
      <div style={{ minHeight: "35rem" }} className="cashier-assign-modal-wrap">
        <p className="title">
          {`Change ${lowerCaseWrap(detail?.fname) || "---"} ${
            lowerCaseWrap(detail?.lname) || "---"
          } from `}
          <br /> {`${lowerCaseWrap(detail?.clearance || "---")} role?`}
        </p>
        <p className="text grey-white-color">
          You need to reassign this user to another role every user must be
          assigned to a role.
        </p>
        <div className="underline"></div>
        {/* form wrap start */}
        <div className="form-inputs-wrap">
          <form action="" autoComplete="off" style={{ width: "100%" }}>
            {" "}
            <RavenInputField
              color={`black-light`}
              type={`select`}
              label={`Assign a role* `}
              placeholder={`Dropdown Option`}
              selectOption={frmatOption(roles_and_users)}
            //   menuPlacement={`top`}
              onChange={(e) => {
                setSelectValue(e);
              }}
              value={selectValue || {label: lowerCaseWrap(detail?.clearance), value: detail?.clearance}}
              selectStyles={reactSelectStyle}
            />
          </form>
        </div>
        {/* form wrap end */}
      </div>
    </RavenModal>
  );
};

export default ChangeMemberFromTeamModal;
