import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBusinessProfile } from "../../redux/settings";
import { getUser } from "../../redux/user";
// import { validateUniqueEmail } from "../../../../redux/user";
import "../../styles/dashboard/pos/DashboardPosIndex.css";
import { removeCountryCode } from "../../utils/helper/Helper";
import { formatNumWithoutCommaNaira } from "../../utils/Helpers";
// import { reactSelectStyle } from "../../../../utils/helper/Helper";
// import { validateEmail } from "../../../utils/helper/ValidateInput";

const EditPhoneNumberModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.settings);
  //   const { user } = useSelector((state) => state.user);
  const [details, setDetails] = useState({
    phone_number: "",
  });

  //   useEffect(() => {
  //     // console.log(user);
  //     setDetails((prev) => {
  //       return {
  //         ...prev,
  //         first_name: user?.fname,
  //         last_name: user?.lname,
  //         email: user?.email,
  //       };
  //     });
  //   }, [user]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!validateEmail(details.email)) {
    //   toast.error("Invalid email address!", {
    //     position: "top-right",
    //   });
    //   setShowError((prev) => {
    //     return { ...prev, email: true };
    //   });
    //   return;
    // }
    const obj = {
      phone:
        details?.phone_number?.length === 10
          ? `0${formatNumWithoutCommaNaira(
              removeCountryCode(details?.phone_number)
            )}`
          : formatNumWithoutCommaNaira(details?.phone_number),
    };
    // console.log(obj);
    // return
    const data = await dispatch(updateBusinessProfile(obj));
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
      onClose();
      setDetails({
        phone_number: "",
      });
    }
  };

  return (
    <RavenModal
      visble={visible}
      onClose={onClose}
      btnColor={`black-light`}
      btnLabel={`Update Number`}
      loading={loading}
      disabled={!details?.phone_number}
      onBtnClick={handleSubmit}
    >
      <div className="cashier-assign-modal-wrap">
        <p className="title">Update Profile</p>
        <p className="text grey-white-color">
          Personalize your account details, update your business phone number
          information
        </p>
        <div className="underline"></div>
        {/* form wrap start */}
        <div style={{minHeight: "unset"}} className="form-inputs-wrap ">
          <RavenInputField
            color={`black-light`}
            type={`phone`}
            label={`Business Phone Number`}
            placeholder={`E.g 816263....`}
            onChange={handleChange}
            name={`phone_number`}
            value={removeCountryCode(details?.phone_number)}
          />
        </div>
        {/* form wrap end */}
      </div>
    </RavenModal>
  );
};

export default EditPhoneNumberModal;
