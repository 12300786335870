import { RavenButton } from "@ravenpay/raven-bank-ui";
import React from "react";

const InstructionContent = ({ setStep }) => {
  const instrucList = [
    "Referees must be a Raven Business account holder or any other commercial bank with a current account.",
    "Referees account must not be less than six months old.",
    "Referees account must not be a salary account.",
    "You should be very familiar with the account holder before agree to be their referees.",
  ];
  return (
    <div className="instruction-content-index-wrap border-theme card-bg">
      <div className="top-box grey-bg-two">
        <div className="img-wrap-content"></div>
        <p className="text grey-white-color">
          Referee’s Invitation from{" "}
          <span className="black-white-color">NotBlank Inc</span>
        </p>
      </div>
      {/* top end */}
      <div className="bottom-content">
        <p className="bottom-title">Read the instruction.</p>
        {/* list start */}
        <div className="list-instruc-box-wrap grey-bg">
          {instrucList?.map((chi, idx) => {
            return (
              <div key={idx} className="item-row">
                <div className="dot-box">
                  <div className="dot"></div>
                </div>
                <p className="text">{chi}</p>
              </div>
            );
          })}
        </div>
        {/* list end --- */}
        {/* btn box start */}
        <div className="btn-instruc-box">
          <RavenButton
            onClick={() => {
              setStep && setStep(2);
            }}
            color="black-light"
            label="Proceed"
          />
        </div>
        {/* btn box end */}
      </div>
    </div>
  );
};

export default InstructionContent;
