import {
  // RavenButton,
  RavenInputField,
  // RavenPagination,
  // RavenTable,
  // RavenTableRow,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
// import { FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import {
  createCashiers,
  getCashiers,
  getPosAdminInfo,
  // getPosAdminInfoTest,
  getPosDashboardInfo,
  getPosTerminalRequestStatus,
} from "../../../redux/pos";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../../styles/dashboard/pos/DashboardPosIndex.css";
import {
  filterEmptyColumns,
  formatDate,
  formatNumWithCommaNairaSymbol,
  formatStringPermissionToLowerCase,
  formatTypeFunction,
  getPosTerminalRequest,
  // getStartAndStopDates,
  reactSelectStyle,
  // trimLongString,
} from "../../../utils/helper/Helper";
import AssignCashierModal from "./modal/AssignCashierModal";
import DeleteCashierModal from "./modal/DeleteCashierModal";
import DeleteTerminalModal from "./modal/DeleteTerminalModal";
import ActionContext from "../../../context/ActionContext";
import TransactionPinModal from "../../../components/reusables/TransactionPinModal";
// import MobileTableCard from "../../../components/reusables/MobileTableCard";
import noContentImg from "../../../assets/no-pos-content-img.png";
import NocontentPage from "../../../components/reusables/NocontentPage";
import BlurWrap from "../../../components/fragments/BlurWrap";
import TransactionDetailModal from "./modal/TransactiondetailModal";
// import walletIcon from "../../../assets/wallet-bal-icon.svg";
// import cashInIcon from "../../../assets/cash-in-icon.svg";
// import cashOutIcon from "../../../assets/cash-out-icon.svg";
import ViewCashierModal from "./modal/ViewCashierModal";
// import TopUpCardModal from "../card/modals/TopUPCardModal";
import ViewTopUPModal from "./modal/ViewTopUPModal";
import GenerateStatementModal from "./modal/GenerateAllPosStatement";
import SpecificDateSelectModal from "./modal/SpecificDateSelectModal";
// import moment from "moment";
import CustomDateSelectModal from "./modal/CustomDateSelectModal";
import SettlementSettingModal from "./modal/SettlementSettingModal";
import { fetchBanks } from "../../../redux/transaction";
// import GenerateStatementModal from "../settings/sections/modal/GenerateStatementModal";

const DashboardPosIndexNew = () => {
  const [singleChild, setSingleChild] = useState({});
  const actionCtx = useContext(ActionContext);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showModal, setShowModal] = useState({
    assign_cashier: false,
    delete_cashier: false,
    delete_terminal: false,
    view: false,
    view_cashier: false,
    top_up: false,
    statement: false,
    selectSpecificDate: false,
    customDate: false,
    settle: false,
  });
  const [details, setDetails] = useState({
    cashier_phone: "",
    cashier_name: "",
    cashier_pin: "",
    pin: "1111",
  });
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const { pos_dashboard, loading, cashiers, loadingSettlement, status } =
    useSelector((state) => state.pos);
  // const [copyItem, setCopyItem] = useState("");
  const [urlBack, setUrlBack] = useState("");
  //   const [preview, setPreview] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [requestStatus, setRequestStatus] = useState("");
  useState(() => {
    const url = window?.location?.href?.split("?")[1];
    if (url) {
      setUrlBack(url);
    }
  }, []);

  // const handleCopy = async () => {
  //   setCopyItem(pos_dashboard?.accountDetail?.account_number);
  //   await navigator.clipboard.writeText(
  //     pos_dashboard?.accountDetail?.account_number
  //   );
  //   setTimeout(() => {
  //     setCopyItem("");
  //   }, 1000);
  // };

  const todayDate = new Date();

  // Subtract one day from the current date
  const yearDate = todayDate.getFullYear();
  const monthDate = todayDate.getMonth() + 1; // Months are zero-indexed, so we add 1
  const dayDate = todayDate.getDate();

  // Format the date as a string in the format "YYYY-MM-DD"
  const formattedDateToday = `${yearDate}-${
    monthDate < 10 ? "0" : ""
  }${monthDate}-${dayDate < 10 ? "0" : ""}${dayDate}`;

  const mobileIcon = (
    <svg
      className="img dull-bright-filter"
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="dull-bright-filter" clip-ath="url(#clip0_16108_38778)">
        <path
          d="M6.29336 7.86761H6.29781M4.5999 9.87302H7.98681C8.48599 9.87302 8.73557 9.87302 8.92623 9.77588C9.09394 9.69042 9.23029 9.55407 9.31574 9.38637C9.41288 9.19571 9.41288 8.94612 9.41288 8.44695V2.38615C9.41288 1.88698 9.41288 1.6374 9.31574 1.44674C9.23029 1.27903 9.09394 1.14268 8.92623 1.05723C8.73557 0.960083 8.48599 0.960083 7.98681 0.960083H4.5999C4.10073 0.960083 3.85114 0.960083 3.66048 1.05723C3.49278 1.14268 3.35642 1.27903 3.27097 1.44674C3.17383 1.6374 3.17383 1.88698 3.17383 2.38615V8.44695C3.17383 8.94612 3.17383 9.19571 3.27097 9.38637C3.35642 9.55407 3.49278 9.69042 3.66048 9.77588C3.85114 9.87302 4.10073 9.87302 4.5999 9.87302ZM6.51618 7.86761C6.51618 7.99067 6.41642 8.09043 6.29336 8.09043C6.17029 8.09043 6.07053 7.99067 6.07053 7.86761C6.07053 7.74455 6.17029 7.64479 6.29336 7.64479C6.41642 7.64479 6.51618 7.74455 6.51618 7.86761Z"
          // stroke=""
          // className="black-white-color"
          strokeWidth="1.00271"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16108_38778">
          <rect
            width="10.6955"
            height="10.6955"
            fill="white"
            transform="translate(0.945312 0.0688477)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  useEffect(() => {
    dispatch(fetchBanks());
    dispatch(getPosAdminInfo());

    dispatch(getCashiers());
    dispatch(getPosTerminalRequestStatus());
    // dispatch(getPosAdminInfoTest());
    const getDashboardInfo = async () => {
      const obj = {
        start_date: formattedDateToday,
        end_date: formattedDateToday,
      };
      // setTimeout(() => {
      //   setLoadingPage(false);
      // }, 10000);
      const data = await dispatch(getPosDashboardInfo(obj));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        setLoadingPage(false);
        // setTimeout(() => {
        //   setLoadingPage(false);
        // }, 5000);
      } else {
        setLoadingPage(false);
      }
    };
    getDashboardInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRequestStatus(getPosTerminalRequest(status));
  }, [status]);

  // const formatTypeWrap = (param) => {
  //   return (
  //     <div
  //       className={`status-type-wrap ${
  //         param === "Disabled" &&
  //         "status-type-wrap-disabled status-type-wrap-action-two"
  //       } ${param !== "Disabled" && "status-type-wrap-success"}`}
  //     >
  //       <span>{param}</span>
  //     </div>
  //   );
  // };

  const purpleNavIcon = (
    <svg
      className="img"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.07422" cy="8" r="8" fill="#F0EEFF" />
      <g clipPath="url(#clip0_15985_77757)">
        <path
          d="M8.07438 12.1666C10.3756 12.1666 12.241 10.3012 12.241 7.99998C12.241 5.69879 10.3756 3.83331 8.07438 3.83331C5.7732 3.83331 3.90771 5.69879 3.90771 7.99998C3.90771 10.3012 5.7732 12.1666 8.07438 12.1666Z"
          fill="#755AE2"
        />
        <path
          d="M8.07438 9.66665L9.74105 7.99998L8.07438 6.33331"
          fill="#755AE2"
        />
        <path
          d="M8.07438 9.66665L9.74105 7.99998M9.74105 7.99998L8.07438 6.33331M9.74105 7.99998H6.40771M12.241 7.99998C12.241 10.3012 10.3756 12.1666 8.07438 12.1666C5.7732 12.1666 3.90771 10.3012 3.90771 7.99998C3.90771 5.69879 5.7732 3.83331 8.07438 3.83331C10.3756 3.83331 12.241 5.69879 12.241 7.99998Z"
          stroke="#F0EEFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15985_77757">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(3.07422 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const handleSubmitDetails = async (pin, token) => {
    const obj = {
      ...details,
      pin: pin,
      two_factor_token: token,
    };
    // console.log(obj);
    // return
    const data = await dispatch(createCashiers(filterEmptyColumns(obj)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
      setDetails({
        cashier_phone: "",
        cashier_name: "",
        cashier_pin: "",
      });
    }
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        assign_cashier: false,
        delete_cashier: false,
        delete_terminal: false,
        view_cashier: false,
        view: false,
        settle: false,
      };
    });
    dispatch(getCashiers());
  };

  const noContentList = [
    "Accurate transaction processing, improving customer satisfaction and reducing errors.",
    "flexibility in payment options, allowing customers to pay with their preferred method.",
    "Enhanced security by reducing the risk of fraud and theft",
  ];

  const today = new Date();

  // Subtract one day from the current date
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const year = yesterday.getFullYear();
  const month = yesterday.getMonth() + 1; // Months are zero-indexed, so we add 1
  const day = yesterday.getDate();

  // Format the date as a string in the format "YYYY-MM-DD"
  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;

  const filterOptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: formattedDate },
    { label: "7 days", value: "1_weeek" },
    { label: "14 days", value: "2_weeks" },
    // { label: "3 Weeks", value: "3_weeks" },
    { label: "1 Month", value: "1_month" },
    // { label: "2 Months", value: "2_months" },
    { label: "3 Months", value: "3_months" },
    { label: "Specific Date", value: "specific_date" },
    { label: "Custom", value: "custom" },
  ];

  const [filterVal, setFilterVal] = useState({
    label: "Today",
    value: "today",
  });

  const handleFilterDasboardInfo = (obj) => {
    dispatch(getPosDashboardInfo(obj));
  };

  useEffect(() => {
    if (
      filterVal?.label === "7 days" ||
      filterVal?.label === "14 days" ||
      filterVal?.label === "3  Weeks" ||
      filterVal?.label === "1 Month" ||
      filterVal?.label === "2 Months" ||
      filterVal?.label === "3 Months"
    ) {
      const obj = {
        range: filterVal?.value,
      };

      handleFilterDasboardInfo(obj);
    }
    if (filterVal?.name === "Specific Date") {
      const obj = {
        specific: filterVal?.value,
      };
      handleFilterDasboardInfo(obj);
    }
    if (filterVal?.label === "Yesterday") {
      const obj = {
        specific: filterVal?.value,
      };
      handleFilterDasboardInfo(obj);
    }
    if (filterVal?.name === "Custom Date") {
      const obj = {
        start_date: filterVal?.value?.split("_")[0],
        end_date: filterVal?.value?.split("_")[1],
      };
      handleFilterDasboardInfo(obj);
    }
    if (filterVal?.value === "today") {
      const obj = {
        start_date: formattedDateToday,
        end_date: formattedDateToday,
      };
      handleFilterDasboardInfo(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  // useEffect(() => {
  //   if (filterVal?.name === "Specific Date") {
  //     const obj = {
  //       specific: filterVal?.value,
  //     };
  //     handleFilterDasboardInfo(obj);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterVal]);

  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterVal]);

  // useEffect(() => {
  //   if (filterVal?.name === "Custom Date") {
  //     const obj = {
  //       start_date: filterVal?.value?.split("_")[0],
  //       end_date: filterVal?.value?.split("_")[1],
  //     };
  //     handleFilterDasboardInfo(obj);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterVal]);

  const formatFilterDate = (obj) => {
    const dateOne = formatDate(obj?.split("_")[0])?.split("—")[0];
    const dateTwo = formatDate(obj?.split("_")[1])?.split("—")[0];
    const val = `${dateOne} to ${dateTwo}`;
    // console.log(val);
    return val;
  };

  return (
    <>
      <DashboardLayout>
        {pos_dashboard?.terminals?.length > 0 ? (
          <>
            {/* title box start */}
            <TitletextBox
              title={`BankBox`}
              text={`Manage all your BankBox in one place`}
              back={urlBack}
              onBack={() => {
                navigate(`/${urlBack}`);
              }}
              onViewCashier={() => {
                setShowModal((prev) => {
                  return { ...prev, view_cashier: true };
                });
              }}
              onRequestTerminal={() => {
                navigate("/dashboard-pos-management-request-pos");
              }}
              // addBtn={requestStatus === 3}
              showTerminalBtn={requestStatus !== 3 && status?.length > 0}
              // terminalStatus={requestStatus }
              // onShowTerminalStatus={() => {
              //   // console.log("uy");
              //   actionCtx?.setCheckShow("six");
              // }}
              onGenerate={() => {
                setShowModal((prev) => {
                  return { ...prev, statement: true };
                });
              }}
              viewPosTransaction={
                user_resource_and_permissions?.allowed_permissions?.includes(
                  "can_view_terminal_transactions"
                ) && `View Transactions`
              }
              settingPos={cashiers}
              onSettlementPos={() => {
                setShowModal((prev) => {
                  return { ...prev, settle: true };
                });
              }}
              onCashierDelete={(obj) => {
                setSingleChild(obj);
                setShowModal((prev) => {
                  return { ...prev, delete_cashier: true };
                });
              }}
              onAddCashier={() => {
                setShowModal((prev) => {
                  return { ...prev, assign_cashier: true };
                });
              }}
              onPosTopUp={() => {
                setShowModal((prev) => {
                  return { ...prev, top_up: true };
                });
              }}
            />
            {/* title box end */}
            {/* top detail card box start */}
            <div
              onClick={() => {
                actionCtx?.setCheckShow("close");
              }}
              className="top-pos-single-detail-card-box-new"
            >
              {/* box start */}
              <div className="box card-bg">
                <div className="title grey-white-color">
                  Wallet Balance{" "}
                  <div
                    onClick={() => {
                      navigate("/dashboard-pos-management-withdraw");
                    }}
                    className="bottom-text-action"
                  >
                    <p className="text">Withdraw Balance</p>
                    <figure className="icon">{purpleNavIcon}</figure>
                    {/* <FaAngleRight className="icon" /> */}
                  </div>
                </div>
                {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p className="value black-white-color">
                      {formatNumWithCommaNairaSymbol(
                        pos_dashboard?.walletBalance || "0.00"
                      )}
                    </p>
                  )}
                </div>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box card-bg">
                <div className="title grey-white-color">
                  Cash In {filterVal?.label}{" "}
                  {/* <div className="title-total black-fade-white-bg">
                    <span>
                      {invoices?.total_paid_invoice || "0"}
                    </span>
                  </div> */}
                </div>
                {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p style={{ color: "#1ACE37" }} className="value">
                      {formatNumWithCommaNairaSymbol(
                        pos_dashboard?.checkIn || "0.00"
                      )}
                    </p>
                  )}
                </div>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box card-bg">
                <div className="title grey-white-color">
                  Cash Out {filterVal?.label}
                </div>
                {/* <p className="title grey-white-color">Total Paid Invoice</p> */}
                <div className="value-box grey-bg">
                  {loading ? (
                    <Skeleton width={`20%`} height={20} />
                  ) : (
                    <p style={{ color: "#FF0F00" }} className="value">
                      {formatNumWithCommaNairaSymbol(
                        pos_dashboard?.checkOut || "0.00"
                      )}
                    </p>
                  )}
                </div>
              </div>
              {/* box end */}
            </div>
            {/* top detail card box send */}

            {/* bottom content box start ------------- */}
            <ContainerWrapper
              // pagination={
              //   <RavenPagination
              //     color={`black-light`}
              //     blackHover
              //     currentPage={1}
              //     totalPage={7}
              //   />
              // }
              width={`100%`}
              dontShowHeader
            >
              {/* pos inde wrap start */}
              <div
                onClick={() => {
                  // console.log(pos_dashboard);
                  // console.log(status);
                }}
                className="dashboard-pos-index-wrap"
              >
                {pos_dashboard?.terminals?.length > 0 ? (
                  <>
                    {" "}
                    <div
                      onClick={() => {
                        actionCtx?.setCheckShow("close");
                      }}
                      className="bottom-content-box-pos-index-new"
                    >
                      <div className="content-title">
                        {" "}
                        <p className="title">ALL TERMINALS</p>{" "}
                        {/* text select start */}
                        <div className="text-select-box">
                          <p className="text grey-white-color">
                            Filter by date
                          </p>
                          {/* select start */}
                          <form autoComplete="off" action="">
                            <RavenInputField
                              className="pos-select-wrap"
                              type={`select`}
                              value={
                                filterVal || { label: "Today", value: "Today" }
                              }
                              // selectMenuOpen
                              selectStyles={reactSelectStyle}
                              color={"black-light"}
                              selectOption={filterOptions}
                              onChange={(e) => {
                                if (
                                  e.label !== "Specific Date" &&
                                  e.label !== "Custom"
                                ) {
                                  setFilterVal(e);
                                }
                                if (e.label === "Specific Date") {
                                  setShowModal((prev) => {
                                    return {
                                      ...prev,
                                      selectSpecificDate: true,
                                    };
                                  });
                                }
                                if (e.label === "Custom") {
                                  setShowModal((prev) => {
                                    return { ...prev, customDate: true };
                                  });
                                }
                                // handleFilterDasboardInfo(e.value);
                              }}
                            />
                          </form>
                          {/* select nd */}
                        </div>
                        {/* text select end */}
                      </div>
                      {/* terminals show box start */}
                      <div className="terminal-show-box">
                        {pos_dashboard?.terminals?.map((chi, idx) => {
                          const {
                            tid_label,
                            status,
                            serial,
                            income,
                            expenses,
                            type,
                          } = chi;
                          return (
                            <div
                              key={idx || tid_label}
                              className="new-terminal-child-box grey-bg border-theme"
                              onClick={() => {
                                if (
                                  user_resource_and_permissions?.allowed_permissions?.includes(
                                    "can_view_terminal_transactions"
                                  )
                                ) {
                                  localStorage.setItem(
                                    "pos-terminal-id",
                                    chi?.tid
                                  );
                                  navigate(
                                    "/dashboard-pos-terminal-information"
                                  );
                                }
                              }}
                            >
                              {/* label and serial no box start */}
                              <div className="label-serial-no-box">
                                {loading ? (
                                  <Skeleton width={90} height={25} />
                                ) : (
                                  <p className="label">
                                    {formatTypeFunction(tid_label) || "---"}
                                  </p>
                                )}
                                {loading ? (
                                  <Skeleton width={140} height={13} />
                                ) : (
                                  <p className="serial-no grey-white-color-white">
                                    Serial No: {serial || "---"}
                                  </p>
                                )}
                              </div>
                              {/* label and serial no box end */}
                              {/* income and expense wrap start */}
                              <div className="income-expense-wrap card-bg shadow-shadow-box-shadow">
                                {/* incomw and exp box start */}
                                <div className="income-expense-box">
                                  <div className="box income-box border-theme-right">
                                    <p className="label income">Income</p>
                                    {loading ? (
                                      <Skeleton width={`100%`} height={20} />
                                    ) : (
                                      <p className="value">
                                        {formatNumWithCommaNairaSymbol(
                                          income || 0
                                        )}
                                      </p>
                                    )}
                                  </div>
                                  <div className="box">
                                    <p className="label expense">Expense</p>
                                    {loading ? (
                                      <Skeleton width={`100%`} height={20} />
                                    ) : (
                                      <p className="value">
                                        {formatNumWithCommaNairaSymbol(
                                          expenses || 0
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {/* incomw and exp box end */}
                                {/* status type wrap start */}

                                <div className="status-type-wrap">
                                  {loading ? (
                                    <Skeleton
                                      width={100}
                                      height={28}
                                      borderRadius=".4rem"
                                    />
                                  ) : (
                                    <div className="box grey-bg">
                                      <div className="img-wrap">
                                        <figure className="img-box">
                                          {mobileIcon}
                                        </figure>
                                      </div>
                                      <p className="text">
                                        {type === "poseidon"
                                          ? "BankBox"
                                          : "Android Screen"}
                                      </p>
                                    </div>
                                  )}
                                  {loading ? (
                                    <Skeleton
                                      width={100}
                                      height={28}
                                      borderRadius=".4rem"
                                    />
                                  ) : (
                                    <div className="box grey-bg">
                                      <p className="text">
                                        {status === 1 ? "Active" : "Disabled"}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {/* status type wrap end */}
                              </div>
                              {/* income and expense wrap end */}
                            </div>
                          );
                        })}
                        {/* terminal box start */}

                        {/* terminal box end */}
                      </div>
                      {/* terminals show box end */}
                    </div>
                  </>
                ) : (
                  <NoContentBox
                    loading={loading}
                    title={`No Bankbox yet`}
                    text={`Click the button below to request a Bankbox`}
                    btnText={`Request Bankbox`}
                  />
                )}
              </div>
              {/* pos inde wrap end */}
            </ContainerWrapper>
            {/* bottom content box end ------------- */}
          </>
        ) : (
          <NocontentPage
            title={`Scale your business with a BankBox.🚀`}
            list={noContentList}
            img={noContentImg}
            loading={loadingPage}
            btntext={
              formatStringPermissionToLowerCase(user_resource_and_permissions?.allowed_permissions)?.includes(
                "can_request_bankbox"
              )
                && `Request for a BankBox`
            }
            onBtnClick={() => {
              navigate("/dashboard-pos-management-request-pos");
            }}
          />
        )}
      </DashboardLayout>
      {/* cashier modal start */}
      <AssignCashierModal
        visible={showModal?.assign_cashier}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, assign_cashier: false };
          });
        }}
        onFinish={(obj) => {
          setDetails((prev) => {
            return { ...prev, ...obj };
          });
          setShowModal((prev) => {
            return { ...prev, pin: true, assign_cashier: false };
          });
        }}
      />
      {/* cashier modal end */}
      {/* delete cashier modal start */}
      <DeleteCashierModal
        visible={showModal?.delete_cashier}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, delete_cashier: false };
          });
        }}
        detail={singleChild}
        onFinish={handleFinish}
      />
      {/* delete cashier modal end */}
      {/* delete terminal modal start */}
      <DeleteTerminalModal
        visible={showModal?.delete_terminal}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, delete_terminal: false };
          });
        }}
      />
      {/* delete terminal modal end */}
      {/* delete terminal modal start */}
      <TransactionDetailModal
        visible={showModal?.view}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view: false };
          });
        }}
        detail={singleChild}
      />
      {/* delete terminal modal end */}
      {/* delete terminal modal start */}
      <ViewCashierModal
        visible={showModal?.view_cashier}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view_cashier: false };
          });
        }}
        onCashierDelete={(obj) => {
          setSingleChild(obj);
          setShowModal((prev) => {
            return { ...prev, delete_cashier: true, view_cashier: false };
          });
        }}
        settingPos={cashiers}
        onAdd={() => {
          setShowModal((prev) => {
            return { ...prev, assign_cashier: true, view_cashier: false };
          });
        }}
      />
      {/* delete terminal modal end */}
      {/* delete terminal modal start */}
      <ViewTopUPModal
        visible={showModal?.top_up}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, top_up: false };
          });
        }}
      />
      {/* delete terminal modal end */}
      {/* genrate start */}
      <GenerateStatementModal
        visible={showModal?.statement}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, statement: false };
          });
        }}
      />
      {/* genrate end */}
      {/* specific date start  */}
      <SpecificDateSelectModal
        visible={showModal?.selectSpecificDate}
        onFinish={(e) => {
          // console.log(e);
          setFilterVal({
            label: formatDate(e)?.split("—")[0],
            value: e,
            name: "Specific Date",
          });
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
      />
      {/* specific date end */}
      {/* settlement settings modal start */}
      <SettlementSettingModal
        visible={showModal?.settle}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, settle: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* settlement settings modal end */}
      {/* Custom date start */}
      <CustomDateSelectModal
        visible={showModal?.customDate}
        onFinish={(e) => {
          formatFilterDate(e);
          setFilterVal({
            label: formatFilterDate(e),
            value: e,
            name: "Custom Date",
          });
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
      />
      {/* custom date end */}
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, pin: e };
          });
        }}
        onPay={(pin, token) => {
          handleSubmitDetails(pin, token);
        }}
        value={details?.pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false, assign_cashier: true };
          });
        }}
        loading={loadingSettlement}
      />
      {/* pin modal end */}
      <BlurWrap />
    </>
  );
};

export default DashboardPosIndexNew;
