import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import bankIcon from "../../../assets/add-money-bank-transfer.svg";
import cardIcon from "../../../assets/add-money-card-icon.svg";
import LockBox from "../../../components/fragments/LockBox";
import "../../../styles/dashboard/add-money/AddMoney.css";
// import copyIcon from "../../../assets/add-money-copy-icon.svg";
import { FaCheck } from "react-icons/fa";
// import { useDarkMode } from "../../../utils/themes/useDarkMode";
import { useSelector } from "react-redux";
import ActionContext from "../../../context/ActionContext";
import AccountDropDownBox from "../../../components/fragments/AccountDropDownBox";
import { trimLongString } from "../../../utils/helper/Helper";
import { RavenToolTip } from "@ravenpay/raven-bank-ui";
import NoContentBox from "../../../components/reusables/NoContentBox";
import useWindowSize from "../../../utils/helper/UseWindowSize";
// import { validateRavenUserName } from "../../../redux/payroll";

const AddMoney = () => {
  const [urlBack, setUrlBack] = useState("");
  const [activeSelect, setActiveSelect] = useState("Bank Transfers");
  const navigate = useNavigate();
  const actionCtx = useContext(ActionContext);
  const [isCopied, setIsCopied] = useState(false);
  // const [ theme ] = useDarkMode(localStorage.getItem("theme"));

  const { all_account } = useSelector((state) => state.user);

  const [bankDetails] = useState([
    {
      title: "Bank Transfers",
      text: "Copy the account number and make a transfer",
      icon: bankIcon,
      // bank_name: `Guaranteed Trust Bank (GTBank)`,
      // acc_num: `0121559651`,
      // acc_name: `Olakunle Temitayo Abraham`,
    },
    {
      title: "Debit /Credit Card",
      text: "Top up your account with your card",
      icon: cardIcon,
      coming_soon: true,
    },
    {
      title: "Direct debit",
      text: "Do a direct debit from your linked accounts.",
      icon: cardIcon,
      coming_soon: true,
    },
    {
      title: "USSD",
      text: "Top up with USSD",
      icon: cardIcon,
      coming_soon: true,
    },
  ]);

  useState(() => {
    const url = window?.location?.href?.split("?")[1];
    if (url) {
      setUrlBack(url);
    }
  }, []);

  const handleCopied = async (num) => {
    setIsCopied(true);
    await navigator.clipboard.writeText(num);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  const size = useWindowSize();
  const [showAccountDrop, setShowAccountDrop] = useState(false);
  const dropDownIcon = (
    <svg
      className="img"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.2423 6.0083L8.24774 10.0137L12.2532 6.0083"
        stroke="rgba(117, 90, 226, 1)"
        strokeWidth="1.20163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const rightArrowIcon = (
    <svg
      className="img"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M6 9.5L12 15.5L18 9.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  if (all_account?.length < 1) {
    return (
      <DashboardLayout>
        <TitletextBox
          title={`Add Money`}
          text={`Top up your Raven account with multiple methods`}
        />
        {/* title box end */}
        <ContainerWrapper dontShowHeader>
          <NoContentBox
            title={`No permission to view this page`}
            text={`You will be able to view this screen when your role has been added to an account`}
            bgColor
            loading={!actionCtx?.currentAccount}
          />
        </ContainerWrapper>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      {/* title box start */}
      <TitletextBox
        title={`Add Money`}
        text={`Top up your Raven account with multiple methods`}
        back={urlBack}
        onBack={() => {
          navigate(`/${urlBack}`);
        }}
      />
      {/* title box end */}
      {/* container wrapp start */}
      <ContainerWrapper width={size.width < 900 && "90%"} dontShowHeader>
        <div className=" add-money-select-wrap-box-new">
          <div className="title-drop-box">
            {" "}
            <p className="title grey-white-color">
              Select your preferred option
            </p>
            {all_account?.length > 1 && (
              <div
                onClick={() => {
                  // setShowAccountDrop(!showAccountDrop);
                  actionCtx.setCurrentAccountDrop(
                    !actionCtx.currentAccountDrop
                  );
                }}
                className="value-box grey-bg-two"
              >
                <p className="value">
                  {actionCtx?.currentAccount?.custom_account_name || "---"}
                </p>
                <figure
                  className={`img-box ${showAccountDrop && "img-box-up"}`}
                >
                  {dropDownIcon}
                </figure>
              </div>
            )}
            {/* toggle account box start */}
            {showAccountDrop && (
              <div className="select-toggle-box">
                <AccountDropDownBox
                  big={false}
                  onSelect={(obj) => {
                    actionCtx?.setCurrentAccount(obj);
                    // console.log(obj);
                    setShowAccountDrop(false);
                  }}
                />
              </div>
            )}
            {/* toggle account box end */}
          </div>
          {/* content wrap start */}
          <div className="content-box-two border-theme  animate-move-up-class">
            {bankDetails?.map((chi, idx) => {
              const { icon, text, title, coming_soon } = chi;
              return (
                <div
                  key={idx}
                  className={`add-money-item ${
                    idx !== bankDetails?.length - 1 && "border-theme-bottom"
                  } ${coming_soon && "wrap-coming-soon"}`}
                >
                  <div
                    onClick={() => {
                      if (!coming_soon) {
                        activeSelect === title
                          ? setActiveSelect("")
                          : setActiveSelect(title);
                      }
                    }}
                    style={{ cursor: coming_soon && "not-allowed" }}
                    className="top-box"
                  >
                    <div className="img-wrap">
                      <figure className="img-box">
                        <img src={icon} alt="" className="img" />
                      </figure>
                    </div>
                    <div
                      // onClick={() => {
                      //   console.log(actionCtx?.currentAccount);
                      // }}
                      className="title-text-box"
                    >
                      <p className="title">{title}</p>
                      <p className="text">{text}</p>
                    </div>
                    <div className="arrow-coming-soon-box">
                      {coming_soon ? (
                        <>
                          {" "}
                          <LockBox text="Coming soon" className="coming-soon" />
                        </>
                      ) : (
                        <>
                          <figure
                            className={`img-box-arrow ${
                              activeSelect === title && `img-box-arrow-rotate`
                            }`}
                          >
                            {rightArrowIcon}
                          </figure>
                        </>
                      )}
                    </div>
                  </div>
                  {/* content display start */}
                  <div
                    className={`content-display-box ${
                      activeSelect === title &&
                      title === "Bank Transfers" &&
                      "content-display-box-show"
                    }`}
                  >
                    <div className="grey-bg display-wrap">
                      <div className="info-box">
                        <div className="bank-info grey-white-color">
                          <div className="name tooltip-hover-wrap">
                            {trimLongString(
                              actionCtx?.currentAccount?.account_name?.replaceAll(
                                "-",
                                " "
                              ),
                              20
                            ) || "------"}{" "}
                            <RavenToolTip
                              color="black-light"
                              position={`right`}
                              text={
                                actionCtx?.currentAccount?.account_name ||
                                "------------"
                              }
                            />{" "}
                          </div>
                          <p>•</p>
                          <p className="bank">
                            {actionCtx?.currentAccount?.bank ||
                              "----------------"}
                          </p>
                        </div>
                        <p className="acc_num">
                          {actionCtx?.currentAccount?.account_number ||
                            "---------------"}
                        </p>
                      </div>
                      {actionCtx?.currentAccount?.account_number && (
                        <div
                          onClick={() => {
                            // const obj = {
                            //   bank: actionCtx?.currentAccount?.bank || "",
                            //   account_name:
                            //     actionCtx?.currentAccount?.account_name,
                            //   account_number:
                            //     actionCtx?.currentAccount?.account_number || "",
                            // };
                            handleCopied(
                              actionCtx?.currentAccount?.account_number
                            );
                          }}
                          className="copy-box white-black-bg"
                        >
                          <figure className="img-box">
                            {isCopied ? (
                              <FaCheck className="img icon" />
                            ) : (
                              <svg
                                width="10"
                                height="11"
                                viewBox="0 0 10 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="img"
                              >
                                <path
                                  // className="dull-bright-filter"
                                  d="M2.22222 2.5V1C2.22222 0.867391 2.28075 0.740215 2.38494 0.646446C2.48913 0.552678 2.63044 0.5 2.77778 0.5H9.44444C9.59179 0.5 9.7331 0.552678 9.83728 0.646446C9.94147 0.740215 10 0.867391 10 1V7.99999C10 8.1326 9.94147 8.25978 9.83728 8.35355C9.7331 8.44731 9.59179 8.49999 9.44444 8.49999H7.77778V9.99999C7.77778 10.276 7.52778 10.5 7.21833 10.5H0.559444C0.486182 10.5004 0.413554 10.4877 0.345735 10.4628C0.277915 10.4379 0.216241 10.4011 0.164255 10.3546C0.112269 10.3082 0.0709961 10.2529 0.0428079 10.1921C0.0146198 10.1312 7.18746e-05 10.0659 0 9.99999L0.00166668 3C0.00166668 2.724 0.251667 2.5 0.561111 2.5H2.22222ZM1.11278 3.5L1.11111 9.49999H6.66667V3.5H1.11278ZM3.33333 2.5H7.77778V7.49999H8.88889V1.5H3.33333V2.5Z"
                                  fill="#755AE2"
                                />
                              </svg>
                            )}
                          </figure>{" "}
                          <span>{isCopied ? `Copied` : `Copy`}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* content display end */}
                </div>
              );
            })}
          </div>
          {/* content wrap end */}
        </div>
      </ContainerWrapper>
      {/* container wrapp end */}
    </DashboardLayout>
  );
};

export default AddMoney;
