/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import setAuthToken from "../utils/auth";
import { toast } from "@ravenpay/raven-bank-ui";
// import setAuthToken from "../utils/auth";

export const verifyBvnNin = createAsyncThunk(
  "admin/verify-bvn-nin",
  async (payload, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const data = await axios.post("bvn/verify", payload);
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          theme: "colored",
        });
        // await data;
        return data;
      }
    } catch (err) {
      //   ;
      toast.error(err.response.data.message, {
        theme: "colored",
        position: "top-right",
      });
      return err;
    }
  }
);

export const verifyBusinessInformation = createAsyncThunk(
  "admin/verify-business-information",
  async (payload, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const data = await axios.post("/business_info/verify", payload);
      // console.log("bvn ", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await data;
        return data;
      }
    } catch (err) {
      //   ;
      toast.error(err.response.data.message, {
        theme: "colored",
        position: "top-right",
      });
      return err;
    }
  }
);

export const verifyAddressInformation = createAsyncThunk(
  "admin/verify-address-information",
  async (payload, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      // console.log(payload);

      const obj = {
        payload,
        type: "formData",
      };
      const data = await axios.post("/address/address_verification_v2", obj);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await data;
        return data;
      }
    } catch (err) {
      // ;
      toast.error(err.response.data.message, {
        theme: "colored",
        position: "top-right",
      });
      return err;
    }
  }
);

export const cacLookUp = createAsyncThunk(
  "admin/cac-look-up",
  async (payload, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const data = await axios.post("/cac/lookup", payload);
      // console.log("bvn ", data);
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data.message);
        await thunkAPI.dispatch(setLookUp(data.data.data));
        return data;
      }
    } catch (err) {
      // console.log(err);
      //   ;
      // toast.error(err.response.data.message, {
      //   theme: "colored",
      //   position: "top-right",
      // });
      return err;
    }
  }
);

export const cacVerification = createAsyncThunk(
  "admin/cac-verification",
  async (payload, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const obj = {
        payload,
        type: "formData",
      };
      const data = await axios.post("/cac/cac_verification_uploads", obj, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {});
        return data;
      }
    } catch (err) {
      // console.log(err);
      // toast.error(err.response.data.message, {
      //   theme: "colored",
      //   position: "top-right",
      // });
      return err;
    }
  }
);

export const signIndenmity = createAsyncThunk(
  "admin/sign-indenmity-verification",
  async (payload, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const obj = {
        payload,
        type: "formData",
      };
      const data = await axios.post(
        "/indemnity/indemnity_signature_upload",
        obj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {});
        return data;
      }
    } catch (err) {
      // console.log(err);
      // toast.error(err.response.data.message, {
      //   theme: "colored",
      //   position: "top-right",
      // });
      return err;
    }
  }
);

export const getIndenmityTexts = createAsyncThunk(
  "admin/get-indenmity-text",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/metadata/indemnity_text", payload);
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.status === "success") {
        // console.log(data?.data?.indemnity);
        await thunkAPI.dispatch(setIndenmityText(data?.data?.indemnity));
        //   return data;
      }
    } catch (err) {
      //   ;
      toast.error(err.response.data.message, {
        theme: "colored",
        position: "top-right",
      });
      return err;
    }
  }
);

export const getBusinessIndustries = createAsyncThunk(
  "admin/get-business-industries",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/metadata/sectors", payload);
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.status === "success") {
        const list = data?.data;
        await thunkAPI.dispatch(setBusinessIndustry(list));
        //   return data;
      }
    } catch (err) {
      //   ;
      toast.error(err.response.data.message, {
        theme: "colored",
        position: "top-right",
      });
      return err;
    }
  }
);

export const intiateNinVerification = createAsyncThunk(
  "admin/initiate-nin-notification",
  async (payload, thunkAPI) => {
    // console.log(payload);
    try {
      const data = await axios.post("/initiate_doc_verification", payload);
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        return data;
      }
    } catch (err) {
      //   ;
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const inviteReferee = createAsyncThunk(
  "admin/invite-referee",
  async (payload, thunkAPI) => {
    // console.log(payload);
    try {
      const data = await axios.post("/verifications/invite_referee", payload);
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        return data;
      }
    } catch (err) {
      //   ;
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getInvitedReferee = createAsyncThunk(
  "admin/get-invited-referee",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        "/verifications/fetch_referees",
        payload
      );
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.status === "success") {
        // console.log(data?.data?.indemnity);
        await thunkAPI.dispatch(setInvitedRef(data?.data));
        //   return data;
      }
    } catch (err) {
      //   ;
      toast.error(err.response.data.message, {
        theme: "colored",
        position: "top-right",
      });
      return err;
    }
  }
);

export const getInvitedRefereeByRefId = createAsyncThunk(
  "admin/get-invited-referee-single",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/verifications/select_invite_invite_with_reference?reference=${
          payload?.ref || ""
        }`,
        payload
      );
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        // console.log(data?.data?.referee);
        await thunkAPI.dispatch(setInvitedRefSingle(data?.data?.referee));
        return data;
      }
    } catch (err) {
      //   ;
      toast.error(err.response.data.message, {
        theme: "colored",
        position: "top-right",
      });
      return err;
    }
  }
);

export const acceptInvitedRefereeByRefId = createAsyncThunk(
  "admin/accept-invited-referee-single",
  async (payload, thunkAPI) => {
    try {
      const obj = {
        payload,
        type: "formData",
      };
      const data = await axios.post(
        `/referees/referee_details_verification`,
        obj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        return data;
      }
    } catch (err) {
      // console.log(err);
      //   ;
      toast.error(err.response.data.message, {
        theme: "colored",
        position: "top-right",
      });
      return err;
    }
  }
);

export const intiateBvnVerification = createAsyncThunk(
  "admin/initiate-bvn-notification",
  async (payload, thunkAPI) => {
    // console.log(payload);
    try {
      const data = await axios.post("/initiate_bvn_verification", payload);
      // console.log(data);
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        return data;
      }
    } catch (err) {
      //   ;
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const intiateIdVerification = createAsyncThunk(
  "admin/initiate-id-notification",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("/initiate_doc_verification", payload);
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        return data;
      }
    } catch (err) {
      //   ;
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const changeVerificationStatus = createAsyncThunk(
  "admin/change-verification-status",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/reset/verification", payload);
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        return data;
      }
    } catch (err) {
      //   ;
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getMonthlyTransactionrange = createAsyncThunk(
  "admin/get-transactionRange",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/metadata/transaction_volume", payload);
      //         // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.status === "success") {
        const list = data?.data;
        await thunkAPI.dispatch(setMonthlyTransactionrange(list));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getNumberOfStaff = createAsyncThunk(
  "admin/get-number-of-staff",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/metadata/number_of_staffs", payload);
      //         // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.status === "success") {
        const list = data.data;
        await thunkAPI.dispatch(setNumberOfStaff(list));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAccountPurposes = createAsyncThunk(
  "admin/get-account-purposes",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/metadata/account_purposes", payload);
      //       // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.status === "success") {
        const list = data.data;
        await thunkAPI.dispatch(setAccountPurpose(list));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getStates = createAsyncThunk(
  "admin/get-state",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/metadata/states", payload);
      //       // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.status === "success") {
        const list = data.data;
        await thunkAPI.dispatch(setStates(list));
        //   return data;
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        theme: "colored",
        position: "top-right",
      });
      return err;
    }
  }
);

export const getLgaByStates = createAsyncThunk(
  "admin/get-lga-by-state",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(`/metadata/state/lgas?state=${payload}`);
      //       // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.status === "success") {
        const list = data.data;
        await thunkAPI.dispatch(setLgas(list));
        //   return data;
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        theme: "colored",
        position: "top-right",
      });
      return err;
    }
  }
);

export const deleteTeamMember = createAsyncThunk(
  "admin/delete-team-member",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.delete("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await data;
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const generateBankStatement = createAsyncThunk(
  "admin/generate-bank-statement",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await data;
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const removeDevice = createAsyncThunk(
  "admin/remove-device-two",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await data;
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

const formatNGN = (str) => {
  const nairaSymbol = "\u{020A6}";
  if (str) {
    const firstFormat = str?.replace("NGN", nairaSymbol);
    // console.log(firstFormat);
    const secondFormat = firstFormat?.replace("- NGN", `- ${nairaSymbol}`);
    return secondFormat;
  }
};

const formatBusinessIndustry = (param, reason) => {
  // console.log(param);
  // const nairaSymbol = "\u{020A6}";
  const list = param.map((chi) => {
    return { label: reason ? formatNGN(chi) : chi, value: chi };
  });
  // console.log(list);
  return list;
};

export const verifications = createSlice({
  name: "payment-link",
  initialState: {
    business_industries: [],
    monthly_transaction_range: [],
    number_of_staff: [],
    account_purpose_list: [],
    account_purpose_list_two: [],
    cacLookUpInfo: [],
    Lgas: [],
    states: [],
    indemnity_text: "",
    invited_referee: {},
    invited_referee_single: {},
    isAuth: false,
    loading: false,
    loadingFetch: false,
    loadingRef: false,
    loadingCac: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setPaymentLinks: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      //   state.paymentLinks = action.payload;
    },
    setIndenmityText: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.indemnity_text = action?.payload;
    },
    setInvitedRef: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.invited_referee = action?.payload;
    },
    setInvitedRefSingle: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.invited_referee_single = action?.payload;
    },
    setBusinessIndustry: (state, action) => {
      state.business_industries = formatBusinessIndustry(action.payload);
      state.isAuth = true;
      //   state.paymentLinks = action.payload;
    },
    setMonthlyTransactionrange: (state, action) => {
      state.monthly_transaction_range = formatBusinessIndustry(
        action.payload,
        "reason"
      );
      state.isAuth = true;
      //   state.paymentLinks = action.payload;
    },
    setNumberOfStaff: (state, action) => {
      state.number_of_staff = formatBusinessIndustry(action.payload);
      state.isAuth = true;
      //   state.paymentLinks = action.payload;
    },
    setAccountPurpose: (state, action) => {
      state.account_purpose_list = action.payload;
      state.account_purpose_list_two = formatBusinessIndustry(action.payload);
      state.isAuth = true;
      //   state.paymentLinks = action.payload;
    },

    setStates: (state, action) => {
      state.states = formatBusinessIndustry(action.payload);
      state.isAuth = true;
      //   state.paymentLinks = action.payload;
    },
    setLgas: (state, action) => {
      state.Lgas = formatBusinessIndustry(action.payload);
      state.isAuth = true;
      //   state.paymentLinks = action.payload;
    },
    setLookUp: (state, action) => {
      state.cacLookUpInfo = action.payload;
      state.isAuth = true;
      //   state.paymentLinks = action.payload;
    },
  },
  extraReducers: {
    [getInvitedReferee.pending]: (state) => {
      state.loadingRef = true;
    },
    [getInvitedReferee.fulfilled]: (state) => {
      state.loadingRef = false;
    },
    [getInvitedReferee.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingRef = false;
      state.isAuth = false;
      state = null;
    },
    [getInvitedRefereeByRefId.pending]: (state) => {
      state.loadingRef = true;
    },
    [getInvitedRefereeByRefId.fulfilled]: (state) => {
      state.loadingRef = false;
    },
    [getInvitedRefereeByRefId.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingRef = false;
      state.isAuth = false;
      state = null;
    },
    [verifyBvnNin.pending]: (state) => {
      state.loading = true;
    },
    [verifyBvnNin.fulfilled]: (state) => {
      state.loading = false;
    },
    [verifyBvnNin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [verifyBusinessInformation.pending]: (state) => {
      state.loading = true;
    },
    [verifyBusinessInformation.fulfilled]: (state) => {
      state.loading = false;
    },
    [verifyBusinessInformation.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [cacLookUp.pending]: (state) => {
      state.loadingCac = true;
    },
    [cacLookUp.fulfilled]: (state) => {
      state.loadingCac = false;
    },
    [cacLookUp.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingCac = false;
      state.isAuth = false;
      state = null;
    },
    [inviteReferee.pending]: (state) => {
      state.loadingCac = true;
    },
    [inviteReferee.fulfilled]: (state) => {
      state.loadingCac = false;
    },
    [inviteReferee.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingCac = false;
      state.isAuth = false;
      state = null;
    },
    [getIndenmityTexts.pending]: (state) => {
      state.loadingCac = true;
    },
    [getIndenmityTexts.fulfilled]: (state) => {
      state.loadingCac = false;
    },
    [getIndenmityTexts.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingCac = false;
      state.isAuth = false;
      state = null;
    },
    [signIndenmity.pending]: (state) => {
      state.loadingFetch = true;
    },
    [signIndenmity.fulfilled]: (state) => {
      state.loadingFetch = false;
    },
    [signIndenmity.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingFetch = false;
      state.isAuth = false;
      state = null;
    },
    [getLgaByStates.pending]: (state) => {
      state.loadingFetch = true;
    },
    [getLgaByStates.fulfilled]: (state) => {
      state.loadingFetch = false;
    },
    [getLgaByStates.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingFetch = false;
      state.isAuth = false;
      state = null;
    },
    [verifyAddressInformation.pending]: (state) => {
      state.loading = true;
    },
    [verifyAddressInformation.fulfilled]: (state) => {
      state.loading = false;
    },
    [verifyAddressInformation.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [cacVerification.pending]: (state) => {
      state.loading = true;
    },
    [cacVerification.fulfilled]: (state) => {
      state.loading = false;
    },
    [cacVerification.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [acceptInvitedRefereeByRefId.pending]: (state) => {
      state.loading = true;
    },
    [acceptInvitedRefereeByRefId.fulfilled]: (state) => {
      state.loading = false;
    },
    [acceptInvitedRefereeByRefId.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [intiateBvnVerification.pending]: (state) => {
      state.loading = true;
    },
    [intiateBvnVerification.fulfilled]: (state) => {
      state.loading = false;
    },
    [intiateBvnVerification.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [intiateNinVerification.pending]: (state) => {
      state.loading = true;
    },
    [intiateNinVerification.fulfilled]: (state) => {
      state.loading = false;
    },
    [intiateNinVerification.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [changeVerificationStatus.pending]: (state) => {
      state.loading = true;
    },
    [changeVerificationStatus.fulfilled]: (state) => {
      state.loading = false;
    },
    [changeVerificationStatus.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [intiateIdVerification.pending]: (state) => {
      state.loading = true;
    },
    [intiateIdVerification.fulfilled]: (state) => {
      state.loading = false;
    },
    [intiateIdVerification.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setPaymentLinks,
  setBusinessIndustry,
  setMonthlyTransactionrange,
  setNumberOfStaff,
  setAccountPurpose,
  setStates,
  setLgas,
  setLookUp,
  setIndenmityText,
  setInvitedRef,
  setInvitedRefSingle,
} = verifications.actions;

export default verifications.reducer;
