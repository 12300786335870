/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
// import setAuthToken from "../utils/auth";
import { toast } from "@ravenpay/raven-bank-ui";

export const getPhysicalCards = createAsyncThunk(
  "/cards/fetch_cards/virtual",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("cards/fetch_cards/virtual", payload);
      // console.log("register", data);

      if (data.status !== "success") {
        toast.error(data.message, {});
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getVirtualCards = createAsyncThunk(
  "card/fetch_cards/virtual",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("cards/fetch_cards/virtual", payload);
      // console.log("cards", data);

      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log(data?.data?.data?.card_details);
        await thunkAPI.dispatch(setCards(data?.data?.data?.card_details));
        return data?.data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getCardDesigns = createAsyncThunk(
  "cards/fetch_card_designs'",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("/cards/fetch_card_designs", payload);
      // console.log("cards", data);

      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        await thunkAPI.dispatch(setCardDesigns(data?.data?.data));
        return data?.data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPhysicalCard = createAsyncThunk(
  "admin/get-physical-card",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      //       // console.log("register", data);

      if (data?.response?.status === 400) {
        toast.error("Something went wrong, Try again!", {
          position: "top-right",
        });
        // return data?.data;
      }
      if (data?.data?.status !== "success") {
        toast.error(data?.data?.message, {});

        return data?.data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   theme: "colored",
        //   position: "top-right",
        // });
        await thunkAPI.dispatch(setCards(data?.data?.data));
        return data?.data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getVirtualCard = createAsyncThunk(
  "admin/get-virtual-card",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`cards/single_card/${payload?.id}`);
      // console.log(data, "---------card details ------");
      if (data?.response?.status === 400) {
        toast.error("Something went wrong, Try again!", {});
      }

      if (data?.data?.status !== "success") {
        toast.error(data?.data?.message, {});
        return data?.data;
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   theme: "colored",
        //   position: "top-right",
        // });
        // console.log(data.data.data.card_details);
        await thunkAPI.dispatch(setCard(data?.data?.data?.card_details));
        return data?.data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getCardTransactions = createAsyncThunk(
  "cards/transactions-get",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `cards/transactions?card_id=${payload?.id}&page=${payload?.current_page || 1}&perPage=${payload?.per_page || 20}`,
        payload
      );
      // console.log("trx - card", data);

      if (data?.response?.status === 400) {
        toast.error("Something went wrong, Try again!", {});
      }

      if (data?.data?.status !== "success") {
        // toast.error(data?.data?.message, {
        //   theme: "colored",
        //   position: "top-right",
        // });
        return data?.data;
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   theme: "colored",
        //   position: "top-right",
        // });
        // console.log(data?.data?.data?.card_transactions);
        await thunkAPI.dispatch(
          setCardTransactions(data?.data?.data?.card_transactions)
        );
        return data?.data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPhysicalCardTransactions = createAsyncThunk(
  "admin/get-physical-card-transactions",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const createVirtualCard = createAsyncThunk(
  "admin/create-virtual-card",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("cards/create_virtual_cards", payload);
      // console.log("create card", data);

      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data.data.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {});
        // await thunkAPI.dispatch(setPreviousDisbursement(data.data.data));
        return data;
      }

      if (data?.message === "Network Error") {
        // console.log('this guy', data)
        toast.error("Something went wrong", {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const createPhysicalCard = createAsyncThunk(
  "admin/create-physical-card",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const cardWithdraw = createAsyncThunk(
  "admin/card-withdraw",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const terminateCard = createAsyncThunk(
  "admin/terminate-card",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.delete("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const changeCardPin = createAsyncThunk(
  "admin/change-card-pin",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const setCardLimit = createAsyncThunk(
  "admin/set-card-limit",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getCardConfig = createAsyncThunk(
  "cards/fetch_card_setup_config",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("cards/fetch_card_setup", payload);
      // console.log("register", data);

      if (data?.response?.status === 400) {
        toast.error("Something went wrong, Try again!", {});
        // return data?.data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});

        return data?.data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   theme: "colored",
        //   position: "top-right",
        // });
        await thunkAPI.dispatch(setConfig(data?.data?.data?.card_config));
        return data?.data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

// export const validateNin = createAsyncThunk(
//   "/cards/verify_nin_details",
//   async (payload, thunkAPI) => {
//     try {
//       const data = await axios.get("cards/verify_nin_details", payload);
//       // console.log("register", data);
//       if (data?.response?.data?.status === "fail") {
//         toast.error(data?.response?.data?.message, {});
//       }
//       if (data?.data?.status === "fail") {
//         toast.error(data?.data?.message, {});
//         // return thunkAPI.rejectWithValue(data?.data?.data);
//       }
//       if (data?.data?.status === "success") {
//         return data;
//       }
//     } catch (err) {
//       // ;
//       if (err.response.data.status === "fail" && err.response.status !== 401) {
//         toast.error(err.response.data.message, {});
//       }
//       return err;
//     }
//   }
// );

export const validateBvn = createAsyncThunk(
  "/cards/verify_bvn_details",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/bvn/verify", payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
        // return thunkAPI.rejectWithValue(data?.data?.data);
      }
      if (data?.data?.status === "success") {
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const fundCard = createAsyncThunk(
  "/cards/fund",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/cards/fund_card", payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
        // return thunkAPI.rejectWithValue(data?.data?.data);
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const withdrawFromCard = createAsyncThunk(
  "/cards/withdraw-fund",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/cards/withdraw-from-card", payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
        // return thunkAPI.rejectWithValue(data?.data?.data);
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const freezeAndUnfreezeCard = createAsyncThunk(
  "/cards/freeze-and-unfreeze-card",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/cards/freeze-card", payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
        // return thunkAPI.rejectWithValue(data?.data?.data);
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const UnfreezeCard = createAsyncThunk(
  "/cards/freeze-and-unfreeze-card",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/cards/unfreeze-card", payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
        // return thunkAPI.rejectWithValue(data?.data?.data);
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const validateNin = createAsyncThunk(
  "/cards/verify_nin_details",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      const data = await axios.post("/nin/verify", payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        // return thunkAPI.rejectWithValue(data?.data?.data);
      }
      if (data?.data?.status === "success") {
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const cards = createSlice({
  name: "cards",
  initialState: {
    nin: "",
    bvn: "",
    config: {},
    cards: [],
    card_designs: {},
    card_transactions: [],
    card: {},
    loadingNin: false,
    isAuth: false,
    loading: false,
    loadingBvn: false,
    loadingTrans: false,
    loadingSingle: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setCards: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.cards = action.payload;
    },

    setCardTransactions: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.card_transactions = action.payload;
    },

    setConfig: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.config = action.payload;
    },

    setNIN: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.nin = action.payload;
    },

    setBVN: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.bvn = action.payload;
    },

    setCard: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.card = action.payload;
    },

    setCardDesigns: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.card_designs = action.payload;
    },
  },
  extraReducers: {
    [getPhysicalCards.pending]: (state) => {
      state.loading = true;
    },
    [getPhysicalCards.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPhysicalCards.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    // [getCardTransactions.pending]: (state) => {
    //   state.loadingSingle = true;
    // },
    // [getCardTransactions.fulfilled]: (state) => {
    //   state.loadingSingle = false;
    // },
    // [getCardTransactions.rejected]: (state) => {
    //   // localStorage.removeItem("token");
    //   state.loadingSingle = false;
    //   state.isAuth = false;
    //   state = null;
    // },

    [getCardDesigns.pending]: (state) => {
      state.loading = true;
    },
    [getCardDesigns.fulfilled]: (state) => {
      state.loading = false;
    },
    [getCardDesigns.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [createVirtualCard.pending]: (state) => {
      state.loading = true;
    },
    [createVirtualCard.fulfilled]: (state) => {
      state.loading = false;
    },
    [createVirtualCard.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [fundCard.pending]: (state) => {
      state.loading = true;
    },
    [fundCard.fulfilled]: (state) => {
      state.loading = false;
    },
    [fundCard.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [withdrawFromCard.pending]: (state) => {
      state.loadingBvn = true;
    },
    [withdrawFromCard.fulfilled]: (state) => {
      state.loadingBvn = false;
    },
    [withdrawFromCard.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingBvn = false;
      state.isAuth = false;
      state = null;
    },

    [getVirtualCards.pending]: (state) => {
      state.loading = true;
    },
    [getVirtualCards.fulfilled]: (state) => {
      state.loading = false;
    },
    [getVirtualCards.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [freezeAndUnfreezeCard.pending]: (state) => {
      state.loadingBvn = true;
    },
    [freezeAndUnfreezeCard.fulfilled]: (state) => {
      state.loadingBvn = false;
    },
    [freezeAndUnfreezeCard.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingBvn = false;
      state.isAuth = false;
      state = null;
    },

    [UnfreezeCard.pending]: (state) => {
      state.loadingBvn = true;
    },
    [UnfreezeCard.fulfilled]: (state) => {
      state.loadingBvn = false;
    },
    [UnfreezeCard.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingBvn = false;
      state.isAuth = false;
      state = null;
    },
    [getVirtualCard.pending]: (state) => {
      state.loadingSingle = true;
    },
    [getVirtualCard.fulfilled]: (state) => {
      state.loadingSingle = false;
    },
    [getVirtualCard.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSingle = false;
      state.isAuth = false;
      state = null;
    },

    [validateBvn.pending]: (state) => {
      // state.loading = true;
      state.loadingBvn = true;
    },
    [validateBvn.fulfilled]: (state) => {
      // state.loading = false;
      state.loadingBvn = false;
    },
    [validateBvn.rejected]: (state) => {
      // localStorage.removeItem("token");
      // state.loading = false;
      state.loadingBvn = false;
      state.isAuth = false;
      state = null;
    },

    [validateNin.pending]: (state) => {
      state.loadingNin = true;
    },
    [validateNin.fulfilled]: (state) => {
      state.loadingNin = false;
    },
    [validateNin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingNin = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setCardDesigns,
  setCardTransactions,
  setCards,
  setCard,
  setConfig,
  setNIN,
  setBVN,
} = cards.actions;

export default cards.reducer;
