import React, { useEffect, useState } from "react";
import DashboardSettingsLayout from "./SettingsLayout";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDirectorShareholders } from "../../../../redux/settings";
import verifyIcon from "../../../../assets/settings-verification-icon.svg";
import { SPECIAL_ROLE_NAME } from "../../../../utils/helper/Helper";

const DashboardSettingsVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refreshTable] = useState(false);
  const { business, user_resource_and_permissions, user } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(getDirectorShareholders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);
  return (
    <>
      <DashboardSettingsLayout>
        <div className="detail-wrap animate-move-up-class">
          <p className="title">Verifications</p>
          <p className="sub-title grey-white-color">
            Keep your account secure and increase your transaction limit when
            you verify your account.
          </p>
          <div className="box-wrap grey-bg">
            <div className="noti-wrap">
              <figure className="img-box">
                <img src={verifyIcon} alt="" className="img" />
              </figure>
              <div className="text-box">
                <p className="value">BVN Verifications</p>
                <p className="label grey-white-color">
                  Confirm your Bank Verification Number.
                </p>
              </div>
              {user_resource_and_permissions?.allowed_permissions?.includes(
                "can_view_verification_status"
              ) && (
                <div
                  className={`add-error-box ${
                    business?.bvn === 1 && "pending"
                  } ${business?.bvn === 2 || business?.bvn ===  4 || business?.bvn === 5 ? "failed" : ""}  ${
                    business?.bvn === 3 && "verified"
                  }`}
                  onClick={() => {
                    if(user?.clearance !== SPECIAL_ROLE_NAME){
                        return
                      }
                    navigate("/dashboard-verification-bvn");
                  }}
                >
                  <span>
                    {business?.bvn === 3
                      ? "Verified"
                      : business?.bvn === 2 || business?.bvn === 4 || business?.bvn === 5
                      ? "failed"
                      : business?.bvn === 1
                      ? "pending"
                      : "Add"}
                  </span>
                </div>
              )}
              {/* <RavenToggleSlide id="jgjk" color="black-light"  /> */}
            </div>

            <div className="noti-wrap">
              <figure className="img-box">
                <img src={verifyIcon} alt="" className="img" />
              </figure>
              <div className="text-box">
                <p className="value">NIN Verifications</p>
                <p className="label grey-white-color">
                  Submit your NIN verification.
                </p>
              </div>
              {user_resource_and_permissions?.allowed_permissions?.includes(
                "can_view_verification_status"
              ) && (
                <div
                  className={`add-error-box  ${
                    business?.nin === 1 && "pending"
                  } ${business?.nin === 2 || business?.nin ===  4 || business?.nin === 5 ? "failed" : ""}  ${
                    business?.nin === 3 && "verified"
                  }`}
                  onClick={() => {
                    if (user?.clearance !== SPECIAL_ROLE_NAME) {
                      return;
                    }
                    navigate("/dashboard-verification-nin");
                  }}
                >
                  <span>
                    {business?.nin === 3
                      ? "Verified"
                      : business?.nin === 2  || business?.nin === 4 || business?.nin === 5
                      ? "failed"
                      : business?.nin === 1
                      ? "pending"
                      : "Add"}
                  </span>
                </div>
              )}
              {/* <RavenToggleSlide id="jgjk" color="black-light"  /> */}
            </div>
            <div className="noti-wrap">
              <figure className="img-box">
                <img src={verifyIcon} alt="" className="img" />
              </figure>
              <div className="text-box">
                <p className="value">Address Verifications</p>
                <p className="label grey-white-color">
                  Verify your address address.
                </p>
              </div>
              {user_resource_and_permissions?.allowed_permissions?.includes(
                "can_view_verification_status"
              ) && (
                <div
                  className={`add-error-box ${
                    business?.address === 1 && "pending"
                  } ${business?.address === 2  || business?.address ===  4 || business?.address === 5 ? "failed" : ""}  ${
                    business?.address === 3 && "verified"
                  }`}
                  onClick={() => {
                    if (user?.clearance !== SPECIAL_ROLE_NAME) {
                      return;
                    }
                    navigate("/dashboard-verification-address");
                  }}
                >
                  <span>
                    {business?.address === 3
                      ? "Verified"
                      : business?.address === 2  || business?.address === 4 || business?.address === 5
                      ? "failed"
                      : business?.address === 1
                      ? "pending"
                      : "Add"}
                  </span>
                </div>
              )}
              {/* <RavenToggleSlide id="jgjk" color="black-light" /> */}
            </div>
            <div className="noti-wrap">
              <figure className="img-box">
                <img src={verifyIcon} alt="" className="img" />
              </figure>
              <div className="text-box">
                <p className="value">Business details verifications</p>
                <p className="label grey-white-color">
                  Verify your business information
                </p>
              </div>
              {user_resource_and_permissions?.allowed_permissions?.includes(
                "can_view_verification_status"
              ) && (
                <div
                  className={`add-error-box ${
                    business?.business_info === 1 && "pending"
                  } ${business?.business_info === 2  || business?.business_info ===  4 || business?.business_info === 5 ? "failed" : ""} ${
                    business?.business_info === 3 && "verified"
                  }`}
                  onClick={() => {
                    if(user?.clearance !== SPECIAL_ROLE_NAME){
                        return
                      }
                    navigate("/dashboard-verification-business");
                  }}
                >
                  <span>
                    {business?.business_info === 3
                      ? "Verified"
                      : business?.business_info === 2   || business?.business_info === 4 || business?.business_info === 5
                      ? "failed"
                      : business?.business_info === 1
                      ? "pending"
                      : "Add"}
                  </span>
                </div>
              )}
              {/* <RavenToggleSlide id="jgjk" color="black-light" /> */}
            </div>

            <div className="noti-wrap">
              <figure className="img-box">
                <img src={verifyIcon} alt="" className="img" />
              </figure>
              <div className="text-box">
                <p className="value">CAC Verifications</p>
                <p className="label grey-white-color">
                  Submit your CAC information for verifications
                </p>
              </div>
              {user_resource_and_permissions?.allowed_permissions?.includes(
                "can_view_verification_status"
              ) && (
                <div
                  className={`add-error-box  ${
                    business?.cac === 1 && "pending"
                  } ${business?.cac === 2 || business?.cac ===  4 || business?.cac === 5 ? "failed" : ""}  ${
                    business?.cac === 3 && "verified"
                  }`}
                  onClick={() => {
                    if(user?.clearance !== SPECIAL_ROLE_NAME){
                        return
                      }
                    navigate("/dashboard-verification-cac");
                  }}
                >
                  <span>
                    {business?.cac === 3
                      ? "Verified"
                      : business?.cac === 2   || business?.cac === 4 || business?.cac === 5
                      ? "failed"
                      : business?.cac === 1
                      ? "pending"
                      : "Add"}
                  </span>
                </div>
              )}
              {/* <RavenToggleSlide id="jgjk" color="black-light" /> */}
            </div>
            {/* <div className="noti-wrap"> */}
            {/* <figure className="img-box">
            <img src={verifyIcon} alt="" className="img" />
          </figure>
          <div className="text-box">
            <p className="value">Director / Shareholder</p>
            <p className="label grey-white-color">
              Add your directors details for verifiications.
            </p>
          </div>
          <div className="add-error-box">
            <span>add</span>
          </div> */}
            {/* <RavenToggleSlide id="jgjk" color="black-light" /> */}
            {/* </div> */}
          </div>
        </div>
      </DashboardSettingsLayout>
    </>
  );
};

export default DashboardSettingsVerification;
