import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { getAllDevices, removeDevice } from "../../../../redux/settings";

const DeleteDeviceModal = ({ visible, onClose, detail }) => {
  const dispatch = useDispatch();
  const { loadingSearch } = useSelector((state) => state.settings);

  const handleSubmit = async () => {
    // console.log(detail);
    const obj = {
      id: String(detail?.id),
    };
    // console.log(obj);
    // return;
    const data = await dispatch(removeDevice(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      dispatch(getAllDevices());
      onClose();
    }
  };

  return (
    <DeleteModal
      title={`Delete ${detail?.fingerprint?.components?.useragent?.os?.family}`}
      text={`Removing this device, would disable their access to your account on your business, ensure you confirm this device before totally removing them from your account.`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
      loading={loadingSearch}
    />
  );
};

export default DeleteDeviceModal;
