import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
// import { textToRead } from "./ReadUpContent";
import { useSelector } from "react-redux";
import NoContentBox from "../../../../../components/reusables/NoContentBox";

const BigContentViewModal = ({ visible, onClose }) => {
  const {  loadingCac, indemnity_text } = useSelector(
    (state) => state.verifications
  );
  return (
    <>
      <RavenModal
        className={`raven-big-content-view-text-box-modal`}
        visble={visible}
        onClose={onClose}
      >
        <div className="raven-big-content-view-text-box">
          {loadingCac ? (
            <>
              <NoContentBox loading={true} />
            </>
          ) : (
            <p className="text">{indemnity_text}</p>
          )}
        </div>
      </RavenModal>
    </>
  );
};

export default BigContentViewModal;
