import React, { useEffect, useState } from "react";
import "../../../styles/dashboard/global-account/ExhangeRateModalBox.css";
import { useSelector } from "react-redux";
import GlobalSelectDropBox from "../../../components/fragments/GlobalSelectDropBox";
// import { formatNumWithComma } from "../../../utils/Helpers";
import {
  convertToLowercase,
  // formatNumWithCommaNaira,
  formatNumberSpecail,
} from "../../../utils/helper/Helper";

const ExchangeRateModalBox = () => {
  const rightChevronIcon = (
    <svg
      className="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66666 12.6666L10.3333 7.99998L5.66666 3.33331"
        stroke="#9C9CAB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const [currencyToview, setCurrencyToView] = useState({});
  const { all_currencies } = useSelector((state) => state.global_account);

  useEffect(() => {
    if (all_currencies?.all_support_currencies?.length > 0) {
      const obj = all_currencies?.all_support_currencies?.find(
        (chi) => chi?.currency === "NGN"
      );
      setCurrencyToView(obj);
    }
  }, [all_currencies]);

  const getToValue = (to_cur, from_cur) => {
    if (all_currencies?.all_support_currencies?.length > 0) {
      const obj = all_currencies?.all_support_currencies?.find(
        (chi) => chi?.currency === to_cur
      );
      // console.log(obj);
      const rates = obj?.rates?.find((chi) => chi?.currency_to === from_cur);
      return rates?.rate || "";
    }
  };

  return (
    <div
      // onClick={() => {
      //   console.log(currencyToview);
      // }}
      className="exchange-rate-modal-box-index-wrap"
    >
      {/* today rate box start */}
      <div className="today-rate-box-wrap border-theme">
        {/* title select drop box start */}
        <div className="title-select-drop-box border-theme-bottom">
          <p className="title">Today’s Rate</p>
          {/* select drop box stat */}
          <GlobalSelectDropBox
            setCurrency={(param) => {
              setCurrencyToView(param);
              // console.log(param);
            }}
            selectedCurrency={currencyToview}
            currencyList={all_currencies?.all_support_currencies}
          />
          {/* select drop box end */}
        </div>
        {/* title select drop box end */}
        {/* middle content rate box start */}
        <div className="middle-content-rate-box">
          {/* from and to box start  */}
          {currencyToview?.rates?.length > 0 &&
            currencyToview?.rates?.map((chi, idx) => {
              const { currency_from, currency_to, rate } = chi;
              return (
                <div
                  onClick={() => {
                    // getToValue(currency_to, currency_from);
                  }}
                  key={idx}
                  className="from-to-rate-box-wrap border-theme-bottom"
                >
                  {/* from box start */}
                  <div className="from-box">
                    <div className="label-box">
                      <div className="label-text">
                        {" "}
                        <div className="img-select">
                          <figure className="img-box">
                            <img
                              src={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                                currency_from
                              )}.png`}
                              className="img"
                              alt=""
                            />
                          </figure>
                        </div>{" "}
                        {currency_from}
                      </div>
                      <div className="icon-wrap">
                        <figure className="img-box">{rightChevronIcon}</figure>
                      </div>
                      <div className="label-text">
                        <div className="img-select">
                          <figure className="img-box">
                            <img
                              src={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                                currency_to
                              )}.png`}
                              className="img"
                              alt=""
                            />
                          </figure>
                        </div>
                        {currency_to}
                      </div>
                    </div>
                    <p className="value grey-white-color">
                      {/* {formatNumWithCommaNaira(rate)} */}
                      {formatNumberSpecail(
                        getToValue(currency_to, currency_from)
                      ) || ""}
                    </p>
                  </div>
                  {/* from box end */}
                  {/* to box start */}
                  <div className="to-box">
                    <div className="label-box">
                      <div className="label-text">
                        {" "}
                        <div className="img-select">
                          <figure className="img-box">
                            <img
                              src={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                                currency_to
                              )}.png`}
                              className="img"
                              alt=""
                            />
                          </figure>
                        </div>{" "}
                        {currency_to}
                      </div>
                      <div className="icon-wrap">
                        <figure className="img-box">{rightChevronIcon}</figure>
                      </div>
                      <div className="label-text">
                        {" "}
                        <div className="img-select">
                          <figure className="img-box">
                            <img
                              src={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                                currency_from
                              )}.png`}
                              className="img"
                              alt=""
                            />
                          </figure>
                        </div>{" "}
                        {currency_from}
                      </div>
                    </div>
                    <p className="value grey-white-color">
                      {" "}
                      {formatNumberSpecail(rate) || ""}
                      {/* {formatNumWithCommaNaira(getToValue(currency_to, currency_from))} */}
                    </p>
                  </div>
                  {/* to box end */}
                </div>
              );
            })}
          {/* from and to box end */}
        </div>
        {/* middle content rate box end */}
        {/* bottom box text wrap start */}
        <div className="bottom-text-box-wrap border-theme-top grey-bg">
          <p className="text grey-white-color">
            Last Updated: 12, April 2024, 08:24PM
          </p>
        </div>
        {/* bottom box text wrap end */}
      </div>
      {/* today rate box end */}
    </div>
  );
};

export default ExchangeRateModalBox;
