import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import recordImg from "../../../../assets/template-record-img.svg";
import { formatDate } from "../../../../utils/helper/Helper";
import useDebounce from "../../../../utils/helper/UseDebounce";

const RecordModal = ({ visible, onClose, onBulkSelect }) => {
  const { ravenBulkRecord } = useSelector((state) => state.transaction);
  const [recordToView, setRecordToView] = useState([]);

  useEffect(() => {
    setRecordToView(ravenBulkRecord);
  }, [ravenBulkRecord]);

  const formatBulkList = (list) => {
    if (list?.length > 0) {
      const newList = JSON?.parse(list);
      //   console.log(newList);
      return newList;
    }
  };

  // const formatTitle = (text) => {
  //   var regex = /[,\s_.csvNG]/g;
  //   var result = String(text).replace(regex, " ");
  //   return result;
  // };

  const [details, setDetails] = useState({
    search: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSearch = (term) => {
    // console.log(term);
    // console.log(ravenBulkRecord);
    const newList = ravenBulkRecord?.filter((chi) => {
      // console.log(chi?.template_title);
      return (
        chi?.template_title?.toLowerCase().indexOf(term?.toLowerCase()) !== -1
      );
    });

    setRecordToView(newList);
  };

  const debouncedSearchTerm = useDebounce(details?.search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      handleSearch(details?.search);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      setRecordToView(ravenBulkRecord);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <RavenModal visble={visible} onClose={onClose}>
      <div
        // onClick={() => {
        //   console.log(ravenBulkRecord);
        // }}
        className="raven-record-modal-wrap"
      >
        <div className="text-title-box border-theme-bottom">
          <p className="title">All Existing Records</p>
          <p className="text grey-white-color">
            Search and select from all your saved transfer records
          </p>
        </div>
        {/* search group start */}
        <RavenInputField
          color="black-light"
          type={`search`}
          placeholder={`Search records by title`}
          name="search"
          onChange={handleChange}
          value={details?.search}
        />
        {/* search group end */}
        {/* record-wrap start */}
        <div className="record-wrap">
          {recordToView?.map((chi, idx) => {
            const { template_title, id, transfer_template_data, created_at } =
              chi;
            return (
              <div
                className={`template-row ${
                  idx !== ravenBulkRecord?.length && "border-theme-bottom"
                }`}
                key={idx || id}
                onClick={() => {
                  onBulkSelect(chi);
                  // console.log(chi);
                }}
              >
                <div className="img-wrap">
                  <figure className="img-box">
                    <img src={recordImg} alt="" />
                  </figure>
                </div>
                <div className="title-text-box">
                  <p className="title">{template_title?.replace("_", "")}</p>
                  <p className="text grey-white-color">{`${
                    formatBulkList(transfer_template_data)?.length
                  } Records • ${formatDate(created_at)?.split("—")[0]}`}</p>
                </div>
              </div>
            );
          })}
        </div>
        {/* record-wrap end */}
      </div>
    </RavenModal>
  );
};

export default RecordModal;
