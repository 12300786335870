// import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../components/reusables/DeleteModal";
import { deleteInvoice } from "../../../redux/invoice";

const DeleteInvoiceModal = ({ visible, onCancel, detail, onFinish }) => {
  const dispatch = useDispatch();
  const { loadingDelete } = useSelector((state) => state.invoice);

  const handleSubmit = async () => {
    const obj = {
      id: detail?.id,
    };
    const data = await dispatch(deleteInvoice(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish && onFinish();
    }
  };

  return (
    <DeleteModal
      title={`Delete Invoice (${detail?.title})`}
      text={`Deleting this invoice means the link attached to this invoice would be obsolete and you won’t accept any payment anymore`}
      visible={visible}
      onCancel={onCancel}
      loading={loadingDelete}
      onDelete={handleSubmit}
    />
  );
};

export default DeleteInvoiceModal;
