import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from '../../../../components/reusables/DeleteModal'
import { disableTermial } from '../../../../redux/pos';

const DeleteTerminalModal = ({ visible, onClose, detail, onFinish }) => {
  const dispatch = useDispatch();
  const { loadingSettlement } = useSelector((state) => state.pos);

  const handleSubmit = async () => {
    const obj = {
      tid: detail?.tid || localStorage?.getItem("pos-terminal-id"),
    };
    // console.log(obj);
    // return
    const data = await dispatch(disableTermial(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
    }
  };

  return (
    <DeleteModal
    loading={loadingSettlement}
      title={`Disable  Terminal POS`}
      text={`This terminal won’t be able to receive or process transaction any more, unless you reach our customer care agent to unblock your terminal. do you wish to proceed`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
    />
  )
}

export default DeleteTerminalModal