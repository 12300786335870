import React, {  useState } from "react";
// import NewSelectScheduleBox from "../../../../components/reusables/NewSelectScheduleBox";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";Group 238182raven-default-bank-img.svg
import "../../../styles/fragments/PreviewDetailsCommonBox.css";
import bankIconDefault from "../../../assets/bank-general-img.svg";
// import bankIconDefault from "../../../assets/raven-default-bank-img.svg";
// import mtnICon from "../../../assets/bill-mtn-icon.svg";
// import airtelICon from "../../../assets/bill-airtel-icon.svg";
// import mobileICon from "../../../assets/bill-moblie-icon.svg";
// import gloICon from "../../../assets/bill-glo-icon.svg";
import moment from "moment";
import {
  formatDate,
  getCurrencySymbol,
  //   formatNumWithCommaNairaSymbol,
  //   formatNumWithoutCommaNaira,
  //   trimLongString,
} from "../../../utils/helper/Helper";
import { FaTimes } from "react-icons/fa";
// import ActionContext from "../../../context/ActionContext";
import ChangeSourceAccount from "../../../components/reusables/ChangeSourceAccount";
// import { RavenToggleSlide } from "@ravenpay/raven-bank-ui";

const NewPreviewDetailBox = ({
  collapse,
  amount,
  name,
  bank,
  account_name,
  narration,
  onChange,
  value,
  id,
  checked,
  previewOpen,
  setPreviewOpen,
  onChangeSchedule,
  valueSchedule,
  enableTime,
  onReset,
  details,
  bulkAmount,
  setDetails,
  handleRemoveFromList,
  handleChange,
  list,
  provider,
  number,
  hideSubInfo,
  //   collapse,
  //   amount,
  email,
  linkDuration,
  linkType,
  globalInfo,
}) => {
  const [viewAll, setViewAll] = useState(false);
  // const actionCtx = useContext(ActionContext);

  // const InfoIcon = (
  //   <svg
  //     className="img"
  //     width="12"
  //     height="12"
  //     viewBox="0 0 12 12"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
  //       stroke="#8B8B8B"
  //       strokeWidth="1.5"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //     />
  //   </svg>
  // );

  return (
    <div className="new-preview-detail-box-wrap card-bg border-theme">
      {/* img top box start */}
      <div
        // style={{ marginTop: "3rem" }}
        onClick={() => {
          console.log(amount);
        }}
        className="img-top-box-wrap border-theme-bottom"
      >
        <div className="img-wrap">
          <figure className="img-box">
            <img src={bankIconDefault} alt="" className="img" />
          </figure>
          {/* <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div> */}
        </div>
        {/* amount date box styart */}
        <div className="amount-date-box">
          <p className="amount">
            {list?.length > 0
              ? bulkAmount
              : `${getCurrencySymbol(
                  globalInfo?.currency || "NGN"
                )}${amount}` || ""}
          </p>
          <p className="date grey-white-color-white">
            {valueSchedule
              ? formatDate(valueSchedule)
              : formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div
        className={`middle-content-wrap ${
          !hideSubInfo && "border-theme-bottom"
        }`}
      >
        {collapse ? (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Recipients:</p>
              <div className="value">
                <div
                  onClick={() => {
                    viewAll ? setViewAll(false) : setViewAll(true);
                  }}
                  className="see-all-box"
                >
                  <span>({list?.length || 0})</span>
                  <p className="see-all-text">
                    {viewAll ? `Close` : `See all`}
                  </p>
                </div>
                {/*  */}
                <div
                  className={`view-all-box card-bg ${
                    viewAll && "view-all-box-show"
                  }`}
                >
                  {" "}
                  <div className="wrap">
                    {list?.map((chi, idx) => {
                      return (
                        <div key={idx} className="child-wrap">
                          <figure className="img-box">
                            <img src={bankIconDefault} alt="" className="img" />
                          </figure>
                          <div className="text-box">
                            <p className="name">{`+234 ${chi?.phone}`}</p>
                            <p className="text grey-white-color">{` ₦ ${chi?.amount}`}</p>
                          </div>
                          <div
                            onClick={() => {
                              handleRemoveFromList(chi);
                            }}
                            className="cancel-box"
                          >
                            <FaTimes className="icon" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            {/* <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">{bulkAmount || 0}</div>
            </div> */}
            {/* box end */}
          </>
        ) : (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Link Title:</p>
              <p className="value">{email || ""}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Link Type:</p>
              <p className="value">{linkType || "---"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            {hideSubInfo && (
              <div className="box">
                <p className="label grey-white-color-white">Currency:</p>
                <p className="value">{globalInfo?.currency || "---"}</p>
              </div>
            )}
            {/* box end */}
            {/* box start */}
            {linkDuration && (
              <div className="box">
                <p className="label grey-white-color-white">Link Type:</p>
                <p className="value">{linkDuration || "---"}</p>
              </div>
            )}
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Note:</p>
              <p className="value">{narration || "---"}</p>
            </div>
            {/* box end */}
          </>
        )}
      </div>
      {/* middle content wrap end */}
      {/* account drop wrap start */}
      {!hideSubInfo &&  <ChangeSourceAccount />}
      {/* account drop wrap end */}
      {!hideSubInfo &&  (
        <div
          className="border border-theme-top"
          style={{ marginTop: "2rem" }}
        ></div>
      )}
      {/* charge and rate box start */}
      {!hideSubInfo &&  (
        <div className="charge-rate-box-wrap ">
          <div className="img-wrap">
            <figure className="img-box">
              <svg
                className="img"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6583 21.1963C16.9342 21.1963 21.2112 16.9193 21.2112 11.6434C21.2112 6.36753 16.9342 2.09058 11.6583 2.09058C6.38242 2.09058 2.10547 6.36753 2.10547 11.6434C2.10547 16.9193 6.38242 21.1963 11.6583 21.1963Z"
                  fill="#EA872D"
                />
                <path
                  d="M11.6583 7.82228V11.6434M11.6583 15.4646H11.6679M21.2112 11.6434C21.2112 16.9193 16.9342 21.1963 11.6583 21.1963C6.38242 21.1963 2.10547 16.9193 2.10547 11.6434C2.10547 6.36753 6.38242 2.09058 11.6583 2.09058C16.9342 2.09058 21.2112 6.36753 21.2112 11.6434Z"
                  stroke="#FFF6ED"
                  strokeWidth="1.43293"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
          </div>
          <p className="text">
            You would be charged <span>1%</span> but not more than{" "}
            <span> 2,000 </span> on every transaction.
          </p>
        </div>
      )}
      {/* charge and rate box end */}
    </div>
  );
};

export default NewPreviewDetailBox;
