import { dashboard_add_money_route_group } from "./add-money";
import { dashboard_bill_payment_routes_group } from "./bill-payment";
import { dashboard_card_route_group } from "./card";
import { dashboard_customer_route_group } from "./customer";
import { dashboard_global_account_route_group } from "./global-account";
import { dashboard_home_route_group } from "./home";
import { invoice_route_group } from "./invoice";
import { dasboard_invoice_finance_routes } from "./invoice-finance";
import { dasboard_loan_finance_routes } from "./loan";
import { dashboard_overdraft_routes } from "./overdraft";
import { dashboard_payment_link_route_group } from "./payment-link";
import { dashboard_payment_request_route_group } from "./payment-request";
import { dashboard_payroll_route_group } from "./payroll";
import { dashboard_pos_route_group } from "./pos";
import { dasshboard_profile_route_group } from "./profile";
import { dashboard_role_and_permission_route_group } from "./role-and-permission";
import { dashboard_schedule_payment_route_group } from "./schedule-payment";
import { dashboard_send_money_route_group } from "./send-money";
import { dashboard_settings_route_group } from "./settings";
import { store_dashboard_routes_group } from "./store";
import { dashboard_sub_account_route_group } from "./sub-account";
import { dashboard_transaction_route_group } from "./transaction";



export const dashboard_routes_group = [
    ...dashboard_home_route_group,
    ...dashboard_add_money_route_group,
    ...dashboard_send_money_route_group,
    ...dashboard_transaction_route_group,
    ...dashboard_payment_link_route_group,
    ...invoice_route_group,
    ...dashboard_bill_payment_routes_group,
    ...dashboard_schedule_payment_route_group,
    ...dashboard_card_route_group,
    ...dashboard_pos_route_group,
    ...dashboard_payroll_route_group,
    ...dashboard_settings_route_group,
    ...dasshboard_profile_route_group,
    ...store_dashboard_routes_group,
    ...dasboard_invoice_finance_routes,
    ...dasboard_loan_finance_routes,
    ...dashboard_overdraft_routes,
    ...dashboard_sub_account_route_group,
    ...dashboard_role_and_permission_route_group,
    ...dashboard_payment_request_route_group,
    ...dashboard_customer_route_group,
    ...dashboard_global_account_route_group,
]