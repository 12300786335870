import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import ActionContext from "../../context/ActionContext";
import { markNotificationAsRead } from "../../redux/paymentRequest";
// import playNotificationSound from "./SoundNotification";
// import ConfettiWithSound from "./ConffetiWithSound";

const MoneyReceivedModal = ({ detail, visible, onCancel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const iconMoney = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9C19 10.45 18.57 11.78 17.83 12.89C16.75 14.49 15.04 15.62 13.05 15.91C12.71 15.97 12.36 16 12 16C11.64 16 11.29 15.97 10.95 15.91C8.96 15.62 7.25 14.49 6.17 12.89C5.43 11.78 5 10.45 5 9C5 5.13 8.13 2 12 2C15.87 2 19 5.13 19 9Z"
        fill="#EA872D"
      />
      <path
        d="M21.2501 18.47L19.6001 18.86C19.2301 18.95 18.9401 19.23 18.8601 19.6L18.5101 21.07C18.3201 21.87 17.3001 22.11 16.7701 21.48L12.0001 16L7.23008 21.49C6.70008 22.12 5.68008 21.88 5.49008 21.08L5.14008 19.61C5.05008 19.24 4.76008 18.95 4.40008 18.87L2.75008 18.48C1.99008 18.3 1.72008 17.35 2.27008 16.8L6.17008 12.9C7.25008 14.5 8.96008 15.63 10.9501 15.92C11.2901 15.98 11.6401 16.01 12.0001 16.01C12.3601 16.01 12.7101 15.98 13.0501 15.92C15.0401 15.63 16.7501 14.5 17.8301 12.9L21.7301 16.8C22.2801 17.34 22.0101 18.29 21.2501 18.47Z"
        fill="#333333"
        className="dull-bright-filter"
      />
      <path
        d="M12.58 5.98L13.17 7.15999C13.25 7.31999 13.46 7.48 13.65 7.51L14.72 7.68999C15.4 7.79999 15.56 8.3 15.07 8.79L14.24 9.61998C14.1 9.75998 14.02 10.03 14.07 10.23L14.31 11.26C14.5 12.07 14.07 12.39 13.35 11.96L12.35 11.37C12.17 11.26 11.87 11.26 11.69 11.37L10.69 11.96C9.96997 12.38 9.53997 12.07 9.72997 11.26L9.96997 10.23C10.01 10.04 9.93997 9.75998 9.79997 9.61998L8.96997 8.79C8.47997 8.3 8.63997 7.80999 9.31997 7.68999L10.39 7.51C10.57 7.48 10.78 7.31999 10.86 7.15999L11.45 5.98C11.74 5.34 12.26 5.34 12.58 5.98Z"
        fill="white"
      />
    </svg>
  );
  const handleMarkAsRead = async (param) => {
    const obj = {
      notifiactionId: String(param),
    };
    navigate("/dashboard-transaction");
    dispatch(markNotificationAsRead(obj));
    onCancel();
  };

  // UseNotificationSound

  return (
    <>
      <RavenModal
        onBtnClick={() => {
          handleMarkAsRead(detail?.id);
        }}
        dontShowClose
        btnLabel="View on transaction table"
        onClose={onCancel}
        btnColor="black-light"
        visble={visible}
      >
        <div className="money-received-index-wrap-modal">
          <div className="wrap">
            <div className="img-wrap">
              <figure className="img-box">{iconMoney}</figure>
            </div>
            <p className="title">Money Received </p>
            <p className="text grey-white-color">
              {detail?.notification_body?.replace("NGN", "\u{020A6}") ||
                `Money received into your account, check your transaction for more details`}
            </p>
          </div>
        </div>
      </RavenModal>
    </>
  );
};

export default MoneyReceivedModal;
