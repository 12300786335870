import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { removePendinInvite } from "../../../../redux/settings";
import { trimLongString } from "../../../../utils/helper/Helper";
import { getInvitedReferee } from "../../../../redux/verifications";

const DeleteInviteRefModal = ({ visible, onClose, detail }) => {
  const dispatch = useDispatch();
  const { loadingStatement } = useSelector((state) => state.settings);

  const handleSubmit = async () => {
    // console.log(detail);
    const obj = {
      ref_id: String(detail?.id),
    };
    // console.log(obj);
    // return;
    const data = await dispatch(removePendinInvite(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      dispatch(getInvitedReferee());
      onClose();
    }
  };

  return (
    <DeleteModal
      title={`Delete Referee ${trimLongString(detail?.referee_email, 18)}`}
      text={`Removing this referee, would remove their access from accepting the invite process.`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
      loading={loadingStatement}
    />
  );
};

export default DeleteInviteRefModal;
