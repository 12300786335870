import React, { Fragment } from "react";
import "../../../styles/auth/AuthLayoutOne_Five.css";
import { useDarkMode } from "../../../utils/themes/useDarkMode";
import {
  GlobalStyles,
  darkTheme,
  lightTheme,
} from "../../../utils/themes/theme";
import { ThemeProvider } from "styled-components";

const AuthLayoutOneFive = ({
  step,
  onNextStep,
  children,
  stepList,
  canClick,
}) => {
  const [theme] = useDarkMode(localStorage.getItem("theme"));
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  
  return (
    <Fragment>
      
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />
        <div className="auth_layout_version_one_point_five_wrap">
          <div className="left-box">
            <div className="wrapper">
              <div className="bottom-wrap white-black-bg">
                <div className="big-text-wrap">
                  <p className="text grey-white-color">Banking for</p>
                  <p className="text grey-white-color">
                    <span>Professionals</span>
                  </p>
                </div>
                <div className="small-text-wrap">
                  <p className="text grey-white-color">
                    Complete this steps to onboard your business.
                  </p>
                </div>
                {/* step start */}
                <div className="step-box-wrap">
                  {stepList?.map((chi, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`box grey-bg ${
                          step === idx + 1 && "box-active black-white-bg"
                        }`}
                        style={{ cursor: canClick && "pointer" }}
                        onClick={() => {
                          onNextStep && onNextStep(idx + 1);
                        }}
                      >
                        <div
                          className={`num ${
                            step === idx + 1 && "white-black-bg"
                          }`}
                        >
                          <span
                            className={`${
                              step === idx + 1 && "black-white-color"
                            }`}
                          >
                            {idx + 1}
                          </span>
                        </div>
                        <p
                          className={`text ${
                            step === idx + 1 && "white-black-color"
                          }`}
                        >
                          {chi}
                        </p>
                      </div>
                    );
                  })}
                </div>
                {/* step end */}
              </div>
            </div>
          </div>
          {/* left box end */}
          <div className="right-box">
            <div className="wrapper-auth-content">{children}</div>
          </div>
        </div>
      </ThemeProvider>
    </Fragment>
  );
};

export default AuthLayoutOneFive;
