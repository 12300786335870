import {
  RavenButton,
  RavenInputField,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  RavenToggleSlide,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/payroll/PayrollDisbursement.css";
import CancelCurrentPayroll from "./modals/CancelCurrentPayroll";
import {
  addIncrementOrDeduction,
  approveCurrentPayroll,
  disburseCurrentPayroll,
  // getPayrollSetup,
  getPendingDisbursment,
} from "../../../redux/payroll";
import {
  filterEmptyColumns,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  getPageNum,
  getTotalPage,
  trimLongString,
} from "../../../utils/helper/Helper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProcessPayrollModal from "./modals/ProcessPayrollModal";
import TransactionPinModal from "../../../components/reusables/TransactionPinModal";
import SuccessModal from "../../../components/reusables/SuccessModal";
import RemoveEmpFRomCurModal from "./modals/RemoveEmpFRomCurModal";
import NoContentBox from "../../../components/reusables/NoContentBox";
import AddDeductionModal from "./modals/AddDeductionModal";
import useDebounce from "../../../utils/helper/UseDebounce";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import MobileEmployeeViewCurrent from "./modals/MobileViewCurrentDisbursement";
import useWindowSize from "../../../utils/helper/UseWindowSize";
// import PerPageComponent from "../../../components/reusables/PerPageComponent";
import ActionContext from "../../../context/ActionContext";

const headerList = [" NAME", "ACCOUNT NO.", "BANK NAME", "USERNAME", "SALARY"];

const avatarFormatWrap = (param) => {
  return (
    <div className="text-avatar-box">
      <div className="avatar-box">
        <span>{`${param?.split("")[0]?.charAt(0)} ${param
          ?.split("")[1]
          ?.charAt(0)}`}</span>
      </div>
      <p className="text">{param}</p>
    </div>
  );
};

const PayrollCurrentDisbursement = () => {
  const [singleChild, setSingleChild] = useState({});
  const { user, user_resource_and_permissions } = useSelector(
    (state) => state.user
  );
  const {
    pending_disbursement,
    loadingManual,
    loading,
    loadingDeduct,
    payrollSettings,
  } = useSelector((state) => state.payroll);
  const [refreshTable, setRefreshTable] = useState(false);
  const [showModal, setShowModal] = useState({
    cancel_payroLL: false,
    disburse_now: false,
    approve: false,
    success: false,
    pin: false,
    remove_from_payroll: false,
    add_deduction: false,
    deduction_pin: false,
    mobile_view: false,
  });
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deductionIncrementObj, setDeductionIncrementObj] = useState({});
  const [details, setDetails] = useState({
    transfer_pin: "",
  });
  const [dropToshow, setDropToShow] = useState("");

  const size = useWindowSize();

  const payrollCurrentActions = (param, idx) => {
    return (
      <div className="payroll-current-action-wrap">
        {loading ? (
          <>
            <Skeleton width={30} height={30} circle />
            <Skeleton width={35} height={20} style={{ borderRadius: "2rem" }} />
          </>
        ) : (
          <>
            <figure
              className="img-box grey-bg drop-down-box-payroll darken-action-bg"
              onClick={() => {
                // console.log(param);
                dropToshow === param?.id
                  ? setDropToShow("")
                  : setDropToShow(param?.id);
              }}
            >
              {/* drop down box start */}
              <div
                className={`drop-down-wrap-payroll border-thin ${
                  dropToshow === param?.id && "drop-down-wrap-payroll-show"
                } ${idx >= 3 && "drop-down-wrap-payroll-up"}`}
              >
                {user_resource_and_permissions?.allowed_permissions?.includes(
                  "can_add_or_deduct_from_employees_salary"
                ) && (
                  <p
                    onClick={() => {
                      const newObj = { ...param, deduction_type: "deduction" };
                      setSingleChild(newObj);
                      setShowModal((prev) => {
                        return { ...prev, add_deduction: true };
                      });
                    }}
                    className="add-text border-thin"
                  >
                    Add Deduction
                  </p>
                )}
                {user_resource_and_permissions?.allowed_permissions?.includes(
                  "can_add_or_deduct_from_employees_salary"
                ) && (
                  <p
                    onClick={() => {
                      const newObj = { ...param, deduction_type: "addition" };
                      setSingleChild(newObj);
                      setShowModal((prev) => {
                        return { ...prev, add_deduction: true };
                      });
                    }}
                    className="add-text"
                  >
                    Add Increment
                  </p>
                )}
              </div>
              {/* drop down box end */}
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="img dull-bright-filter"
              >
                <path
                  d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
            <div className="tooltip-hover-wrap">
              <RavenToolTip
                color={`black-light`}
                text={
                  param?.status === "pending_approval"
                    ? `Remove ${
                        param?.account_name || param?.raven_account_name || "--"
                      } from current disbursement`
                    : `Add ${
                        param?.account_name || param?.raven_account_name || "--"
                      } to current disbursement`
                }
                position={`top-left`}
              />
              <RavenToggleSlide
                color={`black-light`}
                id={`kjjs-${param?.id}`}
                checked={param?.status === "pending_approval" ? true : false}
                onChange={() => {
                  // console.log(param);
                  setSingleChild(param);
                  setShowModal((prev) => {
                    return { ...prev, remove_from_payroll: true };
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    getCurrentDisbursement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    if (payrollSettings?.payroll_status !== "1") {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollSettings]);

  const getCurrentDisbursement = async (cur) => {
    const refId = localStorage.getItem("pending-transaction-ref-id");
    // console.log(refId);
    const payload = {
      per_page: actionCtx?.perPageVal?.value,
      current_page: cur || 1,
      ref: refId,
    };
    dispatch(getPendingDisbursment(payload));
  };

  const handleFinish = () => {
    // setRefreshTable(!refreshTable);
    setShowModal((prev) => {
      return {
        ...prev,
        cancel_payroLL: false,
        disburse_now: false,
        approve: false,
        success: true,
        pin: false,
        remove_from_payroll: false,
        add_deduction: false,
        deduction_pin: false,
      };
    });
    setRefreshTable(!refreshTable);
    setDetails((prev) => {
      return { ...prev, transfer_pin: "" };
    });
  };

  const handleDisburseNow = async (pin, token) => {
    const obj = {
      pin: pin,
      two_factor_token: token,
      reference: pending_disbursement?.salary_disbursements?.reference,
      selected_account_number: actionCtx?.currentAccount?.account_number,
    };
    const data = await dispatch(
      disburseCurrentPayroll(filterEmptyColumns(obj))
    );
    if (data?.payload?.data?.status === "success") {
      actionCtx?.setConfettiVal();
      handleFinish();
    } else {
      setDetails((prev) => {
        return { ...prev, transfer_pin: "" };
      });
    }
  };

  const handleApproveDisbursement = async (pin, token) => {
    const obj = {
      pin: pin,
      two_factor_token: token,
      reference: pending_disbursement?.salary_disbursements?.reference,
      selected_account_number: actionCtx?.currentAccount?.account_number,
    };
    // console.log(obj);
    // return
    const data = await dispatch(approveCurrentPayroll(filterEmptyColumns(obj)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
      actionCtx?.setConfettiVal();
    } else {
      setDetails((prev) => {
        return { ...prev, transfer_pin: "" };
      });
    }
  };

  const handleDecductionIncrement = async (pin, token) => {
    const obj = {
      ...deductionIncrementObj,
      pin: pin,
      two_factor_token: token,
    };
    const data = await dispatch(addIncrementOrDeduction(filterEmptyColumns(obj)));
    if (data?.payload?.data?.status === "success") {
      setShowModal((prev) => {
        return {
          ...prev,
          deduction_pin: false,
        };
      });
      setRefreshTable(!refreshTable);
      setDetails((prev) => {
        return { ...prev, transfer_pin: "" };
      });
    }
  };

  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const refId = localStorage.getItem("pending-transaction-ref-id");
      console.log(refId);
      const payload = {
        per_page: 10,
        current_page: 1,
        ref: refId,
        search: search,
      };
      dispatch(getPendingDisbursment(payload));
      // console.log(obj);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      getCurrentDisbursement();
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Current Disbursement`}
          text={`Easily manage your staffs  payment and deducations`}
          back
          onBack={() => navigate(-1)}
        />
        {/* payroll disrbusment start */}
        {/* <div className="payroll-current-disbursement-wrap-box"> */}
        <div className="payroll-current-disbursemen-top-detail-card-box card-bg">
          {/* left box start */}
          <div
            onClick={() => {
              // console.log(pending_disbursement);
            }}
            className="left-box"
          >
            {/* wallet s\cash box start */}
            <div className="wallet-cash-box">
              {/* bosx styart */}
              <div className="box">
                {loading ? (
                  <Skeleton width={`100%`} height={20} />
                ) : (
                  <p className="value">
                    {formatNumWithCommaNairaSymbol(
                      pending_disbursement?.total_salary || "0.00"
                    )}
                  </p>
                )}
                <p className="label grey-white-color">
                  TOTAL SALARY TO BE PAID
                </p>
              </div>
              {/* bosx end */}
              {/* bosx styart */}
              <div className="box">
                {loading ? (
                  <Skeleton width={`100%`} height={20} />
                ) : (
                  <p className="value">
                    {formatNumWithCommaNairaSymbol(
                      pending_disbursement?.total_deductions || "0.00"
                    )}
                  </p>
                )}
                <p className="label grey-white-color">CUMULATIVE DEDUCTIONS</p>
              </div>
              {/* bosx end */}
              {/* bosx styart */}
              <div className="box">
                {loading ? (
                  <Skeleton width={`50%`} height={20} />
                ) : (
                  <p className="value">
                    {pending_disbursement?.number_of_employees || "0"}
                  </p>
                )}
                <p className="label grey-white-color">EMPLOYEES TO BE PAID</p>
              </div>
              {/* bosx end */}
              {/* bosx styart */}
              <div className="box">
                {loading ? (
                  <Skeleton width={`100%`} height={20} />
                ) : (
                  <p className="value">
                    {formatNumWithCommaNairaSymbol(
                      pending_disbursement?.total_overdraft || "0.00"
                    )}
                  </p>
                )}
                <p className="label grey-white-color">OVERDRAFTS DISBURSED</p>
              </div>
              {/* bosx end */}
            </div>
            {/* wallet s\cash box end */}
            {/* btn-wto box start */}
            <div className="btn-two-box">
              {pending_disbursement?.salary_disbursements?.status ===
                "pending_approval" ||
              pending_disbursement?.salary_disbursements?.status ===
                "cancelled" ? (
                <>
                  {loading ? (
                    <Skeleton
                      width={150}
                      height={40}
                      style={{ borderRadius: ".8rem" }}
                    />
                  ) : (
                    <>
                      {Number(pending_disbursement?.number_of_employees) >
                        0 && (
                        <RavenButton
                          color={`black-light`}
                          label={
                            pending_disbursement?.show_approve_button === 0
                              ? `Disburse now`
                              : "Approve disbursement"
                          }
                          style={{ backgroundColor: "#333333" }}
                          onClick={() => {
                            setSingleChild(
                              pending_disbursement?.salary_disbursements
                            );
                            pending_disbursement?.show_approve_button === 0
                              ? setShowModal((prev) => {
                                  return { ...prev, disburse_now: true };
                                })
                              : setShowModal((prev) => {
                                  return { ...prev, pin: true };
                                });
                          }}
                        />
                      )}
                    </>
                  )}
                  {loading ? (
                    <Skeleton
                      width={150}
                      height={40}
                      style={{ borderRadius: ".8rem" }}
                    />
                  ) : (
                    <>
                      {" "}
                      {user_resource_and_permissions?.allowed_permissions?.includes(
                        "can_cancel_salary_disbursement"
                      ) && (
                        <RavenButton
                          onClick={() => {
                            setSingleChild(
                              pending_disbursement?.salary_disbursements
                            );
                            setShowModal((prev) => {
                              return { ...prev, cancel_payroLL: true };
                            });
                          }}
                          color={
                            pending_disbursement?.salary_disbursements
                              ?.status === "cancelled"
                              ? `purple-light`
                              : `error-light`
                          }
                          label={
                            pending_disbursement?.salary_disbursements
                              ?.status === "cancelled"
                              ? `Activate disbursement`
                              : `Deactivate disbursement`
                          }
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )}
              {pending_disbursement?.salary_disbursements?.status ===
                "pending_processing" ||
              pending_disbursement?.salary_disbursements?.status ===
                "processing" ||
              pending_disbursement?.salary_disbursements?.status ===
                "processed" ? (
                <RavenButton
                  color={`black-light`}
                  label={
                    pending_disbursement?.salary_disbursements?.status ===
                    "pending_processing"
                      ? `Payroll processing`
                      : "Payroll disbursed"
                  }
                  style={{ backgroundColor: "#333333" }}
                  disabled
                />
              ) : (
                ""
              )}
            </div>
            {/* btn-wto box end */}
          </div>
          {/* left box end */}
          {/* right box start */}
          <div className="right-box"></div>
          {/* right box end */}
        </div>
        {/* top detail card box send */}
        <ContainerWrapper
          pagination={
            pending_disbursement?.salary_transction?.next_page_url_params ||
            pending_disbursement?.salary_transction?.prev_page_url_params ? (
              <RavenPagination
                color={`black-light`}
                blackHover
                currentPage={
                  Number(
                    pending_disbursement?.salary_transction?.current_page
                  ) || 1
                }
                nextPage={
                  pending_disbursement?.salary_transction?.next_page_url_params
                }
                prevPage={
                  pending_disbursement?.salary_transction?.prev_page_url_params
                }
                onNext={(e) => {
                  getCurrentDisbursement(getPageNum(e));
                }}
                onPrev={(e) => {
                  getCurrentDisbursement(getPageNum(e));
                }}
                totalPage={
                  getTotalPage(
                    Number(pending_disbursement?.salary_transction?.per_page),
                    Number(pending_disbursement?.salary_transction?.total)
                  ) || 1
                }
                onNumView={(e) => {
                  getCurrentDisbursement(e);
                }}
              />
            ) : (
              ""
            )
          }
          width={`100%`}
          dontShowHeader
        >
          {/* table wrap start */}
          <div className="payroll-current-disbursemen-table-wrap-content-box">
            <div
              style={{
                justifyContent: "unset",
                alignItems: "center",
                gap: "1.5rem",
                position: "relative",
                zIndex: 5,
              }}
              className="total-employee-text-box"
            >
              {/* per page start */}
              {/* <PerPageComponent /> */}
              {/* per page end */}
              <p
                style={{ marginRight: "auto" }}
                className="total-employee-text"
              >
                Total Number of Employee{" "}
                <span>({pending_disbursement?.total_employees || "0"})</span>
              </p>
              <form autoComplete="off" action="">
                <RavenInputField
                  type={`search`}
                  color={`black-light`}
                  placeholder={`Search`}
                  className={`payroll-search`}
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value)}
                />
              </form>
            </div>

            {/* table wrap start */}
            {/* table box start */}
            {pending_disbursement?.salary_transction?.data?.length > 0 ? (
              <div className="table-box">
                <RavenTable
                  className={
                    formatMetaData(user?.theme_display_settings)?.table_font ===
                      "bold" && "table-business"
                  }
                  headerList={headerList}
                  action
                >
                  {pending_disbursement?.salary_transction?.data?.map(
                    (chi, idx) => {
                      const {
                        account_number,
                        bank,
                        account_name,
                        net_amount,
                        username,
                        raven_account_name,
                      } = chi;
                      return (
                        <RavenTableRow
                          className={`payroll-table-row ${
                            dropToshow === chi?.id && "payroll-table-row-index"
                          }`}
                          onRowClick={() => {
                            setDropToShow("");
                          }}
                          key={idx}
                          one={
                            avatarFormatWrap(
                              account_name || raven_account_name
                            ) || "---"
                          }
                          two={account_number || "---"}
                          four={username || "---"}
                          three={bank || "---"}
                          loading={loading}
                          five={
                            formatNumWithCommaNairaSymbol(net_amount) || "---"
                          }
                          ManualAddActions={() =>
                            user_resource_and_permissions?.allowed_permissions?.includes(
                              "can_add_or_deduct_from_employees_salary"
                            ) && payrollCurrentActions(chi, idx)
                          }
                        />
                      );
                    }
                  )}
                </RavenTable>

                <div className="mobile-table-box">
                  {pending_disbursement?.salary_transction?.data?.map(
                    (chi, idx) => {
                      const {
                        account_number,
                        bank,
                        account_name,
                        net_amount,
                        username,
                        raven_account_name,
                      } = chi;
                      // console.log(chi);
                      return (
                        <MobileTableCard
                          key={idx}
                          amount={formatNumWithCommaNairaSymbol(net_amount)}
                          text={`${account_number} • ${trimLongString(
                            bank,
                            15
                          )}`}
                          avatar={`${
                            account_name || username || raven_account_name
                          }`}
                          onRowClick={() => {
                            if (
                              user_resource_and_permissions?.allowed_permissions?.includes(
                                "can_add_or_deduct_from_employees_salary"
                              )
                            ) {
                              setSingleChild(chi);
                              setShowModal((prev) => {
                                return { ...prev, mobile_view: true };
                              });
                            }
                          }}
                          loading={loading}
                          title={account_name || raven_account_name}
                          showImg
                        />
                      );
                    }
                  )}
                </div>
              </div>
            ) : (
              <NoContentBox title={`No current disbursement`} />
            )}
            {/* table box end */}
          </div>
        </ContainerWrapper>
        {/* </div> */}

        {/* payroll disrbusment end */}
      </DashboardLayout>
      {/* delete mod start */}
      <CancelCurrentPayroll
        visible={showModal?.cancel_payroLL}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, cancel_payroLL: false };
          });
        }}
        detail={singleChild}
        onFinish={() => {
          setShowModal((prev) => {
            return { ...prev, cancel_payroLL: false };
          });
          setRefreshTable(!refreshTable);
        }}
      />
      {/* delete mod end */}

      {/* delete mod start */}
      <ProcessPayrollModal
        visible={showModal?.disburse_now}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, disburse_now: false };
          });
        }}
        onProceed={() => {
          setShowModal((prev) => {
            return { ...prev, disburse_now: false, pin: true };
          });
        }}
        detail={singleChild}
        onFinish={handleFinish}
      />
      {/* delete mod end */}
      {/* pin modal start */}
      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          pending_disbursement?.show_approve_button === 0
            ? handleDisburseNow(pin, token)
            : handleApproveDisbursement(pin, token);
        }}
        // value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        btnLabel={`Proceed`}
        loading={loadingManual}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Payroll Disbursed Successfully"
        text={``}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
      {/* add deduction modal startx */}
      <AddDeductionModal
        visible={showModal?.add_deduction}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, add_deduction: false };
          });
        }}
        detail={singleChild}
        onFinish={handleFinish}
        setDeductionIncrementObj={(obj) => {
          setDeductionIncrementObj(obj);
          setShowModal((prev) => {
            return { ...prev, deduction_pin: true, add_deduction: false };
          });
        }}
      />
      {/* add deduction modal end */}
      {/* pin modal start */}
      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          handleDecductionIncrement(pin, token);
        }}
        // value={details?.transfer_pin}
        visible={showModal.deduction_pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, deduction_pin: false, add_deduction: true };
          });
        }}
        btnLabel={`Proceed`}
        loading={loadingDeduct}
      />
      {/* pin modal end */}
      {/* delete mod start */}
      <MobileEmployeeViewCurrent
        visible={showModal?.mobile_view && size.width < 900}
        detail={singleChild}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, mobile_view: false };
          });
        }}
        onRemoveFromPayroll={() => {
          setShowModal((prev) => {
            return { ...prev, mobile_view: false, remove_from_payroll: true };
          });
        }}
        onAddDeduction={() => {
          const newObj = {
            ...singleChild,
            deduction_type: "deduction",
          };
          setSingleChild(newObj);
          setShowModal((prev) => {
            return { ...prev, add_deduction: true, mobile_view: false };
          });
        }}
        onAddIncrement={() => {
          const newObj = {
            ...singleChild,
            deduction_type: "addition",
          };
          setSingleChild(newObj);
          setShowModal((prev) => {
            return { ...prev, add_deduction: true, mobile_view: false };
          });
        }}
      />
      {/* delete mod end */}
      {/* delete mod start */}
      <RemoveEmpFRomCurModal
        visible={showModal?.remove_from_payroll}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, remove_from_payroll: false };
          });
        }}
        detail={singleChild}
        onFinish={() => {
          setShowModal((prev) => {
            return { ...prev, remove_from_payroll: false };
          });
          setRefreshTable(!refreshTable);
        }}
      />
      {/* delete mod end */}
    </>
  );
};

export default PayrollCurrentDisbursement;
