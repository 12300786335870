import {  RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import React, {  useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/payment-links/PaymentLink.css";
// import oneTimeIcon from "../../../assets/request-one-time-icon.svg";
// import reccuringIcon from "../../../assets/request-one-time-icon (1).svg";
// import { FaCheck } from "react-icons/fa";
// import moment from "moment";
// import PreviewPaymentDetailsBox from "./PreviePaymentLinkBox";
// import DeleteModal from "../../../components/reusables/DeleteModal";
// import DeletePaymentModal from "./DeletePaymentModal";
import { useDispatch, useSelector } from "react-redux";
// import { createPaymentLink } from "../../../redux/requestMoney";
// import { formatNumWithoutCommaNaira } from "../../../utils/helper/Helper";
import SuccessModal from "../../../components/reusables/SuccessModal";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import NewPreviewDetailBox from "./NewPreviewLinkBox";
// import ActionContext from "../../../context/ActionContext";
import { validateEmail } from "../../../utils/helper/ValidateInput";
import { createCustomers } from "../../../redux/customers";
// import { validateEmail } from "../../../utils/helper/ValidateInput";

const CreateCustomerIndex = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
//   const actionCtx = useContext(ActionContext);
  const { loading } = useSelector((state) => state.customers);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const stepList = ["Customer details"];
  // eslint-disable-next-line no-unused-vars
  const [paymentType, setPaymentType] = useState("Single Charge");

  const [details, setDetails] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const [error, setError] = useState({
    email: false,
  });

  const handleSubmit = async () => {
    if (!validateEmail(details.email)) {
      toast.error("Invalid email address!", { position: "top-right" });
      setError((prev) => {
        return { ...prev, email: true };
      });
      return;
    }
    const obj = {
        customer_email: details?.email , 
        customer_name: `${details?.first_name} ${details?.last_name}`,
        customer_phone: `0${details?.phone}`
    };
    // console.log(obj);
    // return
    const data = await dispatch(createCustomers(obj));
    if (data?.payload?.data?.status === "success") {
      setDetails({
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
      });
      navigate("/dashboard-customers")
    }
  };

  const size = useWindowSize();
  // const [showError, setShowError] = useState({
  //   num: false,
  //   email: false,
  //   amount: false,
  //   meter: false,
  // });

  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_create_customer"
      )
    ) {
      navigate("/dashboard-customers" || -1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_resource_and_permissions]);

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          back
          onBack={() => navigate("/dashboard-customers" || -1)}
          title={`Create Customer`}
          text={`Add a customer to your list of customers.`}
        />
        {/* wrapper start */}
        <ContainerWrapper
          width={size?.width < 900 && `90%`}
          btnText={`Save Customer`}
          stepList={stepList}
          activeStep={step}
          back={step > 1}
          loading={loading}
          stepClickable={
            paymentType === "Single Charge"
              ? details?.request_amount &&
                details?.title &&
                details?.request_description
              : details?.request_amount &&
                details?.title &&
                details?.request_description &&
                details?.expiry_date
          }
          onStepClick={(e) => {
            paymentType === "Single Charge"
              ? details?.request_amount &&
                details?.title &&
                details?.request_description &&
                setStep(e)
              : details?.request_amount &&
                details?.title &&
                details?.request_description &&
                details?.expiry_date &&
                setStep(e);
          }}
          disabled={
            !details.email || !details?.first_name || !details.last_name
          }
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          onBtnClick={() => {
            if (step === 1) {
             handleSubmit()
            }

            // step === 2 && handleSubmit();
          }}
        >
          {/* request money wrap start */}
          <div className="request-money-wrap-box">
            {step === 1 && (
              <>
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  {" "}
                  <RavenInputField
                    type={`text`}
                    label={`First Name*`}
                    color={`black-light`}
                    placeholder={`e.g Timi`}
                    // showError={showError?.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={details?.first_name}
                    name={`first_name`}
                    capitalize
                    // className={`below-expiry`}
                  />
                </form>
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  {" "}
                  <RavenInputField
                    type={`text`}
                    label={`Last Name*`}
                    color={`black-light`}
                    placeholder={`e.g Paul`}
                    // showError={showError?.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={details?.last_name}
                    name={`last_name`}
                    // className={`below-expiry`}
                    capitalize
                  />
                </form>
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  <RavenInputField
                    type={`email`}
                    label={`Email Address* `}
                    color={`black-light`}
                    placeholder={`E.g ayeni@gmail.com`}
                    // textareaRow={4}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      handleChange(e);
                      setError((prev) => {
                        return { ...prev, email: false };
                      });
                    }}
                    value={details?.email}
                    showError={error?.email}
                    name={`email`}
                  />
                </form>
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  {" "}
                  <RavenInputField
                    type={`phone`}
                    label={`Phone Number`}
                    color={`black-light`}
                    placeholder={`E.g 816263....`}
                    onChange={handleChange}
                    value={details?.phone}
                    name={`phone`}
                  />
                </form>
              </>
            )}
            {/* step 2 start -------------------------- */}
            {step === 2 && (
              <>
                {/* <PreviewPaymentDetailsBox
                  narration={details?.request_description}
                  linkType={paymentType}
                  email={details?.title}
                  amount={details?.request_amount}
                  linkDuration={details?.expiry_date}
                  value={details?.agree}
                  onChange={(e) =>
                    setDetails((prev) => {
                      return { ...prev, agree: e.target.checked };
                    })
                  }
                  id={`nnsj873`}
                  checked={details?.agree}
                /> */}
                <NewPreviewDetailBox
                  linkDuration={details?.expiry_date}
                  amount={details?.request_amount}
                  narration={details?.request_description}
                  linkType={paymentType}
                  email={details?.title}
                />
              </>
            )}
            {/* step 2 end -------------------------- */}
            {/* <div className="space-bottom"></div> */}
          </div>
          {/* request money wrap end */}
        </ContainerWrapper>
        {/* wrapper end */}
      </DashboardLayout>
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        title="Link Created Successful."
        text={`Payment link successfully created, click on close to view link on payment link list`}
        btnText={`Close`}
        onBtnClick={() => {
          navigate("/dashboard-payment-links");
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default CreateCustomerIndex;
