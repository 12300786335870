import { io } from "socket.io-client";
import CryptoJS from "crypto-js";

const SERVER = process.env.REACT_APP_BASE_URL;
// console.log(SERVER);
export const UseSocketFunction = () => {
  const authenticated = localStorage.getItem("token");
  const decryptTokenFunc = (tok) => {
    const encryptedToken = tok;
    const secretKey =
      "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

    // Encrypt the token
    const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
    const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
    // console.log(tok);
    // console.log(decryptedToken);
    return decryptedToken;
  };
  const socket = io(SERVER, {
    withCredentials: true,
    cors: { origin: "*" },
    extraHeaders: {
      auth: authenticated ? decryptTokenFunc(authenticated) : "",
    },
    path:
      process.env.NODE_ENV === "development" ? "/socket.io" : "/v1/socket.io",
    // transports: ["websocket"],
    // secure: true,
  });
  
  return socket;
  // return null;

};
