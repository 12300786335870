import { RavenCheckBox, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import "../../../styles/auth/SignUp.css";
// import dagoteIcon from "../../../assets/dagote-icon.svg";
import {
  convertToLowercase,
  //   formatTypeFunction,
  //   trimLongString,
} from "../../../utils/helper/Helper";
// import { authenticateLogin, authenticateLoginSso } from "../../redux/user";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import ActionContext from "../../context/ActionContext";
// import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";
// import FingerprintJS from "@fingerprintjs/fingerprintjs";
// import ActionContext from "../../../context/ActionContext";
import { useDarkMode } from "../../../utils/themes/useDarkMode";
// import { genCaptchaKeyAsync } from "../../../components/reusables/ReCaptchaFile";
import {
  createGlobalAccount,
  getAllCurrencies,
  getAllGlobalAccount,
} from "../../../redux/globalAccount";
import CheckUrlFragment from "../../../components/fragments/CheckUrlCodeFragment";
// import { getTransactionLimits } from "../../redux/transaction";
// import { getDirectorShareholders } from "../../redux/settings";
// import { set } from "immer/dist/internal";

const CreateMoreCurrencyModal = ({
  visible,
  onClose,
  setAgreeBox,
  agreeBox,
  onViewTerms,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  //   const [accountSelected, setAccountSelected] = useState("");
  // eslint-disable-next-line no-unused-vars
  //   const actionCtx = useContext(ActionContext);
  // eslint-disable-next-line no-unused-vars
  const [details, setDetails] = useState({
    pin: "",
  });
  const { all_currencies, loading } = useSelector(
    (state) => state.global_account
  );
  const [selectedCurList, setSelectedCurList] = useState([]);
  //   const [completeToken, setCompleteToken] = useState(false);

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  //  const [agreeBox, setAgreeBox] = useState(false)
  const [allCurrencyToUse, setAllCurrencyToUse] = useState([]);
  useEffect(() => {
    dispatch(getAllCurrencies());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (all_currencies?.all_support_currencies?.length > 0) {
      const filteredData = all_currencies?.all_support_currencies?.filter(
        (item) =>
          all_currencies?.currencies_available_for_setup.includes(item.currency)
      );
      setAllCurrencyToUse(filteredData);
    }
  }, [all_currencies]);

  const handleSelectedCurrency = (param) => {
    if (selectedCurList?.includes(param)) {
      const newList = selectedCurList?.filter((chi) => chi !== param);
      // console.log(newList);
      // console.log(param);
      setSelectedCurList(newList);
    } else {
      const newList = [...selectedCurList, param];
      setSelectedCurList(newList);
    }
  };

  const removeNgnFromList = (param) => {
    if (param?.length > 0) {
      const newList = param?.filter((chi) => chi?.currency !== "NGN");
      return newList;
    }
  };

  const handleSubmit = async () => {
    const obj = {
      selected_currencies: selectedCurList,
    };
    // console.log(obj);
    const data = await dispatch(createGlobalAccount(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      setAgreeBox(false);
      setSelectedCurList([]);
      dispatch(getAllGlobalAccount());
      dispatch(getAllCurrencies());
      onClose();
      //   navigate("/dashboard-global-account");
    }
  };

  const allIcon = (
    <svg
      className="img"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 2.26667C12.0844 4.54863 13.2689 7.51003 13.3333 10.6C13.2689 13.69 12.0844 16.6514 9.99996 18.9333M9.99996 2.26667C7.91556 4.54863 6.731 7.51003 6.66663 10.6C6.731 13.69 7.91556 16.6514 9.99996 18.9333M9.99996 2.26667C5.39759 2.26667 1.66663 5.99763 1.66663 10.6C1.66663 15.2024 5.39759 18.9333 9.99996 18.9333M9.99996 2.26667C14.6023 2.26667 18.3333 5.99763 18.3333 10.6C18.3333 15.2024 14.6023 18.9333 9.99996 18.9333M2.08331 8.1H17.9166M2.08329 13.1H17.9166"
        stroke="#755AE2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <RavenModal
      className={`auth-pin-modal-wrap`}
      onClose={() => {
        setSelectedCurList([]);
        onClose();
      }}
      visble={visible}
      btnLabel={`Add new ${
        selectedCurList?.length < 2 ? "currency" : "currencies"
      }`}
      btnColor={`black-light`}
      disabled={selectedCurList?.length < 1 || !agreeBox}
      onBtnClick={handleSubmit}
      loading={loading}
    >
      <div
        // onSubmit={(e) => {
        //   e.preventDefault(e);
        // }}
        action=""
        className="form form-modal-auth form-modal-switch-business-account"
      >
        <div className="text-box">
          <p
            className="big-title "
            style={{ color: theme === "light" ? "#020202" : "#ffffff" }}
          >
            Add new currency
          </p>
          <p className="small-title">Select as many currency as you want .</p>
        </div>
        {/* select wrap start */}
        <div className="select-wrap-account-auth select-wrap-account-auth-in-app">
          {/* select all currency  start */}
          {Object.keys(all_currencies)?.length > 0 &&
            removeNgnFromList(allCurrencyToUse)?.length > 1 && (
              <div
                onClick={() => {
                  const newList = removeNgnFromList(allCurrencyToUse)?.map(
                    (chi) => chi?.currency
                  );
                  if (
                    selectedCurList?.length ===
                    removeNgnFromList(allCurrencyToUse)?.length
                  ) {
                    setSelectedCurList([]);
                  } else {
                    setSelectedCurList(newList);
                  }
                }}
                className="select-item  border-theme"
              >
                <div className="img-avatar-wrap">
                  <figure
                    // style={{ backgroundColor: "red !important" }}
                    className="img-box img-box-cur"
                  >
                    {allIcon}
                  </figure>
                </div>
                <p
                  style={{
                    textTransform: "capitalize",
                  }}
                  className="name black-white-color"
                >
                  All currencies
                </p>
                {/* check wrap start */}
                <div
                  className={`check-wrap ${
                    selectedCurList?.length ===
                      removeNgnFromList(allCurrencyToUse)?.length &&
                    "check-wrap-active "
                  }`}
                ></div>
                {/* check wrap end */}
              </div>
            )}
          {/* select all currency end */}
          {removeNgnFromList(allCurrencyToUse)?.map((chi, idx) => {
            // const { business_name, business_logo } = chi;
            return (
              <div
                onClick={() => {
                  handleSelectedCurrency(chi?.currency);
                }}
                className="select-item  border-theme"
                key={idx}
                // style={{
                //   borderColor: theme === "light" && "rgba(247, 248, 247, 0.1)",
                // }}
              >
                <div className="img-avatar-wrap">
                  <CheckUrlFragment
                    url={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                      chi?.currency
                    )}.png`}
                    className={"img-box-cur"}
                  >
                    <span style={{ textTransform: "capitalize" }}>
                      {chi?.currency && chi?.currency?.split(" ")[0]?.charAt(0)}
                      {chi?.currency && chi?.currency?.split(" ")[1]?.charAt(0)}
                    </span>
                  </CheckUrlFragment>
                </div>
                <p
                  style={{
                    textTransform: "capitalize",
                  }}
                  className="name black-white-color"
                >
                  {`${chi?.currency || ""} •  ${chi?.name || ""}` || ""}
                </p>
                {/* check wrap start */}
                <div
                  className={`check-wrap ${
                    selectedCurList?.includes(chi?.currency) &&
                    "check-wrap-active "
                  }`}
                ></div>
                {/* check wrap end */}
              </div>
            );
          })}
        </div>
        {/* select wrap end */}
        <div className="agree-checkbox-wrap">
          <RavenCheckBox
            checked={agreeBox}
            onChange={() => {
              setAgreeBox && setAgreeBox(!agreeBox);
            }}
            color="black-light"
            key={`kjvbjssjkjos`}
          />
          <p className="text grey-white-color">
            I hereby accept and agree to the{" "}
            <span
              style={{
                color: "rgba(151, 71, 255, 1) !important",
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={onViewTerms}
            >
              {" "}
              terms and conditions
            </span>
            {/* <span>
                {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "rgba(117, 90, 226, 1)",
                  }}
                  href="https://getravenbank.com/terms-and-conditions?tab=global%2520accounts"
                >
                  terms and conditions
                </a>
              </span> */}
          </p>
        </div>
      </div>
    </RavenModal>
  );
};

export default CreateMoreCurrencyModal;
