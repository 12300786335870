import React, { useEffect } from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { auth_routes_group } from "./route/auth";
import { dashboard_routes_group } from "./route/dashboard";
import { verification_routes_group } from "./route/dashboard/verifications";
import { Provider } from "react-redux";
import store from "./redux/store";
import { RavenToast } from "@ravenpay/raven-bank-ui";
import PrivateRouteDashboard from "./route/PrivateRouteDashboard";
import setAuthToken from "./utils/auth";
import "flatpickr/dist/themes/airbnb.css";
import { Helmet } from "react-helmet";
import NoPageFound from "./pages/NoPageFound";
import ScrollToTop from "./utils/helper/Scrolltop";
import { payment_web_pay_routes_group } from "./route/payment-web-pay";
// import { useDarkMode } from "./utils/themes/useDarkMode";
// import { darkTheme, lightTheme } from "./utils/themes/theme";
// import { formatMetaData, getSystemThemePreference } from "./utils/helper/Helper";
// import { useSelector } from "react-redux";
import CheckTheme from "./components/fragments/CheckTheme";
// import { getSystemThemePreference } from "./utils/helper/Helper";
// import { genCaptchaKeyAsync } from "./components/reusables/ReCaptchaFile";
// import { setupInterceptors } from "./utils/ReCaptchaFileTwo";
// import { executeRecaptcha } from "./components/reusables/ReCaptchaFile";
// import ActionContext from "./context/ActionContext";
// import CryptoJS from "crypto-js";
// import { login } from "./redux/user";

require("@ravenpay/raven-bank-ui/dist/esm/styles/index.css");

function App() {
  const location = useLocation();
  setAuthToken();

  // Set your APP_ID
  // var APP_ID = "ughilol1";
  // window.intercomSettings = {
  //   app_id: "ughilol1",
  // };

  // (function () {
  //   var w = window;
  //   var ic = w.Intercom;
  //   if (typeof ic === "function") {
  //     ic("reattach_activator");
  //     ic("update", w.intercomSettings);
  //   } else {
  //     var d = document;
  //     var i = function () {
  //       i.c(arguments);
  //     };
  //     i.q = [];
  //     i.c = function (args) {
  //       i.q.push(args);
  //     };
  //     w.Intercom = i;
  //     var l = function () {
  //       var s = d.createElement("script");
  //       s.type = "text/javascript";
  //       s.async = true;
  //       s.src = "https://widget.intercom.io/widget/" + APP_ID;
  //       var x = d.getElementsByTagName("script")[0];
  //       x.parentNode.insertBefore(s, x);
  //     };
  //     if (document.readyState === "complete") {
  //       l();
  //     } else if (w.attachEvent) {
  //       w.attachEvent("onload", l);
  //     } else {
  //       w.addEventListener("load", l, false);
  //     }
  //   }
  // })();

  // useEffect(() => {
  //   // Function to clear browser cache
  //   const clearCache = () => {
  //     // Clear cache logic goes here
  //     // You can use different approaches based on your requirements and browser compatibility
  //     // Below is a sample approach using the Cache API
  //     if ("caches" in window) {
  //       caches.keys().then(function (cacheNames) {
  //         cacheNames.forEach(function (cacheName) {
  //           caches.delete(cacheName);
  //         });
  //       });
  //     }
  //   };

  //   clearCache(); // Call the function to clear cache

  //   // Optionally, you can also add additional cache-clearing logic here
  //   // For example, clearing localStorage or sessionStorage
  // }, []);

  // const channelID = "";
  useEffect(() => {
    if (
      !location.pathname.includes("/dashboard-") &&
      !location.pathname.includes("/dashboard-verification")
    ) {
      const script = document.createElement("script");
      // script.src = `//fw-cdn.com/10934263/3694369.js?channelId=${channelID}`;
      script.src = `//fw-cdn.com/10934263/3694369.js`;
      script.setAttribute("chat", "true");

      // Add an event listener to check when the script has loaded
      script.onload = () => {
        if (window.fcWidget) {
        }
      };

      // Append the script to the document's body
      document.body.appendChild(script);

      // Cleanup by removing the script from the DOM when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   location.state.prevPathname && console.log(location.state.prevPathname);
  //   if (
  //     location.state &&
  //     location.state.prevPathname === "/dashboard-invoice-new"
  //   ) {
  //     if (localStorage.getItem("invoice-draft-single-id")) {
  //       localStorage.removeItem("invoice-draft-single-id");
  //     }
  //     if (localStorage.getItem("invoice-draft-single-id")) {
  //       localStorage.removeItem("invoice-draft-single-id");
  //     }
  //   }
  // }, [location]);

  useEffect(() => {
    // Get all elements with the class name 'form-label'
    const elements = document.querySelectorAll(".form-label");

    // Loop through the elements and check if they contain the text "*"
    elements.forEach((element) => {
      const content = element.innerHTML;
      if (content.includes("*")) {
        // Wrap the "*" character in a span with the class 'highlight'
        const newContent = content.replace(
          /\*/g,
          ' <span class="highlight-red-form">*</span> '
        );
        element.innerHTML = newContent;
      }
    });
  }, [location.pathname]);


  return (
    <Provider store={store}>
      <div
        style={{
          backgroundColor:
            location?.pathname?.includes("/payment-details") ||
            location?.pathname?.includes("/invoice-preview")
              ? "#1b1b1b"
              : "",
        }}
        className="App"
        // onClick={() => {
        //   window.fcWidget && window.fcWidget.close();
        // }}
      >

        <Helmet>
          <title>Raven Business </title>
          <meta
            name="description"
            content="All-in-one banking suite for businesses of all sizes, Transfer, Payments, Invoices, Payroll."
          />
          <meta
            property="og:description"
            content="All-in-one banking suite for businesses of all sizes, Transfer, Payments, Invoices, Payroll."
          />
          <meta
            name="keywords"
            content="bank, banking, loan, savings, roi, Transfer, Invoice, payroll,  interest, nigeria, africa, fintech"
          />
          <meta name="page-topic" content="Banking" />
          <meta name="page-type" content="Landing" />
          <meta name="audience" content="Everyone" />
          <meta
            property="og:image"
            content="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/959d76181716239.652060a529c45.jpeg"
          />
          <meta
            property="og:url"
            content="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/959d76181716239.652060a529c45.jpeg"
          />
          <meta name="robots" content="index, follow" />
          <meta
            property="twitter:image:src"
            content="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/959d76181716239.652060a529c45.jpeg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="GetRavenBank" />
          <meta
            name="twitter:site"
            content="https://www.twitter.com/theravenbank"
          />
          <meta
            httpEquiv="Content-Security-Policy"
            content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
          />
          <meta
            key="cache-control"
            http-equiv="Cache-Control"
            content="no-cache, no-store, must-revalidate"
          />
          <meta key="pragma" http-equiv="Pragma" content="no-cache" />
          <meta key="expires" http-equiv="Expires" content="0" />
        </Helmet>
        <ScrollToTop />
        <CheckTheme />
        <Routes location={location}>
          <Route
            location={location}
            path="/"
            element={<Navigate to="/login" />}
          />
          <Route location={location} path="*" element={<NoPageFound />} />
          {/* auth group start */}
          {auth_routes_group?.map((route, idx) => {
            return <Route key={idx} exact {...route} />;
          })}
          {/* auth group end  */}
          {/* auth group start */}
          {payment_web_pay_routes_group?.map((route, idx) => {
            return <Route key={idx} exact {...route} />;
          })}
          {/* auth group end */}
          {/* verification group start */}
          {verification_routes_group?.map((route, idx) => {
            return (
              <Route element={<PrivateRouteDashboard />} key={idx}>
                <Route location={location} key={idx} exact {...route} />;
              </Route>
            );
          })}
          {/* verification group end */}
          {/* dashboard route group start */}
          {dashboard_routes_group.map((route, idx) => {
            return (
              <Route element={<PrivateRouteDashboard />} key={idx}>
                <Route location={location} key={idx} exact {...route} />;
              </Route>
            );
          })}
          {/* dashboard route group end */}
        </Routes>
      </div>
      <RavenToast />
      {/* <Intercom appID="v4k0zj58"  /> */}
    </Provider>
  );
}

export default App;
