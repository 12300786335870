/* eslint-disable react/style-prop-object */
import dasboardIcon from "../../assets/solid-nav-dashboard-icon.svg";
import addMoneyIcon from "../../assets/solid-nav-add-money-icon.svg";
// import sendMoneyIcon from "../../assets/solid-nav-send-money-icon.svg";
import TransactionIcon from "../../assets/solid-nav-transaction-icon.svg";
import cardIcon from "../../assets/solid-nav-card-icon.svg";
import billPaymentIcon from "../../assets/solid-nav-bill-payment-icon.svg";
import businessToolIcon from "../../assets/solid-nav-business-icon.svg";
import capitalIcon from "../../assets/solid-nav-capital-icon.svg";
import settingIcon from "../../assets/solid-nav-settings-icon.svg";
import dasboardMinimalIcon from "../../assets/minimal-nav-dashboard-icon.svg";
import addMoneyMinimalIcon from "../../assets/minimal-nav-add-money-icon.svg";
// import sendMoneyMinimalIcon from "../../assets/minimal-nav-send-money-icon.svg";
import TransactionMinimalIcon from "../../assets/minimal-nav-transactions-icon.svg";
import cardMinimalIcon from "../../assets/minimal-nav-cards-icon.svg";
import billPaymentMinimalIcon from "../../assets/minimal-nav-bill-payment-icon.svg";
import businessToolMinimalIcon from "../../assets/minimal-nav-business-tools-icon.svg";
import capitalMinimalIcon from "../../assets/minimal-nav-capital-icon.svg";
import settingMinimalIcon from "../../assets/minimal-nav-setting-icon.svg";
import globalAccountIconTwo from "../../assets/Groupglobal-account-ico.svg";
import globalAccountIconMinimal from "../../assets/Layer 1global account-minimal.svg";
// import customerIcon from "../../assets/ReferralscustomersIconDark.svg";
// import paymentRequestDark from "../../assets/paymentRequestIconMinimal.svg";

export const new_dashboard_side_menu_list = [
  {
    name: "Dashboard",
    icon: dasboardIcon,
    iconMinimal: dasboardMinimalIcon,
    // dark_icon: overviewIconDark,
    link: "/dashboard-overview",
  },
  {
    name: "Banking",
    icon: addMoneyIcon,
    iconMinimal: addMoneyMinimalIcon,
    // link: "/dashboard-add-money",
    includeLinks: [
      "/dashboard-add-money",
      "/dashboard-send-money",
      "/dashboard-schedule-payment",
      "/dashboard-payment-requests",
    ],
    children: [
      {
        chi_name: "Add Money",
        chi_link: "/dashboard-add-money",
        // light_icon: airtimeIcon,
        // dark_icon: airtimeIconDark,
      },
      {
        chi_name: "Send Money",
        chi_link: "/dashboard-send-money",
        // light_icon: airtimeIcon,
        // dark_icon: airtimeIconDark,
      },
      {
        chi_name: "Scheduled Payments",
        // icon: billPaymentIcon,
        // iconMinimal: billPaymentMinimalIcon,
        chi_link: "/dashboard-schedule-payment",
      },
      {
        chi_name: "Approval Request",
        chi_link: "/dashboard-payment-requests",
        // light_icon: airtimeIcon,
        // dark_icon: airtimeIconDark,
      },
      // {
      //   chi_name: "Global Account",
      //   chi_link: "/dashboard-global-account",
      //   // light_icon: airtimeIcon,
      //   // dark_icon: airtimeIconDark,
      // },
    ],
  },
  // {
  //   name: "Customers",
  //   icon: addMoneyIcon,
  //   iconMinimal: addMoneyMinimalIcon,
  //   // dark_icon: addMoneyIconDark,
  //   link: "/dashboard-customers",
  // },
  // {
  //   name: "Send Money",
  //   icon: sendMoneyIcon,
  //   iconMinimal: sendMoneyMinimalIcon,
  //   // dark_icon: sendMoneyIconDark,
  //   link: "/dashboard-send-money",
  // },
  // {
  //   name: "Approval request",
  //   icon: paymentRequestIcon,
  //   iconMinimal: paymentRequestDark,
  //   link: "/dashboard-payment-requests",
  // },
  {
    name: "Global Account",
    link: "/dashboard-global-account",
    icon: globalAccountIconTwo,
    iconMinimal: globalAccountIconMinimal,
    // light_icon: airtimeIcon,
    // dark_icon: airtimeIconDark,
    // iconTwo: globalAccountIcon,
    children: [
      {
        chi_name: "Beneficiaries",
        chi_link: "/dashboard-global-account-beneficiary",
      },
    ],
  },
  {
    name: "Transactions",
    icon: TransactionIcon,
    iconMinimal: TransactionMinimalIcon,
    // dark_icon: transactionIconDark,
    link: "/dashboard-transaction",
  },
  // {
  //   name: "Scheduled Payments",
  //   icon: billPaymentIcon,
  //   iconMinimal: billPaymentMinimalIcon,
  //   link: "/dashboard-schedule-payment",
  // },
  {
    name: "Cards",
    icon: cardIcon,
    iconMinimal: cardMinimalIcon,
    link: "/dashboard-card",
  },
  // {
  //   name: "Customers",
  //   icon: customerIcon,
  //   iconMinimal: customerIcon,
  //   // dark_icon: addMoneyIconDark,
  //   link: "/dashboard-customers",
  // },
  {
    name: "Bill Payment",
    icon: billPaymentIcon,
    iconMinimal: billPaymentMinimalIcon,
    link: "/dashboard-bill-payment",
    children: [
      {
        chi_name: "Airtime",
        chi_link: "/dashboard-bill-payment-airtime",
        // light_icon: airtimeIcon,
        // dark_icon: airtimeIconDark,
      },
      {
        chi_name: "Data Plans",
        chi_link: "/dashboard-bill-payment-data",
        // light_icon: dataIcon,
        // dark_icon: dataIconDark,
      },
      {
        chi_name: "Cable Tv",
        chi_link: "/dashboard-bill-payment-cable",
        // light_icon: cableIcon,
        // dark_icon: cableIconDark,
      },
      {
        chi_name: "Electricity",
        chi_link: "/dashboard-bill-payment-electricity",
        // light_icon: electricityIcon,
        // dark_icon: electricityIconDark,
      },
      {
        chi_name: "Betting",
        chi_link: "/dashboard-bill-payment-betting",
        // light_icon: bettingIcon,
        // dark_icon: bettingIconDark,
      },
    ],
  },
];

export const new_business_tool_side_menu_list = [
  {
    name: "Business Tools",
    icon: businessToolIcon,
    iconMinimal: businessToolMinimalIcon,
    // link: "/dashboard-bill-payment",
    children: [
      {
        chi_name: "Payroll",
        chi_link: "/dashboard-payroll",
        // light_icon: airtimeIcon,
        // dark_icon: airtimeIconDark,
      },
      {
        chi_name: "Invoices",
        chi_link: "/dashboard-invoice",
        // light_icon: dataIcon,
        // dark_icon: dataIconDark,
      },
      {
        chi_name: "Payment links",
        chi_link: "/dashboard-payment-links",
        // light_icon: cableIcon,
        // dark_icon: cableIconDark,
      },
      {
        chi_name: "BankBox",
        chi_link: "/dashboard-pos",
        // light_icon: electricityIcon,
        // dark_icon: electricityIconDark,
      },
      {
        chi_name: "Customers",
        // icon: customerIcon,
        // iconMinimal: customerIcon,
        // dark_icon: addMoneyIconDark,
        chi_link: "/dashboard-customers",
      },
      {
        chi_name: "Store",
        chi_link: "/dashboard-store",
        // light_icon: bettingIcon,
        // dark_icon: bettingIconDark,
      },
    ],
  },
];

export const new_capital_side_menu_list = [
  {
    name: "Capital",
    icon: capitalIcon,
    iconMinimal: capitalMinimalIcon,
    // link: "/dashboard-bill-payment",
    children: [
      {
        chi_name: "Loans",
        chi_link: "/dashboard-loan",
        // light_icon: airtimeIcon,
        // dark_icon: airtimeIconDark,
      },
      {
        chi_name: "Overdraft",
        chi_link: "/dashboard-overdraft",
        // light_icon: dataIcon,
        // dark_icon: dataIconDark,
      },
      {
        chi_name: "Invoice Financing",
        chi_link: "/dashboard-finance-invoice",
        // light_icon: cableIcon,
        // dark_icon: cableIconDark,
      },
    ],
  },
  {
    name: "Settings",
    icon: settingIcon,
    iconMinimal: settingMinimalIcon,
    // dark_icon: addMoneyIconDark,
    link: "/dashboard-settings-notification",
  },
];
