import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/reusables/DeleteModal";
import ActionContext from "../../context/ActionContext";
import { logoutUser } from "../../redux/user";
import { clearCache } from "../../utils/helper/Helper";
import { setCookie } from "../../utils/CookiesFunction";

const ConfirmLogoutModal = ({ visible, onCancel }) => {
  // const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const urlSave = location.pathname.split("/")[1];
  const [logoutload, setLogOutLoad] = useState(false);
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    setLogOutLoad(true);
    const data = await dispatch(logoutUser());
    if (data?.payload?.data?.status === "success") {
      actionCtx?.setShowProfileDrop("close");
      setLogOutLoad(false);
      clearCache()
      localStorage.clear();
      setCookie('g-rec-response', "", 0);
      onCancel();
      actionCtx?.setIsLogout(false);
      // navigate("/login");
      window.location.replace(`/login?${urlSave}`);
    }
  };

  return (
    <DeleteModal
      loading={logoutload}
      onDelete={handleLogout}
      visible={visible}
      onCancel={onCancel}
      title={`Want to Logout ?`}
      deleteText={`Yes, logout`}
      text={`Are you sure you want to log out? Please confirm by clicking "Yes, logout" or "Cancel".`}
    />
  );
};

export default ConfirmLogoutModal;
