/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
// import setAuthToken from "../utils/auth";
import { toast } from "@ravenpay/raven-bank-ui";

export const createSubAccount = createAsyncThunk(
  "admin/create-sub-account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/generate_account_number", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const createRoleAndPermissions = createAsyncThunk(
  "admin/create-sub-account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/teams/add_role_for_business", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const removeUserFromRoleAndPermissions = createAsyncThunk(
  "admin/remove-user-from-role-account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/permissions/connect_user_to_role",
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const removeUserFromRoleAndPermissionsTwo = createAsyncThunk(
  "admin/remove-user-from-role-account-two",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/roles_and_permissions/remove_user_from_a_business",
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const editPermissionOfCustomRole = createAsyncThunk(
  "admin/edit-permission-custom-role",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/permissions/create_role_based_permissions_bulk",
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getRolesAndPermissions = createAsyncThunk(
  "admin/get-roles-and-permission",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/roles_and_permissions/get_roles_and_user_under_each_role`
      );
      // console.log(data, "-----------------");
      if (data?.response?.data?.status === "fail") {
        //   toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        //   console.log(data);
        //   toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(
          setRolesAndUsers(data?.data?.data?.roles_and_user)
        );
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getSingleRolesAndPermissions = createAsyncThunk(
  "admin/get-single-roles-and-permission",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/roles_and_permissions/get_single_role_and_users_under_the_role?role=${payload?.role}`
      );
      //   console.log(data, "-----------------");
      if (data?.response?.data?.status === "fail") {
        //   toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        //   console.log(data?.data?.data);
        //   toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(seSingleRolesAndUsers(data?.data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAllExistingUsers = createAsyncThunk(
  "admin/get-all-existing-users",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`/users/fetch_all_user_under_a_business`);
      if (data?.response?.data?.status === "fail") {
        //   toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log(data);
        //   toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(setAllExistingUsers(data?.data?.data?.users));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getResourceRolesAndPermissions = createAsyncThunk(
  "admin/get-resource-roles-and-permission",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/roles_and_permissions/get_resources_and_permissions_for_new_role`
      );
      //   console.log(data, "-----------------");
      if (data?.response?.data?.status === "fail") {
        //   toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        //   console.log(data?.data?.data);
        //   toast.success(data?.data?.message, {});
        await thunkAPI.dispatch(
          seyResourceRolesAndUsers(
            data?.data?.data?.resources_and_permission_array
          )
        );
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const role_and_permission = createSlice({
  name: "schedule-payment",
  initialState: {
    roles_and_users: [],
    all_existing_users: [],
    single_roles_and_users: [],
    resource_role_permission: [],
    isAuth: false,
    loading: false,
    loadingAction: false,
    loadingRemove: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setRolesAndUsers: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.roles_and_users = action.payload;
    },
    seSingleRolesAndUsers: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.single_roles_and_users = action.payload;
    },
    setAllExistingUsers: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.all_existing_users = action.payload;
    },
    seyResourceRolesAndUsers: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.resource_role_permission = action.payload;
    },
  },
  extraReducers: {
    [getRolesAndPermissions.pending]: (state) => {
      state.loading = true;
    },
    [getRolesAndPermissions.fulfilled]: (state) => {
      state.loading = false;
    },
    [getRolesAndPermissions.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSingleRolesAndPermissions.pending]: (state) => {
      state.loading = true;
    },
    [getSingleRolesAndPermissions.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSingleRolesAndPermissions.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [createRoleAndPermissions.pending]: (state) => {
      state.loading = true;
    },
    [createRoleAndPermissions.fulfilled]: (state) => {
      state.loading = false;
    },
    [createRoleAndPermissions.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [removeUserFromRoleAndPermissions.pending]: (state) => {
      state.loadingAction = true;
    },
    [removeUserFromRoleAndPermissions.fulfilled]: (state) => {
      state.loadingAction = false;
    },
    [removeUserFromRoleAndPermissions.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingAction = false;
      state.isAuth = false;
      state = null;
    },
    [removeUserFromRoleAndPermissionsTwo.pending]: (state) => {
      state.loadingRemove = true;
    },
    [removeUserFromRoleAndPermissionsTwo.fulfilled]: (state) => {
      state.loadingRemove = false;
    },
    [removeUserFromRoleAndPermissionsTwo.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingRemove = false;
      state.isAuth = false;
      state = null;
    },
    [editPermissionOfCustomRole.pending]: (state) => {
      state.loadingRemove = true;
    },
    [editPermissionOfCustomRole.fulfilled]: (state) => {
      state.loadingRemove = false;
    },
    [editPermissionOfCustomRole.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingRemove = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setRolesAndUsers,
  seSingleRolesAndUsers,
  seyResourceRolesAndUsers,
  setAllExistingUsers,
} = role_and_permission.actions;

export default role_and_permission.reducer;
