import { RavenCheckBox, RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/payment-links/PaymentLink.css";
// import oneTimeIcon from "../../../assets/request-one-time-icon.svg";
// import reccuringIcon from "../../../assets/request-one-time-icon (1).svg";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
// import PreviewPaymentDetailsBox from "./PreviePaymentLinkBox";
// import DeleteModal from "../../../components/reusables/DeleteModal";
// import DeletePaymentModal from "./DeletePaymentModal";
import { useDispatch, useSelector } from "react-redux";
import { createPaymentLink } from "../../../redux/requestMoney";
import {
  filterEmptyColumns,
  formatNumWithCommaNaira,
  formatNumWithoutCommaNaira,
  getCurrencySymbol,
} from "../../../utils/helper/Helper";
import SuccessModal from "../../../components/reusables/SuccessModal";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import NewPreviewDetailBox from "./NewPreviewLinkBox";
import ActionContext from "../../../context/ActionContext";
import {
  getAllCurrencies,
  getAllGlobalAccount,
} from "../../../redux/globalAccount";
import GlobalAmountInput from "../../../components/fragments/GlobalAmountInput";
// import { validateEmail } from "../../../utils/helper/ValidateInput";

const PaymentLinkRequestMoney = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  // const location = useLocation();
  const actionCtx = useContext(ActionContext);
  const { loading } = useSelector((state) => state.paymentLinks);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const stepList = ["Link details", "Confirm Transactions"];
  const [paymentType, setPaymentType] = useState("Single Charge");
  const {
    // loadingTrans,
    // single_global_account,
    all_global_account,
    // all_beneficiaries,
    all_currencies,
  } = useSelector((state) => state.global_account);
  const typeList = [
    {
      name: "Single Charge",
      text: "Accept a one time payment with a link created",
      icon: (
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img dull-bright-filter"
        >
          <path
            d="M11.0008 1L2.09429 11.6879C1.74549 12.1064 1.57108 12.3157 1.56842 12.4925C1.5661 12.6461 1.63457 12.7923 1.7541 12.8889C1.89159 13 2.16402 13 2.70887 13H10.0008L9.00084 21L17.9074 10.3121C18.2562 9.89358 18.4306 9.68429 18.4333 9.50754C18.4356 9.35388 18.3671 9.2077 18.2476 9.11111C18.1101 9 17.8377 9 17.2928 9H10.0008L11.0008 1Z"
            // stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Multi Charge",
      text: "Accept a payment with a link created with a set time frame",
      icon: (
        <svg
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img dull-bright-filter"
        >
          <path
            d="M20.7 11.5L18.7005 9.5L16.7 11.5M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C13.3019 1 16.1885 2.77814 17.7545 5.42909M10 5V10L13 12"
            // stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];
  const [currencyToUse, setCurrencyToUse] = useState({});
  const [details, setDetails] = useState({
    request_amount: "",
    email: "",
    request_description: "",
    expiry_date: "",
    recurring: false,
    agree: false,
    title: "",
    allow_edit_amount: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async () => {
    let val;
    if (paymentType === "Multi Charge") {
      const obj = {
        ...details,
        request_amount: String(formatNumWithoutCommaNaira(details?.request_amount)),
        expiry_date: details?.expiry_date?.split(" ")[0],
        recurring: "1",
        allow_edit_amount: details?.allow_edit_amount ? 1 : 0,
        title: details?.title,
        account_number: actionCtx?.currentAccount?.account_number,
        currency: currencyToUse?.currency || "",
      };
      val = obj;
    } else {
      const obj = {
        request_amount: String(formatNumWithoutCommaNaira(details?.request_amount)),
        recipient_email: details?.email,
        request_description: details?.request_description,
        recurring: "0",
        allow_edit_amount: details?.allow_edit_amount ? 1 : 0,
        title: details?.title,
        account_number: actionCtx?.currentAccount?.account_number,
        currency: currencyToUse?.currency || "",
      };
      val = obj;
    }
    // console.log(filterEmptyColumns(val));
    const data = await dispatch(createPaymentLink(filterEmptyColumns(val)));
    if (data?.payload?.data?.status === "success") {
      setDetails({
        request_amount: "",
        email: "",
        request_description: "",
        expiry_date: "",
        recurring: false,
        agree: false,
        title: "",
        allow_edit_amount: false,
      });
      setPaymentType("Single Charge");
      setStep(1);
      setShowModal(true);
    }
  };

  const size = useWindowSize();
  // const [showError, setShowError] = useState({
  //   num: false,
  //   email: false,
  //   amount: false,
  //   meter: false,
  // });

  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_create_payment_link"
      )
    ) {
      navigate("/dashboard-payment-links" || -1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_resource_and_permissions]);

  useEffect(() => {
    const urlToUse = window?.location?.href?.split("?currency=")[1];
    // console.log(urlToUse);
    if (
      urlToUse &&
      all_global_account?.global_accounts_available_for_current_business
        ?.length > 0
    ) {
      const currecyObj = addLabelValue(
        all_global_account?.global_accounts_available_for_current_business
      )?.find((chi) => chi?.currency === urlToUse);
      // console.log(currecyObj);
      setCurrencyToUse(currecyObj);
    }

    // ?currency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_currencies]);

  // for currency List option one from dropdown list
  const [currencyAccountList, setCurrencyAccountList] = useState([]);
  useEffect(() => {
    if (
      all_global_account?.global_accounts_available_for_current_business
        ?.length > 1
    ) {
      const newList = addLabelValue(
        all_global_account?.global_accounts_available_for_current_business
      )?.filter((chi) => chi?.currency !== currencyToUse);
      // console.log(newList);
      setCurrencyAccountList(newList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_global_account, currencyToUse]);

  const addLabelValue = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          label: chi?.currency,
          value: chi?.id || chi?.currency,
        };
      });
      return newList;
    }
  };

  useEffect(() => {
    if (Object?.keys(all_currencies)?.length < 1) {
      dispatch(getAllCurrencies());
    }

    if (Object?.keys(all_global_account)?.length < 1) {
      dispatch(getAllGlobalAccount());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNGNtoList = (list) => {
    if (
      list?.length > 0 &&
      all_currencies?.all_support_currencies?.length > 0
    ) {
      const ngnObj = all_currencies?.all_support_currencies?.find(
        (chi) => chi?.currency === "NGN"
      );
      const newObj = {
        ...ngnObj,
        label: ngnObj?.currency,
        value: ngnObj?.code,
        id: ngnObj?.currency,
      };
      // console.log(newObj);
      if (Object?.keys(ngnObj)?.length > 0) {
        const newList = [...list, newObj];
        return newList;
      }
    }
  };

  function changeCurrencyValue(newCurrency) {
    // Parse the current URL
    const url = new URL(window.location.href);

    // Get the query parameters
    const params = new URLSearchParams(url.search);

    // Set the new currency value
    params.set("currency", newCurrency);

    // Update the URL without reloading the page
    url.search = params.toString();
    window.history.replaceState({}, "", url);
  }

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          back
          onBack={() => navigate("/dashboard-payment-links" || -1)}
          title={`Request Money`}
          text={`Create a payment link to generate to request money easily.`}
        />
        {/* wrapper start */}
        <ContainerWrapper
          width={size?.width < 900 && `100%`}
          btnText={`Confirm Link Creation`}
          stepList={stepList}
          activeStep={step}
          back={step > 1}
          loading={loading}
          stepClickable={
            paymentType === "Single Charge"
              ? details?.request_amount &&
                details?.title &&
                details?.request_description
              : details?.request_amount &&
                details?.title &&
                details?.request_description &&
                details?.expiry_date
          }
          onStepClick={(e) => {
            paymentType === "Single Charge"
              ? details?.request_amount &&
                details?.title &&
                details?.request_description &&
                setStep(e)
              : details?.request_amount &&
                details?.title &&
                details?.request_description &&
                details?.expiry_date &&
                setStep(e);
          }}
          disabled={
            step === 1
              ? paymentType === "Single Charge"
                ? !details?.request_amount ||
                  !details?.title ||
                  !details?.request_description
                : !details?.request_amount ||
                  !details?.title ||
                  !details?.request_description ||
                  !details?.expiry_date
              : // : step === 2
                // ? !details?.agree
                false
          }
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          onBtnClick={() => {
            if (step === 1) {
              // if (!validateEmail(details.email)) {
              //   toast.error("Invalid email address!", {
              //     position: "top-right",
              //   });
              //   setShowError((prev) => {
              //     return { ...prev, email: true };
              //   });
              //   return;
              // }
              setStep(2);
            }

            step === 2 && handleSubmit();
          }}
        >
          {/* request money wrap start */}
          <div className="request-money-wrap-box">
            {step === 1 && (
              <>
                {/* type select box start */}
                <div className="select-type-box">
                  {typeList.map((chi, idx) => {
                    const { icon, name, text } = chi;
                    return (
                      <div
                        key={idx}
                        className={`box grey-bg remove-dark-border select-option-class ${
                          name === paymentType && "box-active"
                        }`}
                        onClick={() => {
                          // console.log(name);
                          setPaymentType(name);
                        }}
                      >
                        <div className="check-box">
                          <FaCheck
                            style={{ filter: "brightness(1000%)" }}
                            className="icon"
                          />
                        </div>
                        <figure className="img-box">{icon}</figure>
                        <p className="title">{name}</p>
                        <p className="text grey-white-color">{text}</p>
                      </div>
                    );
                  })}
                </div>
                {/* type select box end */}
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  {" "}
                  <RavenInputField
                    type={`text`}
                    label={`Link Title* `}
                    color={`black-light`}
                    placeholder={`e.g Payment Contract`}
                    // showError={showError?.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={details?.title}
                    name={`title`}
                    // className={`below-expiry`}
                  />
                </form>
                {Object?.keys(currencyToUse)?.length > 0 ? (
                  <>
                    <GlobalAmountInput
                      selectedCurrency={currencyToUse}
                      currencyList={addNGNtoList(currencyAccountList)}
                      setCurrencyList={(param) => {
                        // console.log(param);
                        setCurrencyToUse(param);
                        changeCurrencyValue(param?.currency);
                      }}
                      amountValue={details?.request_amount}
                      placeholder={``}
                      handleChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, request_amount: e.target.value };
                        });
                      }}
                      numberPrefix={getCurrencySymbol(currencyToUse?.currency)}
                    />
                  </>
                ) : (
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      type={`number`}
                      label={`Amount* `}
                      color={`black-light`}
                      placeholder={`e.g 5,000.00`}
                      thousandFormat
                      numberPrefix={`₦ `}
                      onChange={handleChange}
                      value={details?.request_amount}
                      name={`request_amount`}
                    />
                  </form>
                )}

                {/* edit amount start */}
                <div
                  style={{ display: "flex" }}
                  className="edit-amount-check-wrap"
                >
                  <RavenCheckBox
                    color="black-light"
                    value={details?.allow_edit_amount}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          allow_edit_amount: !details.allow_edit_amount,
                        };
                      });
                    }}
                    key={`hgh`}
                    id={`jhbbbdv73`}
                    checked={details?.allow_edit_amount}
                  />
                  <p className="text">Allow customer edit amount</p>
                </div>
                {/* edit amount end */}
                {/* reccuring start */}
                <div
                  className={`reccuring-box ${
                    paymentType === "Multi Charge" && "reccuring-box-show"
                  }`}
                >
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      type={`date`}
                      label={`Link expiry date* `}
                      color={`black-light`}
                      placeholder={`Select a Date`}
                      enableTime
                      onChange={(e) => {
                        setDetails((prev) => {
                          return {
                            ...prev,
                            expiry_date: moment(e[0]).format(
                              "YYYY-MM-DD HH:mm A"
                            ),
                          };
                        });
                      }}
                      value={details?.expiry_date}
                      name={`date`}
                      className="expiry-group"
                    />
                  </form>
                </div>
                {/* reccuring end */}
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  <RavenInputField
                    type={`textarea`}
                    label={`Link Narration* `}
                    color={`black-light`}
                    placeholder={`Whats the payment link for?`}
                    textareaRow={4}
                    onChange={handleChange}
                    value={details?.request_description}
                    name={`request_description`}
                  />
                </form>
              </>
            )}
            {/* step 2 start -------------------------- */}
            {step === 2 && (
              <>
                {/* <PreviewPaymentDetailsBox
                  narration={details?.request_description}
                  linkType={paymentType}
                  email={details?.title}
                  amount={details?.request_amount}
                  linkDuration={details?.expiry_date}
                  value={details?.agree}
                  onChange={(e) =>
                    setDetails((prev) => {
                      return { ...prev, agree: e.target.checked };
                    })
                  }
                  id={`nnsj873`}
                  checked={details?.agree}
                /> */}
                <NewPreviewDetailBox
                  linkDuration={details?.expiry_date}
                  amount={formatNumWithCommaNaira(
                    formatNumWithoutCommaNaira(details?.request_amount)
                  )}
                  narration={details?.request_description}
                  linkType={paymentType}
                  email={details?.title}
                  hideSubInfo={Object?.keys(currencyToUse)?.length > 0}
                  globalInfo={currencyToUse}
                />
              </>
            )}
            {/* step 2 end -------------------------- */}
            {/* <div className="space-bottom"></div> */}
          </div>
          {/* request money wrap end */}
        </ContainerWrapper>
        {/* wrapper end */}
      </DashboardLayout>
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        title="Link Created Successful."
        text={`Payment link successfully created, click on close to view link on payment link list`}
        btnText={`Close`}
        onBtnClick={() => {
          navigate("/dashboard-payment-links");
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default PaymentLinkRequestMoney;
