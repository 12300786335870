import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ActionContext from "../../../context/ActionContext";
import { useSelector } from "react-redux";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitletextBox from "../../../components/reusables/TitletextBox";
import NoContentBox from "../../../components/reusables/NoContentBox";
import noContentImg from "../../../assets/no-content-img (1).png";
import {
  formatDate,
  formatMetaData,
  //   formatNumWithCommaNairaSymbol,
  getTotalPage,
  lowerCaseWrap,
  reactSelectStyle,
  trimLongString,
} from "../../../utils/helper/Helper";
import MobileTableCard from "../../../components/reusables/MobileTableCard";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import {
  // RavenDateTime,
  RavenInputField,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  //   RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import { getPaymentLinksPagination } from "../../../redux/requestMoney";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import useDebounce from "../../../utils/helper/UseDebounce";
import { FaTimes } from "react-icons/fa";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
import Skeleton from "react-loading-skeleton";
import NocontentPage from "../../../components/reusables/NocontentPage";
import { getCustomers } from "../../../redux/customers";
import DeleteCustomerModal from "./DeleteCustomerModal";
import { CSVLink } from "react-csv";
import CustomDateSelectModal from "../pos/modal/CustomDateSelectModal";
import SpecificDateSelectModal from "../pos/modal/SpecificDateSelectModal";
import CustomerMobileViewMobile from "./CustomerMobileViewModal";
import DateFragmentBox from "../../../components/fragments/DateFragmentBox";

const DashboardCustomerIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const { loading, customers } = useSelector((state) => state.customers);
  const { user, user_resource_and_permissions } = useSelector(
    (state) => state.user
  );
  const [dropToshow, setDropToShow] = useState("");
  const [showFilterOption, setShowFilterOption] = useState(false);
  const size = useWindowSize();
  const [tableData, setTableData] = useState(false);
  const [selectOptionVal, setSelectOptionVal] = useState({
    one: "",
    two: "",
  });
  const [showModal, setShowModal] = useState({
    delete: false,
    disable: false,
    share: false,
  });

  const headerList = [
    "FIRST NAME",
    "LAST NAME",
    "EMAIL ADDRESS",
    "PHONE",
    "DATE ADDED",
    // "STATUS",
    "",
  ];
  const [refreshTable, setRefresTable] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  const dropActionList = [
    {
      name: "Edit details",
      icon: (
        <svg
          className="img"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="dull-bright-filter"
            d="M15 1.66675L18.3333 5.00008M1.66663 18.3334L2.73029 14.4333C2.79969 14.1789 2.83439 14.0516 2.88765 13.933C2.93495 13.8276 2.99306 13.7275 3.06106 13.6342C3.13764 13.5291 3.23088 13.4358 3.41738 13.2493L12.0286 4.63815C12.1936 4.47315 12.2761 4.39064 12.3712 4.35973C12.4549 4.33254 12.545 4.33254 12.6287 4.35973C12.7239 4.39064 12.8064 4.47315 12.9714 4.63815L15.3619 7.02868C15.5269 7.19368 15.6094 7.27619 15.6403 7.37132C15.6675 7.45501 15.6675 7.54515 15.6403 7.62884C15.6094 7.72398 15.5269 7.80648 15.3619 7.97149L6.75071 16.5827C6.56422 16.7692 6.47097 16.8624 6.36586 16.939C6.27254 17.007 6.1724 17.0651 6.06706 17.1124C5.94841 17.1657 5.82119 17.2004 5.56674 17.2697L1.66663 18.3334Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Create invoice",
      icon: (
        <svg
          className="img"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="dull-bright-filter"
            d="M9.99996 4.16675V15.8334M4.16663 10.0001H15.8333"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    // {
    //   name: "Delete Customer",
    //   icon: (
    //     <svg
    //       className="img"
    //       width="17"
    //       height="17"
    //       viewBox="0 0 17 17"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M4.13281 6.60026H13.4661M4.13281 10.6003H13.4661M13.4661 3.93359L4.13281 13.2669"
    //         stroke="#676767"
    //         strokeWidth="1.35383"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //     </svg>
    //   ),
    // },
    {
      name: "Delete Customers",
      icon: (
        <svg
          className="img"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3333 5.00008V4.33341C13.3333 3.39999 13.3333 2.93328 13.1517 2.57676C12.9919 2.26316 12.7369 2.00819 12.4233 1.8484C12.0668 1.66675 11.6001 1.66675 10.6667 1.66675H9.33333C8.39991 1.66675 7.9332 1.66675 7.57668 1.8484C7.26308 2.00819 7.00811 2.26316 6.84832 2.57676C6.66667 2.93328 6.66667 3.39999 6.66667 4.33341V5.00008M8.33333 9.58342V13.7501M11.6667 9.58342V13.7501M2.5 5.00008H17.5M15.8333 5.00008V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9684C15.3212 17.4388 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4388 4.43915 16.9684C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00008"
            stroke="#FF0000"
            strokeWidth="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  ];
  const [singleChild, setSingleChild] = useState({});
  const payrollCurrentActions = (param, idx) => {
    return (
      <div className="payroll-current-action-wrap">
        {loading ? (
          <>
            <Skeleton width={30} height={30} circle />
          </>
        ) : (
          <>
            <figure
              className="img-box grey-bg drop-down-box-payroll darken-action-bg"
              onClick={() => {
                // console.log(param);
                dropToshow === param?.id
                  ? setDropToShow("")
                  : setDropToShow(param?.id);
              }}
            >
              {/* drop down box start */}
              <div
                className={`drop-down-wrap-payroll border-theme shadow-shadow-box-shadow ${
                  dropToshow === param?.id && "drop-down-wrap-payroll-show"
                } ${idx >= 3 && "drop-down-wrap-payroll-up"}`}
              >
                {/* */}
                {dropActionList?.map((chi, idx) => {
                  const { icon, name } = chi;
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_update_customer"
                    ) &&
                    name === "Edit details"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_create_invoice"
                    ) &&
                    name === "Create invoice"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  if (
                    !user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_delete_customer"
                    ) &&
                    name === "Delete Customers"
                  ) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }

                  return (
                    <div
                      onClick={() => {
                        if (name === "Edit details") {
                          // console.log(param);
                          localStorage?.setItem("customer-inv-id", param?.id);
                          navigate("/dashboard-customers-edit");
                        }
                        if (name === "Create invoice") {
                          localStorage?.setItem("customer-inv-id", param?.id);
                          navigate("/dashboard-invoice-new");
                        }

                        if (name === "Delete Customers") {
                          setShowModal((prev) => {
                            return { ...prev, delete: true };
                          });
                          setSingleChild(param);
                        }
                      }}
                      key={idx}
                      className="row-child"
                    >
                      <div className="img-wrap">
                        <figure className="img-box">{icon}</figure>
                      </div>
                      <p
                        className="text"
                        style={{
                          color: name === "Delete Customers" && "#FF0F00",
                        }}
                      >
                        {name}
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* drop down box end */}
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="img dull-bright-filter"
              >
                <path
                  d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
                  stroke="#676767"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
          </>
        )}
      </div>
    );
  };

  const exportTableIcon = (
    <svg
      className="img "
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
    </svg>
  );

  const CsvHeader = [
    { label: "S/N", key: "num" },
    { label: "Customer name", key: "customer_name" },
    { label: "Customer email", key: "customer_email" },
    { label: "Customer Phone", key: "customer_phone" },
    { label: "Date Created", key: "created_at" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
      };
      const data = await dispatch(getCustomers(obj));
      //  console.log(data);
      if (data?.payload?.status === "success") {
        if (data?.payload?.data.data.length > 0) {
          setTableData(true);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  //   const today = new Date();

  //   // Subtract one day from the current date
  //   const yesterday = new Date(today);
  //   yesterday.setDate(today.getDate() - 1);
  //   const year = yesterday.getFullYear();
  //   const month = yesterday.getMonth() + 1; // Months are zero-indexed, so we add 1
  //   const day = yesterday.getDate();
  //  // Format the date as a string in the format "YYYY-MM-DD"
  //  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
  //     day < 10 ? "0" : ""
  //   }${day}`;
  const selectOption = [
    { label: "All Time", value: "all" },
    { label: "Specific Date", value: "specific_date" },
    { label: "Custom", value: "custom" },
  ];

  const formatFilterDate = (obj) => {
    const dateOne = formatDate(obj?.split("_")[0])?.split("—")[0];
    const dateTwo = formatDate(obj?.split("_")[1])?.split("—")[0];
    const val = `${dateOne} to ${dateTwo}`;
    // console.log(val);
    return val;
  };

  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 1) {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        search: search,
        status: selectOptionVal?.two?.value || "",
        type: selectOptionVal?.one?.value || "",
      };
      // console.log(obj);
      // return
      dispatch(getCustomers(obj));
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      const obj = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        status: selectOptionVal?.two?.value || "",
        type: selectOptionVal?.one?.value || "",
      };
      dispatch(getCustomers(obj));
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (
      //   selectOptionVal?.one?.label === "Today" ||
      selectOptionVal?.one?.value === ""
    ) {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        status: selectOptionVal?.two?.value || "",
        type: selectOptionVal?.one?.value || "",
        date: selectOptionVal?.one?.value,
        // bank_account_id: selectOptionVal?.three?.value,
      };
      dispatch(getCustomers(payload));
    }
    if (selectOptionVal?.one?.name === "Specific Date") {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        status: selectOptionVal?.two?.value || "",
        type: selectOptionVal?.one?.value || "",
        date: `${selectOptionVal?.one?.value}_${selectOptionVal?.one?.value}`,
        // bank_account_id: selectOptionVal?.three?.value,
      };
      // console.log(payload);
      // return;
      dispatch(getCustomers(payload));
    }
    if (selectOptionVal?.one?.name === "Custom Date") {
      const payload = {
        per_page: actionCtx?.perPageVal?.value,
        current_page: 1,
        status: selectOptionVal?.two?.value || "",
        type: selectOptionVal?.one?.value || "",
        date: `${selectOptionVal?.one?.value?.split("_")[0]}_${
          selectOptionVal?.one?.value?.split("_")[1]
        }`,
      };
      //   console.log(payload);
      //   return;
      dispatch(getCustomers(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptionVal, actionCtx?.perPageVal]);

  const formatExportData = (data) => {
    const list = data?.map((chi, idx) => {
      const { customer_email, customer_name, customer_phone, created_at } = chi;
      return {
        num: idx < 10 ? `0${idx + 1}` : idx + 1,
        customer_email,
        customer_name,
        customer_phone,
        created_at: formatDate(created_at),
      };
    });
    return list;
  };

  const testingCustomersRef = useOnClickOutside(() => {
    setShowFilterOption(false);
  });

  const noContentList = [
    "Reduce data entry errors.",
    "Ensure better management of payments.",
    "Improve customers payments experience.",
  ];

  const handleFinish = () => {
    setRefresTable(!refreshTable);
    // setPaymentLink({});
    setShowModal({
      delete: false,
      disable: false,
      share: false,
    });
  };

  return (
    <>
      <DashboardLayout>
        {customers?.data?.length > 0 || tableData ? (
          <>
            {" "}
            <TitletextBox
              title={`Customers`}
              text={`Manage and process customers request`}
              addBtn={user_resource_and_permissions?.allowed_permissions?.includes(
                "can_create_customer"
              )}
              //   addBtn={true}
              toolTipMsg={`Create a new customer`}
              onAdd={() => navigate("/dashboard-customers-add")}
            ></TitletextBox>
            {/* wrap start customers?.data?.length > 0 || tableData */}
            {/* wrap start */}
            <ContainerWrapper
              pagination={
                customers?.prev_page_url_params ||
                customers?.next_page_url_params ? (
                  <RavenPagination
                    numSpacing={size.width < 900 && 1}
                    removeNext={size.width < 900}
                    removePrev={size.width < 900}
                    currentPage={Number(customers?.current_page)}
                    totalPage={getTotalPage(
                      customers?.per_page,
                      customers?.total
                    )}
                    color={`black-light`}
                    blackHover
                    prevPage={customers?.prev_page_url_params}
                    nextPage={customers?.next_page_url_params}
                    onNumView={(e) => {
                      dispatch(
                        getPaymentLinksPagination(
                          `?per_page=${actionCtx?.perPageVal?.value}&current_page=${e}&range=3`
                        )
                      );
                    }}
                    onPrev={(e) => {
                      dispatch(getPaymentLinksPagination(e));
                      // console.log(e);
                    }}
                    onNext={(e) => {
                      dispatch(getPaymentLinksPagination(e));
                      // console.log(e);
                    }}
                  />
                ) : (
                  ""
                )
              }
              width={`100%`}
              dontShowHeader
            >
              <div className="new-payment-link-table-filter-wrap">
                {/* title text box start */}
                <div className="payment-link-new-search-filter-chart-box">
                  <div className="search-filter-box">
                    {/* per page start */}
                    {/* <PerPageComponent /> */}
                    {/* per page end */}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      autoComplete="off"
                      action=""
                    >
                      <div className="search-group">
                        <RavenInputField
                          type={`search`}
                          color={`black-light`}
                          placeholder={`Search`}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                    {/* export table content start */}
                    <CSVLink
                      // ref={download}
                      className="export-table-content-box tooltip-hover-wrap grey-bg"
                      filename={"Raven Customer.csv"}
                      data={formatExportData(customers?.data) || ""}
                      headers={CsvHeader}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        marginLeft: "auto",
                      }}
                    >
                      {" "}
                      Export Record
                      {/* <RavenToolTip
                        color="black-light"
                        text="Export Table Content"
                        position={`right`}
                      /> */}
                      <figure className="img-box">{exportTableIcon}</figure>
                    </CSVLink>
                    {/* export table content endd */}
                    {/* badge filter options start */}
                    <div
                      className="badge-filter-option-box"
                      style={{ marginLeft: "2rem" }}
                    >
                      {/* box start */}
                      {selectOptionVal?.one.label && (
                        <div className="option-box grey-bg">
                          <span>{selectOptionVal?.one?.label || "--"}</span>{" "}
                          <FaTimes
                            onClick={() => {
                              setSelectOptionVal((prev) => {
                                return {
                                  ...prev,
                                  one: { label: "", value: "" },
                                };
                              });
                            }}
                            className="icon grey-white-color-white"
                          />{" "}
                        </div>
                      )}
                      {selectOptionVal?.two.label && (
                        <div className="option-box grey-bg">
                          <span>{selectOptionVal?.two?.label || "--"}</span>{" "}
                          <FaTimes
                            onClick={() => {
                              setSelectOptionVal((prev) => {
                                return {
                                  ...prev,
                                  two: { label: "", value: "" },
                                };
                              });
                            }}
                            className="icon grey-white-color-white"
                          />{" "}
                        </div>
                      )}
                      {/* box end */}
                    </div>
                    {/* badge filter options end */}
                    {/* filter-drop box start */}
                    <div ref={testingCustomersRef} className="filter-drop-box">
                      {/* drop box start */}
                      {showFilterOption && (
                        <div className="drop-box-wrap  card-bg shadow-shadow-box-shadow">
                          <form autoComplete="off" action="">
                            <RavenInputField
                              selectOption={selectOption}
                              label={`Filter By Date`}
                              color={`black-light`}
                              type={"select"}
                              placeholder={`Select Date`}
                              selectStyles={reactSelectStyle}
                              value={selectOptionVal?.one}
                              onChange={(e) => {
                                if (
                                  e.label !== "Specific Date" &&
                                  e.label !== "Custom"
                                ) {
                                  setSelectOptionVal((prev) => {
                                    return { ...prev, one: e };
                                  });
                                }

                                if (e.label === "Specific Date") {
                                  setShowModal((prev) => {
                                    return {
                                      ...prev,
                                      selectSpecificDate: true,
                                    };
                                  });
                                }
                                if (e.label === "Custom") {
                                  setShowModal((prev) => {
                                    return { ...prev, customDate: true };
                                  });
                                }
                                showFilterOption && setShowFilterOption(false);
                                // setCheckCustom(true);
                              }}
                              selectValue={selectOptionVal?.one}
                            />
                          </form>
                        </div>
                      )}
                      {/* drop box end */}
                      {/* filter box start */}
                      <div
                        className="filter-box grey-bg"
                        onClick={() => {
                          setShowFilterOption(!showFilterOption);
                        }}
                      >
                        <p className="text grey-white-color-white">Filter</p>
                        <div className="line-box">
                          <span className=""></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                      {/* filter box end */}
                      {/* drop box start */}
                      <div className="drop-filter-wrap"></div>
                      {/* drop box end */}
                    </div>
                    {/* filter-drop box end */}
                  </div>
                </div>
                {/* title text box end */}
                {customers?.data?.length > 0 ? (
                  <div className="payment-link-wrap-box payment-link-wrap-box-new-index">
                    {/* <div className="left-box"> */}
                    {/* tabvle start */}
                    <RavenTable
                      className={`table-wrap table-fixed-type ${
                        formatMetaData(user?.theme_display_settings)
                          ?.table_font === "bold" && "table-business"
                      }`}
                      headerList={headerList}
                    >
                      {customers?.data?.map((chi, idx) => {
                        const {
                          customer_email,
                          customer_phone,
                          // created_at,
                          customer_name,
                          //   id,
                        } = chi;
                        return (
                          <RavenTableRow
                            className={`payment-link-table-row ${
                              dropToshow === chi?.id &&
                              "payment-link-table-row-index"
                            } `}
                            loading={loading}
                            key={idx}
                            one={
                              lowerCaseWrap(
                                customer_name?.split(" ")[0] || "---"
                              ) || "--"
                            }
                            three={customer_email || "---"}
                            //   onRowClick
                            ManualAddActions={() =>
                              payrollCurrentActions(chi, idx)
                            }
                            two={
                              lowerCaseWrap(
                                customer_name?.split(" ")[1] || "---"
                              ) || "--"
                            }
                            four={customer_phone || "---"}
                            five={
                              <DateFragmentBox
                                date={chi?.created_at}
                                // dontShowAgo={true}
                              />
                            }
                          />
                        );
                      })}
                    </RavenTable>
                    <div className="mobile-table-box">
                      {customers?.data?.map((chi, idx) => {
                        const { created_at, id } = chi;
                        return (
                          <MobileTableCard
                            key={idx || id}
                            amount={formatDate(created_at)?.split(" — ")[0]}
                            text={`${trimLongString(
                              chi?.customer_email,
                              16
                            )} • ${
                              chi?.customer_email?.length > 15
                                ? trimLongString(chi?.customer_phone, 6)
                                : chi?.customer_phone || ""
                            }`}
                            onRowClick={() => {
                              setSingleChild(chi);
                              setShowModal((prev) => {
                                return { ...prev, view: true };
                              });
                            }}
                            avatar={`${lowerCaseWrap(
                              chi?.customer_name?.split(" ")[0] || "---"
                            )} ${lowerCaseWrap(
                              chi?.customer_name?.split(" ")[1] || ""
                            )}`}
                            textTwo={`${formatDate(created_at).split("—")[0]}`}
                            loading={loading}
                            title={`${lowerCaseWrap(
                              chi?.customer_name?.split(" ")[0] || "---"
                            )} ${lowerCaseWrap(
                              chi?.customer_name?.split(" ")[1] || ""
                            )}`}
                            showImg
                          />
                        );
                      })}
                    </div>
                    {/* tabvle end */}
                    {/* left box end */}
                  </div>
                ) : (
                  <NoContentBox
                    title={`No customer found`}
                    text={`Click on the create button above to create a customer.`}
                    loading={loading}
                  />
                )}
              </div>
            </ContainerWrapper>
            {/* wrap end */}
          </>
        ) : (
          <>
            {" "}
            <NocontentPage
              loading={loading}
              img={noContentImg}
              //   title={`Customers globally 🌍, with your Payment links`}
              title={`Manage customers and send invoices to your billable customers globally🌍.`}
              list={noContentList}
              btntext={
                user_resource_and_permissions?.allowed_permissions?.includes(
                  "can_create_customer"
                ) && `Create customer`
              }
              onBtnClick={() => {
                navigate("/dashboard-customers-add");
              }}
            />
          </>
        )}
      </DashboardLayout>
      {/* delete modal start */}
      <DeleteCustomerModal
        detail={singleChild}
        visible={showModal.delete}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, delete: false };
          });
        }}
        onFinish={handleFinish}
      />
      {/* delete modal end */}
      {/* specific date start  */}
      <SpecificDateSelectModal
        visible={showModal?.selectSpecificDate}
        onFinish={(e) => {
          // console.log(e);
          setSelectOptionVal((prev) => {
            return {
              ...prev,
              one: {
                label: formatDate(e)?.split("—")[0],
                value: e,
                name: "Specific Date",
              },
            };
          });
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, selectSpecificDate: false };
          });
        }}
      />
      {/* specific date end */}
      {/* Custom date start */}
      <CustomDateSelectModal
        visible={showModal?.customDate}
        onFinish={(e) => {
          // formatFilterDate(e);
          setSelectOptionVal((prev) => {
            return {
              ...prev,
              one: {
                label: formatFilterDate(e),
                value: e,
                name: "Custom Date",
              },
            };
          });
          // setFilterVal({
          //   label: formatFilterDate(e),
          //   value: e,
          //   name: "Custom Date",
          // });
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, customDate: false };
          });
        }}
      />
      {/* custom date end */}
      {/* view modal on mobile start */}
      <CustomerMobileViewMobile
        detail={singleChild}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, delete: true, view: false };
          });
        }}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view: false };
          });
        }}
        visible={showModal?.view}
        onFinish={handleFinish}
        // setNewDate={setNewDateFuc}
      />
      {/* view modal on mobile end */}
    </>
  );
};

export default DashboardCustomerIndex;
