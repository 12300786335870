import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import { useNavigate } from "react-router-dom";
import TitletextBox from "../../../components/reusables/TitletextBox";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import "../../../styles/dashboard/global-account/SwapGlobalAccount.css";
import GlobalAmountInput from "../../../components/fragments/GlobalAmountInput";
import NewPreviewDetailBox from "./PreviewSwapContentBox";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllCurrencies,
  getAllGlobalAccount,
  getSingleGlobalAccount,
  swapGlobalAccount,
} from "../../../redux/globalAccount";
import ActionContext from "../../../context/ActionContext";
import {
  filterEmptyColumns,
  formatNumWithCommaNaira,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  formatNumberSpecail,
  getCurrencySymbol,
} from "../../../utils/helper/Helper";
import { RavenToolTip, toast } from "@ravenpay/raven-bank-ui";
import TransactionPinModal from "../../../components/reusables/TransactionPinModal";
import SuccessModal from "../../../components/reusables/SuccessModal";
import ChangeSourceAccount from "../../../components/reusables/ChangeSourceAccount";

const SwapGlobalAccountIndex = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const {
    // loadingTrans,
    single_global_account,
    loading,
    all_global_account,
    // all_beneficiaries,
    all_currencies,
  } = useSelector((state) => state.global_account);
  const dispatch = useDispatch();
  const stepList = ["Enter details", "Confirm details"];
  const swapIcon = (
    <svg
      className="img"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.77778 2.55566V11.4446M9.77778 11.4446L7.55556 9.22233M9.77778 11.4446L12 9.22233M4.22222 11.4446V2.55566M4.22222 2.55566L2 4.77789M4.22222 2.55566L6.44444 4.77789"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
    </svg>
  );

  // eslint-disable-next-line no-unused-vars
  const [currencyOption, setCurrencyOption] = useState([]);
  useEffect(() => {
    const id = localStorage?.getItem("global-acc-id");
    // console.log(id);
    if (id) {
      getSingleGlobalAccFunc(id);
    } else {
      navigate("/dashboard-global-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleGlobalAccFunc = async (param) => {
    // console.log(param, "------ud-----");
    // for transactions
    // console.log(param);
    const obj = {
      id: param,
    };
    // console.log(obj);
    if (Object?.keys(single_global_account)?.length < 1) {
      dispatch(getSingleGlobalAccount(obj));

      // else{
      //   const newObj =
      //   all_global_account?.global_accounts_available_for_current_business?.find(
      //     (chi) => String(chi?.id) === String(single_global_account?.global_account?.id)
      //   );
      // console.log(newObj?.currency);
      // }
    }
  };
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    sendCurrency: {},
    sendAmount: "",
    receiveCurreny: {},
    convertedAmount: "",
  });

  useEffect(() => {
    if (Object?.keys(all_global_account)?.length < 1) {
      dispatch(getAllGlobalAccount());
    }
    if (Object?.keys(all_currencies)?.length < 1) {
      dispatch(getAllCurrencies());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const addLabelValue = (list) => {
  //   if (list?.length > 0) {
  //     const newList = list?.map((chi) => {
  //       return {
  //         ...chi,
  //         label: chi?.currency,
  //         value: chi?.id || chi?.currency,
  //       };
  //     });
  //     return newList;
  //   }
  // };

  // getSingleDetails here------------------------

  // for currency List option one from dropdown list
  const [currencyAccountList, setCurrencyAccountList] = useState([]);
  useEffect(() => {
    if (
      all_global_account?.global_accounts_available_for_current_business
        ?.length > 0
    ) {
      // const newList = addLabelValue(
      //   all_global_account?.global_accounts_available_for_current_business
      // )?.filter((chi) => chi?.id !== details?.sendCurrency?.id);
      // console.log(newList);
      // console.log(addNGNtoList(newList));
      setCurrencyAccountList(
        all_global_account?.global_accounts_available_for_current_business
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_global_account, single_global_account, details?.sendCurrency]);

  useEffect(() => {
    if (
      all_global_account?.global_accounts_available_for_current_business
        ?.length > 0
    ) {
      const newObj =
        all_global_account?.global_accounts_available_for_current_business?.find(
          (chi) =>
            String(chi?.id) ===
            String(single_global_account?.global_account?.id)
        );
      const newCurrencyList = all_currencies?.all_support_currencies?.map(
        (chi) => {
          return { ...chi, label: chi?.currency, value: chi?.currency };
        }
      );
      const getNgnObj = all_currencies?.all_support_currencies?.find(
        (chi) => chi?.currency === "NGN"
      );
      //  console.log(newCurrencyList);
      const addLabelNgnObj = {
        ...getNgnObj,
        label: getNgnObj?.currency,
        value: getNgnObj?.currency,
      };
      setCurrencyOption(newCurrencyList);
      const objTwo = {
        ...newObj,
        value: newObj?.id || newObj?.currency,
        label: newObj?.currency,
      };

      // console.log(objTwo);
      actionCtx?.setGlobalCurrency(objTwo);
      setDetails((prev) => {
        return {
          ...prev,
          sendCurrency: objTwo,
          receiveCurreny: addLabelNgnObj,
        };
      });
      // getDeliveryMethodFunc(newObj?.currency);
      // getCurrencyInputFunc(newObj?.currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_global_account, all_global_account, all_currencies]);

  const getCurrencyRemovalUnwantedOption = (param, list) => {
    if (list?.length > 0) {
      const newList = list?.filter((chi) => chi?.id !== param?.id);
      return newList;
    }
  };

  // all currency list that are available for dropdown two
  const [availableCurrencyList, setAvailableCurencyList] = useState([]);
  useEffect(() => {
    if (Object?.keys(all_global_account)?.length > 0) {
      const newCurrencyListTwo =
        all_global_account?.global_accounts_available_for_current_business?.map(
          (chi) => {
            return {
              ...chi,
              label: chi?.currency,
              value: chi?.id || chi?.currency,
            };
          }
        );
      setAvailableCurencyList(newCurrencyListTwo);
    }
  }, [all_global_account]);

  const [savedRate, setSavedRate] = useState("");
  useEffect(() => {
    if (all_currencies?.all_support_currencies?.length > 0) {
      const newObj = all_currencies?.all_support_currencies?.find(
        (chi) => chi?.currency === details?.sendCurrency?.currency
      );
      const newObjList = newObj?.rates;
      // console.log(newObjList);
      const objRate = newObjList?.find(
        (chi) => chi?.currency_to === details?.receiveCurreny?.currency
      );
      const rate =
        details?.receiveCurreny?.currency === details?.sendCurrency?.currency
          ? 1
          : objRate?.rate;
      // const check = Number?.isNaN(Number(rate)?.toFixed(2))
      //   ? ""
      //   : Number(formatNumWithCommaNaira(rate));
      // console.log(Number(formatNumWithCommaNaira(rate)));
      // console.log((formatNumberSpecail(rate)));
      setSavedRate(formatNumberSpecail(rate));
      if (rate) {
        const value = (
          Number(rate) * Number(formatNumWithoutCommaNaira(details?.sendAmount))
        )?.toFixed(2);
        // console.log(value);
        setDetails((prev) => {
          return { ...prev, convertedAmount: value };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.sendCurrency, details?.receiveCurreny, details?.sendAmount]);

  // subaccout logic select here-----------
  const { all_account } = useSelector((state) => state.user);
  const [subAccount, setSubAccount] = useState({});

  useEffect(() => {
    // console.log();
    if (all_account?.length > 0) {
      const obj = all_account?.find((chi) => Number(chi?.primary) === 1);
      setSubAccount(obj);
    }
  }, [all_account]);

  const handleSubmit = async (pin, token) => {
    const obj = {
      amount: formatNumWithoutCommaNaira(details?.sendAmount),
      transfer_pin: pin || details?.transfer_pin,
      two_factor_token: token,
      from: details?.sendCurrency?.currency,
      to: details?.receiveCurreny?.currency,
      account_id:
        details?.receiveCurreny?.currency === "NGN" ||
        details?.sendCurrency?.currency === "NGN"
          ? subAccount?.id
          : "",
    };
    // console.log(obj);
    const data = await dispatch(swapGlobalAccount(filterEmptyColumns(obj)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    setDetails((prev) => {
      return {
        ...prev,
        sendCurrency: {},
        sendAmount: "",
        receiveCurreny: {},
        convertedAmount: "",
      };
    });
    dispatch(getAllGlobalAccount());
    setShowModal((prev) => {
      return { ...prev, pin: false };
    });
    navigate("/dashboard-global-account");
  };

  const addNGNtoList = (list) => {
    if (
      list?.length > 0 &&
      all_currencies?.all_support_currencies?.length > 0
    ) {
      const ngnObj = all_currencies?.all_support_currencies?.find(
        (chi) => chi?.currency === "NGN"
      );
      const newObj = {
        ...ngnObj,
        label: ngnObj?.currency,
        value: ngnObj?.code,
        id: ngnObj?.currency,
      };
      // console.log(newObj);
      if (Object?.keys(ngnObj)?.length > 0) {
        const newList = [...list, newObj];
        // console.log(newList);
        return newList;
      }
    }
  };

  const { user_resource_and_permissions } = useSelector((state) => state.user);
  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_setup_global_account"
      ) ||
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_swap_currencies"
      )
    ) {
      navigate("/dashboard-global-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Swap Currencies`}
          text={`Swap between multiple currencies`}
          back={true}
          onBack={() => {
            navigate("/dashboard-global-account-single");
          }}
        />
        {/* title box end */}
        {/* container start */}
        <ContainerWrapper
          back={step > 1}
          onBack={() => {
            if (step > 1) {
              setStep(step - 1);
            }
          }}
          disabled={
            Object?.keys(details?.receiveCurreny)?.length < 1 ||
            !details?.sendAmount ||
            Object?.keys(details?.sendCurrency)?.length < 1
          }
          onStepClick={(param) => {
            if (
              Object?.keys(details?.receiveCurreny)?.length > 0 &&
              details?.sendAmount &&
              Object?.keys(details?.sendCurrency)?.length > 0
            ) {
              setStep(param);
            }
          }}
          stepClickable={
            Object?.keys(details?.receiveCurreny)?.length > 0 &&
            details?.sendAmount &&
            Object?.keys(details?.sendCurrency)?.length > 0
          }
          activeStep={step}
          stepList={stepList}
          width={`100%`}
          btnText={step === 1 ? `Proceed` : `Swap`}
          onBtnClick={() => {
            if (step === 1) {
              if (
                Number(details?.sendCurrency?.balance) === 0 ||
                Number(details?.sendCurrency?.balance) <
                  Number(details?.sendAmount)
              ) {
                toast.error(
                  `Insufficient ${details?.sendCurrency?.currency} balance`,
                  {}
                );
                return;
              }
              if (
                details?.sendCurrency?.currency === "NGN" &&
                (Number(subAccount?.balance) === 0 ||
                  Number(subAccount?.balance) < Number(details?.sendAmount))
              ) {
                toast.error(`Insufficient ${subAccount?.currency} balance`, {});
                return;
              }
              setStep(2);
            }
            if (step === 2) {
              setShowModal((prev) => {
                return { ...prev, pin: true };
              });
            }
          }}
        >
          <div className="swap-global-account-index-wrap-box">
            <div className="centerered-div-box">
              {step === 1 && (
                <>
                  {" "}
                  <div
                    style={{ position: "relative", zIndex: 10 }}
                    className="two-amount-global-box"
                  >
                    {/* form box wrap grey start Swap From */}
                    <div
                      // onClick={() => {
                      //   console.log(subAccount);
                      // }}
                      style={{ position: "relative", zIndex: 10 }}
                      className="amount-grey-wrap-box grey-bg"
                    >
                      <GlobalAmountInput
                        dropClass={`grey-bg`}
                        wrapperClass={`white-black-bg`}
                        label={`Swap From`}
                        placeholder={`Enter amount `}
                        key={"kqjhghs"}
                        hideDrop={addNGNtoList(currencyAccountList)?.length < 1}
                        amountValue={details?.sendAmount}
                        selectedCurrency={details?.sendCurrency}
                        currencyList={addNGNtoList(currencyAccountList)}
                        handleChange={(e) => {
                          setDetails((prev) => {
                            return {
                              ...prev,
                              sendAmount: formatNumWithoutCommaNaira(
                                e.target.value
                              ),
                            };
                          });
                        }}
                        setCurrencyList={(param) => {
                          localStorage.setItem("global-acc-id", param?.id);
                          actionCtx?.setGlobalCurrency(param);
                          if (
                            details?.receiveCurreny?.currency ===
                            param?.currency
                          ) {
                            setDetails((prev) => {
                              return {
                                ...prev,
                                sendCurrency: param,
                                receiveCurreny: {},
                                convertedAmount: "",
                              };
                            });
                          } else {
                            setDetails((prev) => {
                              return {
                                ...prev,
                                sendCurrency: param,
                              };
                            });
                          }
                        }}
                        labelSpanText={
                          subAccount &&
                          Object?.keys(subAccount)?.length > 0 &&
                          details?.sendCurrency?.currency === "NGN"
                            ? `${formatNumWithCommaNairaSymbol(
                                subAccount?.balance
                              )}`
                            : Object?.keys(details?.sendCurrency)?.length > 0
                            ? `${getCurrencySymbol(
                                details?.sendCurrency?.currency
                              )}${formatNumWithCommaNaira(
                                details?.sendCurrency?.balance
                              )}`
                            : ""
                        }
                        numberPrefix={getCurrencySymbol(
                          details?.sendCurrency?.currency
                        )}
                        hideCur={
                          Object?.keys(details?.sendCurrency)?.length < 1
                        }
                      />
                    </div>
                    {/* form box wrap grey end */}
                    {/* form box wrap grey start */}
                    <div
                      style={{ position: "relative", zIndex: 9 }}
                      className="amount-grey-wrap-box Swap To grey-bg"
                    >
                      <GlobalAmountInput
                        dropClass={`grey-bg`}
                        wrapperClass={`white-black-bg`}
                        label={`Swap To`}
                        placeholder={` `}
                        disabled={true}
                        selectedCurrency={details?.receiveCurreny}
                        setCurrencyList={(param) => {
                          setDetails((prev) => {
                            return { ...prev, receiveCurreny: param };
                          });
                        }}
                        hideCur={
                          Object?.keys(details?.receiveCurreny)?.length < 1
                        }
                        amountValue={
                          !details?.sendAmount ? "" : details?.convertedAmount
                        }
                        numberPrefix={getCurrencySymbol(
                          details?.receiveCurreny?.currency
                        )}
                        dropStyles={{
                          maxHeight: "22rem",
                          paddingBottom:
                            getCurrencyRemovalUnwantedOption(
                              details?.receiveCurreny,
                              getCurrencyRemovalUnwantedOption(
                                details?.sendCurrency,
                                availableCurrencyList
                              )
                            )?.length > 5
                              ? "3rem"
                              : "0rem",
                        }}
                        currencyList={getCurrencyRemovalUnwantedOption(
                          details?.receiveCurreny,
                          getCurrencyRemovalUnwantedOption(
                            details?.sendCurrency,
                            addNGNtoList(availableCurrencyList)
                          )
                        )}
                        hideDrop={
                          getCurrencyRemovalUnwantedOption(
                            details?.receiveCurreny,
                            getCurrencyRemovalUnwantedOption(
                              details?.sendCurrency,
                              addNGNtoList(availableCurrencyList)
                            )
                          )?.length < 1
                        }
                        labelSpanText={
                          subAccount &&
                          Object?.keys(subAccount)?.length > 0 &&
                          details?.receiveCurreny?.currency === "NGN"
                            ? `${formatNumWithCommaNairaSymbol(
                                subAccount?.balance
                              )}`
                            : Object?.keys(details?.receiveCurreny)?.length > 0
                            ? `${
                                details?.receiveCurreny?.currency
                                  ? getCurrencySymbol(
                                      details?.receiveCurreny?.currency
                                    )
                                  : ""
                              }${formatNumWithCommaNaira(
                                details?.receiveCurreny?.balance
                              )}`
                            : ""
                        }
                      />
                    </div>
                    {/* form box wrap grey end */}
                    {/* swap-icon box start */}
                    <div
                      style={{
                        zIndex: 30,
                        cursor:
                          Object?.keys(details?.receiveCurreny)?.length > 0 &&
                          Object?.keys(details?.sendCurrency)?.length > 0
                            ? "pointer"
                            : "default",
                      }}
                      className="swap-global-icon-box white-black-bg  card-bg"
                      onClick={() => {
                        if (
                          Object?.keys(details?.receiveCurreny)?.length > 0 &&
                          Object?.keys(details?.sendCurrency)?.length > 0
                        ) {
                          setDetails((prev) => {
                            return {
                              ...prev,
                              sendCurrency: details?.receiveCurreny,
                              receiveCurreny: details?.sendCurrency,
                            };
                          });
                        }
                      }}
                    >
                      {/* <div  className="tooltip-hover-wrap">
                        
                      </div> */}
                      <figure className="img-box tooltip-hover-wrap">
                        {swapIcon}
                        {Object?.keys(details?.receiveCurreny)?.length > 0 &&
                          Object?.keys(details?.sendCurrency)?.length > 0 && (
                            <>
                              <RavenToolTip
                                color="black-light"
                                text="Swap currency"
                                position={`right`}
                              />
                            </>
                          )}
                      </figure>
                    </div>
                    {/* swap icon box end */}
                  </div>
                  {/*  swap fee exchange rate box start */}
                  <div className="swap-fee-exchange-rate-box">
                    {/* label value dot box start */}
                    {/* <div className="label-value-dot-box">
                      <p className="text">
                        {" "}
                        <span className="grey-white-color">Swap Fee:</span>
                        <span className="value"> 0.00</span>
                      </p>
                    </div> */}
                    {/*label value dot box end  */}
                    {/* label value dot box start */}
                    <div className="label-value-dot-box">
                      <p className="text">
                        {" "}
                        <span className="grey-white-color">Exchange Rate:</span>
                        {Object?.keys(details?.receiveCurreny)?.length > 0 &&
                        details?.sendAmount &&
                        Object?.keys(details?.sendCurrency)?.length > 0 ? (
                          <span className="value">{`1 ${
                            details?.sendCurrency?.currency || ""
                          } = ${
                            Number?.isNaN(savedRate) ? "" : savedRate || ""
                          } ${details?.receiveCurreny?.currency || ""}`}</span>
                        ) : (
                          ""
                        )}
                        {/* <span className="grey-white-color">Exchange Rate:</span>
                        <span className="value">1 USD = 1310.03 NGN</span> */}
                      </p>
                    </div>
                    {/*label value dot box end  */}
                  </div>
                  {/*  swap fee exchange rate box end */}
                </>
              )}

              {step === 2 && (
                <>
                  <NewPreviewDetailBox
                    details={{ ...details, savedRate: savedRate }}
                    amount={formatNumWithCommaNaira(details?.sendAmount)}
                  />
                </>
              )}
            </div>
            {/* ngn account swap start --- */}
            {step === 1 && (
              <>
                {details?.receiveCurreny?.currency === "NGN" ||
                details?.sendCurrency?.currency === "NGN" ? (
                  <div className="select-form-wrap-box">
                    {/* source acount change box */}
                    <ChangeSourceAccount
                      onObjChange={(e) => {
                        setSubAccount(e);
                      }}
                      // downDropType
                      darkType={true}
                      // onChange={() => onClose()}
                      title={
                        details?.receiveCurreny?.currency === "NGN"
                          ? `Swap to account.`
                          : details?.sendCurrency?.currency === "NGN"
                          ? `Swap from account.`
                          : ``
                      }
                      defaultObj={subAccount}
                    />
                    {/* source account change box end */}
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            {/* ngn account swap end */}
          </div>
        </ContainerWrapper>
        {/* container end */}
      </DashboardLayout>
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Transaction Successful."
        text={`You should also receive a confirmation email with the details of the transaction. We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default SwapGlobalAccountIndex;
