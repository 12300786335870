import { RavenButton,  } from "@ravenpay/raven-bank-ui";
import React from "react";
import { useSelector } from "react-redux";
import testLogo from "../../assets/success-payment-web-img.png";
import { formatNumWithCommaNairaSymbol } from "../../utils/helper/Helper";

const SuccessPaymentContent = ({ setStep, details, onDownload }) => {
  const { loadingDownload } = useSelector((state) => state.billPayment);
  return (
    <>
      <div className="content content-success-wrap">
        {/* top detail box start */}
        <div className="top-detail-box">
          <div className="img-wrap">
            <figure className="img-box">
              <img src={testLogo} alt="" className="img" />
            </figure>
          </div>
          <p className="title">Payment Successful</p>
        </div>
        {/* top detail box end */}
        {/* bottom detail box start */}
        <div className="bottom-detail-box">
          {/* box start */}
          <div className="box">
            <p className="label">Merchant Name</p>
            <p className="value">
              {details?.business_name || details?.merchant_name || "---"}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label">Payment Amount</p>
            <p className="value">
              {formatNumWithCommaNairaSymbol(details?.amount) || "---"}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label">Payment Method</p>
            <p className="value">{details?.payment_method || "---"}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label">Payment Reference</p>
            <p className="value">{details?.ref || "---"}</p>
          </div>
          {/* box end */}
        </div>
        {/* bottom detail box end */}
      </div>
      {/* end of content */}
      {/* btn box start */}
      <div className="btn-box btn-box-two">
        <RavenButton
          onClick={onDownload}
          color={`green-dark`}
          label={`Download Receipt`}
          loading={loadingDownload}
        />
      </div>
      {/* btn box end */}
    </>
  );
};

export default SuccessPaymentContent;
