import React from 'react'
import NocontentPage from '../../../components/reusables/NocontentPage'
import DashboardLayout from '../../../layout/dashboard/DashboardLayout'
import noContentImg from "../../../assets/store-no-content-img.png";

const DashboardLoanIndex = () => {
  return (
    <DashboardLayout>
      <NocontentPage
        img={noContentImg}
        type="two"
        title={`Loan is coming soon 🔥`}
        normtext={`Unlock your financial freedom with our upcoming loan product, where your dreams become a reality.`}
      />
    </DashboardLayout>
  )
}

export default DashboardLoanIndex