import moment from "moment";
import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateStatementAllTransactionForAllEmp } from "../../../../redux/payroll";
import "../../../../styles/dashboard/payroll/PayrollIndex.css";

const GenerateAllDisburseModal = ({ visible, onCancel }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const dispatch = useDispatch();
  const { loadingManual, loadingStatement } = useSelector(
    (state) => state.payroll
  );

  const handleSubmit = async () => {
    const obj = {
      date: `${fromDate}_${toDate}`,
    };
    // console.log(obj);
    const data = await dispatch(generateStatementAllTransactionForAllEmp(obj));
    if (data?.payload?.data?.status === "success") {
      setFromDate("");
      setToDate("");
      // downloadCsv(data?.payload?.data?.data?.url);
      onCancel();
    }
  };

  // const downloadCsv = (url) => {
  //   window.open(url, "_blank").focus();
  // };

  return (
    <RavenModal
      btnColor={`black-light`}
      btnLabel={`Generate`}
      visble={visible}
      onClose={onCancel}
      disabled={!fromDate || !toDate}
      onBtnClick={handleSubmit}
      loading={loadingManual || loadingStatement}
    >
      <div className="generate-employee-transaction-detail-box">
        <p className="title">Generate Statement</p>
        <p className="text grey-white-color">Select Date Range</p>
        <RavenInputField
          color={`black-light`}
          type={`date`}
          value={fromDate}
          label={`From`}
          placeholder={`Select date from`}
          onChange={(e) => {
            setFromDate(moment(e[0]).format("YYYY-MM-DD"));
          }}
        />
        <RavenInputField
          placeholder={`Select date to`}
          color={`black-light`}
          type={`date`}
          value={toDate}
          label={`To`}
          onChange={(e) => {
            setToDate(moment(e[0]).format("YYYY-MM-DD"));
          }}
        />
      </div>
    </RavenModal>
  );
};

export default GenerateAllDisburseModal;
