
import React, { useEffect, useState } from "react";
import DashboardSettingsLayout from './SettingsLayout'
import { RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import notyemailIcon from "../../../../assets/email-settings-notifi-icon.svg";
import notySmsIcon from "../../../../assets/settings-sms-notification-icon.svg";
import {
  notificationCreditSettings,
  notificationSettings,
  updateAppearanceTwo,
} from "../../../../redux/settings";
import { getUser } from "../../../../redux/user";
import waveSound from "../../../../assets/raven_success_sound.wav";
import {
  formatMetaData,
  // getSoundToUseFunction,
} from "../../../../utils/helper/Helper";
import UseNotificationSound from "../../../../components/reusables/newSoundReusable";

const DashboardSettingsNotification = () => {
     // const actionCtx = useContext(ActionContext);
  const { business, user } = useSelector((state) => state.user);
  const [emailStatus, setEmailStatus] = useState(0);
  const [creditStatus, setCreditStatus] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [notificationSound, setNotificationSound] = useState("");
  // const { loading } = useSelector((state) => state.settings);
  const [notificationStatus, setNotificationStatus] = useState(0);
  const [disableSms, setDisableSms] = useState(false);
  const [appearanceObj, setAppearanceObj] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(business)?.length > 0) {
      setEmailStatus(Number(business?.email_notification_control));
      // console.log(business?.email_notification_control);
    }
  }, [business]);
  const playNotification = UseNotificationSound(waveSound);
  const handleNotificationEmail = async (val) => {
    const obj = {
      email_notification_toggle: String(val),
    };
    setEmailStatus(val);
    const data = await dispatch(notificationSettings(obj));
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
    }
  };

  useEffect(() => {
    setAppearanceObj(formatMetaData(user?.theme_display_settings));
    setNotificationSound(formatMetaData(user?.theme_display_settings)?.notification_sound);
    setNotificationStatus(
      formatMetaData(user?.theme_display_settings)?.enable_sound
    );
  }, [user]);


  useEffect(() => {
    Number(user?.credit_alert_notification_status) === 1
      ? setCreditStatus(1)
      : setCreditStatus(0);
  }, [user]);

  // useEffect(() => {}, [])

  const handleEnableSound = async (param) => {
    const obj = {
      ...appearanceObj,
      enable_sound: param,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(updateAppearanceTwo(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      localStorage?.setItem("sidebar-theme-style", obj?.side_nav_style);
      dispatch(getUser());
    }
  };

  const handleShowCreditAlert = async (param) => {
    const obj = {
      status: String(param),
    };
    const data = await dispatch(notificationCreditSettings(obj));
    if (data?.payload?.data?.status === "success") {
      dispatch(getUser());
    }
  };
  return (
    <>
    <DashboardSettingsLayout>
    <div className="detail-wrap animate-move-up-class">
      <p className="title">Notification Preferences</p>
      <p className="sub-title grey-white-color">
        Manage all your notifications in one place.
      </p>
      <div className="box-wrap grey-bg">
        <div className="noti-wrap">
          <figure className="img-box">
            <img src={notyemailIcon} alt="" className="img" />
          </figure>
          <div className="text-box">
            <p className="value">Enable Email Notifications</p>
            <p className="label grey-white-color">
              Access to receiving email notifications for your transactions
            </p>
          </div>
          <RavenToggleSlide
            onChange={() => {
              emailStatus === 0
                ? handleNotificationEmail(1)
                : handleNotificationEmail(0);
            }}
            checked={emailStatus === 1 ? true : false}
            value={emailStatus}
            id="jgjk"
            color="black-light"
          />
        </div>
        <div className="noti-wrap">
          <figure className="img-box">
            <img src={notyemailIcon} alt="" className="img" />
          </figure>
          <div className="text-box">
            <p className="value">
              Enable Money Received Confetti Notifications
            </p>
            <p className="label grey-white-color">
              Access to receiving credit alert notifications for your
              transactions.
            </p>
          </div>
          <RavenToggleSlide
            className=""
            // style={{cursor: "default"}}
            onChange={() => {
              creditStatus === 1 ? setCreditStatus(0) : setCreditStatus(1);
              handleShowCreditAlert(creditStatus === 1 ? 0 : 1);
            }}
            // value={disableSms}
            checked={creditStatus === 1 ? true : false}
            id="iohjg8"
            color="black-light"
          />
        </div>
        {/* sound start */}
        <div className="noti-wrap">
          <figure className="img-box">
            <img src={notyemailIcon} alt="" className="img" />
          </figure>
          <div className="text-box">
            <p className="value">Enable Sound Notifications</p>
            <p className="label grey-white-color">
              Access to receiving credit notifications with sound across the
              application.
            </p>
          </div>
          <RavenToggleSlide
            className=""
            // style={{cursor: "default"}}
            onChange={() => {

              notificationStatus === 1
                ? setNotificationStatus(0)
                : setNotificationStatus(1);
              handleEnableSound(notificationStatus === 1 ? 0 : 1);
              notificationStatus !== 1 && playNotification()
            }}
            // value={disableSms}
            checked={notificationStatus === 1 ? true : false}
            id="iohjg8dgcg"
            color="black-light"
          />
        </div>
        {/* sound end ----------- */}
        {/* sound type start --------- */}
        {/* theme preference start */}
      
        {/* theme preference end */}
        {/* sound type end --------- */}
        <div className="noti-wrap noti-wrap-sms-notify-toggle">
          <figure className="img-box">
            <img src={notySmsIcon} alt="" className="img" />
          </figure>
          <div className="text-box">
            <p className="value">Enable SMS Notifications</p>
            <p className="label grey-white-color">
              Access to receiving SMS notifications for your transactions
            </p>
          </div>
          <RavenToggleSlide
            className="sms-notify-toggle"
            // style={{cursor: "default"}}
            onChange={() => setDisableSms(false)}
            value={disableSms}
            checked={disableSms}
            id="ioh8"
            color="black-light"
          />
        </div>
      </div>
    </div>
    </DashboardSettingsLayout>
    </>
  )
}

export default DashboardSettingsNotification