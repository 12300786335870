import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
// import { FaCheck, FaMinus, FaPlus } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import ChangeSourceAccount from "../../../components/reusables/ChangeSourceAccount";
import ActionContext from "../../../context/ActionContext";
// import ReactGooglePlacesSuggest from 'react-google-places-suggest';
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";
import { createInvoiceSettings } from "../../../redux/invoice";
import "../../../styles/dashboard/invoice/existing_invoice.css";
import {
  //   formatNumWithCommaNaira,
  formatNumWithoutCommaNaira,
  trimLongString,
} from "../../../utils/helper/Helper";
// import { formatNumWithoutCommaNaira } from "../../../utils/helper/Helper"; loadingDelete

const InvoiceSettingsAddress = ({ visible, onClose, onFinish }) => {
  const actionCtx = useContext(ActionContext);
  const { loadingSetting, invoiceSetting } = useSelector(
    (state) => state.invoice
  );
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  //   const [invoiceObj, setInvoiceObj] = useState({});
  const [details, setDetails] = useState({
    discount: 0,
    shipping_fee: 0.0,
    email_reminder_in_days: "",
    tax: 0,
    address: "",
    selected_account_number: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [checkdetails, setcheckDetails] = useState({
    discount: "",
    shipping_fee: "",
    email_reminder_in_days: "",
    tax: "",
    address: "",
    selected_account_number: "",
  });
  // const [Checkdetails, setCheckDetails] = useState({
  //   discount: 0,
  //   shipping_fee: 0.0,
  //   email_reminder_in_days: "",
  //   tax: 0,
  //   address: "",
  //   selected_account_number: "",
  // });
  const dispatch = useDispatch();
  //   const emailDaysOption = ["3", "5", "10", "14", "20"];
  const [editAddress, setEditAddress] = useState(true);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const removeZero =
      String(value).split("")[0] === "0"
        ? value?.toString().substring(1)
        : value;
    const obj = { ...details, [name]: removeZero };
    setDetails(obj);
    setcheckDetails(obj);
  };

  const [subAccount, setSubAccount] = useState({});

  useEffect(() => {
    if (invoiceSetting) {
      if (Object?.keys(invoiceSetting)?.length > 0) {
        // setInvoiceObj(invoiceSetting);
        // console.log(invoiceSetting);
        if (invoiceSetting?.account_number_id) {
          // setDetails(invoiceSetting);
          setSubAccount(
            invoiceSetting?.selected_sub_account || actionCtx?.currentAccount
          );
        }
        setDetails(invoiceSetting);
        // setSubAccount(invoiceSetting?.selected_sub_account);
        // console.log(all_account);
        // console.log(invoiceSetting);
      }
      if (Object?.keys(invoiceSetting)?.length < 1) {
        setDetails({
          discount: 0,
          shipping_fee: 0.0,
          email_reminder_in_days: "",
          tax: 0,
          selected_sub_account: "",
        });
      }
    }

    // console.log(invoiceSetting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceSetting]);

  // useEffect(() => {
  //   console.log(actionCtx?.currentAccount);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [actionCtx?.currentAccount]);

  const addressIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M16 13.3744C19.5318 14.0688 22 15.6547 22 17.5C22 19.9853 17.5228 22 12 22C6.47715 22 2 19.9853 2 17.5C2 15.6547 4.46819 14.0688 8 13.3744M12 17V3L17.3177 6.27244C17.7056 6.51114 17.8995 6.63049 17.9614 6.78085C18.0154 6.912 18.0111 7.0599 17.9497 7.18771C17.8792 7.33426 17.6787 7.44222 17.2777 7.65815L12 10.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    if (Number(details?.tax) > 100) {
      setDetails((prev) => {
        return { ...prev, tax: 100 };
      });
    }
    if (Number(details?.discount) > 100) {
      setDetails((prev) => {
        return { ...prev, discount: 100 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  //   const nairaSymbol = "\u{020A6}";

  const handleSubmit = async () => {
    // console.log(details);
    const obj = {
      discount: String(details?.discount),
      shipping_fee: String(formatNumWithoutCommaNaira(details?.shipping_fee)),
      email_reminder_in_days: String(details?.email_reminder_in_days),
      tax: String(details?.tax),
      address: details?.address,
      selected_account_number:
        subAccount?.account_number || actionCtx?.currentAccount?.account_number,
    };
    // console.log(obj);
    // return
    const data = await dispatch(createInvoiceSettings(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
    }
  };

  return (
    <RavenModal
      className={`invoice-setting-modal-wrap`}
      btnColor="black-light"
      btnLabel={
        user_resource_and_permissions?.allowed_permissions?.includes(
          "can_update_invoice"
        ) &&
        // (checkdetails?.discount ||
        //   checkdetails?.shipping_fee ||
        //   checkdetails?.email_reminder_in_days ||
        //   checkdetails?.tax ||
        //   checkdetails?.address ||
        //   checkdetails?.selected_account_number) &&
        "Save Settings"
      }
      visble={visible}
      onClose={onClose}
      loading={loadingSetting}
      onBtnClick={handleSubmit}
    >
      <div
        style={{ minHeight: "unset", height: "max-content" }}
        className="invoice-setting-wrap-modal"
      >
        <div className="title-text-box border-theme-bottom">
          <p className="title">Invoice Address</p>
          <p className="text grey-white-color">
            Include an address to your invoice settings
          </p>
        </div>
        {/* content box start */}
        <div className="content-box">
          {/* info box start */}
          <div className="info-box">
            {/* invoice address start--------- */}
            <div
              style={{ marginTop: "-1.5rem", marginBottom: "-1.5rem" }}
              className="invoice-address-box"
            >
              <div className="text-title-box">
                {/* <div className="edit-box">
                  <p
                    onClick={() => {
                      setEditAddress(true);
                    }}
                    className="text"
                  >
                    {editAddress ? `` : `Edit`}
                  </p>
                </div> */}
              </div>
              {/* adress content start */}
              <div className="address-content-box">
                <div className="img-wrap ">
                  <figure className="img-box grey-bg">{addressIcon}</figure>
                </div>
                {editAddress ? (
                  <>
                    <RavenInputField
                      placeholder="Invoice address"
                      type="text"
                      color="black-light"
                      onBlur={() => {
                        // setEditAddress(false);
                        // console.log("lkjhd");
                      }}
                      onFocus={() => {
                        setEditAddress(true);
                      }}
                      value={details?.address}
                      name={`address`}
                      onChange={handleChange}
                      autoFocus={true}
                      capitalize
                    />
                    
                  </>
                ) : (
                  <>
                    <p className="address-text">
                      {trimLongString(details?.address, 50) || `---`}
                    </p>
                  </>
                )}
              </div>
              {/* adress content end */}
            </div>
            {/* invoice address end -----------*/}
            {/* account drop wrap start */}
            {/* <ChangeSourceAccount
              onObjChange={(e) => {
                setSubAccount(e);
              }}
              darkType={true}
              // onChange={() => onClose()}
              title={`Invoice Settlement Account.`}
              defaultObj={subAccount}
            /> */}
            {/* account drop wrap end */}
          </div>
          {/* info box end */}
        </div>
        {/* content box end */}
      </div>
    </RavenModal>
  );
};

export default InvoiceSettingsAddress;
