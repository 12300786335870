import {
  RavenCheckBox,
  RavenInputField,
  // RavenPinModal,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/payment-links/PaymentLink.css";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
// import PreviewPaymentDetailsBox from "./PreviePaymentLinkBox";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentLink, updatePaymentLink } from "../../../redux/requestMoney";
import { formatNumWithoutCommaNaira } from "../../../utils/Helpers";

const EditPaymentLink = () => {
  // const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [paymentLink, setPaymentLink] = useState({});
  const dispatch = useDispatch();
  const [step] = useState(1);
  const { loading } = useSelector((state) => state.paymentLinks);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const stepList = ["Link details"];
  const [paymentType, setPaymentType] = useState("One time Payment");
  const typeList = [
    {
      name: "One time Payment",
      text: "Accept a one time payment with a link created",
      icon: (
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img dull-bright-filter"
        >
          <path
            d="M11.0008 1L2.09429 11.6879C1.74549 12.1064 1.57108 12.3157 1.56842 12.4925C1.5661 12.6461 1.63457 12.7923 1.7541 12.8889C1.89159 13 2.16402 13 2.70887 13H10.0008L9.00084 21L17.9074 10.3121C18.2562 9.89358 18.4306 9.68429 18.4333 9.50754C18.4356 9.35388 18.3671 9.2077 18.2476 9.11111C18.1101 9 17.8377 9 17.2928 9H10.0008L11.0008 1Z"
            // stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Recurring Payment",
      text: "Accept a payment with a link created with a set time frame",
      icon: (
        <svg
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img dull-bright-filter"
        >
          <path
            d="M20.7 11.5L18.7005 9.5L16.7 11.5M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C13.3019 1 16.1885 2.77814 17.7545 5.42909M10 5V10L13 12"
            // stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];
  const [details, setDetails] = useState({
    request_amount: "",
    email: "",
    request_description: "",
    expiry_date: "",
    recurring: false,
    agree: false,
    title: "",
    allow_edit_amount: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  useEffect(() => {
    getSingleLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleLink = async () => {
    const id = localStorage.getItem("payment-link-id");
    const data = await dispatch(getPaymentLink(id));
    if (data?.payload?.data?.status === "success") {
      const obj = data?.payload?.data?.data?.linkDetail;
      // console.log(obj);
      setPaymentLink(obj);
      obj?.recurring === 1
        ? setPaymentType("Recurring Payment")
        : setPaymentType("One time Payment");
      setDetails((prev) => {
        return {
          ...prev,
          ...obj,
          allow_edit_amount: obj?.allow_edit_amount === 1 ? true : false,
          request_amount: obj?.request_amount,
          recurring: obj?.recurring === 1 ? true : false,
          email: obj?.email,
          request_description: obj?.request_description,
          expiry_date:
            moment(obj?.expiry_date).format("YYYY-MM-DD HH:mm") || "",
        };
      });
    }
  };

  const handleUpdate = async () => {
    let val;
    if (paymentType === "Recurring Payment") {
      const obj = {
        expiry_date: moment(details?.expiry_date).format("YYYY-MM-DD HH:mm"),
        request_amount: formatNumWithoutCommaNaira(details?.request_amount),
        request_description: details?.request_description,
        id: details?.id,
        recipient_email: details?.email,
        recurring: "1",
        title: details?.title,
        allow_edit_amount: details?.allow_edit_amount ? "1" : "0",
      };
      val = obj;
    } else {
      const obj = {
        request_amount: formatNumWithoutCommaNaira(details?.request_amount),
        recipient_email: details?.email,
        request_description: details?.request_description,
        recurring: "0",
        id: details?.id,
        title: details?.title,
        allow_edit_amount: details?.allow_edit_amount ? "1" : "0",
      };
      val = obj;
    }
    // console.log(val);
    // return
    const data = await dispatch(updatePaymentLink(val));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      // onFinish();
      navigate("/dashboard-payment-links");
    }
  };

  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_update_payment_links"
      )
    ) {
      navigate("/dashboard-payment-links");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_resource_and_permissions]);

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          back
          onBack={() => navigate("/dashboard-payment-links" || -1)}
          title={`Edit Payment Link`}
          text={`Create a payment link to generate to request money easily.`}
        />
        {/* wrapper start */}
        <ContainerWrapper
          btnText={`Confirm Link Update`}
          stepList={stepList}
          activeStep={step}
          // back={step > 1}
          disabled={
            paymentType === "One time Payment"
              ? !details?.request_amount &&
                !details?.email &&
                !details?.request_description
              : !details?.request_amount &&
                !details?.email &&
                !details?.request_description &&
                !details?.expiry_date
          }
          onBtnClick={() => {
            handleUpdate();
          }}
          loading={loading}
        >
          {/* request money wrap start */}
          <div className="request-money-wrap-box">
            {step === 1 && (
              <>
                {/* type select box start */}
                <div className="select-type-box">
                  {typeList.map((chi, idx) => {
                    const { icon, name, text } = chi;
                    return (
                      <div
                        key={idx}
                        className={`box  grey-bg remove-dark-border ${
                          name === paymentType && "box-active"
                        }`}
                        onClick={() => {
                          setPaymentType(name);
                        }}
                      >
                        <div className="check-box">
                          <FaCheck className="icon" />
                        </div>
                        <figure className="img-box">{icon}</figure>
                        <p className="title">{name}</p>
                        <p className="text grey-white-color">{text}</p>
                      </div>
                    );
                  })}
                </div>
                {/* type select box end */}
                {/* type select box end */}
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  {" "}
                  <RavenInputField
                    type={`text`}
                    label={`Link Title* `}
                    color={`black-light`}
                    placeholder={`e.g Payment Contract`}
                    // showError={showError?.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={details?.title}
                    name={`title`}
                    // className={`below-expiry`}
                  />
                </form>
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  {" "}
                  <RavenInputField
                    type={`number`}
                    label={`Amount* `}
                    color={`black-light`}
                    placeholder={`e.g 5,000.00`}
                    thousandFormat
                    numberPrefix={`₦ `}
                    onChange={handleChange}
                    value={details?.request_amount}
                    name={`request_amount`}
                  />
                </form>

                {/* edit amount start */}
                <div
                  style={{ display: "flex" }}
                  className="edit-amount-check-wrap"
                >
                  <RavenCheckBox
                    color="black-light"
                    value={details?.allow_edit_amount}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          allow_edit_amount: !details.allow_edit_amount,
                        };
                      });
                    }}
                    key={`hgh`}
                    id={`jhbbbdv73`}
                    checked={details?.allow_edit_amount}
                  />
                  <p className="text">Allow customer edit amount</p>
                </div>
                {/* edit amount end */}
                {/* reccuring start */}
                <div
                  className={`reccuring-box ${
                    paymentType === "Recurring Payment" && "reccuring-box-show"
                  }`}
                >
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      type={`date`}
                      label={`Link expiry date* `}
                      color={`black-light`}
                      placeholder={`Select a Date`}
                      enableTime
                      onChange={(e) => {
                        setDetails((prev) => {
                          return {
                            ...prev,
                            expiry_date: moment(e[0]).format(
                              "YYYY-MM-DD HH:mm A"
                            ),
                          };
                        });
                      }}
                      value={details?.expiry_date}
                      name={`expiry_date`}
                    />
                  </form>
                </div>
                {/* reccuring end */}
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  {" "}
                  <RavenInputField
                    type={`textarea`}
                    label={`Link Narration* `}
                    color={`black-light`}
                    placeholder={`Whats the payment link for?`}
                    textareaRow={8}
                    onChange={handleChange}
                    value={details?.request_description}
                    name={`request_description`}
                  />
                </form>
              </>
            )}
          </div>
          {/* request money wrap end */}
        </ContainerWrapper>
        {/* wrapper end */}
      </DashboardLayout>
    </>
  );
};

export default EditPaymentLink;
