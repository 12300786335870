/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
// import setAuthToken from "../utils/auth";
import { toast } from "@ravenpay/raven-bank-ui";
import { formatTypeFunction } from "../utils/helper/Helper";

export const notificationSettings = createAsyncThunk(
  "admin/notification-setting",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/business/toggle_email_notification",
        payload
      );
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const notificationCreditSettings = createAsyncThunk(
  "admin/notification-setting",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/user/update_credit_notification_status",
        payload
      );
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const enableTwoFaFactorSettings = createAsyncThunk(
  "admin/2fa-factor-enable-setting",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        "/two-factor/enable-two-factor-auth",
        payload
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const verifyTwoFaFactorSettings = createAsyncThunk(
  "admin/verify-2fa-factor-enable-setting",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/two-factor/verify-2-fa", payload);
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const disableTwoFaFactorSettings = createAsyncThunk(
  "admin/disable-2fa-factor-enable-setting",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/two-factor/disable-two-factor-auth-using-auth-app",
        payload
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const addressVerification = createAsyncThunk(
  "admin/address-verification",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTokenForTransactionResetPin = createAsyncThunk(
  "admin/get-token-for-transaction-reset",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        "/transaction-pin/send-pin-reset-token",
        payload
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const verifyResetTokenTransationPin = createAsyncThunk(
  "admin/verifiy-reset-token-for-transaction-pin",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/transaction-pin/verify-reset-pin-token",
        payload
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const resetTransactionPin = createAsyncThunk(
  "admin/reset-transaction-pin",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        "/transaction-pin/reset-transaction-pin",
        payload
      );
      // console.log(data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getDirectorShareholders = createAsyncThunk(
  "admin/get-director-shareholder",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/get/directors", payload);
      // console.log("register", data);

      if (data?.status === "fail") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data?.status === "success") {
        // toast.success(data.message, {
        //   theme: "colored",
        // });
        await thunkAPI.dispatch(setDirectors(data.data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getShareholders = createAsyncThunk(
  "admin/get-shareholder",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/get/shareholders", payload);
      // console.log("register", data);

      if (data?.status === "fail") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data?.status === "success") {
        // toast.success(data.message, {
        //   theme: "colored",
        // });
        await thunkAPI.dispatch(setShareHolders(data.data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getBeneficiaries = createAsyncThunk(
  "admin/get-beneficiaries",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/get_beneficiaries", payload);
      // console.log("register", data);

      if (data?.status === "fail") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.status === "success") {
        // console.log(data);
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setBeneficiaries(data.data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const searchBeneficiaries = createAsyncThunk(
  "admin/search-beneficiaries",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/search_beneficiaries?query=${payload?.term}`,
        payload
      );
      // console.log("register", data);

      if (data?.status === "fail") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.status === "success") {
        // console.log(data.data.beneficiaries);
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setBeneficiaries(data.data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const addBeneficiaries = createAsyncThunk(
  "admin/add-beneficiaries",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/add_beneficiary", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const updateAppearance = createAsyncThunk(
  "admin/update-appearance",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/update_theme_display_settings", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const updateAppearanceTwo = createAsyncThunk(
  "admin/update-appearance-two",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/update_theme_display_settings", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const removeBeneficiaries = createAsyncThunk(
  "admin/remove-beneficiaries",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/remove_beneficiary", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const editBeneficiaries = createAsyncThunk(
  "admin/edit-beneficiaries",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `/edit_beneficiary/${payload?.id}`,
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getTeamRoles = createAsyncThunk(
  "admin/get-team-roles-members",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/teams/get_roles", payload);
      // console.log("register-----------------", data);
      if (data?.status === "fail") {
        //
      }
      if (data?.status === "success") {
        // console.log("register", data.data);
        await thunkAPI.dispatch(setTeamRoles(data.data.roles));
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getAllDevices = createAsyncThunk(
  "admin/get-all-devices",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/devices/get_all", payload);
      // console.log(data);
      if (data?.status === "fail") {
        //
      }
      if (data?.status === "success") {
        // console.log("devices", data.data.devices);
        await thunkAPI.dispatch(setDevices(data.data.devices));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

const formatMetadata = (param) => {
  const val = param.map((chi) => {
    return { ...chi, fingerprint: JSON.parse(chi?.fingerprint) };
  });
  return val;
};

export const getTeamMembers = createAsyncThunk(
  "admin/get-members",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("/teams/team_members", payload);
      // console.log("register", data);
      if (data?.status === "fail") {
        //
      }
      if (data?.status === "success") {
        await thunkAPI.dispatch(setTeamMembers(data.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const updateTeamRole = createAsyncThunk(
  "admin/update-role-team",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/teams/update_role", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        const messageSplit = data?.response?.data?.message.split("||");
        toast.error(messageSplit[0], {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (
        err?.response.data.status === "fail" &&
        err?.response.status !== 401
      ) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const inviteTeamMember = createAsyncThunk(
  "admin/invite-team-member",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/teams/invite_team_member", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        const messageSplit = data?.response?.data?.message.split("||");
        toast.error(messageSplit[0], {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const deletePendingTeamMember = createAsyncThunk(
  "admin/delete-invite-team-member",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/teams/delete_invitation_record", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        const messageSplit = data?.response?.data?.message.split("||");
        toast.error(messageSplit[0], {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "admin/update-user-profile",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/update_user", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        const messageSplit = data?.response?.data?.message.split("||");
        toast.error(messageSplit[0], {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const updateBusinessProfile = createAsyncThunk(
  "admin/update-business-profile",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("user/update_user_phone_number", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        const messageSplit = data?.response?.data?.message.split("||");
        toast.error(messageSplit[0], {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const deleteTeamMember = createAsyncThunk(
  "admin/delete-team-member",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/teams/delete_team_member", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const generateBankStatement = createAsyncThunk(
  "admin/generate-bank-statement",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/transactions/download_bank_statement?currency=NGN&date=${
          payload?.date
        }&file_type=${payload?.file_type}&send_to=${
          payload?.send_to
        }&account_id=${payload?.account_id || ""}`,
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.status === 200) {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // console.log(err);
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const generateBankStatementElectricity = createAsyncThunk(
  "admin/generate-bank-statement-electricity",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/transactions/fetch_electricity_bills_transactions`
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {
          theme: "colored",
        });
        return data;
      }
      if (data?.status === 200) {
        // console.log("register", data.data.data.electricity_bill_trasactions);
        await thunkAPI.dispatch(
          setElectricityStatements(data.data.data.electricity_bill_trasactions)
        );
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const generateBankStatementElectricityTemplate = createAsyncThunk(
  "admin/generate-bank-statement-electricity-template",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `/transactions/download_electricity_statement`,
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.status === 200) {
        // console.log("register", data.data.data.electricity_bill_trasactions);
        // await thunkAPI.dispatch(setElectricityStatements(data.data.data.electricity_bill_trasactions));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const searchBankStatementElectricity = createAsyncThunk(
  "search-generate-bank-statement-electricity",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `/transactions/search_electricity_bills_transactions`,
        payload
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.status === 200) {
        // console.log("register", data.data.data.electricity_bill_trasactions);
        await thunkAPI.dispatch(
          setElectricityStatements(data.data.data.electricity_bill_trasactions)
        );
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const addDirectorBvn = createAsyncThunk(
  "admin/add-director-bvn",
  async (payload, thunkAPI) => {
    try {
      const obj = {
        payload,
        type: "formData",
      };
      const data = await axios.post(
        "/director/director_details_verification",
        obj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      // console.log("register", data);

      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const addDShareholderBvn = createAsyncThunk(
  "admin/add-director-bvn",
  async (payload, thunkAPI) => {
    try {
      const obj = {
        payload,
        type: "formData",
      };
      const data = await axios.post(
        "/director/director_details_verification",
        obj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      // console.log("register", data);

      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const addDirectorNin = createAsyncThunk(
  "admin/add-director-nin",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/director/nin/verification", payload);
      // console.log("register", data);

      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const removeDevice = createAsyncThunk(
  "admin/remove-device",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/devices/delete_devices", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const removePendinInvite = createAsyncThunk(
  "admin/remove-pending-invite",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `/verifications/cancel_pending_invite?invite_id=${
          payload?.ref_id || ""
        }`
      );
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const deletBeneficiary = createAsyncThunk(
  "admin/remove-bene",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/remove_beneficiary", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const deletBeneficiaryGlobal = createAsyncThunk(
  "admin/remove-bene",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/delete_beneficiary", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const validateBvn = createAsyncThunk(
  "/cards/verify_bvn_details-two",
  async (payload, thunkAPI) => {
    try {
      // console.log(payload);
      // const data = await axios.post("cards/verify_bvn_details", payload);
      // // console.log("register", data);
      // if (data?.response?.data?.status === "fail") {
      //   toast.error(data?.response?.data?.message, {});
      // }
      // if (data?.data?.status === "fail") {
      //   toast.error(data?.data?.message, {});
      //   // return thunkAPI.rejectWithValue(data?.data?.data);
      // }
      // if (data?.data?.status === "success") {
      //   //   toast.success(data.data.message, {
      //   //     theme: "colored",
      //   //   });
      //   // await thunkAPI.dispatch(setBVN(data?.data?.data));
      //   return data;
      // }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

// const formatTeamRoles = (param) => {
//   const list = param?.map((chi) => {
//     return { label: chi, value: chi };
//   });
//   return list;
// };

const formatToSelect = (list) => {
  const val = list.map((chi) => {
    return { label: formatTypeFunction(chi), value: chi };
  });
  // console.log(val);
  return val;
};

export const settings = createSlice({
  name: "payment-link",
  initialState: {
    isAuth: false,
    loading: false,
    beneficiaries: [],
    teamMembers: [],
    teamRoles: [],
    directors: [],
    share_holders: [],
    devices: [],
    electricityStatements: [],
    loadingSearch: false,
    loadingTeamUpdate: false,
    loadingStatement: false,
    loadingAppearance: false,
    loadingEnable: false,
    loadingVerify2fa: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setBeneficiaries: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.beneficiaries = action.payload;
    },
    setTeamMembers: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.teamMembers = action.payload;
    },
    setTeamRoles: (state, action) => {
      state.isAuth = true;
      state.teamRoles = formatToSelect(action.payload);
    },
    setDirectors: (state, action) => {
      state.isAuth = true;
      state.directors = action.payload;
    },
    setShareHolders: (state, action) => {
      state.isAuth = true;
      state.share_holders = action.payload;
    },
    setDevices: (state, action) => {
      state.isAuth = true;
      state.devices = formatMetadata(action.payload);
    },
    setElectricityStatements: (state, action) => {
      state.isAuth = true;
      state.electricityStatements = action.payload;
    },
  },
  extraReducers: {
    [updateAppearance.pending]: (state) => {
      state.loadingAppearance = true;
    },
    [updateAppearance.fulfilled]: (state, action) => {
      state.loadingAppearance = false;
    },
    [updateAppearance.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingAppearance = false;
      state.isAuth = false;
      state = null;
    },
    [verifyTwoFaFactorSettings.pending]: (state) => {
      state.loadingVerify2fa = true;
    },
    [verifyTwoFaFactorSettings.fulfilled]: (state, action) => {
      state.loadingVerify2fa = false;
    },
    [verifyTwoFaFactorSettings.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingVerify2fa = false;
      state.isAuth = false;
      state = null;
    },
    [disableTwoFaFactorSettings.pending]: (state) => {
      state.loadingVerify2fa = true;
    },
    [disableTwoFaFactorSettings.fulfilled]: (state, action) => {
      state.loadingVerify2fa = false;
    },
    [disableTwoFaFactorSettings.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingVerify2fa = false;
      state.isAuth = false;
      state = null;
    },
    [enableTwoFaFactorSettings.pending]: (state) => {
      state.loadingEnable = true;
    },
    [enableTwoFaFactorSettings.fulfilled]: (state, action) => {
      state.loadingEnable = false;
    },
    [enableTwoFaFactorSettings.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingEnable = false;
      state.isAuth = false;
      state = null;
    },
    [verifyResetTokenTransationPin.pending]: (state) => {
      state.loadingEnable = true;
    },
    [verifyResetTokenTransationPin.fulfilled]: (state, action) => {
      state.loadingEnable = false;
    },
    [verifyResetTokenTransationPin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingEnable = false;
      state.isAuth = false;
      state = null;
    },
    [resetTransactionPin.pending]: (state) => {
      state.loadingEnable = true;
    },
    [resetTransactionPin.fulfilled]: (state, action) => {
      state.loadingEnable = false;
    },
    [resetTransactionPin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingEnable = false;
      state.isAuth = false;
      state = null;
    },
    [notificationSettings.pending]: (state) => {
      state.loading = true;
    },
    [notificationSettings.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [notificationSettings.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [removePendinInvite.pending]: (state) => {
      state.loadingStatement = true;
    },
    [removePendinInvite.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [removePendinInvite.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [inviteTeamMember.pending]: (state) => {
      state.loadingStatement = true;
    },
    [inviteTeamMember.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [inviteTeamMember.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [addBeneficiaries.pending]: (state) => {
      state.loading = true;
    },
    [addBeneficiaries.fulfilled]: (state) => {
      state.loading = false;
    },
    [addBeneficiaries.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deletBeneficiary.pending]: (state) => {
      state.loading = true;
    },
    [deletBeneficiary.fulfilled]: (state) => {
      state.loading = false;
    },
    [deletBeneficiary.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deletBeneficiaryGlobal.pending]: (state) => {
      state.loading = true;
    },
    [deletBeneficiaryGlobal.fulfilled]: (state) => {
      state.loading = false;
    },
    [deletBeneficiaryGlobal.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [searchBankStatementElectricity.pending]: (state) => {
      state.loadingSearch = true;
    },
    [searchBankStatementElectricity.fulfilled]: (state) => {
      state.loadingSearch = false;
    },
    [searchBankStatementElectricity.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSearch = false;
      state.isAuth = false;
      state = null;
    },
    [getBeneficiaries.pending]: (state) => {
      state.loading = true;
    },
    [getBeneficiaries.fulfilled]: (state) => {
      state.loading = false;
    },
    [getBeneficiaries.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [generateBankStatementElectricity.pending]: (state) => {
      state.loading = true;
    },
    [generateBankStatementElectricity.fulfilled]: (state) => {
      state.loading = false;
    },
    [generateBankStatementElectricity.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [searchBeneficiaries.pending]: (state) => {
      state.loading = true;
    },
    [searchBeneficiaries.fulfilled]: (state) => {
      state.loading = false;
    },
    [searchBeneficiaries.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [removeBeneficiaries.pending]: (state) => {
      state.loading = true;
    },
    [removeBeneficiaries.fulfilled]: (state) => {
      state.loading = false;
    },
    [removeBeneficiaries.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getTeamMembers.pending]: (state) => {
      state.loading = true;
    },
    [getTeamMembers.fulfilled]: (state, action) => {
      // console.log(action.payload.data)
      state.loading = false;
      state.teamMembers = action.payload.data;
    },
    [getTeamMembers.rejected]: (state, action) => {
      // localStorage.removeItem("token");
      // console.log(action.payload)
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getAllDevices.pending]: (state) => {
      state.loading = true;
    },
    [getAllDevices.fulfilled]: (state) => {
      state.loading = false;
    },
    [getAllDevices.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteTeamMember.pending]: (state) => {
      state.loading = true;
    },
    [deleteTeamMember.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteTeamMember.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateAppearanceTwo.pending]: (state) => {
      state.loading = true;
    },
    [updateAppearanceTwo.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateAppearanceTwo.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [generateBankStatement.pending]: (state) => {
      state.loadingStatement = true;
    },
    [generateBankStatement.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [generateBankStatement.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [getDirectorShareholders.pending]: (state) => {
      state.loading = true;
    },
    [getDirectorShareholders.fulfilled]: (state) => {
      state.loading = false;
    },
    [getDirectorShareholders.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [addDShareholderBvn.pending]: (state) => {
      state.loadingStatement = true;
    },
    [addDShareholderBvn.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [addDShareholderBvn.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [addDirectorBvn.pending]: (state) => {
      state.loadingStatement = true;
    },
    [addDirectorBvn.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [addDirectorBvn.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [getTokenForTransactionResetPin.pending]: (state) => {
      state.loadingStatement = true;
    },
    [getTokenForTransactionResetPin.fulfilled]: (state) => {
      state.loadingStatement = false;
    },
    [getTokenForTransactionResetPin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingStatement = false;
      state.isAuth = false;
      state = null;
    },
    [addDirectorNin.pending]: (state) => {
      state.loading = true;
    },
    [addDirectorNin.fulfilled]: (state) => {
      state.loading = false;
    },
    [addDirectorNin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [removeDevice.pending]: (state) => {
      state.loadingSearch = true;
    },
    [removeDevice.fulfilled]: (state) => {
      state.loadingSearch = false;
    },
    [removeDevice.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSearch = false;
      state.isAuth = false;
      state = null;
    },
    [generateBankStatementElectricityTemplate.pending]: (state) => {
      state.loading = true;
    },
    [generateBankStatementElectricityTemplate.fulfilled]: (state) => {
      state.loading = false;
    },
    [generateBankStatementElectricityTemplate.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateUserProfile.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateUserProfile.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [editBeneficiaries.pending]: (state) => {
      state.loading = true;
    },
    [editBeneficiaries.fulfilled]: (state) => {
      state.loading = false;
    },
    [editBeneficiaries.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateBusinessProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateBusinessProfile.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateBusinessProfile.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [updateTeamRole.pending]: (state) => {
      state.loadingTeamUpdate = true;
    },
    [updateTeamRole.fulfilled]: (state) => {
      state.loadingTeamUpdate = false;
    },
    [updateTeamRole.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingTeamUpdate = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setPaymentLinks,
  setBeneficiaries,
  setTeamMembers,
  setTeamRoles,
  setDirectors,
  setShareHolders,
  setDevices,
  setElectricityStatements,
} = settings.actions;

export default settings.reducer;
