import React, { useEffect, useState } from "react";
import PayementLinkInvoiceLayout from "../../../layout/dashboard/PayementLinkInvoiceLayout";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
// import { UseSocketFunction } from "../../../utils/helper/UseSocket";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import { useDispatch } from "react-redux";
// import { useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
import newImgLoader from "../../../assets/raven-loader.gif";
import testLogo from "../../../assets/expired-payment-web-img.png";
import {
  downloadInvoice,
  getInvoicePublicPreview,
} from "../../../redux/invoice";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import {
  formatDate,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  lowerCaseWrap,
  trimLongString,
} from "../../../utils/helper/Helper";
import SelectCurrencyFragment from "../../../components/fragments/SelectCurrencyFragment";
import { useSelector } from "react-redux";

const AtlasPay = window.AtlasPaySdk;

const PublicPreviewInvoiceVersionTwo = () => {
  const { loadingDelete } = useSelector((state) => state.invoice);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  // const location = useLocation();
  // const { business } = useSelector((state) => state.user);
  //   const { loadingDelete } = useSelector((state) => state.invoice);
//   const [perPage] = useState(1);
  const calculatePercent = (amount, percent) => {
    const val = Math.round((Number(percent) * Number(amount)) / 100);
    return val;
  };

  useEffect(() => {
    getSingleDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleDetail = async () => {
    const id = window?.location.href?.split("?ref=")[1];
    // if (!id) {
    //   navigate("/dashboard-invoice");
    //   return;
    // }
    const obj = {
      id: id,
    };
    // console.log(obj);
    // return
    if (id) {
      const data = await dispatch(getInvoicePublicPreview(obj));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        setLoading(false);
        const obj = data?.payload?.data?.data;
        setFormData(obj);
        paginateFunc(obj?.items, obj?.items?.length, 1);
      } else {
        // setLoading(false)
      }
    }
  };

  const handleDownload = async () => {
    const obj = {
      id: String(formData?.id),
    };
    // console.log(obj);
    const data = await dispatch(downloadInvoice(obj));
    if (data?.payload?.data?.status === "success") {
      downloadCsv(data?.payload?.data?.data?.url);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  AtlasPay.onSuccess = function (data) {
    // console.log("Payment successful:", data);

    getSingleDetail();
  };

  AtlasPay.onClose = function (data) {
    if (data?.message === "transaction_success") {
      //   setStep(2);
      getSingleDetail();
      // const obj = {...formData, status: 2};
      // setFormData(obj)
      AtlasPay.shutdown();
      return;
    }

    AtlasPay.shutdown();
    // console.log(data);
    // setStep(3);
  };

  const [loadingSdk, setLoadingSdk] = useState(false);
  AtlasPay.onLoad = function () {
    setLoadingSdk(false);
  };

  const [paginatedList, setPaginatedList] = useState({
    current_page: "",
    per_page: "",
    total: "",
    total_pages: "",
    dataList: [],
  });

  function paginateFunc(array, page_size, current_page) {
    const start_index = (current_page - 1) * page_size;
    const end_index = start_index + page_size;
    const paginatedData = array.slice(start_index, end_index);
    const info = {
      current_page: current_page,
      per_page: page_size,
      total: array.length,
      total_pages: Math.ceil(array.length / page_size),
      dataList: paginatedData,
    };
    // console.log(info);
    setPaginatedList((prev) => {
      return {
        ...prev,
        current_page: info?.current_page,
        per_page: info?.per_page,
        total: info?.total,
        total_pages: info?.total_pages,
        dataList: info?.dataList,
      };
    });
  }

  const size = useWindowSize();

  //initialize socket.io-client
  // const SERVER = process.env.REACT_APP_SOCKET_BASE_URL;
  // const authenticated = localStorage.getItem("token");

  // const decryptTokenFunc = (tok) => {
  //   const encryptedToken = tok;
  //   const secretKey =
  //     "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

  //   // Encrypt the token
  //   const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  //   const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
  //   // console.log(tok);
  //   // console.log(decryptedToken);
  //   return decryptedToken;
  // };

  // useEffect(() => {
  //   if (location.pathname.includes("dashboard-invoice-preview")) {
  //     const socket = UseSocketFunction();
  //     socket.on("invoice_paid", () => {
  //       getSingleDetail();
  //       //  console.log("yes-----------------------");
  //     });
  //     return () => {
  //       socket.off("invoice_paid");
  //     };
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (loading) {
    return (
      <>
        <PayementLinkInvoiceLayout invoice_type={true}>
          <div
            style={{
              width: size.width < 900 ? "90%" : "45rem",
              height:  "45rem",
              marginTop: "auto",
              marginBottom: "auto",
            }}
            className="version-two-public-invoice-wrap"
          >
            <div className="invoice-public-content-main-wrap">
              <div
                style={{
                  width: `100%`,
                  height: `100%`,
                  display: "grid",
                  placeItems: "center",
                }}
                className=""
              >
                <figure
                  style={{
                    width: size.width < 900 ? "15rem" : "20rem",
                    height: size.width < 900 ? "15rem" : "20rem",
                  }}
                  className="img-box"
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={newImgLoader}
                    alt=""
                    className="img"
                  />
                </figure>
              </div>
            </div>
          </div>
        </PayementLinkInvoiceLayout>
      </>
    );
  }

  return (
    <>
      <PayementLinkInvoiceLayout
        onClickDownload={handleDownload}
        downLoadLabel={true}
        loadingDownload={loadingDelete}
        onClick={() => {
          setLoadingSdk(true);
          AtlasPay.init(formData?.trx_ref);
          //   console.log(formData);
        }}
        loading={loadingSdk}
        btnLabel={
          Number(formData?.status) === 1 || Number(formData?.status) === 3
            ? `Proceed to payment`
            : ""
        }
      >
        <div className="version-two-public-invoice-wrap">
          {/* currency select wrap start */}
          <div className="currency-select-wrap">
            <p className="text">Payment Currency</p>
            {/* <RavenInputField
              placeholder="Select"
              type="select"
              color="deep-green-dark"
              className="cur-select"
            /> */}
            <SelectCurrencyFragment disableCur={true} />
          </div>
          {/* currency select wrap end */}
          {/* title detail box start */}
          <div className="amount-detail-box">
            <p className="text">
              {`${
                trimLongString(lowerCaseWrap(formData?.business_name), 22) ||
                "---"
              } is requesting for payment of`}{" "}
              <span>
                {" "}
                {formatNumWithCommaNairaSymbol(formData?.net || "0")}
              </span>
            </p>
          </div>
          {/* title detail box end */}
          {/* invoice content wrap start */}
          <div className="invoice-public-content-main-wrap">
            {/* preview box start */}
            <div
              className={`preview-box ${
                Number(formData?.status) !== 1 && "preview-box-cancel-expire"
              } ${
                Number(formData?.status) !== 3 && "preview-box-cancel-expire"
              }`}
            >
              {Number(formData?.status) === 1 ||
              Number(formData?.status) === 3 ? (
                <div className="invoice-preview-detail-wrap remove-dark-border">
                  {/* scroll- wrap box start ----------- */}
                  <div className="scroll-wrap-box">
                    {/* box start */}
                    <div className="box firt-box-date grey-bg top-info">
                      <div className="left-box">
                        <p className="label">Invoice Number:</p>
                        {loading ? (
                          <Skeleton width={`50%`} height={15} />
                        ) : (
                          <p
                            style={{ color: "#676767" }}
                            className="value grey-white-color"
                          >
                            {formData?.displayId || "---"}
                          </p>
                        )}
                      </div>
                      <div className="right-box">
                        <div className="date-img-box">
                          <div>
                            {" "}
                            <p className="label">Date:</p>
                            {loading ? (
                              <Skeleton width={`50%`} height={15} />
                            ) : (
                              <p
                                style={{ color: "#676767" }}
                                className="value grey-white-color"
                              >
                                {formData?.invoice_date && formData?.due_date
                                  ? `${
                                      formatDate(formData?.invoice_date)?.split(
                                        "—"
                                      )[0]
                                    } — ${
                                      formatDate(formData?.due_date)?.split(
                                        "—"
                                      )[0]
                                    }`
                                  : "---"}
                              </p>
                            )}
                          </div>
                          {formData?.business_logo ? (
                            <figure className="img-box">
                              <img
                                src={formData?.business_logo}
                                alt="business logo"
                              />
                            </figure>
                          ) : (
                            <div className="avatar-box">
                              <span>
                                {formData?.business_name?.split("")[0] ||
                                  formData?.business_email?.split("")[0]}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box middle-info">
                      <div className="left-box">
                        <p className="label">Bill From:</p>
                        {loading ? (
                          <Skeleton
                            width={`50%`}
                            height={15}
                            style={{ marginBottom: ".7rem" }}
                          />
                        ) : (
                          <p className="value grey-white-color">
                            {trimLongString(formData?.business_name, 22) ||
                              "---"}
                          </p>
                        )}
                        {loading ? (
                          <Skeleton
                            width={`50%`}
                            height={15}
                            style={{ marginBottom: ".7rem" }}
                          />
                        ) : (
                          <p className="value grey-white-color">
                            {trimLongString(formData?.business_address, 50) ||
                              "---"}
                          </p>
                        )}
                        {loading ? (
                          <Skeleton width={`50%`} height={15} />
                        ) : (
                          <p className="value grey-white-color">
                            {trimLongString(formData?.business_email, 25) ||
                              "---"}
                          </p>
                        )}
                      </div>
                      <div className="right-box">
                        <p className="label">Bill To:</p>
                        {loading ? (
                          <Skeleton
                            width={`50%`}
                            height={15}
                            style={{ marginBottom: ".7rem" }}
                          />
                        ) : (
                          <p
                            style={{ color: "#676767" }}
                            className="value grey-white-color"
                          >
                            {trimLongString(formData?.customer_name, 20) ||
                              "---"}
                          </p>
                        )}
                        {loading ? (
                          <Skeleton
                            width={`50%`}
                            height={15}
                            style={{ marginBottom: ".7rem" }}
                          />
                        ) : (
                          <p
                            style={{ color: "#676767" }}
                            className="value grey-white-color"
                          >
                            {trimLongString(formData?.customer_email, 25) ||
                              "---"}
                          </p>
                        )}
                        {loading ? (
                          <Skeleton width={`50%`} height={15} />
                        ) : (
                          <p
                            style={{ color: "#676767" }}
                            className="value grey-white-color"
                          >
                            {formData?.customer_phone || "---"}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box grey-bg top-info detail-info">
                      <div className="left-box">
                        <p className="label">Items</p>
                      </div>
                      <div className="right-box">
                        <p className="label">Cost</p>
                        <p className="label">Qty</p>
                        <p className="label">Price</p>
                      </div>
                    </div>
                    {/* box end */}

                    <div className="detail-sum-wrap-box">
                      {/* box start */}
                      {paginatedList?.dataList?.length > 0 &&
                        paginatedList?.dataList?.map((chi, idx) => {
                          const { product, quantity, unit_price, id, amount } =
                            chi;
                          return (
                            <div key={id || idx} className="box sum-detail-box">
                              <div className="left-box">
                                {loading ? (
                                  <Skeleton
                                    width={`50%`}
                                    height={15}
                                    style={{ marginBottom: ".7rem" }}
                                  />
                                ) : (
                                  <p className="name">{product || "---"}</p>
                                )}
                              </div>
                              <div className="right-box">
                                {loading ? (
                                  <Skeleton
                                    width={`50%`}
                                    height={15}
                                    style={{ marginBottom: ".7rem" }}
                                  />
                                ) : (
                                  <p className="cost">
                                    {formatNumWithCommaNairaSymbol(
                                      Number(
                                        formatNumWithoutCommaNaira(unit_price)
                                      ) || "0"
                                    )}
                                  </p>
                                )}
                                {loading ? (
                                  <Skeleton
                                    width={`20%`}
                                    height={15}
                                    style={{ marginBottom: ".7rem" }}
                                  />
                                ) : (
                                  <p className="quantity">
                                    {quantity || "---"}
                                  </p>
                                )}
                                {loading ? (
                                  <Skeleton
                                    width={`50%`}
                                    height={15}
                                    style={{ marginBottom: ".7rem" }}
                                  />
                                ) : (
                                  <p className="total-price">
                                    {formatNumWithCommaNairaSymbol(
                                      amount || "0"
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      {/* box end */}
                    </div>

                    {/* sum wrap start ------------- */}
                    <>
                        {/* box start */}
                        <div className="box sum-detail-box sum-detail-box-underline">
                          <div className="left-box"></div>
                          <div className="right-box">
                            <div className="underline border-theme"></div>
                          </div>
                        </div>
                        {/* box end */}
                        {/* box start summary start */}
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">Subtotal:</p>
                            <p className="quantity"></p>
                            {loading ? (
                              <Skeleton
                                width={`50%`}
                                height={15}
                                style={{ marginBottom: ".7rem" }}
                              />
                            ) : (
                              <p className="total-price">
                                {formatNumWithCommaNairaSymbol(
                                  formData?.gross || "0"
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">
                              Discount ({formData?.discount}%)
                            </p>
                            <p className="quantity"></p>
                            {loading ? (
                              <Skeleton
                                width={`50%`}
                                height={15}
                                style={{ marginBottom: ".7rem" }}
                              />
                            ) : (
                              <p className="total-price">
                                -{" "}
                                {formatNumWithCommaNairaSymbol(
                                  calculatePercent(
                                    formData?.discount,
                                    formData?.gross
                                  ) || "0"
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">
                              Tax ({formData?.tax}%)
                            </p>
                            <p className="quantity"></p>
                            {loading ? (
                              <Skeleton
                                width={`50%`}
                                height={15}
                                style={{ marginBottom: ".7rem" }}
                              />
                            ) : (
                              <p className="total-price">
                                {" "}
                                {formatNumWithCommaNairaSymbol(
                                  calculatePercent(
                                    formData?.tax,
                                    formData?.gross
                                  ) || "0"
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                        {formData?.shipping_fee ? (
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">
                                Shipping Fee
                              </p>
                              <p className="quantity"></p>
                              {loading ? (
                                <Skeleton
                                  width={`50%`}
                                  height={15}
                                  style={{ marginBottom: ".7rem" }}
                                />
                              ) : (
                                <p className="total-price">
                                  {" "}
                                  {formatNumWithCommaNairaSymbol(
                                    formData?.shipping_fee
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* box start */}
                        <div className="box sum-detail-box sum-detail-box-underline">
                          <div className="left-box"></div>
                          <div className="right-box">
                            <div className="underline border-theme"></div>
                          </div>
                        </div>
                        {/* box end */}
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">
                              Invoice Total
                            </p>
                            <p className="quantity"></p>
                            {loading ? (
                              <Skeleton
                                width={`50%`}
                                height={15}
                                style={{ marginBottom: ".7rem" }}
                              />
                            ) : (
                              <p
                                className="total-price"
                                style={{ fontWeight: "bold" }}
                              >
                                {formatNumWithCommaNairaSymbol(
                                  formData?.net || "0"
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* box end summary end */}
                      </>
                    {/* sum wrap end -------------- */}

                    <div className="underline-box">
                      <div className="underline border-theme"></div>
                    </div>
                    {/* note wrap start */}
                    <div className="note-wrap-box">
                      <p className="note">Note:</p>
                      <p className="text grey-white-color">
                        {`Hi ${formData?.customer_name || "--"} ${
                          formData?.note || "--"
                        }`}
                      </p>
                    </div>
                    {/* note wrap end */}
                    {/* pagination start */}
                    <RavenInputField
                      type="submit"
                      value={`Proceed to payment.`}
                      //   style={{ width: "100%", marginTop: "3rem" }}
                      color="deep-green-dark"
                      className="submit-btn"
                      onClick={() => {
                        setLoadingSdk(true);
                        AtlasPay.init(formData?.trx_ref);
                        //   console.log(formData);
                      }}
                      loading={loadingSdk}
                    />
                    {/* pagination end */}
                  </div>
                  {/* scroll wrap box end ----------- */}
                </div>
              ) : (
                <>
                  {Number(formData?.status) !== 1 &&
                    Number(formData?.status) !== 2 && (
                      <div className="content content-cancel-wrap">
                        <div className="wrap">
                          <div className="logo-name-box">
                            <figure className="img-box">
                              <img src={testLogo} alt="" className="img" />
                            </figure>
                          </div>
                          <p className="title"> Invoice Link Expired</p>
                          <p className="text">
                            You cannot make payment to this link anymore,
                            request a different link from the recipient
                          </p>
                        </div>
                      </div>
                    )}
                  {Number(formData?.status) === 2 && (
                    <div className="content content-cancel-wrap">
                      <div className="wrap">
                        <div className="logo-name-box">
                          <figure className="img-box">
                            <svg
                              width="45"
                              height="45"
                              viewBox="0 0 45 45"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="img"
                            >
                              <path
                                d="M34.5479 8.20545C34.9683 9.22225 35.7753 10.0305 36.7915 10.4524L40.3545 11.9284C41.3714 12.3496 42.1793 13.1575 42.6005 14.1744C43.0216 15.1912 43.0216 16.3338 42.6005 17.3507L41.1256 20.9113C40.7043 21.9287 40.7037 23.0724 41.127 24.0892L42.5992 27.6488C42.808 28.1525 42.9155 28.6923 42.9156 29.2376C42.9157 29.7828 42.8084 30.3227 42.5997 30.8264C42.3911 31.3301 42.0853 31.7878 41.6997 32.1732C41.3141 32.5587 40.8563 32.8644 40.3526 33.0729L36.7921 34.5478C35.7753 34.9682 34.9671 35.7752 34.5452 36.7914L33.0693 40.3546C32.6481 41.3715 31.8402 42.1794 30.8234 42.6006C29.8065 43.0218 28.664 43.0218 27.6472 42.6006L24.0866 41.1257C23.0698 40.7055 21.9277 40.7064 20.9114 41.1281L17.3483 42.6019C16.3321 43.0221 15.1905 43.0218 14.1745 42.6009C13.1585 42.1801 12.3511 41.3731 11.9296 40.3573L10.4533 36.793C10.0329 35.7762 9.22587 34.968 8.20974 34.5461L4.64666 33.0701C3.63025 32.6491 2.82261 31.8417 2.40127 30.8254C1.97992 29.8091 1.97935 28.6671 2.39968 27.6504L3.8745 24.0897C4.29465 23.0728 4.29379 21.9306 3.87211 20.9144L2.39941 17.3486C2.19061 16.8449 2.0831 16.3051 2.08301 15.7599C2.08292 15.2147 2.19026 14.6748 2.3989 14.171C2.60754 13.6673 2.91338 13.2097 3.29896 12.8242C3.68453 12.4387 4.14228 12.133 4.64605 11.9245L8.20657 10.4497C9.22245 10.0296 10.0302 9.22361 10.4524 8.20861L11.9283 4.6454C12.3495 3.62852 13.1574 2.82061 14.1742 2.3994C15.1911 1.97819 16.3336 1.97819 17.3504 2.3994L20.9109 3.87427C21.9278 4.29444 23.0699 4.29358 24.0862 3.87189L27.6507 2.40169C28.6674 1.98072 29.8097 1.9808 30.8264 2.40193C31.843 2.82305 32.6508 3.63073 33.072 4.64735L34.5484 8.21162L34.5479 8.20545Z"
                                fill="#1ACE37"
                              />
                              <path
                                d="M16.3747 22.5002L20.458 26.5835L29.6455 17.396M34.5479 8.20545C34.9683 9.22225 35.7753 10.0305 36.7915 10.4524L40.3545 11.9284C41.3714 12.3496 42.1793 13.1575 42.6005 14.1744C43.0216 15.1912 43.0216 16.3338 42.6005 17.3507L41.1256 20.9113C40.7043 21.9287 40.7037 23.0724 41.127 24.0892L42.5992 27.6488C42.808 28.1525 42.9155 28.6923 42.9156 29.2376C42.9157 29.7828 42.8084 30.3227 42.5997 30.8264C42.3911 31.3301 42.0853 31.7878 41.6997 32.1732C41.3141 32.5587 40.8563 32.8644 40.3526 33.0729L36.7921 34.5478C35.7753 34.9682 34.9671 35.7752 34.5452 36.7914L33.0693 40.3546C32.6481 41.3715 31.8402 42.1794 30.8234 42.6006C29.8065 43.0218 28.664 43.0218 27.6472 42.6006L24.0866 41.1257C23.0698 40.7055 21.9277 40.7064 20.9114 41.1281L17.3483 42.6019C16.3321 43.0221 15.1905 43.0218 14.1745 42.6009C13.1585 42.1801 12.3511 41.3731 11.9296 40.3573L10.4533 36.793C10.0329 35.7762 9.22587 34.968 8.20974 34.5461L4.64666 33.0701C3.63025 32.6491 2.82261 31.8417 2.40127 30.8254C1.97992 29.8091 1.97935 28.6671 2.39968 27.6504L3.8745 24.0897C4.29465 23.0728 4.29379 21.9306 3.87211 20.9144L2.39941 17.3486C2.19061 16.8449 2.0831 16.3051 2.08301 15.7599C2.08292 15.2147 2.19026 14.6748 2.3989 14.171C2.60754 13.6673 2.91338 13.2097 3.29896 12.8242C3.68453 12.4387 4.14228 12.133 4.64605 11.9245L8.20657 10.4497C9.22245 10.0296 10.0302 9.22361 10.4524 8.20861L11.9283 4.6454C12.3495 3.62852 13.1574 2.82061 14.1742 2.3994C15.1911 1.97819 16.3336 1.97819 17.3504 2.3994L20.9109 3.87427C21.9278 4.29444 23.0699 4.29358 24.0862 3.87189L27.6507 2.40169C28.6674 1.98072 29.8097 1.9808 30.8264 2.40193C31.843 2.82305 32.6508 3.63073 33.072 4.64735L34.5484 8.21162L34.5479 8.20545Z"
                                stroke="white"
                                strokeWidth="3.0625"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </figure>
                        </div>
                        <p className="title"> Invoice has been settled</p>
                        <p className="text">
                          You cannot make payment to this link anymore, request
                          a different link from the recipient
                        </p>
                      </div>
                    </div>
                  )}
                  {/* end of content */}
                  {/* btn box start */}
                  {/* <div className="btn-box btn-box-two">
        <RavenButton onClick={() => setStep(1)} color={`black-light`} label={`Contact Support`} />
      </div> */}
                  {/* btn box end */}
                </>
              )}
            </div>
            {/* preview box end */}
          </div>
          {/* invoice content wrap end */}
        </div>
      </PayementLinkInvoiceLayout>
    </>
  );
};

export default PublicPreviewInvoiceVersionTwo;
