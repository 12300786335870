import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext } from "react";
// import { formatNumWithCommaNairaSymbol } from "../../utils/helper/Helper";
import ActionContext from "../../context/ActionContext";
import "../../styles/fragments/GlobalAmountInput.css";
import GlobalSelectDropBox from "./GlobalSelectDropBox";

const GlobalAmountInput = ({
  amountValue,
  onChange,
  handleChange,
  placeholder,
  label,
  wrapperClass,
  dropClass,
  currencyList,
  setCurrencyList,
  selectedCurrency,
  labelSpanText,
  numberPrefix,
  disabled,
  dropStyles,
  hideDrop,
  hideCur,
  selectedAutoCur,
  hideDropIcon,
}) => {
  const actionCtx = useContext(ActionContext);
  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     const obj = { ...details, [name]: value };
  //     setDetails(obj);
  //   };

  return (
    <>
      <div className={`global-amount-input-box border-theme  ${wrapperClass}`}>
        {/* select drop box stat */}
        <div className="select-drop-box-wrapper border-theme-right">
          {/* selected box start */}
          <GlobalSelectDropBox
            dontautoUpdate={true}
            currencyList={currencyList}
            setCurrency={(param) => {
              setCurrencyList && setCurrencyList(param);
            }}
            selectedCurrency={selectedCurrency}
            dropStyles={dropStyles}
            hideDrop={hideDrop}
            hideCur={hideCur}
            selectedAutoCur={selectedAutoCur}
            hideDropIcon={hideDropIcon}
          />
          {/* selected box end */}
        </div>
        {/* select drop box end */}
        {/* form group start */}
        <form action="" autoComplete="off" style={{ width: "100%" }}>
          {" "}
          <RavenInputField
            type={`number`}
            color={`black-light`}
            label={label || `Enter Amount *`}
            name={`amount`}
            value={amountValue}
            onChange={handleChange}
            placeholder={placeholder || `How much are you receiving`}
            labelColor={`black-light`}
            labelSpanText={
              !labelSpanText
                ? ""
                : actionCtx?.showAmount
                ? `Bal: ${labelSpanText || ""}`
                : "Bal: ••••••"
            }
            labelClassName={`label-span-amount label-span-theme`}
            thousandFormat
            numberPrefix={numberPrefix || ""}
            disabled={disabled}
            onActionClick={() => {
              actionCtx?.setShowAmount(!actionCtx?.showAmount);
            }}
          />
        </form>

        {/* form group end */}
      </div>
    </>
  );
};

export default GlobalAmountInput;
