import {
  RavenInputField,
  RavenModal,
  RavenTabLink,
  RavenToolTip,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { sharePaymentLink } from "../../../redux/requestMoney";
import {
  formatNumWithoutCommaNaira,
  trimLongString,
} from "../../../utils/helper/Helper";
import { validateEmail } from "../../../utils/helper/ValidateInput";
import ".././../../styles/dashboard/payment-links/PaymentLink.css";

const SharePaymentLinkModal = ({ visible, onClose, onFinish, detail }) => {
  const { loadingSingle } = useSelector((state) => state.paymentLinks);
  const [generateType, setGenerateType] = useState(0);
  const selectList = ["Share via email", "Share via whatsapp"];
  const dispatch = useDispatch();
  //   const navigate = useNavigate();
  const [details, setDetails] = useState({
    email: "",
    whatsapp_number: "",
  });
  const [error, setError] = useState({
    email: false,
  });

  const handleChange = (e) => {
    details?.email &&
      setError((prev) => {
        return { ...prev, email: false };
      });
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async () => {
    if (!validateEmail(details.email) && generateType !== 1) {
      toast.error("Invalid email address!", { position: "top-right" });
      setError((prev) => {
        return { ...prev, email: true };
      });
      return;
    }

    let val;
    if (generateType === 1) {
      const obj = {
        channel: "whatsapp",
        payment_link_id: String(detail?.id),
        // email: details?.email,
        whatsapp_number: `0${formatNumWithoutCommaNaira(
          details?.whatsapp_number
        )}`,
      };
      val = obj;
    } else {
      const obj = {
        channel: "email",
        payment_link_id: String(detail?.id),
        email: details?.email,
        // whatsapp_number: `0${formatNumWithoutCommaNaira(
        //   details?.whatsapp_number
        // )}`,
      };
      val = obj;
    }
    // console.log(val);
    // return
    const data = await dispatch(sharePaymentLink(val));
    if (data?.payload?.data?.status === "success") {
      setDetails((prev) => {
        return { ...prev, email: "", whatsapp_number: "" };
      });
      onFinish();
    }
  };

  return (
    <RavenModal
      visble={visible}
      onClose={() => {
        setDetails((prev) => {
          return { ...prev, email: "" };
        });
        onClose();
      }}
      btnColor={`black-light`}
      btnLabel={`Share Link`}
      disabled={generateType === 1 ? !details?.whatsapp_number : String(generateType) === "0" ? !details?.email : false}
      onBtnClick={handleSubmit}
      loading={loadingSingle}
    >
      <div className="share-payment-link-wrap">
        <div className="title-text-box">
          <p className="title">{`Share Payment Link  ( ${trimLongString(
            detail?.title,
            17
          )} )`}</p>
          {/* <p className="text grey-white-color">
            Send link to your customer, to get your payment faster and easier.
          </p> */} 
        </div>

        {/* link to share start */}
        <div className="share-box-wrap">
          {/* <p className="text">Share a clickable link</p> */}
          <div className="box grey-bg">
            <figure className="img-box">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="img"
              >
                <path
                  d="M10.3327 6.33333V4.33333C10.3327 2.49238 8.8403 1 6.99935 1C5.1584 1 3.66602 2.49238 3.66602 4.33333V6.33333M4.86602 13H9.13268C10.2528 13 10.8128 13 11.2407 12.782C11.617 12.5903 11.9229 12.2843 12.1147 11.908C12.3327 11.4802 12.3327 10.9201 12.3327 9.8V9.53333C12.3327 8.41323 12.3327 7.85318 12.1147 7.42535C11.9229 7.04903 11.617 6.74307 11.2407 6.55132C10.8128 6.33333 10.2528 6.33333 9.13268 6.33333H4.86602C3.74591 6.33333 3.18586 6.33333 2.75803 6.55132C2.38171 6.74307 2.07575 7.04903 1.884 7.42535C1.66602 7.85318 1.66602 8.41323 1.66602 9.53333V9.8C1.66602 10.9201 1.66602 11.4802 1.884 11.908C2.07575 12.2843 2.38171 12.5903 2.75803 12.782C3.18586 13 3.74591 13 4.86602 13Z"
                  stroke="#676767"
                  strokeWidth="1.36239"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
            <p className="text grey-white-color">
              {" "}
              {trimLongString(detail?.link, 30) || "----"}
            </p>
            <a
              href={`/payment-details?ref=${detail?.reference}`}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              {" "}
              <figure
                // onClick={() =>
                //   navigate(`/payment-details?ref=${detail?.reference}`)
                // }
                className="img-box tooltip-hover-wrap"
                style={{ cursor: "pointer" }}
              >
                <RavenToolTip
                  color="black-light"
                  text="Preview Payment Widget"
                  position={`top-left`}
                />
                <svg
                  className="img"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 6H17.8C16.1198 6 15.2798 6 14.638 6.32698C14.0735 6.6146 13.6146 7.07354 13.327 7.63803C13 8.27976 13 9.11984 13 10.8V12M21 6L18 3M21 6L18 9M10 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V14"
                    stroke="black"
                    strokeWidth="1.5"
                    stroke-inecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </figure>
            </a>
          </div>
          <p className="text grey-white-color">
            Send link to your customer, to get your payment faster and easier.
          </p>
        </div>
        {/* link to share end */}
        <p className="text grey-white-color">
          Select how to generate statement
        </p>
        {/* select wrap start */}
        <div className="select-wrap">
          <RavenTabLink
            color={`black-light`}
            key={`kaja`}
            list={selectList}
            onClick={(e) => {
              setGenerateType(e);
              if (e === 0) {
                setDetails((prev) => {
                  return { ...prev, whatsapp_number: "" };
                });
              } else {
                setDetails((prev) => {
                  return { ...prev, email: "" };
                });
              }
            }}
            fadedBlack
          />
        </div>
        {/* select wrap end */}
        <div
          className={`select-type-wrap ${
            generateType === 0 && "select-type-wrap-show"
          }`}
        >
          {" "}
          <form action="" autoComplete="off" className="form-inbox-wrap">
            <RavenInputField
              color="black-light"
              type="email"
              label={`Customer Email Address`}
              name={`email`}
              onChange={handleChange}
              value={details?.email}
              placeholder={`E.g ayenikehin@gmail.com`}
              showError={error?.email}
            />
          </form>
        </div>
        <div
          className={`select-type-wrap ${
            generateType === 1 && "select-type-wrap-show"
          }`}
        >
          {" "}
          <form action="" autoComplete="off" className="form-inbox-wrap">
            <RavenInputField
              color="black-light"
              type="phone"
              label={`Customer Whatsapp Number`}
              name={`whatsapp_number`}
              onChange={handleChange}
              value={details?.whatsapp_number}
              placeholder={`E.g 81626319..`}
              showError={error?.whatsapp_number}
            />
          </form>
        </div>
      </div>
    </RavenModal>
  );
};

export default SharePaymentLinkModal;
