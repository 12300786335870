import React from 'react';

// interface HighlightSearchProps {
//   text: string;
//   search: string;
// }

const HighlightSearch = ({ text, search, spanClassName }) => {
  if (!search.trim()) {
    return <span>{text}</span>;
  }

  const regex = new RegExp(`(${search})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <mark key={index}>{part}</mark>
        ) : (
          <span className={spanClassName || ""} key={index}>{part}</span>
        )
      )}
    </span>
  );
};

export default HighlightSearch;
