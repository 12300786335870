import React, { useEffect, useState } from "react";
import DashboardSettingsLayout from "./SettingsLayout";
// import { RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import settingIconsmall from "../../../../assets/setting-device-small.svg";
// import settingIconbig from "../../../../assets/settings-device-big.svg";
import NoContentBox from "../../../../components/reusables/NoContentBox";
import { getAllDevices } from "../../../../redux/settings";
import { formatDate } from "../../../../utils/helper/Helper";
import DeleteDeviceModal from "../modal/DeleteDeviceModal";

const DashboardSettingsDevice = () => {
  const dispatch = useDispatch();
  //   const [singleChild, setSingleChild] = useState({});
  //   const [refreshTable, setRefeshTable] = useState(false);
  const { devices, loading } = useSelector((state) => state.settings);
  const [singleChild, setSingleChild] = useState({});
  const [showModal, setShowModal] = useState({
    team_invite: false,
    team_delete: false,
    device_delete: false,
    statement: false,
    electricity: false,
    view_directors: false,
    delete_sub_account: false,
    change_primary: false,
    remove_role_account: false,
    add_role_account: false,
  });

  useEffect(() => {
    dispatch(getAllDevices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardSettingsLayout>
        <>
          {devices?.length > 0 ? (
            <div className="detail-wrap animate-move-up-class">
              <p className="title">Device Management</p>
              <p className="sub-title grey-white-color">
                See all devices who have access to your account and manage them.
              </p>
              <div className="box-wrap grey-bg">
                {/* list start */}
                {devices?.map((chi, idx) => {
                  const { id, fingerprint, updated_at } = chi;
                  return (
                    <div key={id || idx} className="noti-wrap">
                      <figure className="img-box">
                        <img src={settingIconsmall} alt="" className="img" />
                      </figure>
                      <div className="text-box">
                        <p className="value">
                          {" "}
                          {fingerprint?.components?.useragent?.os?.family}
                        </p>
                        <p className="label grey-white-color">
                          Joined: {formatDate(updated_at)}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, device_delete: true };
                          });
                        }}
                        className="add-error-box error-type"
                      >
                        <span>Delete device</span>
                      </div>
                      {/* <RavenToggleSlide id="jgjk" color="black-light" /> */}
                    </div>
                    //   <div key={id || idx} className={style.settingCard}>
                    //     <div className={style.cardLeft}>
                    //       {loading ? (
                    //         <Skeleton width={35} height={35} circle />
                    //       ) : (
                    //         <div>{phone}</div>
                    //       )}
                    //       <div className={style.clText}>
                    //         {loading ? (
                    //           <Skeleton width={120} height={25} />
                    //         ) : (
                    //           <h3>
                    //             {fingerprint?.components?.useragent?.os?.family}
                    //           </h3>
                    //         )}
                    //         {loading ? (
                    //           <Skeleton width={150} height={15} />
                    //         ) : (
                    //           <p>
                    //             {formatDate(
                    //               moment(updated_at).format(
                    //                 "MMMM, DD, YYYY  — HH:mm a"
                    //               )
                    //             )}
                    //           </p>
                    //         )}
                    //       </div>
                    //     </div>
                    //     {loading ? (
                    //       <Skeleton
                    //         width={90}
                    //         height={30}
                    //         style={{ borderRadius: "2rem" }}
                    //       />
                    //     ) : (
                    //       <>
                    //         {business_email === user?.email && (
                    //           <div
                    //             onClick={() => {
                    //               setSingleChild(chi);
                    //               setShowModal((prev) => {
                    //                 return { ...prev, delete: true };
                    //               });
                    //             }}
                    //             className={style.remove}
                    //           >
                    //             Remove
                    //           </div>
                    //         )}
                    //       </>
                    //     )}
                    //   </div>
                  );
                })}
                {/* list end */}
              </div>
            </div>
          ) : (
            <div className="detail-wrap animate-move-up-class">
              <div
                className="box-wrap grey-bg"
                style={{
                  display: "grid",
                  placeItems: "center",
                  minHeight: "45rem",
                }}
              >
                {" "}
                <NoContentBox
                  loading={loading}
                  title={`No device found`}
                  text={`List of all device use to login will be found here`}
                />
              </div>
            </div>
          )}
        </>
      </DashboardSettingsLayout>
      {/* delete device modal start */}
      <DeleteDeviceModal
        visible={showModal?.device_delete}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, device_delete: false };
          });
        }}
        detail={singleChild}
      />
      {/* delete device modal end */}
    </>
  );
};

export default DashboardSettingsDevice;
