import React from "react";
import VerificationIndexOneFive from "../VerificationIndexOneFive";
import ModalLayout from "./ModalLayout";
import { useSelector } from "react-redux";

const TierOneIndex = ({ children, stage, step }) => {
  const { business } = useSelector((state) => state.user);
  return (
    <>
      <VerificationIndexOneFive    />
      <ModalLayout 
      step={step || 1}
        stage={
          // String(business?.address) === "3" &&
          String(business?.nin) === "3" &&
          String(business?.bvn) === "3"
            ? 100
            : stage
        }
      >
        {children}
      </ModalLayout>
    </>
  );
};

export default TierOneIndex;
