import DashboardPayrollIndex from "../../../pages/dashboard/payroll";
import EditEmployee from "../../../pages/dashboard/payroll/EditEmployee";
import EmployeeProfile from "../../../pages/dashboard/payroll/EmployeeProfile";
import PayrollAutoCreate from "../../../pages/dashboard/payroll/PayrollAutoCreate";
import PayrollCurrentDisbursement from "../../../pages/dashboard/payroll/PayrollCurrentDisbursement";
import PayrollHistory from "../../../pages/dashboard/payroll/PayrollHistory";
import PayrollManualCreate from "../../../pages/dashboard/payroll/PayrollManualCreate";
import PayrollSelectCreateMethod from "../../../pages/dashboard/payroll/PayrollSelectCreateMethod";



export const dashboard_payroll_route_group = [
    {path: "/dashboard-payroll", element: <DashboardPayrollIndex />},
    {path: "/dashboard-payroll-create-auto", element: <PayrollAutoCreate />},
    {path: "/dashboard-payroll-create-manual", element: <PayrollManualCreate />},
    {path: "/dashboard-payroll-disbursement-current", element: <PayrollCurrentDisbursement />},
    {path: "/dashboard-payroll-history", element: <PayrollHistory />},
    {path: "/dashboard-payroll-employee-profile", element: <EmployeeProfile />},
    {path: "/dashboard-payroll-employee-profile-edit", element: <EditEmployee />},
    {path: "/dashboard-payroll-create-select-method", element: <PayrollSelectCreateMethod />},
]