import React, { useContext, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/card/DashboardCard.css";
import virtualCardIcon from "../../../assets/select-virtual-card-icon.svg";
import physicalCardIcon from "../../../assets/select-physical-card-icon.svg";
import { RavenInputField, RavenRadioCheck } from "@ravenpay/raven-bank-ui";
// import PreviewCardDetailsBox from "./CardPreviewDetailBox";
import AddForAnotherUserModal from "./modals/AddForAnotherUserModal";
import { useDispatch, useSelector } from "react-redux";
import {
  createVirtualCard,
  getCardConfig,
  getCardDesigns,
} from "../../../redux/card";
import {
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
} from "../../../utils/helper/Helper";
import SuccessModal from "../../../components/reusables/SuccessModal";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import LockBox from "../../../components/fragments/LockBox";
import ActionContext from "../../../context/ActionContext";
import NewPreviewDetailBox from "./NewPreviewDetaioBox";
import CardNinVerificationModal from "./modals/CardNinVerificationModal";
import NoContentBox from "../../../components/reusables/NoContentBox";

const DashboardCardCreate = () => {
  const dispatch = useDispatch();
  const { loading, card_designs, config } = useSelector((state) => state.cards);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const { business } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState({
    add_user: false,
    success: false,
    nin_bvn_notify: false,
  });
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const stepList = ["Card Type", "Card details", "Confirm Inforamtion"];
  const [step, setSTep] = useState(1);
  const [details, setDetails] = useState({
    type: "Virtual Card",
    title: "",
    amount: "",
    currency: "USD",
    theme: "",
    option: "business",
    bvn: "",
    nin: "",
    third_party_email: "",
    card_name: "",
  });
  const size = useWindowSize();
  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const typeList = [
    {
      name: "Virtual Card",
      text: "Easy access to pay globally with multiple stores online.",
      icon: virtualCardIcon,
      detail: [
        "Make purchase online",
        "Take control on your spending",
        "Make withdrawal to your wallet",
        "Dollar and Naira card option",
      ],
    },
    {
      name: "Physical Card",
      text: "Access funds from ATM and POS terminals with your card.",
      icon: physicalCardIcon,
      detail: [
        "Accepted across all local ATMs & POS",
        "Delivered to your doorsteps",
        "Easy access to control limits and expense.",
        "High security maintenance measures",
      ],
    },
  ];

  const handleNextStep = (param) => {
    if (step === 1) {
      setSTep(2);
    }
    if (step === 2) {
      setSTep(3);
    }
    if (step === 3) {
      // setShowModal(true);
      if (details?.option === "third_party") {
        handleSubmit();
      } else {
        if (business?.bvn === 3) {
          handleSubmit();
        } else {
          setShowModal((prev) => {
            return { ...prev, nin_bvn_notify: true };
          });
        }
      }
    }
  };

  // console.log(config);

  const handleSubmit = async () => {
    let val;
    if (details?.option === "business") {
      const obj = {
        card_design: details?.theme,
        card_type: details?.type === "Virtual Card" ? "virtual" : "physical",
        card_title: details?.title,
        currency: details?.currency,
        processing_fee:
          details?.currency === "NGN"
            ? config?.ngn_issuance
            : config?.usd_issuance,
        amount: formatNumWithoutCommaNaira(details?.amount),
        send_email_notification_to_cardowner: 0,
        option: details?.option,
        selected_account_number: actionCtx?.currentAccount?.account_number,
      };
      val = obj;
    } else {
      const obj = {
        card_design: details?.theme,
        card_type: details?.type === "Virtual Card" ? "virtual" : "physical",
        card_title: details?.title,
        currency: details?.currency,
        option: details?.option,
        processing_fee:
          details?.currency === "NGN"
            ? config?.ngn_issuance
            : config?.usd_issuance,
        amount: formatNumWithoutCommaNaira(details?.amount),
        bvn: details?.bvn,
        nin: details?.nin,
        third_party_email: details?.third_party_email,
        card_name: details?.card_name,
        send_email_notification_to_cardowner: 0,
        selected_account_number: actionCtx?.currentAccount?.account_number,
      };
      val = obj;
    }
    // console.log(val);
    // return;
    const data = await dispatch(createVirtualCard(val));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      setShowModal((prev) => {
        return { ...prev, success: true };
      });
      actionCtx?.setConfettiVal();
      setDetails({
        type: "",
        title: "",
        amount: "",
        currency: "",
        theme: "",
        option: "",
        bvn: "",
        nin: "",
        third_party_email: "",
        card_name: "",
      });
    }
  };

  useEffect(() => {
    dispatch(getCardDesigns());
    dispatch(getCardConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !user_resource_and_permissions?.allowed_permissions?.includes(
      "can_create_virtual_cards"
    )
  ) {
    return (
      <DashboardLayout>
        <TitletextBox title={`Cards`} text={`Create your virtual cards`} />
        {/* title box end */}
        <ContainerWrapper dontShowHeader>
          <NoContentBox
            title={`No permission to view this page`}
            text={`You will be able to view this screen when you have pemission to this page`}
            bgColor
            loading={loading}
          />
        </ContainerWrapper>
      </DashboardLayout>
    );
  }

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Cards`}
          text={`Create your virtual cards`}
          // addBtn
          // toolTipMsg={`Create a card`}
          back
          onBack={() => {
            navigate(-1);
          }}
        />
        <ContainerWrapper
          width={size.width < 900 && `100%`}
          loading={loading}
          className={`card-create-reusable-container`}
          stepList={stepList}
          activeStep={step}
          back={step > 1}
          onBack={() => {
            step > 1 && setSTep(step - 1);
          }}
          btnText={
            step === 1 && details?.type === "Physical Card"
              ? "Physical card coming soon"
              : step === 1
              ? `Start creating card`
              : step === 2
              ? `Next`
              : `Create card`
          }
          disabled={
            step === 1
              ? !details?.type || details?.type === "Physical Card"
              : step === 2
              ? !details?.amount ||
                !details?.theme ||
                !details?.currency ||
                !details?.title
              : step === 3
              ? details?.option === "third_party"
                ? !details?.option ||
                  !details?.bvn ||
                  !details?.card_name ||
                  !details?.nin
                : !details?.option
              : false
          }
          onBtnClick={handleNextStep}
        >
          <div
            className={`dashboard-create-card-wrap-box ${
              step > 1 && "dashboard-create-card-wrap-box-reduce"
            }`}
          >
            {/* step one start --------------------------- */}
            {step === 1 && (
              <>
                <div className="select-card-request-type-box">
                  <p className="select-title">Select upload type</p>
                  <div className="img-select-wrap">
                    {" "}
                    {typeList.map((chi, idx) => {
                      const { icon, name } = chi;
                      return (
                        <React.Fragment key={idx}>
                          <div
                            className={`img-wrap grey-bg select-option-class  ${
                              details.type === name &&
                              "select-option-class-active"
                            } `}
                            onClick={() => {
                              // if (name !== "Physical Card") {
                              setDetails((prev) => {
                                return { ...prev, type: name };
                              });
                              // }
                            }}
                            // style={{
                            //   cursor: name === "Physical Card" && "not-allowed",
                            // }}
                          >
                            {name === "Physical Card" && (
                              <>
                                {" "}
                                <LockBox
                                  text={`Coming Soon`}
                                  className={`lock-wrap`}
                                />
                              </>
                            )}
                            <div className="check-box">
                              <FaCheck className="icon" />
                            </div>
                            <figure
                              style={{
                                opacity: name === "Physical Card" && 0.5,
                              }}
                              className="img-box-select "
                            >
                              <img src={icon} alt="" className="img" />
                            </figure>
                            <p
                              style={{
                                opacity: details.type !== name ? 0.6 : 1,
                              }}
                              className={`name ${
                                details.type !== name && "grey-white-color"
                              }`}
                            >
                              {name}
                            </p>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  {typeList?.map((chi, idx) => {
                    const { detail, name, text } = chi;
                    if (details.type === name) {
                      return (
                        <div className="description-box-wrap yellow-grey-dark-bg grey-white-color">
                          <div className="icon-subtitle">
                            <div className="img-wrap">
                              <figure className="img-box">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="img"
                                >
                                  <path
                                    d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                                    stroke="#EA872D"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </figure>
                            </div>
                            <p className="sub-title">{text}</p>
                          </div>
                          {/* list start */}
                          <div className="list-box">
                            {detail?.map((chi, idx) => {
                              return (
                                <div key={idx} className="list-item">
                                  <div className="black-fade-white-bg"></div>
                                  <p className="text grey-white-color">{chi}</p>
                                </div>
                              );
                            })}
                          </div>
                          {/* list end */}
                        </div>
                      );
                    }
                    return <React.Fragment key={idx}></React.Fragment>;
                  })}
                </div>
              </>
            )}
            {/* step one end ----------------------------- */}
            {/* step two start ---------------------------- */}
            {step === 2 && (
              <>
                <div className="step-two-wrap">
                  {" "}
                  <p
                    onClick={() => {
                      // console.log(card_designs);
                    }}
                    className="title"
                  >
                    Select card theme
                  </p>
                  {/* select theme box start */}
                  <div className="select-theme-box">
                    {card_designs?.card_designs?.length > 0 &&
                      card_designs?.card_designs
                        .slice(0, 4)
                        ?.map((chi, idx) => {
                          // const { icon, name } = chi;
                          // console.log(card_designs?.base_url);
                          return (
                            <figure
                              className={`img-box-card select-option-class ${
                                chi === details?.theme &&
                                "select-option-class-active"
                              }`}
                              key={idx}
                              onClick={() => {
                                // console.log(card_designs);
                                setDetails((prev) => {
                                  return { ...prev, theme: chi };
                                });
                              }}
                            >
                              <div className="check-box">
                                <FaCheck className="icon" />
                              </div>
                              <img
                                src={`${card_designs?.base_url}/${chi}`}
                                alt=""
                                className="img"
                              />
                            </figure>
                          );
                        })}
                  </div>
                  {/* select theme box end */}
                  <div className="input-form-wrap">
                    <RavenInputField
                      color={`black-light`}
                      label={`Card Title* `}
                      placeholder={`e.g Netflix subscription card`}
                      type={`text`}
                      value={details?.title}
                      name={`title`}
                      onChange={handleChange}
                    />
                    <RavenInputField
                      color={`black-light`}
                      label={`Funding Amount* `}
                      placeholder={`Amount to fund into the card`}
                      thousandFormat
                      type={`number`}
                      value={details?.amount}
                      name={`amount`}
                      numberPrefix={`$`}
                      onChange={handleChange}
                      labelSpanText={
                        details?.amount &&
                        details?.currency &&
                        details?.currency !== "NGN"
                          ? `${formatNumWithCommaNairaSymbol(
                              Number(config?.usd_rate) *
                                Number(
                                  formatNumWithoutCommaNaira(details?.amount)
                                )
                            )}`
                          : ""
                      }
                    />
                    {/* currency select type box start */}
                    <p className="title">Select currency</p>
                    <div className="switch-box">
                      <div style={{ cursor: "not-allowed" }} className="box">
                        <RavenRadioCheck
                          color={`black-light`}
                          name={`currency-opt-card`}
                          className="radio-select-check"
                          id={`shgjinjh`}
                          value={details?.currency}
                          checked={details?.currency === "NGN" ? true : false}
                          onChange={() =>
                            setDetails((prev) => {
                              return { ...prev, currency: "NGN" };
                            })
                          }
                        />
                        <p className="text">Nigerian Naira {`(\u{020A6})`}</p>
                      </div>
                      <div className="box">
                        <RavenRadioCheck
                          color={`black-light`}
                          name={`currency-opt-card`}
                          id={`gjhbji7`}
                          value={details?.currency}
                          checked={details?.currency === "USD" ? true : false}
                          onChange={() =>
                            setDetails((prev) => {
                              return { ...prev, currency: `USD` };
                            })
                          }
                        />
                        <p className="text">United State Dollar ($)</p>
                      </div>
                    </div>
                    {/* currency select type boox end */}
                  </div>
                </div>
                <div
                  style={{ paddingBottom: "10rem" }}
                  className="select-card-request-type-box"
                >
                  <div className="description-box-wrap yellow-grey-dark-bg grey-white-color">
                    <div className="icon-subtitle">
                      <div className="img-wrap">
                        <figure className="img-box">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="img"
                          >
                            <path
                              d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                              stroke="#EA872D"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </figure>
                      </div>
                      <p className="sub-title">Things you need to know</p>
                    </div>
                    {/* list start */}
                    <div className="list-box">
                      {details?.currency !== "NGN" && (
                        <div className="list-item">
                          <div className="black-fade-white-bg"></div>
                          <p className="text grey-white-color">{`Minimum card funding amount is $${formatNumWithCommaNairaSymbol(
                            config?.usd_minimum_fund
                          )?.replace("₦", "")}`}</p>
                        </div>
                      )}
                      {details?.currency !== "NGN" && (
                        <div className="list-item">
                          <div className="black-fade-white-bg"></div>
                          <p className="text grey-white-color">{`Maximum card funding amount is $${formatNumWithCommaNairaSymbol(
                            config?.usd_maximum_fund
                          )?.replace("₦", "")}`}</p>
                        </div>
                      )}
                      {details?.currency !== "USD" && (
                        <div className="list-item">
                          <div className="black-fade-white-bg"></div>
                          <p className="text grey-white-color">{`Minimum card funding amount is $${formatNumWithCommaNairaSymbol(
                            config?.ngn_minimum_fund
                          )?.replace("₦", "")}`}</p>
                        </div>
                      )}
                      {details?.currency !== "USD" && (
                        <div className="list-item">
                          <div className="black-fade-white-bg"></div>
                          <p className="text grey-white-color">{`Maximum card funding amount is $${formatNumWithCommaNairaSymbol(
                            config?.ngn_axnimum_fund
                          )}`}</p>
                        </div>
                      )}
                    </div>
                    {/* list end */}
                  </div>
                </div>
              </>
            )}
            {/* step two end ---------------------------- */}
            {/* step three start ---------- */}
            {step === 3 && (
              <>
                <div
                  className=""
                  style={{ marginTop: "3rem", paddingBottom: "10rem" }}
                >
                  <NewPreviewDetailBox
                    card_theme={details?.theme}
                    amount={details?.amount}
                    card_fee={
                      details?.currency === "NGN"
                        ? config?.ngn_issuance
                        : config?.usd_issuance
                    }
                    card_type={details?.type}
                    currency={details?.currency}
                    details={details}
                    setShowModal={setShowModal}
                    setDetails={setDetails}
                    rate={
                      details?.currency === "NGN" ? 1 : Number(config?.usd_rate)
                    }
                  />
                </div>

                {/* add for another user box start */}

                {/* add for another user box end */}
              </>
            )}
            {/* step three end ------------- */}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      {/* add user modal start */}
      <AddForAnotherUserModal
        visible={showModal?.add_user}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, add_user: false };
          });
          setDetails((prev) => {
            return {
              ...prev,
              option: "",
              bvn: "",
              nin: "",
              card_name: "",
              third_party_email: "",
            };
          });
        }}
        setToDetails={(obj) => {
          setDetails((prev) => {
            return { ...prev, ...obj };
          });
          setShowModal((prev) => {
            return { ...prev, add_user: false };
          });
        }}
      />
      {/* add user modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Card Created Successfully."
        text={`Thank you for using our services. If you have any questions or concerns, please don't hesitate to contact us. We appreciate your business and look forward to serving you again in the future.`}
        btnText={`Go to view cards`}
        onBtnClick={() => {
          navigate("/dashboard-card");
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
      {/* show modal start */}
      <CardNinVerificationModal
        visible={showModal?.nin_bvn_notify}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, nin_bvn_notify: false };
          });
        }}
      />
      {/* show modal end---- */}
    </>
  );
};

export default DashboardCardCreate;
