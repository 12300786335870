import {
  RavenButton,
  // RavenDateTime,
  // RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../../styles/dashboard/schedule-payment/ViewScheduleModal.css";
import Flatpicker from "react-flatpickr";
import moment from "moment";
import {
  // formatDate,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
} from "../../../utils/helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import { reschedulePayment } from "../../../redux/schedulePayment";
import DateFragmentBox from "../../../components/fragments/DateFragmentBox";

const ViewSchedulePayment = ({
  param,
  visible,
  onClose,
  onCancel,
  onReschedule,
  detail,
  setNewDate,
  onFinish,
}) => {
  const [reschedule, setReschedule] = useState(false);
  const { loading } = useSelector((state) => state.schedule);
  // const [fromDate, setFromDate] = useState("");
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    date: "",
  });

  const handleReschedule = async () => {
    const obj = {
      id: detail?.id,
      schedule_date: details?.date,
    };
    const data = await dispatch(reschedulePayment(obj));
    if (data?.payload?.data?.status === "success") {
      setReschedule(false);
      setNewDate(data?.payload?.data?.data?.schedule_date);
      onFinish();
    }
  };

  return (
    <RavenModal
      visble={visible}
      className="view-schedule-payment-modal"
      onClose={() => {
        setReschedule(false);
        onClose();
      }}
    >
      {reschedule ? (
        <>
          <div className="reschedule-payment-box">
            <p className="title">Rescheduled Transaction</p>
            <div className="underline"></div>
            <div className="wrap">
              <Flatpicker
                theme="dark"
                options={{ enableTime: true, inline: true }}
                value={
                  details.date ||
                  moment(detail?.schedule_date).format("YYYY-MM-DD HH:mm:ss")
                }
                onChange={(date) => {
                  setDetails((prev) => {
                    return {
                      ...prev,
                      date: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
                    };
                  });
                  // setFromDate(moment(date[0]).format("YYYY-MM-DD HH:mm:ss"));
                }}
                className={`form-input input-calender`}
                autoComplete="off"
                //   placeholder={"Placeholder Here"}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="wrap-modal">
          <p
            onClick={() => {
              // console.log(detail);
              // console.log(formatMetaData(detail?.meta_data));
            }}
            className="title"
          >
            Scheduled Transaction
          </p>
          <div className="underline"></div>
          {/* label-value-status box start */}
          <div className="label-value-status-box">
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Amount:</p>
              <p className="value">
                {formatNumWithCommaNairaSymbol(detail?.amount || "0.00")}
              </p>
            </div>
            {/* box end */}
            {/* ---------- type start -------------- */}
            {detail?.category === "transfer" && (
              <>
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Bank:</p>
                  <p style={{ textTransform: "capitalize" }} className="value">
                    {formatMetaData(detail?.meta_data)?.bank}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Account No.</p>
                  <p className="value">
                    {formatMetaData(detail?.meta_data)?.account_number}
                  </p>
                </div>
                {/* box end */}
              </>
            )}
            {/* ---------- type end -------------- */}
            {detail?.category === "Electricity" && (
              <>
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Provider:</p>
                  <p style={{ textTransform: "capitalize" }} className="value">
                    {formatMetaData(detail?.meta_data)?.provider}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Meter No:</p>
                  <p style={{ textTransform: "capitalize" }} className="value">
                    {formatMetaData(detail?.meta_data)?.meter_no}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Customer Name.</p>
                  <p className="value">
                    {formatMetaData(detail?.meta_data)?.customer_name}
                  </p>
                </div>
                {/* box end */}
              </>
            )}
            {/* ---------- type end -------------- */}
            {/* ---------- type end -------------- */}
            {detail?.category === "Cable" && (
              <>
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Provider:</p>
                  <p style={{ textTransform: "capitalize" }} className="value">
                    {formatMetaData(detail?.meta_data)?.provider}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Card No:</p>
                  <p style={{ textTransform: "capitalize" }} className="value">
                    {formatMetaData(detail?.meta_data)?.smart_card_number}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Customer Name.</p>
                  <p className="value">
                    {formatMetaData(detail?.meta_data)?.customer_name}
                  </p>
                </div>
                {/* box end */}
              </>
            )}
            {/* ---------- type end -------------- */}
            {/* ---------- type start -------------- */}
            {detail?.category === "airtime" && (
              <>
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Network:</p>
                  <p style={{ textTransform: "capitalize" }} className="value">
                    {formatMetaData(detail?.meta_data)?.network}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Phone No.</p>
                  <p className="value">
                    {formatMetaData(detail?.meta_data)?.phone_number}
                  </p>
                </div>
                {/* box end */}
              </>
            )}
            {/* ---------- type end -------------- */}
            {/* ---------- type start -------------- */}
            {detail?.category === "Data" && (
              <>
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Network:</p>
                  <p style={{ textTransform: "capitalize" }} className="value">
                    {formatMetaData(detail?.meta_data)?.network}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="label grey-white-color">Phone No.</p>
                  <p className="value">
                    {formatMetaData(detail?.meta_data)?.phone_number}
                  </p>
                </div>
                {/* box end */}
              </>
            )}
            {/* ---------- type end -------------- */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Transaction Type </p>
              <p style={{ textTransform: "capitalize" }} className="value">
                {detail?.type || detail?.category}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Scheduled date </p>
              <p className="value">
                {
                  <DateFragmentBox
                    date={detail?.schedule_date}
                    // dontShowAgo={true}
                  />
                }
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Status </p>
              <div
                className={`status-type-wrap ${
                  String(detail?.status) === "0" && "status-type-wrap-pending"
                } ${
                  String(detail?.status) === "3" && "status-type-wrap-success"
                } ${
                  String(detail?.status) === "success" &&
                  "status-type-wrap-success"
                } ${
                  String(detail?.status) === "paid" &&
                  "status-type-wrap-success"
                } ${
                  String(detail?.status) === "2" && "status-type-wrap-fail"
                } ${
                  String(detail?.status) === "overdue" &&
                  "status-type-wrap-pending"
                } ${
                  String(detail?.status) === "1" && "status-type-wrap-pending"
                } ${
                  String(detail?.status) === "pending" &&
                  "status-type-wrap-pending"
                }`}
              >
                <span>
                  {String(detail?.status) === "1"
                    ? "Processing"
                    : String(detail?.status) === "0"
                    ? "Pending"
                    : String(detail?.status) === "3"
                    ? "Success"
                    : String(detail?.status) === "2"
                    ? "Failed"
                    : String(detail?.status) === "11"
                    ? "Reversal"
                    : detail?.status}
                </span>
              </div>
            </div>
            {/* box end */}
          </div>
          {/* label-value-status box end */}
        </div>
      )}
      <div className="two-button-wrap">
        <RavenButton
          onClick={() => {
            setReschedule(!reschedule);
          }}
          style={{ gridColumn: detail?.status === 2 && `1 / -1` }}
          color={`black-light`}
          label={reschedule ? `Cancel` : `Reschedule`}
          //   style={{width: reschedule && "max-content"}}
          className={reschedule && `btn-outline-base-bg-black`}
          textColor={reschedule && `black-light`}
        />
        {detail?.status !== 2 && (
          <>
            {reschedule ? (
              <RavenButton
                onClick={handleReschedule}
                color={`black-light`}
                label={`Apply`}
                loading={loading}
                // style={{width: reschedule && "max-content"}}
              />
            ) : (
              <RavenButton
                onClick={onCancel}
                color={`error-light`}
                label={`Cancel Schedule`}
              />
            )}
          </>
        )}
      </div>
    </RavenModal>
  );
};

export default ViewSchedulePayment;
