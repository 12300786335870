import moment from "moment";
import {
  RavenInputField,
  RavenModal,
  RavenToggleSlide,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { managePayrollDeductionAndTax } from "../../../../redux/payroll";
import "../../../../styles/dashboard/payroll/PayrollIndex.css";
import { formatDate } from "../../../../utils/helper/Helper";
import ChangeSourceAccount from "../../../../components/reusables/ChangeSourceAccount";
import ActionContext from "../../../../context/ActionContext";

const ManageDeductionModal = ({
  visible,
  onClose,
  detail,
  setPayrollSetup,
  onFinish,
  setPayrollSetupDate,
}) => {
  const dispatch = useDispatch();
  const { loadingDetail } = useSelector((state) => state.payroll);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const [subAccount, setSubAccount] = useState({});
  const actionCtx = useContext(ActionContext);

  const handleSubmit = async () => {
    const obj = {
      payday: moment(detail?.next_pay_date).format("YYYY-MM-DD hh:mm:ss"),
      payee_status: detail?.payee_status,
      pension_status: detail?.pension_status,
      nhf_status: detail?.nhf_status,
      selected_account_number:
        subAccount?.account_number || actionCtx?.currentAccount?.account_number,
    };
    // console.log(obj);
    // return
    const data = await dispatch(managePayrollDeductionAndTax(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
    }
  };

  const flatpickrRef = useRef(null);

  useEffect(() => {
    // In useEffect, we can access the Flatpickr instance through the ref
    if (flatpickrRef.current) {
      const currentMonthStart = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      const currentMonthEnd = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      );

      flatpickrRef.current.config.minDate = currentMonthStart;
      flatpickrRef.current.config.maxDate = currentMonthEnd;
      flatpickrRef.current.redraw(); // Redraw the calendar to apply the new date range
    }
  }, []);

  useEffect(() => {
    setSubAccount(detail?.sub_account_details);
  }, [detail]);

  const clockIcon = (
    <svg
      className="img"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00065 4.50004V8.50004L10.6673 9.83337M14.6673 8.50004C14.6673 12.1819 11.6826 15.1667 8.00065 15.1667C4.31875 15.1667 1.33398 12.1819 1.33398 8.50004C1.33398 4.81814 4.31875 1.83337 8.00065 1.83337C11.6826 1.83337 14.6673 4.81814 14.6673 8.50004Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const disableDates = (date) => {
    // Disable dates outside of the current month and dates greater than 28th
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    return (
      date.getMonth() !== currentMonth ||
      date.getFullYear() !== currentYear ||
      (date.getDate() > 28 && date.getMonth() === currentMonth)
    );
  };

  return (
    <RavenModal
      btnColor={`black-light`}
      btnLabel={
        user_resource_and_permissions?.allowed_permissions?.includes(
          "can_update_payroll_setup"
        ) && `Save Settings`
      }
      visble={visible}
      onClose={onClose}
      loading={loadingDetail}
      onBtnClick={handleSubmit}
    >
      {/* deduction wrap start */}
      <div className="payroll-deduction-modal-wrap-box">
        <p
          onClick={() => {
            console.log(detail);
          }}
          className="title-payroll"
        >
          Deduction & Taxes
        </p>

        <div className="deduct-wrap">
          {/* toggle text box */}
          <div className="toggle-text border-theme-top">
            <div className="text-box">
              <p className="text">Payee Tax:</p>
              <p className="two grey-white-color">
                Automatically applies to all employees payee tax by default
              </p>
            </div>
            <RavenToggleSlide
              className="raven-toggle-dark-theme"
              onChange={(e) => {
                const val = detail?.payee_status === "1" ? "0" : "1";
                setPayrollSetup((prev) => {
                  return { ...prev, payee_status: val };
                });
              }}
              checked={detail?.payee_status === "1" ? true : false}
              value={detail?.payee_status}
              color={`black-light`}
              id="payee"
            />
          </div>
          {/* toggle text box end */}
          {/* toggle text box */}
          <div className="toggle-text border-theme-top">
            <div className="text-box">
              <p className="text">Pension Contribution:</p>
              <p className="two grey-white-color">
                Automatically applies to all employees pension by default
              </p>
            </div>
            <RavenToggleSlide
              onChange={(e) => {
                const val = detail?.pension_status === "1" ? "0" : "1";
                setPayrollSetup((prev) => {
                  return { ...prev, pension_status: val };
                });
              }}
              checked={detail?.pension_status === "1" ? true : false}
              value={detail?.pension_status}
              color={`black-light`}
              id="pension"
            />
          </div>
          {/* toggle text box end */}
          {/* toggle text box */}
          <div className="toggle-text border-theme-top">
            <div className="text-box">
              <p className="text">NHF Contribution:</p>
              <p className="two grey-white-color">
                Automatically applies to all employees nhf by default
              </p>
            </div>
            <RavenToggleSlide
              onChange={(e) => {
                const val = detail?.nhf_status === "1" ? "0" : "1";
                setPayrollSetup((prev) => {
                  return { ...prev, nhf_status: val };
                });
              }}
              checked={detail?.nhf_status === "1" ? true : false}
              value={detail?.nhf_status}
              color={`black-light`}
              id="nhf"
            />
          </div>
          {/* toggle text box end */}
          {/* payout date start */}
          <label
            htmlFor="select-payout-date-id"
            className="payout-date-wrap grey-bg"
          >
            <figure className="img-box">{clockIcon}</figure>
            <p className="text">
              {detail?.next_pay_date
                ? `Payout Date: ${formatDate(detail?.next_pay_date)
                    ?.split(" ")[1]
                    ?.replace(",", "")}`
                : `Select a payout date`}
            </p>
            <RavenInputField
              dateOptions={{ disable: [disableDates] }}
              type={`date`}
              value={detail?.next_pay_date}
              onChange={(e) => {
                setPayrollSetupDate &&
                  setPayrollSetupDate(
                    moment(e[0]).format("YYYY-MM-DD HH:mm:ss")
                  );
              }}
              id={`select-payout-date-id`}
              color={`black-light`}
              className={`input-date`}
              placeholder={" "}
              enableTime={true}
              // dateOptions={{weekNumbers: true,}}
              // scheduleFormat={true}
            />
          </label>
          {/* payout date end */}
          {/* account drop wrap start */}
          <ChangeSourceAccount
            onObjChange={(e) => {
              setSubAccount(e);
            }}
            darkType={true}
            // onChange={() => onClose()}
            title={`Payroll Settlement Account.`}
            defaultObj={subAccount || actionCtx?.currentAccount}
            // downDropType
          />
          {/* account drop wrap end */}
        </div>
      </div>
      {/* deduction wrap end */}
    </RavenModal>
  );
};

export default ManageDeductionModal;
