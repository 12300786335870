import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import "../../../styles/dashboard/global-account/BeneficiaryGlobalModal.css";
import { useSelector } from "react-redux";
import NoContentBox from "../../../components/reusables/NoContentBox";
import {
    capitalizeFirstLetter,
  convertToLowercase,
  searchGlobalFunction,
  trimLongString,
} from "../../../utils/helper/Helper";
import HighlightSearch from "../../../components/fragments/HighlightSearch";

const BeneficiaryModal = ({ visible, onClose, onSelect }) => {
  const [beneList, setBenelist] = useState([]);
  const [search, setSearch] = useState("");
  const { loadingBene, all_beneficiaries } = useSelector(
    (state) => state.global_account
  );

  useEffect(() => {
    if (all_beneficiaries?.beneficiaries?.length > 0) {
      setBenelist(formatBeneficiaryList(all_beneficiaries?.beneficiaries));
    }
  }, [all_beneficiaries]);

  useEffect(() => {
    if (search?.length > 1) {
      const newList = searchGlobalFunction(
        search,
        formatBeneficiaryList(all_beneficiaries?.beneficiaries),
        ["account_number", "bank_name", "beneficiary_name"]
      );
      setBenelist(newList);
    } else {
      setBenelist(formatBeneficiaryList(all_beneficiaries?.beneficiaries));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const formatBeneficiaryList = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          label: `${trimLongString(capitalizeFirstLetter(chi?.beneficiary_name), 22) || ""} || ${
            chi?.account_number || ""
          }`,
          value: chi?.id,
        };
      });
      return newList;
    }
  };

  return (
    <>
      <RavenModal visble={visible} onClose={onClose}>
        <div
          //   onClick={() => {
          //     console.log(all_beneficiaries?.beneficiaries);
          //   }}
          className="global-account-beneficiary-modal-wrap-index"
        >
          <p className="title">Select Beneficiary</p>
          {/* form stat */}
          {beneList?.length > 0 && (
            <form
              action=""
              autoComplete="off"
              style={{ width: "100%" }}
              className="form"
            >
              <RavenInputField
                type={`search`}
                color="black-light"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </form>
          )}
          {/* form end */}
          {/* select beneficiary list box start  */}
          <div className="load-more-select-beneficiary-list-box">
            {/* select start */}
            {loadingBene ? (
              <>
                <NoContentBox loading={true} />
              </>
            ) : (
              <div className="select-beneficiary-list-box">
                {beneList?.map((chi, idx) => {
                  return (
                    <div
                      onClick={() => {
                        onSelect && onSelect(chi);
                      }}
                      className={`bene-row ${
                        beneList?.length - 1 !== idx && "border-theme-bottom"
                      }`}
                      key={idx}
                    >
                      <div className="img-bene-wrap">
                        <figure className="img-box">
                          <img
                            src={`https://personalbanking.getraventest.com/static/media/currencies/${convertToLowercase(
                              chi?.currency
                            )}.png`}
                            alt=""
                            className="img"
                          />
                        </figure>
                      </div>
                      <div className="name-num-box">
                        <p className="name">
                          {" "}
                          <HighlightSearch
                            text={`${chi?.beneficiary_name || ""}`}
                            search={search}
                            spanClassName={`grey-white-color`}
                          />
                        </p>
                        <p className="numb grey-white-color">
                          {chi?.account_number || ""}
                          {/* <HighlightSearch
                            text={`${chi?.account_number || ""}`}
                            search={search}
                            spanClassName={`grey-white-color`}
                          />  */}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* select end */}
            {/* load more start */}
            {beneList?.length > 10 && (
              <div className="load-more-box grey-bg">
                <p className="text">Load more</p>
              </div>
            )}
            {/* load more end */}
          </div>
          {/* select beneficiary list box end */}
        </div>
      </RavenModal>
    </>
  );
};

export default BeneficiaryModal;
