import {
  RavenButton,
//   RavenInputField,
  RavenModal,
  RavenToggleSlide,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../../../styles/dashboard/schedule-payment/ViewScheduleModal.css";
// import Flatpicker from "react-flatpickr";
// import moment from "moment";
import {
//   formatDate,
  // formatMetaData,
  formatNumWithCommaNairaSymbol,
//   sumAllNum,
} from "../../../../utils/helper/Helper";
import { useSelector } from "react-redux";
// import { reschedulePayment } from "../../../../redux/schedulePayment";

const MobileEmployeeViewCurrent = ({
  param,
  visible,
  onClose,
  onCancel,
  onDownload,
  downloadCsvId,
  detail,
  setNewDate,
  onFinish,
  onRemoveFromPayroll,
  onAddDeduction,
  onAddIncrement
}) => {
  const [reschedule, setReschedule] = useState(false);
  const { loadingDowloadSingle } = useSelector((state) => state.payroll);
  // const [fromDate, setFromDate] = useState("");
//   const dispatch = useDispatch();
//   const [details, setDetails] = useState({
//     date: "",
//   });



  return (
    <RavenModal
      visble={visible}
      className="view-schedule-payment-modal"
      onClose={() => {
        setReschedule(false);
        onClose();
      }}
    >
      {reschedule ? (
        <></>
      ) : (
        <div className="wrap-modal">
          <p
            onClick={() => {
              // console.log(detail);
              // console.log(formatMetaData(detail?.meta_data));
            }}
            className="title"
          >
            Transaction Detail
          </p>
          <div className="underline"></div>
          {/* label-value-status box start */}
          <div className="label-value-status-box">
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Amount:</p>
              <p className="value">
                {formatNumWithCommaNairaSymbol(detail?.net_amount || "---")}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Account no:</p>
              <p style={{ textTransform: "capitalize" }} className="value">
                {detail?.account_number || "---"}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Account name:</p>
              <p style={{ textTransform: "capitalize" }} className="value">
                {detail?.account_name}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Username:</p>
              <p style={{ textTransform: "capitalize" }} className="value">
                {detail?.username || "---"}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Bank:</p>
              <p style={{ textTransform: "capitalize" }} className="value">
                {detail?.bank || "---"}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">
                {detail?.status === "pending_approval"
                  ? "Remove from payroll"
                  : "Add to Payroll"}
              </p>
              <div
                style={{ textTransform: "capitalize" }}
                className="value value-toggle"
              >
                <RavenToggleSlide
                  color={`black-light`}
                  id={`kjjnqs-${detail?.id}`}
                  checked={detail?.status === "pending_approval" ? true : false}
                  onChange={() => {
                    onRemoveFromPayroll();
                  }}
                />
              </div>
            </div>
            {/* box end */}

            {/* ---------- type end -------------- */}

            {/* box start */}
            {/* <div className="box">
                  <p className="label grey-white-color">Status </p>
                  <div
                    className={`status-type-wrap ${
                      detail?.status === "Closed" && "status-type-wrap-pending"
                    } ${detail?.status === 2 && "status-type-wrap-success"} ${
                      detail?.status === 3 && "status-type-wrap-fail"
                    } ${detail?.status === 0 && "status-type-wrap-pending"} ${
                      detail?.status === 1 && "status-type-wrap-pending"
                    }`}
                  >
                    <span>
                      {detail?.status === 1
                        ? "Processing"
                        : detail?.status === 0
                        ? "Pending"
                        : detail?.status === 2
                        ? "Success"
                        : detail?.status === 3
                        ? "Failed"
                        : ""}
                    </span>
                  </div>
                </div> */}
            {/* box end */}
          </div>
          {/* label-value-status box end */}
        </div>
      )}
      <div className="two-button-wrap">
        <RavenButton
          loading={loadingDowloadSingle}
          onClick={() => {onAddDeduction()}}
          color={`error-light`}
          label={`Add Deduction`}
          //   style={{width: reschedule && "max-content"}}
        />
        <RavenButton
          loading={loadingDowloadSingle}
          onClick={() => {onAddIncrement() }}
          color={`black-light`}
          label={`Add Increment`}
          //   style={{width: reschedule && "max-content"}}
        />
      </div>
    </RavenModal>
  );
};

export default MobileEmployeeViewCurrent;
