import React, { useState } from "react";
// import NewSelectScheduleBox from "../../../components/reusables/NewSelectScheduleBox";
// import SelectScheduleBox from "../../../components/reusables/SelectScheduleBox";Group 238182raven-default-bank-img.svg
import "../../../styles/fragments/PreviewDetailsCommonBox.css";
import bankIconDefault from "../../../assets/bank-general-img.svg";
// import bankIconDefault from "../../../assets/raven-default-bank-img.svg";
// import moment from "moment";
import // formatDate,
//   formatNumWithoutCommaNaira,
// removeLeadingZeroFromString,
//   trimLongString,
"../../../utils/helper/Helper";
import { FaTimes } from "react-icons/fa";
// import ActionContext from "../../../context/ActionContext";
import { RavenCheckBox, RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import ChangeSourceAccount from "../../../components/reusables/ChangeSourceAccount";

const NewPreviewDetailBox = ({
  collapse,
  handleRemoveFromList,
  list,
  totalDeduction,
  netSalary,
  grossSalary,
  payeeValue,
  payeeAmount,
  onChangePayee,
  checkedPayee,
  checkedPension,
  pensionValue,
  pensionAmount,
  onChangePension,
  checkedNhf,
  nhfValue,
  nhfAmount,
  onChangeNhf,
  addCheckVal,
  onAddCheckVal,
  defaultSubAccount,
  setDefaultSubAccount,
}) => {
  const [viewAll, setViewAll] = useState(false);
  // const actionCtx = useContext(ActionContext);

  // const InfoIcon = (
  //   <svg
  //     className="img"
  //     width="12"
  //     height="12"
  //     viewBox="0 0 12 12"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
  //       stroke="#8B8B8B"
  //       strokeWidth="1.5"
  //       stroke-linecap="round"
  //       stroke-linejoin="round"
  //     />
  //   </svg>
  // );

  return (
    <div className="new-preview-detail-box-wrap card-bg border-theme">
      {/* img top box start */}
      <div className="img-top-box-wrap border-theme-bottom">
        <div className="img-wrap">
          <figure className="img-box">
            <img src={bankIconDefault} alt="" className="img" />
          </figure>
          {/* <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div> */}
        </div>
        {/* amount date box styart */}
        <div className="amount-date-box">
          <p className="amount">{totalDeduction || ""}</p>
          <p className="date grey-white-color-white">
            Total Deductions
            {/* {valueSchedule
              ? formatDate(valueSchedule)
              : formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))} */}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div className="middle-content-wrap border-theme-bottom">
        {collapse ? (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Recipients:</p>
              <div className="value">
                <div
                  onClick={() => {
                    viewAll ? setViewAll(false) : setViewAll(true);
                  }}
                  className="see-all-box"
                >
                  <span>({list?.length || 0})</span>
                  <p className="see-all-text">
                    {viewAll ? `Close` : `See all`}
                  </p>
                </div>
                {/*  */}
                <div
                  className={`view-all-box card-bg ${
                    viewAll && "view-all-box-show"
                  }`}
                >
                  {" "}
                  <div className="wrap">
                    {list?.map((chi, idx) => {
                      return (
                        <div key={idx} className="child-wrap">
                          <figure className="img-box">
                            <img src={bankIconDefault} alt="" className="img" />
                          </figure>
                          <div className="text-box">
                            <p className="name">{`+234 ${chi?.phone}`}</p>
                            <p className="text grey-white-color">{` ₦ ${chi?.amount}`}</p>
                          </div>
                          <div
                            onClick={() => {
                              handleRemoveFromList(chi);
                            }}
                            className="cancel-box"
                          >
                            <FaTimes className="icon" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            {/* <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">{bulkAmount || 0}</div>
            </div> */}
            {/* box end */}
          </>
        ) : (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Net Salaries:</p>
              <p className="value">{netSalary}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Gross Salaries:</p>
              <p className="value">{grossSalary}</p>
            </div>
            {/* box end */}
            {/* box start */}
            {/* <div className="box">
              <p className="label grey-white-color-white">Narration:</p>
              <p className="value">{narration || ""}</p>
            </div> */}
            {/* box end */}
          </>
        )}
      </div>
      {/* middle content wrap end */}
      {/* bottom- content wrap start */}
      <div className="bottom-content-wrap">
        {/* bottom box start */}
        <div className="bottom-box">
          <p className="text grey-white-color">
            {collapse
              ? `Save all as beneficiary`
              : `Payee Tax (${payeeAmount})`}
          </p>
          <RavenToggleSlide
            onChange={onChangePayee}
            id={`derty`}
            value={payeeValue}
            color={`purple-light`}
            checked={checkedPayee}
            //   className={`toggle-box-wrap`}
          />
        </div>
        {/* bottom box end */}
        {/* bottom box start */}
        <div className="bottom-box">
          <p className="text grey-white-color">
            {collapse
              ? `Save all as beneficiary`
              : `Pension Contributions (${pensionAmount})`}
          </p>
          <RavenToggleSlide
            onChange={onChangePension}
            id={`dfghj`}
            value={pensionValue}
            color={`purple-light`}
            checked={checkedPension}
            //   className={`toggle-box-wrap`}
          />
        </div>
        {/* bottom box end */}
        {/* bottom box start */}
        <div className="bottom-box">
          <p className="text grey-white-color">
            {collapse
              ? `Save all as beneficiary`
              : `NHF Contributions (${nhfAmount})`}
          </p>
          <RavenToggleSlide
            onChange={onChangeNhf}
            id={`dfgfdfj`}
            value={nhfValue}
            color={`purple-light`}
            checked={checkedNhf}
            //   className={`toggle-box-wrap`}
          />
        </div>
        {/* bottom box end */}
        {/* box start */}
        <div className="box box-check border-theme-top">
          <RavenCheckBox
            className=""
            color="black-light"
            value={addCheckVal}
            checked={addCheckVal}
            onChange={onAddCheckVal}
          />
          <p className="des-text grey-white-color">
            Include deduction to Gross salary
          </p>
        </div>
        {/* box end */}
      </div>
      {/* bottom- content wrap end */}
      {/* account drop wrap start */}
      {/* change wrap start */}

      {/* account drop wrap start */}
      <ChangeSourceAccount
        onObjChange={(e) => {
          setDefaultSubAccount(e);
        }}
        darkType={true}
        // onChange={() => onClose()}
        title={`Payroll Settlement Account.`}
        defaultObj={defaultSubAccount}
        // downDropType
      />
      {/* account drop wrap end */}
      {/* account drop wrap end */}
    </div>
  );
};

export default NewPreviewDetailBox;
