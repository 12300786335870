import moment from "moment";
import {
  RavenInputField,
  RavenModal,
  RavenTabLink,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateBankStatement } from "../../../../../redux/settings";
import "../../../../../styles/dashboard/setting/SettingModal.css";
import { reactSelectStyle } from "../../../../../utils/helper/Helper";
import { FaCheck } from "react-icons/fa";
import ActionContext from "../../../../../context/ActionContext";

const GenerateStatementModal = ({ visible, onCancel }) => {
  const [fromDate, setFromDate] = useState("");
  const [specificDate, setSpecificDate] = useState("");
  const [toDate, setToDate] = useState("");
  const actionCtx = useContext(ActionContext);
  const [sendToVal, setSendToVal] = useState({
    label: "Download",
    value: "download",
  });
  const [fileTypeVal, setFileTypeVal] = useState({
    label: "PDF",
    value: "pdf",
  });
  const { loadingStatement } = useSelector((state) => state.settings);
  const { all_account } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [generateType, setGenerateType] = useState(0);
  const selectList = ["Range Date Select", "Custom Date Select"];
  const [details, setDetails] = useState({
    selected: "",
    account_id: "",
  });

  const selectOptions = [
    { label: "Specific Date", value: "specific_date" },
    { label: "1 month", value: 1 },
    { label: "3 months", value: 3 },
    { label: "6 months", value: 6 },
  ];

  const fileTypeList = [
    { label: "PDF", value: "pdf" },
    { label: "CSV", value: "csv" },
  ];

  const sendToList = [
    { label: "Download", value: "download" },
    { label: "Email", value: "email" },
    { label: "Both", value: "both" },
  ];

  const handleSubmit = async () => {
    // console.log(details);
    let obj;
    if (generateType === 1) {
      const val = {
        date: `${fromDate}_${toDate}`,
        file_type: fileTypeVal?.value,
        send_to: sendToVal?.value,
        account_id: details?.account_id?.id || actionCtx?.currentAccount?.id,
      };
      obj = val;
    }
    if (generateType !== 1 && details.selected.value === "specific_date") {
      const val = {
        date: `${specificDate}_${specificDate}`,
        file_type: fileTypeVal?.value,
        send_to: sendToVal?.value,
        account_id: details?.account_id?.id || actionCtx?.currentAccount?.id,
      };
      obj = val;
    }
    if (generateType !== 1 && details.selected.value !== "specific_date") {
      const val = {
        date: `${details?.selected.value}_months`,
        file_type: fileTypeVal?.value,
        send_to: sendToVal?.value,
        account_id: details?.account_id?.id || actionCtx?.currentAccount?.id,
      };
      obj = val;
    }
    // else {
    //   const val = {
    //     date: `${details?.selected.value}_months`,
    //     file_type: fileTypeVal?.value,
    //     send_to: sendToVal?.value,
    //   };
    //   obj = val;
    // }
    // console.log(obj);
    // return;
    const data = await dispatch(generateBankStatement(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      // downloadCsv(data?.payload?.data.data.url);
      if (data?.payload?.data.data.url && sendToVal?.value !== "email") {
        downloadCsv(data?.payload?.data.data.url);
      }
      setDetails((prev) => {
        return { ...prev, selected: "", account_id: "" };
      });
      setSendToVal({
        label: "Download",
        value: "download",
      });
      setFileTypeVal({
        label: "PDF",
        value: "pdf",
      });
      setFromDate("");
      setToDate("");
      setSpecificDate("");
      onCancel();
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const handleAllAccountFormat = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return { ...chi, value: chi?.id, label: chi?.custom_account_name };
      });
      return newList;
    }
  };

  return (
    <RavenModal
      visble={visible}
      btnColor={`black-light`}
      btnLabel={`Generate Statement`}
      onClose={() => {
        onCancel();
        setFromDate("");
        setToDate("");
        setDetails((prev) => {
          return { ...prev, selected: "" };
        });
      }}
      onBtnClick={handleSubmit}
      loading={loadingStatement}
      disabled={
        generateType === 0 && details.selected.value !== "specific_date"
          ? !details?.selected
          : generateType === 0 && details.selected.value === "specific_date"
          ? !details?.selected || !specificDate
          : !fromDate || !toDate
      }
    >
      {/* Sttings modal start -------------- */}
      <div
        className="settings-modal-wrap"
        // onClick={() => {
        //   console.log(actionCtx?.currentAccount);
        // }}
      >
        {/* gnerate mwrap start */}
        <div className="generate-modal-wrap">
          <p className="title">Generate Statement</p>
          <p className="text grey-white-color">
            Select how to generate statement
          </p>
          {/* select wrap start */}
          <div className="select-wrap">
            <RavenTabLink
              color={`black-light`}
              key={`kaja`}
              list={selectList}
              onClick={(e) => {
                setGenerateType(e);
                if (e === 0) {
                  setFromDate("");
                  setToDate("");
                } else {
                  setDetails((prev) => {
                    return { ...prev, selected: "" };
                  });
                  setSpecificDate("");
                }
              }}
              fadedBlack
            />
          </div>
          {/* select wrap end */}
          {/*  */}
          {/* custom start */}
          <div className="input-type-wrap">
            {all_account?.length > 1 && (
              <form
                action=""
                autoComplete="off"
                style={{
                  width: "100%",
                  position: "relative",
                  zIndex: 201,
                  marginBottom: "2rem",
                }}
              >
                {" "}
                <RavenInputField
                  color={`black-light`}
                  label={`Select sub acount`}
                  placeholder={`sub account`}
                  value={
                    details?.account_id || {
                      ...actionCtx?.currentAccount,
                      label: actionCtx?.currentAccount?.custom_account_name,
                      value: actionCtx?.currentAccount?.id,
                    }
                  }
                  selectStyles={reactSelectStyle}
                  type={`select`}
                  selectOption={handleAllAccountFormat(all_account)}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, account_id: e };
                    });
                  }}
                />
              </form>
            )}
            {/* select wrap type  */}
            <div
              className={`select-type-wrap ${
                generateType === 0 && "select-type-wrap-show"
              }`}
            >
              <form
                action=""
                autoComplete="off"
                style={{ width: "100%", position: "relative", zIndex: 200 }}
              >
                {" "}
                <RavenInputField
                  // menuPlacement={`top`}
                  color={`black-light`}
                  label={`select date`}
                  placeholder={`E.g 1 month`}
                  value={details?.selected}
                  selectStyles={reactSelectStyle}
                  type={`select`}
                  selectOption={selectOptions}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, selected: e };
                    });
                  }}
                />
              </form>
              <div
                className={`custom-type-wrap ${
                  details.selected.value === "specific_date" &&
                  "custom-type-wrap-show"
                }`}
                style={{
                  position: "relative",
                  zIndex: 100,
                  marginTop: "1.5rem",
                }}
              >
                {" "}
                <form action="" autoComplete="off" style={{ width: "100%" }}>
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    type={`date`}
                    value={specificDate}
                    dateOptions={{ maxDate: new Date() }}
                    label={`Specific Date`}
                    placeholder={`Select specific from`}
                    onChange={(e) => {
                      setSpecificDate(moment(e[0]).format("YYYY-MM-DD"));
                    }}
                  />
                </form>
              </div>
            </div>
            {/* select wrap end */}
            {/* custom type start */}
            <div
              className={`custom-type-wrap ${
                generateType === 1 && "custom-type-wrap-show"
              }`}
            >
              <form action="" autoComplete="off" style={{ width: "100%" }}>
                {" "}
                <RavenInputField
                  color={`black-light`}
                  type={`date`}
                  value={fromDate}
                  dateOptions={{ maxDate: new Date() }}
                  label={`From`}
                  placeholder={`Select date from`}
                  onChange={(e) => {
                    setFromDate(moment(e[0]).format("YYYY-MM-DD"));
                  }}
                />
              </form>
              <form action="" autoComplete="off" style={{ width: "100%" }}>
                {" "}
                <RavenInputField
                  placeholder={`Select date to`}
                  dateOptions={{ maxDate: new Date(), minDate: fromDate }}
                  color={`black-light`}
                  type={`date`}
                  value={toDate}
                  label={`To`}
                  onChange={(e) => {
                    setToDate(moment(e[0]).format("YYYY-MM-DD"));
                  }}
                />
              </form>
            </div>
            {/* custom type end */}
          </div>
          {/* custom end */}
          <div
            className={`select-type-wrap ${
              details.selected && "select-type-wrap-show"
            } ${fromDate && toDate && "select-type-wrap-show"}`}
          >
            {" "}
            {/* theme preference start */}
            <div className="theme-preference-wrap">
              <div className="btn-text-box">
                <div className="text-box">
                  {" "}
                  <p className="content-title ">File Type</p>
                  <p className="content-text grey-white-color-white">
                    Customise your preferred file type to be generated
                  </p>
                </div>
              </div>

              {/* select wrapper start */}
              <div
                className="select-wrapper table-select-font-wrap "
                // style={{ alignSelf: "flex-start", minWidth: "27rem" }}
              >
                {fileTypeList?.map((chi, idx) => {
                  const { label, value } = chi;
                  return (
                    <div
                      key={idx}
                      className={`child card-bg border-theme ${
                        fileTypeVal.value === value && "child-active"
                      }`}
                      onClick={() => {
                        setFileTypeVal(chi);
                      }}
                    >
                      <span className="grey-white-color">{label}</span>
                      {fileTypeVal.value === value && (
                        <div className="check-box">
                          <FaCheck className="icon" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* select wrapper end */}
            </div>
            {/* theme preference end */}
            {/* theme preference start */}
            <div className="theme-preference-wrap">
              <div className="btn-text-box">
                <div className="text-box">
                  {" "}
                  <p className="content-title ">File location</p>
                  <p className="content-text grey-white-color-white">
                    Customise where file generated will be sent to
                  </p>
                </div>
              </div>

              {/* select wrapper start */}
              <div
                className="select-wrapper table-select-font-wrap "
                // style={{ alignSelf: "flex-start", minWidth: "27rem" }}
              >
                {sendToList?.map((chi, idx) => {
                  const { label, value } = chi;
                  return (
                    <div
                      key={idx}
                      className={`child card-bg border-theme ${
                        sendToVal.value === value && "child-active"
                      }`}
                      onClick={() => {
                        setSendToVal(chi);
                      }}
                    >
                      <span className="grey-white-color">{label}</span>
                      {sendToVal.value === value && (
                        <div className="check-box">
                          <FaCheck className="icon" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* select wrapper end */}
            </div>
            {/* theme preference end */}
          </div>
        </div>
        {/* gnerate mwrap end */}
      </div>
      {/* settings modal end -------------- */}
    </RavenModal>
  );
};

export default GenerateStatementModal;
