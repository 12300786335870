import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/bill-payment/Airtime.css";
import showmaxICon from "../../../../assets/showmax-icon.svg";
import dstvICon from "../../../../assets/dstv-icon.svg";
import gotvICon from "../../../../assets/gotv-icon.svg";
import startimeICon from "../../../../assets/startime-icon.svg";
// import addIcon from "../../../../assets/add-icon-form.svg";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";
import { FaCheck, FaTimes } from "react-icons/fa";
import { RavenCheckBox, RavenInputField } from "@ravenpay/raven-bank-ui";
import PreviewCableDetailsBox from "./PreviewCableBox";
import { ThreeDots } from "react-loader-spinner";
import {
  filterEmptyColumns,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  reactSelectStyle,
} from "../../../../utils/helper/Helper";
import ActionContext from "../../../../context/ActionContext";
import { useSelector, useDispatch } from "react-redux";
import {
  buyCablePlan,
  getCablePlans,
  validateSmartCardNo,
} from "../../../../redux/billPayment";
import useDebounce from "../../../../utils/helper/UseDebounce";
import SuccessModal from "../../../../components/reusables/SuccessModal";
// import moment from "moment";
import { getAllAccounts, getUser } from "../../../../redux/user";
import useWindowSize from "../../../../utils/helper/UseWindowSize";
import NewPreviewDetailBox from "./NewPreviewBox";

const DashboardBillPaymentCable = () => {
  const {  user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [previewOpen, setPreviewOpen] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const { loading, cablePlans } = useSelector((state) => state.billPayment);
  const actionCtx = useContext(ActionContext);
  const [urlBack, setUrlBack] = useState("");
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [viewAll, setViewAll] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [details, setDetails] = useState({
    number: "",
    amount: "",
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    schedule: "",
    provider: "",
    cable_plan: "",
    smart_card_number: "",
    phone: "",
    code: "",
    user_pin: "",
    schedule_date: "",
  });

  const providerList = [
    { name: "startimes", icon: startimeICon },
    { name: "dstv", icon: dstvICon },
    { name: "gotv", icon: gotvICon },
    { name: "showmax", icon: showmaxICon },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };
  const stepList = ["Transaction detail", "Confirm Transactions"];
  useEffect(() => {
    const url = window?.location?.href?.split("?")[1];
    if (url) {
      setUrlBack(url);
    }
  }, []);

  const formatCableList = (list) => {
    const newList = list?.map((chi) => {
      return { ...chi, label: chi?.name, value: chi?.code };
    });
    return newList;
  };

  // useEffect(() => {
  //   if (
  //     !user_resource_and_permissions?.allowed_permissions?.includes(
  //       "can_view_bill_payments"
  //     )
  //   ){
  //     navigate("/dashboard-bill-payment")
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user_resource_and_permissions])
  // const handleBulkTransfer = () => {
  //   const checkList = details?.bulk_list?.some(
  //     (chi) => chi?.smart_card_number === details?.smart_card_number
  //   );
  //   if (!checkList) {
  //     const obj = {
  //       smart_card_number: details?.smart_card_number,
  //       amount: details?.amount,
  //       beneficiary: false,
  //       provider: details?.provider || selectedProvider,
  //       cable_plan: details?.cable_plan,
  //       phone: details?.phone,
  //     };
  //     const newList = [obj, ...details?.bulk_list];
  //     setVerifiedNum(false);
  //     setSelectedProvider("");
  //     setDetails((prev) => {
  //       return {
  //         ...prev,
  //         bulk_list: newList,
  //         smart_card_number: "",
  //         amount: "",
  //         narration: "Sent from raven",
  //         provider: "",
  //         cable_plan: "",
  //         phone: "",
  //       };
  //     });
  //   } else {
  //     toast.error("Recepient already exist in bulk list");
  //   }
  // };

  const handleRemoveFromList = (obj) => {
    if (details?.bulk_list?.length === 1) {
      setViewAll(false);
    }
    const newList = details?.bulk_list?.filter(
      (chi) => chi?.smart_card_number !== obj?.smart_card_number
    );
    setDetails((prev) => {
      return {
        ...prev,
        bulk_list: newList,
      };
    });
  };

  const handleAddBeneficiary = (obj, val) => {
    if (details?.bulk_list?.length > 0) {
      const newList = details?.bulk_list?.map((chi) =>
        chi?.smart_card_number === obj?.smart_card_number
          ? { ...chi, beneficiary: val }
          : chi
      );
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
        };
      });
    } else {
      const newObj = { ...details, beneficiary: val };
      setDetails(newObj);
    }
  };

  const getCablePlansFunc = async (param) => {
    const obj = {
      provider: param,
    };
    dispatch(getCablePlans(obj));
  };

  const handleNextStep = (param) => {
    if (param === "one") {
      setStep(2);
      // setSelectedProvider("")
    }
    if (param === "two") {
      if (user?.pin_status === 0) {
        actionCtx.setCheckShow("nine");
      }
      else{
        setShowModal((prev) => {
        return { ...prev, pin: true };
      });
      }
      // setShowModal((prev) => {
      //   return { ...prev, pin: true };
      // });
    }
  };

  const [verifiedNum, setVerifiedNum] = useState(false);
  const debouncedSearchTerm = useDebounce(details?.smart_card_number, 1000);
  useEffect(() => {
    let isMount = true;

    if (
      isMount &&
      selectedProvider &&
      debouncedSearchTerm?.length > 9 &&
      step === 1
    ) {
      validateCardNumber();
    }

    if (isMount && debouncedSearchTerm?.length < 9) {
      setVerifiedNum(false);
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const [verifiedName, setVerifiedName] = useState("");
  const validateCardNumber = async () => {
    const payload = {
      provider: selectedProvider,
      smart_card_number: details?.smart_card_number,
    };
    const data = await dispatch(validateSmartCardNo(payload));
    if (data.payload.status === "success") {
      const info = data?.payload?.data;
      // console.log(info);
      if (
        info.type === selectedProvider 
      ) {
        // console.log("eyye");
        setVerifiedName(info?.customer_name);
        setVerifiedNum(true);
      }
    }
  };

  const handleSubmit = async (pin, token) => {
    let val;
    if (details?.bulk_list.length > 0) {
      const newList = details?.bulk_list?.map((chi) => {
        return {
          ...chi,
          phone: `0${formatNumWithoutCommaNaira(chi?.phone)}`,
          amount: chi.cable_plan?.amount,
          code: chi?.cable_plan?.code,
        };
      });
      // console.log(newList);
      // return;
      const obj = {
        recipients: newList,
        user_pin: pin,
        two_factor_token: token,
        schedule: fromDate ? "later" : "now",
        schedule_date: fromDate ? fromDate : "",
        selected_account_number: actionCtx?.currentAccount?.account_number,
      };
      val = obj;
    } else {
      const obj = {
        recipients: [
          {
            provider: details?.provider || selectedProvider,
            smart_card_number: details?.smart_card_number,
            phone: `0${formatNumWithoutCommaNaira(details?.phone)}`,
            code: details?.cable_plan?.code,
            amount: details?.cable_plan?.amount,
            customer_name: verifiedName,
            selected_account_number: actionCtx?.currentAccount?.account_number,
          },
        ],

        user_pin: pin,
        two_factor_token: token,
        schedule: fromDate ? true : false,
        schedule_date: fromDate ? fromDate : "",
      };
      val = obj;
    }
    // console.log(val);
    const data = await dispatch(buyCablePlan(filterEmptyColumns(val)));
    if (data.payload.data.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    dispatch(getUser());
    dispatch(getAllAccounts());
    setSelectedProvider("");
    setVerifiedNum(false);
    setDetails({
      number: "",
      amount: "",
      narration: "Sent from raven",
      bulk_list: [],
      beneficiary: false,
      schedule: "",
      provider: "",
      cable_plan: "",
      smart_card_number: "",
      phone: "",
      code: "",
      user_pin: "",
      schedule_date: "",
    });
    setStep(1);
    setShowModal((prev) => {
      return { ...prev, success: true, pin: false };
    });
  };

  const size = useWindowSize();

  useEffect(() => {
    if (
      actionCtx?.currentAccount?.authorization_level !==
      ("initiator_approver" || "initiator") && actionCtx?.currentAccount?.authorization_level !==
      ("initiator" || "initiator_approver" )
    ) {
      navigate("/dashboard-bill-payment");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Recharge Cable TV`}
          text={`Pay for your cable Tv’s with support for all providers`}
          back={true}
          onBack={() => {
            urlBack
              ? navigate(`/${urlBack}`)
              : navigate("/dashboard-bill-payment");
          }}
        />
        {/* title box end */}
        <ContainerWrapper
          width={size?.width < 900 && `100%`}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          activeStep={step}
          stepList={stepList}
          btnText={`Complete Transaction`}
          stepClickable={
            details?.bulk_list.length > 0
              ? details?.bulk_list.length > 0
              : details?.cable_plan &&
                details?.smart_card_number &&
                details?.provider &&
                details?.phone
          }
          onStepClick={(e) => {
            details?.bulk_list.length > 0
              ? setStep(e)
              : details?.cable_plan &&
                details?.smart_card_number &&
                details?.provider &&
                details?.phone &&
                setStep(e);
          }}
          disabled={
            step === 1
              ? details?.bulk_list.length > 0
                ? details?.bulk_list.length < 1
                : !details?.cable_plan ||
                  !details?.smart_card_number ||
                  !details?.provider ||
                  !details?.phone
              : false
          }
          onBtnClick={() => {
            step === 1 && handleNextStep("one");
            step === 2 && handleNextStep("two");
          }}
        >
          <div className="bill-payment-airtime-wrap animate-move-up-class">
            {step === 1 && (
              <>
                {/* select type box start */}
                <div className="select-type-box">
                  <p className="title dull-bright-filter-two">
                    Select Cable provider
                  </p>
                  {/* select wrap start */}
                  <div className="select-wrap">
                    {providerList.map((chi, idx) => {
                      const { icon, name } = chi;
                      return (
                        <div
                          className={`child-wrap`}
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: ".8rem",
                          }}
                          key={idx}
                        >
                          <figure
                            className={`img-box select-option-class  ${
                              selectedProvider === name &&
                              "select-option-class-active"
                            }`}
                            onClick={() => {
                              if (selectedProvider !== name) {
                                setVerifiedNum(false);
                                setSelectedProvider(name);
                                setDetails((prev) => {
                                  return {
                                    ...prev,
                                    provider: name,
                                    cable_plan: "",
                                  };
                                });
                                getCablePlansFunc(name);
                              }
                            }}
                          >
                            <div className="check-box">
                              <FaCheck className="icon" />
                            </div>
                            <img src={icon} alt="" className="img" />
                          </figure>
                        </div>
                      );
                    })}
                  </div>
                  {/* select wrap end */}
                </div>
                {/* select type box end */}
                <form autoComplete="off" style={{width: "100%"}} action="">   <RavenInputField
                  color={`black-light`}
                  label="Smartcard Number*"
                  placeholder="E.g 24567898*****"
                  // labelSpanText="Select from beneficiaries"
                  labelSpanText={
                    loading ? (
                      <div
                        style={{
                          padding: "0rem",
                          display: "flex",
                          justifyContent: "flex-end",
                          transform: "translateX(1.5rem)",
                        }}
                        className="load-wrap"
                      >
                        <ThreeDots
                          height="10"
                          width="80"
                          radius="9"
                          color="#020202"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      "Select from beneficiaries"
                    )
                  }
                  labelColor={`purple-light`}
                  type={`text`}
                  name={`smart_card_number`}
                  onChange={handleChange}
                  value={details?.smart_card_number}
                /></form>
             
                {/* verify name box start */}
                <div
                  className={`verify-name-box ${
                    verifiedNum && "verify-name-box-show"
                  }`}
                >
                  <RavenCheckBox
                    checked={true}
                    color={`black-light`}
                    className={`check`}
                  />
                  <p className="text">{verifiedName}</p>
                </div>
                {/* verify name box end */}
                <form autoComplete="off" style={{width: "100%"}} action=""> <RavenInputField
                  selectStyles={reactSelectStyle}
                  color={`black-light`}
                  label="Cable plans* "
                  onActionClick={() => {
                    actionCtx?.setShowAmount(!actionCtx?.showAmount);
                  }}
                  labelSpanText={`Bal: ${
                    actionCtx?.showAmount
                      ? formatNumWithCommaNairaSymbol(actionCtx?.currentAccount?.balance)
                      : "₦ ••••••"
                  }`}
                  labelColor={`black-light`}
                  labelClassName={`label-span-amount label-span-theme`}
                  type={`select`}
                  placeholder={
                    selectedProvider
                      ? "Select cable plan"
                      : "Select provider first"
                  }
                  className={`select-data-plan`}
                  name={`cable_plan`}
                  style={{ position: "relative", zIndex: "20" }}
                  onChange={(e) => {
                    console.log(e);
                    setDetails((prev) => {
                      return { ...prev, cable_plan: e };
                    });
                  }}
                  value={details?.cable_plan}
                  selectValue={details?.cable_plan}
                  disabled={!selectedProvider || !details?.provider}
                  selectOption={formatCableList(cablePlans)}
                /></form>
               
               <form autoComplete="off" action="">
               <RavenInputField
                  color={`black-light`}
                  label="Phone Number* "
                  labelColor={`black-light`}
                  type={`phone`}
                  placeholder="E.g 081 626 ..."
                  name={`phone`}
                  onChange={handleChange}
                  value={
                    details?.phone.slice(0, 3) === "234"
                      ? details?.phone.replace("234", "0")
                      : details?.phone.slice(0, 1) === "0"
                      ? details?.phone.replace("0", "")
                      : details?.phone
                  }
                />
               </form>
                {/* add another text start */}
                {/* <div
                  onClick={() => {
                    selectedProvider &&
                      details?.cable_plan &&
                      details?.smart_card_number &&
                      details?.phone &&
                      handleBulkTransfer();
                  }}
                  className={`add-another-box ${
                    selectedProvider &&
                    details?.cable_plan &&
                    details?.smart_card_number &&
                    details?.phone &&
                    "add-another-box-active"
                  }`}
                >
                  <figure className="img-box">
                    <img src={addIcon} alt="" className="img" />
                  </figure>
                  <p className="text">Recharge for another recipient.</p>
                </div> */}
                {/* add another text end */}
                {/* recepient box start */}
                <div
                  className={`recepient-box ${
                    details?.bulk_list?.length > 0 && "recepient-box-show"
                  }`}
                >
                  <div className="text-line">
                    <span></span>
                    <p className="text">Recipients</p>
                    <span></span>
                  </div>
                  <div
                    style={{ zIndex: viewAll && "30" }}
                    className="view-total-box"
                  >
                    <p className="total">{`Total added (${details?.bulk_list?.length})`}</p>
                    <div className="view-wrap">
                      {/* view all box start */}
                      <div
                        className={`view-all-box card-bg ${
                          viewAll && "view-all-box-show"
                        }`}
                      >
                        <div className="wrap">
                          {details?.bulk_list?.map((chi, idx) => {
                            return (
                              <div key={idx} className="child-wrap">
                                <figure className="img-box">
                                  <img
                                    src={
                                      chi?.provider === "dstv"
                                        ? dstvICon
                                        : chi?.provider === "gotv"
                                        ? gotvICon
                                        : chi?.provider === "showmax"
                                        ? showmaxICon
                                        : startimeICon
                                    }
                                    alt=""
                                    className="img"
                                  />
                                </figure>
                                <div className="text-box">
                                  <p className="name">
                                    {chi?.smart_card_number.length > 10
                                      ? `${chi?.smart_card_number.slice(
                                          0,
                                          10
                                        )} ...`
                                      : chi?.smart_card_number}
                                  </p>
                                  <p className="text grey-white-color">{` ${chi?.cable_plan?.label}`}</p>
                                </div>
                                <div
                                  onClick={() => {
                                    handleRemoveFromList(chi);
                                  }}
                                  className="cancel-box"
                                >
                                  <FaTimes className="icon" />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* view all box end */}
                      {viewAll ? (
                        <p
                          onClick={() => {
                            setViewAll(false);
                            details?.bulk_list?.length === 1 &&
                              setViewAll(false);
                          }}
                          className="text"
                        >
                          Close
                        </p>
                      ) : (
                        <p
                          onClick={() => {
                            setViewAll(true);
                          }}
                          className="text"
                        >
                          View all
                        </p>
                      )}
                    </div>
                  </div>
                  {/* end of view total box */}
                  {/* preview list start */}
                  {!viewAll && (
                    <div className="preview-box">
                      {details?.bulk_list?.slice(0, 2).map((chi, idx) => {
                        const { smart_card_number, cable_plan, provider } = chi;
                        return (
                          <div className="box box-card " key={idx}>
                            <div
                              onClick={() => {
                                handleRemoveFromList(chi);
                              }}
                              className="cancel-box"
                            >
                              <FaTimes className="icon" />
                            </div>
                            <figure className="img-box">
                              <img
                                src={
                                  provider === "dstv"
                                    ? dstvICon
                                    : provider === "gotv"
                                    ? gotvICon
                                    : provider === "consat"
                                    ? showmaxICon
                                    : startimeICon
                                }
                                alt=""
                                className="img"
                              />
                            </figure>
                            <p className="name">
                              {" "}
                              {smart_card_number.length > 10
                                ? `${smart_card_number.slice(0, 10)} ...`
                                : smart_card_number}
                            </p>
                            <p className="text grey-white-color">{` ${cable_plan?.label}`}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* preview list end */}
                  <div className="space-bottom"></div>
                </div>
                {/* recepient box end */}
              </>
            )}
            {step === 2 && (
              <>
                {/* select schedule start */}
                {/* <SelectScheduleBox
                  id={`onaklupd`}
                  value={fromDate}
                  onChange={(e) => setFromDate(e)}
                  onReset={() => setFromDate("")}
                /> */}
                {/* select schedule end */}
                {details?.bulk_list?.length > 0 ? (
                  <>
                    {details?.bulk_list?.length === 1 ? (
                      <>
                        {details?.bulk_list?.map((chi, idx) => {
                          const {
                            smart_card_number,
                            beneficiary,
                            provider,
                            cable_plan,
                            phone,
                          } = chi;
                          return (
                            <PreviewCableDetailsBox
                              key={idx}
                              value={beneficiary}
                              onChange={(e) => {
                                handleAddBeneficiary(chi, e.target.checked);
                              }}
                              phone={phone}
                              provider={provider}
                              number={smart_card_number}
                              narration={cable_plan?.label}
                              // collapse
                              amount={cable_plan?.amount}
                              checked={beneficiary}
                              tipposition={`bottom-left`}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {details?.bulk_list?.map((chi, idx) => {
                          const {
                            // name,
                            smart_card_number,
                            beneficiary,
                            provider,
                            cable_plan,
                            phone,
                          } = chi;
                          return (
                            <PreviewCableDetailsBox
                              previewOpen={previewOpen}
                              setPreviewOpen={setPreviewOpen}
                              key={idx}
                              id={idx}
                              value={beneficiary}
                              onChange={(e) => {
                                handleAddBeneficiary(chi, e.target.checked);
                              }}
                              phone={phone}
                              provider={provider}
                              number={smart_card_number}
                              narration={cable_plan?.label}
                              collapse
                              amount={cable_plan?.amount}
                              checked={beneficiary}
                              tipposition={
                                details?.bulk_list?.length === idx + 1
                                  ? "bottom-left"
                                  : `top-left`
                              }
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <PreviewCableDetailsBox
                      value={details?.beneficiary}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, beneficiary: e.target.checked };
                        });
                      }}
                      // cable_plan={details?.cable_plan?.name}
                      provider={details?.provider || selectedProvider}
                      number={details?.smart_card_number}
                      narration={details?.cable_plan?.name}
                      amount={details?.cable_plan?.amount}
                      checked={details?.beneficiary}
                      tipposition={`bottom-left`}
                      phone={details?.phone}
                      name={verifiedName}
                    /> */}
                    <NewPreviewDetailBox
                      onReset={() => setFromDate("")}
                      valueSchedule={fromDate}
                      onChangeSchedule={(e) => {
                        setFromDate(e);
                      }}
                      value={details?.beneficiary}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, beneficiary: e.target.checked };
                        });
                      }}
                      checked={details?.beneficiary}
                      previewOpen={previewOpen}
                      setPreviewOpen={setPreviewOpen}
                      provider={details?.provider || selectedProvider}
                      number={details?.smart_card_number}
                      phone={details?.phone}
                      name={verifiedName}
                      narration={details?.cable_plan?.name}
                      amount={details?.cable_plan?.amount}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, user_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Cable Purchase Successful."
        text={` You should receive a confirmation message with the details of your purchase shortly.  We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default DashboardBillPaymentCable;
