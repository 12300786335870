import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { freezeAndUnfreezeCard, UnfreezeCard } from "../../../../redux/card";
import { lowerCaseWrap } from "../../../../utils/helper/Helper";
import ActionContext from "../../../../context/ActionContext";
import { useLocation } from "react-router-dom";

const FeezeCardModal = ({ visible, onCancel, onFinish, value }) => {
  // const { loading } = useSelector((state) => state.cards);
  const { card } = useSelector((state) => state.cards);
  const dispatch = useDispatch();
  const location = useLocation();
  const actionCtx = useContext(ActionContext);
  const [loadingFreeze, setLoadingFreeze] = useState(false);

  const handleDelete = async () => {
    const obj = {
      card_id: String(card?.id),
    };
    setLoadingFreeze(true);
    if (card?.main_card_object?.status !== "active") {
      // console.log(card);
      // return
      const data = await dispatch(UnfreezeCard(obj));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        onFinish();
        setLoadingFreeze(false);
        actionCtx.setUnfreezeCard(false);
      } else {
        setLoadingFreeze(false);
      }
    } else {
      //   console.log(card);
      // return
      const data = await dispatch(freezeAndUnfreezeCard(obj));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        onFinish();
        setLoadingFreeze(false);
        actionCtx.setUnfreezeCard(false);
      } else {
        setLoadingFreeze(false);
      }
    }
  };

  return (
    <DeleteModal
      loading={loadingFreeze}
      visible={
        actionCtx?.unfreezeCard &&
        location?.pathname?.includes("dashboard-card")
      }
      deleteText={
        card?.main_card_object?.status !== "active"
          ? `Activate card`
          : `Freeze Card`
      }
      onCancel={onCancel}
      onDelete={handleDelete}
      buttonColor={
        card?.main_card_object?.status !== "active" && "black-light"
      }
      title={`${
        card?.main_card_object?.status === "active" ? "Freeze" : "Activate"
      } this card (${lowerCaseWrap(card?.card_title)})`}
      text={
        card?.main_card_object?.status === "active"
          ? `Activating this card would render the card active during the duration it’s active and payment can be processed. and can be used on any website or application`
          : `Frezzing this card would render the card obsolete during the duration it’s freeze and payment cannot be processed. and can’t be used on any website or application`
      }
    />
  );
};

export default FeezeCardModal;
