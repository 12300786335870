import React from "react";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";

const InitiatePayrollModal = ({ visible, onClose }) => {
  return (
    <TransactionPinModal
      visible={visible}
      onCancel={onClose}
      title={`Initiate Payroll Payment`}
    />
  );
};

export default InitiatePayrollModal;
