import {
  // RavenDateTime,
  RavenPagination,
  RavenTable,
  RavenTableRow,
  // RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import NoContentBox from "../../../components/reusables/NoContentBox";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/card/DashboardCard.css";
// import blackImg from "../../../assets/raven-black-card.png";
import freezeIcon from "../../../assets/freeze-icon.svg";
import plusIcon from "../../../assets/plus-icon.svg";
import minusIcon from "../../../assets/minus-icon.svg";
import { FaCheck } from "react-icons/fa";
import TopUpCardModal from "./modals/TopUPCardModal";
import WithdrawCardModal from "./modals/WidrawCardModal";
import FeezeCardModal from "./modals/FeezeCardModal";
import noContentCard from "../../../assets/no-content-card-img (1).png";
import NocontentPage from "../../../components/reusables/NocontentPage";
import { useDispatch } from "react-redux";
import {
  getCardConfig,
  getCardTransactions,
  getVirtualCard,
  getVirtualCards,
} from "../../../redux/card";
import { useSelector } from "react-redux";
import {
  // formatDate,
  // formatMetaData,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  getTotalPage,
  lowerCaseWrap,
  trimLongString,
} from "../../../utils/helper/Helper";
// import env from "../../../env";
import ActionContext from "../../../context/ActionContext";
import env from "../../../env";
import Skeleton from "react-loading-skeleton";
import DateFragmentBox from "../../../components/fragments/DateFragmentBox";
// import IntercomPopup from "../../../components/reusables/IntercomPopup";

const DashboardcardIndex = () => {
  // const [showIntercom, setShowIntercom] = useState(false);
  const actionCtx = useContext(ActionContext);
  const { user_resource_and_permissions } = useSelector((state) => state.user);
  const { cards, loading, loadingSingle, card_transactions, card, config } =
    useSelector((state) => state.cards);
  const dispatch = useDispatch();
  const [copyItem, setCopyItem] = useState("");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({
    topUp: false,
    withdraw: false,
    freeze: false,
  });
  // const [refreshContent, setRefreshContent] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [preview, setPreview] = useState("");
  const [selectedView, setSelectedView] = useState("Card Information");
  const headerList = ["TITLE", "TYPE", `BALANCE ($)`];

  const selectViewList = ["Card Information", "Transactions"];

  const handleCopy = async (param, num) => {
    setCopyItem(param);
    await navigator.clipboard.writeText(num);
    setTimeout(() => {
      setCopyItem("");
    }, 1000);
  };

  // const handleOpenIntercom = () => {
  //   setShowIntercom(true);
  //   // console.log("kjhghj");
  // };
  // const noCards = [];
  const formatTypeWrap = (param) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "Virtual" && "status-type-wrap-virtual"
        }`}
      >
        <span>{lowerCaseWrap(param)}</span>
      </div>
    );
  };

  const formatTypeWrapTwo = (param) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "Processing" && "status-type-wrap-virtual"
        } ${
          param === "inactive" &&
          "status-type-wrap-disabled status-type-wrap-disabled-box"
        } ${param === "active" && "status-type-wrap-active"}`}
      >
        <span>{lowerCaseWrap(param)}</span>
      </div>
    );
  };

  const noContentList = [
    "Virtual cards offer control and flexibility in spending limits & vendor restrictions.",
    "We provide a secure and convenient way to make payments online, reducing the risk of fraud.",
    "Easily track and monitor expenses in real-time, improving transparency and accountability.",
  ];

  useEffect(() => {
    dispatch(getVirtualCards());
    dispatch(getCardConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCardTransactionsFunc = async (param, num) => {
    const obj = {
      id: String(param) || String(preview?.id),
      per_page: actionCtx?.perPageVal?.value,
      current_page: num || 1,
    };
    // console.log(obj);
    // return
    dispatch(getVirtualCard(obj));
    dispatch(getCardTransactions(obj));
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return {
        ...prev,
        topUp: false,
        withdraw: false,
        freeze: false,
      };
    });
    getCardTransactionsFunc(card?.id);
  };

  if (
    !user_resource_and_permissions?.allowed_permissions?.includes(
      "can_view_virtual_cards"
    )
  ) {
    return (
      <DashboardLayout>
        <TitletextBox
          title={`Cards`}
          text={`All created invoices in one section`}
        />
        {/* title box end */}
        <ContainerWrapper dontShowHeader>
          <NoContentBox
            title={`No permission to view this page`}
            text={`You will be able to view this screen when you have pemission to view cards`}
            bgColor
            loading={loading}
          />
        </ContainerWrapper>
      </DashboardLayout>
    );
  }

  return (
    <>
      <DashboardLayout>
        {cards?.length > 0 ? (
          <>
            <TitletextBox
              title={`Cards`}
              text={`All created invoices in one section`}
              addBtn={user_resource_and_permissions?.allowed_permissions?.includes(
                "can_create_virtual_cards"
              )}
              toolTipMsg={`Create a card`}
              onAdd={() => {
                user_resource_and_permissions?.allowed_permissions?.includes(
                  "can_create_virtual_cards"
                ) && navigate("/dashboard-card-create");
              }}
            />
            <ContainerWrapper width={`100%`} dontShowHeader>
              {cards?.length > 0 ? (
                <>
                  <div className="dashboard-card-main-wrap-box">
                    {/* left- box start */}
                    <div className="left-box">
                      <RavenTable headerList={headerList}>
                        {cards?.map((chi, idx) => {
                          const {
                            balance,
                            card_title,
                            // type,
                            id,
                            card_type,
                            currency,
                            main_card_object,
                          } = chi;
                          return (
                            <RavenTableRow
                              onRowClick={() => {
                                getCardTransactionsFunc(id);
                                // console.log(chi);
                                // setPreview(chi);
                              }}
                              key={idx}
                              one={
                                lowerCaseWrap(
                                  main_card_object?.card_name || card_title
                                ) || "---"
                              }
                              three={
                                currency?.toLowerCase() === "usd"
                                  ? `${formatNumWithCommaNairaSymbol(
                                      main_card_object?.balance || balance
                                    )?.replace("₦", "$")}  
                                    (${formatNumWithCommaNairaSymbol(
                                      Number(
                                        main_card_object?.balance || balance
                                      ) * config?.usd_rate
                                    )})
                                    `
                                  : formatNumWithCommaNairaSymbol(
                                      main_card_object?.balance || balance
                                    ) || "---"
                              }
                              two={formatTypeWrap(card_type) || "---"}
                              // four={formatNumWithCommaNairaSymbol(
                              //   Number(main_card_object?.balance || balance) *
                              //     config?.usd_rate
                              // )}
                              // five={formatTypeWrapTwo(chi?.status)}
                              className={
                                card?.id === id && `table-row-selected`
                              }
                              loading={loading}
                            />
                          );
                        })}
                      </RavenTable>
                      {/* pagination start */}
                      {/* <div className="table-pagination-box">
                    <RavenPagination
                      color={`black-light`}
                      blackHover
                      currentPage={1}
                      totalPage={2}
                    />
                  </div> */}
                      {/* pagination end */}
                    </div>
                    {/* left- box end */}
                    {/* right- box start */}
                    <div className="right-box">
                      {!loadingSingle &&
                      card &&
                      Object?.keys(card)?.length > 0 ? (
                        <>
                          {/* card action description box start */}
                          <div className="card-action-description-box grey-bg animate-move-up-class">
                            {/* left box start */}
                            <div className="left-view-box">
                              {loading ? (
                                <>
                                  <Skeleton
                                    width={85}
                                    height={135}
                                    borderRadius={`1.2rem`}
                                  />
                                </>
                              ) : (
                                <figure className="img-box">
                                  <img
                                    src={`${env.base_url}/files/card_designs/${card?.card_design}`}
                                    alt=""
                                    className="img"
                                  />
                                </figure>
                              )}
                            </div>
                            {/* left box end */}
                            {/* right box start */}
                            <div
                              onClick={() => {
                                // console.log(cards);
                              }}
                              className="right-view-box"
                            >
                              <p className="label grey-white-color">
                                CARD NAME
                              </p>
                              {loading ? (
                                <>
                                  <Skeleton
                                    style={{ margin: "1rem 0rem" }}
                                    width={130}
                                    height={15}
                                  />
                                </>
                              ) : (
                                <p className="value">
                                  {lowerCaseWrap(card?.card_title) || "---"}
                                </p>
                              )}
                              <p className="label label-two grey-white-color">
                                Balance:{" "}
                                {loading ? (
                                  <>
                                    <Skeleton width={70} height={15} />
                                  </>
                                ) : (
                                  <span className="black-white-color">
                                    {card?.currency?.toLowerCase() === "usd"
                                      ? formatNumWithCommaNairaSymbol(
                                          card?.main_card_object?.balance
                                        )?.replace("₦", "$")
                                      : formatNumWithCommaNairaSymbol(
                                          card?.main_card_object?.balance
                                        )}
                                  </span>
                                )}
                              </p>
                              <>
                                {formatTypeWrapTwo(
                                  card?.main_card_object?.status
                                )}
                              </>

                              <div className="action-wrap">
                                {card?.main_card_object?.status ===
                                  "active" && (
                                  <>
                                    {" "}
                                    {/* box start */}
                                    {user_resource_and_permissions?.allowed_permissions?.includes(
                                      "can_fund_virtual_cards"
                                    ) && (
                                      <div
                                        onClick={() => {
                                          !loading &&
                                            setShowModal((prev) => {
                                              return { ...prev, topUp: true };
                                            });
                                        }}
                                        className="box"
                                      >
                                        {loading ? (
                                          <>
                                            <Skeleton
                                              width={30}
                                              height={30}
                                              circle
                                            />
                                          </>
                                        ) : (
                                          <div className="icon-box">
                                            <figure className="img-box">
                                              <img
                                                src={plusIcon}
                                                alt=""
                                                className="img"
                                              />
                                            </figure>
                                          </div>
                                        )}
                                        {loading ? (
                                          <>
                                            <Skeleton width={60} height={17} />
                                          </>
                                        ) : (
                                          <p className="text">Top up</p>
                                        )}
                                      </div>
                                    )}
                                    {/* box end */}
                                    {/* box start */}
                                    {user_resource_and_permissions?.allowed_permissions?.includes(
                                      "can_withdraw_from_virtual_cards"
                                    ) && (
                                      <div
                                        onClick={() => {
                                          !loading &&
                                            setShowModal((prev) => {
                                              return {
                                                ...prev,
                                                withdraw: true,
                                              };
                                            });
                                        }}
                                        className="box"
                                      >
                                        {loading ? (
                                          <>
                                            <Skeleton
                                              width={30}
                                              height={30}
                                              circle
                                            />
                                          </>
                                        ) : (
                                          <div className="icon-box">
                                            <figure className="img-box">
                                              <img
                                                src={minusIcon}
                                                alt=""
                                                className="img"
                                              />
                                            </figure>
                                          </div>
                                        )}
                                        {loading ? (
                                          <>
                                            <Skeleton width={60} height={17} />
                                          </>
                                        ) : (
                                          <p className="text">Withdraw</p>
                                        )}
                                      </div>
                                    )}
                                    {/* box end */}
                                  </>
                                )}

                                {/* box start */}
                                {user_resource_and_permissions?.allowed_permissions?.includes(
                                  "can_freeze_and_unfreeze_virtual_cards"
                                ) && (
                                  <div
                                    onClick={() => {
                                      !loading &&
                                        actionCtx.setUnfreezeCard(true);
                                    }}
                                    className="box"
                                  >
                                    {loading ? (
                                      <>
                                        <Skeleton
                                          width={30}
                                          height={30}
                                          circle
                                        />
                                      </>
                                    ) : (
                                      <div className="icon-box">
                                        <figure className="img-box freeze">
                                          <img
                                            src={freezeIcon}
                                            alt=""
                                            className="img"
                                          />
                                        </figure>
                                      </div>
                                    )}
                                    {loading ? (
                                      <>
                                        <Skeleton width={60} height={17} />
                                      </>
                                    ) : (
                                      <p className="text">
                                        {card?.main_card_object?.status ===
                                        "active"
                                          ? "Freeze"
                                          : "Unfreeze"}
                                      </p>
                                    )}
                                  </div>
                                )}
                                {/* box end */}
                              </div>
                            </div>
                            {/* right box end */}
                          </div>
                          {/* card action description box end */}
                          {/* select view wrap start */}
                          <div className="select-view-wrap active-border-color animate-move-up-class">
                            {selectViewList.map((chi, idx) => {
                              return (
                                <p
                                  className={`option ${
                                    chi === selectedView &&
                                    "option-active black-white-color"
                                  }`}
                                  key={idx}
                                  onClick={() => {
                                    selectedView !== chi &&
                                      setSelectedView(chi);
                                  }}
                                >
                                  <span className="grey-white-color">
                                    {chi}
                                  </span>
                                </p>
                              );
                            })}
                          </div>
                          {/* select view wrap end */}
                          {/* information transaction box start */}
                          {loadingSingle ? (
                            <>
                              {" "}
                              <NoContentBox
                                text={`Click on a card to view card details`}
                                title={`No Card Selected.`}
                                loading={loadingSingle}
                              />
                            </>
                          ) : (
                            <div className="information-transaction-box animate-move-up-class">
                              {/* information box start */}
                              <div className="information-wrap">
                                <div className="info-wrap">
                                  {/* box start */}
                                  <div className="box active-border-color">
                                    <div className="label-value-box">
                                      <p className="label grey-white-color">
                                        CARD NUMBER
                                      </p>
                                      {loading ? (
                                        <>
                                          <Skeleton width={120} height={17} />
                                        </>
                                      ) : (
                                        <p className="value">
                                          {card?.main_card_object
                                            ?.card_number || "---"}
                                        </p>
                                      )}
                                    </div>
                                    {loading ? (
                                      <>
                                        <Skeleton
                                          width={49}
                                          height={23}
                                          borderRadius={"2rem"}
                                        />
                                      </>
                                    ) : (
                                      <div
                                        onClick={() =>
                                          handleCopy(
                                            "card-number",
                                            card?.main_card_object?.card_number
                                          )
                                        }
                                        className={`copy-box grey-bg ${
                                          copyItem === "card-number" &&
                                          "copy-box-active"
                                        } `}
                                      >
                                        {copyItem === "card-number" ? (
                                          <>
                                            <span>
                                              <FaCheck className="icon" />
                                            </span>
                                            <span>COPIED</span>{" "}
                                          </>
                                        ) : (
                                          <span>COPY</span>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {/* box end */}
                                  {/* box start */}
                                  <div className="box active-border-color">
                                    <div className="label-value-box">
                                      <p className="label grey-white-color">
                                        CARD NAME
                                      </p>
                                      {loading ? (
                                        <>
                                          <Skeleton width={120} height={17} />
                                        </>
                                      ) : (
                                        <p className="value">
                                          {card?.main_card_object?.card_name ||
                                            "---"}
                                        </p>
                                      )}
                                    </div>
                                    {loading ? (
                                      <>
                                        <Skeleton
                                          width={49}
                                          height={23}
                                          borderRadius={"2rem"}
                                        />
                                      </>
                                    ) : (
                                      <div
                                        onClick={() =>
                                          handleCopy(
                                            "card-name",
                                            card?.main_card_object?.card_name
                                          )
                                        }
                                        className={`copy-box grey-bg ${
                                          copyItem === "card-name" &&
                                          "copy-box-active"
                                        } `}
                                      >
                                        {copyItem === "card-name" ? (
                                          <>
                                            <span>
                                              <FaCheck className="icon" />
                                            </span>
                                            <span>COPIED</span>{" "}
                                          </>
                                        ) : (
                                          <span>COPY</span>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {/* box end */}
                                  {/* box start */}
                                  <div className="box active-border-color">
                                    <div className="label-value-box">
                                      <p className="label grey-white-color">
                                        EXPIRY DATE
                                      </p>
                                      {loading ? (
                                        <>
                                          <Skeleton width={120} height={17} />
                                        </>
                                      ) : (
                                        <p className="value">{`${
                                          Number(
                                            card?.main_card_object?.expiry_month
                                          ) < 10
                                            ? `0${card?.main_card_object?.expiry_month}`
                                            : card?.main_card_object
                                                ?.expiry_month || " "
                                        } / ${
                                          card?.main_card_object?.expiry_year?.slice(
                                            2,
                                            4
                                          ) || " "
                                        }`}</p>
                                      )}
                                    </div>
                                    {loading ? (
                                      <>
                                        <Skeleton
                                          width={49}
                                          height={23}
                                          borderRadius={"2rem"}
                                        />
                                      </>
                                    ) : (
                                      <div
                                        onClick={() =>
                                          handleCopy(
                                            "expiry-date",
                                            `${card?.main_card_object?.expiry_month}/${card?.main_card_object?.expiry_year}`
                                          )
                                        }
                                        className={`copy-box grey-bg ${
                                          copyItem === "expiry-date" &&
                                          "copy-box-active"
                                        } `}
                                      >
                                        {copyItem === "expiry-date" ? (
                                          <>
                                            <span>
                                              <FaCheck className="icon" />
                                            </span>
                                            <span>COPIED</span>{" "}
                                          </>
                                        ) : (
                                          <span>COPY</span>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {/* box end */}
                                  {/* box start */}
                                  <div className="box active-border-color">
                                    <div className="label-value-box">
                                      <p className="label grey-white-color">
                                        CVC
                                      </p>
                                      {loading ? (
                                        <>
                                          <Skeleton width={120} height={17} />
                                        </>
                                      ) : (
                                        <p className="value">
                                          {card?.main_card_object?.cvv || "---"}
                                        </p>
                                      )}
                                    </div>
                                    {loading ? (
                                      <>
                                        <Skeleton
                                          width={49}
                                          height={23}
                                          borderRadius={"2rem"}
                                        />
                                      </>
                                    ) : (
                                      <div
                                        onClick={() =>
                                          handleCopy(
                                            "card-cvc",
                                            card?.main_card_object?.cvv
                                          )
                                        }
                                        className={`copy-box grey-bg ${
                                          copyItem === "card-cvc" &&
                                          "copy-box-active"
                                        } `}
                                      >
                                        {copyItem === "card-cvc" ? (
                                          <>
                                            <span>
                                              <FaCheck className="icon" />
                                            </span>
                                            <span>COPIED</span>{" "}
                                          </>
                                        ) : (
                                          <span>COPY</span>
                                        )}{" "}
                                      </div>
                                    )}
                                  </div>
                                  {/* box end */}
                                  {/* box start */}
                                  <div className="box active-border-color">
                                    <div className="label-value-box">
                                      <p className="label grey-white-color">
                                        BILLING ADDRESS
                                      </p>
                                      {loading ? (
                                        <>
                                          <Skeleton width={120} height={17} />
                                        </>
                                      ) : (
                                        <div className="value tooltip-hover-wrap">
                                          {card?.main_card_object
                                            ?.billing_address?.street?.length +
                                            card?.main_card_object
                                              ?.billing_address?.city?.length +
                                            card?.main_card_object
                                              ?.billing_address?.country
                                              ?.length >
                                          25
                                            ? trimLongString(
                                                card?.main_card_object
                                                  ?.billing_address?.street,
                                                25
                                              )
                                            : `${card?.main_card_object?.billing_address?.street} ${card?.main_card_object?.billing_address?.city} ${card?.main_card_object?.billing_address?.country}`}
                                          {/* {card?.main_card_object
                                            ?.billing_address?.street?.length +
                                            card?.main_card_object
                                              ?.billing_address?.city?.length +
                                            card?.main_card_object
                                              ?.billing_address?.country
                                              ?.length >
                                            25 && (
                                            <RavenToolTip
                                              color="black-light"
                                              position={`top-center`}
                                              text={`${card?.main_card_object?.billing_address?.street} ${card?.main_card_object?.billing_address?.city} ${card?.main_card_object?.billing_address?.country}`}
                                            />
                                          )} */}
                                        </div>
                                      )}
                                    </div>
                                    {loading ? (
                                      <>
                                        <Skeleton
                                          width={49}
                                          height={23}
                                          borderRadius={"2rem"}
                                        />
                                      </>
                                    ) : (
                                      <div
                                        onClick={() =>
                                          handleCopy(
                                            "address",
                                            `${card?.main_card_object?.billing_address?.street} ${card?.main_card_object?.billing_address?.city} ${card?.main_card_object?.billing_address?.country}`
                                          )
                                        }
                                        className={`copy-box grey-bg ${
                                          copyItem === "address" &&
                                          "copy-box-active"
                                        } `}
                                      >
                                        {copyItem === "address" ? (
                                          <>
                                            <span>
                                              <FaCheck className="icon" />
                                            </span>
                                            <span>COPIED</span>{" "}
                                          </>
                                        ) : (
                                          <span>COPY</span>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {/* box end */}
                                  {/* box start */}
                                  <div className="box active-border-color">
                                    <div className="label-value-box">
                                      <p className="label grey-white-color">
                                        POSTAL CODE
                                      </p>
                                      {loading ? (
                                        <>
                                          <Skeleton width={120} height={17} />
                                        </>
                                      ) : (
                                        <p className="value">
                                          {card?.main_card_object
                                            ?.billing_address?.postal_code ||
                                            "---"}
                                        </p>
                                      )}
                                    </div>
                                    {loading ? (
                                      <>
                                        <Skeleton
                                          width={49}
                                          height={23}
                                          borderRadius={"2rem"}
                                        />
                                      </>
                                    ) : (
                                      <div
                                        onClick={() =>
                                          handleCopy(
                                            "card-zip",
                                            card?.main_card_object
                                              ?.billing_address?.postal_code
                                          )
                                        }
                                        className={`copy-box grey-bg ${
                                          copyItem === "card-zip" &&
                                          "copy-box-active"
                                        } `}
                                      >
                                        {copyItem === "card-zip" ? (
                                          <>
                                            <span>
                                              <FaCheck className="icon" />
                                            </span>
                                            <span>COPIED</span>{" "}
                                          </>
                                        ) : (
                                          <span>COPY</span>
                                        )}{" "}
                                      </div>
                                    )}
                                  </div>
                                  {/* box end */}
                                </div>
                              </div>
                              {/* information box end */}
                              {/* transaction box start */}
                              <div
                                className={`transaction-wrap card-bg box-toggle-theme-active ${
                                  selectedView === "Transactions" &&
                                  "transaction-wrap-show"
                                }`}
                                style={{ border: "none" }}
                              >
                                {!loadingSingle ||
                                card_transactions?.items?.length > 0 ? (
                                  <div className="transact-box">
                                    {card_transactions?.items?.map(
                                      (chi, idx) => {
                                        const {
                                          amount,
                                          // created_at,
                                          name,
                                          direction,
                                          type,
                                          currency,
                                        } = chi;
                                        return (
                                          <div
                                            key={idx}
                                            className={`child-row active-border-color ${
                                              idx !==
                                                card_transactions?.items
                                                  ?.length -
                                                  1 && "border-theme-bottom"
                                            } border-theme-bottom`}
                                          >
                                            <div className="img-text-box">
                                              {loading ? (
                                                <>
                                                  <Skeleton
                                                    width={32}
                                                    height={32}
                                                    circle
                                                    style={{
                                                      marginRight: ".7rem",
                                                    }}
                                                  />
                                                </>
                                              ) : (
                                                <figure
                                                  className={`img-box ${
                                                    direction === "debit" &&
                                                    "img-box-debit"
                                                  } img-box-debit-credit img-box-cre-deb`}
                                                >
                                                  {direction === "credit" ? (
                                                    <svg
                                                      width="10"
                                                      height="10"
                                                      viewBox="0 0 10 10"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="img"
                                                    >
                                                      <path
                                                        d="M9 1L1 9M1 9H6.33333M1 9V3.66667"
                                                        stroke="#1ACE37"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  ) : (
                                                    <svg
                                                      width="10"
                                                      height="10"
                                                      viewBox="0 0 10 10"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="img"
                                                    >
                                                      <path
                                                        d="M1 9L9 1M9 1H3.66667M9 1V6.33333"
                                                        stroke="#FF0F00"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  )}
                                                </figure>
                                              )}
                                              <div className="text-box">
                                                {loading ? (
                                                  <>
                                                    <Skeleton
                                                      width={100}
                                                      height={17}
                                                    />
                                                  </>
                                                ) : (
                                                  <p className="name">
                                                    {name ||
                                                      formatTypeFunction(
                                                        type ||
                                                          trimLongString(
                                                            chi?.narration,
                                                            35
                                                          )
                                                      ) ||
                                                      "---"}
                                                  </p>
                                                )}
                                                {loading ? (
                                                  <>
                                                    <Skeleton
                                                      width={170}
                                                      height={12}
                                                    />
                                                  </>
                                                ) : (
                                                  <div className="date grey-white-color">
                                                    {/* {formatDate(chi?.trx_date)} */}
                                                    <DateFragmentBox
                                                      date={chi?.trx_date}
                                                      dontShowAgo={true}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            {loading ? (
                                              <>
                                                <Skeleton
                                                  width={40}
                                                  height={17}
                                                />
                                              </>
                                            ) : (
                                              <p
                                                className={`amount ${
                                                  direction === "debit" &&
                                                  "amount-debit"
                                                }`}
                                              >
                                                {currency?.toLowerCase() ===
                                                "usd"
                                                  ? formatNumWithCommaNairaSymbol(
                                                      amount
                                                    )?.replace("₦", "$")
                                                  : formatNumWithCommaNairaSymbol(
                                                      amount
                                                    )}
                                              </p>
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    <NoContentBox
                                      title={`No transaction yet`}
                                      text={`No transaction found for this card`}
                                      loading={loadingSingle}
                                    />
                                  </>
                                )}
                                {/* pagination start */}
                                <div className="table-pagination-box">
                                  {card_transactions?.previousPage ||
                                  card_transactions?.nextPage ? (
                                    <RavenPagination
                                      color={`black-light`}
                                      blackHover
                                      currentPage={
                                        Number(
                                          card_transactions?.currentPage
                                        ) || 1
                                      }
                                      nextPage={card_transactions?.nextPage}
                                      prevPage={card_transactions?.previousPage}
                                      onNext={(e) => {
                                        getCardTransactionsFunc(
                                          card?.id,
                                          Number(
                                            card_transactions?.currentPage
                                          ) + 1
                                        );
                                      }}
                                      onPrev={(e) => {
                                        getCardTransactionsFunc(
                                          card?.id,
                                          Number(
                                            card_transactions?.currentPage
                                          ) - 1
                                        );
                                      }}
                                      totalPage={
                                        getTotalPage(
                                          actionCtx?.perPageVal?.value,
                                          card_transactions?.total
                                        ) || 1
                                      }
                                      onNumView={(e) => {
                                        getCardTransactionsFunc(card?.id, e);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {/* pagination end */}
                              </div>
                              {/* transaction box end */}
                            </div>
                          )}
                          {/* information transaction box end */}
                        </>
                      ) : (
                        <NoContentBox
                          text={`Click on a card to view card details`}
                          title={`No Card Selected.`}
                          loading={loadingSingle}
                        />
                      )}
                    </div>
                    {/* right- box end */}
                  </div>
                </>
              ) : (
                <NoContentBox
                  text={`Click the button below to get started`}
                  title={`No Card created yet.`}
                  btnText={
                    user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_create_virtual_cards"
                    ) && `Create Card`
                  }
                  onBtnClick={() => {
                    // console.log("jcvbnm");
                    user_resource_and_permissions?.allowed_permissions?.includes(
                      "can_create_virtual_cards"
                    ) && navigate("/dashboard-card-create");
                  }}
                />
              )}
            </ContainerWrapper>
          </>
        ) : (
          <NocontentPage
            loading={loading}
            img={noContentCard}
            title={`Spend without limit, with your Raven card simple global 🌍 payment.`}
            list={noContentList}
            // btntext={`Coming Soon`}
            onIntercomLoad={() => {
              // window.Intercom("show");
              window.fcWidget.open();
            }}
            // btntext={`Coming soon`}
            btntext={`Create card`}
            onBtnClick={() => {
              user_resource_and_permissions?.allowed_permissions?.includes(
                "can_create_virtual_cards"
              ) && navigate("/dashboard-card-create");
            }}
          />
        )}
      </DashboardLayout>

      {/* top up modal start */}
      <TopUpCardModal
        visible={showModal?.topUp}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, topUp: false };
          });
        }}
        value={preview}
        onFinish={handleFinish}
      />
      {/* top up modal end */}
      {/* withdraw modal start */}
      <WithdrawCardModal
        visible={showModal?.withdraw}
        onCancel={() => {
          setShowModal((prev) => {
            return { ...prev, withdraw: false };
          });
        }}
        value={preview}
        onFinish={handleFinish}
      />
      {/* withdraw modal end */}
      {/* withdraw modal start */}
      <FeezeCardModal
        visible={showModal?.freeze}
        onCancel={() => {
          // setShowModal((prev) => {
          //   return { ...prev, freeze: false };
          // });
          actionCtx.setUnfreezeCard(false);
        }}
        value={preview}
        onFinish={handleFinish}
      />
      {/* withdraw modal end */}
      {/* {showIntercom && <IntercomPopup appId="ughilol1" />} */}
    </>
  );
};

export default DashboardcardIndex;
