import React, { useEffect, useState } from "react";
import "../../styles/auth/AuthlayoutOne.css";
// import ChatIcon from "../../assets/chat-icon-img.svg";
import ravenLogo from "../../assets/raven-logo-full-black.svg";
// import securityIcon from "../../assets/security-tip-icon.svg";
import ravenLogoMobile from "../../assets/raven-logo-full-black.svg";
// import LockBox from "../../components/fragments/LockBox";
import { useNavigate } from "react-router-dom";
import { RavenActionBar } from "@ravenpay/raven-bank-ui";
import { genCaptchaKeyAsync } from "../../components/reusables/ReCaptchaFile";
// import Intercom from 'intercom-client';

const AuthLayoutTwo = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard-overview");
    }
    genCaptchaKeyAsync("6Lfbo54nAAAAAEUGhpgYHJY7DvpS_e7JoUt2Na4d");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const intercom = new Intercom.Client({ token: 'YOUR_APP_ID' });
  //   intercom.boot({
  //     app_id: 'YOUR_APP_ID',
  //     // Add any other options here
  //   });
  // }, []);

  // const handleClick = () => {
  //   const intercom = new Intercom.Client({ token: 'YOUR_APP_ID' });
  //   intercom.trackEvent('Clicked Button', { buttonId: 'my-button' });
  // }
  const [inviteProcess, setinviteProcess] = useState(false);
  useEffect(() => {
    const email = localStorage?.getItem("invite--email");
    if (email) {
      setinviteProcess(true);
      // setDetails((prev) => {
      //   return { ...prev, email: email };
      // });
    }
    // console.log(email);
  }, []);

  return (
    <>
      <div className="auth-two-layout-wrap">
        <div className="header-wrap border-theme">
          <div className="size-wrap">
            {/* logo wrap start */}
            <div
              onClick={() => {
                navigate("/dashboard-overview");
              }}
              className="logo-wrap"
            >
              <figure className="img-box">
                {/* <a
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://getravenbank.com/raven-business"
                > */}
                {/* {" "} */}
                <img src={ravenLogo} alt="" className="img" />
                {/* </a> */}
              </figure>
            </div>
            {/* logo wrap end */}
            {/* logo wrap start */}
            <div className="logo-wrap logo-wrap-two">
              <figure className="img-box">
                <img src={ravenLogoMobile} alt="" className="img" />
              </figure>
            </div>
            {/* logo wrap end */}
          </div>
          <RavenActionBar
            msg={`You have been invited to a new business banking acount, Please login with existing password to accept invitation`}
            type="info"
            actionText="Okay, Got it!"
            visible={inviteProcess}
            onAction={() => {
              localStorage?.removeItem("invite--email");
              setinviteProcess(false);
            }}
            onCancel={() => {
              // localStorage?.removeItem("invite--email");
              setinviteProcess(false);
            }}
          />
        </div>
        {/* header wrap end */}
        <div className="content-wrap">
          <div className="size-wrap">
            {/* security wrap end */}
            <div className="content">
              {" "}
              <div className="form-wrap">{children} </div>
            </div>
            {/* end of content */}
            {/* <div className="security-wrap security-wrap-big">
            <LockBox text={`Security tip`} className="security-box" /> */}
            {/* security box end */}
            {/* <p className="text">
              Ensure the url you visit is <span><a href="https://business.getravenbank.com">https://business.getravenbank.com</a> </span>,
              Beware of scams and malicious link that stores your password for
              use.
            </p> */}
            {/* </div> */}
            {/* security wrap end */}
          </div>
        </div>
        {/* content-wrap end */}
        <div className="footer-wrap">
          <div className="size-wrap">
            {/* <figure className="img-box">
            <img src={ChatIcon} alt="" className="img" />
          </figure> */}
          </div>
        </div>
        {/* footer wrap end */}
      </div>
    </>
  );
};

export default AuthLayoutTwo;
