import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DeleteModal from "../../../components/reusables/DeleteModal";
import ActionContext from "../../../context/ActionContext";
import { rejectPaymentRequest } from "../../../redux/paymentRequest";
import { trimLongString } from "../../../utils/helper/Helper";

const RejectPaymentRequestModal = ({ detail, visible, onClose, onFinish }) => {
  const { loadingStatement } = useSelector((state) => state.payment_request);
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const obj = {
      request_id: String(detail?.id),
      account_number: actionCtx?.currentAccount?.account_number,
    };
    const data = await dispatch(rejectPaymentRequest(obj));
    if (data?.payload?.data?.status === "success") {
      onFinish();
    }
  };
  return (
    <DeleteModal
      title={`Reject Payment Request from  ${detail?.intiator_fname || "---"} ${
        detail?.intiator_fname?.length + detail?.intiator_lname?.length > 12
          ? trimLongString(detail?.intiator_lname, 5)
          : detail?.intiator_lname
      }`}
      text={`Rejecting this request, would disable their request  on your business, ensure you confirm this request before totally removing them from your account.`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
      loading={loadingStatement}
    />
  );
};

export default RejectPaymentRequestModal;
