import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import BadgeComponent from "../../components/fragments/badge/BadgeComponent";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  reactSelectStyle,
  removeSpace,
  trimLongString,
} from "../../utils/helper/Helper";
import { validateBankAccount } from "../../redux/transaction";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { acceptInvitedRefereeByRefId } from "../../redux/verifications";

const RefereeInviteForm = ({ step, setStep }) => {
  const { bankList, loadingVerify } = useSelector((state) => state.transaction);
  const { loading, invited_referee_single } = useSelector(
    (state) => state.verifications
  );
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    full_name: "",
    address: "",
    phone: "",
    signature: "",
    bank_name: "",
    account_num: "",
    bank_address: "",
  });
  const handleChange = (e) => {
    // e && e?.preventDefault();
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };
  const navList = [
    {
      name: "User Details",
      active: step === 2,
      canClick: true,
      onClick: () => {
        setStep(2);
      },
    },
    {
      name: "Bank Details",
      active: step === 3,
      onClick: () => {
        // handleSubmit();
        setStep(3);
      },
      canClick:
        details?.full_name &&
        details?.address &&
        details?.phone &&
        details?.signature,
    },
  ];

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name } = chi;
      return {
        label: chi?.name,
        value: code,
        labelTwo: name,
      };
    });
    // const newList = formatListWithImage(list)
    // console.log(newList);
    return list;
  };

  const [validateName, setValidatedName] = useState("");
  // validate bank account details
  const validateBankDetails = async (e) => {
    const payload = {
      bank_code: details?.bank_name.value || details?.bank_name?.bank_code,
      account_number: removeSpace(details.account_num),
    };
    // console.log(payload);

    if (payload?.bank_code && payload?.account_number?.length > 9) {
      const data = await dispatch(validateBankAccount(payload));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        // console.log(data?.payload?.data.data);
        setDetails((prev) => {
          return { ...prev, username: data?.payload?.data.data };
        });
        setValidatedName(data?.payload?.data.data);
        // setStage(3);
      }
    }
  };

  // const selectValidateAccount = async (e) => {
  //   if (details?.account_num.replace?.length > 9) {
  //     validateBankDetails(e);
  //   }
  // };

  useEffect(() => {
    if (details?.bank_name && details?.account_num?.length > 9) {
      validateBankDetails();
    }
    removeSpace(details?.account_num);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.account_num || details?.bank_name]);

  const handleSubmit = async () => {
    const obj = {
      email: invited_referee_single?.referee_email,
      name: details?.full_name,

      address: details?.address,

      bank_name: details?.bank_name?.label,

      account_number: details?.account_num,
      bank_address: details?.bank_address,
      reference: invited_referee_single?.reference,

      signature: details?.signature,
      phone: details?.phone,
    };
    function createFormData(obj) {
      const formData = new FormData();

      Object.entries(obj).forEach(([key, value]) => {
        // Make sure to handle null or undefined values if necessary
        if (value !== undefined && value !== null) {
          formData.append(key, value);
        }
      });

      return formData;
    }
    const formData = new createFormData(obj);
    const data = await dispatch(acceptInvitedRefereeByRefId(formData));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      setDetails({
        full_name: "",
        address: "",
        phone: "",
        signature: "",
        bank_name: "",
        account_num: "",
        bank_address: "",
      });
      setStep(4)
    }
  };

  return (
    <div className="referee-invite-form-index-wrap">
      {/* top title back box start */}
      <div className="top-title-back-box">
        <div className="title-box">Complete the referee form.</div>
      </div>
      {/* top title back box end */}
      {/* side nav form box start */}
      <div className="side-nav-form-box card-bg">
        {/* menu nav box start */}
        <div className="menu-nav-box">
          {navList?.map((chi, idx) => {
            const { active, canClick, name, onClick } = chi;
            return (
              <div
                onClick={() => {
                  if (canClick) {
                    onClick && onClick();
                  }
                }}
                style={{
                  cursor: !active && canClick ? "pointer" : "not-allowed",
                }}
                className={`nav-item ${active && "nav-item-active grey-bg"}`}
              >
                <p className="name">{name}</p>
              </div>
            );
          })}
        </div>
        {/* menu nav box end */}
        {/* form content start */}
        <div className="form-content">
          {/* step === 2 start */}
          {step === 2 && (
            <>
              <RavenInputField
                color="black-light"
                label="Referee Email"
                onChange={handleChange}
                value={invited_referee_single?.referee_email}
                name="email"
                placeholder="akot kenny"
                type="email"
                disabled
              />
              <RavenInputField
                color="black-light"
                label="Full Name* "
                onChange={handleChange}
                value={details?.full_name}
                name="full_name"
                placeholder="akot kenny"
                type="text"
              />
              <RavenInputField
                color="black-light"
                label="Address*  "
                onChange={handleChange}
                value={details?.address}
                name="address"
                placeholder="E.g No 2 plazza"
                type="text"
              />
              <RavenInputField
                color="black-light"
                label="Phone Number* "
                onChange={handleChange}
                value={details?.phone}
                name="phone"
                placeholder="E.g 816263...."
                type="phone"
              />
              <RavenInputField
                color="black-light"
                label="Upload Signature*"
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, signature: e };
                  });
                }}
                customPreviewSrc={
                  details?.signature
                    ? URL.createObjectURL(details?.signature)
                    : ""
                }
                customPreviewName="Signature image"
                // value={details?.phone}
                name="phone"
                // placeholder="E.g 816263...."
                type="upload"
              />

              <div style={{ marginTop: "2.5rem", width: "100%" }} className="">
                {" "}
                <RavenInputField
                  color="black-light"
                  value="Next"
                  onSubmit={() => {
                    // navList[0]?.onClick();
                    setStep(3);
                  }}
                  disabled={
                    !details?.full_name ||
                    !details?.phone ||
                    !details?.signature ||
                    !details?.address
                  }
                  type="submit"
                />
              </div>
            </>
          )}
          {/* step === 2 end */}
          {/* step === 3 start */}
          {step === 3 && (
            <>
              <RavenInputField
                color="black-light"
                label="Bank Name* "
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, bank_name: e };
                  });
                }}
                style={{ position: "relative", zIndex: 10 }}
                value={details?.bank_name}
                name="bank_name"
                placeholder="Select bank"
                type="select"
                selectOption={formatSelectOption(bankList)}
                selectStyles={reactSelectStyle}
              />
              <RavenInputField
                color={`black-light`}
                type={`account-number`}
                label="Account number* "
                placeholder="Recipient account no."
                onChange={(e) => {
                  setValidatedName("");
                  handleChange(e);
                }}
                value={details?.account_num}
                name={`account_num`}
                maxLength={10}
                labelSpanText={
                  loadingVerify ? (
                    <div
                      style={{
                        padding: "0rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        transform: "translateX(1.5rem)",
                      }}
                      className="load-wrap"
                    >
                      <ThreeDots
                        height="10"
                        width="80"
                        radius="9"
                        // color="#020202"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      {validateName ? (
                        <BadgeComponent
                          className={`pending black-white-color grey-bg`}
                        >
                          {trimLongString(validateName, 20)}
                        </BadgeComponent>
                      ) : (
                        ""
                      )}
                    </>
                  )
                }
                labelColor={`purple-light`}
                labelClassName={`label-class-span ${
                  details?.account_num?.length === 10 &&
                  "label-class-span-active"
                }`}
                onActionClick={() => {
                  details?.account_num?.length === 10 && validateBankDetails();
                }}
              />
              {/* badge start */}
              <div style={{ marginTop: "-1rem" }} className="badge-ref-box">
                <BadgeComponent
                  className={`info`}
                  text={`Ensure this is a CURRENT ACCOUNT `}
                />
              </div>
              {/* badge end */}
              <RavenInputField
                color="black-light"
                label="Bank Address*"
                style={{ position: "relative", zIndex: 1 }}
                onChange={handleChange}
                value={details?.bank_address}
                name="bank_address"
                placeholder="E.g N0 45 plazza.."
                type="text"
              />
              <div style={{ marginTop: "2.5rem", width: "100%" }} className="">
                {" "}
                <RavenInputField
                  color="black-light"
                  value="Submit Details"
                  loading={loading}
                  onSubmit={() => {
                    handleSubmit();
                  }}
                  disabled={
                    !details?.bank_name ||
                    !details?.bank_address ||
                    !validateName ||
                    !details?.full_name ||
                    !details?.phone ||
                    !details?.signature ||
                    !details?.address
                  }
                  type="submit"
                />
              </div>
            </>
          )}
          {/* step === 3 end */}
        </div>
        {/* form content end */}
      </div>
      {/* side nav form box end */}
    </div>
  );
};

export default RefereeInviteForm;
