// import DashboardSettings from "../../../pages/dashboard/settings";

import DashboardSettingsAppearance from "../../../pages/dashboard/settings/pages/AppearancePage";
import BeneficiarySettings from "../../../pages/dashboard/settings/pages/BeneficiarySettings";
import DashboardSettingsDevice from "../../../pages/dashboard/settings/pages/DeviceManagement";
import DashboardSettingsDirectors from "../../../pages/dashboard/settings/pages/DirectorPage";
import DashboardSettingsNotification from "../../../pages/dashboard/settings/pages/NotificationPage";
import DashboardSettingsRoles from "../../../pages/dashboard/settings/pages/RoleAndPermissionPage";
import DashboardSettingsSecurity from "../../../pages/dashboard/settings/pages/SecurityPage";
import DashboardSettingsStatement from "../../../pages/dashboard/settings/pages/StatementPage";
import DashboardSettingsSubAccount from "../../../pages/dashboard/settings/pages/SubAccountPage";
import DashboardSettingsTeams from "../../../pages/dashboard/settings/pages/TeamsPage";
import DashboardSettingsVerification from "../../../pages/dashboard/settings/pages/VerificationPage";

export const dashboard_settings_route_group = [
  {
    path: "/dashboard-settings-notification",
    element: <DashboardSettingsNotification />,
  },
  {
    path: "/dashboard-settings-verification",
    element: <DashboardSettingsVerification />,
  },
  {
    path: "/dashboard-settings-security",
    element: <DashboardSettingsSecurity />,
  },
  { path: "/dashboard-settings-team", element: <DashboardSettingsTeams /> },
  {
    path: "/dashboard-settings-role-permission",
    element: <DashboardSettingsRoles />,
  },
  {
    path: "/dashboard-settings-sub-account",
    element: <DashboardSettingsSubAccount />,
  },
  {
    path: "/dashboard-settings-statement",
    element: <DashboardSettingsStatement />,
  },
  {
    path: "/dashboard-settings-directors",
    element: <DashboardSettingsDirectors />,
  },
  {
    path: "/dashboard-settings-device-management",
    element: <DashboardSettingsDevice />,
  },
  {
    path: "/dashboard-settings-appearance",
    element: <DashboardSettingsAppearance />,
  },
  { path: "/dashboard-settings-beneficiary", element: <BeneficiarySettings /> },
];
