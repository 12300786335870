import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/send-money/SendMoneyBank.css";
import addIcon from "../../../../assets/add-icon-form.svg";
import { FaCheck, FaTimes } from "react-icons/fa";
import bankIcon from "../../../../assets/gtco-icon.svg";
import { reactSelectStyle } from "../../../../utils/helper/Helper";
import PrevieDetailsBox from "../raven/PrevieDetailsBox";
import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";
import ConfirmTransactionPinModal from "../ConfirmPinModal";

const DashboardSendToBankBulkManual = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [beneficiarySelect, setBeneficiarySelect] = useState("");
  const [viewAll, setViewAll] = useState(false);
  const stepList = ["Transfer detail", "Confirm Transactions"];
  const [details, setDetails] = useState({
    username: "",
    amount: "",
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    account_num: "",
    bank: "",
    name: "",
  });
  const beneList = [
    {
      name: "Ade John ",
      account_num: "802838373",
      bank: "Gtbank",
      label: "Ade John gtb bank ls",
      value: "ksk",
    },
    {
      name: "Peter Obi",
      account_num: "9028381373",
      bank: "UBA",
      label: "Peter Obi ksiujnw j",
      value: "ksp",
    },
    {
      name: "Ba la blue",
      account_num: "902830373",
      bank: "Zenith bank",
      label: "Ba la blue vjsy sjdh",
      value: "ksa",
    },
    {
      name: "bu la ba",
      account_num: "672838373",
      bank: "First Bank",
      label: "bu la ba",
      value: "ksl",
    },
  ];
  const [beneListVal, setBenelistVal] = useState(beneList);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleBulkTransfer = () => {
    const checkList = details?.bulk_list?.some(
      (chi) => chi?.account_num === details?.account_num
    );
    if (!checkList) {
      const obj = {
        account_num: details?.account_num,
        amount: details?.amount,
        narration: details?.narration,
        beneficiary: false,
        bank: details?.bank.label,
        name: details?.name,
      };
      const newList = [...details?.bulk_list, obj];
      //   console.log(newList);
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          bank: "",
          amount: "",
          narration: "Sent from raven",
          account_num: "",
          name: "",
        };
      });
      setBeneficiarySelect("");
    } else {
      // console.log("no");
    }
  };

  const handleRemoveFromList = (obj) => {
    if (details?.bulk_list?.length === 1) {
      setViewAll(false);
    }
    const newList = details?.bulk_list?.filter(
      (chi) => chi?.account_num !== obj?.account_num
    );
    setDetails((prev) => {
      return {
        ...prev,
        bulk_list: newList,
      };
    });
  };

  const bankList = [
    { label: "GTB", value: "gtb" },
    { label: "UBA", value: "UBA" },
    { label: "Access Bank", value: "Access Bank" },
    { label: "Zenith bank", value: "Zenith bank" },
  ];

  const handleBeneficiarySelect = (obj) => {
    // console.log(obj);
    const newObj = {
      ...details,
      bank: { label: obj?.bank, value: "js" },
      name: obj?.name,
      account_num: obj?.account_num,
    };
    setDetails(newObj);
    setBeneficiarySelect(obj);
  };

  const handleMoveToPreview = (obj) => {
    const list = beneList?.filter(
      (chi) => chi?.account_num !== obj?.account_num
    );
    const newList = [obj, ...list];
    // console.log(newList);
    setBenelistVal(newList);
  };

  const removeBeneficiaryOption = () => {
    setBeneficiarySelect("");
    setDetails((prev) => {
      return { ...prev, account_num: "", bank: "", name: "" };
    });
  };

  const handleNextStep = (param) => {
    if (param === "one") {
      if (details?.amount && details?.bank) {
        handleBulkTransfer();
      }
      setBeneficiarySelect("");
      setStep(2);
    }
    if (param === "two") {
      setShowModal(true);
    }
  };

  const handleAddBeneficiary = (obj, val) => {
    if (details?.bulk_list?.length > 0) {
      const newList = details?.bulk_list?.map((chi) =>
        chi?.bank === obj?.bank ? { ...chi, beneficiary: val } : chi
      );
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          username: "",
          amount: "",
          narration: "Sent from raven",
        };
      });
    } else {
      const newObj = { ...details, beneficiary: val };
      setDetails(newObj);
    }
  };

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Send to Bank account`}
          text={`Send funds to a bank account`}
          back
          onBack={() => {
            navigate(`/dashboard-send-money`);
          }}
        />
        <ContainerWrapper
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          onBtnClick={() => {
            step === 1 && handleNextStep("one");
            step === 2 && handleNextStep("two");
          }}
          activeStep={step}
          stepList={stepList}
          btnText={`Complete Transfer`}
          disabled={
            details?.bulk_list?.length > 0
              ? details?.bulk_list?.length < 1
              : !details?.account_num || !details?.amount || !details?.bank
          }
        >
          <div className="send-money-wrap-box-bank">
            <form
              style={{ paddingBottom: viewAll && "10rem" }}
              onSubmit={(e) => e.preventDefault()}
              action=""
              className="form"
            >
              {/* step one start ------------------- */}
              {step === 1 && (
                <>
                  {/* recepient box start */}
                  <div
                    className={`beneficiary-box ${
                      details?.bulk_list?.length > 0 && "beneficiary-box-show"
                    }`}
                  >
                    <div className="view-total-box">
                      <span>Select beneficiaries* </span>
                      <div className="view-wrap">
                        <RavenInputField
                          selectStyles={reactSelectStyle}
                          type={`select`}
                          placeholder={`See all`}
                          selectOption={beneList}
                          color={`black-light`}
                          className={"select-bene-wrap"}
                          value={""}
                          onChange={(e) => {
                            handleBeneficiarySelect(e);
                            handleMoveToPreview(e);
                          }}
                        />
                      </div>
                    </div>
                    {/* end of view total box */}
                    {/* preview list start */}
                    <div className="preview-box">
                      {beneListVal?.slice(0, 3)?.map((chi, idx) => {
                        const { name, account_num, bank } = chi;
                        return (
                          <div
                            className={`box box-card hover-white-class ${
                              beneficiarySelect?.name === name &&
                              "box-card-active"
                            }`}
                            key={idx}
                            onClick={() => {
                              beneficiarySelect?.name === name
                                ? removeBeneficiaryOption()
                                : handleBeneficiarySelect(chi);
                            }}
                          >
                            <div className="check-box">
                              <FaCheck className="icon" />
                            </div>
                            <div className="avatar-box">
                              <span>{name.split(" ")[0].charAt(0)}</span>
                            </div>
                            <p className="name">{name}</p>
                            <p className="text grey-white-color">{`${bank} • ${account_num}`}</p>
                          </div>
                        );
                      })}
                    </div>
                    {/* preview list end */}
                  </div>
                  {/* recepient box end */}
                  <div className="text-line">
                    <span></span>
                    <p className="text">Or</p>
                    <span></span>
                  </div>
                  <RavenInputField
                    color={`black-light`}
                    type={`select`}
                    label="Select bank* "
                    selectStyles={reactSelectStyle}
                    placeholder="Choose a bank"
                    onChange={(e) => {
                      setBeneficiarySelect("");
                      setDetails((prev) => {
                        return { ...prev, bank: e };
                      });
                    }}
                    selectValue={details?.bank}
                    name={`bank`}
                    value={details?.bank}
                    className={`select-react`}
                    selectOption={bankList}
                  />
                  <RavenInputField
                    color={`black-light`}
                    type={`number`}
                    label="Account number* "
                    placeholder="Recipient account no."
                    onChange={handleChange}
                    value={details?.account_num}
                    name={`account_num`}
                  />
                  <RavenInputField
                    type={`number`}
                    color={`black-light`}
                    label={`Amount* `}
                    name={`amount`}
                    value={details?.amount}
                    onChange={handleChange}
                    placeholder={`How much are you sending`}
                    labelColor={`black-light`}
                    labelSpanText={`Bal: ₦24,120.00`}
                    labelClassName={`label-span-amount`}
                    thousandFormat
                    numberPrefix={`₦`}
                  />
                  <RavenInputField
                    type={`text`}
                    color={`black-light`}
                    label={`Narration* `}
                    value={details?.narration}
                    onChange={handleChange}
                    placeholder={`E.g From timi`}
                    name={`narration`}
                  />
                  {/* add another text start */}
                  <div
                    onClick={() => {
                      details?.amount && details?.bank && handleBulkTransfer();
                    }}
                    className={`add-another-box ${
                      details?.amount &&
                      details?.bank &&
                      "add-another-box-active"
                    }`}
                  >
                    <figure className="img-box">
                      <img src={addIcon} alt="" className="img" />
                    </figure>
                    <p className="text">Transfer to another account.</p>
                  </div>
                  {/* add another text end */}
                  {/* recepient box start */}
                  <div
                    className={`recepient-box ${
                      details?.bulk_list?.length > 0 && "recepient-box-show"
                    }`}
                  >
                    <div className="text-line">
                      <span></span>
                      <p className="text">Recipients</p>
                      <span></span>
                    </div>
                    <div className="view-total-box">
                      <p className="total">{`Total added (${details?.bulk_list?.length})`}</p>
                      <div className="view-wrap">
                        {/* view all box start */}
                        <div
                          className={`view-all-box card-bg ${
                            viewAll && "view-all-box-show"
                          }`}
                        >
                          <div className="wrap">
                            {details?.bulk_list?.map((chi, idx) => {
                              return (
                                <div key={idx} className="child-wrap">
                                  <figure className="img-box">
                                    <img
                                      src={bankIcon}
                                      alt=""
                                      className="img"
                                    />
                                  </figure>
                                  <div className="text-box">
                                    <p className="name">
                                      {chi?.name || "pppp testing"}
                                    </p>
                                    <p className="text grey-white-color">{`${
                                      chi?.bank || "087654567"
                                    } • ${chi?.account_num}`}</p>
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleRemoveFromList(chi);
                                    }}
                                    className="cancel-box"
                                  >
                                    <FaTimes className="icon" />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* view all box end */}
                        {viewAll ? (
                          // <div
                          //   onClick={() => {
                          //     setViewAll(false);
                          //   }}
                          //   style={{
                          //     display: "flex",
                          //     alignItems: "center",
                          //     gap: ".7rem",
                          //     cursor: "pointer",
                          //   }}
                          // >
                          //   <div className="cancel-box">
                          //     <FaTimes className="icon" />
                          //   </div>
                          //   <span>Close</span>
                          // </div>
                          <p
                            onClick={() => {
                              setViewAll(false);
                              details?.bulk_list?.length === 1 &&
                                setViewAll(false);
                            }}
                            className="text"
                          >
                            Close
                          </p>
                        ) : (
                          <p
                            onClick={() => {
                              setViewAll(true);
                            }}
                            className="text"
                          >
                            View all
                          </p>
                        )}
                      </div>
                    </div>
                    {/* end of view total box */}
                    {/* preview list start */}
                    {!viewAll && (
                      <div className="preview-box">
                        {details?.bulk_list?.slice(0, 2).map((chi, idx) => {
                          const { bank, account_num,  name } = chi;
                          return (
                            <div className="box box-card " key={idx}>
                              <div
                                onClick={() => {
                                  handleRemoveFromList(chi);
                                }}
                                className="cancel-box"
                              >
                                <FaTimes className="icon" />
                              </div>
                              <figure className="img-box">
                                <img src={bankIcon} alt="" className="img" />
                              </figure>
                              <p className="name">{name || "opl"}</p>
                              <p className="text grey-white-color">{`${
                                bank || "0121559651"
                              } • ${account_num}`}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* preview list end */}
                  </div>
                  {/* recepient box end */}
                </>
              )}
              {/* step one end ------------------- */}
              {/* step two start ------------------ */}
              {step === 2 && (
                <>
                  {/* select schedule start */}
                  <SelectScheduleBox
                    id={`onud`}
                    value={details?.schedule}
                    onChange={(e) =>
                      setDetails((prev) => {
                        return { ...prev, schedule: e };
                      })
                    }
                  />
                  {/* select schedule end */}
                  {/* <PrevieDetailsBox collapse onChange={(e) => {console.log(e.target.checked);}} /> */}
                  {details?.bulk_list?.length > 0 ? (
                    <>
                      {details?.bulk_list?.length === 1 ? (
                        <>
                          {details?.bulk_list?.map((chi, idx) => {
                            const {
                              name,
                              amount,
                              beneficiary,
                              narration,
                              bank,
                              account_num,
                            } = chi;
                            return (
                              <PrevieDetailsBox
                                key={idx}
                                value={beneficiary}
                                onChange={(e) => {
                                  handleAddBeneficiary(chi, e.target.checked);
                                }}
                                bank={bank}
                                account_num={account_num}
                                narration={narration}
                                name={name}
                                amount={amount}
                                checked={beneficiary}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {details?.bulk_list?.map((chi, idx) => {
                            const {
                              name,
                              amount,
                              beneficiary,
                              narration,
                              bank,
                              account_num,
                            } = chi;
                            return (
                              <PrevieDetailsBox
                                key={idx}
                                id={idx}
                                value={beneficiary}
                                onChange={(e) => {
                                  handleAddBeneficiary(chi, e.target.checked);
                                }}
                                bank={bank}
                                account_num={account_num}
                                narration={narration}
                                name={name}
                                amount={amount}
                                collapse
                                checked={beneficiary}
                              />
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <PrevieDetailsBox
                        value={details?.beneficiary}
                        onChange={(e) => {
                          handleAddBeneficiary(e.target.checked);
                        }}
                        account_num={details?.account_num}
                        bank={details?.bank}
                        amount={details?.amount}
                        name={details?.name}
                        narration={details?.narration}
                        checked={details?.beneficiary}
                      />
                    </>
                  )}
                </>
              )}
              {/* step two end ------------------ */}
            </form>
          </div>
        </ContainerWrapper>
        {/* title box end */}
      </DashboardLayout>
      {/* pin modal start */}
      <ConfirmTransactionPinModal
        onClose={() => {
          setShowModal(false);
        }}
        visible={showModal}
      />
      {/* pin modal end */}
    </>
  );
};

export default DashboardSendToBankBulkManual;
