import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import forgetImg from "../../assets/forget-password-icon.svg";
import AuthLayoutTwo from "../../layout/auth/AuthLayoutTwo";
// import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import "../../styles/auth/SignUp.css";
import { RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import { FaArrowLeft } from "react-icons/fa";
import {
  validatePasswordLowercase,
  validatePasswordNumber,
  validatePasswordSpecialCharacter,
  validatePasswordUpperCase,
} from "../../utils/helper/ValidateInput";
import { useDispatch, useSelector } from "react-redux";
import SuccessModal from "../../components/reusables/SuccessModal";
import { resetPassword } from "../../redux/user";
// import { sumAllNum } from "../../utils/helper/Helper";

const CreateNewPassword = () => {
  const [showModal, setSHowModal] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    password: "",
    new_password: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async () => {
    if (!validatePasswordLowercase(details?.password)) {
      toast.error("Password should have at least 1 lowercase letter", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    if (!validatePasswordUpperCase(details?.password)) {
      toast.error("Password should have at least 1 capital letter", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    if (!validatePasswordSpecialCharacter(details?.password)) {
      toast.error("Password should have at least 1 special character", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    if (!validatePasswordNumber(details?.password)) {
      toast.error("Password should have at least  1 numeric character.", {
        position: "top-right",
      });
      setError((prev) => {
        return { ...prev, password: true };
      });
      return;
    }
    if (details?.new_password !== details?.password) {
      toast.error("Passwords doesn't match.", {
        position: "top-right",
      });
      return;
    }
    const obj = {
      otp: localStorage.getItem("otp"),
      password: details?.password,
    };
    const data = await dispatch(resetPassword(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      setSHowModal(true);
      localStorage.removeItem("otp");
    }
  };

  // const [showGuessBox, setShowGuessBox] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validate, setValidate] = useState({
    hasLow: 0,
    hasCap: 0,
    hasNumber: 0,
    has8digit: 0,
    hasSpecial: 0,
  });

  const validatePassword = (password) => {
    if (password.match(/\d+/g)) {
      setValidate((o) => ({ ...o, hasNumber: 20 }));
    } else {
      setValidate((o) => ({ ...o, hasNumber: 0 }));
    }
    if (password.match(/[!@#$%^.&*_=+-]/g)) {
      setValidate((o) => ({ ...o, hasSpecial: 20 }));
    } else {
      setValidate((o) => ({ ...o, hasSpecial: 0 }));
    }

    if (password.match(/[A-Z]+/g)) {
      setValidate((o) => ({ ...o, hasCap: 20 }));
    } else {
      setValidate((o) => ({ ...o, hasCap: 0 }));
    }

    if (password.match(/[a-z]+/g)) {
      setValidate((o) => ({ ...o, hasLow: 20 }));
    } else {
      setValidate((o) => ({ ...o, hasLow: 0 }));
    }

    if (password.length > 7) {
      setValidate((o) => ({ ...o, has8digit: 20 }));
    } else {
      setValidate((o) => ({ ...o, has8digit: 0 }));
    }
  };

  const infoIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15940_68964)">
        <path
          d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6024 18.3332 10C18.3332 5.39765 14.6022 1.66669 9.99984 1.66669C5.39746 1.66669 1.6665 5.39765 1.6665 10C1.6665 14.6024 5.39746 18.3334 9.99984 18.3334Z"
          fill="#EA872D"
        />
        <path
          d="M9.99984 6.66669V10M9.99984 13.3334H10.0082M18.3332 10C18.3332 14.6024 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6024 1.6665 10C1.6665 5.39765 5.39746 1.66669 9.99984 1.66669C14.6022 1.66669 18.3332 5.39765 18.3332 10Z"
          stroke="#FFF6ED"
          strokeWidth="1.43293"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15940_68964">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <>
      <AuthLayoutTwo>
        <div className="sign-in-wrap">
          {/* form start */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            action=""
            className="form form-forget-password"
          >
                      {/* security tip start */}
          <div className="security-tip-wrap">
            <div className="img-wrap">
              <figure className="img-box">{infoIcon}</figure>
            </div>
            <p className="text">
              Confirm if this url is{" "}
              <span>
                <a href="https://business.getravenbank.com">
                  https://business.getravenbank.com
                </a>{" "}
              </span>{" "}
            </p>
          </div>
          {/* security tip end */}
            <div className="img-wrap-forget">
              <figure className="img-box">
                <img src={forgetImg} alt="" className="img" />
              </figure>
            </div>
            {/* title text box start */}
            <div className="text-box">
              <p className="big-title">Add your new password</p>
              <p className="small-title">
                Keep your password safe from intruders
              </p>
            </div>
            {/* title text box end */}
            <form
              className="password-wrapper-check"
              autoComplete="off"
              style={{ width: "100%" }}
              action=""
              autoCorrect="off"
            >
              {" "}
              <RavenInputField
                type={`password`}
                label={`New password * `}
                placeholder="Your new password here"
                color={`black-light`}
                name="password"
                onChange={(e) => {
                  handleChange(e);
                  validatePassword(e.target.value);
                  setError((prev) => {
                    return { ...prev, password: false };
                  });
                }}
                // onFocus={() => {
                //   // console.log("us");
                //   setShowGuessBox(true);
                // }}
                // onBlur={() => {
                //   // console.log("ksjs");
                //   setShowGuessBox(false);
                // }}
                value={details?.password}
                showColor={`black-light`}
                showError={error?.password}
                showPasswordStrength
              />
              {/* check drop box start */}
           
              {/* check drop box end */}
            </form>

            <RavenInputField
              type={`password`}
              label={`Confirm new password *`}
              placeholder="Your confirm password here"
              color={`black-light`}
              name="new_password"
              onChange={handleChange}
              value={details?.new_password}
              showColor={`black-light`}
            />
            <RavenInputField
              type={`submit`}
              color="black-light"
              value={`Proceed to next step`}
              disabled={!details?.password || !details?.new_password}
              className="btn-submit"
              loading={loading}
              onSubmit={() => {
                handleSubmit();
              }}
            />
            {/* back text start */}
            <div className="back-text">
              <FaArrowLeft className="icon" />
              <p className="text">
                Back to <span onClick={() => navigate("/login")}>Login</span>
              </p>
            </div>
            {/* back text end */}
          </form>
          {/* form end */}
        </div>
      </AuthLayoutTwo>
      {/* sign up modal start */}
      <SuccessModal
        authType
        visible={showModal}
        title="Password Reset Successful."
        text={`You can now log in to your account using your new password. If you did not request this password reset, please contact our support team immediately to secure your account.
        `}
        btnText={`Go to Login`}
        onBtnClick={() => {
          navigate("/login");
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default CreateNewPassword;
