import {
  RavenButton,
  // RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../../../styles/dashboard/schedule-payment/ViewScheduleModal.css";
//   import Flatpicker from "react-flatpickr";
//   import moment from "moment";
import {
//   formatDate,
//   formatMetaData,
  formatNumWithCommaNairaSymbol,
  sumAllNum,
} from "../../../../utils/helper/Helper";
// import {  useSelector } from "react-redux";
// import { reschedulePayment } from "../../../../redux/schedulePayment";

const MobileEmployeeViewCreate = ({
  param,
  visible,
  onClose,
  onCancel,
  onDownload,
  downloadCsvId,
  detail,
  setNewDate,
  onFinish,
}) => {
  const [reschedule, setReschedule] = useState(false);
//   const { loadingDowloadSingle } = useSelector((state) => state.payroll);
  // const [fromDate, setFromDate] = useState("");

  return (
    <RavenModal
      visble={visible}
      className="view-schedule-payment-modal"
      onClose={() => {
        setReschedule(false);
        onClose();
      }}
    >
      {reschedule ? (
        <></>
      ) : (
        <div className="wrap-modal">
          <p className="title">Employee Details</p>
          <div className="underline"></div>
          {/* label-value-status box start */}
          <div className="label-value-status-box">
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">First name:</p>
              <p className="value">
                {detail?.first_name || "---"}
              </p>
            </div>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Last Name:</p>
              <p className="value">
                {detail?.last_name || "---"}
              </p>
            </div>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Phone no:</p>
              <p className="value">
                {detail?.phone_number || "---"}
              </p>
            </div>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Raven username:</p>
              <p className="value">
                {detail?.raven_username || "---"}
              </p>
            </div>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Salary:</p>
              <p className="value">
                {formatNumWithCommaNairaSymbol(detail?.gross_salary || "---")}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Account number:</p>
              <p className="value">
                {detail?.account_number || "---"}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Bank:</p>
              <p style={{ textTransform: "capitalize" }} className="value">
                {detail?.bank || "---"}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color">Total deductions:</p>
              <p style={{ textTransform: "capitalize" }} className="value">
                {formatNumWithCommaNairaSymbol(
                  sumAllNum([
                    detail?.employer_dedcutions,
                    detail?.nhf,
                    detail?.paye,
                    detail?.overdraft_deductions,
                    detail?.pension,
                  ])
                )}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            {/* box end */}
            {/* ---------- type end -------------- */}

            {/* box start */}
            {/* <div className="box">
                  <p className="label grey-white-color">Status </p>
                  <div
                    className={`status-type-wrap ${
                      detail?.status === "Closed" && "status-type-wrap-pending"
                    } ${detail?.status === 2 && "status-type-wrap-success"} ${
                      detail?.status === 3 && "status-type-wrap-fail"
                    } ${detail?.status === 0 && "status-type-wrap-pending"} ${
                      detail?.status === 1 && "status-type-wrap-pending"
                    }`}
                  >
                    <span>
                      {detail?.status === 1
                        ? "Processing"
                        : detail?.status === 0
                        ? "Pending"
                        : detail?.status === 2
                        ? "Success"
                        : detail?.status === 3
                        ? "Failed"
                        : ""}
                    </span>
                  </div>
                </div> */}
            {/* box end */}
          </div>
          {/* label-value-status box end */}
        </div>
      )}
      <div className="two-button-wrap">
        <RavenButton
          onClick={() => {
            onCancel()
          }}
          style={{ gridColumn: "1 / -1" }}
          color={`black-light`}
          label={`Cancel`}
          //   style={{width: reschedule && "max-content"}}
        />
      </div>
    </RavenModal>
  );
};

export default MobileEmployeeViewCreate;
