/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import NewSelectScheduleBox from "../../../components/reusables/NewSelectScheduleBox";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";Group 238182raven-default-bank-img.svg
import "../../../styles/fragments/PreviewDetailsCommonBox.css";
import bankIconDefault from "../../../assets/bank-general-img.svg";
// import bankIconDefault from "../../../assets/raven-default-bank-img.svg";
// import { RavenInputField, RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import bankIconDefaultRav from "../../../assets/raven-default-bank-img.svg";
import bankIcon from "../../../assets/bank-general-img.svg";
import moment from "moment";
import mtnICon from "../../../assets/bill-mtn-icon.svg";
import airtelICon from "../../../assets/bill-airtel-icon.svg";
// import mobileICon from "../../../assets/bill-moblie-icon.svg";
import gloICon from "../../../assets/bill-glo-icon.svg";
import {
  convertToLowercase,
  formatDate,
  formatNumWithCommaNairaSymbol,
  trimLongString,
} from "../../../utils/helper/Helper";
// import { FaTimes } from "react-icons/fa";
// import ActionContext from "../../../context/ActionContext";
// import { useSelector } from "react-redux";
import ChangeSourceAccount from "../../../components/reusables/ChangeSourceAccount";
import { RavenNumberFormat, RavenToolTip } from "@ravenpay/raven-bank-ui";
import ImageFragment from "../../../components/fragments/ImageFragment";

// import { useDispatch } from "react-redux";
// import { getBeneficiaries } from "../../../../redux/settings";
// import { useSelector } from "react-redux";

// const restructureName = (name) => {
//     let val;
//     const nameSplit = name?.split(" ");
//     if (nameSplit?.length > 2) {
//       const newVal = `${nameSplit[0]} ${nameSplit[1]}  ...`;
//       val = newVal;
//       // eslint-disable-next-line no-unused-expressions
//     } else {
//       val = name;
//     }
//     return val;
//   };

const NewPreviewDetailBox = ({
  collapse,
  amount,
  narration,
  totalFee,
  onChangeSchedule,
  valueSchedule,
  enableTime,
  onReset,
  details,
  bulkAmount,
  // narration,
  accountObj,
  detail,
  list,
}) => {
  const [viewAll, setViewAll] = useState(false);
  // const { user } = useSelector((state) => state.user);
  // const actionCtx = useContext(ActionContext);

  const formatMetaData = (param) => {
    if (param) {
      const newParam = JSON.parse(param);
      // console.log(newParam);
      return newParam;
    }
  };

  // console.log(list);

  return (
    <div
      onClick={() => {
        console.log(detail);
      }}
      className="new-preview-detail-box-wrap card-bg border-theme"
    >
      {/* img top box start */}
      <div className="img-top-box-wrap border-theme-bottom">
        <div className="img-wrap">
          <ImageFragment
            url={
              `https://businessapi.getraventest.com/static/media/banks/${
                list?.length < 2
                  ? list[0]?.bank_code
                  : detail?.bank_code || detail?.bank?.value
              }.png ` ||
              `https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
                detail?.network
              )}.png`
            }
          >
            {" "}
            <figure className="img-box">
              <img src={bankIconDefault} alt="" className="img" />
            </figure>
          </ImageFragment>

          {/* <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div> */}
        </div>
        {/* amount date box styart */}
        <div className="amount-date-box">
          <p className="amount">
            {list?.length > 0 ? bulkAmount : amount || ""}
          </p>
          <p className="date grey-white-color-white">
            {valueSchedule
              ? formatDate(valueSchedule)
              : formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div className="middle-content-wrap border-theme-bottom">
        {list?.length > 1 ? (
          <>
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Recipients:</p>
              <div className="value">
                <div
                  onClick={() => {
                    viewAll ? setViewAll(false) : setViewAll(true);
                  }}
                  className="see-all-box"
                >
                  <span>({list?.length || 0})</span>
                  <p className="see-all-text">
                    {viewAll ? `Close` : `See all`}
                  </p>
                </div>
                {/*  */}
                <div
                  className={`view-all-box card-bg ${
                    viewAll && "view-all-box-show"
                  }`}
                >
                  {" "}
                  <div className="wrap">
                    {list?.map((chi, idx) => {
                      return (
                        <div key={idx} className="child-wrap">
                          <ImageFragment
                            url={
                              `https://businessapi.getraventest.com/static/media/banks/${
                                chi?.bank_code ||
                                chi?.bank?.value ||
                                formatMetaData(chi?.meta_data)?.bank_code
                              }.png ` ||
                              `https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
                                chi?.network || formatMetaData(chi?.meta_data)?.network
                              )}.png`
                            }
                            className={`border-theme`}
                            styles={{ borderRadius: "50%" }}
                          >
                            <figure className="img-box">
                              <img
                                src={
                                  chi?.network === "mtn"
                                    ? mtnICon
                                    : chi?.network === "glo"
                                    ? gloICon
                                    : chi?.network === "airtel"
                                    ? airtelICon
                                    : chi?.identifier
                                    ? bankIconDefaultRav
                                    : bankIcon
                                }
                                alt=""
                                className="img"
                              />
                            </figure>
                          </ImageFragment>

                          <div className="text-box">
                            <p className="name">
                              {`${
                                trimLongString(
                                  chi?.account_name ||
                                    chi?.phone ||
                                    formatMetaData(chi?.meta_data)
                                      ?.account_name ||
                                    `@${chi?.identifier}`,
                                  12
                                ) || "----"
                              } • ${formatNumWithCommaNairaSymbol(
                                chi?.amount
                              )}`}
                            </p>
                            <p className="text grey-white-color">{`${trimLongString(
                              chi?.bank?.label ||
                                chi?.bank ||
                                formatMetaData(chi?.meta_data)?.bank ||
                                formatMetaData(chi?.meta_data)?.code ||
                                "---",
                              17
                            )} • ${
                              chi?.identifier
                                ? ""
                                : trimLongString(
                                    chi?.account_number ||
                                      formatMetaData(chi?.meta_data)
                                        ?.account_number ||
                                      "--- ",
                                    11
                                  )
                            }`}</p>
                          </div>
                          {/* <div
                            onClick={() => {
                              handleRemoveFromList(chi);
                            }}
                            className="cancel-box"
                          >
                            <FaTimes className="icon" />
                          </div> */}
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">
                {RavenNumberFormat(detail?.requestData?.fee) || totalFee || 0}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Narration:</p>
              <div
                className={`value ${
                  narration?.length > 14 && "tooltip-hover-wrap"
                }`}
              >
                <RavenToolTip
                  position={`left`}
                  text={narration}
                  color="black-light"
                />
                {trimLongString(narration, 15) || ""}
              </div>
            </div>
            {/* box end */}
            {/* box end */}
          </>
        ) : (
          <>
            {list?.length > 0 && list[0]?.account_name && (
              <div className="box">
                <p className="label grey-white-color-white">Account Name:</p>
                <p className="value">
                  {list?.length > 0 ? list[0]?.account_name : ""}
                </p>
              </div>
            )}
            {/* box start */}
            {list?.length > 0 && list[0]?.account_number && (
              <div className="box">
                <p className="label grey-white-color-white">Account Number:</p>
                <p className="value">
                  {list?.length > 0
                    ? formatMetaData(list[0]?.meta_data)?.account_number ||
                      list[0]?.account_number
                    : ""}
                </p>
              </div>
            )}

            {list?.length > 0 &&
              formatMetaData(list[0]?.meta_data)?.account_number && (
                <div className="box">
                  <p className="label grey-white-color-white">
                    Account Number:
                  </p>
                  <p className="value">
                    {list?.length > 0
                      ? formatMetaData(list[0]?.meta_data)?.account_number ||
                        list[0]?.account_number
                      : ""}
                  </p>
                </div>
              )}

            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Bank Name:</p>
              <p className="value">
                {list?.length > 0
                  ? formatMetaData(list[0]?.meta_data)?.bank || list[0]?.bank
                  : ""}
              </p>
            </div>
            {/* box end */}
            {list?.length > 0 && list[0]?.identifier && (
              <>
                {" "}
                <div className="box">
                  <p className="label grey-white-color-white">
                    Raven Username:
                  </p>
                  <p className="value">
                    {list?.length > 0 ? `@${list[0]?.identifier}` : ""}
                  </p>
                </div>
                {/* <div className="box">
                  <p className="label grey-white-color-white">Account Name:</p>
                  <p className="value">
                    {list?.length > 0 ? list[0]?.account_name : ""}
                  </p>
                </div> */}
              </>
            )}
            {/* box start */}

            {/* box end */}
            {/* box start */}
            {/* <div className="box">
              <p className="label grey-white-color-white">Account Name:</p>
              <p className="value">{account_name || ""}</p>
            </div> */}
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Total Fee:</p>
              <div className="value">
                {" "}
                {RavenNumberFormat(detail?.requestData?.fee) || totalFee || 0}
              </div>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="label grey-white-color-white">Narration:</p>
              <div
                className={`value ${
                  narration?.length > 14 && "tooltip-hover-wrap"
                }`}
              >
                <RavenToolTip
                  position={`left`}
                  text={narration}
                  color="black-light"
                />
                {trimLongString(narration, 15) || ""}
              </div>
            </div>
            {/* box end */}
          </>
        )}
      </div>
      {/* middle content wrap end */}
      {/* bottom- content wrap start */}
      <div className="bottom-content-wrap">
        {/* <NewSelectScheduleBox
          id={`onudjhahabafw`}
          value={valueSchedule}
          textSchedule="Schedule this transaction"
          onChange={(e) => {
            onChangeSchedule(e);
          }}
          onReset={onReset}
        /> */}
        {/* bottom box start */}
        {/* <div className="bottom-box">
          <p className="text grey-white-color">
            {collapse
              ? `Save all as beneficiary`
              : `Save recipient as beneficiary`}
          </p>
          <RavenToggleSlide
            onChange={onChange}
            id={id}
            value={value}
            color={`purple-light`}
            checked={checked}
            //   className={`toggle-box-wrap`}
          />
        </div> */}
        {/* bottom box end */}
        {/* account drop wrap start */}
        <ChangeSourceAccount
          darkType={true}
          defaultObj={accountObj}
          dontShowChange
        />
        {/* account drop wrap end */}
        {/* template start ------------------ */}
      </div>
      {/* bottom- content wrap end */}
      {/* account drop wrap start */}

      {/* account drop wrap end */}
    </div>
  );
};

export default NewPreviewDetailBox;

{
  /* <div className="account-change-drop-wrap border-theme-top">
<p className="title grey-white-color">Source Account.</p>
<div className="change-wrap grey-bg-two">
  <div className="change-name-info">
    <div className="name-icon">
      <p className="name">{actionCtx?.currentAccount?.name}</p>
      <figure className="img-box">{InfoIcon}</figure>
    </div>
    <p className="label grey-white-color">N80,320.00</p>
  </div>
  <div
    onClick={() => {
      actionCtx.setCurrentAccountDrop(!actionCtx.currentAccountDrop);
    }}
    className="change-text"
  >
    <p className="text">Change</p>
  </div>
</div>
</div> */
}
