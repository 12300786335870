import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import TitletextBox from "../../../components/reusables/TitletextBox";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import "../../../styles/dashboard/global-account/SendMoneyGlobalAccount.css";
import bankIcon from "../../../assets/add-money-bank-transfer.svg";
// import cardIcon from "../../../assets/add-money-card-icon.svg";
import ActionContext from "../../../context/ActionContext";
import {
  // RavenCheckBox,
  RavenInputField,
  toast,
  // RavenToolTip,
} from "@ravenpay/raven-bank-ui";
// import { formatNumWithCommaNairaSymbol } from "../../../utils/helper/Helper";
import GlobalAmountInput from "../../../components/fragments/GlobalAmountInput";
import LockBox from "../../../components/fragments/LockBox";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getAllBeneficiaries,
  getAllCurrencies,
  getAllGlobalAccount,
  getCurrencyInput,
  getSingleGlobalAccount,
  sendDifCurGlobalAccount,
  sendSameCurGlobalAccount,
  // sendDifCurGlobalAccount,
  // sendSameCurGlobalAccount,
} from "../../../redux/globalAccount";
import {
  capitalizeFirstLetter,
  filterEmptyColumns,
  formatMetaData,
  formatNumWithCommaNaira,
  formatNumWithoutCommaNaira,
  formatNumberSpecail,
  getCurrencySymbol,
  reactSelectStyle,
  trimLongString,
} from "../../../utils/helper/Helper";
import NewCreateRecipientGlobalWrapIndex from "../create-recipient-global/NewRecipientGlobalAccount";
import {
  beneficiaryIcon,
  cardIconTwo,
  momoIcon,
  ravenIcon,
  swapIcon,
} from "../../../utils/IconStore";
import BeneficiaryModal from "./BeneficiaryModal";
import NewPreviewDetailBox from "./PreviewSwapContentBox";
import SuccessModal from "../../../components/reusables/SuccessModal";
import TransactionPinModal from "../../../components/reusables/TransactionPinModal";
import Skeleton from "react-loading-skeleton";
// import TermsAndConditionModal from "./TermsAndConditionModal";
// import CurrencySymbol from "./TestSymbol";

const SendMoneyGlobalAccount = () => {
  const navigate = useNavigate();
  const stepList = ["Enter details", "Enter amount", "Confirm details"];
  const [step, setStep] = useState(1);
  const actionCtx = useContext(ActionContext);
  // const [ravenName, setRavenName] = useState("");
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const [showBeneModal, setShowBeneModal] = useState(false);
  const [reciepientVal, setRecipientVal] = useState(false);
  const [methodOptionsList, setMethodOptionsList] = useState([]);
  const dispatch = useDispatch();
  const [countryOptions, setCountryOptions] = useState([]);
  const [details, setDetails] = useState({
    request_amount: "",
    email: "",
    request_description: "",
    expiry_date: "",
    recurring: false,
    agree: false,
    title: "",
    allow_edit_amount: false,
    sendCurrency: "",
    country: "",
    sendAmount: "",
    convertedAmount: "",
    transfer_pin: "",
    narration: "",
    delivery_method: "",
    // channel: ""
  });

  const rightArrowIcon = (
    <svg
      className="img"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M6 9.5L12 15.5L18 9.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const [activeSelect, setActiveSelect] = useState("Individual");
  const selectOptionList = ["Individual", "Business"];
  const [currencyOption, setCurrencyOption] = useState([]);
  const {
    // loadingTrans,
    single_global_account,
    loading,
    all_global_account,
    all_beneficiaries,
    all_currencies,
    loadingBene,
  } = useSelector((state) => state.global_account);

  const selectMethodList = [
    {
      title: "Bank Account",
      text: "Send to any bank account",
      icon: bankIcon,
      onAction: () => {
        actionCtx?.createRecipientGlobalFunc(true);
        actionCtx?.createRecipientGlobalTypeFunc("bank");
      },
      channel: "bank_account",
    },
    {
      title: "Momo",
      text: "Send to a Momo account",
      iconTwo: momoIcon,
      onAction: () => {
        actionCtx?.createRecipientGlobalFunc(true);
        actionCtx?.createRecipientGlobalTypeFunc("bank");
      },
      channel: "mobile_money",
    },
    {
      title: "Raven Account",
      text: "Send money to a Raven user super fast",
      iconTwo: ravenIcon,
      channel: "raven",
      coming_soon: true,
    },
    {
      title: "Card",
      text: "Send to card recipient",
      iconTwo: cardIconTwo,
      onAction: () => {
        actionCtx?.createRecipientGlobalFunc(true);
        actionCtx?.createRecipientGlobalTypeFunc("bank");
      },
      channel: "card",
      coming_soon: true,
    },
    {
      title: "Beneficiaries",
      text: "Send from your beneficiary",
      iconTwo: beneficiaryIcon,
    },
  ];
  // const [accountObj, setAccountObj] =
  useEffect(() => {
    const id = localStorage?.getItem("global-acc-id");
    // console.log(id);
    if (id) {
      getSingleGlobalAccFunc(id);
    } else {
      navigate("/dashboard-global-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleGlobalAccFunc = async (param) => {
    // console.log(param, "------ud-----");
    // for transactions
    // console.log(param);
    const obj = {
      id: param,
    };
    // console.log(obj);
    if (Object?.keys(single_global_account)?.length < 1) {
      dispatch(getSingleGlobalAccount(obj));
    }

    // end for transaction
  };

  useEffect(() => {
    if (Object?.keys(all_global_account)?.length < 1) {
      dispatch(getAllGlobalAccount());
    }
    if (Object?.keys(all_currencies)?.length < 1) {
      dispatch(getAllCurrencies());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [currencyAccountList, setCurrencyAccountList] = useState([]);
  useEffect(() => {
    if (
      all_global_account?.global_accounts_available_for_current_business
        ?.length > 1
    ) {
      const newList =
        all_global_account?.global_accounts_available_for_current_business?.filter(
          (chi) => chi?.id !== actionCtx?.globalCurrency?.id
        );
      // console.log(newList);
      setCurrencyAccountList(newList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_global_account, single_global_account, actionCtx?.globalCurrency]);
  const [accountToUse, setAccountToUse] = useState({});
  useEffect(() => {
    if (
      all_global_account?.global_accounts_available_for_current_business
        ?.length > 0
    ) {
      const newObj =
        all_global_account?.global_accounts_available_for_current_business?.find(
          (chi) =>
            String(chi?.id) ===
            String(single_global_account?.global_account?.id)
        );
      // const newCurrencyList =
      //   all_global_account?.global_accounts_available_for_current_business?.map(
      //     (chi) => {
      //       return { ...chi, label: chi?.currency, value: chi?.id };
      //     }
      //   );
      const newCurrencyList = all_currencies?.all_support_currencies?.map(
        (chi) => {
          return {
            ...chi,
            label: `${chi?.currency}  • ${chi?.name}`,
            value: chi?.currency,
          };
        }
      );

      setCurrencyOption(newCurrencyList);
      const objTwo = { ...newObj, value: newObj?.id, label: newObj?.currency };
      // console.log(objTwo);
      setAccountToUse(objTwo);
      actionCtx?.setGlobalCurrency(objTwo);
      // getDeliveryMethodFunc(newObj?.currency);
      // getCurrencyInputFunc(newObj?.currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_global_account, all_global_account, all_currencies]);
  const getDeliveryMethodFunc = (param) => {
    const methodObj = all_currencies?.all_support_currencies?.find(
      (chi) => chi?.currency === param
    );
    const method = methodObj?.delivery_methods;
    // console.log(combineArraysByChannel(method, selectMethodList));
    setMethodOptionsList(combineArraysByChannel(method, selectMethodList));
  };

  function combineArraysByChannel(arr1, arr2) {
    // Create a map from the first array using the 'channel' as the key
    const map = new Map(arr1?.map((item) => [item.channel, item]));

    // Iterate through the second array and combine objects with matching 'channel'
    const combined = arr2
      ?.filter((item) => map?.has(item.channel))
      ?.map((item) => ({ ...map?.get(item.channel), ...item }));

    return combined;
  }

  const beneOptionList = [
    {
      title: "Beneficiaries",
      text: "Send from your beneficiary",
      iconTwo: beneficiaryIcon,
      onAction: () => {
        setShowBeneModal(true);
      },
      bene_num: all_beneficiaries?.beneficiaries?.length || "0",
    },
  ];
  const [inputObjList, setInputObjList] = useState([]);
  const getCurrencyInputFunc = async (param) => {
    if (param) {
      const data = await dispatch(getCurrencyInput({ currency: param }));
      // console.log(data);
      if (data?.payload?.status === "success") {
        const inputOptions = data?.payload?.data?.currency_inputs?.data;
        // console.log(inputOptions);
        const inputOptionTwo = inputOptions?.map((chi) => {
          const obj = {
            ...chi,
            value: chi?.value || "",
            input_type:
              chi?.input_type === "digit"
                ? "account-number"
                : chi?.input_type === "selector"
                ? "select"
                : chi?.input_type,
            options:
              chi?.options?.length > 0
                ? chi?.options?.map((chid) => {
                    return { ...chid, label: chid?.key || chid?.value };
                  })
                : "",
          };
          return obj;
        });
        setInputObjList(inputOptionTwo);
        actionCtx?.setGlobalCurrencyInputs(
          createObjectFromFields(inputOptions)
        );
      }
    }
  };
  const [checkChangeCountry, setCheckChange] = useState(false);
  useEffect(() => {
    if (Object?.keys(details?.country)?.length > 0 && !checkChangeCountry) {
      // console.log(inputObjList);
      const inputOptionTwo = inputObjList?.map((chi) =>
        chi?.key === "beneficiary_country"
          ? {
              ...chi,
              value: details?.country || "",
              input_type: "select",
              options: countryOptions,
            }
          : chi
      );
      // console.log(inputOptionTwo);
      setInputObjList(inputOptionTwo);
      setCheckChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.country, inputObjList, countryOptions, details?.sendCurrency]);

  // const changeCountryTypeToSelect = (value, options) => {
  //   console.log(value);
  //   if (inputObjList?.length > 0) {
  //     const inputOptionTwo = inputObjList?.map((chi) =>
  //       chi?.key === "beneficiary_country"
  //         ? {
  //             ...chi,
  //             value: value || "",
  //             input_type: "select",
  //             options: options,
  //           }
  //         : chi
  //     );
  //     console.log(inputOptionTwo);
  //     setInputObjList(inputOptionTwo);
  //   }
  // };

  const handleChangeInputValue = (e) => {
    const { name, value, type } = e.target;
    if (type && type === "select") {
      // console.log(e);
      const newList = inputObjList?.map((chi) =>
        chi?.key === name ? { ...chi, value: value } : chi
      );
      setInputObjList(newList);
    } else {
      const newList = inputObjList?.map((chi) =>
        chi?.key === name ? { ...chi, value: value } : chi
      );
      setInputObjList(newList);
    }
  };

  function createObjectFromFields(fields) {
    if (fields?.length > 0) {
      return fields?.reduce((result, field) => {
        if (field?.visible) {
          result[field.key] = true;
        }
        return result;
      }, {});
    }
  }

  // const getSymbolTotalAmount = (param) => {
  //   const obj = all_global_account?.all_support_currencies?.find(
  //     (chi) => chi?.currency === param
  //   );
  //   const symbol = obj?.symbol;
  //   return symbol;
  // };
  const [savedRate, setSavedRate] = useState("");
  useEffect(() => {
    if (all_currencies?.all_support_currencies?.length > 0) {
      const newObj = all_currencies?.all_support_currencies?.find(
        (chi) => chi?.currency === actionCtx?.globalCurrency?.currency
      );
      const newObjList = newObj?.rates;
      // console.log(newObjList);
      const objRate = newObjList?.find(
        (chi) => chi?.currency_to === details?.sendCurrency?.currency
      );
      const rate =
        details?.sendCurrency?.currency === actionCtx?.globalCurrency?.currency
          ? 1
          : objRate?.rate;
      setSavedRate(formatNumberSpecail(rate));
      if (rate) {
        const value =
          Number(rate) *
          Number(formatNumWithoutCommaNaira(details?.sendAmount));
        // console.log(value);
        setDetails((prev) => {
          return { ...prev, convertedAmount: value };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.sendCurrency, actionCtx?.globalCurrency, details?.sendAmount]);

  // useEffect(() => {
  //   if (
  //     actionCtx?.globalCurrency &&
  //     Object?.keys(all_beneficiaries)?.length < 1
  //   ) {
  //     dispatch(
  //       getAllBeneficiaries({ currency: actionCtx?.globalCurrency?.currency })
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [actionCtx?.globalCurrency]);

  const formatBeneficiaryList = (list) => {
    if (list?.beneficiaries?.length > 0) {
      const newList = list?.beneficiaries?.map((chi) => {
        return {
          ...chi,
          label: `${
            trimLongString(capitalizeFirstLetter(chi?.beneficiary_name), 22) ||
            ""
          } || ${chi?.account_number || ""}`,
          value: chi?.id,
        };
      });
      return newList;
    }
  };

  const handleSubmit = async (pin, token) => {
    let val;
    if (
      actionCtx?.globalCurrency?.currency === details?.sendCurrency?.currency
    ) {
      const obj = {
        amount: formatNumWithoutCommaNaira(details?.sendAmount),
        recipient_id: String(reciepientVal?.id),
        currency: actionCtx?.globalCurrency?.currency || "",
        narration: details?.narration || "",
        transfer_pin: pin || details?.transfer_pin,
        two_factor_token: token,
        // recipient_id: String(reciepientVal?.id),
        mobile_number:
          formatMetaData(reciepientVal?.payload)?.mobile_number || "",
        delivery_method:
          reciepientVal?.delivery_method || details?.delivery_method,
      };
      // console.log(filterEmptyColumns(obj));
      const data = await dispatch(
        sendSameCurGlobalAccount(filterEmptyColumns(obj))
      );
      val = data;
    } else {
      const obj = {
        amount: formatNumWithoutCommaNaira(details?.sendAmount),
        recipient_id: String(reciepientVal?.id),
        narration: details?.narration || "",
        transfer_pin: pin || details?.transfer_pin,
        two_factor_token: token,
        mobile_number:
          formatMetaData(reciepientVal?.payload)?.mobile_number || "",
        delivery_method:
          reciepientVal?.delivery_method || details?.delivery_method || "",
        to: details?.sendCurrency?.currency || "bank_transfer",
        from: actionCtx?.globalCurrency?.currency || "",
      };
      // console.log(filterEmptyColumns(obj));
      const data = await dispatch(
        sendDifCurGlobalAccount(filterEmptyColumns(filterEmptyColumns(obj)))
      );
      val = data;
    }
    if (val?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    setDetails((prev) => {
      return {
        ...prev,
        sendCurrency: {},
        request_amount: "",
        email: "",
        request_description: "",
        expiry_date: "",
        recurring: false,
        agree: false,
        title: "",
        allow_edit_amount: false,
        country: "",
        sendAmount: "",
        convertedAmount: "",
        transfer_pin: "",
        narration: "",
        delivery_method: "",
      };
    });
    dispatch(getAllGlobalAccount());
    setShowModal((prev) => {
      return { ...prev, pin: false };
    });
    navigate("/dashboard-global-account");
  };

  const handleCountryList = (param) => {
    if (param?.length > 0) {
      const newList = param?.map((chi) => {
        return { ...chi, label: chi?.country, value: chi?.country };
      });
      // console.log(newList);
      return newList;
    }
  };

  const removeNgnFromList = (param) => {
    if (param?.length > 0) {
      const newList = param?.filter((chi) => chi?.currency !== "NGN");
      return newList;
    }
  };

  const [countryError, setCountryError] = useState("");

  const { user_resource_and_permissions } = useSelector((state) => state.user);
  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_setup_global_account"
      ) ||
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_setup_foreign_beneficiary"
      )
    ) {
      navigate("/dashboard-global-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Send ${details?.sendCurrency?.currency || "Money"}`}
          text={`Transfer ${
            details?.sendCurrency?.currency || "money"
          } to a reciepient`}
          back={true}
          onBack={() => {
            navigate("/dashboard-global-account-single");
          }}
        />
        {/* title box end */}
        {/* container start */}
        <ContainerWrapper
          width={`100%`}
          onBack={() => {
            if (step > 1) {
              setStep(step - 1);
            }
          }}
          stepList={stepList}
          activeStep={step}
          back={step > 1}
          disabled={
            step === 2
              ? !details?.sendAmount ||
                !reciepientVal ||
                !details?.convertedAmount ||
                String(details?.sendAmount) === "0"
              : step === 3
              ? !details?.narration ||
                !details?.sendAmount ||
                !reciepientVal ||
                !details?.convertedAmount
              : false
          }
          onStepClick={(param) => {
            // setStep(param)
            if (
              details?.sendAmount &&
              reciepientVal &&
              details?.convertedAmount &&
              details?.sendCurrency
            ) {
              setStep(param);
            }
          }}
          stepClickable={
            details?.sendAmount &&
            reciepientVal &&
            details?.convertedAmount &&
            details?.sendCurrency
            // &&
            // !Number?.isNaN(formatNumWithoutCommaNaira(details?.sendAmount))
            //   ? true
            //   : Number?.isNaN(formatNumWithoutCommaNaira(details?.sendAmount))
            //   ? false
            //   : false
          }
          btnText={step === 3 ? "Enter pin" : step === 2 ? "Proceed" : ""}
          onBtnClick={() => {
            if (step === 1) {
              setStep(2);
            }
            if (step === 2) {
              // console.log(details);
              // console.log(reciepientVal);
              if (
                Number(
                  formatNumWithoutCommaNaira(actionCtx?.globalCurrency?.balance)
                ) === 0 ||
                Number(
                  formatNumWithoutCommaNaira(actionCtx?.globalCurrency?.balance)
                ) < Number(details?.sendAmount)
              ) {
                toast.error(
                  `Insufficient ${actionCtx?.globalCurrency?.currency} balance`,
                  {}
                );
                return;
              }
              setStep(3);
            }
            if (step === 3) {
              setShowModal((prev) => {
                return { ...prev, pin: true };
              });
            }
          }}
        >
          {/* send- money global account start */}
          <div
            style={{ marginTop: "unset" }}
            className="send-money-global-account-index-box"
            // onClick={() => {
            //   console.log(inputObjList);
            // }}
          >
            {/* step one start ----------- */}
            {step === 1 && (
              <>
                <div
                  // onClick={() => {
                  //   console.log(inputObj);
                  // }}
                  className="select-form-wrap-box"
                >
                  {" "}
                  <div className="form-box-wrap">
                    <RavenInputField
                      label="Currency* "
                      placeholder="Select currency"
                      color="black-light"
                      type="select"
                      value={details?.sendCurrency}
                      selectOption={removeNgnFromList(currencyOption)}
                      selectStyles={reactSelectStyle}
                      onChange={(param) => {
                        if (param.countries?.length === 1) {
                          // changeCountryTypeToSelect(
                          //   handleCountryList(param.countries)[0],
                          //   handleCountryList(param.countries)
                          // );
                        }
                        // console.log(param);
                        setCheckChange(false);
                        setCountryOptions(handleCountryList(param.countries));

                        setDetails((prev) => {
                          return {
                            ...prev,
                            sendCurrency: param,
                            // country:
                            //   param.countries?.length === 1
                            //     ? handleCountryList(param.countries)[0]
                            //     : "",
                            country: "",
                          };
                        });
                        dispatch(
                          getAllBeneficiaries({ currency: param?.currency })
                        );
                        // setAccountToUse(param)
                        getCurrencyInputFunc(param?.currency);
                        getDeliveryMethodFunc(param?.currency);
                        // localStorage?.setItem("global-acc-id", param?.id);
                        // actionCtx?.setGlobalCurrency(param);
                      }}
                    />
                    {/* {details?.sendCurrency?.countries && ( */}
                    <RavenInputField
                      label="Country* "
                      placeholder="Dropdown Option"
                      color="black-light"
                      type="select"
                      value={details?.country}
                      onChange={(param) => {
                        setDetails((prev) => {
                          return { ...prev, country: param };
                        });
                        setCheckChange(false);
                        countryError && setCountryError("");
                        // changeCountryTypeToSelect(param, countryOptions);
                      }}
                      name={`country`}
                      selectOption={countryOptions}
                      selectStyles={reactSelectStyle}
                      showError={countryError}
                      errorText={countryError}
                    />
                    {/* )} */}
                  </div>
                </div>
                {methodOptionsList?.length > 0 &&
                details?.sendCurrency &&
                details?.country ? (
                  <div
                    style={{ marginTop: "3rem" }}
                    className="select-form-wrap-box "
                  >
                    <div className="content-box-two border-theme  animate-move-up-class">
                      <p
                        style={{
                          textTransform: "uppercase",
                          marginBottom: "1rem",
                          fontSize: "1.5rem",
                        }}
                        className="title grey-white-color"
                      >
                        Select a delivery method
                      </p>
                      <>
                        {methodOptionsList?.length > 0 && (
                          <>
                            {methodOptionsList?.map((chi, idx) => {
                              const {
                                icon,
                                text,
                                title,
                                coming_soon,
                                iconTwo,
                                // link,
                                onAction,
                              } = chi;
                              if (coming_soon) {
                                return (
                                  <React.Fragment KEY={idx}></React.Fragment>
                                );
                              }
                              return (
                                <div
                                  key={idx}
                                  className={`add-money-item ${
                                    // all_beneficiaries?.beneficiaries?.length > 0 &&
                                    "border-theme-bottom"
                                  } ${coming_soon && "wrap-coming-soon"}`}
                                  onClick={() => {
                                    if (
                                      details?.sendCurrency?.countries &&
                                      !details?.country
                                    ) {
                                      setCountryError("Please select country.");
                                      return;
                                    }
                                    setDetails((prev) => {
                                      return {
                                        ...prev,
                                        delivery_method: chi?.channel,
                                      };
                                    });
                                    onAction && onAction();
                                  }}
                                  style={{
                                    border:
                                      methodOptionsList?.length - 1 === idx &&
                                      all_beneficiaries?.beneficiaries?.length <
                                        1 &&
                                      "none",
                                  }}
                                >
                                  <div
                                    style={{
                                      cursor: coming_soon && "not-allowed",
                                    }}
                                    className="top-box"
                                  >
                                    <div className="img-wrap">
                                      <figure className="img-box">
                                        {iconTwo ? (
                                          <>{iconTwo}</>
                                        ) : (
                                          <img
                                            src={icon}
                                            alt=""
                                            className="img"
                                          />
                                        )}
                                      </figure>
                                    </div>
                                    <div
                                      // onClick={() => {
                                      //   console.log(actionCtx?.currentAccount);
                                      // }}
                                      className="title-text-box"
                                    >
                                      <p className="title">{title}</p>
                                      <p className="text">{text}</p>
                                    </div>
                                    <div className="arrow-coming-soon-box">
                                      {coming_soon ? (
                                        <>
                                          {" "}
                                          <LockBox
                                            text="Coming soon"
                                            className="coming-soon"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <figure
                                            className={`img-box-arrow ${
                                              activeSelect === title &&
                                              `img-box-arrow-rotate`
                                            }`}
                                          >
                                            {rightArrowIcon}
                                          </figure>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {all_beneficiaries?.beneficiaries?.length > 0 &&
                              beneOptionList?.map((chi, idx) => {
                                const {
                                  icon,
                                  text,
                                  title,
                                  coming_soon,
                                  iconTwo,
                                  // link,
                                  onAction,
                                  // bene_num,
                                } = chi;
                                return (
                                  <div
                                    key={idx}
                                    className={`add-money-item  ${
                                      coming_soon && "wrap-coming-soon"
                                    }`}
                                    onClick={() => {
                                      if (!loadingBene) {
                                        onAction && onAction();
                                      }
                                    }}
                                  >
                                    <div
                                      style={{
                                        cursor: coming_soon && "not-allowed",
                                      }}
                                      className="top-box"
                                    >
                                      <div className="img-wrap">
                                        <figure className="img-box">
                                          {iconTwo ? (
                                            <>{iconTwo}</>
                                          ) : (
                                            <img
                                              src={icon}
                                              alt=""
                                              className="img"
                                            />
                                          )}
                                        </figure>
                                      </div>
                                      <div
                                        // onClick={() => {
                                        //   console.log(actionCtx?.currentAccount);
                                        // }}
                                        className="title-text-box"
                                      >
                                        <div
                                          // style={{
                                          //   display: "flex",
                                          //   alignItems: "center",
                                          // }}
                                          className="title"
                                        >
                                          {title}{" "}
                                          {all_beneficiaries?.beneficiaries
                                            ?.length > 0 && (
                                            <>
                                              {loadingBene ? (
                                                <>
                                                  <Skeleton
                                                    width={20}
                                                    height={20}
                                                    style={{
                                                      borderRadius: "4rem",
                                                    }}
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  <div className="title-total black-fade-white-bg">
                                                    <span>
                                                      {all_beneficiaries
                                                        ?.beneficiaries
                                                        ?.length || "0"}
                                                    </span>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </div>
                                        <p className="text">{text}</p>
                                      </div>
                                      <div className="arrow-coming-soon-box">
                                        {coming_soon ? (
                                          <>
                                            {" "}
                                            <LockBox
                                              text="Coming soon"
                                              className="coming-soon"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <figure
                                              className={`img-box-arrow ${
                                                activeSelect === title &&
                                                `img-box-arrow-rotate`
                                              }`}
                                            >
                                              {rightArrowIcon}
                                            </figure>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            {/* step one end ----------- */}
            {/* step two start ----------- */}
            {step === 2 && (
              <>
                <div
                  style={{
                    marginTop: "3rem",
                    position: "relative",
                    zIndex: 20,
                  }}
                  className="select-form-wrap-box"
                >
                  <RavenInputField
                    label="Recipient"
                    type="select"
                    color="black-light"
                    placeholder="Select beneficiary"
                    selectOption={formatBeneficiaryList(all_beneficiaries)}
                    value={reciepientVal}
                    selectStyles={reactSelectStyle}
                    onChange={(param) => {
                      setRecipientVal(param);
                    }}
                  />
                </div>
                <div
                  style={{ marginTop: "2.5rem" }}
                  className="select-form-wrap-box "
                >
                  <>
                    <div
                      style={{ position: "relative", zIndex: 10 }}
                      className="two-amount-global-box"
                      // onClick={() => {
                      //   console.log(actionCtx?.globalCurrency);
                      // }}
                    >
                      {/* form box wrap grey start */}
                      <div
                        style={{ position: "relative", zIndex: 2 }}
                        className="amount-grey-wrap-box grey-bg"
                      >
                        <GlobalAmountInput
                          dropClass={`grey-bg`}
                          wrapperClass={`white-black-bg`}
                          label={`You send`}
                          placeholder={`Enter amount `}
                          key={"kqjhghs"}
                          amountValue={details?.sendAmount}
                          selectedCurrency={
                            actionCtx?.globalCurrency || accountToUse
                          }
                          currencyList={currencyAccountList}
                          handleChange={(e) => {
                            setDetails((prev) => {
                              return {
                                ...prev,
                                sendAmount: formatNumWithoutCommaNaira(
                                  e.target.value
                                ),
                              };
                            });
                          }}
                          hideDrop={currencyAccountList?.length < 1}
                          setCurrencyList={(param) => {
                            // console.log(param);
                            localStorage.setItem("global-acc-id", param?.id);
                            // dispatch(
                            //   getAllBeneficiaries({ currency: param?.currency })
                            // );
                            setAccountToUse(param);
                            actionCtx?.setGlobalCurrency(param);
                          }}
                          labelSpanText={`${getCurrencySymbol(
                            actionCtx?.globalCurrency?.currency ||
                              accountToUse?.currency ||
                              ""
                          )}${formatNumWithCommaNaira(
                            actionCtx?.globalCurrency?.balance ||
                              accountToUse?.balance ||
                              "0"
                          )}`}
                          numberPrefix={getCurrencySymbol(
                            actionCtx?.globalCurrency?.currency ||
                              accountToUse?.currency
                          )}
                        />
                      </div>
                      {/* form box wrap grey end */}
                      {/* form box wrap grey start */}
                      <div
                        style={{ position: "relative", zIndex: 1 }}
                        className="amount-grey-wrap-box grey-bg"
                      >
                        <GlobalAmountInput
                          hideDrop={true}
                          dropClass={`grey-bg`}
                          wrapperClass={`white-black-bg`}
                          label={`Recipient Receives `}
                          placeholder={` `}
                          disabled={true}
                          selectedCurrency={details?.sendCurrency}
                          setCurrencyList={(param) => {
                            setDetails((prev) => {
                              return { ...prev, sendCurrency: param };
                            });
                          }}
                          amountValue={
                            !details?.sendAmount ? "" : details?.convertedAmount
                          }
                          numberPrefix={getCurrencySymbol(
                            details?.sendCurrency?.currency
                          )}
                          dropStyles={{
                            maxHeight: "22rem",
                            paddingBottom: "3rem",
                          }}
                        />
                      </div>
                      {/* form box wrap grey end */}
                      {/* swap-icon box start */}
                      <div
                        style={{ zIndex: 30 }}
                        className="swap-global-icon-box white-black-bg card-bg"
                      >
                        <figure className="img-box">{swapIcon}</figure>
                      </div>
                      {/* swap icon box end */}
                    </div>
                    {/*  swap fee exchange rate box start */}
                    <div className="swap-fee-exchange-rate-box">
                      {/* label value dot box start */}
                      {/* <div className="label-value-dot-box">
                      <p className="text">
                        {" "}
                        <span className="grey-white-color">Swap Fee:</span>
                        <span className="value"> $5.00</span>
                      </p>
                    </div> */}
                      {/*label value dot box end  */}
                      {/* label value dot box start */}
                      <div
                        style={{ marginTop: "2.5rem" }}
                        className="label-value-dot-box"
                      >
                        <p className="text">
                          {" "}
                          <span className="grey-white-color">
                            Exchange Rate:
                          </span>
                          <span className="value">{`1 ${
                            actionCtx?.globalCurrency?.currency || ""
                          } = ${savedRate || ""} ${
                            details?.sendCurrency?.currency
                          }`}</span>
                        </p>
                      </div>
                      {/*label value dot box end  */}
                    </div>
                    {/*  swap fee exchange rate box end */}
                  </>
                </div>
              </>
            )}
            {/* step two end ----------- */}
            {/* step three start */}
            {step === 3 && (
              <>
                <div
                  style={{
                    marginTop: "3rem",
                    position: "relative",
                    zIndex: 20,
                  }}
                  className="select-form-wrap-box"
                >
                  {" "}
                  <NewPreviewDetailBox
                    notSwap={true}
                    amount={formatNumWithCommaNaira(details?.sendAmount)}
                    convertedAmount={formatNumWithCommaNaira(
                      details?.convertedAmount
                    )}
                    convertedCurrency={details?.sendCurrency?.currency}
                    account_name={capitalizeFirstLetter(
                      reciepientVal?.beneficiary_name
                    )}
                    exChangeRate={`1 ${
                      actionCtx?.globalCurrency?.currency || ""
                    } = ${savedRate || ""} ${details?.sendCurrency?.currency}`}
                    reciepientVal={reciepientVal}
                    details={details}
                    handleChange={handleChange}
                  />
                </div>
              </>
            )}
            {/* step three end */}
            {/* old file */}
          </div>
          {/* send- money global account end */}
        </ContainerWrapper>
        {/* container end */}
      </DashboardLayout>
      {actionCtx?.createRecipientGlobal && (
        <NewCreateRecipientGlobalWrapIndex
          selectOptionList={selectOptionList}
          activeSelect={activeSelect}
          setActiveSelect={setActiveSelect}
          setOptionList={(e) => {
            handleChangeInputValue(e);
          }}
          inputObjList={inputObjList}
          detail={details}
          handleFinishFunc={(param) => {
            // console.log(param);
            setInputObjList([]);
            const newObj = {
              ...param,
              value: param?.id,
              label: `${
                param?.beneficiary_name
                  ? trimLongString(
                      capitalizeFirstLetter(param?.beneficiary_name),
                      22
                    )
                  : param?.first_name
                  ? `${param?.first_name || ""} ${param?.last_name || ""}`
                  : ""
              } || ${param?.account_number || ""}`,
            };
            // console.log(newObj);
            setRecipientVal(newObj);
            setStep(2);
          }}
        />
      )}
      {/* bene modal start */}
      <BeneficiaryModal
        visible={showBeneModal}
        onClose={() => {
          setShowBeneModal(false);
        }}
        onSelect={(param) => {
          if (formatMetaData(param?.payload)?.narration) {
            setDetails((prev) => {
              return {
                ...prev,
                narration: formatMetaData(param?.payload)?.narration,
                delivery_method:
                  param?.delivery_method ||
                  formatMetaData(param?.payload)?.delivery_method,
              };
            });
          }

          setRecipientVal(param);
          setShowBeneModal(false);
          setStep(2);
        }}
      />
      {/* bene modal end */}
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Transaction Successful."
        text={`You should also receive a confirmation email with the details of the transaction. We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default SendMoneyGlobalAccount;
