import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/bill-payment/Airtime.css";
import mtnICon from "../../../../assets/bill-mtn-icon.svg";
import airtelICon from "../../../../assets/bill-airtel-icon.svg";
import mobileICon from "../../../../assets/bill-moblie-icon.svg";
import gloICon from "../../../../assets/bill-glo-icon.svg";
import addIcon from "../../../../assets/add-icon-form.svg";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";
// import PreviewAirtimeDetailsBox from "./PreviewAirtime";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";
import { FaCheck, FaTimes } from "react-icons/fa";
import { RavenInputField, toast } from "@ravenpay/raven-bank-ui";
// import PreviewDataDetailsBox from "./PreviewDataBox";
import {
  filterEmptyColumns,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  getAllAmount,
  reactSelectStyle,
  trimLongString,
} from "../../../../utils/helper/Helper";
import { DetectNetwork } from "../../../../utils/helper/DetectNetwork";
import ActionContext from "../../../../context/ActionContext";
import { useSelector, useDispatch } from "react-redux";
import { buyData, getDataPlans } from "../../../../redux/billPayment";
import SuccessModal from "../../../../components/reusables/SuccessModal";
// import moment from "moment";
import { getAllAccounts, getUser } from "../../../../redux/user";
import useWindowSize from "../../../../utils/helper/UseWindowSize";
import NewPreviewDetailBox from "./NewPreviewBox";

const DashboardBillPaymentData = () => {
  const [previewOpen, setPreviewOpen] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [urlBack, setUrlBack] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { loading, dataPlans } = useSelector((state) => state.billPayment);
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [viewAll, setViewAll] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [details, setDetails] = useState({
    number: "",
    amount: "",
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    schedule: "",
    provider: "",
    data_plan: "",
    code: "",
    phone: "",
    provider_code: "",
    network: "",
  });

  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });

  const providerList = [
    { name: "mtn", icon: mtnICon },
    { name: "mobile", icon: mobileICon },
    { name: "airtel", icon: airtelICon },
    { name: "glo", icon: gloICon },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };
  const stepList = ["Transaction detail", "Confirm Transactions"];
  useState(() => {
    const url = window?.location?.href?.split("?")[1];
    if (url) {
      setUrlBack(url);
    }
  }, []);

  DetectNetwork(
    formatNumWithoutCommaNaira(`0${details?.phone}`),
    setSelectedProvider
  );

  const handleBulkTransfer = () => {
    const checkList = details?.bulk_list?.some(
      (chi) => chi?.phone === details?.phone
    );
    if (!checkList) {
      const obj = {
        phone: details?.phone,
        code: details?.data_plan?.code,
        provider_code: details?.data_plan?.provider_code,
        network: selectedProvider,
        amount: details?.amount,
        data_plan: details?.data_plan?.name,
      };
      const newList = [obj, ...details?.bulk_list];
      //   console.log(newList);
      setSelectedProvider("");
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          phone: "",
          amount: "",
          narration: "Sent from raven",
          provider: "",
          data_plan: "",
        };
      });
    } else {
      toast.error("Recepient already exist in bulk list");
    }
  };

  const handleRemoveFromList = (obj) => {
    if (details?.bulk_list?.length === 1) {
      setViewAll(false);
    }
    const newList = details?.bulk_list?.filter(
      (chi) => chi?.phone !== obj?.phone
    );
    setDetails((prev) => {
      return {
        ...prev,
        bulk_list: newList,
      };
    });
  };

  // useEffect(() => {
  //   if (
  //     !user_resource_and_permissions?.allowed_permissions?.includes(
  //       "can_view_bill_payments"
  //     )
  //   ){
  //     navigate("/dashboard-bill-payment")
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user_resource_and_permissions])

  const handleAddBeneficiary = (obj, val) => {
    if (details?.bulk_list?.length > 0) {
      const newList = details?.bulk_list?.map((chi) =>
        chi?.phone === obj?.phone ? { ...chi, beneficiary: val } : chi
      );
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          number: "",
          amount: "",
          narration: "Sent from raven",
          provider: "",
          data_plan: "",
        };
      });
    } else {
      const newObj = { ...details, beneficiary: val };
      setDetails(newObj);
    }
  };

  const handleNextStep = (param) => {
    if (param === "one") {
      setStep(2);
      console.log(details);
    }
    if (param === "two") {
      if (user?.pin_status === 0) {
        actionCtx.setCheckShow("nine");
      } else {
        setShowModal((prev) => {
          return { ...prev, pin: true };
        });
      }
      // setShowModal((prev) => {
      //   return { ...prev, pin: true };
      // });
    }
  };

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name, provider_code } = chi;
      return {
        ...chi,
        label: name,
        value: code,
        provider_code: String(provider_code),
      };
    });
    return list;
  };

  useEffect(() => {
    if (selectedProvider) {
      getDataPlanFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider]);

  const getDataPlanFunc = async () => {
    const obj = {
      network: selectedProvider === "mobile" ? "etisalat" : selectedProvider,
    };
    dispatch(getDataPlans(obj));
  };

  useEffect(() => {
    setDetails((prev) => {
      return { ...prev, data_plan: "" };
    });
  }, [selectedProvider]);

  const handleSubmit = async (pin, token) => {
    let val;
    if (details?.bulk_list.length > 0) {
      const newList = details?.bulk_list?.map((chi) => {
        return { ...chi, phone: `0${formatNumWithoutCommaNaira(chi?.phone)}` };
      });
      const obj = {
        recipients: newList,
        type: "1",
        user_pin: pin, 
        two_factor_token: token,
        schedule: fromDate ? "later" : "now",
        schedule_date: fromDate ? fromDate : "",
        selected_account_number: actionCtx?.currentAccount?.account_number,
      };
      val = obj;
    } else {
      const obj = {
        recipients: [
          {
            code: details?.data_plan?.code,
            phone: `0${details?.phone}`,
            provider_code: details?.data_plan?.provider_code,
            network: selectedProvider,
          },
        ],
        type: "0",
        user_pin: pin,
        two_factor_token: token,
        schedule: fromDate ? "later" : "now",
        schedule_date: fromDate ? fromDate : "",
        selected_account_number: actionCtx?.currentAccount?.account_number,
      };
      val = obj;
    }
    // console.log(val);
    // return;
    const data = await dispatch(buyData(filterEmptyColumns(val)));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    dispatch(getUser());
    dispatch(getAllAccounts());
    setSelectedProvider("");
    setDetails({
      number: "",
      amount: "",
      narration: "Sent from raven",
      bulk_list: [],
      beneficiary: false,
      schedule: "",
      provider: "",
      data_plan: "",
      code: "",
      phone: "",
      provider_code: "",
      network: "",
    });
    setStep(1);
    setShowModal((prev) => {
      return { ...prev, success: true, pin: false };
    });
  };

  const size = useWindowSize();

  useEffect(() => {
    if (
      actionCtx?.currentAccount?.authorization_level !==
      ("initiator_approver" || "initiator") && actionCtx?.currentAccount?.authorization_level !==
      ("initiator" || "initiator_approver" )
    ) {
      navigate("/dashboard-bill-payment");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Buy Data`}
          text={`Buy data with any network`}
          back={true}
          onBack={() => {
            urlBack
              ? navigate(`/${urlBack}`)
              : navigate("/dashboard-bill-payment");
          }}
        />
        {/* title box end */}
        <ContainerWrapper
          width={size?.width < 900 && `100%`}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          activeStep={step}
          stepList={stepList}
          btnText={`Complete Transaction`}
          disabled={
            step === 1
              ? details?.bulk_list.length > 0
                ? details?.bulk_list.length < 1
                : !details?.data_plan || !details?.phone || !selectedProvider
              : false
          }
          onBtnClick={() => {
            step === 1 && handleNextStep("one");
            step === 2 && handleNextStep("two");
          }}
        >
          <div className="bill-payment-airtime-wrap animate-move-up-class">
            {step === 1 && (
              <>
                {/* select type box start */}
                <div className="select-type-box">
                  <p className="title dull-bright-filter-two">Select Network</p>
                  {/* select wrap start */}
                  <div className="select-wrap">
                    {providerList.map((chi, idx) => {
                      const { icon, name } = chi;
                      return (
                        <div className={`child-wrap`} key={idx}>
                          <figure
                            className={`img-box select-option-class  ${
                              selectedProvider === name &&
                              "select-option-class-active"
                            }`}
                            onClick={() => {
                              if (selectedProvider !== name) {
                                setSelectedProvider(name);
                                setDetails((prev) => {
                                  return { ...prev, provider: name };
                                });
                              }
                            }}
                          >
                            <div className="check-box">
                              <FaCheck className="icon" />
                            </div>
                            <img src={icon} alt="" className="img" />
                          </figure>
                        </div>
                      );
                    })}
                  </div>
                  {/* select wrap end */}
                </div>
                {/* select type box end */}
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    label="Phone number* "
                    labelSpanText="Use my number"
                    labelClassName={`label-span-class`}
                    labelColor={`purple-light`}
                    type={`phone`}
                    name={`phone`}
                    onChange={handleChange}
                    placeholder={`E.g 8162631...`}
                    value={details?.phone}
                    onActionClick={() =>
                      setDetails((prev) => {
                        return {
                          ...prev,
                          phone:
                            user?.phone?.slice(0, 1) === "0"
                              ? user?.phone.replace("0", "")
                              : user?.phone,
                        };
                      })
                    }
                  />
                </form>
                <form autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    color={`black-light`}
                    label="Data plans* "
                    onActionClick={() => {
                      actionCtx?.setShowAmount(!actionCtx?.showAmount);
                    }}
                    labelSpanText={`Bal: ${
                      actionCtx?.showAmount
                        ? formatNumWithCommaNairaSymbol(
                            actionCtx?.currentAccount?.balance
                          )
                        : "••••••"
                    }`}
                    labelColor={`black-light`}
                    labelClassName={`label-span-amount label-span-theme`}
                    type={`select`}
                    style={{ position: "relative", zIndex: "20" }}
                    disabled={!selectedProvider}
                    placeholder={
                      !selectedProvider
                        ? "Select Network first"
                        : "Select data plans"
                    }
                    className={`select-data-plan`}
                    selectStyles={reactSelectStyle}
                    name={`data_plan`}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, data_plan: e, amount: e.amount };
                      });
                    }}
                    value={details?.data_plan}
                    selectValue={details?.data_plan}
                    selectOption={formatSelectOption(dataPlans)}
                  />
                </form>

                {/* <RavenInputField
                  color={`black-light`}
                  label="Narration* "
                  labelColor={`black-light`}
                  type={`text`}
                  placeholder="E.g From timi"
                  name={`narration`}
                  onChange={handleChange}
                  value={details?.narration}
                /> */}
                {/* add another text start */}
                <div
                  onClick={() => {
                    selectedProvider &&
                      details?.data_plan &&
                      details?.phone &&
                      handleBulkTransfer();
                  }}
                  className={`add-another-box ${
                    selectedProvider &&
                    details?.data_plan &&
                    details?.phone &&
                    "add-another-box-active"
                  }`}
                >
                  <figure className="img-box">
                    <img src={addIcon} alt="" className="img" />
                  </figure>
                  <p className="text">Recharge for another recipient.</p>
                </div>
                {/* add another text end */}
                {/* recepient box start */}
                <div
                  className={`recepient-box ${
                    details?.bulk_list?.length > 0 && "recepient-box-show"
                  }`}
                >
                  <div className="text-line">
                    <span></span>
                    <p className="text">Recipients</p>
                    <span></span>
                  </div>
                  <div
                    style={{ zIndex: viewAll && "30" }}
                    className="view-total-box"
                  >
                    <p className="total">{`Total added (${details?.bulk_list?.length})`}</p>
                    <div className="view-wrap">
                      {/* view all box start */}
                      <div
                        className={`view-all-box card-bg ${
                          viewAll && "view-all-box-show"
                        }`}
                      >
                        <div className="wrap">
                          {details?.bulk_list?.map((chi, idx) => {
                            return (
                              <div key={idx} className="child-wrap">
                                <figure className="img-box">
                                  <img
                                    src={
                                      chi?.network === "mtn"
                                        ? mtnICon
                                        : chi?.network === "glo"
                                        ? gloICon
                                        : chi?.network === "airtel"
                                        ? airtelICon
                                        : mobileICon
                                    }
                                    alt=""
                                    className="img"
                                  />
                                </figure>
                                <div className="text-box">
                                  <p className="name">+234 {chi?.phone}</p>
                                  <p className="text grey-white-color">{` ${trimLongString(
                                    chi?.data_plan,
                                    20
                                  )}`}</p>
                                </div>
                                <div
                                  onClick={() => {
                                    handleRemoveFromList(chi);
                                  }}
                                  className="cancel-box"
                                >
                                  <FaTimes className="icon" />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* view all box end */}
                      {viewAll ? (
                        <p
                          onClick={() => {
                            setViewAll(false);
                            details?.bulk_list?.length === 1 &&
                              setViewAll(false);
                          }}
                          className="text"
                        >
                          Close
                        </p>
                      ) : (
                        <p
                          onClick={() => {
                            setViewAll(true);
                          }}
                          className="text"
                        >
                          View all
                        </p>
                      )}
                    </div>
                  </div>
                  {/* end of view total box */}
                  {/* preview list start */}
                  {!viewAll && (
                    <div className="preview-box">
                      {details?.bulk_list?.slice(0, 2).map((chi, idx) => {
                        const { phone, data_plan, network } = chi;
                        return (
                          <div className="box box-card grey-bg" key={idx}>
                            <div
                              onClick={() => {
                                handleRemoveFromList(chi);
                              }}
                              className="cancel-box"
                            >
                              <FaTimes className="icon" />
                            </div>
                            <figure className="img-box">
                              <img
                                src={
                                  network === "mtn"
                                    ? mtnICon
                                    : network === "glo"
                                    ? gloICon
                                    : network === "airtel"
                                    ? airtelICon
                                    : mobileICon
                                }
                                alt=""
                                className="img"
                              />
                            </figure>
                            <p className="name ">{`+234 ${phone}`}</p>
                            <p className="text grey-white-color">{` ${trimLongString(
                              data_plan,
                              15
                            )}`}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* preview list end */}
                  <div className="space-bottom"></div>
                </div>
                {/* recepient box end */}
              </>
            )}
            {step === 2 && (
              <>
                {/* select schedule start */}
                {/* <SelectScheduleBox
                textSchedule={`When would you like to send this data?`}
                  id={`onupd8uw`}
                  value={fromDate}
                  onChange={(e) => setFromDate(e)}
                  onReset={() => setFromDate("")}
                /> */}
                {/* select schedule end */}
                {details?.bulk_list?.length > 0 ? (
                  <NewPreviewDetailBox
                    collapse={true}
                    list={details?.bulk_list}
                    onReset={() => setFromDate("")}
                    valueSchedule={fromDate}
                    onChangeSchedule={(e) => {
                      setFromDate(e);
                    }}
                    value={details?.beneficiary}
                    onChange={(e) => {
                      handleAddBeneficiary(e.target.checked);
                    }}
                    amount={details?.amount}
                    name={details?.username}
                    narration={details?.narration}
                    checked={details?.beneficiary}
                    previewOpen={previewOpen}
                    setPreviewOpen={setPreviewOpen}
                    provider={details?.provider || selectedProvider}
                    number={details?.phone}
                    bulkAmount={
                      details?.bulk_list?.length > 0
                        ? formatNumWithCommaNairaSymbol(
                            getAllAmount(details?.bulk_list)
                          )
                        : formatNumWithCommaNairaSymbol(0)
                    }
                    handleRemoveFromList={(obj) => {
                      //   console.log(listTableToSubmit?.length);
                      if (step === 2 && details?.bulk_list?.length === 1) {
                        setStep(1);
                      }
                      handleRemoveFromList(obj);
                    }}
                  />
                ) : (
                  <>
                    {/* <PreviewDataDetailsBox
                      value={details?.beneficiary}
                      onChange={(e) => {
                        handleAddBeneficiary(e.target.checked);
                      }}
                      provider={selectedProvider}
                      number={details?.phone}
                      narration={details?.narration}
                      amount={details?.amount}
                      checked={details?.beneficiary}
                      data_plan={details?.data_plan?.name}
                    /> */}
                    <NewPreviewDetailBox
                      onReset={() => setFromDate("")}
                      valueSchedule={fromDate}
                      onChangeSchedule={(e) => {
                        setFromDate(e);
                      }}
                      network={selectedProvider}
                      value={details?.beneficiary}
                      onChange={(e) => {
                        handleAddBeneficiary(e.target.checked);
                      }}
                      data_plan={details?.data_plan?.name}
                      amount={details?.amount}
                      name={details?.username}
                      narration={details?.narration}
                      checked={details?.beneficiary}
                      previewOpen={previewOpen}
                      setPreviewOpen={setPreviewOpen}
                      provider={details?.provider || selectedProvider}
                      number={details?.phone}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      <TransactionPinModal
        pin={details?.user_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, user_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Data Purchase Successful."
        text={` You should receive a confirmation message with the details of your purchase shortly.  We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default DashboardBillPaymentData;
