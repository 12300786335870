import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../styles/fragments/GlobalSelectDropBox.css";
import { useOnClickOutside } from "../../utils/helper/UseOnClickOutside";
import { useDispatch } from "react-redux";
import { getAllCurrencies } from "../../redux/globalAccount";
import Skeleton from "react-loading-skeleton";

const dropIcon = (
  <svg
    className="img"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dull-bright-filter"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.70711 7.29289L10 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
      fill="#1A1A1A"
    />
  </svg>
);

const GlobalSelectDropBox = ({
  setCurrency,
  hideText,
  dropStyles,
  selectStyles,
  selectedCurrency,
  currencyList,
  dontautoUpdate,
  hideDrop,
  hideCur,
  selectedAutoCur,
  hideDropIcon,
}) => {
  const { all_currencies, all_global_account } = useSelector(
    (state) => state.global_account
  );
  const [showDrop, setShowDrop] = useState(false);
  const [selectedCur, setSelectedCur] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (all_currencies?.all_support_currencies?.length > 0) {
      // console.log(all_currencies?.all_support_currencies);
      // console.log();
      const usdCurrencyObj = all_currencies?.all_support_currencies?.find(
        (chi) =>
          chi?.currency ===
          all_global_account?.global_accounts_available_for_current_business?.find(
            (chi) => chi?.primary === 1
          )?.currency
      );
      // console.log(usdCurrencyObj);
      setSelectedCur(
        usdCurrencyObj ||
          all_global_account?.global_accounts_available_for_current_business?.find(
            (chi) => chi?.primary === 1
          )
      );
      !dontautoUpdate && setCurrency && setCurrency(usdCurrencyObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_currencies]);

  // useEffect(() => {
  //   console.log(selectedCurrency);
  //   if(selectedCurrency){
  //     console.log(selectedCurrency);
  //     // setSelectedCur(selectedCurrency)
  //   }
  // }, [selectedCurrency])

  function covertToLowerCase(cur) {
    let lowerCaseCurrency = cur?.toLowerCase();
    // console.log(lowerCaseCurrency);
    return lowerCaseCurrency;
  }

  const testingPreviewRefGlobalDrop = useOnClickOutside(() => {
    setShowDrop(false);
  });

  useEffect(() => {
    if (Object?.keys(all_currencies)?.length < 1) {
      dispatch(getAllCurrencies());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* select drop box stat */}
      <div
        onClick={() => {
          //   console.log(all_currencies);
          //   console.log(selectedCur);
        }}
        ref={testingPreviewRefGlobalDrop}
        className="global-account-select-drop-box-wrapper select-drop-box-wrapper"
      >
        {/* selected box start */}
        <div
          onClick={() => {
            setShowDrop(!showDrop);
          }}
          className="select-drop-box  grey-bg"
        >
          <div className="img-select">
            {hideCur ? (
              <>
                <div className="img-box">
                  {/* <Skeleton
                    width={`100%`}
                    height={`100%`}
                    style={{ borderRadius: "50%" }}
                  /> */}
                </div>
              </>
            ) : (
              <figure className="img-box">
                {" "}
                <img
                  src={`https://personalbanking.getraventest.com/static/media/currencies/${covertToLowerCase(
                    selectedCurrency?.currency || selectedCur?.currency
                  )}.png`}
                  className="img"
                  alt=""
                />
              </figure>
            )}
          </div>
          {!hideText && (
            <p className="value">
              {hideCur ? (
                <div style={{ width: "3rem" }}>
                  {/* <Skeleton width={30} height={20} /> */}
                </div>
              ) : (
                <span>
                  {" "}
                  {selectedCurrency?.currency || selectedCur?.currency}
                </span>
              )}
            </p>
          )}
          {!hideDropIcon && (
            <div className="drop-icon">
              {hideCur ? (
                <>
                  <Skeleton
                    width={12}
                    height={12}
                    style={{ borderRadius: "50%" }}
                  />
                </>
              ) : (
                <>
                  {!hideDropIcon && (
                    <figure className="img-box">{dropIcon}</figure>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {/* selected box end */}
        {/* drop box for all start */}
        {showDrop && !hideDrop && (
          <div
            style={dropStyles}
            className="drop-select-box card-bg border-theme"
          >
            {currencyList?.length > 0 ? (
              <>
                {" "}
                {currencyList?.map((chi, idx) => {
                  const { currency } = chi;
                  return (
                    <div
                      key={idx}
                      className={`item-row-global border-theme-bottom ${
                        selectedCurrency &&
                        selectedCurrency?.currency === currency
                          ? "grey-bg"
                          : !selectedCurrency &&
                            selectedCur?.currency === currency
                          ? "grey-bg"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          selectedCurrency
                            ? selectedCurrency?.currency !== currency
                            : selectedCur?.currency !== currency
                        ) {
                          setSelectedCur(chi);
                          setCurrency && setCurrency(chi);
                          setShowDrop(false);
                        }
                      }}
                    >
                      <div className="img-select">
                        <figure className="img-box">
                          <img
                            src={`https://personalbanking.getraventest.com/static/media/currencies/${covertToLowerCase(
                              currency
                            )}.png`}
                            className="img"
                            alt=""
                          />
                        </figure>
                      </div>
                      <p className="name">{currency}</p>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {" "}
                {all_currencies?.all_support_currencies?.map((chi, idx) => {
                  const { currency } = chi;
                  return (
                    <div
                      key={idx}
                      className={`item-row-global border-theme-bottom ${
                        selectedCur?.currency === currency && "grey-bg"
                      }`}
                      onClick={() => {
                        if (selectedCur?.currency !== currency) {
                          setSelectedCur(chi);
                          setCurrency && setCurrency(chi);
                          setShowDrop(false);
                          //   console.log(chi);
                        }
                      }}
                    >
                      <div className="img-select">
                        <figure className="img-box">
                          <img
                            src={`https://personalbanking.getraventest.com/static/media/currencies/${covertToLowerCase(
                              currency
                            )}.png`}
                            className="img"
                            alt=""
                          />
                        </figure>
                      </div>
                      <p className="name">{currency}</p>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
        {/* drop box for all end */}
      </div>
      {/* select drop box end */}
    </>
  );
};

export default GlobalSelectDropBox;
