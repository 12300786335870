import { RavenCheckBox, RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/send-money/SendMoneyIndex.css";
import addIcon from "../../../../assets/add-icon-form.svg";
// import { FaCheck } from "react-icons/fa";
import ravenLogo from "../../../../assets/raven-small-logo-green.svg";
import { FaTimes } from "react-icons/fa";
// import PrevieDetailsBox from "./PrevieDetailsBox";
// import ConfirmTransactionPinModal from "../ConfirmPinModal";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";
import { useDispatch } from "react-redux";
import { validateRavenUserName } from "../../../../redux/payroll";
import useWindowSize from "../../../../utils/helper/UseWindowSize";
import {
  filterEmptyColumns,
  formatNumWithCommaNairaSymbol,
  getAllAmount,
  // returnCbnTransferFee,
  trimLongString,
} from "../../../../utils/helper/Helper";
import ActionContext from "../../../../context/ActionContext";
import { useSelector } from "react-redux";
import useDebounce from "../../../../utils/helper/UseDebounce";
import { ThreeDots } from "react-loader-spinner";
import SuccessModal from "../../../../components/reusables/SuccessModal";
import { getAllAccounts, getUser } from "../../../../redux/user";
import { formatNumWithoutCommaNaira } from "../../../../utils/Helpers";
// import moment from "moment";
import {
  bulkTransferManualRaven,
  singleTransferManualRaven,
} from "../../../../redux/transaction";
import NewPreviewDetailBox from "./NewPreviewDetailBox";
import { useOnClickOutside } from "../../../../utils/helper/UseOnClickOutside";
// import UseNotificationSound from "../../../../components/reusables/newSoundReusable";
// import { useDarkMode } from "../../../../utils/themes/useDarkMode";
// import { darkTheme, lightTheme } from "../../../../utils/themes/theme";
// import waveSound from "../../../../assets/raven_success_sound.wav";

const DashboardSendMoneyRaven = () => {
  const [previewOpen, setPreviewOpen] = useState("");
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.transaction);
  const { loadingSearch } = useSelector((state) => state.payroll);
  const actionCtx = useContext(ActionContext);
  // const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  // const [verify, setVerify] = useState(false);
  // const playNotification = UseNotificationSound(waveSound);
  const stepList = ["Transfer detail", "Confirm Transactions"];
  const [viewAll, setViewAll] = useState(false);
  const [ravenName, setRavenName] = useState("");
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const [details, setDetails] = useState({
    username: "",
    amount: "",
    narration: "Sent from raven",
    bulk_list: [],
    beneficiary: false,
    schedule: "",
    transfer_pin: "",
    template_title: "",
    save_template: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleBulkTransfer = () => {
    const checkList = details?.bulk_list?.some(
      (chi) => chi?.username === details?.username
    );
    if (!checkList) {
      const obj = {
        account_name: ravenName,
        username: details?.username,
        amount: details?.amount,
        narration: details?.narration,
        beneficiary: false,
      };
      const newList = [obj, ...details?.bulk_list];
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          username: "",
          amount: "",
          narration: "Sent from raven",
        };
      });
      setRavenName("");
      // setVerify(false);
    } else {
      toast.error("Recepient already exist in bulk list");
    }
  };

  const handleAddBeneficiary = (obj, val) => {
    if (details?.bulk_list?.length > 0) {
      const newList = details?.bulk_list?.map((chi) =>
        chi?.username === obj?.username ? { ...chi, beneficiary: val } : chi
      );
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          username: "",
          amount: "",
          narration: "Sent from raven",
        };
      });
    } else {
      const newObj = { ...details, beneficiary: val };
      setDetails(newObj);
    }
  };

  const handleAddBeneficiaryAll = (val) => {
    // console.log(val);
    if (details?.bulk_list?.length > 0) {
      const newList = details?.bulk_list?.map((chi) => {
        return { ...chi, beneficiary: val };
      });
      setDetails((prev) => {
        return {
          ...prev,
          bulk_list: newList,
          username: "",
          amount: "",
          beneficiary: val,
          narration: "Sent from raven",
        };
      });
    } else {
      const newObj = { ...details, beneficiary: val };
      setDetails(newObj);
    }
  };

  const handleRemoveFromList = (obj) => {
    if (details?.bulk_list?.length === 1) {
      setViewAll(false);
      setStep(1);
    }
    const newList = details?.bulk_list?.filter(
      (chi) => chi?.username !== obj?.username
    );
    setDetails((prev) => {
      return {
        ...prev,
        bulk_list: newList,
      };
    });
  };

  const handleNextStep = (param) => {
    if (param === "one") {
      if (
        details?.amount &&
        details?.username &&
        ravenName &&
        details?.bulk_list?.length > 0
      ) {
        handleBulkTransfer();
      }
      setStep(2);
    }
    if (param === "two") {
      if (user?.pin_status === 0) {
        actionCtx.setCheckShow("nine");
      } else {
        setShowModal((prev) => {
          return { ...prev, pin: true };
        });
      }
      // setShowModal((prev) => {
      //   return { ...prev, pin: true };
      // });
    }
  };
  const [error, setError] = useState({
    username: false,
  });
  const [loadingVery, setLoadingVerify] = useState(false);
  const validateUsername = async (param) => {
    setLoadingVerify(true);
    let data = await dispatch(validateRavenUserName(param));
    // console.log(data);
    if (data?.payload?.status === "success") {
      setLoadingVerify(false);
      const info = data?.payload.data;
      const fullName = `${info?.fname} ${info?.lname}`;
      // console.log(fullName);
      setRavenName(fullName);
    }
    if (data?.payload?.data?.status === "fail") {
      setRavenName("");
      setLoadingVerify(false);
      setError((prev) => {
        return { ...prev, username: true };
      });
    }
  };
  const size = useWindowSize();

  const debouncedSearchTerm = useDebounce(details?.username, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 3) {
      const payload = {
        username: details?.username,
      };
      validateUsername(payload);
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      setRavenName("");
    }

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const [fromDate, setFromDate] = useState("");

  const handleSubmit = async (pin, token) => {
    if (details?.bulk_list?.length > 0) {
      const newList = details?.bulk_list?.map((chi) => {
        return {
          identifier: chi?.username,
          amount: formatNumWithoutCommaNaira(chi?.amount),
          currency: "NGN",
          narration: chi?.narration,
        };
      });
      const obj = {
        transfer_data: newList,
        transfer_pin: pin,
        two_factor_token: token,
        schedule: fromDate ? "1" : "0",
        schedule_time: fromDate ? fromDate : "",
        template_title: details?.save_template ? details?.template_title : "",
        selected_account_number: actionCtx?.currentAccount?.account_number,
      };
      // console.log(obj);
      // return;
      const data = await dispatch(bulkTransferManualRaven(obj));
      if (data?.payload?.data?.status === "success") {
        //  console.log(data);
        handleFinish();
      }
    } else {
      let val;
      if (fromDate) {
        const obj = {
          transfer_pin: pin,
          two_factor_token: token,
          schedule: fromDate ? "1" : "0",
          schedule_time: fromDate ? fromDate : "",
          amount: formatNumWithoutCommaNaira(details?.amount),
          currency: "NGN",
          narration: details?.narration,
          identifier: details?.username,
          selected_account_number: actionCtx?.currentAccount?.account_number,
          // template_title: details?.save_template ? details?.template_title : "",
        };
        val = obj;
      } else {
        const obj = {
          transfer_pin: pin,
          two_factor_token: token,
          schedule: fromDate ? "1" : "0",
          schedule_time: fromDate ? fromDate : "",
          amount: formatNumWithoutCommaNaira(details?.amount),
          currency: "NGN",
          narration: details?.narration,
          identifier: details?.username,
          selected_account_number: actionCtx?.currentAccount?.account_number,
          // template_title: details?.save_template ? details?.template_title : "",
        };
        val = obj;
      }
      // console.log(val);
      // return;
      const data = await dispatch(singleTransferManualRaven(filterEmptyColumns(val)));
      if (data?.payload?.data?.status === "success") {
        // playNotification()
        handleFinish();
      }
    }
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return { ...prev, success: true, pin: false };
    });
    dispatch(getUser());
    dispatch(getAllAccounts());
    setViewAll(false);
    setStep(1);
    setFromDate("");
    setRavenName("");
    setDetails({
      username: "",
      amount: "",
      narration: "Sent from raven",
      bulk_list: [],
      beneficiary: false,
      schedule: "",
      transfer_pin: "",
      template_title: "",
      save_template: false,
    });
    setPreviewOpen(false);
    navigate("/dashboard-overview");
  };

  const testingPreviewRavenRef = useOnClickOutside(() => {
    setViewAll(false);
  });

  useEffect(() => {
    if (
      actionCtx?.currentAccount?.authorization_level !==
        ("initiator_approver" || "initiator") &&
      actionCtx?.currentAccount?.authorization_level !==
        ("initiator" || "initiator_approver")
    ) {
      navigate("/dashboard-send-money");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Send to Raven user`}
          text={`Send funds to users registered with Raven`}
          back
          onBack={() => {
            navigate(`/dashboard-send-money`);
          }}
        />
        {/* title box end */}
        <ContainerWrapper
          width={size?.width < 900 && `100%`}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          onBtnClick={() => {
            step === 1 && handleNextStep("one");
            step === 2 && handleNextStep("two");
          }}
          activeStep={step}
          stepList={stepList}
          btnText={`Complete Transfer`}
          disabled={
            step === 1
              ? details?.bulk_list?.length > 0
                ? details?.bulk_list?.length < 1
                : !details?.amount ||
                  !details?.narration ||
                  !details?.username ||
                  !ravenName
              : step === 2
              ? details?.save_template
                ? !details?.template_title
                : false
              : false
          }
        >
          <div
            // onClick={() => {
            //   // String(user?.two_factor) === "0"
            //   console.log(user);
            // }}
            className="send-money-wrap-box-raven animate-move-up-class"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              action=""
              className="form"
              style={{ paddingBottom: viewAll && "10rem" }}
              autoComplete="off"
            >
              {/* step 1 start ------------------------------ */}
              {step === 1 && (
                <>
                  <div className="bg-box"></div>
                  {/* end of bg box */}
                  <RavenInputField
                    type={`text`}
                    color={`black-light`}
                    label={`Username* `}
                    value={details?.username}
                    onChange={(e) => {
                      handleChange(e);
                      setError((prev) => {
                        return { ...prev, username: false };
                      });
                    }}
                    placeholder={`E.g akot`}
                    labelColor={`purple-light`}
                    showError={error?.username}
                    labelSpanText={
                      loadingVery || loadingSearch ? (
                        <div
                          style={{
                            padding: "0rem",
                            display: "flex",
                            justifyContent: "flex-end",
                            transform: "translateX(1.5rem)",
                          }}
                          className="load-wrap"
                        >
                          <ThreeDots
                            height="10"
                            width="80"
                            radius="9"
                            // color="#020202"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Verify"
                      )
                    }
                    labelClassName={`label-class-span ${
                      details?.account_num?.length > 1 &&
                      "label-class-span-active"
                    }`}
                    name={`username`}
                    onActionClick={() => {
                      details?.username.length > 3 &&
                        validateUsername(details?.username);
                    }}
                  />
                  {/* verify name box start */}
                  <div
                    className={`verify-name-box ${
                      ravenName && "verify-name-box-show"
                    }`}
                  >
                    <RavenCheckBox
                      checked={true}
                      color={`black-light`}
                      className={`check`}
                    />
                    <p className="text">{ravenName}</p>
                  </div>
                  {/* verify name box end */}
                  <RavenInputField
                    type={`number`}
                    color={`black-light`}
                    label={`Amount*`}
                    name={`amount`}
                    value={details?.amount}
                    onChange={handleChange}
                    placeholder={`How much are you sending`}
                    labelColor={`black-light`}
                    labelSpanText={
                      actionCtx?.showAmount
                        ? `Bal: ${formatNumWithCommaNairaSymbol(
                            actionCtx?.currentAccount?.balance
                          )}`
                        : "Bal: ₦ ••••••"
                    }
                    labelClassName={`label-span-amount label-span-theme`}
                    thousandFormat
                    numberPrefix={`₦`}
                    onActionClick={() => {
                      actionCtx?.setShowAmount(!actionCtx?.showAmount);
                    }}
                  />
                  {details?.bulk_list < 1 && (
                    <RavenInputField
                      type={`text`}
                      color={`black-light`}
                      label={`Narration* `}
                      value={details?.narration}
                      onChange={handleChange}
                      placeholder={`E.g From timi`}
                      name={`narration`}
                      capitalize
                    />
                  )}
                  {/* add another text start */}
                  <div
                    onClick={() => {
                      details?.amount &&
                        details?.username &&
                        ravenName &&
                        handleBulkTransfer();
                    }}
                    className={`add-another-box ${
                      details?.amount &&
                      details?.username &&
                      ravenName &&
                      "add-another-box-active"
                    }`}
                  >
                    <figure className="img-box">
                      <img src={addIcon} alt="" className="img" />
                    </figure>
                    <p className="text">Transfer to another account.</p>
                  </div>
                  {/* add another text end */}
                  {/* recepient box start */}
                  <div
                    className={`recepient-box ${
                      details?.bulk_list?.length > 0 && "recepient-box-show"
                    }`}
                  >
                    <div className="text-line">
                      <span></span>
                      <p className="text">Recipients</p>
                      <span></span>
                    </div>
                    <div className="view-total-box">
                      <p className="total">{`Total added (${details?.bulk_list?.length})`}</p>
                      <div ref={testingPreviewRavenRef} className="view-wrap">
                        {/* view all box start */}
                        <div
                          className={`view-all-box card-bg ${
                            viewAll && "view-all-box-show"
                          }`}
                        >
                          <div className="wrap">
                            {details?.bulk_list?.map((chi, idx) => {
                              return (
                                <div key={idx} className="child-wrap">
                                  <figure className="img-box">
                                    <img
                                      src={ravenLogo}
                                      alt=""
                                      className="img"
                                    />
                                  </figure>
                                  <div className="text-box">
                                    <p className="name">@{chi?.username}</p>
                                    <p className="text grey-white-color">{`${
                                      trimLongString(chi?.account_name, 15) ||
                                      "---"
                                    } • ${chi?.amount}`}</p>
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleRemoveFromList(chi);
                                    }}
                                    className="cancel-box"
                                  >
                                    <FaTimes className="icon" />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* view all box end */}
                        {viewAll ? (
                          // <div
                          //   onClick={() => {
                          //     setViewAll(false);
                          //   }}
                          //   style={{
                          //     display: "flex",
                          //     alignItems: "center",
                          //     gap: ".7rem",
                          //     cursor: "pointer",
                          //   }}
                          // >
                          //   <div className="cancel-box">
                          //     <FaTimes className="icon" />
                          //   </div>
                          //   <span>Close</span>
                          // </div>
                          <p
                            onClick={() => {
                              setViewAll(false);
                              details?.bulk_list?.length === 1 &&
                                setViewAll(false);
                            }}
                            className="text"
                          >
                            Close
                          </p>
                        ) : (
                          <p
                            onClick={() => {
                              setViewAll(true);
                            }}
                            className="text"
                          >
                            View all
                          </p>
                        )}
                      </div>
                    </div>
                    {/* end of view total box */}
                    {/* preview list start */}
                    {!viewAll && (
                      <div className="preview-box">
                        {details?.bulk_list?.slice(0, 2).map((chi, idx) => {
                          const { username, amount } = chi;
                          return (
                            <div className="box box-card grey-bg" key={idx}>
                              <div
                                onClick={() => {
                                  handleRemoveFromList(chi);
                                }}
                                className="cancel-box"
                              >
                                <FaTimes className="icon" />
                              </div>
                              <figure className="img-box">
                                <img src={ravenLogo} alt="" className="img" />
                              </figure>
                              <p className="name">@{username}</p>
                              <p className="text grey-white-color">{`${
                                trimLongString(chi?.account_name, 15) || "---"
                              } • ${amount}`}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* preview list end */}
                  </div>
                  {/* recepient box end */}
                </>
              )}
              {/* step 1 end -------------------------------- */}
              {/* step 2 start ----------------------------- */}
              {step === 2 && (
                <div className="step-two-send-raven animate-move-up-class">
                  {/* select schedule start */}
                  {/* <SelectScheduleBox
                    id={`onudjhahabafw`}
                    value={fromDate}
                    onChange={(e) => {
                      setFromDate(e);
                    }}
                    onReset={() => setFromDate("")}
                  /> */}
                  {/* select schedule end */}
                  {/* <PrevieDetailsBox collapse onChange={(e) => {console.log(e.target.checked);}} /> */}
                  {details?.bulk_list?.length > 0 ? (
                    // <>
                    //   {details?.bulk_list?.length === 1 ? (
                    //     <>
                    //       {details?.bulk_list?.map((chi, idx) => {
                    //         const {
                    //           username,
                    //           amount,
                    //           beneficiary,
                    //           narration,
                    //           account_name,
                    //         } = chi;
                    //         return (
                    //           <PrevieDetailsBox
                    //             key={idx}
                    //             value={beneficiary}
                    //             onChange={(e) => {
                    //               handleAddBeneficiary(chi, e.target.checked);
                    //             }}
                    //             account_name={account_name}
                    //             narration={narration}
                    //             name={username}
                    //             amount={amount}
                    //             checked={beneficiary}
                    //             previewOpen={previewOpen}
                    //             setPreviewOpen={setPreviewOpen}
                    //           />
                    //         );
                    //       })}
                    //     </>
                    //   ) : (
                    //     <>
                    //       {details?.bulk_list?.map((chi, idx) => {
                    //         const {
                    //           username,
                    //           amount,
                    //           beneficiary,
                    //           narration,
                    //           account_name,
                    //         } = chi;
                    //         return (
                    //           <PrevieDetailsBox
                    //             key={idx}
                    //             id={idx}
                    //             value={beneficiary}
                    //             onChange={(e) => {
                    //               handleAddBeneficiary(chi, e.target.checked);
                    //             }}
                    //             account_name={account_name}
                    //             narration={narration}
                    //             name={username}
                    //             amount={amount}
                    //             collapse
                    //             checked={beneficiary}
                    //             previewOpen={previewOpen}
                    //             setPreviewOpen={setPreviewOpen}
                    //           />
                    //         );
                    //       })}
                    //     </>
                    //   )}
                    // </>
                    <>
                      <NewPreviewDetailBox
                        onReset={() => setFromDate("")}
                        valueSchedule={fromDate}
                        onChangeSchedule={(e) => {
                          setFromDate(e);
                        }}
                        value={details?.beneficiary}
                        onChange={(e) => {
                          handleAddBeneficiaryAll(e.target.checked);
                        }}
                        collapse
                        list={details?.bulk_list}
                        bulkAmount={
                          details?.bulk_list?.length > 0
                            ? formatNumWithCommaNairaSymbol(
                                getAllAmount(details?.bulk_list)
                              )
                            : formatNumWithCommaNairaSymbol(0)
                        }
                        amount={details?.amount}
                        name={details?.username}
                        narration={details?.narration}
                        checked={details?.beneficiary}
                        previewOpen={previewOpen}
                        setPreviewOpen={setPreviewOpen}
                        account_name={ravenName}
                        handleRemoveFromList={handleRemoveFromList}
                        details={details}
                        setDetails={setDetails}
                        handleChange={handleChange}
                      />
                    </>
                  ) : (
                    <>
                      {/* <PrevieDetailsBox
                        value={details?.beneficiary}
                        onChange={(e) => {  {bulkList.length > 0
                          ? formatNumWithCommaNairaSymbol(
                              getAllAmount(bulkList)
                            )
                          : formatNumWithCommaNairaSymbol(0)}
                          handleAddBeneficiary(e.target.checked);
                        }}
                        //   account_num={details?.}
                        amount={details?.amount}
                        name={details?.username}
                        narration={details?.narration}
                        checked={details?.beneficiary}
                        previewOpen={previewOpen}
                        setPreviewOpen={setPreviewOpen}
                        account_name={ravenName}
                      /> */}
                      <NewPreviewDetailBox
                        // transferFee={
                        //   user?.transfer_fee_details?.transfer_fee_style ===
                        //   "cbn"
                        //     ? returnCbnTransferFee(user?.transfer_fee_details?.transfer_fee, details?.amount)
                        //     : formatNumWithCommaNairaSymbol(user?.transfer_fee_details?.transfer_fee?.flat)
                        // }
                        onReset={() => setFromDate("")}
                        valueSchedule={fromDate}
                        onChangeSchedule={(e) => {
                          setFromDate(e);
                        }}
                        value={details?.beneficiary}
                        onChange={(e) => {
                          handleAddBeneficiary(e.target.checked);
                        }}
                        amount={details?.amount}
                        name={details?.username}
                        narration={details?.narration}
                        checked={details?.beneficiary}
                        previewOpen={previewOpen}
                        setPreviewOpen={setPreviewOpen}
                        account_name={ravenName}
                      />
                    </>
                  )}
                </div>
              )}
              {/* step 2 end ----------------------------- */}
            </form>
          </div>
        </ContainerWrapper>
        {/* <ConfirmTransactionPinModal visible={!showModal} /> */}
      </DashboardLayout>
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Transaction Successful."
        text={`You should also receive a confirmation email with the details of the transaction. We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default DashboardSendMoneyRaven;
