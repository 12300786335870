import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import { changePrimarySubAccount } from "../../../../redux/sub_account";

const ChangePrimaryModal = ({ detail, visible, onClose, onFinish }) => {
  const { loading,  } = useSelector((state) => state.sub_account);
  const dispatch = useDispatch();

  

  const handleSubmit = async () => {
    const obj = {
      account_id: String(detail?.id),
    }
    const data = await dispatch(changePrimarySubAccount(obj));
    if(data?.payload?.data?.status === "success"){
      onFinish()
    }
  };


  return (
    <DeleteModal
      title={`Change ( ${detail?.custom_account_name} ) to Primary`}
      text={`Change this sub account to primary, would disable their access to your account on your business, ensure you confirm this device before totally removing them from your account.`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
      loading={loading}
      buttonColor={`black-light`}
    />
  );
};

export default ChangePrimaryModal;
